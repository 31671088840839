import { Button } from '@chakra-ui/react';
import { Table } from 'antd';
import React, { useState } from 'react';
import { generateAndDownloadExcel } from '../../../services/excel-export';
import ResultsGrid from '../ResultsGrid';
import analyzer from './analyzer';
import {downloadFromS3Link} from "../../../helpers/downloads";

const GRID = false;

const defaultValueGetter = value => {
  if (value === undefined || value === null) {
    return '';
  }
  if (typeof value === 'object') {
    return 'Complex';
    // return JSON.stringify(value);
  }
  return value;
};

const createDefaultColumnsGrid = rawRows => {
  const analyzedColumns = analyzer(rawRows);
  return Object.entries(analyzedColumns).map(([key, val]) => {
    return {
      key,
      name: key,
      sortable: true,
      formatter: props => {
        const { value } = props;
        return <span>{defaultValueGetter(value)}</span>;
      },
    };
  });
};

const createDefaultColumnsTable = rawRows => {
  const analyzedColumns = analyzer(rawRows);

  const handleDownload = (value) => {
    downloadFromS3Link(value);
  };
  return Object.entries(analyzedColumns).map(([key, val]) => {
    const column = {
      title: key,
      dataIndex: key,
      key,
      render: value => {
        if (value?.toString().includes('https://s3.amazonaws.com')) {
          return <button onClick={() => handleDownload(value)}>Download File</button>;
        }
        return <span>{defaultValueGetter(value)}</span>;
      },
    };

    if (val.isSearchable) {
    } else if (val.selectable) {
      const uniqueKeys = Array.from(val.uniqueValues.keys());
      const sortedKeys = uniqueKeys.sort((a, b) => {
        const aVal = a.toUpperCase ? a.toUpperCase() : a;
        const bVal = b.toUpperCase ? b.toUpperCase() : b;
        if (aVal > bVal) {
          return 1;
        }
        if (aVal < bVal) {
          return -1;
        }
        return 0;
      });
      column.filters = sortedKeys.map(key => ({ text: key, value: key }));
      column.onFilter = (value, record) => {
        if (record[key]) {
          if (val.dataType === 'string') {
            return record[key].indexOf(value) === 0;
          }
          if (val.dataType === 'number') {
            return record[key] === value;
          }
        }
      };
    }

    if (val.sortable) {
      if (val.dataType === 'number') {
        column.sorter = (a, b) => a[key] - b[key];
      } else if (val.dataType === 'date') {
      } else if (val.dataType === 'string') {
        column.sorter = (a, b) => {
          const aVal = a[key] ? a[key].toUpperCase() : '';
          const bVal = b[key] ? b[key].toUpperCase() : '';
          if (aVal > bVal) {
            return 1;
          }
          if (aVal < bVal) {
            return -1;
          }
          return 0;
        };
      }
    }

    return column;
  });
};

const DataTable = ({ data, fileName }) => {
  const [filteredData, setFilteredData] = useState(data);
  const columns = GRID ? createDefaultColumnsGrid(data) : createDefaultColumnsTable(data);
  const excelHeaders = columns.map(columnConfig => ({
    headerText: GRID ? columnConfig.name : columnConfig.title,
    getter: row => {
      return defaultValueGetter(row[columnConfig.key]);
    },
  }));

  const worksheetConfig = [
    {
      worksheetName: 'Report Results',
      columnConfigs: excelHeaders,
      data: filteredData || data,
    },
  ];
  if (!GRID) {
    data.forEach((d, idx) => (d.key = idx));
  }
  if (GRID) {
    return (
      <div>
        <div style={{ height: '80vh', overflowY: 'scroll' }}>
          {data && data.length && <ResultsGrid id="data-table" columns={columns} rows={data} renderFullTable />}
        </div>
        <div style={{ padding: 20 }}>
          <span>Total Records: {data.length}</span>
          <Button
            colorScheme="actionPrimary"
            style={{ marginLeft: 20 }}
            onClick={() => {
              generateAndDownloadExcel(fileName, worksheetConfig);
            }}
          >
            Export to Excel
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        scroll={{ x: true }}
        onChange={(...changedData) => {
          setFilteredData(changedData[3].currentDataSource);
        }}
      />
      <div>
        <span>Total Records: {data.length}</span>
        <Button
          style={{ marginLeft: 20 }}
          colorScheme="actionPrimary"
          onClick={() => {
            generateAndDownloadExcel(fileName, worksheetConfig);
          }}
        >
          Export to Excel
        </Button>
      </div>
    </div>
  );
};

export default DataTable;
