import isEmpty from 'lodash/isEmpty';

import api from '../api';
import Constants from '../Constants';

export const FDR_FREIGHT_RATES_FETCHED = 'FDR_FREIGHT_RATES_FETCHED';
export const FDR_FREIGHT_RATE_ADDED = 'FDR_FREIGHT_RATE_ADDED';
export const FDR_FREIGHT_RATE_UPDATED = 'FDR_FREIGHT_RATE_UPDATED';
export const FDR_FREIGHT_RATE_REMOVED = 'FDR_FREIGHT_RATE_REMOVED';
export const FDR_FREIGHT_RATE_ERROR = 'FDR_FREIGHT_RATE_ERROR';

const basePath = '/fdr_freight_rates';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export const loadFDRFreightRates = FDRFreightRates => {
  return {
    type: FDR_FREIGHT_RATES_FETCHED,
    payload: FDRFreightRates,
  };
};

export const addFDRFreightRate = FDRFreightRate => {
  return {
    type: FDR_FREIGHT_RATE_ADDED,
    payload: FDRFreightRate,
  };
};

export const removeFDRFreightRate = FDRFreightRate => {
  return {
    type: FDR_FREIGHT_RATE_REMOVED,
    payload: FDRFreightRate,
  };
};

export const updateFDRFreightRate = FDRFreightRate => {
  return {
    type: FDR_FREIGHT_RATE_UPDATED,
    payload: FDRFreightRate,
  };
};

export const errorFDRFreightRates = error => {
  return {
    type: FDR_FREIGHT_RATE_ERROR,
    payload: error,
  };
};

/* ---------------------------------
    THUNKS
-----------------------------------*/
export const fetchFDRFreightRates = () => dispatch => {
  return api
    .get(basePath)
    .then(response => Constants.handleErrors(response, dispatch, errorFDRFreightRates))
    .then(json => dispatch(loadFDRFreightRates(json)))
    .catch(error => dispatch(errorFDRFreightRates(error)));
};

export const createFDRFreightRate = payload => dispatch => {
  return api
    .post(basePath, payload)
    .then(response => Constants.handleErrors(response, dispatch, errorFDRFreightRates))
    .then(json => dispatch(addFDRFreightRate(json)))
    .catch(error => dispatch(errorFDRFreightRates(error)));
};

export const patchFDRFreightRate = FDRFreightRate => dispatch => {
  const url = `${basePath}/${FDRFreightRate.id}`;
  const { id, unit_of_measure: uom, grinder, fdr_item: fdr, buy_incoterms: buyIncoterms, ...payload } = FDRFreightRate;
  if (!isEmpty(buyIncoterms)) {
    payload.buy_incoterms = buyIncoterms.map(inco => inco.value);
  }
  return api
    .patch(url, payload)
    .then(response => Constants.handleErrors(response, dispatch, errorFDRFreightRates))
    .then(json => dispatch(updateFDRFreightRate(json)))
    .catch(error => dispatch(errorFDRFreightRates(error)));
};

export const deleteFDRFreightRate = FDRFreightRate => dispatch => {
  const url = `${basePath}/${FDRFreightRate.id}`;
  return api
    .delete(url)
    .catch(response => Constants.handleErrors(response, dispatch, errorFDRFreightRates))
    .then(() => dispatch(removeFDRFreightRate(FDRFreightRate)))
    .catch(error => dispatch(errorFDRFreightRates(error)));
};
