import { createSelector, createSlice } from '@reduxjs/toolkit';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import set from 'lodash/set';
// Local Deps
import api from '../../api';
import Constants from '../../Constants';
import { createOptions, selectOrderOptions } from '../masterData/selectOrderEntities';
import views from './bulk-updater-views';

/* ---------------------------------
    SLICE
-----------------------------------*/
export const bulkUpdaterSlice = createSlice({
  name: 'bulkUpdater',
  initialState: {
    views: [],
    isLoading: false,
    error: null,
    isSubmitting: false,
    submissionError: null,
    submissionWasSuccessful: false,
  },
  reducers: {
    requestBulkUpdaterViews: state => {
      state.error = null;
      state.isLoading = true;
      state.views = [];
    },
    loadBulkUpdaterViews: (state, action) => {
      state.error = null;
      state.isLoading = false;
      state.views = action.payload.views;
    },
    errorBulkUpdaterViews: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    initiateBulkSubmission: state => {
      state.isSubmitting = true;
      state.submissionError = null;
      state.submissionWasSuccessful = false;
    },
    completeBulkSubmission: state => {
      state.isSubmitting = false;
      state.submissionWasSuccessful = true;
    },
    errorBulkUpdaterSubmission: (state, action) => {
      state.isSubmitting = false;
      state.submissionError = action.payload;
      state.submissionWasSuccessful = false;
    },
  },
});

/* ---------------------------------
    THUNKS
-----------------------------------*/
export const {
  requestBulkUpdaterViews,
  loadBulkUpdaterViews,
  errorBulkUpdaterViews,
  initiateBulkSubmission,
  completeBulkSubmission,
  errorBulkUpdaterSubmission,
} = bulkUpdaterSlice.actions;

/* TO REMOVE */
export function fetchBulkUpdaterViews() {
  return dispatch => {
    dispatch(requestBulkUpdaterViews);
    // TMP in FE should be moved to BE
    dispatch(loadBulkUpdaterViews({ views }));
    /* return api
      .get(`/order_management/purchase_orders/bulk/edit/view`)
      .then(response => Constants.handleErrors(response, dispatch, errorBulkUpdaterViews))
      .then(json => {
        dispatch(loadBulkUpdaterViews(json));
      })
      .catch(error => dispatch(errorBulkUpdaterViews(error))); */
  };
}

/* TO REMOVE */
export function submitBulkUpdaterChanges(trackedChanges) {
  // TODO -- manipulate tracked changes for submission here.
  // (This is part of BOP-6327)
  const formattedOrders = trackedChanges.reduce((agg, change) => {
    const { id: purchaseOrderLineId, purchaseOrderId, key, new: newValue, colKey } = change;

    // TODO -- Fix hard-coded exception of packer_plant.name
    const isOrderProperty = colKey.includes('order.') || colKey.includes('packer_plant.name');
    const orderToUpdate = agg[purchaseOrderId] || { id: purchaseOrderId };
    // ----------------------------------
    // Handle order-level changes:
    // ----------------------------------
    if (isOrderProperty) {
      // Any order-specific keys that don't have a designated rule
      // will need to be trimmed:
      const trimmedKey = key.replace('order.', '');
      // Update order property:
      set(orderToUpdate, trimmedKey, newValue);
      return {
        ...agg,
        [purchaseOrderId]: orderToUpdate,
      };
    }

    // ----------------------------------
    // Handle line-level changes:
    // ----------------------------------
    const lineToUpdate = orderToUpdate.lines?.[purchaseOrderLineId] || { id: purchaseOrderLineId };
    set(lineToUpdate, key, newValue);
    return {
      ...agg,
      [purchaseOrderId]: {
        ...orderToUpdate,
        lines: {
          ...orderToUpdate.lines,
          [purchaseOrderLineId]: {
            ...lineToUpdate,
          },
        },
      },
    };
  }, {});

  const payload = Object.values(formattedOrders).map(order => ({
    ...order,
    // Turn lines object, if it exists, into an array
    ...(order.lines && { lines: Object.values(order.lines) }),
  }));

  return dispatch => {
    dispatch(initiateBulkSubmission());

    // We use the Syndey bulk patch API here, because it handles both Chicago invoicing office
    // and Sydney invoicing office orders.
    // The key difference being the child entities associated with Sydney invoicing office orders.
    return api
      .patch('/order_management/purchase_orders/bulk/sydney', payload)
      .then(response => {
        return Constants.handleErrors(response, dispatch, errorBulkUpdaterSubmission);
      })
      .then(() => {
        dispatch(completeBulkSubmission());
      })
      .catch(error => {
        return dispatch(errorBulkUpdaterSubmission(error));
      });
  };
}

export function submitBulkUpdater(posChanges) {
  return dispatch => {
    dispatch(initiateBulkSubmission());

    // We use the Syndey bulk patch API here, because it handles both Chicago invoicing office
    // and Sydney invoicing office orders.
    // The key difference being the child entities associated with Sydney invoicing office orders.
    return api
      .patch('/order_management/purchase_orders/bulk/sydney', posChanges)
      .then(response => {
        return Constants.handleErrors(response, dispatch, errorBulkUpdaterSubmission);
      })
      .then(() => {
        dispatch(completeBulkSubmission());
      })
      .catch(error => {
        return dispatch(errorBulkUpdaterSubmission(error));
      });
  };
}


/* ---------------------------------
    SELECTORS
-----------------------------------*/
/* TO REMOVE */
export function selectBulkUpdaterViews(state) {
  const raw = state.bulkUpdater.views || [];
  // Make fields in view have options:
  const viewsWithFormattedFields = raw.map(view => ({
    ...view,
    fields: createOptions(
      view.fields,
      field => startCase(field?.field?.label),
      field => field?.field?.key
    ),
  }));
  const viewsFormattedAsOptions = createOptions(viewsWithFormattedFields, 'name', 'fields');
  return viewsFormattedAsOptions;
}

// Todo - Move to Constants on BE at some point
/* TO REMOVE */
const lineStatusOptions = [
  {
    label: 'Cancelled',
    value: false,
  },
];

export const selectBulkUpdaterViewsWithOptions = createSelector(
  selectBulkUpdaterViews,
  selectOrderOptions,
  (views, orderOptions) => {
    const updatedOptions = {
      ...orderOptions,
      lineStatusOptions,
    };

    const updatedViews = views.map(view => {
      const updatedFields = view.fields.map(field => {
        const { reference_key: referenceKey, type } = field.field;
        if (type !== 'reference') return field;
        const foundOptions = get(updatedOptions, `${referenceKey}Options`) || [];
        return {
          ...field,
          options: foundOptions,
        };
      });
      return {
        ...view,
        fields: updatedFields,
      };
    });
    return updatedViews;
  }
);

export const selectBulkUpdaterSubmissionData = state => {
  const { isSubmitting, submissionError, submissionWasSuccessful } = state.bulkUpdater;
  return {
    isSubmitting,
    error: submissionError,
    wasSuccessful: submissionWasSuccessful,
  };
};

/* ---------------------------------
    REDUCER
-----------------------------------*/
// This goes to the root reducer in reducers/index.js
export default bulkUpdaterSlice.reducer;
