const schema = [
  {
    name: 'specifications',
    displayName: 'Specification',
    systemIdField: 'uid',
    deletable: true,
    path: '/specifications',
    fields: [
      {
        name: 'uid',
        displayName: 'ID',
        tooltip: 'Unique identifier for the specification',
        dataType: 'text',
        required: true,
        systemIdentifier: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'The name or description of the specification',
        dataType: 'text',
        required: true,
      },
    ],
  },
];

export default schema;
