import Constants from '../Constants';

export const LOAD_OPTIMIZER_DATA = 'LOAD_OPTIMIZER_DATA';
export const OPTIMIZER_PACKER_PLANT_BEEF_INPUT_ADDED = 'OPTIMIZER_PACKER_PLANT_BEEF_INPUT_ADDED';
export const OPTIMIZER_PACKER_PLANT_BEEF_INPUT_EDIT = 'OPTIMIZER_PACKER_PLANT_BEEF_INPUT_EDIT';
export const OPTIMIZER_PACKER_PLANT_BEEF_INPUT_REMOVE = 'OPTIMIZER_PACKER_PLANT_BEEF_INPUT_REMOVE';
export const EDIT_OPTIMIZER_TRANSPORT = 'EDIT_OPTIMIZER_TRANSPORT';
export const OPTIMIZER_ERROR = 'OPTIMIZER_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadOptimizerData(data) {
  return {
    type: LOAD_OPTIMIZER_DATA,
    payload: data,
  };
}

export function addOptimizerPackerBeefInput(inputProduct) {
  const copy = {
    ...inputProduct,
    input_product_uid: Number(inputProduct.input_product_uid),
  };
  return {
    type: OPTIMIZER_PACKER_PLANT_BEEF_INPUT_ADDED,
    payload: copy,
  };
}

export function editOptimizerPackerBeefInput(inputProduct) {
  const copy = {
    ...inputProduct,
    price: parseFloat(inputProduct.price),
    quantity: parseFloat(inputProduct.quantity),
  };

  return {
    type: OPTIMIZER_PACKER_PLANT_BEEF_INPUT_EDIT,
    payload: copy,
  };
}

export function removeOptimizerPackerBeefInput(inputProduct) {
  return {
    type: OPTIMIZER_PACKER_PLANT_BEEF_INPUT_REMOVE,
    payload: inputProduct,
  };
}

export function editOptimizerTransport(transport) {
  return {
    type: EDIT_OPTIMIZER_TRANSPORT,
    payload: transport,
  };
}

export function errorOptimizer(error) {
  return {
    type: OPTIMIZER_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchOptimizerData(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}optimiser/data`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOptimizer))
      .then(json => dispatch(loadOptimizerData(json)))
      .catch(error => dispatch(errorOptimizer(error)));
  };
}
