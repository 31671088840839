import { connect } from 'react-redux';

import DashboardComponent from '../../components/pages/Dashboard';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

const mapStateToProps = state => {
  return {
    ...findEntitiesInState(state, { grinders: 'grinder' }),
    user: state.user,
    input_product_inventory_levels: state.input_product_inventory_levels,
    token: state.user.token,
  };
};

const Dashboard = connect(mapStateToProps)(DashboardComponent);

export default Dashboard;
