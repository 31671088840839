import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ant-design/compatible';
import { FieldArray } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Loading from '../Loading';
import './EmailModal.scss';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button as ChakraButton,
  Flex,
  Text,
  Grid,
  GridItem,
  VStack,
  Input as ChakraInput,
  ButtonGroup,
  Alert as ChakraAlert,
  AlertIcon,
  AlertDescription,
  Textarea,
} from '@chakra-ui/react';

const propTypes = {
  attachmentList: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  initialValues: PropTypes.shape({
    body: PropTypes.string,
    subject: PropTypes.string,
  }),
  error: PropTypes.string,
  handleSubmit: PropTypes.func,
  isDownloadingAllAttachments: PropTypes.bool,
  isLoadingAttachments: PropTypes.bool,
  isSendingEmail: PropTypes.bool,
  isVisible: PropTypes.bool,
  onAttachmentClick: PropTypes.func,
  onCancel: PropTypes.func,
  onDownloadAllClick: PropTypes.func,
  pendingPDFDownloadList: PropTypes.arrayOf(PropTypes.object),
  recipientsComponent: PropTypes.element,
};

const EmailModal = ({
  error,
  isVisible,
  onCancel,
  attachmentList,
  onDownloadAllClick,
  onAttachmentClick,
  pendingPDFDownloadList,
  isLoadingAttachments,
  isDownloadingAllAttachments,
  isSendingEmail,
  children,
  className,
  title,
  text,
  // Below values provided by Formik:
  setFieldValue,
  handleSubmit,
  values,
  recipientsComponent,
}) => {
  return (
    <Modal isOpen={isVisible} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent minWidth="5xl" maxW="80%">
        <ModalHeader borderBottomWidth={1} fontSize="16px" fontWeight="normal">
          <Text as="p" textAlign="center">
            {title || 'Email Preview'}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={10}>
          <form onSubmit={handleSubmit}>
            {error && (
              <ChakraAlert status="error">
                <AlertIcon />
                <AlertDescription>{error}</AlertDescription>
              </ChakraAlert>
            )}
            {text && (
              <Text as="p" textAlign="center" color="gray">
                {text}
              </Text>
            )}
            <Grid templateColumns="repeat(12, 1fr)" gap={6}>
              <GridItem colSpan={9}>
                <VStack spacing={6} align="stretch">
                  {/* <!-- CHILDREN --> */}
                  {children && children}
                  {/* <!-- SUBHEADER --> */}
                  <Text as="h6">Email Preview</Text>
                  <Box>
                    <Text as="label" fontSize="13px" fontWeight="bold">
                      Subject
                    </Text>
                    <ChakraInput
                      name="subject"
                      placeholder="Subject"
                      value={values.subject}
                      onChange={e => {
                        const value = get(e, 'target.value', '');
                        setFieldValue('subject', value);
                      }}
                    />
                  </Box>
                  {/* <!-- BODY --> */}
                  <Box>
                    <Text as="label" fontSize="13px" fontWeight="bold">
                      Message
                    </Text>
                    <Textarea
                      name="body"
                      value={values.body}
                      placeholder="Body"
                      rows={8}
                      onChange={e => {
                        const value = get(e, 'target.value', '');
                        setFieldValue('body', value);
                      }}
                    />
                  </Box>

                  {/* <!-- RECIPIENTS --> */}
                  <Box>{recipientsComponent}</Box>
                </VStack>
              </GridItem>
              <GridItem colSpan={3}>
                <Box>
                  {/* <!-- SUBHEADER --> */}
                  <Text as="h6">Attachments</Text>
                  {/* <!-- ATTACHMENTS --> */}
                  <VStack spacing={10} align="stretch">
                    {isLoadingAttachments ? (
                      <Loading />
                    ) : (
                      attachmentList.map((attachment, idx) => {
                        const isLoading = pendingPDFDownloadList
                          .map(attachment => attachment.id)
                          .includes(attachment.id);
                        return (
                          <Flex
                            justifyContent="center"
                            alignItems="center"
                            wrap="wrap"
                            minW="100px"
                            height="35px"
                            color="white"
                            key={`attachment-${idx}`}
                            cursor={!isDownloadingAllAttachments && isLoading ? 'not-allowed' : 'pointer'}
                            bgColor={!isDownloadingAllAttachments && isLoading ? '#d2d0d0' : 'gray'}
                            onClick={() => onAttachmentClick(attachment)}
                          >
                            <Text as="p">{attachment.docType}</Text>
                            {!isDownloadingAllAttachments && isLoading && <Icon type="loading" />}
                          </Flex>
                        );
                      })
                    )}
                    {/* <!-- DOWNLOAD LINK --> */}
                    {isDownloadingAllAttachments ? (
                      <Loading />
                    ) : (
                      !isEmpty(attachmentList) && (
                        <ChakraButton
                          variant="link"
                          colorScheme="blue"
                          htmlType="button"
                          onClick={() => onDownloadAllClick(attachmentList)}
                        >
                          <Text as="p" fontSize="12px">
                            Download All
                          </Text>
                        </ChakraButton>
                      )
                    )}
                  </VStack>
                </Box>
              </GridItem>
            </Grid>
          </form>
        </ModalBody>
        <ModalFooter padding={4}>
          <ButtonGroup>
            <ChakraButton
              type="submit"
              borderRadius={2}
              width={40}
              colorScheme="blue"
              variant="solid"
              mr={2}
              onClick={handleSubmit}
            >
              Email
            </ChakraButton>
            <ChakraButton
              variant="outline"
              _hover={{
                bg: 'white',
                color: 'blue.600',
                borderColor: 'blue.600',
              }}
              borderRadius={2}
              width={40}
              onClick={onCancel}
            >
              Cancel
            </ChakraButton>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

EmailModal.propTypes = propTypes;

export default EmailModal;
