import moment from 'moment';
import orderBy from 'lodash/orderBy';

export function formatAge(dateString, showAbbreviated) {
  const now = moment.utc();
  const created = moment.utc(dateString);
  const formatted = now.diff(created, 'days');

  if (showAbbreviated) {
    return formatted < 1 ? `${now.diff(created, 'hours')}h` : `${formatted}d`;
  }

  return formatted < 1 ? `${now.diff(created, 'hours')} hours` : `${formatted} days`;
}

export function convertToQueryParams(key, valuesList) {
  if (!valuesList || !key || !Array.isArray(valuesList)) return '';
  return valuesList.map(item => `${key}=${item.value ? item.value : item}`).join('&');
}

export function orderOptions(options, order = 'asc') {
  return orderBy(options, [option => option.label.toLowerCase()], [order]);
}

export function getSelectedOptions(appliedValues = [], options) {
  const values = Array.isArray(appliedValues) ? appliedValues : [appliedValues];
  const stringValues = values.map(val => val.toString());
  const selectedOptions = options.filter(option => stringValues.includes(option.value.toString()));
  return selectedOptions;
}
