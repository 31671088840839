import React from 'react';
import PackerOfferFormLine from './PackerOfferFormLine';

const PackerOfferFormLines = props => {
  return (
    <div style={{ paddingTop: '20px' }}>
      <div>
        <h3>Offer Lines</h3>
      </div>
      {props.lines.map((line, i) => (
        <PackerOfferFormLine
          key={i}
          removeLine={props.removeFromList}
          onLineValueChange={props.onLineValueChange}
          removeWarning={props.removeWarning}
          onFabricationDateChange={props.onFabricationDateChange}
          dateWarnings={props.dateWarnings}
          line={line}
          index={i}
          input_products={props.input_products}
          price_types={props.price_types}
        />
      ))}

      {props.lines.length < 8 ? (
        <div className="row pull-right" style={{ paddingTop: '20px' }}>
          <button className="btn btn-success" onClick={props.addLine}>
            Add Packer Offer line
          </button>
        </div>
      ) : (
        false
      )}
    </div>
  );
};

export default PackerOfferFormLines;
