import { HStack, IconButton, Text } from '@chakra-ui/react';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { downloadFromS3Link, getDocVersion } from '../../../helpers/downloads';
import { ReactComponent as DownloadIcon } from '../../../assets/download-icon.svg';
import { ReactComponent as MailIcon } from '../../../assets/mail-icon.svg';

// --------------------------
// Formatters
// --------------------------
function formatActions({ row }) {
  const { url, onEmail, isEditable } = row;
  return (
    <HStack alignItems="center" height="100%">
      <IconButton variant="link" icon={<DownloadIcon />} onClick={() => downloadFromS3Link(url)} />
      {isEditable && (
        <IconButton
          variant="link"
          icon={<MailIcon />}
          onClick={() => {
            onEmail(document);
          }}
        />
      )}
    </HStack>
  );
}

const GridHeaderText = ({ name }) => {
  return (
    <Text as="p" fontSize="12px" fontWeight="bold" textTransform="uppercase">
      {name}
    </Text>
  );
};

GridHeaderText.propTypes = {
  name: PropTypes.string,
};

const GridDataText = ({ value }) => {
  return (
    <Text as="p" fontSize="14px">
      {value}
    </Text>
  );
};

GridDataText.propTypes = {
  value: PropTypes.string,
};
// --------------------------
// Columns
// --------------------------

// Ignoring propType declarations for formatters for clarity
/* eslint-disable react/prop-types */
const initializeColumnConfig = () => ({
  documentType: {
    key: 'docLabel',
    name: 'Document Type',
    width: 225,
    headerRenderer: () => <GridHeaderText name="Document Type" />,
    formatter: ({ row }) => {
      const documentType = get(row, 'docLabel');
      return <GridDataText value={startCase(documentType)} />;
    },
  },
  generationDate: {
    key: 'created',
    name: 'Generation Date',
    headerRenderer: () => <GridHeaderText name="Generation Date" />,
    formatter: ({ row }) => {
      const generationDate = get(row, 'created');
      if (!generationDate) return <GridDataText value=" " />;
      const formattedDate = moment(generationDate).format('YYYY-MM-DD');
      return <GridDataText value={formattedDate} />;
    },
  },
  version: {
    key: 'version',
    name: 'Version',
    headerRenderer: () => <GridHeaderText name="Version" />,
    formatter: props => {
      const { row } = props;
      const version = getDocVersion(row.documents, row.id);
      return <GridDataText value={version} />;
    },
  },
  generatedBy: {
    key: 'generatedBy',
    name: 'Generated By',
    headerRenderer: () => <GridHeaderText name="Generated By" />,
    formatter: ({ row }) => {
      const user = get(row, 'user', {});
      return <GridDataText value={`${user.firstName} ${user.lastName}`} />;
    },
  },
  actions: {
    key: 'actions',
    name: 'Actions',
    width: 150,
    headerRenderer: () => <GridHeaderText name="Actions" />,
    formatter: formatActions,
  },
});

export const configureColumns = columnKeyList => {
  const config = initializeColumnConfig();
  const columns = columnKeyList.map(key => config[key]).filter(Boolean);
  return columns;
};
