const schema = [
  {
    name: 'strategy_price',
    displayName: 'Strategy Price',
    nested: true,
    systemIdField: null,
    displayIdField: null,
    path: '/strategy-prices',
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'hidden',
        required: false,
        systemIdentifier: true,
      },
      {
        name: 'strategy_number',
        displayName: 'Strategy Number',
        dataType: 'hidden',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'input_product_uid',
        displayName: 'Product',
        tooltip: 'Select the products included in the contract from the list',
        dataType: 'reference',
        referenceType: 'select',
        required: true,
        referenceEntityType: 'input_product',
      },
      {
        name: 'fob_price',
        displayName: 'FOB Price',
        tooltip:
          "Specify the Free On Board (FOB) terms, indicating the point at which the seller's responsibility ends and the buyer's begins",
        dataType: 'number',
        required: true,
      },
    ],
  },
];

export default schema;
