import { connect } from 'react-redux';

import UserManagementComponent from '../../components/pages/UserManagement';

const mapStateToProps = state => {
  // Any props of items can be accessed
  return {
    user: state.user,
  };
};

const UserManagement = connect(mapStateToProps)(UserManagementComponent);

export default UserManagement;
