import { createSelector } from '@reduxjs/toolkit';
import { group } from 'd3';
import { selectQualityClaimsData } from './selectBasicDetailsClaimsData';

const selectAvailableStatuses = createSelector(selectQualityClaimsData, data => {
  if (!data) return [];

  const allStatusData = [];

  data.forEach(x => {
    allStatusData.push(...x.byStatus);
  });

  const tmp = Array.from(group(allStatusData, x => x.status));

  return tmp.map(statusEntries => statusEntries[0]);
});

export default selectAvailableStatuses;
