import { createSelector } from '@reduxjs/toolkit';
import { selectQualityNCRsActivePackerPlants, selectQualityNCRsMarket } from './selectBasicDetailsNcrsData';
import { selectQualityNCRsData } from './selectQualityNCRsData';
import { VOLUME_OPTIONS } from '../qualityDetailsNcrsConfig';

const selectQualityNCRsDataFiltered = createSelector(
  selectQualityNCRsData,
  selectQualityNCRsActivePackerPlants,
  selectQualityNCRsMarket,
  (data, activePackerPlants, market) => {
    if (!data) return null;
    const multiplier = VOLUME_OPTIONS[market.key].MULTIPLIER;
    const activePackerPlantsIds = activePackerPlants.filter(x => !!x).map(x => x.id);

    let dataFiltered = [];
    if (activePackerPlantsIds.length > 0) {
      dataFiltered = data.map(d => {
        let byPackerPlant = [...d.byPackerPlant]
          .filter(p => activePackerPlantsIds.includes(p.packerPlantId))
          .sort(
            (a, b) => activePackerPlantsIds.indexOf(a.packerPlantId) - activePackerPlantsIds.indexOf(b.packerPlantId)
          );
        // TODO check if bottom10PackerPlants used on UI
        if (activePackerPlants[0] && activePackerPlants[0].bottomTenPackerPlants) {
          const bottomTenPackerPlants = d.byPackerPlant.filter(x =>
            activePackerPlants[0].bottomTenPackerPlants.map(b => b.id).includes(x.packerPlantId)
          );
          const incidentsCountBottomTen = bottomTenPackerPlants.reduce((a, b) => a + b.incidentsCount, 0);
          const receivedWeightBottomTen = bottomTenPackerPlants.reduce((a, b) => a + b.receivedWeight, 0);
          const amountAffectedBottomTen = bottomTenPackerPlants.reduce((a, b) => a + b.weight, 0);
          const bottomTen = {
            incidentsCountBottomTen,
            receivedWeightBottomTen,
            incidentRateBottomTen: receivedWeightBottomTen
              ? (incidentsCountBottomTen * multiplier) / receivedWeightBottomTen
              : 0,
            percentOfTotalVolumeBottomTen: receivedWeightBottomTen
              ? amountAffectedBottomTen / receivedWeightBottomTen
              : 0,
            weightBottomTen: amountAffectedBottomTen,
            claimDollarsBottomTen: bottomTenPackerPlants.reduce((a, b) => a + b.claimDollars, 0),
            incidentsPerUnitBottomTen: bottomTenPackerPlants.reduce(
              (a, b) => a + b.incidentsCount / b.receivedWeight,
              0
            ),
          };
          byPackerPlant = [{ ...byPackerPlant[0], ...bottomTen }];
        }
        const receivedWeightTotalVolumeBottomTen = byPackerPlant.reduce((a, b) => a + b.receivedWeightBottomTen, 0);
        const incidentCountTotalVolumeBottomTen = byPackerPlant.reduce((a, b) => a + b.incidentsCountBottomTen, 0);
        const weightTotalVolumeBottomTen = byPackerPlant.reduce((a, b) => a + b.weightBottomTen, 0);

        byPackerPlant = byPackerPlant.map(p => ({
          ...p,
          incidentRate: p.receivedWeight ? (p.incidentsCount * multiplier) / p.receivedWeight : 0,
          percentOfTotalVolume: p.receivedWeight ? p.weight / p.receivedWeight : 0,
          incidentsPerUnit: p.receivedWeight ? p.incidentsCount / p.receivedWeight : 0,
          // TODO check and delete if Bottom10 code isn't rendered on UI
          incidentsCountBottomTen: byPackerPlant.reduce((a, b) => a + b.incidentsCountBottomTen, 0),
          incidentRateBottomTen: receivedWeightTotalVolumeBottomTen
            ? (incidentCountTotalVolumeBottomTen * multiplier) / receivedWeightTotalVolumeBottomTen
            : 0,
          percentOfTotalVolumeBottomTen: receivedWeightTotalVolumeBottomTen
            ? weightTotalVolumeBottomTen / receivedWeightTotalVolumeBottomTen
            : 0,
          weightBottomTen: weightTotalVolumeBottomTen,
          claimDollarsBottomTen: byPackerPlant.reduce((a, b) => a + b.claimDollarsBottomTen, 0),
          incidentsPerUnitBottomTen: byPackerPlant.reduce(
            (a, b) => a + b.incidentRateBottomTen / b.receivedWeightBottomTen,
            0
          ),
        }));
        return {
          ...d,
          byPackerPlant,
        };
      });
    } else {
      // FIXME, code duplicated above
      dataFiltered = data.map(d => {
        let byPackerPlant = [...d.byPackerPlant];
        byPackerPlant = byPackerPlant.map(p => ({
          ...p,
          incidentRate: p.receivedWeight ? (p.incidentsCount * multiplier) / p.receivedWeight : 0,
          percentOfTotalVolume: p.receivedWeight ? p.weight / p.receivedWeight : 0,
          incidentsPerUnit: p.receivedWeight ? p.incidentsCount / p.receivedWeight : 0,
        }));

        return {
          ...d,
          byPackerPlant,
          incidentRate: d.receivedWeight ? (d.incidentsCount * multiplier) / d.receivedWeight : 0,
          percentOfTotalVolume: d.receivedWeight ? d.weight / d.receivedWeight : 0,
          incidentsPerUnit: d.receivedWeight ? d.incidentsCount / d.receivedWeight : 0,
        };
      });
    }
    return dataFiltered;
  }
);

// TODO not seemed to be rendered on UI, check and delete
export const selectQualityNCRsPackerDataFiltered = createSelector(
  selectQualityNCRsData,
  selectQualityNCRsActivePackerPlants,
  selectQualityNCRsMarket,
  (data, activePackerPlants, market) => {
    if (!data) return null;
    const multiplier = VOLUME_OPTIONS[market.key].MULTIPLIER;

    let packerPackerPlantsIds = [];
    if (activePackerPlants[0] && activePackerPlants[0].includesPackerPlants) {
      packerPackerPlantsIds = activePackerPlants[0].includesPackerPlants.map(x => x.id);
    }

    let dataFiltered = [];
    if (packerPackerPlantsIds.length) {
      dataFiltered = data.map(d => {
        const byPackerPlant = d.byPackerPlant.filter(p => packerPackerPlantsIds.includes(p.packerPlantId));
        const incidentsCount = byPackerPlant.reduce((a, b) => a + b.incidentsCount, 0);
        const totalReceivedWeight = byPackerPlant.reduce((a, b) => a + b.receivedWeight, 0);
        const amountAffected = byPackerPlant.reduce((a, b) => a + b.weight, 0);
        return {
          ...d,
          incidentsCount,
          incidentRate: totalReceivedWeight ? (incidentsCount * multiplier) / totalReceivedWeight : 0,
          percentOfTotalVolume: totalReceivedWeight ? amountAffected / totalReceivedWeight : 0,
          weight: amountAffected,
          claimDollars: byPackerPlant.reduce((a, b) => a + b.claimDollars, 0),
          incidentsPerUnit: byPackerPlant.reduce((a, b) => a + b.incidentsCount / b.receivedWeight, 0),
          byPackerPlant,
        };
      });
    } else {
      dataFiltered = data.map(d => {
        return {
          ...d,
          percentOfTotalVolume: d.receivedWeight ? d.weight / d.receivedWeight : 0,
          incidentRate: d.receivedWeight ? (d.incidentCount * multiplier) / d.receivedWeight : 0,
        };
      });
    }

    return dataFiltered;
  }
);

export default selectQualityNCRsDataFiltered;
