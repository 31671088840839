import { merge } from 'lodash';
import { createOrUpdateTheme, fetchTheme, fetchThemeById, updateTheme } from '../services/theme-api';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export const CREATE_THEME_SUCCESS = 'CREATE_THEME_SUCCESS';
export const CREATE_THEME_ERROR = 'CREATE_THEME_ERROR';
export const FETCH_THEME_SUCCESS = 'FETCH_THEME_SUCCESS';
export const FETCH_THEME_ERROR = 'FETCH_THEME_ERROR';
export const RESET_THEME_LOADED_FLAG = 'RESET_THEME_LOADED_FLAG';
export const RESET_THEME_EDITED_FLAG = 'RESET_THEME_EDITED_FLAG';
export const UPDATE_EDITED_THEME_SUCCESS = 'UPDATE_EDITED_THEME_SUCCESS';
export const UPDATE_EDITED_THEME_ERROR = 'UPDATE_EDITED_THEME_ERROR';
export const SAVE_THEME_SUCCESS = 'SAVE_THEME_SUCCESS';
export const SAVE_THEME_ERROR = 'SAVE_THEME_ERROR';
export const SET_LOCAL_THEME_ENABLED_FLAG = 'SET_LOCAL_THEME_ENABLED_FLAG';

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function retrieveTheme() {
  return async dispatch => {
    try {
      const theme = await fetchTheme();
      if (theme) {
        dispatch({
          type: FETCH_THEME_SUCCESS,
          payload: theme,
        });
      } else {
        dispatch({
          type: FETCH_THEME_ERROR,
          payload: {},
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_THEME_ERROR,
        payload: {},
      });
    }
  };
}

export function retrieveThemeInEditorMode() {
  return async dispatch => {
    try {
      const localStoreTheme = localStorage.getItem('theme');
      const theme = localStoreTheme ? JSON.parse(localStoreTheme) : await fetchTheme();
      if (theme) {
        dispatch({
          type: FETCH_THEME_SUCCESS,
          payload: theme,
        });
      } else {
        dispatch({
          type: FETCH_THEME_ERROR,
          payload: {},
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_THEME_ERROR,
        payload: {},
      });
    }
  };
}

export function createTheme(themeData) {
  return async dispatch => {
    try {
      const newTheme = await createOrUpdateTheme(themeData);
      if (newTheme) {
        dispatch({
          type: CREATE_THEME_SUCCESS,
          payload: newTheme,
        });
      } else {
        dispatch({
          type: CREATE_THEME_ERROR,
          payload: {},
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_THEME_ERROR,
        payload: {},
      });
    }
  };
}

export function resetThemeEditedFlag() {
  return async dispatch => {
    dispatch({
      type: RESET_THEME_EDITED_FLAG,
    });
  };
}
export function resetThemeLoadedFlag() {
  return async dispatch => {
    dispatch({
      type: RESET_THEME_LOADED_FLAG,
    });
  };
}

export function setLocalThemeEnabledFlag(enableLocalTheme) {
  return async dispatch => {
    dispatch({
      type: SET_LOCAL_THEME_ENABLED_FLAG,
      payload: enableLocalTheme,
    });
  };
}

export function updateEditedTheme(themeData, isPartialEdit) {
  return async dispatch => {
    try {
      if (themeData) {
        const localStorageTheme = JSON.parse(localStorage.getItem('theme'));
        const existingTheme = localStorageTheme || (await fetchTheme());
        const theme = isPartialEdit ? merge(existingTheme, themeData) : themeData;
        localStorage.setItem('theme', JSON.stringify(theme));
        dispatch({
          type: UPDATE_EDITED_THEME_SUCCESS,
          payload: theme,
        });
      } else {
        dispatch({
          type: UPDATE_EDITED_THEME_ERROR,
          payload: {},
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_EDITED_THEME_ERROR,
        payload: {},
      });
    }
  };
}

export function revertEditedChanges() {
  return async dispatch => {
    localStorage.removeItem('theme');
    localStorage.removeItem('theme_id');
    dispatch({
      type: RESET_THEME_LOADED_FLAG,
    });
  };
}

export function saveTheme(themeData) {
  return async dispatch => {
    try {
      await updateTheme(themeData);
      dispatch({
        type: SAVE_THEME_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: SAVE_THEME_ERROR,
        payload: {},
      });
    }
  };
}

export function previewTheme(themeId) {
  return async dispatch => {
    try {
      const theme = await fetchThemeById(themeId);

      if (theme) {
        dispatch({
          type: FETCH_THEME_SUCCESS,
          payload: theme,
        });
      } else {
        dispatch({
          type: FETCH_THEME_ERROR,
          payload: {},
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_THEME_ERROR,
        payload: {},
      });
    }
  };
}
