import React from 'react';

const POTableLine = ({ fieldName, lines }) => {
  const lineStyle = { padding: '5 0 5 5' };
  const items = lines.map(item => {
    const isObject = typeof item[fieldName] === 'object' && item[fieldName] !== null;
    return isObject ? item[fieldName].name : item[fieldName];
  });

  return (
    <td style={{ paddingRight: 0 }}>
      {items.length
        ? items.map((line, index) => {
            return (
              <div key={index} style={lineStyle}>
                {line}
                {index === lines.length - 1 ? false : <hr />}
              </div>
            );
          })
        : false}
    </td>
  );
};

export default POTableLine;
