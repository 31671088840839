import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
} from '@chakra-ui/react';
import { Alert } from 'antd';
import { DeleteIcon, EditIcon, AddIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import Constants from '../../../Constants';
import ConditionBuilder from '../../../components/ConditionBuilder/ConditionBuilder';
import Select from '../../../components/basic/SelectField';
import { selectCurrentUser } from '../../../reducers/reducer_user';
import RightAngleIcon from '../../../components/core/RightAngleIcon';
import { Modal as AntdModal } from 'antd';
const { confirm } = AntdModal;
import './document-types.scss';
import Tooltip from '../../../components/quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const stringOps = [
  { label: 'Equal', value: "%var == '%val'", inputRequired: true },
  { label: 'Not equal', value: "%var != '%val'", inputRequired: true },
  { label: 'Equal (case insensitive)', value: "%var.lower() == '%val'.lower()", inputRequired: true },
  { label: 'Not equal (case insensitive)', value: "%var.lower() != '%val'.lower()", inputRequired: true },
  { label: 'Contain', value: "'%val' in %var", inputRequired: true },
  { label: 'Not contain', value: "not '%val' in %var", inputRequired: true },
  { label: 'Contain (case insensitive)', value: "bool(re.search('%val', %var, re.IGNORECASE))", inputRequired: true },
  {
    label: 'Not contain (case insensitive)',
    value: "not bool(re.search('%val', %var, re.IGNORECASE))",
    inputRequired: true,
  },
  { label: 'Match', value: "re.match('%val', %var)", inputRequired: true },
  { label: 'Not Match', value: "not re.match('%val', %var)", inputRequired: true },
];
const arrayOfstringsOps = [
  { label: 'Contain', value: "'%val' in %var", inputRequired: true },
  { label: 'Not contain', value: "not '%val' in %var", inputRequired: true },
];
const emailDocMatchConditions = [
  {
    name: 'email_attachment.email.subject',
    label: 'Email Subject',
    ops: [...stringOps],
  },
  {
    name: 'email_attachment.email.text',
    label: 'Email Body',
    ops: [...stringOps],
  },
  {
    name: '[i["address"] for i in email_attachment.email.from_email["value"]]',
    label: 'Email Address From',
    ops: [...arrayOfstringsOps],
  },
  {
    name: '[i["address"] for i in email_attachment.email.to["value"]]',
    label: 'Email Address To',
    ops: [...arrayOfstringsOps],
  },
  {
    name: 'email_attachment.filename',
    label: 'Email Attachment File Name',
    ops: [...stringOps],
  },
  {
    name: 'email_attachment.file_text',
    label: 'Email Attachment File Text',
    ops: [...stringOps],
  },
];

const extractor_fields = ['po_lookup_field', 'po_number'];

const formatOptions = v => ({ label: v.replaceAll('_', ' '), value: v });

export default () => {
  const [preventClosingModalOnSubmit, setPreventClosingModalOnSubmit] = useState(false);
  const { token } = useSelector(selectCurrentUser);
  const [axiosConfig, setAxiosConfig] = useState();
  const [documentTypes, setDocumentTypes] = useState([]);
  const [isDevMode] = useState(new URLSearchParams(window.location.search).get('mode') === 'dev');

  useEffect(() => {
    if (!token) {
      return;
    }
    setAxiosConfig({
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      data: '{}',
    });
    loadDocumentTypes();
  }, [token]);

  const loadDocumentTypes = async () =>
    axios.get(`${Constants.URL}document-types`, axiosConfig).then(response => setDocumentTypes(response.data));

  const saveDocumentType = async data => {
    (!data.created
      ? axios.post(`${Constants.URL}document-types`, data, axiosConfig)
      : axios.patch(`${Constants.URL}document-types/${data.id}`, data, axiosConfig)
    ).then(loadDocumentTypes);
  };

  const removeDocumentType = async documentTypeId => {
    if (window.confirm('Are you sure you want to delete this item?') == true) {
      axios
        .delete(`${Constants.URL}document-types/${documentTypeId || values.id}`, axiosConfig)
        .then(loadDocumentTypes);
      if (!documentTypeId) {
        closeFormModal();
      }
    }
  };

  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues,
    resetForm,
    values,
    touched,
    isValid,
  } = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      name: Yup.string().nullable().required('Required'),
      email_doc_match_rule: Yup.object().nullable().required('Required'),
      email_doc_fields_extractors_rule: Yup.object().nullable(),
      email_doc_auto_upload: Yup.bool().nullable(),
    }),
    onSubmit: data => {
      saveDocumentType(data);
      if (!preventClosingModalOnSubmit) {
        closeFormModal();
      }
    },
  });
  const [showFormModal, setShowFormModal] = useState(false);

  const openFormModal = data => {
    resetForm();
    setValues(data);
    setShowFormModal(true);
  };

  const closeFormModal = () => setShowFormModal(false);

  return (
    <Box margin="2em" className="document-types">
      <HStack spacing="10px" justifyContent="space-between" mb={6}>
        <HStack>
          <RightAngleIcon />
          <Heading className="dashboard-section__heading" fontSize="15px">
            Document Types
          </Heading>
        </HStack>
        <VStack alignItems="end">
          <Button colorScheme="actionPrimary" mb={'1em'} onClick={() => openFormModal({})}>
            Add New
          </Button>
        </VStack>
      </HStack>
      <TableContainer className="table-container">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Auto upload</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {documentTypes.map(item => (
              <Tr key={item.id}>
                <Td width="100%">{item.name}</Td>
                <Td>{item.email_doc_auto_upload ? 'Yes' : 'No'}</Td>
                <Td className="actions">
                  <Button leftIcon={<EditIcon />} onClick={() => openFormModal(item)} variant="outline"></Button>
                  <Button
                    leftIcon={<DeleteIcon />}
                    onClick={() => removeDocumentType(item.id)}
                    variant="outline"
                  ></Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Modal size="6xl" isOpen={showFormModal} onClose={closeFormModal}>
        <ModalOverlay />
        <ModalContent minHeight="200px" className="document-types-modal-content">
          <form onSubmit={handleSubmit}>
            <ModalHeader>Document Type</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing="10">
                <FormControl id="name" isInvalid={errors.name && touched.name}>
                  <FormLabel>
                    Name&nbsp;
                    <Tooltip content="Enter the rules or guidelines for different document types" placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Input name="name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} />
                  {errors.name && touched.name && (
                    <Alert style={{ marginTop: '5px' }} message={errors.name} type="error" />
                  )}
                </FormControl>
                <FormControl
                  id="email_doc_match_rule"
                  isInvalid={errors.email_doc_match_rule && touched.email_doc_match_rule}
                >
                  <FormLabel>
                    Email document match rule&nbsp;
                    <Tooltip
                      content="Define multiple rules to categorize and automatically upload documents into the corresponding PO."
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <ConditionBuilder
                    condition={values.email_doc_match_rule}
                    variables={emailDocMatchConditions}
                    onUpdateCondition={con => {
                      setFieldValue('email_doc_match_rule', con);
                    }}
                  />
                  {errors.email_doc_match_rule && touched.email_doc_match_rule && (
                    <Alert style={{ marginTop: '5px' }} message={errors.email_doc_match_rule} type="error" />
                  )}
                </FormControl>
                <FormControl
                  id="email_doc_auto_upload"
                  isInvalid={errors.email_doc_auto_upload && touched.email_doc_auto_upload}
                >
                  <FormLabel>
                    Auto upload&nbsp;
                    <Tooltip
                      content="Specify whether to automatically upload documents from emails into the PO."
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                  <Checkbox
                    name="email_doc_auto_upload"
                    marginRight="auto"
                    isChecked={values.email_doc_auto_upload}
                    onChange={() => setFieldValue('email_doc_auto_upload', !values.email_doc_auto_upload)}
                    onBlur={handleBlur}
                  >
                    Enable auto upload
                  </Checkbox>
                  {errors.email_doc_auto_upload && touched.email_doc_auto_upload && (
                    <Alert style={{ marginTop: '5px' }} message={errors.email_doc_auto_upload} type="error" />
                  )}
                </FormControl>
                {isDevMode && (
                  <Box borderWidth="1px" borderRadius="lg" overflow="hidden" padding={'1em'} minHeight="400px">
                    <FormControl
                      id="email_doc_fields_extractors_rule"
                      isInvalid={errors.email_doc_fields_extractors_rule && touched.email_doc_fields_extractors_rule}
                    >
                      <FormLabel className="email-document-fields">
                        Email document fields extractors rule
                        <Menu closeOnSelect={false}>
                          <MenuButton as={Button} variant="link" color="primary">
                            Guide
                          </MenuButton>
                          <MenuList className="guide-box">
                            <p>
                              <b>Match</b>
                            </p>
                            <ul>
                              <li>Get patterns from the subject, body and document text.</li>
                              <li>
                                parentheses "()" are required to indicate the part of the pattern that needs to be
                                extracted
                                <br />
                                <p>Examples:</p>
                                <p>
                                  ".*(F\\d{5}).*": extract the pattern that is wrapped by parentheses (A number of 5
                                  digits that has the prefix 'F'), no matter the text that is before or after the
                                  parentheses pattern.
                                </p>
                              </li>
                            </ul>
                            <p>
                              <b>Eval</b>
                            </p>
                            <ul>
                              <li>Get a value from a python expression, allowing access to all email content.</li>
                              <li>
                                <p>Examples:</p>
                                <p>""internal_po_number"": return a constant</p>
                              </li>
                            </ul>
                          </MenuList>
                        </Menu>
                      </FormLabel>
                      <Select
                        {...{
                          isClearable: true,
                          isSearchable: true,
                          closeMenuOnSelect: false,
                          removeSelected: true,
                          isMulti: true,
                          name: 'email_doc_fields_extractors_rule',
                          value: Object.keys(values.email_doc_fields_extractors_rule || []).map(formatOptions),
                          onChange: events => {
                            const val = events
                              .map(e => e.value)
                              .reduce((o, c) => {
                                o[c] = values.email_doc_fields_extractors_rule?.[c] || [];
                                return o;
                              }, {});
                            setFieldValue('email_doc_fields_extractors_rule', Object.keys(val).length ? val : null);
                          },
                          options: (extractor_fields || []).map(formatOptions),
                        }}
                      />
                      {errors.email_doc_fields_extractors_rule && touched.email_doc_fields_extractors_rule && (
                        <Alert
                          style={{ marginTop: '5px' }}
                          message={errors.email_doc_fields_extractors_rule}
                          type="error"
                        />
                      )}
                    </FormControl>
                    {Object.keys(values.email_doc_fields_extractors_rule || []).map(fe => (
                      <FormControl id={fe} key={fe} my="1em">
                        <FormLabel>
                          {fe}{' '}
                          <Button
                            leftIcon={<AddIcon />}
                            className="add-expression"
                            size="sm"
                            colorScheme="actionPrimary"
                            onClick={() => {
                              const fieldsCopy = { ...values.email_doc_fields_extractors_rule };

                              confirm({
                                title: 'Select an expression type',
                                content:
                                  'match: Extract patterns from the email content. eval: Evaluate an expression.',
                                zIndex: 9000000,
                                okText: 'eval',
                                okType: 'default',
                                cancelText: 'match',
                                onOk() {
                                  fieldsCopy[fe].push({ type: 'eval', value: '', placeholder: '"literal"' });
                                  setFieldValue('email_doc_fields_extractors_rule', fieldsCopy);
                                },
                                onCancel() {
                                  fieldsCopy[fe].push({ type: 'match', value: '', placeholder: '.*(Fd{5}).*' });
                                  setFieldValue('email_doc_fields_extractors_rule', fieldsCopy);
                                },
                              });
                            }}
                          >
                            Add New Expression
                          </Button>
                        </FormLabel>
                        <HStack flexWrap="wrap">
                          {(values.email_doc_fields_extractors_rule[fe] || []).map((expression, i) => (
                            <VStack key={i} alignItems="flex-start" marginInlineStart="0 !important">
                              <HStack>
                                <Input
                                  margin="4px"
                                  width="150px"
                                  name={`fe-${i}`}
                                  type="text"
                                  placeholder={expression.placeholder}
                                  onChange={e => {
                                    const fieldsCopy = { ...values.email_doc_fields_extractors_rule };
                                    fieldsCopy[fe][i].value = e.target.value;
                                    setFieldValue('email_doc_fields_extractors_rule', fieldsCopy);
                                  }}
                                  value={expression.value}
                                />
                                <Badge
                                  colorScheme="red"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    const fieldsCopy = { ...values.email_doc_fields_extractors_rule };
                                    fieldsCopy[fe] = fieldsCopy[fe].filter((_, index) => index !== i);
                                    setFieldValue('email_doc_fields_extractors_rule', fieldsCopy);
                                  }}
                                >
                                  &times;
                                </Badge>
                              </HStack>
                              <Text pl="8px" fontSize="xs" as="sup">
                                {expression.type}
                              </Text>
                            </VStack>
                          ))}
                        </HStack>
                      </FormControl>
                    ))}
                  </Box>
                )}
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Checkbox
                marginRight="auto"
                isChecked={preventClosingModalOnSubmit}
                onChange={() => setPreventClosingModalOnSubmit(!preventClosingModalOnSubmit)}
              >
                Prevent closing modal on submit
              </Checkbox>
              {values.created && (
                <Button type="button" colorScheme="error" onClick={() => removeDocumentType()} marginRight="16px">
                  Remove
                </Button>
              )}
              <Button type="submit" colorScheme="actionPrimary" isDisabled={!isValid}>
                {!values.created ? 'New' : 'Save'}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};
