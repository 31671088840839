import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
  fetchAnnotationsRequest,
  createAnnotationRequest,
  updateAnnotationRequest,
  deleteAnnotationRequest,
} from '../../data-fixtures/annotations-all';

export const TIME_SCALE = {
  YEARLY: 'yearly',
  QUARTERLY: 'quarterly',
  MONTHLY: 'monthly',
};

const annotationsSlice = createSlice({
  name: 'annotations',
  initialState: {
    data: [],
  },
  reducers: {
    setAnnotations(state, { payload }) {
      state.data = payload;
    },
    appendAnnotations(state, { payload: newAnnotations }) {
      state.data = [...state.data, ...newAnnotations];
    },
    replaceAnnotation(state, { payload: annotation }) {
      const index = state.data.findIndex(({ id }) => id === annotation.id);
      state.data = [...state.data];
      state.data.splice(index, 1, annotation);
    },
    removeAnnotation(state, { payload: annotation }) {
      const index = state.data.findIndex(({ id }) => id === annotation.id);
      state.data = [...state.data];
      state.data.splice(index, 1);
    },
  },
});

export const { setAnnotations, appendAnnotations, replaceAnnotation, removeAnnotation } = annotationsSlice.actions;

// ------
// Basic selectors
export const selectAnnotations = state => state.annotations.data;

// ------
// Thunks

export const fetchAnnotations = (scope, timeRange) => {
  return async dispatch => {
    try {
      const annotations = await fetchAnnotationsRequest(scope, timeRange);
      dispatch(appendAnnotations(annotations));
      return annotations;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export const createAnnotation = (scope, newAnnotation) => {
  return async dispatch => {
    try {
      const annotation = await createAnnotationRequest(scope, newAnnotation);
      dispatch(appendAnnotations([annotation]));
      return true;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export const updateAnnotation = annotation => {
  return async dispatch => {
    try {
      await updateAnnotationRequest(annotation);
      dispatch(replaceAnnotation(annotation));
      return true;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export const deleteAnnotation = annotation => {
  return async dispatch => {
    try {
      await deleteAnnotationRequest(annotation);
      dispatch(removeAnnotation(annotation));
      return true;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export default annotationsSlice.reducer;

export const filterByScope =
  ({ page, focus, module, timeScale }) =>
  annotation => {
    return (
      annotation.page === page &&
      annotation.focus === focus &&
      annotation.module === module &&
      annotation.timeScale === timeScale
    );
  };

export function createScopedAnnotationSelector(page, focusSelector, module, timeScaleSelector) {
  return createSelector(focusSelector, timeScaleSelector, selectAnnotations, (focus, timeScale, annotations) => {
    const scope = { page, focus, module, timeScale };
    return annotations.filter(filterByScope(scope));
  });
}
