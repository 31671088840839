import { LOAD_DAILY_TASK_DETAILS } from '../actions/actions_daily_task_details';

const dailyTaskDetailsReducer = (
  dailyTaskDetails = { exceptions: [], purchase_order: {}, grinder: {}, packer_plant: {} },
  action
) => {
  switch (action.type) {
    case LOAD_DAILY_TASK_DETAILS: {
      return action.payload;
    }
    default:
      return dailyTaskDetails;
  }
};

export default dailyTaskDetailsReducer;
