import { get } from 'lodash';
import { connect } from 'react-redux';
import { fetchFormFields } from '../../actions/actions_form_fields';
import { fetchOrganisations } from '../../actions/actions_organisations';
import ArticleEdit from '../../components/articles/ArticleEdit';
import { actions, patchArticle, postArticle, deleteArticle } from '../../reducers/article';

const mapStateToProps = state => ({
  token: state.user.token,
  userFullName: `${state.user.first_name} ${state.user.last_name}`,
  article: state.article.article,
  editing: state.article.editing,
  saving: state.article.saving,
  saved: state.article.saved,
  tags: get(state, 'form_fields.tags') || [],
  organisations: get(state, 'organisations') || [],
});

const mapDispatchToProps = dispatch => ({
  getTags: token => dispatch(fetchFormFields(token, '?type=tags')),
  getOrganisations: token => dispatch(fetchOrganisations(token)),
  triggerEditMode: () => dispatch(actions.triggerEditMode()),
  deleteArticle: async (article) => {
    dispatch(deleteArticle(article));},
  saveArticle: async (article) => {
    if (article.id) {
      await dispatch(patchArticle(article));
    } else {
      await dispatch(postArticle(article));
    }
  },
  resetEditStatus: () => dispatch(actions.resetEditStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleEdit);
