import accounting from 'accounting';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Constants from '../../../../Constants';

export const createNewLine = (idx = 1) => ({
  chilled: false,
  frozen: false,
  active: true,
  buyQuantity: '',
  expectedProductionDate: null,
  idx,
  lineNumber: idx,
  inputProductUid: null,
  specifications: [],
  sellQuantity: '',
  sellPricePerUnit: '0.0',
  isPendingSubmission: true,
  productionDetails: [],
  packageCount: 0,
  groupHousedPremiumRate:0,
  packageType: Constants.PACKAGE_TYPE.CARTON,
});

export function initializeShipmentCosts(purchaseOrderId, poFees) {
  const feeKeys = ['totalFreightCharge', 'totalDocumentsCharge', 'marineInsurance'];
  const feesList = poFees || [];

  const fees = feeKeys.map(key => {
    const existingFee = feesList.find(fee => fee.feeName === key);

    if (existingFee) return existingFee;

    const newFee = Constants.createPurchaseOrderFee(key, purchaseOrderId);
    return newFee;
  });

  return fees;
}

export const createNewPurchaseOrder = ({
  arrivalDateEnd = '',
  arrivalDateStart = '',
  dateType = 'shipment',
  deliveryDate = '',
  idx = 1,
  isShipmentDateRequiredPrompt = false,
  lineCounter = 1,
  lineIndices = [1],
  lines = { 1: createNewLine(1) },
  recurringDateEnd = '',
  recurringDateStart = '',
  shipmentDateEnd = '',
  shipmentDateStart = '',
  voyage = {},
} = {}) => ({
  arrivalDateEnd,
  arrivalDateStart,
  dateType,
  deliveryDate,
  idx,
  isShipmentDateRequiredPrompt,
  lineCounter,
  lineIndices,
  lines,
  orderCount: 1,
  recurringDateEnd,
  recurringDateStart,
  shipmentDateEnd,
  shipmentDateStart,
  voyage,
});

export const adaptServerPurchaseOrderToClient = po => {
  const poLines = get(po, 'lines', []);
  const poLinesList = Array.isArray(poLines) ? poLines : Object.values(poLines);

  const lineIndices = poLinesList.map((order, idx) => idx + 1);
  const lineCounter = lineIndices.length;
  const linesObject = poLinesList.reduce((agg, line, idx) => {
    return {
      ...agg,
      [idx + 1]: { ...line, idx: idx + 1 },
    };
  }, {});

  const poFee = po.fee ? [po.fee] : [];
  const poFees = initializeShipmentCosts(po.id, poFee);
  const formattedFees = poFees.map(fee => ({
    ...fee,
    amount: fee.amount ? accounting.toFixed(fee.amount, 2) : '',
    estimate: fee.estimate ? accounting.toFixed(fee.estimate, 2) : '',
  }));

  const isChilled = get(poLinesList, '[0].chilled');
  const arrivalDateText = po.arrival ? 'arrival' : 'delivery_date';

  const adaptedPO = {
    ...po,
    coldstoreDetails: po.coldstoreDetails || { ...Constants.INITIAL_COLDSTORE },
    rejectedCartons: isEmpty(po.rejectedCartons) ? [{ ...Constants.INITIAL_REJECTED_CARTON }] : po.rejectedCartons,
    billOfLading: po.billOfLading || { ...Constants.INITIAL_BOL },
    exportDetails: po.exportDetails || { ...Constants.INITIAL_EXPORT_DETAILS },
    fees: formattedFees,
    dateType: po.shipment ? 'shipment' : arrivalDateText,
    isChilled,
    isPendingSubmission: false,
    linesObject,
    lineIndices,
    lineCounter,
    purchaseOrderIndex: po.id,
    defaultColdstoreId: po.grinder.defaultColdstoreId,
    surcharges: isEmpty(po.surcharges) ? [{ ...Constants.INITIAL_SURCHARGES }] : po.surcharges,
    isShipmentDateRequiredPrompt: !!(po.shipment && !(po.shipmentDateStart || po.shipmentDateEnd)),
  };
  return adaptedPO;
};

export function checkShipmentCostsVisibility(buyIncoterms) {
  return [Constants.INCOTERMS.fob, Constants.INCOTERMS.fas, Constants.INCOTERMS.cif, Constants.INCOTERMS.cfr].includes(
    buyIncoterms
  );
}

export function checkSydneyFormEligibility(formAttributes, isChilled) {
  const { HAS_VOYAGE_DETAILS, IS_AUSTRALIAN_DOMESTIC, IS_EUROPE_DOMESTIC } = formAttributes;
  if (IS_AUSTRALIAN_DOMESTIC) {
    return true;
  }
  if (IS_EUROPE_DOMESTIC) {
    return true;
  }
  if (!HAS_VOYAGE_DETAILS && isChilled) {
    return false;
  }
  return true;
}
