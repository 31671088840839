import accounting from 'accounting';
import { Button, HStack, StackDivider, Text, VStack } from '@chakra-ui/react';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';
import PropTypes from 'prop-types';
import React from 'react';
import { sumOrdersByWeightType } from './helpers';
import './InvoiceTotals.scss';
import { formatMonetaryValue } from '../../functions';
import { ReactComponent as ShareOutlineSVG } from '../../assets/share-outline.svg';

/* --------------------------------------
    INVOICE TOTAL
-----------------------------------------*/
const InvoiceTotal = ({ amount, unit, label }) => (
  <VStack justifyContent="center" spacing="0px" marginY="34px">
    <HStack spacing="5px" alignItems="baseline">
      <Text as="p" fontWeight="bold" fontSize="20px">
        {amount}
      </Text>
      <Text as="p" fontWeight="semibold" fontSize="16px">
        {unit}
      </Text>
    </HStack>
    <Text as="p">{label}</Text>
  </VStack>
);

InvoiceTotal.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unit: PropTypes.string,
  label: PropTypes.string,
};

/* --------------------------------------
    INVOICE TOTALS
-----------------------------------------*/
class InvoiceTotals extends React.Component {
  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    selectedRows: PropTypes.arrayOf(PropTypes.object),
    label: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
        unit: PropTypes.string,
      })
    ),
    isDisabled: PropTypes.bool,
    packageWeights: PropTypes.arrayOf(PropTypes.Object),
    onClickExport: PropTypes.func,
    isExportingExcel: PropTypes.bool,
  };

  render() {
    const { selectedRows, buttonText, onButtonClick, isDisabled, packageWeights, onClickExport, isExportingExcel } =
      this.props;
    const invoiceCount = selectedRows ? selectedRows.length : 0;

    // Weight Total Groups
    const weightRowGroups = groupBy(selectedRows, 'sell_unit_of_measure.name');
    const sortedWeightKeys = orderBy(Object.keys(weightRowGroups), key => key);
    const weightTotalList = sortedWeightKeys.map(weightKey => {
      const rows = weightRowGroups[weightKey];
      return {
        unit: weightKey,
        amount: sumOrdersByWeightType(rows, packageWeights),
      };
    });

    // Dollar Total Groups
    const currencyRowGroups = groupBy(selectedRows, 'sell_currency');
    const sortedCurrencyKeys = orderBy(Object.keys(currencyRowGroups), key => key.toLowerCase());
    const dollarTotalList = sortedCurrencyKeys.map(currencyKey => {
      const rows = currencyRowGroups[currencyKey];
      return {
        unit: currencyKey === 'null' ? 'USD' : currencyKey,
        amount: sumBy(rows, 'pricing_details.total_sell_price'),
      };
    });

    return (
      <HStack justifyContent="space-between !important">
        <Button
          width="207px"
          height="43px"
          rightIcon={<ShareOutlineSVG />}
          isLoading={isExportingExcel}
          loadingText="Exporting..."
          onClick={onClickExport}
        >
          Export as Excel
        </Button>
        <HStack spacing="50px" backgroundColor="white" borderRadius="8px" paddingX="21px" paddingY="8px">
          <HStack
            spacing="25px"
            divider={<StackDivider borderColor="gray.200" />}
            alignItems="center"
            justifyContent="flex-end"
          >
            {/* <!-- INVOICE COUNT --> */}
            <InvoiceTotal
              {...{
                amount: invoiceCount,
                unit: '',
                label: 'Total no. Invoices',
              }}
            />
            {/* <!-- TOTAL WEIGHT --> */}
            {weightTotalList.map((obj, idx) => (
              <InvoiceTotal
                {...{
                  key: `invoice-weight-total-${idx}`,
                  amount: accounting.formatNumber(obj.amount, 2, ','),
                  unit: obj.unit,
                  label: idx === 0 ? 'Total Weight(s)' : '',
                }}
              />
            ))}
            {/* <!-- DOLLAR TOTALS --> */}
            {dollarTotalList.map((obj, idx) => (
              <InvoiceTotal
                {...{
                  key: `invoice-currency-total-${idx}`,
                  amount: formatMonetaryValue(obj.unit ? obj.unit : 'USD', obj.amount, {
                    notation: 'standard',
                    minimumFractionDigits: 2,
                  }),
                  unit: '',
                  label: idx === 0 ? 'Total Invoice Amount(s)' : '',
                }}
              />
            ))}
          </HStack>
          <Button
            onClick={onButtonClick}
            disabled={isDisabled}
            colorScheme="actionPrimary"
            minWidth="133px"
            height="43px"
          >
            {buttonText}
          </Button>
        </HStack>
      </HStack>
    );
  }
}

export default InvoiceTotals;
