import axios from 'axios';
import { group } from 'd3';
import { endOfMonth, parse as parseDate, startOfMonth, subMonths } from 'date-fns';
import get from 'lodash/get';
import moment from 'moment';
import queryString from 'query-string';
import Constants from '../Constants';
import { epochToJsDate } from '../helpers/dates';
import { KG_TO_LB, LB_TO_KG, UOM } from '../modules/const';
import { dateStringToEpoch } from '../modules/order-utils';
import { MARKET_FOCUS, ORDER_PRICE_TYPE } from '../slices/markets/marketsConfig';

const BASE_URL = `${Constants.URL}analytic-dashboard`;
const NCR_URL = `${Constants.URL}ncrs`;
const PACKER_PLANT_RANKING_URL = `${Constants.URL}analytic-dashboard/quality/packer-plant-scores`;
const PACKER_PLANT_GRINDER_LOCATIONS = `${Constants.URL}packer-plant-grinder-geo-locations`;
const CLAIM_URL = `${Constants.URL}claims`;
const ARTICLE_URL = `${Constants.URL}articles`;

const SUSTAINABILITY_METRICS_URL = `${Constants.URL}sustainability-metrics`;

const RISK_MANAGED_PERIOD_IN_DAYS = 30;

// Not used, TODO clean up references
const S3FixtureUsdaHistorical = `${BASE_URL}/populate-data?reqFileKey=HJOyf8OYGxFdEO8d`;

// TODO: remove these. we want dates in our standard d/M/yy format.
const yearMonthDateToTime = dateString => parseDate(dateString, 'yyyy-MM-dd', new Date()).getTime();

const dayMonthYearToTime = dateString => parseDate(dateString, 'd/M/yy', new Date()).getTime();

export function normalizeCurrency(amount, order, currency, desiredCurrency) {
  const startingCurrency = currency;
  if (startingCurrency === desiredCurrency) {
    return amount;
  }

  switch (desiredCurrency) {
    case 'USD': {
      return amount * order.usd_exchange_rate;
    }
    case 'AUD':
    default: {
      return amount * order.aud_exchange_rate;
    }
  }
}

export function normalizeQuantity(amount, currentUom, desiredUom) {
  if (currentUom === desiredUom) {
    return amount;
  }

  if (currentUom === UOM.LBS && desiredUom === UOM.KGS) {
    return amount * LB_TO_KG;
  }
  if (currentUom === UOM.KGS && desiredUom === UOM.LBS) {
    return amount * KG_TO_LB;
  }

  return amount;
}

const normalizeSellPrice = (order, desiredCurrency, desiredUom) => {
  const currencyNormalizedPrice = normalizeCurrency(
    order.sell_price_per_unit,
    order,
    order.sell_currency,
    desiredCurrency
  );
  const quantityNormalizedPrice = currencyNormalizedPrice / normalizeQuantity(1, order.sell_uom, desiredUom);

  return quantityNormalizedPrice;
};

const normaliseUnit = (raw, desiredCurrency, desiredUom) => {
  return {
    ...(raw.quantity && { quantity: normalizeQuantity(raw.quantity, raw.sell_unit_of_measure, desiredUom) }),
    ...(raw.buy_quantity && {
      buy_quantity: normalizeQuantity(raw.buy_quantity, raw.sell_unit_of_measure, desiredUom),
    }),
    ...(raw.recv_quantity && {
      recv_quantity: normalizeQuantity(raw.recv_quantity, raw.sell_unit_of_measure, desiredUom),
    }),
    sell_price_per_unit: normalizeSellPrice(raw, desiredCurrency, desiredUom),
  };
};

function determineOrderType(raw) {
  if (['USA', 'Canada'].includes(raw.grinder_country)) {
    return raw.order_type;
  }
  if (raw.latest_cop_cos_date && raw.delivery_date) {
    if (moment(raw.delivery_date).diff(moment(raw.latest_cop_cos_date), 'days') >= RISK_MANAGED_PERIOD_IN_DAYS) {
      return ORDER_PRICE_TYPE.RISK_MANAGED;
    }
  }
  return raw.order_type;
}

async function fetchMarketsDataFixture(fetchParameters) {
  const { activeMarket, timePeriod, currency } = fetchParameters;
  const activeMarketKey = activeMarket.key;
  const desiredCurrency = currency;
  const desiredUom = activeMarket.quantityUnit === 'kg' ? UOM.KGS : UOM.LBS;
  // When Australia is selected, it includes both AU and NZ.
  const { includedCountries } = activeMarket;

  // ----------------------------------------------------------------------------

  // TODO: use order_date in query params.
  const startDateInSeconds = new Date(timePeriod[0]).getTime();
  const endDateInSeconds = new Date(timePeriod[1]).getTime();
  const startDate = moment(epochToJsDate(startDateInSeconds)).format('YYYY-MM-DD');
  const endDate = moment(epochToJsDate(endDateInSeconds)).format('YYYY-MM-DD');
  // for markets volume detail page
  const { volumeFilter } = fetchParameters;
  const query = queryString.stringify({
    delivery_start_date: startDate,
    delivery_end_date: endDate,
    country_tab: activeMarketKey,
    order_status: volumeFilter,
    product_type: Constants.PRODUCT_TYPE_LIST.BEEF,
  });

  const ordersDataAllPromise = axios.get(`${BASE_URL}/markets?${query}`);

  // The geodata must return ALL packerPlants/grinders for the market
  // regardless of whether any orders were processed.
  const geoPromise = axios.get(PACKER_PLANT_GRINDER_LOCATIONS);

  // NOTE: please check transform below (starting with line `const ncrAll = await ncrPromise;`)
  // it would be preferred to get data from the server in that format.
  // dates should remain text.
  // Should return previous month's NCRs
  const ncrPromise = axios.get(NCR_URL);

  // const scorePromise = fetch(`${process.env.PUBLIC_URL}/data/fixtures/packer-plant-scores.json`).then(res => res.json());
  const params = queryString.stringify({
    date: startDate,
    country_tab: activeMarketKey,
    product_type: Constants.PRODUCT_TYPE_LIST.BEEF,
    desired_uom: desiredUom,
    time_scale: 'monthly',
  });
  const scorePromise = axios.get(`${PACKER_PLANT_RANKING_URL}?${params}`);

  const previousMonth = subMonths(new Date(), 1);
  const previousMonthStart = startOfMonth(previousMonth).getTime();
  const previousMonthEnd = endOfMonth(previousMonth).getTime();

  // ----------------------------------------------------------------------------

  // ---------------------------------------------
  // Orders
  // ---------------------------------------------

  const ordersDataAll = await ordersDataAllPromise;

  const locationsResponse = await geoPromise;
  const locations = get(locationsResponse, 'data') || [];

  // TODO: for now, we are filtering in JS but query should do it.
  const queriedOrders = ordersDataAll.data.map(raw => {
    return {
      ...raw,
      // Turn dates into integers.
      order_date: dateStringToEpoch(raw.order_date),
      delivery_date: dateStringToEpoch(raw.delivery_date),
      order_type: determineOrderType(raw),
      coldstore_entry_date: dateStringToEpoch(raw.order_date),
      // Normalise the quantity and price per unit
      ...normaliseUnit(raw, desiredCurrency, desiredUom),
    };
  });
  // Commented as landing page doesn't have this filter
  // .filter(d => {
  //   if (!d.sell_price_per_unit || !d.quantity) return false;
  //   return true;
  // });

  // Pre-process the orders by splitting into "domestic" and "export"
  const groupedOrders = group(queriedOrders, order => {
    // TODO: think about imports for US/CAN
    // For markets that don't export, we short circuit to domestic.
    if (!activeMarket.allowExportFocus) return MARKET_FOCUS.DOMESTIC;

    // If it's being ground in another country, it's "export"
    const isExport = includedCountries
      ? includedCountries.every(x => order.grinder_country !== x)
      : order.grinder_country !== activeMarketKey;

    return isExport ? MARKET_FOCUS.EXPORT : MARKET_FOCUS.DOMESTIC;
  });

  const orders = Object.fromEntries(groupedOrders.entries());

  // ---------------------------------------------
  // NCR
  // ---------------------------------------------
  const ncrAll = await ncrPromise;
  // TODO: dates should be in ""3/4/20" (or "d/M/yy")" format
  const ncrs = ncrAll.data
    .map(raw => ({
      id: raw.record_no,
      orderNo: raw.record_no,
      est: get(raw, 'packer_plant.est'),
      packer_plant_name: get(raw, 'packer_plant.name'),
      grinder: get(raw, 'grinder.name'),
      grinder_country: get(raw, 'grinder.destination_country'),
      category: get(raw, 'non_conformance_sub_type.category'),
      subCategory: get(raw, 'non_conformance_sub_type.name'),
      dateRaised: yearMonthDateToTime(raw.reviewed_date),
      dateClosed: yearMonthDateToTime(raw.closed_date),
      targetClosureDate: yearMonthDateToTime(get(raw, 'target_closure_date')),
      status: raw.status,
      amountAffected: raw.amount_affected,
      unit: get(raw, 'shipment_weight_type.full_name'),
    }))
    // Take NCRs from previous month.
    // Ex: If today is 7 Feb 2021, we would only show data for Jan 2021
    .filter(raw => {
      const isFromPreviousMonth = raw.dateRaised >= previousMonthStart && raw.dateRaised <= previousMonthEnd;
      const isApplicableGrinder = includedCountries
        ? includedCountries.includes(raw.grinder_country)
        : raw.grinder_country === activeMarketKey;

      return isFromPreviousMonth && isApplicableGrinder;
    });

  // ---------------------------------------------
  // PackerPlant scores, not shown currently
  // ---------------------------------------------
  const allPackerPlantsScores = await scorePromise;
  const packerPlantScores = allPackerPlantsScores.data.packer_plant_scores.scores.map(raw => ({
    packer_plant_id: get(raw, 'packerPlantId'),
    // packer_plant_name: get(raw, 'packer_plant.name'),
    score: get(raw, 'rank'),
  }));

  // ---------------------------------------------
  // Claims
  // ---------------------------------------------
  // Return claims for year to date
  const claimsAll = await axios.get(CLAIM_URL);
  // TODO: dates should be in ""3/4/20" (or "d/M/yy")" format
  const claims = claimsAll.data.map(raw => ({
    ...raw,
    date_opened: yearMonthDateToTime(raw.date_opened),
    date_closed: yearMonthDateToTime(raw.date_closed),
    closed: get(raw, 'status') === 'closed',
    dollar_credit_approved: parseFloat(get(raw, 'dollar_credit_approved')),
    dollar_credit_requested: parseFloat(get(raw, 'dollar_credit_requested')),
  }));

  // Not used, includeUsdaCalculations is always False
  // TODO clean up references
  let usdaPromise = null;
  if (activeMarket.includeUsdaCalculations) {
    usdaPromise = axios.get(S3FixtureUsdaHistorical);
  }

  const usdaData = await usdaPromise;
  let usdaPrices;
  if (usdaPromise) {
    usdaPrices = usdaData.data.map(raw => ({
      ...raw,
      date: new Date(raw.date).getTime(),
      formula: normaliseUnit({
        sell_price_per_unit: raw.formula,
        sell_unit_of_measure: raw.sell_unit_of_measure,
        sell_currency: raw.sell_currency,
        usd_exchange_rate: 1, // TODO - fix
        aud_exchange_rate: 1, // TODO - fix
      }).sell_price_per_unit,
      spot: normaliseUnit({
        sell_price_per_unit: raw.spot,
        sell_unit_of_measure: raw.sell_unit_of_measure,
        sell_currency: raw.sell_currency,
        usd_exchange_rate: 1, // TODO - fix
        aud_exchange_rate: 1, // TODO - fix
      }).sell_price_per_unit,
      contract: normaliseUnit({
        sell_price_per_unit: raw.contract,
        sell_unit_of_measure: raw.sell_unit_of_measure,
        sell_currency: raw.sell_currency,
        usd_exchange_rate: 1, // TODO - fix
        aud_exchange_rate: 1, // TODO - fix
      }).sell_price_per_unit,
      nof: normaliseUnit({
        sell_price_per_unit: raw.nof,
        sell_unit_of_measure: raw.sell_unit_of_measure,
        sell_currency: raw.sell_currency,
        usd_exchange_rate: 1, // TODO - fix
        aud_exchange_rate: 1, // TODO - fix
      }).sell_price_per_unit,
    }));
  }

  // ---------------------------------------------
  // Sustainability Metrics
  // ---------------------------------------------
  let sustainabilityMetrics;
  if (activeMarket.hasSustainabilityMetrics) {
    const sustainabilityResult = await axios.get(SUSTAINABILITY_METRICS_URL);
    sustainabilityMetrics = sustainabilityResult.data
      .map(raw => ({
        ...raw,
        date: dateStringToEpoch(raw.date),
      }))
      .sort((a, b) => a.date - b.date);
  }

  // ---------------------------------------------
  // Articles
  // ---------------------------------------------
  const tags_all = ['Sustainability'];
  let tags_any = [];
  if (activeMarket.includedCountries) {
    tags_any = tags_any.concat(activeMarket.includedCountries);
  } else {
    tags_all.push(activeMarket.key);
  }
  const articleQuery = queryString.stringify({
    tags_all,
    tags_any,
    limit: 4,
  });

  const sustainabilityArticlesData = await axios.get(`${ARTICLE_URL}?${articleQuery}`);
  const sustainabilityArticles = sustainabilityArticlesData.data.map(article => ({
    title: article.title,
    link: `/articles/${article.slug}`,
    date: moment.utc(article.published_date).local().format('DD MMM YYYY'),
  }));

  // ---------------------------------------------
  return {
    orders,
    locations,
    ncrs,
    packerPlantScores,
    claims,
    usdaPrices,
    sustainabilityMetrics,
    sustainabilityArticles,
  };
}

export default fetchMarketsDataFixture;
