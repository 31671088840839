import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import { getQuarter, getYear, format } from 'date-fns';

import useAnnotations from '../../../modules/hooks/useAnnotation';
import {
  selectTreasuryActiveMarket,
  selectTreasuryTimeScale,
} from '../../../slices/treasury/selectors/selectTreasuryBasicData';
import { TREASURY_MODULE, TREASURY_TIME_INTERVAL } from '../../../slices/treasury/treasuryConfig';
import {
  updateActiveVolume,
  updateActivePrice,
  TREASURY_DETAILS_PRICE,
  updateActiveCost,
} from '../../../slices/treasury-details/treasuryDetailsReducers';
import { selectTreasuryBuyPerformanceData } from '../../../slices/treasury/selectors/selectTreasuryBuyPerformanceData';

import PriceChart from '../../../components/analyticDashboard/treasury/PriceChart/PriceChart';
import AnnotationModal from '../../../shared/AnnotationModal/AnnotationModal';
import AnnotationList from '../../../shared/AnnotationList/AnnotationList';
import { ChevronRight } from '../../../shared/Icons';

export default function TreasuryPerformanceAndForecast(/* props */) {
  const dispatch = useDispatch();
  const buyPerformanceData = useSelector(selectTreasuryBuyPerformanceData);
  const timeScale = useSelector(selectTreasuryTimeScale);
  const { market: marketParam } = useParams();

  const annotationState = useAnnotations({
    page: 'treasury',
    focusSelector: selectTreasuryActiveMarket,
    module: 'performance-and-forecast',
    timeScaleSelector: selectTreasuryTimeScale,
  });
  const { addNewAnnotation, editAnnotation, annotations } = annotationState;

  const handlePriceSelect = React.useCallback(() => {
    dispatch(updateActiveVolume(null));
    dispatch(updateActivePrice(TREASURY_DETAILS_PRICE.MARKET_PRICE_AND_FORECAST));
    dispatch(updateActiveCost(null));
  }, [dispatch]);

  if (!buyPerformanceData) return null;

  const currentInterval =
    timeScale === TREASURY_TIME_INTERVAL.QUARTERLY
      ? `Q${getQuarter(new Date())} ${getYear(new Date())}`
      : `${format(new Date(), 'MMM')} ${getYear(new Date())}`;

  const annotationIntervalFormat = timeScale === TREASURY_TIME_INTERVAL.QUARTERLY ? 'QQQ yyyy' : 'MMM yyyy';

  const ind = buyPerformanceData.findIndex(x => x.interval === currentInterval);

  return (
    <>
      <div>
        <Link to={`/analytic-dashboard/treasury/${marketParam}/treasury-details`} onClick={() => handlePriceSelect()}>
          <div style={{ textTransform: 'uppercase', color: '#fc5d36' }}>
            FMG forecast vs usda price
            <div style={{ display: 'inline-block', width: '12px' }}>
              <ChevronRight />
            </div>
          </div>
        </Link>
      </div>
      <Row>
        <Col span={20}>
          <PriceChart
            data={buyPerformanceData}
            splitIndex={ind === -1 ? -1 : ind + 1}
            addNewAnnotation={addNewAnnotation}
            editAnnotation={editAnnotation}
            moduleAnnotations={annotations}
            annotationIntervalFormat={annotationIntervalFormat}
            chartType={TREASURY_DETAILS_PRICE.MARKET_PRICE_AND_FORECAST}
          />
          <AnnotationModal {...annotationState} />
        </Col>
        <Col span={4}>
          <AnnotationList annotationIntervalFormat={annotationIntervalFormat} {...annotationState} />
        </Col>
      </Row>
    </>
  );
}
