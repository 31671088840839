import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

import { commify, formattedValue, shorten } from '../../../functions';
import CostsSavingsDetails from './CostSavingsDetailsKPI';

class CostsSavingsKPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cost_savings: [],
      cost_savings_by_grinder: [],
      detailCostSavings: false,
      months: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.cost_savings && nextProps.additional_value && nextProps.grinders.length) {
      this.setState({ detailCostSavings: false });
      const cost_savings = [];
      const costSavingsIterate = {
        savings: nextProps.cost_savings ? nextProps.cost_savings : [],
        additional_value: nextProps.additional_value ? nextProps.additional_value : [],
      };

      for (const valueSet in costSavingsIterate) {
        costSavingsIterate[valueSet].forEach(thisData => {
          nextProps.grinders.forEach(grinder => {
            if (grinder.uid === thisData.grinder_uid) {
              let found = false;

              cost_savings.forEach(cost_saving => {
                if (cost_saving.id === thisData.grinder_uid && cost_saving.date === thisData.date) {
                  cost_saving[valueSet] = thisData.value;
                  found = true;
                }
              });

              if (!found) {
                const newData = {
                  uid: grinder.uid,
                  date: thisData.date,
                  grinder: grinder.name,
                };
                newData[valueSet] = thisData.value;
                cost_savings.push(newData);
              }
            }
          });
        });
      }

      const months = new Set();
      const cost_savings_by_grinder = [];

      cost_savings.forEach(cost_saving => {
        months.add(moment(cost_saving.date).format('MMM'));

        let found = false;
        cost_savings_by_grinder.forEach((by_grinder, index) => {
          if (by_grinder.grinder === cost_saving.grinder) {
            found = index;
          }
        });

        if (found !== false) {
          cost_savings_by_grinder[found].monthlySavings.push(parseFloat(cost_saving.savings));
          cost_savings_by_grinder[found].monthlyAdditionalValue.push(parseFloat(cost_saving.additional_value));
        } else {
          cost_savings_by_grinder.push({
            grinder: cost_saving.grinder,
            monthlySavings: [parseFloat(cost_saving.savings)],
            monthlyAdditionalValue: [parseFloat(cost_saving.additional_value)],
          });
        }
      });

      this.setState(
        {
          months,
          cost_savings_by_grinder,
        },
        () => {
          let latestDate = false;
          cost_savings.forEach(cost_saving => {
            if (!latestDate || moment(cost_saving.date).isAfter(moment(latestDate))) {
              latestDate = cost_saving.date;
            }
          });
          const monthNumber = moment(latestDate).format('M');
          this.selectCostSavingsMonth(monthNumber);
        }
      );
    }
  }

  getDataSets(monthNumber) {
    const datasets = [];

    this.state.cost_savings_by_grinder.forEach((by_grinder, index) => {
      datasets.push({
        label: `${by_grinder.grinder} Savings`,
        backgroundColor: `rgba(255, 217, 102, 0.${index + 5})`,
        borderColor: 'rgba(255, 255, 255, 1)',
        borderWidth: 1,
        stack: '2',
        hoverBackgroundColor: 'rgba(255, 217, 102, 1)',
        hoverBorderColor: 'rgba(255, 255, 255, 1)',
        data: [by_grinder.monthlySavings[monthNumber]],
      });

      datasets.push({
        label: `${by_grinder.grinder} Cost Avoidance`,
        backgroundColor: `rgba(236, 134, 79, 0.${index + 5})`,
        borderColor: 'rgba(255, 255, 255, 1)',
        borderWidth: 1,
        stack: '4',
        hoverBackgroundColor: 'rgba(236, 134, 79, 1)',
        hoverBorderColor: 'rgba(255, 255, 255, 1)',
        data: [by_grinder.monthlyAdditionalValue[monthNumber]],
      });
    });

    return datasets;
  }

  selectCostSavingsMonth(monthNumber) {
    this.setState({ detailCostSavings: false });
    const monthName = moment.monthsShort(monthNumber - 1);

    const chartData = {
      type: 'bar',
      labels: [monthName],
      datasets: this.getDataSets(monthNumber - 1),
      options: {
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
    };

    this.setState({
      detailCostSavings: { chartData, text: monthName },
    });
  }

  clearDetails = () => {
    this.setState({ detailCostSavings: false });
  };

  render() {
    let datasets = [];
    let ytdSavingsTotal = 0;
    let costAvoidance = 0;

    let maxValue = 0;
    let maxRange = 0;
    let step = 10;

    if (this.state.cost_savings_by_grinder.length) {
      const cost_savings_by_category = {
        savings: [],
        additional_value: [],
      };

      for (let i = 1; i <= this.state.cost_savings_by_grinder[0].monthlySavings.length; i++) {
        cost_savings_by_category.savings.push(0);
        cost_savings_by_category.additional_value.push(0);
      }

      this.state.cost_savings_by_grinder.forEach(function (by_grinder) {
        const savingsCombined = by_grinder.monthlySavings.map(function (num, idx) {
          return num + cost_savings_by_category.savings[idx];
        });

        cost_savings_by_category.savings = savingsCombined;

        const savingsSum = by_grinder.monthlySavings.reduce((a, b) => a + b, 0);
        ytdSavingsTotal += savingsSum;
        // Additional value incl
        const additionalValueCombined = by_grinder.monthlyAdditionalValue.map(function (num, idx) {
          return num + cost_savings_by_category.additional_value[idx];
        });
        cost_savings_by_category.additional_value = additionalValueCombined;

        const additionalValueSum = by_grinder.monthlyAdditionalValue.reduce((a, b) => a + b, 0);
        costAvoidance += additionalValueSum;
        maxValue = Math.max(...savingsCombined, ...additionalValueCombined, maxValue);
      });
      datasets = [
        {
          label: 'Savings',
          backgroundColor: 'rgba(255, 217, 102, 0.5)',
          borderColor: 'rgba(255, 217, 102, 0.6)',
          borderWidth: 0.1,
          stack: '1',
          hoverBackgroundColor: 'rgba(255, 217, 102, 1)',
          hoverBorderColor: 'rgba(255, 217, 102, 1)',
          data: cost_savings_by_category.savings,
        },
        {
          label: 'Cost Avoidance',
          backgroundColor: 'rgba(236, 134, 79, 0.5)',
          borderColor: 'rgba(236, 134, 79, 0.6)',
          borderWidth: 1,
          stack: '4',
          hoverBackgroundColor: 'rgba(236, 134, 79, 1)',
          hoverBorderColor: 'rgba(236, 134, 79, 1)',
          data: cost_savings_by_category.additional_value,
        },
      ];

      // calculate max axisY value
      while (maxValue / step > 10) {
        step *= 10;
      }
      step = 2 * step;
      maxRange = Math.ceil(maxValue / step) * step;
    }

    const costSavings = {
      type: 'bar',
      labels: Array.from(this.state.months),
      datasets,
      options: {
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
    };

    const costSavingsChartOptions = {
      data: costSavings,
      width: 3,
      height: 1,
      options: {
        legend: {
          display: true,
          position: 'bottom',
        },
        onClick: (e, activeElements) => {
          if (activeElements[0]) {
            const monthNumber = moment().month(activeElements[0]._index).format('M');
            this.selectCostSavingsMonth(monthNumber);
          }
        },
        title: {
          display: false,
          text: 'Cost Savings',
          fontSize: 20,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: (value, index, values) => `$${commify(value)}`,
              },
            },
          ],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            beforeLabel: (value, values) => {
              return `${values.datasets[value.datasetIndex].label}: $${shorten(value.yLabel)}`;
            },
            label: () => {
              return false;
            },
          },
        },
      },
    };

    if (maxRange) {
      costSavingsChartOptions.options.scales.yAxes[0].ticks.max = maxRange;
      costSavingsChartOptions.options.scales.yAxes[0].ticks.stepSize = step;
    }

    return (
      <div className="row">
        <div className="col-sm-12">
          <h3>Cost Savings</h3>
          <ul className="kpi-summary-stats">
            <li>
              Target:{' '}
              <strong>
                <span className="kpi-summary-stats-target">
                  ${this.props.cost_savings_target ? formattedValue(this.props.cost_savings_target) : 0}
                </span>
              </strong>
            </li>
            <li>
              YTD Savings: <span style={ytdSavingsTotal > 0 ? {} : { color: 'red' }}>${shorten(ytdSavingsTotal)}</span>
            </li>
            <li>
              Cost Avoidance: <span style={costAvoidance > 0 ? {} : { color: 'red' }}>${shorten(costAvoidance)}</span>
            </li>
          </ul>
        </div>
        <div
          style={{
            width: '650px',
            paddingLeft: '20px',
            paddingTop: '10px',
            marginBottom: '25px',
          }}
        >
          <Bar {...costSavingsChartOptions} />
        </div>
        <CostsSavingsDetails
          details={this.state.detailCostSavings}
          clearDetails={this.clearDetails}
          step={step}
          maxRange={maxRange}
        />
      </div>
    );
  }
}

export default CostsSavingsKPI;
