const schema = [
  {
    name: 'ncr_sub_type',
    displayName: 'NCR SubType',
    nested: false,
    systemIdField: 'id',
    displayIdField: 'name',
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    deletable: true,
    path: '/non-conformance-sub-types',
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'The name or description of the NCR sub-type',
        dataType: 'shorttext',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'non_conformance_type_id',
        displayName: 'NCR Type',
        tooltip: 'Select the main NCR type to which this sub-type is linked',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'ncr_type',
        required: true,
        systemIdentifier: false,
      },
    ],
  },
];
export default schema;
