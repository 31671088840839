import React from 'react';
import { max, scaleLinear, format as d3Format} from 'd3';
import { formatDollars } from '../../../../modules/format';
import styles from './QualityClaims.module.css';
import { Typography } from 'antd';
import { ExclamationMarkIcon } from '../../../../shared/Icons';
import PropTypes from 'prop-types';

const formatCurrency = formatDollars(2);

export default function QualityClaims(props) {
  const { claimData, activeMarket } = props;

  const asArray = React.useMemo(() => {
    if (!claimData) return [];
    const submitted = {
      label: 'Submitted',
      claims: [
        {
          label: '',
          ...claimData.previousMonth.submitted,
        },
        {
          label: `${claimData.year} YTD`,
          ...claimData.yearToDate.submitted,
        },
      ],
    };

    const finalised = {
      label: activeMarket.key === 'USA' ? 'Finalized' : 'Finalised',
      claims: [
        {
          label: '',
          ...claimData.previousMonth.realised,
        },
        {
          label: `${claimData.year} YTD`,
          ...claimData.yearToDate.realised,
        },
      ],
    };
    const outstanding = {
      label: 'Outstanding',
      claims: [
        {
          label: `${claimData.year} YTD`,
          count: claimData.yearToDate.submitted.count - claimData.yearToDate.realised.count,
          value: claimData.yearToDate.submitted.value - claimData.yearToDate.realised.value,
        },
      ],
    };

    const submittedYtd2020 = submitted.claims[1].value;
    finalised.claims[0].percentage = finalised.claims[0].value / submittedYtd2020;
    finalised.claims[0].percentage = isNaN(finalised.claims[0].percentage) ? '' : finalised.claims[0].percentage
    finalised.claims[1].percentage = finalised.claims[1].value / submittedYtd2020;
    finalised.claims[1].percentage = isNaN(finalised.claims[1].percentage) ? '' : finalised.claims[1].percentage
    outstanding.claims[0].percentage = outstanding.claims[0].value / submittedYtd2020;
    outstanding.claims[0].percentage = isNaN(outstanding.claims[0].percentage) ? '' : outstanding.claims[0].percentage

    return [submitted, finalised, outstanding];
  }, [claimData]);

  const vis = () => {
    const blockWidthPercent = 1 / claimData.intervals.length;
    const maxVolume = max(claimData.intervals, d => d.sum);
    const heightScale = scaleLinear().range([0, 15]).domain([0, maxVolume]);

    const scheduleBlocks = claimData.intervals.map(day => {
      return (
        <div
          key={day.interval.toString()}
          className={styles.scheduleVisBlock}
          style={{
            width: blockWidthPercent * 100 + '%',
            height: heightScale(day.sum),
            backgroundColor: '#0B1435',
            opacity: 0.8,
          }}
        />
      );
    });

    return <div className={styles.scheduleVis}>{scheduleBlocks}</div>;
  };

  return (
    <div>
      <Typography.Text>Comments: n/a</Typography.Text>
      <br />
      <br />
      {claimData && <div style={{ height: 37 }}>{vis()}</div>}
      {claimData && (
        <div style={{ display: 'flex', marginTop: 20 }}>
          {asArray.map(x => (
            <div
              key={x.label}
              className={styles.table}
              style={{
                width: `${100 / asArray.length}%`,
              }}
            >
              <div className={styles.title}>
                {x.label === 'Outstanding' ? <ExclamationMarkIcon /> : null}
                {x.label}
              </div>
              <div className={styles.claimsAll}>
                {x.claims.map(y => (
                  <div
                    key={x.label + ' ' + y.label}
                    className={styles.claims}
                    style={{
                      width: `${100 / x.claims.length}%`,
                      opacity: y.label.indexOf('YTD') !== -1 && x.label !== 'Outstanding' ? 0.4 : 1,
                    }}
                  >
                    <div>{y.label}</div>
                    <div
                      className={styles.count}
                      style={{
                        color: x.label === 'Outstanding' ? '#FF2C1B' : 'inherit',
                        opacity: 0.8,
                      }}
                    >
                      {y.count}
                    </div>
                    <div
                      className={styles.value}
                      style={{
                        color: x.label === 'Outstanding' ? '#FF2C1B' : 'inherit',
                        opacity: 0.8,
                      }}
                    >
                      <strong>{formatCurrency(y.value)}</strong>
                      {y.percentage && (
                        <>
                          {' '}
                          <span>({d3Format('.0%')(y.percentage)})</span>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

QualityClaims.propTypes = {
  claimData: PropTypes.shape({
    yearToDate: PropTypes.shape({
      submitted: PropTypes.shape({
        count: PropTypes.number,
        value: PropTypes.string,
      }),
      realised: PropTypes.shape({
        count: PropTypes.number,
        value: PropTypes.string,
      }),
    }),
    year: PropTypes.string,
    previousMonth: PropTypes.shape({
      yearToDate: PropTypes.shape({
        submitted: PropTypes.shape({
          count: PropTypes.number,
          value: PropTypes.string,
        }),
        realised: PropTypes.shape({
          count: PropTypes.number,
          value: PropTypes.string,
        }),
      }),
    }),
    intervals: PropTypes.arrayOf(
      PropTypes.shape({
        interval: PropTypes.number,
        sum: PropTypes.number,
      })
    ),
  }),
  activeMarket: PropTypes.string.isRequired,
};
