import BuyActivity from './components/buyActivity/BuyActivity';
import Articles from './components/pages/Articles';
import PackerInterface from './components/pages/PackerInterface';
import ProcurementOptimizer from './components/pages/ProcurementOptimizer';
import Markets from './containers/analyticDashboard/markets/Markets';
import MarketsVolumeDetails from './containers/analyticDashboard/marketsDetails/Volume';
import Quality from './containers/analyticDashboard/quality/Quality';
import QualityDetailsClaims from './containers/analyticDashboard/qualityDetails/claims/QualityDetailsClaims';
import QualityDetailsNCRs from './containers/analyticDashboard/qualityDetails/ncrs/QualityDetailsNCRs';
import SupplyChainDetails from './containers/analyticDashboard/supplyChainDetails/SupplyChainDetails';
import Treasury from './containers/analyticDashboard/treasury/Treasury';
import TreasuryDetails from './containers/analyticDashboard/treasuryDetails/TreasuryDetails';
import Article from './containers/articles/Article';
import ArticleEdit from './containers/articles/ArticleEdit';
import DailyTasksAdmin from './containers/dailyTasks/DailyTasksAdmin';
import DailyTasksDashboard from './containers/dailyTasks/DailyTasksDashboard';
import DrawDownReports from './containers/finance/DrawDownReports';
import FinancePage from './containers/finance/FinancePage';
import MasterData from './containers/masterData/MasterData';
import ColdstoreManagement from './containers/pages/ColdstoreManagement';
import Covid19Aus from './containers/pages/Covid19Aus';
import CurrentAffairs from './containers/pages/CurrentAffairs';
import Dashboard from './containers/pages/Dashboard';
import DataManagement from './containers/pages/DataManagement';
import Logout from './containers/pages/Logout';
import OrderManagement from './containers/pages/OrderManagement';
import Support from './containers/pages/Support';
import UserManagement from './containers/pages/UserManagement';
import UserSettings from './containers/pages/UserSettings';
import selfServiceReport from './containers/selfServiceReports/selfServiceReport';
import selfServiceReportConfig from './containers/selfServiceReports/selfServiceReportConfig';
import ReportsList from './containers/selfServiceReports/selfServiceReportsList';

const routeConfig = {
  groups: [
    {
      name: 'Standard',
      children: [
        {
          name: 'Dashboards',
          authConfig: {
            requiredPermissions: ['dashboard_read'],
          },
          routeConfig: {
            exact: true,
            path: '/',
          },
          component: Dashboard,
          icon: 'flaticon-squares-1',
          children: [
            {
              name: 'Daily Tasks',
              authConfig: {
                requiredPermissions: ['exceptions_read'],
              },
              routeConfig: {
                exact: true,
                path: '/daily-tasks',
              },
              component: Dashboard,
              children: [
                {
                  name: 'Daily Tasks Admin',
                  routeConfig: {
                    exact: true,
                    path: '/daily-tasks-admin',
                  },
                  component: DailyTasksAdmin,
                },
                {
                  name: 'Daily Tasks',
                  routeConfig: {
                    exact: true,
                    path: '/daily-tasks',
                  },
                  component: DailyTasksDashboard,
                }
              ],
            },
            {
              name: 'Buy Activity Dashboard',
              authConfig: {
                requiredPermissions: ['buy_activity_read'],
              },
              routeConfig: {
                exact: true,
                path: '/buy-activity',
              },
              component: BuyActivity,
            },
          ],
        },
        {
          name: 'Market Treasury Dashboard',
          authConfig: {
            requiredPermissions: ['dashboard_read'],
          },
          routeConfig: {
            exact: true,
            path: '/analytic-dashboard/market/:market',
            pathOptions: ['/analytic-dashboard/market/aus', '/analytic-dashboard/market/us'],
            defaultPath: '/analytic-dashboard/market/aus',
          },
          component: Markets,
          icon: 'fa fa-bar-chart',
          children: [
            {
              name: 'Treasury Dashboard',
              authConfig: {
                requiredPermissions: ['dashboard_read'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/treasury/:market',
                pathOptions: ['/analytic-dashboard/treasury/aus', '/analytic-dashboard/treasury/us'],
                defaultPath: '/analytic-dashboard/treasury/us',
              },
              component: Treasury,
            },
            {
              name: 'Treasury Dashboard Details',
              authConfig: {
                requiredPermissions: ['dashboard_read'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/treasury/:market/treasury-details',
              },
              component: TreasuryDetails,
            },
            {
              name: 'Markets Volume Details',
              authConfig: {
                requiredPermissions: ['dashboard_read'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/market/:market/volume-details',
              },
              component: MarketsVolumeDetails,
            },
            {
              name: 'Supply Chain Details',
              authConfig: {
                requiredPermissions: ['dashboard_read'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/market/:market/supply-chain-details',
              },
              component: SupplyChainDetails,
            },
            {
              name: 'Quality',
              authConfig: {
                requiredPermissions: ['read_analytic_dashboard_quality'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/quality/:market',
                pathOptions: ['/analytic-dashboard/quality/aus', '/analytic-dashboard/quality/us'],
                defaultPath: '/analytic-dashboard/quality/aus',
              },
              component: Quality,
            },
            {
              name: 'NCRs Details',
              excludeFromNavigation: true,
              authConfig: {
                requiredPermissions: ['read_analytic_dashboard_quality'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/quality/:market/ncrs-details',
              },
              component: QualityDetailsNCRs,
            },
            {
              name: 'Claims Details',
              excludeFromNavigation: true,
              authConfig: {
                requiredPermissions: ['read_analytic_dashboard_quality'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/quality/:market/claims-details',
              },
              component: QualityDetailsClaims,
              icon: 'cfc-icon icon-fin-bank',
            },
          ],
        },
        {
          name: 'Current Affairs',
          authConfig: {
            requiredPermissions: ['read_current_events'],
          },
          routeConfig: {
            exact: true,
            path: '/current-affairs',
          },
          component: CurrentAffairs,
          icon: 'flaticon-network',
          children: [
            {
              name: 'Australia Covid Details',
              authConfig: {
                requiredPermissions: ['read_current_events'],
              },
              routeConfig: {
                exact: true,
                path: '/aus-covid19-details',
              },
              component: Covid19Aus,
            },
          ],
        },
        {
          name: 'Data Management',
          authConfig: {
            requiredPermissions: ['write_current_events'],
          },
          routeConfig: {
            exact: true,
            path: '/data-management',
          },
          component: DataManagement,
          icon: 'flaticon-file',
        },
        {
          name: 'Procurement Optimizer',
          authConfig: {
            requiredPermissions: ['procurement_optimizer_read'],
          },
          routeConfig: {
            exact: true,
            path: '/procurement-optimizer',
          },
          component: ProcurementOptimizer,
          icon: 'flaticon-dashboard',
        },
        {
          name: 'Order Management',
          authConfig: {
            requiredPermissions: ['order_management_read'],
          },
          routeConfig: {
            exact: true,
            path: '/order-management',
          },
          component: OrderManagement,
          icon: 'flaticon-truck',
        },
        {
          name: 'Coldstore Management',
          authConfig: {
            requiredPermissions: ['order_management_read'],
          },
          routeConfig: {
            exact: true,
            path: '/coldstore-management',
          },
          component: ColdstoreManagement,
          icon: 'fa fa-snowflake-o',
        },
        {
          name: 'Finance Invoicing',
          authConfig: {
            requiredPermissions: ['finance_invoices_read'],
          },
          routeConfig: {
            exact: true,
            path: '/finance-invoicing',
          },
          component: FinancePage,
          icon: 'fa fa-dollar',
          children: [
            {
              name: 'Drawdown Reports',
              routeConfig: {
                exact: true,
                path: '/finance-invoicing/drawdown-reports',
              },
              component: DrawDownReports,
            },
          ],
        },
        {
          name: 'Self Service Reports',
          authConfig: {
            requiredPermissions: ['read_self_service_report'],
          },
          routeConfig: {
            exact: true,
            path: '/self-service-reports',
          },
          component: ReportsList,
          icon: 'fa fa-file-excel-o',
          children: [
            {
              name: 'Self Service Report Config',
              routeConfig: {
                exact: true,
                path: '/self-service-reports/:reportId',
              },
              component: selfServiceReportConfig,
            },
            {
              name: 'Self Service Report',
              routeConfig: {
                exact: true,
                path: '/self-service-report/:reportId',
              },
              component: selfServiceReport,
            },
          ],
        },
        {
          name: 'Packer Offers',
          authConfig: {
            requiredPermissions: ['packer_inventory_read'],
          },
          routeConfig: {
            exact: true,
            path: '/packer-interface',
          },
          component: PackerInterface,
          icon: 'flaticon-list-2',
        },
        {
          name: 'Articles',
          authConfig: {
            requiredPermissions: ['articles_read'],
          },
          routeConfig: {
            exact: true,
            path: '/articles',
          },
          component: Articles,
          icon: 'flaticon-book',
          children: [
            {
              name: 'Article',
              routeConfig: {
                exact: true,
                path: '/articles/:slug',
              },
              component: Article,
            },
            {
              name: 'ArticleEdit',
              excludeFromNavigation: true,
              routeConfig: {
                exact: false,
                path: '/articles/edit/:slug',
              },
              component: ArticleEdit,
            },
          ],
        },
        {
          name: 'Support Tickets',
          routeConfig: {
            exact: true,
            path: '/support',
          },
          component: Support,
          icon: 'flaticon-alarm',
        },
        {
          name: 'My User',
          routeConfig: {
            exact: true,
            path: '/user-settings',
          },
          component: UserSettings,
          icon: 'flaticon-profile',
        },
        {
          name: 'Log Out',
          routeConfig: {
            exact: true,
            path: '/logout',
          },
          component: Logout,
          icon: 'flaticon-logout',
        },
      ],
    },

    {
      name: 'Admin',
      authConfig: {
        anyOfPermissions: ['master_data_read', 'roles_read', 'organisations_read', 'users_read'],
      },
      children: [
        {
          name: 'Master Data Management',
          authConfig: {
            anyOfPermissions: ['master_data_read', 'master_data_write'],
          },
          routeConfig: {
            exact: true,
            path: '/master-data',
          },
          component: MasterData,
          icon: 'flaticon-clipboard',
          children: [
            {
              name: 'Master Data Management',
              excludeFromNavigation: true,
              routeConfig: {
                exact: false,
                path: '/master-data/entity/:entityType',
              },
              component: MasterData,
            },
          ],
        },
        {
          name: 'Users Management',
          authConfig: {
            requiredPermissions: [
              'roles_read',
              'users_read',
              'organisations_read',
              'roles_write',
              'users_write',
              'organisations_write',
            ],
          },
          routeConfig: {
            exact: true,
            path: '/user-management',
          },
          component: UserManagement,
          icon: 'flaticon-users',
          children: [
            {
              name: 'Users Management',
              excludeFromNavigation: true,
              routeConfig: {
                exact: false,
                path: '/user-management/entity/:entityType',
              },
              component: UserManagement,
            },
          ],
        },
      ],
    },
  ],
};

export default routeConfig;
