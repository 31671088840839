import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Component
import { VStack, Wrap, FormLabel, Switch, Input, IconButton } from '@chakra-ui/react';
import CustomFormControl from '../../../core/CustomFormControl';
import { isEqual } from 'lodash';
import DateTimeWithTrappedFocus from '../../../core/DateTimeWithTrappedFocus';
import Tooltip from '../../../quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const propTypes = {
  editMode: PropTypes.bool,
  isPermit: PropTypes.bool,
  exportDetails: PropTypes.shape({
    docsReceivedDate: PropTypes.Datetime,
    isfReceivedDate: PropTypes.Datetime,
    docs_sent_to_custom_broker: PropTypes.Datetime,
    docsToBankDate: PropTypes.Datetime,
    importPermit: PropTypes.bool,
    importPermitExpiryDate: PropTypes.Datetime,
  }),
  onChangeValue: PropTypes.func,
  onObjectValueChange: PropTypes.func,
  onVoyageValueChange: PropTypes.func,
  showToggles: PropTypes.bool,
};

const ShipmentImportantDetails = ({
  exportDetails,
  editMode,
  onObjectValueChange,
  isCourierForFmg,
  isIsfFileDateCfc,
  isActiveDocsSentToBank,
  isActiveDocsSentToCustomer,
  showToggles,
}) => {
  const [importPermit, setImportPermit] = useState(exportDetails.importPermit?.toString());

  return (
    <VStack spacing="11px" align="stretch">
      <Wrap spacing="56px" width="100%">
        <CustomFormControl width="305px">
          <FormLabel htmlFor="docsReceivedDate">
            Docs received Date&nbsp;
            <Tooltip content="Records the date when shipment documents were received to track" placement="right">
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
          </FormLabel>
          <DateTimeWithTrappedFocus
            id="docsReceivedDate"
            inputProps={{
              className: 'form-control m-input',
              disabled: !editMode,
            }}
            closeOnSelect
            value={exportDetails?.docsReceivedDate}
            onChange={e =>
              onObjectValueChange(
                'exportDetails',
                'docsReceivedDate',
                e._isAMomentObject ? e.format('YYYY-MM-DD') : null || ''
              )
            }
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
          />
        </CustomFormControl>
        {showToggles ? (
          <CustomFormControl width="305px">
            <FormLabel htmlFor="isfReceivedDate">
              ISF received Date&nbsp;
              <Tooltip
                content="Records the date when Importer Security Filing (ISF) documents were received for customs compliance."
                placement="right"
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </FormLabel>
            <DateTimeWithTrappedFocus
              id="isfReceivedDate"
              inputProps={{
                className: 'form-control m-input',
                disabled: !editMode,
              }}
              closeOnSelect
              value={exportDetails.isfReceivedDate || ''}
              onChange={e =>
                onObjectValueChange(
                  'exportDetails',
                  'isfReceivedDate',
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : null || ''
                )
              }
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </CustomFormControl>
        ) : (
          ''
        )}
        {showToggles && isIsfFileDateCfc ? (
          <CustomFormControl width="305px">
            <FormLabel htmlFor="isfFileDate">ISF File Date</FormLabel>
            <DateTimeWithTrappedFocus
              id="isfFileDate"
              inputProps={{
                className: 'form-control m-input',
                disabled: !editMode,
              }}
              closeOnSelect
              value={exportDetails.isfFileDate || ''}
              onChange={e =>
                onObjectValueChange(
                  'exportDetails',
                  'isfFileDate',
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : null || ''
                )
              }
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </CustomFormControl>
        ) : (
          ''
        )}
        {showToggles ? (
          <CustomFormControl width="305px">
            <FormLabel htmlFor="docsToCustomBrokerDate">
              {isActiveDocsSentToCustomer}&nbsp;
              <Tooltip
                content="Records the date when shipment documents were sent to the customs broker for processing."
                placement="right"
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </FormLabel>
            <DateTimeWithTrappedFocus
              id="docsToCustomBrokerDate"
              inputProps={{
                className: 'form-control m-input',
                disabled: !editMode,
              }}
              closeOnSelect
              value={exportDetails?.docsToCustomBrokerDate || ''}
              onChange={e =>
                onObjectValueChange(
                  'exportDetails',
                  'docsToCustomBrokerDate',
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : null || ''
                )
              }
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </CustomFormControl>
        ) : (
          ''
        )}
      </Wrap>
      <Wrap spacing="56px" width="100%">
        {showToggles && isCourierForFmg ? (
          <CustomFormControl width="305px">
            <FormLabel htmlFor="courierTrackingNo">Courier tracking no.</FormLabel>
            <Input
              backgroundColor="white"
              id="courierTrackingNo"
              value={exportDetails?.courierTrackingNo || ''}
              isDisabled={!editMode}
              className="form-control"
              onChange={e => onObjectValueChange('exportDetails', 'courierTrackingNo', e.target.value)}
            />
          </CustomFormControl>
        ) : (
          <>
            {showToggles && isActiveDocsSentToBank ? (
              <CustomFormControl width="305px">
                <FormLabel htmlFor="docsToBankDate">Docs sent to Bank</FormLabel>
                <DateTimeWithTrappedFocus
                  id="docsToBankDate"
                  inputProps={{
                    className: 'form-control m-input',
                    disabled: !editMode,
                  }}
                  closeOnSelect
                  value={exportDetails?.docsToBankDate || ''}
                  onChange={e =>
                    onObjectValueChange(
                      'exportDetails',
                      'docsToBankDate',
                      e._isAMomentObject ? e.format('YYYY-MM-DD') : null || ''
                    )
                  }
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </CustomFormControl>
            ) : (
              ''
            )}{' '}
          </>
        )}
        {showToggles ? (
          <CustomFormControl width="305px">
            <FormLabel htmlFor="import-permit">
              Import Permit&nbsp;
              <Tooltip
                content="Indicates whether an import permit is required for the shipment (Yes or No)"
                placement="right"
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </FormLabel>
            <Switch
              marginTop="6px"
              data-test="import-permit"
              id="isRecurring"
              size="lg"
              colorScheme="actionPrimary"
              defaultChecked={importPermit}
              isDisabled={!editMode}
              onChange={e => {
                const value = e.nativeEvent.target.checked;
                setImportPermit(value);
                onObjectValueChange('exportDetails', 'importPermit', value);
              }}
            />
          </CustomFormControl>
        ) : (
          ''
        )}
        {importPermit === 'true' && (
          <CustomFormControl width="305px">
            <FormLabel htmlFor="import_permit_expiry">Import Permit Expiry</FormLabel>
            <DateTimeWithTrappedFocus
              id="import_permit_expiry"
              inputProps={{
                className: 'form-control m-input',
                disabled: !editMode,
              }}
              closeOnSelect
              value={exportDetails?.importPermitExpiryDate || ''}
              onChange={e =>
                onObjectValueChange(
                  'exportDetails',
                  'importPermitExpiryDate',
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : null || ''
                )
              }
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </CustomFormControl>
        )}
      </Wrap>
    </VStack>
  );
};

ShipmentImportantDetails.ProType = propTypes;
export default React.memo(ShipmentImportantDetails, isEqual);
