import api from '../api';
import Constants from '../Constants';

export const BEEF_INPUT_PRODUCTS_FETCHED = 'BEEF_INPUT_PRODUCTS_FETCHED';
export const BEEF_INPUT_PRODUCT_ADDED = 'BEEF_INPUT_PRODUCT_ADDED';
export const BEEF_INPUT_PRODUCT_EDIT = 'BEEF_INPUT_PRODUCT_EDIT';
export const BEEF_INPUT_PRODUCT_REMOVED = 'BEEF_INPUT_PRODUCT_REMOVED';
export const BEEF_INPUT_PRODUCT_ERROR = 'BEEF_INPUT_PRODUCT_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadBeefInputProducts(beefProducts) {
  return {
    type: BEEF_INPUT_PRODUCTS_FETCHED,
    payload: beefProducts,
  };
}

export function addBeefInputProduct(beefInputProduct) {
  return {
    type: BEEF_INPUT_PRODUCT_ADDED,
    payload: beefInputProduct,
  };
}

export function removeBeefInputProduct(beefInputProduct) {
  return {
    type: BEEF_INPUT_PRODUCT_REMOVED,
    payload: beefInputProduct,
  };
}

export function editBeefInputProduct(beefInputProduct) {
  return {
    type: BEEF_INPUT_PRODUCT_EDIT,
    payload: beefInputProduct,
  };
}

export function errorBeefInputProduct(error) {
  return {
    type: BEEF_INPUT_PRODUCT_ERROR,
    payload: error,
  };
}

const basePath = '/input_products';

/* ---------------------------------
    THUNKS
-----------------------------------*/
export const fetchBeefInputProducts = () => dispatch => {
  return api
    .get(basePath)
    .then(response => Constants.handleErrors(response, dispatch, errorBeefInputProduct))
    .then(json => dispatch(loadBeefInputProducts(json)))
    .catch(error => dispatch(errorBeefInputProduct(error)));
};

export const postBeefInputProduct = beefInputProduct => dispatch => {
  return api
    .post(basePath, beefInputProduct)
    .then(response => Constants.handleErrors(response, dispatch, errorBeefInputProduct))
    .then(json => {
      if (json.title) {
        dispatch(errorBeefInputProduct(json.title));
      } else {
        dispatch(addBeefInputProduct(json));
      }
    })
    .catch(error => dispatch(errorBeefInputProduct(error)));
};

export const patchBeefInputProduct = beefInputProduct => dispatch => {
  return api
    .patch(`${basePath}/${beefInputProduct.uid}`, beefInputProduct)
    .then(response => Constants.handleErrors(response, dispatch, errorBeefInputProduct))
    .then(json => {
      if (json.title) {
        dispatch(errorBeefInputProduct(json.title));
      } else {
        dispatch(editBeefInputProduct(json));
      }
    })
    .catch(error => dispatch(errorBeefInputProduct(error)));
};

export const deleteBeefInputProduct = beefInputProduct => dispatch => {
  return api
    .delete(`${basePath}/${beefInputProduct.uid}`)
    .then(response => Constants.handleErrors(response, dispatch, errorBeefInputProduct))
    .then(() => dispatch(removeBeefInputProduct(beefInputProduct)))
    .catch(error => dispatch(errorBeefInputProduct(error)));
};
