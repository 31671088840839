const schema = [
  {
    name: 'contact',
    displayName: 'Contact',
    nested: true,
    systemIdField: null,
    displayIdField: null,
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'hidden',
        required: false,
        systemIdentifier: true,
      },
      {
        name: 'grinder_uid',
        displayName: 'Grinder UID',
        dataType: 'hidden',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'packer_plant_id',
        displayName: 'Packer UID',
        dataType: 'hidden',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'user_id',
        displayName: 'User UID',
        dataType: 'hidden',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'The name of the contact person.',
        dataType: 'text',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'country_code',
        displayName: 'Country Code',
        tooltip: "The country code for the contact's phone number",
        dataType: 'shorttext',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'number',
        displayName: 'Number',
        tooltip: "The contact's phone number",
        dataType: 'shorttext',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'main',
        displayName: 'Main',
        tooltip: 'Indicate if this is the primary contact number (Select Yes or No).',
        dataType: 'boolean',
        required: false,
        systemIdentifier: false,
      },
    ],
  },
];
export default schema;
