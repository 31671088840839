import { MARKETS } from '../../../config/markets';

// ---------------
// Basic selectors

/**
 * Whether the market page is loading
 * @param {*} state
 */
export const selectQualityNCRsLoading = state => state.qualityNCRs.loading;

/**
 * Any page-wide error for the market page
 * @param {*} state
 */
export const selectQualityNCRsError = state => state.qualityNCRs.error;

/**
 * The raw categories stored in state.
 * @param {*} state
 */
export const selectQualityNCRsCategories = state => state.qualityNCRs.data && state.qualityNCRs.data.categories;

/**
 * The time scale in the filter ui, i.e., historically or YoY
 * @param {*} state
 */
export const selectQualityNCRsTimeRange = state => state.qualityNCRs.timeRange;

/**
 * The time period selected by the date picker
 * @param {*} state
 */
export const selectQualityNCRsTimePeriod = state => state.qualityNCRs.timePeriod;

/**
 * The time period frequency selected
 * @param {*} state
 */
export const selectQualityNCRsFrequency = state => state.qualityNCRs.frequency;

/**
 * The active market
 * @param {*} state
 */
export const selectQualityNCRsMarket = state => MARKETS[state.qualityNCRs.marketKey];

/**
 * The active region
 * @param {*} state
 */
export const selectQualityNCRsRegion = state => state.qualityNCRs.region;

/**
 * The incidents setting
 * @param {*} state
 */
export const selectQualityNCRsIncidents = state => state.qualityNCRs.incidents;

/**
 * The volume setting
 * @param {*} state
 */
export const selectQualityNCRsVolume = state => state.qualityNCRs.volume;

/**
 * The claim dollars setting
 * @param {*} state
 */
export const selectQualityNCRsClaimDollars = state => state.qualityNCRs.claimDollars;

/**
 * The breakdown setting
 * @param {*} state
 */
export const selectQualityNCRsBreakdownMode = state => state.qualityNCRs.breakdownMode;

/**
 * The selected activePackerPlants to filter the data for
 * @param {*} state
 */
export const selectQualityNCRsActivePackerPlants = state => state.qualityNCRs.activePackerPlants;

/**
 * The selected packerPlant from the Dashboard to filter the data for
 * @param {*} state
 */
export const selectQualityNCRsSelectedPackerPlantId = state => state.qualityNCRs.selectedPackerPlantId;

/**
 * The available packerPlant ids
 * @param {*} state
 */
export const selectQualityNCRsPackerPlantId = state =>
  state.qualityNCRs.data && state.qualityNCRs.data.packer_plant_ids;

/**
 * The active grinder
 * @param {*} state
 */
export const selectQualityNCRsGrinder = state => state.qualityNCRs.grinder;
