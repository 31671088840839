import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { python } from '@codemirror/lang-python';
import CodeMirror from '@uiw/react-codemirror';
import { Alert } from 'antd';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import Select from '../../../components/basic/SelectField';
import Tooltip from '../../../components/quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const VARIBLE_TYPES = ['constant', 'function'];
const VARIBLE_RETURN_TYPES = ['str', 'int', 'float', 'bool'];
const DEFAULT_PYTHON_EDITOR = 'def main(**kwargs):';

const formatOption = item => ({ label: item, value: item });

export default ({ variables, onSaveVariable, onRemoveVariable }) => {
  const [preventClosingModalOnSubmit, setPreventClosingModalOnSubmit] = useState(false);

  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues,
    resetForm,
    values,
    touched,
    isValid,
  } = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      label: Yup.string().nullable().required('Required'),
      description: Yup.string().nullable().required('Required'),
      uid: Yup.string().nullable().required('Required'),
      value: Yup.string().nullable().required('Required'),
      type: Yup.string().nullable().required('Required'),
      return_type: Yup.string().nullable().required('Required'),
    }),
    onSubmit: data => {
      onSaveVariable(data);
      if (!preventClosingModalOnSubmit) {
        closeFormModal();
      }
    },
  });
  const [showFormModal, setShowFormModal] = useState(false);

  const openFormModal = (variable = {}) => {
    resetForm();
    setValues(variable);
    setShowFormModal(true);
  };

  const closeFormModal = () => setShowFormModal(false);

  const removeVariable = () => {
    if (confirm('Are you sure you want to delete this item?') == true) {
      onRemoveVariable(values);
      closeFormModal();
    }
  };

  return (
    <Box>
      <VStack marginBottom="80px" alignItems="end">
        <Button colorScheme="actionPrimary" onClick={openFormModal}>
          New
        </Button>
      </VStack>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Label</Th>
              <Th>Description</Th>
              <Th>Name</Th>
              <Th>Value</Th>
              <Th>Type</Th>
              <Th>Return type</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {variables &&
              variables.map(item => (
                <Tr key={item.uid}>
                  <Td>{item.label}</Td>
                  <Td>{item.description}</Td>
                  <Td>{item.uid}</Td>
                  <Td>{item.type !== 'function' ? item.value : '(code)'}</Td>
                  <Td>{item.type}</Td>
                  <Td>{item.return_type}</Td>
                  <Td>
                    <Button onClick={() => openFormModal(item)}>Edit</Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Modal size="6xl" isOpen={showFormModal} onClose={closeFormModal}>
        <ModalOverlay />
        <ModalContent minHeight="200px">
          <form onSubmit={handleSubmit}>
            <ModalHeader>Variable</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing="10">
                <FormControl id="label" isInvalid={errors.label && touched.label}>
                  <FormLabel>
                    Label&nbsp;
                    <Tooltip
                      content="Enter a unique name to identify this variable within the Pricing Engine"
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Input name="label" type="text" onChange={handleChange} onBlur={handleBlur} value={values.label} />
                  {errors.label && touched.label && (
                    <Alert style={{ marginTop: '5px' }} message={errors.label} type="error" />
                  )}
                </FormControl>
                <FormControl id="description" isInvalid={errors.description && touched.description}>
                  <FormLabel>
                    Description&nbsp;
                    <Tooltip
                      content="Provide a detailed description of the purpose and usage of this variable"
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Input
                    name="description"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                  {errors.description && touched.description && (
                    <Alert style={{ marginTop: '5px' }} message={errors.description} type="error" />
                  )}
                </FormControl>
                <FormControl id="uid" isInvalid={errors.uid && touched.uid}>
                  <FormLabel>
                    Name&nbsp;
                    <Tooltip
                      content="Specify the name of the variable to be used in calculations or rules"
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Input
                    name="uid"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.uid}
                    readOnly={values.created}
                  />
                  {errors.uid && touched.uid && (
                    <Alert style={{ marginTop: '5px' }} message={errors.uid} type="error" />
                  )}
                </FormControl>
                <FormControl id="type" isInvalid={errors.type && touched.type}>
                  <FormLabel>
                    Type&nbsp;
                    <Tooltip
                      content="Select whether the variable type is 'Constant' or 'Function' to define its behavior"
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Select
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: true,
                      removeSelected: true,
                      name: 'type',
                      value: formatOption(values.type),
                      onChange: type => {
                        setFieldValue('type', type.value);
                        if (type.value === 'function') {
                          setFieldValue('value', DEFAULT_PYTHON_EDITOR);
                        } else {
                          setFieldValue('value', '');
                        }
                      },
                      options: VARIBLE_TYPES.map(formatOption),
                    }}
                  />
                  {errors.type && touched.type && (
                    <Alert style={{ marginTop: '5px' }} message={errors.type} type="error" />
                  )}
                </FormControl>
                <FormControl id="return_type" isInvalid={errors.return_type && touched.return_type}>
                  <FormLabel>
                    Return type&nbsp;
                    <Tooltip
                      content="Choose the data type that this variable will return, such as String, Integer, Float, or Boolean."
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Select
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: true,
                      removeSelected: true,
                      name: 'return_type',
                      value: formatOption(values.return_type),
                      onChange: return_type => {
                        setFieldValue('return_type', return_type.value);
                        if (values.type === 'function') {
                          setFieldValue('value', DEFAULT_PYTHON_EDITOR);
                        } else {
                          setFieldValue('value', '');
                        }
                      },
                      options: VARIBLE_RETURN_TYPES.map(formatOption),
                    }}
                  />
                  {errors.return_type && touched.return_type && (
                    <Alert style={{ marginTop: '5px' }} message={errors.return_type} type="error" />
                  )}
                </FormControl>
                <FormControl id="value" isInvalid={errors.value && touched.value}>
                  <FormLabel>
                    Value&nbsp;
                    <Tooltip
                      content="Enter the technical code that defines how this variable will be calculated"
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  {values.type === 'constant' && values.return_type === 'bool' && (
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'value',
                        value: formatOption(values.value),
                        onChange: value => {
                          setFieldValue('value', value.value);
                        },
                        options: ['True', 'False'].map(formatOption),
                      }}
                    />
                  )}
                  {values.type === 'constant' && ['str', 'int', 'float'].includes(values.return_type) && (
                    <Input name="value" type="text" onChange={handleChange} onBlur={handleBlur} value={values.value} />
                  )}
                  {values.type === 'function' && (
                    <CodeMirror
                      value={values.value}
                      height="500px"
                      extensions={[python({})]}
                      onChange={value => {
                        setFieldValue('value', value);
                      }}
                    />
                  )}
                  {errors.value && touched.value && (
                    <Alert style={{ marginTop: '5px' }} message={errors.value} type="error" />
                  )}
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Checkbox
                marginRight="auto"
                isChecked={preventClosingModalOnSubmit}
                onChange={() => setPreventClosingModalOnSubmit(!preventClosingModalOnSubmit)}
              >
                Prevent closing modal on submit
              </Checkbox>
              {values.created && (
                <Button type="button" colorScheme="error" onClick={removeVariable} marginRight="16px">
                  Remove
                </Button>
              )}
              <Button type="submit" colorScheme="actionPrimary" isDisabled={!isValid}>
                {!values.created ? 'New' : 'Save'}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};
