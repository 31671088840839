import { ACTIVE_ITEM_PICKED, CREATE_NEW_THING } from '../actions/index';

const activeEdit = (state = false, action) => {
  switch (action.type) {
    case ACTIVE_ITEM_PICKED:
      return action.payload;
    case CREATE_NEW_THING:
      return action.payload;
    default:
      return state;
  }
};

export default activeEdit;
