import { connect } from 'react-redux';
import Schedule from '../../components/coldstore/release-management/Schedule';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

const mapStateToProps = state => {
  return {
    ...findEntitiesInState(state, {
      grinders: 'grinder',
      packer_plants: 'packer_plant',
      input_products: 'input_product',
      cold_stores: 'coldstore',
      transporters: 'transporter',
    }),
    user: state.user,
    previewOrders: state.coldstore_orders_util.previewOrders,
    orders: state.orders_pending_coldstore_schedule,
  };
};

export default connect(mapStateToProps)(Schedule);
