const fields = {
  1: {
    id: 1,
    label: 'Product type',
    key: 'order.product_type',
    type: 'string',
    description: null,
    model: 'PurchaseOrderLine',
    edit_rule: null,
    reference_key: null,
  },
  2: {
    id: 2,
    label: 'Status',
    key: 'order.status',
    type: 'string',
    description: null,
    model: 'PurchaseOrderLine',
    edit_rule: null,
    reference_key: null,
  },
  3: {
    id: 3,
    label: 'Delivery date',
    key: 'order.delivery_date',
    type: 'date',
    description: null,
    model: 'PurchaseOrderLine',
    edit_rule: {
      id: 1,
      field_id: 3,
      affected_fields_message:
        'Some FOB prices are calculated depending on the Delivery Date of the PO. The system will auto-recalculate the following:',
      related_fields_message: 'Please re-evaluate these fields:',
      jobs: [],
      affected_fields: [
        {
          id: 1,
          label: 'Product type',
          key: 'order.product_type',
          type: 'string',
          description: null,
          model: 'PurchaseOrderLine',
          reference_key: null,
        },
        {
          id: 2,
          label: 'Status',
          key: 'order.status',
          type: 'string',
          description: null,
          model: 'PurchaseOrderLine',
          reference_key: null,
        },
      ],
      related_fields: [
        {
          id: 4,
          label: 'Grinder PO#',
          key: 'order.grinder_po_number',
          type: 'string',
          description: null,
          model: 'PurchaseOrderLine',
          reference_key: null,
        },
        {
          id: 5,
          label: 'Grinder',
          key: 'order.grinder.name',
          type: 'reference',
          description: null,
          model: 'PurchaseOrderLine',
          reference_key: 'grinder',
        },
      ],
    },
    reference_key: null,
  },
  4: {
    id: 4,
    label: 'Grinder PO#',
    key: 'order.grinder_po_number',
    type: 'string',
    description: null,
    model: 'PurchaseOrderLine',
    edit_rule: null,
    reference_key: null,
  },
  5: {
    id: 5,
    label: 'Grinder',
    key: 'order.grinder.name',
    type: 'reference',
    description: null,
    model: 'PurchaseOrderLine',
    edit_rule: null,
    reference_key: 'grinder',
  },
  6: {
    id: 6,
    label: 'Establishment',
    key: 'packer_plant.name',
    type: 'reference',
    description: null,
    model: 'PurchaseOrderLine',
    edit_rule: null,
    reference_key: 'packerPlant',
  },
  7: {
    id: 7,
    label: 'Product',
    key: 'input_product.name',
    type: 'reference',
    description: null,
    model: 'PurchaseOrderLine',
    edit_rule: null,
    reference_key: 'inputProduct',
  },
  8: {
    id: 8,
    label: 'Quantity',
    key: 'buy_quantity',
    type: 'number',
    description: null,
    model: 'PurchaseOrderLine',
    edit_rule: null,
    reference_key: null,
  },
  9: {
    id: 9,
    label: 'All-in Cost',
    key: 'order.all_in_cost',
    type: 'number',
    description: null,
    model: 'PurchaseOrderLine',
    edit_rule: null,
    reference_key: null,
  },
  10: {
    id: 10,
    label: 'Line Status',
    key: 'active',
    type: 'reference',
    description: null,
    model: 'PurchaseOrderLine',
    edit_rule: null,
    reference_key: 'lineStatus',
  },
};

const mapViewFieldWithField = vf => ({ ...vf, field: fields[vf.id] });

export default [
  {
    id: 1,
    name: 'Default View',
    fields: [
      {
        id: 1,
        results_view_id: 1,
        field_id: 1,
        display_order: 1,
        bulk_edit_is_sortable: true,
        bulk_edit_is_editable: false,
        bulk_edit_column_width: null,
      },
      {
        id: 2,
        results_view_id: 1,
        field_id: 2,
        display_order: 2,
        bulk_edit_is_sortable: true,
        bulk_edit_is_editable: false,
        bulk_edit_column_width: 90,
      },
      {
        id: 3,
        results_view_id: 1,
        field_id: 3,
        display_order: 3,
        bulk_edit_is_sortable: true,
        bulk_edit_is_editable: false,
        bulk_edit_column_width: 120,
      },
      {
        id: 4,
        results_view_id: 1,
        field_id: 4,
        display_order: 4,
        bulk_edit_is_sortable: true,
        bulk_edit_is_editable: false,
        bulk_edit_column_width: 120,
      },
      {
        id: 5,
        results_view_id: 1,
        field_id: 5,
        display_order: 5,
        bulk_edit_is_sortable: true,
        bulk_edit_is_editable: false,
        bulk_edit_column_width: 170,
      },
      {
        id: 6,
        results_view_id: 1,
        field_id: 6,
        display_order: 6,
        bulk_edit_is_sortable: true,
        bulk_edit_is_editable: true,
        bulk_edit_column_width: 230,
      },
      {
        id: 7,
        results_view_id: 1,
        field_id: 7,
        display_order: 7,
        bulk_edit_is_sortable: true,
        bulk_edit_is_editable: true,
        bulk_edit_column_width: 230,
      },
      {
        id: 8,
        results_view_id: 1,
        field_id: 8,
        display_order: 8,
        bulk_edit_is_sortable: true,
        bulk_edit_is_editable: true,
        bulk_edit_column_width: 150,
      },
      {
        id: 9,
        results_view_id: 1,
        field_id: 9,
        display_order: 9,
        bulk_edit_is_sortable: true,
        bulk_edit_is_editable: false,
        bulk_edit_column_width: null,
      },
      {
        id: 10,
        results_view_id: 1,
        field_id: 10,
        display_order: 10,
        bulk_edit_is_sortable: true,
        bulk_edit_is_editable: true,
        bulk_edit_column_width: 150,
      },
    ].map(mapViewFieldWithField),
  },
];
