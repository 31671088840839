import Constants from '../Constants';

export const addTwoFactorAuthMethod = async (token, method, password) => {
  try {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    const response = await fetch(`${Constants.URL}two_factor_auth`, {
      method: 'POST',
      body: JSON.stringify({ method, password }),
      headers: header,
    });
    if (response.status !== 200) return null;
    return await response.json();
  } catch (error) {
    return null;
  }
};

export const deleteTwoFactorAuthMethod = async (token, method, password) => {
  try {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    const response = await fetch(`${Constants.URL}two_factor_auth/${method}`, {
      method: 'DELETE',
      body: JSON.stringify({ password }),
      headers: header,
    });
    if (response.status !== 200) return null;
    return await response.json();
  } catch (error) {
    return null;
  }
};

export const updateTwoFactorAuthMethod = async (token, method, flag) => {
  try {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    const response = await fetch(`${Constants.URL}two_factor_auth/${method}`, {
      method: 'PATCH',
      body: JSON.stringify({ flag }),
      headers: header,
    });
    if (response.status !== 200) return null;
    return await response.json();
  } catch (error) {
    return null;
  }
};

export const fetchTwoFactorAuthMethods = async token => {
  try {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    const response = await fetch(`${Constants.URL}two_factor_auth`, {
      method: 'GET',
      headers: header,
    });
    if (response.status !== 200) return null;
    return await response.json();
  } catch (error) {
    return null;
  }
};

export const fetchQrCode = async token => {
  try {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    const response = await fetch(`${Constants.URL}get_qr_code`, {
      method: 'GET',
      headers: header,
    });
    if (response.status !== 200) return null;
    return await response.json();
  } catch (error) {
    return null;
  }
};

export const confirmOtpRequest = async (token, otp, method) => {
  try {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    const response = await fetch(`${Constants.URL}confirm_otp`, {
      method: 'POST',
      body: JSON.stringify({ token, otp, method }),
      headers: header,
    });
    if (response.status !== 200) return null;
    return true;
  } catch (error) {
    return null;
  }
};

export const sendOtpEmail = async email => {
  try {
    const header = new Headers({
      'Content-Type': 'application/json',
    });
    const response = await fetch(`${Constants.URL}send_otp?email=${email}`, {
      method: 'GET',
      headers: header,
    });
    if (response.status !== 200) return null;
    return await response.json();
  } catch (error) {
    return null;
  }
};
