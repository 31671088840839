import React, { useState } from 'react';
import { Table } from 'antd';
import {
  Box,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  Text,
} from '@chakra-ui/react';
import './EntityTable.scss';
import { delEntity } from '../../slices/masterData/entityManagerSlice';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { useDispatch } from 'react-redux';
import { ReactComponent as ChevronDown } from '../../assets/chevron-down.svg';
import { SearchIcon } from '@chakra-ui/icons';
import { get, isEmpty } from 'lodash';

const { Column } = Table;

const renderMultiSelectTag = (name, systemId) => {
  const vowelRegex = '[aieouAIEOU]$';
  const getTagStyles = () => {
    if ((systemId && isNaN(systemId) && systemId.match(vowelRegex)) || systemId % 2 === 0) {
      return {
        bgColor: '#FBD38D',
        color: '#000000',
      };
    }
    return {
      bgColor: '#805AD5',
      color: '#FFFFFF',
    };
  };
  return (
    <Tag key={name} variant="solid" margin="3px" fontSize="11px" fontWeight="medium" {...getTagStyles()}>
      {name}
    </Tag>
  );
};

const renderSortIcon = order => {
  if (order === 'ascend') {
    return (
      <Box transform="rotate(180deg)" marginLeft="10px">
        <ChevronDown />
      </Box>
    );
  }
  if (order === 'descend') {
    return (
      <Box>
        <ChevronDown />
      </Box>
    );
  }
  return null;
};
const lookupRenderer = (
  entityDefinition,
  fieldDef,
  referencedEntities,
  setActiveEntityid,
  setShowEditModel,
  dispatch
) => {
  const { dataType, referenceType } = fieldDef;
  let renderFn = data => data;

  if (['float', 'number', 'integer'].includes(dataType)) {
    renderFn = data => <Box width="100%">{data}</Box>;
  }

  if (['boolean'].includes(dataType)) {
    renderFn = data => <Text as="p"> {data ? 'Yes' : 'No'}</Text>;
  }

  if (['longtext'].includes(dataType)) {
    const limit = 50;
    renderFn = str => (str && str.length > limit ? `${str.substr(0, limit - 1)}…` : str);
  }
  if (dataType === 'reference' && referenceType === 'nested') {
    renderFn = data => {
      const processedValue = data
        ? Object.values(data).reduce((prev, current) => {
            if (current && current.length) {
              prev += ` ${current}`;
            }
            return prev;
          }, '')
        : '';
      return <Box>{processedValue}</Box>;
    };
  }

  if (dataType === 'reference' && ['multi-select', 'select'].includes(referenceType)) {
    renderFn = data => {
      const refEnt = referencedEntities[fieldDef.referenceEntityType];
      if (!refEnt) {
        return null;
      }
      let refEntId = refEnt.entityDefinition.systemIdField;
      let refEntDisplayId = refEnt.entityDefinition.displayIdField;
      let { entities } = refEnt;
      if (fieldDef.referenceEntitySubType) {
        // For form fileds reference, we have to look into sub entity type.
        entities = entities.filter(ent => ent.type === fieldDef.referenceEntitySubType);
        refEntId = 'value';
        refEntDisplayId = 'value';
      }
      const match = entities.filter(ent => {
        const value = refEntId ? ent[refEntId] : ent;
        if (Array.isArray(data)) {
          return data.includes(value);
        }
        return value === data;
      });

      let processedValue;

      if (match && match.length) {
        if (match.length > 1) {
          processedValue = (
            <Box
              height="100px"
              width="200px"
              overflow="scroll"
              css={{
                '&::-webkit-scrollbar': {
                  height: '4px',
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  height: '4px',
                  width: '6px',
                  background: 'var(--chakra-colors-gray-100)',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'var(--chakra-colors-actionSecondary-default)',
                  borderRadius: '24px',
                },
              }}
            >
              {match
                .map(value =>
                  value[refEntDisplayId]
                    ? {
                        name: value[refEntDisplayId],
                        systemId: value[refEnt.entityDefinition.systemIdField],
                      }
                    : { name: value, systemId: value[refEnt.entityDefinition.systemIdField] }
                )
                .map(({ name, systemId }) => renderMultiSelectTag(name, systemId))}
            </Box>
          );
        } else {
          processedValue = match[0][refEntDisplayId];
        }
      } else {
        processedValue = 'None';
      }
      return <Box>{processedValue}</Box>;
    };
  }
  if (dataType === 'deleteAction') {
    renderFn = (data, record) => {
      return (
        <Flex justifyContent="center">
          <IconButton
            color="actionSecondary.500"
            backgroundColor="inherit"
            onClick={() => {
              const confirmation = window.confirm(
                `Are you sure you want to delete this ${entityDefinition.displayName}?`
              );
              if (confirmation) {
                dispatch(delEntity({ entityDef: entityDefinition, entityid: record[entityDefinition.systemIdField] }));
              }
            }}
            icon={<DeleteIcon />}
          />
        </Flex>
      );
    };
  }

  if (entityDefinition.systemIdField === fieldDef.name) {
    const innerRenderFn = renderFn;
    renderFn = data => {
      return (
        <a
          onClick={e => {
            e.preventDefault();
            setActiveEntityid(data);
            setShowEditModel(true);
          }}
          href="javascript:void(0);"
        >
          {innerRenderFn(data)}
        </a>
      );
    };
  }
  return renderFn;
};

const EntityTable = ({
  entityDefinition,
  entities,
  referencedEntities,
  setActiveEntityid,
  setShowEditModel,
  pageSize,
  currentPage,
}) => {
  const [searchState, setSearchState] = useState({
    searchFilteredEntities: [],
    searchValues: {},
    searchArr: [],
    useSearch: false,
  });

  const dispatch = useDispatch();
  const hideFields = f => {
    // Multi-Nested reference entities no need to show in table list, too much details.
    const { dataType, referenceType } = f;
    if (dataType === 'reference' && referenceType === 'multi-nested') {
      return false;
    }
    if (f.hideList) {
      // Insignificant fields no need to be shown in table list.
      return false;
    }
    if (dataType === 'component') {
      // No need to render custom component
      return false;
    }
    return true;
  };

  const findFilterValues = name => {
    const referencedEntity = entityDefinition.fields.find(field => field.name === name);

    const uniqValues = entities.reduce((accumulate, current, i) => {
      accumulate[current[name]] = current[name];
      return accumulate;
    }, {});
    return Object.entries(uniqValues).map(([key, value]) => {
      const entry = get(referencedEntities[referencedEntity.referenceEntityType], 'entities', []).find(entity => {
        return (
          entity[referencedEntities[referencedEntity.referenceEntityType].entityDefinition.systemIdField] === value
        );
      });
      return { text: value ? get(entry, 'name', value) : 'None', value };
    });
  };

  const handleSearch = (e, colName) => {
    let newSearchState = { ...searchState };
    const searchValue = e.target.value;
    let filteredBySearch = entities;
    let useSearch = '';
    const existingSearchIndexOfCol = newSearchState.searchArr.findIndex(item => item.colName === colName);
    if (existingSearchIndexOfCol > -1) {
      newSearchState.searchArr.splice(existingSearchIndexOfCol, 1);
    }
    newSearchState.searchArr = [
      ...newSearchState.searchArr,
      {
        colName,
        searchValue,
      },
    ];
    newSearchState.searchArr.forEach(searchParameter => {
      useSearch += searchParameter.searchValue;
      filteredBySearch = filteredBySearch.filter(data =>
        `${data[searchParameter.colName]}`.toLowerCase().includes(searchParameter.searchValue.toLowerCase())
      );
    });
    newSearchState = {
      ...newSearchState,
      searchFilteredEntities: filteredBySearch,
    };
    setSearchState({ ...newSearchState, useSearch: !isEmpty(useSearch) });
  };

  return (
    <Table
      pagination={{ pageSize, current: currentPage, style: { display: 'none' } }}
      rowClassName="entity-table-row"
      className="entity-table"
      dataSource={(searchState.useSearch ? searchState.searchFilteredEntities : entities).map((entity, i) => ({
        ...entity,
        key: i,
      }))}
    >
      {[
        ...entityDefinition.fields,
        entityDefinition.deletable && {
          name: 'delete_action',
          displayName: '',
          dataType: 'deleteAction',
        },
      ]
        .filter(hideFields)
        .map(fieldDef => {
          let sorter = null;
          let onFilter = null;
          let filters = null;
          // Adding sorter and filter to table.
          if (['shorttext', 'text'].includes(fieldDef.dataType) || fieldDef.referenceType === 'select') {
            sorter = (x, y) => {
              const a = x[fieldDef.name];
              const b = y[fieldDef.name];
              if (a && b && typeof a === 'string' && typeof b === 'string') {
                return a.localeCompare(b);
              }
              return a - b;
            };
          } else if (['integer', 'number'].includes(fieldDef.dataType)) {
            sorter = (a, b) => a[fieldDef.name] - b[fieldDef.name];
          }
          // It's performance intensive, only do it for filtable field.
          if (fieldDef.filterable) {
            filters = findFilterValues(fieldDef.name);
            onFilter = (value, record) => {
              return record[fieldDef.name] === value;
            };
          }
          return (
            <Column
              title={({ sortColumns }) => {
                const sortedColumn = sortColumns?.find(({ column }) => column.key === fieldDef.name);
                return (
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>{fieldDef.displayName}</Text>
                    {sortedColumn ? renderSortIcon(sortedColumn.order) : null}
                  </Flex>
                );
              }}
              {...(fieldDef.searchable && {
                filterDropdown: () => <Box> </Box>,
                filterIcon: () => (
                  <Popover placement="top">
                    {({ isOpen }) => (
                      <>
                        <PopoverTrigger>
                          <IconButton
                            variant="unstyled"
                            fontSize="11px"
                            minWidth="11.37px"
                            height="100%"
                            icon={<SearchIcon />}
                            _focus={{
                              outline: 'none',
                            }}
                            color={isOpen || searchState.searchValues[fieldDef.name] ? 'accent.1' : 'inherit'}
                          />
                        </PopoverTrigger>
                        <PopoverContent
                          borderColor="neutral.5"
                          borderRadius="8px"
                          height="52px"
                          width="208px"
                          _focus={{
                            outline: 'none',
                          }}
                        >
                          <PopoverArrow borderColor="neutral.5" />
                          <PopoverBody padding="8px 12px" color="neutral.1">
                            <InputGroup>
                              <Input
                                placeholder="Search"
                                border="none"
                                backgroundColor="card.default"
                                _focus={{
                                  border: 'none',
                                }}
                                value={searchState.searchArr.find(item => item.colName === fieldDef.name)?.searchValue}
                                onChange={e => handleSearch(e, fieldDef.name)}
                              />
                              <InputRightElement>
                                <SearchIcon />
                              </InputRightElement>
                            </InputGroup>
                          </PopoverBody>
                        </PopoverContent>
                      </>
                    )}
                  </Popover>
                ),
              })}
              dataIndex={fieldDef.name}
              key={fieldDef.name}
              sorter={sorter}
              onFilter={onFilter}
              filters={filters}
              render={lookupRenderer(
                entityDefinition,
                fieldDef,
                referencedEntities,
                setActiveEntityid,
                setShowEditModel,
                dispatch
              )}
            />
          );
        })}
    </Table>
  );
};

export default EntityTable;
