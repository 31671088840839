import { CONFIGS_FETCHED, CONFIG_ADDED, CONFIG_EDIT, CONFIG_REMOVED } from '../actions/actions_config';

const configs = (configs = [], action) => {
  let newConfigs = null;
  switch (action.type) {
    case CONFIGS_FETCHED:
      return action.payload;
    case CONFIG_ADDED:
      newConfigs = JSON.parse(JSON.stringify(configs));
      newConfigs.push(action.payload);
      return newConfigs;
    case CONFIG_EDIT:
      newConfigs = JSON.parse(JSON.stringify(configs));
      return replaceConfig(newConfigs, action.payload);
    case CONFIG_REMOVED:
      newConfigs = JSON.parse(JSON.stringify(configs));
      return removeConfig(newConfigs, action.payload);
    default:
      return configs;
  }
};

function replaceConfig(allConfigs, editedConfig) {
  const newConfigs = [];
  allConfigs.forEach(function (element) {
    if (element.id === editedConfig.id) {
      newConfigs.push(editedConfig);
    } else {
      newConfigs.push(element);
    }
  });
  return newConfigs;
}

function removeConfig(allConfigs, removedConfig) {
  const newConfigs = [];
  allConfigs.forEach(function (element) {
    if (element.id !== removedConfig.id) {
      newConfigs.push(element);
    }
  });
  return newConfigs;
}

export const selectConfigs = state => {
  return state.configs;
}

export default configs;
