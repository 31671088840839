import React from 'react';
import POQuickFilters from './POQuickFilters';
import POMultipleFilters from './POMultipleFilters';
import { Button } from '@chakra-ui/react';

const PackerFilters = props => {
  return (
    <div>
      <POQuickFilters search={props.search} />
      <hr />
      <POMultipleFilters onFilterChange={props.onFilterChange} search={props.search} filters={props.filters} />
      <div className="row" style={{ marginTop: '10px' }}>
        <div className="col-sm-12">
          <Button mt="27px" colorScheme="actionSecondary" className="pull-right" onClick={this.submitSearch.bind(this)}>
            Submit Search
          </Button>
          <Button
            mt="27px"
            mr="10px"
            colorScheme="actionPrimary"
            className="pull-right"
            onClick={this.resetSearch.bind(this)}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PackerFilters;
