import { createSlice } from '@reduxjs/toolkit';
import {
  deleteStrategyRequest,
  fetchStrategiesRequest,
  patchStrategyRequest,
  postStrategyRequest,
} from '../../data-fixtures/strategies';
import { removeObjectFromArray, replaceObjectInArray } from '../../helpers';

const initialState = { strategies: [] };

const strategiesSlice = createSlice({
  name: 'strategies',
  initialState,
  reducers: {
    setStrategies: (state, { payload }) => payload,
    addStrategy: (state, { payload }) => {
      state.push(payload);
    },
    updateStrategy: (state, { payload }) => {
      replaceObjectInArray(state.strategies, payload);
    },
    deleteStrategy: (state, { payload }) => {
      removeObjectFromArray(state.strategies, payload);
    },
  },
});

export const { setStrategies, addStrategy, updateStrategy, deleteStrategy } = strategiesSlice.actions;

// Basic selectors
export const selectStrategies = state => {
  return state.strategies.data;
};

// Thunks
export const fetchStrategies = () => {
  return async dispatch => {
    try {
      const strategies = await fetchStrategiesRequest();
      dispatch(setStrategies(strategies));
      return strategies;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export const postStrategy = strategy => {
  return async dispatch => {
    try {
      const newStrategy = await postStrategyRequest(strategy);
      dispatch(addStrategy(newStrategy));
      return newStrategy;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export const patchStrategy = strategy => {
  return async dispatch => {
    try {
      const updatedStrategy = await patchStrategyRequest(strategy);
      dispatch(updateStrategy(updatedStrategy));
      return updatedStrategy;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export const handleDeleteStrategy = strategy => {
  return async dispatch => {
    try {
      await deleteStrategyRequest(strategy);
      dispatch(deleteStrategy(strategy));
      return strategy;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export default strategiesSlice.reducer;
