import { Col, Layout, Result, Row, Card, Typography } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTreasuryData, fetchTreasuryAnnotations } from '../../../slices/treasury/treasuryReducers';
import {
  selectTreasuryError,
  selectTreasuryFetchParameters,
  selectTreasuryLoading,
  selectTreasuryAnnotationFetchParameters,
} from '../../../slices/treasury/selectors/selectTreasuryBasicData';
import TreasuryDetailsFilters from './TreasuryDetailsFilters';
import Charts from '../../../components/analyticDashboard/treasuryDetails/Charts/Charts';
import LoadingOverlay from '../../../shared/LoadingOverlay/LoadingOverlay';
import TabBar from '../../../shared/TabBar';

// This container is for the treasury page as a whole and manages data
export default function TreasuryDetails() {
  const dispatch = useDispatch();
  const error = useSelector(selectTreasuryError);
  const loading = useSelector(selectTreasuryLoading);

  // Parameters for data fetch
  const dataFetchParameters = useSelector(selectTreasuryFetchParameters);
  React.useEffect(() => {
    dispatch(fetchTreasuryData(dataFetchParameters));
  }, [dispatch, dataFetchParameters]);

  React.useEffect(() => {
    dispatch(fetchTreasuryAnnotations());
  }, [dispatch]);

  if (error) {
    return (
      <div>
        <Result title="Error" status="error" subTitle={error} />
      </div>
    );
  }

  return (
    <div className="analytic-dashboard-container" style={{ position: 'relative' }}>
      <TabBar />
      <Layout>
        <Layout.Content>
          <Row gutter={[16, 16]}>
            <Typography.Title level={3}>Treasury Details</Typography.Title>
            <Col span={24}>
              <TreasuryDetailsFilters />
            </Col>
            <Col span={24}>
              <Card>
                <Charts />
              </Card>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
      <LoadingOverlay loading={loading} />
    </div>
  );
}
