const schema = [
  {
    name: 'package_weights',
    displayName: 'Package Weight',
    systemIdField: 'id',
    displayIdField: 'name',
    deletable: true,
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    path: '/package_weights',
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
        excludePayload: true,
      },
      {
        name: 'input_product_uid',
        displayName: 'Product',
        tooltip: 'The product associated with this package weight.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'input_product',
        filterable: true,
      },
      {
        name: 'packer_plant_id',
        displayName: 'Establishment',
        tooltip: 'Select the estabilishment of the package weight',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'packer_plant',
        filterable: true,
      },
      {
        name: 'unit_of_measure_id',
        displayName: 'Unit Of Measure',
        tooltip: 'The measurement unit used for the weight (e.g., BIN, CTN, KGS, LBS).',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'unit',
        required: true,
      },
      {
        name: 'weight',
        displayName: 'Weight',
        tooltip: 'The actual weight of the package',
        dataType: 'number',
        required: true,
      },
    ],
  },
];

export default schema;
