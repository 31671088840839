import React, { useEffect, useState } from 'react';
import { Box, Text, UnorderedList, ListItem, Link, IconButton } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import UpdateExtrnalAudit from './UpdateExtrnalAudit';
import { EditIcon } from '@chakra-ui/icons';
import { Tooltip } from 'antd';
import { previewFromS3Link } from '../../../helpers/downloads';

const ExternalAudit = ({ data, estId, updateList }) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    if (estId && data && data.length > 0) {
      const promises = data.map(async item => {
        const previewUrl = await previewFromS3Link(item.filepath);
        return { ...item, previewUrl };
      });

      Promise.all(promises).then(res => {
        setLinks(res);
      });
    }
  }, [estId, data]);

  return (
    <Box marginBottom="15px">
      <Box display="flex" justifyContent="space-between">
        <Text color="rgba(0, 0, 0, 0.87)" fontSize="14px" fontWeight="600" lineHeight="1.43" marginBottom="15px">
          3rd Party Food Safety Certificate
        </Text>
        <Tooltip title="Update 3rd Party Food Safety Certificate">
          <IconButton variant="solid" colorScheme="actionSecondary" onClick={() => setIsOpenPopup(true)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {links && links?.length > 0 && (
        <UnorderedList fontSize="14px" marginBottom="10px" color="#000000B2" style={{ columnCount: 2 }}>
          {links?.map(externalAuditLink => {
            return externalAuditLink?.previewUrl || externalAuditLink?.filepath ? (
              <ListItem marginBottom="10px" paddingRight="10px" key={externalAuditLink?.title}>
                <Link
                  href={externalAuditLink?.previewUrl || externalAuditLink?.filepath}
                  marginLeft="4px"
                  color="#3A44B0"
                  textDecoration="underline"
                  _hover={{
                    color: '#3A44B0',
                    textDecoration: 'underline',
                  }}
                  target="_blank"
                >
                  {externalAuditLink?.title}
                </Link>
              </ListItem>
            ) : null;
          })}
        </UnorderedList>
      )}

      {isOpenPopup && (
        <UpdateExtrnalAudit
          isOpenPopup={isOpenPopup}
          setIsOpenPopup={setIsOpenPopup}
          editItem={data}
          estId={estId}
          updateList={updateList}
        />
      )}
    </Box>
  );
};

ExternalAudit.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      filepath: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  estId: PropTypes.number,
  updateList: PropTypes.func,
};

export default ExternalAudit;
