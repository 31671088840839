const schema = [
  {
    name: 'packer_plant',
    displayName: 'Establishment',
    systemIdField: 'id',
    displayIdField: 'name',
    path: '/packer_plants',
    tabGroup: { key: 'suppliers', label: 'Suppliers' },
    deletable: false,
    autoUid: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        tooltip: 'Provide the Unique ID to create the Establishment',
        dataType: 'shorttext',
        required: false,
        systemIdentifier: true,
        filterable: true,
      },
      {
        name: 'packer_id',
        displayName: 'Packer ID',
        tooltip: 'Provide the Packer ID to create the Establishment',
        dataType: 'shorttext',
        required: false,
        systemIdentifier: false,
        filterable: true,
      },
      {
        name: 'est',
        displayName: 'Establishment Number',
        tooltip: 'Provide the Establishment number',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'Provide the Establishment Name',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'origin_country',
        displayName: 'Origin Country',
        tooltip: 'Select the country where the Establishment is located',
        dataType: 'simple-reference',
        referenceEntityType: 'country',
        required: true,
      },
      {
        name: 'purchasing_office',
        displayName: 'Purchasing Office',
        tooltip: 'Select the Purchasing Office of the Establishment',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'business_office',
      },
      {
        name: 'preferred_unit_id',
        displayName: 'Default Buy UOM',
        tooltip: 'Select the Default Buy Unit of Measure (UOM) of the Establishment',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'unit',
      },
      {
        name: 'default_buy_incoterms',
        displayName: 'Default Buy Incoterms',
        tooltip: 'Select the Default Buy Incoterms of the Establishment',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'incoterms',
      },
      {
        name: 'default_buy_currency',
        displayName: 'Default Buy Currency',
        tooltip: 'Select the Default Buy Currency of the Establishment',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'currency',
      },
      {
        name: 'product_type',
        displayName: 'Product Type',
        tooltip: 'Select the Product type of the Establishment, either Beef or Pork',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'product_types',
        required: true,
      },
      {
        name: 'audit_system_supplier_id',
        displayName: 'Audit Sytem Supplier',
        tooltip: 'Select the Audit System Supplier of the Establishment',
        dataType: 'integer',
      },
      {
        name: 'organisation_ids',
        displayName: 'Organisation',
        tooltip: 'Select the Organisation associated with the Establishment',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'organisation',
        required: true,
      },
      {
        name: 'address',
        displayName: 'Address',
        dataType: 'reference',
        referenceType: 'nested',
        referenceEntityType: 'address_port',
        hideList: true,
      },
      {
        name: 'lat',
        displayName: 'Lat',
        dataType: 'number',
        hideList: true,
      },
      {
        name: 'lon',
        displayName: 'Lon',
        dataType: 'number',
        hideList: true,
      },
      {
        name: 'timezone',
        displayName: 'Timezone',
        dataType: 'shorttext',
        hideList: true,
        required: false,
      },
      {
        name: 'contacts',
        displayName: 'Contacts',
        dataType: 'reference',
        referenceType: 'multi-nested',
        referenceEntityType: 'contact',
      },
      {
        name: 'delisted',
        displayName: 'Is Delisted',
        dataType: 'boolean',
        hideList: true,
        required: true,
      },
    ],
  },
];
export default schema;
