const schema = [
  {
    name: 'ncr_type',
    displayName: 'NCR Type',
    nested: false,
    systemIdField: 'id',
    displayIdField: 'name',
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    deletable: true,
    path: '/non-conformance-types',
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'The name or description of the Non-Conformance Report (NCR) type.',
        dataType: 'shorttext',
        required: true,
        systemIdentifier: false,
      }
    ],
  },
];
export default schema;
