import { createSelector } from '@reduxjs/toolkit';
import { selectPackerPlants } from '../../quality-packer-plants/selectors/selectBasicPackerPlants';
import { selectQualityData, selectQualityPackerPlant } from './selectBasicQualityData';
import { getTwoPeriods } from '../../../modules/data/year-on-year';

export const selectQualityFreshnessDays = createSelector(
  selectQualityData,
  selectQualityPackerPlant,
  selectPackerPlants,
  (data, selectedPackerPlant, packerPlants) => {
    if (!data || !selectedPackerPlant || !packerPlants) return null;
    const freshnessDays = data['freshness-days'];
    const twoPeriods = getTwoPeriods(freshnessDays.byPeriod);

    const packerPlantsWithFreshnessDays = freshnessDays.packerPlants;

    const pc = selectedPackerPlant.isPacker
      ? packerPlants.filter(x => x.isPartOf === selectedPackerPlant.id)
      : packerPlants;

    const packerPlantsNotInSpec =
      packerPlantsWithFreshnessDays &&
      packerPlantsWithFreshnessDays.packerPlantsNotInSpec.map(x => {
        const name = pc.find(p => p.id === x.packerPlantId)?.name;
        return {
          ...x,
          packerPlantName: name,
        };
      });

    const totalCount =
      packerPlantsWithFreshnessDays &&
      packerPlantsWithFreshnessDays.countInSpec + packerPlantsWithFreshnessDays.packerPlantsNotInSpec.length;

    return {
      ...freshnessDays,
      byPeriod: twoPeriods,
      packerPlants: freshnessDays.packerPlants
        ? {
            ...packerPlantsWithFreshnessDays,
            packerPlantsNotInSpec,
            totalCount,
          }
        : undefined,
    };
  }
);
