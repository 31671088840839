import {
  FINANCE_FACTORINGS_FETCHED,
  FINANCE_FACTORING_UPDATED,
  FINANCE_FACTORING_REMOVED,
  FINANCE_FACTORING_ADDED,
  FINANCE_FACTORING_ERROR,
} from '../actions/actions_finance_factorings';

const finance_factorings = (states = [], action) => {
  switch (action.type) {
    case FINANCE_FACTORINGS_FETCHED:
      return action.payload;
    case FINANCE_FACTORING_ADDED:
      return [...states, action.payload];
    case FINANCE_FACTORING_UPDATED:
      return [...states.filter(item => item.id !== action.payload.id), action.payload];
    case FINANCE_FACTORING_REMOVED:
      return [...states.filter(item => item.id !== action.payload.id)];
    default:
      return states;
  }
};

export default finance_factorings;
