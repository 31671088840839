const schema = [
  {
    name: 'po_series',
    displayName: 'PO Series',
    path: '/po_series',
    shouldNotPluralize: true,
    nested: false,
    systemIdField: 'id',
    displayIdField: 'id',
    tabGroup: { key: 'grinders', label: 'Grinders' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'po_number_prefix',
        displayName: 'PO Number Prefix',
        tooltip: 'This prefix will be added to the beginning of each new PO number in the series',
        dataType: 'shorttext',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'last_po_number',
        displayName: 'Last PO Number',
        tooltip: 'Specify the last Purchase Order number that was generated',
        dataType: 'integer',
        required: true,
        systemIdentifier: false,
      },
    ],
  },
];
export default schema;
