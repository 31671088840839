// Column configs for order export report

export const columns = [
  { Grinder: 'grinder.name' },
  { 'Grinder PO#': 'order.grinder_po_number' },
  { 'FMG PO#': 'order.internal_po_number' },
  { Establishment: 'packer_plant.name' },
  { 'Establishment ID': 'packer_plant.est' },
  { 'Packer SO#': 'order.packer_so_number' },
  { Status: 'line.status' },
  { IsActive: 'line.active' },
  { 'Delivery Date': 'order.delivery_date' },
  { Temp: 'line.temp' },
  { Product: 'line.product.name' },
  { 'Product Description': 'line.input_product.description' },
  { CL: 'line.product.cl' },
  { Description: 'line.description' },
  { Specification: 'line.specification' },
  { 'Quantity Buy': 'line.buy_quantity' },
  { 'Buy UOM': 'order.buy_unit_of_measure.name' },
  { 'Buy Price Per Unit': 'line.buy_price_per_unit' },
  { 'Buy Currency': 'order.buy_currency' },
  { 'Quantity Sell': 'line.sell_quantity' },
  { 'Sell UOM': 'order.sell_unit_of_measure.name' },
  { 'COS Price Per Unit': 'line.cos_price_per_unit' },
  { 'Sell Price Per Unit': 'line.sell_price_per_unit' },
  { 'Sell Currency': 'order.sell_currency' },
  { 'Received Quantity': 'line.recv_quantity' },
  { 'Remaining Weight': 'line.remaining_weight' },
  { 'Internal Comments': 'order.internal_comments' },
  { 'External Comments': 'order.external_comments' },
  { Container: 'order.load_container_details' },
  { 'Buy Incoterms': 'order.buy_incoterms' },
  { 'Sell Incoterms': 'order.sell_incoterms' },
  { Halal: 'order.halal' },
  { MTC: 'order.mtc' },
  { 'Delivery Time': 'order.scheduled_delivery_time' },
  { 'Transport Cost': 'order.transport_cost' },
  { 'Date Type': 'dateType.type' },
  { 'Shipment/Arrival Start Date': 'dateType.start_date' },
  { 'Shipment/arrival End Date': 'dateType.end_date' },
  { 'Preferred ETA': 'order.preferred_eta' },
  { 'Expected Production Date': 'line.expected_production_date' },
  { 'Final Destination': 'order.final_destination' },
  { 'Vessel Name': 'order.voyage[0].vessel_name' },
  { 'Voyage Number': 'order.voyage[0].voyage_number' },
  { 'Shipping Line': 'order.voyage[0].shipping_line.name' },
  { 'Port of Loading': 'order.voyage[0].load_port.name' },
  { ETD: 'order.voyage[0].etd' },
  { 'Port of Discharge': 'order.voyage[0].discharge_port.name' },
  { ETA: 'order.voyage[0].eta' },
  { 'Tranship Port': 'order.voyage[0].tranship_port.name' },
  { 'Booking Reference #': 'order.bill_of_lading.booking_reference_number' },
  { EDN: 'order.bill_of_lading.export_declaration_number' },
  { 'Container #': 'order.bill_of_lading.container_number' },
  { 'Container Temp': 'order.bill_of_lading.container_temperature' },
  { 'Seal #': 'order.bill_of_lading.seal_number' },
  { 'Bill of Lading #': 'order.bill_of_lading.bol_number' },
  { 'Health Certificate #': 'order.export_details.health_certificate_number' },
  { ISF: 'order.export_details.isf' },
  { 'Permit #': 'order.export_details.permit_number' },
  { 'Container Depot': 'order.export_details.container_depot' },
  { 'Container Return': 'order.export_details.container_return' },
  { 'Container Gate in': 'order.export_details.container_gate_in_date' },
  { 'Coldstore Name': 'coldstore_name' },
  { 'Gross Shipped Weight': 'order.bill_of_lading.gross_shipped_weight' },
  { 'Customs Cleared Date': 'order.coldstore_details.customed_cleared_date' },
  { 'Inspection Date': 'order.coldstore_details.inspection_date' },
];

export const columns_poc = [
  { 'Grinder': 'grinder' },
  { 'Grinder PO#': 'grinder_po' },
  { 'Internal PO Label': 'fmg_po' },
  { Establishment: 'lines[0].line.establishment' },
  { 'Establishment Id': 'lines[0].line.establishment_id' },
  { 'Packer SO#': 'vendor_so' },
  { 'Status': 'lines[0].line.status' },
  { 'IsActive': 'lines[0].line.active' },
  { 'Date of Delivery': 'delivery_date' },
  { 'Temperature': 'lines[0].line.temp' },
  { 'Product': 'lines[0].line.product' },
  { 'Product Description': 'lines[0].line.product_description' },
  { 'CL': 'lines[0].line.cl' },
  { 'Description': 'lines[0].line.line_desc' },
  { 'Specification': 'lines[0].line.specification' },
  { 'Quantity Buy': 'lines[0].line.quantity' },
  { 'Buy UOM': 'buy_uom' },
  { 'Buy Price Per Unit': 'lines[0].line.price_per_unit' },
  { 'Buy Currency': 'buy_currency' },
  { 'Quantity Sell': 'lines[0].line.sell_quantity' },
  { 'Sell UOM': 'sell_uom' },
  { 'COS Price Per Unit': 'lines[0].line.cos_price' },
  { 'Sell Price Per Unit': 'lines[0].line.sell_price' },
  { 'Sell Currency': 'sell_currency' },
  { 'Received Quantity': 'lines[0].line.recv_quantity' },
  { 'Remaining Weight': 'lines[0].line.remaining_weight' },
  { 'Internal comments': 'internal_comments' },
  { 'External comments': 'external_comments' },
  { 'Container': 'container' },
  { 'Buy Incoterms': 'buy_inco' },
  { 'Sell Incoterms': 'sell_inco' },
  { 'Halal': 'halal' },
  { 'MTC': 'mtc' },
  { 'Delivery Time': 'delivery_time' },
  { 'Transport Cost': 'transport_cost' },
  { 'Date Type': 'lines[0].line.date_type' },
  { 'Shipment/Arrival Start Date': 'lines[0].line.start_date' },
  { 'Shipment/Arrival End Date': 'lines[0].line.end_date' },
  { 'Preferred ETA': 'preferred_eta' },
  { 'Expected Production Date': 'lines[0].line.expected_date' },
  {'Final Destination': 'final_dest'},
  {'Vessel Name': 'voyages[0].voyage.vessel'},
  {'Voyage Number': 'voyages[0].voyage.voyage_num'},
  {'Shipping Line': 'voyages[0].voyage.shipping_line'},
  { 'Port of Loading': 'voyages[0].voyage.port_of_loading' },
  { 'ETD': 'voyages[0].voyage.etd' },
  { 'Port of Discharge': 'voyages[0].voyage.port_of_discharge' },
  { 'ETA': 'voyages[0].voyage.eta' },
  { 'Tranship Port': 'voyages[0].voyage.tranship_port' },
  { 'Booking Reference #': 'booking_ref' },
  { 'Booking Reference #': 'booking_ref' },
  { 'EDN': 'export_num' },
  { 'Container #': 'container_num' },
  { 'Container Temperature': 'container_temp' },
  { 'Sel #': 'seal_num' },
  { 'Bill of Lading #': 'bol_number' },
  { 'Health Certificate #': 'health_cert' },
  { 'ISF': 'isf' },
  { 'Permit #': 'permit_num' },
  { 'Container Depot': 'depot' },
  { 'Container Return': 'container_return' },
  { 'Container Gate in': 'container_gate' },
  { 'Coldstore Name': 'coldstore' },
  { 'Gross Shipped Weight': 'shipped_weight' },
  { 'Weight': 'depot' },  
];
