import Constants from '../Constants';

export const BEEF_OUTPUT_INVENTORY_ITEMS_FETCHED = 'BEEF_OUTPUT_INVENTORY_ITEMS_FETCHED';
export const BEEF_OUTPUT_INVENTORY_ITEMS_ADDED = 'BEEF_OUTPUT_INVENTORY_ITEMS_ADDED';
export const BEEF_OUTPUT_INVENTORY_ITEMS_ERROR = 'BEEF_OUTPUT_INVENTORY_ITEMS_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadBeefOutputInventoryItem(grinderInventoryItems) {
  return {
    type: BEEF_OUTPUT_INVENTORY_ITEMS_FETCHED,
    payload: grinderInventoryItems,
  };
}

export function addBeefOutputInventoryItem(grinderInventoryItem) {
  return {
    type: BEEF_OUTPUT_INVENTORY_ITEMS_ADDED,
    payload: grinderInventoryItem,
  };
}

export function errorBeefOutputInventoryItems(error) {
  return {
    type: BEEF_OUTPUT_INVENTORY_ITEMS_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchBeefOutputInventoryItems(grinder, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}inventory/grinders/${grinder.uid}/output_product_inventory_items`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorBeefOutputInventoryItems))
      .then(json => dispatch(loadBeefOutputInventoryItem(json)))
      .catch(error => dispatch(errorBeefOutputInventoryItems(error)));
  };
}

export function postBeefOutputInventoryItem(grinder, beefProduct, increment, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(
      `${Constants.URL}inventory/grinders/${grinder.uid}/output_product_inventory_items/${beefProduct.beef_product_id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({ increment_quantity: increment }),
        headers: header,
      }
    )
      .then(response => Constants.handleErrors(response, dispatch, errorBeefOutputInventoryItems))
      .then(json => dispatch(addBeefOutputInventoryItem(json)))
      .catch(error => dispatch(errorBeefOutputInventoryItems(error)));
  };
}
