import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const getUserColor = name => {
  const colors = ['#4376F9', '#3D9FA1', '#FEB00A', '#F94343', '#6543F9', '#43F98C', '#F943E7', '#00FF00', '#FF00FF'];
  const charCode = name.charCodeAt(0);
  return colors[charCode % colors.length];
};

const ContactBedge = ({ name } = { name: '' }) => {
  const getInitials = _name => _name[0];

  return (
    <Flex alignItems="center">
      <Box
        width="24px"
        height="24px"
        flexShrink="0"
        backgroundColor={getUserColor(name)}
        borderRadius="50%"
        marginRight="10px"
        textAlign="center"
        lineHeight="24px"
        fontWeight="400"
        textColor="#fff"
      >
        {getInitials(name)}
      </Box>
      <Text as="p">{name}</Text>
    </Flex>
  );
};

ContactBedge.propTypes = {
  name: PropTypes.string,
};

export default ContactBedge;
