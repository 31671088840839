import { createSelector } from '@reduxjs/toolkit';
import { selectPackerPlants } from '../../quality-packer-plants/selectors/selectBasicPackerPlants';
import { selectQualityData } from './selectBasicQualityData';

export const selectQualityAudit = createSelector(selectQualityData, selectPackerPlants, (data, packerPlants) => {
  if (!data || !packerPlants) return null;

  const { audits } = data;

  if (!audits) return undefined;

  const performedAudits = audits.performed
    .filter(x => x.type !== 'SWA')
    .map(x => {
      return {
        ...x,
        completed: {
          total: x.completed.total || '-',
          onTime: x.completed.onTime || '-',
        },
        performance: {
          drop: x.performance.drop || '-',
          increase: x.performance.increase || '-',
        },
        scoreSummary: {
          approved: x.scoreSummary.approved || '-',
          provisional: x.scoreSummary.provisional || '-',
          failed: x.scoreSummary.failed || '-',
        },
        delists: x.delists || '-',
      };
    });

  const swa = audits.performed.find(x => x.type === 'SWA');
  let swaAudit;
  if (swa) {
    swaAudit = {
      ...swa,
      selfAssessment: {
        submitted: swa.selfAssessment.submitted || '-',
        onTime: swa.selfAssessment.onTime || '-',
        outstanding: swa.selfAssessment.outstanding || '-',
      },
      inspection: {
        pass: swa.inspection.pass || '-',
        provisional: swa.inspection.provisional || '-',
        reAudit: swa.inspection.reAudit || '-',
        failed: swa.inspection.failed || '-',
      },
      delists: swa.delists || '-',
    };
  }

  const upcomingAudits =
    audits.upcoming &&
    audits.upcoming.map(u => {
      const byPackerPlant = u.byPackerPlant.map(x => {
        const name = packerPlants.find(p => p.id === x.packerPlantId)?.name;
        return {
          ...x,
          packerPlantName: name,
        };
      });
      return {
        ...u,
        byPackerPlant,
      };
    });

  return {
    performed: performedAudits,
    swa: swaAudit,
    upcoming: upcomingAudits,
  };
});
