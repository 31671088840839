import {
  SSR_REPORT_CATEGORIES,
  SSR_REPORT_CONFIG_FETCHED,
  SSR_REPORT_DATA_FETCHED,
  SSR_REPORT_DELETED_STATUS,
  SSR_ERROR,
} from '../actions/actions_self_service_reports';

const selfServiceReportConfig = (state = { config: {} }, action) => {
  switch (action.type) {
    case SSR_REPORT_CONFIG_FETCHED:
      return { ...state, config: action.payload };
    case SSR_REPORT_DATA_FETCHED:
      return { ...state, reportsData: action.payload, error: null };
    case SSR_REPORT_CATEGORIES:
      return { ...state, categories: action.payload };
    case SSR_REPORT_DELETED_STATUS:
      return { reportDeleted: action.payload };
    case SSR_ERROR:
      return { ...state, error: action.payload };
    default:
      return { ...state, reportDeleted: '' };
  }
};

export default selfServiceReportConfig;
