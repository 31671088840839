import { Col } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths, subYears } from 'date-fns';
import FilterFrequency from '../../../../components/analyticDashboard/qualityDetails/shared/FilterFrequency/FilterFrequency';
import FilterSelectGeneric from '../../../../components/analyticDashboard/qualityDetails/shared/FilterSelectGeneric/FilterSelectGeneric';
import FilterTimePeriod from '../../../../components/analyticDashboard/qualityDetails/shared/FilterTimePeriod/FilterTimePeriod';
import { MARKETS } from '../../../../config/markets';
import {
  BREAKDOWN_OPTIONS,
  CLAIM_DOLLARS_OPTIONS,
  INCIDENTS_OPTIONS,
  TIME_RANGE_OPTIONS,
  VOLUME_OPTIONS,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsConfig';
import { FREQUENCY } from '../../../../slices/quality/qualityConfig';
import {
  setQualityNCRsActivePackerPlants,
  setQualityNCRsBreakdownMode,
  setQualityNCRsClaimDollars,
  setQualityNCRsIncidents,
  setQualityNCRsTimeRange,
  setQualityNCRsVolume,
  updateQualityNCRsFrequency,
  updateQualityNCRsRegion,
  updateQualityNCRsGrinder,
  updateQualityNCRsTimePeriod,
  updateQualityNCRsTimeRange,
  updateQualityNCRsClaimDollars,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsReducers';
import {
  selectQualityNCRsClaimDollars,
  selectQualityNCRsFrequency,
  selectQualityNCRsIncidents,
  selectQualityNCRsMarket,
  selectQualityNCRsRegion,
  selectQualityNCRsTimePeriod,
  selectQualityNCRsTimeRange,
  selectQualityNCRsVolume,
  selectQualityNCRsGrinder,
} from '../../../../slices/quality-details-ncrs/selectors/selectBasicDetailsNcrsData';
import { selectQualityGrinderOptions } from '../../../../slices/quality/selectors/selectQualityGrinderOptions';

export default function QualityDetailsNCRsFilters() {
  const market = useSelector(selectQualityNCRsMarket);
  const region = useSelector(selectQualityNCRsRegion);
  const incidentsMode = useSelector(selectQualityNCRsIncidents);
  const volumeMode = useSelector(selectQualityNCRsVolume);
  const claimDollarsMode = useSelector(selectQualityNCRsClaimDollars);
  const timeRange = useSelector(selectQualityNCRsTimeRange);
  const timePeriod = useSelector(selectQualityNCRsTimePeriod);
  const frequency = useSelector(selectQualityNCRsFrequency);
  const selectedGrinder = useSelector(selectQualityNCRsGrinder);
  const grinderOptions = useSelector(selectQualityGrinderOptions);
  const optionLabels = {};
  grinderOptions.forEach(g => {
    optionLabels[g.id] = g.name;
  });

  const dispatch = useDispatch();

  const changeRegion = React.useCallback(r => dispatch(updateQualityNCRsRegion(r)), [dispatch]);
  const changeGrinder = React.useCallback(g => dispatch(updateQualityNCRsGrinder(g)), [dispatch]);
  const changeIncidentsMode = React.useCallback(
    mode => {
      dispatch(setQualityNCRsActivePackerPlants([undefined]));
      dispatch(setQualityNCRsIncidents(mode));
      if (!(mode === INCIDENTS_OPTIONS.TOTAL || mode === null)) {
        dispatch(setQualityNCRsTimeRange(TIME_RANGE_OPTIONS.HISTORICAL));
      }
      if (mode === INCIDENTS_OPTIONS.TOTAL_WITH_BREAKDOWN) {
        dispatch(setQualityNCRsVolume(null));
        dispatch(setQualityNCRsClaimDollars(null));
        dispatch(setQualityNCRsBreakdownMode(BREAKDOWN_OPTIONS.PACKER_PLANT));
      } else {
        dispatch(setQualityNCRsBreakdownMode(null));
      }
    },
    [dispatch]
  );
  const changeVolumeMode = React.useCallback(mode => dispatch(setQualityNCRsVolume(mode)), [dispatch]);
  const changeClaimDollarsMode = React.useCallback(mode => dispatch(updateQualityNCRsClaimDollars(mode)), [dispatch]);
  const changeTimeRange = React.useCallback(
    tRange => {
      dispatch(setQualityNCRsActivePackerPlants([undefined]));
      const now = new Date();
      if (tRange === TIME_RANGE_OPTIONS.YOY) {
        dispatch(updateQualityNCRsFrequency(FREQUENCY.MONTHLY));
        dispatch(updateQualityNCRsTimePeriod([startOfYear(subYears(now, 3)), endOfYear(now)]));
      } else {
        dispatch(updateQualityNCRsTimePeriod([startOfMonth(subMonths(now, 12)), endOfMonth(now)]));
      }
      dispatch(updateQualityNCRsTimeRange(tRange));
    },
    [dispatch, TIME_RANGE_OPTIONS, FREQUENCY]
  );
  const changeTimePeriod = React.useCallback(tPeriod => dispatch(updateQualityNCRsTimePeriod(tPeriod)), [dispatch]);
  const changeFrequency = React.useCallback(
    f => {
      dispatch(updateQualityNCRsFrequency(f));
      const now = new Date();
      if (f === FREQUENCY.ANNUALLY) {
        dispatch(updateQualityNCRsTimePeriod([startOfYear(subYears(now, 3)), endOfYear(now)]));
      } else {
        dispatch(updateQualityNCRsTimePeriod([startOfMonth(subMonths(now, 12)), endOfMonth(now)]));
      }
    },
    [dispatch, FREQUENCY]
  );

  React.useEffect(() => {
    if (incidentsMode !== INCIDENTS_OPTIONS.BY_MARKET && volumeMode === VOLUME_OPTIONS[market.key].PERCENT_OF_MARKET) {
      changeVolumeMode(VOLUME_OPTIONS[market.key].PERCENT_OF_TOTAL);
    }
  }, [incidentsMode, volumeMode]);

  React.useEffect(() => {
    changeClaimDollarsMode(market.currency);
  }, [market, MARKETS, changeClaimDollarsMode]);

  return (
    <>
      {market.includedCountries && market.includedCountries.length && (
        <Col span={3}>
          <FilterSelectGeneric
            value={region}
            onChange={changeRegion}
            label="Region"
            options={['All', ...market.includedCountries]}
          />
        </Col>
      )}
      <Col span={3}>
        <FilterSelectGeneric
          value={selectedGrinder}
          onChange={changeGrinder}
          label="Grinder"
          options={grinderOptions.map(g => g.id)}
          optionLabels={optionLabels}
        />
      </Col>
      <Col span={3}>
        <FilterSelectGeneric
          value={incidentsMode}
          onChange={changeIncidentsMode}
          label="Incidents"
          options={[
            INCIDENTS_OPTIONS.TOTAL,
            INCIDENTS_OPTIONS.TOTAL_WITH_BREAKDOWN,
            INCIDENTS_OPTIONS.BY_CATEGORY,
            INCIDENTS_OPTIONS.BY_SUBCATEGORY,
            INCIDENTS_OPTIONS.BY_MARKET,
          ]}
          includeNullOption
        />
      </Col>
      <Col span={3}>
        <FilterSelectGeneric
          value={volumeMode}
          onChange={changeVolumeMode}
          label="Volume"
          isDisabled={incidentsMode === INCIDENTS_OPTIONS.TOTAL_WITH_BREAKDOWN}
          options={
            incidentsMode === INCIDENTS_OPTIONS.BY_MARKET
              ? [
                  VOLUME_OPTIONS[market.key].PERCENT_OF_TOTAL,
                  VOLUME_OPTIONS[market.key].INCIDENTS_RATE,
                  VOLUME_OPTIONS[market.key].PERCENT_OF_MARKET,
                  VOLUME_OPTIONS[market.key].WEIGHT,
                ]
              : [
                  VOLUME_OPTIONS[market.key].PERCENT_OF_TOTAL,
                  VOLUME_OPTIONS[market.key].INCIDENTS_RATE,
                  VOLUME_OPTIONS[market.key].WEIGHT,
                ]
          }
          includeNullOption
        />
      </Col>
      <Col span={3}>
        <FilterSelectGeneric
          value={claimDollarsMode}
          onChange={changeClaimDollarsMode}
          label="Claim dollars"
          isDisabled={incidentsMode === INCIDENTS_OPTIONS.TOTAL_WITH_BREAKDOWN}
          options={CLAIM_DOLLARS_OPTIONS}
          includeNullOption
        />
      </Col>
      <Col span={3}>
        <FilterSelectGeneric
          value={timeRange}
          onChange={changeTimeRange}
          label="Time range"
          isDisabled={!(incidentsMode === INCIDENTS_OPTIONS.TOTAL || incidentsMode === null)}
          options={[TIME_RANGE_OPTIONS.HISTORICAL, TIME_RANGE_OPTIONS.YOY]}
        />
      </Col>
      {timeRange === TIME_RANGE_OPTIONS.HISTORICAL && (
        <>
          <Col span={4}>
            <FilterTimePeriod value={timePeriod} onChange={changeTimePeriod} frequency={frequency} />
          </Col>
          <Col span={3}>
            <FilterFrequency value={frequency} onChange={changeFrequency} />
          </Col>
        </>
      )}
    </>
  );
}
