import {
  EXCEPTION_ASSIGNMENT_ADDED,
  EXCEPTION_ASSIGNMENT_EDIT,
  EXCEPTION_ASSIGNMENT_REMOVED,
  EXCEPTION_ASSIGNMENTS_FETCHED,
} from '../actions/actions_exception_assignments';
import { removeObjectFromArray, replaceObjectInArray } from '../helpers';

const exceptionAssignmentsReducer = (exceptionAssignments = [], action) => {
  switch (action.type) {
    case EXCEPTION_ASSIGNMENTS_FETCHED: {
      return action.payload.exceptionAssignments;
    }
    case EXCEPTION_ASSIGNMENT_ADDED:
      return [...exceptionAssignments, action.payload.exceptionAssignment];
    case EXCEPTION_ASSIGNMENT_EDIT:
      return replaceObjectInArray(exceptionAssignments, action.payload.exceptionAssignment, 'grinder_uid');
    case EXCEPTION_ASSIGNMENT_REMOVED: {
      return removeObjectFromArray(
        exceptionAssignments,
        { grinder_uid: action.payload.exceptionAssignmentId },
        'grinder_uid'
      );
    }
    default:
      return exceptionAssignments;
  }
};

export default exceptionAssignmentsReducer;
