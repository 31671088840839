import { connect } from 'react-redux';
import App from '../components/App';

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const ActiveApp = connect(mapStateToProps)(App);

export default ActiveApp;
