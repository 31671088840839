import { get } from 'lodash';
import { isJSON } from '../helpers';
import {
  ADD_NCR_CHAT_ERROR,
  ADD_NCR_CHAT_SUCCESS,
  FETCH_NCR_CHAT_CHANNELS_ERROR,
  FETCH_NCR_CHAT_CHANNELS_SUCCESS,
  FETCH_NCR_CHAT_CHANNEL_MESSAGES_ERROR,
  FETCH_NCR_CHAT_CHANNEL_MESSAGES_SUCCESS,
  RESET_NCR_CHAT_CHANNELS,
  RESET_NCR_CHAT_CHANNEL_MESSAGES,
  SEND_NCR_CHAT_MESSAGE_ERROR,
  SEND_NCR_CHAT_MESSAGE_SUCCESS,
  FETCH_NCR_UNREAD_MESSAGES_SUCCESS,
  FETCH_NCR_UNREAD_MESSAGES_ERROR,
  FETCH_NCR_UNREAD_MESSAGES_RESET,
} from '../actions/action_ncr_chat';

const initialState = {
  channels: [],
  messages: [],
  isLoading: false,
  channelsFetched: false,
  hasError: false,
  errorMessage: '',
  unreadMessages: null,
  unreadMessagesFetched: false,
};

const getErrorMessage = payload => {
  return get(isJSON(payload) ? JSON.parse(payload) : payload, 'title', '');
};

const ncrChat = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NCR_CHAT_SUCCESS:
      return {
        ...state,
        hasError: false,
        errorMessage: '',
      };
    case ADD_NCR_CHAT_ERROR:
      return {
        ...state,
        hasError: true,
        errorMessage: getErrorMessage(action.payload),
      };
    case RESET_NCR_CHAT_CHANNELS:
      return {
        ...state,
        hasError: false,
        channelsFetched: false,
        channels: [],
      };
    case FETCH_NCR_CHAT_CHANNELS_SUCCESS:
      return {
        ...state,
        hasError: false,
        channelsFetched: true,
        channels: action.payload,
      };
    case FETCH_NCR_CHAT_CHANNELS_ERROR:
      return {
        ...state,
        hasError: true,
        channelsFetched: false,
        errorMessage: getErrorMessage(action.payload),
      };
    case SEND_NCR_CHAT_MESSAGE_SUCCESS:
      return {
        ...state,
        hasError: false,
      };
    case SEND_NCR_CHAT_MESSAGE_ERROR:
      return {
        ...state,
        hasError: true,
        errorMessage: getErrorMessage(action.payload),
      };
    case RESET_NCR_CHAT_CHANNEL_MESSAGES:
      return {
        ...state,
        messages: [],
      };
    case FETCH_NCR_CHAT_CHANNEL_MESSAGES_SUCCESS:
      return {
        ...state,
        hasError: false,
        messages: action.payload,
      };
    case FETCH_NCR_CHAT_CHANNEL_MESSAGES_ERROR:
      return {
        ...state,
        hasError: true,
        errorMessage: getErrorMessage(action.payload),
      };
    case FETCH_NCR_UNREAD_MESSAGES_RESET:
      return {
        ...state,
        unreadMessages: null,
        unreadMessagesFetched: false,
      };
    case FETCH_NCR_UNREAD_MESSAGES_SUCCESS:
      return {
        ...state,
        hasError: false,
        unreadMessages: action.payload,
        unreadMessagesFetched: true,
      };
    case FETCH_NCR_UNREAD_MESSAGES_ERROR:
      return {
        ...state,
        hasError: true,
        unreadMessagesFetched: false,
        errorMessage: getErrorMessage(action.payload),
      };
    default:
      return state;
  }
};

export default ncrChat;
