import {
  FORM_FIELD_ADDED,
  FORM_FIELD_EDIT,
  FORM_FIELD_REMOVED,
  FORM_FIELDS_FETCHED,
} from '../actions/actions_form_fields';

function removeFormValue(allFormValues, removedFormValue) {
  Object.keys(allFormValues).forEach(key => {
    const index = allFormValues[key].findIndex(item => item.id === removedFormValue.id);
    if (index > -1) {
      allFormValues[key].splice(index, 1);
    }
  });
  return allFormValues;
}

function replaceFormValue(allFormValues, editedFormValue) {
  const newFormValues = [];

  if (allFormValues[editedFormValue.type]) {
    let found = false;
    allFormValues[editedFormValue.type].forEach(element => {
      if (element.id === editedFormValue.id) {
        newFormValues.push(editedFormValue);
        found = true;
      } else {
        newFormValues.push(element);
      }
    });
    if (found) {
      allFormValues[editedFormValue.type] = newFormValues;
    } else {
      removeFormValue(allFormValues, editedFormValue);
      allFormValues[editedFormValue.type].push(editedFormValue);
    }
  } else {
    removeFormValue(allFormValues, editedFormValue);
    allFormValues[editedFormValue.type] = [editedFormValue];
  }
  return allFormValues;
}

const organizeItems = (payload, formFields) => {
  const result = {};

  payload.forEach(item => {
    if (result[item.type]) {
      result[item.type].push(item);
    } else {
      result[item.type] = [item];
    }
  });
  // Only override the specific form field type that we fetched.
  // The rest of existing fields should remain.
  return { ...formFields, ...result };
};

const formFields = (formFieldsInner = [], action) => {
  const items = JSON.parse(JSON.stringify(formFieldsInner));
  switch (action.type) {
    case FORM_FIELDS_FETCHED:
      return organizeItems(action.payload, formFieldsInner);
    case FORM_FIELD_ADDED:
      if (items[action.payload[0].type]) {
        items[action.payload[0].type].push(action.payload[0]);
      } else {
        items[action.payload[0].type] = action.payload;
      }
      return items;
    case FORM_FIELD_EDIT:
      return replaceFormValue(items, action.payload);
    case FORM_FIELD_REMOVED:
      return removeFormValue(items, action.payload);
    default:
      return formFieldsInner;
  }
};

export default formFields;
