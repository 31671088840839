const schema = [
  {
    name: 'unit',
    displayName: 'Unit Of Measure',
    systemIdField: 'id',
    displayIdField: 'name',
    path: '/units',
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'Short or abbreviated name of the unit of measure',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'full_name',
        displayName: 'Full Name',
        tooltip: 'Complete name of the unit of measure',
        dataType: 'shorttext',
      },
      {
        name: 'conversion_rate',
        displayName: 'Conversion Rate',
        tooltip: 'Factor used to convert this unit of measure to a standard unit.',
        dataType: 'number',
      },
    ],
  },
];
export default schema;
