import React, { useState, useEffect } from 'react';
import { HStack, PinInputField, PinInput } from '@chakra-ui/react';

// eslint-disable-next-line react/prop-types
export default function OtpInputField({ handleChangeOtp, n = 6, marginLeft = 20 }) {
  const values = Array(n).fill('');

  const [otpValue, setOtpValue] = useState(values);

  useEffect(() => {
    const fullOtp = Object.values(otpValue).join('');
    handleChangeOtp(fullOtp);
  }, [otpValue]);

  const handleUpdate = (value, index) => {
    setOtpValue(prevState => {
      return {
        ...prevState,
        [index]: value,
      };
    });
  };

  const inputs = values.map((v, i) => {
    return (
      <PinInputField
        ml={marginLeft}
        fontFamily="Helvetica"
        fontWeight="normal"
        onChange={e => {
          handleUpdate(e.target.value, i);
        }}
      />
    );
  });
  return (
    <HStack>
      <PinInput otp size="lg">
        {inputs}
      </PinInput>
    </HStack>
  );
}
