import React from 'react';
import styles from './ColumnChart.module.css';
import { format, setDayOfYear } from 'date-fns';
import { FREQUENCY } from '../../../slices/markets-details/marketsDetailsConfig';

function Wrap({ x, children }) {
  const lines = children.split('\n');

  return lines.map((line, lineIndex) => (
    <tspan dy="17" x={x} key={lineIndex}>
      {line}
    </tspan>
  ));
}

export default function ColumnXAxis({ scale, frequency, mode }) {
  const formats = {
    [FREQUENCY.WEEKLY]: date => format(date, 'd/M/yy'),
    [FREQUENCY.MONTHLY]: date => format(date, 'MMM-yy'),
    [FREQUENCY.QUARTERLY]: date => format(date, 'qqq yy'),
    [FREQUENCY.ANNUALLY]: date => format(date, 'yyyy'),
  };

  const [left, right] = scale.range();
  const minTickWidth = 50;
  const numTicks = Math.floor((right - left) / minTickWidth);
  const ticks = mode === 'column' ? scale.domain() : scale.ticks();
  const skip = Math.ceil(ticks.length / numTicks);
  const formatter =
    mode === 'column'
      ? timestamp => formats[frequency](new Date(+timestamp))
      : dateOfYear => format(setDayOfYear(new Date(), +dateOfYear), 'MMM');

  return (
    <>
      <line x1={left} x2={right} y1="0" y2="0" className={styles.xAxisLine} />

      {ticks.map((label, tickIndex) => {
        const x = mode === 'column' ? scale(label) + scale.bandwidth() / 2 : scale(label);
        const areBarsTooSmall = mode === 'column' ? scale.bandwidth() < 50 : false;

        return (
          <g key={`group-${label}`}>
            <line x1={x} x2={x} y1="0" y2="5" key={`line-${label}`} className={styles.xAxisLine} />
            {(areBarsTooSmall ? tickIndex % skip === 0 : true) && (
              <text className={styles.xAxisTick} key={`text-${label}`} x={x} y="5">
                <Wrap x={x}>{formatter(label)}</Wrap>
              </text>
            )}
          </g>
        );
      })}
    </>
  );
}
