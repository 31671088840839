import { extendTheme } from '@chakra-ui/react';
import themeColors from './colors';
import { Button, IconButton, Heading } from './components';

export const colors = themeColors;

const home = {
  title: 'BOAB',
  logo: 'img/BOAB_Blue.png',
  showVideo: true,
  welcomeMsg: 'WELCOME TO BOAB',
};

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

export const shadows = {
  primary: '0 3px 10px #ccc',
};

const fontSizes = {
  '7xl': '41px',
  '6xl': '38px',
  '5xl': '33px',
  '4xl': '30px',
  '3xl': '27px',
  '2xl': '24px',
  xl: '21px',
  lg: '18px',
  md: '14px',
  sm: '12px',
};

const fontWeights = {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

const fonts = {
  heading: `'Poppins', sans-serif`,
  body: `'Roboto', sans-serif`,
};

const lineHeights = {
  normal: 'normal',
  none: 1,
  shorter: 1.25,
  short: 1.375,
  base: 1.5,
  tall: 1.625,
  taller: '2',
  3: '.75rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  xl: '26px',
};

const components = {
  Button,
  IconButton,
  Heading,
};

const styles = {
  global: () => ({
    'html, body': {
      fontFamily: 'Roboto',
    },
    h1: {
      fontSize: '6xl',
      mb: '4',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      lineHeight: '44px',
    },
    h2: {
      mb: '4',
      fontSize: '5xl',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      lineHeight: '36px',
    },
    h3: {
      mb: '4',
      fontSize: '3xl',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      lineHeight: '32px',
    },
    h4: {
      mb: '4',
      fontSize: '2xl',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      lineHeight: '28px',
    },
    h5: {
      mb: '4',
      fontSize: 'lg',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      lineHeight: '24px',
    },
    h6: {
      mb: '4',
      fontSize: 'md',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      lineHeight: '20px',
    },
  }),
};

const theme = extendTheme({
  colors: themeColors,
  config,
  home,
  fontSizes,
  fontWeights,
  lineHeights,
  styles,
  components,
  shadows,
});

export const getTenantTheme = reduxTheme => {
  const apiTheme = reduxTheme;
  if (apiTheme && !apiTheme.hasOwnProperty('home')) {
    apiTheme.home = home;
  }

  return extendTheme({
    // colors: themeColors,
    config,
    home,
    fontSizes,
    fontWeights,
    lineHeights,
    styles,
    components,
    shadows,
    fonts,
    ...(apiTheme && apiTheme),
  });
};

export default theme;
