import React from 'react';
import PropTypes from 'prop-types';

import SelectField from '../../../basic/SelectField';
import { IconButton, Input, TableContainer, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { ReactComponent as TrashOutlineIconSVG } from '../../../../assets/trash-outline-icon.svg';
import Tooltip from '../../../quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const propTypes = {
  editMode: PropTypes.bool,
  form: PropTypes.shape({
    lines: PropTypes.arrayOf(PropTypes.object),
  }),
  handleChangeObjectList: PropTypes.func,
  handleRemoveObjectListItem: PropTypes.func,
  index: PropTypes.number,
  item: PropTypes.shape({
    id: PropTypes.number,
    purchaseOrderLineIds: PropTypes.arrayOf(PropTypes.number),
    numberOfCartons: PropTypes.number,
    mark: PropTypes.string,
  }),
  onDeleteMark: PropTypes.func,
  onAddMark: PropTypes.func,
  count: PropTypes.number,
};
const ShipmentPortMark = props => {
  const { count, onAddMark } = props;

  const removeMark = () => {
    if (props.item.id) {
      const confirmation = window.confirm('Are you sure you want to remove mark?');

      if (confirmation) {
        props.onDeleteMark(props.item);
      }
    } else {
      props.handleRemoveObjectListItem('billOfLading', 'shipmentPortMarks', props.index);
    }
  };

  const { handleChangeObjectList, index, item, editMode, form } = props;
  const lineOptions = form.lines
    .filter(line => {
      if (!line.id) {
        return false;
      }
      if (!line.active) {
        return false;
      }
      return true;
    })
    .map(line => ({ label: `PO Line #${line.lineNumber}`, value: line.id }));
  const lineValue = item.purchaseOrderLineIds;
  return (
    <TableContainer overflowX="visible" overflowY="visible">
      <Table variant="unstyled" width="fit-content">
        <Thead>
          <Tr>
            <Th paddingX="0px" paddingBottom="0px" />
            <Th paddingLeft="5px" paddingBottom="0px">
              <Text as="p" fontSize="14px" fontWeight="medium" textTransform="capitalize">
                Shipment/Port Mark&nbsp;
                <Tooltip
                  content="Specifies the shipment or port mark, which serves as an identifier or label for tracking."
                  placement="right"
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </Text>
            </Th>
            <Th paddingBottom="0px">
              <Text as="p" fontSize="14px" fontWeight="medium" textTransform="capitalize">
                Cartons&nbsp;
                <Tooltip
                  content="Indicates the number of cartons in the shipment to facilitate inventory management"
                  placement="right"
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </Text>
            </Th>
            <Th paddingBottom="0px">
              <Text as="p" fontSize="14px" fontWeight="medium" textTransform="capitalize">
                Purchase Order Line&nbsp;
                <Tooltip
                  content="Selects the specific purchase order line related to the shipment from the dropdown list"
                  placement="right"
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td fontWeight="medium" color="accent.one.default" paddingX="0px">
              {index + 1}
            </Td>
            <Td paddingLeft="5px" width="305px">
              <Input
                name="shipmentPortMark"
                value={item.mark}
                placeholder="Shipment/Port Mark"
                isDisabled={!editMode}
                backgroundColor="white"
                className="form-control"
                onChange={e =>
                  handleChangeObjectList('billOfLading', 'shipmentPortMarks', 'mark', index, e.target.value, true)
                }
              />
            </Td>
            <Td width="305px">
              <Input
                name="numberOfCartons"
                type="number"
                value={item.numberOfCartons || ''}
                placeholder="Cartons"
                isDisabled={!editMode}
                backgroundColor="white"
                className="form-control"
                onChange={e =>
                  handleChangeObjectList(
                    'billOfLading',
                    'shipmentPortMarks',
                    'numberOfCartons',
                    index,
                    e.target.value,
                    true
                  )
                }
                onWheelCapture={e => {
                  e.target.blur();
                }}
              />
            </Td>
            <Td width="305px">
              <SelectField
                isDisabled={!editMode}
                name="portmarkPurchaseOrderLine"
                defaultValue={lineValue}
                options={lineOptions}
                isMulti
                onChange={e =>
                  handleChangeObjectList(
                    'billOfLading',
                    'shipmentPortMarks',
                    'purchaseOrderLineIds',
                    index,
                    e.map(itm => itm.value),
                    false
                  )
                }
              />
            </Td>
            {editMode && (
              <Td padding="0px">
                <IconButton
                  onClick={removeMark}
                  variant="ghost"
                  isDisabled={!editMode}
                  icon={<TrashOutlineIconSVG />}
                  height="25px"
                  width="25px"
                  fontSize="25px"
                />
              </Td>
            )}
            {editMode && index + 1 === count && (
              <Td padding="0px">
                <IconButton
                  onClick={onAddMark}
                  colorScheme="actionSecondary"
                  variant="solid"
                  isDisabled={!editMode}
                  icon={<AddIcon />}
                  height="25px"
                  minWidth="25px"
                  borderRadius="14px"
                />
              </Td>
            )}
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

ShipmentPortMark.propTypes = propTypes;

export default ShipmentPortMark;
