import React from 'react';
import { Radio, Typography } from 'antd';
import { DATE_MODE } from '../../../../slices/markets/marketsConfig';

export default function FilterDateType(props) {
  const { controlState, onDateModeChange } = props;

  const dateModeOptions = Object.entries(DATE_MODE).map(([key, value]) => ({
    label: key === 'DELIVERY' ? 'Deliveries' : 'Orders',
    value,
  }));

  return (
    <div>
      <Typography.Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Showing
      </Typography.Text>
      <div>
        <Radio.Group value={controlState.dateMode} onChange={e => onDateModeChange(e.target.value)}>
          {dateModeOptions.map(option => (
            <Radio.Button key={option.value} value={option.value}>
              {option.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
    </div>
  );
}
