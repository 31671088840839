import { clearStoredCredentials } from '../../actions/actions_auth';

const { fetch: originalFetch } = window;
const UNAUTHORIZED_STATUS_CODE = 401;

window.fetch = async (...args) => {
  const [resource, config] = args;

  const response = await originalFetch(resource, config);
  if (response.status === UNAUTHORIZED_STATUS_CODE) {
    clearStoredCredentials();
    window.location.href = '/login';
  }
  return response;
};
