import { connect } from 'react-redux';

import CreatePurchaseOrderFormComponent from '../../components/orders/order-form/CreatePurchaseOrderForm/CreatePurchaseOrderForm';
import Constants from '../../Constants';
import { convertToCamelCase } from '../../helpers';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

// The Sydney Order form should be Beef only. Filter out any Pork entities.
const filterProductType = data =>
  data.filter(d => d.product_type && d.product_type.toUpperCase() === Constants.PRODUCT_TYPE_LIST.BEEF);

const mapStateToProps = state => {
  const {
    business_offices: businessOffices,
    containers,
    currencies,
    grinders,
    inco_terms: incoTerms,
    input_products: inputProducts,
    load_sizes: loadSizes,
    packer_plants: packerPlants,
    packers,
    ports,
    price_types: priceTypes,
    tags,
    transporters,
    units_of_measure: unitsOfMeasure,
    end_users: endUsers,
  } = { ...findEntitiesInState(state) };
  const result = {
    businessOffices,
    containers,
    currencies,
    grinders: filterProductType(grinders),
    incoTerms,
    inputProducts: filterProductType(inputProducts),
    loadSizes,
    packerPlants: filterProductType(packerPlants),
    packers: filterProductType(packers),
    ports,
    priceTypes,
    tags,
    transporters,
    unitsOfMeasure,
    user: state.user,
    users: state.users,
    voyage: state.voyage || Constants.INITIAL_VOYAGE,
    endUsers,
    configs: state.configs,
    settingsConfig: state.settingsConfig,
    productTypes: state.form_fields.product_types || [],
    packageWeights: state.packageWeights,
  };

  const cameled = convertToCamelCase(result);
  return cameled;
};

const CreatePurchaseOrderForm = connect(mapStateToProps)(CreatePurchaseOrderFormComponent);

export default CreatePurchaseOrderForm;
