import React, { Component } from 'react';
import { useTheme } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import ARCOP from '../../img/ARCOP.jpg'
import cfc_blue from '../../img/cfc_blue.png'
import Constants from '../../Constants';


const Home = props => {

    const { user } = props

    
    return (
        <>
        {user?.organisation?.url_name === Constants.BRANDS.INSPIRE_BRANDS ? (
            <div>
                <div style={{ display: 'flex', placeContent: 'left'}}>
                    <img style={{ maxWidth: '300px', height: '100px' }} src={cfc_blue} alt="cif" />
                </div>
                <div style={{ display: 'grid', placeContent: 'center'}}>
                    <div style={{ display: 'flex', placeContent: 'center'}}>
                        <img style={{ maxWidth: '200px', height: '100px' }} src={ARCOP} alt="ARCOP" />
                    </div>
                    <Text style={{ fontSize: 'xxx-large',textTransform: 'capitalize'}}>Welcome <b>{user?.first_name} {user?.last_name}</b></Text>
                    <Text style={{ fontSize: 'x-large', textAlign: 'center'}}><b>{(new Date()).toDateString()}</b></Text>
                </div>
            </div>
            ) : 
            ('')
        }
        </>
             
            )
    
}

export default Home;