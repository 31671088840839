import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  FormLabel,
  Select,
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Textarea,
  toast,
} from '@chakra-ui/react';
import { Formik, useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import api from '../../../api';
import { actions } from '../../../reducers/reducer_establishment_details';
import { useDispatch } from 'react-redux';

const { requestEstblishmentDetailsEditStatus } = actions;

const ChangeStatus = ({ establishmentId }) => {
  const dispatch = useDispatch();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const chnageStatusHandler = async values => {
    try {
      setIsLoading(true);
      const response = await api.patch(`/op-establishment-detail/${establishmentId}`, {
        establishment: values,
      });

      if (response.ok) {
        dispatch(requestEstblishmentDetailsEditStatus(await response.json()));
        toast({
          position: 'top-right',
          title: 'Status Changed Successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        position: 'top-right',
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      setIsOpenPopup(false);
    }
  };

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: {
      status: '',
      reason: '',
    },
    onSubmit: chnageStatusHandler,
    validationSchema: Yup.object().shape({
      status: Yup.string().required('Status is Required'),
      reason: Yup.string().when('status', {
        is: 'Rejected',
        then: Yup.string().required('Reason is Required'),
      }),
    }),
  });

  useEffect(() => {
    if (!isOpenPopup) {
      setFieldValue('status', '');
      setFieldValue('reason', '');
    }
  }, [isOpenPopup, setFieldValue]);

  return (
    <>
      <Button variant="solid" colorScheme="actionSecondary" onClick={() => setIsOpenPopup(true)}>
        Change Status
      </Button>
      {isOpenPopup && (
        <Modal isOpen={isOpenPopup} onClose={() => setIsOpenPopup(false)} closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent maxW="min(700px, 80%)">
            <ModalHeader borderBottomWidth="1px" fontSize="20px">
              Change Status
            </ModalHeader>
            <ModalCloseButton />
            <Formik>
              <form onSubmit={handleSubmit}>
                <ModalBody padding="22px">
                  <Box flexGrow={1}>
                    <FormLabel>Status</FormLabel>
                    <Select
                      name="status"
                      placeholder="Select"
                      height="40px"
                      onChange={e => {
                        setFieldValue('status', e.currentTarget.value);
                      }}
                      value={values.status}
                    >
                      <option value="Approved">Approved</option>
                      <option value="Rejected">Rejected</option>
                    </Select>
                    {errors.status && touched.status && (
                      <Alert status="error">
                        <AlertIcon />
                        <AlertDescription>{errors.status}</AlertDescription>
                      </Alert>
                    )}
                  </Box>
                  {values.status === 'Rejected' && (
                    <Box flexGrow={1} marginTop="25px">
                      <FormLabel>Reason</FormLabel>
                      <Textarea height="120px" value={values.reason} onChange={handleChange} name="reason" />
                      {errors.reason && touched.reason && (
                        <Alert status="error">
                          <AlertIcon />
                          <AlertDescription>{errors.reason}</AlertDescription>
                        </Alert>
                      )}
                    </Box>
                  )}
                </ModalBody>
                <ModalFooter borderTopWidth="1px" marginTop="26px" padding="16px 22px">
                  <ButtonGroup marginLeft="auto">
                    <Button
                      type="button"
                      fontSize="16px"
                      width="126px"
                      height="43px"
                      onClick={() => setIsOpenPopup(false)}
                    >
                      Close
                    </Button>
                    &nbsp;
                    <Button
                      type="submit"
                      width="126px"
                      height="43px"
                      colorScheme="actionPrimary"
                      marginLeft="10px"
                      fontSize="16px"
                      loadingText="Saving..."
                      isLoading={isLoading}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </form>
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

ChangeStatus.propTypes = {
  establishmentId: PropTypes.number.isRequired,
  estDetail: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    reason: PropTypes.string,
    listed_status: PropTypes.string,
    est: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    est_supported_options: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
  }),
};

export default ChangeStatus;
