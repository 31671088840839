import { format } from 'date-fns';
import * as React from 'react';
import { TIME_SCALES } from '../../../../slices/quality/qualityConfig';

const ThisPeriod = ({ timeScale, timePeriod }) => {
  const formatString = timeScale === TIME_SCALES.ANNUALLY ? 'yyyy' : 'MMMM yyyy';
  return <span>{format(timePeriod[1], formatString)}</span>;
};

export default ThisPeriod;
