import Constants from '../Constants';

export const BEEF_OUTPUT_PRODUCTS_FETCHED = 'BEEF_OUTPUT_PRODUCTS_FETCHED';
export const BEEF_OUTPUT_PRODUCT_ADDED = 'BEEF_OUTPUT_PRODUCT_ADDED';
export const BEEF_OUTPUT_PRODUCT_EDIT = 'BEEF_OUTPUT_PRODUCT_EDIT';
export const BEEF_OUTPUT_PRODUCT_REMOVED = 'BEEF_OUTPUT_PRODUCT_REMOVED';
export const BEEF_OUTPUT_PRODUCT_ERROR = 'BEEF_OUTPUT_PRODUCT_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadBeefOutputProducts(beefProducts) {
  return {
    type: BEEF_OUTPUT_PRODUCTS_FETCHED,
    payload: beefProducts,
  };
}

export function addBeefOutputProduct(beefProduct) {
  return {
    type: BEEF_OUTPUT_PRODUCT_ADDED,
    payload: beefProduct,
  };
}

export function removeBeefOutputProduct(beefProduct) {
  return {
    type: BEEF_OUTPUT_PRODUCT_REMOVED,
    payload: beefProduct,
  };
}

export function editBeefOutputProduct(beefProduct) {
  return {
    type: BEEF_OUTPUT_PRODUCT_EDIT,
    payload: beefProduct,
  };
}

export function errorBeefOutputProduct(error) {
  return {
    type: BEEF_OUTPUT_PRODUCT_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchBeefOutputProducts(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}output_products`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorBeefOutputProduct))
      .then(json => dispatch(loadBeefOutputProducts(json)))
      .catch(error => dispatch(errorBeefOutputProduct(error)));
  };
}

export function postBeefOutputProduct(beefProduct, token) {
  return dispatch => {
    const copy = { ...beefProduct };
    delete copy.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}output_products`, {
      method: 'POST',
      body: JSON.stringify(copy),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorBeefOutputProduct))
      .then(json => {
        if (json.title) {
          dispatch(errorBeefOutputProduct(json.title));
        } else {
          dispatch(addBeefOutputProduct(json));
        }
      })
      .catch(error => dispatch(errorBeefOutputProduct(error)));
  };
}

export function patchBeefOutputProduct(beefProduct, token) {
  return dispatch => {
    const copy = { ...beefProduct };
    delete copy.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}output_products/${beefProduct.uid}`, {
      method: 'PATCH',
      body: JSON.stringify(copy),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorBeefOutputProduct))
      .then(json => {
        if (json.title) {
          dispatch(errorBeefOutputProduct(json.title));
        } else {
          dispatch(editBeefOutputProduct(json));
        }
      })
      .catch(error => dispatch(errorBeefOutputProduct(error)));
  };
}

export function deleteBeefOutputProduct(beefProduct, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}output_products/${beefProduct.uid}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorBeefOutputProduct))
      .then(() => dispatch(removeBeefOutputProduct(beefProduct)))
      .catch(error => dispatch(errorBeefOutputProduct(error)));
  };
}
