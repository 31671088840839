import React from 'react';
import { useField } from 'formik';
import Select from 'react-select';
import PropTypes from 'prop-types';

const SimpleReferenceSelectField = ({ fieldName, options, isClearable, ...rest }) => {
  const [field, meta, helpers] = useField(fieldName);
  const valueArray = Array.isArray(meta.value) ? meta.value : [meta.value];
  const selection = options.filter(opt => valueArray.includes(opt.value));
  const { setValue } = helpers;
  const onChange = changes => {
    return setValue(changes ? changes.value : '');
  };

  return (
    <Select
      name={fieldName}
      options={options}
      value={selection}
      placeholder="Select value"
      isClearable={isClearable == null ? true : isClearable}
      onChange={onChange.bind(options)}
      styles={{
        container: styles => ({
          ...styles,
          padding: '0px',
        }),
        control: styles => ({
          ...styles,
          maxHeight: '225px',
          width: '234px',
          overflowY: 'scroll',
          ...rest.controlStyles,
        }),
        clearIndicator: styles => ({
          ...styles,
          padding: '0px',
          ...rest.clearIndicatorStyles,
        }),
        dropdownIndicator: styles => ({
          ...styles,
          padding: '0px',
          ...rest.dropdownIndicatorStyles,
        }),
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};

SimpleReferenceSelectField.propTypes = {
  fieldName: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  isClearable: PropTypes.bool,
};
export default SimpleReferenceSelectField;
