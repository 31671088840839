import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuButton, MenuItem, MenuList, Tab, TabList, Tabs } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import MasterDataBaseComponent from './MasterDataBaseComponent';

const MasterDataTabsComponent = ({ allEntitiesSchema, mainPath, entityType }) => {
  const [tabGroups, setTabGroups] = useState([]);
  const [activeTabGroupIndex, setActiveTabGroupIndex] = useState(0);

  useEffect(() => {
    if (allEntitiesSchema) {
      const groupedAllEntitiesSchema = allEntitiesSchema
        .filter(def => !def.nested && !def.hidden)
        .reduce((acc, curr) => {
          if (curr.tabGroup) {
            if (acc[curr.tabGroup.key]) {
              acc[curr.tabGroup.key].data.push(curr);
              acc[curr.tabGroup.key].entityTypes.push(curr.name);
            } else {
              acc[curr.tabGroup.key] = { data: [curr], label: curr.tabGroup.label, entityTypes: [curr.name] };
            }
          } else if (acc.others) {
            acc.others.data = [...acc.others.data, curr];
            acc.others.entityTypes = [...acc.others.entityTypes, curr.name];
          } else {
            acc.others = { data: [curr], label: 'Others', entityTypes: [curr.name] };
          }

          return acc;
        }, {});

      const tabGroupsArray = Object.keys(groupedAllEntitiesSchema).reduce(
        (acc, curr) => [...acc, groupedAllEntitiesSchema[curr]],
        []
      );
      tabGroupsArray.push(
        tabGroupsArray.splice(
          tabGroupsArray.findIndex(tabGroup => tabGroup.label === 'Others'),
          1
        )[0]
      );
      setTabGroups(tabGroupsArray);
    }
  }, [allEntitiesSchema]);

  useEffect(() => {
    if (tabGroups) {
      const activeIndex = tabGroups.findIndex(tabGroup => {
        const isActiveTabGroup = tabGroup.entityTypes.includes(entityType);
        return isActiveTabGroup;
      });
      setActiveTabGroupIndex(activeIndex);
    }
  }, [entityType, tabGroups]);

  return (
    <Tabs index={activeTabGroupIndex}>
      <TabList borderBottomWidth="0px">
        {tabGroups.map((tabGroup, tabGroupIndex) => (
          <Tab
            key={tabGroup.label}
            _focus={{ outline: 'none' }}
            _selected={{ borderColor: 'actionSecondary.default', borderBottomWidth: '2px' }}
            _hover={{ borderColor: 'actionSecondary.default', borderBottomWidth: '2px' }}
            fontWeight="normal"
            width="fit-content"
            fontSize="14px"
            padding="0px"
            height="103px"
            marginRight="10px"
          >
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    as={Button}
                    rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    bgColor="inherit"
                    color={activeTabGroupIndex === tabGroupIndex ? 'actionSecondary.default' : 'inherit'}
                    fontWeight={activeTabGroupIndex === tabGroupIndex ? 'bold' : 'normal'}
                    height="100%"
                    _hover={{
                      bgColor: 'inherit',
                      color: 'actionSecondary.default',
                    }}
                    _focus={{
                      bgColor: 'inherit',
                      outline: '0',
                      color: 'actionSecondary.default',
                    }}
                    _active={{
                      bgColor: 'inherit',
                      color: 'actionSecondary.default',
                    }}
                  >
                    {tabGroup.label}
                  </MenuButton>
                  <MenuList zIndex="98" width="270px" padding="0px" borderRadius="0px">
                    {tabGroup.data.map(tab => (
                      <Link to={`/${mainPath}/entity/${tab.name}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <MenuItem
                          minHeight="40px"
                          outline="none"
                          color={entityType === tab.name && 'actionSecondary.default'}
                          paddingY="9px"
                          paddingLeft="40px"
                          borderRightWidth={entityType === tab.name && '3px'}
                          borderColor="actionSecondary.default"
                          bgColor={entityType === tab.name && 'actionSecondary.active20'}
                          _hover={{
                            bgColor: 'actionSecondary.active20',
                            borderRightWidth: '3px',
                          }}
                          _focus={{ outline: 'none' }}
                        >
                          {tab.shouldNotPluralize ? tab.displayName : `${tab.displayName}s`}
                        </MenuItem>
                      </Link>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

MasterDataTabsComponent.propTypes = {
  allEntitiesSchema: PropTypes.arrayOf(PropTypes.object),
  mainPath: PropTypes.string,
  entityType: PropTypes.arrayOf(PropTypes.object),
};

const MasterData = () => {
  return <MasterDataBaseComponent TabsComponent={MasterDataTabsComponent} />;
};

export default MasterData;
