import { createSelector } from '@reduxjs/toolkit';
import { selectQualityNCRsCategories } from './selectBasicDetailsNcrsData';
import { CUSTOM_FILTERS } from '../../quality/qualityConfig';

export const selectAvailableCategories = createSelector(selectQualityNCRsCategories, categories => {
  if (!categories || categories.length === 0) return [];

  return categories
    .map(x => x.category)
    .filter(x => x !== CUSTOM_FILTERS.MOST_REPORTED_SUB_CATEGORIES)
    .sort();
});

export const selectAvailableCategoriesWithSubcategories = createSelector(selectQualityNCRsCategories, categories => {
  if (!categories || categories.length === 0) return [];

  const categoriesSorted = categories
    .map(x => ({
      category: x.category,
      subCategories: x.subCategories ? [...x.subCategories].sort() : [],
    }))
    .sort((a, b) => a.category?.localeCompare(b.category));

  const mostReported = categoriesSorted.find(x => x.category === CUSTOM_FILTERS.MOST_REPORTED_SUB_CATEGORIES);

  return categoriesSorted
    .filter(x => x.category !== CUSTOM_FILTERS.MOST_REPORTED_SUB_CATEGORIES)
    .concat([mostReported]);
});
