import {
  INTERESTS_FETCHED,
  INTEREST_UPDATED,
  INTEREST_REMOVED,
  INTEREST_ADDED,
  INTEREST_ERROR,
} from '../actions/actions_interests';

const interests = (interests = [], action) => {
  switch (action.type) {
    case INTERESTS_FETCHED:
      return action.payload.map(interest => ({ ...interest, rate: interest.rate * 100 }));
    case INTEREST_ADDED:
      return [...interests, action.payload];
    case INTEREST_UPDATED:
      const newInterests = [...interests.filter(item => item.id !== action.payload.id), action.payload];
      return newInterests.map(interest => ({
        ...interest,
        rate: interest.rate * 100,
      }));
    case INTEREST_REMOVED:
      return [...interests.filter(item => item.id !== action.payload.id)];
    default:
      return interests;
  }
};

export default interests;
