const schema = [
  {
    name: 'organisation',
    displayName: 'Organisation',
    systemIdField: 'id',
    displayIdField: 'name',
    path: '/organisations',
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'uid',
        displayName: 'UID',
        tooltip: 'Unique identifier assigned to the organization',
        dataType: 'shorttext',
        required: true,
        systemIdentifier: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'Official name of the organization',
        dataType: 'shorttext',
        displayIdentifier: true,
        required: true,
      },
      {
        name: 'org_type',
        displayName: 'Type',
        tooltip: 'Classification or category of the organization',
        dataType: 'text',
        required: true,
      },
      {
        name: 'parent_id',
        displayName: 'Parent Organisation',
        tooltip: 'Name of the parent organization, if this organization is a subsidiary or part of a larger entity.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'organisation',
        required: true,
      },
    ],
  },
];
export default schema;
