import { USER_SIGNATURE_LOADED, USER_SIGNATURE_LOADING } from '../actions/actions_users';

const user_documents = (state = false, action) => {
  switch (action.type) {
    case USER_SIGNATURE_LOADED:
      return {
        loading: false,
        signature: action.payload,
      };
    case USER_SIGNATURE_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default user_documents;
