import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';

import { updateActiveVolumeGrouping, updateVolumeOptions } from '../../../slices/markets/marketReducers';
import {
  selectActiveVolumeGrouping,
  selectVolumeGroups,
  selectActiveMarket,
  selectVolumeOptions,
} from '../../../slices/markets/selectors/selectBasicMarketData';
import { selectMarketsVolumeData } from '../../../slices/markets/selectors/selectMarketsVolumeData';

import VolumeTable from '../../../components/analyticDashboard/markets/VolumeTable/VolumeTable';
import { ChevronRight } from '../../../shared/Icons';
import IUnleashClassFlagProvider from '../../../components/unleash/UnleashClassFlagProvider';
import Constants from '../../../Constants';

// This container specifically serves the cost + performance card.
function CostPerformance(/* props */) {
  const dispatch = useDispatch();
  const { market: marketParam } = useParams();

  const { currentData, previousData } = useSelector(selectMarketsVolumeData);
  const volumeGroups = useSelector(selectVolumeGroups);
  const activeVolumeGrouping = useSelector(selectActiveVolumeGrouping);
  const activeMarket = useSelector(selectActiveMarket);
  const volumeOptions = useSelector(selectVolumeOptions);

  const handleActiveVolumeGroupingChange = React.useCallback(
    grouping => {
      dispatch(updateActiveVolumeGrouping(grouping.key));
    },
    [dispatch]
  );

  const handleVolumeOptionsChange = React.useCallback(
    newOptions => {
      dispatch(updateVolumeOptions(newOptions));
    },
    [dispatch]
  );

  // Make sure selected grouping is available
  React.useEffect(() => {
    if (!volumeGroups.includes(activeVolumeGrouping)) {
      dispatch(updateActiveVolumeGrouping(volumeGroups[0].key));
    }
  }, [activeVolumeGrouping, volumeGroups]);

  return (
    <Card>
      <Link to={`/analytic-dashboard/market/${marketParam}/volume-details`}>
        <Typography.Title level={3} style={{ fontWeight: 'normal' }}>
          Volume
          <div style={{ display: 'inline-block', width: '18px' }}>
            <ChevronRight />
          </div>
        </Typography.Title>
      </Link>
      <VolumeTable
        activeMarket={activeMarket}
        marketsVolumeData={currentData}
        previousMarketsVolumeData={previousData}
        volumeGroups={volumeGroups}
        activeVolumeGrouping={activeVolumeGrouping}
        onActiveVolumeGroupingChange={handleActiveVolumeGroupingChange}
        onVolumeOptionsChange={handleVolumeOptionsChange}
        volumeOptions={volumeOptions}
      />
    </Card>
  );
}

const CostPerformanceUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.MARKET_DASHBOARD_COST_PERFORMANCE}
        show={<CostPerformance {...props} />}
        hide=""
      />
    </>
  );
};

export default CostPerformanceUnleashController;
