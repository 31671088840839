import React from 'react';
import PropTypes from 'prop-types';
import { Center, Flex } from '@chakra-ui/react';
// Local Deps
import Button from '../core/Button';

const propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    onClickAction1: PropTypes.func,
    onClickAction2: PropTypes.func,
  }),
};

// This formatter is for display purposes only.  Clicking into the component from
// React-data-grid will activate the editor.
// I've used the same component (without the portal), to make sure that the options menu
// does not render.
function ActionsFormatter({ row }) {
  return (
    <Flex>
      <Center>
        <Button
          _focus={{ outline: 'none' }}
          cursor="pointer"
          minWidth="25px"
          px="2px"
          variant="borderless"
          onClick={() => row.onClickAction1(row)}
        >
          <i className="fa fa-pencil-alt" />
        </Button>
        <Button
          _focus={{ outline: 'none' }}
          cusor="pointer"
          minWidth="25px"
          px="2px"
          variant="borderless"
          onClick={() => row.onClickAction2(row)}
        >
          <i className="fa fa-upload" />
        </Button>
      </Center>
    </Flex>
  );
}

ActionsFormatter.propTypes = propTypes;
export default ActionsFormatter;
