import PropTypes from 'prop-types';

export const packerPlantPropTypes = PropTypes.shape({
  address: PropTypes.string,
  country: PropTypes.string,
  delisted: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isIndependent: PropTypes.bool,
  isPacker: PropTypes.bool,
  isPartOf: PropTypes.string,
  lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string.isRequired,
  bottomTenPackerPlants: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })),
  includesPackerPlants: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })),
});
