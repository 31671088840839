import React, { Component } from 'react';

class MDsComments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      article: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.articles && nextProps.articles.length) {
      let foundArticle = false;
      nextProps.articles.forEach(function (article) {
        if (article.title === "MD's Comments" && !foundArticle) {
          foundArticle = article;
        }
      });
      this.setState({ article: foundArticle });
    }
  }

  render() {
    const { article } = this.state;
    let firstPTag = false;

    if (this.state.article) {
      firstPTag = this.state.article.content.split('</p>')[0];
    }

    return (
      <div className="row">
        <div className="col-sm-12" style={{ marginTop: '30px' }}>
          {article ? (
            <div>
              <h4>{article.title}</h4>
              {this.state.expanded ? (
                <div>
                  <div dangerouslySetInnerHTML={{ __html: article.content }} />
                  <button onClick={() => this.setState({ expanded: false })} className="btn btn-sm btn-default">
                    Minimize
                  </button>
                </div>
              ) : (
                <div>
                  <div dangerouslySetInnerHTML={{ __html: firstPTag }} />
                  <button onClick={() => this.setState({ expanded: true })} className="btn btn-sm btn-default">
                    Read more
                  </button>
                </div>
              )}
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    );
  }
}

export default MDsComments;
