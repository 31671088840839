import React, { useEffect } from 'react';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import { FieldGroup, PreambleText, TipCard, VStackLayout } from '../Components/index';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  Textarea,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import SelectField from '../../basic/SelectField';
import Datetime from 'react-datetime';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { calculateInitialClaimValue } from '../helpers';
import { getOr } from '../../../helpers';
import Constants from '../../../Constants';

const dateTimeProps = {
  closeOnSelect: false,
  timeFormat: false,
  dateFormat: 'DD-MMM-YYYY',
  inputProps: {
    style: { height: '40px' },
  },
  isValidDate: current => {
    return current.isAfter(moment().subtract(1, 'day'));
  },
};

const processStageOptions = [
  {
    value: 'Pre-grind',
    label: 'Pre-grind',
  },
  {
    value: 'Post-grind',
    label: 'Post-grind',
  },
];

const productStatusOptions = [
  {
    value: 'On hold',
    label: 'On hold',
  },
  {
    value: 'Rejected',
    label: 'Rejected',
  },
  {
    value: 'Accepted',
    label: 'Accepted',
  },
  {
    value: 'Rework',
    label: 'Rework',
  },
];

const NCRDetails = ({
  form,
  currentStage,
  onUpdate,
  onUpdateMany,
  nonConformanceTypesOptions,
  nonConformanceSubTypesOptions,
  user,
}) => {
  const { values: formikValues, errors } = useFormikContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStage]);
  const ncrType = get(form, 'ncrType.key', Constants.NCR_TYPES.NCR)
  const ncrDetails = `${ncrType === Constants.NCR_TYPES.INCIDENT ? 'Incident': 'NCR'} Details`
  const filteredNonConformanceSubTypesOptions = nonConformanceSubTypesOptions.filter(
    subCategoryOption => get(formikValues.nonConformanceType, 'value', null) === subCategoryOption.nonConformanceTypeId
  );

  const willMakeClaim = get(formikValues, 'ncrDetails.willMakeClaim');
  const editingExistingNCR = get(formikValues, 'editingExistingNCR');
  const initialClaimValue = get(formikValues, 'ncrDetails.initialClaimValue');
  
  const isMultiPacker = () => {
    if (ncrType === Constants.NCR_TYPES.INCIDENT){
      let poLines = get(formikValues, 'purchaseOrderLineIds',[])
      let packerNames = poLines.map( poLine => poLine.packerName)
      if ( new Set(packerNames).size > 1){
        return true
      } else {
        return false
      }
    }
    return false
  }

  return (
    <DashboardSectionTile title={ncrDetails} padding="16px 28px 145px 18px" position="relative">
      <VStackLayout divider={null} spacing="25px" margin="0 !important">
        {currentStage === 0 ? (
          <>
              {ncrType === Constants.NCR_TYPES.INCIDENT ? (
                <Box marginTop="4px">
                  <PreambleText fontSize="16px">Completed By</PreambleText>
                  <HStack spacing="32px" marginTop="18px">
                    <FieldGroup label="Name">
                      <Input value={`${get(user, 'firstName')} ${get(user, 'lastName')}`} isDisabled />
                    </FieldGroup>
                    <FieldGroup label="Role">
                      <Input value={get(user, 'role.name')} isDisabled />
                    </FieldGroup>
                    <FieldGroup label="Email">
                      <Input value={get(user, 'email')} isDisabled />
                    </FieldGroup>
                    <FieldGroup label="Phone">
                      <Input value={get(user, 'phone')} isDisabled />
                    </FieldGroup>
                  </HStack>
              </Box>
              ) : ('')}
            <HStack spacing="32px">
              <FieldGroup label="Category">
                <SelectField
                  placeholder="Category"
                  name="nonConformanceType"
                  value={formikValues.nonConformanceType}
                  className={formikValues.highlightInputError && errors.nonConformanceType && 'error'}
                  onChange={e => {
                    onUpdateMany({
                      nonConformanceType: e,
                      nonConformanceSubType: null,
                    });
                  }}
                  options={nonConformanceTypesOptions}
                />
              </FieldGroup>
              <FieldGroup label="Sub-Category">
                <SelectField
                  placeholder="Sub-Category"
                  name="nonConformanceSubType"
                  value={formikValues.nonConformanceSubType}
                  className={formikValues.highlightInputError && errors.nonConformanceSubType && 'error'}
                  onChange={e => onUpdate('nonConformanceSubType', e)}
                  isDisabled={isEmpty(formikValues.nonConformanceType)}
                  options={filteredNonConformanceSubTypesOptions}
                />
              </FieldGroup>
              <FieldGroup label="Entry Date">
                <Datetime
                  {...dateTimeProps}
                  value={moment(get(formikValues, 'created', undefined))}
                  inputProps={{
                    ...dateTimeProps.inputProps,
                    disabled: true,
                  }}
                />
              </FieldGroup>
            </HStack>
            {ncrType !== Constants.NCR_TYPES.INCIDENT ? (
              <HStack spacing="32px">
              <FieldGroup label="Process Stage">
                <SelectField
                  placeholder="Process Stage"
                  name="processStage"
                  value={processStageOptions.find(
                    processStageOption => processStageOption.value === get(formikValues, 'ncrDetails.processStage')
                  )}
                  className={formikValues.highlightInputError && get(errors, 'ncrDetails.processStage') && 'error'}
                  onChange={e => onUpdate('ncrDetails.processStage', e.value)}
                  options={processStageOptions}
                />
              </FieldGroup>
              <FieldGroup label="Product Status">
                <SelectField
                  placeholder="Status"
                  name="productStatus"
                  value={productStatusOptions.find(
                    productStatusOption => productStatusOption.value === get(formikValues, 'ncrDetails.productStatus')
                  )}
                  className={formikValues.highlightInputError && get(errors, 'ncrDetails.productStatus') && 'error'}
                  onChange={e => onUpdate('ncrDetails.productStatus', e.value)}
                  options={productStatusOptions}
                />
              </FieldGroup>
              <FieldGroup label="Target Closure Date">
                <Datetime
                  {...dateTimeProps}
                  value={moment(get(formikValues, 'ncrDetails.targetClosureDate', null))}
                  className={formikValues.highlightInputError && get(errors, 'ncrDetails.targetClosureDate') && 'error'}
                  onChange={value => {
                    const { _isAMomentObject: isAMomentObject } = value;
                    onUpdate('ncrDetails.targetClosureDate', isAMomentObject ? value.format() : null);
                  }}
                />
              </FieldGroup>
            </HStack>
            ) : ('')}
            <CheckboxGroup colorScheme="actionPrimary">
              {ncrType !== Constants.NCR_TYPES.INCIDENT ? (
              <HStack spacing="52px" direction={['column', 'row']}>
                <Checkbox
                  isChecked={get(formikValues, 'ncrDetails.isOnHold')}
                  className={formikValues.highlightInputError && get(errors, 'ncrDetails.isOnHold') && 'error'}
                  onChange={e => onUpdate('ncrDetails.isOnHold', e.target.checked)}
                  marginBottom={0}
                >
                  Is there raw material on hold?{' '}
                </Checkbox>
                <Checkbox
                  isChecked={get(formikValues, 'ncrDetails.isFinishedGoods')}
                  className={formikValues.highlightInputError && get(errors, 'ncrDetails.isFinishedGoods') && 'error'}
                  onChange={e => onUpdate('ncrDetails.isFinishedGoods', e.target.checked)}
                >
                  Are there finished goods on hold?{' '}
                </Checkbox>
                <Checkbox
                  isChecked={willMakeClaim}
                  className={formikValues.highlightInputError && get(errors, 'ncrDetails.willMakeClaim') && 'error'}
                  onChange={e => {
                    const updateValues = {
                      ncrDetails: {
                        willMakeClaim: e.target.checked,
                        ...(editingExistingNCR &&
                          !initialClaimValue && {
                            initialClaimValue: calculateInitialClaimValue(
                              get(formikValues, 'amountAffected', 0),
                              get(formikValues, 'ncrDetails.purchaseOrderLine.sellPricePerUnit', 0),
                              getOr(formikValues, 'ncrDetails.unitOfMeasure', 0),
                              get(formikValues, 'ncrDetails.purchaseOrderLine.purchaseOrder.sellUnitOfMeasure')
                            ),
                          }),
                      },
                    };
                    onUpdateMany(updateValues);
                  }}
                >
                  Will this NCR have a claim value?
                </Checkbox>
              </HStack>) :  ('')}
              {ncrType === Constants.NCR_TYPES.INCIDENT ? (
                <HStack spacing="52px" direction={['column', 'row']}>
                  <Checkbox
                    isChecked={get(formikValues, 'ncrDetails.isIncident')}
                    className={formikValues.highlightInputError && get(errors, 'ncrDetails.isIncident') && 'error'}
                    onChange={e => onUpdate('ncrDetails.isIncident', e.target.checked)}
                    marginBottom={0}
                  >
                    Quality Incident
                  </Checkbox>
                  <Checkbox
                    isChecked={isMultiPacker()}
                    onChange={e => onUpdate('ncrDetails.isMultiPacker', e.target.checked)}
                    marginBottom={0}
                    disabled
                  >
                    Multi-packer incident
                </Checkbox>  
                <Checkbox
                    isChecked={get(formikValues, 'ncrDetails.isEntrapment')}
                    className={formikValues.highlightInputError && get(errors, 'ncrDetails.isEntrapment') && 'error'}
                    onChange={e => onUpdate('ncrDetails.isEntrapment', e.target.checked)}
                    marginBottom={0}
                  >
                    Entrapment incident
                </Checkbox>                                                
                </HStack>
              ) : ('')}
            </CheckboxGroup>
            <Box marginTop="42px !important">
              <Flex>
                <FormLabel>Details</FormLabel>
                <Popover placement="right">
                  <PopoverTrigger>
                    <QuestionOutlineIcon boxSize="14px" marginTop="4px" />
                  </PopoverTrigger>
                  <PopoverContent
                    width="406px"
                    left="8px"
                    _focus={{
                      outline: 'none',
                    }}
                    boxShadow="0px 1.25px 4px rgba(0, 0, 0, 0.25)"
                  >
                    <PopoverArrow />
                    <PopoverCloseButton top="16px" right="14px" />
                    <PopoverBody padding="16px 46px 16px 19px">
                      <Flex>
                        <QuestionOutlineIcon boxSize="16px" marginTop="6px" />
                        <Text marginLeft="8px" fontSize="16px">
                          Please provide full and clear information regarding {ncrType === Constants.NCR_TYPES.INCIDENT ? 'incident' : 'claim'} finding. Include: what was found,
                          where it was found, how it was found, and what happened as a result.
                        </Text>
                      </Flex>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Flex>
              <Textarea
                rows={6}
                placeholder="Details"
                name="details"
                value={get(formikValues, 'ncrDetails.details')}
                className={formikValues.highlightInputError && get(errors, 'ncrDetails.details') && 'error'}
                onChange={e => onUpdate('ncrDetails.details', e.target.value)}
              />
            </Box>
          </>
        ) : (
          ''
        )}
        {currentStage === 1 ? (
          <>
            <Box marginTop="4px">
              <PreambleText fontSize="16px">Completed By</PreambleText>
              <HStack spacing="32px" marginTop="18px">
                <FieldGroup label="Name">
                  <Input value={`${get(user, 'firstName')} ${get(user, 'lastName')}`} isDisabled />
                </FieldGroup>
                <FieldGroup label="Role">
                  <Input value={get(user, 'role.name')} isDisabled />
                </FieldGroup>
                <FieldGroup label="Email">
                  <Input value={get(user, 'email')} isDisabled />
                </FieldGroup>
                <FieldGroup label="Phone">
                  <Input value={get(user, 'phone')} isDisabled />
                </FieldGroup>
              </HStack>
            </Box>
            <Box>
              <FormLabel>Immediate Actions *</FormLabel>
              <Textarea
                rows={6}
                placeholder="Immediate Actions"
                name="immediateAction"
                value={get(formikValues, 'ncrDetails.immediateAction')}
                className={formikValues.highlightInputError && get(errors, 'ncrDetails.immediateAction') && 'error'}
                onChange={e => onUpdate('ncrDetails.immediateAction', e.target.value)}
              />
            </Box>
            <Box>
              <FormLabel>Further Actions</FormLabel>
              <Textarea
                rows={6}
                placeholder="Further Actions"
                name="furtherAction"
                value={get(formikValues, 'ncrDetails.furtherAction')}
                className={formikValues.highlightInputError && get(errors, 'ncrDetails.furtherAction') && 'error'}
                onChange={e => onUpdate('ncrDetails.furtherAction', e.target.value)}
              />
            </Box>
            {willMakeClaim ? (
              <FieldGroup label="Initial Estimated NCR Claim Value">
                <Flex alignItems="center">
                  <Input
                    placeholder="10000"
                    width="169px"
                    type="number"
                    name="initialClaimValue"
                    value={initialClaimValue}
                    className={
                      formikValues.highlightInputError && get(errors, 'ncrDetails.initialClaimValue') && 'error'
                    }
                    onChange={e => onUpdate('ncrDetails.initialClaimValue', e.target.value)}
                  />
                  <PreambleText marginLeft="8px">
                    {get(form, 'purchaseOrder.sellCurrency', '') 
                    ? get(form, 'purchaseOrder.sellCurrency', '') 
                    : get(formikValues, 'ncrDetails.purchaseOrderLine.purchaseOrder.sellCurrency', '')} 
                  </PreambleText>
                </Flex>
              </FieldGroup>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
      </VStackLayout>
      {currentStage === 1 && willMakeClaim ? (
        <TipCard position="absolute" bottom="47px" right="66px" width="256px">
          <Text>
            This value is the Grinder&apos;s initial proposed claim value. This value is subject to change as the Claim
            process is continued.
          </Text>
        </TipCard>
      ) : (
        ''
      )}
    </DashboardSectionTile>
  );
};

NCRDetails.propTypes = {
  currentStage: PropTypes.number,
  form: PropTypes.objectOf(PropTypes.any),
  onUpdate: PropTypes.func,
  onUpdateMany: PropTypes.func,
  nonConformanceTypesOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  nonConformanceSubTypesOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  user: PropTypes.objectOf(PropTypes.any),
};

export default NCRDetails;
