import axios from 'axios';
import get from 'lodash/get';
import moment from 'moment';
import queryString from 'query-string';
import Constants from '../Constants';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 15;
const DEFAULT_EXCEL_PAGE_SIZE = 15000;

const BASE_ORDER_URL = `${Constants.URL}invoice-orders`;
const BASE_INVOICE_URL = `${Constants.URL}invoices`;
const INVOICING_EMAIL_URL = `${BASE_INVOICE_URL}/mail`;
const FETCH_PACKER_PLANT_INVOICE_POS_URL = `${BASE_INVOICE_URL}/packer-invoice-orders`;
const GENERATE_PACKER_PLANT_INVOICE_DRAWDOWN_REPORT = `${BASE_INVOICE_URL}/packer-invoice-orders`;
const DOMESTIC_INVOICE_SUMMARY_URL = `${Constants.URL}invoice-summary`;
const APF_INVOICE_SUMMARY_URL = `${DOMESTIC_INVOICE_SUMMARY_URL}/apf`;
const FETCH_DRAW_DOWN_HISTORICAL_URL = `${BASE_INVOICE_URL}/packer-invoiced-drawdowns`;

export const REQUEST_INVOICING_ORDERS = 'REQUEST_INVOICING_ORDERS';
export const LOAD_INVOICING_ORDERS = 'LOAD_INVOICING_ORDERS';
export const BEFORE_CREATE_AND_SEND_INVOICES = 'BEFORE_CREATE_AND_SEND_INVOICES';
export const CREATE_AND_SEND_INVOICES = 'CREATE_AND_SEND_INVOICES';
export const GENERATE_INVOICE = 'GENERATE_INVOICE';
export const GENERATE_INVOICES = 'GENERATE_INVOICES';
export const PACKER_PLANT_INVOICE_POS = 'PACKER_PLANT_INVOICE_POS';
export const REQUEST_PACKER_PLANT_INVOICE_POS = 'REQUEST_PACKER_PLANT_INVOICE_POS';
export const DRAW_DOWN_LINK = 'DRAW_DOWN_LINK';
export const LOAD_INVOICE_SUMMARY = 'LOAD_INVOICE_SUMMARY';
export const DRAW_DOWN_HISTORICAL = 'DRAW_DOWN_HISTORICAL';
// Errors
export const CLEAR_INVOICE_ERROR = 'CLEAR_INVOICE_ERROR';
export const RESET_INVOICING_STATE = 'RESET_INVOICING_STATE';
export const ERROR_INVOICING_ORDERS = 'ERROR_INVOICING_ORDERS';
export const ERROR_CREATE_AND_SEND_INVOICES = 'ERROR_CREATE_AND_SEND_INVOICES';
export const ERROR_GENERATE_INVOICE = 'ERROR_GENERATE_INVOICE';
export const ERROR_GENERATE_INVOICES = 'ERROR_GENERATE_INVOICES';
export const ERROR_GET_PACKER_PLANT_INVOICE_POS = 'ERROR_GET_PACKER_PLANT_INVOICE_POS';
export const ERROR_GENERATE_INVOICE_SUMMARY = 'ERROR_GENERATE_INVOICE_SUMMARY';

const createAuthHeaders = token => ({
  'Content-Type': 'application/json',
  Authorization: `Token ${token}`,
});

/* ----------------------------------
  ACTIONS
-------------------------------------*/
export function requestOrdersToInvoice() {
  return {
    type: REQUEST_INVOICING_ORDERS,
  };
}
export function loadOrdersToInvoice(orders, pagination) {
  return {
    type: LOAD_INVOICING_ORDERS,
    orders,
    pagination,
  };
}

export function generateInvoices(invoices) {
  return {
    type: GENERATE_INVOICES,
    invoices,
  };
}

export function generateInvoice(invoice) {
  return {
    type: GENERATE_INVOICE,
    invoice,
  };
}

export function beforeCreateAndSendInvoices() {
  return {
    type: BEFORE_CREATE_AND_SEND_INVOICES,
    isSendingInvoices: true,
  };
}

export function createAndSendInvoices(invoices) {
  return {
    type: CREATE_AND_SEND_INVOICES,
    invoices,
    isSendingInvoices: false,
  };
}

export function loadInvoiceSummary(invoiceSummary) {
  return {
    type: LOAD_INVOICE_SUMMARY,
    invoiceSummary,
  };
}

export function resetInvoicingState() {
  return {
    type: RESET_INVOICING_STATE,
  };
}

export function requestPackerInvoicePos() {
  return {
    type: REQUEST_PACKER_PLANT_INVOICE_POS,
  };
}

export function getPackerInvoicePos(packerInvoiceOrders) {
  return {
    type: PACKER_PLANT_INVOICE_POS,
    packerInvoiceOrders,
  };
}

export function downloadDrawDownReport(generatedDrawDownLink) {
  return {
    type: DRAW_DOWN_LINK,
    generatedDrawDownLink,
  };
}

export function errorGetPackerInvoicePos(error) {
  return {
    type: ERROR_GET_PACKER_PLANT_INVOICE_POS,
    error,
  };
}

export function errorOrdersToInvoice(error) {
  return {
    type: ERROR_INVOICING_ORDERS,
    error,
  };
}

export function errorGenerateInvoice(error) {
  return {
    type: ERROR_GENERATE_INVOICE,
    error,
  };
}
export function errorGenerateInvoices(error) {
  return {
    type: ERROR_GENERATE_INVOICES,
    error,
  };
}

export function errorCreateAndSendInvoices(error) {
  return {
    type: ERROR_CREATE_AND_SEND_INVOICES,
    error,
    isSendingInvoices: false,
  };
}

export function clearInvoiceError(error) {
  return {
    type: CLEAR_INVOICE_ERROR,
    error,
  };
}

export function errorGenerateInvoiceSummary(error) {
  return {
    type: ERROR_GENERATE_INVOICE_SUMMARY,
    error,
  };
}

export function getPackerInvoicedPos(drawDownHistoricalData, pagination, pageCount) {
  return {
    type: DRAW_DOWN_HISTORICAL,
    drawDownHistoricalData,
    pagination,
    pageCount,
  };
}

/* ----------------------------------
  THUNKS
-------------------------------------*/
export function fetchOrdersToInvoice(
  token,
  {
    page = DEFAULT_PAGE,
    size = DEFAULT_PAGE_SIZE,
    grinder,
    packer_plant: packerPlant,
    dateType,
    dateTypeStart,
    dateTypeEnd,
    invoiceType,
    priceStatus,
    endUserId,
    invoiceOffice,
  }
) {
  let status = [Constants.INVOICE_STATUSES.TODO];
  if (invoiceType && invoiceType.toUpperCase() === Constants.INVOICE_TYPES.FINAL) {
    status = [Constants.INVOICE_STATUSES.INVOICED_PRIOR];
  }

  const query = queryString.stringify({
    page,
    size,
    grinder,
    packer_plant: packerPlant,
    status,
    dateType,
    dateTypeStart,
    dateTypeEnd,
    priceStatus,
    endUserId,
    invoiceOffice,
  });
  return async dispatch => {
    const headers = createAuthHeaders(token);
    try {
      dispatch(requestOrdersToInvoice());
      const response = await axios.get(`${BASE_ORDER_URL}?${query}`, { headers });
      Constants.handleErrors(response, dispatch, errorOrdersToInvoice);
      const json = get(response, 'data', []);
      dispatch(loadOrdersToInvoice(json.purchase_orders, json.pagination));
    } catch (e) {
      dispatch(errorOrdersToInvoice(e));
    }
  };
}

export async function fetchOrderInvoicesToExport(
  token,
  {
    page = DEFAULT_PAGE,
    size = DEFAULT_EXCEL_PAGE_SIZE,
    grinder,
    packer_plant: packerPlant,
    dateType,
    dateTypeStart,
    dateTypeEnd,
    invoiceType,
    priceStatus,
    endUserId,
  }
) {
  let status = [Constants.INVOICE_STATUSES.TODO];
  if (invoiceType && invoiceType.toUpperCase() === Constants.INVOICE_TYPES.FINAL) {
    status = [Constants.INVOICE_STATUSES.INVOICED_PRIOR];
  }

  const query = queryString.stringify({
    page,
    size,
    grinder,
    packer_plant: packerPlant,
    status,
    dateType,
    dateTypeStart,
    dateTypeEnd,
    priceStatus,
    endUserId,
  });
  const headers = createAuthHeaders(token);
  try {
    const response = await axios.get(`${BASE_ORDER_URL}?${query}`, { headers });
    const json = get(response, 'data', []);
    const orderList = json.purchase_orders.reduce(
      (agg, order) => [
        ...agg,
        {
          ...order.purchase_order,
          grinder: order.purchase_order.grinder,
          packer_plant: order.purchase_order.packer_plant,
          priceBucket: order.price_bucket,
        },
      ],
      []
    );
    return orderList;
  } catch (e) {
    return [];
  }
}

export function previewInvoices(token, orderList) {
  return async dispatch => {
    const headers = createAuthHeaders(token);
    try {
      const response = await axios.post(
        `${BASE_INVOICE_URL}/pdf`,
        {
          po_list: orderList,
        },
        { headers }
      );
      Constants.handleErrors(response, dispatch, errorGenerateInvoices);
      const invoices = get(response, 'data.invoice_list', []);
      dispatch(generateInvoices(invoices));
    } catch (e) {
      dispatch(errorGenerateInvoices(e));
    }
  };
}

export function previewInvoice(token, { weightType, invoiceDueDate, orderId, type }) {
  return async dispatch => {
    const headers = createAuthHeaders(token);
    try {
      const response = await axios.post(
        `${BASE_INVOICE_URL}/${orderId}`,
        {
          invoice_weight_type: weightType,
          invoice_payment_due_date: invoiceDueDate,
          invoice_type: type,
        },
        { headers }
      );
      Constants.handleErrors(response, dispatch, errorGenerateInvoice);
      const invoices = get(response, 'data', []);
      dispatch(generateInvoice(invoices));
    } catch (e) {
      dispatch(errorGenerateInvoice(e));
    }
  };
}

export function sendInvoicingEmail(token, { poList, recipients, isBatch, subject, body }) {
  return async dispatch => {
    const headers = createAuthHeaders(token);
    dispatch(beforeCreateAndSendInvoices());
    try {
      const response = await axios.post(
        INVOICING_EMAIL_URL,
        {
          po_list: poList,
          recipients_list: recipients,
          isBatch: isBatch,
          email_subject: subject,
          email_body: body,
        },
        { headers }
      );
      Constants.handleErrors(response, dispatch, errorCreateAndSendInvoices);
      const invoices = get(response, 'data.invoice_list', []);
      dispatch(createAndSendInvoices(invoices));
    } catch (e) {
      dispatch(errorCreateAndSendInvoices(e));
    }
  };
}

export function generateInvoiceSummary(token, invoiceList, grinderUid) {
  return async dispatch => {
    const headers = createAuthHeaders(token);
    try {
      let response;
      // Check for historic and new Anzco Prepared Foods Uids
      if (['APFNZ', 'APFWA'].includes(grinderUid)) {
        response = await axios.post(APF_INVOICE_SUMMARY_URL, { invoice_list: invoiceList }, { headers });
      } else {
        response = await axios.post(DOMESTIC_INVOICE_SUMMARY_URL, { invoice_list: invoiceList }, { headers });
      }

      Constants.handleErrors(response, dispatch, errorGenerateInvoiceSummary);
      const s3Link = get(response, 'data.url', '');
      dispatch(loadInvoiceSummary(s3Link));
    } catch (e) {
      dispatch(errorGenerateInvoiceSummary(e));
    }
  };
}

/* --- Draw Down Reports --- */
export function fetchPackerInvoicePurchaseOrders(
  token,
  { isNotInPreviousReports, readyForPaymentStartDate, readyForPaymentEndDate, invoicingOffice }
) {
  const queryObject = {
    isNotInPreviousReports,
    invoicingOffice,
  };
  if (moment(readyForPaymentStartDate, 'YYYY-MM-DD', true).isValid()) {
    queryObject['readyForPaymentStartDate'] = readyForPaymentStartDate;
  }
  if (moment(readyForPaymentEndDate, 'YYYY-MM-DD', true).isValid()) {
    queryObject['readyForPaymentEndDate'] = readyForPaymentEndDate;
  }
  const queryFilters = queryString.stringify(queryObject);
  return async dispatch => {
    const headers = createAuthHeaders(token);
    try {
      dispatch(requestPackerInvoicePos());
      const response = await axios.get(`${FETCH_PACKER_PLANT_INVOICE_POS_URL}?${queryFilters}`, { headers });
      Constants.handleErrors(response, dispatch, errorGetPackerInvoicePos);
      const packerInvoiceOrders = get(response, 'data', []);
      dispatch(getPackerInvoicePos(packerInvoiceOrders));
    } catch (e) {
      dispatch(errorGetPackerInvoicePos(e));
    }
  };
}

export function createPackerInvoiceDrawDownReports(token, { grinderPOs, filters, fmgPOs }) {
  return async dispatch => {
    const headers = createAuthHeaders(token);
    const clientLocalDateTime = moment().format('MM-DD-YYYY - hh.mm.ss A');
    try {
      const response = await axios.post(
        `${GENERATE_PACKER_PLANT_INVOICE_DRAWDOWN_REPORT}`,
        { grinderPOs, filters, clientLocalDateTime, fmgPOs },
        { headers }
      );
      Constants.handleErrors(response, dispatch, errorGetPackerInvoicePos);
      const generatedDrawDownLink = get(response, 'data.downloadableURL', '');
      dispatch(downloadDrawDownReport(generatedDrawDownLink));
    } catch (e) {
      dispatch(errorGetPackerInvoicePos(e));
    }
  };
}

export function fetchPackerInvoicedPurchaseOrders(token, { pageNum }) {
  return async dispatch => {
    const headers = createAuthHeaders(token);
    try {
      const response = await axios.get(`${FETCH_DRAW_DOWN_HISTORICAL_URL}?pageNum=${pageNum}`, { headers });
      Constants.handleErrors(response, dispatch, errorGetPackerInvoicePos);
      const packerInvoicedOrders = get(response, 'data', []);
      dispatch(
        getPackerInvoicedPos(
          packerInvoicedOrders.draw_down_historical_data,
          packerInvoicedOrders.pagination,
          packerInvoicedOrders.count
        )
      );
    } catch (e) {
      dispatch(errorGetPackerInvoicePos(e));
    }
  };
}
