import { Text, VStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ResultsGrid from '../basic/ResultsGrid';
import { configureColumns } from './invoiceGridConfig';
import './InvoiceResults.scss';

class InvoiceResults extends Component {
  static propTypes = {
    pagination: PropTypes.shape({
      pages: PropTypes.number,
    }),
    dateType: PropTypes.string,
    ordersList: PropTypes.arrayOf(PropTypes.object),
    selectedRows: PropTypes.arrayOf(PropTypes.object),
    onRowSelect: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    packageWeights: PropTypes.arrayOf(PropTypes.object),
    internalPoLabel: PropTypes.string,
  };

  columns = configureColumns(this.props, [
    'selector',
    'internal_po_number',
    'grinder_po_number',
    'grinder_name',
    'date_type',
    'po_weight',
    'shipped_weight',
    'remaining_weight',
    'received_weight',
    'status',
  ]);

  render() {
    const { ordersList, pagination, onRowSelect, selectedRows, onPageChange } = this.props;
    return (
      <VStack spacing="19px" align="stretch">
        {/* <!-- TEXT --> */}
        <Text as="p" fontWeight="medium">
          Choose which items to add to the invoicing cart below
        </Text>
        {/* <!-- GRID --> */}
        <ResultsGrid
          id="invoice-results"
          className="invoice-results__grid results-grid--minimal"
          {...{
            columns: this.columns,
            onPageChange,
            onRowSelect,
            pagination,
            selectedRows,
            rows: ordersList,
          }}
        />
      </VStack>
    );
  }
}

export default InvoiceResults;
