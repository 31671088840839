import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BulkUpdaterSubmissionModal from '../../components/bulkUpdater/BulkUpdaterSubmissionModal';
import { selectBulkUpdaterSubmissionData, submitBulkUpdater } from '../../slices/bulk-updater/bulkUpdaterSlice';
import { fetchEntitiesData } from '../../slices/masterData/entityManagerSlice';
import views from './bulk-updater-views';
import BulkUpdaterEditor from './components/BulkUpdaterEditor';
import BulkUpdaterSearch from './components/BulkUpdaterSearch';
import './BulkUpdaterPage.scss';
import { fetchOrderStatusMap } from '../../slices/order-status-map/orderStatusMapSlice';

const steps = {
  search: 1,
  editor: 2,
};
export default () => {
  const dispatch = useDispatch();
  const submissionData = useSelector(selectBulkUpdaterSubmissionData);

  const [step, setStep] = useState(steps.search);
  const [view, setView] = useState(views.default);
  const [submissionModalIsOpen, setSubmissionModalIsOpen] = useState(false);

  // pos selected in the search result
  const [selectedResults, setSelectedResults] = useState([]);

  useEffect(() => {
    dispatch(
      fetchEntitiesData([
        'input_product',
        'grinder',
        'packer',
        'packer_plant',
        'form_fields',
        'transporter',
        'freight_rate',
        'port',
        'coldstore',
        'shipping_line',
      ])
    );
    dispatch(fetchOrderStatusMap());
  }, []);

  const onClickEditModeButton = selectedSearchResults_ => {
    setSelectedResults(selectedSearchResults_);
    setStep(steps.editor);
  };

  const onClickExitButton = () => setStep(steps.search);

  const onSubmitBulkUpdate = posChanges => {
    dispatch(submitBulkUpdater(posChanges));
    setSubmissionModalIsOpen(true);
  };

  return (
    <Box>
      {step === steps.search && (
        <BulkUpdaterSearch view={view} setView={setView} onClickEditModeButton={onClickEditModeButton} />
      )}
      {step === steps.editor && (
        <BulkUpdaterEditor
          view={view}
          selectedResults={selectedResults}
          onClickExitButton={onClickExitButton}
          onSubmitBulkUpdate={onSubmitBulkUpdate}
        />
      )}
      <BulkUpdaterSubmissionModal
        {...{
          error: submissionData.error,
          isOpen: submissionModalIsOpen,
          isSubmitting: submissionData.isSubmitting,
          onBackToSummaryClick: () => setSubmissionModalIsOpen(false),
          onCloseModal: () => setSubmissionModalIsOpen(false),
          onNewSearchClick: () => {
            setSubmissionModalIsOpen(false);
            onClickExitButton();
          },
          wasSuccessful: submissionData.wasSuccessful,
        }}
      />
    </Box>
  );
};
