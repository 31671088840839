import { createSelector } from '@reduxjs/toolkit';
import { group, mean, median, sum } from 'd3';
import { selectTreasuryDetailsShowOutlook } from '../../treasury-details/treasuryDetailsReducers';
import { TREASURY_MODULE } from '../treasuryConfig';
import { selectTreasuryAnnotationsData } from './selectTreasuryBasicData';
import {
  selectTreasuryTimeGroupedData,
  selectTreasuryTimeGroupedDataWithForecast,
} from './selectTreasuryTimeGroupedData';

const breakdown = (array, name, totalVolume) => {
  const volume = sum(array, order => order.volume_received || 0);
  const forecastedVolume = sum(array, order => order.volume_expected || 0);
  const volumeRatio = volume / totalVolume;
  const forecastedVolumeRatio = forecastedVolume / totalVolume;
  const averageCostPrice = mean(array, order => order.buy_price_per_unit);
  const medianCostPrice = median(array, order => order.buy_price_per_unit);
  const usdaCostPrice = mean(array, order => order.usda_market_price);
  const costSavings = sum(array, order => {
    if (!order.buy_price_per_unit || !order.usda_market_price || !order.volume_received) {
      return 0;
    }
    return (order.buy_price_per_unit - order.usda_market_price) * order.volume_received;
  });
  const costTotal = sum(array, order => {
    if (!order.buy_price_per_unit || !order.volume_received) {
      return 0;
    }
    return order.buy_price_per_unit * order.volume_received;
  });

  return {
    orderType: name,
    volume,
    forecastedVolume,
    volumeRatio,
    forecastedVolumeRatio,
    averageCostPrice,
    medianCostPrice,
    usdaCostPrice,
    costSavings,
    costTotal,
  };
};

const breakdownByEntity = (array, name, entityKey) => {
  const totalVolume = sum(array, order => order.volume_received || order.volume_expected);
  const totalGroupedByEntity = group(array, order => order[entityKey]);
  const totalBreakdownByEntity = Array.from(totalGroupedByEntity.entries()).map(([orderEntity, entityOrders]) => {
    return breakdown(entityOrders, orderEntity, totalVolume);
  });

  return { type: name, volumeBreakdown: totalBreakdownByEntity };
};

const breakdownByType = (array, name, totalVolume) => {
  const totalBreakdownByType = breakdown(array, name, totalVolume);

  return totalBreakdownByType;
};

const getTreasuryDataPerEntity = (timeGrouped, treasuryAnnotations, entityKey) => {
  if (!timeGrouped) return null;

  const annotations = treasuryAnnotations[TREASURY_MODULE.RISK_MANAGEMENT];

  const data = [];
  timeGrouped.forEach(year => {
    const newIntervals = year.intervals.map(interval => {
      const groupedByOrderType = group(interval.orders, order => order.order_type);

      const totalBreakdownByEntity = breakdownByEntity(interval.orders, 'Total', entityKey);
      const hedgedBreakdownByEntity = breakdownByEntity(
        interval.orders.filter(x => x.order_type === 'Contract' || x.order_type === 'NOF'),
        'Hedged',
        entityKey
      );
      const exposedBreakdownByEntity = breakdownByEntity(
        interval.orders.filter(x => x.order_type !== 'Contract' && x.order_type !== 'NOF'),
        'Exposed',
        entityKey
      );

      const typeBreakdown = Array.from(groupedByOrderType.entries()).map(([orderType, typeOrders]) => {
        const totalVolumeAcrossType = sum(typeOrders, order => order.volume_received || order.volume_expected);

        const groupedByEntity = group(typeOrders, order => order[entityKey]);
        const volumeBreakdown = Array.from(groupedByEntity.entries()).map(([orderEntity, entityOrders]) => {
          return breakdown(entityOrders, orderEntity, totalVolumeAcrossType);
        });
        return {
          type: orderType,
          volumeBreakdown,
        };
      });

      const groupedByEntity = group(interval.orders, order => order[entityKey]);

      const entityBreakdown = Array.from(groupedByEntity.entries()).map(([orderEntity, entityOrders]) => {
        const totalVolumeAcrossEntity = sum(entityOrders, order => order.volume_received || order.volume_expected);
        const groupedByOrderTypeInner = group(entityOrders, order => order.order_type);
        const volumeBreakdown = Array.from(groupedByOrderTypeInner.entries()).map(([orderType, typeOrders]) => {
          return breakdown(typeOrders, orderType, totalVolumeAcrossEntity);
        });
        const totalBreakdownByType = breakdownByType(entityOrders, 'Total', totalVolumeAcrossEntity);
        const hedgedBreakdownByType = breakdownByType(
          entityOrders.filter(x => x.order_type === 'Contract' || x.order_type === 'NOF'),
          'Hedged',
          totalVolumeAcrossEntity
        );
        const exposedBreakdownByType = breakdownByType(
          entityOrders.filter(x => x.order_type !== 'Contract' && x.order_type !== 'NOF'),
          'Exposed',
          totalVolumeAcrossEntity
        );

        return {
          entity: orderEntity,
          volumeBreakdown: volumeBreakdown.concat([
            totalBreakdownByType,
            hedgedBreakdownByType,
            exposedBreakdownByType,
          ]),
        };
      });

      const matchingAnnotation = annotations.find(
        annotation => annotation.interval === interval.interval && annotation.year === interval.year
      );

      return {
        interval: `${interval.interval} ${interval.year}`,
        rawInterval: { year: interval.year, interval: interval.interval },
        typeBreakdown: typeBreakdown.concat([
          totalBreakdownByEntity,
          hedgedBreakdownByEntity,
          exposedBreakdownByEntity,
        ]),
        entityBreakdown,
        annotation: matchingAnnotation || null,
      };
    });

    data.push(...newIntervals);
  });

  return data;
};

export const selectTreasuryDataPerPackerPlant = createSelector(
  selectTreasuryTimeGroupedData,
  selectTreasuryTimeGroupedDataWithForecast,
  selectTreasuryAnnotationsData,
  selectTreasuryDetailsShowOutlook,
  (timeGrouped, timeGroupedWithForecast, treasuryAnnotations, showOutlook) => {
    const groupedData = showOutlook ? timeGroupedWithForecast : timeGrouped;
    return getTreasuryDataPerEntity(groupedData, treasuryAnnotations, 'packer_plant_name');
  }
);

export const selectTreasuryDataPerGrinder = createSelector(
  selectTreasuryTimeGroupedData,
  selectTreasuryTimeGroupedDataWithForecast,
  selectTreasuryAnnotationsData,
  selectTreasuryDetailsShowOutlook,
  (timeGrouped, timeGroupedWithForecast, treasuryAnnotations, showOutlook) => {
    const groupedData = showOutlook ? timeGroupedWithForecast : timeGrouped;
    return getTreasuryDataPerEntity(groupedData, treasuryAnnotations, 'grinder_name');
  }
);
