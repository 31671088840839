import Constants from '../Constants';

export const WEIGHT_FETCHED = 'WEIGHT_FETCHED';

const BASE_URL = `${Constants.URL}`;

export function weightInDesiredUnit(weight) {
  return {
    type: WEIGHT_FETCHED,
    payload: weight,
  };
}

export function fetchPackageWeights() {
  const accessToken = localStorage.getItem('boabUser');
  if (!accessToken) {
    return {};
  }
  const json = JSON.parse(accessToken);
  const { token } = json;
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}package_weights`, {
      method: 'GET',
      headers: header,
    })
      .then(resp => {
        if (!resp.ok) {
          throw new Error(resp.statusText);
        }
        return resp.json();
      })
      .then(jsonResponse => dispatch(weightInDesiredUnit(jsonResponse)))
      .catch(err => {
        console.log(err);
        return {};
      });
  };
}
