const schema = [
  {
    name: 'port',
    displayName: 'Port',
    nested: false,
    systemIdField: 'id',
    displayIdField: 'name',
    tabGroup: { key: 'logistics', label: 'Logistics' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'Official name of the port for identification within the system',
        dataType: 'shorttext',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'iso_code',
        displayName: 'ISO Code',
        tooltip: 'Unique International Standard Organization (ISO) code for the port, used in international shipping and trade',
        dataType: 'shorttext',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'address',
        displayName: 'Address',
        dataType: 'reference',
        referenceType: 'nested',
        referenceEntityType: 'address_port',
      },
      {
        name: 'timezone',
        displayName: 'Timezone',
        tooltip: 'Timezone of the port',
        dataType: 'shorttext',
        systemIdentifier: false,
      },
      {
        name: 'lat',
        displayName: 'Latitude',
        tooltip: 'Geographic latitude of the port.',
        dataType: 'number',
        systemIdentifier: false,
      },
      {
        name: 'lon',
        displayName: 'Longitude',
        tooltip: 'Geographic longitude of the port',
        dataType: 'number',
        systemIdentifier: false,
      },
    ],
  },
];
export default schema;
