import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Switch, FormControl, FormLabel, useBoolean } from '@chakra-ui/react';
import {
  previewTheme,
  resetThemeLoadedFlag,
  retrieveThemeInEditorMode,
  setLocalThemeEnabledFlag,
} from '../../actions/actions_theme';

const LocalThemeToggle = ({ theme }) => {
  const [flag, setFlag] = useBoolean(theme.local_enabled);
  const dispatch = useDispatch();
  const localTheme = JSON.parse(localStorage.getItem('theme'));

  useEffect(() => {
    dispatch(setLocalThemeEnabledFlag(flag));
    if (!flag) {
      const themeId = localStorage.getItem('theme_id');
      dispatch(previewTheme(themeId));
    } else {
      dispatch(retrieveThemeInEditorMode());
    }
  }, [flag]);

  const handleToggle = () => {
    dispatch(resetThemeLoadedFlag());
    setFlag.toggle();
  };

  if (localTheme) {
    return (
      <FormControl display="flex" alignItems="center">
        <FormLabel>{flag ? 'Disable local theme' : 'Enable local theme'}</FormLabel>
        <Switch colorScheme="actionSecondary" size="sm" isChecked={flag} onChange={handleToggle} />
      </FormControl>
    );
  }
  localStorage.removeItem('theme_status');
  return null;
};

LocalThemeToggle.propTypes = {
  theme: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => {
  return {
    theme: state.theme,
  };
};

export default connect(mapStateToProps)(LocalThemeToggle);
