import React from 'react';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import { Flex, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import { AttachmentFieldSectionAdapter, TipCard, VStackLayout } from '../Components/index';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { useFormikContext } from 'formik';
import { getOr } from '../../../helpers';
import Constants from '../../../Constants';

const Attachments = ({ isDisabled, isReviewing, onUpdate, editingExistingNCR, form }) => {
  const { values: formikValues } = useFormikContext();
  const ncrMedia = getOr(formikValues, 'ncrDetails.ncrMedia', {});
  const groupedAttachment = Array.isArray(ncrMedia) ? groupBy(ncrMedia, 'type') : ncrMedia;
  const ncrType = getOr(formikValues,'ncrType', Constants.NCR_TYPES.NCR)

  const NCRAttachmentSections = [
    {
      key: 'photos',
      label: 'Photos',
    },
    {
      key: 'product_labels',
      label: 'Product Labels',
    },
    {
      key: 'others',
      label: 'Others',
    },
  ];

  const incidentAttachments = [
    {
      key: 'attachments',
      label: '',
    }    
  ]

  const attachmentSections = ncrType === Constants.NCR_TYPES.INCIDENT ? incidentAttachments : NCRAttachmentSections

  return (
    <DashboardSectionTile title="Attachments" padding="22px 33px 22px 20px" className="claim-list-form">
      <Flex marginTop="33px !important">
        <VStackLayout paddingBottom="42px" divider={null} spacing="56px" marginTop="0px" paddingTop="0px" flex={1}>
          {attachmentSections.map((attachmentSection, index) => {
            const { key, label } = attachmentSection;
            return (
              <AttachmentFieldSectionAdapter
                key={key}
                attachmentSectionKey={key}
                sectionIndex={index}
                label={label}
                onUpdate={onUpdate}
                attachmentsInGroup={getOr(groupedAttachment, key, [])}
                isReviewing={isReviewing}
                isDisabled={isDisabled}
              />
            );
          })}
        </VStackLayout>
        <TipCard marginTop="12px" width="279px" paddingRight="10px" closable>
          <Text>Please choose pictures which are clear and of the product involved in the claim.</Text>
          <br />
          <Text fontWeight="700">Photos required: </Text>
          <UnorderedList spacing="20px" marginLeft="24px">
            <ListItem>
              <Text>
                <b>Product:</b> Label (must include picture of full end panel / combo side).
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                <b>Product:</b> Reason for reporting.
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                <b>Foreign Material:</b> All items reported require picture with measurement guide (white background if
                possible).
              </Text>
            </ListItem>
          </UnorderedList>
          <br />
          <Text fontWeight="700">Documents required:</Text>
          <Text>Supporting evidence for any claim values submitted, including claim breakdown.</Text>
        </TipCard>
      </Flex>
    </DashboardSectionTile>
  );
};

Attachments.propTypes = {
  isDisabled: PropTypes.bool,
  isReviewing: PropTypes.bool,
  onUpdate: PropTypes.func,
};

export default Attachments;
