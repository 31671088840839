import { Alert } from 'antd';
import React, { Component } from 'react';
// Local Deps
import { uploadDataManagementFile } from '../../actions/actions_data_management';
import { today } from '../../utils';
import SelectField from '../basic/SelectField';
import Loading from '../basic/Loading';

export default class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileType: '',
      fileUploaded: false,
      fileUploading: false,
    };
  }

  componentDidUpdate() {
    if (this.props.fileUploaded.message !== '' && !this.state.fileUploaded && this.state.fileUploading) {
      this.setState({ fileUploaded: true, fileUploading: false });
    }
  }

  onFieldChange = e => {
    // TODO, move this to Constants
    const fileNames = {
      grinderVolumes: `grinders_production_volumes_${today()}.csv`,
      packerVolumes: `packers_production_volumes_${today()}.csv`,
      covid19Data: `aus_covid19_data_${today()}.csv`,
    };
    this.setState({ fileUploading: true });
    const payload = {
      file: e.target.files[0],
      fileName: fileNames[this.state.fileType],
    };

    this.props.dispatch(uploadDataManagementFile(payload, this.props.token));
  };

  render() {
    const fileStyle = {
      border: '1px solid grey',
      backgroundColor: 'grey',
    };

    const fileTypes = [
      { value: 'covid19Data', label: 'COVID19 Data' },
      { value: 'grinderVolumes', label: 'Grinder Volumes' },
      { value: 'packerVolumes', label: 'Packer Volumes' },
    ];
    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="row">
            <div className="col-xl-12">
              <div className="m-portlet m-portlet--full-height ">
                <div className="m-portlet__head">
                  <div className="m-portlet__head-tools">
                    <ul className="nav nav-tabs m-tabs m-tabs-line admin-tabs" role="tablist">
                      <li key="list-menu-user-settings" className="nav-item m-tabs__item active">
                        <a className="nav-link m-tabs__link">Data Management</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="m-portlet__body" style={{ minHeight: '320px' }}>
                  <div className="tab-content">
                    <div className="tab-pane active" id="m_widget2_tab4_content">
                      <div style={{ padding: '20px' }}>
                        <div className="row">
                          <div className="col-md-12" style={{ marginTop: '10px' }}>
                            <div>
                              <h5>Upload</h5>
                              <hr />
                            </div>
                            {this.state.fileUploaded === true && (
                              <Alert
                                type="info"
                                message={this.props.fileUploaded.message}
                                style={{ marginBottom: '10px' }}
                              />
                            )}
                            <div className="col-sm-12 row">
                              <div className="col-sm-2">
                                <label htmlFor="file-type">Select File Type</label>
                                <SelectField
                                  name="file_type"
                                  defaultValue={this.state.fileType}
                                  options={fileTypes}
                                  onChange={fileType => {
                                    this.setState({ fileType: fileType.value, fileUploaded: false });
                                  }}
                                />
                              </div>
                              {!this.state.fileUploading ? (
                                <div className="col-sm-4" style={{ marginTop: '30px' }}>
                                  <label className="btn btn-sm btn-info btn-file" style={fileStyle}>
                                    Choose file...
                                    <input
                                      type="file"
                                      accept="csv"
                                      style={{ display: 'none' }}
                                      disabled={!this.state.fileType}
                                      onChange={e => {
                                        this.onFieldChange(e);
                                      }}
                                    />
                                  </label>
                                </div>
                              ) : (
                                <Loading />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
