import { deleteTheme, fetchThemes, updateActiveTheme } from '../services/theme-api';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export const FETCH_THEMES_SUCCESS = 'FETCH_THEMES_SUCCESS';
export const FETCH_THEMES_ERROR = 'FETCH_THEMES_ERROR';
export const SET_ACTIVE_THEME_SUCCESS = 'SET_ACTIVE_THEME_SUCCESS';
export const SET_ACTIVE_THEME_ERROR = 'SET_ACTIVE_THEME_ERROR';
export const DELETE_THEME_SUCCESS = 'DELETE_THEME_SUCCESS';
export const DELETE_THEME_ERROR = 'DELETE_THEME_ERROR';

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function retrieveThemes() {
  return async dispatch => {
    try {
      const themes = await fetchThemes();
      if (themes) {
        dispatch({
          type: FETCH_THEMES_SUCCESS,
          payload: themes,
        });
      } else {
        dispatch({
          type: FETCH_THEMES_ERROR,
          payload: {},
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_THEMES_ERROR,
        payload: {},
      });
    }
  };
}

export function setActiveTheme(themeId) {
  return async dispatch => {
    try {
      if (themeId) {
        await updateActiveTheme(themeId);
        dispatch({
          type: SET_ACTIVE_THEME_SUCCESS,
        });
      } else {
        dispatch({
          type: SET_ACTIVE_THEME_ERROR,
          payload: {},
        });
      }
    } catch (error) {
      dispatch({
        type: SET_ACTIVE_THEME_ERROR,
        payload: {},
      });
    }
  };
}

export function removeTheme(themeId) {
  return async dispatch => {
    try {
      if (themeId) {
        await deleteTheme(themeId);
        dispatch({
          type: DELETE_THEME_SUCCESS,
        });
      } else {
        dispatch({
          type: DELETE_THEME_ERROR,
          payload: {},
        });
      }
    } catch (error) {
      dispatch({
        type: DELETE_THEME_ERROR,
        payload: {},
      });
    }
  };
}
