import { BEEF_INPUT_INVENTORY_ITEMS_SEARCH_FETCHED } from '../actions/actions_input_product_inventory_items_search';

const input_product_inventory_items_search = (inventory_items = [], action) => {
  switch (action.type) {
    case BEEF_INPUT_INVENTORY_ITEMS_SEARCH_FETCHED:
      return action.payload;
    default:
      return inventory_items;
  }
};

export default input_product_inventory_items_search;
