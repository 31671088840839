import ButtonComponent from './Button';
import IconButtonComponent from './IconButton';
import HeadingComponent from './Heading';

export const Button = ButtonComponent;
export const IconButton = IconButtonComponent;
export const Heading = HeadingComponent;

export default {
  Button,
  IconButton,
  Heading,
};
