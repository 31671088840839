import {
  DRAW_DOWN_HISTORICAL,
  DRAW_DOWN_LINK,
  ERROR_GET_PACKER_PLANT_INVOICE_POS,
  PACKER_PLANT_INVOICE_POS,
  REQUEST_PACKER_PLANT_INVOICE_POS,
} from '../actions/actions_invoicing';

const initialState = {
  isSearching: false,
  ordersToDrawDown: [],
  error: null,
};

const packerInvoiceOrders = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PACKER_PLANT_INVOICE_POS: {
      return {
        ...state,
        isSearching: true,
        ordersToDrawDown: [],
      };
    }
    case PACKER_PLANT_INVOICE_POS: {
      return {
        ...state,
        isSearching: false,
        ordersToDrawDown: action.packerInvoiceOrders,
      };
    }

    case DRAW_DOWN_LINK: {
      return {
        ...state,
        drawDownLink: action.generatedDrawDownLink,
      };
    }

    case DRAW_DOWN_HISTORICAL: {
      return {
        ...state,
        drawDownHistoricalData: {
          orders: action.drawDownHistoricalData,
          pagination: action.pagination,
          pageCount: action.pageCount,
        },
      };
    }

    case ERROR_GET_PACKER_PLANT_INVOICE_POS: {
      return {
        ...state,
        error: action.payload,
        isSearching: false,
      };
    }

    default:
      return state;
  }
};

export default packerInvoiceOrders;
