import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Checkbox } from '@chakra-ui/react';

function CheckboxComponent(props) {
  const { name, isChecked, onChange, defaultIsChecked, text, ...style } = props;
  const { bg, borderColor, borderRadius, colorScheme, height } = style;
  return (
    <Field name={name} id={name} type="boolean">
      {({ field: { value }, form: { setFieldValue } }) => (
        <Checkbox
          {...{
            isChecked: value,
            onChange: () => {
              setFieldValue(name, !value);
              if (onChange) {
                onChange(name, !value, setFieldValue);
              }
            },
            height,
            defaultIsChecked,
            bg: bg || 'white',
            marginBottom: '0px',
            color: borderColor || 'gray.400',
            colorScheme: colorScheme || 'primary',
            border: '1px solid',
            borderRadius: borderRadius || '2px',
          }}
        >
          {text}
        </Checkbox>
      )}
    </Field>
  );
}

CheckboxComponent.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  defaultIsChecked: PropTypes.bool,
  text: PropTypes.string,
};

export default CheckboxComponent;
