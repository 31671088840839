import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { FormLabel, HStack, IconButton, Text, Textarea, VStack } from '@chakra-ui/react';

import './Comments.scss';
import { withInternationalOrderFormContext } from '../InternationalOrderForm/InternationalOrderFormContext';

class Comments extends Component {
  static propTypes = {
    onChangeValue: PropTypes.func,
    form: PropTypes.shape({
      confirmationOfPurchaseComments: PropTypes.string,
      confirmationOfSaleComments: PropTypes.string,
      externalComments: PropTypes.string,
      internalComments: PropTypes.string,
    }),
  };

  state = {
    showInternalComments: false,
    showExternalComments: false,
  };

  handleToggleInternalComments = () => {
    const { showInternalComments } = this.state;
    this.setState({
      showInternalComments: !showInternalComments,
    });
  };

  handleToggleExternalComments = () => {
    const { showExternalComments } = this.state;
    this.setState({
      showExternalComments: !showExternalComments,
    });
  };

  render() {
    const { form } = this.props.state ? this.props.state : this.props;
    const onChangeValue = this.props.handleChangeValue;
    const { showExternalComments, showInternalComments } = this.state;
    return (
      <VStack spacing="26px" align="stretch" marginTop="20px !important">
        <VStack tack align="stretch">
          <FormLabel htmlFor="confirmationNotes">Confirmation of Purchase Notes</FormLabel>
          <Textarea
            id="confirmationNotes"
            placeholder="Add comment here..."
            backgroundColor="white"
            value={form.confirmationOfPurchaseComments}
            onChange={e => onChangeValue('confirmationOfPurchaseComments', e.target.value)}
            rows="5"
          />
        </VStack>
        <VStack align="stretch">
          <FormLabel htmlFor="confirmationSaleNotes">Confirmation of Sale Notes</FormLabel>
          <Textarea
            id="confirmationSaleNotes"
            placeholder="Add comment here..."
            backgroundColor="white"
            value={form.confirmationOfSaleComments}
            onChange={e => onChangeValue('confirmationOfSaleComments', e.target.value)}
            rows="5"
          />
        </VStack>
        <VStack align="stretch">
          <HStack role="presentation" className="comments__collapse-header" onClick={this.handleToggleInternalComments}>
            <IconButton
              icon={<i className={`la ${showInternalComments ? 'la-minus' : 'la-plus'}`} />}
              colorScheme="actionSecondary"
              height="35px"
              width="35px"
              borderRadius="18px"
            />
            <FormLabel htmlFor="internalComments">Internal Comments</FormLabel>
          </HStack>
          <Collapse isOpen={showInternalComments}>
            <Textarea
              id="internalComments"
              placeholder="Add comment here..."
              backgroundColor="white"
              value={form.internalComments}
              onChange={e => onChangeValue('internalComments', e.target.value)}
              rows="5"
            />
          </Collapse>
        </VStack>
        <VStack align="stretch">
          <HStack role="presentation" className="comments__collapse-header" onClick={this.handleToggleExternalComments}>
            <IconButton
              icon={<i className={`la ${showExternalComments ? 'la-minus' : 'la-plus'}`} />}
              colorScheme="actionSecondary"
              height="35px"
              width="35px"
              borderRadius="18px"
            />
            <FormLabel htmlFor="externalComments">External Comments</FormLabel>
          </HStack>
          <Collapse isOpen={showExternalComments}>
            <Textarea
              id="externalComments"
              placeholder="Add comment here..."
              backgroundColor="white"
              value={form.externalComments}
              onChange={e => onChangeValue('externalComments', e.target.value)}
              rows="5"
            />
          </Collapse>
        </VStack>
      </VStack>
    );
  }
}

export default withInternationalOrderFormContext(Comments);
