import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import { removeOrderDocument, replaceOrder } from '../../actions/actions_orders';
import { downloadFromS3Link } from '../../helpers/downloads';
import { convertToCamelCase } from '../../helpers';
import {
  Text,
  VStack,
  Center,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import UploadDocumentForm from './UploadDocumentForm';
import { ReactComponent as DownloadIcon } from '../../assets/download-icon.svg';
import { ReactComponent as TrashOutlineIconSVG } from '../../assets/trash-outline-icon.svg';

const PurchaseOrderDocumentsTableTh = ({ children, ...rest }) => {
  return (
    <Th fontSize="12px" paddingY="12px" {...rest} fontFamily="body">
      {children}
    </Th>
  );
};

PurchaseOrderDocumentsTableTh.propTypes = {
  children: PropTypes.node.isRequired,
};
class PurchaseOrderDocuments extends PureComponent {
  static propTypes = {
    autoSave: PropTypes.bool,
    currentOrder: PropTypes.shape({
      loaded: PropTypes.bool,
      voyage: PropTypes.arrayOf(PropTypes.object),
    }),
    closeModal: PropTypes.func,
    disabled: PropTypes.bool,
    dispatch: PropTypes.func,
    documents: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    poDocTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    readonly: PropTypes.bool,
    stateForm: PropTypes.shape({
      form: PropTypes.shape({
        id: PropTypes.number,
      }),
      voyage: PropTypes.instanceOf(Object),
    }),
    token: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    error: PropTypes.string,
  };

  removeDocument = document => {
    const remove = window.confirm(`Are you sure you want to remove ${document.docType}?`);
    const modifiedOrder = cloneDeep(this.props.stateForm.form);
    modifiedOrder.voyage = [{ ...this.props.stateForm.voyage }];
    // deleting is not end of order update functionality
    // modifiedOrder.editMode = true;
    if (remove) {
      if (document.id && this.props.autoSave) {
        this.props.dispatch(removeOrderDocument(modifiedOrder, document.id, this.props.token));
      } else {
        const index = modifiedOrder.documents.findIndex(doc => doc.url === document.url);
        modifiedOrder.documents.splice(index, 1);
        this.props.dispatch(replaceOrder(modifiedOrder));
      }
    }
  };

  render() {
    const style = { fontSize: '15px' };
    const header = { fontSize: '15px', fontWeight: 'bold' };
    const {
      autoSave,
      closeModal,
      currentOrder,
      disabled,
      dispatch,
      documents,
      loading,
      poDocTypes,
      readonly,
      stateForm,
      token,
      user,
      error,
    } = this.props;

    return (
      <VStack spacing="18px" align="stretch">
        <Text as="p" fontStyle="italic">
          Uploaded documents are not persisted until the form has been saved.
        </Text>
        {/* documents */}
        {!disabled && (
          <UploadDocumentForm
            token={token}
            error={error}
            {...convertToCamelCase({
              autoSave,
              closeModal,
              disabled,
              dispatch,
              loading,
              poDocTypes,
            })}
            currentOrder={currentOrder}
            stateForm={stateForm}
          />
        )}
        {documents.length ? (
          <TableContainer borderRadius="6px" borderWidth="1px">
            <Table variant="unstyled">
              <Thead backgroundColor="gray.100">
                <Tr>
                  <PurchaseOrderDocumentsTableTh>DOCUMENT TYPE</PurchaseOrderDocumentsTableTh>
                  <PurchaseOrderDocumentsTableTh>DATE UPLOADED</PurchaseOrderDocumentsTableTh>
                  <PurchaseOrderDocumentsTableTh>UPLOADED BY</PurchaseOrderDocumentsTableTh>
                  <PurchaseOrderDocumentsTableTh>ACTIONS</PurchaseOrderDocumentsTableTh>
                </Tr>
              </Thead>
              <Tbody backgroundColor="white">
                {documents.map((document, indx) => (
                  <Tr key={indx}>
                    <Td> {document.docType}</Td>
                    <Td>{document.dateUploaded || 'Pending'}</Td>
                    <Td>{document.user ? `${document.user.firstName} ${document.user.lastName}` : ''}</Td>
                    <Td>
                      <HStack>
                        <IconButton
                          variant="link"
                          icon={<DownloadIcon />}
                          onClick={() => downloadFromS3Link(document.url)}
                        />
                        {!readonly && (
                          <IconButton
                            variant="ghost"
                            icon={<TrashOutlineIconSVG />}
                            isDisabled={disabled}
                            hidden={disabled}
                            onClick={() => {
                              this.removeDocument(document);
                            }}
                          />
                        )}
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Center>
            <Text as="p">No documents</Text>
          </Center>
        )}
      </VStack>
    );
  }
}

export default PurchaseOrderDocuments;
