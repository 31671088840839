import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import buffer from '@turf/buffer';

import { commify } from '../../functions';

function getRange(prop, array) {
  const minMax = [1000000000, 0];
  array.forEach(function (item) {
    if (item.properties[prop] < minMax[0]) {
      minMax[0] = item.properties[prop];
    }
    if (item.properties[prop] > minMax[1]) {
      minMax[1] = item.properties[prop];
    }
  });
  return minMax;
}

class POSDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: false,
      initialLoad: true,
      loading: true,
      posCoopData: false,
      posRegionData: false,
      current_report: false,
      selectedData: false,
      summaryStats: [],
      detailExpanded: [false, false],
    };
  }

  componentDidMount() {
    const posCoop = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            coop_name: 'LOS ANGELES SAN DIEGO BAKERSFIELD',
            lat: 34.0522,
            lon: -118.2437,
            total_patties: 864520,
            total_weight: 105846.0167,
          },
          geometry: { type: 'Point', coordinates: [-118.2437, 34.0522] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'MICHIGAN',
            lat: 44.3148,
            lon: -85.6024,
            total_patties: 623528,
            total_weight: 77852.45,
          },
          geometry: { type: 'Point', coordinates: [-85.6024, 44.3148] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'NEW YORK METRO',
            lat: 40.7128,
            lon: -74.006,
            total_patties: 602265,
            total_weight: 77152.35,
          },
          geometry: { type: 'Point', coordinates: [-74.006, 40.7128] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'NEW ENGLAND',
            lat: 42.3601,
            lon: -71.0589,
            total_patties: 568273,
            total_weight: 72399.73333,
          },
          geometry: { type: 'Point', coordinates: [-71.0589, 42.3601] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'CHICAGO',
            lat: 41.8781,
            lon: -87.6298,
            total_patties: 551214,
            total_weight: 70566.46667,
          },
          geometry: { type: 'Point', coordinates: [-87.6298, 41.8781] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'WASHINGTON DC BALTIMORE EASTERN SHORE',
            lat: 38.9072,
            lon: -77.0369,
            total_patties: 515950,
            total_weight: 68930.93333,
          },
          geometry: { type: 'Point', coordinates: [-77.0369, 38.9072] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'TAMPA ST PETE ORLANDO',
            lat: 27.9506,
            lon: -82.4572,
            total_patties: 504531,
            total_weight: 63646.2,
          },
          geometry: { type: 'Point', coordinates: [-82.4572, 27.9506] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'COLUMBUS CINCY DAYTON TOLEDO LIMA',
            lat: 39.7589,
            lon: -84.1916,
            total_patties: 485696,
            total_weight: 61138.38333,
          },
          geometry: { type: 'Point', coordinates: [-84.1916, 39.7589] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'SW NORTH CAROLINA E SOUTH CAROLINA',
            lat: 35.5951,
            lon: -82.5515,
            total_patties: 439221,
            total_weight: 55760.85,
          },
          geometry: { type: 'Point', coordinates: [-82.5515, 35.5951] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'PHOENIX LAS VEGAS TUCSON YUMA EL CENTRO',
            lat: 33.4484,
            lon: -112.074,
            total_patties: 445653,
            total_weight: 54279.9,
          },
          geometry: { type: 'Point', coordinates: [-112.074, 33.4484] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'TRIAD WILMINGTON SW VA WI S GNW',
            lat: 34.2257,
            lon: -77.9447,
            total_patties: 418489,
            total_weight: 53365.3,
          },
          geometry: { type: 'Point', coordinates: [-77.9447, 34.2257] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'DALLAS TYLER',
            lat: 32.7767,
            lon: -96.797,
            total_patties: 383124,
            total_weight: 49210.03333,
          },
          geometry: { type: 'Point', coordinates: [-96.797, 32.7767] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'WISCONSIN ROCKFORD',
            lat: 42.2711,
            lon: -89.094,
            total_patties: 364457,
            total_weight: 48981.35,
          },
          geometry: { type: 'Point', coordinates: [-89.094, 42.2711] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'SACRAMENTO FRESNO RENO',
            lat: 38.5816,
            lon: -121.4944,
            total_patties: 371693,
            total_weight: 46585.13333,
          },
          geometry: { type: 'Point', coordinates: [-121.4944, 38.5816] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'GREATER INDIANA',
            lat: 40.2672,
            lon: -86.1349,
            total_patties: 355868,
            total_weight: 45022.7,
          },
          geometry: { type: 'Point', coordinates: [-86.1349, 40.2672] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'PHILADELPHIA',
            lat: 39.9526,
            lon: -75.1652,
            total_patties: 327079,
            total_weight: 42120.3,
          },
          geometry: { type: 'Point', coordinates: [-75.1652, 39.9526] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'DENVER COL SPRINGS S COLORADO',
            lat: 39.7392,
            lon: -104.9903,
            total_patties: 329936,
            total_weight: 41966.2,
          },
          geometry: { type: 'Point', coordinates: [-104.9903, 39.7392] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'MIAMI W PALM BEACH FT MYERS',
            lat: 25.7617,
            lon: -80.1918,
            total_patties: 320490,
            total_weight: 41058.58333,
          },
          geometry: { type: 'Point', coordinates: [-80.1918, 25.7617] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'DES MOINES QUAD CITIES PEORIA CENT IL',
            lat: 41.5868,
            lon: -93.625,
            total_patties: 313055,
            total_weight: 40898.9,
          },
          geometry: { type: 'Point', coordinates: [-93.625, 41.5868] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'KANSAS CITY NEBRASKA SIOUX CITY OMAHA',
            lat: 39.0997,
            lon: -94.5786,
            total_patties: 305457,
            total_weight: 40620.75,
          },
          geometry: { type: 'Point', coordinates: [-94.5786, 39.0997] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'ALBANY BUFFALO ROCHESTER SYRACUSE',
            lat: 43.1566,
            lon: -77.6088,
            total_patties: 312390,
            total_weight: 39631.2,
          },
          geometry: { type: 'Point', coordinates: [-77.6088, 43.1566] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'MEMPHIS MISSISSIPPI',
            lat: 35.1495,
            lon: -90.049,
            total_patties: 302531,
            total_weight: 38126.75,
          },
          geometry: { type: 'Point', coordinates: [-90.049, 35.1495] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'HOUSTON',
            lat: 29.7604,
            lon: -95.3698,
            total_patties: 294639,
            total_weight: 37962.43333,
          },
          geometry: { type: 'Point', coordinates: [-95.3698, 29.7604] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'MINNEAPOLIS ST PAUL ROCHESTER DULUTH',
            lat: 44.9778,
            lon: -93.265,
            total_patties: 281089,
            total_weight: 37510.66667,
          },
          geometry: { type: 'Point', coordinates: [-93.265, 44.9778] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'ALABAMA NW FLORIDA',
            lat: 32.3792,
            lon: -86.3077,
            total_patties: 283200,
            total_weight: 36749.1,
          },
          geometry: { type: 'Point', coordinates: [-86.3077, 32.3792] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'W VIRGINIA KNOXVILLE BRISTOL KINGSPORT',
            lat: 38.5976,
            lon: -80.4549,
            total_patties: 289978,
            total_weight: 36345.31667,
          },
          geometry: { type: 'Point', coordinates: [-80.4549, 38.5976] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'ATLANTA NW GEORGIA',
            lat: 33.749,
            lon: -84.388,
            total_patties: 274050,
            total_weight: 35834.1,
          },
          geometry: { type: 'Point', coordinates: [-84.388, 33.749] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'GREATER TENNESSEE VALLEY',
            lat: 35.5175,
            lon: -86.5804,
            total_patties: 279781,
            total_weight: 35148.25,
          },
          geometry: { type: 'Point', coordinates: [-86.5804, 35.5175] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'SAN ANTONIO AUSTIN WACO VICTORIA',
            lat: 29.4241,
            lon: -98.4936,
            total_patties: 266242,
            total_weight: 35100.68333,
          },
          geometry: { type: 'Point', coordinates: [-98.4936, 29.4241] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'CLEVELAND',
            lat: 41.4993,
            lon: -81.6944,
            total_patties: 278132,
            total_weight: 34835.21667,
          },
          geometry: { type: 'Point', coordinates: [-81.6944, 41.4993] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'WICHITA TOPEKA OK CITY WICHITA FALLS',
            lat: 35.4676,
            lon: -97.5164,
            total_patties: 264147,
            total_weight: 34380.45,
          },
          geometry: { type: 'Point', coordinates: [-97.5164, 35.4676] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'SAN FRANCISCO SANTA BARBARA',
            lat: 37.7749,
            lon: -122.4194,
            total_patties: 258615,
            total_weight: 33844.33333,
          },
          geometry: { type: 'Point', coordinates: [-122.4194, 37.7749] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'PITTSBURGH JOHNSTOWN ERIE',
            lat: 40.4406,
            lon: -79.9959,
            total_patties: 255185,
            total_weight: 32681.56667,
          },
          geometry: { type: 'Point', coordinates: [-79.9959, 40.4406] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'SEATTLE TACOMA',
            lat: 47.6062,
            lon: -122.3321,
            total_patties: 238465,
            total_weight: 31175.8,
          },
          geometry: { type: 'Point', coordinates: [-122.3321, 47.6062] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'PORTLAND MEDFORD EUGENE BEND',
            lat: 45.5122,
            lon: -122.6587,
            total_patties: 235831,
            total_weight: 30144.31667,
          },
          geometry: { type: 'Point', coordinates: [-122.6587, 45.5122] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'LEXINGTON LOUISVILLE',
            lat: 38.0406,
            lon: -84.5037,
            total_patties: 233284,
            total_weight: 29269,
          },
          geometry: { type: 'Point', coordinates: [-84.5037, 38.0406] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'SOUTH LA BEAUMONT',
            lat: 30.2241,
            lon: -92.0198,
            total_patties: 201494,
            total_weight: 26982.2,
          },
          geometry: { type: 'Point', coordinates: [-92.0198, 30.2241] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'RICHMOND SHENANDOAH VALLEY NORFOLK',
            lat: 37.5407,
            lon: -77.436,
            total_patties: 198761,
            total_weight: 25487.15,
          },
          geometry: { type: 'Point', coordinates: [-77.436, 37.5407] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'ST LOUIS',
            lat: 38.627,
            lon: -90.1994,
            total_patties: 192681,
            total_weight: 24897.45,
          },
          geometry: { type: 'Point', coordinates: [-90.1994, 38.627] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'SAVANNAH AUGUSTA MACON ALBANY COLUMBUS',
            lat: 32.0809,
            lon: -81.0912,
            total_patties: 189240,
            total_weight: 24727.8,
          },
          geometry: { type: 'Point', coordinates: [-81.0912, 32.0809] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'SUSQUEHANNA',
            lat: 40.7973,
            lon: -76.8745,
            total_patties: 185980,
            total_weight: 24192.4,
          },
          geometry: { type: 'Point', coordinates: [-76.8745, 40.7973] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'ALBUQUERQUE EL PASO',
            lat: 35.0844,
            lon: -106.6504,
            total_patties: 178279,
            total_weight: 22451.95,
          },
          geometry: { type: 'Point', coordinates: [-106.6504, 35.0844] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'JOPLIN QUINCY SPRINGFIELD COL JEFF CITY',
            lat: 37.209,
            lon: -93.2923,
            total_patties: 177702,
            total_weight: 22295.1,
          },
          geometry: { type: 'Point', coordinates: [-93.2923, 37.209] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'LITTLE ROCK SHREVEPORT MONROE',
            lat: 34.7465,
            lon: -92.2896,
            total_patties: 170677,
            total_weight: 21892,
          },
          geometry: { type: 'Point', coordinates: [-92.2896, 34.7465] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'SALT LAKE CITY S WYOMING',
            lat: 40.7608,
            lon: -111.891,
            total_patties: 168939,
            total_weight: 20808.6,
          },
          geometry: { type: 'Point', coordinates: [-111.891, 40.7608] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'TERREHAUTE EVANSVILLE CAPE GIR PADUCAH',
            lat: 37.9716,
            lon: -87.5711,
            total_patties: 158844,
            total_weight: 20012.85,
          },
          geometry: { type: 'Point', coordinates: [-87.5711, 37.9716] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'JACKSONVILLE TALLAHASSEE',
            lat: 30.3322,
            lon: -81.6557,
            total_patties: 154817,
            total_weight: 19335.5,
          },
          geometry: { type: 'Point', coordinates: [-81.6557, 30.3322] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'TULSA FORT SMITH',
            lat: 36.154,
            lon: -95.9928,
            total_patties: 151085,
            total_weight: 19180.4,
          },
          geometry: { type: 'Point', coordinates: [-95.9928, 36.154] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'VALLEY CORPUS CHRISTI LAREDO',
            lat: 27.8006,
            lon: -97.3964,
            total_patties: 121533,
            total_weight: 15423.86667,
          },
          geometry: { type: 'Point', coordinates: [-97.3964, 27.8006] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'AMARILLO LUBBOCK',
            lat: 35.222,
            lon: -101.8313,
            total_patties: 112323,
            total_weight: 14789.25,
          },
          geometry: { type: 'Point', coordinates: [-101.8313, 35.222] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'E WASHINGTON NW IDAHO NE OREGON',
            lat: 45.995,
            lon: -116.9607,
            total_patties: 110313,
            total_weight: 14500.8,
          },
          geometry: { type: 'Point', coordinates: [-116.9607, 45.995] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'N DAKOTA S DAKOTA W MINNESOTA',
            lat: 43.0389,
            lon: -87.9065,
            total_patties: 104760,
            total_weight: 14096.55,
          },
          geometry: { type: 'Point', coordinates: [-87.9065, 43.0389] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'HAWAII',
            lat: 19.8968,
            lon: -155.5828,
            total_patties: 82715,
            total_weight: 10551.2,
          },
          geometry: { type: 'Point', coordinates: [-155.5828, 19.8968] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'MONTANA',
            lat: 46.8797,
            lon: -110.3626,
            total_patties: 67591,
            total_weight: 9423.4,
          },
          geometry: { type: 'Point', coordinates: [-110.3626, 46.8797] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'BOISE TWIN FALLS IDAHO FALLS',
            lat: 43.615,
            lon: -116.2023,
            total_patties: 71899,
            total_weight: 9295.15,
          },
          geometry: { type: 'Point', coordinates: [-116.2023, 43.615] },
        },
        {
          type: 'Feature',
          properties: {
            coop_name: 'ALASKA',
            lat: 64.2008,
            lon: -149.4937,
            total_patties: 39484,
            total_weight: 5611,
          },
          geometry: { type: 'Point', coordinates: [-149.4937, 64.2008] },
        },
      ],
    };

    const posRegion = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            region_name: 'FLORIDA',
            lat: 27.6648,
            lon: -81.5158,
            total_patties: 998492,
            total_weight: 126478.0833,
          },
          geometry: { type: 'Point', coordinates: [-81.5158, 27.6648] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'MIDWEST',
            lat: 39.0997,
            lon: -94.5786,
            total_patties: 888348,
            total_weight: 119138.7667,
          },
          geometry: { type: 'Point', coordinates: [-94.5786, 39.0997] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'ROCKY MOUNTAIN',
            lat: 37.5407,
            lon: -77.436,
            total_patties: 953347,
            total_weight: 118167.3,
          },
          geometry: { type: 'Point', coordinates: [-77.436, 37.5407] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'RALEIGH',
            lat: 35.7796,
            lon: -78.6382,
            total_patties: 918286,
            total_weight: 116202.9167,
          },
          geometry: { type: 'Point', coordinates: [-78.6382, 35.7796] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'GREATER SOUTHWEST',
            lat: 32.7767,
            lon: -96.797,
            total_patties: 887546,
            total_weight: 113514.7833,
          },
          geometry: { type: 'Point', coordinates: [-96.797, 32.7767] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'ATLANTA',
            lat: 33.749,
            lon: -84.388,
            total_patties: 853429,
            total_weight: 110598.4,
          },
          geometry: { type: 'Point', coordinates: [-84.388, 33.749] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'CHICAGO',
            lat: 41.8781,
            lon: -87.6298,
            total_patties: 849336,
            total_weight: 109654.2167,
          },
          geometry: { type: 'Point', coordinates: [-87.6298, 41.8781] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'SOUTHERN CALIFORNIA',
            lat: 34.0522,
            lon: -118.2437,
            total_patties: 890436,
            total_weight: 109111.8667,
          },
          geometry: { type: 'Point', coordinates: [-118.2437, 34.0522] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'OHIO',
            lat: 40.4173,
            lon: -82.9071,
            total_patties: 866621,
            total_weight: 108803.2,
          },
          geometry: { type: 'Point', coordinates: [-82.9071, 40.4173] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'INDIANAPOLIS',
            lat: 39.7684,
            lon: -86.1581,
            total_patties: 812676,
            total_weight: 102632.55,
          },
          geometry: { type: 'Point', coordinates: [-86.1581, 39.7684] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'HOUSTON',
            lat: 29.7604,
            lon: -95.3698,
            total_patties: 787193,
            total_weight: 102268.3333,
          },
          geometry: { type: 'Point', coordinates: [-95.3698, 29.7604] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'NORTHWEST',
            lat: 47.6062,
            lon: -122.3321,
            total_patties: 763583,
            total_weight: 100150.4667,
          },
          geometry: { type: 'Point', coordinates: [-122.3321, 47.6062] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'GREAT SOUTHERN',
            lat: 35.5175,
            lon: -86.5804,
            total_patties: 742315,
            total_weight: 95890.15,
          },
          geometry: { type: 'Point', coordinates: [-86.5804, 35.5175] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'BALTIMORE-WASHINGTON',
            lat: 38.9072,
            lon: -77.0369,
            total_patties: 713087,
            total_weight: 94214.58333,
          },
          geometry: { type: 'Point', coordinates: [-77.0369, 38.9072] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'HEARTLAND',
            lat: 38.627,
            lon: -90.1994,
            total_patties: 658852,
            total_weight: 85018,
          },
          geometry: { type: 'Point', coordinates: [-90.1994, 38.627] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'PHILADELPHIA',
            lat: 39.9526,
            lon: -75.1562,
            total_patties: 636341,
            total_weight: 82071.25,
          },
          geometry: { type: 'Point', coordinates: [-75.1562, 39.9526] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'BOSTON',
            lat: 42.3601,
            lon: -71.0589,
            total_patties: 640124,
            total_weight: 81834.53333,
          },
          geometry: { type: 'Point', coordinates: [-71.0589, 42.3601] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'MICHIGAN',
            lat: 44.3148,
            lon: -85.6024,
            total_patties: 625163,
            total_weight: 78059.3,
          },
          geometry: { type: 'Point', coordinates: [-85.6024, 44.3148] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'PACIFIC SIERRA',
            lat: 37.7749,
            lon: -122.4194,
            total_patties: 604392,
            total_weight: 77163.61667,
          },
          geometry: { type: 'Point', coordinates: [-122.4194, 37.7749] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'NEW YORK METRO',
            lat: 40.7128,
            lon: -74.006,
            total_patties: 599048,
            total_weight: 76691.15,
          },
          geometry: { type: 'Point', coordinates: [-74.006, 40.7128] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'PITTSBURGH',
            lat: 40.4406,
            lon: -79.9959,
            total_patties: 504974,
            total_weight: 63771.16667,
          },
          geometry: { type: 'Point', coordinates: [-79.9959, 40.4406] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'HAWAII',
            lat: 19.8968,
            lon: -155.5828,
            total_patties: 82715,
            total_weight: 10551.2,
          },
          geometry: { type: 'Point', coordinates: [-155.5828, 19.8968] },
        },
        {
          type: 'Feature',
          properties: {
            region_name: 'HOME OFFICE',
            lat: 41.8781,
            lon: -87.6298,
            total_patties: 871,
            total_weight: 110.5,
          },
          geometry: { type: 'Point', coordinates: [-87.6298, 41.8781] },
        },
      ],
    };

    mapboxgl.accessToken = 'pk.eyJ1IjoiY2Zjb2pvbm8iLCJhIjoiY2toeWNhOXh3MGY0cDJ4b2RhMG54M3ExaSJ9.hlvEPe6nwdJ1a3z1dGB5bA';
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/light-v9',
      center: [-110.7129, 38.0902],
      zoom: 2.8,
      pitch: 50,
      rotate: 60,
    });

    map.on(
      'load',
      function () {
        this.setState({ loading: false, current_report: 'coop' });
      }.bind(this)
    );

    map.on(
      'click',
      function (e) {
        const features = map.queryRenderedFeatures(e.point);
        // Set selected data
        if (features.length) {
          const firstFeature = features[0];
          if (firstFeature.layer.id.indexOf('coop') > -1 || firstFeature.layer.id.indexOf('region') > -1) {
            const thisData = {};
            thisData.name = firstFeature.layer.id.split('coop-')[1];
            thisData.props = firstFeature.properties;
            // thisData.props = firstFeature.
            this.setState({ selectedData: thisData });
          } else {
            this.setState({ selectedData: false });
          }
        }
      }.bind(this)
    );

    this.setState({ map, posCoopData: posCoop, posRegionData: posRegion });
  }

  componentDidUpdate(prevProps, prevState) {
    const { posCoopData, posRegionData, map, loading } = this.state;

    if (!loading) {
      const newSummaryStats = [
        {
          name: 'Patties',
          value: 0,
          groups: [],
        },
        {
          name: 'Weight',
          value: 0,
          groups: [],
        },
      ];

      if (this.state.current_report === 'coop' && prevState.current_report !== 'coop') {
        const totalPattiesRange = getRange('total_patties', posCoopData.features);

        posCoopData.features.forEach(function (coop) {
          if (!map.getSource(`coop-${coop.properties.coop_name}`)) {
            const bufferedCircle = buffer(coop, 50);
            const thisGeoJSON = {
              type: 'FeatureCollection',
              features: [bufferedCircle],
            };
            map.addSource(`coop-${coop.properties.coop_name}`, {
              type: 'geojson',
              data: thisGeoJSON,
            });
            map.addLayer({
              id: `coop-${coop.properties.coop_name}`,
              type: 'fill-extrusion',
              source: `coop-${coop.properties.coop_name}`,
              paint: {
                'fill-extrusion-color': {
                  property: 'total_patties',
                  type: 'exponential',
                  stops: [
                    [totalPattiesRange[0], '#dd3e54'],
                    [totalPattiesRange[1], '#6be585'],
                  ],
                },
                'fill-extrusion-height': {
                  property: 'total_patties',
                  type: 'exponential',
                  stops: [
                    [totalPattiesRange[0], 1000],
                    [totalPattiesRange[1], 1000000],
                  ],
                },
              },
            });
            map.on('mouseover', `coop-${coop.properties.coop_name}`, function (e) {
              map.getCanvas().style.cursor = 'crosshair';
              map.setPaintProperty(`coop-${coop.properties.coop_name}`, 'fill-extrusion-opacity', 0.9);
            });
            map.on('mouseout', `coop-${coop.properties.coop_name}`, function (e) {
              map.getCanvas().style.cursor = '-webkit-grab';
              map.setPaintProperty(`coop-${coop.properties.coop_name}`, 'fill-extrusion-opacity', 1);
            });
          } else {
            map.setLayoutProperty(`coop-${coop.properties.coop_name}`, 'visibility', 'visible');
          }

          // Make summary stats
          newSummaryStats[0].groups.push({
            name: coop.properties.coop_name,
            type: 'number',
            value: commify(coop.properties.total_patties.toFixed(0)),
          });
          newSummaryStats[1].groups.push({
            name: coop.properties.coop_name,
            type: 'number',
            value: commify(coop.properties.total_weight.toFixed(2)),
          });
          newSummaryStats[0].value += coop.properties.total_patties;
          newSummaryStats[1].value += coop.properties.total_weight;
        });

        this.setState({ summaryStats: newSummaryStats });
      }
      if (prevState.current_report === 'coop' && this.state.current_report !== 'coop') {
        posCoopData.features.forEach(function (coop) {
          map.setLayoutProperty(`coop-${coop.properties.coop_name}`, 'visibility', 'none');
        });
      }

      if (this.state.current_report === 'region' && prevState.current_report !== 'region') {
        const totalPattiesRange = getRange('total_patties', posRegionData.features);

        posRegionData.features.forEach(function (region) {
          if (!map.getSource(`region-${region.properties.region_name}`)) {
            const bufferedCircle = buffer(region, 50);
            const thisGeoJSON = {
              type: 'FeatureCollection',
              features: [bufferedCircle],
            };
            map.addSource(`region-${region.properties.region_name}`, {
              type: 'geojson',
              data: thisGeoJSON,
            });
            map.addLayer({
              id: `region-${region.properties.region_name}`,
              type: 'fill-extrusion',
              source: `region-${region.properties.region_name}`,
              paint: {
                'fill-extrusion-color': {
                  property: 'total_patties',
                  type: 'exponential',
                  stops: [
                    [totalPattiesRange[0], '#dd3e54'],
                    [totalPattiesRange[1], '#6be585'],
                  ],
                },
                'fill-extrusion-height': {
                  property: 'total_patties',
                  type: 'exponential',
                  stops: [
                    [totalPattiesRange[0], 1000],
                    [totalPattiesRange[1], 1000000],
                  ],
                },
              },
            });
            map.on('mouseover', `region-${region.properties.region_name}`, function (e) {
              map.getCanvas().style.cursor = 'crosshair';
              map.setPaintProperty(`region-${region.properties.region_name}`, 'fill-extrusion-opacity', 0.9);
            });
            map.on('mouseout', `region-${region.properties.region_name}`, function (e) {
              map.getCanvas().style.cursor = '-webkit-grab';
              map.setPaintProperty(`region-${region.properties.region_name}`, 'fill-extrusion-opacity', 1);
            });
          } else {
            map.setLayoutProperty(`region-${region.properties.region_name}`, 'visibility', 'visible');
          }

          // Make summary stats
          newSummaryStats[0].groups.push({
            name: region.properties.region_name,
            type: 'number',
            value: commify(region.properties.total_patties.toFixed(0)),
          });
          newSummaryStats[1].groups.push({
            name: region.properties.region_name,
            type: 'number',
            value: commify(region.properties.total_weight.toFixed(2)),
          });
          newSummaryStats[0].value += region.properties.total_patties;
          newSummaryStats[1].value += region.properties.total_weight;
        });

        this.setState({ summaryStats: newSummaryStats });
      }
      if (prevState.current_report === 'region' && this.state.current_report !== 'region') {
        posRegionData.features.forEach(function (region) {
          map.setLayoutProperty(`region-${region.properties.region_name}`, 'visibility', 'none');
        });
      }
    }
  }

  expandDetail(index) {
    const detailExpanded = JSON.parse(JSON.stringify(this.state.detailExpanded));
    detailExpanded[index] = !detailExpanded[index];
    this.setState({ detailExpanded });
  }

  render() {
    return (
      <div>
        {!this.state.loading ? false : <img className="map-loader" src="./img/loader.gif" alt="loader" />}

        <div className="report-selector">
          <div style={{ marginTop: '5px' }}>
            <select
              className="form-control width-200"
              onChange={e => this.setState({ current_report: e.target.value })}
            >
              <option value="coop">Coop</option>
              <option value="region">Region</option>
            </select>
          </div>
        </div>

        <div className="map-overlay">
          {this.state.selectedData ? (
            <div className="selected-overlay">
              <div className="overlay-header">{this.state.selectedData.name}</div>
              <div className="overlay-collapse ">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Total Patties</td>
                      <td>{commify(this.state.selectedData.props.total_patties)}</td>
                    </tr>
                    <tr>
                      <td>Total Weight</td>
                      <td>{commify(this.state.selectedData.props.total_weight.toFixed(2))}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            false
          )}
          <div className="summary-overlay">
            <div className="overlay-header">System Summary Data</div>
            <div className="overlay-collapse">
              <p style={{ marginTop: '-6px', marginBottom: '0px' }}>
                <small>{this.state.summarySnippet}</small>
              </p>
              <div>
                {this.state.summaryStats.map((stats, i) => {
                  const groupRows = [];
                  stats.groups.forEach(function (group, k) {
                    const floatValue1 = parseFloat(group.value.toString().replace(/,/g, '').replace('$', ''));
                    const floatValue2 = parseFloat(stats.value.toString().replace(/,/g, '').replace('$', ''));
                    groupRows.push(
                      <tr key={`stats-${i}-${k}`} style={{ fontSize: '10px' }}>
                        <td style={{ padding: '0.5rem' }}>{group.name}</td>
                        <td style={{ padding: '0.5rem' }}>{group.value}</td>
                        <td style={{ padding: '0.5rem' }}>{`${((floatValue1 / floatValue2) * 100).toFixed(2)}%`}</td>
                      </tr>
                    );
                  });
                  return (
                    <table key={`data-table-${i}`} className="table" style={{ marginBottom: '0px' }}>
                      <tbody>
                        <tr style={{ cursor: 'pointer' }} onClick={this.expandDetail.bind(this, i)} key={`stats-${i}`}>
                          <td>{stats.name}</td>
                          <td>{commify(stats.value.toFixed(0))}</td>
                          <td>
                            <i
                              className={`collapsing-icon fa fa-caret-right ${
                                this.state.detailExpanded[i] ? 'opened' : ''
                              }`}
                            />
                          </td>
                        </tr>
                        {this.state.detailExpanded[i] ? groupRows : false}
                      </tbody>
                    </table>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div id="map" style={{ width: '100%', height: window.innerHeight - 60 }} />
      </div>
    );
  }
}

export default POSDashboard;
