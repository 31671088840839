import React, { useEffect, useState } from 'react';
import { Button, Box, Flex, Text, IconButton, useToast } from '@chakra-ui/react';
import { Table } from 'antd';
import Tooltip from '../_components/Tooltip';
import { RiPencilFill } from 'react-icons/ri';
import api from '../../../api';
import PropTypes from 'prop-types';
import AddHistory from './AddHistory';

const History = ({ establishmentId: estId }) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const toast = useToast();

  const getEstablishmentHistory = async id => {
    try {
      const response = await api.get(`/op-audit-reasons/${id}`);
      if (response.ok) {
        const json = await response.json();
        setData(json);
      }
    } catch (error) {
      toast({
        title: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    if (estId) {
      getEstablishmentHistory(estId);
    }
  }, [estId]);

  useEffect(() => {
    if (!isOpenPopup) {
      setEditItem(null);
    }
  }, [isOpenPopup]);

  return (
    <>
      <Box marginBottom="15px">
        <Flex justifyContent="space-between" alignItems="center" marginBottom="15px">
          <Text color="rgba(0, 0, 0, 0.87)" fontSize="14px" fontWeight="600" lineHeight="1.43">
            ASL Status & History
          </Text>
          <Button
            variant="unstyled"
            height="auto"
            lineHeight="1"
            color="#3A44B0"
            fontSize="12px"
            fontWeight="500"
            onClick={() => setIsOpenPopup(true)}
          >
            Add New
          </Button>
        </Flex>
        <Table
          pagination={{
            pageSize: 10,
            current: 1,
            style: { display: 'none' },
          }}
          rowClassName="food-safety-table-row"
          className="food-safety-table"
          dataSource={data}
          columns={[
            {
              title: 'DELISTED DATE',
              dataIndex: 'delisted_date',
              key: 'delisted_date',
              sortOrder: 'descend',
            },
            {
              title: 'AUDIT FAILURE',
              dataIndex: 'audit_failure',
              key: 'audit_failure',
            },
            {
              title: 'REASON',
              dataIndex: 'audit_reason',
              key: 'audit_reason',
            },
            {
              title: 'RE-INSTATED DATE',
              dataIndex: 'reinstated_date',
              key: 'reinstated_date',
              render: date => date || '-',
            },
            {
              title: 'ACTION',
              dataIndex: 'action',
              key: 'action',
              render: (text, record, index) =>
              (
                <Tooltip content="Edit">
                  <IconButton
                    width="24px"
                    height="24px"
                    padding="0"
                    minW="auto"
                    border="1px solid #D9DADF"
                    borderRadius="2px"
                    color="#00000099"
                    backgroundColor="#fff"
                    icon={<RiPencilFill size={12} />}
                    onClick={() => {
                      setIsOpenPopup(true);
                      setEditItem(record);
                    }}
                  />
                </Tooltip>
              ),
            },
          ]}
        />
        {isOpenPopup && (
          <AddHistory
            isOpenPopup={isOpenPopup}
            setIsOpenPopup={setIsOpenPopup}
            estId={estId}
            editItem={editItem}
            setData={setData}
          />
        )}
      </Box>
    </>
  );
};

History.propTypes = {
  establishmentId: PropTypes.number,
};

export default History;
