import Constants from '../Constants';

export const ORDERS_PENDING_COLDSTORE_RELEASE_BASE_URL = `${Constants.URL}coldstore_orders?status=pending_coldstore_release`;

export const ORDERS_PENDING_COLDSTORE_RELEASE_FETCHED = 'ORDERS_PENDING_COLDSTORE_RELEASE_FETCHED';
export const REPLACE_ORDER_PENDING_COLDSTORE_RELEASE = 'REPLACE_ORDER_PENDING_COLDSTORE_RELEASE';
export const REMOVE_ORDER_PENDING_COLDSTORE_RELEASE = 'REMOVE_ORDER_PENDING_COLDSTORE_RELEASE';
export const RELEASE_PDF_EMAIL_SENT = 'RELEASE_PDF_EMAIL_SENT';
export const ORDERS_PENDING_COLDSTORE_RELEASE_DELETED = 'ORDERS_PENDING_COLDSTORE_RELEASE_DELETED';
export const ORDERS_PENDING_COLDSTORE_RELEASE_ERROR = 'ORDERS_PENDING_COLDSTORE_RELEASE_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function releasePDFEmailSent(data) {
  return {
    type: RELEASE_PDF_EMAIL_SENT,
    payload: data,
  };
}

export function removeOrderPendingColdstoreRelease(orderPendingColdstoreRelease) {
  return {
    type: REMOVE_ORDER_PENDING_COLDSTORE_RELEASE,
    payload: orderPendingColdstoreRelease,
  };
}

export function replaceOrderPendingColdstoreRelease(orderPendingColdstoreRelease) {
  return {
    type: REPLACE_ORDER_PENDING_COLDSTORE_RELEASE,
    payload: orderPendingColdstoreRelease,
  };
}

export function loadOrdersPendingColdstoreRelease(ordersPendingColdstoreRelease) {
  return {
    type: ORDERS_PENDING_COLDSTORE_RELEASE_FETCHED,
    payload: ordersPendingColdstoreRelease,
  };
}

export function errorOrdersPendingColdstoreRelease(error) {
  return {
    type: ORDERS_PENDING_COLDSTORE_RELEASE_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchOrdersPendingColdstoreRelease(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${ORDERS_PENDING_COLDSTORE_RELEASE_BASE_URL}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrdersPendingColdstoreRelease))
      .then(json => dispatch(loadOrdersPendingColdstoreRelease(json)))
      .catch(error => dispatch(errorOrdersPendingColdstoreRelease(error)));
  };
}
