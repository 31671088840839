import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@chakra-ui/react';
import RightAngleIcon from '../../core/RightAngleIcon';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const CardTitleArea = props => {
  const { children } = props;
  return (
    <Flex color="black" fontWeight="bold" fontSize="15px" alignItems="baseline" {...props}>
      <RightAngleIcon />
      {children}
    </Flex>
  );
};

CardTitleArea.propTypes = propTypes;

export default CardTitleArea;
