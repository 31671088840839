import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ErrorMessage } from 'formik';

import Checkbox from '../Checkbox';

function FormCheckbox(field) {
  const { indices, isChecked, onChange, defaultIsChecked, label, name, height, pl, ...rest } = field;
  const fieldName = typeof name === 'function' ? name(indices) : name;

  return (
    <Box
      {...{
        d: 'flex',
        alignItems: rest.alignItems || rest.align || 'center',
        marginBottom: rest.marginBottom || rest.mb || '0px',
        height,
      }}
    >
      <Checkbox
        {...{
          isChecked,
          onChange,
          defaultIsChecked,
          label,
          name: fieldName,
          size: 'lg',
          ...rest,
        }}
      />
      <Box pl={pl || '12px'}>
        <Text fontSize="md" fontWeight="bold">
          {label}
        </Text>
      </Box>
      <ErrorMessage name={fieldName}>{msg => <Text color="error">{msg}</Text>}</ErrorMessage>
    </Box>
  );
}

export default FormCheckbox;
