import { Alert, Upload } from 'antd';
import Button from '../core/Button';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import Select from '../basic/SelectField';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Constants from '../../Constants';
import { getEmbedUrl } from '../../functions';
import Loading from '../basic/Loading';
import { downloadFromS3Link } from '../../helpers/downloads';
import { Box, Text, Stack, FormControl, Input, FormLabel, HStack, Flex } from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { selectArticleRegions, fetchArticleRegions } from '../../slices/article-region/articleRegionSlice';
import { useDispatch, useSelector } from 'react-redux';

const ArticleEdit = ({
  tags,
  deleteArticle,
  saveArticle,
  getTags,
  currentArticle,
  token,
  userFullName,
  saving,
  saved,
  article,
  resetEditStatus,
  isKpi = false,
  organisations,
  getOrganisations,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { editedArticle } = location.state || {};
  const initCurrentArticle = editedArticle || {
    author: userFullName,
    title: '',
    content: '',
    hook: '',
    hero_url: '',
    attachment_url: '',
    image_urls: [],
    tags: isKpi ? ['KPI'] : [],
    region: isKpi ? useParams().region : [],
    published_date: new Date().toISOString(),
    read_access: [],
  };
  const [currentArticleState, setCurrentArticleState] = useState(initCurrentArticle);
  const [currentContent, setCurrentContent] = useState(EditorState.createEmpty());
  const [uploadingHero, setUploadingHero] = useState(false);
  const [uploadingAttachment, setUploadingAttachment] = useState(false);
  const articleRegions = useSelector(selectArticleRegions);
  const { errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: initCurrentArticle,
    validationSchema: Yup.object().shape({
      region: Yup.string().nullable().required('Required'),
      published_date: Yup.string().nullable().required('Required'),
    }),
    onSubmit: async () => {
      const thisArticle = { ...currentArticleState };
      thisArticle.content = draftToHtml(convertToRaw(currentContent.getCurrentContent()));
      await saveArticle(thisArticle);
      isKpi ?  history.push("/kpi") : history.push("/articles");
    },
  });
  useEffect(() => {
    getOrganisations(token);
    getTags(token);
    dispatch(fetchArticleRegions());
  }, []);

  useEffect(() => {
    if (editedArticle) {
      const html = editedArticle.content;
      const contentBlock = htmlToDraft(html);
      setCurrentArticleState({
        ...editedArticle,
      });
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setCurrentContent(editorState);
      }
    }
  }, [editedArticle]);

  const onEditorStateChange = editorState => {
    setCurrentContent(editorState);
  };

  const inputEdited = (type, value) => {
    setCurrentArticleState(prevArticleState => ({ ...prevArticleState, [type]: value }));
  };

  const onDelete = async () => {
    await deleteArticle(currentArticleState);
    window.history.replaceState({}, document.title)
    isKpi ?  history.replace("/kpi") : history.push("/articles");
  };
  
  const organisationOptions = [...organisations].reduce((agg, org) => {
    agg.push({
      label: `${org.uid} - ${org.name}`,
      value: org.id,
    });
    return agg;
  }, []);

  const readAccessOrgs = [];
  currentArticleState.read_access?.forEach(id => {
    readAccessOrgs.push(organisations.filter(o => o.id === id)[0]);
  });
  const uploadImage = (file, uploadType) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('model_name', 'article');
    const header = new Headers({ Authorization: `Token ${token}` },{ 'Content-Type': 'multipart/form-data' });
    return fetch(`${Constants.URL}upload_image_file`, {
      method: 'POST',
      headers: header,
      body: formData,
    })
      .then(response => response.json())
      .then(json => {
        const newArticle = { ...currentArticleState };
        if (uploadType === 'hero') {
          newArticle.hero_url = json.link;
          setCurrentArticleState(newArticle);
          setUploadingHero(false);
        } else {
          inputEdited('image_urls', [...newArticle.image_urls, json.link])
        }

        return {
          data: {
            link: json.link,
          },
        };
      })
      .catch(() => {});
  };

  const uploadAttachment = file => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('model_name', 'article');
    formData.append('file_type', 'file');
    const header = new Headers({ Authorization: `Token ${token}` });
    return fetch(`${Constants.URL}upload_image_file`, {
      method: 'POST',
      headers: header,
      body: formData,
    })
      .then(response => response.json())
      .then(json => {
        const newArticle = { ...currentArticleState };
        newArticle.attachment_url = json.link;
        setCurrentArticleState(newArticle);
        setUploadingAttachment(false);
        return {
          data: {
            link: json.link,
          },
        };
      })
      .catch(() => {});
  };
  const embedCallback = url => {
    try {
      return getEmbedUrl(url);
    } catch (err) {
      // No required
    }
    return null;
  };

  if (saving === true) {
    return <Loading />;
  }
  if (saved === true) {
    return (
      <Box p="20">
        <Text mb="10">
          Your {isKpi ? 'kpi' : 'article'} has been {!currentArticle ? 'created' : 'updated'}.
        </Text>
        <Link to={`/${isKpi ? `kpi/${currentArticleState.region}` : 'articles'}/${article.slug}`}>
          <Button onClick={resetEditStatus}>View it here</Button>
        </Link>
      </Box>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <Box p="10" background="white">
        <Stack spacing="10">
          <FormControl id="title">
            <FormLabel>Title</FormLabel>
            <Input
              type="text"
              value={currentArticleState.title}
              placeholder="Article Title"
              onChange={e => inputEdited('title', e.target.value)}
            />
          </FormControl>
          <FormControl id="hook">
            <Flex justifyContent="space-between">
              <FormLabel>Hook</FormLabel>
              <Text>
                {currentArticleState?.hook?.length}/{Constants.ARTICLE_HOOK_MAX_LEN}
              </Text>
            </Flex>
            <Input
              type="text"
              value={currentArticleState.hook}
              placeholder="A short phrase used to entice the reader."
              onChange={e => inputEdited('hook', e.target.value)}
              maxLength={Constants.ARTICLE_HOOK_MAX_LEN}
            />
          </FormControl>
          <FormControl id="author">
            <FormLabel>Author</FormLabel>
            <Input
              type="text"
              value={currentArticleState.author}
              placeholder="Author"
              onChange={e => inputEdited('author', e.target.value)}
            />
          </FormControl>
          <FormControl id="tags">
            <FormLabel>Tags</FormLabel>
            <Select
              {...{
                isClearable: true,
                isSearchable: true,
                closeMenuOnSelect: false,
                removeSelected: true,
                isMulti: true,
                name: 'select-tags',
                value: currentArticleState.tags ? currentArticleState.tags.map(l => ({ label: l, value: l })) : [],
                onChange: e =>
                  inputEdited(
                    'tags',
                    e.map(tg => tg.value)
                  ),
                options: tags.map(l => ({ label: l.value, value: l.value })),
              }}
            />
          </FormControl>
          <FormControl isInvalid={errors.region}>
            <FormLabel htmlFor="region">Region</FormLabel>
            <Select
              id="region"
              name="region"
              value={
                currentArticleState.region
                  ? { label: currentArticleState.region, value: currentArticleState.region }
                  : currentArticleState.region
              }
              options={articleRegions.map(l => ({ label: l.name, value: l.name }))}
              placeholder="Region"
              onChange={option => {
                inputEdited('region', option.value);
                setFieldValue('region', option.value);
              }}
            />
            {errors.region && <Alert style={{ marginTop: '5px' }} message={errors.region} type="error" />}
          </FormControl>
          <FormControl id="read_access">
            <FormLabel>Read Access</FormLabel>
            <Select
              {...{
                name: 'select-tags',
                value:
                  currentArticleState.read_access && readAccessOrgs.length > 0
                    ? readAccessOrgs.reduce((agg, org) => {
                        agg.push({
                          label: `${org?.uid} - ${org?.name}`,
                          value: org?.id,
                        });
                        return agg;
                      }, [])
                    : [],
                options: organisationOptions,
                isMulti: true,
                onChange: e =>
                  inputEdited(
                    'read_access',
                    e.map(acc => acc.value)
                  ),
              }}
            />
          </FormControl>
          <FormControl isInvalid={errors.published_date}>
            <FormLabel htmlFor="published_date">Creation Date</FormLabel>
            <Datetime
              id="published_date"
              inputProps={{
                placeholder: 'Creation Date',
                readOnly: true,
              }}
              closeOnSelect
              value={new Date(currentArticleState.published_date)}
              onChange={e => {
                inputEdited('published_date', e);
                setFieldValue('published_date', e);
              }}
              timeFormat="hh:mm a"
              dateFormat="YYYY-MM-DD"
            />
            {errors.published_date && (
              <Alert style={{ marginTop: '5px' }} message={errors.published_date} type="error" />
            )}
          </FormControl>
          <FormControl id="hero-url">
            <FormLabel>Hero Image</FormLabel>
            <Upload
              name="hero-url"
              showUploadList={false}
              customRequest={({ file }) => uploadImage(file, 'hero')}
              multiple={false}
              onChange={info => {
                if (info.file.status === 'uploading') {
                  setUploadingHero(true);
                }
              }}
            >
              {uploadingHero && <div>Loading...</div>}
              {!uploadingHero && currentArticleState.hero_url && (
                <img alt="Hero" style={{ width: '100%' }} src={currentArticleState.hero_url} />
              )}
              {!uploadingHero && !currentArticleState.hero_url && (
                <Box background="neutral.8" p="10">
                  Click or drag an image over this box to upload
                </Box>
              )}
            </Upload>
          </FormControl>
          <FormControl id="file-attachment">
            <FormLabel>KPI Attachment</FormLabel>
            <Upload
              name="file-attachment"
              showUploadList={false}
              customRequest={({ file }) => uploadAttachment(file)}
              multiple={false}
              onChange={info => {
                if (info.file.status === 'uploading') {
                  setUploadingAttachment(true);
                }
              }}
            >
              {uploadingAttachment ? (
                <div>Loading...</div>
              ) : (
                <HStack>
                  {currentArticleState.attachment_url && (
                    <Button
                      type="link"
                      onClick={e => {
                        e.stopPropagation();
                        downloadFromS3Link(currentArticleState.attachment_url);
                      }}
                    >
                      Download
                    </Button>
                  )}
                  <Button>Upload Attachment</Button>
                </HStack>
              )}
            </Upload>
          </FormControl>
          <Box border="1px" borderColor="neutral.8">
            <Editor
              editorState={currentContent}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  previewImage: true,
                  uploadEnabled: true,
                  uploadCallback: uploadImage,
                },
                embedded: {
                  embedCallback,
                  defaultSize: {
                    height: '200',
                    width: '400',
                  },
                },
              }}
            />
          </Box>
        </Stack>
        <HStack>
        <Button type="submit" colorScheme="actionSecondary" mt="5">
          Submit
        </Button>
        
        <Button  colorScheme='red' paddingTop="28px" variant='outline' mt="5" onClick={onDelete} >
          Delete
        </Button>
        </HStack>
      </Box>
    </form>
  );
};

ArticleEdit.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string, value: PropTypes.string })),
  saveArticle: PropTypes.func,
  getTags: PropTypes.func,
  deleteArticle: PropTypes.func,
  currentArticle: PropTypes.shape({
    content: PropTypes.string,
    published_date: PropTypes.string,
    created: PropTypes.string,
  }),
  token: PropTypes.string,
  userFullName: PropTypes.string,
  saving: PropTypes.bool,
  saved: PropTypes.bool,
  article: PropTypes.shape({ slug: PropTypes.string }),
  resetEditStatus: PropTypes.func,
  isKpi: PropTypes.bool,
  getOrganisations: PropTypes.func,
  organisations: PropTypes.arrayOf(
    PropTypes.objectOf(
      { type: PropTypes.string, value: PropTypes.string },
      { type: PropTypes.integer, value: PropTypes.integer }
    )
  ),
};

export default ArticleEdit;
