import lineChunk from '@turf/line-chunk';
import combine from '@turf/combine';
import buffer from '@turf/buffer';

export default map => {
  return {
    setExtrusion: (
      type,
      grindersOrPackers,
      dataset,
      propertiesToAdd,
      extrusionValues,
      colorValues,
      colorsToUse,
      allowFrozenInv
    ) => {
      grindersOrPackers.forEach(function (grinderOrPacker) {
        if (
          (grinderOrPacker.name.indexOf('Frozen Inventory') === -1 && !allowFrozenInv) ||
          (allowFrozenInv && grinderOrPacker.name.indexOf('Frozen Inventory') > -1)
        ) {
          let thisDataSet = false;
          dataset.forEach(function (pieceOfData) {
            if (pieceOfData[type][0] === grinderOrPacker.name) {
              thisDataSet = pieceOfData;
            }
          });

          const thisPointGeoJSON = map.getSource(`${type}-${grinderOrPacker.name}`)._data;
          const thisPolyGeoJSON = map.getSource(`${type}-extrusion-${grinderOrPacker.name}`)._data;
          propertiesToAdd.forEach(function (property) {
            thisPointGeoJSON.features[0].properties[property] = 0;
            thisPolyGeoJSON.features[0].properties[property] = 0;
          });
          propertiesToAdd.forEach(function (property) {
            const thisValue = thisPointGeoJSON.features[0].properties[property]
              ? thisPointGeoJSON.features[0].properties[property] + thisDataSet[property]
              : thisDataSet[property];
            thisPointGeoJSON.features[0].properties[property] = thisValue;
            thisPolyGeoJSON.features[0].properties[property] = thisValue;
          });

          map.getSource(`${type}-${grinderOrPacker.name}`).setData(thisPointGeoJSON);
          map.getSource(`${type}-extrusion-${grinderOrPacker.name}`).setData(thisPolyGeoJSON);

          if (extrusionValues) {
            var extrusionVal = {
              property: propertiesToAdd[0],
              type: 'exponential',
              stops: [
                [extrusionValues[0], 50000],
                [extrusionValues[1], 1000000],
              ],
            };
            if (extrusionValues[0] === extrusionValues[1]) {
              extrusionVal = 1000000;
            }
            map.setPaintProperty(`${type}-extrusion-${grinderOrPacker.name}`, 'fill-extrusion-height', extrusionVal);
          }
          if (colorValues) {
            let revisedColors = [
              [colorValues[0], colorsToUse ? colorsToUse[0] : '#dd3e54'],
              [colorValues[1], colorsToUse ? colorsToUse[1] : '#6be585'],
            ];
            if (colorValues.length > 2) {
              revisedColors = [
                [colorValues[0], '#dd3e54'],
                [colorValues[1], '#dd3e54'],
                [colorValues[2], '#6be585'],
              ];
            }
            let extrusionVal = {
              property: propertiesToAdd[1] ? propertiesToAdd[1] : propertiesToAdd[0],
              type: 'exponential',
              stops: revisedColors,
            };
            if (colorValues[0] === colorValues[1]) {
              extrusionVal = '#6be585';
            }
            map.setPaintProperty(`${type}-extrusion-${grinderOrPacker.name}`, 'fill-extrusion-color', extrusionVal);
          } else {
            extrusionVal = '#6be585';
            map.setPaintProperty(`${type}-extrusion-${grinderOrPacker.name}`, 'fill-extrusion-color', extrusionVal);
          }
          map.setLayoutProperty(`${type}-${grinderOrPacker.name}`, 'visibility', 'none');
          map.setLayoutProperty(`${type}-extrusion-${grinderOrPacker.name}`, 'visibility', 'visible');
        }
      });
    },
    addDataToPackers: (packer_plants, dataset, propertiesToAdd, propertiesToAddNames) => {
      const packersPassed = [];
      dataset.forEach(function (dataPiece) {
        packer_plants.forEach(function (packer_plant) {
          if (packer_plant.id === dataPiece.packer_plant_id && packer_plant.name.indexOf('Frozen Inventory') === -1) {
            const thisPackerPointGeoJSON = map.getSource(`packers-${packer_plant.name}`)._data;
            const thisPackerPolyGeoJSON = map.getSource(`packers-extrusion-${packer_plant.name}`)._data;
            if (packersPassed.indexOf(packer_plant.id) === -1) {
              propertiesToAdd.forEach(function (property, index) {
                thisPackerPointGeoJSON.features[0].properties[propertiesToAddNames[index]] = 0;
                thisPackerPolyGeoJSON.features[0].properties[propertiesToAddNames[index]] = 0;
              });
            }
            packersPassed.push(packer_plant.id);
            propertiesToAdd.forEach(function (property, index) {
              const thisValue = thisPackerPointGeoJSON.features[0].properties[propertiesToAddNames[index]]
                ? thisPackerPointGeoJSON.features[0].properties[propertiesToAddNames[index]] + dataPiece[property]
                : dataPiece[property];
              thisPackerPointGeoJSON.features[0].properties[propertiesToAddNames[index]] = thisValue;
              thisPackerPolyGeoJSON.features[0].properties[propertiesToAddNames[index]] = thisValue;
            });
            map.getSource(`packers-${packer_plant.name}`).setData(thisPackerPointGeoJSON);
            map.getSource(`packers-extrusion-${packer_plant.name}`).setData(thisPackerPolyGeoJSON);
          }
        });
      });
    },
    resetGrinders: (grinders, filters) => {
      grinders.forEach(function (grinder) {
        if (filters.grinders[grinder.name]) {
          map.setPaintProperty(`grinders-${grinder.name}`, 'circle-color', '#2C333D');
          map.setLayoutProperty(`grinders-${grinder.name}`, 'visibility', 'visible');
          map.setLayoutProperty(`grinders-extrusion-${grinder.name}`, 'visibility', 'none');
        } else {
          map.setLayoutProperty(`grinders-${grinder.name}`, 'visibility', 'none');
          map.setLayoutProperty(`grinders-extrusion-${grinder.name}`, 'visibility', 'none');
        }
      });
    },
    resetPackers: (packer_plants, filters) => {
      packer_plants.forEach(function (packer_plant) {
        if (packer_plant.name.indexOf('Frozen Inventory') === -1) {
          if (filters.packer_plants[packer_plant.name]) {
            map.setPaintProperty(`packers-${packer_plant.name}`, 'circle-color', '#EC864F');
            map.setLayoutProperty(`packers-${packer_plant.name}`, 'visibility', 'visible');
            map.setLayoutProperty(`packers-extrusion-${packer_plant.name}`, 'visibility', 'none');
          } else if (
            map.getLayer(`packers-${packer_plant.name}`) &&
            map.getLayer(`packers-extrusion-${packer_plant.name}`)
          ) {
            map.setLayoutProperty(`packers-${packer_plant.name}`, 'visibility', 'none');
            map.setLayoutProperty(`packers-extrusion-${packer_plant.name}`, 'visibility', 'none');
          }
        } else {
          map.setLayoutProperty(`packers-${packer_plant.name}`, 'visibility', 'none');
          map.setLayoutProperty(`packers-extrusion-${packer_plant.name}`, 'visibility', 'none');
        }
      });
    },
    addLines: (
      grinders,
      packer_plants,
      dataset,
      propertiesToAdd,
      propertiesToAddNames,
      widthProperty,
      widthValues,
      colorProperty,
      colorValues,
      mouseover
    ) => {
      // Dataset pieces must have grinder and packer_plant names
      // First property is the width quantity
      const newLines = [];
      dataset.forEach(function (dataPiece) {
        packer_plants.forEach(function (packer_plant) {
          if (packer_plant.id === dataPiece.packer_plant_id) {
            grinders.forEach(function (grinder) {
              if (grinder.uid === dataPiece.grinder_uid) {
                if (newLines.indexOf(`packer-${packer_plant.name}-grinder-${grinder.name}-line`) === -1) {
                  const thisPackerPointGeoJSON = map.getSource(`packers-${packer_plant.name}`)._data;
                  const thisGrinderPointGeoJSON = map.getSource(`grinders-${grinder.name}`)._data;

                  const lineGeoJSON = {
                    type: 'FeatureCollection',
                    features: [
                      {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                          type: 'LineString',
                          coordinates: [
                            thisPackerPointGeoJSON.features[0].geometry.coordinates,
                            thisGrinderPointGeoJSON.features[0].geometry.coordinates,
                          ],
                        },
                      },
                    ],
                  };
                  const lineChunkGeoJSON = combine(lineChunk(lineGeoJSON, 200));
                  lineChunkGeoJSON.features[0].properties.name = `${packer_plant.name}, ${grinder.name}`;
                  lineChunkGeoJSON.features[0].properties.uid = `${packer_plant.id}, ${grinder.uid}`;
                  propertiesToAdd.forEach(function (property, index) {
                    lineChunkGeoJSON.features[0].properties[propertiesToAddNames[index]] = dataPiece[property];
                  });
                  map.addSource(`packer-${packer_plant.name}-grinder-${grinder.name}-line`, {
                    type: 'geojson',
                    data: lineChunkGeoJSON,
                  });
                  let lineColor = '#EC864F';
                  if (colorProperty) {
                    lineColor = {
                      property: colorProperty,
                      type: 'exponential',
                      stops: colorValues,
                    };
                  }
                  map.addLayer(
                    {
                      id: `packer-${packer_plant.name}-grinder-${grinder.name}-line`,
                      type: 'line',
                      source: `packer-${packer_plant.name}-grinder-${grinder.name}-line`,
                      paint: {
                        'line-color': lineColor,
                        'line-width': {
                          property: widthProperty,
                          type: 'exponential',
                          stops: [
                            [widthValues[0], 3],
                            [widthValues[1], 10],
                          ],
                        },
                      },
                      layout: {
                        visibility: 'none',
                      },
                    },
                    `packers-${packer_plant.name}`
                  );

                  if (mouseover) {
                    map.on('mouseover', `packer-${packer_plant.name}-grinder-${grinder.name}-line`, function (e) {
                      map.getCanvas().style.cursor = 'crosshair';
                      map.setPaintProperty(
                        `packer-${packer_plant.name}-grinder-${grinder.name}-line`,
                        'line-color',
                        '#FFC300'
                      );
                    });
                    map.on('mouseout', `packer-${packer_plant.name}-grinder-${grinder.name}-line`, function (e) {
                      map.getCanvas().style.cursor = '-webkit-grab';
                      map.setPaintProperty(
                        `packer-${packer_plant.name}-grinder-${grinder.name}-line`,
                        'line-color',
                        '#EC864F'
                      );
                    });
                  }
                  newLines.push(`packer-${packer_plant.name}-grinder-${grinder.name}-line`);
                } else {
                  // Just add data
                  const thisLineGeoJSON = map.getSource(
                    `packer-${packer_plant.name}-grinder-${grinder.name}-line`
                  )._data;
                  propertiesToAdd.forEach(function (property, index) {
                    const thisValue = thisLineGeoJSON.features[0].properties[propertiesToAddNames[index]]
                      ? thisLineGeoJSON.features[0].properties[propertiesToAddNames[index]] + dataPiece[property]
                      : dataPiece[property];
                    thisLineGeoJSON.features[0].properties[propertiesToAddNames[index]] = thisValue;
                  });
                  map.getSource(`packer-${packer_plant.name}-grinder-${grinder.name}-line`).setData(thisLineGeoJSON);
                }
              }
            });
          }
        });
      });
      return newLines;
    },
    associateGrindersAndPackersWithLines: (grinders, packer_plants) => {
      // Add to appropriate array
      // Dataset must have grinder and packer_plant names
      const packersToGrinders = [];
      const grindersToPackers = [];

      grinders.forEach(function (thisGrinder) {
        packer_plants.forEach(function (thisPacker) {
          let foundPacker = false;
          packersToGrinders.forEach(function (packer_plant) {
            if (packer_plant.name === thisPacker.name) {
              packer_plant.lines.push(`packer-${thisPacker.name}-grinder-${thisGrinder.name}-line`);
              foundPacker = true;
            }
          });
          if (!foundPacker) {
            packersToGrinders.push({
              name: thisPacker.name,
              lines: [`packer-${thisPacker.name}-grinder-${thisGrinder.name}-line`],
            });
          }
          let foundGrinder = false;
          grindersToPackers.forEach(function (grinder) {
            if (grinder.name === thisGrinder.name) {
              grinder.lines.push(`packer-${thisPacker.name}-grinder-${thisGrinder.name}-line`);
              foundGrinder = true;
            }
          });
          if (!foundGrinder) {
            grindersToPackers.push({
              name: thisGrinder.name,
              lines: [`packer-${thisPacker.name}-grinder-${thisGrinder.name}-line`],
            });
          }
        });
      });
      return { packersToGrinders, grindersToPackers };
    },
    showLines: (grindersToPackersAndPackersToGrinders, firstFeature) => {
      grindersToPackersAndPackersToGrinders.grindersToPackers.forEach(function (grinders) {
        grinders.lines.forEach(function (line) {
          if (map.getSource(line)) {
            map.setLayoutProperty(line, 'visibility', 'none');
          }
        });
      });
      grindersToPackersAndPackersToGrinders.packersToGrinders.forEach(function (packer_plants) {
        packer_plants.lines.forEach(function (line) {
          if (map.getSource(line)) {
            map.setLayoutProperty(line, 'visibility', 'none');
          }
        });
      });
      if (firstFeature.layer.id.indexOf('grinders') > -1) {
        grindersToPackersAndPackersToGrinders.grindersToPackers.forEach(function (grinders) {
          const grinderName =
            firstFeature.layer.id.indexOf('extrusion') === -1
              ? firstFeature.layer.id.split('s-')[1]
              : firstFeature.layer.id.split('s-extrusion-')[1];
          if (grinders.name === grinderName) {
            grinders.lines.forEach(function (line) {
              if (map.getSource(line)) {
                map.setLayoutProperty(line, 'visibility', 'visible');
              }
            });
          }
        });
      } else if (firstFeature.layer.id.indexOf('packers') > -1) {
        grindersToPackersAndPackersToGrinders.packersToGrinders.forEach(function (packer_plants) {
          const packerName =
            firstFeature.layer.id.indexOf('extrusion') === -1
              ? firstFeature.layer.id.split('s-')[1]
              : firstFeature.layer.id.split('s-extrusion-')[1];
          if (packer_plants.name === packerName) {
            packer_plants.lines.forEach(function (line) {
              if (map.getSource(line)) {
                map.setLayoutProperty(line, 'visibility', 'visible');
              }
            });
          }
        });
      }
    },
    dataInitialization: (grinders, packer_plants, mapboxgl) => {
      const filterSetup = {
        packer_plants: {},
        grinders: {},
      };

      // Setting up all geoJSON starting sources
      const packerPolyGeoJSON = {
        type: 'FeatureCollection',
        features: [],
      };
      const packerPointGeoJSON = {
        type: 'FeatureCollection',
        features: [],
      };
      packer_plants.forEach(function (packer_plant) {
        if (packer_plant.lon && packer_plant.lat) {
          filterSetup.packer_plants[packer_plant.name] = true;

          const geojsonFeature = {
            type: 'Feature',
            properties: {
              // id: packer_plant.id,
              uid: packer_plant.id,
              name: packer_plant.name,
              address: packer_plant.address,
            },
            geometry: {
              type: 'Point',
              coordinates: [packer_plant.lon || 0, packer_plant.lat || 0],
            },
          };
          packerPointGeoJSON.features.push(geojsonFeature);
          const bufferedCircle = buffer(geojsonFeature, 50);
          packerPolyGeoJSON.features.push(bufferedCircle);
        }
      });

      const grinderPolyGeoJSON = {
        type: 'FeatureCollection',
        features: [],
      };

      const grinderPointGeoJSON = {
        type: 'FeatureCollection',
        features: [],
      };

      grinders.forEach(function (grinder) {
        filterSetup.grinders[grinder.name] = true;

        const geojsonFeature = {
          type: 'Feature',
          properties: {
            // id: grinder.id,
            uid: grinder.uid,
            name: grinder.name,
            address: grinder.address,
          },
          geometry: {
            type: 'Point',
            coordinates: [grinder.lon || 0, grinder.lat || 0],
          },
        };
        grinderPointGeoJSON.features.push(geojsonFeature);
        const bufferedCircle = buffer(geojsonFeature, 50);
        grinderPolyGeoJSON.features.push(bufferedCircle);
      });

      // Adding map sources and initial layers
      packerPointGeoJSON.features.forEach(function (packer_plant) {
        const thisGeoJSON = {
          type: 'FeatureCollection',
          features: [packer_plant],
        };
        map.addSource(`packers-${packer_plant.properties.name}`, {
          type: 'geojson',
          data: thisGeoJSON,
        });
        map.addLayer({
          id: `packers-${packer_plant.properties.name}`,
          type: 'circle',
          source: `packers-${packer_plant.properties.name}`,
          paint: {
            'circle-radius': 5,
            'circle-color': '#EC864F',
            'circle-stroke-color': '#FFC300',
          },
        });
        map.on('mouseover', `packers-${packer_plant.properties.name}`, function (e) {
          map.getCanvas().style.cursor = 'crosshair';
          map.setPaintProperty(`packers-${packer_plant.properties.name}`, 'circle-stroke-width', 1);
        });
        map.on('mouseout', `packers-${packer_plant.properties.name}`, function (e) {
          map.getCanvas().style.cursor = '-webkit-grab';
          map.setPaintProperty(`packers-${packer_plant.properties.name}`, 'circle-stroke-width', 0);
        });
      });
      packerPolyGeoJSON.features.forEach(function (packer_plant) {
        const thisGeoJSON = {
          type: 'FeatureCollection',
          features: [packer_plant],
        };
        map.addSource(`packers-extrusion-${packer_plant.properties.name}`, {
          type: 'geojson',
          data: thisGeoJSON,
        });
        map.addLayer({
          id: `packers-extrusion-${packer_plant.properties.name}`,
          type: 'fill-extrusion',
          source: `packers-extrusion-${packer_plant.properties.name}`,
          paint: {
            'fill-extrusion-color': '#EC864F',
            'fill-extrusion-height': 100,
          },
          layout: {
            visibility: 'none',
          },
        });
        map.on('mouseover', `packers-extrusion-${packer_plant.properties.name}`, function (e) {
          map.getCanvas().style.cursor = 'crosshair';
          map.setPaintProperty(`packers-extrusion-${packer_plant.properties.name}`, 'fill-extrusion-opacity', 0.9);
        });
        map.on('mouseout', `packers-extrusion-${packer_plant.properties.name}`, function (e) {
          map.getCanvas().style.cursor = '-webkit-grab';
          map.setPaintProperty(`packers-extrusion-${packer_plant.properties.name}`, 'fill-extrusion-opacity', 1);
        });
      });

      grinderPointGeoJSON.features.forEach(function (grinder) {
        const thisGeoJSON = {
          type: 'FeatureCollection',
          features: [grinder],
        };

        const sourceName = `grinders-${grinder.properties.name}`;

        if (!map.getSource(sourceName)) {
          map.addSource(sourceName, {
            type: 'geojson',
            data: thisGeoJSON,
          });
        }

        map.addLayer({
          id: `grinders-${grinder.properties.name}`,
          type: 'circle',
          source: `grinders-${grinder.properties.name}`,
          paint: {
            'circle-radius': 7,
            'circle-color': '#2C333D',
            'circle-stroke-color': '#FFC300',
          },
        });
        map.on('mouseover', `grinders-${grinder.properties.name}`, function (e) {
          map.getCanvas().style.cursor = 'crosshair';
          map.setPaintProperty(`grinders-${grinder.properties.name}`, 'circle-stroke-width', 1);
        });
        map.on('mouseout', `grinders-${grinder.properties.name}`, function (e) {
          map.getCanvas().style.cursor = '-webkit-grab';
          map.setPaintProperty(`grinders-${grinder.properties.name}`, 'circle-stroke-width', 0);
        });
      });

      grinderPolyGeoJSON.features.forEach(function (grinder) {
        const thisGeoJSON = {
          type: 'FeatureCollection',
          features: [grinder],
        };

        const sourceName = `grinders-extrusion-${grinder.properties.name}`;

        if (!map.getSource(sourceName)) {
          map.addSource(sourceName, {
            type: 'geojson',
            data: thisGeoJSON,
          });
        }

        map.addLayer({
          id: `grinders-extrusion-${grinder.properties.name}`,
          type: 'fill-extrusion',
          source: `grinders-extrusion-${grinder.properties.name}`,
          paint: {
            'fill-extrusion-color': '#2C333D',
            'fill-extrusion-height': 100,
          },
          layout: {
            visibility: 'none',
          },
        });
        map.on('mouseover', `grinders-extrusion-${grinder.properties.name}`, function (e) {
          map.getCanvas().style.cursor = 'crosshair';
          map.setPaintProperty(`grinders-extrusion-${grinder.properties.name}`, 'fill-extrusion-opacity', 0.9);
        });
        map.on('mouseout', `grinders-extrusion-${grinder.properties.name}`, function (e) {
          map.getCanvas().style.cursor = '-webkit-grab';
          map.setPaintProperty(`grinders-extrusion-${grinder.properties.name}`, 'fill-extrusion-opacity', 1);
        });
      });

      return filterSetup;
    },
  };
};
