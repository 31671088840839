import ColdstoreInventory from '../../components/coldstore/coldstore-inventory/ColdstoreInventory';
import { connect } from 'react-redux';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

const mapStateToProps = state => {
  return {
    ...findEntitiesInState(state, {
      grinders: 'grinder',
      packer_plants: 'packer_plant',
      input_products: 'input_product',
      cold_stores: 'coldstore',
      currencies: ['form_fields', 'currency'],
      end_users: 'end_user',
    }),
    user: state.user,
    orders: state.orders_coldstore || [],
    notifying_action_groups: state.notifying_action_groups || [],
  };
};

export default connect(mapStateToProps)(ColdstoreInventory);
