import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { Input } from '@chakra-ui/react'
import PropTypes from 'prop-types';

const defaultCondition = { operator: 'and', expressions: [], conditions: [] }

const getPythonCondition = condition => {
    const op = [' ', condition.operator, ' '].join('');

    const e = [];
    const elen = condition.expressions.length;
    for (let i = 0; i < elen; i++) {
        const expr = condition.expressions[i];
        e.push(expr.op.replaceAll('%val', expr.val).replaceAll('%var', expr.name));
    }

    const n = [];
    const nlen = condition.conditions.length;
    for (let k = 0; k < nlen; k++) {
        const nestexpr = condition.conditions[k];
        const result = getPythonCondition(nestexpr);
        n.push(result);
    }

    const q = [];
    if (e.length > 0)
        q.push(e.join(op));

    if (n.length > 0)
        q.push(n.join(op));

    const query = ['(', q.join(op), ')'].join(' ');
    return '(  )' === query ? '' : query;
}

const getVariables = condition => {
    let e = [];
    const elen = condition.expressions.length;
    for (let i = 0; i < elen; i++) {
        const expr = condition.expressions[i];
        e.push(expr.name);
    }
    const nlen = condition.conditions.length;
    for (let k = 0; k < nlen; k++) {
        const nestexpr = condition.conditions[k];
        e = [...e, ...getVariables(nestexpr)]
    }
    return e;
}

const ConditionBuilder = ({ condition: con, parentCondition = null, variables, onUpdateCondition, onUpdateParentCondition }) => {

    const [condition, setCondition] = useState(parentCondition ? con : (con?.condition || defaultCondition))
    const defaultExpression = cloneDeep({
        name: variables[0]?.name,
        op: variables[0]?.ops[0]?.value,
        val: ''
    });

    useEffect(() => {
        const newCondition = cloneDeep(condition)
        if (parentCondition) {
            const newParentCondition = cloneDeep(parentCondition);
            newParentCondition.conditions[condition.index] = newCondition
            onUpdateParentCondition(newParentCondition);
        } else {
            onUpdateCondition({
                condition: newCondition,
                pythonCondition: getPythonCondition(newCondition),
                variables: [...new Set(getVariables(newCondition))],
            });
        }
    }, [condition])

    const onClickAddCondition = () => {
        const newCondition = cloneDeep(condition);
        const newChildCondition = cloneDeep(defaultCondition);
        newChildCondition.expressions.push(defaultExpression);
        newCondition.conditions.push(newChildCondition)
        setCondition(newCondition);
    }

    const onRemoveCondition = () => {
        const newParentCondition = cloneDeep(parentCondition);
        newParentCondition.conditions = newParentCondition.conditions.filter((_, index) => index !== condition.index)
        onUpdateParentCondition(newParentCondition)
    }

    const onChangeOperator = (e) => {
        const newCondition = cloneDeep(condition);
        newCondition.operator = e.target.value;
        setCondition(newCondition);
    }

    const onRemoveExpression = index => {
        const newCondition = cloneDeep(condition);
        if (newCondition.expressions.length <= 1 && parentCondition) {
            onRemoveCondition()
        }
        newCondition.expressions = newCondition.expressions.filter((_, i) => i !== index)
        setCondition(newCondition);
    }

    const onClickAddExpression = () => {
        const newCondition = cloneDeep(condition);
        newCondition.expressions.push(defaultExpression);
        setCondition(newCondition);
    }

    const onChangeExpressioVariable = (index, value) => {
        const newCondition = cloneDeep(condition);
        const variable = variables.find(t => t.name === value)
        const expression = cloneDeep({
            name: variable.name,
            op: variable.ops[0].value,
            val: ''
        });
        newCondition.expressions[index] = expression
        setCondition(newCondition);
    }

    const onChangeExpressioOps = (index, value) => {
        const newCondition = cloneDeep(condition);
        const newExpression = cloneDeep(newCondition.expressions[index]);
        newExpression.op = value;
        newExpression.val = '';
        newCondition.expressions[index] = newExpression
        setCondition(newCondition);
    }

    const onChangeExpressioVal = (index, value) => {
        const newCondition = cloneDeep(condition);
        const newExpression = cloneDeep(newCondition.expressions[index]);
        newExpression.val = value;
        newCondition.expressions[index] = newExpression
        setCondition(newCondition);
    }

    if (!Array.isArray(variables) || !variables.length) {
        return null;
    }
    
    return (
        <table>
            <tbody>
                <tr>
                    {parentCondition && (
                        <td onClick={onRemoveCondition}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                <path d="M12.5 2.3623C7.1239 2.3623 2.75 6.73621 2.75 12.1123C2.75 17.4884 7.1239 21.8623 12.5 21.8623C17.8761 21.8623 22.25 17.4884 22.25 12.1123C22.25 6.73621 17.8761 2.3623 12.5 2.3623ZM16.0302 14.5821C16.1027 14.6511 16.1608 14.7339 16.2008 14.8256C16.2409 14.9173 16.2622 15.0162 16.2635 15.1163C16.2648 15.2164 16.246 15.3157 16.2083 15.4084C16.1706 15.5012 16.1147 15.5854 16.0439 15.6562C15.9731 15.727 15.8889 15.7829 15.7961 15.8206C15.7034 15.8583 15.6041 15.8771 15.504 15.8758C15.4039 15.8745 15.305 15.8532 15.2133 15.8131C15.1216 15.7731 15.0388 15.715 14.9698 15.6425L12.5 13.1731L10.0302 15.6425C9.88836 15.7772 9.69954 15.8512 9.50397 15.8487C9.3084 15.8462 9.12155 15.7674 8.98325 15.6291C8.84494 15.4908 8.76614 15.3039 8.76364 15.1083C8.76113 14.9128 8.83513 14.7239 8.96984 14.5821L11.4392 12.1123L8.96984 9.64246C8.83513 9.50066 8.76113 9.31185 8.76364 9.11628C8.76614 8.92071 8.84494 8.73385 8.98325 8.59555C9.12155 8.45725 9.3084 8.37845 9.50397 8.37594C9.69954 8.37344 9.88836 8.44743 10.0302 8.58215L12.5 11.0515L14.9698 8.58215C15.1116 8.44743 15.3004 8.37344 15.496 8.37594C15.6916 8.37845 15.8784 8.45725 16.0167 8.59555C16.155 8.73385 16.2339 8.92071 16.2364 9.11628C16.2389 9.31185 16.1649 9.50066 16.0302 9.64246L13.5608 12.1123L16.0302 14.5821Z" fill="#C62704" />
                            </svg>
                        </td>
                    )}
                    <td>
                        <select value={condition.operator} onChange={onChangeOperator}>
                            <option value='and'>And</option>
                            <option value='or'>Or</option>
                        </select>
                    </td>
                    <td>
                        {condition.expressions.map((e, i) => {
                            const variable = variables.find(t => t.name === e.name);
                            return (
                                <table key={i}>
                                    <tbody>
                                        <tr>
                                            <td onClick={() => onRemoveExpression(i)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                    <path d="M12.5 2.3623C7.1239 2.3623 2.75 6.73621 2.75 12.1123C2.75 17.4884 7.1239 21.8623 12.5 21.8623C17.8761 21.8623 22.25 17.4884 22.25 12.1123C22.25 6.73621 17.8761 2.3623 12.5 2.3623ZM16.0302 14.5821C16.1027 14.6511 16.1608 14.7339 16.2008 14.8256C16.2409 14.9173 16.2622 15.0162 16.2635 15.1163C16.2648 15.2164 16.246 15.3157 16.2083 15.4084C16.1706 15.5012 16.1147 15.5854 16.0439 15.6562C15.9731 15.727 15.8889 15.7829 15.7961 15.8206C15.7034 15.8583 15.6041 15.8771 15.504 15.8758C15.4039 15.8745 15.305 15.8532 15.2133 15.8131C15.1216 15.7731 15.0388 15.715 14.9698 15.6425L12.5 13.1731L10.0302 15.6425C9.88836 15.7772 9.69954 15.8512 9.50397 15.8487C9.3084 15.8462 9.12155 15.7674 8.98325 15.6291C8.84494 15.4908 8.76614 15.3039 8.76364 15.1083C8.76113 14.9128 8.83513 14.7239 8.96984 14.5821L11.4392 12.1123L8.96984 9.64246C8.83513 9.50066 8.76113 9.31185 8.76364 9.11628C8.76614 8.92071 8.84494 8.73385 8.98325 8.59555C9.12155 8.45725 9.3084 8.37845 9.50397 8.37594C9.69954 8.37344 9.88836 8.44743 10.0302 8.58215L12.5 11.0515L14.9698 8.58215C15.1116 8.44743 15.3004 8.37344 15.496 8.37594C15.6916 8.37845 15.8784 8.45725 16.0167 8.59555C16.155 8.73385 16.2339 8.92071 16.2364 9.11628C16.2389 9.31185 16.1649 9.50066 16.0302 9.64246L13.5608 12.1123L16.0302 14.5821Z" fill="#C62704" />
                                                </svg>
                                            </td>
                                            <td>
                                                <select value={e.name} onChange={(e) => onChangeExpressioVariable(i, e.target.value)}>
                                                    ${variables.map(t => <option key={t.name} value={t.name}>{t.label}</option>)}
                                                </select>
                                            </td>
                                            <td>
                                                <select value={e.op} onChange={(e) => onChangeExpressioOps(i, e.target.value)}>
                                                    ${variable?.ops?.map(t => <option key={t.value} value={t.value}>{t.label}</option>)}
                                                </select>
                                            </td>
                                            <td>
                                                {variable?.ops?.find(o => o.value === e.op)?.inputRequired && (
                                                    <Input value={e.val} onChange={(e) => onChangeExpressioVal(i, e.target.value)} />
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}
                        <table>
                            <tbody>
                                <tr>
                                    <td onClick={onClickAddExpression} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                            <path d="M12.5001 2.36182C7.12403 2.36182 2.75012 6.73572 2.75012 12.1118C2.75012 17.4879 7.12403 21.8618 12.5001 21.8618C17.8762 21.8618 22.2501 17.4879 22.2501 12.1118C22.2501 6.73572 17.8762 2.36182 12.5001 2.36182ZM17.0001 12.8618H13.2501V16.6118H11.7501V12.8618H8.00012V11.3618H11.7501V7.61181H13.2501V11.3618H17.0001V12.8618Z" fill="#00BA88" />
                                        </svg>
                                    </td>
                                    <td>
                                        <button type="button" onClick={onClickAddCondition}>+()</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {condition.conditions.map((c, index) => <ConditionBuilder key={index} condition={{ ...c, index }} variables={variables} parentCondition={condition} onUpdateParentCondition={setCondition} />)}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

ConditionBuilder.propTypes = {
    condition: PropTypes.object,
    variables: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        ops: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            inputRequired: PropTypes.bool
        }))
    })).isRequired,
    onUpdateCondition: PropTypes.func,
}

export default ConditionBuilder;