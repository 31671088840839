import React from 'react';
import PackerOfferActions from './PackerOfferActions';
import POTableLine from './POTableLine';
import PurchaseOrderCell from './PurchaseOrderSection';

const PackerOfferTableContent = props => {
  return (
    <tbody>
      {props.items.map((item, index) => {
        return (
          <tr key={index}>
            <PackerOfferActions item={item} cancel={props.cancel} edit={props.edit} index={index} />
            <td>{item.start_date}</td>
            {item.packer_plant_id && item.packer_plant ? <td>{item.packer_plant.name}</td> : null}
            {item.packer_id && item.packers ? <td>{`Est. Group - ${item.packers.name}`}</td> : null}
            <td>{item.delivery_start_date}</td>
            <td>{item.delivery_end_date}</td>
            <POTableLine key={`${index}1`} lines={item.lines} fieldName="input_product" />
            <POTableLine key={`${index}2 `} lines={item.lines} fieldName="quantity" />
            <td rowSpan={item.lines}>{item.avg_load_cl.toFixed(2)}</td>
            <td rowSpan={item.lines}>${item.avg_unit_price.toFixed(4)}</td>
            <PurchaseOrderCell item={item} {...props} />
          </tr>
        );
      })}
    </tbody>
  );
};

export default PackerOfferTableContent;
