import React from 'react';
import { extent, line, curveCatmullRom } from 'd3';
import { scaleLinear } from 'd3-scale';
import useDimensions from '../../modules/hooks/useDimensions';
import styles from './LineChart.module.css';
import PropTypes from 'prop-types';

const padding = {
  top: 10,
  bottom: 10,
  left: 10,
  right: 10,
};

const useDomains = data => {
  const x = extent(data, d => d.date);
  const y = extent(data, d => d.price);
  return {
    x,
    y,
  };
};

const useScales = (dimensions, domains) => {
  if (!dimensions) return undefined;

  const frame = {
    top: padding.top,
    right: dimensions.width - padding.right,
    bottom: dimensions.height - padding.bottom,
    left: padding.left,
    width: dimensions.width - padding.right - padding.left,
    height: dimensions.height - padding.top - padding.bottom,
  };

  const x = scaleLinear().domain(domains.x).range([frame.left, frame.right]);

  const y = scaleLinear().domain(domains.y).rangeRound([frame.bottom, frame.top]).nice();
  return {
    frame,
    x,
    y,
  };
};

function LineChart(props) {
  const { data, format } = props;
  const [ref, dimensions] = useDimensions();
  const domains = useDomains(data);
  const scales = useScales(dimensions, domains);

  const chartProps = {
    data,
    dimensions,
    domains,
    scales,
    format,
  };
  const chartContent = scales && data.length && <Data {...chartProps} />;

  return (
    <svg className={styles.lineChartSvg} ref={ref}>
      {chartContent}
    </svg>
  );
}

const Data = props => {
  const { scales, data } = props;
  const lineWithData = line()
    .curve(curveCatmullRom)
    .x(d => scales.x(d.date))
    .y(d => scales.y(d.price));

  return (
    <g>
      <path d={lineWithData(data)} stroke="black" fill="none" />
    </g>
  );
};

Data.propTypes = {
  data: PropTypes.instanceOf(Object),
  scales: PropTypes.instanceOf(Object),
};

LineChart.propTypes = {
  data: PropTypes.instanceOf(Object),
  format: PropTypes.instanceOf(Object),
  scales: PropTypes.instanceOf(Object),
};

export default LineChart;
