import { HStack, Text, VStack } from '@chakra-ui/react';
import get from 'lodash/get';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RejectedCarton from './RejectedCarton';
import './RejectedCartons.scss';

class RejectedCartons extends Component {
  static propTypes = {
    pickupDate: PropTypes.string,
    rejectionReasons: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
      })
    ),
    rejectedCartons: PropTypes.arrayOf(PropTypes.object),
    lotNumbers: PropTypes.arrayOf(PropTypes.string),
    packerLotNumbers: PropTypes.arrayOf(PropTypes.string),
    portMarks: PropTypes.arrayOf(PropTypes.object),
    onAddCarton: PropTypes.func.isRequired,
    onChangeCarton: PropTypes.func.isRequired,
    onRemoveCarton: PropTypes.func.isRequired,
    isInEditMode: PropTypes.bool,
    orderId: PropTypes.number,
    lines: PropTypes.arrayOf(PropTypes.object),
    showToggles: PropTypes.bool,

  };

  static sumRejectedCartons(rejectedCartonList, pickupDate) {
    const validRejectedCartons = rejectedCartonList.filter(rc => {
      if (!pickupDate) return true;
      if (pickupDate && rc.rejectionDate) {
        return moment(rc.rejectionDate).isBefore(moment(pickupDate));
      }
      return true;
    });
    const totalCartons = sumBy(validRejectedCartons, rc => Number(rc.numberOfRejectedCartons || 0)) || 0;
    return totalCartons;
  }

  static calculateLineCartonCount(line, pickupDate, lineRejectedCartons) {
    const validRejectedCartonCountFromDB = get(line, 'validRejectedCartonCount') || 0;
    const cartonCountFromDB = get(line, 'cartonCount') || 0;

    let cartonCountOnPage = cartonCountFromDB;
    // Carton count is the difference between the total available cartons less the number of *valid*
    // rejected cartons.  In this case, we are dynamically updating this value when a user makes changes
    // in the browser, so we need to normalize the number of available cartons.
    const lineRejectedCartonCount = RejectedCartons.sumRejectedCartons(lineRejectedCartons, pickupDate);

    cartonCountOnPage = cartonCountFromDB + validRejectedCartonCountFromDB - lineRejectedCartonCount;

    return cartonCountOnPage;
  }

  render() {
    const {
      lines,
      rejectionReasons,
      rejectedCartons,
      lotNumbers,
      portMarks,
      onRemoveCarton,
      onAddCarton,
      onChangeCarton,
      isInEditMode,
      orderId,
      pickupDate,
      showToggles,
      packerLotNumbers,
    } = this.props;

    const linesList = Object.values(lines);

    const rejectionReasonOptions = rejectionReasons.map(el => ({
      label: el.value,
      value: el.value,
    }));

    const lineNumberOptions = linesList.map(line => ({
      label: get(line, 'inputProduct.name'),
      value: get(line, 'id'),
    }));

    const portMarkOptions = portMarks.filter(pm => pm.id).map(pm => ({ label: pm.mark, value: pm.id }));
    const lotNumberOptions = lotNumbers.map(ln => ({ label: ln, value: ln }));
    const packerLotNumberOptions = packerLotNumbers.map(ln => ({ label: ln, value: ln }))

    const totalAvailableCartonsInDB = sumBy(lines, line => Number(line.cartonCount || 0)) || 0;
    const totalRejectedCartonsInDB = sumBy(lines, line => Number(line.validRejectedCartonCount || 0)) || 0;

    const totalRejectedCartonsOnPage = RejectedCartons.sumRejectedCartons(rejectedCartons, pickupDate);

    // Carton count is the difference between the total availalbe cartons less the number of *valid*
    // rejected cartons.  In this case, we are dynamically updating this value when a user makes changes
    // in the browser, so we need to normalize the number of available cartons.
    const updatedTotalAvailableCartons =
      totalAvailableCartonsInDB + totalRejectedCartonsInDB - totalRejectedCartonsOnPage;

    return (
      <VStack align="stretch" spacing="12px">
        {/* <!-- TOTALS --> */}
        <HStack spacing="20px">
          {/* <!-- TOTAL REJECTED CARTONS --> */}
          <HStack spacing="10px">
            <Text as="p" fontWeight="bold">
              Total Rejected Cartons:
            </Text>
            <Text as="p" fontStyle="italic">
              {totalRejectedCartonsOnPage}
            </Text>
          </HStack>
          {/* <!-- REMAINING INVENTORY --> */}
          <HStack spacing="10px">
            <Text as="p" fontWeight="bold">
              Total Available Cartons:
            </Text>
            <Text as="p" fontStyle="italic">
              {updatedTotalAvailableCartons}
            </Text>
          </HStack>
        </HStack>
        {/* <!-- CARTONS --> */}
        {rejectedCartons.map((el, index) => {
          const selectedLine = el.lineNumber ? lines.find(line => Number(line.id) === Number(el.lineNumber)) : {};

          const allCartonsForLine = rejectedCartons.filter(rc => Number(rc.lineNumber) === Number(el.lineNumber));

          const lineProductionDates = [
            ...new Set(get(selectedLine, 'productionDetails', []).map(pd => pd.productionDate)),
          ];
          const lineCartonCount = RejectedCartons.calculateLineCartonCount(selectedLine, pickupDate, allCartonsForLine);
          const productionDateOptions = lineProductionDates.map(pd => ({ label: pd, value: pd }));
          const isLast = rejectedCartons.length === index + 1;
          return (
            <HStack key={`rejected-carton-${index}`} justifyContent="center" marginLeft="-11px !important">
              <Text as="p" color="accent.one.default" fontWeight="medium">
                {index + 1}
              </Text>
              <VStack spacing="18px" align="stretch" paddingLeft="11px" borderLeftWidth="1px" borderColor="black">
                <RejectedCarton
                  index={index}
                  item={el}
                  isLast={isLast}
                  cartonCount={lineCartonCount}
                  pickupDate={pickupDate}
                  rejectionReasonOptions={rejectionReasonOptions}
                  productionDateOptions={productionDateOptions}
                  lineNumberOptions={lineNumberOptions}
                  lotNumberOptions={lotNumberOptions}
                  packerLotNumberOptions={packerLotNumberOptions}
                  portMarkOptions={portMarkOptions}
                  onAddCarton={onAddCarton}
                  onRemoveCarton={onRemoveCarton}
                  onChangeCarton={onChangeCarton}
                  orderId={orderId}
                  showToggles={showToggles}
                  isDisabled={!isInEditMode}
                />
              </VStack>
            </HStack>
          );
        })}
      </VStack>
    );
  }
}

export default RejectedCartons;
