import { Spin } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const headerHeight = '85px';
const mainContentPaddingTop = '32px';
const spinHeight = '38px';

// This component is for the full page loading overlay
export default function LoadingOverlay({ loading }) {
  return (
    loading && (
      <div
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: '100%',
          background: 'rgba(255,255,255,0.2)',
          backdropFilter: 'blur(5px)',
          opacity: 1,
          transition: 'opacity 0.3s',
          textAlign: 'center',
          paddingTop: `calc((100vh - ${headerHeight} - ${mainContentPaddingTop} - ${spinHeight}) / 2)`,
          zIndex: 12,
        }}
        data-test="component-loading-overlay"
      >
        <Spin size="large" data-test="component-spin" />
      </div>
    )
  );
}

LoadingOverlay.propTypes = {
  loading: PropTypes.bool,
};
