import { Col, Radio, Row } from 'antd';
import * as React from 'react';
import ByCategory from './ByCategory';
import Comparison from './Comparison';
import Incidents from './Incidents';
import styles from './NCRs.module.css';
import NCRsDrivenByPackerPlants from './NCRsDrivenByPackerPlants';
import Summary from './Summary';
import ClaimDollars from './ClaimDollars';
import Constants from '../../../../Constants';
import VolumeSupplied from './VolumeSupplied';
import { MARKETS } from '../../../../config/markets';

const NCRs = props => {
  const { ncrsData, singlePackerPlant, market } = props;
  const [mode, setMode] = React.useState('total');

  const categoryColors = {};
  const uniqueCategory = Array.from(
    new Set(
      ncrsData.summary.incidents.byCategory
        .concat(ncrsData.summary.volume.byCategory)
        .concat(ncrsData.summary.dollars.byCategory)
        .map(x => x.category)
    )
  );
  (uniqueCategory || []).forEach((x, i) => {
    categoryColors[x] = Constants.CATEGORY_COLORS[i % Constants.CATEGORY_COLORS.length];
  });

  const childrenProps = {
    ...props,
    categoryColors,
    totalIncidents: ncrsData.incidents.count,
    packerPlantsInPacker:
      mode === 'perPackerPlant' && singlePackerPlant && singlePackerPlant.isPacker && ncrsData.bottomPackerPlants,
  };

  const listOfModules = React.useMemo(() => {
    if (ncrsData.exports) {
      if (singlePackerPlant && singlePackerPlant.isPacker && mode === 'perPackerPlant') {
        return [
          Incidents,
          VolumeSupplied,
          <Col span={12} key={'comparison'}>
            <Comparison {...childrenProps} data={ncrsData.packerPlantsTotal} />
          </Col>,
          ClaimDollars,
          Summary,
          // Exports,
        ];
      } else {
        return [
          Incidents,
          VolumeSupplied,
          ByCategory,
          ClaimDollars,
          <Col span={12} key={'comparison'}>
            {singlePackerPlant ? (
              !singlePackerPlant.isPacker ? (
                <Comparison {...childrenProps} data={ncrsData.packerPlantsTotal} />
              ) : null
            ) : (
              <NCRsDrivenByPackerPlants {...childrenProps} />
            )}
            <br />
            <Summary {...childrenProps} />
          </Col>,
          // Exports,
        ];
      }
    } else {
      return [
        Incidents,
        VolumeSupplied,
        mode === 'total' && ByCategory,
        ClaimDollars,
        (singlePackerPlant && !singlePackerPlant.isPacker) ||
        (singlePackerPlant &&
          singlePackerPlant.isPacker &&
          mode === 'perPackerPlant' &&
          ncrsData &&
          ncrsData.packerPlantsTotal.length > 0) ? (
          <Col span={12} key={'comparison'}>
            <Comparison {...childrenProps} data={ncrsData.packerPlantsTotal} />
          </Col>
        ) : (
          NCRsDrivenByPackerPlants
        ),
        Summary,
      ];
    }
  }, [singlePackerPlant, mode, ncrsData]);

  if (!ncrsData) return null;

  return (
    <div>
      {singlePackerPlant && singlePackerPlant.isPacker && (
        <div style={{ position: 'absolute', top: 32, right: 32 }}>
          <Radio.Group
            onChange={e => {
              setMode(e.target.value);
            }}
            defaultValue={mode}
            size="medium"
            className={styles.QualityNcrs_radio}
          >
            <Radio.Button key={1} value={'total'}>
              Total
            </Radio.Button>
            <Radio.Button key={2} value={'perPackerPlant'}>
              By establishment
            </Radio.Button>
          </Radio.Group>
        </div>
      )}
      <Row gutter={[50, 16]}>
        {singlePackerPlant && singlePackerPlant.isPacker && mode === 'perPackerPlant' && <Legend {...props} />}
        {ncrsData &&
          listOfModules
            .filter(c => c !== null)
            .map((Component, i) => {
              if (typeof Component === 'function') {
                return (
                  <Col span={12} key={`module-${i}`}>
                    <Component {...childrenProps} />
                  </Col>
                );
              }
              return Component;
            })}
      </Row>
    </div>
  );
};

const Legend = props => {
  const { ncrsData, singlePackerPlant } = props;
  return (
    <Col span={24}>
      <div style={{ opacity: 0.6 }}>Establishments with the worst NCR performance over the last 12 months</div>
      <div
        style={{
          display: 'flex',
          borderBottom: '1px solid rgba(210, 210, 210, 0.5)',
          paddingBottom: 10,
        }}
      >
        {ncrsData.summary.incidents.byPackerPlant.map(x => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 20,
            }}
            key={x.packerPlantId}
          >
            <div
              className={styles.QualityNcrs_legendBadge}
              style={{
                backgroundColor: x.color,
              }}
            />
            {x.packerPlantName}
          </div>
        ))}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 40,
          }}
        >
          <div
            className={styles.QualityNcrs_legendBadge}
            style={{
              backgroundColor: '#D3D5E0',
            }}
          />
          Other {singlePackerPlant.name} establishments
        </div>
      </div>
    </Col>
  );
};

export default NCRs;
