import React from 'react';

const ValidationErrors = ({ mainMessage, errors }) => {
  const validationErrors = errors.map((error, index) => <li key={index}>{error}</li>);
  return (
    <div className="row col-sm-12">
      {validationErrors.length ? (
        <div className="alert alert-danger pull-left">
          <div>
            {mainMessage ? (
              <span>{mainMessage}</span>
            ) : (
              <span>
                Please check the input fields and ensure they are all filled in correctly.
                <br />
                Note: A packer_plant offer cannot contain more than 22 total bins (44,000 lbs):
              </span>
            )}
          </div>
          <ul>{validationErrors}</ul>
        </div>
      ) : (
        false
      )}
    </div>
  );
};

export default ValidationErrors;
