import React from 'react';
import { useSelector } from 'react-redux';
import { getYear, getQuarter, format } from 'date-fns';
import { Col, Row, Checkbox, Collapse, Radio, Select } from 'antd';
import useAnnotations from '../../../../modules/hooks/useAnnotation';
import { TREASURY_MODULE, TREASURY_TIME_INTERVAL } from '../../../../slices/treasury/treasuryConfig';
import {
  selectTreasuryActiveMarket,
  selectTreasuryTimeScale,
} from '../../../../slices/treasury/selectors/selectTreasuryBasicData';
import {
  selectTreasuryDetailsActiveVolume,
  selectTreasuryDetailsActivePrice,
  selectTreasuryDetailsActiveCost,
  TREASURY_DETAILS_PRICE,
  TREASURY_DETAILS_VOLUME,
} from '../../../../slices/treasury-details/treasuryDetailsReducers';
import { selectTreasuryData } from '../../../../slices/treasury/selectors/selectTreasuryData';
import { selectTreasuryBuyPerformanceData } from '../../../../slices/treasury/selectors/selectTreasuryBuyPerformanceData';
import {
  selectTreasuryDataPerPackerPlant,
  selectTreasuryDataPerGrinder,
} from '../../../../slices/treasury/selectors/selectTreasuryDataPerEntity';
import VolumeChart from '../../treasury/VolumeChart/VolumeChart';
import PriceChart from '../../treasury/PriceChart/PriceChart';
import CostChart from '../../treasury/CostChart/CostChart';
import { RankingChart } from '../RankingChart/RankingChart';
import AnnotationModal from '../../../../shared/AnnotationModal/AnnotationModal';
import AnnotationList from '../../../../shared/AnnotationList/AnnotationList';
import styles from './Charts.module.css';

const STRATEGIES = ['Formula', 'Contract', 'Spot', 'NOF', 'Financial derivative', 'Frozen product usage'];

const PRICE_OPTIONS = [
  'fmg_price',
  'usda_price',
  // 'usda_fat',
  // 'usda_lean',
  // 'usda_50CL',
  // 'usda_65CL',
  // 'usda_78CL',
  // 'usda_90CL',
  // 'mecardo_price',
  // 'mla_price',
];

const priceOptionsLabels = {
  usda_price: 'USDA Market Px (76CL WAVG)',
  fmg_price: 'FMG Forecast Px (76CL WAVG)',
  // usda_fat: 'Fat beef',
  // usda_lean: 'Lean beef',
  // usda_50CL: '50CL',
  // usda_65CL: '65CL',
  // usda_78CL: '78CL',
  // usda_90CL: '90CL',
  // mecardo_price: 'Mecardo',
  // mla_price: 'MLA',
};

const getStrategiesArray = array =>
  array.map(x => {
    return { label: x, isShown: true };
  });

const getPriceOptionsArray = array =>
  array.map(x => {
    return {
      label: x,
      checkboxLabel: priceOptionsLabels[x],
      isShown: x === 'fmg_price' || x === 'usda_price',
    };
  });

export default function Charts() {
  const timeScale = useSelector(selectTreasuryTimeScale);
  const volume = useSelector(selectTreasuryDetailsActiveVolume);
  const price = useSelector(selectTreasuryDetailsActivePrice);
  const cost = useSelector(selectTreasuryDetailsActiveCost);
  const treasuryData = useSelector(selectTreasuryData);
  const buyPerformanceData = useSelector(selectTreasuryBuyPerformanceData);
  const dataPerPackerPlant = useSelector(selectTreasuryDataPerPackerPlant);
  const dataPerGrinder = useSelector(selectTreasuryDataPerGrinder);

  const packer_plants = React.useMemo(
    () => getListOfEntities(dataPerPackerPlant, volume),
    [dataPerPackerPlant, volume]
  );

  const grinders = React.useMemo(() => getListOfEntities(dataPerGrinder, volume), [dataPerGrinder, volume]);

  const annotationStateRiskManagement = useAnnotations({
    page: 'treasury',
    focusSelector: selectTreasuryActiveMarket,
    module: 'risk-management',
    timeScaleSelector: selectTreasuryTimeScale,
  });
  const annotationStatePerformance = useAnnotations({
    page: 'treasury',
    focusSelector: selectTreasuryActiveMarket,
    module: 'performance-and-forecast',
    timeScaleSelector: selectTreasuryTimeScale,
  });
  const annotationStateCost = useAnnotations({
    page: 'treasury',
    focusSelector: selectTreasuryActiveMarket,
    module: 'cost-savings',
    timeScaleSelector: selectTreasuryTimeScale,
  });

  const [strategies, setStrategies] = React.useState(getStrategiesArray(STRATEGIES));
  const [priceOptions, setPriceOptions] = React.useState(getPriceOptionsArray(PRICE_OPTIONS));
  const [volumeType, setVolumeType] = React.useState('percent');
  const [costPriceType, setCostPriceType] = React.useState('average');
  const [groupBy, setGroupBy] = React.useState(null);
  const [packer_plant, setPackerPlant] = React.useState(null);
  const [grinder, setGrinder] = React.useState(null);

  React.useEffect(() => {
    if (volume === TREASURY_DETAILS_VOLUME.HEDGED_VS_EXPOSED) {
      setStrategies(getStrategiesArray(['Hedged', 'Exposed']));
    } else if (volume === TREASURY_DETAILS_VOLUME.FAT_VS_LEAN) {
      setStrategies(getStrategiesArray(['Fat', 'Lean']));
    } else if (volume === TREASURY_DETAILS_VOLUME.ALL_STRATEGIES || !volume) {
      setStrategies(getStrategiesArray(STRATEGIES));
    }
  }, [volume]);

  React.useEffect(() => {
    if (price === TREASURY_DETAILS_PRICE.MARKET_PRICE_AND_FORECAST) {
      setPriceOptions(getPriceOptionsArray(PRICE_OPTIONS));
    }
  }, [price]);

  React.useEffect(() => {
    if (volume === TREASURY_DETAILS_VOLUME.TOTAL) {
      setVolumeType('unit');
    }
  }, [volume]);

  React.useEffect(() => {
    if (packer_plants) {
      setPackerPlant(packer_plants[0]);
    }
  }, [packer_plants]);

  React.useEffect(() => {
    if (grinders) {
      setGrinder(grinders[0]);
    }
  }, [grinders]);

  const {
    addNewAnnotation: addAnnotationVolume,
    editAnnotation: editAnnotationVolume,
    annotations: annotationsVolume,
  } = annotationStateRiskManagement;
  const {
    addNewAnnotation: addAnnotationPrice,
    editAnnotation: editAnnotationPrice,
    annotations: annotationsPrice,
  } = annotationStatePerformance;
  const {
    addNewAnnotation: addAnnotationCost,
    editAnnotation: editAnnotationCost,
    annotations: annotationsCost,
  } = annotationStateCost;

  if (!treasuryData) return null;

  const isSingleStrategyChart =
    volume === TREASURY_DETAILS_VOLUME.FORMULA ||
    volume === TREASURY_DETAILS_VOLUME.CONTRACT ||
    volume === TREASURY_DETAILS_VOLUME.SPOT ||
    volume === TREASURY_DETAILS_VOLUME.NOF ||
    volume === TREASURY_DETAILS_VOLUME.HEDGED ||
    volume === TREASURY_DETAILS_VOLUME.EXPOSED ||
    volume === TREASURY_DETAILS_VOLUME.TOTAL;

  const getFilteredByPackerPlantData = () => {
    return dataPerPackerPlant.map(y => {
      let breakdown = [];
      const selectedPackerPlant = y.entityBreakdown.find(z => z.entity === packer_plant.name);
      if (selectedPackerPlant) {
        breakdown = selectedPackerPlant.volumeBreakdown;
      }
      let rankingBreakdown = [];
      const selectedType = y.typeBreakdown.find(z => z.type === volume);
      if (selectedType) {
        rankingBreakdown = selectedType.volumeBreakdown.sort((t, v) => v.volumeRatio - t.volumeRatio);
      }
      const int = {
        interval: y.interval,
        breakdown,
        rankingBreakdown,
        annotation: y.annotation,
      };
      return int;
    });
  };

  const getFilteredByGrinderData = () => {
    return dataPerGrinder.map(y => {
      let breakdown = [];
      const selectedGrinder = y.entityBreakdown.find(z => z.entity === grinder.name);
      if (selectedGrinder) {
        breakdown = selectedGrinder.volumeBreakdown;
      }
      let rankingBreakdown = [];
      const selectedType = y.typeBreakdown.find(z => z.type === volume);
      if (selectedType) {
        rankingBreakdown = selectedType.volumeBreakdown.sort((t, v) => v.volumeRatio - t.volumeRatio);
      }
      const int = {
        interval: y.interval,
        breakdown,
        rankingBreakdown,
        annotation: y.annotation,
      };
      return int;
    });
  };

  let data = treasuryData;
  if (groupBy === 'packerPlants' && packer_plant && isSingleStrategyChart) {
    data = getFilteredByPackerPlantData();
  } else if (groupBy === 'grinders' && grinder && isSingleStrategyChart) {
    data = getFilteredByGrinderData();
  }

  const currentInterval =
    timeScale === TREASURY_TIME_INTERVAL.QUARTERLY
      ? `Q${getQuarter(new Date())} ${getYear(new Date())}`
      : `${format(new Date(), 'MMM')} ${getYear(new Date())}`;

  const annotationIntervalFormat = timeScale === TREASURY_TIME_INTERVAL.QUARTERLY ? 'QQQ yyyy' : 'MMM yyyy';

  const ind = data.findIndex(x => x.interval === currentInterval);
  const intervalsUntilCurrent = data.slice(0, ind + 1).map(x => x.interval);

  const forceColor =
    isSingleStrategyChart && groupBy === 'packerPlants' && packer_plant
      ? { [volume]: packer_plant.color }
      : groupBy === 'grinders' && grinder
      ? { [volume]: grinder.color }
      : undefined;

  const rankingChartComponent = (
    <RankingChart
      data={data}
      splitIndex={ind === -1 ? -1 : ind + 1}
      entities={groupBy === 'packerPlants' ? packer_plants : grinders}
    />
  );

  const volumeChartComponent = (
    <VolumeChart
      riskManagementData={data}
      addNewAnnotation={addAnnotationVolume}
      editAnnotation={editAnnotationVolume}
      moduleAnnotations={annotationsVolume}
      annotationIntervalFormat={annotationIntervalFormat}
      splitIndex={ind === -1 ? -1 : ind + 1}
      isLarge={!price && !cost && !groupBy}
      strategies={isSingleStrategyChart ? [volume] : strategies.filter(x => x.isShown).map(x => x.label)}
      volumeType={volumeType}
      withDataTable={
        (volume === TREASURY_DETAILS_VOLUME.ALL_STRATEGIES ||
          volume === TREASURY_DETAILS_VOLUME.HEDGED_VS_EXPOSED ||
          volume === TREASURY_DETAILS_VOLUME.HEDGED ||
          volume === TREASURY_DETAILS_VOLUME.EXPOSED) &&
        timeScale === TREASURY_TIME_INTERVAL.QUARTERLY
      }
      withMultiOptionTable
      forceColor={forceColor}
    />
  );

  const priceChartComponent = (
    <PriceChart
      data={price === TREASURY_DETAILS_PRICE.MARKET_PRICE_AND_FORECAST ? buyPerformanceData : data}
      splitIndex={ind === -1 ? -1 : ind + 1}
      addNewAnnotation={addAnnotationPrice}
      editAnnotation={editAnnotationPrice}
      moduleAnnotations={annotationsPrice}
      annotationIntervalFormat={annotationIntervalFormat}
      isLarge={!volume && !cost}
      chartType={price}
      strategies={
        isSingleStrategyChart
          ? [volume]
          : (price === TREASURY_DETAILS_PRICE.MARKET_PRICE_AND_FORECAST ? priceOptions : strategies)
              .filter(x => x.isShown)
              .map(x => x.label)
      }
      costPriceType={costPriceType}
      forceColor={forceColor}
    />
  );

  const costChartComponent = (
    <CostChart
      costSavingsData={data}
      splitIndex={ind === -1 ? -1 : ind + 1}
      addNewAnnotation={addAnnotationCost}
      editAnnotation={editAnnotationCost}
      moduleAnnotations={annotationsCost}
      annotationIntervalFormat={annotationIntervalFormat}
      isLarge={!volume && !price}
      chartType={cost}
      strategies={isSingleStrategyChart ? [volume] : strategies.filter(x => x.isShown).map(x => x.label)}
      withDataTable={!volume && timeScale === TREASURY_TIME_INTERVAL.QUARTERLY}
      withMultiOptionTable
      forceColor={forceColor}
    />
  );

  const volumeChartSwitch = (
    <Radio.Group size="small" onChange={e => setVolumeType(e.target.value)} value={volumeType}>
      <Radio.Button value="percent">%</Radio.Button>
      <Radio.Button value="unit">lb</Radio.Button>
    </Radio.Group>
  );

  const priceChartSwitch = (
    <Radio.Group size="small" onChange={e => setCostPriceType(e.target.value)} value={costPriceType}>
      <Radio.Button value="average">average</Radio.Button>
      <Radio.Button value="median">median</Radio.Button>
    </Radio.Group>
  );

  const rankingChart =
    groupBy !== null && volume && isSingleStrategyChart ? (
      <Collapse ghost defaultActiveKey={1}>
        <Collapse.Panel
          header={`${
            groupBy === 'packerPlants' ? 'Establishment' : 'Grinder'
          } ranking (by % of total ${volume} volume)`}
          key={1}
        >
          {rankingChartComponent}
        </Collapse.Panel>
      </Collapse>
    ) : null;

  const volumeChart =
    volume && !price && !cost && !groupBy ? (
      <>
        <div className={styles.headerSwitch} style={{ left: '70px' }}>
          {volumeChartSwitch}
        </div>
        <div style={{ marginBottom: 26 }}>Volume</div>
        {volumeChartComponent}
      </>
    ) : (
      <div style={{ position: 'relative' }}>
        <div className={styles.headerSwitch} style={{ left: '70px' }} data-collapse>
          {volumeChartSwitch}
        </div>
        <Collapse ghost defaultActiveKey={1}>
          <Collapse.Panel header="Volume" key={1}>
            {volumeChartComponent}
          </Collapse.Panel>
        </Collapse>
      </div>
    );

  const priceChart =
    !volume && price && !cost ? (
      <>
        {price !== TREASURY_DETAILS_PRICE.MARKET_PRICE_AND_FORECAST && (
          <div
            className={styles.headerSwitch}
            style={{
              left: price === TREASURY_DETAILS_PRICE.COST_PRICE ? '90px' : '110px',
            }}
          >
            {priceChartSwitch}
          </div>
        )}
        <div style={{ marginBottom: 26 }}>{price}</div>
        {priceChartComponent}
      </>
    ) : (
      <div style={{ position: 'relative' }}>
        {price !== TREASURY_DETAILS_PRICE.MARKET_PRICE_AND_FORECAST && (
          <div
            className={styles.headerSwitch}
            style={{
              left: price === TREASURY_DETAILS_PRICE.COST_PRICE ? '90px' : '110px',
            }}
            data-collapse
          >
            {priceChartSwitch}
          </div>
        )}

        <Collapse ghost defaultActiveKey={1}>
          <Collapse.Panel header={price} key={1}>
            {priceChartComponent}
          </Collapse.Panel>
        </Collapse>
      </div>
    );

  const costChart =
    !volume && !price && cost ? (
      <>
        <div>{`Cost ${cost}`}</div>
        {costChartComponent}
      </>
    ) : (
      <Collapse ghost defaultActiveKey={1}>
        <Collapse.Panel header={`Cost ${cost}`} key={1}>
          {costChartComponent}
        </Collapse.Panel>
      </Collapse>
    );

  return (
    <Row>
      <Col span={19}>
        {rankingChart}
        {volume && !price && !cost && volumeChart}
        {price && !volume && !cost && priceChart}
        {cost && !volume && !price && costChart}
        {volume && price && !cost && (
          <>
            {volumeChart}
            {priceChart}
          </>
        )}
        {volume && !price && cost && (
          <>
            {volumeChart}
            {costChart}
          </>
        )}
        {!volume && price && cost && (
          <>
            {priceChart}
            {costChart}
          </>
        )}
        {volume && price && cost && (
          <>
            {volumeChart}
            {priceChart}
            {costChart}
          </>
        )}
        <AnnotationModal {...annotationStateRiskManagement} />
        <AnnotationModal {...annotationStatePerformance} />
        <AnnotationModal {...annotationStateCost} />
      </Col>
      <Col span={5} style={{ paddingLeft: 20 }}>
        {!(!volume && price === TREASURY_DETAILS_PRICE.MARKET_PRICE_AND_FORECAST && !cost) &&
          (isSingleStrategyChart ? (
            <GroupBySelector
              groupBy={groupBy}
              setGroupBy={setGroupBy}
              packer_plants={packer_plants}
              packer_plant={packer_plant}
              setPackerPlant={setPackerPlant}
              grinders={grinders}
              grinder={grinder}
              setGrinder={setGrinder}
            />
          ) : (
            <StrategySelector strategies={strategies} setStrategies={setStrategies} />
          ))}
        {price === TREASURY_DETAILS_PRICE.MARKET_PRICE_AND_FORECAST && (
          <PriceOptionSelector priceOptions={priceOptions} setPriceOptions={setPriceOptions} />
        )}
        <div style={{ textTransform: 'uppercase' }}>Annotations</div>
        {volume && (
          <>
            <div style={{ textTransform: 'uppercase', fontSize: '12px' }}>Volume</div>
            <AnnotationList
              {...annotationStateRiskManagement}
              annotationIntervalFormat={annotationIntervalFormat}
              intervalsUntilCurrent={intervalsUntilCurrent}
            />
          </>
        )}
        {price && (
          <>
            <div style={{ textTransform: 'uppercase', fontSize: '12px' }}>Price</div>
            <AnnotationList
              {...annotationStatePerformance}
              annotationIntervalFormat={annotationIntervalFormat}
              intervalsUntilCurrent={intervalsUntilCurrent}
            />
          </>
        )}
        {cost && (
          <>
            <div style={{ textTransform: 'uppercase', fontSize: '12px' }}>Cost</div>
            <AnnotationList
              {...annotationStateCost}
              annotationIntervalFormat={annotationIntervalFormat}
              intervalsUntilCurrent={intervalsUntilCurrent}
            />
          </>
        )}
      </Col>
    </Row>
  );
}

function StrategySelector(props) {
  const { strategies, setStrategies } = props;
  return (
    <>
      <span style={{ textTransform: 'uppercase' }}>Strategy</span>
      <div style={{ margin: '10px 0 20px' }}>
        {strategies.map(s => {
          return (
            <Checkbox
              key={s.label}
              className={styles[`checkboxStrategy-${s.label.replaceAll(' ', '-')}`]}
              onChange={() => {
                const tmp = strategies.map(x => {
                  if (x.label === s.label) {
                    return { label: x.label, isShown: !x.isShown };
                  }
                  return x;
                });
                setStrategies(tmp);
              }}
              checked={s.isShown}
              style={{ width: '100%', marginLeft: 0 }}
            >
              {s.label}
            </Checkbox>
          );
        })}
      </div>
    </>
  );
}

function PriceOptionSelector(props) {
  const { priceOptions, setPriceOptions } = props;
  return (
    <>
      <span style={{ textTransform: 'uppercase' }}>Price</span>
      <div style={{ margin: '10px 0 20px' }}>
        {priceOptions.map(s => {
          return (
            <Checkbox
              key={s.label}
              className={styles[`checkboxPriceOption-${s.label.replaceAll(' ', '-')}`]}
              onChange={() => {
                const tmp = priceOptions.map(x => {
                  if (x.label === s.label) {
                    return {
                      label: x.label,
                      checkboxLabel: x.checkboxLabel,
                      isShown: !x.isShown,
                    };
                  }
                  return x;
                });
                setPriceOptions(tmp);
              }}
              checked={s.isShown}
              style={{ width: '100%', marginLeft: 0 }}
            >
              {s.checkboxLabel}
            </Checkbox>
          );
        })}
      </div>
    </>
  );
}

function GroupBySelector(props) {
  const { groupBy, setGroupBy, packer_plants, packer_plant, setPackerPlant, grinders, grinder, setGrinder } = props;
  return (
    <>
      <span style={{ textTransform: 'uppercase' }}>Group by</span>
      <div style={{ margin: '10px 0 20px' }}>
        <Select value={groupBy} style={{ width: '100%' }} onChange={v => setGroupBy(v)}>
          <Select.Option value={null}>--</Select.Option>
          <Select.Option value="packerPlants">Establishments</Select.Option>
          <Select.Option value="grinders">Grinders</Select.Option>
        </Select>
      </div>
      {groupBy === 'packerPlants' && (
        <Radio.Group
          onChange={e => setPackerPlant(e.target.value)}
          value={packer_plant}
          style={{ marginBottom: 20 }}
          size="small"
        >
          {packer_plants.map(p => {
            if (!p) {
              return null;
            }
            return (
              <Radio.Button
                key={p.name}
                value={p}
                style={{ width: '100%', fontSize: 12 }}
                checked={packer_plant && packer_plant.name === p.name}
              >
                <div className={styles.entitySelector}>
                  <div className={styles.entityColor} style={{ backgroundColor: p.color }} />
                  {p.name}
                </div>
              </Radio.Button>
            );
          })}
        </Radio.Group>
      )}
      {groupBy === 'grinders' && (
        <Radio.Group
          onChange={e => setGrinder(e.target.value)}
          value={grinder}
          style={{ marginBottom: 20 }}
          size="small"
        >
          {grinders.map(g => {
            if (!g) {
              return null;
            }
            return (
              <Radio.Button
                key={g.name}
                value={g}
                style={{ width: '100%', fontSize: 12 }}
                checked={grinder && grinder.name === g.name}
              >
                <div className={styles.entitySelector}>
                  <div className={styles.entityColor} style={{ backgroundColor: g.color }} />
                  {g.name}
                </div>
              </Radio.Button>
            );
          })}
        </Radio.Group>
      )}
    </>
  );
}

function getListOfEntities(data, volumeType) {
  if (!data) {
    return null;
  }
  const entities = [];
  const colors = [
    '#1CA6B2',
    '#F01D82',
    '#805CFF',
    '#F2C94C',
    '#00B5F2',
    '#FC5D36',
    '#006df2',
    '#FF9A3D',
    '#545B72',
    '#AB2424',
    '#029916',
  ];
  data.forEach(y => {
    const selectedType = y.typeBreakdown.find(z => z.type === volumeType);
    if (selectedType) {
      const entitiesPerInt = selectedType.volumeBreakdown.map(q => q.orderType);
      entitiesPerInt.forEach(p => {
        if (entities.indexOf(p) === -1 && p !== undefined) {
          entities.push(p);
        }
      });
    }
  });
  return entities.map((x, i) => {
    return { name: x, color: colors[i % colors.length] };
  });
}
