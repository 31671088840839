import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Typography } from 'antd';
import { selectMarketsMcMenuData } from '../../../slices/markets/selectors/selectMarketsMcMenuData';
import McMenuTable from '../../../components/analyticDashboard/markets/McMenuTable/McMenuTable';
import IUnleashClassFlagProvider from '../../../components/unleash/UnleashClassFlagProvider';
import Constants from '../../../Constants';
// import { ChevronRight } from '../../../shared/Icons';

// This container specifically serves the McMenu Board card.
function McMenu(/* props */) {
  const marketsMcMenuData = useSelector(selectMarketsMcMenuData);

  return (
    <Card>
      <Typography.Title level={3} style={{ fontWeight: 'normal' }}>
        McMenu Board Index
        <div style={{ display: 'inline-block', width: '18px' }}>{/* <ChevronRight /> */}</div>
      </Typography.Title>
      <McMenuTable marketsMcMenuData={marketsMcMenuData} />
    </Card>
  );
}

const McMenuUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.MARKET_DASHBOARD_MC_MENU}
        show={<McMenu {...props} />}
        hide=""
      />
    </>
  );
};

export default McMenuUnleashController;
