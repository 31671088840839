import React, { Component } from 'react';
import Constants from '../../Constants';

class Maintenance extends Component {
  render() {
    return (
      <div className="login-container login-page">
        <div className="login">
          <div className="logo">
            <img style={{ width: '100px' }} src="img/BOAB_Blue.png" alt="" />
          </div>
          <div className="title">
            <h3 id="form-title" className="form-title font-green">
              BOAB Under Maintenance
            </h3>
          </div>
          <div className="content">
            <div>
              <form className="login-form">
                <div className="form-group">
                  <h1 className="control-label visible-ie8 visible-ie9">Ongoing Maintenance</h1>
                </div>
                <div className="form-group">
                  <p>
                    BOAB is undergoing maintenance. The maintenance window is from 6:30 PM Central time to 4:30 AM
                    Central time. If you need any help during this window, please contact Jonathan
                    (jonathan@fultonmarketgroup.com) and Andy (andy@fultonmarketgroup.com).
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Maintenance;
