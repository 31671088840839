import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel, IconButton } from '@chakra-ui/react';
import { withInternationalOrderFormContext } from '../InternationalOrderForm/InternationalOrderFormContext';
import { isEqual } from 'lodash';
import { defaultRequiredSydneyPOFormLabels } from '../../../lib/PurchaseOrderFunctions';
import Tooltip from '../../../quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const POFormFieldLabel = ({ htmlFor, label, fieldName, isRequired, requiredFieldsLabels, tooltip }) => {
  return (
    <FormLabel as="label" htmlFor={htmlFor}>
      {label}
      {tooltip !== undefined && (
        <>
          &nbsp;
          <Tooltip content={tooltip} placement="right">
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              icon={<IoInformationCircleOutline size="14px" />}
              variant="unstyled"
            />
          </Tooltip>
        </>
      )}
      {fieldName &&
      (isRequired ||
        requiredFieldsLabels?.form?.includes(fieldName) ||
        defaultRequiredSydneyPOFormLabels.includes(fieldName))
        ? '*'
        : ''}
    </FormLabel>
  );
};

POFormFieldLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string,
  isRequired: PropTypes.bool,
  requiredFieldsLabels: PropTypes.arrayOf(PropTypes.string),
  tooltip: PropTypes.string,
};

export default withInternationalOrderFormContext(React.memo(POFormFieldLabel, isEqual));
