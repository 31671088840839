import React, { Component } from 'react';

import TabbedWindow, { Tab } from '../basic/TabbedWindow';
import ArticlesList from '../../containers/articles/ArticlesList';
import ArticleEdit from '../../containers/articles/ArticleEdit';

class Articles extends Component {
  render() {
    return (
      <TabbedWindow defaultTab="articles">
        <Tab name="Articles" tabKey="articles">
          <ArticlesList />
        </Tab>
        <Tab name="Create Article" tabKey="createarticle" authConfig={{ requiredPermissions: ['articles_write'] }}>
          <ArticleEdit />
        </Tab>
      </TabbedWindow>
    );
  }
}

export default Articles;
