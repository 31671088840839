import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  useToast
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Constants from '../../Constants';
import { fetchConfigs } from '../../actions/actions_config';
import HorizontalNavigationBand from '../../components/core/HorizontalNavigationBand';
import { selectConfigs } from '../../reducers/reducer_config';
import { selectCurrentUser } from '../../reducers/reducer_user';
import PricingExecutor from './PricingExecutor/PricingExecutor';
import PricingCalculators from './PricingCalculators/PricingCalculators';
import PricingFees from './PricingFees/PricingFees';
import PricingVariables from './PricingVariables/PricingVariables';
import PricingDocumentTemplate from './PricingDocumentTemplate/PricingDocumentTemplate';

const tabs = [
  'Calculators',
  'Variables',
  'Fees',
  'Document Templates',
  'Tester',
];

const tabNewButtonStyle = { marginTop: '-80px' }

export default () => {
  const { token } = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);
  const configs = useSelector(selectConfigs);
  const toast = useToast();

  const [axiosConfig, setAxiosConfig] = useState();
  const [variables, setVariables] = useState()
  const [calculators, setCalculators] = useState()
  const [documentTemplates, setDocumentTemplates] = useState()
  const [fees, setFees] = useState()
  const [feeRateLookupColumns, setFeeRateLookupColumns] = useState()
  const [feeRateStateColumns, setFeeRateStateColumns] = useState()
  const [events, setEvents] = useState()

  useEffect(() => {
    if (!token) {
      return;
    }
    setAxiosConfig({
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      data: '{}'
    });
    dispatch(fetchConfigs(token));
    loadVariables()
    loadCalculators()
    loadDocumentTemplates()
    loadFees()
    loadEvents()
    loadFeeRateColumns()
  }, [token]);

  /* Variables */

  const loadVariables = () => {
    axios.get(`${Constants.URL}pricing/variables`, axiosConfig).then(response => {
      setVariables(response.data);
    });
  }

  const onSaveVariable = variable => {
    (!variable.created ?
      axios.post(`${Constants.URL}pricing/variables`, variable, axiosConfig) :
      axios.patch(`${Constants.URL}pricing/variables/${variable.uid}`, variable, axiosConfig)
    ).then(loadVariables)
  }

  const onRemoveVariable = variable => {
    axios.delete(`${Constants.URL}pricing/variables/${variable.uid}`, axiosConfig).then(loadVariables).catch(() => handleError())
  }

  /* Calculators */

  const loadCalculators = () => {
    axios.get(`${Constants.URL}pricing/calculators`, axiosConfig).then(response => {
      setCalculators(response.data);
    });
  }

  const onSaveCalculator = calculator => {
    (!calculator.created ?
      axios.post(`${Constants.URL}pricing/calculators`, calculator, axiosConfig) :
      axios.patch(`${Constants.URL}pricing/calculators/${calculator.uid}`, calculator, axiosConfig)
    ).then(loadCalculators)
  }

  const onRemoveCalculator = calculator => {
    axios.delete(`${Constants.URL}pricing/calculators/${calculator.uid}`, axiosConfig).then(loadCalculators).catch(() => handleError())
  }

  /* Fees */
  const loadFees = () => {
    axios.get(`${Constants.URL}pricing/fees`, axiosConfig).then(response => {
      setFees(response.data);
    });
  }

  const onSaveFee = fee => {
    (!fee.created ?
      axios.post(`${Constants.URL}pricing/fees`, fee, axiosConfig) :
      axios.patch(`${Constants.URL}pricing/fees/${fee.uid}`, fee, axiosConfig)
    ).then(loadFees)
  }

  const onRemoveFee = fee => {
    axios.delete(`${Constants.URL}pricing/fees/${fee.uid}`, axiosConfig).then(loadFees).
      catch(error => handleError(error?.response?.data?.title?.includes('pricing_fee_rate') && 'This fee has rates associated, this can\'t be removed.'))
  }

  const loadFeeRateColumns = () => {
    axios.get(`${Constants.URL}pricing/fee-rate-columns?type=lookup`, axiosConfig).then(response => {
      setFeeRateLookupColumns(response.data);
    });
    axios.get(`${Constants.URL}pricing/fee-rate-columns?type=state`, axiosConfig).then(response => {
      setFeeRateStateColumns(response.data);
    });
  }

  /* Document templates */

  const loadDocumentTemplates = () => {
    axios.get(`${Constants.URL}pricing/document-templates`, axiosConfig).then(response => {
      setDocumentTemplates(response.data);
    });
  }

  const onSaveDocumentTemplate = documentTemplate => {
    (!documentTemplate.created ?
      axios.post(`${Constants.URL}pricing/document-templates`, documentTemplate, axiosConfig) :
      axios.patch(`${Constants.URL}pricing/document-templates/${documentTemplate.uid}`, documentTemplate, axiosConfig)
    ).then(loadDocumentTemplates)
  }

  const onRemoveDocumentTemplate = documentTemplate => {
    axios.delete(`${Constants.URL}pricing/document-templates/${documentTemplate.uid}`, axiosConfig).then(loadDocumentTemplates).catch(() => handleError())
  }

  /* Events */
  const loadEvents = () => {
    axios.get(`${Constants.URL}pricing/events`, axiosConfig).then(response => {
      setEvents(response.data);
    });
  }

  /* Pricing */
  const onPricing = request => axios.post(`${Constants.URL}pricing`, request, axiosConfig)

  /* Promoting */
  const onPromotingImport = e => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      axios.post(`${Constants.URL}pricing/promoting`, e.target.result, axiosConfig).then(() => {
        loadVariables()
        loadCalculators()
        loadDocumentTemplates()
        loadFees()
        loadEvents()
        loadFeeRateColumns()
      })
    };
  }

  const onPromotingExport = () => axios({
    url: `${Constants.URL}pricing/promoting`,
    method: 'GET',
    responseType: 'blob',
    ...axiosConfig
  }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `export-pricing-${new Date().toISOString()}.json`); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });

  const handleError = msg => toast({
    title: 'An error occurred.',
    description: msg || 'Please contact administrator with time of error.',
    status: 'error',
    duration: 9000,
    isClosable: true,
  })

  return (
    <Box>
      <Tabs>
        <HorizontalNavigationBand justifyContent="flex-start" paddingX="52px">
          <TabList>
            {tabs.map(tab => (
              <Tab key={tab}
                _focus={{ outline: 'none' }}
                _selected={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
                _hover={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
                fontWeight="normal"
                width="162px"
                fontSize="14px"
                padding="0px"
                height="103px"
                marginRight="10px">
                {tab}
              </Tab>
            ))}
          </TabList>
        </HorizontalNavigationBand>
        <TabPanels>
          <TabPanel {...tabNewButtonStyle}>
            <PricingCalculators calculators={calculators} variables={variables} events={events} fees={fees} onSaveCalculator={onSaveCalculator} onRemoveCalculator={onRemoveCalculator} />
          </TabPanel>
          <TabPanel {...tabNewButtonStyle}>
            <PricingVariables variables={variables} onSaveVariable={onSaveVariable} onRemoveVariable={onRemoveVariable} />
          </TabPanel>
          <TabPanel {...tabNewButtonStyle}>
            <PricingFees fees={fees} feeRateLookupColumns={feeRateLookupColumns} feeRateStateColumns={feeRateStateColumns} onSaveFee={onSaveFee} onRemoveFee={onRemoveFee} axiosConfig={axiosConfig} />
          </TabPanel>
          <TabPanel {...tabNewButtonStyle}>
            <PricingDocumentTemplate documentTemplates={documentTemplates} calculators={calculators} onSaveDocumentTemplate={onSaveDocumentTemplate} onRemoveDocumentTemplate={onRemoveDocumentTemplate} />
          </TabPanel>
          <TabPanel>
            <PricingExecutor mode="test" calculators={calculators} onPricing={onPricing} configs={configs} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <VStack marginBottom="80px" alignItems="end" hidden={!/[?&]promoting$/.test(location.search)}>
        <Box>
          <Button colorScheme="actionPrimary" onClick={onPromotingExport} marginRight="16px">Export</Button>
          <Button colorScheme="actionPrimary" onClick={() => {
            hiddenFileInput.current.value = ""
            hiddenFileInput.current.click()
          }} marginRight="16px">Import</Button>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={onPromotingImport}
            style={{ display: 'none' }}
          />
        </Box>
      </VStack>
    </Box>
  );
};
