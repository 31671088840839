const schema = [
  {
    name: 'interest',
    displayName: 'Interest',
    systemIdField: 'id',
    displayIdField: 'name',
    path: '/interests',
    tabGroup: { key: 'pricing', label: 'Pricing' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'Name or title of the interest rate',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'effective_from',
        displayName: 'Effective From',
        tooltip: 'Date when the interest rate starts to apply',
        dataType: 'date',
        searchable: true,
        required: true,
      },
      {
        name: 'rate',
        displayName: 'Rate',
        tooltip: 'Provide the rate of the interest',
        dataType: 'number',
        required: true,
      },
    ],
  },
];
export default schema;
