import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@chakra-ui/react';

function HorizontalNavigationBand({ children, ...rest }) {
  return (
    <Flex
      justifyContent="flex-end"
      alignItems="center"
      bgColor="nav.horizontalBandBgColor"
      height="105px"
      paddingRight="58px"
      {...rest}
    >
      {children}
    </Flex>
  );
}

HorizontalNavigationBand.propTypes = {
  children: PropTypes.node,
};

export default HorizontalNavigationBand;
