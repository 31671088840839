import React from 'react';
import { Bar } from 'react-chartjs-2';

import { commify, shorten } from '../../../functions';

const CostsSavingsDetails = ({ maxRange, step, clearDetails, details }) => {
  const costSavingsDetailsChartOptions = {
    data: details.chartData,
    width: 4,
    height: 2,
    options: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        position: 'right',
        text: details.text,
        fontSize: 14,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              callback: (value, index, values) => `$${commify(value)}`,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          beforeLabel: (value, values) => {
            const thisLabel = values.datasets[value.datasetIndex].label;
            return `${thisLabel}: $${shorten(value.yLabel)}`;
          },
          label: (value, values) => {
            return false;
          },
        },
      },
    },
  };

  if (maxRange) {
    costSavingsDetailsChartOptions.options.scales.yAxes[0].ticks.max = maxRange;
    costSavingsDetailsChartOptions.options.scales.yAxes[0].ticks.stepSize = step;
  }

  return (
    <div style={{ width: '400px', paddingTop: '10px', paddingLeft: '40px' }}>
      {details ? (
        <div>
          <Bar {...costSavingsDetailsChartOptions} />
          <button className="btn btn-default btn-sm" onClick={clearDetails}>
            Clear Detail View
          </button>
        </div>
      ) : (
        <div>
          <p>Click on any chart on the left to see a per-grinder breakdown here.</p>
        </div>
      )}
    </div>
  );
};

export default CostsSavingsDetails;
