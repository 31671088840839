import React from 'react';
import { Alert, AlertIcon, AlertDescription, Box, CloseButton, Text, UnorderedList, ListItem } from '@chakra-ui/react';

const OrderFormFinalizeMessage = ({ pendingLoaded, fastLoaded, copies, latestOrders, reset }) => {
  return (
    <Alert rounded="md" status="success">
      <AlertIcon />
      <Box>
        <AlertDescription>
          {pendingLoaded ? (
            <Text>
              Successfully created {copies} pending order{copies > 1 ? 's' : false}
              <br />
            </Text>
          ) : (
            false
          )}
          {fastLoaded ? (
            <Box>
              Successfully sent {copies} order{copies > 1 ? 's' : false}
              <UnorderedList styleType="none">
                {latestOrders.map(order => {
                  return (
                    <ListItem key={order.grinder_po_number}>
                      Grinder PO #:{' '}
                      {order.grinder_po_number
                        ? order.grinder_po_number
                        : order.grinder.po_strategy.toLowerCase() === 'manual' && 'to be assigned'}
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </Box>
          ) : (
            false
          )}
        </AlertDescription>
      </Box>
      <Box>
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={1}
          onClick={() => {
            reset(true);
          }}
        />
      </Box>
    </Alert>
  );
};

export default OrderFormFinalizeMessage;
