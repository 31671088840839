import { createSelector } from '@reduxjs/toolkit';
import { group } from 'd3';
import { getYear } from 'date-fns';
import { selectQualityClaimsData } from './selectBasicDetailsClaimsData';

const selectAvailableYears = createSelector(selectQualityClaimsData, data => {
  if (!data) return [];

  const groupedByYear = Array.from(
    group(data, x => {
      return getYear(new Date(x.date));
    })
  );

  return groupedByYear.map(yearEntries => yearEntries[0]).sort((a, b) => b - a);
});

export default selectAvailableYears;
