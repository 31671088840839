import {
  COLD_STORE_SEND_EMAIL_ERROR,
  PREVIEW_PDF,
  RESET_COLD_STORE_MODAL,
  TRIGGER_COLDSTORE_EMAIL_SENT_STATUS,
} from '../actions/actions_coldstore_orders_util';

const initialState = {
  previewOrders: '',
  isColdstoreEmailSent: false,
  coldstoreSendEmailError: null,
};

const coldstore_orders_util = (state = initialState, action) => {
  switch (action.type) {
    case TRIGGER_COLDSTORE_EMAIL_SENT_STATUS:
      return {
        ...state,
        isColdstoreEmailSent: action.payload,
      };
    case PREVIEW_PDF:
      return {
        ...state,
        previewOrders: action.payload,
      };
    case COLD_STORE_SEND_EMAIL_ERROR:
      return {
        ...state,
        coldstoreSendEmailError: action.payload,
      };
    case RESET_COLD_STORE_MODAL:
      return {
        ...state,
        coldstoreSendEmailError: null,
        isColdstoreEmailSent: false,
      };
    default:
      return state;
  }
};

export default coldstore_orders_util;
