import { DatePicker, Radio, Typography } from 'antd';
import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
} from 'date-fns';
import moment from 'moment';
import React from 'react';

export default function FilterTimePeriod(props) {
  const { timePeriod, onTimePeriodChange } = props;

  const [type, setType] = React.useState(undefined);

  const lastWeekStart = startOfWeek(subDays(new Date(), 7)).getTime();
  const lastWeekEnd = endOfWeek(subDays(new Date(), 7)).getTime();

  const thisWeekStart = startOfWeek(new Date()).getTime();
  const thisWeekEnd = endOfWeek(new Date()).getTime();

  const thisMonthStart = startOfMonth(new Date()).getTime();
  const thisMonthEnd = endOfMonth(new Date()).getTime();

  const thisQuarterStart = startOfQuarter(new Date()).getTime();
  const thisQuarterEnd = endOfQuarter(new Date()).getTime();

  const thisYearStart = startOfYear(new Date()).getTime();
  const thisYearEnd = endOfYear(new Date()).getTime();

  return (
    <div>
      <Typography.Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Time range
      </Typography.Text>
      <div>
        <Radio.Group value={type} onChange={e => setType(e.target.value)}>
          <Radio.Button value="last_week" onClick={() => onTimePeriodChange([lastWeekStart, lastWeekEnd])}>
            Last week
          </Radio.Button>
          <Radio.Button value="this_week" onClick={() => onTimePeriodChange([thisWeekStart, thisWeekEnd])}>
            This week
          </Radio.Button>
          <Radio.Button value="this_month" onClick={() => onTimePeriodChange([thisMonthStart, thisMonthEnd])}>
            Month
          </Radio.Button>
          <Radio.Button value="this_quarter" onClick={() => onTimePeriodChange([thisQuarterStart, thisQuarterEnd])}>
            Quarter
          </Radio.Button>
          <Radio.Button value="this_year" onClick={() => onTimePeriodChange([thisYearStart, thisYearEnd])}>
            Year
          </Radio.Button>
        </Radio.Group>
        <DatePicker.RangePicker
          picker="week"
          value={timePeriod.map(d => moment(d))}
          format={m => {
            return m.format('DD MMM YYYY');
          }}
          onChange={newPeriod => {
            if (!newPeriod) return;
            onTimePeriodChange(newPeriod.map(m => m.set({ hour: 23, minute: 59, second: 59 }).toDate().getTime()));
          }}
        />
      </div>
    </div>
  );
}
