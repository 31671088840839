import React from 'react';
import PropTypes from 'prop-types';
import { Box, Image } from '@chakra-ui/react';

const ArticleContent = ({ article = {}, ...args }) => {
  return (
    <Box>
      <Image src={article.hero_url} />
      <Box mt="5">
        <div
          dangerouslySetInnerHTML={{
            __html: article.content,
          }}
        />
      </Box>
    </Box>
  );
};

ArticleContent.propTypes = {
  article: PropTypes.instanceOf(Object),
};

export default ArticleContent;
