import { Tooltip } from 'antd';
import { curveMonotoneX, line, scaleBand, scaleLinear } from 'd3';
import pick from 'lodash/pick';
import React from 'react';
import { largestRemainderRound } from '../../../../modules/format';
import useDimensions from '../../../../modules/hooks/useDimensions';
import styles from './BaseChart.module.css';
import { Box } from '@chakra-ui/react';

const defaultPadding = {
  top: 10,
  bottom: 10,
  left: 10,
  right: 30,
};
const defaultPathColors = ['rgba(78, 89, 132, 0.3)', 'rgba(78, 89, 132, 1)'];

const useDomains = (maxVal, minVal, data) => {
  return {
    x: data.map(x => x.interval),
    y: [minVal, maxVal],
  };
};

const useScales = (dimensions, padding, domains, ticksNumber) => {
  if (!dimensions) return undefined;

  const frame = {
    top: padding.top,
    right: dimensions.width - padding.right,
    bottom: dimensions.height - padding.bottom,
    left: padding.left,
    width: dimensions.width - padding.right - padding.left,
    height: dimensions.height - padding.top - padding.bottom,
  };

  const x = scaleBand().domain(domains.x).range([frame.left, frame.right]).paddingInner(0);

  const y = scaleLinear().domain(domains.y).rangeRound([frame.bottom, frame.top]).nice(ticksNumber);
  return {
    frame,
    x,
    y,
  };
};

export default function BaseChart(props) {
  const {
    barData,
    isLarge,
    isSmall,
    splitIndex,
    currentLineIndex,
    colors,
    columns,
    legendLabels,
    formatValues,
    formatIntervals = value => value,
    minMaxGenerator,
    ticksNumber,
    isBicolored,
    futureDataKey,
    customContent,
    tableContent,
    noCurrentLine,
    noLegend,
    AnnotationLayer,
    tooltipLabels,
    formatTooltip,
    lastBarColor,
    isLineChart,
    withSmoothing = false,
    isYearOnYear,
    highlightedValue,
    padding = defaultPadding,
    pathColors,
    debug = false,
    volumeType,
    noLastPoint,
  } = props;
  let maxValData;
  let minValData;

  barData.forEach(x => {
    const vals = columns.map(c => x[c] || 0).filter(x => x !== undefined && x !== null);
    const sums = futureDataKey
      ? columns.map(c => {
          const a = x[c] !== undefined ? x[c] : 0;
          const b = x[`${c}-${futureDataKey}`] !== undefined ? x[`${c}-${futureDataKey}`] : 0;
          return a + b;
        })
      : [];
    const max = Math.max(...vals, ...sums, 0);
    const min = Math.min(...vals, ...sums, 0);
    if (max > maxValData || maxValData === undefined) maxValData = Math.round(max * 10000) / 10000;
    if (min < minValData || minValData === undefined) minValData = Math.round(min * 10000) / 10000;
  });

  const [ref, dimensions] = useDimensions();
  const { minVal, maxVal } = minMaxGenerator
    ? minMaxGenerator(maxValData, minValData)
    : { minVal: 0.9 * minValData, maxVal: 1.1 * maxValData };

  const domains = useDomains(maxVal, minVal, barData);
  const scales = useScales(dimensions, { ...defaultPadding, ...padding }, domains, ticksNumber);
  const itemWidth = scales && scales.x.bandwidth();
  const ticks = scales && scales.y.ticks(ticksNumber);

  const chartProps = {
    scales,
    ticks,
    barData,
    itemWidth,
    splitIndex,
    minVal,
    formatValues,
    formatIntervals,
  };

  const [tooltip, setTooltip] = React.useState(undefined);

  let data = [];
  let lastPoint;

  if (scales) {
    data = columns.map(column => {
      return {
        column,
        data: barData
          .map((x, i) => {
            const point = [
              scales.x(x.interval) + scales.x.bandwidth() / 2,
              (x[column] != null && scales.y(x[column])) || null,
            ];
            if (point[1] === null && !lastPoint && barData[i - 1] && !noLastPoint) {
              // get the last point that had a defined y axis value
              // as it's the last data point in the line chart
              lastPoint = {
                column,
                coordinates: [
                  scales.x(barData[i - 1].interval) + scales.x.bandwidth() / 2,
                  (barData[i - 1][column] != null && scales.y(barData[i - 1][column])) || null,
                ],
              };
            }
            return point;
          })
          .filter(x => {
            return x[1] !== null;
          }),
      };
    });

    if (debug) {
      console.log('columns', columns);
      console.log('data', data);
      console.log('lastPoint', lastPoint);
    }
  }

  const lineGenerator = withSmoothing ? line().curve(curveMonotoneX) : line().defined(([x, y]) => y != null);
  const pathStrings = data.map(d => {
    return { column: d.column, pathString: lineGenerator(d.data) };
  });

  return (
    <Box
      maxWidth={{ base: '950px', lg: '1200px', xl: '1180px', '2xl': '2100px' }}
      overflow="scroll"
      className="base-chart"
    >
      {!noLegend && (
        <div className={styles.BaseChart_legend}>
          {columns.map(x => (
            <div className={styles.BaseChart_legendItem} key={x}>
              <div className={styles.BaseChart_legendColor} style={{ backgroundColor: colors[x] }} />
              {legendLabels ? legendLabels[x] : x}
            </div>
          ))}
        </div>
      )}
      {AnnotationLayer && AnnotationLayer({ barData, scales, splitIndex })}
      <div className={styles.BaseChart_mainChartContainer}>
        <div
          className={`${styles.BaseChart_tickXContainer} tick-x-container`}
          style={{ height: isLarge ? 500 : isSmall ? 100 : 200 }}
        >
          {scales && <TickXValues {...chartProps} formatValues={formatValues} />}
        </div>
        <div
          className={`${styles.BaseChart_chartContainer} chart-container`}
          style={{ height: isLarge ? 500 : isSmall ? 100 : 200 }}
        >
          <svg
            style={{ width: barData.length < 45 ? '100%' : barData.length * 20 }}
            className={styles.BaseChart_svg}
            ref={ref}
          >
            {scales && (
              <>
                <TickXLines {...chartProps} />
                <TickYLines {...chartProps} yPosition={scales.y(ticks[0])} />
                {isLineChart ? (
                  <>
                    {pathStrings.map((pathS, index) => (
                      <path
                        key={`path-${pathS.column}`}
                        d={pathS.pathString}
                        // stroke={pathColors[index] || defaultPathColors[1]}
                        stroke={pathColors ? pathColors[pathS.column] : defaultPathColors[index]}
                        fill="none"
                        strokeWidth="2"
                      />
                    ))}
                    {lastPoint && (
                      <circle
                        cx={lastPoint.coordinates[0]}
                        cy={lastPoint.coordinates[1]}
                        r={5}
                        fill={pathColors ? pathColors[lastPoint.column] : defaultPathColors[1]}
                      />
                    )}
                    {TooltipForLineCharts({
                      barData,
                      scales,
                      columns,
                      formatTooltip,
                      tooltipLabels,
                    })}
                  </>
                ) : customContent ? (
                  <>
                    {customContent({ barData, scales, splitIndex, setTooltip })}
                    {TooltipForLineCharts({
                      barData,
                      scales,
                      columns,
                      formatTooltip,
                      tooltipLabels,
                    })}
                  </>
                ) : (
                  <StackedBars
                    data={barData}
                    scales={scales}
                    splitIndex={splitIndex}
                    colors={colors}
                    columns={columns}
                    isBicolored={isBicolored}
                    futureDataKey={futureDataKey}
                    setTooltip={setTooltip}
                    formatValues={formatValues}
                    formatTooltip={formatTooltip}
                    tooltipLabels={tooltipLabels}
                    lastBarColor={lastBarColor}
                    volumeType={volumeType}
                    currentLineIndex={currentLineIndex}
                    withForecastTransparent
                  />
                )}
                {highlightedValue && (
                  <line
                    x1={scales.frame.left}
                    y1={scales.y(highlightedValue.value)}
                    x2={scales.frame.right}
                    y2={scales.y(highlightedValue.value)}
                    style={highlightedValue.style}
                  />
                )}
                {!noCurrentLine &&
                  (splitIndex !== -1 && barData[splitIndex] ? (
                    <line
                      x1={scales.x(barData[splitIndex].interval)}
                      y1={scales.y(ticks[ticks.length - 1])}
                      x2={scales.x(barData[splitIndex].interval)}
                      y2={scales.y(ticks[0])}
                      stroke="#0B1435"
                      opacity={0.2}
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeDasharray="2 4"
                    />
                  ) : (
                    <line
                      x1={scales.x(barData[barData.length - 1]?.interval) + scales.x.bandwidth()}
                      y1={scales.y(ticks[ticks.length - 1])}
                      x2={scales.x(barData[barData.length - 1]?.interval) + scales.x.bandwidth()}
                      y2={scales.y(ticks[0])}
                      stroke="#0B1435"
                      opacity={0.2}
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeDasharray="2 4"
                    />
                  ))}
              </>
            )}
          </svg>
          {tooltip}
        </div>
      </div>
      <div className={styles.BaseChart_rowContainer}>
        {scales && <TickYValues {...chartProps} showYears={!isYearOnYear} />}
      </div>
      {tableContent && tableContent({ chartProps })}
    </Box>
  );
}

function TooltipForLineCharts({ barData, scales, columns, formatTooltip, tooltipLabels }) {
  return (
    <g>
      {barData.map(x => {
        return (
          <Tooltip
            key={x.interval}
            placement="bottom"
            overlayStyle={{ whiteSpace: 'pre-line' }}
            title={columns
              .filter(c => x[c] !== undefined)
              .map(
                c =>
                  `${x.interval}${tooltipLabels ? ` ${tooltipLabels[c]}` : ` ${c}`}: ${
                    formatTooltip ? formatTooltip(x[c]) : x[c]
                  }`
              )
              .join('\n')}
          >
            <rect
              x={scales.x(x.interval)}
              y={scales.frame.top}
              width={scales.x.bandwidth()}
              height={scales.frame.height}
              className={styles.BaseChart_hoverThing}
              fill="rgba(31, 35, 52, 0)"
            />
          </Tooltip>
        );
      })}
    </g>
  );
}

function TickXValues({ scales, ticks, formatValues }) {
  return (
    <>
      {ticks.map(t => {
        return (
          <div
            key={`tickX-${Math.random()}`}
            className={`${styles.BaseChart_tickX} tick-x`}
            style={{
              top: scales.y(t) - 20 / 2,
            }}
          >
            {formatValues ? formatValues(t) : t}
          </div>
        );
      })}
    </>
  );
}

function TickXLines({ scales, ticks, splitIndex, barData, minVal }) {
  return (
    <g>
      {ticks.map((t, i) => {
        return (
          <XAxisLine
            key={`tickXline-${Math.random()}`}
            scales={scales}
            splitIndex={splitIndex}
            barData={barData}
            yPosition={scales.y(t)}
            bold={i === 0}
            isZeroLine={t === 0}
          />
        );
      })}
    </g>
  );
}

export function XAxisLine({ scales, splitIndex, yPosition, barData, bold, isZeroLine }) {
  return (
    <>
      {splitIndex !== -1 && barData[splitIndex] ? (
        <>
          <rect
            x={10}
            y={yPosition}
            width={scales.x(barData[splitIndex].interval) - 10}
            height={bold ? 2 : 1}
            opacity={bold || isZeroLine ? 1 : 0.1}
            fill="#0B1435"
          />
          <rect
            x={scales.x(barData[splitIndex].interval) + 20}
            y={yPosition}
            width={scales.frame.width - scales.x(barData[splitIndex].interval) + 10}
            height={bold ? 2 : 1}
            opacity={bold || isZeroLine ? 1 : 0.1}
            fill="#0B1435"
          />
        </>
      ) : (
        <rect
          x={10}
          y={yPosition}
          width={scales.frame.width}
          height={bold ? 2 : 1}
          opacity={bold || isZeroLine ? 1 : 0.1}
          fill="#0B1435"
        />
      )}
    </>
  );
}

export function TickYValues({ scales, barData, splitIndex, formatIntervals, showYears }) {
  return (
    <>
      {barData.map((d, i) => {
        const offset = i >= splitIndex && splitIndex !== -1 ? 20 : 0;
        const intName = d.interval.split(' ')[0];
        return (
          <div
            key={`tickY-${Math.random()}`}
            className={`${styles.BaseChart_tickY} tick-y`}
            style={{
              left: scales.x(d.interval) + (scales.x.bandwidth() - 40) / 2 + offset,
            }}
          >
            {formatIntervals(d.interval).split(' ')[0]}
            {showYears && (
              <>
                <br />
                <span style={{ fontSize: '12px', fontWeight: '500' }}>
                  {intName === 'Q1' || intName === 'Jan' || intName === 'J' || i == 0 ? d.interval.split(' ')[1] : ''}
                </span>
              </>
            )}
          </div>
        );
      })}
    </>
  );
}

export function TickYLines({ scales, barData, yPosition, splitIndex }) {
  return (
    <g>
      {barData.map((d, i) => {
        const offset = splitIndex !== -1 && i >= splitIndex ? 20 : 0;
        return (
          <g key={`tickYline-${Math.random()}`}>
            <rect
              x={scales.x(d.interval) - 1 + offset}
              y={yPosition}
              width={2}
              height={d.interval.split(' ')[0] === 'Q1' ? 10 : 5}
              fill="#0B1435"
            />
            <rect
              x={scales.x(d.interval) - 1 + offset + scales.x.bandwidth()}
              y={yPosition}
              width={2}
              height={d.interval.split(' ')[0] === 'Q4' ? 10 : 5}
              fill="#0B1435"
            />
          </g>
        );
      })}
    </g>
  );
}

function StackedBars(props) {
  const {
    data,
    scales,
    splitIndex,
    colors,
    columns,
    isBicolored,
    futureDataKey,
    setTooltip,
    formatValues,
    formatTooltip,
    tooltipLabels,
    lastBarColor,
    volumeType,
    currentLineIndex,
    withForecastTransparent,
  } = props;

  const commonProps = {
    scales,
    splitIndex,
    colors,
    columns,
    isBicolored,
    futureDataKey,
    setTooltip,
    formatValues,
    formatTooltip,
    tooltipLabels,
    volumeType,
    currentLineIndex,
    withForecastTransparent,
  };

  return (
    <>
      {data.map((d, i) => (
        <g
          key={`${Math.random()}-bars`}
          style={i >= splitIndex && splitIndex !== -1 ? { transform: 'translateX(20px)' } : undefined}
        >
          {columns.map((c, ind) => {
            return (
              <StackedBar
                key={`data-${Math.random()}`}
                data={d}
                order={ind}
                index={i}
                orderType={c}
                {...commonProps}
                specialColor={i === data.length - 1 ? lastBarColor : undefined}
              />
            );
          })}
        </g>
      ))}
    </>
  );
}

const StackedBar = ({
  data,
  order,
  index,
  scales,
  orderType,
  splitIndex,
  colors,
  columns,
  isBicolored,
  futureDataKey,
  setTooltip,
  formatValues,
  formatTooltip,
  tooltipLabels,
  specialColor,
  volumeType,
  currentLineIndex,
  withForecastTransparent,
}) => {
  let activeDataSet = data;

  // Rounds data to equal 100%, which is expressed as 1 here
  if (volumeType === 'percent') {
    const targetForecastData = pick(data, ['Contract-forecast', 'Formula-forecast', 'Spot-forecast', 'NOF-forecast']);
    const roundedForecastData = largestRemainderRound(targetForecastData, 1, 2);

    const targetActualData = pick(data, ['Contract', 'Formula', 'Spot', 'NOF']);
    const roundedActualData = largestRemainderRound(targetActualData, 1, 2);
    activeDataSet = {
      ...activeDataSet,
      ...roundedActualData,
      ...roundedForecastData,
    };
  }

  const bandWidth = scales.x.bandwidth();
  const BAR_GAP = 2;
  const BAR_GROUP_GAP = 10;
  const BAR_WIDTH = Math.max(bandWidth / columns.length - BAR_GAP - BAR_GROUP_GAP / columns.length, 2);
  const offset = BAR_WIDTH + BAR_GAP;

  const getBarColor = value => {
    return value > 0 ? '#3DE5C7' : '#F8617B';
  };

  const TOOLTIP_WIDTH = 250;
  const TOOLTIP_HEIGHT = 85;

  const getTooltipPosition = () => {
    let x = scales.x(data.interval) + offset * order - (TOOLTIP_WIDTH - BAR_WIDTH) / 2;
    let y = data[orderType] >= 0 ? scales.y(data[orderType]) - TOOLTIP_HEIGHT : scales.y(0) - TOOLTIP_HEIGHT;

    if (data[`${orderType}-${futureDataKey}`]) {
      y =
        data[orderType] >= 0
          ? scales.y(data[orderType] + data[`${orderType}-${futureDataKey}`]) - TOOLTIP_HEIGHT
          : scales.y(0) - TOOLTIP_HEIGHT;
    }
    if (splitIndex !== -1 && index >= splitIndex) {
      x += 20;
    }
    if (y < -84) {
      y = -84;
    }
    return { x, y };
  };

  const tooltip = (
    <div
      className={styles.BaseChart_tooltip}
      style={{
        width: TOOLTIP_WIDTH,
        left: getTooltipPosition().x,
        top: getTooltipPosition().y,
      }}
    >
      <div className={styles.BaseChart_tooltipTitle}>{data.interval}</div>
      {data[orderType] !== undefined && data[orderType] !== 0 && (
        <div className={styles.BaseChart_tooltipRow}>
          <div className={styles.BaseChart_tooltipNumber}>
            {formatValues ? formatValues(activeDataSet[orderType]) : activeDataSet[orderType]}
          </div>
          <div className={styles.BaseChart_tooltipLabel}>{tooltipLabels ? tooltipLabels[orderType] : orderType}</div>
        </div>
      )}
      {data[`${orderType}-${futureDataKey}`] !== undefined && data[`${orderType}-${futureDataKey}`] !== 0 && (
        <div className={styles.BaseChart_tooltipRow}>
          <div className={styles.BaseChart_tooltipNumber}>
            {formatValues
              ? formatValues(activeDataSet[`${orderType}-${futureDataKey}`])
              : activeDataSet[`${orderType}-${futureDataKey}`]}
          </div>
          <div className={styles.BaseChart_tooltipLabel}>
            {tooltipLabels ? tooltipLabels[`${orderType}-${futureDataKey}`] : `${orderType} (${futureDataKey})`}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <g key={`${data.interval}-${orderType}`}>
      {/* forecast data bar */}
      {data[`${orderType}-${futureDataKey}`] && (
        <g>
          <rect
            x={scales.x(data.interval) + offset * order + BAR_GROUP_GAP / 2}
            y={scales.y(data[`${orderType}-${futureDataKey}`] - data[orderType])}
            width={BAR_WIDTH}
            height={scales.y(0) - scales.y(data[`${orderType}-${futureDataKey}`] - data[orderType])}
            opacity={0.2}
            fill={specialColor || colors[orderType]}
            onMouseEnter={() => setTooltip(tooltip)}
            onMouseLeave={() => setTooltip(undefined)}
          />
          <line
            x1={scales.x(data.interval) + offset * order + BAR_GROUP_GAP / 2}
            y1={scales.y(data[`${orderType}-${futureDataKey}`] - data[orderType])}
            x2={scales.x(data.interval) + offset * order + BAR_WIDTH + BAR_GROUP_GAP / 2}
            y2={scales.y(data[`${orderType}-${futureDataKey}`] - data[orderType])}
            stroke={specialColor || colors[orderType]}
            strokeWidth={2}
          />
        </g>
      )}
      {/* ordered data */}
      {data[orderType] && (
        <g>
          <rect
            x={scales.x(data.interval) + offset * order + BAR_GROUP_GAP / 2}
            y={data[orderType] > 0 ? scales.y(data[orderType]) : scales.y(0)}
            width={BAR_WIDTH}
            height={
              data[orderType] > 0 ? scales.y(0) - scales.y(data[orderType]) : scales.y(data[orderType]) - scales.y(0)
            }
            fill={isBicolored ? getBarColor(data[orderType]) : specialColor || colors[orderType] || '#4E5984'}
            opacity={index > currentLineIndex - 1 && withForecastTransparent ? 0.3 : 1}
          />
          {withForecastTransparent && (
            <rect
              x={scales.x(data.interval) + offset * order + BAR_GROUP_GAP / 2}
              y={data[orderType] > 0 ? scales.y(data[orderType]) : scales.y(0)}
              width={BAR_WIDTH}
              height={2}
              fill={colors[orderType] || '#4E5984'}
            />
          )}
          <Tooltip
            key={data.interval}
            placement="bottom"
            overlayStyle={{ whiteSpace: 'pre-line' }}
            destroyTooltipOnHide
            title={`${data.interval}${tooltipLabels ? ` ${tooltipLabels[orderType]}` : ''}: ${
              formatTooltip ? formatTooltip(data[orderType]) : data[orderType]
            }`}
          >
            <rect
              x={scales.x(data.interval) + offset * order + BAR_GROUP_GAP / 2}
              width={BAR_WIDTH}
              y={scales.frame.top}
              height={scales.frame.height}
              className={styles.hoverThing}
              fill="rgba(31, 35, 52, 0)"
            />
          </Tooltip>
        </g>
      )}
    </g>
  );
};
