import { createSelector } from '@reduxjs/toolkit';
import { selectActiveMarket, selectMarketsCurrentPeriodDataRaw, selectMarketsDataRaw } from './selectBasicMarketData';

/**
 *  Select data for the sustainability metrics section
 */
export const selectSustainabilityMetrics = createSelector(
  selectMarketsCurrentPeriodDataRaw,
  selectActiveMarket,
  (data, activeMarket) => {
    if (!activeMarket.hasSustainabilityMetrics) return null;
    if (!data) return null;
    return data.sustainabilityMetrics || [];
  }
);

export const selectSustainabilityArticles = createSelector(selectMarketsDataRaw, data => {
  return !data || !data.sustainabilityArticles ? [] : data.sustainabilityArticles;
});
