import Constants from '../Constants';

export const ORGANISATIONS_FETCHED = 'ORGANISATIONS_FETCHED';
export const ORGANISATION_ADDED = 'ORGANISATION_ADDED';
export const ORGANISATION_EDIT = 'ORGANISATION_EDIT';
export const ORGANISATION_REMOVED = 'ORGANISATION_REMOVED';
export const ORGANISATION_ERROR = 'ORGANISATION_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadOrganisations(organisations) {
  return {
    type: ORGANISATIONS_FETCHED,
    payload: organisations,
  };
}

export function addOrganisation(organisation) {
  return {
    type: ORGANISATION_ADDED,
    payload: organisation,
  };
}

export function removeOrganisation(organisation) {
  return {
    type: ORGANISATION_REMOVED,
    payload: organisation,
  };
}

export function editOrganisation(organisation) {
  return {
    type: ORGANISATION_EDIT,
    payload: organisation,
  };
}

export function errorOrganisation(error) {
  return {
    type: ORGANISATION_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchOrganisations(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}organisations`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrganisation))
      .then(json => dispatch(loadOrganisations(json)))
      .catch(error => dispatch(errorOrganisation(error)));
  };
}

export function postOrganisation(organisation, token) {
  return dispatch => {
    const copy = { ...organisation };
    delete copy.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}organisations`, {
      method: 'POST',
      body: JSON.stringify(copy),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrganisation))
      .then(json => {
        if (json.title) {
          dispatch(errorOrganisation(json.title));
        } else {
          dispatch(addOrganisation(json));
        }
      })
      .catch(error => dispatch(errorOrganisation(error)));
  };
}

export function patchOrganisation(organisation, token) {
  return dispatch => {
    const copy = { ...organisation };
    delete copy.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}organisations/${organisation.id}`, {
      method: 'PATCH',
      body: JSON.stringify(copy),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrganisation))
      .then(json => {
        if (json.title) {
          dispatch(errorOrganisation(json.title));
        } else {
          dispatch(editOrganisation(json));
        }
      })
      .catch(error => dispatch(errorOrganisation(error)));
  };
}

export function deleteOrganisation(organisation, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}organisations/${organisation.id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrganisation))
      .then(() => dispatch(removeOrganisation(organisation)))
      .catch(error => dispatch(errorOrganisation(error)));
  };
}
