import { ROLE_ADDED, ROLE_EDIT, ROLE_REMOVED, ROLES_FETCHED } from '../actions/actions_roles';
import { removeObjectFromArray, replaceObjectInArray } from '../helpers';

const roles = (roles = [], action) => {
  const newRoles = JSON.parse(JSON.stringify(roles));

  switch (action.type) {
    case ROLES_FETCHED:
      return action.payload;
    case ROLE_ADDED:
      newRoles.push(action.payload);
      return newRoles;
    case ROLE_EDIT:
      return replaceObjectInArray(newRoles, action.payload);
    case ROLE_REMOVED:
      return removeObjectFromArray(newRoles, action.payload);
    default:
      return roles;
  }
};

export default roles;
