import { END_USERS_FETCHED } from '../actions/actions_end_user';

const end_users = (endUsers = [], action) => {
  switch (action.type) {
    case END_USERS_FETCHED:
      return action.payload;
    default:
      return endUsers;
  }
};

export default end_users;
