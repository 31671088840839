import { range, scaleBand } from 'd3';
import React from 'react';
import { Tooltip } from 'antd';
import { formatPercent } from '../../../../modules/format';
import useDimensions from '../../../../modules/hooks/useDimensions';
import { TickYLines, TickYValues } from '../../treasury/BaseChart/BaseChart';
import styles from './RankingChart.module.css';

const padding = {
  top: 10,
  bottom: 10,
  left: 10,
  right: 30,
};

const useDomains = (data, maxNumber) => {
  return {
    x: data.map(x => x.interval),
    y: range(0, maxNumber),
  };
};

const useScales = (dimensions, domains) => {
  if (!dimensions) return undefined;

  const frame = {
    top: padding.top,
    right: dimensions.width - padding.right,
    bottom: dimensions.height - padding.bottom,
    left: padding.left,
    width: dimensions.width - padding.right - padding.left,
    height: dimensions.height - padding.top - padding.bottom,
  };

  const x = scaleBand().domain(domains.x).range([frame.left, frame.right]).paddingInner(0);

  const y = scaleBand().domain(domains.y).range([frame.bottom, frame.top]).paddingInner(0);
  return {
    frame,
    x,
    y,
  };
};

export const RankingChart = props => {
  const { data, splitIndex, entities } = props;
  const [ref, dimensions] = useDimensions();
  const maxN = Math.max(...data.map(x => (x.rankingBreakdown ? x.rankingBreakdown.length : x.breakdown.length)));
  const domains = useDomains(data, maxN);
  const scales = useScales(dimensions, domains);
  const itemHeight = scales && scales.y.bandwidth();
  const chartProps = {
    scales,
    barData: data,
    splitIndex,
  };

  const [tooltip, setTooltip] = React.useState(undefined);

  const getColumn = xPosition => {
    const emptyCells = [];
    for (let i = 0; i < maxN; i++) {
      const cell = (
        <rect
          key={`${xPosition}-${i}`}
          fill="#F2F2F2"
          opacity={0.5}
          x={xPosition}
          y={scales.y(i) + 1}
          width={scales.x.bandwidth() - 2}
          height={itemHeight - 2}
        />
      );
      emptyCells.push(cell);
    }
    return emptyCells;
  };

  const cells = [];
  data.forEach((d, i) => {
    if (i < splitIndex) {
      cells.push(scales ? getColumn(scales.x(d.interval) + 1) : []);
    }
  });

  const getRankingNumbers = scales => {
    const numbers = [];
    for (let i = 0; i < maxN; i++) {
      const number = (
        <div key={i} className={styles.rank} style={{ top: scales.y(i) }}>
          {maxN - i}
        </div>
      );
      numbers.push(number);
    }
    return numbers;
  };

  return (
    <div>
      <div className={styles.mainChartContainer}>
        <div className={styles.tickXContainer} style={{ minHeight: 300, height: 600 }}>
          {scales && getRankingNumbers(scales)}
        </div>
        <div className={styles.chartContainer} style={{ minHeight: 300, height: 600 }}>
          <div style={{ position: 'absolute' }}>{tooltip}</div>
          <svg className={styles.rankingChartSvg} ref={ref}>
            {scales && (
              <>
                {cells.map(w => w.map(q => q))}
                {data.map((x, i) => {
                  const tempBreakDown = x.rankingBreakdown ? x.rankingBreakdown : x.breakdown;

                  if (i < splitIndex) {
                    return tempBreakDown.map((r, i) => {
                      const entity = entities.find(e => e.name === r.orderType);
                      return (
                        <Tooltip
                          key={`${r.interval}-${i}`}
                          title={`${r.orderType} - ${formatPercent(2)(r.volumeRatio)}`}
                        >
                          <g key={`${r.interval}-${i}`}>
                            <rect
                              fill="#fff"
                              opacity={0}
                              x={scales.x(x.interval) + 1}
                              y={scales.y(maxN - i - 1) + 1}
                              width={scales.x.bandwidth() - 2}
                              height={itemHeight - 2}
                            />
                            <rect
                              fill={entity ? entity.color : '#D2D2D2'}
                              x={scales.x(x.interval) + 1}
                              y={scales.y(maxN - i - 1) + 1}
                              width={r.volumeRatio ? Math.max((scales.x.bandwidth() - 2) * r.volumeRatio, 2) : 0}
                              height={itemHeight - 2}
                            />
                          </g>
                        </Tooltip>
                      );
                    });
                  }
                })}
                <XAxisLine {...chartProps} yPosition={scales.y(0) + itemHeight} />
                <TickYLines {...chartProps} yPosition={scales.y(0) + itemHeight} />
              </>
            )}
          </svg>
        </div>
      </div>
      <div className={styles.rowContainer}>
        {scales && <TickYValues {...chartProps} formatIntervals={v => v} showYears />}
      </div>
    </div>
  );
};

const XAxisLine = props => {
  const { yPosition, scales, barData, splitIndex } = props;
  return (
    <>
      {' '}
      {splitIndex !== -1 && barData[splitIndex] ? (
        <>
          <rect
            x={10}
            y={yPosition}
            width={scales.x(barData[splitIndex].interval) - 10}
            height={2}
            opacity={1}
            fill="#0B1435"
          />
          <rect
            x={scales.x(barData[splitIndex].interval) + 20}
            y={yPosition}
            width={scales.frame.width - scales.x(barData[splitIndex].interval) + 10}
            height={2}
            opacity={1}
            fill="#0B1435"
          />
        </>
      ) : (
        <rect x={10} y={yPosition} width={scales.frame.width} height={2} opacity={1} fill="#0B1435" />
      )}
    </>
  );
};
