import { DATA_MANAGEMENT_FILE_UPLOADED } from '../actions/actions_data_management';

const file_uploaded = (status = { message: '' }, action) => {
  switch (action.type) {
    case DATA_MANAGEMENT_FILE_UPLOADED:
      return action.payload;
    default:
      return status;
  }
};

export default file_uploaded;
