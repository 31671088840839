import Constants from '../Constants';

export const END_USERS_FETCHED = 'END_USERS_FETCHED';
export const END_USERS_ERROR = 'END_USERS_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadEndUsers(endUsers) {
  return {
    type: END_USERS_FETCHED,
    payload: endUsers,
  };
}

export function errorEndUsers(error) {
  return {
    type: END_USERS_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchEndUsers(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}customers`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorEndUsers))
      .then(json => dispatch(loadEndUsers(json)))
      .catch(error => dispatch(errorEndUsers(error)));
  };
}
