import { removeObjectFromArray, replaceObjectInArray } from '../helpers';
import {
  REPLACE_ORDERS_PENDING_COLDSTORE_SCHEDULE,
  REPLACE_ORDER_PENDING_COLDSTORE_SCHEDULE,
  REMOVE_ORDER_PENDING_COLDSTORE_SCHEDULE,
  ORDERS_PENDING_COLDSTORE_SCHEDULE_FETCHED,
} from '../actions/actions_orders_pending_coldstore_schedule';

const orders_pending_coldstore_schedule = (orders_pending_coldstore_schedule = [], action) => {
  const ordersPendingColdstoreScheduleCopy = JSON.parse(JSON.stringify(orders_pending_coldstore_schedule));
  switch (action.type) {
    case ORDERS_PENDING_COLDSTORE_SCHEDULE_FETCHED:
      return action.payload;
    case REPLACE_ORDERS_PENDING_COLDSTORE_SCHEDULE:
    case REPLACE_ORDER_PENDING_COLDSTORE_SCHEDULE:
      return replaceObjectInArray(ordersPendingColdstoreScheduleCopy, action.payload);
    case REMOVE_ORDER_PENDING_COLDSTORE_SCHEDULE:
      return removeObjectFromArray(ordersPendingColdstoreScheduleCopy, action.payload);
    default:
      return orders_pending_coldstore_schedule;
  }
};

export default orders_pending_coldstore_schedule;
