import { connect } from 'react-redux';
import { fetchArticles } from '../../reducers/articles';
import ArticleList from '../../components/articles/ArticleList';

const mapStateToProps = state => {
  return {
    loading: state.articles.loading,
    error: state.articles.error,
    articleList: state.articles.articleList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getArticlesList: () => dispatch(fetchArticles()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleList);
