import Constants from '../Constants';

export const BEEF_INPUT_INVENTORY_ITEMS_FETCHED = 'BEEF_INPUT_INVENTORY_ITEMS_FETCHED';
export const BEEF_INPUT_INVENTORY_ITEMS_ADDED = 'BEEF_INPUT_INVENTORY_ITEMS_ADDED';
export const BEEF_INPUT_INVENTORY_ITEMS_ERROR = 'BEEF_INPUT_INVENTORY_ITEMS_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadBeefInputInventoryItem(inventoryItems) {
  return {
    type: BEEF_INPUT_INVENTORY_ITEMS_FETCHED,
    payload: inventoryItems,
  };
}

export function addBeefInputInventoryItem(inventoryItem) {
  return {
    type: BEEF_INPUT_INVENTORY_ITEMS_ADDED,
    payload: inventoryItem,
  };
}

export function errorBeefInputInventoryItems(error) {
  return {
    type: BEEF_INPUT_INVENTORY_ITEMS_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchBeefInputInventoryItems(grinder, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}inventory/grinders/${grinder.uid}/input_product_inventory_items`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorBeefInputInventoryItems))
      .then(json => dispatch(loadBeefInputInventoryItem(json)))
      .catch(error => dispatch(errorBeefInputInventoryItems(error)));
  };
}

export function postBeefInputInventoryItem(grinder, beefProduct, increment, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(
      `${Constants.URL}inventory/grinders/${grinder.uid}/input_product_inventory_items/${beefProduct.beef_product_id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({ increment_quantity: increment }),
        headers: header,
      }
    )
      .then(response => Constants.handleErrors(response, dispatch, errorBeefInputInventoryItems))
      .then(json => dispatch(addBeefInputInventoryItem(json)))
      .catch(error => dispatch(errorBeefInputInventoryItems(error)));
  };
}
