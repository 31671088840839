import React, { useState, useEffect } from 'react';
import {  Button, Text, VStack, HStack, Wrap, IconButton, Input } from '@chakra-ui/react';
import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import SelectField from '../../basic/SelectField';
import CustomFormControl from '../../core/CustomFormControl';
import { AddIcon, ChevronDownIcon, ChevronRightIcon, SmallCloseIcon } from '@chakra-ui/icons';
import POFormFieldLabel from './edit-form/POFormFieldLabel';
import user from '../../../reducers/reducer_user';

function CL({ isInEditMode,  handleCLChange, line, lineIndex}) {
  const [expandedDetails, setExpandedDetails] = React.useState(false);
  const [type, setType] = useState(line.opClType || 'bin'); // 'bin','carton' or 'batch'
  const [op_testresult, setOpTestresult] = useState(line.opTestresult || null);
  const [thirdpartycl, setThirdpartycl] = useState(line.opAverageThirdPartyClResult || null);
  const [defaultTypes, setDefaultTypes] = useState([
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
    { clvalue: null },
  ]);
  const [extraTypes, setExtraTypes] = useState([]);

  useEffect(() => {
    if (
      line.clResults &&
      line.clResults.length > 0
    ) {
      const defaultTypesTemp = line.clResults.slice(0, 22);
      const extraTypesTemp = line.clResults.slice(22);

      if (defaultTypesTemp.length > 0) {
        setDefaultTypes(
          defaultTypesTemp.map((item) => ({
            id: item.id,
            clvalue: item.clvalue,
          })),
        );
      }

      if (extraTypesTemp.length > 0) {
        setExtraTypes(
          extraTypesTemp.map((item, index) => ({
            id: item.id,
            localId: index + 1,
            clvalue: item.clvalue,
          })),
        );
      }
    }
  }, []);

  const handleCLTypeChange = (value) => {
    setType(value);
    handleCLChange(lineIndex, 'opClType', value);
  }

  const handleOpCLResult = (value) => {
    setOpTestresult(value);
    handleCLChange(lineIndex, 'opTestResult', value);
  }
  const handleThirdPartyCLChange = (value) => {
    setThirdpartycl(value);
    handleCLChange(lineIndex, 'opAverageThirdPartyClResult',   value);
  }

  useEffect(() => {
    handleCLChange(lineIndex, 'clResults', cloneDeep([...defaultTypes, ...extraTypes]));
  }, [defaultTypes, extraTypes]);

  const addExtraType = () => {
    if (extraTypes.length <= 7) {
      setExtraTypes((prev) => [
        ...prev,
        {
          localId: prev.length > 0 ? prev[prev.length - 1].localId + 1 : 1,
          value: null,
        },
      ]);
    }
  };
  
  const handleExtraTypeValue = (value, id) => {
    setExtraTypes((prev) =>
      prev.map((item) => {
        if (item.localId === id) {
          item.clvalue = value ? value: null;
        }
        return item;
      }),
    );
  };  


   const handleDefaultTypeValue = (value, index) => {
    setDefaultTypes((prev) => {
      const newTypes = [...prev];
      newTypes[index].clvalue = value ? value : null;
      return newTypes;
    });
  }; 


  return (
    <VStack align="stretch" spacing="30px">
      <HStack justifyContent="space-between" color="accent.one.default" width="143px">
        <Text as="p" fontWeight="medium" color="inherit">
          CL
        </Text>
        <IconButton
          variant="unstyled"
          minWidth="18px"
          height="fit-content"
          padding="0px"
          fontSize="24px"
          icon={expandedDetails ? <ChevronDownIcon /> : <ChevronRightIcon />}
          onClick={() => setExpandedDetails(!expandedDetails)}
        />
      </HStack>
      {expandedDetails && (
        <>
          <Wrap spacing="10px" paddingX="10px" width="100%">
          <CustomFormControl width="19%">
                <POFormFieldLabel
                  label="Average Tested CL"
                  htmlFor="select-input-ingredient-description"
                  className="international-order-line__input-product-desc"
                  transform="capitalize"
                />
                <Input
                  height="40px"
                  disabled={!isInEditMode}
                  value={op_testresult || ''}
                  onChange={e => handleOpCLResult(e.target.value)}
                />
              </CustomFormControl>  
              <CustomFormControl width="19%">
                <POFormFieldLabel
                  label="3rd Party Tested CL"
                  htmlFor="select-input-ingredient-description"
                  className="international-order-line__input-product-desc"
                  transform="capitalize"
                />
                <Input
                  height="40px"
                  disabled={!isInEditMode}
                  value={thirdpartycl}
                  onChange={e => handleThirdPartyCLChange(e.target.value)}
                />
              </CustomFormControl>                         
          </Wrap>
          <Wrap spacing="56px" paddingX="10px" width="100%">
            <CustomFormControl width="200px">
              <POFormFieldLabel label="Type" htmlFor="cltype" className="international-order-line__label" />
              <SelectField
                isDisabled={!isInEditMode}
                name="cltype"
                defaultValue={type}
                options={[
                  { label: 'Bin', value: 'bin' },
                  { label: 'Carton', value: 'carton' },
                  { label: 'Batch', value: 'batch' },
                ]}
                onChange={e => handleCLTypeChange(e.value)}
                controlStyles={{ height: '38px' }}
              />
            </CustomFormControl>
          </Wrap>
          <Wrap spacing="10px" paddingX="10px" width="100%">
            {defaultTypes.map((item, index) => (
              <CustomFormControl width="19%" key={index}>
                <POFormFieldLabel
                  label={`${type[0].toUpperCase() + type.slice(1)} ${index + 1}`}
                  htmlFor="select-input-ingredient-description"
                  className="international-order-line__input-product-desc"
                  text-transform="capitalize"
                />
                <Input
                  type="number"
                  height="40px"
                  disabled={!isInEditMode}
                  value={item.clvalue || ''}
                  onChange={e => handleDefaultTypeValue(e.target.value, index)}
                />
              </CustomFormControl>  
              ))
            }
            {extraTypes.map((item, index) => (
              <CustomFormControl width="19%" key={index}>
                <POFormFieldLabel
                  label={`${type[0].toUpperCase() + type.slice(1)} ${index + defaultTypes.length + 1}`}
                  htmlFor="select-input-ingredient-description"
                  className="international-order-line__input-product-desc"
                  text-transform="capitalize"
                />
                <Input
                  type="number"
                  height="40px"
                  disabled={!isInEditMode}
                  value={item.clvalue || ''}
                  onChange={e => handleExtraTypeValue(e.target.value, item.localId)}
                />
                <IconButton
                  sx={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    colorScheme:"actionPrimary",
                    position: 'absolute',
                    right: '-17px',
                    top: '17px',
                  }}
                  disabled={!isInEditMode}
                  onClick={() => {
                    setExtraTypes((prev) =>
                      prev.filter((itm) => itm.localId !== item.localId),
                    );
                  }}
                >
                <SmallCloseIcon />
              </IconButton>                
              </CustomFormControl>  
              ))
            }            
            <Button
              disabled={!isInEditMode}
              onClick={addExtraType}
              marginTop="auto!important"
            >
            <AddIcon />
          </Button>
          </Wrap>
        </>
      )}
    </VStack>
  );
}

CL.propTypes = {
  clResults: PropTypes.arrayOf(PropTypes.object),
  isInEditMode: PropTypes.bool.isRequired,
};

export default CL;
