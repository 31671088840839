import { Button, VStack } from '@chakra-ui/react';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { Component } from 'react';
import Constants from '../../../Constants';
import './GrinderSchedule.scss';
import GrinderTable from './GrinderTable';
import { defaultTo } from 'lodash';

class GrinderSchedule extends Component {
  constructor(props) {
    super(props);

    const ordersMap = {};
    this.props.orders.forEach(order => {
      ordersMap[order.id] = {
        delivery_date: order.delivery_date,
        transporter_id: order.transporter_id,
        scheduled_delivery_time: order.scheduled_delivery_time,
        shipment_date: order.shipment_date,
        null_delivery_time: order.null_delivery_time || null,
        id: order.id,
        active_price_bucket: order.active_price_bucket,
      };
    });

    this.state = {
      orders: this.props.orders,
      // ordersMap is for easying representation
      ordersMap,
      selectedOrders: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.orders.length !== prevProps.orders.length) {
      const ordersMap = {};
      const orders = [...this.props.orders];
      orders.forEach(order => {
        const prevStateOrder = defaultTo(
          prevState.orders.find(prevStateOrderItem => prevStateOrderItem.id === order.id),
          {}
        );
        ordersMap[order.id] = {
          delivery_date: order.delivery_date,
          transporter_id: prevStateOrder.transporter_id || order.transporter_id,
          scheduled_delivery_time: prevStateOrder.scheduled_delivery_time || order.scheduled_delivery_time,
          shipment_date: prevStateOrder.shipment_date || order.shipment_date,
          null_delivery_time: prevStateOrder.null_delivery_time || order.null_delivery_time || null,
          id: order.id,
        };
      });
      this.setState({
        ordersMap,
        orders: orders.map(order => ({
          ...order,
          ...ordersMap[order.id],
        })),
      });
    }
  }

  handleValueChange = (id, key, value) => {
    let pValue = value === undefined || value === '' ? null : value;
    if (key && key.includes('_date') && !moment(value).isValid()) {
      pValue = null;
    }

    // update orders to send as body for fetch
    const orders = cloneDeep(this.state.orders);
    orders.find(o => o.id === id)[key] = pValue;

    // update ordersMap for representation
    const ordersMap = cloneDeep(this.state.ordersMap);
    ordersMap[id][key] = pValue;

    if (key === 'null_delivery_time') {
      if (pValue === true) {
        orders.find(o => o.id).scheduled_delivery_time = null;
      }
      ordersMap[id].null_delivery_time = pValue;
    }

    // update selected order fields
    const selectedOrders = cloneDeep(this.state.selectedOrders);
    if (selectedOrders[id]) {
      selectedOrders[id] = ordersMap[id];
    }

    this.setState({ orders, ordersMap, selectedOrders });
  };

  handleOrderSelection = (order, value) => {
    const { selectedOrders, ordersMap } = this.state;
    const selectedOrdersDeepCopy = cloneDeep(selectedOrders);
    if (value) {
      selectedOrdersDeepCopy[order.id] = ordersMap[order.id];
    } else {
      delete selectedOrdersDeepCopy[order.id];
    }
    this.setState({
      selectedOrders: selectedOrdersDeepCopy,
    });
  };

  handleMultipleOrdersSelection = orders => {
    const { selectedOrders, ordersMap } = this.state;
    const selectedOrdersDeepCopy = cloneDeep(selectedOrders);

    if (orders.length === Object.values(selectedOrdersDeepCopy).length) {
      orders.map(order => {
        delete selectedOrdersDeepCopy[order.id];
        return null;
      });
    } else {
      orders.map(order => {
        selectedOrdersDeepCopy[order.id] = ordersMap[order.id];
        return null;
      });
    }
    this.setState({
      selectedOrders: selectedOrdersDeepCopy,
    });
  };

  render() {
    return (
      <VStack align="stretch" key="schedule" spacing="21px" marginBottom="16px">
        <GrinderTable
          orders={this.state.orders}
          patchOrder={this.props.patchOrder}
          transporters={this.props.transporters}
          handleValueChange={this.handleValueChange}
          handleOrderSelection={this.handleOrderSelection}
          handleMultipleOrdersSelection={this.handleMultipleOrdersSelection}
          ordersMap={this.state.ordersMap}
          selectedOrders={this.state.selectedOrders}
          prevColdstoreStatus={Constants.ORDER_STATUSES.COLDSTORE}
          isEditable
          onReleaseEditModal={false}
          token={this.props.token}
          internalPoLabel={this.props.internalPoLabel}
        />
        <Button
          marginLeft="auto !important"
          marginRight="30px !important"
          colorScheme="actionSecondary"
          height="48px"
          width="223px"
          fontSize="16px"
          onClick={() => {
            this.props.scheduleRelease(
              this.state.selectedOrders,
              Constants.ORDER_STATUSES.PENDING_COLDSTORE_RELEASE,
              'release'
            );
          }}
        >
          Schedule Release(s)
        </Button>
      </VStack>
    );
  }
}

export default GrinderSchedule;
