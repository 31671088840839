import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SelectField from '../../../basic/SelectField';
import './RecipientsCard.scss';
import { Text, Input as ChakraInput, Tooltip, Box, FormLabel, HStack, IconButton, VStack } from '@chakra-ui/react';
import { AddIcon, InfoIcon } from '@chakra-ui/icons';
import { ReactComponent as TrashOutlineIconSVG } from '../../../../assets/trash-outline-icon.svg';

class RecipientsCard extends Component {
  state = {
    emailGroupOptions: [],
    selectedEmailGroups: [],
    emails: [],
  };

  componentDidMount() {
    const { emailGroupOptions } = this.state;
    if (emailGroupOptions.length === 0) {
      this.populateEmails();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedEmailGroups, emails } = this.state;
    const { populateEmails, transporterId } = this.props;
    let selectedEmailList = Object.values(selectedEmailGroups).flat();
    // send when emails are updated
    if ((emails && emails !== prevState.emails) || selectedEmailGroups !== prevState.selectedEmailGroups) {
      selectedEmailList = [selectedEmailList, emails].flat();
      populateEmails([...new Set(selectedEmailList)]);
    }

    if (
      transporterId !== prevProps.transporterId ||
      prevProps.notifying_action_groups !== this.props.notifying_action_groups
    ) {
      this.populateEmails();
    }
  }

  render() {
    const { selectedEmailGroups, emailGroupOptions } = this.state;
    const { disabled } = this.props;
    const distinctEmailList = [...new Set(Object.values(selectedEmailGroups).flat())];
    const flatEmails = distinctEmailList.map(email => {
      return {
        label: email,
        value: email,
      };
    });

    // Creates a list of email options from all email option groups
    const emailOptions = [
      ...new Set(
        emailGroupOptions
          .map(group => {
            const options = group.value.map(email => ({
              label: email,
              value: email,
              category: group.label,
            }));
            return options;
          })
          .flat()
      ),
    ];

    return (
      <VStack align="stretch" spacing="23px">
        <Box>
          <HStack spacing="8.5px" marginBottom="7px">
            <FormLabel fontSize="16px" margin="0px" marginLeft="16px">
              Recipients
            </FormLabel>
            <Tooltip hasArrow placement="right" label="Selected Email IDs">
              <Text fontSize="15px" color="neutral.5">
                <InfoIcon />
              </Text>
            </Tooltip>
          </HStack>
          <HStack spacing="24px">
            <Box width="571px">
              <SelectField
                isClearable
                isSearchable
                closeMenuOnSelect
                removeSelected
                title="Email Groups"
                name="select-emailGroup"
                isDisabled={disabled}
                isMulti
                placeholder="Email Groups"
                value={flatEmails}
                multiValueStyles={{
                  background: 'var(--chakra-colors-multiSelectionItem-bgColor)',
                  borderWidth: '1.8px',
                  borderColor: 'var(--chakra-colors-multiSelectionItem-borderColor)',
                  borderRadius: '12px',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  marginRight: '10px',
                  height: '29px',
                }}
                multiValueLabelStyles={{
                  color: 'var(--chakra-colors-multiSelectionItem-color)',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  paddingLeft: 0,
                }}
                multiValueRemoveStyles={{
                  color: 'var(--chakra-colors-multiSelectionItem-color)',
                }}
                onChange={e => this.emailGroupUpdated(e)}
                options={emailOptions}
                containerWidth="571px"
                controlStyles={{
                  borderRadius: '8px',
                  borderColor: 'var(--chakra-colors-neutral-6)',
                  minHeight: '53px',
                }}
                indicatorsContainerStyles={{
                  minHeight: '53px',
                }}
                valueContainerStyles={{
                  minHeight: '53px',
                }}
              />
            </Box>

            <IconButton
              variant="solid"
              colorScheme="actionSecondary"
              borderRadius="32px"
              icon={<AddIcon />}
              isDisabled={disabled}
              onClick={() => this.addEmail()}
            />
          </HStack>
        </Box>
        {this.renderIndividualEmailIds()}
      </VStack>
    );
  }

  renderIndividualEmailIds = () => {
    const renderEmails = this.state.emails.map((email, i) => ({ id: i, email }));
    return (
      <VStack align="stretch" spacing="10px">
        {renderEmails.map((email, i) => (
          <HStack key={email.id} spacing="33px">
            <ChakraInput
              width="571px"
              height="48px"
              borderRadius="8px"
              fontSize="16px"
              borderColor="neutral.6"
              value={email.email}
              onChange={value => this.editEmail(i, value && value.target.value)}
            />
            <IconButton variant="unstyled" icon={<TrashOutlineIconSVG />} onClick={() => this.deleteEmail(i)} />
          </HStack>
        ))}
      </VStack>
    );
  };

  emailGroupUpdated = emailGroupSelected => {
    const selectedEmailGroups = [];
    if (Array.isArray(emailGroupSelected)) {
      emailGroupSelected.forEach(group => {
        selectedEmailGroups[group.category] = emailGroupSelected
          .filter(emailGroup => emailGroup.category === group.category)
          .map(emailGroup => emailGroup.value);
      });
    }
    this.setState({ selectedEmailGroups });
  };

  addEmail = () => {
    this.setState(prevState => ({ emails: [...prevState.emails, ''] }));
  };

  deleteEmail = index => {
    this.setState(prevState => ({
      emails: prevState.emails.filter((email, i) => i !== index),
    }));
  };

  editEmail = (index, value) => {
    const { emails } = this.state;
    const newEmails = [...emails];
    newEmails[index] = value;
    this.setState({ emails: newEmails });
  };

  populateEmails = () => {
    const actionGroups = this.props.actionGroups || [];
    const targetNotificationEmails = this.props.notifying_action_groups.filter(e =>
      actionGroups.includes(e.action_type)
    );
    const emailGroups = {};
    for (const action of targetNotificationEmails) {
      if (!emailGroups[action.action_type]) {
        emailGroups[action.action_type] = [];
      }
      // grinders , packer planet
      if (action.is_internal_user) {
        emailGroups[action.action_type].push(action.email_id);
      } else if (
        action.grinder_uid === this.props.grinderUid &&
        (action.packer_plant_id === this.props.packerPlantId || !action.packer_plant_id)
      ) {
        emailGroups[action.action_type].push(action.email_id);
      } else if (action.packer_plant_id === this.props.packerPlantId && !action.grinder_uid) {
        emailGroups[action.action_type].push(action.email_id);
      }
      // coldstore , transporter
      else if (
        action.coldstore_id === this.props.coldstoreId &&
        (action.transporter_id === this.props.transporterId || !action.transporter_id)
      ) {
        emailGroups[action.action_type].push(action.email_id);
      } else if (action.transporter_id === this.props.transporterId && !action.coldstore_id) {
        emailGroups[action.action_type].push(action.email_id);
      }
    }
    if (this.props.extraEmails) {
      emailGroups.extra_emails = this.props.extraEmails;
    }
    const emailGroupOptions = Object.keys(emailGroups).map(key => ({
      label: key,
      value: emailGroups[key],
    }));
    if (this.state.selectedEmailGroups) {
      this.setState({ emailGroupOptions, selectedEmailGroups: emailGroups }, () => {
        const selectedEmailList = [Object.values(this.state.selectedEmailGroups).flat(), this.state.emails].flat();
        this.props.populateEmails([...new Set(selectedEmailList)]);
      });
    }
  };
}

export default RecipientsCard;

RecipientsCard.propTypes = {
  actionGroups: PropTypes.arrayOf(PropTypes.string),
  coldstoreId: PropTypes.number,
  defaultActionGroups: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  grinderUid: PropTypes.string,
  packerPlantId: PropTypes.string,
  notifying_action_groups: PropTypes.arrayOf(
    PropTypes.shape({
      action_type: PropTypes.string,
      coldstore: PropTypes.shape({ company_name: PropTypes.string, id: PropTypes.number }),
      coldstore_id: PropTypes.number,
      email_id: PropTypes.string,
      grinder: PropTypes.shape({ name: PropTypes.string, uid: PropTypes.string }),
      grinder_uid: PropTypes.string,
      id: PropTypes.number,
      is_internal_user: PropTypes.bool,
      packer_plant_id: PropTypes.string,
      transporter: PropTypes.shape({ name: PropTypes.string, id: PropTypes.number }),
      transporter_id: PropTypes.number,
    })
  ),
  populateEmails: PropTypes.func,
  transporterId: PropTypes.number,
  extraEmails: PropTypes.arrayOf(PropTypes.string),
};
