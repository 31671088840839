const schema = [
  {
    name: 'fdr_rate',
    displayName: 'FDR Rate',
    systemIdField: 'id',
    displayIdField: 'fdr_item_id',
    path: '/fdr_rates',
    tabGroup: { key: 'pricing', label: 'Pricing' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'fdr_item_id',
        displayName: 'FDR Item',
        tooltip: 'Name or identifier of the item in the Fixed Deposit Receipt (FDR)',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'fdr_item',
        required: true,
      },
      {
        name: 'end_user_id',
        displayName: 'End User',
        tooltip: 'Final recipient or user of the FDR item',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'end_user',
        required: true,
      },
      {
        name: 'grinder_uid',
        displayName: 'Grinder',
        tooltip: 'Select the Grinder associated with the FDR item.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'grinder',
        required: true,
      },
      {
        name: 'country_of_origin',
        displayName: 'Country Of Origin',
        tooltip: 'Country where the FDR item was manufactured or produced',
        dataType: 'simple-reference',
        referenceEntityType: 'country',
        code: true,
        required: true,
      },
      {
        name: 'unit_of_measure_id',
        displayName: 'Unit Of Measure',
        tooltip: 'Measurement unit for the FDR item  (e.g., BIN, CTN, KGS, LBS).',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'unit',
        required: true,
      },
      {
        name: 'currency',
        displayName: 'Currency',
        tooltip: 'Currency used for transactions involving the FDR item',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'currency',
        required: true,
      },
      {
        name: 'effective_from',
        displayName: 'Effective From',
        tooltip: 'Date when the details or terms of the FDR item become effective',
        dataType: 'date',
        required: true,
      },
      {
        name: 'rate',
        displayName: 'Rate',
        tooltip: 'Rate applied to the FDR item, such as interest rate or exchange rate',
        dataType: 'number',
        required: true,
      },
      {
        name: 'buy_incoterms',
        displayName: 'Buy Incoterms',
        tooltip: 'International commercial terms specifying the conditions of the sale for the FDR item',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'incoterms',
      },
    ],
  },
];
export default schema;
