import { Card, Col, Layout, Result, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMarketsData } from '../../../slices/markets/marketReducers';
import {
  selectMarketFetchParameters,
  selectMarketsDataRaw,
  selectMarketsError,
  selectMarketsLoading,
  selectMarketsCurrentPeriodDataRaw,
} from '../../../slices/markets/selectors/selectBasicMarketData';
import LoadingOverlay from '../../../shared/LoadingOverlay/LoadingOverlay';

import Details from '../../../components/analyticDashboard/supplyChainDetails/Details/Details';
import SupplyChainFilters from './SupplyChainFilters';
import DetailPageHeading from '../../../shared/DetailPageHeading/DetailPageHeading';
import TabBar from '../../../shared/TabBar';

// This container is for the Market page as a whole and manages data

export default function SupplyChainDetails() {
  const dispatch = useDispatch();
  const error = useSelector(selectMarketsError);
  const loading = useSelector(selectMarketsLoading);
  const data = useSelector(selectMarketsCurrentPeriodDataRaw);

  // Parameters for data fetch
  const fetchParameters = useSelector(selectMarketFetchParameters);

  React.useEffect(() => {
    dispatch(fetchMarketsData(fetchParameters));
  }, [dispatch, fetchParameters]);

  if (error) {
    return (
      <div>
        <Result title="Error" status="error" subTitle={error} />
      </div>
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <TabBar />
      <DetailPageHeading title="Supply Chain Details" />
      <Layout>
        <Layout.Content style={{ padding: 24 }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>{data && <SupplyChainFilters />}</Col>
            <Col span={24}>
              <Card style={{ height: '100%' }}>{data && <Details />}</Card>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
      <LoadingOverlay loading={loading} />
    </div>
  );
}
