import React from 'react';
import { formatSI } from '../../../../modules/format';
import BaseChart from '../BaseChart/BaseChart';
import AnnotationLayer from '../../../../shared/AnnotationLayer/AnnotationLayer';
import { format } from 'date-fns';

const colors = {
  'Fat Beef': '#545B72',
  'Lean Beef': '#AB2424',
};

const columns = ['Fat Beef', 'Lean Beef'];

const breakdownArrayToObject = breakdown =>
  breakdown.reduce(
    (acc, n) => ({
      ...acc,
      [`${n.beefType}`]: n.hedged,
      [`${n.beefType}-exposed`]: n.exposed,
    }),
    {}
  );

export default function ExpectedVolumeChart(props) {
  const {
    expectedVolumeData,
    timeScale,
    addNewAnnotation,
    editAnnotation,
    moduleAnnotations,
    annotationIntervalFormat,
    activeMarket,
  } = props;

  const barData = expectedVolumeData.map(({ interval, rawInterval, volumeBreakdown }) => {
    return {
      interval,
      rawInterval,
      ...breakdownArrayToObject(volumeBreakdown),
    };
  });

  return (
    <BaseChart
      barData={barData}
      columns={columns}
      timeScale={timeScale}
      splitIndex={-1}
      colors={colors}
      formatValues={x => `${formatSI(0)(x)} ${activeMarket === 'USA' ? 'lb' : 'kg'}`}
      ticksNumber={3}
      futureDataKey="exposed"
      noCurrentLine
      AnnotationLayer={p => {
        if (!p.scales) return <div />;
        return (
          <AnnotationLayer
            data={p.barData}
            xScale={p.scales.x}
            splitIndex={p.splitIndex}
            addNewAnnotation={addNewAnnotation}
            editAnnotation={editAnnotation}
            moduleAnnotations={moduleAnnotations}
            getXValue={d => format(d.timestamp, annotationIntervalFormat)}
          />
        );
      }}
    />
  );
}
