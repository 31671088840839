import { createSlice } from '@reduxjs/toolkit';
import qualityClaimsDataRequest from '../../data-fixtures/quality-claims-all';
import { endOfYear, startOfYear, subYears } from 'date-fns';
import { MARKETS } from '../../config/markets';
import { fetchPackerPlants } from '../quality-packer-plants/qualityPackerPlantsReducers';
import { FREQUENCY, DEFAULT_GRINDER_OPTION } from '../quality/qualityConfig';
import { STATUS_OPTIONS, COUNT_OPTIONS, AMOUNT_OPTIONS, TIME_RANGE_OPTIONS } from './qualityDetailsClaimsConfig';
import { fetchGrinders } from '../quality-grinders/qualityGrindersReducers';

const now = new Date();

const qualityClaimsSlice = createSlice({
  name: 'quality-claims',
  initialState: {
    loading: true,
    error: null,
    data: null,

    // Global filters
    marketKey: MARKETS.Australia.key,

    // Filters
    region: MARKETS.Australia.includedCountries[0], // set to equal to marketKey if the selected market doesn't have regions
    status: STATUS_OPTIONS.SUBMITTED,
    count: COUNT_OPTIONS.TOTAL,
    amount: AMOUNT_OPTIONS.TOTAL,
    timeRange: TIME_RANGE_OPTIONS.YOY,
    timePeriod: [startOfYear(subYears(now, 3)), endOfYear(now)],
    frequency: FREQUENCY.MONTHLY,
    groupBy: null,
    filterBy: null,
    groupedByPackerPlant: undefined,
    filteredByPackerPlant: undefined,
    selectedPackerPlantId: undefined,
    shouldBeGroupedByPacker: false,
    grinder: DEFAULT_GRINDER_OPTION.id,
  },
  reducers: {
    /* eslint-disable-next-line no-unused-vars */
    qualityClaimsDataRequestStart(state, _action) {
      state.loading = true;
    },
    qualityClaimsDataRequestSuccess(state, { payload }) {
      state.loading = false;
      state.data = payload;
      state.error = null;
    },
    qualityClaimsDataRequestFailure(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
    setQualityClaimsMarketKey(state, { payload }) {
      state.marketKey = payload;
    },
    setQualityClaimsRegion(state, { payload }) {
      state.region = payload;
    },
    setQualityClaimsStatus(state, { payload }) {
      state.status = payload;
    },
    setQualityClaimsCount(state, { payload }) {
      state.count = payload;
    },
    setQualityClaimsAmount(state, { payload }) {
      state.amount = payload;
    },
    setQualityClaimsTimeRange(state, { payload }) {
      state.timeRange = payload;
    },
    setQualityClaimsTimePeriod(state, { payload }) {
      state.timePeriod = payload;
    },
    setQualityClaimsFrequency(state, { payload }) {
      state.frequency = payload;
    },
    setQualityClaimsGroupBy(state, { payload }) {
      state.groupBy = payload;
    },
    setQualityClaimsFilterBy(state, { payload }) {
      state.filterBy = payload;
    },
    setQualityClaimsFilteredByPackerPlant(state, { payload }) {
      state.filteredByPackerPlant = payload;
    },
    setQualityClaimsGroupedByPackerPlant(state, { payload }) {
      state.groupedByPackerPlant = payload;
    },
    setQualityClaimsSelectedPackerPlantId(state, { payload }) {
      state.selectedPackerPlantId = payload;
    },
    setQualityClaimsShouldBeGroupedByPacker(state, { payload }) {
      state.shouldBeGroupedByPacker = payload;
    },
    setQualityClaimsGrinder(state, { payload }) {
      state.grinder = payload;
    },
  },
});

export const {
  qualityClaimsDataRequestStart,
  qualityClaimsDataRequestSuccess,
  qualityClaimsDataRequestFailure,
  setQualityClaimsMarketKey,
  setQualityClaimsRegion,
  setQualityClaimsStatus,
  setQualityClaimsCount,
  setQualityClaimsAmount,
  setQualityClaimsTimeRange,
  setQualityClaimsTimePeriod,
  setQualityClaimsFrequency,
  setQualityClaimsGroupBy,
  setQualityClaimsFilterBy,
  setQualityClaimsFilteredByPackerPlant,
  setQualityClaimsGroupedByPackerPlant,
  setQualityClaimsSelectedPackerPlantId,
  setQualityClaimsShouldBeGroupedByPacker,
  setQualityClaimsGrinder,
} = qualityClaimsSlice.actions;

// ------
// Thunks

/**
 * Fetches quality details Claims data and manages loading/error state
 * @param {QualityFetchParameters} fetchParameters
 */
export const fetchQualityClaimsData = fetchParameters => {
  return async dispatch => {
    dispatch(qualityClaimsDataRequestStart());

    try {
      const [qualityClaimsData] = await Promise.all(
        [qualityClaimsDataRequest(fetchParameters)],
        dispatch(
          fetchPackerPlants({
            market: fetchParameters.market,
            timePeriod: fetchParameters.timePeriod,
          })
        ),
        dispatch(
          fetchGrinders({
            market: fetchParameters.market,
          })
        )
      );
      dispatch(qualityClaimsDataRequestSuccess(qualityClaimsData));
      return true;
    } catch (error) {
      dispatch(qualityClaimsDataRequestFailure(error.toString()));
      return error;
    }
  };
};

export const updateQualityClaimsTimeRange = timeRange => {
  return async dispatch => {
    dispatch(setQualityClaimsTimeRange(timeRange));
    await dispatch(fetchQualityClaimsData);
  };
};

export const updateQualityClaimsTimePeriod = timePeriod => {
  return async dispatch => {
    dispatch(setQualityClaimsTimePeriod(timePeriod));
    await dispatch(fetchQualityClaimsData);
  };
};

export const updateQualityClaimsFrequency = frequency => {
  return async dispatch => {
    dispatch(setQualityClaimsFrequency(frequency));
    await dispatch(fetchQualityClaimsData);
  };
};

export const updateQualityClaimsRegion = region => {
  return async dispatch => {
    dispatch(setQualityClaimsRegion(region));
    await dispatch(fetchQualityClaimsData);
  };
};

export const updateQualityClaimsGrinder = grinder => {
  return async dispatch => {
    dispatch(setQualityClaimsGrinder(grinder));
    await dispatch(fetchQualityClaimsData);
  };
};

export default qualityClaimsSlice.reducer;
