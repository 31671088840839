import axios from 'axios';

import Constants from '../Constants';

const createAuthHeaders = token => ({
  'Content-Type': 'application/json',
  Authorization: `Token ${token}`,
});

export const SEND_EMAIL_URL = `${Constants.URL}send-email`;

export const ERROR_SEND_EMAIL = 'ERROR_SEND_EMAIL';
export const CONFIRM_EMAIL_SENT = 'CONFIRM_EMAIL_SENT';
export const INITIATE_EMAIL_SEND = 'INITIATE_EMAIL_SEND';
export const RESET_EMAIL_MODAL = 'RESET_EMAIL_MODAL';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function initiateEmailSend() {
  return {
    type: INITIATE_EMAIL_SEND,
  };
}

export function errorSendEmail(error) {
  return {
    type: ERROR_SEND_EMAIL,
    error,
  };
}

export function confirmEmailSent() {
  return {
    type: CONFIRM_EMAIL_SENT,
  };
}

export function resetEmailModal() {
  return {
    type: RESET_EMAIL_MODAL,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function sendEmail(token, email) {
  return async dispatch => {
    const headers = createAuthHeaders(token);
    dispatch(initiateEmailSend());
    try {
      const response = await axios.post(SEND_EMAIL_URL, email, { headers });
      Constants.handleErrors(response, dispatch, errorSendEmail);
      dispatch(confirmEmailSent());
    } catch (e) {
      dispatch(errorSendEmail(e));
    }
  };
}
