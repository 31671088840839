import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Switch, FormControl, FormLabel } from '@chakra-ui/react';

function SwitchComponent(props) {
  const { name, onChange, defaultIsChecked, label, ...rest } = props;
  const { colorScheme } = rest;

  return (
    <FormControl display="flex" justifyContent="center">
      <Field name={name} id={name} type="boolean">
        {({ field: { value }, form: { setFieldValue } }) => (
          <>
            <Switch
              {...{
                id: name,
                isChecked: value,
                onChange: () => {
                  setFieldValue(name, !value);
                  if (onChange) {
                    onChange(name, !value, setFieldValue);
                  }
                },
                defaultIsChecked,
                colorScheme: colorScheme || 'primary',
                marginBottom: '0px',
              }}
            />
            <FormLabel htmlFor={name} mb="0" pl="12px">
              {typeof label === 'function' ? label(value) : label}
            </FormLabel>
          </>
        )}
      </Field>
    </FormControl>
  );
}

SwitchComponent.propTypes = {
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
};

export default SwitchComponent;
