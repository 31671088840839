import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  HStack,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  ButtonGroup,
  IconButton,
} from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import HorizontalNavigationBand from '../../core/HorizontalNavigationBand';
import NCRDetails from './NCRDetails';
import { ReactComponent as DownloadIconSVG } from '../../../assets/download-icon.svg';
import Attachments from './Attachments';
import CommunicationTab from './CommunicationTab';
import { AdvanceButton, BackButton, StatusPriorityBadge, OutlineButton } from '../Components/index';
import HistoryTab from './HistoryTab';
import { Formik } from 'formik';
import { fetchEntitiesData, findEntitiesInState } from '../../../slices/masterData/entityManagerSlice';
import { convertToCamelCase, getOr } from '../../../helpers';
import { connect } from 'react-redux';
import {
  fetchGrinderNCR,
  resetNCRAttachmentUpload,
  resetNCREdit,
  resetReviewNCR,
  updateGrinderNCR,
  uploadAttachments,
  fetchChangeToNCR,
  fetchIncidentsByGroupId, 
  downloadIncidentPDF,
  downloadNCRPDF
} from '../../../actions/action_ncrs';
import { get, isEmpty, merge, set } from 'lodash';
import { prepareAdminUpdateNCRPayload, prepareUpdateNCRPayload } from '../helpers';
import { checkIfIsAdminUser, checkIfIsPackerUser } from '../../../utils';
import { jsPDF } from 'jspdf';
import Constants from '../../../Constants';
import ShowConnectedIncidents from '../Components/ShowConnectedIncidents';

const JSPDF = jsPDF;

const NCRCard = props => {
  const {
    match,
    dispatch,
    ncrs: { hasError, connectIncidents, isUploading, uploadedAttachments, uploadCompleted, currentNCR, isUpdating, hasUpdated, isChangeToNCRSart, hasChangeToNCRFinish, isIncidentByGroupLoading, hasIncidentbyGroupLoading },
    token,
    user,
  } = props;
  const history = useHistory();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [showConnected, setShowConnected] = useState(0)
  const [changeToNCR, setChangeToNCR] = useState(0)

  const formRef = useRef();

  useEffect(() => {
    dispatch(fetchEntitiesData());
    dispatch(fetchEntitiesData(['claim_entity']));

    return () => {
      dispatch(resetReviewNCR());
    };
  }, []);

  useEffect(() => {
    dispatch(resetNCREdit());
    if (match.path.startsWith('/incidents')) {
      dispatch(fetchGrinderNCR(match.params.id, token, Constants.NCR_TYPES.INCIDENT));
    } else {
      dispatch(fetchGrinderNCR(match.params.id, token));
    }
    
  }, [match.params.id, token]);

  const updateFieldValue = (path, value) => {
    if (formRef.current) {
      const { values, touched } = formRef.current;
      let updatedValues = { ...values };
      const touchedFields = { ...touched };
      if (path === 'purchaseOrderLineId') {
        updatedValues = { ...updatedValues, uom: null, amountAffected: null, initialClaimValue: null };
      }
      set(updatedValues, path, value);
      set(touchedFields, path, true);
      formRef.current.setTouched(touchedFields, false);
      formRef.current.setValues(updatedValues, !isEmpty(formRef.current.errors));
    }
  };

  const updateFieldValues = objectData => {
    if (formRef.current) {
      const { values } = formRef.current;
      const updatedValues = { ...values, ...objectData };
      const mergedValues = { ...values };
      merge(mergedValues, updatedValues);
      formRef.current.setValues(mergedValues, !isEmpty(formRef.current.errors));
    }
  };

  useEffect(() => {
    if (!isUpdating && !hasError && hasUpdated) {
      updateFieldValues({
        isEditing: false,
        isEditingMonitoring: false,
        isEditingClosure: false,
      });
    }
  }, [isUpdating, hasError, hasUpdated]);

  useEffect(() => {
    if (!isUploading && !hasError && uploadCompleted) {
      let payload;

      if (checkIfIsAdminUser(user)) {
        payload = prepareAdminUpdateNCRPayload(formRef.current.values, currentNCR, user);
      } else {
        payload = prepareUpdateNCRPayload({ ...formRef.current.values, uploadedAttachments }, currentNCR, user);
      }
      if (payload) payload.submit = true;
      dispatch(updateGrinderNCR(payload, props.token,''));
    }
  }, [isUploading, hasError, uploadedAttachments, uploadCompleted]);

  
  const isNCR = match.path.startsWith('/ncr')
  const editDisabled = isNCR ? !checkIfIsAdminUser(user) && get(currentNCR, 'status', '').toLowerCase() === 'closed' : get(currentNCR, 'status', '').toLowerCase() === 'closed';

  const ncr_tabs = [
    {
      key: 'ncr-details',
      label: 'NCR  Details',
      content: (
        <NCRDetails
          onUpdate={(field, value) => {
            updateFieldValue(field, value);
          }}
          onUpdateMany={data => updateFieldValues(data)}
          isGeneratingPDF={isGeneratingPDF}
          setIsGeneratingPDF={setIsGeneratingPDF}
          {...props}
          editDisabled={editDisabled}
          isNCR={isNCR}
        />
      ),
    },
    {
      key: 'attachments',
      label: 'Attachments',
      content: (
        <Attachments
          onUpdate={(field, value) => {
            updateFieldValue(field, value);
          }}
          onUpdateMany={data => updateFieldValues(data)}
          {...props}
          editDisabled={editDisabled}
          isNCR={isNCR}
        />
      ),
    },
    {
      key: 'communication',
      label: 'Communication',
      content: <CommunicationTab {...props} />,
    },
    {
      key: 'history',
      label: 'History',
      content: <HistoryTab {...props} isNCR={isNCR}/>,
    },
  ];
  
  const incident_tabs = [
    {
      key: 'incident-details',
      label: 'Incident  Details',
      content: (
        <NCRDetails
          onUpdate={(field, value) => {
            updateFieldValue(field, value);
          }}
          onUpdateMany={data => updateFieldValues(data)}
          isGeneratingPDF={isGeneratingPDF}
          setIsGeneratingPDF={setIsGeneratingPDF}
          {...props}
          editDisabled={editDisabled}
          isNCR={isNCR}
          setShowConnected={recornNo => setShowConnected(recornNo)}
          token={token}
        />
      ),
    },
    {
      key: 'attachments',
      label: 'Attachments',
      content: (
        <Attachments
          onUpdate={(field, value) => {
            updateFieldValue(field, value);
          }}
          onUpdateMany={data => updateFieldValues(data)}
          {...props}
          editDisabled={editDisabled}
          isNCR={isNCR}
        />
      ),
    },
    {
      key: 'history',
      label: 'History',
      content: <HistoryTab {...props} isNCR={isNCR}/>,
    },
  ];

  const tabs = isNCR ? ncr_tabs : incident_tabs
  const handleTabsChange = index => {
    if (index <= tabs.length - 1 && index >= 0) setActiveTabIndex(index);
  };

  const status = get(currentNCR, 'status');
  let priority = get(currentNCR, 'claim.priority', 'minor');
  priority = priority ? priority : 'minor';

  const submitForm = () => {
    if (formRef.current) {
      dispatch(resetNCRAttachmentUpload());
      dispatch(uploadAttachments(getOr(formRef.current.values, 'ncrDetails.ncrMedia', []), props.token));
    }
  };

  const backButtonProps = {
    onClick: () => {
      if (activeTabIndex === 0) {
        history.push('/ncr');
        return;
      }
      handleTabsChange(activeTabIndex - 1);
    },
    isDisabled: isUpdating || isGeneratingPDF,
  };

  const handleDownload = async () => {
    if (isNCR) {
      const recordNo = get(currentNCR, 'recordNo', '');
      dispatch(downloadNCRPDF(token,recordNo))
      setIsGeneratingPDF(false)
      // const containerEl = document.getElementById('divToPrint');
      // const containerElAtt = document.getElementById('divToPrintAttachment');
      // const firstChild = containerEl.firstChild;
      // const img = new Image();
      // img.src = '../../../img/fmg_blue.png';
  
      // img.onload = async () => {
      //     const pdf = new jsPDF('p', 'mm', 'a4');
      //     const canvas = document.createElement('canvas');
      //     const ctx = canvas.getContext('2d');
      //     canvas.width = img.width;
      //     canvas.height = img.height;
      //     ctx.drawImage(img, 0, 0);
      //     const logoDataUrl = canvas.toDataURL('image/png');
  
      //     const logoImage = document.createElement('img');
      //     logoImage.src = logoDataUrl;
  
      //     const textNode = document.createElement('div');
      //     textNode.textContent = 'NCR ' + recordNo;
      //     textNode.style.fontWeight = 'bold';
      //     textNode.style.fontSize = '30px';
  
      //     containerEl.insertBefore(textNode.cloneNode(false), firstChild);
      //     await pdf.html(containerEl, {
      //         width: pdf.internal.pageSize.getWidth() - 60,
      //         windowWidth: containerEl.offsetWidth,
      //         margin: [30, 30, 30, 40],
      //         callback: async (doc) => {
      //             // Add logo in header
      //             const totalPages = doc.internal.getNumberOfPages();
      //             for (let i = 1; i <= totalPages; i++) {
      //               pdf.setPage(i);
      //               pdf.addImage(logoImage, 'PNG', 170, 5, 30, 20);
      //               if (i==1){
      //                 pdf.setFont('','bold',6)
      //                 pdf.setFontSize(12);
      //                 pdf.setTextColor(100);
      //                 pdf.text("NCR "+recordNo, 40, 30)
      //               }
      //             }
      //             doc.addPage();
      //             await doc.save(`ncr-details-${recordNo}.pdf`);
      //             setIsGeneratingPDF(false);
      //         },
      //     });
      // };
    } else {
      dispatch(downloadIncidentPDF(token, get(currentNCR, 'recordNo', '')))
      setIsGeneratingPDF(false);
    }
  };

  useEffect(() => {
    if (isGeneratingPDF) handleDownload();
  }, [isGeneratingPDF]);
  return (
    <Box>
      <HorizontalNavigationBand justifyContent="flex-start">
        <BackButton {...backButtonProps} marginLeft="40px" />
      </HorizontalNavigationBand>
      {get(currentNCR, 'recordNo') == match.params.id ? (
        <Box maxW="1232px" marginLeft="auto" marginRight="auto" marginTop="36px">
          <HStack spacing="16px" justifyContent={'space-between'}>
            <HStack spacing="16px">
              <Heading fontSize="18px" minWidth="174px">
                {isNCR ? 'NCR' : 'Incident'} {match.params.id}
              </Heading>
              {status ? <StatusPriorityBadge> {status.replace(/_/g, ' ')}</StatusPriorityBadge> : ''}
              {isNCR ? priority ? <StatusPriorityBadge> {priority.replace(/_/g, ' ')}</StatusPriorityBadge> : '' : ''}
            </HStack>
            {!isNCR && !checkIfIsPackerUser(user) && get(currentNCR, 'status','') === Constants.NCR_STATUES.CLOSED ? 
              (
                <Link to={`/change-ncr/${get(currentNCR, 'recordNo')}/?startEditTabKey=po_details&grinderPo=${get(currentNCR, 'purchaseOrder.ginderPo')}&packerName=${get(currentNCR, 'purchaseOrder.packerName')}`}>
                  <OutlineButton >Change to NCR</OutlineButton>
                </Link>
              ) : 
              ('')
            }
            
          </HStack>
          <Tabs marginTop="19px" index={activeTabIndex} onChange={handleTabsChange}>
            <TabList borderBottomWidth={0}>
              {tabs.map(tab => (
                <Tab
                  key={tab.key}
                  fontWeight={400}
                  fontSize="14ox"
                  _selected={{
                    fontWeight: 700,
                    borderBottomWidth: '0',
                    boxShadow: '1px 4px 1px #F0F0F0',
                  }}
                  _focus={{
                    outline: 'none',
                  }}
                  border="1px solid #F0F0F0"
                  borderRadius="6px 6px 0px 0px"
                  marginRight="4px"
                  paddingX="20px"
                  backgroundColor="card.default"
                  isDisabled={isUpdating}
                >
                  <Heading fontWeight="inherit" fontSize="inherit">
                    {tab.label}
                  </Heading>
                </Tab>
              ))}
            </TabList>
            <Formik
              innerRef={formRef}
              initialValues={{
                ...currentNCR,
                isEditing: false,
                purchaseOrderLineId: get(currentNCR, 'ncrDetails.purchaseOrderLineId'),
                claims: get(currentNCR, 'claim.claimEntities', []).reduce(
                  (acc, curr) => ({
                    ...acc,
                    [`claim${curr.claimEntityTypeId}`]: curr,
                  }),
                  {}
                ),
                isEditingMonitoring: false,
                isEditingClosure: false,
              }}
              onSubmit={() => {
                submitForm();
              }}
            >
              <TabPanels>
                {tabs.map(tab => (
                  <TabPanel key={tab.key} padding="0px">
                    {tab.content}
                  </TabPanel>
                ))}
                <Flex justifyContent="space-between" marginY="35px">
                  <BackButton {...backButtonProps} />
                  <ButtonGroup spacing="22px" colorScheme="actionPrimary">
                    {tabs[activeTabIndex].key === 'ncr-details' ? (
                      <IconButton
                        variant="outline"
                        minW="70px"
                        height="40px"
                        icon={<DownloadIconSVG />}
                        isDisabled={isUpdating || isGeneratingPDF}
                        onClick={(event) => {
                          event.preventDefault();
                          setIsGeneratingPDF(true);
                        }}
                        isLoading={isGeneratingPDF}
                      />
                    ) : (
                      ''
                    )}
                    <AdvanceButton
                      onClick={() => {
                        if (activeTabIndex === tabs.length-1) {
                          history.push('/ncr');
                          return;
                        }
                        handleTabsChange(activeTabIndex + 1);
                      }}
                      paddingX="26px"
                      {...(activeTabIndex === 3 && { rightIcon: null })}
                      isDisabled={isUpdating || isGeneratingPDF}
                    >
                      {activeTabIndex === tabs.length-1 ? 'Back to Claims List' : `Go to ${tabs[activeTabIndex + 1].label}`}
                    </AdvanceButton>
                  </ButtonGroup>
                </Flex>
              </TabPanels>
            </Formik>
          </Tabs>
        </Box>
      ) : (
        'Loading...'
      )}
      {showConnected !== 0 && hasIncidentbyGroupLoading && (
        <ShowConnectedIncidents 
        onCloseModal={() => setShowConnected(0)}
        recordNo={get(currentNCR, 'recordNo', '')}
        connectIncidents={connectIncidents}
        />
      )}
    </Box>
  );
};

NCRCard.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  dispatch: PropTypes.func,
  token: PropTypes.string,
  ncrs: PropTypes.objectOf(PropTypes.any),
  claimEntities: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  user: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = state => {
  const result = {
    ...findEntitiesInState(state, {
      grinders: 'grinder',
      packer_plants: 'packer_plant',
      packers: 'packer',
      input_products: 'input_product',
      cold_stores: 'coldstore',
      transporters: 'transporter',
      claim_entities: 'claim_entity',
      units_of_measure: 'unit',
    }),
    user: state.user,
    token: state.user.token,
    apiRequestError: state.error,
    order_history: state.orders.order_history,
    ncrs: state.ncrs,
  };
  const cameled = convertToCamelCase(result);
  return {
    ...cameled,
    packerOptions: cameled.packers.map(packer => ({
      ...packer,
      value: packer.id,
      label: packer.name,
    })),
    unitsOfMeasureOptions: cameled.unitsOfMeasure.map(unitOfMeasure => ({
      ...unitOfMeasure,
      value: unitOfMeasure.id,
      label: unitOfMeasure.name,
    })),
  };
};

export default connect(mapStateToProps)(NCRCard);
