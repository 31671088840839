import Constants from '../Constants';

export const NOTIFYING_ACTION_GROUPS_BASE_URL = `${Constants.URL}notifying-action-groups`;

export const NOTIFYING_ACTION_GROUPS_FETCHED = 'NOTIFYING_ACTION_GROUPS_FETCHED';
export const NOTIFYING_ACTION_GROUPS_ERROR = 'NOTIFYING_ACTION_GROUPS_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadNotifyingActionGroups(actionGroupsObjects) {
  return {
    type: NOTIFYING_ACTION_GROUPS_FETCHED,
    payload: actionGroupsObjects,
  };
}

export function errorNotifyingActionGroups(error) {
  return {
    type: NOTIFYING_ACTION_GROUPS_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function getNotifyingActionGroups(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${NOTIFYING_ACTION_GROUPS_BASE_URL}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorNotifyingActionGroups))
      .then(json => dispatch(loadNotifyingActionGroups(json)))
      .catch(error => dispatch(errorNotifyingActionGroups(error)));
  };
}
