import { OPTIMIZER_BEEF_FORECAST } from '../actions/actions_optimizer_beef_forecast';

const optimizer_beef_forecast = (forecast = false, action) => {
  switch (action.type) {
    case OPTIMIZER_BEEF_FORECAST:
      return action.payload;
    default:
      return forecast;
  }
};

export default optimizer_beef_forecast;
