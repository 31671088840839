import { Col, Layout, Result, Row, Space } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { startOfDay, subYears } from 'date-fns';

import { fetchMarketsData, fetchMarketsYtdData, setActiveMarket } from '../../../slices/markets/marketReducers';
import {
  selectMarketFetchParameters,
  selectMarketsDataRaw,
  selectMarketsError,
  selectMarketsLoading,
} from '../../../slices/markets/selectors/selectBasicMarketData';
import CostPerformance from './CostPerformance';
import MarketsFilters from './MarketsFilters';
import McMenu from './McMenu';
import Quality from './Quality';
import ScaleForGood from './ScaleForGood';
import SupplyChain from './SupplyChain';
import LoadingOverlay from '../../../shared/LoadingOverlay/LoadingOverlay';
import TabBar from '../../../shared/TabBar';
import { MARKETS } from '../../../config/markets';
import Constants from '../../../Constants';
import IUnleashClassFlagProvider from '../../../components/unleash/UnleashClassFlagProvider';
// This container is for the Market page as a whole and manages data

const Markets = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectMarketsError);
  const loading = useSelector(selectMarketsLoading);
  const data = useSelector(selectMarketsDataRaw);
  const { market: marketParam } = useParams();

  // Parameters for data fetch
  const fetchParameters = useSelector(selectMarketFetchParameters);

  React.useEffect(() => {
    dispatch(fetchMarketsData(fetchParameters));
    dispatch(fetchMarketsYtdData(fetchParameters));
  }, [dispatch, fetchParameters]);

  React.useEffect(() => {
    if (marketParam === MARKETS.Australia.url) {
      dispatch(setActiveMarket(MARKETS.Australia.key));
    }
    if (marketParam === MARKETS.USA.url) {
      dispatch(setActiveMarket(MARKETS.USA.key));
    }
  }, [marketParam, setActiveMarket]);

  if (error && !loading) {
    return (
      <div>
        <Result title="Error" status="error" subTitle={error} />
      </div>
    );
  }

  return (
    <div className="analytic-dashboard-container" style={{ position: 'relative' }}>
      <TabBar />
      <Layout>
        <Layout.Content>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <MarketsFilters />
            </Col>
            <Col span={12}>
              <Space size="middle" direction="vertical">
                {data && <CostPerformance />}
                {data && <McMenu />}
              </Space>
            </Col>
            <Col span={12}>{data && <SupplyChain />}</Col>
            {/* <Col span={24}>
              <Quality />
            </Col>
            <Col span={24}>
            <ScaleForGood />
            </Col> */}
          </Row>
        </Layout.Content>
      </Layout>
      <LoadingOverlay loading={loading} />
    </div>
  );
};

const MarketDashboardUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.MARKET_DASHBOARD}
        show={<Markets {...props} />}
        hide={<h1>Feature Not Enabled</h1>}
      />
    </>
  );
};

export default MarketDashboardUnleashController;
