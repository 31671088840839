import React from 'react';
import { Select, Divider } from 'antd';
import styles from './FilterGrinders.module.css';

export default function FilterGrinders(props) {
  const { value, options, onChange } = props;

  const handleChange = val => {
    const selectedGrinder = options.find(x => x.id === val);
    if (selectedGrinder) {
      onChange(selectedGrinder);
    }
  };

  return (
    <div>
      <Select
        value={value.id}
        style={{ width: '300px', textAlign: 'left' }}
        onChange={handleChange}
        className={styles.QualityFilterGrinders_selectBox}
      >
        {options.map(({ id, name }) => {
          if (id === 'Divider') {
            return (
              <Select.Option key="divider" disabled style={{ overflow: 'none', minHeight: 5 }}>
                <Divider style={{ margin: 0 }} />
              </Select.Option>
            );
          }
          return (
            <Select.Option key={`grinder-${id}`} value={id}>
              {name}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}
