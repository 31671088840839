const schema = [
  {
    name: 'role',
    displayName: 'Role',
    systemIdField: 'id',
    displayIdField: 'name',
    path: '/roles',
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'The name or title of the role',
        dataType: 'shorttext',
        displayIdentifier: true,
        required: true,
      },
      {
        name: 'access_list',
        displayName: 'Access List',
        tooltip: 'List of permissions or access rights associated with the role',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'access_level',
        required: true,
      },
    ],
  },
];
export default schema;
