import get from 'lodash/get';
import {
  renderCard,
  renderGrid,
  renderFieldArray,
  renderLine,
  renderText,
  renderButton,
} from '../../../../lib/formLoader';

import { correctDecimalRoundingBlur } from '../../../../../helpers';

function lookupLineValue(context, name, index) {
  const value = get(context, `values.orderLineList[${index}].${name}`);
  return value;
}

const newOrderLine = {
  product: {},
  quantity: '',
  tag: {},
  orderType: {},
  fobPrice: '',
  packerDeliveredPrice: '',
  nofName: {},
};

const orderLine = {
  listName: 'orderLineList',
  renderComponent: renderFieldArray,
  children: [
    {
      key: index => `line-text-${index}`,
      renderComponent: renderGrid,
      templateColumns: 'repeat(7, 1fr)',
      alignItems: 'center',
      mb: '10px',
      gap: 9,
      children: [
        {
          key: index => `line-${index}-a`,
          renderComponent: renderLine,
          colSpan: 2,
        },
        {
          renderComponent: renderText,
          text: index => `PURCHASE ORDER LINE #${index - 0 + 1}`,
          colSpan: 3,
        },
        {
          key: index => `line-${index}-b`,
          renderComponent: renderLine,
          colSpan: 2,
        },
      ],
    },
    {
      key: index => `po-line-actions-${index}`,
      display: 'flex',
      justifyContent: 'flex-end',
      mb: '-10px',
      children: [
        {
          key: index => `remove-line-button-${index}`,
          renderComponent: renderButton,
          onClick: (e, context, indices) => {
            const arrayHelpers = get(context, 'orderLineListArrayHelpers');
            arrayHelpers.remove(indices[1]);
          },
          icon: 'trash',
          pt: '1px',
          iconColor: '#858C94',
          iconFontSize: '20px',
          type: 'borderless',
          colorScheme: 'actionSecondary',
        },
        {
          key: index => `add-line-button-${index}`,
          renderComponent: renderButton,
          mx: '10px',
          borderRadius: '30px',
          colorScheme: 'actionPrimary',
          onClick: (e, context, indices) => {
            const arrayHelpers = get(context, 'orderLineListArrayHelpers');
            arrayHelpers.push(newOrderLine);
          },
          icon: 'plus',
        },
      ],
    },
    {
      key: index => `line-fields-${index}`,
      renderComponent: renderGrid,
      templateColumns: 'repeat(8, 1fr)',
      gap: 9,
      mb: '30px',
      children: [
        {
          label: 'Product',
          name: index => `orderLineList[${index}].product`,
          fieldType: 'select',
          optionsKey: 'inputProduct',
          colSpan: 2,
        },
        {
          label: 'Weight',
          name: index => `orderLineList[${index}].weight`,
          fieldType: 'number',
          colSpan: 2,
        },
        {
          label: 'Received Weight',
          name: index => `orderLineList[${index}].receivedWeight`,
          fieldType: 'number',
          colSpan: 2,
        },
        {
          label: 'Tag',
          name: index => `orderLineList[${index}].tag`,
          fieldType: 'select',
          optionsKey: 'tag',
          colSpan: 2,
        },
        {
          label: 'Order Type',
          name: index => `orderLineList[${index}].orderType`,
          fieldType: 'select',
          optionsKey: 'orderType',
          colSpan: 2,
        },
        {
          label: isTrue => (isTrue ? 'NOF' : 'Formula'),
          name: index => `orderLineList[${index}].isNofType`,
          fieldType: 'switch',
          isHidden: (props, context, index) => {
            const orderType = lookupLineValue(context, 'orderType', index);
            return !(get(orderType, 'value') === 'NOF');
          },
          height: '57px',
          colSpan: 2,
        },
        {
          label: 'NOF Name',
          name: index => `orderLineList[${index}].nofName`,
          fieldType: 'select',
          optionsKey: 'nofName',
          isHidden: (props, context, index) => {
            const orderType = lookupLineValue(context, 'orderType', index);
            const isNofType = lookupLineValue(context, 'isNofType', index);
            const isNofOrder = get(orderType, 'value') === 'NOF';
            return !isNofOrder || !isNofType;
          },
          colSpan: 2,
        },
        {
          label: 'Formula Name',
          name: index => `orderLineList[${index}].nofFormula`,
          fieldType: 'select',
          optionsKey: 'nofFormula',
          isHidden: (props, context, index) => {
            const orderType = lookupLineValue(context, 'orderType', index);
            const isNofOrder = get(orderType, 'value') === 'NOF';
            const isNofType = lookupLineValue(context, 'isNofType', index);
            return !isNofOrder || isNofType;
          },
          colSpan: 2,
        },
        {
          label: isTrue => (isTrue ? 'Contract' : 'Formula'),
          name: index => `orderLineList[${index}].isContractType`,
          fieldType: 'switch',
          isHidden: (props, context, index) => {
            const orderType = lookupLineValue(context, 'orderType', index);
            return !(get(orderType, 'value') === 'contract');
          },
          height: '57px',
          colSpan: 2,
        },
        {
          label: 'Contract Name',
          name: index => `orderLineList[${index}].contractName`,
          fieldType: 'select',
          optionsKey: 'contract',
          isHidden: (props, context, index) => {
            const orderType = lookupLineValue(context, 'orderType', index);
            const isContractOrder = get(orderType, 'value') === 'contract';
            const isContractType = lookupLineValue(context, 'isContractType', index);
            return !isContractOrder || !isContractType;
          },
          colSpan: 2,
        },
        {
          label: 'Formula Name',
          name: index => `orderLineList[${index}].contractFormula`,
          fieldType: 'select',
          optionsKey: 'contractFormula',
          isHidden: (props, context, index) => {
            const orderType = lookupLineValue(context, 'orderType', index);
            const isContractOrder = get(orderType, 'value') === 'contract';
            const isContractType = lookupLineValue(context, 'isContractType', index);
            return !isContractOrder || isContractType;
          },
          colSpan: 2,
        },
        {
          label: 'Formula Name',
          name: index => `orderLineList[${index}].formulaName`,
          fieldType: 'select',
          optionsKey: 'formula',
          isHidden: (props, context, index) => {
            const orderType = lookupLineValue(context, 'orderType', index);
            const isFormulaOrder = get(orderType, 'value') === 'formula';
            return !isFormulaOrder;
          },
          colSpan: 2,
        },
        {
          label: 'FOB Price',
          name: index => `orderLineList[${index}].fobPrice`,
          fieldType: 'input',
          colSpan: 2,
          onBlur: (name, value, setFieldValue) => {
            // format input to 4 decimal places
            const { result, roundedNumber } = correctDecimalRoundingBlur(value, 4);
            if (isNaN(roundedNumber)) return;
            setFieldValue(name, result);
          },
        },
        {
          label: 'Packer Delivered Price',
          name: index => `orderLineList[${index}].packerDeliveredPrice`,
          fieldType: 'input',
          colSpan: 2,
          onBlur: (name, value, setFieldValue) => {
            // format input to 4 decimal places
            const { result, roundedNumber } = correctDecimalRoundingBlur(value, 4);
            if (isNaN(roundedNumber)) return;
            setFieldValue(name, result);
          },
        },
        {
          label: 'Grinder Delivered Price',
          name: index => `orderLineList[${index}].grinderDeliveredPrice`,
          fieldType: 'input',
          colSpan: 2,
        },
      ],
    },
  ],
};

const orderLinesSection = {
  renderComponent: renderCard,
  headerText: 'Purchase Order Lines',
  key: 'order',
  style: { marginBottom: '400px' },
  mb: '40px',
  children: [orderLine],
};

export { newOrderLine, orderLinesSection };
