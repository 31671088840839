import { connect } from 'react-redux';

import ReportMapComponent from '../../components/dashboard/ReportMap';

const mapStateToProps = state => {
  return {
    user: state.user,
    packer_plants: state.packer_plants,
    map_data: state.map_data,
    configs: state.configs,
    input_products: state.input_products,
    grinders: state.grinders,
    token: state.user.token,
  };
};

const ReportMap = connect(mapStateToProps)(ReportMapComponent);

export default ReportMap;
