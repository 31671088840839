import axios from 'axios';
import get from 'lodash/get';

import Constants from '../Constants';

export const EXCEPTION_ASSIGNMENTS_FETCHED = 'EXCEPTION_ASSIGNMENTS_FETCHED';
export const EXCEPTION_ASSIGNMENT_ADDED = 'EXCEPTION_ASSIGNMENT_ADDED';
export const EXCEPTION_ASSIGNMENT_EDIT = 'EXCEPTION_ASSIGNMENT_EDIT';
export const EXCEPTION_ASSIGNMENT_REMOVED = 'EXCEPTION_ASSIGNMENT_REMOVED';
export const EXCEPTION_ASSIGNMENT_ERROR = 'EXCEPTION_ASSIGNMENT_ERROR';

const BASE_URL = `${Constants.URL}exception_assignments`;

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadExceptionAssignments(exceptionAssignments) {
  return {
    type: EXCEPTION_ASSIGNMENTS_FETCHED,
    payload: { exceptionAssignments },
  };
}

export function addExceptionAssignment(exceptionAssignment) {
  return {
    type: EXCEPTION_ASSIGNMENT_ADDED,
    payload: { exceptionAssignment },
  };
}

export function removeExceptionAssignment(exceptionAssignmentId) {
  return {
    type: EXCEPTION_ASSIGNMENT_REMOVED,
    payload: { exceptionAssignmentId },
  };
}

export function editExceptionAssignment(exceptionAssignment) {
  return {
    type: EXCEPTION_ASSIGNMENT_EDIT,
    payload: { exceptionAssignment },
  };
}

export function errorExceptionAssignment(error) {
  return {
    type: EXCEPTION_ASSIGNMENT_ERROR,
    payload: { error },
  };
}

const createAuthHeaders = token => ({
  'Content-Type': 'application/json',
  Authorization: `Token ${token}`,
});

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchExceptionAssignments(token, taskType) {
  return async dispatch => {
    const headers = createAuthHeaders(token);
    try {
      const queryParams = taskType ? `?exception_types=${taskType}` : '';
      const response = await axios.get(`${BASE_URL}${queryParams}`, { headers });
      Constants.handleErrors(response, dispatch, errorExceptionAssignment);
      const exceptionAssignments = get(response, 'data', []);
      dispatch(loadExceptionAssignments(exceptionAssignments));
    } catch (e) {
      dispatch(errorExceptionAssignment(e));
    }
  };
}

export function postExceptionAssignment(exceptionAssignment, token) {
  const { grinder_uid, user_id, exception_type_name } = exceptionAssignment;
  return async dispatch => {
    const headers = createAuthHeaders(token);
    try {
      const response = await axios.post(
        BASE_URL,
        {
          grinder_uid,
          user_id,
          exception_type_name
        },
        { headers }
      );
      Constants.handleErrors(response, dispatch, errorExceptionAssignment);
    } catch (e) {
      dispatch(errorExceptionAssignment(e));
    }
  };
}

export function patchExceptionAssignment(exceptionAssignment, userId, token) {
  return async dispatch => {
    const headers = createAuthHeaders(token);
    try {
      const response = await axios.patch(`${BASE_URL}/${userId}`, exceptionAssignment, { headers });

      Constants.handleErrors(response, dispatch, errorExceptionAssignment);
      const updatedExceptionAssignment = get(response, 'data', {});
      dispatch(editExceptionAssignment(updatedExceptionAssignment));
    } catch (e) {
      dispatch(errorExceptionAssignment(e));
    }
  };
}

export function deleteExceptionAssignment(userId, grinderIds, token) {
  return async dispatch => {
    const headers = createAuthHeaders(token);
    try {
      // axios strips off 'Content-Type' header https://github.com/axios/axios/issues/1083
      // solution: https://github.com/axios/axios/issues/1083#issuecomment-379509873
      const response = await axios.delete(`${BASE_URL}/${userId}`, {
        headers,
        data: {},
      });

      Constants.handleErrors(response, dispatch, errorExceptionAssignment);
      grinderIds.map(grinderId => dispatch(removeExceptionAssignment(grinderId)));
    } catch (e) {
      dispatch(errorExceptionAssignment(e));
    }
  };
}
