import { selectKPIArchiveData, fetchAllKPIData } from '../../slices/kpi/kpiSlice';
import HeadingWithButton from '../core/HeadingWithButton';
import KPISummaryList from '../core/articles/KPISummaryList';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const KPIArchive = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllKPIData());
  }, []);

  const articles = useSelector(selectKPIArchiveData);
  return (
    <>
      <HeadingWithButton text="KPI's Archive" />
      <KPISummaryList articles={articles} />
    </>
  );
};

export default KPIArchive;
