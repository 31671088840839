import { createSelector } from '@reduxjs/toolkit';
import { selectQualitySortedPackerPlantsOptions } from '../../quality/selectors/selectQualityPackerPlantOptions';
import Constants from '../../../Constants';

export const selectQualityNCRsRawData = state => {
  return state.qualityNCRs.data?.data
    ? [...state.qualityNCRs.data.data].sort((a, b) => new Date(a.date) - new Date(b.date))
    : null;
};

export const selectQualityNCRsData = createSelector(
  selectQualityNCRsRawData,
  selectQualitySortedPackerPlantsOptions,
  (qualityNCRsRaw, sortedPackerPlantsOptions) => {
    const allPackersAndPackerPlantsIds = sortedPackerPlantsOptions.map(x => x.id);

    const qualityNCRs = qualityNCRsRaw?.map(d => {
      let allPackerPlants = [];
      if (allPackersAndPackerPlantsIds.length > 0) {
        allPackersAndPackerPlantsIds.forEach(ap => {
          const byPackerPlantData = d.byPackerPlant?.find(x => x.packerPlantId === ap);
          if (byPackerPlantData) {
            allPackerPlants.push(byPackerPlantData);
          } else {
            allPackerPlants.push({ ...Constants.DEFAULT_PACKER_PLANT_DATA, packerPlantId: ap });
          }
        });
      } else {
        allPackerPlants = d.byPackerPlant;
      }
      return {
        ...d,
        byPackerPlant: allPackerPlants,
      };
    });

    return qualityNCRs;
  }
);
