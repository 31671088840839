import { createSlice } from '@reduxjs/toolkit';
import {
  deleteStrategyPriceRequest,
  fetchStrategyPricesRequest,
  patchStrategyPriceRequest,
  postStrategyPriceRequest,
} from '../../data-fixtures/strategy-prices';
import { removeObjectFromArray, replaceObjectInArray } from '../../helpers';

const initialState = { strategyPrices: [] };

const strategyPricesSlice = createSlice({
  name: 'strategyPrices',
  initialState,
  reducers: {
    setStrategyPrices: (state, { payload }) => payload,
    addStrategyPrice: (state, { payload }) => {
      state.push(payload);
    },
    updateStrategyPrice: (state, { payload }) => {
      replaceObjectInArray(state.strategyPrices, payload);
    },
    deleteStrategyPrice: (state, { payload }) => {
      removeObjectFromArray(state.strategyPrices, payload);
    },
  },
});

export const { setStrategyPrices, addStrategyPrice, updateStrategyPrice, deleteStrategyPrice } =
  strategyPricesSlice.actions;

// Basic selectors
export const selectStrategyPrices = state => {
  return state.strategyPrices.data;
};

// Thunks
export const fetchStrategyPrices = () => {
  return async dispatch => {
    try {
      const strategyPrices = await fetchStrategyPricesRequest();
      dispatch(setStrategyPrices(strategyPrices));
      return strategyPrices;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export const postStrategyPrice = strategyPrice => {
  return async dispatch => {
    try {
      const newStrategyPrice = await postStrategyPriceRequest(strategyPrice);
      dispatch(addStrategyPrice(newStrategyPrice));
      return newStrategyPrice;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export const patchStrategyPrice = strategyPrice => {
  return async dispatch => {
    try {
      const updatedStrategyPrice = await patchStrategyPriceRequest(strategyPrice);
      dispatch(updateStrategyPrice(updatedStrategyPrice));
      return updatedStrategyPrice;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export const handleDeleteStrategyPrice = strategyPrice => {
  return async dispatch => {
    try {
      await deleteStrategyPriceRequest(strategyPrice);
      dispatch(deleteStrategyPrice(strategyPrice));
      return strategyPrice;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export default strategyPricesSlice.reducer;
