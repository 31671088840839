import React, { Component } from 'react';
import { getSupportEmails, getSupportTickets, patchSupportTicket } from '../../actions/actions_support';
import TicketDetails from './TicketDetails';
import TicketList from './TicketList';
import SupportContacts from './SupportContacts';

class SubmittedTickets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ticket: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getSupportEmails(this.props.token));
    this.props.dispatch(getSupportTickets(this.props.token));
  }

  onReadMoreClick = ticket => {
    this.setState({ ticket });
  };

  onCloseClick = ticket => {
    const confirm = window.confirm('Are you sure you want to close ticket?');

    if (confirm) {
      const copy = JSON.parse(JSON.stringify(ticket));
      copy.opened = false;
      this.props.dispatch(patchSupportTicket(copy, this.props.token));

      if (this.state.ticket) {
        this.setState({ ticket: copy });
      }
    }
  };

  onBackToAllTicketsClick = () => {
    this.setState({ ticket: false });
  };

  render() {
    const { ticket } = this.state;
    const groupedTickets = {
      opened: [],
      resolved: [],
    };

    this.props.tickets.forEach(ticket => {
      if (ticket.opened) {
        groupedTickets.opened.push(ticket);
      } else {
        groupedTickets.resolved.push(ticket);
      }
    });

    return (
      <div>
        {ticket ? (
          <TicketDetails
            ticket={ticket}
            onCloseClick={this.onCloseClick}
            onBackToAllTicketsClick={this.onBackToAllTicketsClick}
          />
        ) : (
          <div>
            <SupportContacts {...this.props} />
            <br />
            <TicketList
              onReadMoreClick={this.onReadMoreClick}
              onCloseClick={this.onCloseClick}
              title="Open tickets"
              tickets={groupedTickets.opened}
            />
            <br />
            <TicketList
              onReadMoreClick={this.onReadMoreClick}
              onCloseClick={this.onCloseClick}
              title="Resolved tickets"
              tickets={groupedTickets.resolved}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SubmittedTickets;
