import { MARKETS } from '../../config/markets';

export const STATUS_OPTIONS = {
  SUBMITTED: 'Submitted',
  FINALISED: 'Finalised',
  OVERDUE: 'Overdue',
  REJECTED: 'Rejected',
  SUBMITTED_VS_FINALISED: 'Submitted vs finalised',
  SUBMITTED_VS_REJECTED: 'Submitted vs rejected',
  FINALISED_VS_REJECTED: 'Finalised vs rejected',
};

export const COUNT_OPTIONS = {
  TOTAL: 'Total',
};

export const AMOUNT_OPTIONS = {
  TOTAL: 'Total',
};

export const TIME_RANGE_OPTIONS = {
  HISTORICAL: 'Historical',
  YOY: 'YoY',
};

export const GROUP_BY_OPTIONS = {
  CATEGORY: 'Category',
  PACKER_PLANT: 'Establishment',
  CLAIM_AMOUNT: 'Claim amount',
  DAYS_TO_RESOLVE: 'Days to resolve',
};

export const FILTER_BY_OPTIONS = {
  CATEGORY: 'Category',
  PACKER_PLANT: 'Establishment',
  CLAIM_AMOUNT: 'Claim amount',
  DAYS_TO_RESOLVE: 'Days to resolve',
};

export const PACKER_MODE = {
  TOTAL: 'Packer total',
  BOTTOM_10_TOTAL: 'Bottom 10 total',
  BOTTOM_10: 'Bottom 10',
};

export const INCIDENTS_RATE_UOM = {
  [MARKETS.Australia.key]: {
    INCIDENTS_RATE: 'incidents per 10 tonnes',
  },
  [MARKETS.USA.key]: {
    INCIDENTS_RATE: 'incidents per 1M lbs',
  },
};

export const WEIGHT_OPTIONS = {
  [MARKETS.Australia.key]: 'kgs',
  [MARKETS.USA.key]: 'lbs',
};
