import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import _ from 'underscore';
import { commify } from '../../functions';
import MapFunctionsInit from '../lib/MapFunctions';
import { fetchEntitiesData } from '../../slices/masterData/entityManagerSlice';
import { fetchSensors, fetchTodaysOrders, fetchWeather } from '../../actions/actions_map';

function getRange(prop, array) {
  const minMax = [1000000000, 0];
  array.forEach(function (item) {
    if (item[prop] < minMax[0]) {
      minMax[0] = item[prop];
    }
    if (item[prop] > minMax[1]) {
      minMax[1] = item[prop];
    }
  });
  return minMax;
}

// Load today's orders on load
// Sort into packers and grinders
// On click, they can see that packer's orders leaving, and the grinder's orders arriving

class OpsMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: false,
      initialLoad: true,
      loading: true,
      weatherLoaded: false,
      sensorsLoaded: false,
      mapLoaded: false,
      currentDataTitle: false,
      currentType: false,
      currentData: false,
      allWeather: [],
      lines: [],
      ordersLoaded: false,
      grindersToPackersAndPackersToGrinders: [],
      wallMode: false,
      height: window.innerHeight,
      heightInterval: false,
    };
  }

  componentDidMount() {
    const that = this;

    const heightInterval = setInterval(function () {
      if (that.state.wallMode) {
        if (that.state.height !== window.innerHeight) {
          that.setState({ height: window.innerHeight });
        }
      }
    }, 500);
    this.setState({ heightInterval });

    this.props.dispatch(fetchEntitiesData(['grinder', 'packer_plant']));

    mapboxgl.accessToken = 'pk.eyJ1IjoiY2Zjb2pvbm8iLCJhIjoiY2toeWNhOXh3MGY0cDJ4b2RhMG54M3ExaSJ9.hlvEPe6nwdJ1a3z1dGB5bA';
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/light-v9',
      center: [-105.7129, 38.0902],
      zoom: 3.3,
      pitch: 50,
      rotate: 60,
    });

    this.setState({ map });

    map.on(
      'load',
      function () {
        this.UNSAFE_componentWillReceiveProps(this.props);
        this.props.dispatch(
          fetchTodaysOrders(
            this.props.grinders,
            this.props.packer_plants,
            moment().format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
            this.props.token
          )
        );
        // Should be replaced by today's date later
        this.props.dispatch(
          fetchSensors(moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), this.props.token)
        );
      }.bind(this)
    );
  }

  componentWillUnmount() {
    clearInterval(this.state.heightInterval);
    this.setState({ heightInterval: false });
  }

  UNSAFE_componentWillReceiveProps(nextProps, forceUpdate) {
    const { map, initialLoad, weatherLoaded } = this.state;
    const MapFunctions = MapFunctionsInit(map);

    if (nextProps.map_data.no_data && weatherLoaded) {
      this.setState({
        no_data: true,
        loading: false,
        summaryStats: [],
        selectedData: false,
        ordersLoaded: true,
      });
    } else {
      this.setState({ no_data: false });
    }

    if (nextProps.map_data.sensors.length && !this.state.sensorsLoaded) {
      const sensorsSortedByPO = [];
      nextProps.map_data.sensors.forEach(function (sensor) {
        if (sensor.purchase_order_id && sensor.latitude && sensor.longitude) {
          let found = false;
          sensorsSortedByPO.forEach(function (thisSensor) {
            if (thisSensor.purchase_order_id === sensor.purchase_order_id) {
              thisSensor.locations.push(sensor);
              found = true;
            }
          });
          if (!found) {
            sensorsSortedByPO.push({
              purchase_order_id: sensor.purchase_order_id,
              locations: [sensor],
            });
          }
        }
      });
      sensorsSortedByPO.forEach(function (sensors) {
        sensors.locations.reverse();
        sensors.locations.forEach(function (locationData, index) {
          const featureCollection = {
            type: 'FeatureCollection',
            features: [
              {
                properties: locationData,
                geometry: {
                  type: 'Point',
                  coordinates: [locationData.longitude, locationData.latitude],
                },
              },
            ],
          };
          map.addSource(`truck-${locationData.purchase_order_id}-index-${index}`, {
            type: 'geojson',
            data: featureCollection,
          });
          if (index === 0) {
            map.addLayer({
              id: `truck-${locationData.purchase_order_id}-index-expanding-${index}`,
              type: 'circle',
              source: `truck-${locationData.purchase_order_id}-index-${index}`,
              paint: {
                'circle-radius': 4,
                'circle-opacity': 1,
                'circle-color': '#FFD966',
              },
            });
          }
          if (index < 3) {
            map.addLayer({
              id: `truck-${locationData.purchase_order_id}-index-${index}`,
              type: 'circle',
              source: `truck-${locationData.purchase_order_id}-index-${index}`,
              paint: {
                'circle-radius': 3 - index / 2,
                'circle-opacity': 1 - index / 3,
                'circle-color': '#099E64',
              },
            });
          }
        });
      });

      this.setState({ sensorsLoaded: true });
    }

    if (
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.todays_orders, nextProps.map_data.todays_orders) ||
        !_.isEqual(this.props.map_data.returned, nextProps.map_data.returned))
    ) {
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });

      const ordersByRelationship = [];
      nextProps.map_data.todays_orders.forEach(function (order) {
        let found = false;
        ordersByRelationship.forEach(function (thisOrder) {
          if (thisOrder.packer_plant_id === order.packer_plant_id && thisOrder.grinder_uid === order.grinder_uid) {
            thisOrder.order_number += 1;
            thisOrder.order_quantity += order.buy_quantity;
            found = true;
          }
        });
        if (!found) {
          ordersByRelationship.push({
            packer_plant_id: order.packer_plant_id,
            grinder_uid: order.grinder_uid,
            order_quantity: order.buy_quantity,
            order_number: 1,
          });
        }
      });

      const orderRelationshipQuantityRange = getRange('order_quantity', ordersByRelationship);
      const newLines = MapFunctions.addLines(
        nextProps.grinders,
        nextProps.packer_plants,
        ordersByRelationship,
        ['order_quantity', 'order_number'],
        ['order_quantity', 'order_number'],
        '' + 'order_quantity',
        orderRelationshipQuantityRange,
        false
      );
      const grindersToPackersAndPackersToGrinders = MapFunctions.associateGrindersAndPackersWithLines(
        nextProps.grinders,
        nextProps.packer_plants
      );
      this.setState({
        lines: newLines,
        grindersToPackersAndPackersToGrinders,
        ordersLoaded: true,
      });

      map.on(
        'click',
        function (e) {
          const features = map.queryRenderedFeatures(e.point);
          if (features.length) {
            // Grinder popups
            let foundThing = false;
            if (features[0].layer.id.indexOf('grinders') > -1) {
              if (features[0].layer.id.indexOf('line') === -1) {
                MapFunctions.showLines(this.state.grindersToPackersAndPackersToGrinders, features[0]);
              }
              const grinderName = features[0].layer.id.split('grinders-')[1];
              const theseOrders = [];
              nextProps.map_data.todays_orders.forEach(function (order) {
                nextProps.grinders.forEach(function (grinder) {
                  if (grinder.uid === order.grinder_uid && grinder.name === grinderName) {
                    theseOrders.push(order);
                  }
                });
              });
              foundThing = true;
              this.setState({
                currentType: 'grinder',
                currentDataTitle: grinderName,
                currentData: theseOrders,
              });
            }
            // Packers popup
            if (features[0].layer.id.indexOf('packers') > -1) {
              if (features[0].layer.id.indexOf('line') === -1) {
                MapFunctions.showLines(this.state.grindersToPackersAndPackersToGrinders, features[0]);
              }
              const packerName = features[0].layer.id.split('packers-')[1];
              const theseOrders = [];
              nextProps.map_data.todays_orders.forEach(function (order) {
                nextProps.packer_plants.forEach(function (packer_plant) {
                  if (packer_plant.id === order.packer_plant_id && packer_plant.name === packerName) {
                    theseOrders.push(order);
                  }
                });
              });
              foundThing = true;
              this.setState({
                currentType: 'packer',
                currentDataTitle: packerName,
                currentData: theseOrders,
              });
            }
            if (!foundThing) {
              this.setState({
                currentType: false,
                currentDataTitle: false,
                currentData: false,
              });
            }
          } else {
            this.setState({
              currentType: false,
              currentDataTitle: false,
              currentData: false,
            });
          }
        }.bind(this)
      );

      this.setState({ ordersLoaded: true });
    }

    // First load
    if (initialLoad && map && map.isStyleLoaded() && nextProps.packer_plants.length && nextProps.grinders.length) {
      const filterSetup = MapFunctions.dataInitialization(nextProps.grinders, nextProps.packer_plants, mapboxgl);

      nextProps.dispatch(fetchWeather(nextProps.token));
      this.setState({ initialLoad: false, loading: true });
    }

    if (!this.props.map_data.weather && nextProps.map_data.weather && !weatherLoaded) {
      nextProps.map_data.weather.features.forEach(function (weatherAlert) {
        if (weatherAlert.geometry) {
          const thisGeoJSON = {
            type: 'FeatureCollection',
            features: [weatherAlert],
          };
          map.addSource(`weather-${weatherAlert.properties.id}`, {
            type: 'geojson',
            data: thisGeoJSON,
          });
          map.addLayer(
            {
              id: `weather-${weatherAlert.properties.id}`,
              type: 'fill',
              source: `weather-${weatherAlert.properties.id}`,
              paint: {
                'fill-color': 'blue',
                'fill-opacity': 0.3,
                'fill-outline-color': 'blue',
              },
              layout: {
                visibility: 'visible',
              },
            },
            `packers-${nextProps.packer_plants[0].name}`
          );

          map.on('click', `weather-${weatherAlert.properties.id}`, function (e) {
            const firstFeature = map.queryRenderedFeatures(e.point)[0];
            if (firstFeature.layer.id.indexOf('grinders-') === -1 && firstFeature.layer.id.indexOf('packers-') === -1) {
              const popup = new mapboxgl.Popup({ className: 'weather-popup' })
                .setLngLat(e.lngLat)
                .setHTML(
                  `<h5 style="width:200px;">${firstFeature.properties.event}</h5>` +
                    `<p>${firstFeature.properties.headline}</p>` +
                    `<ul>` +
                    `<li>Certainty: ${firstFeature.properties.certainty}</li>` +
                    `</ul>`
                )
                .addTo(map);
            }
          });
        }
      });
      this.setState({ weatherLoaded: true, loading: false });
    }
  }

  wallModeToggle() {
    const { map, wallMode } = this.state;
    const MapFunctions = MapFunctionsInit(map);
    let thisInterval = false;

    if (wallMode) {
      document.getElementById('sidebar-menu').style.display = 'block';
      document.getElementById('main-header').style.display = 'table';
      document.getElementById('main-body').style.width = '96%';
      if (wallMode.length) {
        wallMode.forEach(function (interval) {
          clearInterval(interval);
        });
      }
    } else {
      document.getElementById('sidebar-menu').style.display = 'none';
      document.getElementById('main-header').style.display = 'none';
      document.getElementById('main-body').style.width = '100%';

      const that = this;
      let count = 0;
      thisInterval = [];
      if (this.props.map_data.todays_orders.length) {
        function showGrinder() {
          const grinderName = that.props.grinders[count].name;
          const theseOrders = [];
          that.props.map_data.todays_orders.forEach(function (order) {
            that.props.grinders.forEach(function (grinder) {
              if (grinder.uid === order.grinder_uid && grinder.name === grinderName) {
                theseOrders.push(order);
              }
            });
          });
          const falseFeature = { layer: { id: `grinders-${grinderName}` } };
          MapFunctions.showLines(that.state.grindersToPackersAndPackersToGrinders, falseFeature);
          that.setState({
            currentType: 'grinder',
            currentDataTitle: grinderName,
            currentData: theseOrders,
          });
          if (that.props.grinders[count + 1]) {
            count += 1;
          } else {
            count = 0;
          }
        }

        showGrinder();
        const firstInterval = setInterval(function () {
          showGrinder();
          that.setState({ height: window.innerHeight });
        }, 1000 * 30); // 30 seconds

        const secondInterval = setInterval(function () {
          that.props.dispatch(
            fetchTodaysOrders(
              that.props.grinders,
              that.props.packer_plants,
              moment().format('YYYY-MM-DD'),
              moment().format('YYYY-MM-DD'),
              that.props.token
            )
          );
          // Should be replaced by today's date later
          that.props.dispatch(
            fetchSensors(moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), that.props.token)
          );
        }, 1000 * 600); // 600 seconds, or 10 minutes (20 loops of 30 seconds)

        thisInterval = [firstInterval, secondInterval];
      }
    }

    this.setState({ wallMode: thisInterval });

    setTimeout(function () {
      map.resize();
    }, 500);
  }

  render() {
    const { ordersLoaded } = this.state;
    return (
      <div>
        {!this.state.loading ? (
          <div className="map-overlay" style={this.state.wallMode ? { marginTop: '10px' } : {}}>
            <div className="ops-overlay">
              <div className={this.state.wallMode ? 'overlay-collapse wall-collapse' : 'overlay-collapse'}>
                {this.state.currentData ? (
                  <div>
                    <h5>{this.state.currentDataTitle}</h5>
                    {this.state.currentData.length ? (
                      <div>
                        <p style={{ marginBottom: '0px' }}>
                          {this.state.currentData.length} order{this.state.currentData.length > 1 ? 's' : ''}{' '}
                          {this.state.currentType === 'packer' ? 'dispatched' : 'arriving'} today.
                        </p>
                        <div style={{ marginBottom: '10px' }}>
                          <div
                            style={{
                              width: '10px',
                              height: '10px',
                              background: 'rgba(104, 177, 47,0.3)',
                              display: 'inline-block',
                            }}
                          />
                          Order OK <br />
                          <div
                            style={{
                              width: '10px',
                              height: '10px',
                              background: 'rgba(236, 134, 79,0.3)',
                              display: 'inline-block',
                            }}
                          />
                          Missing COA
                        </div>
                        <ul className="list-group">
                          {this.state.currentData.map((order, i) => {
                            const hasMissingCOA = !order.documents.some(doc => doc.doc_type === 'COA');
                            const missingCOA = hasMissingCOA
                              ? { background: 'rgba(236, 134, 79,0.3)' }
                              : { background: 'rgba(104, 177, 47,0.3)' };

                            return (
                              <li key={`order-${i}`} className="list-group-item" style={missingCOA}>
                                {hasMissingCOA ? (
                                  <div>
                                    PO #: {order.grinder_po_number}, {commify(order.sell_quantity)}{' '}
                                    {order.sell_unit_of_measure.name}
                                  </div>
                                ) : (
                                  <div>
                                    PO #: {order.grinder_po_number}, {commify(order.sell_quantity)}{' '}
                                    {order.sell_unit_of_measure.name}
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : (
                      <p>No orders {this.state.currentType === 'packer' ? 'dispatched' : 'arriving'} today.</p>
                    )}
                  </div>
                ) : (
                  false
                )}
                {!ordersLoaded ? (
                  <div>
                    <img className="map-loader" src="img/loader.gif" />
                    <p>Orders loading...</p>
                  </div>
                ) : (
                  false
                )}
                {this.props.map_data.no_data ? (
                  <div>
                    <p>No data found for {moment().format('YYYY-MM-DD')}.</p>
                  </div>
                ) : (
                  false
                )}
                {ordersLoaded && !this.props.map_data.no_data && !this.state.currentData ? (
                  <div>
                    <p>Click a packer_plant or a grinder to see details about orders today.</p>
                  </div>
                ) : (
                  false
                )}
              </div>
            </div>
          </div>
        ) : (
          false
        )}
        {!this.state.loading ? false : <img className="map-loader" src="img/loader.gif" />}
        <button className="btn btn-primary wall-mode" onClick={this.wallModeToggle.bind(this)}>
          {this.state.wallMode ? 'Boab' : 'Wall Mode'}
        </button>
        <div
          id="map"
          style={{
            width: '100%',
            height: this.state.wallMode ? this.state.height : this.state.height - 60,
            top: this.state.wallMode ? 0 : 60,
          }}
        />
      </div>
    );
  }
}

export default OpsMap;
