/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { endOfWeek, startOfDay, startOfWeek, subYears } from 'date-fns';
import { MARKETS } from '../../config/markets';
import { VOLUME_GROUPINGS } from '../../config/marketsVolumeGrouping';
import fetchMarketsDataFixture from '../../data-fixtures/markets-all';
import { CURRENCY, DATE_MODE, MARKET_FOCUS, PROTEIN, SUPPLY_CHAIN_VIEW_MODE } from './marketsConfig';

/**
 * Get the initial market from a URL param.
 */
const getInitialMarket = () => {
  // Quick and dirty as we arent expecting any other URL params
  const split = window.location.search
    .replace('?', '')
    .split('&')
    .map(s => s.split('='));

  const marketParam = split.find(s => s[0] === 'activeMarket');

  if (marketParam) return marketParam[1];

  return 'Australia';
};

/**
 * Return the initial time period for the app
 */
const getInitialTimePeriod = () => {
  // const market = getInitialMarket();

  // switch (market) {
  //   case 'USA': {
  //     const endDate = endOfDay(new Date(1595685600000));
  //     return [startOfDay(subDays(endDate, 6)), endDate].map(d => d.getTime());
  //   }
  //   case 'Australia':
  //   default: {
  //     const endDate = endOfDay(new Date(1599314400000));
  //     return [startOfDay(subDays(endDate, 6)), endDate].map(d => d.getTime());
  //   }
  // }
  // Default to this week.
  const thisWeekStart = startOfWeek(new Date()).getTime();
  const thisWeekEnd = endOfWeek(new Date()).getTime();
  return [thisWeekStart, thisWeekEnd];
};

/**
 * Return the initial volume options for the app
 */
const getInitialVolumeOptions = () => {
  // Take all default values from static config.
  const values = {};
  Object.values(VOLUME_GROUPINGS).forEach(grouping => {
    if (grouping.options) {
      grouping.options.forEach(opt => {
        values[opt.key] = opt.default;
      });
    }
  });

  return values;
};

// ------------------------------------
// MARKETS SLICE
// ------------------------------------

const marketsSlice = createSlice({
  name: 'markets',
  initialState: {
    // TODO: initial load needs to set these ones up.
    availableMarkets: ['USA', 'Australia'],
    activeMarket: getInitialMarket(),
    activeVolumeGrouping: MARKETS[getInitialMarket()].volumeGroupings[0].key,
    volumeOptions: getInitialVolumeOptions(),
    //
    supplyChainViewMode: SUPPLY_CHAIN_VIEW_MODE.GRINDERS,
    supplyChainDateMode: DATE_MODE.ORDER,
    supplyChainHoveredLocation: null,
    //
    activeFocus: MARKET_FOCUS.DOMESTIC,
    activeProtein: PROTEIN.BEEF,
    activeCurrency: CURRENCY.USD,
    timePeriod: getInitialTimePeriod(),
    loading: true,
    error: null,
    data: null,
    ytdLoading: true,
    ytdError: null,
    ytdData: null,
  },
  reducers: {
    marketsDataRequest(state) {
      state.loading = true;
      state.error = null;
    },
    marketsDataRequestSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    marketsDataRequestFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    marketsYtdDataRequest(state) {
      state.ytdLoading = true;
      state.ytdError = null;
    },
    marketsYtdDataRequestSuccess(state, action) {
      state.ytdLoading = false;
      state.ytdData = action.payload;
      state.ytdError = null;
    },
    marketsYtdDataRequestFailure(state, action) {
      state.ytdLoading = false;
      state.ytdError = action.payload;
    },
    updateActiveMarket(state, action) {
      if (state.availableMarkets.includes(action.payload)) {
        state.activeMarket = action.payload;
      }
    },
    updateActiveVolumeGrouping(state, action) {
      state.activeVolumeGrouping = action.payload;
    },
    updateActiveFocus(state, action) {
      state.activeFocus = action.payload;
    },
    updateActiveProtein(state, action) {
      state.activeProtein = action.payload;
    },
    updateActiveCurrency(state, action) {
      state.activeCurrency = action.payload;
    },
    updateTimePeriod(state, action) {
      state.timePeriod = action.payload;
    },
    updateSupplyChainDateMode(state, action) {
      state.supplyChainDateMode = action.payload;
    },
    updateSupplyChainViewMode(state, action) {
      state.supplyChainViewMode = action.payload;
    },
    updateSupplyChainHoveredLocation(state, action) {
      state.supplyChainHoveredLocation = action.payload;
    },
    updateVolumeOptions(state, action) {
      state.volumeOptions = action.payload;
    },
  },
});

export const {
  marketsDataRequest,
  marketsDataRequestSuccess,
  marketsDataRequestFailure,
  marketsYtdDataRequest,
  marketsYtdDataRequestSuccess,
  marketsYtdDataRequestFailure,
  updateActiveVolumeGrouping,
  updateActiveFocus,
  updateActiveProtein,
  updateActiveCurrency,
  updateTimePeriod,
  updateSupplyChainDateMode,
  updateSupplyChainViewMode,
  updateSupplyChainHoveredLocation,
  updateVolumeOptions,
} = marketsSlice.actions;

// ------
// Thunks

/**
 * Thunk to fetch and update data in place.
 * @param {MarketFetchParameters} fetchParameters
 */
export const fetchMarketsData = fetchParameters => {
  return async dispatch => {
    dispatch(marketsDataRequest());
    try {
      const marketsData = await fetchMarketsDataFixture(fetchParameters);
      dispatch(marketsDataRequestSuccess(marketsData));
      return marketsData;
    } catch (error) {
      dispatch(marketsDataRequestFailure(error.toString()));
      return error;
    }
  };
};

/**
 * Thunk to fetch and update data in place for full year.
 * @param {MarketFetchParameters} fetchParameters
 */
export const fetchMarketsYtdData = fetchParameters => {
  const ytdFetchParameters = {
    ...fetchParameters,
    timePeriod: [startOfDay(subYears(new Date(), 1)).getTime(), startOfDay(new Date()).getTime()],
  };
  return async dispatch => {
    dispatch(marketsYtdDataRequest());
    try {
      const marketsData = await fetchMarketsDataFixture(ytdFetchParameters);
      dispatch(marketsYtdDataRequestSuccess(marketsData));
      return marketsData;
    } catch (error) {
      dispatch(marketsYtdDataRequestFailure(error.toString()));
      return error;
    }
  };
};

/**
 * Thunk to fetch and update data in place.
 * @param {MarketFetchParameters} fetchParameters
 */
// TODO: ideally we make a new action to fetch data and set the market/grouping in one fell swoop.
// This will help us avoid race conditions when fetching and having set data before.
export const setActiveMarket = newMarketKey => {
  return async dispatch => {
    dispatch(marketsSlice.actions.updateActiveMarket(newMarketKey));
    dispatch(marketsSlice.actions.updateActiveVolumeGrouping(MARKETS[newMarketKey].volumeGroupings[0].key));
  };
};

export default marketsSlice.reducer;
