import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterDateType from '../../../components/analyticDashboard/supplyChainDetails/FilterDateType/FilterDateType';
import FilterFocus from '../../../components/analyticDashboard/supplyChainDetails/FilterFocus/FilterFocus';
import FilterGrinder from '../../../components/analyticDashboard/supplyChainDetails/FilterGrinder/FilterGrinder';
import FilterPackerPlant from '../../../components/analyticDashboard/supplyChainDetails/FilterPackerPlant/FilterPackerPlant';
import FilterTimePeriod from '../../../components/analyticDashboard/supplyChainDetails/FilterTimePeriod/FilterTimePeriod';
import { updateActiveGrinder, updateActivePackerPlant } from '../../../slices/markets-details/supplyChainDetails';
import {
  updateActiveFocus,
  updateSupplyChainDateMode,
  updateSupplyChainViewMode,
  updateTimePeriod,
} from '../../../slices/markets/marketReducers';
import { SUPPLY_CHAIN_VIEW_MODE } from '../../../slices/markets/marketsConfig';
import {
  selectActiveFocus,
  selectMarketsTimePeriod,
  selectMarketSupplyChainControlState,
} from '../../../slices/markets/selectors/selectBasicMarketData';
import {
  selectMarketSupplyChainGrinders,
  selectMarketSupplyChainPackerPlants,
  selectSupplyChainDetailsActiveGrinder,
  selectSupplyChainDetailsActivePackerPlant,
} from '../../../slices/markets/selectors/selectMarketsSupplyChainData';
import styles from './SupplyChain.module.css';

export default function SupplyChainFilters(props) {
  const dispatch = useDispatch();

  const focus = useSelector(selectActiveFocus);
  const timePeriod = useSelector(selectMarketsTimePeriod);
  const marketSupplyChainControlState = useSelector(selectMarketSupplyChainControlState);
  const packer_plant = useSelector(selectSupplyChainDetailsActivePackerPlant);
  const grinder = useSelector(selectSupplyChainDetailsActiveGrinder);
  const packer_plants = useSelector(selectMarketSupplyChainPackerPlants);
  const grinders = useSelector(selectMarketSupplyChainGrinders);

  const handleFocusChange = React.useCallback(
    newVal => {
      dispatch(updateActiveFocus(newVal));
    },
    [dispatch]
  );

  const handleTimePeriodChange = React.useCallback(
    newVal => {
      dispatch(updateTimePeriod(newVal));
    },
    [dispatch]
  );

  const handleDateModeChange = React.useCallback(v => dispatch(updateSupplyChainDateMode(v)), [dispatch]);

  const handlePackerPlantChange = React.useCallback(
    newVal => {
      dispatch(updateActivePackerPlant(newVal));
      dispatch(updateActiveGrinder(null));
      if (newVal !== null) {
        dispatch(updateSupplyChainViewMode(SUPPLY_CHAIN_VIEW_MODE.PACKER_PLANTS));
      }
    },
    [dispatch]
  );

  const handleGrinderChange = React.useCallback(
    newVal => {
      dispatch(updateActiveGrinder(newVal));
      dispatch(updateActivePackerPlant(null));
      if (newVal !== null) {
        dispatch(updateSupplyChainViewMode(SUPPLY_CHAIN_VIEW_MODE.GRINDERS));
      }
    },
    [dispatch]
  );

  return (
    <div className={styles.filterRow}>
      <div data-columns={4} className={styles.filterItem}>
        <FilterFocus activeFocus={focus} onActiveFocusChange={handleFocusChange} />
      </div>
      <div data-columns={10} className={styles.filterItem}>
        <FilterTimePeriod timePeriod={timePeriod} onTimePeriodChange={handleTimePeriodChange} />
      </div>
      <div data-columns={4} className={styles.filterItem}>
        <FilterDateType controlState={marketSupplyChainControlState} onDateModeChange={handleDateModeChange} />
      </div>
      <div data-columns={3} className={styles.filterItem}>
        <FilterPackerPlant
          packer_plant={packer_plant}
          packer_plants={packer_plants}
          onActivePackerPlantChange={handlePackerPlantChange}
        />
      </div>
      <div data-columns={3} className={styles.filterItem}>
        <FilterGrinder grinder={grinder} grinders={grinders} onActiveGrinderChange={handleGrinderChange} />
      </div>
    </div>
  );
}
