import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import { BackButton, detailsHStackLayoutProps, FieldGroup, PreambleText, VStackLayout } from '../Components';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  Flex,
  HStack,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import Constants from '../../../Constants';

function sellQuantityInCartons(sellQuantity, sellUnitOfMeasure) {
  if (sellQuantity === null || sellQuantity <= 0) {
    return 0;
  }
  if (sellUnitOfMeasure === 'bin') {
    return 0;
  }
  if (sellUnitOfMeasure === 'kgs') {
    return sellQuantity / 27.2;
  }
  if (sellUnitOfMeasure === 'lbs') {
    return sellQuantity / 60;
  }
  return sellQuantity;
}

const DetailContainer = ({ children }) => (
  <VStack align="stretch" spacing="28px">
    {children}
  </VStack>
);
DetailContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const DetailItem = ({ label, detail }) => (
  <Box>
    <PreambleText>{label}</PreambleText>
    <Text>{detail}</Text>
  </Box>
);
DetailItem.propTypes = {
  label: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
};

const EntrapmentForm = ({ order, token, grinderPoNumber, onSave }) => {
  const history = useHistory();
  const [entrapments, setEntrapments] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleEntrapmentChange = (index, value) => {
    const updatedEntrapments = [...entrapments];
    updatedEntrapments[index].entrapment = value;
    setEntrapments(updatedEntrapments);
  };

  const onClose = () => {
    if (typeof onSave === 'function') {
      onSave();
    }
    history.push('/ncr');
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const endpointUrl = `${Constants.URL}entrapment`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      });

      const response = await fetch(endpointUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          grinder_po_number: grinderPoNumber,
          entrapment: entrapments[0].entrapment,
        }),
      });
      if (response.status === 201 || response.status === 200) {
        onClose();
      } else {
        const data = await response.json();
        setErrorMessage(data.message);
      }
    } catch (error) {
      setErrorMessage(error?.message ?? 'An error occurred while saving the entrapment details');
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (!order) {
      return;
    }
    const totalSellQuantity = order.lines.reduce((sum, line) => {
      if (line?.sellQuantity !== null && line?.sellQuantity !== undefined) {
        return sum + line?.sellQuantity;
      }
      return sum;
    }, 0);

    const totalSellQuantityInCartons = sellQuantityInCartons(totalSellQuantity, order?.sellUnitOfMeasure?.name);

    setEntrapments([
      {
        grinderPoNumber,
        totalSellQuantity: totalSellQuantityInCartons || 0,
        entrapment: order?.entrapment || 0,
      },
    ]);
  }, [order]);

  return (
    <DashboardSectionTile
      title="Entrapment Detials"
      padding="22px 33px 22px 20px"
      marginTop="20px"
      position="relative"
      headerFlexStyle={{
        justifyContent: 'space-between',
      }}
    >
      <VStackLayout>
        <Box>
          {entrapments.map((item, index) => (
            <HStack key={index} {...detailsHStackLayoutProps} spacing="32px" marginTop="18px">
              <FieldGroup label="Grinder PO Number">
                <Input value={item.grinderPoNumber} isDisabled />
              </FieldGroup>
              <FieldGroup label="Total Sell Quantity(Cartons)">
                <Input value={item.totalSellQuantity.toFixed(2)} isDisabled />
              </FieldGroup>
              <FieldGroup label="Number of cartons entrapped">
                <Input
                  value={item.entrapment}
                  type="number"
                  onChange={e => handleEntrapmentChange(index, +e.target.value)}
                />
              </FieldGroup>
            </HStack>
          ))}
        </Box>

        {errorMessage && (
          <Alert
            status="error"
            marginTop="23px"
            backgroundColor="#FED7D7"
            borderRadius="6px"
            fontWeight="400"
            fontSize="16px"
          >
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
            <CloseButton
              marginLeft="auto"
              onClick={() => {
                setErrorMessage('');
              }}
            />
          </Alert>
        )}

        <Flex justifyContent="space-between" marginY="35px">
          <BackButton fontSize="16px" onClick={onClose} />

          <Button
            colorScheme="actionPrimary"
            fontWeight="700"
            width="115px"
            height="40px"
            onClick={handleSave}
            isLoading={isSaving}
          >
            Save
          </Button>
        </Flex>
      </VStackLayout>
    </DashboardSectionTile>
  );
};

export default EntrapmentForm;
EntrapmentForm.propTypes = {
  order: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  grinderPoNumber: PropTypes.string.isRequired,
  onSave: PropTypes.func,
};
