import React, { useEffect } from 'react';
import Loading from '../basic/Loading';
import HeadingWithButton from '../core/HeadingWithButton';
import ArticleContent from '../core/articles/ArticleContent';
import AuthGate from '../../containers/auth/AuthGate';
import ArticleEdit from '../../containers/articles/ArticleEdit';
import { HStack, Text, Box, Button, IconButton } from '@chakra-ui/react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { downloadFromS3Link } from '../../helpers/downloads';
import { DownloadIcon } from '@chakra-ui/icons';

const Article = ({ getArticle, article, triggerEditMode, isKpi = false }) => {
  const { slug } = useParams();

  useEffect(() => {
    getArticle(slug);
  }, [slug]);

  const button = isKpi ? (
    <AuthGate requiredPermissions={['articles_write']}>
      <Link to={{pathname: `/kpi/${article?.region}/edit/${article?.slug}`, state: { editedArticle: article  }}} >
      <Button colorScheme="actionSecondary" >
      Edit KPI
      </Button>
      </Link>
    </AuthGate>
  ) : (<Link to={{pathname: `/edit-article/${slug}`,state: { editedArticle: article  }}} >
  <Button colorScheme="actionSecondary">Edit Article</Button>
    </Link>);

  const topText = (
    <HStack h="10" spacing="5">
      {article &&
        article.tags &&
        article.tags.map(t => (
          <Text color="secondary.800" fontSize="sm" fontWeight="bold" key={t}>
            {t}
          </Text>
        ))}
    </HStack>
  );

  const bottomText = (
    <>
      {article && (
        <HStack h="10" spacing="2">
          {!isKpi && (
            <>
              <Text color="neutral.3" fontSize="md">
                {`${moment(article.published_date).format('MMM DD, YYYY')} by `}
              </Text>
              <Text color="neutral.3" fontSize="md" fontWeight="bold">
                {' '}
                {article.author}
              </Text>
            </>
          )}
          {isKpi && article.attachment_url && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 'auto' }}>
              <div>
                {' '}
                <IconButton
                  icon={<DownloadIcon />}
                  onClick={e => {
                    e.stopPropagation();
                    if (article && article.attachment_url) {
                      downloadFromS3Link(article.attachment_url);
                    }
                  }}
                  colorScheme="actionSecondary"
                />
              </div>
            </div>
          )}
        </HStack>
      )}
    </>
  );

  // if (editing) {
  //   return <ArticleEdit currentArticle={article} />;
  // }
  return (
    <Box p="10" background="white">
      {article ? (
        <>
          <HeadingWithButton text={article.title} topText={!isKpi && topText} bottomText={bottomText} button={button} />
          <ArticleContent article={article} />
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};

Article.propTypes = {
  getArticle: PropTypes.func,
  article: PropTypes.shape({
    title: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    published_date: PropTypes.string,
    created: PropTypes.string,
    author: PropTypes.string,
    attachment_url: PropTypes.string,
  }),
  editing: PropTypes.bool,
  triggerEditMode: PropTypes.func,
  isKpi: PropTypes.bool,
};

export default Article;
