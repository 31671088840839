import { removeObjectFromArray, replaceObjectInArray } from '../helpers';
import {
  REMOVE_ORDER_COLDSTORE,
  ORDERS_COLDSTORE_FETCHED,
  REPLACE_ORDERS_COLDSTORE,
  REPLACE_ORDER_COLDSTORE,
} from '../actions/actions_orders_coldstore';

const orders_coldstore = (orders_coldstore = [], action) => {
  const ordersColdstoreCopy = JSON.parse(JSON.stringify(orders_coldstore));
  switch (action.type) {
    case ORDERS_COLDSTORE_FETCHED:
      return action.payload;
    case REPLACE_ORDERS_COLDSTORE:
    case REPLACE_ORDER_COLDSTORE:
      return replaceObjectInArray(ordersColdstoreCopy, action.payload);
    case REMOVE_ORDER_COLDSTORE:
      return removeObjectFromArray(ordersColdstoreCopy, action.payload);
    default:
      return orders_coldstore;
  }
};

export default orders_coldstore;
