import { Alert, AlertIcon, AlertTitle, Box, Center, Flex, Spacer, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ResultsGrid from '../../../../components/basic/ResultsGrid';
import Button from '../../../../components/core/Button';
import Card from '../../../../components/core/Card';
import TabListComponent from '../../../../components/core/TabList';
import OrderActionsFormatter from '../../../../components/gridHelpers/OrderActionsFormatter';
import { difference, gridHeight, rowHeight, rowKeyGetter, rowClass } from '../../bulk-updater-views';
import BulkUpdaterModal from './BulkUpdaterModal/BulkUpdaterModal';
import BulkUpdaterSummary from './BulkUpdaterSummary';
import Constants from '../../../../Constants';
import { findOptionMatch } from '../../../../components/gridHelpers/SelectEditor';
import { selectOrderOptions } from '../../../../slices/masterData/selectOrderEntities';

const BulkUpdaterEditor = ({ view, selectedResults, onClickExitButton, onSubmitBulkUpdate }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const [pos, setPos] = useState([]);
  const [posSelectedIds, setSelectedRows] = useState(new Set());
  const [posChanges, setPosChanges] = useState([]);
  const [posChangesSummary, setPosChangesSummary] = useState([]);
  const [showBulkUpdateModal, setShowBulkUpdateModal] = useState(false);
  const { inputProductOptions, packerPlantOptions, portOptions, shippingLineOptions } = useSelector(selectOrderOptions);

  useEffect(() => {
    if (selectedResults) {
      setPos(JSON.parse(JSON.stringify(selectedResults)));
    }
  }, [selectedResults]);

  useEffect(() => {
    if (pos) {
      const diff = difference(selectedResults, pos);
      setPosChanges(diff);
      const ignoreFields = ['purchase_order_id', 'id'];
      setPosChangesSummary(
        diff.reduce((pos_, po) => {
          const originaPo = selectedResults.find(po_ => po_.id === po.id);
          const addObj = (ori, ob, name) => {
            Object.keys(ob)
              .filter(key => (!ignoreFields.includes(key) && typeof ob[key] !== 'object') || key==='lot_numbers')
              .forEach(key => {
                let key_name = view.columns.filter(
                  col => col.key === key || col.key === `${name}.${key}` || col.key === `${key.split('_id')[0]}`
                )[0];
                const match = findOptionMatch(packerPlantOptions, ob[key]);
                const old_match = ori
                  ? ori[key]
                    ? findOptionMatch(packerPlantOptions, ori[key])?.name
                    : '(Empty)'
                  : null;
                pos_.push({
                  purchaseOrderId: po.id,
                  grinderPurchaseOrderNumber: po.grinder_po_number || originaPo.grinder_po_number,
                  id: '',
                  key: key_name ? key_name.name : null,
                  old: key === 'packer_plant_id' ? old_match : ori ? (ori[key] ? ori[key] : '(Empty)') : null,
                  new: key === 'packer_plant_id' ? match.name : ob[key],
                });
              });
          };
          // order values fields
          addObj(originaPo, po, 'order');
          // order object fields
          Object.keys(po)
            .filter(key => typeof po[key] === 'object' && !Array.isArray(po[key]))
            .forEach(key => addObj(originaPo[key], po[key], key));
          // order array fields
          Object.keys(po)
            .filter(key => Array.isArray(po[key]))
            .forEach(poKey => {
              const items = po[poKey];
              items.forEach(line => {
                Object.keys(line)
                  .filter(key => !ignoreFields.includes(key) && typeof line[key] !== 'object')
                  .forEach(key => {

                    let key_object =
                      poKey === 'lines'
                        ? view.columns.filter(col => col.key === 'lines_aus_logistics' || col.key === 'lines')[0]
                        : view.columns.filter(
                            col =>
                              col.key === poKey || col.key === `${poKey}.${key}` || col.key === `${key.split('_id')[0]}`
                          )[0];
                    let key_object_name = key_object.columns?.find(line => line.key === key);
                    const originaLine = originaPo[poKey].find(line_ => line_.id === line.id);
                    const port =
                      key === 'load_port_id'
                        ? findOptionMatch(portOptions, originaLine.load_port_id)
                        : key === 'discharge_port_id'
                        ? findOptionMatch(portOptions, originaLine.discharge_port_id)
                        : key === 'shipping_line_id'
                        ? findOptionMatch(shippingLineOptions, originaLine.shipping_line_id)
                        : findOptionMatch(portOptions, originaLine.tranship_port_id);
                    const new_port = findOptionMatch(portOptions, line[key]);
                    const match = findOptionMatch(inputProductOptions, originaLine.input_product_uid);
                    const product_name = match ? match.name : '';
                    pos_.push({
                      purchaseOrderId: po.id,
                      grinderPurchaseOrderNumber: po.grinder_po_number || originaPo.grinder_po_number,
                      id: poKey === 'lines' ? product_name : '',
                      key: key_object_name
                        ? key_object_name.name
                        : Constants.VIEW_COLS.find(column => column.key === key).name,
                      // key: `${poKey}.${key}`,
                      old:
                        key === 'load_port_id' || key === 'discharge_port_id' || key === 'tranship_port_id' || key === 'shipping_line_id'
                          ? port
                            ? port.name
                            : '(Empty)'
                          : originaPo[poKey].find(line_ => line_.id === line.id)[key],
                      new:
                      key === 'shipping_line_id' ? findOptionMatch(shippingLineOptions, line[key])?.name :
                        key === 'load_port_id' || key === 'discharge_port_id' || key === 'tranship_port_id'
                          ? new_port?.name
                          : line[key],
                    });
                  });
              });
            });
          return pos_;
        }, [])
      );
    }
  }, [pos]);

  const moveToEditor = () => setTabIndex(0);

  const moveToSummary = () => setTabIndex(1);

  const moveToDone = () => setTabIndex(2);

  return (
    <>
      <Tabs index={tabIndex}>
        <Flex bg="#EBF0FF" py="20px" px="40px">
          <TabListComponent
            tabList={[{ label: 'Edit PO' }, { label: 'Summary' }, { label: 'Done' }]}
            activeTabIndex={tabIndex}
          />
          <Spacer />
          <Button colorScheme="actionSecondary" width="110px" onClick={onClickExitButton}>
            Exit
          </Button>
        </Flex>
        <Box pb="20px" px="40px" w="100%">
          <TabPanels>
            <TabPanel>
              <Card
                {...{
                  my: '20px',
                  pt: '17px',
                  pb: '33px',
                  px: '27px',
                  borderColor: 'gray.400',
                  backgroundColor: 'graySubtle',
                  text: 'PO List',
                }}
              >
                <Flex mb="40px">
                  <Box w="100px" h="30px" borderWidth='1px' borderRadius='lg'>
                    <Button
                      variant='solid'
                      size="sm"
                      width="100px"
                      onClick={() => setShowBulkUpdateModal(true)}
                      disabled={isEmpty(posSelectedIds)}
                    >
                      Bulk Edit
                    </Button>
                  </Box>
                  <Spacer />
                  <Alert
                    status="info"
                    w="250px"
                    h="40px"
                    bg="infoBg"
                    color="info"
                    border="1px solid"
                    borderColor="infoBorder"
                    borderRadius="8px"
                  >
                    <Flex>
                      <AlertIcon />
                      <Flex direction="column">
                        <AlertTitle pl="10px">You are in Edit Mode</AlertTitle>
                      </Flex>
                    </Flex>
                  </Alert>
                  <Spacer />
                  <Box
                    {...{
                      w: '175px',
                      borderRadius: '10px',
                      p: '12px',
                      marginRight: '15px',
                    }}
                    boxShadow="primary"
                  >
                    <Center
                      {...{
                        fontSize: 'xl',
                        lineHeight: 'xl',
                        color: 'var(--chakra-colors-primary100)',
                        fontWeight: 'bold',
                      }}
                    >
                      {posChanges.length}
                    </Center>
                    <Center fontWeight="400">POs modified</Center>
                  </Box>
                  <Box
                    {...{
                      w: '175px',
                      borderRadius: '10px',
                      p: '12px',
                    }}
                    boxShadow="primary"
                  >
                    <Center
                      {...{
                        fontSize: 'xl',
                        lineHeight: 'xl',
                        color: 'var(--chakra-colors-primary100)',
                        fontWeight: 'bold',
                      }}
                    >
                      {posChangesSummary.length}
                    </Center>
                    <Center fontWeight="400">Fields modified</Center>
                  </Box>
                </Flex>
                <ResultsGrid
                  id="bulk-updater-editor-grid"
                  className="bulk-updater__results-grid"
                  columns={view.columns}
                  rows={pos}
                  rowKeyGetter={rowKeyGetter}
                  onGridRowUpdate={setPos}
                  selectedRows={posSelectedIds}
                  onRowSelect={setSelectedRows}
                  rowHeight={rowHeight}
                  disableSelect
                  rowClass={rowClass}
                  height={400}
                  minHeight={400}
                />
              </Card>
              <Flex justifyContent="flex-end">
                {/* TODO BULKUPDATER <FieldEditWarningAlert fields={editedFields} /> */}
                <Button ml="auto" width="110px" onClick={moveToSummary} disabled={posChanges.length === 0}>
                  Next
                </Button>
              </Flex>
            </TabPanel>
            <TabPanel>
              <BulkUpdaterSummary
                posChangesSummary={posChangesSummary}
                posChanges={posChanges}
                onClickBackButton={moveToEditor}
                onClickConfirmButton={() => {
                  onSubmitBulkUpdate(posChanges);
                  moveToDone();
                }}
              />
            </TabPanel>
            <TabPanel>
              <BulkUpdaterSummary
                posChangesSummary={posChangesSummary}
                posChanges={posChanges}
                done
                onClickFinishButton={() => {
                  onClickExitButton();
                  moveToEditor();
                }}
              />
            </TabPanel>
          </TabPanels>
        </Box>
      </Tabs>
      <OrderActionsFormatter.Modals />
      {showBulkUpdateModal && (
        <BulkUpdaterModal
          {...{
            isOpen: showBulkUpdateModal,
            view,
            pos,
            posSelectedIds,
            onCloseModal: () => setShowBulkUpdateModal(false),
            onSubmit: pos_ => setPos(pos_),
          }}
        />
      )}
    </>
  );
};

BulkUpdaterEditor.propTypes = {
  view: PropTypes.objectOf(Object).isRequired,
  selectedResults: PropTypes.arrayOf(PropTypes.objectOf(Object)).isRequired,
  onClickExitButton: PropTypes.func.isRequired,
  onSubmitBulkUpdate: PropTypes.func.isRequired,
};

export default BulkUpdaterEditor;
