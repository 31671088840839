import { connect } from 'react-redux';
import AppError from '../../components/utilities/AppError';

const mapStateToProps = state => {
  return {
    error: state.error,
  };
};

const AppErrorContainer = connect(mapStateToProps)(AppError);

export default AppErrorContainer;
