import React from 'react';

const PurchaseOrderCell = props => {
  const onClickFn = props.item.status === 'current' ? props.openConfirm : props.editPurchaseOrder;
  const title = props.item.status === 'current' ? '[Confirm]' : '[Edit]';
  return (
    <td>
      {props.item.purchase_order_id || props.item.status === 'current' ? (
        <a
          onClick={() => {
            onClickFn(true, props.item);
          }}
          href="javascript:void(0)"
        >
          {title}
        </a>
      ) : (
        false
      )}
    </td>
  );
};

export default PurchaseOrderCell;
