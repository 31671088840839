import {
  PACKER_PLANTS_FETCHED,
  PACKER_PLANT_ADDED,
  PACKER_PLANT_EDIT,
  PACKER_PLANT_REMOVED,
} from '../actions/actions_packer_plants';
import { removeObjectFromArray, replaceObjectInArray } from '../helpers';

const packer_plants = (packer_plants = [], action) => {
  const newPackerPlants = JSON.parse(JSON.stringify(packer_plants));

  switch (action.type) {
    case PACKER_PLANTS_FETCHED:
      return action.payload;
    case PACKER_PLANT_ADDED:
      newPackerPlants.push(action.payload);
      return newPackerPlants;
    case PACKER_PLANT_EDIT:
      return replaceObjectInArray(newPackerPlants, action.payload);
    case PACKER_PLANT_REMOVED:
      return removeObjectFromArray(newPackerPlants, action.payload);
    default:
      return packer_plants;
  }
};

export default packer_plants;
