import React from 'react';
import { Select, Typography } from 'antd';
import { TREASURY_DETAILS_COST } from '../../../../slices/treasury-details/treasuryDetailsReducers';

const OPTIONS = [TREASURY_DETAILS_COST.TOTAL, TREASURY_DETAILS_COST.SAVINGS];

export default function FilterCost(props) {
  const { activeCost, onActiveCostChange } = props;

  function handleChange(value) {
    onActiveCostChange(value);
  }

  return (
    <div>
      <Typography.Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Cost
      </Typography.Text>
      <Select value={activeCost} style={{ width: '100%' }} onChange={handleChange}>
        <Select.Option value={null}>--</Select.Option>
        {OPTIONS.map(o => (
          <Select.Option key={o} value={o}>
            {o}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
