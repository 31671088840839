import { cloneDeep, isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { fetchGrinders } from '../../../actions/actions_grinders';
import {
  bulkOrders,
  getColdStoreReleasePDF,
  resetColdStoreEmailModal,
  saveOrder,
  sendEmailWithReleasePDF,
  removeOrderFromScreen,
} from '../../../actions/actions_coldstore_orders_util';

import { fetchOrdersPendingColdstoreSchedule } from '../../../actions/actions_orders_pending_coldstore_schedule';

import { fetchOrdersPendingColdstoreRelease } from '../../../actions/actions_orders_pending_coldstore_release';

import { fetchOrdersColdstoreReleased } from '../../../actions/actions_orders_coldstore_released';

import { fetchTransporters } from '../../../actions/actions_transporters';
import Constants from '../../../Constants';
import Loading from '../../basic/Loading';
import { groupBy, isOrderCompleted, checkForInvalidOrders } from '../helpers';
import GrinderRelease from './GrinderRelease';
import GrinderSchedule from './GrinderSchedule';
import './ReleaseTemplate.scss';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Tooltip as ChakraTooltip,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Modal as ChakraModal,
  Button as ChakraButton,
  UnorderedList,
  ListItem,
  VStack,
  Center,
} from '@chakra-ui/react';
import { ReleasableOrderContext } from './contexts';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import { ChevronDownIcon, ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';
import { message } from 'antd';
import StyledAlert from './StyledAlert';
import { getTenantFromDomain } from '../../../helpers/tenant';

class ReleaseTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      lastUpdate: null,
      sentEmailsForOrdersIds: [],
      showConfirmModal: false,
      partiallyFilledOrders: [],
      releasableOrders: [],
      toTab: null,
      coldstoreStatus: null,
      fetchOrders: false,
      showAlert: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.reloadOrders();
    if (this.props.grinders.length === 0) {
      this.props.dispatch(fetchGrinders(this.props.token));
    }
    if (this.props.transporters.length === 0) {
      this.props.dispatch(fetchTransporters(this.props.token));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.fetchOrders && this.state.fetchOrders !== prevState.fetchOrders) {
      this.reloadOrders();
    }
  }

  // just to dry up the action dispatch calls
  fetchColdstoreOrdersByFn(fn, deliveryDateStart) {
    this.props.dispatch(fn(this.props.token, deliveryDateStart)).then(() => {
      this.setState({
        loading: false,
        lastUpdate: moment().format('DD-MM-YYYY h:mm A'),
      });
    });
  }

  reloadOrders = () => {
    this.setState({ loading: true });
    const { coldstore_status: coldstoreStatus, dispatch } = this.props;

    const status = coldstoreStatus;
    const deliveryDateStart = moment().startOf('week').format('YYYY-MM-DD');

    if (status === Constants.ORDER_STATUSES.PENDING_COLDSTORE_SCHEDULE) {
      this.fetchColdstoreOrdersByFn(fetchOrdersPendingColdstoreSchedule);
    } else if (status === Constants.ORDER_STATUSES.PENDING_COLDSTORE_RELEASE) {
      this.fetchColdstoreOrdersByFn(fetchOrdersPendingColdstoreRelease);
    } else if (status === Constants.ORDER_STATUSES.COLDSTORE_RELEASED) {
      this.fetchColdstoreOrdersByFn(fetchOrdersColdstoreReleased, deliveryDateStart);
    }

    dispatch(resetColdStoreEmailModal());
  };

  // function for setting releasable orders
  updateScheduleRelease = (ordersMap, coldstoreStatus, toTab) => {
    let releasableOrders = [];
    let partiallyFilledOrders = [];

    // if any of the fields are not empty, partiallyFilledOrders is empty
    const selectedOrders = Object.values(cloneDeep(ordersMap));

    selectedOrders.forEach((iteratorOrder, index) => {
      if (isOrderCompleted(iteratorOrder)) {
        // remove null_delivery_time as its's only for UI convenience, update status
        // remove fmg_po_number as it is not a patchable field
        iteratorOrder.status = coldstoreStatus;
        delete iteratorOrder.null_delivery_time;
        delete iteratorOrder.internal_po_number;
        // add to releasableOrders if not exists
        if (!releasableOrders.find(o => o.id === iteratorOrder.id)) {
          releasableOrders = [...releasableOrders, iteratorOrder];
        }
      } else {
        partiallyFilledOrders = [...partiallyFilledOrders, iteratorOrder];
      }
    });

    if (releasableOrders.length === 0) {
      message.warning('Please select releaseable load(s) to proceed.');
    }
    if (selectedOrders.length === 0) {
      message.warning('Please select load(s) to proceed.');
    } else if (releasableOrders.length > 0) {
      const confirmButtonText = toTab === 'release' ? 'Schedule release(s)' : 'Release Schedules';
      // confirm pop up
      this.setState({
        showConfirmModal: true,
        confirmButtonText,
        partiallyFilledOrders,
        toTab,
        coldstoreStatus,
        releasableOrders,
      });
    } else {
      this.setState({ partiallyFilledOrders });
    }
  };

  // backend
  confirmScheduleRelease = () => {
    const { releasableOrders, toTab, coldstoreStatus } = this.state;
    const { dispatch, token, handleNavigation } = this.props;
    // saves data to db and procees to next tab 'Release' or 'Schedule' if fully populated
    if (releasableOrders.length) {
      const safeOrders = releasableOrders.map(order => {
        const shallowCopy = { ...order };
        delete shallowCopy.active_price_bucket;
        return shallowCopy;
      });
      dispatch(bulkOrders(safeOrders, coldstoreStatus, token)).then(() => {
        this.onExit();
        this.setState({ fetchOrders: true });
        handleNavigation('release_management', toTab);
      });
    }
  };

  onExit = () => {
    this.setState({ showConfirmModal: false });
  };

  // released tab
  clearColdStoreEmailModal = () => {
    this.props.dispatch(resetColdStoreEmailModal());
  };

  emailColdstoreRelease = async (orders, subject, body, emails) => {
    const payload = {
      purchase_order_ids: orders.map(order => order.id),
      emails,
      email_subject: subject,
      email_body: body,
      status: Constants.ORDER_STATUSES.COLDSTORE_RELEASED,
      pdfTitle: { title: 'Delivery Schedule', fontColour: 'black' },
    };
    await this.props.dispatch(sendEmailWithReleasePDF(payload, orders, this.props.token));
    this.props.handleNavigation('release', 'released')
  };

  fetchColdStoreReleasePDF = orders => {
    const purchaseOrderIds = orders.map(o => o.id);
    const payload = {
      purchase_order_ids: purchaseOrderIds,
      pdfTitle: { title: 'Delivery Schedule', fontColour: 'black' },
    };
    this.props.dispatch(getColdStoreReleasePDF(payload, this.props.token));
  };

  // in release and released tab or deleting individual rows
  patchOrder = (id, payload, moveToPrevTab = false) => {
    const currentOrder = this.props.orders.find(order => order.id === id);

    if (moveToPrevTab !== false) {
      if (currentOrder.status !== payload.status) {
        this.props.dispatch(removeOrderFromScreen(currentOrder, payload.status));
      }
      this.props.dispatch(saveOrder(payload, id, this.props.token));
      this.props.handleNavigation('release_management', moveToPrevTab);
    } else {
      if (currentOrder.status !== payload.status) {
        this.props.dispatch(removeOrderFromScreen(currentOrder, payload.status));
      }
      return this.props.dispatch(saveOrder(payload, id, this.props.token));
    }
  };

  renderConfirmModalContent = () => {
    const { releasableOrders } = this.state;
    const tenant = getTenantFromDomain();
    const domain = tenant.includes('cfc') ? 'CFCO' : 'FMG'
    return (
      <Box>
        <Text as="p" fontWeight="bold">
          The following PO's will be scheduled:
        </Text>
        <UnorderedList styleType="none" margin="0px">
          {releasableOrders.map(orderIterator => {
            let orderItem = this.props.orders?.filter(o => o?.id === orderIterator.id);
            if( !orderItem || orderItem.length === 0){
              return null;
            }
            return (
              <ListItem>
                {domain} PO# {orderItem[0]?.internal_po_number || ''} / Grinder PO# {orderItem[0]?.grinder_po_number || ''}
              </ListItem>
            )
          })}
        </UnorderedList>
        <Center height="136px">
          <Text textAlign="center" fontWeight="bold">
            Are you sure you want to continue?
          </Text>
        </Center>
      </Box>
    );
  };

  // render data
  render() {
    const groupedOrders = groupBy(this.props.orders, 'grinder_uid');
    const { showAlert } = this.state;
    const { title } = this.props;
    return (
      <VStack align="stretch" spacing="47px" padding="47px">
        <DashboardSectionTile title={title} paddingX="36px">
          {this.state.loading ? (
            <Center>
              <Loading />
            </Center>
          ) : (
            <VStack align="stretch" spacing="38px">
              {isEmpty(groupedOrders) && showAlert && (
                <StyledAlert
                  status="error"
                  infoMessage={isEmpty(groupedOrders) ? this.props.noDataHeaderMessage : this.props.headerMessage}
                  onClick={() => this.setState({ showAlert: !showAlert })}
                />
              )}
              {this.props.infoMessage && !isEmpty(groupedOrders) && showAlert && (
                <StyledAlert
                  status="info"
                  infoMessage={this.props.infoMessage}
                  onClick={() => this.setState({ showAlert: !showAlert })}
                />
              )}
              <Accordion
                allowMultiple
                borderRadius="20px"
                boxShadow="0px 4px 20px 0px #0000001A"
                borderTopWidth="0px"
                backgroundColor="white"
              >
                {Object.entries(groupedOrders).map(([grinderUID, orders]) => {
                  return this.renderGrinderOrders(grinderUID, orders);
                })}
              </Accordion>
            </VStack>
          )}
        </DashboardSectionTile>
      </VStack>
    );
  }

  // grouped grinder orders
  renderGrinderOrders = (grinderUID, orders) => {
    // sort by delivery date by default
    orders = orderBy(orders, 'delivery_date');
    const grinder = this.props.grinders.find(grinder => grinder.uid === grinderUID);
    const releasedCount = orders.filter(o => o.status === Constants.ORDER_STATUSES.COLDSTORE_RELEASED).length;
    return (
      <AccordionItem
        borderTopWidth="0px"
        borderBottomWidth="1px"
        paddingY="11px"
        _last={{
          borderBottomWidth: '0px',
        }}
      >
        {({ isExpanded }) => (
          <>
            <AccordionButton paddingY="8.5px">
              {/* <AccordionIcon color={isExpanded && 'accent.one.default'} /> */}
              {isExpanded ? (
                <ChevronDownIcon color="accent.one.default" fontSize="20px" />
              ) : (
                <ChevronRightIcon fontSize="20px" />
              )}
              <ChakraTooltip
                label={releasedCount === 0 && `${orders.length} load(s) with detail to update/email`}
                placement="right"
              >
                <Text as="p" marginLeft="14px" fontWeight={isExpanded ? 'black' : 'normal'}>
                  {grinder?.name}
                </Text>
              </ChakraTooltip>
            </AccordionButton>
            <AccordionPanel padding="0px 10px">
              {this.props.coldstore_status === Constants.ORDER_STATUSES.PENDING_COLDSTORE_SCHEDULE ? (
                <ReleasableOrderContext.Provider value={this.state.releasableOrders}>
                  <GrinderSchedule
                    orders={orders}
                    transporters={this.props.transporters}
                    grinders={this.props.grinders}
                    patchOrder={this.patchOrder}
                    scheduleRelease={this.updateScheduleRelease}
                    token={this.props.token}
                    user={this.props.user}
                    dispatch={this.props.dispatch}
                    internalPoLabel={this.props.internalPoLabel}
                  />
                </ReleasableOrderContext.Provider>
              ) : (
                <GrinderRelease
                  orders={orders}
                  transporters={this.props.transporters}
                  grinders={this.props.grinders}
                  patchOrder={this.patchOrder}
                  onSendEmails={this.emailColdstoreRelease}
                  sentEmailsForOrdersIds={this.state.sentEmailsForOrdersIds}
                  previewOrders={this.props.previewOrders}
                  token={this.props.token}
                  user={this.props.user}
                  dispatch={this.props.dispatch}
                  status={this.props.coldstore_status}
                  release={this.updateScheduleRelease}
                  getColdStoreReleasePDF={this.fetchColdStoreReleasePDF}
                  notifying_action_groups={this.props.notifying_action_groups}
                  coldstoreSendEmailError={this.props.coldstoreSendEmailError}
                  clearColdStoreEmailModal={this.clearColdStoreEmailModal}
                  isColdstoreEmailSent={this.props.isColdstoreEmailSent}
                  internalPoLabel={this.props.internalPoLabel}
                />
              )}
              <ChakraModal isOpen={this.state.showConfirmModal} onClose={this.onExit} size="lg" isCentered={true}>
                <ModalOverlay />
                <ModalContent maxWidth="860px">
                  <ModalHeader borderBottom="1px" borderColor="gray.200" paddingLeft="48px" marginBottom="17px">
                    <Text as="p" fontSize="20px" fontWeight="bold">
                      Confirm Your Selection
                    </Text>
                    <ModalCloseButton marginTop="8px" />
                  </ModalHeader>
                  <ModalBody paddingX="52px">{this.renderConfirmModalContent()}</ModalBody>
                  <ModalFooter borderTopWidth="1px" marginTop="48px">
                    <ChakraButton
                      colorScheme="actionPrimary"
                      height="40px"
                      width="112px"
                      fontSize="16px"
                      onClick={() => {
                        this.confirmScheduleRelease();
                      }}
                    >
                      Confirm
                    </ChakraButton>
                  </ModalFooter>
                </ModalContent>
              </ChakraModal>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    );
  };
}

export default ReleaseTemplate;
