import React from 'react';
import accounting from 'accounting';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { Popover } from 'antd';
import { SelectColumn } from 'react-data-grid';

import Constants from '../../Constants';
import {
  InputEditor,
  InputFormatter,
  SelectEditor,
  ActionsFormatter,
  TextFormatter,
  DatePickerEditor,
} from '../gridHelpers';
import { determineWeightType, formatWeightType, sumOrderByWeightType } from './helpers';
import PriceBreakdown from './PriceBreakdown';
import { Flex, Tag, Text } from '@chakra-ui/react';
import { RowCopier } from '../gridHelpers/ClickToCopy';

const { FINANCE_CARTS: CARTS } = Constants;

export function stopPropagation(event) {
  event.stopPropagation();
}

const HeaderRenderer = headerRendererProps => {
  const { column } = headerRendererProps;
  const { name } = column;
  return (
    <Text as="p" fontSize="12px" {...headerRendererProps} textTransform="uppercase">
      {name}
    </Text>
  );
};

// --------------------------
// OPTIONS
// --------------------------
const weightTypeOptions = [
  {
    id: 'po_weight',
    value: 'po_weight',
    name: 'po_weight',
    label: 'PO Weight',
    title: 'PO Weight',
  },
  {
    id: 'shipped_weight',
    value: 'shipped_weight',
    name: 'shipped_weight',
    label: 'Shipped Weight',
    title: 'Shipped Weight',
  },
  {
    id: 'received_weight',
    value: 'received_weight',
    name: 'received_weight',
    label: 'Received Weight',
    title: 'Received Weight',
  },
  {
    id: 'remaining_weight',
    value: 'remaining_weight',
    name: 'remaining_weight',
    label: 'Remaining Weight',
    title: 'Remaining Weight',
  },
  // TODO - re-instate once purchase order line level support is available on invoicing tabs
  // {
  //   id: 'amended_weight',
  //   value: 'amended_weight',
  //   text: 'Amended Weight',
  //   title: 'Amended Weight'
  // }
];

// --------------------------
// Formatters
// --------------------------

function renderPriceBreakdown(lineItems) {
  return <PriceBreakdown lineItems={lineItems} />;
}
function formatCalculatedPrice(priceSummary, noJsx) {
  if (!priceSummary) {
    return TextFormatter('');
  }
  const invoiceLineItems = get(priceSummary, 'invoice_line_items', []);
  const grinderDeliveredPrice = get(priceSummary, 'total_sell_price');

  const priceBreakdown = renderPriceBreakdown(invoiceLineItems);
  if (noJsx) return grinderDeliveredPrice ? accounting.formatMoney(grinderDeliveredPrice, '') : '';
  return (
    <Popover content={priceBreakdown} title="Price Breakdown">
      <div className="grinder-delivered-price">
        {grinderDeliveredPrice ? accounting.formatMoney(grinderDeliveredPrice, '') : ''}
      </div>
    </Popover>
  );
}

const getStatusColorConfig = status => {
  const colorConfigs = {
    todo: {
      bgColor: '#FFC246',
      color: '#FFFFFF',
    },
    active: {
      bgColor: '#00BA88',
      color: '#FFFFFF',
    },
  };

  return colorConfigs[status] ? colorConfigs[status] : colorConfigs.todo;
};
// --------------------------
// Columns
// --------------------------
const initializeColumnConfig = props => ({
  selector: SelectColumn,
  actions: {
    key: 'actions',
    name: 'Action',
    headerRenderer: HeaderRenderer,
    formatter: ActionsFormatter,
    width: 80,
  },
  grinder_po_number: {
    key: 'grinder_po_numbers',
    name: 'Grinder PO #',
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => <Text>{get(row, 'grinder_po_number')}</Text>,
    formattedValue: row => get(row, 'grinder_po_number'),
    minWidth: 120,
  },
  internal_po_number: {
    key: 'internal_po_number',
    name: 'Po#',
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => <Text>{get(row, 'internal_po_number')}</Text>,
    formattedValue: row => get(row, 'internal_po_number'),
    minWidth: 120,
  },
  grinder_name: {
    key: 'grinder_name',
    name: 'Grinder',
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => {
      const name = get(row, 'grinder.name', '');
      return <Text>{name}</Text>;
    },
    minWidth: 300,
    formattedValue: row => get(row, 'grinder.name', ''),
  },
  date_type: {
    key: 'date_type',
    name: startCase(props.dateType),
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => {
      let value = '';
      switch (row.dateType) {
        case 'delivery_date':
          value = get(row, row.dateType, '');
          break;
        case 'eta':
        case 'etd':
          value = get(row, `voyage[0].${row.dateType}`, '');
          break;
        default:
          value = '';
      }
      return <Text>{value}</Text>;
    },
    formattedValue: row => {
      let value = '';
      switch (row.dateType) {
        case 'delivery_date':
          value = get(row, row.dateType, '');
          break;
        case 'eta':
        case 'etd':
          value = get(row, `voyage[0].${row.dateType}`, '');
          break;
        default:
          value = '';
      }
      return value;
    },
  },
  po_weight: {
    key: 'po_weight',
    name: 'PO Weight',
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => {
      const unitOfMeasure = get(row, 'sell_unit_of_measure.name', '');
      const poWeight = sumOrderByWeightType(row, 'po_weight', props.packageWeights);
      const formattedPOWeight = accounting.formatNumber(poWeight, 0, ',');
      const value = `${formattedPOWeight} ${unitOfMeasure}`;
      return <Text>{value}</Text>;
    },
    formattedValue: row => {
      const unitOfMeasure = get(row, 'sell_unit_of_measure.name', '');
      const poWeight = sumOrderByWeightType(row, 'po_weight', props.packageWeights);
      const formattedPOWeight = accounting.formatNumber(poWeight, 0, ',');
      return `${formattedPOWeight} ${unitOfMeasure}`;
    },
  },
  shipped_weight: {
    key: 'shipped_weight',
    name: 'Shipped Weight',
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => {
      const unitOfMeasure = get(row, 'sell_unit_of_measure.name', '');
      const shippedWeight = sumOrderByWeightType(row, 'shipped_weight', props.packageWeights);
      if (shippedWeight === 0) return '';
      const formattedShippedWeight = accounting.formatNumber(shippedWeight, 0, ',');
      const value = `${formattedShippedWeight} ${unitOfMeasure}`;
      return <Text>{value}</Text>;
    },
    formattedValue: row => {
      const unitOfMeasure = get(row, 'sell_unit_of_measure.name', '');
      const shippedWeight = sumOrderByWeightType(row, 'shipped_weight', props.packageWeights);
      if (shippedWeight === 0) return '';
      const formattedShippedWeight = accounting.formatNumber(shippedWeight, 0, ',');
      return `${formattedShippedWeight} ${unitOfMeasure}`;
    },
  },
  remaining_weight: {
    key: 'remaining_weight',
    name: 'Remaining Weight',
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => {
      const unitOfMeasure = get(row, 'sell_unit_of_measure.name', '');
      const remainingWeight = sumOrderByWeightType(row, 'remaining_weight', props.packageWeights);
      const formattedRemainingWeight = accounting.formatNumber(remainingWeight, 0, ',');
      const value = `${formattedRemainingWeight} ${unitOfMeasure}`;
      return <Text>{value}</Text>;
    },
    formattedValue: row => {
      const unitOfMeasure = get(row, 'sell_unit_of_measure.name', '');
      const remainingWeight = sumOrderByWeightType(row, 'remaining_weight', props.packageWeights);
      const formattedRemainingWeight = accounting.formatNumber(remainingWeight, 0, ',');
      return `${formattedRemainingWeight} ${unitOfMeasure}`;
    },
  },
  received_weight: {
    key: 'received_weight',
    name: 'Received Weight',
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => {
      const unitOfMeasure = get(row, 'sell_unit_of_measure.name', '');
      const receivedWeight = sumOrderByWeightType(row, 'received_weight', props.packageWeights);
      const formattedReceivedWeight = accounting.formatNumber(receivedWeight, 0, ',');
      const value = receivedWeight ? `${formattedReceivedWeight} ${unitOfMeasure}` : '';
      return <Text>{value}</Text>;
    },
    formattedValue: row => {
      const unitOfMeasure = get(row, 'sell_unit_of_measure.name', '');
      const receivedWeight = sumOrderByWeightType(row, 'received_weight', props.packageWeights);
      const formattedReceivedWeight = accounting.formatNumber(receivedWeight, 0, ',');
      return receivedWeight ? `${formattedReceivedWeight} ${unitOfMeasure}` : '';
    },
  },
  buy_incoterms: {
    key: 'buy_incoterms',
    name: 'Buy INCO Terms',
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => <Text>{row.buy_incoterms}</Text>,
    formattedValue: row => get(row, 'buy_incoterms'),
  },
  status: {
    key: 'grinder_invoice_status',
    name: 'Status',
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => {
      const value = row.grinder_invoice_status;
      let displayValue = '';
      if (!value || value === Constants.INVOICE_STATUSES.TODO) {
        displayValue = 'To-do';
      } else {
        displayValue = startCase(value);
      }
      return (
        <Flex alignItems="center" height="100%">
          <Tag
            height="18px"
            {...getStatusColorConfig(value)}
            textTransform="uppercase"
            fontSize="12px"
            borderRadius="2px"
            paddingY="1px"
            paddingX="4px"
          >
            {displayValue}
          </Tag>
        </Flex>
      );
    },
    formattedValue: row => {
      const value = row.grinder_invoice_status;
      let displayValue = '';
      if (!value || value === Constants.INVOICE_STATUSES.TODO) {
        displayValue = 'To-do';
      } else {
        displayValue = startCase(value);
      }
      return displayValue;
    },
  },
  amended_weight: {
    key: 'amended_weight',
    name: 'Amended Weight*',
    editor: InputEditor,
    headerRenderer: HeaderRenderer,
    validator: row => {
      const value = get(row, 'amended_weight');
      const unitOfMeasure = get(row, 'sell_unit_of_measure.name', '');
      const text = value ? `${accounting.formatNumber(value, 0, ',')} ${unitOfMeasure}` : '';
      return text;
    },
    formatter: ({ row, ...rest }) => {
      const value = get(row, 'amended_weight');
      const unitOfMeasure = get(row, 'sell_unit_of_measure.name', '');
      const text = value ? `${accounting.formatNumber(value, 0, ',')} ${unitOfMeasure}` : '';
      return InputFormatter({ row, value: text, ...rest });
    },
    formattedValue: row => {
      const value = get(row, 'amended_weight');
      const unitOfMeasure = get(row, 'sell_unit_of_measure.name', '');
      return value ? `${accounting.formatNumber(value, 0, ',')} ${unitOfMeasure}` : '';
    },
  },
  invoice_based_on: {
    key: 'invoice_weight_type',
    name: 'Pricing Based On*',
    width: 150,
    headerRenderer: HeaderRenderer,
    validator: row => {
      const priceBucketData = {
        lock_date: row.bucket_price_lock_date,
        weight_type: row.bucket_weight_type_raw,
      };
      const weightType = determineWeightType(row, priceBucketData);
      const formattedWeightType = formatWeightType(weightType);
      return formattedWeightType;
    },
    formatter: ({ row, ...rest }) => {
      const priceBucketData = {
        lock_date: row.bucket_price_lock_date,
        weight_type: row.bucket_weight_type_raw,
      };
      const weightType = determineWeightType(row, priceBucketData);
      const formattedWeightType = formatWeightType(weightType);
      return InputFormatter({ row, value: formattedWeightType, ...rest });
    },
    editor: ({ row, onRowChange, editorPortalTarget }) => {
      const priceBucketData = {
        lock_date: row.bucket_price_lock_date,
        weight_type: row.bucket_weight_type_raw,
      };
      const weightType = determineWeightType(row, priceBucketData);
      return (
        <SelectEditor
          defaultValue={weightType}
          onChange={value => onRowChange({ ...row, invoice_weight_type: value }, true)}
          options={weightTypeOptions}
          editorPortalTarget={editorPortalTarget}
        />
      );
    },
    formattedValue: row => {
      const priceBucketData = {
        lock_date: row.bucket_price_lock_date,
        weight_type: row.bucket_weight_type_raw,
      };
      const weightType = determineWeightType(row, priceBucketData);
      return formatWeightType(weightType);
    },
  },
  invoice_based_on_read_only: {
    key: 'invoice_weight_type',
    name: 'Pricing Based On',
    width: 150,
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => TextFormatter(row.bucket_weight_type),
    formattedValue: row => get(row, 'bucket_weight_type'),
  },
  invoice_payment_due_date: {
    key: 'invoice_payment_due_date',
    name: 'Invoice Due Date*',
    editor: DatePickerEditor,
    editorOptions: {
      editOnClick: true,
    },
    headerRenderer: HeaderRenderer,
    validator: row => {
      return row.invoice_payment_due_date;
    },
    formatter: InputFormatter,
    width: 150,
    formattedValue: row => get(row, 'invoice_payment_due_date'),
  },
  invoice_payment_due_date_read_only: {
    key: 'invoice_payment_due_date',
    name: 'Invoice Due Date',
    width: 150,
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => {
      const value = get(row, 'bucket_tenor_end_date', '');
      return <Text>{value}</Text>;
    },
    formattedValue: row => get(row, 'bucket_tenor_end_date', ''),
  },
  packer_paid_date: {
    key: 'packer_paid_date',
    name: 'Packer Paid Date*',
    editor: DatePickerEditor,
    editorOptions: {
      editOnClick: true,
    },
    headerRenderer: HeaderRenderer,
    validator: row => {
      return row.packer_paid_date;
    },
    formatter: InputFormatter,
    width: 150,
    formattedValue: row => get(row, 'packer_paid_date'),
  },
  packer_paid_date_non_required: {
    key: 'packer_paid_date',
    name: 'Packer Paid Date',
    editor: DatePickerEditor,
    editorOptions: {
      editOnClick: true,
    },
    formatter: InputFormatter,
    width: 150,
    formattedValue: row => get(row, 'packer_paid_date'),
  },
  packer_paid_date_read_only: {
    key: 'packer_paid_date',
    name: 'Tenor Start Date',
    lookupKey: 'bucket_tenor_start_date',
    width: 150,
    headerRenderer: HeaderRenderer,
    formatter: ({ row, lookupKey }) => {
      const value = get(row, 'bucket_tenor_start_date') || '';
      return <Text>{value}</Text>;
    },
    formattedValue: row => get(row, 'bucket_tenor_start_date', ''),
  },
  calculated_price: {
    key: 'calculated_price',
    name: 'Calculated Invoice Price',
    width: 120,
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => {
      const value = get(row, 'pricing_details');
      return formatCalculatedPrice(value);
    },
    formattedValue: row => formatCalculatedPrice(get(row, 'pricing_details'), true),
  },
  price_lock_date: {
    key: 'bucket_price_lock_date',
    name: 'Price Lock Date',
    width: 150,
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => <Text>{row.bucket_price_lock_date}</Text>,
    formattedValue: row => get(row, 'bucket_price_lock_date'),
  },
  cos_date: {
    key: 'bucket_cos_date',
    name: 'COS Date',
    width: 150,
    headerRenderer: HeaderRenderer,
    formatter: ({ row }) => <Text>{row.bucket_cos_date}</Text>,
    formattedValue: row => get(row, 'bucket_cos_date'),
  },
});

export const configureColumns = (props, columnKeyList) => {
  const config = initializeColumnConfig(props);
  const columns = columnKeyList.map(key => config[key]).filter(Boolean);
  columns[1].name = props.internalPoLabel;
  columns.push({
    key: 'copy_action',
    name: 'Copy',
    headerRenderer: HeaderRenderer,
    formatter: formatterProps => <RowCopier {...formatterProps} cols={columns} />,
    width: 80,
  });
  return columns;
};

export function getCartConfig(props, selectedCart, grinder, invoiceType) {
  const grinderCountry = get(grinder, 'destination_country');
  const invoiceFlow = get(grinder, 'invoice_flow');
  switch (selectedCart) {
    case CARTS.invoiceCart:
    default: {
      if (['New Zealand', 'Australia'].includes(grinderCountry)) {
        return configureColumns(props, [
          'selector',
          'copy_action',
          'internal_po_number',
          'grinder_po_number',
          'grinder_name',
          'date_type',
          'po_weight',
          'shipped_weight',
          'received_weight',
          'remaining_weight',
          'buy_incoterms',
          'packer_paid_date',
          'invoice_based_on',
          'invoice_payment_due_date',
          'calculated_price',
        ]);
      }
      if (grinderCountry === 'USA') {
        return configureColumns(props, [
          'selector',
          'copy_action',
          'internal_po_number',
          'grinder_po_number',
          'grinder_name',
          'date_type',
          'po_weight',
          'shipped_weight',
          'received_weight',
          'remaining_weight',
          'buy_incoterms',
          'price_lock_date',
          'packer_paid_date_read_only',
          'invoice_based_on_read_only',
          'invoice_payment_due_date_read_only',
          'calculated_price',
        ]);
      }
      // Unfunded Asian Grinders
      if (invoiceFlow === 'prior_final') {
        if (invoiceType === 'prior') {
          return configureColumns(props, [
            'selector',
            'copy_action',
            'internal_po_number',
            'grinder_po_number',
            'grinder_name',
            'date_type',
            'po_weight',
            'shipped_weight',
            'received_weight',
            'remaining_weight',
            'buy_incoterms',
            'cos_date',
            'packer_paid_date_non_required',
            'invoice_based_on',
            'invoice_payment_due_date',
            'calculated_price',
          ]);
        }
        return configureColumns(props, [
          'selector',
          'copy_action',
          'internal_po_number',
          'grinder_po_number',
          'grinder_name',
          'date_type',
          'po_weight',
          'shipped_weight',
          'received_weight',
          'remaining_weight',
          'buy_incoterms',
          'cos_date',
          'packer_paid_date_read_only',
          'invoice_based_on',
          'invoice_payment_due_date_read_only',
          'calculated_price',
        ]);
      }
      // Funded Asian Grinders
      return configureColumns(props, [
        'selector',
        'copy_action',
        'internal_po_number',
        'grinder_po_number',
        'grinder_name',
        'date_type',
        'po_weight',
        'shipped_weight',
        'received_weight',
        'remaining_weight',
        'buy_incoterms',
        'cos_date',
        'packer_paid_date',
        'invoice_based_on',
        'invoice_payment_due_date',
        'calculated_price',
      ]);
    }
    case CARTS.priceCart: {
      return configureColumns(props, [
        'selector',
        'copy_action',
        'internal_po_number',
        'grinder_po_number',
        'grinder_name',
        'date_type',
        'po_weight',
        'shipped_weight',
        'received_weight',
        'remaining_weight',
        'buy_incoterms',
        'price_lock_date',
        'packer_paid_date',
        'invoice_based_on',
        'invoice_payment_due_date',
        'calculated_price',
      ]);
    }
  }
}
