import React, { Component } from 'react';
import ResponsiveOrdinalFrame from 'semiotic/lib/ResponsiveOrdinalFrame';
import BuyActivityPaneBody from './BuyActivityPaneBody';
import Card, { CardTitle, CardBodyGroup } from '../basic/Card';
import Loading from '../basic/Loading';
import { commify, formatMonetaryValue } from '../../functions';
import WatermarkText from './WatermarkText';
import { Box, Center, Select as ChakraSelect, Text, Wrap, HStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import './BuyVolumes.scss';

const chartLeftSpacing = 130;

const byOptions = [
  { label: 'Buy Volumes (by packer)', value: 'packer' },
  { label: 'Buy Volumes (by protein)', value: 'protein' },
  { label: 'Buy Volumes (by origin country)', value: 'source_country' },
];
const timeFrames = [
  { label: '24 hours', value: 1 },
  { label: '7 days', value: 7 },
  { label: '4 weeks', value: 28 },
  { label: '12 weeks', value: 84 },
  { label: '6 months', value: 182 },  
];
const currenciesOptions = ['USD', 'AUD', 'NZD'];
const unitsOfMeasureOptions = ['lbs', 'kgs'];

const timeFrameConfig = {
  name: 'Time Frame',
  stateKey: 'timeFrame',
  options: timeFrames,
};
const currencyConfig = {
  name: 'Currency',
  stateKey: 'displayCurrency',
  options: currenciesOptions,
};
const unitOfMeasureConfig = {
  name: 'Unit of Measure',
  stateKey: 'displayUnitOfMeasure',
  options: unitsOfMeasureOptions,
};

class BuyVolumes extends Component {
  state = {
    by: 'packer',
    timeFrame: 28,
    displayCurrency: 'USD',
    displayUnitOfMeasure: 'lbs',
  };

  componentDidMount() {
    const { getCurrencies, getUnitsOfMeasure, token } = this.props;
    this.getData();
    getCurrencies(token);
    getUnitsOfMeasure(token);
  }

  getData() {
    const { getBuyVolumes } = this.props;
    const { by, timeFrame, displayCurrency, displayUnitOfMeasure } = this.state;

    getBuyVolumes({
      by,
      days: timeFrame,
      displayCurrency,
      displayUnitOfMeasure,
    });
  }

  renderBuyVolumes() {
    const { buyVolumes } = this.props;
    if (buyVolumes.length === 0) {
      return (
        <Center height="356px">
          <WatermarkText>Cannot find buy volumes in that timeframe.</WatermarkText>
        </Center>
      );
    }

    const { displayUnitOfMeasure, displayCurrency } = this.state;
    const [totalVolume, totalCost] = buyVolumes.reduce(
      (acc, group) => {
        acc[0] += group.total_volume;
        acc[1] += group.total_cost;
        return acc;
      },
      [0, 0]
    );

    const sortedVolumes = [...buyVolumes].sort((a, b) => {
      if (a.total_volume < b.total_volume) {
        return 1;
      }
      if (a.total_volume > b.total_volume) {
        return -1;
      }
      return 0;
    });

    return (
      <Box mt="30px">
        <HStack fontSize="22px" fontWeight="bold" spacing="78px">
          <Text as="p">Total</Text>
          <Text as="p">
            {commify(Math.round(totalVolume))} {displayUnitOfMeasure}
          </Text>
          <Text as="p">{formatMonetaryValue(displayCurrency, totalCost)}</Text>
        </HStack>
        <Box position="relative" maxHeight="262px" overflowY="scroll" overflowX="visible">
          <ResponsiveOrdinalFrame
            data={sortedVolumes}
            responsiveWidth
            type={{
              type: 'bar',
              customMark: (d, i, rest) => {
                const { x, height, width } = rest;
                const formattedFigure = formatMonetaryValue(d.currency_code, d.total_cost);
                const textY = height / 2 + 5;
                return (
                  <>
                    <rect
                      x={x}
                      y={0}
                      width={width -20}
                      height={height}
                      style={{
                        fill: '#f6f6f6',
                        stroke: '#002c64',
                        strokeWidth: '2px',
                        rx: '3px',
                        ry: '3px',
                      }}
                    />
                    {width > 124 ? (
                      <>
                        <text x={8} y={textY} style={{ textAlign: 'center' }}>
                          <tspan style={{ fontSize: 14, fontWeight: 500 }}>{commify(d.total_volume)} </tspan>
                          <tspan style={{ fontSize: 8, fontWeight: 500 }}>{displayUnitOfMeasure} </tspan>
                          <tspan style={{ fontSize: 8, marginLeft: 10, marginRight: 10 }}>
                            ({commify((d.total_volume / totalVolume) * 100, 1)}%)
                          </tspan>
                        </text>
                        <text x={1 + width} y={textY}>
                          <tspan style={{ fontSize: 8, marginLeft: 10, marginRight: 10 }}>{d.currency_code} </tspan>
                          <tspan style={{ fontSize: 14, fontWeight: 500 }}>{formattedFigure}</tspan>
                        </text>
                      </>
                    ) : (
                      <text x={8 + width} y={textY}>
                        <tspan style={{ fontSize: 14, fontWeight: 500 }}>{commify(d.total_volume)} </tspan>
                        <tspan style={{ fontSize: 8, fontWeight: 500 }}>{displayUnitOfMeasure} </tspan>
                        <tspan style={{ fontSize: 8 }}>({commify((d.total_volume / totalVolume) * 100, 1)}%) </tspan>
                        <tspan style={{ fontSize: 8, marginLeft: 8 }}>{d.currency_code} </tspan>
                        <tspan style={{ fontSize: 14, fontWeight: 500 }}>{formattedFigure}</tspan>
                      </text>
                    )}
                  </>
                );
              },
            }}
            projection="horizontal"
            oAccessor="group_by"
            rAccessor="total_volume"
            oLabel={d => {
              return (
                <text
                  size="16"
                  x={-chartLeftSpacing + 5}
                  y="5"
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  {d.length <= 15 ? d : `${d.substring(0, 13)}...`}
                </text>
              );
            }}
            oPadding={16}
            pixelColumnWidth={36 + 16}
            style={{
              fill: '#f6f6f6',
              stroke: '#002c64',
              strokeWidth: '2px',
              rx: '3px',
              ry: '3px',
            }}
            margin={{
              left: chartLeftSpacing,
              top: 0,
              right: 90,
            }}
          />
        </Box>
      </Box>
    );
  }

  renderSelect(selectConfig) {
    const { stateKey, options } = selectConfig;

    return (
      <ChakraSelect
        borderRadius="4px"
        width="124px"
        cursor="pointer"
        value={this.state[stateKey]}
        onChange={e => {
          this.setState({ [stateKey]: e.target.value }, this.getData);
        }}
      >
        {options.map(opt => {
          let label;
          let value;
          if (opt.label && opt.value) {
            ({ value, label } = opt);
          } else {
            label = opt;
            value = opt;
          }
          return (
            <option key={value} value={value} disabled={value === 'PORK' ? 'disabled' : ''}>
              {label}
            </option>
          );
        })}
      </ChakraSelect>
    );
  }

  render() {
    const { requesting, error, buyVolumes, currencies, unitsOfMeasure } = this.props;

    const { by } = this.state;

    if (currencies) {
      const newCurrencies = [...new Set(currencies.map(p => p.value))];
      currencyConfig.options = newCurrencies;
    }

    if (unitsOfMeasure) {
      const newUnitsOfMeasure = [...new Set(unitsOfMeasure.map(u => u.name))];
      unitOfMeasureConfig.options = newUnitsOfMeasure;
    }

    return (
      <Card style={{ height: '100%' }}>
        <CardTitle>
          <Box className="buy-volumes-title">
            <ChakraSelect
              color="black"
              border="none"
              width="fit-content"
              cursor="pointer"
              fontWeight="bold"
              style={{
                paddingLeft: 0,
              }}
              value={by}
              onChange={e => {
                this.setState({ by: e.target.value }, this.getData);
              }}
            >
              {byOptions.map(byOption => {
                return (
                  <option key={byOption.value} value={byOption.value}>
                    {byOption.label}
                  </option>
                );
              })}
            </ChakraSelect>
          </Box>
        </CardTitle>
        <BuyActivityPaneBody>
          <CardBodyGroup>
            <Wrap spacing="20px" color="gray">
              <Box>
                <Text as="p" fontSize="14px" fontWeight="semibold">
                  Showing the last:
                </Text>
                {this.renderSelect(timeFrameConfig)}
              </Box>
              <Box>
                <Text as="p" fontSize="14px" fontWeight="semibold">
                  In:
                </Text>
                {this.renderSelect(currencyConfig)}
              </Box>
              <Box>
                <Text as="p" fontSize="14px" fontWeight="semibold">
                  And:
                </Text>
                {this.renderSelect(unitOfMeasureConfig)}
              </Box>
            </Wrap>
          </CardBodyGroup>
          {requesting && <Loading style={{ height: 'auto', width: 'auto', margin: 0, marginTop: 20 }} />}
          {error && <WatermarkText>Could not load buy volumes</WatermarkText>}
          {!requesting && !error && buyVolumes && <CardBodyGroup>{this.renderBuyVolumes()}</CardBodyGroup>}
        </BuyActivityPaneBody>
      </Card>
    );
  }
}

BuyVolumes.propTypes = {
  getCurrencies: PropTypes.func,
  getUnitsOfMeasure: PropTypes.func,
  getBuyVolumes: PropTypes.func,
  token: PropTypes.string,
  requesting: PropTypes.bool,
  error: PropTypes.string,
  buyVolumes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  currencies: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  unitsOfMeasure: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

export default BuyVolumes;
