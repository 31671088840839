import { createSelector } from '@reduxjs/toolkit';
import { selectTreasuryTimeGroupedExpectedVolumeData } from './selectTreasuryTimeGroupedData';
import { sum, group } from 'd3';

export const selectTreasuryExpectedVolumeData = createSelector(
  selectTreasuryTimeGroupedExpectedVolumeData,
  timeGrouped => {
    if (!timeGrouped) return null;

    const data = [];
    timeGrouped.forEach(year => {
      const newIntervals = year.intervals.map(interval => {
        const groupedByBeefType = group(interval.orders, order => order.beef_type);

        const volumeBreakdown = Array.from(groupedByBeefType.entries()).map(([beefType, typeOrders]) => {
          const hedged = sum(typeOrders, order => order.volume_hedged);

          const exposed = sum(typeOrders, order => order.volume_exposed);

          return {
            beefType,
            hedged,
            exposed,
          };
        });

        return {
          interval: `${interval.interval} ${interval.year}`,
          rawInterval: { year: interval.year, interval: interval.interval },
          volumeBreakdown,
        };
      });

      data.push(...newIntervals);
    });

    return data;
  }
);
