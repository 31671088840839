import { createSelector } from '@reduxjs/toolkit';
import { group } from 'd3';
import { selectMarketsDataRaw } from './selectBasicMarketData';

/**
 * Returns a structure with data required for the markets quality NCR vis
 */
export const selectMarketsQualityNcrData = createSelector(selectMarketsDataRaw, dataRaw => {
  if (!dataRaw) return null;
  const { ncrs } = dataRaw;

  // NCRs are already previous month's data.

  const groupedByCategory = group(ncrs, ncr => ncr.category);
  const groupBySubCategory = group(ncrs, ncr => ncr.subCategory);
  const groupByPackerPlant = group(ncrs, ncr => ncr.packer_plant_name);
  const categories = Array.from(groupedByCategory.entries()).map(([category, catNcrs]) => {
    return {
      label: category,
      count: catNcrs.length,
    };
  });

  const subCategories = Array.from(groupBySubCategory.entries()).map(([subCategory, catNcrs]) => {
    return {
      label: subCategory,
      count: catNcrs.length,
      category: catNcrs.length ? catNcrs[0].category : '',
    };
  });

  const packerPlants = Array.from(groupByPackerPlant.entries()).map(([packerPlant, catNcrs]) => {
    return {
      label: packerPlant,
      count: catNcrs.length,
    };
  });

  return {
    total: ncrs.length,
    categories,
    subCategories,
    packerPlants,
  };
});
