import React, { Component } from 'react';
import PackerOffers from '../../containers/packers/PackerOffers';
import PackerOfferForm from '../../containers/packers/PackerOfferForm';

class PackerInterface extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_type: 'offers',
    };
  }

  render() {
    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content admin">
          <div className="row">
            <div className="col-12">
              <div className="m-portlet m-portlet--full-height ">
                <div className="m-portlet__head">
                  <div className="m-portlet__head-tools">
                    <ul className="nav nav-tabs m-tabs m-tabs-line admin-tabs" role="tablist">
                      <li className={`nav-item m-tabs__item ${this.state.active_type === 'offers' ? 'active' : ''}`}>
                        <a className="nav-link m-tabs__link" onClick={() => this.setState({ active_type: 'offers' })}>
                          Offers
                        </a>
                      </li>
                      <li
                        className={`nav-item m-tabs__item ${
                          this.state.active_type === 'packer_offer_form' ? 'active' : ''
                        }`}
                      >
                        <a
                          className="nav-link m-tabs__link"
                          onClick={() => this.setState({ active_type: 'packer_offer_form' })}
                        >
                          Packer Offer Form
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="m-portlet__body">
                  <div className="m-section">
                    {this.state.active_type === 'offers' ? <PackerOffers /> : false}
                    {this.state.active_type === 'packer_offer_form' ? <PackerOfferForm /> : false}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PackerInterface;
