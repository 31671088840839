import React from 'react';
import { ScatterplotLayer, ArcLayer } from '@deck.gl/layers';
import { DeckGL, MapView } from 'deck.gl';
import { StaticMap } from 'react-map-gl';
import { COLORS_RGB } from '../../../../modules/colors';
import useDeckViewport from '../../../../modules/hooks/useDeckViewport';
import { SUPPLY_CHAIN_VIEW_MODE } from '../../../../slices/markets/marketsConfig';
import styles from './SupplyChainMap.module.css';

const MAPBOX_TOKEN = 'pk.eyJ1IjoiY2Zjb2pvbm8iLCJhIjoiY2toeWNhOXh3MGY0cDJ4b2RhMG54M3ExaSJ9.hlvEPe6nwdJ1a3z1dGB5bA';
const MAPBOX_BASEMAP_STYLE_ID = process.env.REACT_APP_MAPBOX_STYLE || 'mapbox://styles/mapbox/light-v9';

export default function SupplyChainMap(props) {
  const { marketSupplyChainData, controlState, onHoveredLocationChange, hoveredLocationId, activeMarket } = props;
  const { viewMode } = controlState;

  const initialBounds = React.useMemo(() => {
    return activeMarket.bounds;
  }, [activeMarket]);

  const { mapContainerRef, viewport, handleViewStateChange } = useDeckViewport(initialBounds);

  const orderSources = React.useMemo(() => {
    return viewMode === SUPPLY_CHAIN_VIEW_MODE.GRINDERS
      ? marketSupplyChainData.grinders
      : marketSupplyChainData.packerPlants;
  }, [marketSupplyChainData, viewMode]);

  const packerPlantToGrinderLineData = React.useMemo(() => {
    return orderSources
      .map(source => source.orders)
      .flat()
      .filter(d => d.path);
  }, [orderSources]);

  const packerPlantCirclesData = React.useMemo(() => {
    return marketSupplyChainData?.packerPlants?.filter(d => d.location) || [];
  }, [marketSupplyChainData]);

  const grinderCirclesData = React.useMemo(() => {
    return marketSupplyChainData.grinders.filter(d => d.location);
  }, [marketSupplyChainData]);

  const updateHovered = React.useCallback(
    hover => {
      if (!hover || !hover.object) return onHoveredLocationChange(null);
      const newId = hover.object.id;
      if (newId !== hoveredLocationId) {
        onHoveredLocationChange(hover.object.id);
      }
    },
    [onHoveredLocationChange, hoveredLocationId]
  );

  const layers = [
    // PackerPlant -> Grinder Lines
    new ArcLayer({
      id: 'packer-plant-to-grinder-layer',
      data: packerPlantToGrinderLineData,
      pickable: false,
      wrapLongitude: false,
      widthMinPixels: 2,
      greatCircle: true,
      // TODO: proper scales+
      getWidth: 1, // d => d.volume / 10_000,
      getHeight: 1,
      getSourcePosition: d => d.path[0],
      getTargetPosition: d => d.path[1],
      getSourceColor: d => {
        const selected = d.id === hoveredLocationId || d.from === hoveredLocationId;
        const a = hoveredLocationId === null || selected ? 85 : 20;
        return [0, 0, 0, a];
      },
      getTargetColor: d => {
        const selected = d.id === hoveredLocationId || d.from === hoveredLocationId;
        const a = hoveredLocationId === null || selected ? 85 : 20;
        return [0, 0, 0, a];
      },
      updateTriggers: {
        getSourceColor: hoveredLocationId,
        getTargetColor: hoveredLocationId,
      },
    }),
    // PackerPlants Circles
    new ScatterplotLayer({
      id: 'packer-plants-circles-layer',
      label: 'PackerPlant',
      data: packerPlantCirclesData,
      pickable: true,
      opacity: 1.0,
      stroked: false,
      filled: true,
      radiusScale: 6,
      radiusMinPixels: 5,
      radiusMaxPixels: 100,
      lineWidthMinPixels: 1,
      getPosition: d => d.location && d.location.geometry.coordinates,
      getRadius: d => d.totalVolume / 500,
      getFillColor: d => {
        const isConnectedToSelected = d.orders.some(grinder => grinder.id === hoveredLocationId);
        if (hoveredLocationId === null || hoveredLocationId === d.id || isConnectedToSelected) {
          return COLORS_RGB.PACKER_PLANT;
        }
        return [...COLORS_RGB.PACKER_PLANT, 100];
      },
      updateTriggers: {
        getFillColor: hoveredLocationId,
      },
      onHover: updateHovered,
    }),
    // Grinders Circles
    new ScatterplotLayer({
      id: 'grinders-circles-layer',
      label: 'Grinder',
      data: grinderCirclesData,
      pickable: true,
      opacity: 1.0,
      stroked: false,
      filled: true,
      radiusScale: 6,
      radiusMinPixels: 5,
      radiusMaxPixels: 100,
      lineWidthMinPixels: 1,
      getPosition: d => d.location && d.location.geometry.coordinates,
      getRadius: d => d.totalVolume / 5000,
      getFillColor: d => {
        const isConnectedToSelected = d.orders.some(packer_plant => packer_plant.id === hoveredLocationId);

        if (hoveredLocationId === null || hoveredLocationId === d.id || isConnectedToSelected) {
          return COLORS_RGB.GRINDER;
        }
        return [...COLORS_RGB.GRINDER, 100];
      },
      updateTriggers: {
        getFillColor: hoveredLocationId,
      },
      onHover: updateHovered,
    }),
  ];

  return (
    <div className={styles.mapContainer} ref={mapContainerRef}>
      {viewport && (
        <DeckGL
          viewState={viewport}
          layers={layers}
          onViewStateChange={handleViewStateChange}
          controller
          views={new MapView({ repeat: true })}
          getTooltip={({ object, layer }) => object && `${layer.props.label} - ${object.name}`}
        >
          <StaticMap mapboxApiAccessToken={MAPBOX_TOKEN} mapStyle={MAPBOX_BASEMAP_STYLE_ID} />
        </DeckGL>
      )}
    </div>
  );
}
