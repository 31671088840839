import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Heading,
  HStack,
  Button,
  VStack,
  Box,
  Text,
} from '@chakra-ui/react';
import { ReactComponent as OverviewOutlineIconSVG } from '../../../assets/overview-outline.svg';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { PODetails, StatusPriorityBadge } from '../Components';
import { getOr } from '../../../helpers';

const SectionCard = ({ children, title }) => {
  return (
    <Box backgroundColor="card.default" padding="16px 20px 34px">
      <Box borderBottomWidth="1px" paddingBottom="13px" marginBottom="16px">
        <Heading fontSize="16px">{title}</Heading>
      </Box>
      {children}
    </Box>
  );
};

SectionCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

const OverviewDrawer = props => {
  const { isOpen, onClose, overviewNCR } = props;

  const status = get(overviewNCR, 'status');
  const priority = get(overviewNCR, 'claim.priority', 'minor');

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent minWidth="803px" paddingX="51px">
        <DrawerCloseButton />
        <DrawerHeader padding="37px 0 0 0">
          <HStack spacing="10px" color="accent.one.default" borderBottomWidth="1px" paddingBottom="20px">
            <OverviewOutlineIconSVG />
            <Heading fontSize="16px" color="inherit">
              Overview
            </Heading>
            <Box marginLeft="auto !important">
              <Link to={`/ncr/${get(overviewNCR, 'recordNo')}`}>
                <Button colorScheme="gray" height="6px" color="#000000">
                  View NCR
                </Button>
              </Link>
            </Box>
          </HStack>
        </DrawerHeader>
        <DrawerBody overflowY="overlay" paddingX="0" paddingY="23px" fontWeight="400px">
          <HStack justifyContent="flex-end">
            {status ? <StatusPriorityBadge> {status.replace(/_/g, ' ')}</StatusPriorityBadge> : ''}
            {priority ? <StatusPriorityBadge> {priority.replace(/_/g, ' ')}</StatusPriorityBadge> : ''}
          </HStack>
          <VStack align="stretch" spacing="23px" marginTop="25px">
            <SectionCard title="PO Details">
              <PODetails overviewNCR={overviewNCR} spacing="34px" />
            </SectionCard>
            <SectionCard title="Details of Issue">
              <Text>{getOr(overviewNCR, 'ncrDetails.details', '-')}</Text>
            </SectionCard>
            <SectionCard title="Initial Investigation">
              <Text>{getOr(overviewNCR, 'ncrDetails.initialInvestigation.details', '-')}</Text>
            </SectionCard>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

OverviewDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  overviewNCR: PropTypes.objectOf(PropTypes.any),
};

export default OverviewDrawer;
