import { createSelector } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { selectQualitySortedPackerPlantsOptions } from '../../quality/selectors/selectQualityPackerPlantOptions';
import {
  selectQualityClaimsData,
  selectQualityClaimsStatus,
  selectQualityClaimsCount,
  selectQualityClaimsAmount,
} from './selectBasicDetailsClaimsData';
import { STATUS_OPTIONS } from '../qualityDetailsClaimsConfig';

const selectQualityClaimsBottomTenPackerPlants = createSelector(
  selectQualityClaimsData,
  selectQualitySortedPackerPlantsOptions,
  selectQualityClaimsStatus,
  selectQualityClaimsCount,
  selectQualityClaimsAmount,
  (qualityClaimsData, sortedPackerPlantsOptions, claimStatus, countMode, amountMode) => {
    let fieldName;
    if (!!countMode && !amountMode) {
      fieldName = 'count';
    }
    if (!countMode && !!amountMode) {
      fieldName = 'amount';
    }

    const statusMap = {
      [STATUS_OPTIONS.SUBMITTED_VS_FINALISED]: [STATUS_OPTIONS.SUBMITTED, STATUS_OPTIONS.FINALISED],
      [STATUS_OPTIONS.SUBMITTED_VS_REJECTED]: [STATUS_OPTIONS.SUBMITTED, STATUS_OPTIONS.REJECTED],
      [STATUS_OPTIONS.FINALISED_VS_REJECTED]: [STATUS_OPTIONS.FINALISED, STATUS_OPTIONS.REJECTED],
      [STATUS_OPTIONS.SUBMITTED]: [STATUS_OPTIONS.SUBMITTED],
      [STATUS_OPTIONS.REJECTED]: [STATUS_OPTIONS.REJECTED],
      [STATUS_OPTIONS.FINALISED]: [STATUS_OPTIONS.FINALISED],
      [STATUS_OPTIONS.OVERDUE]: [STATUS_OPTIONS.OVERDUE],
    };

    const filterdData = qualityClaimsData?.map(dataByDate => {
      const dataByStatus = statusMap[claimStatus]
        ?.map(s => dataByDate.byStatus.find(status => status.status === s))
        .filter(x => !!x);
      return {
        date: dataByDate.date,
        byStatus: dataByStatus,
      };
    });

    const d = filterdData?.map(x => {
      let total = x.byStatus[0] ? x.byStatus[0][fieldName] : 0;
      let rankingBreakdown = (x.byStatus[0]?.byPackerPlant || []).map(p => {
        const pac = sortedPackerPlantsOptions.find(ap => ap.id === p.packerPlantId);
        return {
          packerPlantId: pac?.id,
          volumeRatio: p[fieldName] && total > 0 ? p[fieldName] / total : 0,
        };
      });

      if (x.byStatus.length === 2) {
        const availPackerPlantsWithRatio = sortedPackerPlantsOptions.map(availP => {
          const valFirst = (x.byStatus[0]?.byPackerPlant || []).find(pp => availP.id === pp.packerPlantId);
          const valSecond = (x.byStatus[1]?.byPackerPlant || []).find(pp => availP.id === pp.packerPlantId);
          const fieldValFirst = valFirst && valFirst[fieldName];
          const fieldValSecond = valSecond && valSecond[fieldName];
          const ratio = fieldValSecond && fieldValFirst && fieldValFirst > 0 ? fieldValSecond / fieldValFirst : 0;
          return {
            ...availP,
            ratio,
          };
        });
        total = availPackerPlantsWithRatio.reduce((a, b) => a + b.ratio, 0);

        rankingBreakdown = (x.byStatus[0]?.byPackerPlant || []).map(p => {
          const pac = availPackerPlantsWithRatio.find(ap => ap.id === p.packerPlantId);
          return {
            packerPlantId: pac?.id,
            volumeRatio: pac?.ratio / total,
          };
        });
      }

      return {
        interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
        rankingBreakdown: rankingBreakdown.sort((a, b) => b.volumeRatio - a.volumeRatio),
      };
    });

    const packerPlantClaimsRatioMap = new Map();
    d?.forEach(interval =>
      interval.rankingBreakdown.forEach(p => {
        const volumeRatio = packerPlantClaimsRatioMap.get(p.packerPlantId);
        if (volumeRatio) {
          packerPlantClaimsRatioMap.set(p.packerPlantId, volumeRatio + p.volumeRatio);
        } else {
          packerPlantClaimsRatioMap.set(p.packerPlantId, p.volumeRatio);
        }
      })
    );

    const sortedPackerPlantIds = [...packerPlantClaimsRatioMap].sort((a, b) => b[1] - a[1]).map(a => a[0]);

    const sortedPackerPlants = [];

    sortedPackerPlantIds.forEach(ppId => {
      const packerPlant = sortedPackerPlantsOptions.find(pp => pp.id === ppId);
      if (packerPlant && !packerPlant.isPacker) {
        sortedPackerPlants.push(packerPlant);
      }
    });
    return sortedPackerPlants.slice(0, 10);
  }
);

export default selectQualityClaimsBottomTenPackerPlants;
