import React, { Component } from 'react';
import { connect } from 'react-redux';

import Optimizer from '../../containers/optimizer/Optimizer';
import Inventory from '../../containers/optimizer/Inventory';

import { fetchOptimizerData, loadOptimizerData } from '../../actions/actions_optimizer';

class ProcurementOptimizerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelected: 'optimizer',
    };
  }

  fetchOptimizer() {
    this.props.dispatch(loadOptimizerData(false));
    this.props.dispatch(fetchOptimizerData(this.props.token));
  }

  render() {
    let currentTab = false;
    switch (this.state.tabSelected) {
      case 'optimizer':
        currentTab = <Optimizer />;
        break;
      case 'inventory':
        currentTab = <Inventory />;
        break;
      default:
        break;
    }

    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="row">
            <div className="col-xl-12">
              <div className="m-portlet m-portlet--full-height optimizer">
                <div className="m-portlet__head">
                  <div className="m-portlet__head-tools">
                    <button onClick={this.fetchOptimizer.bind(this)} className="btn btn-default pull-right">
                      Refresh Data
                    </button>
                    <ul className="nav nav-tabs m-tabs m-tabs-line admin-tabs" role="tablist">
                      <li className={`nav-item m-tabs__item ${this.state.tabSelected === 'optimizer' ? 'active' : ''}`}>
                        <a
                          onClick={() => this.setState({ tabSelected: 'optimizer' })}
                          className={`nav-link m-tabs__link${this.state.tabSelected === 'optimizer' ? ' active' : ''}`}
                          data-toggle="tab"
                          href="#m_widget2_tab1_content"
                          role="tab"
                          aria-expanded="true"
                        >
                          Optimizer
                        </a>
                      </li>
                      <li
                        style={{ display: 'none' }}
                        className={`nav-item m-tabs__item ${this.state.tabSelected === 'inventory' ? 'active' : ''}`}
                      >
                        <a
                          onClick={() => this.setState({ tabSelected: 'inventory' })}
                          className={`nav-link m-tabs__link${this.state.tabSelected === 'inventory' ? ' active' : ''}`}
                          data-toggle="tab"
                          href="#m_widget2_tab2_content1"
                          role="tab"
                          aria-expanded="false"
                        >
                          Imported Inventory
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {currentTab}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.user.token,
    optimizer_data: state.optimizer_data,
  };
};

const ProcurementOptimizer = connect(mapStateToProps)(ProcurementOptimizerComponent);

export default ProcurementOptimizer;
