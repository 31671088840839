const schema = [
  {
    name: 'form_fields',
    displayName: 'Form Field',
    nested: false,
    systemIdField: 'id',
    displayIdField: 'value',
    deletable: true,
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    createMultiple: true,
    path: '/form_fields',
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'type',
        displayName: 'Type',
        dataType: 'text',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'value',
        displayName: 'Value',
        dataType: 'text',
        required: true,
        systemIdentifier: false,
      },
    ],
  },
];
export default schema;
