import get from 'lodash/get';
import { generateAndDownloadExcel } from '../../../../services/excel-export';
import { columns_poc } from '../../../../components/bulkUpdater/BulkUpdaterResults/exportReportConfig';
import moment from 'moment';
import Constants from '../../../../Constants';
import { fetchExportHistory } from '../../../../actions/actions_orders';

export function formatArray(item) {
  const list = Array.isArray(item) ? item : [item];
  return list.map(itm => itm?.value);
}

export function submitExport(formValues, token) {
  const {
    grinderPoNumber,
    internalPoNumber,
    vendorInvoiceNumber,
    vendorSoNumber,
    buyer,
    deliveryDateStart,
    deliveryDateEnd,
    createdDateStart,
    createdDateEnd,
    grinders,
    purchasingOffice,
    packers,
    packerPlants,
    inputProducts,
    productTypes,
    orderType,
    tags,
    bolNumber,
    containerNumber,
    bookingReferenceNumber,
    portMark,
    etdDateStart,
    etdDateEnd,
    etaDateEnd,
    etaDateStart,
    vesselName,
    voyageNumber,
    orderShipmentTypes,
    coldstore,
    missingDocType,
    limit,
  } = formValues;

  const searchFilters = {
    bol_number: bolNumber,
    booking_reference_number: bookingReferenceNumber,
    container_number: containerNumber,
    delivery_end_date: deliveryDateEnd,
    delivery_start_date: deliveryDateStart,
    etd_end_date: etdDateEnd,
    eta_end_date: etaDateEnd,
    etd_start_date: etdDateStart,
    eta_start_date: etaDateStart,
    vessel_name: vesselName,
    voyage_number: voyageNumber,
    internal_po_number: internalPoNumber,
    grinder_po_number: grinderPoNumber,
    grinder_uids: formatArray(grinders),
    input_product_uids: formatArray(inputProducts),
    missing_doc_type: missingDocType.value,
    order_type: orderType?.value, // investigate
    ordered_end_date: createdDateEnd,
    ordered_start_date: createdDateStart,
    orderShipmentTypes: formatArray(orderShipmentTypes),
    cold_store_ids: formatArray(coldstore),
    packer_ids: formatArray(packers),
    packer_invoice_number: vendorInvoiceNumber,
    packer_plant_ids: formatArray(packerPlants),
    packer_so_number: vendorSoNumber,
    product_types: formatArray(productTypes),
    purchasing_office: purchasingOffice?.value,
    shipmentPortMark: portMark,
    tags: formatArray(tags),
    user_id: buyer?.value,
    limit: limit,
  };
  return fetchExportHistory(searchFilters, token);
}

export function configureColumns(configList) {
  const configuredColumns = configList.reduce((agg, config) => {
    const configKeyPair = Object.entries(config)[0];
    const headerText = configKeyPair[0];
    const dataMap = configKeyPair[1];
    return [
      ...agg,
      {
        headerText,
        getter: data => {
          return get(data, dataMap, '');
        },
      },
    ];
  }, []);

  return configuredColumns;
}
function getLineSpecifications(line) {
  return line.specifications;
}

export function exportOrdersPOC(orders) {
  const data = orders.map(order => {
    return {
      delivery_date: order.delivery_date,
      delivery_time: order.scheduled_delivery_time?.substring(0, 5),
      creation_date: order.order_date,
      grinder_po: order.grinder_po_number,
      permit_num: order.permit_number,
      grinder: order.grinder?.name,
      day: moment(order.delivery_date).format('ddd'),
      health_cert: order.export_details?.health_certificate_number,
      bol_number: order.bill_of_lading?.bol_number,
      shipped_weight: order.bill_of_lading?.gross_shipped_weight,
      container_num: order.bill_of_lading?.container_number,
      container_temp: order.bill_of_lading?.container_temperature,
      export_num: order.bill_of_lading?.export_declaration_number,
      booking_ref: order.bill_of_lading?.booking_reference_number,
      seal_num: order.bill_of_lading?.seal_number,
      isf: order.export_details?.isf,
      depot: order.export_details?.container_depot,
      container_return: order.export_details?.container_return,
      container_gate: order.export_details?.container_gate_in_date,
      fmg_po: order.internal_po_number,
      lines: order.lines?.map(line => {
        return {
          line: {
            date_type: line.shipment ? 'Shipment' : 'Arrival',
            product: line.input_product?.name,
            expected_date: line.expected_production_date,
            quantity: line.buy_quantity,
            start_date: line.shipment ? order.shipment_date_start : order.arrival_date_start,
            end_date: line.shipment ? order.shipment_date_end : order.arrival_date_end,
            price_per_unit: line.price_per_unit,
            order_type: line.price_type,
            establishment: line.packer_plant?.name,
            establishment_id: line.packer_plant?.id,
            status: order.status,
            active: line.active,
            temp: line.frozen ? 'Frozen' : 'Chilled',
            product_description: line.input_product?.description,
            cl: line.input_product?.cl,
            line_desc:
              line.specifications.includes('fmg_angus') || line.specifications.includes('usda_angus')
                ? 'Angus'
                : 'Regular',
            specification: getLineSpecifications(line),
            sell_quantity: line.sell_quantity,
            cos_price: line.cos_price_per_unit,
            sell_price: line.sell_price_per_unit,
            recv_quantity: line.recv_quantity,
            remaining_weight: line.remaining_weight,
          },
        };
      }),
      voyages: order.voyage?.map(voyage => {
        return {
          voyage: {
            vessel: voyage?.vessel_name,
            port_of_loading: voyage.load_port?.name,
            port_of_discharge: voyage?.discharge_port?.name,
            voyage_num: voyage?.voyage_number,
            shipping_line: voyage?.shipping_line?.name,
            tranship_port: voyage?.tranship_port?.name,
            etd: voyage?.etd,
            eta: voyage?.eta,
          },
        };
      }),
      production_date: order.production_date,
      transport_cost: order.transport_cost_per_unit,
      shipment_date: order.shipment_date,
      week_ending: moment(order.delivery_date).endOf('week').add(1, 'day').format(Constants.DEFAULT_READ_DATE_FORMAT),
      vendor_so: order.packer_so_number,
      buy_uom: order.buy_unit_of_measure?.name,
      buy_currency: order.buy_currency,
      sell_uom: order.sell_unit_of_measure?.name,
      sell_currency: order.sell_currency,
      internal_comments: order.internal_comments,
      external_comments: order.external_comments,
      container: order.load_container_details,
      buy_inco: order.buy_incoterms,
      sell_inco: order.sell_incoterms,
      halal: order.halal,
      mtc: order.mtc,
      preferred_eta: order.preferred_eta,
      final_dest: order.final_destination,
      coldstore: order?.coldstore_details?.cold_store?.company_name,
    };
  });
  generateAndDownloadExcel('Purchase Orders', [
    {
      worksheetName: 'Purchase Orders',
      columnConfigs: configureColumns(columns_poc),
      data,
    },
  ]);
}
