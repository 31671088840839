import {
  Box,
  Flex,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Button,
  Center,
  VStack,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingGraphic from '../../assets/color-ring-loading.svg';
import TabListComponent from '../../components/core/TabList';
import Constants from '../../Constants';
import { selectCurrentUser } from '../../reducers/reducer_user';
import { generateAndDownloadExcel } from '../../services/excel-export';
import DataImportChooseData from './DataImportChooseData';
import DataImportPreview from './DataImportPreview';
import { ReactComponent as ErrorIconSVG } from '../../assets/error.svg';
import { ReactComponent as BigSuccessIconSVG } from '../../assets/big-success.svg';
import XLSX from 'xlsx';

const steps = {
  chooseData: 0,
  preview: 1,
  done: 2,
};
const responseStatus = {
  success: 0,
  error: 1,
};

export default () => {
  const { token } = useSelector(selectCurrentUser);

  const [axiosConfig, setAxiosConfig] = useState();

  // List of all the import data options
  const [dataImportList, setDataImportList] = useState();

  // Indicate the active tab could be: Choose Data, Preview or Done
  const [step, setStep] = useState(steps.chooseData);

  // Preview tabla data to show in Preview tab
  const [previewTableData, setPreviewTableData] = useState();

  // Preview summary tabla data to show in Preview tab
  const [summaryTableData, setSummaryTableData] = useState();

  // data that will be sent to the back
  const [bulkData, setBulkData] = useState();

  // the modal indicating submission
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [submitResult, setSubmitResult] = useState();

  const [uploadFileErrorMessage, setUploadFileErrorMessage] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const hiddenFileInput = React.useRef(null);

  const [selectedFiles, setSelectedFiles] = useState([]);

  // Ajax
  const loadDataImportConfigs = () =>
    axios.get(`${Constants.URL}data_import/config`, axiosConfig).then(response => {
      setDataImportList(response.data);
    });

  const loadSummaryTableData = tableBulkData => {
    const newBulkData = { ...tableBulkData, mode: 'preview' };
    axios.post(`${Constants.URL}data_import`, newBulkData, axiosConfig).then(response => {
      setSummaryTableData(response.data);
    });
  };

  const onSubmitImportFile = files => {
    const file = files[0];
    const fileTypes = ['xls', 'xlsx'];
    const extension = file.name.split('.').pop().toLowerCase();
    if (!fileTypes.includes(extension)) {
      setUploadFileErrorMessage('Only excel format files are allowed.');
      setStep(steps.chooseData);
      return;
    }
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const wsname = workbook.SheetNames[0];
      const sheet = workbook.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const cleanData = data.filter(d => d.length > 0);

      // Validations
      if (!cleanData.length) {
        setUploadFileErrorMessage('The file is empty.');
        setStep(steps.chooseData);
        return;
      }
      if (cleanData.length > 501) {
        setUploadFileErrorMessage('The file has more than 500 records.');
        setStep(steps.chooseData);
        return;
      }
      if (!dataImportList) {
        setUploadFileErrorMessage('The templates have not been loaded.');
        setStep(steps.chooseData);
        return;
      }
      const dataImport = dataImportList.find(di => di.name === wsname);
      if (!dataImport) {
        setUploadFileErrorMessage('The first sheet name of the template should match with one of the following templates: ' + dataImportList.map(t => t.name).join(', '));
        setStep(steps.chooseData);
        return;
      }

      const indexOfHeaderColumns = cleanData.findIndex(row => dataImport.config.columns.every(c => row.includes(c.name)));
      if (indexOfHeaderColumns === -1) {
        setUploadFileErrorMessage('The next columms are missing in the file: ' + dataImport.config.columns.map(c => c.name).join(', '));
        setStep(steps.chooseData);
        return;
      }

      const colummsIndexMap = dataImport.config.columns.reduce((o, c) => {
        o[c.key] = cleanData[indexOfHeaderColumns].findIndex(col => col === c.name)
        return o
      }, {});

      const items = cleanData
        .slice(indexOfHeaderColumns + 1)
        .map(row =>
          Object.keys(colummsIndexMap).reduce((o, key) => {
            const newObj = { ...o };
            newObj[key] = row[colummsIndexMap[key]] || '';
            return newObj;
          }, {})
        );
      setPreviewTableData({
        columns: dataImport.config.columns.map(col => {
          return {
            ...col,
            headerRenderer: () => (
              <Text fontWeight="700" fontSize="14px">
                {col.name}
              </Text>
            ),
          };
        }),
        rows: items,
        disableSelect: true,
      });
      const newBulkData = {
        dataImportId: dataImport.id,
        items,
      };
      setBulkData({ ...newBulkData });
      loadSummaryTableData({ ...newBulkData });
    };
    reader.readAsBinaryString(file);
    setUploadFileErrorMessage('');
    setStep(steps.preview);
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    setAxiosConfig({
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      data: '{}',
    });
    loadDataImportConfigs();
  }, [token]);

  const onClickDownloadTemplate = di => {
    const columns = di.config.columns.reduce((list, c) => {
      const { name, key } = c;
      const column = {};
      column[name] = key;
      return { ...list, ...column };
    }, {});
    generateAndDownloadExcel(di.name, [
      {
        worksheetName: di.name,
        columnConfigs: Object.entries(columns).reduce(
          (list, [name, value]) => [...list, { headerText: name, getter: data => data[value] }],
          []
        ),
        data: [],
      },
    ]);
  };

  const openSubmissionModal = () => setShowSubmissionModal(true);

  const closeSubmissionModal = () => setShowSubmissionModal(false);

  const moveToChooseDataTab = clearSelectedFiles => {
    setPreviewTableData(null);
    setSummaryTableData(null);
    setBulkData(null);
    setSubmitResult(null);
    setIsSubmitting(false);
    closeSubmissionModal();
    setStep(steps.chooseData);
    if (clearSelectedFiles) setSelectedFiles([]);
  };

  const moveToDoneTab = () => setStep(steps.done);

  /* Promoting */
  const onPromotingImport = e => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = event => {
      axios
        .post(`${Constants.URL}data_import/config/promoting`, event.target.result, axiosConfig)
        .then(() => loadDataImportConfigs());
    };
  };

  const onPromotingExport = () =>
    axios({
      url: `${Constants.URL}data_import/config/promoting`,
      method: 'GET',
      responseType: 'blob',
      ...axiosConfig,
    }).then(response => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `export-data-import-${new Date().toISOString()}.json`); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

  const onSubmitBulkData = () => {
    const newBulkData = { ...bulkData, mode: 'execute' };
    openSubmissionModal(true);
    setIsSubmitting(true);
    setErrorMessage('');
    axios
      .post(`${Constants.URL}data_import`, newBulkData, axiosConfig)
      .then(() => {
        setSubmitResult(responseStatus.success);
        moveToDoneTab();
      })
      .catch(r => {
        if (r.response.data.description[0] !== undefined) {
          setErrorMessage(r.response.data.description[0]);
        }
        setSubmitResult(responseStatus.error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const wasSuccessful = submitResult === responseStatus.success;
  const wasError = submitResult === responseStatus.error;

  return (
    <Box>
      <Tabs index={step}>
        <Flex bg="#EBF0FF" py="20px" px="40px">
          <TabListComponent
            tabList={[{ label: 'Choose Data' }, { label: 'Preview' }, { label: 'Done' }]}
            activeTabIndex={step}
          />
        </Flex>
        <Box maxW="1257px" marginLeft="auto" marginRight="auto" pb="20px">
          <TabPanels>
            <TabPanel paddingX={0} paddingY="36.75px">
              <DataImportChooseData
                dataImportList={dataImportList}
                onClickDownloadTemplate={onClickDownloadTemplate}
                onSubmitImportFile={onSubmitImportFile}
                uploadFileErrorMessage={uploadFileErrorMessage}
                setUploadFileErrorMessage={setUploadFileErrorMessage}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            </TabPanel>
            <TabPanel paddingX={0} paddingY="36.75px">
              <DataImportPreview
                previewTableData={previewTableData}
                summaryTableData={summaryTableData}
                onClickBackButton={() => moveToChooseDataTab()}
                onClickConfirmButton={onSubmitBulkData}
              />
            </TabPanel>
            <TabPanel paddingX={0} paddingY="36.75px">
              <DataImportPreview
                previewTableData={previewTableData}
                summaryTableData={summaryTableData}
                // onClickFinishButton={moveToChooseDataTab}
                onClickFinishButton={() => moveToChooseDataTab()}
              />
            </TabPanel>
          </TabPanels>
        </Box>
      </Tabs>
      <Modal size="xl" isOpen={showSubmissionModal} onClose={closeSubmissionModal} isCentered>
        <ModalOverlay />
        <ModalContent minWidth="600px">
          <ModalHeader fontSize="20px" borderBottomWidth="1px">
            Import Wizard
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center flexDirection="column" minHeight="344px">
              <Text fontSize="18px" fontWeight="700" marginBottom="29px">
                {isSubmitting && 'Submitting Changes'}
                {wasSuccessful && 'The operation was successful.'}
                {wasError && 'Please try again'}
              </Text>
              {isSubmitting && <Img src={LoadingGraphic} />}
              {wasSuccessful && <BigSuccessIconSVG />}
              {wasError && (
                <>
                  <ErrorIconSVG />
                  <Text fontSize="16px" fontWeight="400" mt="10px" marginTop="35px">
                    Operation Failed due to an unexpected error.
                    <br /> Please contact administrator for more details.
                  </Text>
                  {errorMessage && <Box mt="10px">{errorMessage}</Box>}
                </>
              )}
            </Center>
          </ModalBody>
          <ModalFooter borderTopWidth="1px">
            {wasSuccessful && (
              <ModalActionButton onClick={() => moveToChooseDataTab(true)}>Back to Import Wizard</ModalActionButton>
            )}
            {wasError && <ModalActionButton onClick={closeSubmissionModal}>Back to Import Wizard</ModalActionButton>}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <VStack marginBottom="80px" alignItems="end" hidden={!/[?&]promoting$/.test(location.search)}>
        <Box>
          <Button colorScheme="actionPrimary" onClick={onPromotingExport} marginRight="16px">
            Export
          </Button>
          <Button
            colorScheme="actionPrimary"
            onClick={() => {
              hiddenFileInput.current.value = '';
              hiddenFileInput.current.click();
            }}
            marginRight="16px"
          >
            Import
          </Button>
          <input type="file" ref={hiddenFileInput} onChange={onPromotingImport} style={{ display: 'none' }} />
        </Box>
      </VStack>
    </Box>
  );
};

const ModalActionButton = ({ children, ...rest }) => (
  <Button
    colorScheme="actionPrimary"
    variant="outline"
    fontSize="16px"
    height="40px"
    width="204px"
    fontWeight="900"
    {...rest}
  >
    {children}
  </Button>
);

ModalActionButton.propTypes = {
  children: PropTypes.node.isRequired,
};
