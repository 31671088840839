import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import BuyPerformanceDetailsKPI from './BuyPerformanceDetailsKPI';

class BuyPerformanceKPI extends Component {
  constructor(props) {
    super(props);
    this.selectBuyPerformanceMonth = this.selectBuyPerformanceMonth.bind(this);

    this.state = {
      buy_performance: [],
      detailBuyPerformance: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.fmg_buy_performance) {
      const buy_performance = [];
      nextProps.fmg_buy_performance.forEach((buy_perf, index) => {
        buy_performance.push({
          date: buy_perf.date,
          fmg: buy_perf.value,
          usda: nextProps.usda_buy_performance[index].value,
        });
      });

      this.setState(
        {
          buy_performance,
        },
        () => {
          let latestDate = false;
          buy_performance.forEach(function (buy_perf) {
            if (!latestDate || moment(buy_perf.date).isAfter(moment(latestDate))) {
              latestDate = buy_perf.date;
            }
          });
          const index = buy_performance.length ? buy_performance.length - 1 : 0;
          this.selectBuyPerformanceMonth(index);
        }
      );
    }
  }

  selectBuyPerformanceMonth(index) {
    this.setState({ detailBuyPerformance: false });
    if (this.state.buy_performance && this.state.buy_performance.length) {
      const datasets = [];
      const monthLabel = BuyPerformanceKPI.getLabel(this.state.buy_performance[index].date);

      datasets.push({
        label: `${monthLabel} Performance`,
        backgroundColor: 'rgba(236, 134, 79, 1)',
        borderColor: 'rgba(255, 255, 255, 1)',
        borderWidth: 1,
        data: [this.state.buy_performance[index].fmg - this.state.buy_performance[index].usda],
      });
      const chartData = {
        type: 'bar',
        labels: [monthLabel],
        datasets,
      };
      this.setState({
        detailBuyPerformance: { chartData, text: monthLabel },
      });
    }
  }

  clearDetails = () => {
    this.setState({ detailBuyPerformance: false });
  };

  static getLabel(date) {
    const monthName = moment(date).format('MMM');
    const year = moment(date).format('YY');

    return `${year}/${monthName}`;
  }

  render() {
    const labels = [];

    let lineChartData = {};
    if (this.state.buy_performance.length) {
      const lineDatasets = [
        {
          label: 'Buy Performance',
          borderColor: 'rgba(235, 100, 103, 1)',
          backgroundColor: 'rgba(235, 100, 103, 1)',
          fill: false,
          data: [],
          yAxisID: 'y-axis-1',
        },
        {
          label: 'USDA',
          borderColor: 'rgba(44, 51, 61, 1)',
          backgroundColor: 'rgba(44, 51, 61, 1)',
          fill: false,
          data: [],
          yAxisID: 'y-axis-2',
        },
      ];

      this.state.buy_performance.forEach(buy_perf => {
        labels.push(BuyPerformanceKPI.getLabel(buy_perf.date));
        lineDatasets[0].data.push(buy_perf.fmg.toFixed(4));
        lineDatasets[1].data.push(buy_perf.usda.toFixed(4));
      });
      lineChartData = {
        labels,
        datasets: lineDatasets,
      };
    }

    const buyPerformanceChartOptions = {
      data: lineChartData,
      options: {
        responsive: true,
        title: {
          display: false,
          text: 'Buy Performance vs USDA',
          fontSize: 20,
        },
        onClick: (e, activeElements) => {
          if (activeElements[0]) {
            this.selectBuyPerformanceMonth(activeElements[0]._index.toString());
          }
        },
        scales: {
          yAxes: [
            {
              type: 'linear',
              display: true,
              position: 'left',
              id: 'y-axis-1',
              ticks: {
                max: 1.9,
                min: 1.2,
                callback: (value, index, values) => `$${value.toFixed(4)}`,
              },
            },
            {
              type: 'linear',
              display: true,
              position: 'right',
              id: 'y-axis-2',
              ticks: {
                max: 1.9,
                min: 1.2,
                callback: (value, index, values) => `$${value.toFixed(4)}`,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: (value, index, values) => `$${value.yLabel.toFixed(4)}`,
          },
        },
      },
    };

    return (
      <div className="m-portlet__body">
        <div className="row" style={{ marginTop: '30px' }}>
          <div style={{ width: '650px', paddingLeft: '20px' }}>
            <h3>Buy Performance vs USDA</h3>
            <div style={{ paddingLeft: '40px' }}>
              <Line {...buyPerformanceChartOptions} />
            </div>
          </div>
          <BuyPerformanceDetailsKPI details={this.state.detailBuyPerformance} clearDetails={this.clearDetails} />
        </div>
      </div>
    );
  }
}

export default BuyPerformanceKPI;
