import Constants from '../Constants';

export const BALANCE_SHEET_FETCHED = 'BALANCE_SHEET_FETCHED';
export const BALANCE_SHEET_ERROR = 'BALANCE_SHEET_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadBalanceSheet(balanceSheet) {
  return {
    type: BALANCE_SHEET_FETCHED,
    payload: balanceSheet,
  };
}

export function errorBalanceSheet(error) {
  return {
    type: BALANCE_SHEET_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchBalanceSheet(grinderUid, startDate, endDate, token) {
  return dispatch => {
    const queryString = `start_date=${startDate}&end_date=${endDate}`;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}balance_sheet/chart/grinders/${grinderUid}?${queryString}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorBalanceSheet))
      .then(json => dispatch(loadBalanceSheet(json)))
      .catch(error => dispatch(errorBalanceSheet(error)));
  };
}
