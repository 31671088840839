import { connect } from 'react-redux';
import PurchaseOrderComponent from '../../components/orders/PurchaseOrder';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

const mapStateToProps = state => {
  return {
    ...findEntitiesInState(state),
    orders: state.orders.orders,
    pending_orders: state.orders.pending_orders,
    user_order_history: state.orders.user_order_history,
    latestOrders: state.orders.latestOrders,
    token: state.user.token,
    pendingLoaded: state.orders.pendingLoaded,
    poFormPendingLoaded: state.orders.poFormPendingLoaded,
    fastLoaded: state.orders.fastLoaded,
    historyLoaded: state.orders.historyLoaded,
    user: state.user,
    edit_mode: state.edit_mode,
    users: state.users,
    endUsers: state.end_users,
    configs: state.configs,
  };
};

const PurchaseOrder = connect(mapStateToProps)(PurchaseOrderComponent);

export default PurchaseOrder;
