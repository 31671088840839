import axios from 'axios';
import { parse as parseDate } from 'date-fns';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import queryString from 'query-string';
import Constants from '../Constants';
import { dateStringToEpoch } from '../modules/order-utils';

const BASE_URL = `${Constants.URL}analytic-dashboard`;
const GENERIC_FORECAST_CATEGORY_URL = `${Constants.URL}generic_forecast_category`;

// query params available are effective_start_date, effective_end_date, forecast_start_date, forecast_end_date
const treasury_expected_volume_url = `${GENERIC_FORECAST_CATEGORY_URL}/expected_volume/forecasts`;
const treasury_forecast_volume_url = `${GENERIC_FORECAST_CATEGORY_URL}/risk_management_volume/forecasts`;
const usda_blend_76_fresh_cl_prices_url = `${Constants.URL}market-prices?is_usda=true&cl=76&is_daily=true&is_fresh=true&is_derived=true&is_blend=true`;
const treasury_forecast_fmg_price_url = `${GENERIC_FORECAST_CATEGORY_URL}/fmg_price/forecasts`;

// TODO: remove these. we want dates in our standard d/M/yy format.
const yearMonthDateToTime = dateString => parseDate(dateString, 'yyyy-MM-dd', new Date()).getTime();

const dayMonthYearToTime = dateString => parseDate(dateString, 'd/M/yy', new Date()).getTime();

export default async function fetchTreasuryDataFixture(fetchParameters) {
  const { activeMarket, timePeriod } = fetchParameters;

  // Only showing 12 months worth of data due to too much data performance problem.
  // We need to optimise backend SQL to aggregate data before sending it to browser.
  const current_date = moment().format('YYYY-MM-DD');
  const start_date = `${moment().subtract(12, 'months').format('YYYY')}-01-01`;
  const query = queryString.stringify({
    delivery_start_date: start_date,
    delivery_end_date: current_date,
    country_tab: activeMarket,
    product_type: Constants.PRODUCT_TYPE_LIST.BEEF,
  });
  const ordersDataPromise = axios.get(`${BASE_URL}/treasury?${query}`);

  // volume_expected should be the forecasted volume of product,
  // in either kilograms or pounds, with the unit indicated in a volume_units field
  const forecastDataPromise = axios.get(treasury_forecast_volume_url);

  // Buy Performance Data is assembled from Treasury Price Forecast data, and USDA data.
  const usdaBlend76FreeshCLDataPromise = axios.get(usda_blend_76_fresh_cl_prices_url);
  const treasuryForecastFmgPriceDataPromise = axios.get(treasury_forecast_fmg_price_url);

  const expectedVolumeDataPromise = axios.get(treasury_expected_volume_url);
  // ----------------------------------------------------------------------------

  // We're assuming we get text dates from the API here (or otherwise NON TIMEZONED data)
  // It's important we arent timezoned so that the correct date displays no matter where we
  // are viewing from
  const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const ordersDataRaw = await ordersDataPromise;
  const ordersData = orderBy(
    ordersDataRaw.data.map(raw => ({
      ...raw,
      // Turn dates into integers.
      date_received: dateStringToEpoch(raw.date_received),
      order_type: capitalize(raw.order_type),
    })),
    ['date_received'],
    ['asc']
  );

  // -------------------------------------
  // RISK MANAGEMENT FORECAST DATA
  // -------------------------------------
  const forecastDataResponse = await forecastDataPromise;
  const forecastDataRaw = get(forecastDataResponse, 'data') || [];
  const forecastData = forecastDataRaw.map(raw => ({
    // Turn dates into integers.
    date: dateStringToEpoch(raw.effective_date),
    order_type: Constants.ORDER_TYPES[get(raw, 'forecast_type.name')],
    volume_expected: get(raw, 'likely'),
    volume_units: get(raw, 'forecast_type.unit_of_measure'),
  }));

  // -------------------------------------
  // BUY PERFORMANCE DATA
  // -------------------------------------
  const usdaBlend76FreshCLResponse = await usdaBlend76FreeshCLDataPromise;
  const treasuryForecastFmgPriceResponse = await treasuryForecastFmgPriceDataPromise;
  const currentMonth = moment().format('YYYY-MM');

  const forecastByDateMap = treasuryForecastFmgPriceResponse.data.reduce((agg, curr) => {
    const forecastEffectiveMonth = moment(curr.effective_date, 'YYYY-MM-DD').format('YYYY-MM');
    return {
      ...agg,
      [forecastEffectiveMonth]: curr,
    };
  }, {});

  const forecastByDateEntries = Object.entries(forecastByDateMap);

  const buyPerformanceData = orderBy(
    forecastByDateEntries.reduce((agg, [forecastMonth, forecastRaw]) => {
      const buyPerformanceMetric = {
        fmg_price: forecastRaw.likely,
        fmg_high: forecastRaw.high,
        fmg_low: forecastRaw.low,
        // Turn dates into integers.
        date: dateStringToEpoch(forecastRaw.effective_date),
        date_string: forecastRaw.effective_date,
      };

      // Find matching USDA data for the same month:
      const matches = usdaBlend76FreshCLResponse.data.filter(
        usdaRaw => moment(usdaRaw.effective_date).format('YYYY-MM') === forecastMonth
      );

      // If USDA data is found:
      if (!isEmpty(matches)) {
        const buyPerformanceMetrics = matches.map(match => ({
          ...buyPerformanceMetric,
          usda_price: match.price / 100,
        }));

        return [...agg, ...buyPerformanceMetrics];
      }
      return [...agg, buyPerformanceMetric];
    }, []),
    ['date']
  );

  // -------------------------------------
  // EXPECTED VOLUME DATA
  // -------------------------------------
  const expectedVolumeDataRaw = await expectedVolumeDataPromise;
  const expectedVolumeGroupedByDate = groupBy(expectedVolumeDataRaw.data, 'effective_date');

  const expectedVolumeData = Object.entries(expectedVolumeGroupedByDate).reduce((agg, raw) => {
    // raw is ['2020-12-01', [{}, {}, {}, {}]]
    raw[1].map(val => {
      const volume_key = get(val, 'forecast_type.name').includes('hedged') ? 'volume_hedged' : 'volume_exposed';
      const beefType = Constants.BEEF_TYPE_BY_FORECAST_NAME[get(val, 'forecast_type.name')];
      const effectiveDateIntime = yearMonthDateToTime(raw[0]);

      if (beefType === 'Lean Beef') {
        const existing_index = agg.findIndex(d => d.date === effectiveDateIntime && d.beef_type === 'Lean Beef');
        // for a given date and lean type if the dct already exists, just append volume to it
        if (existing_index !== -1 && get(val, 'forecast_type.name').includes('lean')) {
          agg[existing_index][volume_key] = val.likely;
        } else {
          agg.push({
            date: effectiveDateIntime,
            beef_type: 'Lean Beef',
            [volume_key]: val.likely,
            volume_units: get(val, 'forecast_type.unit_of_measure'),
          });
        }
      } else {
        const existing_index = agg.findIndex(d => d.date === effectiveDateIntime && d.beef_type === 'Fat Beef');
        if (existing_index !== -1 && get(val, 'forecast_type.name').includes('fat')) {
          agg[existing_index][volume_key] = val.likely;
        } else {
          agg.push({
            date: effectiveDateIntime,
            beef_type: 'Fat Beef',
            [volume_key]: val.likely,
            volume_units: get(val, 'forecast_type.unit_of_measure'),
          });
        }
      }
    });
    return agg;
  }, []);

  return {
    orders: ordersData,
    forecast: forecastData,
    buyPerformance: buyPerformanceData,
    expectedVolume: expectedVolumeData,
  };
}

/*
 * Moved to annotations-all.js
 */

// const getAnnotationsKey = ({ timeScale, activeProtein, activeMarket }) =>
//   `${activeMarket}-${activeProtein}-${timeScale}`;

// /**
//  * This function should fetch the annotations for the treasury page,
//  * for a given market, and time interval (monthly/quartlery/yearly)
//  * @param {*} fetchParameters
//  */
// export async function requestTreasuryAnnotations(fetchParameters) {
//   // Make a unique key for the combination of timescale, protein and active market
//   const storageKey = getAnnotationsKey(fetchParameters);

//   // -----------------------------------
//   // Replace this section with API calls instead of local storage mocks.
//   const annotationsStr = localStorage.getItem(storageKey);
//   if (annotationsStr) {
//     return JSON.parse(annotationsStr);
//   } else {
//     return null;
//   }
//   // -----------------------------------
// }

// export async function saveTreasuryAnnotations(fetchParameters, newAnnotations) {
//   // Make a unique key for the combination of timescale, protein and active market
//   const storageKey = getAnnotationsKey(fetchParameters);
//   // -----------------------------------
//   // Replace this section with API calls instead of local storage mocks.
//   const stringified = JSON.stringify(newAnnotations);
//   localStorage.setItem(storageKey, stringified);
//   // -----------------------------------
// }
