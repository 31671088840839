import Constants from '../Constants';

export const fetchTheme = async () => {
  try {
    const header = new Headers({
      'Content-Type': 'application/json',
    });
    const response = await fetch(`${Constants.URL}theme`, {
      method: 'GET',
      headers: header,
    });
    if (response.status !== 200) return null;
    const apiResponse = await response.json();
    localStorage.setItem('theme_id', apiResponse.id);
    return { name: apiResponse.name, ...apiResponse.data };
  } catch (error) {
    return null;
  }
};

export const fetchThemes = async () => {
  try {
    const accessToken = localStorage.getItem('boabUser');
    if (!accessToken) {
      return '';
    }
    const json = JSON.parse(accessToken);
    const { token } = json;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    const response = await fetch(`${Constants.URL}themes`, {
      method: 'GET',
      headers: header,
    });
    if (response.status !== 200) return null;
    const apiResponse = await response.json();
    return apiResponse;
  } catch (error) {
    return null;
  }
};

export const fetchThemeById = async id => {
  try {
    const accessToken = localStorage.getItem('boabUser');
    if (!accessToken) {
      return '';
    }
    const json = JSON.parse(accessToken);
    const { token } = json;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    const response = await fetch(`${Constants.URL}themes/${id}`, {
      method: 'GET',
      headers: header,
    });
    if (response.status !== 200) return null;
    const apiResponse = await response.json();
    localStorage.setItem('theme_id', apiResponse.id);
    return { name: apiResponse.name, ...apiResponse.data };
  } catch (error) {
    return null;
  }
};

export const createOrUpdateTheme = async data => {
  try {
    const accessToken = localStorage.getItem('boabUser');
    if (!accessToken) {
      return '';
    }
    const json = JSON.parse(accessToken);
    const { token } = json;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    const response = await fetch(`${Constants.URL}theme`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: header,
    });
    if (response.status !== 200) return null;
    const apiResponse = await response.json();
    return apiResponse.data;
  } catch (error) {
    return null;
  }
};

export const updateTheme = async data => {
  try {
    const accessToken = localStorage.getItem('boabUser');
    if (!accessToken) {
      return '';
    }
    const json = JSON.parse(accessToken);
    const { token } = json;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    const themeId = localStorage.getItem('theme_id');
    const body = {
      name: data.name,
      data,
    };
    delete body.data.name;
    const response = await fetch(`${Constants.URL}themes/${themeId}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: header,
    });
    if (response.status !== 200) return null;
    const apiResponse = await response.json();
    return apiResponse.data;
  } catch (error) {
    return null;
  }
};

export const updateActiveTheme = async themeId => {
  try {
    const accessToken = localStorage.getItem('boabUser');
    if (!accessToken) {
      return '';
    }
    const json = JSON.parse(accessToken);
    const { token } = json;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    const body = {
      is_active: true,
    };

    const response = await fetch(`${Constants.URL}themes/${themeId}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: header,
    });
    if (response.status !== 200) return null;
    const apiResponse = await response.json();
    return apiResponse.data;
  } catch (error) {
    return null;
  }
};

export const deleteTheme = async themeId => {
  try {
    const accessToken = localStorage.getItem('boabUser');
    if (!accessToken) {
      return '';
    }
    const json = JSON.parse(accessToken);
    const { token } = json;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    const response = await fetch(`${Constants.URL}themes/${themeId}`, {
      method: 'DELETE',
      headers: header,
    });
    if (response.status !== 200) return null;
    const apiResponse = await response.json();
    return apiResponse.data;
  } catch (error) {
    return null;
  }
};
