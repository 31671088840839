import { createSelector } from '@reduxjs/toolkit';
import { group } from 'd3';
import { format, getQuarter, getYear } from 'date-fns';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { selectTreasuryDetailsTimeRange } from '../../treasury-details/treasuryDetailsReducers';
import { TREASURY_TIME_INTERVAL } from '../treasuryConfig';
import { selectTreasuryDataRaw, selectTreasuryTimeScale } from './selectTreasuryBasicData';

// Return a key to identify grouping for different timescales
export const timeGroupingFuncs = {
  [TREASURY_TIME_INTERVAL.MONTHLY]: date => format(date, 'MMM'),
  [TREASURY_TIME_INTERVAL.QUARTERLY]: date => `Q${getQuarter(date)}`,
};

export const timeGroupingSegments = {
  [TREASURY_TIME_INTERVAL.MONTHLY]: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  [TREASURY_TIME_INTERVAL.QUARTERLY]: ['Q1', 'Q2', 'Q3', 'Q4'],
};

const applyMonthlyFilter = (data, year) => {
  const currentyear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const filteredOrders = data.filter(d =>
    year < currentyear ? moment().month(d.interval).format('M') >= currentMonth : d
  );
  return filteredOrders;
};

export function createIntervals(yearString, yearOrders, timeScale) {
  const year = parseInt(yearString, 10);
  const ordersByInterval = group(yearOrders, row => timeGroupingFuncs[timeScale](row.date_received || row.date));

  const segments = get(timeGroupingSegments, timeScale) || [];

  const intervals = segments.map(intervalString => {
    const intervalOrders = ordersByInterval.get(intervalString) || [];
    const interval = parseInt(intervalString, 10) || intervalString;

    return {
      interval,
      year,
      orders: intervalOrders,
    };
  });

  let filteredIntervals = [];
  filteredIntervals = timeScale === TREASURY_TIME_INTERVAL.MONTHLY ? applyMonthlyFilter(intervals, year) : intervals;

  return {
    year,
    intervals: filteredIntervals,
  };
}

export const getDates = () => {
  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  return {
    oneYearAgo: oneYearAgo.getTime(),
    oneYearFromNow: oneYearFromNow.getTime(),
  };
};

const getTimeFilteredData = (data, timeScale) => {
  let dataFiltered = [];
  if (timeScale === TREASURY_TIME_INTERVAL.MONTHLY) {
    const { oneYearAgo, oneYearFromNow } = getDates();
    dataFiltered = data.filter(
      d =>
        (d.date_received > oneYearAgo || d.date > oneYearAgo) &&
        (d.date_received < oneYearFromNow || d.date < oneYearFromNow)
    );
  } else {
    dataFiltered = data;
  }

  return dataFiltered;
};

/**
 * Returns data for the treasury page, grouped by the time scale
 * It is an array of year objects, with property "intervals" containing the
 * finer time intervals. This interval is selectable.
 */
export const selectTreasuryTimeGroupedData = createSelector(
  selectTreasuryDataRaw,
  selectTreasuryTimeScale,
  selectTreasuryDetailsTimeRange,
  (treasuryData, timeScale, timeRange) => {
    if (!treasuryData) return null;

    const { orders } = treasuryData;
    let ordersFiltered = getTimeFilteredData(orders, timeScale);
    ordersFiltered = ordersFiltered.filter(
      d =>
        (d.date_received > timeRange[0] || d.date > timeRange[0]) &&
        (d.date_received < timeRange[1] || d.date < timeRange[1])
    );

    const yearGrouped = group(ordersFiltered, order => getYear(order.date_received));

    const dataList = Array.from(yearGrouped.entries()).map(([yearString, yearOrders]) => {
      const intervals = createIntervals(yearString, yearOrders, timeScale);
      return intervals;
    });

    const sortedDataList = sortBy(dataList, 'year');
    return sortedDataList;
  }
);

/**
 * Returns actual data and forecast data for the treasury page, grouped by the time scale
 * It is an array of year objects, with property "intervals" containing the
 * finer time intervals. This interval is selectable.
 */
export const selectTreasuryTimeGroupedDataWithForecast = createSelector(
  selectTreasuryDataRaw,
  selectTreasuryTimeScale,
  selectTreasuryDetailsTimeRange,
  (treasuryData, timeScale, timeRange) => {
    if (!treasuryData) return null;

    const { orders, forecast } = treasuryData;

    let ordersFiltered = getTimeFilteredData(orders, timeScale);

    ordersFiltered = ordersFiltered.filter(
      d =>
        (d.date_received > timeRange[0] || d.date > timeRange[0]) &&
        (d.date_received < timeRange[1] || d.date < timeRange[1])
    );
    const forecastTimeFiltered = getTimeFilteredData(forecast, timeScale);
    // TODO -- Ignore Frozen Usage forecast data for now, as there are no orders
    // with the order type "Frozen Usage" in BOAB at the moment.
    const forecastTypeFiltered = forecastTimeFiltered.filter(fcst => fcst.order_type !== 'Frozen Usage');

    const tmp = ordersFiltered.concat(forecastTypeFiltered);

    const yearGrouped = group(tmp, order => getYear(order.date_received || order.date));

    return Array.from(yearGrouped.entries()).map(([yearString, yearOrders]) => {
      const intervals = createIntervals(yearString, yearOrders, timeScale);
      return intervals;
    });
  }
);

/**
 * Returns data for the treasury page, grouped by the time scale
 * It is an array of year objects, with property "intervals" containing the
 * finer time intervals. This interval is selectable.
 */
export const selectTreasuryTimeGroupedExpectedVolumeData = createSelector(
  selectTreasuryDataRaw,
  selectTreasuryTimeScale,
  (expectedVolumeData, timeScale) => {
    if (!expectedVolumeData) return null;

    const { expectedVolume } = expectedVolumeData;
    const expectedVolumeFiltered = getTimeFilteredData(expectedVolume, timeScale);

    const yearGrouped = group(expectedVolumeFiltered, order => getYear(order.date));

    return Array.from(yearGrouped.entries()).map(([yearString, yearOrders]) => {
      const year = parseInt(yearString, 10);
      const ordersByInterval = group(yearOrders, row => timeGroupingFuncs[timeScale](row.date_received || row.date));

      const intervals = Array.from(ordersByInterval.entries()).map(([intervalString, intervalOrders]) => {
        const interval = parseInt(intervalString, 10) || intervalString;

        return {
          interval,
          year,
          orders: intervalOrders,
        };
      });

      return {
        year,
        intervals,
      };
    });
  }
);
