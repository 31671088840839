const schema = [
  {
    name: 'strategy_type',
    displayName: 'Strategy Type',
    nested: false,
    systemIdField: 'id',
    displayIdField: 'name',
    path: '/strategy-types',
    deletable: true,
    hidden: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'hidden',
        required: false,
        systemIdentifier: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        dataType: 'shorttext',
        required: true,
        systemIdentifier: false,
      },
    ],
  },
];

export default schema;
