import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

class MarketDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="m-portlet__body">
        <div className="row" style={{ marginTop: '30px', height: '350px' }}>
          <div style={{ paddingTop: '30px', width: '400px', marginLeft: '30px' }}>
            <h4>Markets Comparison</h4>
            <div className="row">
              <div
                className="col-sm-6"
                style={{
                  borderRight: 'thin #ccc solid',
                }}
              >
                <table className="markets-table">
                  <thead>
                    <tr>
                      <th>Domestic</th>
                      <th>Today</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>---</span>
                      </td>
                      <td>
                        <span>---</span>
                      </td>
                    </tr>
                    <tr>
                      <td>92-94 cl</td>
                      <td>
                        <span>---</span>
                      </td>
                    </tr>
                    <tr>
                      <td>90 cl</td>
                      <td>
                        <span className="markets-red">$2.2174</span>
                      </td>
                    </tr>
                    <tr>
                      <td>85 cl</td>
                      <td>
                        <span>---</span>
                      </td>
                    </tr>
                    <tr>
                      <td>81 cl</td>
                      <td>
                        <span>---</span>
                      </td>
                    </tr>
                    <tr>
                      <td>73 cl</td>
                      <td>
                        <span>---</span>
                      </td>
                    </tr>
                    <tr>
                      <td>65 cl</td>
                      <td>
                        <span>---</span>
                      </td>
                    </tr>
                    <tr>
                      <td>50 cl</td>
                      <td>
                        <span className="markets-green">$0.7451</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-sm-6">
                <table className="markets-table">
                  <thead>
                    <tr>
                      <th>Today</th>
                      <th>*Imported</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className="markets-yellow">$2.3685</span>
                      </td>
                      <td>95 cl</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="markets-yellow">$2.3047</span>
                      </td>
                      <td>93 cl</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="markets-yellow">$2.1898</span>
                      </td>
                      <td>90 cl</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="markets-yellow">$2.0722</span>
                      </td>
                      <td>85 cl</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="markets-yellow">$1.9113</span>
                      </td>
                      <td>80 cl</td>
                    </tr>
                    <tr>
                      <td>
                        <span>---</span>
                      </td>
                      <td>
                        <span>---</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>---</span>
                      </td>
                      <td>
                        <span>---</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>---</span>
                      </td>
                      <td>
                        <span>---</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MarketDashboard;
