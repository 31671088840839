import {
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Tag,
    Th,
    Thead,
    Tr,
    VStack,
    RadioGroup,
    Radio
} from '@chakra-ui/react';
import { Alert } from 'antd';
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Datetime from 'react-datetime';
import * as Yup from 'yup';
import Select from '../../../../components/basic/SelectField';


export default ({ setFeeRates, feeRates, selectedFee, setSelectedFee, grinders, packerPlants, endUsers, uoms, productTypes, currencies, incoterms, countries, ports, productCategories, coldStores, onSaveFeeRate, onRemoveFeeRate }) => {

    const [packerPlantLabelMap, setPackerPlantLabelMap] = useState()
    const [grinderLabelMap, setGrinderLabelMap] = useState()
    const [endUsersLabelMap, setEndUsersLabelMap] = useState()
    const [uomLabelMap, setUomLabelMap] = useState()
    const [coldStoresLabelMap, setColdStoresLabelMap] = useState()
    const [portsLabelMap, setPortsLabelMap] = useState()
    const [columns] = useState([...selectedFee.lookup_columns, ...selectedFee.state_columns].reduce((o, c) => {
        const colParts = c.split(':')
        o[colParts.length > 1 ? colParts[1] : colParts[0]] = true;
        return o;
    }, {}))



    useEffect(() => {
        if (!packerPlants) {
            return;
        }
        setPackerPlantLabelMap(packerPlants.reduce((o, c) => {
            o[c.value] = c.label
            return o;
        }, {}))
    }, [packerPlants])

    useEffect(() => {
        if (!grinders) {
            return;
        }
        setGrinderLabelMap(grinders.reduce((o, c) => {
            o[c.value] = c.label
            return o;
        }, {}))
    }, [grinders])

    useEffect(() => {
        if (!endUsers) {
            return;
        }
        setEndUsersLabelMap(endUsers.reduce((o, c) => {
            o[c.value] = c.label
            return o;
        }, {}))
    }, [endUsers])

    useEffect(() => {
        if (!uoms) {
            return;
        }
        setUomLabelMap(uoms.reduce((o, c) => {
            o[c.value] = c.label
            return o;
        }, {}))
    }, [uoms])

    useEffect(() => {
        if (!coldStores) {
            return;
        }
        setColdStoresLabelMap(coldStores.reduce((o, c) => {
            o[c.value] = c.label
            return o;
        }, {}))
    }, [coldStores])

    useEffect(() => {
        if (!ports) {
            return;
        }
        setPortsLabelMap(ports.reduce((o, c) => {
            o[c.value] = c.label
            return o;
        }, {}))
    }, [ports])

    const { errors, handleChange, handleBlur, setFieldValue, handleSubmit, setValues, resetForm, values, touched, isValid } = useFormik({
        initialValues: {},
        validationSchema: Yup.object().shape({
            end_user_id: columns.end_user_id ? Yup.number().nullable().required('Required') : undefined,
            packer_plant_id: columns.packer_plant_id ? Yup.string().nullable().required('Required') : undefined,
            grinder_uid: columns.grinder_uid ? Yup.string().nullable().required('Required') : undefined,
            product_type: columns.product_type ? Yup.string().nullable().required('Required') : undefined,
            buy_incoterms: columns.buy_incoterms ? Yup.array().nullable().required('Required') : undefined,
            sell_incoterms: columns.sell_incoterms ? Yup.array().nullable().required('Required') : undefined,
            buy_unit_of_measure_id: columns.buy_unit_of_measure_id ? Yup.number().nullable().required('Required') : undefined,
            sell_unit_of_measure_id: columns.sell_unit_of_measure_id ? Yup.number().nullable().required('Required') : undefined,
            buy_currency: columns.buy_currency ? Yup.string().nullable().required('Required') : undefined,
            sell_currency: columns.sell_currency ? Yup.string().nullable().required('Required') : undefined,
            origin_country: columns.origin_country ? Yup.string().nullable().required('Required') : undefined,
            destination_country: columns.destination_country ? Yup.string().nullable().required('Required') : undefined,
            cold_store_id: columns.cold_store_id ? Yup.number().nullable().required('Required') : undefined,
            product_category: columns.product_category ? Yup.string().nullable().required('Required') : undefined,
            is_grinder_unfunded: columns.is_grinder_unfunded ? Yup.boolean().nullable().required('Required') : undefined,
            cold_store_unit_of_measure_id: columns.cold_store_unit_of_measure_id ? Yup.number().nullable().required('Required') : undefined,
            cold_store_currency: columns.cold_store_currency ? Yup.string().nullable().required('Required') : undefined,
            from_date: columns.from_date ? Yup.date().nullable().required('Required') : undefined,
            to_date: columns.to_date ? Yup.date().nullable().required('Required') : undefined,
            rate: columns.rate ? Yup.number().nullable().required('Required') : undefined,
            free_days: columns.free_days ? Yup.number().nullable().required('Required') : undefined,
            roll_period_days: columns.roll_period_days ? Yup.number().nullable().required('Required') : undefined,
            destination_port: columns.destination_port ? Yup.number().nullable().required('Required') : undefined,
            origin_port: columns.origin_port ? Yup.number().nullable().required('Required') : undefined,
        
        }),
        onSubmit: (data) => {
            onSaveFeeRate(data)
            closeFormModal()
        },
    });
    const [showFormModal, setShowFormModal] = useState(false)

    const openFormModal = (feeRate = {}) => {
        resetForm()
        setValues(feeRate)
        setShowFormModal(true)
    }

    const closeFormModal = () => setShowFormModal(false)

    const removeFeeRate = () => {
        if (confirm("Are you sure you want to delete this item?") == true) {
            onRemoveFeeRate(values)
            closeFormModal()
        }
    }


    return (
        <Box>
            <VStack marginBottom="80px" alignItems="end">
                <Button colorScheme="actionPrimary" onClick={() => openFormModal()}>New</Button>
            </VStack>
            <HStack marginBottom="16px">
                <Button type="button" colorScheme="actionPrimary" onClick={() => { setSelectedFee(null); setFeeRates(null) }} marginRight="32px">
                    Back
                </Button>
                <p>{selectedFee.label}</p>
            </HStack>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Id</Th>
                            {columns.end_user_id && <Th>End user</Th>}
                            {columns.packer_plant_id && <Th>Packer plant</Th>}
                            {columns.grinder_uid && <Th>Grinder</Th>}
                            {columns.product_type && <Th>Product type</Th>}
                            {columns.buy_incoterms && <Th>Buy Incoterms</Th>}
                            {columns.sell_incoterms && <Th>Sell Incoterms</Th>}
                            {columns.buy_unit_of_measure_id && <Th>Buy UOM</Th>}
                            {columns.sell_unit_of_measure_id && <Th>Sell UOM</Th>}
                            {columns.buy_currency && <Th>Buy Currency</Th>}
                            {columns.sell_currency && <Th>Sell Currency</Th>}
                            {columns.origin_country && <Th>Origin Country</Th>}
                            {columns.destination_country && <Th>Destination Country</Th>}
                            {columns.cold_store_id && <Th>Cold Store</Th>}
                            {columns.product_category && <Th>Product category </Th>}
                            {columns.is_grinder_unfunded && <Th>Is Grinder Unfunded</Th>}
                            {columns.cold_store_unit_of_measure_id && <Th>Store UOM</Th>}
                            {columns.cold_store_currency && <Th>Store Currency</Th>}
                            {columns.origin_port && <Th>Origin Port</Th>}
                            {columns.destination_port && <Th>Destination Port</Th>}
                            {columns.from_date && <Th>From date</Th>}
                            {columns.to_date && <Th>To date</Th>}
                            {columns.rate && <Th>Rate</Th>}
                            {columns.free_days && <Th>Free Days</Th>}
                            {columns.roll_period_days && <Th>Roll Period Days</Th>}
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {feeRates &&
                            feeRates.map(item => (
                                <Tr key={item.id}>
                                    <Td>{item.id}</Td>
                                    {columns.end_user_id && <Td>{endUsersLabelMap?.[item.end_user_id]}</Td>}
                                    {columns.packer_plant_id && <Td>{packerPlantLabelMap?.[item.packer_plant_id]}</Td>}
                                    {columns.grinder_uid && <Td>{grinderLabelMap?.[item.grinder_uid]}</Td>}
                                    {columns.product_type && <Td>{item.product_type}</Td>}
                                    {columns.buy_incoterms && <Td>{(item.buy_incoterms || []).map(item => <Tag key={item} marginRight="4px">{item}</Tag>)}</Td>}
                                    {columns.sell_incoterms && <Td>{(item.sell_incoterms || []).map(item => <Tag key={item} marginRight="4px">{item}</Tag>)}</Td>}
                                    {columns.buy_unit_of_measure_id && <Td>{uomLabelMap?.[item.buy_unit_of_measure_id]}</Td>}
                                    {columns.sell_unit_of_measure_id && <Td>{uomLabelMap?.[item.sell_unit_of_measure_id]}</Td>}
                                    {columns.buy_currency && <Td>{item.buy_currency}</Td>}
                                    {columns.sell_currency && <Td>{item.sell_currency}</Td>}
                                    {columns.origin_country && <Td>{item.origin_country}</Td>}
                                    {columns.destination_country && <Td>{item.destination_country}</Td>}
                                    {columns.cold_store_id && <Td>{coldStoresLabelMap?.[item.cold_store_id]}</Td>}
                                    {columns.product_category && <Td>{item.product_category}</Td>}
                                    {columns.is_grinder_unfunded && <Td>{item.is_grinder_unfunded === true && 'Yes'}{item.is_grinder_unfunded === false && 'No'}</Td>}
                                    {columns.cold_store_unit_of_measure_id && <Td>{uomLabelMap?.[item.cold_store_unit_of_measure_id]}</Td>}
                                    {columns.cold_store_currency && <Td>{item.cold_store_currency}</Td>}
                                    {columns.origin_port && <Td>{portsLabelMap?.[item.origin_port]}</Td>}
                                    {columns.destination_port && <Td>{portsLabelMap?.[item.destination_port]}</Td>}
                                    {columns.from_date && <Td>{item.from_date}</Td>}
                                    {columns.to_date && <Td>{item.to_date}</Td>}
                                    <Td>{columns.rate && item.rate}</Td>
                                    <Td>{columns.free_days && item.free_days}</Td>
                                    <Td>{columns.roll_period_days && item.roll_period_days}</Td>
                                    <Td>
                                        <Button onClick={() => openFormModal(item)}>
                                            Edit
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Modal size="xl" isOpen={showFormModal} onClose={closeFormModal}>
                <ModalOverlay />
                <ModalContent minHeight="200px">
                    <form onSubmit={handleSubmit}>
                        <ModalHeader>Fee rate</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Stack spacing="10">
                                {columns.end_user_id && (
                                    <FormControl id="end_user_id" isInvalid={errors.end_user_id && touched.end_user_id}>
                                        <FormLabel>End user *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'end_user_id',
                                                value: values.end_user_id && endUsersLabelMap && ({ id: values.end_user_id, label: endUsersLabelMap?.[values.end_user_id] }),
                                                onChange: endUser => {
                                                    setFieldValue('end_user_id', endUser?.value)
                                                },
                                                options: endUsers || []
                                            }}
                                        />
                                        {errors.end_user_id && touched.end_user_id && <Alert style={{ marginTop: '5px' }} message={errors.end_user_id} type="error" />}
                                    </FormControl>
                                )}
                                {columns.packer_plant_id && (
                                    <FormControl id="packer_plant_id" isInvalid={errors.packer_plant_id && touched.packer_plant_id}>
                                        <FormLabel>Packer plant *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'packer_plant_id',
                                                value: values.packer_plant_id && packerPlantLabelMap && ({ id: values.packer_plant_id, label: packerPlantLabelMap?.[values.packer_plant_id] }),
                                                onChange: packerPlant => {
                                                    setFieldValue('packer_plant_id', packerPlant?.value)
                                                },
                                                options: packerPlants || []
                                            }}
                                        />
                                        {errors.packer_plant_id && touched.packer_plant_id && <Alert style={{ marginTop: '5px' }} message={errors.packer_plant_id} type="error" />}
                                    </FormControl>
                                )}
                                {columns.grinder_uid && (
                                    <FormControl id="grinder_uid" isInvalid={errors.grinder_uid && touched.grinder_uid}>
                                        <FormLabel>Grinder *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'grinder_uid',
                                                value: values.grinder_uid && grinderLabelMap && ({ id: values.grinder_uid, label: grinderLabelMap?.[values.grinder_uid] }),
                                                onChange: grinder => {
                                                    setFieldValue('grinder_uid', grinder?.value)
                                                },
                                                options: grinders || []
                                            }}
                                        />
                                        {errors.grinder_uid && touched.grinder_uid && <Alert style={{ marginTop: '5px' }} message={errors.grinder_uid} type="error" />}
                                    </FormControl>
                                )}
                                {columns.product_type && (
                                    <FormControl id="product_type" isInvalid={errors.product_type && touched.product_type}>
                                        <FormLabel>Product type *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'product_type',
                                                value: ({ id: values.product_type, label: values.product_type }),
                                                onChange: product_type => {
                                                    setFieldValue('product_type', product_type?.value)
                                                },
                                                options: productTypes
                                            }}
                                        />
                                        {errors.product_type && touched.product_type && <Alert style={{ marginTop: '5px' }} message={errors.product_type} type="error" />}
                                    </FormControl>
                                )}
                                {columns.buy_incoterms && (
                                    <FormControl id="buy_incoterms" isInvalid={errors.buy_incoterms && touched.buy_incoterms}>
                                        <FormLabel>Buy Incoterms *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: false,
                                                removeSelected: true,
                                                isMulti: true,
                                                name: 'buy_incoterms',
                                                value: values.buy_incoterms && values.buy_incoterms.map(item => ({ label: item, value: item })),
                                                onChange: buy_incoterms => {
                                                    setFieldValue(
                                                        'buy_incoterms',
                                                        buy_incoterms.map(c => c.value)
                                                    )
                                                },
                                                options: incoterms || []
                                            }}
                                        />
                                        {errors.buy_incoterms && touched.buy_incoterms && <Alert style={{ marginTop: '5px' }} message={errors.buy_incoterms} type="error" />}
                                    </FormControl>
                                )}
                                {columns.sell_incoterms && (
                                    <FormControl id="sell_incoterms" isInvalid={errors.sell_incoterms && touched.sell_incoterms}>
                                        <FormLabel>Sell Incoterms *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: false,
                                                removeSelected: true,
                                                isMulti: true,
                                                name: 'sell_incoterms',
                                                value: values.sell_incoterms && values.sell_incoterms.map(item => ({ label: item, value: item })),
                                                onChange: sell_incoterms => {
                                                    setFieldValue(
                                                        'sell_incoterms',
                                                        sell_incoterms.map(c => c.value)
                                                    )
                                                },
                                                options: incoterms || []
                                            }}
                                        />
                                        {errors.sell_incoterms && touched.sell_incoterms && <Alert style={{ marginTop: '5px' }} message={errors.sell_incoterms} type="error" />}
                                    </FormControl>
                                )}
                                {columns.buy_unit_of_measure_id && (
                                    <FormControl id="buy_unit_of_measure_id" isInvalid={errors.buy_unit_of_measure_id && touched.buy_unit_of_measure_id}>
                                        <FormLabel>Buy UOM *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'buy_unit_of_measure_id',
                                                value: values.buy_unit_of_measure_id && uomLabelMap && ({ id: values.buy_unit_of_measure_id, label: uomLabelMap?.[values.buy_unit_of_measure_id] }),
                                                onChange: buy_unit_of_measure_id => {
                                                    setFieldValue('buy_unit_of_measure_id', buy_unit_of_measure_id?.value)
                                                },
                                                options: uoms || []
                                            }}
                                        />
                                        {errors.buy_unit_of_measure_id && touched.buy_unit_of_measure_id && <Alert style={{ marginTop: '5px' }} message={errors.buy_unit_of_measure_id} type="error" />}
                                    </FormControl>
                                )}
                                {columns.sell_unit_of_measure_id && (
                                    <FormControl id="sell_unit_of_measure_id" isInvalid={errors.sell_unit_of_measure_id && touched.sell_unit_of_measure_id}>
                                        <FormLabel>Sell UOM *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'sell_unit_of_measure_id',
                                                value: values.sell_unit_of_measure_id && uomLabelMap && ({ id: values.sell_unit_of_measure_id, label: uomLabelMap?.[values.sell_unit_of_measure_id] }),
                                                onChange: sell_unit_of_measure_id => {
                                                    setFieldValue('sell_unit_of_measure_id', sell_unit_of_measure_id?.value)
                                                },
                                                options: uoms || []
                                            }}
                                        />
                                        {errors.sell_unit_of_measure_id && touched.sell_unit_of_measure_id && <Alert style={{ marginTop: '5px' }} message={errors.sell_unit_of_measure_id} type="error" />}
                                    </FormControl>
                                )}
                                {columns.buy_currency && (
                                    <FormControl id="buy_currency" isInvalid={errors.buy_currency && touched.buy_currency}>
                                        <FormLabel>Buy Currency *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'buy_currency',
                                                value: values.buy_currency && ({ id: values.buy_currency, label: values.buy_currency }),
                                                onChange: buy_currency => {
                                                    setFieldValue('buy_currency', buy_currency?.value)
                                                },
                                                options: currencies || []
                                            }}
                                        />
                                        {errors.buy_currency && touched.buy_currency && <Alert style={{ marginTop: '5px' }} message={errors.buy_currency} type="error" />}
                                    </FormControl>
                                )}
                                
                                
                                {columns.sell_currency && (
                                    <FormControl id="sell_currency" isInvalid={errors.sell_currency && touched.sell_currency}>
                                        <FormLabel>Sell Currency *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'sell_currency',
                                                value: values.sell_currency && ({ id: values.sell_currency, label: values.sell_currency }),
                                                onChange: sell_currency => {
                                                    setFieldValue('sell_currency', sell_currency?.value)
                                                },
                                                options: currencies || []
                                            }}
                                        />
                                        {errors.sell_currency && touched.sell_currency && <Alert style={{ marginTop: '5px' }} message={errors.sell_currency} type="error" />}
                                    </FormControl>
                                )}
                                {columns.origin_country && (
                                    <FormControl id="origin_country" isInvalid={errors.origin_country && touched.origin_country}>
                                        <FormLabel>Origin Country *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'origin_country',
                                                value: values.origin_country && ({ id: values.origin_country, label: values.origin_country }),
                                                onChange: origin_country => {
                                                    setFieldValue('origin_country', origin_country?.value)
                                                },
                                                options: countries || []
                                            }}
                                        />
                                        {errors.origin_country && touched.origin_country && <Alert style={{ marginTop: '5px' }} message={errors.origin_country} type="error" />}
                                    </FormControl>
                                )}
                                {columns.destination_country && (
                                    <FormControl id="destination_country" isInvalid={errors.destination_country && touched.destination_country}>
                                        <FormLabel>Destination Country *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'destination_country',
                                                value: values.destination_country && ({ id: values.destination_country, label: values.destination_country }),
                                                onChange: destination_country => {
                                                    setFieldValue('destination_country', destination_country?.value)
                                                },
                                                options: countries || []
                                            }}
                                        />
                                        {errors.destination_country && touched.destination_country && <Alert style={{ marginTop: '5px' }} message={errors.destination_country} type="error" />}
                                    </FormControl>
                                )}
                                {columns.cold_store_id && (
                                    <FormControl id="cold_store_id" isInvalid={errors.cold_store_id && touched.cold_store_id}>
                                        <FormLabel>Cold Store *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'cold_store_id',
                                                value: values.cold_store_id && coldStoresLabelMap && ({ id: values.cold_store_id, label: coldStoresLabelMap?.[values.cold_store_id] }),
                                                onChange: cold_store_id => {
                                                    setFieldValue('cold_store_id', cold_store_id?.value)
                                                },
                                                options: coldStores || []
                                            }}
                                        />
                                        {errors.cold_store_id && touched.cold_store_id && <Alert style={{ marginTop: '5px' }} message={errors.cold_store_id} type="error" />}
                                    </FormControl>
                                )}
                                {columns.product_category && (
                                    <FormControl id="product_category" isInvalid={errors.product_category && touched.product_category}>
                                        <FormLabel>Product Category *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'product_category',
                                                value: values.product_category && ({ id: values.product_category, label: values.product_category }),
                                                onChange: product_category => {
                                                    setFieldValue('product_category', product_category?.value)
                                                },
                                                options: productCategories || []
                                            }}
                                        />
                                        {errors.product_category && touched.product_category && <Alert style={{ marginTop: '5px' }} message={errors.product_category} type="error" />}
                                    </FormControl>
                                )}
                                {columns.is_grinder_unfunded && (
                                    <FormControl id="is_grinder_unfunded" isInvalid={errors.is_grinder_unfunded && touched.is_grinder_unfunded}>
                                        <FormLabel>Is Grinder Unfunded *</FormLabel>
                                        <RadioGroup onChange={is_grinder_unfunded => setFieldValue('is_grinder_unfunded', is_grinder_unfunded === 'true')} value={values.is_grinder_unfunded === true ? 'true' : (values.is_grinder_unfunded === false ? 'false' : '')}>
                                            <Stack>
                                                <Radio value='true'>Yes</Radio>
                                                <Radio value='false'>No</Radio>
                                            </Stack>
                                        </RadioGroup>
                                        {errors.is_grinder_unfunded && touched.is_grinder_unfunded && <Alert style={{ marginTop: '5px' }} message={errors.is_grinder_unfunded} type="error" />}
                                    </FormControl>
                                )}
                                {columns.cold_store_unit_of_measure_id && (
                                    <FormControl id="cold_store_unit_of_measure_id" isInvalid={errors.cold_store_unit_of_measure_id && touched.cold_store_unit_of_measure_id}>
                                        <FormLabel>Store UOM *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'cold_store_unit_of_measure_id',
                                                value: values.cold_store_unit_of_measure_id && uomLabelMap && ({ id: values.cold_store_unit_of_measure_id, label: uomLabelMap?.[values.cold_store_unit_of_measure_id] }),
                                                onChange: cold_store_unit_of_measure_id => {
                                                    setFieldValue('cold_store_unit_of_measure_id', cold_store_unit_of_measure_id?.value)
                                                },
                                                options: uoms || []
                                            }}
                                        />
                                        {errors.cold_store_unit_of_measure_id && touched.cold_store_unit_of_measure_id && <Alert style={{ marginTop: '5px' }} message={errors.cold_store_unit_of_measure_id} type="error" />}
                                    </FormControl>
                                )}
                                {columns.cold_store_currency && (
                                    <FormControl id="cold_store_currency" isInvalid={errors.cold_store_currency && touched.cold_store_currency}>
                                        <FormLabel>Store Currency *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'cold_store_currency',
                                                value: values.cold_store_currency && ({ id: values.cold_store_currency, label: values.cold_store_currency }),
                                                onChange: cold_store_currency => {
                                                    setFieldValue('cold_store_currency', cold_store_currency?.value)
                                                },
                                                options: currencies || []
                                            }}
                                        />
                                        {errors.cold_store_currency && touched.cold_store_currency && <Alert style={{ marginTop: '5px' }} message={errors.cold_store_currency} type="error" />}
                                    </FormControl>
                                )}

                                {columns.origin_port && (
                                    <FormControl id="origin_port" isInvalid={errors.origin_port && touched.origin_port}>
                                        <FormLabel>Origin Port *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'origin_port',
                                               
                                                value: values.origin_port && portsLabelMap && ({ id: values.origin_port, label: portsLabelMap?.[values.origin_port] }),
                                                onChange: origin_port => {
                                                    for (let x = 0 ; x < 100; x++){
                                                        console.log('Origin Port',origin_port)
                                                    }
                                                        setFieldValue('origin_port', origin_port?.value)
                                                },
                                                options: ports || []
                                            }}
                                        />
                                        {errors.origin_port && touched.origin_port && <Alert style={{ marginTop: '5px' }} message={errors.origin_port} type="error" />}
                                    </FormControl>
                                )}
                                {columns.destination_port && (
                                    <FormControl id="destination_port" isInvalid={errors.destination_port && touched.destination_port}>
                                        <FormLabel>Desitnation Port *</FormLabel>
                                        <Select
                                            {...{
                                                isClearable: true,
                                                isSearchable: true,
                                                closeMenuOnSelect: true,
                                                removeSelected: true,
                                                name: 'destination_port',
                                                value: values.destination_port  && portsLabelMap && ({ id: values.destination_port, label: portsLabelMap?.[values.destination_port]}),
                                                onChange: destination_port => {
                                                    setFieldValue('destination_port', destination_port?.value)
                                                },
                                                options: ports || []
                                            }}
                                        />
                                        {errors.destination_port && touched.destination_port && <Alert style={{ marginTop: '5px' }} message={errors.destination_port} type="error" />}
                                    </FormControl>
                                )}
                                {columns.from_date && (
                                    <FormControl id="from_date" isInvalid={errors.from_date && touched.from_date}>
                                        <FormLabel>From date *</FormLabel>
                                        <Datetime
                                            name="from_date"
                                            inputProps={{
                                                className: 'form-control m-input',
                                            }}
                                            closeOnSelect
                                            value={values.from_date}
                                            onChange={e => setFieldValue('from_date', e._isAMomentObject ? e.format('YYYY-MM-DD') : null)}
                                            timeFormat={false}
                                            dateFormat="YYYY-MM-DD"
                                        />
                                        {errors.from_date && touched.from_date && <Alert style={{ marginTop: '5px' }} message={errors.from_date} type="error" />}
                                    </FormControl>
                                )}
                                {columns.to_date && (
                                    <FormControl id="to_date" isInvalid={errors.to_date && touched.to_date}>
                                        <FormLabel>To date *</FormLabel>
                                        <Datetime
                                            name="to_date"
                                            inputProps={{
                                                className: 'form-control m-input',
                                            }}
                                            closeOnSelect
                                            value={values.to_date}
                                            onChange={e => setFieldValue('to_date', e._isAMomentObject ? e.format('YYYY-MM-DD') : null)}
                                            timeFormat={false}
                                            dateFormat="YYYY-MM-DD"
                                        />
                                        {errors.to_date && touched.to_date && <Alert style={{ marginTop: '5px' }} message={errors.to_date} type="error" />}
                                    </FormControl>
                                )}
                                {columns.rate && (
                                    <FormControl id="rate" isInvalid={errors.rate && touched.rate}>
                                        <FormLabel>Rate *</FormLabel>
                                        <Input
                                            name="rate"
                                            type="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.rate}
                                        />
                                        {errors.rate && touched.rate && <Alert style={{ marginTop: '5px' }} message={errors.rate} type="error" />}
                                    </FormControl>
                                )}
                                {columns.free_days && (
                                    <FormControl id="free_days" isInvalid={errors.free_days && touched.free_days}>
                                        <FormLabel>Free Days *</FormLabel>
                                        <Input
                                            name="free_days"
                                            type="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.free_days}
                                        />
                                        {errors.free_days && touched.free_days && <Alert style={{ marginTop: '5px' }} message={errors.free_days} type="error" />}
                                    </FormControl>
                                )}
                                {columns.roll_period_days && (
                                    <FormControl id="roll_period_days" isInvalid={errors.roll_period_days && touched.roll_period_days}>
                                        <FormLabel>Roll Period Days *</FormLabel>
                                        <Input
                                            name="roll_period_days"
                                            type="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.roll_period_days}
                                        />
                                        {errors.roll_period_days && touched.roll_period_days && <Alert style={{ marginTop: '5px' }} message={errors.roll_period_days} type="error" />}
                                    </FormControl>
                                )}
                            </Stack>
                        </ModalBody>
                        <ModalFooter>
                            {values.created && (
                                <Button type="button" colorScheme="error" onClick={removeFeeRate} marginRight="16px">
                                    Remove
                                </Button>
                            )}
                            <Button type="submit" colorScheme="actionPrimary" isDisabled={!isValid}>
                                {!values.created ? 'New' : 'Save'}
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </Box>
    )
}
