/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { endOfMonth, getDate, startOfMonth, subMonths } from 'date-fns';
import { MARKETS } from '../../config/markets';
import qualityDataRequest from '../../data-fixtures/quality-all';
import { fetchPackerPlants } from '../quality-packer-plants/qualityPackerPlantsReducers';
import { fetchGrinders } from '../quality-grinders/qualityGrindersReducers';
import { ALL_PACKER_PLANTS_OPTION, TIME_SCALES, DEFAULT_GRINDER_OPTION } from './qualityConfig';
import { selectQualityPackerPlant, selectQualityGrinder } from './selectors/selectBasicQualityData';
import { selectQualityPackerPlantOptions } from './selectors/selectQualityPackerPlantOptions';
import { selectQualityGrinderOptions } from './selectors/selectQualityGrinderOptions';

function getInitialTimePeriod() {
  const now = new Date();
  const day = getDate(now);
  if (day <= 8) {
    return [startOfMonth(subMonths(now, 2)), endOfMonth(subMonths(now, 2))];
  }
  return [startOfMonth(subMonths(now, 1)), endOfMonth(subMonths(now, 1))];
}

const qualitySlice = createSlice({
  name: 'quality',
  initialState: {
    loading: true,
    error: null,
    data: null,

    // Global filters
    marketKey: MARKETS.Australia.key,

    // Filters
    timeScale: TIME_SCALES.MONTHLY,
    timePeriod: getInitialTimePeriod(),
    packerPlant: ALL_PACKER_PLANTS_OPTION,
    grinder: DEFAULT_GRINDER_OPTION,
  },
  reducers: {
    qualityDataRequestStart(state) {
      state.loading = true;
    },
    qualityDataRequestSuccess(state, { payload }) {
      state.loading = false;
      state.data = payload;
      state.error = null;
    },
    qualityDataRequestFailure(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
    setMarketKey(state, { payload }) {
      state.marketKey = payload;
    },
    setQualityTimeScale(state, { payload }) {
      state.timeScale = payload;
    },
    setQualityTimePeriod(state, { payload }) {
      state.timePeriod = payload;
    },
    setQualityPackerPlant(state, { payload }) {
      state.packerPlant = payload;
    },
    setQualityGrinder(state, { payload }) {
      state.grinder = payload;
    },
  },
});

export const {
  qualityDataRequestStart,
  qualityDataRequestSuccess,
  qualityDataRequestFailure,
  setQualityTimeScale,
  setQualityTimePeriod,
  setQualityPackerPlant,
  setMarketKey,
  setQualityGrinder,
} = qualitySlice.actions;

// ------
// Thunks

/**
 * Fetches quality data and manages loading/error state, also fetches
 * packerPlants and sets the selected packer_plant id to ALL if the currently
 * selected id is not in the available packerPlants for the market and time
 * period.
 * @param {QualityFetchParameters} fetchParameters
 */
export const fetchQualityData = fetchParameters => {
  return async (dispatch, getState) => {
    dispatch(qualityDataRequestStart());

    try {
      const [qualityData] = await Promise.all(
        [qualityDataRequest(fetchParameters)],
        dispatch(
          fetchPackerPlants({
            market: fetchParameters.market,
            timePeriod: fetchParameters.timePeriod,
          })
        ),
        dispatch(
          fetchGrinders({
            market: fetchParameters.market,
          })
        )
      );

      // If the selected packer_plant id is no longer in the packer_plant list, we
      // should reset to all. This would happen if the time period was
      // changed to before the packer_plant was onboarded.
      const state = getState();
      const packerPlants = selectQualityPackerPlantOptions(state);
      const packerPlant = selectQualityPackerPlant(state);
      if (!packerPlants.find(({ id }) => id === packerPlant.id)) {
        dispatch(setQualityPackerPlant(ALL_PACKER_PLANTS_OPTION));
      }
      const grinders = selectQualityGrinderOptions(state);
      const grinder = selectQualityGrinder(state);
      if (!grinders.find(({ id }) => id === grinder.id)) {
        dispatch(setQualityGrinder(DEFAULT_GRINDER_OPTION));
      }
      dispatch(qualityDataRequestSuccess(qualityData));
      return true;
    } catch (error) {
      dispatch(qualityDataRequestFailure(error.toString()));
      return error;
    }
  };
};

export const updateQualityTimeScale = timeScale => {
  return async dispatch => {
    dispatch(setQualityTimeScale(timeScale));
    await dispatch(fetchQualityData);
  };
};

export const updateQualityTimePeriod = timePeriod => {
  return async dispatch => {
    dispatch(setQualityTimePeriod(timePeriod));
    await dispatch(fetchQualityData);
  };
};

export const updateQualityPackerPlant = packerPlant => {
  return async dispatch => {
    dispatch(setQualityPackerPlant(packerPlant));
    await dispatch(fetchQualityData);
  };
};

export const updateQualityGrinder = grinder => {
  return async dispatch => {
    dispatch(setQualityGrinder(grinder));
    await dispatch(fetchQualityData);
  };
};

export default qualitySlice.reducer;
