import { createSelector } from '@reduxjs/toolkit';
import { eachDayOfInterval, eachWeekOfInterval, isSameDay, startOfDay, subYears } from 'date-fns';
import { UOM } from '../../../modules/const';
import { filterOrdersForDateMode } from '../../../modules/order-utils';
import { MARKET_FOCUS } from '../marketsConfig';
import {
  selectActiveMarket,
  selectMarketMenuItems,
  selectMarketsDataRaw,
  selectMarketsTimePeriod,
  selectMarketsYtdDataRaw,
} from './selectBasicMarketData';
import { selectCostPerformanceDateMode } from './selectMarketsVolumeData';

export const selectMenuOrders = createSelector(
  selectMarketsDataRaw,
  selectMarketsTimePeriod,
  selectCostPerformanceDateMode,
  (rawData, timePeriod, dateMode) => {
    if (!rawData) return null;

    // Note: for now we are forcing domestic only.
    const orders = rawData.orders[MARKET_FOCUS.DOMESTIC] || [];

    const filtered = orders.filter(filterOrdersForDateMode(timePeriod, dateMode)).map(d => ({
      ...d,
      date: d[dateMode],
    }));
    return filtered;
  }
);

export const selectMenuOrdersYearToDate = createSelector(
  selectMarketsYtdDataRaw,
  selectCostPerformanceDateMode,
  (rawData, dateMode) => {
    if (!rawData) return null;

    // Note: for now we are forcing domestic only.
    const orders = rawData.orders[MARKET_FOCUS.DOMESTIC] || [];
    const timePeriod = [startOfDay(subYears(new Date(), 1)).getTime(), startOfDay(new Date()).getTime()];

    const filtered = orders.filter(filterOrdersForDateMode(timePeriod, dateMode)).map(d => ({
      ...d,
      date: d[dateMode],
    }));
    return filtered;
  }
);

/**
 * Returns a structure with data required for the markets McMenu table
 */
export const selectMarketsMcMenuData = createSelector(
  selectMenuOrders,
  selectMenuOrdersYearToDate,
  selectMarketsTimePeriod,
  selectMarketMenuItems,
  selectActiveMarket,
  (orders, ordersYearToDate = [], timePeriod, menuItemDefs, activeMarket) => {
    const ytdOrders = ordersYearToDate || [];
    const currency = activeMarket.selectorText === 'AUS' ? 'AUD' : 'USD';
    const uom = activeMarket.quantityUnit === 'kg' ? UOM.KGS : UOM.LBS;

    const movementDays = eachDayOfInterval({
      start: new Date(timePeriod[0]),
      end: new Date(timePeriod[1]),
    });

    const historicalDays = eachWeekOfInterval(
      {
        start: startOfDay(subYears(new Date(), 1)),
        end: startOfDay(new Date()),
      },
      { weekStartsOn: 1 } // Monday
    );

    const menuItems = menuItemDefs.map(def => {
      const movementPeriodPrices = movementDays.map(day => {
        const daysOrders = orders.filter(order => isSameDay(day, order.date));
        const price = daysOrders.length ? def.calculatePrice(daysOrders, uom) : null;
        return {
          date: day,
          price,
          forecast: false,
        };
      });

      const movementHistoricalPrices = historicalDays.map(day => {
        const daysOrders = ytdOrders.filter(order => isSameDay(day, order.date));
        const price = daysOrders.length ? def.calculatePrice(daysOrders, uom) : null;
        return {
          date: day,
          price,
          forecast: false,
        };
      });

      // TODO: add forecast prices
      const movementForecast = [];
      const movement = [...movementHistoricalPrices, ...movementForecast];
      const periodPrice = def.calculatePrice(orders, uom);

      // TODO: implement with data from API
      // TODO - Recommendations will be managed similarly to annotations in the future
      // const insight =
      //   def.id === 'BEEF_10_1'
      //     ? {
      //         title: 'Consider 1 week LTO',
      //         text: 'May 2019 “Big Mac for $2” LTO: 157% increase in product movement, $1.2M customer spending',
      //       }
      //     : undefined;
      const insight = undefined;

      return {
        id: def.id,
        label: def.label,
        movementPeriodPrices,
        movement,
        price: periodPrice,
        insight,
        currency,
        uom,
      };
    });

    return menuItems;
  }
);
