/* eslint-disable no-param-reassign */
export const TREASURY_TIME_INTERVAL = {
  YEARLY: 'yearly',
  QUARTERLY: 'quarterly',
  MONTHLY: 'monthly',
};

export const TREASURY_PROTEIN = {
  BEEF: 'monthly',
  CHICKEN_PORK: 'chicken_pork',
};

export const TREASURY_MODULE = {
  RISK_MANAGEMENT: 'riskManagement',
  PERFORMANCE_AND_FORECAST: 'performanceAndForecast',
  COST_SAVINGS: 'costSavings',
  EXPECTED_VOLUME: 'expectedVolume',
};

export const INITIAL_ANNOTATIONS_DATA = {
  [TREASURY_MODULE.RISK_MANAGEMENT]: [],
  [TREASURY_MODULE.PERFORMANCE_AND_FORECAST]: [],
  [TREASURY_MODULE.COST_SAVINGS]: [],
  [TREASURY_MODULE.EXPECTED_VOLUME]: [],
};

export const TREASURY_CURRENCY = {
  USD: 'USD',
  AUD: 'AUD',
};
