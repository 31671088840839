export const WEIGHT_UNIT = {
  KILOGRAM: 'kg',
  POUND: 'lb',
};

export const CURRENCY = {
  AUD: 'AUD',
  NZD: 'NZD',
  USD: 'USD',
};

export const UOM = {
  KGS: 'kgs',
  LBS: 'lbs',
};

export const KG_TO_LB = 2.20462262185;
export const LB_TO_KG = 1 / KG_TO_LB;
export const KGS_PER_LOAD = 42000 * LB_TO_KG;

/**
 * Market focus is used in markets that have exports.
 * This has no effect for no-export countries, and is short-circuited to domestic.
 */
export const MARKET_FOCUS = {
  DOMESTIC: 'domestic',
  EXPORT: 'export',
};

/**
 * Takes raw country keys from boab and maps to 2 letter codes
 */
export const COUNTRY_CODE_MAP = {
  IE: 'ie',
  Thailand: 'th',
  Australia: 'au',
  'South Africa': 'za',
  Singapore: 'sg',
  Japan: 'jp',
  Philippines: 'ph',
  // Misspelt
  Phillipines: 'ph',
  China: 'cn',
  'United Arab Emirates': 'ae',
  UAE: 'ae',
  Malaysia: 'my',
  Uruguay: 'uy',
  UY: 'uy',
  'South Korea': 'kr',
  Korea: 'kr',
  Canada: 'ca',
  CA: 'ca',
  CANADA: 'ca',
  UK: 'gb',
  'New Zealand': 'nz',
  'Hong Kong': 'hk',
  Indonesia: 'id',
  USA: 'us',
  US: 'us',
  Taiwan: 'tw',
};
