import Constants from '../Constants';
import api from '../api';

export const FECS_FETCHED = 'FECS_FETCHED';
export const FEC_ADDED = 'FEC_ADDED';
export const FEC_UPDATED = 'FEC_UPDATED';
export const FEC_REMOVED = 'FEC_REMOVED';
export const FEC_ERROR = 'FEC_ERROR';

const BASE_PATH = `/fec`;

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadFECs(fec) {
  return {
    type: FECS_FETCHED,
    payload: fec,
  };
}

export function addFEC(fec) {
  return {
    type: FEC_ADDED,
    payload: fec,
  };
}

export function removeFEC(fec) {
  return {
    type: FEC_REMOVED,
    payload: fec,
  };
}

export function updateFEC(fec) {
  return {
    type: FEC_UPDATED,
    payload: fec,
  };
}

export function errorFEC(error) {
  return {
    type: FEC_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchFEC() {
  return dispatch => {
    return api
      .get(BASE_PATH)
      .then(response => Constants.handleErrors(response, dispatch, errorFEC))
      .then(json => dispatch(loadFECs(json)))
      .catch(error => dispatch(errorFEC(error)));
  };
}

export function postFEC(fec) {
  return dispatch => {
    return api
      .post(BASE_PATH, fec)
      .then(response => Constants.handleErrors(response, dispatch, errorFEC))
      .then(json => dispatch(addFEC(json)))
      .catch(error => dispatch(errorFEC(error)));
  };
}

export function patchFEC(fec) {
  return dispatch => {
    return api
      .patch(`${BASE_PATH}/${fec.id}`, fec)
      .then(response => Constants.handleErrors(response, dispatch, errorFEC))
      .then(json => {
        dispatch(updateFEC(json));
      })
      .catch(error => dispatch(errorFEC(error)));
  };
}

export function deleteFEC(fec) {
  return dispatch => {
    return api
      .delete(`${BASE_PATH}/${fec.id}`)
      .then(response => Constants.handleErrors(response, dispatch, errorFEC))
      .then(() => dispatch(removeFEC(fec)))
      .catch(error => dispatch(errorFEC(error)));
  };
}
