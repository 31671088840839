import axios from 'axios';
import get from 'lodash/get';
import Constants from '../Constants';

export const SSR_LIST_FETCHED = 'SSR_LIST_FETCHED';
export const SSR_ERROR = 'SSR_ERROR';
export const SSR_REPORT_CONFIG_FETCHED = 'SSR_REPORT_CONFIG_FETCHED';
export const SSR_REPORT_DATA_FETCHED = 'SSR_REPORT_DATA_FETCHED';
export const SSR_REPORT_CATEGORIES = 'SSR_REPORT_CATEGORIES';
export const SSR_REPORT_DELETED_STATUS = 'SSR_REPORT_DELETED_STATUS';

const BASE_URL = `${Constants.URL}self_service_reports`;

export function fetchReportsList(token) {
  return async dispatch => {
    try {
      const response = await axios.get(BASE_URL);
      Constants.handleErrors(response, dispatch, errorSSR);
      const ssrReports = get(response, 'data', []);
      dispatch(loadSSRList(ssrReports));
    } catch (error) {
      dispatch(errorSSR(error));
    }
  };
}

export function fetchReportConfig(token, reportId) {
  return async dispatch => {
    try {
      const response = await axios.get(`${BASE_URL}/${reportId}`);
      Constants.handleErrors(response, dispatch, errorSSR);
      const reportConfig = get(response, 'data', []);
      dispatch(loadReportConfig(reportConfig));
    } catch (error) {
      dispatch(errorSSR(error));
    }
  };
}

export function runReport(token, paramsValues, reportId) {
  return async dispatch => {
    try {
      const response = await axios.post(`${BASE_URL}/${reportId}`, { params: paramsValues });
      Constants.handleErrors(response, dispatch, errorSSR);
      const reports = get(response, 'data', []);
      dispatch(loadReportData(reports));
    } catch (error) {
      dispatch(errorSSR(error));
    }
  };
}

export function fetchReportCategories(token) {
  return async dispatch => {
    try {
      const response = await axios.get(`${Constants.URL}self_service_report_categories`);
      Constants.handleErrors(response, dispatch, errorSSR);
      const ssrReportCategories = get(response, 'data', []);
      dispatch(loadSSRCategories(ssrReportCategories));
    } catch (error) {
      dispatch(errorSSR(error));
    }
  };
}

export function addReport(token, payload) {
  return async dispatch => {
    try {
      const response = await axios.post(`${BASE_URL}`, payload);
      Constants.handleErrors(response, dispatch, errorSSR);
      const reportConfig = get(response, 'data', []);
      dispatch(loadReportConfig(reportConfig));
    } catch (error) {
      dispatch(errorSSR(error));
    }
  };
}

export function updateReport(token, payload, reportId) {
  return async dispatch => {
    try {
      const response = await axios.patch(`${BASE_URL}/${reportId}`, payload);
      Constants.handleErrors(response, dispatch, errorSSR);
      const reportConfig = get(response, 'data', []);
      dispatch(loadReportConfig(reportConfig));
    } catch (error) {
      dispatch(errorSSR(error));
    }
  };
}
export function deleteReport(token, reportId) {
  return async dispatch => {
    try {
      const response = await axios.delete(`${BASE_URL}/${reportId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
        data: '{}',
      });
      Constants.handleErrors(response, dispatch, errorSSR);
      const deleteStatus = 'Report and related data successfully deleted.';
      dispatch(deletedReportStatus(deleteStatus));
    } catch (e) {
      dispatch(errorSSR(e));
    }
  };
}

export const loadSSRList = ssrList => {
  return {
    type: SSR_LIST_FETCHED,
    payload: ssrList,
  };
};

export const loadReportConfig = reportConfig => {
  return {
    type: SSR_REPORT_CONFIG_FETCHED,
    payload: reportConfig,
  };
};

export const loadReportData = reportsData => {
  return {
    type: SSR_REPORT_DATA_FETCHED,
    payload: reportsData,
  };
};

export const loadSSRCategories = categories => {
  return {
    type: SSR_REPORT_CATEGORIES,
    payload: categories,
  };
};

export const deletedReportStatus = status => {
  return {
    type: SSR_REPORT_DELETED_STATUS,
    payload: status,
  };
};

export const errorSSR = error => {
  return {
    type: SSR_ERROR,
    payload: error,
  };
};
