const schema = [
  {
    name: 'fec',
    displayName: 'FEC',
    systemIdField: 'id',
    displayIdField: 'fec_confirmation_number',
    path: '/fec',
    tabGroup: { key: 'pricing', label: 'Pricing' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'fec_confirmation_number',
        displayName: 'FEC Confirmation Number',
        tooltip: 'Unique identifier for the Foreign Exchange Contract',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'fec_description',
        displayName: 'FEC Description',
        tooltip: 'Brief description of the Foreign Exchange Contract',
        dataType: 'text',
        searchable: true,
        required: true,
      },
      {
        name: 'provider',
        displayName: 'Provider',
        tooltip: 'Financial institution providing the Foreign Exchange Contract',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'provider',
        required: true,
      },
      {
        name: 'buy_currency',
        displayName: 'Buy Currency',
        tooltip: 'Currency being purchased in the contract',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'currency',
        required: true,
      },
      {
        name: 'sell_currency',
        displayName: 'Sell Currency',
        tooltip: 'Currency being sold in the contract',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'currency',
        required: true,
      },
      {
        name: 'fec_maturity_date',
        displayName: 'FEC Maturity Date',
        tooltip: 'Date when the Foreign Exchange Contract matures',
        dataType: 'date',
        required: true,
      },
      {
        name: 'fec_rate',
        displayName: 'FEC Rate',
        tooltip: 'Exchange rate agreed upon for the contract',
        dataType: 'number',
        required: true,
      },
      {
        name: 'spot_rate',
        displayName: 'Spot Rate',
        tooltip: 'Current market exchange rate at the time of the contract',
        dataType: 'number',
        required: true,
      },
      {
        name: 'total_fec_value',
        displayName: 'Total Fec Value',
        tooltip: 'Total value of the Foreign Exchange Contract',
        dataType: 'number',
        required: true,
      },
    ],
  },
];
export default schema;
