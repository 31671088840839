import axios from 'axios';
import Constants from '../Constants';

export const BASE_URL = `${Constants.URL}file-upload`;

export async function uploadFileToS3Request(fileData) {
  const fileName = fileData.file.name;
  const fileType = fileData.file.type;
  const formData = new FormData();
  formData.append('file', fileData.file);
  formData.append('file_name', fileName);
  formData.append('Content-Type', fileType);

  const response = await axios.post(`${BASE_URL}`, formData);
  return response.data;
}
