import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import ReactDataGrid, { Row } from 'react-data-grid';
import ReactPaginate from 'react-paginate';

import './GeneratedDocumentsResultsGrid.scss';
import Loading from '../Loading';

export const ROW_HEIGHT = 35;
export const HEADER_ROW_HEIGHT = 40;
const MIN_COLUMN_WIDTH = 130;
export const HEIGHT_BUFFER = 2;

function MyRowRenderer(props) {
  return <Row {...props} className={props['aria-selected'] && 'selected_data_grid_row'} />;
}

class GeneratedDocumentsResultsGrid extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    headerRowHeight: PropTypes.number,
    id: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    onGridRowUpdate: PropTypes.func,
    onPageChange: PropTypes.func,
    onRowSelect: PropTypes.func,
    pagination: PropTypes.shape({
      pages: PropTypes.number,
      has_next: PropTypes.bool,
      has_previous: PropTypes.bool,
      next_page: PropTypes.number,
      previous_page: PropTypes.number,
      total: PropTypes.number,
    }),
    paginationIsHidden: PropTypes.bool,
    renderFullTable: PropTypes.bool,
    rowHeight: PropTypes.oneOfType(PropTypes.number, PropTypes.func),
    height: PropTypes.number,
    rowKeyGetter: PropTypes.func,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedRows: PropTypes.oneOfType(PropTypes.arrayOf(PropTypes.object), PropTypes.object),
    disableSelect: PropTypes.bool,
    rowClass: PropTypes.func,
  };

  state = {
    selectedPage: 0,
  };

  defaultRowGetter = row => {
    return row.id;
  };

  componentDidMount() {
    if (
      this.grid !== undefined &&
      this.grid.selectAllCheckbox &&
      this.grid.selectAllCheckbox.id === 'select-all-checkbox'
    ) {
      const { id } = this.props;
      this.grid.selectAllCheckbox.id += `-${id}`;
      this.grid.selectAllCheckbox.nextSibling.htmlFor += `-${id}`;
    }
  }

  handlePageChange = newPage => {
    const { onPageChange } = this.props;
    this.setState(
      {
        selectedPage: newPage?.selected || 0,
      },
      () => onPageChange(newPage)
    );
  };

  render() {
    const {
      className = '',
      columns,
      headerRowHeight,
      isLoading,
      onRowSelect,
      pagination,
      paginationIsHidden,
      rows,
      rowHeight,
      height,
      selectedRows,
      onGridRowUpdate,
      rowKeyGetter,
      renderFullTable,
      disableSelect,
      rowClass,
    } = this.props;

    const rowsCount = get(rows, 'length', 1);
    const pagesCount = get(pagination, 'pages', 1);

    const { selectedPage } = this.state;
    let currentPage = selectedPage;
    if (pagination) {
      const currentPageFromPagination = pagination.has_next ? pagination.next_page - 2 : pagination.pages - 1;
      if (currentPageFromPagination !== currentPage) {
        currentPage = currentPageFromPagination;
      }
    }

    let selectedRowsSet = null;
    if (!selectedRows) {
      selectedRowsSet = new Set([]);
    } else if (!isNaN(parseInt(selectedRows.size, 10))) {
      selectedRowsSet = selectedRows;
    } else {
      selectedRowsSet = new Set(selectedRows.map(rowKeyGetter || this.defaultRowGetter));
    }

    return (
      <div className={['results-grid', disableSelect && 'disable-select'].filter(Boolean).join(' ')}>
        {isLoading ? (
          <Loading />
        ) : (
          <ReactDataGrid
            className={`rdg-light ${className}`}
            ref={node => {
              this.grid = node;
            }}
            columns={columns}
            headerRowHeight={headerRowHeight || HEADER_ROW_HEIGHT}
            onRowsChange={onGridRowUpdate}
            onSelectedRowsChange={onRowSelect}
            rowHeight={rowHeight || ROW_HEIGHT}
            rowKeyGetter={rowKeyGetter || this.defaultRowGetter}
            rows={rows}
            selectedRows={selectedRowsSet}
            style={{
              height:
                height ||
                (headerRowHeight || HEADER_ROW_HEIGHT) + rowsCount * (rowHeight || ROW_HEIGHT) + HEIGHT_BUFFER,
            }}
            width={renderFullTable ? MIN_COLUMN_WIDTH * columns.length : undefined}
            rowRenderer={MyRowRenderer}
            rowClass={rowClass}
          />
        )}
        {/* <!-- PAGINATION --> */}
        {pagination && !paginationIsHidden && (
          <div className="results-grid__pagination">
            <ReactPaginate
              forcePage={currentPage}
              pageCount={pagesCount}
              pageRangeDisplayed={pagesCount < 8 ? pagesCount : 8}
              marginPagesDisplayed={0}
              onPageChange={this.handlePageChange}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
              previousLabel={<i className="fa fa-angle-left" />}
              nextLabel={<i className="fa fa-angle-right" />}
            />
            <div className="results-grid__pagination-stats">
              {currentPage + 1} / {pagesCount}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default GeneratedDocumentsResultsGrid;
