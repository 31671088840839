import { createSelector } from '@reduxjs/toolkit';
import {
  selectQualityNCRsTimeRange,
  selectQualityNCRsTimePeriod,
  selectQualityNCRsFrequency,
  selectQualityNCRsMarket,
  selectQualityNCRsRegion,
  selectQualityNCRsGrinder,
  selectQualityNCRsClaimDollars,
} from './selectBasicDetailsNcrsData';

/**
 * The parameters for quality data requests based on the filter state
 */
export const selectQualityNCRsFetchParameters = createSelector(
  selectQualityNCRsTimeRange,
  selectQualityNCRsTimePeriod,
  selectQualityNCRsFrequency,
  selectQualityNCRsMarket,
  selectQualityNCRsRegion,
  selectQualityNCRsGrinder,
  selectQualityNCRsClaimDollars,
  (timeRange, timePeriod, frequency, market, region, grinder, currency) => ({
    timeRange,
    timePeriod,
    frequency,
    market: market.key,
    region,
    grinderId: grinder,
    currency,
  })
);
