import { VOYAGE_ADDED_OR_UPDATED, VOYAGE_DELETED, VOYAGE_FETCHED } from '../actions/actions_voyage';

const voyage = (voyage = {}, action) => {
  switch (action.type) {
    case VOYAGE_FETCHED:
      return action.payload[0] || {};
    case VOYAGE_ADDED_OR_UPDATED:
      return action.payload;
    case VOYAGE_DELETED:
      return '';
    default:
      return voyage;
  }
};

export default voyage;
