const schema = [
  {
    name: 'business_group',
    displayName: 'Business Group',
    systemIdField: 'id',
    displayIdField: 'name',
    deletable: true,
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    path: '/business_group',
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        tooltip: 'Unique identifier for the business group',
        dataType: 'number',
        required: true,
        systemIdentifier: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'Official name of the business group',
        dataType: 'text',
        required: true,
      },
    ],
  },
];
export default schema;
