import React from 'react';

const TicketDetails = ({ ticket, onCloseClick, onBackToAllTicketsClick }) => {
  return (
    <div style={{ padding: '20px' }}>
      <div className="row">
        <div className="col-sm-12">
          <h3>
            <strong>Subject</strong>: {ticket.subject}
            <button className="pull-right btn btn-sm btn-default" onClick={onBackToAllTicketsClick}>
              Back to all tickets
            </button>
          </h3>
          <h5>
            <strong>Requestor</strong>: {ticket.name} ({ticket.email})
          </h5>
        </div>
        <div className="col-sm-12" style={{ paddingTop: '10px' }}>
          <h5>
            <strong>Device</strong>: {ticket.device}
          </h5>
          <h5>
            <strong>Browser</strong>: {ticket.browser}
          </h5>
          <h5>
            <strong>Details</strong>:{' '}
          </h5>
          <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: ticket.description }} />
        </div>
        <div className="col-sm-12">
          {ticket.opened ? (
            <button
              onClick={() => {
                onCloseClick(ticket);
              }}
              className="btn btn-danger btn-sm"
            >
              Close
            </button>
          ) : (
            false
          )}
        </div>
        <hr />
      </div>
    </div>
  );
};

export default TicketDetails;
