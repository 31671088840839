import { connect } from 'react-redux';
import EditPackerOfferFormComponent from '../../components/packers/packer_offer_form/edit/EditPackerOfferForm';

const mapStateToProps = state => {
  return {
    packers: state.packers,
    packer_plants: state.packer_plants,
    user: state.user,
    token: state.user.token,
    input_products: state.input_products,
    offer_source: state.form_fields.offer_source || [],
  };
};

const EditPackerOfferForm = connect(mapStateToProps)(EditPackerOfferFormComponent);

export default EditPackerOfferForm;
