export const TIME_SCALES = {
  MONTHLY: 'Monthly',
  ANNUALLY: 'Yearly',
};

export const FREQUENCY = {
  MONTHLY: 'Monthly',
  ANNUALLY: 'Yearly',
};

export const PACKER_PLANTS_ALL = 'All';
export const ALL_PACKER_PLANTS_OPTION = {
  id: PACKER_PLANTS_ALL,
  name: 'All establishments',
};

export const GRINDERS_ALL = 'All';
export const ALL_GRINDERS_OPTION = {
  id: GRINDERS_ALL,
  name: 'All grinders',
};

export const DEFAULT_GRINDER = 'APFNZ';
export const DEFAULT_GRINDER_OPTION = {
  id: DEFAULT_GRINDER,
  name: 'Anzco Prepared Foods',
};

export const DIVIDER_OPTION = {
  id: 'Divider',
  name: 'Divider',
};

export const CUSTOM_FILTERS = {
  MOST_REPORTED_SUB_CATEGORIES: 'Most reported subcategories',
};

export const PACKER_PLANT_RANKING_COMPONENTS = {
  Australia: 'NCR Incident Rate, FO Incident Rate and DIFOT',
  USA: 'FO Incident Rate and DIFOT',
};
