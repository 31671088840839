const schema = [
  {
    name: 'access_level',
    displayName: 'Access Level',
    nested: false,
    hidden: true,
    systemIdField: null,
    displayIdField: null,
    deletable: false,
    path: '/access_levels',
  },
];
export default schema;
