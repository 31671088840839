import { removeObjectFromArray, replaceObjectInArray } from '../helpers';
import { PORT_ADDED, PORT_REMOVED, PORT_UPDATED, PORTS_FETCHED } from '../actions/actions_ports';

const ports = (ports = [], action) => {
  const portCopy = JSON.parse(JSON.stringify(ports));

  switch (action.type) {
    case PORTS_FETCHED:
      return action.payload;
    case PORT_ADDED:
      portCopy.push(action.payload);
      return portCopy;
    case PORT_UPDATED:
      return replaceObjectInArray(portCopy, action.payload);
    case PORT_REMOVED:
      return removeObjectFromArray(portCopy, action.payload);
    default:
      return ports;
  }
};

export default ports;
