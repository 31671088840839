import { Col, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTreasuryTimeScale } from '../../../slices/treasury/selectors/selectTreasuryBasicData';
import { updateTimeScale } from '../../../slices/treasury/treasuryReducers';
import {
  selectTreasuryDetailsActiveVolume,
  updateActiveVolume,
  selectTreasuryDetailsActivePrice,
  updateActivePrice,
  updateActiveCost,
  selectTreasuryDetailsActiveCost,
  updateTimeRange,
  updateShowOutlook,
  selectTreasuryDetailsTimeRange,
  selectTreasuryDetailsShowOutlook,
} from '../../../slices/treasury-details/treasuryDetailsReducers';
import FilterVolume from '../../../components/analyticDashboard/treasuryDetails/FilterVolume/FilterVolume';
import FilterPrice from '../../../components/analyticDashboard/treasuryDetails/FilterPrice/FilterPrice';
import FilterCost from '../../../components/analyticDashboard/treasuryDetails/FilterCost/FilterCost';
import FilterTimeRange from '../../../components/analyticDashboard/treasuryDetails/FilterTimeRange/FilterTimeRange';
import FilterTimeScale from '../../../components/analyticDashboard/treasury/FilterTimeScale/FilterTimeScale';

export default function TreasuryDetailsFilters(/* props */) {
  const dispatch = useDispatch();

  const activeVolume = useSelector(selectTreasuryDetailsActiveVolume);
  const activePrice = useSelector(selectTreasuryDetailsActivePrice);
  const activeCost = useSelector(selectTreasuryDetailsActiveCost);
  const timeRange = useSelector(selectTreasuryDetailsTimeRange);
  const showOutlook = useSelector(selectTreasuryDetailsShowOutlook);
  const timeScale = useSelector(selectTreasuryTimeScale);

  const handleActiveVolumeChange = React.useCallback(
    newVal => {
      dispatch(updateActiveVolume(newVal));
    },
    [dispatch]
  );

  const handleActivePriceChange = React.useCallback(
    newVal => {
      dispatch(updateActivePrice(newVal));
    },
    [dispatch]
  );

  const handleActiveCostChange = React.useCallback(
    newVal => {
      dispatch(updateActiveCost(newVal));
    },
    [dispatch]
  );

  const handleTimeRangeChange = React.useCallback(
    newVal => {
      dispatch(updateTimeRange(newVal));
    },
    [dispatch]
  );

  const handleShowOutlookChange = React.useCallback(
    newVal => {
      dispatch(updateShowOutlook(newVal));
    },
    [dispatch]
  );

  const handleTimeScaleChange = React.useCallback(
    newVal => {
      dispatch(updateTimeScale(newVal));
    },
    [dispatch]
  );

  return (
    <Row gutter={16}>
      <Col span={4}>
        <FilterVolume activeVolume={activeVolume} onActiveVolumeChange={handleActiveVolumeChange} />
      </Col>
      <Col span={4}>
        <FilterPrice activePrice={activePrice} onActivePriceChange={handleActivePriceChange} />
      </Col>
      <Col span={3}>
        <FilterCost activeCost={activeCost} onActiveCostChange={handleActiveCostChange} />
      </Col>
      <Col span={10}>
        <FilterTimeRange
          timeRange={timeRange}
          onTimeRangeChange={handleTimeRangeChange}
          showOutlook={showOutlook}
          onShowOutlookChange={handleShowOutlookChange}
        />
      </Col>
      <Col span={3}>
        <div
          style={{
            textTransform: 'uppercase',
            marginBottom: '5px',
            color: '#0B1435',
            opacity: 0.6,
          }}
        >
          Frequency
        </div>
        <FilterTimeScale timeScale={timeScale} onTimeScaleChange={handleTimeScaleChange} size="small" />
      </Col>
    </Row>
  );
}
