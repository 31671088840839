import { createSelector } from '@reduxjs/toolkit';
import { group } from 'd3';
import { selectQualityNCRsActivePackerPlants, selectQualityNCRsMarket } from './selectBasicDetailsNcrsData';
import { selectAvailableCategoriesWithSubcategories } from './selectAvailableCategories';
import { selectQualityNCRsData } from './selectQualityNCRsData';
import Constants from '../../../Constants';
import { CUSTOM_FILTERS } from '../../quality/qualityConfig';
import { VOLUME_OPTIONS } from '../qualityDetailsNcrsConfig';

const selectQualityNCRsDataBySubCategory = createSelector(
  selectQualityNCRsData,
  selectQualityNCRsActivePackerPlants,
  selectAvailableCategoriesWithSubcategories,
  selectQualityNCRsMarket,
  (data, activePackerPlants, availableCategoriesWithSubcategories, market) => {
    if (!data) return null;

    const activePackerPlantsIds = activePackerPlants.filter(x => !!x).map(x => x.id);

    const dataBySubCategory = data.map(d => {
      const allSubCategoryData = [];
      let allPackerPlants = [];
      let totalReceivedWeight = 0;
      const multiplier = VOLUME_OPTIONS[market.key].MULTIPLIER;

      if (activePackerPlantsIds.length > 0) {
        activePackerPlantsIds.forEach(ap => {
          const byPackerPlantData = d.byPackerPlant?.find(x => x.packerPlantId === ap);
          if (byPackerPlantData) {
            allPackerPlants.push(byPackerPlantData);
            // if packer or packer plants selected
            // for calculating incidentRate or percentof Volume
            // receivedWeight should be weight received for that selection
            // for the selected period(month)
            // When more than one active packerplant, it's comparison and is handled below
            if (activePackerPlantsIds.length < 2) {
              totalReceivedWeight = byPackerPlantData.receivedWeight;
            }
          } else {
            allPackerPlants.push({ ...Constants.DEFAULT_PACKER_PLANT_DATA, packerPlantId: ap });
          }
        });
      } else {
        allPackerPlants = d.byPackerPlant.filter(p => p.packerPlantId.length > 3);
        // if no selection made
        // then weight received for that period(month)
        totalReceivedWeight = d.receivedWeight;
      }

      // FILTER ESTABLISHMENT COMPARISON
      if (activePackerPlantsIds.length >= 2) {
        allPackerPlants.forEach(p => {
          availableCategoriesWithSubcategories.forEach(category => {
            category.subCategories.forEach(subCategory => {
              let subcategoryData;
              for (let i = 0; i < p.byCategory.length; i += 1) {
                subcategoryData = p.byCategory[i].subCategories.find(sc => sc.subCategory === subCategory);
                if (subcategoryData) {
                  break;
                }
              }
              // if packer or packer plants selected
              // for calculating incidentRate or percentof Volume
              // receivedWeight should be weight received for that selection
              // for the selected period(month)
              const packerPlantReceivedWeight = d.byPackerPlant.filter(ap => ap.packerPlantId === p.packerPlantId)[0]
                .receivedWeight;
              if (subcategoryData) {
                allSubCategoryData.push({
                  ...subcategoryData,
                  packerPlantId: p.packerPlantId,
                  percentOfTotalVolume: packerPlantReceivedWeight
                    ? subcategoryData.weight / packerPlantReceivedWeight
                    : 0,
                  incidentsPerUnit: packerPlantReceivedWeight
                    ? subcategoryData.incidentsCount / packerPlantReceivedWeight
                    : 0,
                  incidentRate: packerPlantReceivedWeight
                    ? (subcategoryData.incidentsCount * multiplier) / packerPlantReceivedWeight
                    : 0,
                });
              } else {
                const defaultSCData = { subCategory, ...Constants.DEFAULT_CATEGORY_DATA };
                allSubCategoryData.push({ ...defaultSCData, packerPlantId: p.packerPlantId });
              }
            });
          });
        });
        const groupedBySubCategory = Array.from(
          group(allSubCategoryData, x => {
            return x.subCategory;
          })
        );
        return {
          date: d.date,
          bySubCategory: groupedBySubCategory.map(([subCategory, entries]) => {
            return {
              subCategory,
              byPackerPlant: entries,
            };
          }),
        };
      }

      if (allPackerPlants.length > 0) {
        allPackerPlants.forEach(p => {
          availableCategoriesWithSubcategories.forEach(category => {
            if (category.category !== CUSTOM_FILTERS.MOST_REPORTED_SUB_CATEGORIES) {
              category.subCategories.forEach(subCategory => {
                let subcategoryData;
                for (let i = 0; i < p.byCategory.length; i += 1) {
                  subcategoryData = p.byCategory[i].subCategories.find(sc => sc.subCategory === subCategory);
                  if (subcategoryData) {
                    break;
                  }
                }
                if (subcategoryData) {
                  allSubCategoryData.push(subcategoryData);
                } else {
                  const defaultSCData = { subCategory, ...Constants.DEFAULT_CATEGORY_DATA };
                  allSubCategoryData.push(defaultSCData);
                }
              });
            }
          });
        });
      } else {
        availableCategoriesWithSubcategories.forEach(category => {
          category.subCategories.forEach(subCategory => {
            const defaultSCData = { subCategory, ...Constants.DEFAULT_CATEGORY_DATA };
            allSubCategoryData.push(defaultSCData);
          });
        });
      }

      const groupedBySubCategory = Array.from(
        group(allSubCategoryData, x => {
          return x.subCategory;
        })
      );

      // incidentRate = num_of_incidents_per_sub_category / received_weight - for that month
      // packer incidentRate = num_of_incidents_per_sub_category_for_packer / received_weight_of_packer - for that month
      // bottom10 incidentRate = incident_per_sub_category_of_bottom_10 / received_weight - for that month
      return {
        date: d.date,
        bySubCategory: groupedBySubCategory.map(([subCategory, entries]) => {
          const incidentsCount = entries.reduce((a, b) => a + b.incidentsCount, 0);
          const weight = entries.reduce((a, b) => a + b.weight, 0);
          let subcat = {
            subCategory,
            incidentsCount,
            incidentRate: totalReceivedWeight ? (incidentsCount * multiplier) / totalReceivedWeight : 0,
            percentOfTotalVolume: totalReceivedWeight ? weight / totalReceivedWeight : 0,
            weight,
            claimDollars: entries.reduce((a, b) => a + b.claimDollars, 0),
            incidentsPerUnit: totalReceivedWeight ? incidentsCount / totalReceivedWeight : 0,
          };
          if (activePackerPlants[0] && activePackerPlants[0].bottomTenPackerPlants) {
            const bottomTenPackerPlants = d.byPackerPlant.filter(x =>
              activePackerPlants[0].bottomTenPackerPlants.map(b => b.id).includes(x.packerPlantId)
            );
            const tmp = bottomTenPackerPlants.map(x => {
              return { ...x, bySubCategory: x.byCategory.map(cc => cc.subCategories).flat() };
            });
            const incidentsCountBottomTen = tmp
              .map(x => x.bySubCategory.find(c => c.subCategory === subCategory)?.incidentsCount)
              .reduce((a, b) => a + b, 0);
            const weightBottomTen = tmp
              .map(x => x.bySubCategory.find(c => c.subCategory === subCategory)?.weight)
              .reduce((a, b) => a + b, 0);
            const receivedWeightBottomTen = totalReceivedWeight;
            const bottomTen = {
              incidentsCountBottomTen,
              incidentRateBottomTen: receivedWeightBottomTen
                ? (incidentsCountBottomTen * multiplier) / receivedWeightBottomTen
                : 0,
              percentOfTotalVolumeBottomTen: receivedWeightBottomTen ? weightBottomTen / receivedWeightBottomTen : 0,
              weightBottomTen,
              incidentsPerUnitBottomTen: tmp
                .map(x => x.bySubCategory.find(c => c.subCategory === subCategory)?.incidentsPerUnit)
                .reduce((a, b) => a + b, 0),
              claimDollarsBottomTen: tmp
                .map(x => x.bySubCategory.find(c => c.subCategory === subCategory)?.claimDollars)
                .reduce((a, b) => a + b, 0),
            };
            subcat = { ...subcat, ...bottomTen };
          }
          return subcat;
        }),
      };
    });
    return dataBySubCategory;
  }
);

// TODO not seemed to be rendered on UI, check and delete
export const selectQualityNCRsPackerDataBySubCategory = createSelector(
  selectQualityNCRsData,
  selectQualityNCRsActivePackerPlants,
  (data, activePackerPlants) => {
    if (!data) return null;

    let packerPackerPlantsIds = [];
    if (activePackerPlants[0] && activePackerPlants[0].includesPackerPlants) {
      packerPackerPlantsIds = activePackerPlants[0].includesPackerPlants.map(x => x.id);
    }

    const dataBySubCategory = data.map(d => {
      const allSubCategoryData = [];
      let allPackerPlants = [];

      allPackerPlants = d.byPackerPlant.filter(x => packerPackerPlantsIds.includes(x.packerPlantId));

      allPackerPlants.forEach(p => {
        p.byCategory.forEach(c => {
          allSubCategoryData.push(...c.subCategories.map(n => ({ ...n, packerPlantId: p.packerPlantId })));
        });
      });

      const groupedBySubCategory = Array.from(
        group(allSubCategoryData, x => {
          return x.subCategory;
        })
      );

      return {
        date: d.date,
        bySubCategory: groupedBySubCategory.map(([subCategory, entries]) => {
          return {
            subCategory,
            byPackerPlant: entries,
          };
        }),
      };
    });
    return dataBySubCategory;
  }
);

export default selectQualityNCRsDataBySubCategory;
