import { Col, Layout, Result, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DetailPageHeading from '../../../../shared/CardTitle/CardTitle';
import LoadingOverlay from '../../../../shared/LoadingOverlay/LoadingOverlay';
import TabBar from '../../../../shared/TabBar';
import {
  INCIDENTS_OPTIONS,
  TIME_RANGE_OPTIONS,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsConfig';
import {
  fetchQualityNCRsData,
  setQualityNCRsActivePackerPlants,
  setQualityNCRsSelectedPackerPlantId,
  setQualityNCRsMarketKey,
  setQualityNCRsRegion,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsReducers';
import {
  selectAvailableCategories,
  selectAvailableCategoriesWithSubcategories,
} from '../../../../slices/quality-details-ncrs/selectors/selectAvailableCategories';
import selectAvailablePackerPlants from '../../../../slices/quality/selectors/selectAvailablePackerPlants';
import selectAvailableYears from '../../../../slices/quality-details-ncrs/selectors/selectAvailableYears';
import {
  selectQualityNCRsError,
  selectQualityNCRsIncidents,
  selectQualityNCRsLoading,
  selectQualityNCRsSelectedPackerPlantId,
  selectQualityNCRsTimeRange,
} from '../../../../slices/quality-details-ncrs/selectors/selectBasicDetailsNcrsData';
import { selectQualityNCRsFetchParameters } from '../../../../slices/quality-details-ncrs/selectors/selectQualityNCRsFetchParameters';
import { setMarketKey } from '../../../../slices/quality/qualityReducers';
import { setActiveMarket } from '../../../../slices/markets/marketReducers';
import QualityDetailsNCRsCharts from './QualityDetailsNCRsCharts';
import QualityDetailsNCRsFilters from './QualityDetailsNCRsFilters';
import QualityDetailsNCRsSidebar from './QualityDetailsNCRsSidebar';
import { MARKETS } from '../../../../config/markets';
import styles from './QualityDetailsNCRs.module.css';

// This container is for the Quality Details NCRs page as a whole and manages data

export default function QualityDetailsNCRs() {
  const dispatch = useDispatch();
  const error = useSelector(selectQualityNCRsError);
  const loading = useSelector(selectQualityNCRsLoading);
  const availableYears = useSelector(selectAvailableYears);
  const availableCategories = useSelector(selectAvailableCategories);
  const availableCategoriesWithSubcategories = useSelector(selectAvailableCategoriesWithSubcategories);
  const availableMarkets = ['Domestic', 'Export'];
  const availablePackerPlants = useSelector(selectAvailablePackerPlants);
  const selectedPackerPlantId = useSelector(selectQualityNCRsSelectedPackerPlantId);
  const incidentsMode = useSelector(selectQualityNCRsIncidents);
  const timeRange = useSelector(selectQualityNCRsTimeRange);
  const { market: marketParam } = useParams();

  const [dataColors, setDataColors] = React.useState({});

  React.useEffect(() => {
    if (selectedPackerPlantId && availablePackerPlants.length > 0) {
      const pac = availablePackerPlants.find(x => x.id === selectedPackerPlantId);
      if (
        ((incidentsMode === INCIDENTS_OPTIONS.TOTAL || !incidentsMode) &&
          timeRange === TIME_RANGE_OPTIONS.HISTORICAL) ||
        incidentsMode === INCIDENTS_OPTIONS.BY_CATEGORY ||
        incidentsMode === INCIDENTS_OPTIONS.BY_SUBCATEGORY
      ) {
        dispatch(setQualityNCRsActivePackerPlants([pac, undefined]));
      } else {
        dispatch(setQualityNCRsActivePackerPlants([pac]));
      }
      dispatch(setQualityNCRsSelectedPackerPlantId(undefined));
    }
  }, [selectedPackerPlantId, availablePackerPlants]);

  React.useEffect(() => {
    if (marketParam === MARKETS.Australia.url) {
      dispatch(setActiveMarket(MARKETS.Australia.key));
      dispatch(setMarketKey(MARKETS.Australia.key));
      dispatch(setQualityNCRsMarketKey(MARKETS.Australia.key));
      dispatch(setQualityNCRsRegion(MARKETS.All.key));
    }
    if (marketParam === MARKETS.USA.url) {
      dispatch(setActiveMarket(MARKETS.USA.key));
      dispatch(setMarketKey(MARKETS.USA.key));
      dispatch(setQualityNCRsMarketKey(MARKETS.USA.key));
      dispatch(setQualityNCRsRegion(MARKETS.All.key));
    }
  }, [marketParam, setActiveMarket, setMarketKey, setQualityNCRsMarketKey]);

  // Parameters for data fetch
  const fetchParameters = useSelector(selectQualityNCRsFetchParameters);

  React.useEffect(() => {
    dispatch(fetchQualityNCRsData(fetchParameters));
  }, [dispatch, fetchParameters]);

  const [selectedYears, setSelectedYears] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = React.useState([]);
  const [selectedMarkets, setSelectedMarkets] = React.useState([]);
  const [packerMode, setPackerMode] = React.useState(undefined);
  const [breakdownPackerPlant, setBreakdownPackerPlant] = React.useState(undefined);
  const [highlightedPackerPlants, setHighlightedPackerPlants] = React.useState([]);

  if (error) {
    return (
      <div>
        <Result title="Error" status="error" subTitle={error} />
      </div>
    );
  }

  return (
    <div
      style={{ position: 'relative' }}
      className={styles.QualityDetailsNCRs_quality}
      data-test="QualityDetailsNCRs-component"
    >
      <TabBar setActiveMarketAction={setMarketKey} />
      <div style={{ paddingLeft: 24 }}>
        <DetailPageHeading>NCRs</DetailPageHeading>
      </div>
      <Layout>
        <Layout.Content>
          <Row gutter={[16, 32]} style={{ padding: `12px 24px 6px` }}>
            <QualityDetailsNCRsFilters />
          </Row>
          <Row gutter={[0, 32]} style={{ backgroundColor: '#ffffff', padding: `12px 24px 6px` }}>
            <Col span={18} style={{ paddingRight: '64px' }}>
              {!loading && (
                <QualityDetailsNCRsCharts
                  dataColors={dataColors}
                  selectedYears={selectedYears}
                  selectedCategories={selectedCategories}
                  selectedSubcategories={selectedSubcategories}
                  selectedMarkets={selectedMarkets}
                  packerMode={packerMode}
                  breakdownPackerPlant={breakdownPackerPlant}
                  highlightedPackerPlants={highlightedPackerPlants}
                />
              )}
            </Col>
            <Col span={6}>
              <QualityDetailsNCRsSidebar
                dataColors={dataColors}
                setDataColors={setDataColors}
                availableYears={availableYears}
                selectedYears={selectedYears}
                setSelectedYears={setSelectedYears}
                availableCategories={availableCategories}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                availableCategoriesWithSubcategories={availableCategoriesWithSubcategories}
                selectedSubcategories={selectedSubcategories}
                setSelectedSubcategories={setSelectedSubcategories}
                availableMarkets={availableMarkets}
                selectedMarkets={selectedMarkets}
                setSelectedMarkets={setSelectedMarkets}
                availablePackerPlants={availablePackerPlants}
                packerMode={packerMode}
                setPackerMode={setPackerMode}
                breakdownPackerPlant={breakdownPackerPlant}
                setBreakdownPackerPlant={setBreakdownPackerPlant}
                highlightedPackerPlants={highlightedPackerPlants}
                setHighlightedPackerPlants={setHighlightedPackerPlants}
              />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
      <LoadingOverlay loading={loading} />
    </div>
  );
}
