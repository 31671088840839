import get from 'lodash/get';
import queryString from 'query-string';

export function prepareQueryStringFromReportsList(reportParamsList) {
  const params = reportParamsList.reduce((agg, paramConfig) => {
    agg[paramConfig.name] = paramConfig.default ? JSON.parse(paramConfig.default) : '';
    return agg;
  }, {});
  return queryString.stringify(params);
}

/*
 Convert JSON String to comma separated string. Or reversely.
 E.g ["option1", "option2"] => option1,option2
 The Options and Default of params need this kind of conversion.
*/
export function paramOptionsToJsonString(param) {
  function prepareObjectsArray(values) {
    return JSON.stringify(
      values.split(',').reduce((agg, item) => {
        item = item.trim();
        const matches = item.match(/([\w. _-]+)\(([-&\w. _]+)\)/);
        if (matches) {
          // For multiple select, format as value(label)
          const label = matches[2];
          const value = matches[1];
          agg.push({ [label]: value });
        } else {
          agg.push({ [item]: item });
        }
        return agg;
      }, [])
    );
  }
  return param.param_type === 'multi-select' ? prepareObjectsArray(param.options) : JSON.stringify(param.options);
}

export function paramDefaultToJsonString(param) {
  if (!param.default) return '';
  return param.param_type === 'multi-select'
    ? JSON.stringify(param.default.split(',').map(val => val.trim()))
    : JSON.stringify(param.default);
}

export function paramOptionsFromJsonString(param) {
  if (!param.options) return '';
  return param.param_type === 'multi-select' && param.options
    ? JSON.parse(param.options)
        .map(item => {
          const value = item[Object.keys(item)[0]];
          const label = Object.keys(item)[0];
          return `${value}(${label})`;
        })
        .join(',')
    : JSON.parse(param.options);
}

export function paramDefaultFromJsonString(param) {
  if (!param.default) return '';
  return param.param_type === 'multi-select' && param.default
    ? JSON.parse(param.default).join(',')
    : JSON.parse(param.default);
}

export function createReportPayload(stateParams, props) {
  const newPermissions = [];
  stateParams.permittedRoles.forEach(roleId => {
    if (!roleId) {
      return;
    }
    const found = stateParams.permissions
      ? stateParams.permissions.find(e => e.role_id == roleId && !e.user_id)
      : false;
    if (found) {
      // No change, copy over.
      newPermissions.push(found);
    } else {
      // Adding new permitted role.
      newPermissions.push({
        role_id: roleId,
        user_id: null,
      });
    }
  });
  stateParams.permittedUsers.forEach(userId => {
    if (!userId) {
      return;
    }
    const found = stateParams.permissions
      ? stateParams.permissions.find(e => e.user_id == userId && !e.role_id)
      : false;
    if (found) {
      // No change, copy over.
      newPermissions.push(found);
    } else {
      // Adding new permitted user.
      newPermissions.push({
        role_id: null,
        user_id: userId,
      });
    }
  });

  return {
    name: stateParams.name,
    category: props.categories.find(category => category.id === stateParams.category).name,
    description: stateParams.description,
    sql: stateParams.sql,
    params: stateParams.params
      .filter(param => param.name)
      .map(param => ({
        ...param,
        name: param.name && param.name.toLowerCase().replace(/[\W]+/g, '_'),
        label: param.name,
        default: paramDefaultToJsonString(param),
        options: paramOptionsToJsonString(param),
      })),
    permissions: newPermissions,
  };
}

export function prepareSelfServiceReportPayload(stateParams, props) {
  const requiredFields = ['name', 'category', 'sql'];
  let hasRequiredFieldsMissing = [];
  hasRequiredFieldsMissing = requiredFields.filter(
    field => stateParams[field] === '' || (stateParams[field] instanceof Array && stateParams[field].length <= 0)
  );
  const requiredPermissionFields = ['permittedRoles', 'permittedUsers'];
  if (requiredPermissionFields.every(field => stateParams[field].length == 0)) {
    hasRequiredFieldsMissing.push('permissions');
  }

  let payload = null;
  if (hasRequiredFieldsMissing.length == 0) {
    payload = createReportPayload(stateParams, props);
  }
  return { hasRequiredFieldsMissing, payload };
}

export function formatReportConfig(props) {
  const {
    reportConfig,
    categories,
    roles,
    users,
    match: { params },
  } = props;
  reportConfig.category = categories && categories.find(c => c.name === reportConfig.category).id;
  reportConfig.permittedRoles = reportConfig.permissions.map(p => p.role_id);
  reportConfig.permittedUsers = reportConfig.permissions.map(p => p.user_id);
  reportConfig.params = reportConfig.params.map(param => ({
    ...param,
    name: param.label,
    default: paramDefaultFromJsonString(param),
    options: paramOptionsFromJsonString(param),
  }));
  return reportConfig;
}
