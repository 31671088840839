import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  Spinner,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
  Center,
  IconButton,
} from '@chakra-ui/react';
import ResultsGrid from '../../../components/basic/ResultsGrid';
import DashboardSectionTile from '../../../components/core/DashboardSectionTile';
import { ArrowBackIcon, WarningIcon } from '@chakra-ui/icons';
import './DataImportPreview.scss';
import Tooltip from '../../../components/quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const DataImportPreview = ({
  previewTableData,
  summaryTableData,
  onClickBackButton,
  onClickConfirmButton,
  onClickFinishButton,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getPOsCount = () => {
    return new Set(summaryTableData.rows.map(row => row.po_id)).size;
  };

  return (
    <>
      <DashboardSectionTile
        title={
          <>
            Preview Upload&nbsp;
            <Tooltip
              content="Display a preview of the fields uploaded in the CSV or Excel file for verification"
              placement="right"
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
          </>
        }
        padding="22px 16px 49px 15px"
      >
        {previewTableData && (
          <Box marginLeft="10px !important">
            <ResultsGrid
              id="DataImportPreviewUpload"
              className="data_import_preview_results_grid"
              {...previewTableData}
              minHeight="fit-content"
              rowHeight={() => 57}
            />
            <Text fontWeight="700" fontSize="12px" marginTop="23px">
              Total Records: {previewTableData.rows.length}
            </Text>
          </Box>
        )}
      </DashboardSectionTile>
      <DashboardSectionTile
        title={
          <>
            Summary&nbsp;
            <Tooltip
              content="Show a summary of changes, including which fields need to be updated from old values to new values"
              placement="right"
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
          </>
        }
        padding="22px 16px 49px 15px"
        marginTop="55px"
      >
        {summaryTableData && (
          <Box marginLeft="10px !important">
            <ResultsGrid
              id="DataImportPreviewSummary"
              className="data_import_preview_results_grid"
              {...summaryTableData}
              minHeight="fit-content"
              rowHeight={() => 57}
            />
          </Box>
        )}
        {!summaryTableData && (
          <Center>
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="actionSecondary.default" size="xl" />
          </Center>
        )}
      </DashboardSectionTile>
      <ButtonGroup
        colorScheme="actionPrimary"
        justifyContent="flex-end"
        width="100%"
        marginTop="39px"
        fontSize="16px"
        spacing="15px"
      >
        {onClickBackButton && (
          <Button
            variant="outline"
            width="117px"
            onClick={onClickBackButton}
            leftIcon={<ArrowBackIcon />}
            fontSize="inherit"
            fontWeight="600"
          >
            Back
          </Button>
        )}
        {onClickConfirmButton && (
          <Button
            width="110px"
            disabled={!summaryTableData?.rows?.length}
            fontSize="inherit"
            fontWeight="900"
            onClick={onOpen}
          >
            Confirm
          </Button>
        )}
        {onClickFinishButton && (
          <Button width="110px" onClick={onClickFinishButton} fontSize="inherit" fontWeight="900">
            Finish
          </Button>
        )}
      </ButtonGroup>
      {summaryTableData && previewTableData ? (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent minWidth="600px">
            <ModalHeader fontSize="20px" borderBottomWidth="1px">
              <WarningIcon color="#545D69" /> &nbsp;Do you wish to continue?
            </ModalHeader>
            <ModalCloseButton top="12px" />
            <ModalBody>
              <Center minHeight="264px">
                <Text fontSize="18px">
                  You are about to make <b>{summaryTableData.rows.length} changes</b> to{' '}
                  <b>{getPOsCount()} PO&rsquo;s.</b>
                </Text>
              </Center>
            </ModalBody>
            <ModalFooter borderTopWidth="1px">
              <ButtonGroup colorScheme="actionPrimary" marginLeft="auto" fontSize="16px" fontWeight="900" spacing="9px">
                <Button
                  variant="outline"
                  fontSize="inherit"
                  fontWeight="inherit"
                  height="40px"
                  width="130px"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    onClose();
                    onClickConfirmButton();
                  }}
                  fontSize="inherit"
                  fontWeight="inherit"
                  height="40px"
                  width="126px"
                >
                  Continue
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
};

DataImportPreview.propTypes = {
  previewTableData: PropTypes.arrayOf(PropTypes.any).isRequired,
  summaryTableData: PropTypes.arrayOf(PropTypes.any).isRequired,
  onClickBackButton: PropTypes.func,
  onClickConfirmButton: PropTypes.string,
  onClickFinishButton: PropTypes.func,
};

export default DataImportPreview;
