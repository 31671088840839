import React from 'react';
import { Radio } from 'antd';
import { TIME_SCALES } from '../../../../slices/quality/qualityConfig';
import styles from './FilterTimeScale.module.css';

// todo :
// 1. Show how the data could be structured, and how it refetches as filters change

export default function FilterTimeScale({ value, onChange }) {
  return (
    <Radio.Group
      onChange={e => onChange(e.target.value)}
      defaultValue={value}
      className={styles.FilterTimeScale_radioGroup}
    >
      <Radio.Button key={TIME_SCALES.MONTHLY} value={TIME_SCALES.MONTHLY}>
        {TIME_SCALES.MONTHLY}
      </Radio.Button>
      {/* <Radio.Button key={TIME_SCALES.ANNUALLY} value={TIME_SCALES.ANNUALLY} disabled={true}>
        {TIME_SCALES.ANNUALLY}
      </Radio.Button> */}
    </Radio.Group>
  );
}
