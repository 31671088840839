import React from 'react';
import { useSelector } from 'react-redux';
import { format, subMonths } from 'date-fns';
import { Typography, Row, Col, Card } from 'antd';

import { selectMarketsQualityClaimData } from '../../../slices/markets/selectors/selectMarketsQualityClaimData';
import { selectMarketsQualityNcrData } from '../../../slices/markets/selectors/selectMarketsQualityNcrData';
import { selectMarketsQualityScorecardData } from '../../../slices/markets/selectors/selectMarketsQualityScorecardData';
import { selectMarketsTimePeriod, selectActiveMarket } from '../../../slices/markets/selectors/selectBasicMarketData';

import QualityNCRs from '../../../components/analyticDashboard/markets/QualityNCRs/QualityNCRs';
import QualityPackerPlantScorecard from '../../../components/analyticDashboard/markets/QualityPackerPlantScorecard/QualityPackerPlantScorecard';
import QualityClaims from '../../../components/analyticDashboard/markets/QualityClaims/QualityClaims';
import IUnleashClassFlagProvider from '../../../components/unleash/UnleashClassFlagProvider';
import Constants from '../../../Constants';
// import { ChevronRight } from '../../../shared/Icons';

// This container specifically serves the quality section
function Quality(/* props */) {
  const qualityNcrData = useSelector(selectMarketsQualityNcrData);
  const scorecardData = useSelector(selectMarketsQualityScorecardData);
  const claimData = useSelector(selectMarketsQualityClaimData);
  const timePeriod = useSelector(selectMarketsTimePeriod);
  const activeMarket = useSelector(selectActiveMarket);

  const previousMonth = subMonths(new Date(), 1);

  return (
    <Card>
      <Typography.Title level={3} style={{ fontWeight: 'normal' }}>
        Quality
        <div style={{ display: 'inline-block', width: '18px' }}>{/* <ChevronRight /> */}</div>
      </Typography.Title>{' '}
      {/* TODO: make dynamic */}
      <Typography.Text style={{ color: '#fc5d36', textTransform: 'uppercase' }}>
        {format(previousMonth, 'MMMM yyyy')}
      </Typography.Text>
      <br />
      <br />
      <Row gutter={[16, 32]}>
        <Col span={12}>
          <Typography.Title level={4}>
            {qualityNcrData ? qualityNcrData.total : ''} NCRs
            <div style={{ display: 'inline-block', width: '18px' }}>{/* <ChevronRight /> */}</div>
          </Typography.Title>
          <QualityNCRs qualityNcrData={qualityNcrData} />
        </Col>
        {/* <Col span={8}>
          <Typography.Title level={4}>
            Establishment report card
            <div style={{ display: 'inline-block', width: '18px' }}>
            <ChevronRight /></div>
          </Typography.Title>
          <QualityPackerPlantScorecard scorecardData={scorecardData} />
          </Col>
        */}
        <Col span={12}>
          <Typography.Title level={4}>
            Claims
            <div style={{ display: 'inline-block', width: '18px' }}>{/* <ChevronRight /> */}</div>
          </Typography.Title>
          <QualityClaims claimData={claimData} activeMarket={activeMarket} />
        </Col>
      </Row>
    </Card>
  );
}

const QualityUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.MARKET_DASHBOARD_SCALE_QUALITY}
        show={<Quality {...props} />}
        hide=""
      />
    </>
  );
};

export default QualityUnleashController;
