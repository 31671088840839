import React, { Component } from 'react';
import Constants from '../../../Constants';
import ReleaseTemplate from './ReleaseTemplate';

class Release extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const coldstore_status = Constants.ORDER_STATUSES.PENDING_COLDSTORE_RELEASE;
    return (
      <ReleaseTemplate
        coldstore_status={coldstore_status}
        orders={this.props.orders}
        grinders={this.props.grinders}
        transporters={this.props.transporters}
        token={this.props.user.token}
        user={this.props.user}
        dispatch={this.props.dispatch}
        handleNavigation={this.props.handleNavigation}
        previewOrders={this.props.previewOrders}
        coldstoreSendEmailError={this.props.coldstoreSendEmailError}
        notifying_action_groups={this.props.notifying_action_groups}
        isColdstoreEmailSent={this.props.isColdstoreEmailSent}
        headerMessage="Release scheduled loads with pre-generated emails"
        noDataHeaderMessage="There are no scheduled loads available to release. Please visit Coldstore Schedule tab to populate orders to release"
        internalPoLabel={this.props.internalPoLabel}
        title="Release scheduled loads with pre-generated emails"
      />
    );
  }
}

export default Release;
