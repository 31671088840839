export const ACTIVE_ITEM_PICKED = 'ACTIVE_ITEM_PICKED'; // for editing page
export const CREATE_NEW_THING = 'CREATE_NEW_THING';
export const SELECT_CURRENT_MODAL_DETAIL = 'SELECT_CURRENT_MODAL_DETAIL'; // for viewing pages
export const CLOSE_ERROR = 'CLOSE_ERROR'; // for viewing pages

// All actions files contain fetch, edit, delete, post
// and some error handling

const address_field = {
  line1: '',
  line2: '',
  city: '',
  post_code: '',
  state: '',
  country: '',
};

// Set default object props for when a new object is initialized
const defaultObjects = {
  end_users: {
    type: 'end_user',
    id: '',
    is_active: true,
    common_name: '',
    legal_name: '',
    organisation_ids: [],
    grinders_uids: [],
    pricing_strategy: '',
    address: address_field,
  },
  grinders: {
    type: 'grinder',
    name: '',
    uid: '',
    organisation_ids: [],
    address: address_field,
  },
  roles: {
    type: 'role',
    name: '',
  },
  packer_plants: {
    type: 'establishment',
    name: '',
    id: '',
    organisation_ids: [],
    packer_id: '',
    address: address_field,
  },
  packers: {
    type: 'packer',
    id: '',
    packer_plant_ids: [],
    name: '',
    address: address_field,
    company_name: '',
    organisation_ids: [],
  },
  organisations: {
    type: 'organisations',
    name: '',
    uid: '',
    org_type: '',
    parent_id: '',
  },
  configs: {
    type: 'configs',
    name: '',
    value_int: '',
    value_str: '',
    value_float: '',
  },
  users: {
    type: 'users',
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    organisation_id: '',
    role_id: '',
    contacts: [],
    business_use: [],
  },
  input_products: {
    type: 'input_product',
    uid: '',
    name: '',
    cl: '',
    fresh: '',
    claim: '',
    organisation_ids: [],
  },
  output_products: {
    type: 'output_product',
    uid: '',
    name: '',
    description: '',
    cl: '',
    min_fresh: '',
    min_50s: '',
    claim: '',
    organisation_ids: [],
  },
  packer_input_product: {
    type: 'packer_input_product',
    uid: '',
    price: '',
    quantity: '',
    input_product_uid: '',
    price_type: '',
  },
  transporters: {
    type: 'transporters',
    uid: '',
    scac: '',
    name: '',
    phone_number: '',
    address: address_field,
  },
  // establishment_groups: {
  //   type: 'establishment_groups',
  //   name: '',
  //   packer_plant_ids: [],
  // },
  form_fields: {
    type: 'form_fields',
    field_type: '',
    value: '',
  },
  load_sizes: {
    type: 'load_sizes',
    origin: '',
    destination: '',
    maximum_load_size: '',
    minimum_load_size: '',
    unit_of_measure_id: '',
  },
  units_of_measure: {
    type: 'units_of_measure',
    name: '',
    conversion_rate: '',
    full_name: '',
  },
  ports: {
    type: 'ports',
    name: '',
    lat: '',
    lon: '',
    timezone: '',
    address: address_field,
  },
  shipping_lines: {
    type: 'shipping_lines',
    name: '',
    timezone: '',
    address: address_field,
  },
  voyage_legs: {
    type: 'voyage_legs',
    vessel_name: '',
    voyage_number: '',
    shipping_line_id: '',
    origin_port_id: '',
    destination_port_id: '',
    eta: '',
    etd: '',
  },
  voyage: {
    type: 'voyage',
    vessel_name: '',
    voyage_number: '',
    shipping_line_id: '',
    load_port_id: '',
    discharge_port_id: '',
    eta: '',
    etd: '',
    tranship_port_id: '',
  },
  cold_stores: {
    type: 'cold_stores',
    location_name: '',
    company_name: '',
    lon: '',
    lat: '',
    timezone: '',
    initial_free_time: '',
    initial_price: '',
    initial_price_currency: 'USD',
    roll_window: '',
    roll_window_price: '',
    roll_window_price_currency: 'USD',
    address: address_field,
  },
  fdr_items: {
    item: '',
    category: '',
    description: '',
  },
  fdr_rates: {
    fdr_item_id: null,
    grinder_uid: null,
    country_of_origin: '',
    unit_of_measure_id: null,
    currency: null,
    effective_from: null,
    rate: null,
  },
  interests: {
    name: '',
    effective_from: null,
    rate: null,
  },
  fecs: {
    type: 'fecs',
    fec_confirmation_number: '',
    fec_description: '',
    provider: '',
    buy_currency: '',
    sell_currency: '',
    fec_rate: 0,
    spot_rate: 0,
    fec_maturity_date: null,
    total_fec_value: 0,
  },
  finance_factorings: {
    grinder_uid: null,
    rate: null,
    effective_from: null,
    should_lock: 0,
  },
};

export function pickActiveItem(type, item) {
  if (!type && !item) {
    return {
      type: ACTIVE_ITEM_PICKED,
      payload: false,
    };
  }
  return {
    type: ACTIVE_ITEM_PICKED,
    payload: {
      type,
      item,
    },
  };
}

// When creating new object, select correct default
export function createNewThing(type) {
  let newThing;
  if (type in defaultObjects) {
    newThing = { ...defaultObjects[type] };
  } else {
    newThing = {};
  }

  return {
    type: CREATE_NEW_THING,
    payload: {
      type,
      item: newThing,
    },
  };
}

export function selectCurrentModalDetail(details) {
  return {
    type: SELECT_CURRENT_MODAL_DETAIL,
    payload: details,
  };
}

export function closeError(error) {
  return {
    type: CLOSE_ERROR,
    payload: error,
  };
}
