import {
  BEEF_INPUT_PRODUCTS_FETCHED,
  BEEF_INPUT_PRODUCT_ADDED,
  BEEF_INPUT_PRODUCT_EDIT,
  BEEF_INPUT_PRODUCT_REMOVED,
} from '../actions/actions_input_products';

const input_products = (input_products = [], action) => {
  switch (action.type) {
    case BEEF_INPUT_PRODUCTS_FETCHED:
      return action.payload;
    case BEEF_INPUT_PRODUCT_ADDED:
      var newBeefInputProducts = JSON.parse(JSON.stringify(input_products));
      newBeefInputProducts.push(action.payload);
      return newBeefInputProducts;
    case BEEF_INPUT_PRODUCT_EDIT:
      var newBeefInputProducts = JSON.parse(JSON.stringify(input_products));
      return replaceBeefInputProduct(newBeefInputProducts, action.payload);
    case BEEF_INPUT_PRODUCT_REMOVED:
      var newBeefInputProducts = JSON.parse(JSON.stringify(input_products));
      return removeBeefInputProduct(newBeefInputProducts, action.payload);
    default:
      return input_products;
  }
};

function replaceBeefInputProduct(allBeefInputProducts, editedBeefInputProduct) {
  const newBeefInputProducts = [];
  allBeefInputProducts.forEach(function (element) {
    if (element.uid === editedBeefInputProduct.uid) {
      newBeefInputProducts.push(editedBeefInputProduct);
    } else {
      newBeefInputProducts.push(element);
    }
  });
  return newBeefInputProducts;
}

function removeBeefInputProduct(allBeefInputProducts, removedBeefInputProduct) {
  const newBeefInputProducts = [];
  allBeefInputProducts.forEach(function (element) {
    if (element.uid !== removedBeefInputProduct.uid) {
      newBeefInputProducts.push(element);
    }
  });
  return newBeefInputProducts;
}

export const validCLsSelector = state => {
  return Object.keys(
    state.input_products.reduce((acc, curr) => {
      acc[curr.cl] = true;
      return acc;
    }, {})
  );
};

export default input_products;
