import React, { useEffect, useState } from 'react';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import { Box, Heading, useDisclosure } from '@chakra-ui/react';
import { ChatItem, VStackLayout } from '../Components/index';
import CommunicationDrawer from '../Forms/CommunicationDrawer';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { fetchNCRChatChannelMessages, fetchNCRChatUnreadMessages, fetchNCRChatChannels } from '../../../actions/action_ncr_chat';
import { convertToCamelCase } from '../../../helpers';
import { connect } from 'react-redux';
import { checkIfIsAdminUser } from '../../../utils'
const CommunicationTab = ({ user, ncrs: { currentNCR }, token, ncrChat, dispatch, packers, packerPlants }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { recordNo } = currentNCR;
  const [currentChatChannelId, setCurrentChatChannelId] = useState(null);
  const isAdminUser = checkIfIsAdminUser(user);

  useEffect(() => {
    if (recordNo) dispatch(fetchNCRChatChannels(recordNo, token));
  }, [recordNo]);

  useEffect(() => {
    if (recordNo) dispatch(fetchNCRChatUnreadMessages(recordNo, token));
  }, [recordNo]);

  const handleCommDrawerClose = () => {
    onClose();
    dispatch(fetchNCRChatChannels(recordNo, token));
  };

  return (
    <DashboardSectionTile
      padding="2px 33px 22px 21px"
      marginTop="2px"
      position="relative"
      borderTopLeftRadius={0}
      headerFlexStyle={{
        justifyContent: 'space-between',
      }}
      minHeight="612px"
    >
      <VStackLayout spacing="28px" paddingRight="0">
        <Heading fontFamily="Poppins" fontSize="15px" color="accent.one.default">
          Communication
        </Heading>
        {ncrChat.channels.map(channel => {
          let data = {}
          let mostRecentMessage = {};
          if (channel?.party === 'packer' && (user?.role?.accessList.includes('ncr_packer') || user?.role?.accessList.includes('ncr_admin'))) {
            mostRecentMessage = channel?.messages.reduce((latest, message) => {
              if (message.created > latest.created) {
                return message;
              }
              return latest;
            }, channel?.messages[0]);
            data = channel;
          } else if (channel?.party === 'grinder' && (user?.role?.accessList.includes('ncr_grinder') || user?.role?.accessList.includes('ncr_admin'))) {
            mostRecentMessage = channel?.messages.reduce((latest, message) => {
              if (message.created > latest.created) {
                return message;
              }
              return latest;
            }, channel?.messages[0]);
            data = channel;
          }
          let chatUnreadMessages;
          if(ncrChat?.unreadMessages) {
            chatUnreadMessages = ncrChat?.unreadMessages[channel.id];
          }
          let chatGroup = '';
          if (isAdminUser) {
            chatGroup = channel?.party || 'Admin';
            if (chatGroup === 'grinder') {
              chatGroup = chatGroup+ ': ' +currentNCR?.grinder?.name;
            } else if (chatGroup === 'packer') {
              const packerId = get(
                packerPlants.find(packerPlant => packerPlant.id === get(currentNCR, 'packerPlantId')),
                'packerId',
                null
              );
              const packerName = get(packers.find(packer => packer.id === packerId), 'name', '-')
              chatGroup = chatGroup+ ': ' +packerName;
            }
          }
          
          if(Object.keys(data).length > 0){
            return (
              <ChatItem
                key={data.id}
                onOpenDrawer={() => {
                  dispatch(fetchNCRChatChannelMessages(data.id, token));
                  setCurrentChatChannelId(data.id);
                  onOpen();
                }}
                groupType={chatGroup}
                mostRecentMessage={mostRecentMessage}
                unreadCount={chatUnreadMessages}
                // orgName={channel.orgName || ''}
              />
            );
          }
        })}
        <Box />
      </VStackLayout>
      <CommunicationDrawer
        {...{
          isOpen,
          onOpen,
          onClose: handleCommDrawerClose,
          user,
          mainView: 'communication_tab',
          recordNo: currentNCR.recordNo,
          chatChannelId: currentChatChannelId,
        }}
      />
    </DashboardSectionTile>
  );
};

CommunicationTab.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  ncrs: PropTypes.objectOf(PropTypes.any),
  ncrChat: PropTypes.objectOf(PropTypes.any),
  dispatch: PropTypes.func,
  token: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const result = {
    ncr_chat: state.ncr_chat,
    packers: state?.entityManager?.data?.packer?.entities,
    packerPlants: state?.entityManager?.data?.packer_plant?.entities,
  };
  return convertToCamelCase(result);
};

export default connect(mapStateToProps)(CommunicationTab);
