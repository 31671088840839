import axios from 'axios';
import Constants from '../Constants';

const BASE_URL = `${Constants.URL}analytic-dashboard`;

/**
 * Makes the API request for packerPlants in this market for this time period.
 *
 * TODO: Swap from fixtures to the real API
 *
 * @param {String} market
 * @param {[Date, Date]} timePeriod
 */
export default async function fetchPackerPlants(market, timePeriod) {
  const query = {
    product_type: Constants.PRODUCT_TYPE_LIST.BEEF,
    country_tab: market,
    time_scale: timePeriod,
  };
  const response = await axios.get(`${BASE_URL}/quality/packer-plants`, {
    params: query,
  });
  return response.data;
}

// export default async function fetchPackerPlants(market, timePeriod) {
//   const path = '/data/fixtures/packer-establishments.json';
//   const data = await fetch(`${process.env.PUBLIC_URL}${path}`).then(res => res.json());
//   return data.filter(packerPlant => packerPlant.country === market);
// }
