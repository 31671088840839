import Constants from '../Constants';

export const BEEF_INPUT_INVENTORY_LEVELS_FETCHED = 'BEEF_INPUT_INVENTORY_LEVELS_FETCHED';
export const BEEF_INPUT_INVENTORY_LEVELS_ERROR = 'BEEF_INPUT_INVENTORY_LEVELS_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadBeefInputInventoryLevels(grinderInventoryLevels) {
  return {
    type: BEEF_INPUT_INVENTORY_LEVELS_FETCHED,
    payload: grinderInventoryLevels,
  };
}

export function errorBeefInputInventoryLevels(error) {
  return {
    type: BEEF_INPUT_INVENTORY_LEVELS_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchBeefInputInventoryLevels(grinder, token) {
  return dispatch => {
    if (grinder) {
      const header = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      });
      return fetch(`${Constants.URL}inventory/grinders/${grinder.uid}/input_product_inventory_levels`, {
        method: 'GET',
        headers: header,
      })
        .then(response => Constants.handleErrors(response, dispatch, errorBeefInputInventoryLevels))
        .then(json => {
          dispatch(loadBeefInputInventoryLevels(json));
        })
        .catch(error => dispatch(errorBeefInputInventoryLevels(error)));
    }
    return dispatch(errorBeefInputInventoryLevels('grinder error'));
  };
}
