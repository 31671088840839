import React from 'react';
import styles from './AnnotationLayer.module.css';
import PropTypes from 'prop-types';

const height = 16;

function AnnotationLayer(props) {
  const { data, xScale, splitIndex, addNewAnnotation, editAnnotation, moduleAnnotations, getXValue } = props;

  const existingItems = moduleAnnotations.map((d, index) => {
    const int = getXValue(d);
    const x = xScale(int);
    const width = xScale.bandwidth();
    const onClick = () => editAnnotation(d);
    const i = data.findIndex(f => f.interval === int);
    const position = splitIndex !== -1 && i !== -1 && i >= splitIndex ? x + 20 : x;
    if (position) {
      return (
        <div
          role="link"
          onClick={onClick}
          onKeyDown={onClick}
          className={styles.existingItem}
          key={`edit-${Math.random()}`}
          tabIndex={0}
          style={{
            left: position,
            width,
            height,
          }}
        >
          {index + 1}
        </div>
      );
    }
    return false;
  });

  // TODO: get this out. its really messy that the x scale is diff to this.
  const addNewItems = data.map((d, i) => {
    const onClick = () => addNewAnnotation(d.rawInterval.year, d.rawInterval.interval);

    const x = xScale(d.interval);
    const width = xScale.bandwidth();
    return (
      <div
        role="link"
        className={styles.addNewItem}
        onClick={onClick}
        onKeyDown={onClick}
        key={`add-${Math.random()}`}
        tabIndex={0}
        style={{
          left: splitIndex !== -1 && i >= splitIndex ? x + 20 : x,
          width,
          height,
        }}
      >
        +
      </div>
    );
  });
  return (
    <div
      style={{
        width: 'calc(100% - 40px)',
        height: '26px',
        marginLeft: '40px',
        position: 'relative',
      }}
    >
      {addNewItems}
      {existingItems}
    </div>
  );
}

AnnotationLayer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      'Fat Beef': PropTypes.number,
      'Fat Beef-exposed': PropTypes.number,
      'Lean Beef': PropTypes.number,
      'Lean Beef-exposed': PropTypes.number,
      interval: PropTypes.string,
      rawInterval: PropTypes.shape({
        interval: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        year: PropTypes.number,
      }),
    })
  ),
  xScale: PropTypes.number,
  splitIndex: PropTypes.number,
  addNewAnnotation: PropTypes.func,
  editAnnotation: PropTypes.func,
  moduleAnnotations: PropTypes.instanceOf([]),
  getXValue: PropTypes.func,
};

export default AnnotationLayer;
