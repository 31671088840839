import React from 'react';
import { Box, Button, Text } from '@chakra-ui/react';


const FinalizeMessage = ({ finalizeSuccessMsg, copies, reset }) => {
  return (
    <Box className="finalize-div">
      {finalizeSuccessMsg ? (
        <Box p="10px" textAlign="right">
          <Text className="finalize-success-msg">
            Successfully created {copies} Packer Offer{copies > 1 ? 's' : false}
            <br />
          </Text>
          <Box>
            <Button className="btn btn-success" onClick={reset} ml="4px">
              Reset
            </Button>
          </Box>
        </Box>
      ) : (
        false
      )}
    </Box>
  );
};

export default FinalizeMessage;
