import { createSelector } from '@reduxjs/toolkit';
import { selectPackerPlants } from '../../quality-packer-plants/selectors/selectBasicPackerPlants';
import { selectQualityData } from './selectBasicQualityData';
import { getTwoPeriods } from '../../../modules/data/year-on-year';

export const selectQualityClaims = createSelector(selectQualityData, selectPackerPlants, (data, packerPlants) => {
  if (!data || !packerPlants) return null;
  const { claims } = data;
  const amountByTwoPeriods = getTwoPeriods(claims.amountByPeriod);
  const countByTwoPeriods = getTwoPeriods(claims.countByPeriod);
  const packerPlantsByCategory = (claims.packerPlants || []).map(x => {
    const packerPlantName = packerPlants.find(p => p.id === x.packerPlantId)?.name;
    return {
      packerPlantName,
      count: x.byCategory.reduce((a, b) => a + b.count, 0),
      claimAmount: x.byCategory.reduce((a, b) => a + b.claimAmount, 0),
      volume: x.byCategory.reduce((a, b) => a + b.volume, 0),
      incidentRate: x.byCategory.reduce((a, b) => parseFloat(a) + parseFloat(b.incidentRate), 0),
      ...x,
    };
  });

  const packerPlantsTotal = (claims.packerPlantsTotal || []).map(x => {
    const packerPlantName = packerPlants.find(p => p.id === x.packerPlantId)?.name;
    return {
      packerPlantName,
      ...x,
    };
  });

  return {
    ...claims,
    amountByPeriod: amountByTwoPeriods,
    countByPeriod: countByTwoPeriods,
    packerPlants: packerPlantsByCategory,
    packerPlantsTotal,
  };
});
