import React from 'react';
import { Bar } from 'react-chartjs-2';

const BuyPerformanceDetailsKPI = ({ details, clearDetails }) => {
  const options = {
    data: details.chartData,
    width: 4,
    height: 3,
    options: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        position: 'right',
        text: details.text,
        fontSize: 14,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              suggestedMin: -0.02,
              suggestedMax: 0.02,
              callback: (value, index, values) => `$${value.toFixed(4)}`,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: (value, index, values) => `$${value.yLabel.toFixed(4)}`,
        },
      },
    },
  };

  return (
    <div style={{ width: '350px', marginLeft: '30px', marginTop: '60px' }}>
      {details ? (
        <div>
          <Bar {...options} />
          <button className="btn btn-default btn-sm" onClick={clearDetails}>
            Clear Detail View
          </button>
        </div>
      ) : (
        false
      )}
    </div>
  );
};

export default BuyPerformanceDetailsKPI;
