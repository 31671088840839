import { getTenantFromDomain } from '../helpers/tenant';
import {
  CREATE_THEME_SUCCESS,
  CREATE_THEME_ERROR,
  FETCH_THEME_SUCCESS,
  FETCH_THEME_ERROR,
  RESET_THEME_LOADED_FLAG,
  RESET_THEME_EDITED_FLAG,
  UPDATE_EDITED_THEME_SUCCESS,
  SAVE_THEME_SUCCESS,
  SAVE_THEME_ERROR,
  SET_LOCAL_THEME_ENABLED_FLAG,
} from '../actions/actions_theme';

const initalTheme = {
  name: getTenantFromDomain(),
  is_active: true,
  is_edited: false,
  is_loaded: false,
  local_enabled: true,
  data: {},
};

const themes = (themeData = initalTheme, action) => {
  const currentTheme = JSON.parse(JSON.stringify(themeData));
  let update;
  switch (action.type) {
    case FETCH_THEME_SUCCESS:
      return { ...currentTheme, data: action.payload, is_loaded: true };
    case FETCH_THEME_ERROR:
      return {};
    case RESET_THEME_LOADED_FLAG:
      return { ...currentTheme, is_loaded: false };
    case RESET_THEME_EDITED_FLAG:
      return { ...currentTheme, is_edited: false };
    case SET_LOCAL_THEME_ENABLED_FLAG:
      return { ...currentTheme, local_enabled: action.payload };
    case UPDATE_EDITED_THEME_SUCCESS:
      update = { ...currentTheme, data: action.payload, is_edited: true };
      return update;
    case CREATE_THEME_SUCCESS:
      return { ...currentTheme, data: action.payload };
    case CREATE_THEME_ERROR:
      return {};
    case SAVE_THEME_SUCCESS:
      return { ...currentTheme, is_loaded: false };
    case SAVE_THEME_ERROR:
      return {};
    default:
      return { ...currentTheme };
  }
};

export default themes;
