import React from 'react';
import { Radio } from 'antd';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { MARKETS } from '../../../../config/markets';
import styles from './FilterMarket.module.css';
import PropTypes from 'prop-types';

const FilterMarket = ({ markets, activeMarket, onActiveMarketChange }) => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const { market } = useParams();

  const onChange = event => {
    onActiveMarketChange(event.target.value);
    const newPath = path.replace(':market', MARKETS[event.target.value].url);
    history.push(newPath);
  };

  React.useEffect(() => {
    if (market === MARKETS.Australia.url) {
      onActiveMarketChange(MARKETS.Australia.key);
    }
    if (market === MARKETS.USA.url) {
      onActiveMarketChange(MARKETS.USA.key);
    }
  }, [market, onActiveMarketChange]);

  return (
    <>
      <Radio.Group
        onChange={onChange}
        defaultValue={activeMarket}
        value={activeMarket}
        className={styles.FilterMarket_radioGroup}
      >
        {markets.map(m => (
          <Radio.Button key={m.key} value={m.key}>
            {m.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </>
  );
};

export default FilterMarket;

FilterMarket.propTypes = {
  markets: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeMarket: PropTypes.string.isRequired,
  onActiveMarketChange: PropTypes.func.isRequired,
};
