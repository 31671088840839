import { createSlice } from '@reduxjs/toolkit';
import { subMonths, subYears } from 'date-fns';
import { FREQUENCY, MARKET_FOCUS, TIME_MODE, TIME_RANGE, VOLUME, VOLUME_UNITS } from './marketsDetailsConfig';

export const monthsToSubtract = {
  [TIME_RANGE.ONE_MONTH]: 1,
  [TIME_RANGE.THREE_MONTHS]: 3,
  [TIME_RANGE.SIX_MONTHS]: 6,
  [TIME_RANGE.ONE_YEAR]: 12,
};

const timeRangeFrom = (date, timeRange) => {
  if (!monthsToSubtract[timeRange]) {
    throw new Error(`${timeRange} is not a relative time range.`);
  }
  return [subMonths(date, monthsToSubtract[timeRange]), date];
};

// ------------------------------------
// MARKETS VOLUME DETAIL SLICE
// ------------------------------------
const marketsVolumeDetailSlice = createSlice({
  name: 'markets-detail-volume',
  initialState: {
    market: MARKET_FOCUS.ALL,
    volume: VOLUME.ORDERED,
    groupBy: null,
    groupBySelection: [],
    aggregateBy: null,
    aggregateBySelection: [],
    timeMode: TIME_MODE.HISTORICAL,
    yearSelection: [],
    timeRangeSelection: TIME_RANGE.ONE_MONTH,
    timeRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
    // timeRange: [subYears(new Date(), 5), new Date()],
    units: VOLUME_UNITS.KG,
    frequency: FREQUENCY.WEEKLY,
    loading: true,
    error: null,
    data: {
      orders: {
        DOMESTIC: [],
        EXPORT: [],
      },
    },
  },
  reducers: {
    updateMarket(state, { payload }) {
      state.market = payload;
    },
    updateVolume(state, { payload }) {
      state.volume = payload;
    },
    updateGroupBy(state, { payload }) {
      // Not called directly, called from a thunk
      // cos groupBySelection needs the state change before it can be derived.
      state.groupBy = payload;
      state.groupBySelection = [];
    },
    updateGroupBySelection(state, { payload }) {
      state.groupBySelection = payload;
    },
    updateAggregateBy(state, { payload }) {
      state.aggregateBy = payload;
      state.aggregateBySelection = [];
    },
    updateAggregateBySelection(state, { payload }) {
      state.aggregateBySelection = payload;
    },
    updateTimeMode(state, { payload }) {
      state.timeMode = payload;
      state.timeRange = [subYears(new Date(), 5), new Date()];
    },
    updateYearSelection(state, { payload }) {
      state.yearSelection = payload;
    },
    /**
     * Pass in a timeRange, and if it's TIME_RANGE.CUSTOM you must
     * also pass from and to as Dates. Otherwise, it will be
     * calculated backwards from now.
     *
     * @param timeRange valueof TIME_RANGE  Which time range?
     * @param from? Date  Specified from if custom time range.
     * @param to? Date  Specified to if custom time range.
     */
    updateTime(state, { payload: { timeRangeSelection, timeRange } }) {
      state.timeRangeSelection = timeRangeSelection;
      let newTimeRange = timeRange;
      // Max time range has no from
      if (timeRangeSelection === TIME_RANGE.MAX) {
        newTimeRange = [subYears(new Date(), 5), new Date()];
        // Everything else but custom needs to calculate the timeRange
      } else if (timeRangeSelection !== TIME_RANGE.CUSTOM) {
        newTimeRange = timeRangeFrom(new Date(), timeRangeSelection);
      }

      state.timeRange = newTimeRange;
    },
    updateUnits(state, { payload }) {
      state.units = payload;
    },
    updateFrequency(state, { payload }) {
      state.frequency = payload;
    },
    marketsVolumeDetailDataRequest(state) {
      state.loading = true;
      state.error = null;
    },
    marketsVolumeDetailDataRequestSuccess(state, { payload }) {
      state.loading = false;
      state.data = payload;
      state.error = null;
    },
    marketsVolumeDetailDataRequestFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const {
  updateMarket,
  updateVolume,
  updateGroupBy,
  updateGroupBySelection,
  updateAggregateBy,
  updateAggregateBySelection,
  updateYearSelection,
  updateTime,
  updateTimeMode,
  updateUnits,
  updateFrequency,
  marketsVolumeDetailDataRequest,
  marketsVolumeDetailDataRequestSuccess,
  marketsVolumeDetailDataRequestFailure,
} = marketsVolumeDetailSlice.actions;

export default marketsVolumeDetailSlice.reducer;
