import React from 'react';
import PropTypes from 'prop-types';
// Local Deps
import SelectField from '../basic/SelectField';
import { isEmpty } from 'lodash';

function getValueFromOption(option) {
  if (!option) return '';
  return option.value || option.name;
}

export function findOptionMatch(options, value) {
  if (!options || isEmpty(options)) return null;
  return options.find(o => getValueFromOption(o) === value);
}

const propTypes = {
  closeMenuOnSelect: PropTypes.bool,
  defaultValue: PropTypes.any,
  height: PropTypes.string,
  inputHeight: PropTypes.string,
  isClearable: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  menuPortalTarget: PropTypes.node,
  disableGridMode: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType[(PropTypes.string, PropTypes.number, PropTypes.node)],
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.instanceOf(Object)]),
};

function SelectEditor({
  closeMenuOnSelect,
  defaultValue,
  height,
  inputHeight,
  isClearable,
  isCreatable,
  isMulti,
  isSearchable,
  label,
  menuPortalTarget,
  name,
  onChange,
  options,
  placeholder,
  value,
  disableGridMode,
  ...rest
}) {
  const defaultValueMatch = typeof defaultValue !== 'object' ? findOptionMatch(options, defaultValue) : defaultValue;
  const select = (
    <SelectField
      {...{
        autoFocus: disableGridMode ? undefined : true,
        closeMenuOnSelect,
        defaultMenuIsOpen: disableGridMode ? undefined : true,
        defaultValue: defaultValueMatch,
        isClearable,
        isCreatable,
        isMulti,
        isSearchable,
        label,
        menuPortalTarget: disableGridMode ? undefined : menuPortalTarget || document.body,
        name,
        onChange: o => onChange(getValueFromOption(o)),
        options,
        placeholder,
        height: disableGridMode ? undefined : height || '23px',
        inputHeight: disableGridMode ? undefined : inputHeight || '17px',
        value,
        ...rest,
      }}
    />
  );
  if (disableGridMode) {
    return select;
  }
  return (
    <div
      style={{
        display: 'list-item',
        paddingLeft: rest.px || rest.pl || rest.paddingLeft || '8px',
        paddingRight: rest.px || rest.pr || rest.paddingRight || '8px',
        paddingTop: rest.py || rest.pt || rest.paddingTop || '4px',
        paddingBottom: rest.py || rest.pb || rest.paddingBottom || '0px',
      }}
    >
      {select}
    </div>
  );
}

SelectEditor.propTypes = propTypes;
export default SelectEditor;
