import React from 'react';
import { Select, Typography } from 'antd';
import { TREASURY_DETAILS_PRICE } from '../../../../slices/treasury-details/treasuryDetailsReducers';

const OPTIONS = [
  TREASURY_DETAILS_PRICE.MARKET_PRICE_AND_FORECAST,
  TREASURY_DETAILS_PRICE.COST_PRICE,
  TREASURY_DETAILS_PRICE.PRICE_SAVINGS,
];

export default function FilterPrice(props) {
  const { activePrice, onActivePriceChange } = props;

  function handleChange(value) {
    onActivePriceChange(value);
  }

  return (
    <div>
      <Typography.Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Price
      </Typography.Text>
      <Select value={activePrice} style={{ width: '100%' }} onChange={handleChange}>
        <Select.Option value={null}>--</Select.Option>
        {OPTIONS.map(o => (
          <Select.Option key={o} value={o}>
            {o}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
