import { Box } from '@chakra-ui/react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Constants from '../../Constants';
import { downloadFromS3Link } from '../../helpers/downloads';
import { getEmailTemplate } from '../../services/email-templates';
import EmailModal from '../basic/EmailModal';
import { connect } from 'react-redux';
import { getNotifyingActionGroups } from '../../actions/actions_notifying_action_groups';
import RecipientsCard from '../coldstore/release-management/RecipientCard/RecipientsCard';

class DocumentEmailModalComponent extends Component {
  static propTypes = {
    attachmentList: PropTypes.arrayOf(PropTypes.object),
    documentType: PropTypes.string.isRequired,
    error: PropTypes.string,
    fmgPONumbers: PropTypes.array.isRequired,
    grinderPONumbers: PropTypes.array.isRequired,
    isLoadingAttachments: PropTypes.bool.isRequired,
    isSendingEmail: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSend: PropTypes.func.isRequired,
    purchaseOrderIds: PropTypes.array.isRequired,
    recipients: PropTypes.array,
    token: PropTypes.string,
    notifyingActionGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    recipientsGroup: PropTypes.array.isRequired,
    order: PropTypes.object.isRequired,
    internalPoLabel: PropTypes.string,
  };

  state = {
    initialSubject: '',
    initialBody: '',
    initialRecipientList: [],
    pendingPDFDownloadList: [],
    isDownloadingAllAttachments: false,
  };

  static lookupDocumentLabel(docType) {
    const documents = Object.values(Constants.GENERATED_DOCUMENTS);
    const match = documents.find(doc => doc.docType === docType);
    return get(match, 'label', '');
  }

  async componentDidMount() {
    const { fmgPONumbers, grinderPONumbers, recipients, token, dispatch, notifyingActionGroups } = this.props;
    await this.setDefaultEmailContent({ fmgPONumbers, grinderPONumbers });
    if (notifyingActionGroups.length === 0) {
      dispatch(getNotifyingActionGroups(token));
    }
  }

  setDefaultEmailContent = async ({ fmgPONumbers, grinderPONumbers }) => {
    const { PO_DOC_TYPES } = Constants;
    const { documentType, internalPoLabel } = this.props;
    const getFirstAndLastPoNumbersByTenant = () => {
      let firstPoNumber;
      let lastPoNumber;
      if (internalPoLabel.includes('CFC')) {
        firstPoNumber = fmgPONumbers[0];
        // TODO, find the usage of fmgPONumbers in the current context
        lastPoNumber = fmgPONumbers[fmgPONumbers.length - 1];
      } else {
        firstPoNumber = grinderPONumbers[0];
        // TODO, find the usage of fmgPONumbers in the current context
        lastPoNumber = grinderPONumbers[fmgPONumbers.length - 1];
      }
      return [firstPoNumber, lastPoNumber];
    };
    const firstAndLastPoNumbersByTenantLabel = getFirstAndLastPoNumbersByTenant();
    const emailData = {
      documentType: DocumentEmailModalComponent.lookupDocumentLabel(documentType),
      firstPoNumber: firstAndLastPoNumbersByTenantLabel[0],
      lastPoNumber: firstAndLastPoNumbersByTenantLabel[1],
      fmgOrGrinderPOs:
        documentType === PO_DOC_TYPES.SALE ? 'Grinder PO Number(s)' : `${internalPoLabel || 'FMG'} order(s)`,
    };
    // TODO, find usage of fmgPONumbers
    let initialSubject = '';
    let initialBody = '';
    if (fmgPONumbers.length === 1) {
      initialSubject = await getEmailTemplate('single_order_document_subject', {
        ...emailData,
      });
      initialBody = await getEmailTemplate('single_order_document_body', {
        ...emailData,
      });
    } else if (fmgPONumbers.length > 1) {
      initialSubject = await getEmailTemplate('multiple_orders_document_subject', {
        ...emailData,
      });
      initialBody = await getEmailTemplate('multiple_orders_document_body', {
        ...emailData,
      });
    }
    this.setState({ initialSubject, initialBody });
  };

  handleSendEmail = ({ body, attachmentList, subject }) => {
    const { onSend } = this.props;
    onSend({
      subject,
      body,
      attachments: attachmentList,
      recipients: this.state.selectedEmailList,
    });
  };

  handleAttachmentClick = attachment => {
    const { pendingPDFDownloadList } = this.state;
    if (pendingPDFDownloadList.map(doc => doc.id).includes(attachment.id)) {
      return;
    }

    this.setState(
      {
        pendingPDFDownloadList: [...pendingPDFDownloadList, attachment],
      },
      async () => {
        await downloadFromS3Link(attachment.url);
        this.setState({
          pendingPDFDownloadList: pendingPDFDownloadList.filter(doc => doc.id !== attachment.id),
        });
      }
    );
  };

  handleDownloadAllClick = attachmentList => {
    const { isDownloadingAllAttachments } = this.state;

    // Prevent users from double-clicking
    if (isDownloadingAllAttachments) {
      return;
    }

    this.setState(
      {
        pendingPDFDownloadList: attachmentList,
        isDownloadingAllAttachments: true,
      },
      async () => {
        const promiseList = attachmentList.map(attachment => downloadFromS3Link(attachment.url));
        await Promise.all(promiseList);
        this.setState({
          pendingPDFDownloadList: [],
          isDownloadingAllAttachments: false,
        });
      }
    );
  };

  handleCancelModal = () => {
    const { onCancel } = this.props;

    this.setState(
      {
        pendingPDFDownloadList: [],
      },
      () => {
        onCancel();
      }
    );
  };

  getSelectedEmailList = selectedEmailList => {
    this.setState({ selectedEmailList });
  };

  render() {
    const { initialBody, initialSubject, initialRecipientList, isDownloadingAllAttachments, pendingPDFDownloadList } =
      this.state;
    const {
      attachmentList,
      isVisible,
      error,
      isSendingEmail,
      isLoadingAttachments,
      notifyingActionGroups,
      order,
      recipientsGroup,
    } = this.props;

    const recipientsComponent = (
      <RecipientsCard
        disabled={false}
        grinderUid={order.grinderUid}
        packerPlantId={order.packerPlantId}
        notifying_action_groups={notifyingActionGroups}
        populateEmails={this.getSelectedEmailList}
        actionGroups={recipientsGroup}
        defaultActionGroups={recipientsGroup}
        extraEmails={this.props.recipients}
      />
    );
    return (
      <Box>
        {initialBody && initialSubject && (
          <EmailModal
            {...{
              initialValues: {
                body: initialBody,
                subject: initialSubject,
                recipientList: initialRecipientList,
              },
              className: 'document-email-modal__modal',
              title: 'Send Email',
              isVisible,
              isDownloadingAllAttachments,
              isSendingEmail,
              error,
              attachmentList,
              isLoadingAttachments,
              pendingPDFDownloadList,
              onSubmit: this.handleSendEmail,
              onCancel: this.handleCancelModal,
              onAttachmentClick: this.handleAttachmentClick,
              onDownloadAllClick: this.handleDownloadAllClick,
              recipientsComponent,
            }}
          />
        )}
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.user.token,
    notifyingActionGroups: state.notifying_action_groups,
  };
};

const DocumentEmailModal = connect(mapStateToProps)(DocumentEmailModalComponent);
export default DocumentEmailModal;
