import React from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Button, Text } from '@chakra-ui/react';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';

const getSelectedFilter = filters => {
  let textValue = '';
  if (filters.status) {
    textValue = filters.status;
  } else if (filters.sortby) {
    textValue = filters.sortby;
  } else if (filters.contact_of) {
    textValue = filters.contact_of;
  } else {
    textValue = 'All';
  }

  return textValue;
};

const Filters = ({ value, onChange, options }) => {
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            bgColor="inherit"
            color="rgba(0, 0, 0, 0.6)"
            height="100%"
            fontSize="14px"
            _hover={{
              bgColor: 'inherit',
              color: 'rgba(0, 0, 0, 0.6)',
            }}
            _focus={{
              bgColor: 'inherit',
              outline: '0',
              color: 'rgba(0, 0, 0, 0.6)',
            }}
            _active={{
              bgColor: 'inherit',
              color: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            Filter by: &nbsp;
            <Text as="b" color="rgba(0, 0, 0, 0.8)" textTransform="capitalize">
              {getSelectedFilter(value)}
            </Text>
          </MenuButton>
          <MenuList zIndex="98" width="270px" padding="0px" borderRadius="0px">
            {options.map(fltr => (
              <MenuItem
                key={fltr.label}
                minHeight="40px"
                outline="none"
                paddingY="9px"
                paddingLeft="40px"
                borderColor="actionSecondary.default"
                _hover={{
                  bgColor: 'actionSecondary.active20',
                  borderRightWidth: '3px',
                }}
                _focus={{ outline: 'none' }}
                onClick={() => onChange(fltr.value)}
              >
                {fltr.label}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

Filters.propTypes = {
  value: PropTypes.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Filters;
