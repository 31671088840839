import { connect } from 'react-redux';
import OrderManagementComponent from '../../components/pages/OrderManagement';

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const OrderManagement = connect(mapStateToProps)(OrderManagementComponent);

export default OrderManagement;
