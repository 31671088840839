const schema = [
  {
    name: 'transporter',
    displayName: 'Transporter',
    systemIdField: 'id',
    displayIdField: 'name',
    path: '/transporters',
    tabGroup: { key: 'logistics', label: 'Logistics' },
    deletable: false,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'Enter the name of the transporter company or individual.',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'scac',
        displayName: 'SCAC',
        tooltip: 'Input the Standard Carrier Alpha Code (SCAC) assigned to the transporter',
        dataType: 'shorttext',
        required: false,
      },
      {
        name: 'phone_number',
        displayName: 'Phone Number',
        tooltip: 'Provide a contact phone number for the transporter',
        dataType: 'shorttext',
        required: false,
      },
      {
        name: 'notification_email',
        displayName: 'Notification Email',
        tooltip: 'Enter the email address where notifications and updates related to transportation will be sent',
        dataType: 'shorttext',
        required: false,
      },
      {
        name: 'address',
        displayName: 'Address',
        dataType: 'reference',
        referenceType: 'nested',
        referenceEntityType: 'address',
      },
    ],
  },
];
export default schema;
