import React from 'react';
import moment from 'moment';
import { DatePicker, Space, Radio } from 'antd';
import { startOfWeek, endOfWeek, subDays, addDays } from 'date-fns';
import PropTypes from 'prop-types';

export default function MarketFilterTimePeriod(props) {
  const { timePeriod, onTimePeriodChange } = props;

  const [type, setType] = React.useState('this_week');

  const thisWeekStart = startOfWeek(new Date()).getTime();
  const thisWeekEnd = endOfWeek(new Date()).getTime();

  const lastWeekStart = startOfWeek(subDays(new Date(), 7)).getTime();
  const lastWeekEnd = endOfWeek(subDays(new Date(), 7)).getTime();

  const nextWeekStart = startOfWeek(addDays(new Date(), 7)).getTime();
  const nextWeekEnd = endOfWeek(addDays(new Date(), 7)).getTime();

  return (
    <div>
      <Space direction="horizontal">
        <Radio.Group value={type} onChange={e => setType(e.target.value)}>
          <Radio.Button value="last_week" onClick={() => onTimePeriodChange([lastWeekStart, lastWeekEnd])}>
            Last week
          </Radio.Button>
          <Radio.Button value="this_week" onClick={() => onTimePeriodChange([thisWeekStart, thisWeekEnd])}>
            This week
          </Radio.Button>
          <Radio.Button value="next_week" onClick={() => onTimePeriodChange([nextWeekStart, nextWeekEnd])}>
            Next week
          </Radio.Button>
        </Radio.Group>
        <DatePicker.RangePicker
          picker="week"
          value={timePeriod.map(d => moment(d))}
          format="DD/MM/YYYY"
          onChange={newPeriod => {
            if (!newPeriod) return;
            // Unset the radio box if custom dates are selected.
            setType('');
            onTimePeriodChange(newPeriod.map(m => m.toDate().getTime()));
          }}
        />
      </Space>
    </div>
  );
}

MarketFilterTimePeriod.propTypes = {
  timePeriod: PropTypes.string.isRequired,
  onTimePeriodChange: PropTypes.func.isRequired,
};
