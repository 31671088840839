import {
  LOAD_SIZES_ADDED,
  LOAD_SIZES_EDIT,
  LOAD_SIZES_FETCHED,
  LOAD_SIZES_REMOVED,
} from '../actions/actions_load_sizes';
import { removeObjectFromArray, replaceObjectInArray } from '../helpers';

const loadSizes = (loadSizesArr = [], action) => {
  const loadSizesCopy = JSON.parse(JSON.stringify(loadSizesArr));
  switch (action.type) {
    case LOAD_SIZES_FETCHED:
      return action.payload;
    case LOAD_SIZES_ADDED:
      return [...loadSizesCopy, ...action.payload];
    case LOAD_SIZES_EDIT:
      return replaceObjectInArray(loadSizesCopy, action.payload);
    case LOAD_SIZES_REMOVED:
      return removeObjectFromArray(loadSizesCopy, action.payload);
    default:
      return loadSizesArr;
  }
};

export default loadSizes;
