import React from 'react';
import { Radio } from 'antd';
import { TREASURY_TIME_INTERVAL } from '../../../../slices/treasury/treasuryConfig';

export default function FilterTimeScale(props) {
  const { timeScale, onTimeScaleChange, size } = props;
  const isSmall = size === 'small';
  return (
    <div>
      <Radio.Group value={timeScale} onChange={e => onTimeScaleChange(e.target.value)}>
        <Radio.Button value={TREASURY_TIME_INTERVAL.YEARLY} disabled>
          {isSmall ? 'Y' : 'Yearly'}
        </Radio.Button>
        <Radio.Button value={TREASURY_TIME_INTERVAL.QUARTERLY}>{isSmall ? 'Q' : 'Quarterly'}</Radio.Button>
        <Radio.Button value={TREASURY_TIME_INTERVAL.MONTHLY}>{isSmall ? 'M' : 'Monthly'}</Radio.Button>
      </Radio.Group>
    </div>
  );
}
