import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import api from '../../../api';
import FileUploader from '../_components/FileUploader';
import { getTenantFromDomain } from '../../../helpers/tenant';
import { uploadFileToS3Request } from '../../../data-fixtures/basic';

const UpdateExtrnalAudit = ({ isOpenPopup, setIsOpenPopup, estId, editItem, updateList }) => {
  const toast = useToast();
  const tenant = getTenantFromDomain();
  const isCFC = tenant.includes('cfc');

  const [isLoading, setIsLoading] = useState(false);
  const [file_upload_call, setFileUploadCall] = useState(false);

  const [links, setLinks] = useState([
    {
      title: `${isCFC ? '' : "McDonald's"} Global HACCP/GMP or GFSI Audit (BRC only)`,
      file: '',
      file_name: '',
    },
    {
      title: 'AUS-MEAT Audit',
      file: '',
      file_name: '',
    },
    {
      title: `${isCFC ? '' : "McDonald's"} Global BSE Firewalls`,
      file: '',
      file_name: '',
    },
    {
      title: `${isCFC ? '' : "McDonald's"} Global Animal Health and Welfare Standards Audit`,
      file: '',
      file_name: '',
    },
    {
      title: 'E. coli O157:H7 Laboratory Audit for APMEA',
      file: '',
      file_name: '',
    },
    {
      title: 'Religious: Halal',
      file: '',
      file_name: '',
    },
  ]);

  const handleChangeFileInput = (name, file) => {
    setFileUploadCall(true);
    if (file) {
      setLinks(prev => {
        return prev.map(link => {
          if (link.title === name) {
            return {
              ...link,
              file,
              file_name: file.name,
            };
          }
          return link;
        });
      });
    } else {
      setLinks(prev => {
        return prev.map(link => {
          if (link.title === name) {
            return {
              ...link,
              file: '',
              filepath: '',
              file_name: '',
            };
          }
          return link;
        });
      });
    }
  };

  const handleUpload = async () => {
    const linksPromises = links.map(link => {
      if (link.file instanceof File) {
        return uploadFileToS3Request({ file: link.file });
      }
      return Promise.resolve(link.file || link.filepath);
    });

    const filesUrls = await Promise.all(linksPromises);
    return filesUrls.map(fileUrl => {
      if (typeof fileUrl === 'object') {
        return fileUrl.file_url;
      }
      return fileUrl;
    });
  };

  const updateInfo = async values => {
    const payload = {
      screen: 'audit_other_links',
      fields_info: values,
      file_upload_call,
    };

    try {
      setIsLoading(true);
      if (estId) {
        const response = await api.patch(`/op-establishment/${estId}`, payload);
        if (response.ok) {
          const data = await response.json();

          updateList(allList => {
            const updatedList = allList.map(item => {
              if (item.id === estId) {
                return { ...item, audit_other_links: data };
              }
              return item;
            });
            return updatedList;
          });

          toast({
            title: 'Record updated successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        }
      }
    } catch (error) {
      toast({
        title: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
      setIsOpenPopup(false);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    const filesUrls = await handleUpload();
    const linksCopy = JSON.parse(JSON.stringify(links));
    const updatedLinks = linksCopy?.map((l, index) => {
      const link = l;
      if (filesUrls[index]) {
        link.filepath = filesUrls[index] ?? null;
      } else {
        link.filepath = '';
      }
      link.link_type = 'external_audit';
      if (l.id) {
        link.id = l.id;
      }
      delete link.file;
      delete link.file_name;
      return link;
    });

    await updateInfo(updatedLinks);

    setIsLoading(false);
  };

  useEffect(() => {
    if (editItem) {
      const linksCopy = JSON.parse(JSON.stringify(links));
      const updatedLinks = linksCopy?.map(l => {
        const link = l;
        const file = editItem.find(item => item.title === link.title);

        if (file) {
          link.filepath = file.filepath;
          link.id = file.id || null;
        } else {
          link.filepath = '';
        }

        link.link_type = 'external_audit';
        delete link.file;
        delete link.file_name;
        return link;
      });
      setLinks(updatedLinks);
    }
  }, []);

  return (
    <Modal isOpen={isOpenPopup} onClose={() => setIsOpenPopup(false)} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent maxW="min(870px, 80%)">
        <ModalHeader borderBottomWidth="1px" fontSize="20px">
          Update External Audit
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding="22px">
          {links.map(link => (
            <Box key={link.title} flexGrow={1} marginBottom="20px">
              <FileUploader
                label={link.title}
                setFieldValue={handleChangeFileInput}
                fieldName={link.title}
                fileObject={link.file || link.filepath}
                accept=".pdf"
              />
            </Box>
          ))}
        </ModalBody>
        <ModalFooter borderTopWidth="1px" padding="16px 22px">
          <ButtonGroup marginLeft="auto">
            <Button type="button" fontSize="16px" width="126px" height="43px" onClick={() => setIsOpenPopup(false)}>
              Close
            </Button>
            &nbsp;
            <Button
              type="button"
              width="126px"
              height="43px"
              colorScheme="actionPrimary"
              marginLeft="10px"
              fontSize="16px"
              loadingText="Saving..."
              isLoading={isLoading}
              onClick={handleSave}
            >
              Save
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

UpdateExtrnalAudit.propTypes = {
  isOpenPopup: PropTypes.bool,
  setIsOpenPopup: PropTypes.func,
  estId: PropTypes.number,
  editItem: PropTypes.shape({}) || null,
  updateList: PropTypes.func,
};
export default UpdateExtrnalAudit;
