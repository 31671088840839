import { connect } from 'react-redux';

import DailyTasksAdminComponent from '../../components/dailyTasks/DailyTasksAdmin';

const mapStateToProps = state => {
  return {
    token: state.user.token || '',
    users: state.users || null,
    grinders: state.grinders || null,
    exception_assignments: state.exception_assignments || null,
  };
};

const DailyTasksAdmin = connect(mapStateToProps)(DailyTasksAdminComponent);

export default DailyTasksAdmin;
