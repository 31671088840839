import { Col, Radio, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveMarket,
  selectMarketsTimePeriod,
  selectMarketSupplyChainControlState,
  selectSupplyChainHoveredLocation,
  selectSupplyChainIncidents,
} from '../../../../slices/markets/selectors/selectBasicMarketData';
import { updateSupplyChainHoveredLocation, updateSupplyChainViewMode } from '../../../../slices/markets/marketReducers';
import {
  selectMarketSupplyChainData,
  selectMarketSupplyChainGrinders,
  selectMarketSupplyChainPackerPlants,
  selectMarketSupplyChainVolumeData,
  selectSupplyChainDetailsActiveGrinder,
  selectSupplyChainDetailsActivePackerPlant,
} from '../../../../slices/markets/selectors/selectMarketsSupplyChainData';

import SupplyChainIncidents from '../../markets/SupplyChainIncidents/SupplyChainIncidents';
import SupplyChainMap from '../../markets/SupplyChainMap/SupplyChainMap';
import DataTable from '../DataTable/DataTable';
import SankeyChart from '../SankeyChart/SankeyChart';
import VolumeChart from '../VolumeChart/VolumeChart';

// This container specifically serves the supply chain card.

const getEntitiesArray = array =>
  array.map((x, i) => {
    return { label: x, isShown: i < 5 };
  });

export default function Details(props) {
  const dispatch = useDispatch();
  const activeMarket = useSelector(selectActiveMarket);
  const timePeriod = useSelector(selectMarketsTimePeriod);
  const marketSupplyChainData = useSelector(selectMarketSupplyChainData);
  const marketSupplyChainVolumeData = useSelector(selectMarketSupplyChainVolumeData);
  const marketSupplyChainControlState = useSelector(selectMarketSupplyChainControlState);
  const incidents = useSelector(selectSupplyChainIncidents);
  const hoveredLocationId = useSelector(selectSupplyChainHoveredLocation);
  const packer_plant = useSelector(selectSupplyChainDetailsActivePackerPlant);
  const packer_plants = useSelector(selectMarketSupplyChainPackerPlants);
  const grinder = useSelector(selectSupplyChainDetailsActiveGrinder);
  const grinders = useSelector(selectMarketSupplyChainGrinders);

  const handleHoveredLocationChange = React.useCallback(
    id => dispatch(updateSupplyChainHoveredLocation(id)),
    [dispatch]
  );

  const handleViewModeChange = React.useCallback(v => dispatch(updateSupplyChainViewMode(v)), [dispatch]);

  const [viewMode, setViewMode] = React.useState('map');

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <strong>View</strong>
        <Radio.Group
          style={{ marginLeft: 10 }}
          value={viewMode}
          size="small"
          onChange={e => setViewMode(e.target.value)}
        >
          <Radio.Button value="map">Map</Radio.Button>
          <Radio.Button value="relationships">Relationships</Radio.Button>
        </Radio.Group>
      </Col>
      <Col span={14}>
        {viewMode === 'map' ? (
          <>
            <SupplyChainMap
              marketSupplyChainData={marketSupplyChainData}
              onHoveredLocationChange={handleHoveredLocationChange}
              controlState={marketSupplyChainControlState}
              hoveredLocationId={hoveredLocationId}
              activeMarket={activeMarket}
              isLarge={!(packer_plant || grinder)}
              incidents={incidents}
            />
            {incidents && incidents.length && <SupplyChainIncidents incidents={incidents} />}
          </>
        ) : (
          <SankeyChart
            marketSupplyChainData={marketSupplyChainData}
            activeMarket={activeMarket}
            isLarge={!(packer_plant || grinder)}
          />
        )}
      </Col>
      <Col span={10}>
        <DataTable
          marketSupplyChainData={marketSupplyChainData}
          onViewModeChange={handleViewModeChange}
          onHoveredLocationChange={handleHoveredLocationChange}
          controlState={marketSupplyChainControlState}
          hoveredLocationId={hoveredLocationId}
          activeMarket={activeMarket}
          incidents={incidents}
        />
      </Col>
      {(packer_plant || grinder) && (
        <Col span={24}>
          <VolumeChart
            marketSupplyChainVolumeData={marketSupplyChainVolumeData}
            timePeriod={timePeriod}
            entityList={
              packer_plant !== null
                ? { type: 'grinders', entities: getEntitiesArray(grinders) }
                : grinder !== null
                ? { type: 'establishments', entities: getEntitiesArray(packer_plants) }
                : { type: 'none', entities: [] }
            }
            activeMarket={activeMarket}
          />
        </Col>
      )}
    </Row>
  );
}
