import { createSelector } from '@reduxjs/toolkit';
import { selectPackerPlants } from './selectBasicPackerPlants';
import { selectQualityMarket } from '../../quality/selectors/selectBasicQualityData';

export const selectQualityMarketPackerPlants = createSelector(
  selectPackerPlants,
  selectQualityMarket,
  (packerPlants, { includedCountries = [] }) =>
    packerPlants.filter(packerPlant => includedCountries.includes(packerPlant.country))
);
