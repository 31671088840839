import moment from 'moment';
import { KPIS_FETCHED } from '../actions/actions_kpis';

const COST_SAVINGS_VALUES = ['cost_savings', 'cost_savings_target', 'additional_value', 'savings'];
const BUY_PERFORMANCE_VALUES = ['fmg_buy_performance', 'usda_buy_performance'];

const kpis = (
  kpis = {
    cost_savings: false,
    cost_savings_target: false,
    additional_value: false,
    savings: false,
    fmg_buy_performance: false,
    usda_buy_performance: false,
  },
  action
) => {
  switch (action.type) {
    case KPIS_FETCHED:
      action.payload.sort(function (a, b) {
        return moment.utc(a.date).diff(moment.utc(b.date));
      });

      return organizeItems(action.payload);
    default:
      return kpis;
  }
};

const organizeItems = payload => {
  const result = {
    cost_savings: [],
    cost_savings_target: [],
    additional_value: [],
    savings: [],
    fmg_buy_performance: [],
    usda_buy_performance: [],
  };

  const latestPerformance = getLatestItem(BUY_PERFORMANCE_VALUES, payload);

  payload.forEach(item => {
    if (isCostSavingsValue(item)) {
      result[item.key].push(item);
    } else if (isBuyPerformanceValue(item, latestPerformance)) {
      result[item.key].push(item);
    }
  });

  // get latest cost savings target value
  const lastTargetIndex = result.cost_savings_target.length - 1;

  result.cost_savings_target = lastTargetIndex > -1 ? result.cost_savings_target[lastTargetIndex].value : 0;

  return result;
};

function getLatestItem(keys, items) {
  return (
    items
      .filter(item => keys.indexOf(item.key) > -1)
      .map(x => x.date)
      .sort()
      .slice(-1)[0] || null
  );
}

function isBuyPerformanceValue(item) {
  const yearAgo = moment().subtract(12, 'month');

  return BUY_PERFORMANCE_VALUES.indexOf(item.key) > -1 && moment(item.date).isSameOrAfter(yearAgo);
}

function isCostSavingsValue(item) {
  const firstDayOfYear = moment().startOf('year');

  return COST_SAVINGS_VALUES.indexOf(item.key) > -1 && moment(item.date).isSameOrAfter(firstDayOfYear);
}

export default kpis;
