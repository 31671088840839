import React from 'react';
import Constants from '../../../Constants';
import ReleaseTemplate from './ReleaseTemplate';
import PropTypes from 'prop-types';

function Schedule(props) {
  const coldstoreStatus = Constants.ORDER_STATUSES.PENDING_COLDSTORE_SCHEDULE;
  const {
    orders,
    grinders,
    transporters,
    user: { token },
    dispatch,
    handleNavigation,
    internalPoLabel,
  } = props;
  return (
    <ReleaseTemplate
      coldstore_status={coldstoreStatus}
      orders={orders}
      grinders={grinders}
      transporters={transporters}
      token={token}
      dispatch={dispatch}
      handleNavigation={handleNavigation}
      headerMessage="Schedule loads for delivery"
      infoMessage="The following loads have been added to this page for scheduling."
      noDataHeaderMessage="There are no available loads to be reviewed at this time.  Please visit the Coldstore Inventory tab to select loads."
      internalPoLabel={internalPoLabel}
      title="Schedule loads for delivery"
    />
  );
}

export default Schedule;
