import { createSelector } from '@reduxjs/toolkit';
import { selectMarketsDataRaw, selectMarketsTimePeriod } from './selectBasicMarketData';
import { startOfDay, subDays, getYear, subMonths, startOfMonth, endOfMonth, getDaysInMonth } from 'date-fns';
import { sum, group } from 'd3';

/**
 * Returns a structure with data required for the markets quality claim stuff
 */

/**
 * Returns the dollar value for a claim depending on whether it has resolved or not.
 * @param {*} claim
 */
const getClaimValue = claim => (claim.closed ? claim.dollar_credit_approved : claim.dollar_credit_requested);

const processClaims = claims => {
  const realisedClaims = claims.filter(claim => claim.closed);

  // TODO: check if we should use credit approved if it has been approved
  const submittedValue = sum(claims, getClaimValue);
  const realisedValue = sum(realisedClaims, getClaimValue);

  return {
    submitted: {
      count: claims.length,
      value: submittedValue,
    },
    realised: {
      count: realisedClaims.length,
      value: realisedValue,
    },
  };
};

export const selectMarketsQualityClaimData = createSelector(
  selectMarketsDataRaw,
  selectMarketsTimePeriod,
  (dataRaw, timePeriod) => {
    if (!dataRaw) return null;
    const { claims } = dataRaw;
    const ytd = claims.slice();

    const previousMonth = subMonths(new Date(), 1);
    const previousMonthStart = startOfMonth(previousMonth).getTime();
    const previousMonthEnd = endOfMonth(previousMonth).getTime();

    const previousMonthClaims = ytd.filter(
      d => d.date_opened >= previousMonthStart && d.date_opened <= previousMonthEnd
    );

    const intervalsArray = [];
    const daysInMonth = getDaysInMonth(previousMonth);
    for (let i = 0; i < daysInMonth; i += 1) {
      const day = {
        interval: startOfDay(subDays(previousMonthEnd, daysInMonth - i)).getTime(),
        sum: 0,
      };
      intervalsArray.push(day);
    }

    const claimsGrouped = group(previousMonthClaims, claim => claim.date_opened);
    const intervals = Array.from(claimsGrouped.entries()).map(([intervalString, intervalClaims]) => {
      const interval = intervalString;
      const totalClaims = intervalClaims.length;

      return {
        interval,
        sum: totalClaims,
      };
    });

    intervalsArray.forEach(x => {
      const res = intervals.find(y => {
        return x.interval === y.interval;
      });
      if (res) {
        // eslint-disable-next-line no-param-reassign
        x.sum = res.sum;
      }
    });

    return {
      yearToDate: processClaims(ytd),
      year: getYear(timePeriod[0]),
      previousMonth: processClaims(previousMonthClaims),
      intervals: intervalsArray,
    };
  }
);
