import { useFlag, UnleashClient } from '@unleash/proxy-client-react';
import { getTenantFromDomain } from '../../../src/helpers/tenant';
import Constants from "../../Constants"

interface IUnleashClassFlagProvider {
    show: (props: any) => React.ReactNode;
    hide: (props: any) => React.ReactNode;
    flagName: String;
}

const getTenantAppName = () => {
  /* 
    The Below statement will set the variable to fmg if 
    getTenantFromDomain return Undefined. This defaults 
    the feature toggles to that of the 
  */


    const domain = getTenantFromDomain() || 'dev.tarot';
  /*
    Will need a more robust solution in future to handle diffenret types of domain names
  */
  const [FirstSubDomain, SecondSubDomain] = domain.split('.');
  const tenantID = domain.includes('dev') || domain.includes('uat') ? SecondSubDomain : FirstSubDomain;
  const unleashTenant = Constants.mapTenantIDToAppName[tenantID];
  return unleashTenant;
}

export const useFlagMultiTenant = (flagName) => {
  const completeTenantFlag = `${getTenantAppName()}_${flagName}`;
  return useFlag(completeTenantFlag);
};

const UnleashConditionalRender = ({ flagName, show, hide }) => {
  const enabled = useFlagMultiTenant(flagName);
  return (
    <>
      {enabled ? show : hide}
    </>
  );
}

export const createUnleashClient = () => {
  const config = {
    url: process.env.REACT_APP_UNLEASH_PROXY_URL,
    clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
    refreshInterval: process.env.REACT_APP_UNLEASH_REFRESH_INTERVAL,
    environment: process.env.REACT_APP_UNLEASH_ENVIRONMENT,
  };

  const TenantAppName = getTenantAppName();
  const updatedConfig = Object.assign({}, config, {appName: TenantAppName});
  const client = new UnleashClient(updatedConfig);
  return client;
}

export default UnleashConditionalRender;