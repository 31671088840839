import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@chakra-ui/react';
import ArticleSummary from './ArticleSummary';

const ArticleSummaryList = ({ articles = [] }) => {
  return (
    <Flex justify="space-between" wrap="wrap">
      {articles.map(article => {
        return <ArticleSummary article={article} />;
      })}
    </Flex>
  );
};

ArticleSummaryList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
};

export default ArticleSummaryList;
