import { Button, Col, Row } from 'antd';
import { useField } from 'formik';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { downloadFromS3Link } from '../../../helpers/downloads';
import { formatLinkToDisplay } from '../../../utils';
import Loading from '../Loading';
import './FileUpload.scss';

const FileUpload = ({ uploadFileToS3, resetUploadStatus, token, fieldName, uploadedState }) => {
  const [loading, setLoading] = useState(false);
  const [field, meta, helpers] = useField(fieldName);
  const { setValue } = helpers;

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const { uploadSuccess, uploadedFileUrl, uploadSaved } = uploadedState;
    setLoading(false);
    if (!uploadSuccess && uploadSuccess !== undefined && !uploadSaved) {
      const attachments = [...field.value];
      attachments.pop();
      setValue(attachments);
    } else if (uploadedFileUrl !== undefined) {
      get(field, 'value') ? setValue([...field.value, uploadedFileUrl]) : setValue([uploadedFileUrl]);
      resetUploadStatus();
    }
  }, [get(uploadedState, 'uploadedFileUrl')]);

  const onFieldChange = e => {
    setLoading(true);
    uploadFileToS3({ file: e.target.files[0] });
  };

  const removeAttachment = idx => {
    if (field.value) {
      const attachments = [...field.value];
      attachments.splice(idx, 1);
      setValue(attachments);
    }
  };

  return (
    <div className="file-upload col-md-12">
      <div>
        <Row>
          <Col>
            <label className="btn btn-sm btn-info btn-file">
              Upload Files
              <input type="file" style={{ display: 'none' }} onChange={onFieldChange} />
            </label>
          </Col>
        </Row>
        {loading ? (
          <Loading />
        ) : (
          field.value &&
          field.value.length &&
          field.value.map((attachment, idx) => {
            return (
              <Row gutter={[8, 8]} key={attachment}>
                <Col className="gutter-row" span={6}>
                  <Button type="link" onClick={() => downloadFromS3Link(attachment)}>
                    <span className="file-upload__anchor-link">{formatLinkToDisplay(attachment)}</span>
                  </Button>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Button className="file-upload__trash-btn" onClick={() => removeAttachment(idx)}>
                    <i className="la la-trash-o" />
                  </Button>
                </Col>
              </Row>
            );
          })
        )}
      </div>
    </div>
  );
};

export default FileUpload;
