import React from 'react';
import PropTypes from 'prop-types';
// Local Deps
import SelectField from '../basic/SelectField';

function getValueFromOption(option) {
  if (!option) return '';
  return option.value || option.name;
}

function findOptionMatch(options, value) {
  return options.find(o => getValueFromOption(o) === value);
}

const propTypes = {
  defaultValue: PropTypes.any,
  height: PropTypes.string,
  inputHeight: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType[(PropTypes.string, PropTypes.number, PropTypes.node)],
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.instanceOf(Object)]),
};

// This formatter is for display purposes only.  Clicking into the component from
// React-data-grid will activate the editor.
// I've used the same component (without the portal), to make sure that the options menu
// does not render.
function SelectFormatter({
  defaultValue,
  label,
  name,
  onChange,
  options,
  placeholder,
  height,
  inputHeight,
  value,
  ...rest
}) {
  const defaultValueMatch = findOptionMatch(options, defaultValue);
  return (
    <div
      style={{
        paddingLeft: rest.px || rest.pl || rest.paddingLeft || '0px',
        paddingRight: rest.px || rest.pr || rest.paddingRight || '0px',
        paddingTop: rest.py || rest.pt || rest.paddingTop || '4px',
        paddingBottom: rest.py || rest.pb || rest.paddingBottom || '0px',
      }}
    >
      <SelectField
        {...{
          autoFocus: false,
          defaultValue: defaultValueMatch,
          height: height || '23px',
          inputHeight: inputHeight || '17px',
          onChange: o => onChange(getValueFromOption(o)),
          menuIsOpen: false,
          name,
          placeholder,
          value,
          ...rest,
        }}
      />
    </div>
  );
}

SelectFormatter.propTypes = propTypes;
export default SelectFormatter;
