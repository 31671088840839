import { connect } from 'react-redux';
import EditPackerOfferLinesComponent from '../../components/packers/packer_offer_form/edit/EditPackerOfferLines';

const mapStateToProps = state => {
  return {
    packers: state.packers,
    user: state.user,
    token: state.user.token,
    input_products: state.input_products,
  };
};

const EditPackerOfferLines = connect(mapStateToProps)(EditPackerOfferLinesComponent);

export default EditPackerOfferLines;
