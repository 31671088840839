import { createSelector } from '@reduxjs/toolkit';
import { selectPackerPlants } from '../../quality-packer-plants/selectors/selectBasicPackerPlants';

/**
 * Create a selector returning a packer - packer_plant mapping
 * @return {selector} - The selector returns an object whose keys are packer id and values are array of packer plant object beloging to that packer.
 */
export const selectQualityPackers = createSelector(selectPackerPlants, packerPlants => {
  const packers = {};
  [...packerPlants]
    .filter(x => !x.delisted && !!x.isPacker)
    .sort((a, b) => a.name.localeCompare(b.name))
    .forEach(x => {
      const packerPackerPlants = [...packerPlants]
        .filter(p => p.isPartOf === x.id)
        .sort((a, b) => a.name.localeCompare(b.name));
      packers[x.id] = packerPackerPlants;
    });
  return packers;
});
