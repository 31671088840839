const schema = [
  {
    name: 'end_user',
    displayName: 'End User',
    systemIdField: 'id',
    displayIdField: 'common_name',
    path: '/customers',
    tabGroup: { key: 'grinders', label: 'Grinders' },
    deletable: false,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
        excludePayload: true,
      },
      {
        name: 'is_active',
        displayName: 'Active',
        tooltip: "Select whether the end user are active or not, by selecting 'Yes' or 'No'",
        dataType: 'boolean',
        required: true,
      },
      {
        name: 'common_name',
        displayName: 'Common Name',
        tooltip: 'Provide the Common Name of the End User',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'legal_name',
        displayName: 'Legal Name',
        tooltip: 'Provide the Legal Name of the End User',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'organisation_ids',
        displayName: 'Organisation',
        tooltip: 'Select the Organisation associated with the End User.',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'organisation',
        required: true,
      },
      {
        name: 'end_user_custom_id',
        displayName: 'Custom ID',
        tooltip: 'Provide the Custom ID of the End User',
        dataType: 'shorttext',
        required: false,
      },
      {
        name: 'grinders_uids',
        displayName: 'Grinder',
        tooltip: 'Select the Grinder associated with the End User.',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'grinder',
        required: true,
      },
      {
        name: 'pricing_strategy',
        displayName: 'Pricing Strategy',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'pricing_strategy_end_user',
        required: false,
      },
      {
        name: 'address',
        displayName: 'Address',
        dataType: 'reference',
        referenceType: 'nested',
        referenceEntityType: 'address',
        required: false,
      },
    ],
  },
];
export default schema;
