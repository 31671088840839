import Constants from '../Constants';
import api from '../api';

const EstablishmentDetailsBasePath = '/op-establishment-details';
const EstablishmentDetailBasePath = '/op-establishment-detail';
const OperationalPackersBasePath = '/op-packers';
const EstblishmentOptionsBasePath = '/est-options';
const AuditReasonsBasePath = '/op-audit-reasons';
const AuditReasonBasePath = '/op-audit-reason';

const actionTypes = {
  ESTABLISHMENT_DETAILS_REQUESTED: 'ESTABLISHMENT_DETAILS_REQUESTED',
  ESTABLISHMENT_DETAILS_REQUEST_SUCCESS: 'ESTABLISHMENT_DETAILS_REQUEST_SUCCESS',
  ESTABLISHMENT_DETAILS_REQUESTED_FAILURE: 'ESTABLISHMENT_DETAILS_REQUESTED_FAILURE',
  ESTABLISHMENT_DETAILS_EDIT_STATUS: 'ESTABLISHMENT_DETAILS_EDIT_STATUS',
  ESTABLISHMENT_DETAILS_EDIT_LISTED_STATUS: 'ESTABLISHMENT_DETAILS_EDIT_LISTED_STATUS',
  ESTABLISHMENT_DETAILS_CHANGE_TYPES: 'ESTABLISHMENT_DETAILS_CHANGE_TYPES',
  OP_PACKERS_REQUESTED: 'OP_PACKERS_REQUESTED',
  OP_PACKERS_REQUEST_SUCCESS: 'OP_PACKERS_REQUEST_SUCCESS',
  OP_PACKERS_REQUEST_ERROR: 'OP_PACKERS_REQUEST_ERROR',
  ESTABLISHMENT_OPTIONS_REQUESTED: 'ESTABLISHMENT_OPTIONS_REQUESTED',
  ESTABLISHMENT_OPTIONS_REQUEST_SUCCESS: 'ESTABLISHMENT_OPTIONS_REQUEST_SUCCESS',
  ESTABLISHMENT_OPTIONS_REQUEST_FAILURE: 'ESTABLISHMENT_OPTIONS_REQUEST_FAILURE',
  OP_AUDIT_REASONS_REQUESTED: 'OP_AUDIT_REASONS_REQUESTED',
  OP_AUDIT_REASONS_REQUEST_SUCCESS: 'OP_AUDIT_REASONS_REQUEST_SUCCESS',
  OP_AUDIT_REASONS_REQUEST_FAILURE: 'OP_AUDIT_REASONS_REQUEST_FAILURE',
  OP_AUDIT_REASONS_REQUEST_ADDNEW: 'OP_AUDIT_REASONS_REQUEST_ADDNEW',
  OP_AUDIT_REASONS_REQUEST_UPDATE: 'OP_AUDIT_REASONS_REQUEST_UPDATE',
};

const defaultState = {
  loading: true,
  error: null,
  editing: false,
  estblishmentDetailList: [],
  packers: [],
  establishmentOptions: [],
};

export const actions = {
  requestEstablishmentDetails() {
    return {
      type: actionTypes.ESTABLISHMENT_DETAILS_REQUESTED,
    };
  },
  requestEstablishmentDetailsSuccess(resource) {
    return {
      type: actionTypes.ESTABLISHMENT_DETAILS_REQUEST_SUCCESS,
      payload: resource,
    };
  },
  requestEstblishmentDetailsFailure(error) {
    return {
      type: actionTypes.ESTABLISHMENT_DETAILS_REQUESTED_FAILURE,
      payload: error,
    };
  },
  requestEstblishmentDetailsEditStatus(resource) {
    return {
      type: actionTypes.ESTABLISHMENT_DETAILS_EDIT_STATUS,
      payload: resource,
    };
  },
  requestEstblishmentDetailsEditListedStatus(resource) {
    return {
      type: actionTypes.ESTABLISHMENT_DETAILS_EDIT_LISTED_STATUS,
      payload: resource,
    };
  },  
  requestEstablishmentDetailsUpdate(resource) {
    return {
      type: actionTypes.ESTABLISHMENT_DETAILS_CHANGE_TYPES,
      payload: resource,
    };
  },
  requestOperationalPackers() {
    return {
      type: actionTypes.OP_PACKERS_REQUESTED,
    };
  },
  requestOperationalPackersSuccess(resource) {
    return {
      type: actionTypes.OP_PACKERS_REQUEST_SUCCESS,
      payload: resource,
    };
  },
  requestOperationalPackersFailure(error) {
    return {
      type: actionTypes.OP_PACKERS_REQUEST_ERROR,
      payload: error,
    };
  },
  requestEstablishmentOptionsRequested() {
    return {
      type: actionTypes.ESTABLISHMENT_OPTIONS_REQUESTED,
    };
  },
  requestEstablishmentOptionsSuccess(resource) {
    return {
      type: actionTypes.ESTABLISHMENT_OPTIONS_REQUEST_SUCCESS,
      payload: resource,
    };
  },
  requestEstablishmentOptionFailure(resource) {
    return {
      type: actionTypes.ESTABLISHMENT_OPTIONS_REQUEST_FAILURE,
      payload: resource,
    };
  },
  requestOpAuditReasons() {
    return {
      type: actionTypes.OP_AUDIT_REASONS_REQUESTED,
    };
  },
  requestOpAuditReasonsSuccess(resource) {
    return {
      type: actionTypes.OP_AUDIT_REASONS_REQUEST_SUCCESS,
      payload: resource,
    };
  },
  requestOpAuditReasonFailure(resource) {
    return {
      type: actionTypes.OP_AUDIT_REASONS_REQUEST_FAILURE,
      payload: resource,
    };
  },
  requestOpAuditReasonAddnew(resource) {
    return {
      type: actionTypes.OP_AUDIT_REASONS_REQUEST_ADDNEW,
      payload: resource,
    };
  },
  requestOpAuditReasonUpdate(resource) {
    return {
      type: actionTypes.OP_AUDIT_REASONS_REQUEST_UPDATE,
      payload: resource,
    };
  },
};

export const fetchOperationalPackers = filters => async dispatch => {
  dispatch(actions.requestOperationalPackers());
  try {
    const response = await api.get(`${OperationalPackersBasePath}${filters.name ? `?q=${filters.name}` : ''}`);
    const json = await Constants.handleErrors(response, dispatch, actions.requestOperationalPackersFailure);
    return dispatch(actions.requestOperationalPackersSuccess(json));
  } catch (error) {
    return dispatch(actions.requestOperationalPackersFailure(error));
  }
};

export const fetchEstablishmentDetailsList = packerId => async dispatch => {
  dispatch(actions.requestEstablishmentDetails());
  try {
    const response = await api.get(`${EstablishmentDetailsBasePath}/${packerId}`);
    const json = await Constants.handleErrors(response, dispatch, actions.requestEstblishmentDetailsFailure);
    return dispatch(actions.requestEstablishmentDetailsSuccess(json));
  } catch (error) {
    return dispatch(actions.requestEstblishmentDetailsFailure(error));
  }
};

export const fetchEstablishmentDetails = Id => async dispatch => {
  dispatch(actions.requestEstablishmentDetails());
  try {
    const response = await api.get(`${EstablishmentDetailBasePath}/${Id}`);
    const json = await Constants.handleErrors(response, dispatch, actions.requestEstblishmentDetailsFailure);
    return dispatch(actions.requestEstablishmentDetailsSuccess(json));
  } catch (error) {
    return dispatch(actions.requestEstblishmentDetailsFailure(error));
  }
};

export const updateEstablishmentDetails = Id => async dispatch => {
  dispatch(actions.requestEstablishmentDetails());
  try {
    const response = await api.get(`${EstablishmentDetailBasePath}/${Id}`);
    const json = await Constants.handleErrors(response, dispatch, actions.requestEstblishmentDetailsFailure);
    return dispatch(actions.requestEstablishmentDetailsUpdate(json));
  } catch (error) {
    return dispatch(actions.requestEstblishmentDetailsFailure(error));
  }
};

export const fetchOperationalAuditReasons = EstablishmentDetailsId => async dispatch => {
  dispatch(actions.requestOpAuditReasons());
  try {
    const response = await api.get(`${AuditReasonsBasePath}/${EstablishmentDetailsId}`);
    const json = await Constants.handleErrors(response, dispatch, actions.requestOpAuditReasonFailure);
    return dispatch(actions.requestOpAuditReasonsSuccess(json));
  } catch (error) {
    return dispatch(actions.requestOpAuditReasonFailure(error));
  }
};

export const createOperationalAuditReasons = () => async dispatch => {
  dispatch(actions.requestOpAuditReasons());
  try {
    const response = await api.post(`${AuditReasonsBasePath}`);
    const json = await Constants.handleErrors(response, dispatch, actions.requestOpAuditReasonFailure);
    return dispatch(actions.requestOpAuditReasonAddnew(json));
  } catch (error) {
    return dispatch(actions.requestOpAuditReasonFailure(error));
  }
};

export const updateOperationalAuditReason = Id => async dispatch => {
  dispatch(actions.requestOpAuditReasons());
  try {
    const response = await api.patch(`${AuditReasonBasePath}/${Id}`);
    const json = await Constants.handleErrors(response, dispatch, actions.requestOpAuditReasonFailure);
    return dispatch(actions.requestOpAuditReasonUpdate(json));
  } catch (error) {
    return dispatch(actions.requestOpAuditReasonFailure(error));
  }
};

export const fetchOperationalAuditReason = Id => async dispatch => {
  dispatch(actions.requestOpAuditReasons());
  try {
    const response = await api.get(`${AuditReasonBasePath}/${Id}`);
    const json = await Constants.handleErrors(response, dispatch, actions.requestOpAuditReasonFailure);
    return dispatch(actions.requestOpAuditReasonsSuccess(json));
  } catch (error) {
    return dispatch(actions.requestOpAuditReasonFailure(error));
  }
};

export const fetchEstablishmentOptions = () => async dispatch => {
  dispatch(actions.requestEstablishmentDetailsSuccess());
  try {
    const response = await api.get(`${EstblishmentOptionsBasePath}`);
    const json = await Constants.handleErrors(response, dispatch, actions.requestEstablishmentOptionFailure);
    return dispatch(actions.requestEstablishmentOptionsSuccess(json));
  } catch (error) {
    return dispatch(actions.requestEstablishmentOptionFailure(error));
  }
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.ESTABLISHMENT_DETAILS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        editing: false,
      };

    case actionTypes.ESTABLISHMENT_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        estblishmentDetailList: action.payload,
      };

    case actionTypes.ESTABLISHMENT_DETAILS_REQUESTED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.OP_PACKERS_REQUESTED:
      return {
        ...state,
        // loading: true,
        error: null,
        editing: false,
      };

    case actionTypes.OP_PACKERS_REQUEST_SUCCESS:
      return {
        ...state,
        // loading: false,
        packers: action.payload,
      };

    case actionTypes.OP_PACKERS_REQUEST_ERROR:
      return {
        ...state,
        // loading: false,
        error: action.payload,
      };

    case actionTypes.ESTABLISHMENT_DETAILS_EDIT_STATUS:
      return {
        ...state,
        estblishmentDetailList: state.estblishmentDetailList.map(value => {
          if (value.id === action.payload.id) {
            return {
              ...value,
              status: action.payload.status,
              reason: action.payload.reason,
            };
          }

          return value;
        }),
      };

      case actionTypes.ESTABLISHMENT_DETAILS_EDIT_LISTED_STATUS:
        return {
          ...state,
          estblishmentDetailList: state.estblishmentDetailList.map(value => {
            if (value.id === action.payload.id) {
              return {
                ...value,
                listed_status: action.payload.listed_status,
              };
            }
  
            return value;
          }),
        };      

    case actionTypes.ESTABLISHMENT_DETAILS_CHANGE_TYPES:
      return {
        ...state,
        estblishmentDetailList: state.estblishmentDetailList.map(value => {
          if (value.id === action.payload.id) {
            return {
              ...value,
              est_supported_options: action.payload.est_supported_options,
            };
          }

          return value;
        }),
      };

    case actionTypes.ESTABLISHMENT_OPTIONS_REQUESTED:
      return {
        ...state,
        error: null,
        editing: false,
      };

    case actionTypes.ESTABLISHMENT_OPTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        establishmentOptions: action.payload,
      };

    case actionTypes.ESTABLISHMENT_OPTIONS_REQUEST_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.payload,
      };

    case actionTypes.OP_AUDIT_REASONS_REQUESTED:
      return {
        ...state,
        // loading: true,
        error: null,
        editing: false,
      };

    // case actionTypes.OP_AUDIT_REASONS_REQUEST_SUCCESS:
    //   return {
    //     ...state,
    //   };
    case actionTypes.OP_AUDIT_REASONS_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    // case actionTypes.OP_AUDIT_REASONS_REQUEST_ADDNEW:
    //   return {
    //     ...state,
    //   };

    // case actionTypes.OP_AUDIT_REASONS_REQUEST_UPDATE:
    //   return {
    //     ...state,
    //   };

    default:
      return state;
  }
};

export default reducer;
