import moment from 'moment';
import axios from 'axios';
// Local Deps
import Constants from '../Constants';
import { removeRequestAuthorizationToken, setRequestAuthToken } from '../components/lib/AxiosUtils';
import api from '../api';
import { fetchDynamicNavigations } from './actions_access_levels';

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const REQUEST_ACCESS_ERROR = 'REQUEST_ACCESS_ERROR';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const FOUND_ACCESS_TOKEN = 'FOUND_ACCESS_TOKEN';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
/**
 * Stores user login information into browser localStorage.
 * @param json - User json object information
 */
const setRememberMeCredentials = json => {
  localStorage.setItem('auto-login-expiry', json.token_expiry);
  localStorage.setItem('auto-login', json.token);
  localStorage.setItem('auto-login-name', `${json.first_name} ${json.last_name}`);
  localStorage.setItem('remember-me', 'yes');
};

export const clearStoredCredentials = () => {
  localStorage.removeItem('auto-login-expiry');
  localStorage.removeItem('auto-login');
  localStorage.removeItem('auto-login-name');
  localStorage.removeItem('boabUser');
};

export function userLogin(resp) {
  if (typeof resp.token !== 'undefined') {
    return {
      type: USER_LOGGED_IN,
      payload: resp,
    };
  }
  return {
    type: LOGIN_ERROR,
    payload: resp.title,
  };
}

export function userLogout() {
  return {
    type: USER_LOGGED_OUT,
  };
}

export function userError(resp) {
  return {
    type: LOGIN_ERROR,
    payload: resp,
  };
}

export function passwordReset(resp) {
  return {
    type: PASSWORD_RESET,
    payload: resp,
  };
}

export function passwordResetError(resp) {
  return {
    type: PASSWORD_RESET_ERROR,
    payload: resp,
  };
}

export function errorLogout(resp) {
  return {
    type: LOGOUT_ERROR,
    payload: resp,
  };
}

export function requestAccessError(resp) {
  return {
    type: REQUEST_ACCESS_ERROR,
    payload: resp,
  };
}

export function foundAccessToken() {
  return {
    type: FOUND_ACCESS_TOKEN,
  };
}

/* ----------------------------------------
  THUNKS
-------------------------------------------*/

export function login(email, password, otp, twoFactorAuthMethod, rememberMe, browserHistory, showOtpField) {
  return async dispatch => {
    const user = {
      email: email.toLowerCase(),
      password,
      otp,
      two_factor_auth_method: twoFactorAuthMethod?.name,
      remember_me: rememberMe,
      device_token: localStorage.getItem('deviceToken'),
    };
    const header = new Headers({
      'Content-Type': 'application/json',
    });

    try {
      const response = await fetch(`${Constants.URL}login`, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(user),
      });
      const json = await response.json();

      if (typeof json.title !== 'undefined') {
        // There is an error
        dispatch(userError(json.title));
      } else if (json.two_factor_auth === true) {
        showOtpField(json.methods);
      } else {
        localStorage.setItem(
          'boabUser',
          JSON.stringify({
            token: json.token,
            token_expiry: json.token_expiry,
          })
        );
        if (json.device_token && rememberMe) {
          localStorage.setItem('deviceToken', json.device_token);
        }

        if (rememberMe) {
          setRememberMeCredentials(json);
        } else {
          localStorage.removeItem('remember-me');
        }
        setRequestAuthToken(json.token);
        api.registerHeader('Authorization', `Token ${json.token}`);
        api.registerHeader('Content-Type', 'application/json');
        dispatch(userLogin(json));
        dispatch(fetchDynamicNavigations(json.token));
        if (json.role.access_list.includes('dashboard_read')) {
          browserHistory.push('/');
        } else {
          browserHistory.push('/articles');
        }
      }
    } catch (error) {
      dispatch(userError(error));
    }
  };
}

export function logout(token) {
  return async dispatch => {
    setRequestAuthToken(token);
    try {
      // Remove token from user:
      const response = await axios.post(`${Constants.URL}logout`, {});

      // Handle API error
      Constants.handleErrors(response, dispatch, errorLogout);

      // Remove token from Axios default config:
      removeRequestAuthorizationToken();

      // Clear local storage
      const saveDevice = localStorage.getItem('deviceToken');
      clearStoredCredentials();
      localStorage.setItem('deviceToken', saveDevice);

      // Update global state
      dispatch(userLogout());
    } catch (e) {
      // Handle thrown error.
      dispatch(errorLogout(e));
    }
  };
}

export function autoLogin(accessToken, optionalRedirectUrl, browserHistory) {
  return dispatch => {
    const token = accessToken || localStorage.getItem('auto-login');
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}login/with_token`, {
      method: 'GET',
      headers: header,
    })
      .then(response => response.json())
      .then(json => {
        if (typeof json.title !== 'undefined') {
          // There is an error
          dispatch(userError(json.title));
        } else {
          setRememberMeCredentials(json);
          setRequestAuthToken(json.token);
          api.registerHeader('Authorization', `Token ${json.token}`);
          api.registerHeader('Content-Type', 'application/json');
          dispatch(userLogin(json));
          dispatch(fetchDynamicNavigations(json.token));
          if (optionalRedirectUrl) {
            browserHistory.push(optionalRedirectUrl);
          }
        }
      })
      .catch(error => {
        dispatch(userError(error));
      });
  };
}

export function checkForToken(browserHistory) {
  return dispatch => {
    const accessToken = localStorage.getItem('boabUser');
    if (accessToken) {
      const json = JSON.parse(accessToken);
      const tokenExpiry = moment.utc(json.token_expiry);
      const now = moment.utc();
      // Check if token is expired. If it is, redirect to login page.
      if (tokenExpiry.isBefore(now)) {
        return browserHistory.push('/login');
      }
      return dispatch(autoLogin(json.token));
    }
    return browserHistory.push('/login');
  };
}

export function forgotPassword(email) {
  return dispatch => {
    const header = new Headers({ 'Content-Type': 'application/json' });
    return fetch(`${Constants.URL}reset-password?email=${email.toLowerCase()}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => {
        if (response.status === 200) {
          return response;
        }
        return response.json();
      })
      .then(json => {
        if (json && json.title) {
          dispatch(passwordResetError(json.title));
        } else {
          const reset = 'Reset Successful';
          dispatch(passwordReset(reset));
        }
      })
      .catch(error => {
        dispatch(passwordResetError(error));
      });
  };
}

export function autoQueryStringLogin(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}login/with_token`, {
      method: 'GET',
      headers: header,
    })
      .then(response => response.json())
      .then(json => {
        if (typeof json.title !== 'undefined') {
          // There is an error
          setRememberMeCredentials(json);
          dispatch(userError(json.title));
        } else {
          setRequestAuthToken(json.token);
          api.registerHeader('Authorization', `Token ${json.token}`);
          api.registerHeader('Content-Type', 'application/json');
          dispatch(userLogin(json));
        }
      })
      .catch(error => {
        dispatch(userError(error));
      });
  };
}

/**
 * Log in a user using the authority of an SSO user.
 * @param token - SSO user's web token
 * @param email - user_id of the target user to log in as
 */
export const ssoLogin = (token, email) => {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}sso/users/${email.toLowerCase()}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => response.json())
      .then(json => {
        if (typeof json.title !== 'undefined') {
          dispatch(userError(json.title));
        } else {
          setRememberMeCredentials(json);
          setRequestAuthToken(json.token);
          api.registerHeader('Authorization', `Token ${json.token}`);
          api.registerHeader('Content-Type', 'application/json');
          dispatch(userLogin(json));
        }
      })
      .catch(error => {
        dispatch(userError(error));
      });
  };
};
