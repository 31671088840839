import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker, Typography } from 'antd';
import { endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns';
import styles from '../Filter.module.css';
import { FREQUENCY } from '../../../../../slices/quality/qualityConfig';

export default function FilterTimePeriod(props) {
  const { value, onChange, frequency } = props;

  return (
    <div>
      <div style={{ height: 30 }}>
        <Typography.Text className={styles.filterLabel}> </Typography.Text>
      </div>
      <DatePicker.RangePicker
        className={styles.datePicker}
        picker={frequency === FREQUENCY.ANNUALLY ? 'year' : 'month'}
        value={value.map(d => moment(d))}
        format={m => {
          if (frequency === FREQUENCY.ANNUALLY) {
            return m.format('YYYY');
          }
          return m.format('MMM, YYYY');
        }}
        disabledDate={current => {
          return current && current > moment().subtract(1, 'month');
        }}
        onChange={newPeriod => {
          if (!newPeriod) return;
          if (frequency === FREQUENCY.ANNUALLY) {
            onChange([startOfYear(newPeriod[0].toDate().getTime()), endOfYear(newPeriod[1].toDate().getTime())]);
          } else {
            onChange([startOfMonth(newPeriod[0].toDate().getTime()), endOfMonth(newPeriod[1].toDate().getTime())]);
          }
        }}
      />
    </div>
  );
}

FilterTimePeriod.propTypes = {
  frequency: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
};
