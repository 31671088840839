import Constants from '../Constants';

export const CREATE_ORDER = 'CREATE_ORDER';
export const SEND_ORDER = 'SEND_ORDER';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const OPTIMIZER_ERROR = 'OPTIMIZER_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function addOrder(data) {
  return {
    type: CREATE_ORDER,
    payload: data,
  };
}

export function sentOrder(data) {
  return {
    type: SEND_ORDER,
    payload: data,
  };
}

export function clearOrders() {
  return {
    type: CLEAR_ORDERS,
    payload: [{}, {}, {}, {}, {}, {}],
  };
}

export function errorOptimizer(error) {
  return {
    type: OPTIMIZER_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function createOrder(order, index, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/orders`, {
      method: 'POST',
      body: JSON.stringify(order),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOptimizer))
      .then(json => {
        dispatch(
          addOrder({
            json,
            index,
          })
        );
      })
      .catch(error => dispatch(errorOptimizer(error)));
  };
}

export function sendOrder(orderId, index, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/orders/${orderId}/send`, {
      method: 'PUT',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOptimizer))
      .then(json => {
        dispatch(
          sentOrder({
            json,
            index,
          })
        );
      })
      .catch(error => dispatch(errorOptimizer(error)));
  };
}
