import { Icon } from '@ant-design/compatible';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FormLabel, Stack } from '@chakra-ui/react';
import './SelectField.scss';

class SelectField extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !this.equalFunction(nextProps, this.props);
  }

  equalFunction = (n, c) => {
    const keys = [
      'isDisabled',
      'options',
      'closeMenuOnSelect',
      'isClearable',
      'isSearchable',
      'defaultValue',
      'value',
      'isCreatable',
      'isMulti',
      'className',
      'changeableKey',
    ];
    for (const key of keys) {
      if (JSON.stringify(n[key]) !== JSON.stringify(c[key])) {
        return false;
      }
    }
    return true;
  };

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    closeMenuOnSelect: PropTypes.bool,
    isClearable: PropTypes.bool,
    isSearchable: PropTypes.bool,
    defaultValue: PropTypes.any,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.instanceOf(Object)]),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.string,
    isMulti: PropTypes.bool,
    isCreatable: PropTypes.bool,
    isDisabled: PropTypes.bool,
  };

  static arrowRenderer() {
    return (
      <span>
        <Icon type="down" />
      </span>
    );
  }

  render() {
    const {
      onChange,
      options,
      defaultValue,
      value,
      label,
      isClearable,
      isSearchable,
      name,
      isMulti,
      isCreatable,
      placeholder,
      ...rest
    } = this.props;
    const valueArray = Array.isArray(defaultValue) ? defaultValue : [defaultValue];
    let defaultValueFormatted = [];
    if (valueArray.length) {
      if (valueArray[0] && valueArray[0].value && valueArray[0].label) {
        defaultValueFormatted = valueArray;
      } else {
        defaultValueFormatted = options ? options.filter(opt => valueArray.includes(opt.value)) : [];
      }
    }

    const SelectComponent = isCreatable ? CreatableSelect : Select;

    return (
      <Stack
        direction={rest.asHorizontalStack ? 'row' : 'column'}
        spacing={0}
        width="100%"
        className="select-field"
        align="stretch"
        {...rest.containerStackProps}
      >
        {label && (
          <FormLabel mb="5px" {...rest.labelProps}>
            {label}
          </FormLabel>
        )}
        <SelectComponent
          className="select-field__select"
          key={
            defaultValue
          } /* re-render when default value changes https://github.com/ant-design/ant-design/issues/4347#issuecomment-268941475   */
          defaultValue={defaultValueFormatted}
          value={value}
          isClearable={isClearable}
          isSearchable={isSearchable}
          name={name}
          isMulti={isMulti || false}
          placeholder={placeholder || 'Select...'}
          onChange={selectedOption => {
            onChange(selectedOption, name);
          }}
          options={options || []}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: `var(--chakra-colors-secondary-300, ${theme.colors.primary})`,
              primary25: `var(--chakra-colors-secondary-100, ${theme.colors.primary25})`,
            },
          })}
          // Please see --> https://react-select.com/styles
          styles={{
            container: styles => ({
              ...styles,
              width: rest.containerWidth,
              ...rest.containerStyles,
            }),
            placeholder: styles => ({
              ...styles,
              lineHeight: rest.height || '20px',
              height: rest.inputHeight || '17px',
              display: rest.display || 'flex',
              justifyContent: rest.justifyContent || 'center',
              alignItems: rest.alignItems || 'center',
              ...rest.placeholderStyles,
            }),
            input: styles => ({
              ...styles,
              height: rest.inputHeight || '24px',
              width: rest.inputWidth,
              lineHeight: rest.inputHeight || '24px',
              borderColor: '#ccc',
              paddingLeft: rest.px || rest.pl || rest.paddingLeft || 0,
              paddingRight: rest.px || rest.pr || rest.paddingRight || 0,
              paddingTop: rest.py || rest.pt || rest.paddingTop || 0,
              paddingBottom: rest.py || rest.pb || rest.paddingBottom || 0,
              marginBottom: rest.mb || '3px',
            }),
            indicatorsContainer: styles => ({
              ...styles,
              height: rest.height || '38px',
              ...rest.indicatorsContainerStyles,
            }),
            indicatorSeparator: styles => {
              return {
                ...styles,
                backgroundColor: 'transparent',
                marginTop: rest.indicatorMarginTop || '1px',
                marginBottom: rest.indicatorMarginBottom || '1px',
              };
            },
            valueContainer: styles => ({
              ...styles,
              lineHeight: '16px',
              minHeight: rest.height || '38px',
              // paddingLeft: 0,
              paddingRight: 0,
              ...rest.valueContainerStyles,
            }),
            dropdownIndicator: styles => ({
              ...styles,
              paddingLeft: '3px',
              paddingRight: '3px',
              paddingTop: 0,
              paddingBottom: 0,
            }),
            clearIndicator: styles => ({
              ...styles,
              paddingTop: '5px',
              paddingBottom: '5px',
              paddingLeft: '1px',
              paddingRight: '1px',
            }),
            control: styles => ({
              ...styles,
              minHeight: rest.minHeight || rest.height || '40px',
              cursor: 'pointer',
              boxShadow: rest.boxShadow || 'none',
              borderColor: '#ccc',
              ...rest.controlStyles,
              border: rest.isDisabled && 'none',
              backgroundColor: rest.isDisabled && '#f4f5f8',
              opacity: rest.isDisabled && 0.9,
            }),
            option: styles => ({
              ...styles,
              cursor: 'pointer',
              color: 'inherit',
              ...rest.optionStyles,
            }),
            menu: styles => ({
              ...styles,
              ...rest.menuStyles,
            }),
            singleValue: styles => ({
              ...styles,
              color: rest.isDisabled && '#9FA6AD',
              fontWeight: rest.isDisabled && '500',
              ...rest.singleValueStyles,
            }),
            multiValue: styles => ({
              ...styles,
              ...rest.multiValueStyles,
            }),
            multiValueLabel: styles => ({
              ...styles,
              ...rest.multiValueLabelStyles,
            }),
            multiValueRemove: styles => ({
              ...styles,
              ...rest.multiValueRemoveStyles,
            }),
            menuPortal: styles => ({
              ...styles,
              ...rest.menuPortalStyles,
            }),
          }}
          {...rest}
        />
      </Stack>
    );
  }
}

export default SelectField;
