import { createSelector } from '@reduxjs/toolkit';
import { selectQualityClaimsCategories } from './selectBasicDetailsClaimsData';

const selectAvailableDaysToResolve = createSelector(selectQualityClaimsCategories, categories => {
  if (!categories || categories.length === 0) return [];

  return [...categories[0].daysToResolve];
});

export default selectAvailableDaysToResolve;
