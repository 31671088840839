import { CREATE_ORDER, SEND_ORDER, CLEAR_ORDERS } from '../actions/actions_optimizer_bsm_orders';

const optimizer_bsm_orders = (order = [{}, {}, {}, {}, {}, {}], action) => {
  let orderCopy = null;
  switch (action.type) {
    case CREATE_ORDER:
      orderCopy = JSON.parse(JSON.stringify(order));
      orderCopy[action.payload.index] = action.payload.json;
      return orderCopy;
    case SEND_ORDER:
      orderCopy = JSON.parse(JSON.stringify(order));
      orderCopy[action.payload.index] = action.payload.json;
      return orderCopy;
    case CLEAR_ORDERS:
      return action.payload;
    default:
      return order;
  }
};

export default optimizer_bsm_orders;
