const schema = [
  {
    name: 'output_product',
    displayName: 'Output Product',
    systemIdField: 'uid',
    displayIdField: 'name',
    path: '/output_products',
    tabGroup: { key: 'products', label: 'Products' },
    deletable: false,
    fields: [
      {
        name: 'uid',
        displayName: 'UID',
        dataType: 'text',
        required: true,
        systemIdentifier: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        dataType: 'text',
        required: true,
      },
      {
        name: 'description',
        displayName: 'Description',
        dataType: 'text',
        required: true,
      },
      {
        name: 'cl',
        displayName: 'CL',
        dataType: 'number',
        required: true,
      },
      {
        name: 'min_fresh',
        displayName: 'Min Fresh',
        dataType: 'number',
        required: true,
      },
      {
        name: 'min_50s',
        displayName: 'Min 50s',
        dataType: 'number',
        required: true,
      },
      {
        name: 'claim',
        displayName: 'Claim',
        dataType: 'shorttext',
      },
      {
        name: 'organisation_ids',
        displayName: 'Organisation',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'organisation',
      },
    ],
  },
];
export default schema;
