import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Heading,
  Text,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  useMediaQuery,
  useTheme,
} from '@chakra-ui/react';
// Local Deps
import './Login.scss';
import Constants from '../../Constants';
import browserHistory from '../utilities/BrowserHistory';
import boabHeroVideo from '../../img/boab-hero-video.mov';
import cfcHeroVideo from '../../img/cfc-hero-video.mov';
import {
  login,
  autoLogin,
  forgotPassword,
  requestAccessError,
  clearStoredCredentials,
} from '../../actions/actions_auth';
import { connect } from 'react-redux';
import { get, isNull, startCase } from 'lodash';
import OtpiInput from '../basic/Otp';
import { sendOtpEmail } from '../../services/two-factor-auth';
import { getTenantFromDomain } from '../../helpers/tenant';

const LoginFormInput = props => {
  return <Input height="50px" onChange={e => props.onChange({ email: e.target.value })} {...props} />;
};

LoginFormInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const LoginPageForm = ({ children, onSubmit, ...rest }) => {
  return (
    <Box
      as="form"
      display="flex"
      justifyContent="center"
      marginTop="25px !important"
      width="100%"
      onSubmit={onSubmit}
      {...rest}
    >
      <VStack align="stretch" width="350px" spacing="10px">
        {children}
      </VStack>
    </Box>
  );
};

LoginPageForm.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const modalContentProps = {
  minWidth: {
    lg: '4xl',
  },
  maxW: {
    base: '90%',
    lg: '65%',
  },
};
class Login extends Component {
  static propTypes = {
    theme: PropTypes.node.isRequired,
    isMobileScreen: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      autoLogin: false,
      displayPrivacyPolicyModal: false,
      displayTermsOfUseModal: false,
      email: '',
      forgotEmail: '',
      forgotPasswordDisplay: false,
      isMuted: true,
      password: '',
      rememberMe: false,
      requestAccess: false,
      requestAccessCompany: '',
      requestAccessEmail: '',
      requestAccessName: '',
      requestAccessRole: '',
      requestedAccess: false,
      isOtpRequired: false,
      otp: '',
      isResendEmailActive: true,
      twoFactorAuthMethod: false,
      availableTwoFactorMethods: [],
    };
  }

  componentDidMount() {
    this.setState({
      rememberMe: !isNull(localStorage.getItem('remember-me')),
    });
    // Check for existing localStorage login info
    if (localStorage.getItem('auto-login-name') !== null) {
      // Check if token has expired; if so, reload page with no data in localStorage
      if (
        localStorage.getItem('auto-login-expiry') &&
        new Date(localStorage.getItem('auto-login-expiry')) > Date.now()
      ) {
        this.setState({
          autoLogin: localStorage.getItem('auto-login-name'),
        });
      } else {
        clearStoredCredentials();
      }
    }
    // if (!this.state.theme) {
    //   getTenantTheme().then(theme => {
    //     this.setState({ theme });
    //     document.title = theme?.data?.home?.title || 'BOAB';
    //   });
    // }
  }

  handleLogin = (type, e) => {
    e.preventDefault();

    if (type === 'direct') {
      this.props.dispatch(
        login(
          this.state.email,
          this.state.password,
          this.state.otp,
          this.state.twoFactorAuthMethod,
          this.state.rememberMe,
          browserHistory,
          this.showOtpField
        )
      );
    } else if (e.keyCode === 13) {
      this.props.dispatch(
        login(
          this.state.email,
          this.state.password,
          this.state.otp,
          this.state.rememberMe,
          browserHistory,
          this.showOtpField
        )
      );
    }
  };

  handleResetPassword = e => {
    e.preventDefault();
    this.props.dispatch(forgotPassword(this.state.forgotEmail));
  };

  handleAutoLogin = e => {
    e.preventDefault();
    this.props.dispatch(autoLogin(null, '/', browserHistory));
  };

  handleModalEsc = e => {
    if (e.keyCode === 27) {
      this.setState({
        displayPrivacyPolicyModal: false,
        displayTermsOfUseModal: false,
      });
    }
  };

  handleDifferentUserLogin = () => {
    clearStoredCredentials();
    this.setState({ autoLogin: false });
  };

  handleVolumeClick = () => {
    const { isMuted } = this.state;
    this.setState({
      isMuted: !isMuted,
    });
  };

  requestAccess() {
    const { requestAccessName, requestAccessEmail, requestAccessCompany, requestAccessRole } = this.state;
    const data = {
      name: requestAccessName,
      email: requestAccessEmail,
      company: requestAccessCompany,
      role: requestAccessRole,
    };
    const that = this;
    fetch(`${Constants.URL}request-access`, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(data),
    })
      .then(response => Constants.handleErrors(response, that.props.dispatch, requestAccessError))
      .then(json => that.setState({ requestedAccess: json }))
      .catch(error => that.props.dispatch(requestAccessError(error)));
  }

  showOtpField = methods => {
    let method = methods.find(el => el.is_default === true && el.is_confirmed === true);
    if (!method) {
      method = methods.find(el => el.is_confirmed === true);
    }
    this.setState({ isOtpRequired: true, twoFactorAuthMethod: method, availableTwoFactorMethods: methods });
  };

  handleChangeOtp = otp => {
    this.setState({ otp });
  };

  handleResendOtp = async () => {
    this.setState({ isResendEmailActive: false });
    await sendOtpEmail(this.state.email);

    setTimeout(() => this.setState({ isResendEmailActive: true }), 30000);
  };

  render() {
    const { isMuted, twoFactorAuthMethod } = this.state;
    const { theme, isMobileScreen } = this.props;
    const tenant = getTenantFromDomain();
    const home = get(theme, 'data.home', {});
    const { authFormPosition, showVideo, videoSource, logo, welcomeMsg } = home;
    return (
      <>
        <Box className="login-page-redesign">
          {showVideo && !isMobileScreen ? (
            <video
              playsinline
              autoPlay="autoplay"
              muted={isMuted}
              loop
              src={videoSource ?? (tenant.includes('cfc') ? cfcHeroVideo : boabHeroVideo)}
            >
              <track kind="captions" />
            </video>
          ) : (
            <Box className="underlay_bg" />
          )}

          <Flex
            alignItems="center"
            justifyContent={{
              base: 'center',
              lg: authFormPosition || 'center',
            }}
            {...{
              [`padding${startCase(authFormPosition)}`]: {
                lg: '63px',
              },
            }}
            width="100%"
            height="100%"
          >
            <VStack
              align="stretch"
              alignItems="center"
              backgroundColor="card.default"
              spacing="35px"
              paddingX={{
                base: '30px',
                lg: '51px',
              }}
              paddingY="31px"
              zIndex="4"
              width={{
                base: '380px',
                lg: '452px',
              }}
              minHeight="585px"
              borderRadius="2xl"
            >
              <Flex justifyContent="flex-end" width="100%">
                {showVideo && !isMobileScreen ? (
                  <IconButton
                    colorScheme="actionPrimary"
                    fontSize="20px"
                    variant="ghost"
                    icon={
                      <i
                        className={`sort-icon fa ${isMuted ? 'fa-volume-mute' : 'fa-volume-up'}`}
                        style={{ fontSize: 'inherit' }}
                      />
                    }
                    onClick={this.handleVolumeClick}
                  />
                ) : (
                  ''
                )}
              </Flex>
              <VStack align="stretch" alignItems="center">
                <Image src={logo} alt="theme_logo" height="97.01px" width="97.01px" />
                <Center height="50px">
                  <Heading as="p" fontFamily="Soleil" fontWeight="bold" fontSize="26px" lineHeight="36.4px">
                    {welcomeMsg}
                  </Heading>
                </Center>
              </VStack>
              {this.state.autoLogin && (
                <Box
                  as="form"
                  marginTop="25px !important"
                  width="100%"
                  onSubmit={this.handleAutoLogin}
                  data-test="auto-login-form"
                >
                  <VStack align="stretch" spacing="6px">
                    <Text fontFamily="Helvetica" a="p">
                      You are currently signed in as:
                    </Text>
                    <Text fontFamily="Helvetica" a="p" fontWeight="semibold" fontSize="20px">
                      {this.state.autoLogin}
                    </Text>
                    <Button
                      variant="link"
                      width="fit-content"
                      marginLeft="auto !important"
                      fontFamily="Helvetica"
                      fontWeight="bold"
                      fontSize="12px"
                      onClick={this.handleDifferentUserLogin}
                      data-test="not-you"
                    >
                      Not you?
                    </Button>
                    <Button
                      type="submit"
                      colorScheme="actionPrimary"
                      marginTop="26.5px !important"
                      fontFamily="Helvetica"
                      fontSize="12px"
                      fontWeight="700"
                      height="45px"
                    >
                      Continue
                    </Button>
                  </VStack>
                </Box>
              )}
              {!this.state.autoLogin &&
                !this.state.requestAccess &&
                !this.state.forgotPasswordDisplay &&
                !this.state.isOtpRequired && (
                  <LoginPageForm onSubmit={this.handleLogin.bind(this, 'direct')} data-test="login-form">
                    <FormControl>
                      <FormLabel
                        fontFamily="Helvetica"
                        fontWeight="bold"
                        marginBottom="5px"
                        htmlFor="login_email"
                        marginLeft="6px"
                      >
                        Email
                      </FormLabel>
                      <LoginFormInput
                        id="login_email"
                        type="text"
                        autoComplete="off"
                        fontFamily="Helvetica"
                        fontWeight="normal"
                        placeholder="Your email address"
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                        data-test="username"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        fontFamily="Helvetica"
                        fontWeight="bold"
                        marginBottom="5px"
                        htmlFor="login_password"
                        marginLeft="6px"
                      >
                        Password
                      </FormLabel>
                      <LoginFormInput
                        id="login_password"
                        type="password"
                        autoComplete="off"
                        fontFamily="Helvetica"
                        fontWeight="normal"
                        placeholder="Your password"
                        value={this.state.password}
                        onChange={e => this.setState({ password: e.target.value })}
                        data-test="password"
                      />
                    </FormControl>
                    <Flex justifyContent="space-between" marginTop="24px !important">
                      <HStack spacing="12px">
                        <Switch
                          colorScheme="actionPrimary"
                          mb="0"
                          id="remember_me"
                          size="md"
                          isChecked={this.state.rememberMe}
                          onChange={e => this.setState({ rememberMe: e.target.checked })}
                          data-test="remember-me"
                        />
                        <FormLabel
                          htmlFor="remember_me"
                          mb="0"
                          fontFamily="Helvetica"
                          fontWeight="bold"
                          fontSize="12px"
                        >
                          Remember me
                        </FormLabel>
                      </HStack>
                      <Button
                        variant="link"
                        colorScheme="actionPrimary"
                        fontFamily="Helvetica"
                        fontWeight="bold"
                        fontSize="12px"
                        onClick={() =>
                          this.setState({
                            forgotPasswordDisplay: true,
                          })
                        }
                      >
                        Forgot Password?
                      </Button>
                    </Flex>
                    <Button
                      type="submit"
                      colorScheme="actionPrimary"
                      marginTop="36.5px !important"
                      fontFamily="Helvetica"
                      fontSize="12px"
                      fontWeight="700"
                      height="45px"
                      data-test="sign-in-button"
                    >
                      Sign in
                    </Button>
                    <Flex width="100%" justifyContent="center">
                      <Button
                        variant="link"
                        colorScheme="actionPrimary"
                        fontFamily="Helvetica"
                        fontWeight="bold"
                        width="max-content"
                        onClick={() => this.setState({ requestAccess: true })}
                      >
                        Request Access
                      </Button>
                    </Flex>
                  </LoginPageForm>
                )}
              {this.state.requestAccess && (
                <LoginPageForm onSubmit={this.requestAccess.bind(this)}>
                  <Flex width="100%" justifyContent="center">
                    <Button
                      variant="link"
                      colorScheme="actionPrimary"
                      fontFamily="Helvetica"
                      fontWeight="bold"
                      width="max-content"
                      onClick={() => this.setState({ requestAccess: false })}
                    >
                      Cancel Request Access
                    </Button>
                  </Flex>
                  <LoginFormInput
                    type="text"
                    autoComplete="off"
                    fontFamily="Helvetica"
                    fontWeight="normal"
                    placeholder="Name"
                    name="name"
                    value={this.state.requestAccessName}
                    onChange={e => this.setState({ requestAccessName: e.target.value })}
                    data-test="request-access-name"
                  />
                  <LoginFormInput
                    type="text"
                    autoComplete="off"
                    fontFamily="Helvetica"
                    fontWeight="normal"
                    placeholder="Email"
                    name="email"
                    value={this.state.requestAccessEmail}
                    onChange={e => this.setState({ requestAccessEmail: e.target.value })}
                    data-test="request-access-email"
                  />
                  <LoginFormInput
                    type="text"
                    autoComplete="off"
                    fontFamily="Helvetica"
                    fontWeight="normal"
                    placeholder="Company"
                    name="company"
                    value={this.state.requestAccessCompany}
                    onChange={e => this.setState({ requestAccessCompany: e.target.value })}
                    data-test="request-access-company"
                  />
                  <LoginFormInput
                    type="text"
                    autoComplete="off"
                    fontFamily="Helvetica"
                    fontWeight="normal"
                    placeholder="Role"
                    name="role"
                    value={this.state.requestAccessRole}
                    onChange={e => this.setState({ requestAccessRole: e.target.value })}
                    data-test="request-access-role"
                  />
                  {!this.state.requestedAccess ? (
                    <Button
                      onClick={this.requestAccess.bind(this)}
                      data-test="send-request-access-btn"
                      colorScheme="actionPrimary"
                      fontFamily="Helvetica"
                      fontSize="12px"
                      fontWeight="700"
                      height="45px"
                    >
                      Send access request
                    </Button>
                  ) : (
                    <Alert status="info" data-test="request-access-alert" fontFamily="Helvetica" fontSize="12px">
                      Access has been requested for {this.state.requestedAccess.name}. Please wait to be confirmed by an
                      administrator.
                    </Alert>
                  )}
                </LoginPageForm>
              )}

              {this.state.forgotPasswordDisplay && (
                <LoginPageForm id="login-page-forget-form" onSubmit={this.handleResetPassword} data-test="forgot-form">
                  <Text as="p" fontFamily="Helvetica" fontWeight="bold" fontSize="23px">
                    Forgot Password?
                  </Text>
                  <Text as="p" fontFamily="Helvetica" fontSize="13.5px">
                    Enter your e-mail address below to reset your password.
                  </Text>
                  <LoginFormInput
                    type="text"
                    autoComplete="off"
                    fontFamily="Helvetica"
                    fontWeight="normal"
                    placeholder="Email"
                    name="email"
                    value={this.state.forgotEmail}
                    onChange={e => this.setState({ forgotEmail: e.target.value })}
                    data-test="forgot-input"
                  />
                  {this.props.password_reset && (
                    <Alert status="info" data-test="request-access-alert" fontFamily="Helvetica" fontSize="12px">
                      Check your email for a reset password link.
                    </Alert>
                  )}
                  <Flex justifyContent="space-between">
                    <Button
                      id="back-btn"
                      fontFamily="Helvetica"
                      fontWeight="bold"
                      variant="link"
                      onClick={() =>
                        this.setState({
                          forgotPasswordDisplay: !this.state.forgotPasswordDisplay,
                        })
                      }
                      data-test="back-btn"
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      value="Submit"
                      fontFamily="Helvetica"
                      fontWeight="bold"
                      colorScheme="actionPrimary"
                    >
                      Submit
                    </Button>
                  </Flex>
                </LoginPageForm>
              )}
              {this.state.isOtpRequired && (
                <LoginPageForm onSubmit={this.handleLogin.bind(this, 'direct')} data-test="login-form">
                  <VStack align="stretch" alignItems="center">
                    <Text as="p" fontWeight="bold" fontFamily="Helvetica" fontSize="16px" marginTop="30px !important">
                      Please verify that it&apos;s you
                    </Text>
                    {twoFactorAuthMethod?.name === 'email' && (
                      <Text as="p" fontFamily="Helvetica" fontSize="14px" align="center">
                        We sent verification code to the email address attached to your account
                      </Text>
                    )}

                    {twoFactorAuthMethod?.name === 'authentication_app' && (
                      <Text as="p" fontFamily="Helvetica" fontSize="14px" align="center">
                        You will need a Google Authenticator to complete this process
                      </Text>
                    )}
                  </VStack>
                  <Text as="p" fontFamily="Helvetica" fontSize="14px" marginTop="20px !important">
                    Verification Code:
                  </Text>
                  <OtpiInput handleChangeOtp={this.handleChangeOtp} marginLeft={30} />
                  <Flex justifyContent="space-between">
                    <Button
                      id="back-btn"
                      fontFamily="Helvetica"
                      variant="link"
                      onClick={() =>
                        this.setState({
                          isOtpRequired: false,
                          otp: '',
                          twoFactorAuthMethod: false,
                        })
                      }
                      data-test="back-btn"
                    >
                      Back
                    </Button>
                    <HStack spacing="12px">
                      <Switch
                        colorScheme="actionSecondary"
                        mb="0"
                        id="remember_me"
                        size="md"
                        isChecked={this.state.rememberMe}
                        onChange={e => this.setState({ rememberMe: e.target.checked })}
                        data-test="remember-me"
                      />
                      <FormLabel
                        htmlFor="remember_me"
                        mb="0"
                        fontFamily="Helvetica"
                        fontWeight="normal"
                        fontSize="12px"
                      >
                        Remember me
                      </FormLabel>
                    </HStack>
                  </Flex>

                  <Button
                    type="submit"
                    colorScheme="actionPrimary"
                    marginTop="30px !important"
                    fontFamily="Helvetica"
                    fontSize="12px"
                    fontWeight="700"
                    height="45px"
                    data-test="sign-in-button"
                  >
                    Veify and Sign in
                  </Button>

                  {this.state.twoFactorAuthMethod?.name === 'email' && !this.state.isResendEmailActive && (
                    <Text as="p" fontFamily="Helvetica" fontWeight="bold" fontSize="10px" align="center">
                      Please wait 30 seconds to request another code
                    </Text>
                  )}
                  {this.state.twoFactorAuthMethod?.name === 'email' && (
                    <Button
                      disabled={!this.state.isResendEmailActive}
                      variant="link"
                      type="link"
                      marginTop="10px !important"
                      fontFamily="Helvetica"
                      fontSize="12px"
                      fontWeight="700"
                      height="45px"
                      data-test="sign-in-button"
                      onClick={() => this.handleResendOtp()}
                    >
                      Resend Code
                    </Button>
                  )}

                  <Menu closeOnSelect={false}>
                    <MenuButton
                      as={Button}
                      colorScheme="white"
                      color="black"
                      marginTop="10px !important"
                      fontFamily="Helvetica"
                      fontSize="12px"
                      fontWeight="700"
                      height="45px"
                    >
                      VERIFY ANOTHER WAY
                    </MenuButton>
                    <MenuList minW="0" w="350px">
                      <MenuOptionGroup defaultValue={this.state.twoFactorAuthMethod.name} type="radio">
                        {this.state.availableTwoFactorMethods.map(el => {
                          if (el.is_confirmed) {
                            return (
                              <MenuItemOption
                                value={el.name}
                                fontFamily="Helvetica"
                                fontWeight="normal"
                                onClick={() => this.setState({ twoFactorAuthMethod: el })}
                              >
                                {el.name}
                              </MenuItemOption>
                            );
                          }
                        })}
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                </LoginPageForm>
              )}
              <Flex justifyContent="space-between" width="100%">
                <Button
                  variant="link"
                  width="max-content"
                  fontFamily="Helvetica"
                  fontWeight="bold"
                  fontSize="sm"
                  onClick={e =>
                    this.setState({
                      displayPrivacyPolicyModal: !this.state.displayPrivacyPolicyModal,
                    })
                  }
                  data-test="policy-link"
                  color="gray.700"
                >
                  Privacy Policy
                </Button>
                <Button
                  variant="link"
                  width="max-content"
                  fontFamily="Helvetica"
                  fontWeight="bold"
                  fontSize="sm"
                  onClick={e =>
                    this.setState({
                      displayTermsOfUseModal: !this.state.displayTermsOfUseModal,
                    })
                  }
                  data-test="terms-link"
                  color="gray.700"
                >
                  Terms of Use
                </Button>
              </Flex>
            </VStack>
          </Flex>
          <Modal
            // isOpen={this.state.displayPrivacyPolicyModal}
            isOpen={this.state.displayPrivacyPolicyModal}
            onClose={() =>
              this.setState({
                displayPrivacyPolicyModal: false,
              })
            }
          >
            <ModalOverlay />
            <ModalContent {...modalContentProps}>
              <ModalHeader borderBottomWidth="1px">Privacy Policy</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div
                  dangerouslySetInnerHTML={{
                    __html: theme?.data?.config?.privacyPolicy,
                  }}
                />
              </ModalBody>
              <ModalFooter borderTopWidth="1px">
                <Button
                  marginLeft="auto"
                  onClick={() =>
                    this.setState({
                      displayPrivacyPolicyModal: false,
                    })
                  }
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Modal
            isOpen={this.state.displayTermsOfUseModal}
            onClose={() =>
              this.setState({
                displayTermsOfUseModal: false,
              })
            }
          >
            <ModalOverlay />
            <ModalContent {...modalContentProps}>
              <ModalHeader borderBottomWidth="1px">Terms Of Use</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div
                  dangerouslySetInnerHTML={{
                    __html: theme?.data?.config?.termsAndConditions,
                  }}
                />
              </ModalBody>
              <ModalFooter borderTopWidth="1px">
                <Button
                  marginLeft="auto"
                  onClick={() =>
                    this.setState({
                      displayTermsOfUseModal: false,
                    })
                  }
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    theme: state.theme,
  };
};

const LoginFunctionalComponentContainer = props => {
  const { __breakpoints: breakpoints } = useTheme();

  const [isMobileScreen] = useMediaQuery(`(max-width: ${get(breakpoints, 'asObject.lg', '62em')})`);

  return <Login {...props} isMobileScreen={isMobileScreen} />;
};

export default connect(mapStateToProps)(LoginFunctionalComponentContainer);
