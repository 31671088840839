import { FormControl, FormLabel } from '@chakra-ui/form-control';
import {
  Box,
  Button,
  IconButton,
  Input,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import Select from '../../../components/basic/SelectField';
import { getInternalPoLabel } from '../../../utils';
import Tooltip from '../../../components/quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const PO_FROM = {
  internal_po_number: 'internal_po_number',
  grinder_po_number: 'grinder_po_number',
  packer_so_number: 'packer_so_number',
  id: 'id',
  fake_load: 'fake_load',
};

const textStyle = {
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  fontFamily: 'SFMono-Regular,Menlo,Monaco,Consolas,monospace',
  fontSize: '1em',
};

const formatCalculatorOption = item => ({ label: item.label, value: item.uid });

export default ({ mode = 'test', onPricing, calculators, configs }) => {
  const [poFrom, setPoFrom] = useState(Object.keys(PO_FROM)[0]);
  const [poFromValue, setPoFromValue] = useState();
  const [pricingCalculatorUid, setPricingCalculatorUid] = useState();
  const [testResult, setTestResult] = useState();
  const [isLoading, setLoading] = useState();

  const onPricingLoad = () => {
    setTestResult(null);
    setLoading(true);
    onPricing({ load_from: poFrom, load_from_value: poFromValue, pricing_calculator_uid: pricingCalculatorUid, mode })
      .then(response => {
        setTestResult(response.data);
      })
      .finally(() => setLoading(false));
  };
  return (
    <Box>
      <Stack spacing="10">
        <FormControl>
          <FormLabel>
            PO from&nbsp;
            <Tooltip
              content="Select the PO from where the PO needs to come to test the Pricing Engine."
              placement="right"
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
          </FormLabel>
          <Select
            {...{
              isClearable: true,
              isSearchable: true,
              closeMenuOnSelect: true,
              removeSelected: true,
              isMulti: false,
              value: poFrom && calculators && { value: poFrom, label: PO_FROM[poFrom].replaceAll('_', ' ') },
              onChange: poFrom => {
                setPoFrom(poFrom.value);
                setPoFromValue('');
              },
              options: Object.keys(PO_FROM).map(key => ({ value: key, label: PO_FROM[key].replaceAll('_', ' ') })),
            }}
          />
        </FormControl>
        {PO_FROM.internal_po_number === poFrom && (
          <FormControl>
            <FormLabel>
              {getInternalPoLabel(configs)}&nbsp;
              <Tooltip
                content={`Provide the ${getInternalPoLabel(configs)} to test the Pricing Engine.`}
                placement="right"
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </FormLabel>
            <Input type="number" onChange={e => setPoFromValue(e.target.value)} value={poFromValue} />
          </FormControl>
        )}
        {PO_FROM.grinder_po_number === poFrom && (
          <FormControl>
            <FormLabel>Grinder PO #</FormLabel>
            <Input type="text" onChange={e => setPoFromValue(e.target.value)} value={poFromValue} />
          </FormControl>
        )}
        {PO_FROM.packer_so_number === poFrom && (
          <FormControl>
            <FormLabel>Packer SO #</FormLabel>
            <Input type="text" onChange={e => setPoFromValue(e.target.value)} value={poFromValue} />
          </FormControl>
        )}
        {PO_FROM.id === poFrom && (
          <FormControl>
            <FormLabel>PO ID #</FormLabel>
            <Input type="number" onChange={e => setPoFromValue(e.target.value)} value={poFromValue} />
          </FormControl>
        )}
        {PO_FROM.fake_load === poFrom && (
          <FormControl>
            <FormLabel>Fake load</FormLabel>
            <Textarea
              marginBottom="10px"
              onChange={e => setPoFromValue(e.target.value)}
              value={poFromValue}
              height={300}
            />
            <Button
              colorScheme="teal"
              size="xs"
              onClick={() =>
                setPoFromValue(
                  JSON.stringify(
                    {
                      id: 142972,
                      end_user_id: 1,
                      delivery_date: '2023-03-30',
                      grinder_uid: 'OSIIL',
                      packer_plant_id: 'CARDC',
                      transport_cost_per_unit: 0.11,
                      lines: [
                        {
                          id: 194194,
                          line_number: 1,
                          buy_price_per_unit: 2.76,
                          price_per_unit: 2.65,
                          input_product: {
                            product_type: 'BEEF',
                          },
                        },
                      ],
                    },
                    null,
                    2
                  )
                )
              }
            >
              Load example
            </Button>
          </FormControl>
        )}
        <FormControl>
          <FormLabel>
            Calculator&nbsp;
            <Tooltip
              content="Select the Calculator created under Calculator to test the Pricing Engine."
              placement="right"
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
          </FormLabel>
          <Select
            {...{
              isClearable: true,
              isSearchable: true,
              closeMenuOnSelect: true,
              removeSelected: true,
              isMulti: false,
              value:
                pricingCalculatorUid &&
                calculators &&
                formatCalculatorOption({
                  id: pricingCalculatorUid,
                  label: calculators.find(c => c.uid === pricingCalculatorUid).label,
                }),
              onChange: calculator => {
                setPricingCalculatorUid(calculator.value);
              },
              options: (calculators || []).map(formatCalculatorOption),
            }}
          />
        </FormControl>
      </Stack>
      <Box marginY="32px">
        {testResult && (
          <Tabs>
            <TabList>
              <Tab>Pricing</Tab>
              <Tab>Documents</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {testResult?.logs?.map((t, i) => {
                  const type = t.type;
                  const data = t.data;
                  let content = null;
                  if ('title' === type) {
                    content = (
                      <p style={textStyle}>
                        <b>{data}</b>
                      </p>
                    );
                  } else if ('text' === type || 'debug' === type) {
                    content = <p style={textStyle}>{data}</p>;
                  } else if ('json' === type) {
                    content = (
                      <ReactJson
                        name={false}
                        src={data}
                        collapsed={1}
                        enableClipboard={false}
                        displayObjectSize={false}
                        displayDataTypes={false}
                      />
                    );
                  } else if ('exception' === type) {
                    content = <p style={{ ...textStyle, color: '#E53E3E' }}>{data}</p>;
                  } else if ('html' === type) {
                    content = <div dangerouslySetInnerHTML={{ __html: data }}></div>;
                  }
                  return <div key={i}>{content}</div>;
                })}
              </TabPanel>
              <TabPanel>
                {testResult?.documents?.map(d => (
                  <Box key={d.label} py="2em" my="2em" borderBottom="1px" borderColor="#CCC">
                    <p style={textStyle}>
                      <b>{d.label}</b>
                    </p>
                    <div dangerouslySetInnerHTML={{ __html: d.preview }}></div>
                  </Box>
                ))}
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
        {isLoading && (
          <div style={{ textAlign: 'center' }}>
            <Spinner />
          </div>
        )}
      </Box>
      <Button type="button" colorScheme="actionPrimary" onClick={onPricingLoad}>
        {mode === 'test' && 'Test'}
      </Button>
    </Box>
  );
};
