import {
  FDR_FREIGHT_RATES_FETCHED,
  FDR_FREIGHT_RATE_UPDATED,
  FDR_FREIGHT_RATE_REMOVED,
  FDR_FREIGHT_RATE_ADDED,
} from '../actions/actions_fdr_freight_rates';

const fdrFreightRates = (FDRFreightRates = [], action) => {
  switch (action.type) {
    case FDR_FREIGHT_RATES_FETCHED:
      return action.payload;
    case FDR_FREIGHT_RATE_ADDED:
      return [...FDRFreightRates, action.payload];
    case FDR_FREIGHT_RATE_UPDATED:
      return [...FDRFreightRates.filter(item => item.id !== action.payload.id), action.payload];
    case FDR_FREIGHT_RATE_REMOVED:
      return [...FDRFreightRates.filter(item => item.id !== action.payload.id)];
    default:
      return FDRFreightRates;
  }
};

export default fdrFreightRates;
