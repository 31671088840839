import React from 'react';
import NextGenHeader from '../headers/NextGenHeader';
import './NextGenLayoutContainer.scss';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
  navigation: PropTypes.elementType,
  mainContent: PropTypes.elementType,
};

const NextGenLayoutContainer = props => {
  const { navigation, mainContent, children } = props;
  return (
    <div className="next-gen-layout-container">
      <NextGenHeader {...props} />
      <div className="app-content">
        {navigation}
        <div className="nav-spacer" />
        <div className="main-content">
          {mainContent}
          {children}
        </div>
      </div>
    </div>
  );
};

NextGenLayoutContainer.propTypes = propTypes;

export default NextGenLayoutContainer;
