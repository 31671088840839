import { connect } from 'react-redux';

import SignatureUploadComponent from '../../components/admin/SignatureUpload';

const mapStateToProps = state => {
  return {
    token: state.user.token,
    signature: state.user_documents.signature ? state.user_documents.signature.signature : null,
    loading: state.user_documents.loading,
  };
};

const SignatureUpload = connect(mapStateToProps)(SignatureUploadComponent);

export default SignatureUpload;
