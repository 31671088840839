import Constants from '../Constants';

export const PORTS_FETCHED = 'PORTS_FETCHED';
export const PORT_ADDED = 'PORT_ADDED';
export const PORT_UPDATED = 'PORT_UPDATED';
export const PORT_REMOVED = 'PORT_REMOVED';
export const PORT_ERROR = 'PORT_ERROR';

const BASE_URL = `${Constants.URL}ports`;

export function fetchPorts(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(BASE_URL, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPort))
      .then(json => dispatch(loadPorts(json)))
      .catch(error => dispatch(errorPort(error)));
  };
}

export function postPorts(port, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(BASE_URL, {
      method: 'POST',
      body: JSON.stringify(port),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPort))
      .then(json => dispatch(addPort(json)))
      .catch(error => dispatch(errorPort(error)));
  };
}

export function patchPorts(port, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${port.id}`, {
      method: 'PATCH',
      body: JSON.stringify(port),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPort))
      .then(json => {
        const thisJson = JSON.parse(JSON.stringify(json));
        dispatch(updatePort(thisJson));
      })
      .catch(error => dispatch(errorPort(error)));
  };
}

export function deletePorts(port, token) {
  return dispatch => {
    delete port.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${port.id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPort))
      .then(json => dispatch(removePort(port)))
      .catch(error => dispatch(errorPort(error)));
  };
}

export function loadPorts(ports) {
  return {
    type: PORTS_FETCHED,
    payload: ports,
  };
}

export function addPort(port) {
  return {
    type: PORT_ADDED,
    payload: port,
  };
}

export function removePort(port) {
  return {
    type: PORT_REMOVED,
    payload: port,
  };
}

export function updatePort(port) {
  return {
    type: PORT_UPDATED,
    payload: port,
  };
}

export function errorPort(error) {
  return {
    type: PORT_ERROR,
    payload: error,
  };
}
