import { connect } from 'react-redux';
import PoStrategyComponent from '../../components/basic/PoStrategy/PoStrategy';

const mapStateToProps = state => {
  return {
    entityManager: state.entityManager,
  };
};

const PoStrategy = connect(mapStateToProps)(PoStrategyComponent);

export default PoStrategy;
