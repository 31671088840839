import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';

import isEmpty from 'lodash/isEmpty';
import { get, groupBy, startCase } from 'lodash';
import RightAngleIcon from '../core/RightAngleIcon';

export const TaskCategories = ({ groups, onViewAll }) => {
  return (
    <>
      {isEmpty(groups) ? (
        <Flex width="100%" height="200px" justifyContent="center" alignItems="center">
          <Text>No Daily Tasks found</Text>
        </Flex>
      ) : (
        groups.map(el => {
          const groupedExceptions = groupBy(el.data, 'exception.exception_type_name');
          return (
            <VStack
              key={el.label}
              paddingX="13px"
              paddingY="23px"
              bgColor="card.default"
              width="286px"
              height="fit-content"
              borderRadius="15px"
              boxShadow="md"
              minW="100%"
            >
              <Flex alignItems="center" width="100%">
                <RightAngleIcon />
                <Heading fontSize="15px" marginLeft="10px">
                  {el.label}
                </Heading>
              </Flex>
              {!isEmpty(groupedExceptions) ? (
                <>
                  <Accordion allowMultiple width="100%">
                    {Object.keys(groupedExceptions).map(key => {
                      const exceptions = get(groupedExceptions, `[${key}]`, []).map(ex => ({
                        ...ex.exception,
                        packer_name: ex.packer_name,
                        grinder_name: ex.grinder_name,
                      }));
                      const total = exceptions.length;
                      const exceptionTypeName = startCase(get(exceptions, '[0].exception_type.name').replace('_', ' '));
                      return (
                        <AccordionItem key={key}>
                          {({ isExpanded }) => (
                            <>
                              <AccordionButton
                                paddingY="10px"
                                paddingX="6px"
                                borderBottomWidth="1px"
                                bgColor={isExpanded ? 'white' : 'inherit'}
                              >
                                <Badge
                                  variant="solid"
                                  width="24px"
                                  height="24px"
                                  borderRadius="24px"
                                  mr="9px"
                                  colorScheme="actionSecondary"
                                >
                                  <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
                                    <Text fontSize="12px" fontWeight="500">
                                      {total}
                                    </Text>
                                  </Flex>
                                </Badge>
                                <Box flex="1">
                                  <Text fontWeight={isExpanded ? '600' : '500'} textAlign="left">
                                    {exceptionTypeName}
                                  </Text>
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                              <AccordionPanel padding={0} bgColor={isExpanded ? 'white' : 'inherit'}>
                                <VStack
                                  align="stretch"
                                  divider={<StackDivider borderColor="gray.200" style={{ margin: '0px' }} />}
                                >
                                  {exceptions.slice(0, 5).map(exception => {
                                    const assignedUser = get(exception, 'assigned_user.first_name');
                                    return (
                                      <HStack
                                        key={exception.id}
                                        spacing="12px"
                                        width="100%"
                                        paddingY="8.5px"
                                        paddingX="12px"
                                        alignItems="flex-start"
                                        _hover={{
                                          bgColor: 'actionSecondary.hover',
                                        }}
                                      >
                                        <Badge
                                          variant="solid"
                                          width="32px"
                                          height="32px"
                                          borderRadius="32px"
                                          colorScheme="actionSecondary"
                                        >
                                          <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
                                            {isEmpty(assignedUser) ? (
                                              <i
                                                className="fa fa-user-circle"
                                                style={{
                                                  fontSize: '32px',
                                                }}
                                              />
                                            ) : (
                                              <Text fontWeight="500">
                                                {get(exception, 'assigned_user.first_name')?.slice(0, 1)}
                                                {get(exception, 'assigned_user.last_name')?.slice(0, 1)}
                                              </Text>
                                            )}
                                          </Flex>
                                        </Badge>
                                        <Box mt="2.5px">
                                          <Text fontWeight="400">
                                            Grinder PO #{get(exception, 'purchase_orders[0].grinder_po_number')}
                                          </Text>
                                          <Text fontWeight="600">
                                            Internal PO #{get(exception, 'purchase_orders[0].internal_po_number')}
                                          </Text>
                                          <Text>Establishment {get(exception, 'packer_name')}</Text>
                                          <Text>Grinder {get(exception, 'grinder_name')}</Text>
                                        </Box>
                                      </HStack>
                                    );
                                  })}
                                  <Flex mt="80px" mb="10px" justifyContent="center">
                                    <Button bgColor="inherit" fontWeight="700" onClick={onViewAll}>
                                      View all
                                    </Button>
                                  </Flex>
                                </VStack>
                              </AccordionPanel>
                            </>
                          )}
                        </AccordionItem>
                      );
                    })}
                  </Accordion>
                </>
              ) : (
                <Flex height="200px" justifyContent="center" alignItems="center">
                  <Text>No Daily Tasks found</Text>
                </Flex>
              )}
            </VStack>
          );
        })
      )}
    </>
  );
};

TaskCategories.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  onViewAll: PropTypes.func.isRequired,
};
