import Constants from '../Constants';

export const ORDERS_COLDSTORE_RELEASED_BASE_URL = `${Constants.URL}coldstore_orders?status=coldstore_released`;

export const ORDERS_COLDSTORE_RELEASED_FETCHED = 'ORDERS_COLDSTORE_RELEASED_FETCHED';
export const REPLACE_ORDER_COLDSTORE_RELEASED = 'REPLACE_ORDER_COLDSTORE_RELEASED';
export const REPLACE_ORDERS_COLDSTORE_RELEASED = 'REPLACE_ORDERS_COLDSTORE_RELEASED';
export const REMOVE_ORDER_COLDSTORE_RELEASED = 'REMOVE_ORDER_COLDSTORE_RELEASED';
export const ORDERS_COLDSTORE_RELEASED_ERROR = 'ORDERS_COLDSTORE_RELEASED_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function replaceOrderColdstoreReleased(orderColdstoreReleased) {
  return {
    type: REPLACE_ORDER_COLDSTORE_RELEASED,
    payload: orderColdstoreReleased,
  };
}

export function removeOrderColdstoreReleased(orderColdstoreReleased) {
  return {
    type: REMOVE_ORDER_COLDSTORE_RELEASED,
    payload: orderColdstoreReleased,
  };
}

export function loadOrdersColdstoreReleased(ordersColdstoreReleased) {
  return {
    type: ORDERS_COLDSTORE_RELEASED_FETCHED,
    payload: ordersColdstoreReleased,
  };
}

export function errorOrdersColdstoreReleased(error) {
  return {
    type: ORDERS_COLDSTORE_RELEASED_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchOrdersColdstoreReleased(token, deliveryDateStart) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    let url = ORDERS_COLDSTORE_RELEASED_BASE_URL;
    if (deliveryDateStart) {
      url = `${ORDERS_COLDSTORE_RELEASED_BASE_URL}&delivery_date_start=${deliveryDateStart}`;
    }
    return fetch(url, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrdersColdstoreReleased))
      .then(json => dispatch(loadOrdersColdstoreReleased(json)))
      .catch(error => dispatch(errorOrdersColdstoreReleased(error)));
  };
}
