import { connect } from 'react-redux';

import SupportComponent from '../../components/pages/Support';

const mapStateToProps = state => {
  return {
    token: state.user.token,
    user: state.user,
    error: state.error,
  };
};

const Support = connect(mapStateToProps)(SupportComponent);

export default Support;
