import Constants from '../Constants';

export const PACKER_FETCHED = 'PACKER_FETCHED';
export const PACKER_ADDED = 'PACKER_ADDED';
export const PACKER_EDIT = 'PACKER_EDIT';
export const PACKER_REMOVED = 'PACKER_REMOVED';
export const PACKER_ERROR = 'PACKER_ERROR';

export const PACKERS_PATH = `${Constants.URL}packers/`;

export function fetchPackers(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(PACKERS_PATH, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPacker))
      .then(json => dispatch(loadPacker(json)))
      .catch(error => dispatch(errorPacker(error)));
  };
}

export function postPackers(packers, token) {
  return dispatch => {
    delete packers.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(PACKERS_PATH, {
      method: 'POST',
      body: JSON.stringify(packers),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPacker))
      .then(json => dispatch(addPacker(json)))
      .catch(error => dispatch(errorPacker(error)));
  };
}

export function patchPacker(packer, token) {
  return dispatch => {
    delete packer.type;
    delete packer.id;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(PACKERS_PATH + packer.id, {
      method: 'PATCH',
      body: JSON.stringify(packer),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPacker))
      .then(json => dispatch(editPacker(json)))
      .catch(error => dispatch(errorPacker(error)));
  };
}

export function deletePacker(packer, token) {
  return dispatch => {
    delete packer.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(PACKERS_PATH + packer.id, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPacker))
      .then(json => dispatch(removePacker(packer)))
      .catch(error => dispatch(errorPacker(error)));
  };
}

export function loadPacker(packer) {
  return {
    type: PACKER_FETCHED,
    payload: packer,
  };
}

export function addPacker(packers) {
  return {
    type: PACKER_ADDED,
    payload: packers,
  };
}

export function removePacker(transporter) {
  return {
    type: PACKER_REMOVED,
    payload: transporter,
  };
}

export function editPacker(packers) {
  return {
    type: PACKER_EDIT,
    payload: packers,
  };
}

export function errorPacker(error) {
  return {
    type: PACKER_ERROR,
    payload: error,
  };
}
