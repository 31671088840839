import { SSR_LIST_FETCHED } from '../actions/actions_self_service_reports';

const selfServiceReports = (ssr = [], action) => {
  switch (action.type) {
    case SSR_LIST_FETCHED:
      return action.payload;
    default:
      return ssr;
  }
};

export default selfServiceReports;
