import React from 'react';
import { Checkbox, Typography } from 'antd';
import styles from '../Filter.module.css';

const MAX_NUM_OF_ITEMS_CHECKED = 10;

export default function FilterCheckboxGroupGeneric(props) {
  const { value, onChange, options, label, dataColors, isDisabled } = props;

  return (
    <div>
      {label && <Typography.Text className={styles.filterLabel}>{label}</Typography.Text>}
      <Checkbox.Group className={styles.checkboxGroup} value={value} defaultValue={value} onChange={onChange}>
        {options.map(x => {
          const displayValue = typeof x === 'string' && x.split('->').length > 1 ? x.split('->')[1] : x;
          return (
            <div key={label + '-' + x} className={styles.checkbox} data-color={dataColors?.[x]}>
              <Checkbox
                value={x}
                disabled={
                  isDisabled ||
                  (value.length === 1 && value[0] === x) ||
                  (value.length === MAX_NUM_OF_ITEMS_CHECKED && !value.includes(x))
                }
              >
                {displayValue}
              </Checkbox>
            </div>
          );
        })}
      </Checkbox.Group>
    </div>
  );
}
