import { connect } from 'react-redux';

import OptimizerComponent from '../../components/optimizer/Optimizer';

const mapStateToProps = state => {
  return {
    plant_id: state.grinders,
    suppliers: state.suppliers,
    transport: state.transport.transport_items,
    packer_plants: state.packer_plants,
    grinders: state.grinders,
    optimizer_data: state.optimizer_data,
    input_products: state.input_products,
    output_products: state.output_products,
    optimizer_beef_forecast: state.optimizer_beef_forecast,
    optimizer_bsm_orders: state.optimizer_bsm_orders,
    user: state.user,
    token: state.user.token,
  };
};

const Optimizer = connect(mapStateToProps)(OptimizerComponent);

export default Optimizer;
