import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Wrap } from '@chakra-ui/react';
import ArticleCard from './ArticleCard';
import KPICard from './KPICard';
import moment from 'moment';
import HeadingWithButton from '../HeadingWithButton';

const ArticleCardList = ({ articles = [], groupByYear = false }) => {
  const [articlesByYear, setArticlesByYear] = useState();

  useEffect(() => {
    if (!groupByYear) {
      return;
    }
    setArticlesByYear(
      articles.reduce((o, c) => {
        const newObject = o;
        if (moment(c.published_date).isValid()) {
          const year = moment(c.published_date).year();
          if (Array.isArray(o[year])) {
            newObject[year].push(c);
          } else {
            newObject[year] = [c];
          }
        }
        return newObject;
      }, {})
    );
  }, [groupByYear, articles]);

  const renderArticles = as => (
    <Wrap spacing="50px" mb="12" justify="center">
      {as.map(article => {
        return article.tags && article.tags.includes('KPI') ? (
          <KPICard article={article} />
        ) : (
          <ArticleCard article={article} />
        );
      })}
    </Wrap>
  );

  return articlesByYear
    ? Object.keys(articlesByYear)
        .sort()
        .reverse()
        .map(k => (
          <>
            <HeadingWithButton text={k} />
            {renderArticles(articlesByYear[k])}
          </>
        ))
    : renderArticles(articles);
};

ArticleCardList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
  groupByYear: PropTypes.bool,
};

export default ArticleCardList;
