import { Box, Avatar, VStack, Text, Badge, HStack } from '@chakra-ui/react';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';



const OrderHistoryRow = ({ row }) => {
  const getColorForEntity = entity => {
    switch (entity) {
      case 'line':
        return 'blue';

      case 'order':
        return 'purple';

      case 'document':
        return 'yellow';

      case 'coldstore_details':
        return 'green';

      default:
        return 'linkedin';
    }
  };

  return (
    <HStack spacing={4}>
      <Box>
        <Avatar name={row.edited_by} />
      </Box>
      <Box ml="6">
        <VStack spacing={2} align="stretch">
          <Text>
            <b>{row.edited_by}</b> edited the{' '}
            <b>
              {row.entity}:&nbsp;
              <Badge variant="outline" colorScheme={getColorForEntity(row.entity)}>
                &nbsp; {row.field} &nbsp;
              </Badge>{' '}
            </b>
            on:&nbsp; {moment(row.edit_date).format('YYYY-MM-DD hh:mm A')}
          </Text>

          <Text>
            Old Value: <Badge variant="outline">&nbsp;{row.edited_from}&nbsp;</Badge> -> New Value{' '}
            <Badge variant="outline">&nbsp;{row.edited_to}&nbsp;</Badge>
          </Text>
        </VStack>
      </Box>
    </HStack>
  );
};

OrderHistoryRow.propTypes = {
  row: PropTypes.shape({
    edit_date: PropTypes.string,
    edited_by: PropTypes.string,
    edited_from: PropTypes.string,
    edited_to: PropTypes.string,
    entity: PropTypes.string,
    field: PropTypes.string
  })
}

const OrderHistoryTable = ({ data }) => {
  const HistoryTableRows = [...data].map(rowData => <OrderHistoryRow key={rowData.id} row={rowData} />);
  return <>{HistoryTableRows}</>;
};


export default OrderHistoryTable;
