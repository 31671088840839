import Constants from '../Constants';
import { replaceOrder } from './actions_orders';
import { removeObjectFromArray, replaceObjectInArray } from '../helpers';

export const COLD_STORES_FETCHED = 'COLD_STORES_FETCHED';
export const COLD_STORE_ADDED = 'COLD_STORE_ADDED';
export const COLD_STORE_UPDATED = 'COLD_STORE_UPDATED';
export const COLD_STORE_REMOVED = 'COLD_STORE_REMOVED';
export const COLD_STORE_ERROR = 'COLD_STORE_ERROR';

const BASE_URL = `${Constants.URL}cold_stores`;

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadColdStores(coldStore) {
  return {
    type: COLD_STORES_FETCHED,
    payload: coldStore,
  };
}

export function addColdStore(coldStore) {
  return {
    type: COLD_STORE_ADDED,
    payload: coldStore,
  };
}

export function removeColdStore(coldStore) {
  return {
    type: COLD_STORE_REMOVED,
    payload: coldStore,
  };
}

export function updateColdStore(coldStore) {
  return {
    type: COLD_STORE_UPDATED,
    payload: coldStore,
  };
}

export function errorColdStores(error) {
  return {
    type: COLD_STORE_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchColdStores(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(BASE_URL, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorColdStores))
      .then(json => dispatch(loadColdStores(json)))
      .catch(error => dispatch(errorColdStores(error)));
  };
}

export function postColdStore(coldStore, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(BASE_URL, {
      method: 'POST',
      body: JSON.stringify(coldStore),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorColdStores))
      .then(json => dispatch(addColdStore(json)))
      .catch(error => dispatch(errorColdStores(error)));
  };
}

export function postColdStoreDetails(order, coldStoreDetails, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/details`, {
      method: 'POST',
      body: JSON.stringify(coldStoreDetails),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorColdStores))
      .then(json => {
        const orderCopy = JSON.parse(JSON.stringify(order));

        orderCopy.coldstore_details = json;
        dispatch(replaceOrder(orderCopy));
      })
      .catch(error => dispatch(errorColdStores(error)));
  };
}

export function postRejectedCarton(order, rejectedCarton, coldStoreId, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/details/${coldStoreId}/rejected`, {
      method: 'POST',
      body: JSON.stringify(rejectedCarton),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorColdStores))
      .then(json => {
        const orderCopy = JSON.parse(JSON.stringify(order));
        orderCopy.coldstore_details.rejected_cartons[rejectedCarton.index] = json;
        dispatch(replaceOrder(orderCopy));
      })
      .catch(error => dispatch(errorColdStores(error)));
  };
}

export function patchRejectedCarton(order, rejectedCarton, coldStoreId, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/details/${coldStoreId}/rejected/${rejectedCarton.id}`, {
      method: 'PATCH',
      body: JSON.stringify(rejectedCarton),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorColdStores))
      .then(json => {
        const orderCopy = JSON.parse(JSON.stringify(order));
        orderCopy.coldstore_details.rejected_cartons = replaceObjectInArray(
          order.coldstore_details.rejected_cartons,
          json
        );
        dispatch(replaceOrder(orderCopy));
      })
      .catch(error => dispatch(errorColdStores(error)));
  };
}

export function deleteRejectedCatron(order, coldStoreId, rejectedCarton, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/details/${coldStoreId}/rejected/${rejectedCarton.id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorColdStores))
      .then((/* json */) => {
        const orderCopy = JSON.parse(JSON.stringify(order));
        orderCopy.coldstore_details.rejected_cartons = removeObjectFromArray(
          orderCopy.coldstore_details.rejected_cartons,
          rejectedCarton
        );
        dispatch(replaceOrder(orderCopy));
      })
      .catch(error => dispatch(errorColdStores(error)));
  };
}

export function patchColdStore(coldStore, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${coldStore.id}`, {
      method: 'PATCH',
      body: JSON.stringify(coldStore),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorColdStores))
      .then(json => {
        const thisJson = JSON.parse(JSON.stringify(json));
        dispatch(updateColdStore(thisJson));
      })
      .catch(error => dispatch(errorColdStores(error)));
  };
}

export function deleteColdStore(coldStore, token) {
  return dispatch => {
    const copy = { ...coldStore };
    delete copy.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${copy.id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorColdStores))
      .then(() => dispatch(removeColdStore(copy)))
      .catch(error => dispatch(errorColdStores(error)));
  };
}
