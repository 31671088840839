import { ChevronDown, ChevronRight } from '../../../../shared/Icons';
import { Radio, Tooltip, Tree, Typography } from 'antd';
import * as React from 'react';
import { endOfMonth, startOfMonth, subMonths } from 'date-fns';
import ThisPeriod from '../NCRs/ThisPeriod';
import { formatDollars, formatMarketQuantity, formatSI } from '../../../../modules/format';
import styles from './Claims.module.css';
import { descending } from 'd3';
import Icon from '@ant-design/icons/lib/components/Icon';
import {
  AMOUNT_OPTIONS,
  GROUP_BY_OPTIONS,
  STATUS_OPTIONS,
  TIME_RANGE_OPTIONS,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsConfig';
import {
  setQualityClaimsAmount,
  setQualityClaimsCount,
  setQualityClaimsGroupBy,
  setQualityClaimsRegion,
  setQualityClaimsSelectedPackerPlantId,
  setQualityClaimsShouldBeGroupedByPacker,
  setQualityClaimsStatus,
  setQualityClaimsTimeRange,
  updateQualityClaimsTimePeriod,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsReducers';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { INCIDENTS_RATE_UOM } from '../../../../slices/quality-details-claims/qualityDetailsClaimsConfig';

const PackerPlantsWithMostClaims = props => {
  const { data, categoryColors, market, singlePackerPlant, selectedRegion, timeScale, timePeriod } = props;
  const [dataType, setDataType] = React.useState('count');
  const { market: marketParam } = useParams();

  const formatBasedOnDataType = React.useMemo(() => {
    if (dataType === 'count') {
      return val => val;
    } else if (dataType === 'incidentRate') {
      return formatSI(4);
    } else {
      return formatDollars(0);
    }
  }, [dataType]);

  const maxCount = Math.max(...data.map(x => x[dataType]));
  const packerPlants = data.slice();
  const dataSorted = packerPlants.sort((a, b) => b.count - a.count);
  const bottom5PackerPlants = dataSorted.slice(0, 5);
  const TOLERANCE = 0.01;

  const dispatch = useDispatch();
  const handleNavigationToDetails = React.useCallback(() => {
    if (singlePackerPlant) {
      dispatch(setQualityClaimsSelectedPackerPlantId(singlePackerPlant.id));
      dispatch(setQualityClaimsRegion(singlePackerPlant.country));
      if (singlePackerPlant.isPacker) {
        dispatch(setQualityClaimsShouldBeGroupedByPacker(true));
      }
    } else {
      dispatch(setQualityClaimsRegion(selectedRegion));
    }
    dispatch(setQualityClaimsStatus(STATUS_OPTIONS.SUBMITTED));
    dispatch(setQualityClaimsCount(null));
    dispatch(setQualityClaimsAmount(AMOUNT_OPTIONS.TOTAL));
    const now = new Date();
    dispatch(updateQualityClaimsTimePeriod([startOfMonth(subMonths(now, 12)), endOfMonth(now)]));
    dispatch(setQualityClaimsTimeRange(TIME_RANGE_OPTIONS.HISTORICAL));
    dispatch(setQualityClaimsGroupBy(GROUP_BY_OPTIONS.PACKER_PLANT));
  }, [dispatch, selectedRegion, singlePackerPlant]);

  const getTreeData = data => {
    if (!data) {
      return [];
    }
    const row = (d, isSecondary, color) => {
      return (
        <div
          className={`${styles.QualityClaims_tableRow} ${isSecondary ? styles.QualityClaims_tableRowSecondary : ''}`}
          style={{ marginLeft: isSecondary ? -24 : 0 }}
        >
          <div
            style={{
              width: '35%',
              padding: '0 5px',
              paddingLeft: isSecondary ? 24 : 0,
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {isSecondary ? d.category : d.packerPlantName}
            </div>
          </div>
          <div
            className={styles.QualityClaims_tableRowValues}
            style={{
              width: '15%',
              padding: '0 5px',
              fontWeight: !isSecondary && 'bold',
            }}
          >
            {formatBasedOnDataType(d[dataType])}
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 5px',
            }}
          >
            {isSecondary ? (
              <div
                style={{
                  width: `calc(${
                    !d[dataType] || d[dataType] === 0 || Math.abs(d[dataType] - 0) < TOLERANCE
                      ? 0
                      : (d[dataType] * 100) / maxCount
                  }% - 2px)`,
                  height: 7,
                  backgroundColor: color,
                }}
              />
            ) : (
              <div
                style={{
                  display: 'flex',
                  width: `${
                    !d[dataType] || d[dataType] === 0 || Math.abs(d[dataType] - 0) < TOLERANCE
                      ? 0
                      : (d[dataType] * 100) / maxCount
                  }%`,
                }}
              >
                {d.byCategory.map(c => {
                  let title = '';
                  if (dataType === 'incidentRate') {
                    title = `${c.category}
                    ${formatBasedOnDataType(c[dataType])} ${INCIDENTS_RATE_UOM[market.key].INCIDENTS_RATE}`;
                    // ${formatMarketQuantity(market)(c.volume)} impacted volume
                  } else {
                    title = `${c.category} - ${formatBasedOnDataType(c[dataType])}`;
                  }
                  return (
                    <Tooltip key={`bar-${c.category}`} overlayStyle={{ whiteSpace: 'pre-line' }} title={title}>
                      <div
                        style={{
                          width: `${
                            !c[dataType] || c[dataType] === 0 || Math.abs(c[dataType] - 0) < TOLERANCE
                              ? 0
                              : (c[dataType] * 100) / d[dataType]
                          }%`,
                          height: 10,
                          backgroundColor: categoryColors[c.category],
                          marginRight: 2,
                        }}
                      />
                    </Tooltip>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      );
    };

    return bottom5PackerPlants
      .slice()
      .sort((a, b) => descending(a.count, b.count))
      .map((packerPlant, i) => {
        return {
          title: row(packerPlant, false, 'grey'),
          key: i + '-' + packerPlant.packerPlantId,
          children: packerPlant.byCategory.map((category, j) => {
            return {
              title: row(category, true, categoryColors[category.category]),
              key: i + '-' + j + '-' + category.category,
            };
          }),
        };
      });
  };

  return (
    <div>
      <div className={styles.QualityClaims_packerPlantsTitle}>
        <Typography.Text className={styles.QualityClaims_label}>
          {singlePackerPlant && singlePackerPlant.isPacker
            ? 'bottom 5 establishments'
            : 'establishments with the most claims'}
        </Typography.Text>
        <Radio.Group
          onChange={e => {
            setDataType(e.target.value);
          }}
          defaultValue={dataType}
          size="medium"
          className={styles.QualityClaims_radio}
        >
          <Radio.Button key={1} value={'count'}>
            Count
          </Radio.Button>
          <Radio.Button key={2} value={'incidentRate'}>
            Incident Rate
          </Radio.Button>
          <Radio.Button key={3} value={'claimAmount'}>
            Claim amount
          </Radio.Button>
        </Radio.Group>
      </div>
      <Tree
        className={styles.QualityClaims_tree}
        switcherIcon={<Icon component={ChevronDown} style={{ opacity: 0.6 }} />}
        selectable={false}
        treeData={getTreeData(data)}
        blockNode={true}
      />
      {data.length > 0 ? (
        <Link
          to={`/analytic-dashboard/quality/${marketParam}/claims-details`}
          className={styles.QualityClaims_viewAll}
          onClick={() => handleNavigationToDetails()}
        >
          View all {singlePackerPlant && singlePackerPlant.isPacker ? `${singlePackerPlant.name} ` : ''}
          establishments
          <Icon style={{ position: 'relative', width: 14, top: 1 }} component={ChevronRight} />
        </Link>
      ) : (
        <p>
          No incidents reported yet in <ThisPeriod timeScale={timeScale} timePeriod={timePeriod} />
        </p>
      )}
    </div>
  );
};

export default PackerPlantsWithMostClaims;
