import React from 'react';
import Toggle from 'react-bootstrap-toggle';
import { Box, Button, Center, Flex, FormLabel, Text, useTheme } from '@chakra-ui/react';
import { ReactComponent as SnowflakeIconSVG } from '../../../assets/snowflake.svg';
import { ReactComponent as SteakIconSVG } from '../../../assets/steak.svg';
import PropTypes from 'prop-types';

import './Toggle.scss';
import { invertColor } from '../../../theme/utils';
import { get } from 'lodash';

const CFToggle = ({
  className = '',
  onText = '',
  offText = '',
  isDisabled = false,
  onClick = () => '',
  isActive = true,
} = {}) => {
  return (
    <Toggle
      className={`cf-toggle ${className}`}
      disabled={isDisabled}
      onClick={onClick}
      on={onText}
      off={offText}
      onClassName="cf-toggle__slider cf-toggle__slider--on"
      offClassName="cf-toggle__slider cf-toggle__slider--off"
      active={isActive}
    />
  );
};

CFToggle.propTypes = {
  className: PropTypes.string,
  onText: PropTypes.string,
  offText: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

export const CFSwitch = ({
  onText = '',
  offText = '',
  isDisabled = false,
  onClick = () => '',
  isActive = true,
  width = 123,
  height = 32,
  padding = 3,
}) => {
  const theme = useTheme();

  const widthValue = `${width}px`;
  const heightValue = `${height}px`;
  const paddingValue = `${padding}px`;
  const offsetValue = width - 13 - padding * 2;
  return (
    <FormLabel
      className="cf__switch"
      htmlFor="cf-switcher"
      as="label"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      width={widthValue}
    >
      <Button
        onClick={() => onClick(!isActive)}
        isDisabled={isDisabled}
        width={widthValue}
        minWidth={widthValue}
        height={heightValue}
        borderRadius="10px"
        padding={0}
        position="absolute"
        top="0"
        left="0"
      />
      <Box
        id="cf-switcher"
        cursor="pointer"
        width={widthValue}
        height={heightValue}
        borderRadius="10px"
        backgroundColor="actionPrimary.default"
        variant="solid"
        padding={paddingValue}
        position="absolute"
        top="0"
        left="0"
        justifyContent="flex-start"
        pointerEvents="none"
        overflow="hidden"
      >
        <Flex
          transition="all 0.2s ease-in"
          transform={`${isActive ? `translateX(-${offsetValue}px)` : 'translateX(0)'}`}
          color="inherit"
          width="fit-content"
          height="100%"
          backgroundColor="inherit"
        >
          <Center color={invertColor(get(theme, 'colors.actionPrimary.default', ''), true)} width={`${offsetValue}px`}>
            {offText}
          </Center>
          <Center
            width="13px"
            height="100%"
            backgroundColor={invertColor(get(theme, 'colors.actionPrimary.default', ''), true)}
            borderRadius="8px"
          />
          <Center color={invertColor(get(theme, 'colors.actionPrimary.default', ''), true)} width={`${offsetValue}px`}>
            {onText}
          </Center>
        </Flex>
      </Box>
    </FormLabel>
  );
};

CFSwitch.propTypes = {
  onText: PropTypes.string,
  offText: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  padding: PropTypes.number,
};

export const ProductTypeToggle = ({ isDisabled, onClick, isActive }) => {
  const theme = useTheme();
  return (
    <FormLabel
      className="product-type__switch"
      htmlFor="product-type-switcher"
      as="label"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Button
        id="product-type-switcher"
        type="checkbox"
        onClick={onClick}
        cursor="pointer"
        w="167px"
        height="32px !important"
        backgroundColor="#0B1435"
        borderRadius="10px"
        colorScheme="actionPrimary"
        variant="solid"
        padding="6px 3px"
        position="relative"
        justifyContent="flex-start"
        isDisabled={isDisabled}
      >
        <Text
          left="14px"
          transition="all 0.2s ease-in"
          transform={`${isActive ? 'translateX(83px)' : 'translateX(0)'}`}
          position="absolute"
        >
          {isActive ? 'Frozen' : 'Chilled'} {isActive ? <SteakIconSVG /> : <SnowflakeIconSVG />}
        </Text>
      </Button>
      <Button
        className={`iconMove `}
        transition="all 0.2s ease-in"
        transform={`${isActive ? 'translateX(0)' : 'translateX(78px)'}`}
        position="absolute"
        cursor="pointer"
        top="3px"
        left="3px"
        w="83px"
        h="26px"
        colorScheme="actionPrimary"
        variant="outline"
        backgroundColor={invertColor(get(theme, 'colors.actionPrimary.500', ''), true)}
        borderRadius="8px"
        pointerEvents="none"
        tabindex="-1"
        rightIcon={isActive ? <SnowflakeIconSVG /> : <SteakIconSVG />}
      >
        <Text>{!isActive ? 'Frozen' : 'Chilled'}</Text>
      </Button>
    </FormLabel>
  );
};

ProductTypeToggle.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

export default CFToggle;
