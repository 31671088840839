import React, { useState } from 'react';
import PropTypes from 'prop-types';

import VoyageDetailsItem from '../../../../containers/orders/VoyageDetailsItem';
import './VoyageDetails.scss';
import { Box, Button, FormLabel, IconButton, Input, Text, VStack, Wrap } from '@chakra-ui/react';
import CustomFormControl from '../../../core/CustomFormControl';
import Tooltip from '../../../quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const propTypes = {
  deleteVoyage: PropTypes.func,
  editMode: PropTypes.bool,
  exportDetails: PropTypes.shape({
    healthCertificateNumber: PropTypes.string,
    isf: PropTypes.string,
    permitNumber: PropTypes.string,
  }),
  finalDestination: PropTypes.string,
  isISFRequired: PropTypes.bool,
  isUsCustomsNumberCfc: PropTypes.string,
  isPermitNumberRequired: PropTypes.bool,
  onChangeValue: PropTypes.func,
  onObjectValueChange: PropTypes.func,
  onVoyageValueChange: PropTypes.func,
  ports: PropTypes.array,
  shippingLines: PropTypes.array,
  validationErrors: PropTypes.instanceOf(Object),
  voyage: PropTypes.instanceOf(Object),
  documents: PropTypes.arrayOf(PropTypes.object),
};

const VoyageDetails = ({
  deleteVoyage,
  editMode,
  exportDetails,
  finalDestination,
  isISFRequired,
  isUsCustomsNumberCfc,
  isPermitNumberRequired,
  onChangeValue,
  onObjectValueChange,
  onVoyageValueChange,
  ports,
  shippingLines,
  validationErrors,
  voyage,
  documents,
}) => {
  const [state, setState] = useState({
    areAllExpanded: false,
  });
  const shippingLineOptions = shippingLines.map(s => ({ label: s.name, value: s.id }));
  const portsOptions = ports.map(p => ({ label: p.name, value: p.id }));

  /* checks if voyage has at least one key with value
       if none of the voyage keys have values then it is considered to be empty 
    */
  const isVoyageEmpty = !Object.values(voyage).some(v => v !== null && v !== '');
  const isVoyageRemoved = editMode ? isVoyageEmpty : false;

  return (
    <VStack spacing="24px" align="stretch">
      <Wrap spacing="56px">
        <CustomFormControl width="305px">
          <FormLabel htmlFor="final-destination">
            Final Destination&nbsp;
            <Tooltip content="Specifies the final delivery location for the shipment." placement="right">
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
          </FormLabel>
          <Input
            backgroundColor="white"
            name="finalDestination"
            value={finalDestination || ''}
            isDisabled={!editMode}
            className="form-control"
            onChange={e => onChangeValue('finalDestination', e.target.value)}
          />
        </CustomFormControl>
        <CustomFormControl width="305px">
          <FormLabel htmlFor="health-certificate-number">
            Health Certificate Number&nbsp;
            <Tooltip content="Provides the official health certificate number." placement="right">
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
          </FormLabel>
          <Input
            backgroundColor="white"
            name="healthCertificateNumber"
            value={exportDetails?.healthCertificateNumber || ''}
            isDisabled={!editMode}
            className="form-control"
            onChange={e => onObjectValueChange('exportDetails', 'healthCertificateNumber', e.target.value || '')}
          />
        </CustomFormControl>
        {isUsCustomsNumberCfc && (
          <CustomFormControl width="305px">
            <FormLabel htmlFor="us-customs-entry-number">
              US Customs Entry Number&nbsp;
              <Tooltip content="Provides the official health certificate number." placement="right">
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </FormLabel>
            <Input
              backgroundColor="white"
              name="usCustomsEntryNumber"
              value={exportDetails?.usCustomsEntryNumber || ''}
              isDisabled={!editMode}
              className="form-control"
              onChange={e => onObjectValueChange('exportDetails', 'usCustomsEntryNumber', e.target.value || '')}
            />
          </CustomFormControl>
        )}
        {isISFRequired && (
          <CustomFormControl width="305px">
            <FormLabel htmlFor="isf">
              ISF&nbsp;
              <Tooltip content="Provides the Importer Security Filing (ISF) information." placement="right">
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </FormLabel>
            <Input
              backgroundColor="white"
              name="isf"
              value={exportDetails?.isf || ''}
              isDisabled={!editMode}
              className="form-control"
              onChange={e => onObjectValueChange('exportDetails', 'isf', e.target.value || '')}
            />
          </CustomFormControl>
        )}
        {isPermitNumberRequired && (
          <CustomFormControl width="305px">
            <FormLabel htmlFor="permit-number">Permit Number</FormLabel>
            <Input
              name="permitNumber"
              value={exportDetails?.permitNumber || ''}
              isDisabled={!editMode}
              backgroundColor="white"
              className="form-control"
              onChange={e => onObjectValueChange('exportDetails', 'permitNumber', e.target.value || '')}
            />
          </CustomFormControl>
        )}
      </Wrap>
      <Box>
        <VStack spacing="11px" align="stretch">
          <VoyageDetailsItem
            editMode={editMode}
            voyage={voyage}
            onVoyageValueChange={onVoyageValueChange}
            shippingLineOptions={shippingLineOptions}
            portsOptions={portsOptions}
            ports={ports}
            validationErrors={validationErrors}
            onExpansionChange={value => setState({ ...state, areAllExpanded: value })}
            documents={documents}
          />
        </VStack>
        {state.areAllExpanded && (
          <Button
            onClick={deleteVoyage}
            hidden={!editMode || isVoyageRemoved}
            colorScheme="actionPrimary"
            isDisabled={!editMode}
            float="right"
          >
            <Text as="p">Delete Voyage</Text>
          </Button>
        )}
      </Box>
    </VStack>
  );
};

VoyageDetails.propTypes = propTypes;

export default VoyageDetails;
