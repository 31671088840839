// Plese refer to the theming schema here:
// https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/components/button.ts

const Button = {
  // 1. We can update the base styles
  baseStyle: {
    fontWeight: 500,
    fontFamily: "'Roboto', Arial, 'sans-serif'",
  },
  // 2. We can add a new button size or extend existing
  sizes: {
    lg: {
      fontSize: 'lg',
      px: '18px',
      py: '14px',
    },
    md: {
      fontSize: 'md',
      px: '16px',
      py: '12px',
    },
    sm: {
      fontSize: 'sm',
      px: '44px',
      py: '8px',
    },
  },
  // 3. We can add new variants or overwrite existing
  variants: {
    filled: props => {
      const { colorScheme: c } = props;
      return {
        bg: `${c}.default`,
        color: 'white',
        _hover: {
          bg: `${c}.hover`,
          _disabled: {
            bg: `${c}.disabled`,
          },
        },
        _active: { bg: `${c}.active` },
        _disabled: {
          bg: `${c}.disabled`,
        },
      };
    },
    outline: props => {
      const { colorScheme: c } = props;
      return {
        border: '1px solid',
        borderColor: `${c}.default`,
        color: `${c}.default`,
        bg: 'transparent',
        _hover: {
          borderColor: `${c}.hover`,
          color: `${c}.hover`,
        },
        _active: {
          borderColor: `${c}.active`,
          color: `${c}.active`,
        },
        _disabled: {
          borderColor: `${c}.disabled`,
          color: `${c}.disabled`,
        },
      };
    },
    ghost: props => {
      const { colorScheme: c } = props;
      return {
        border: '1px solid',
        borderColor: `transparent`,
        color: `${c}.default`,
        bg: 'transparent',
        _hover: {
          color: `${c}.hover`,
        },
        _active: {
          color: `${c}.active`,
        },
        _disabled: {
          color: `${c}.disabled`,
        },
      };
    },
  },
};

export default Button;
