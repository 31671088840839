/**
 * Market focus is used in markets that have exports.
 * This has no effect for no-export countries, and is short-circuited to domestic.
 */
export const MARKET_FOCUS = {
  DOMESTIC: 'domestic',
  EXPORT: 'export',
};

/**
 * Which protein to filter data on.
 */
export const PROTEIN = {
  BEEF: 'beef',
  CHICKEN_PORK: 'chicken_pork',
};

/**
 * Which date to use for filtering orders by date.
 */
export const DATE_MODE = {
  /**
   * The date the product was delivered.
   */
  DELIVERY: 'delivery_date',
  /**
   * The date the order was placed.
   */
  ORDER: 'order_date',
};

export const SUPPLY_CHAIN_VIEW_MODE = {
  GRINDERS: 'grinders',
  PACKER_PLANTS: 'packer plants',
};

export const LOCATION_TYPE = {
  GRINDER: 'grinder',
  // Temporary workaround to get the markets dashboard working again w/o an API deploy
  // The full fix should include:
  //   - Updating the geojson locations API to change the `type` returned from "packer" to "packer plant"
  //   - Updating the next line to be PACKER_PLANT: 'packer plant'
  //   - Updating any references to LOCATION_TYPE.PACKER_PLANT to reflect the previous change
  PACKER_PLANT: 'packer plant',
};

export const FRESH_FROZEN = {
  FRESH: 'FRESH',
  FROZEN: 'FROZEN',
};

export const ANGUS = {
  ANGUS: 'ANGUS',
  NOT_ANGUS: 'NOT_ANGUS',
};

export const ORDER_PRICE_TYPE = {
  SPOT: 'spot',
  RISK_MANAGED: 'risk-managed',
  FORMULA: 'formula',
  NOF: 'NOF',
  CONTRACT: 'contract',
};

export const CURRENCY = {
  USD: 'USD',
  AUD: 'AUD',
};
