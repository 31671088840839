import React from 'react';
import { Radio, Typography } from 'antd';
import { FREQUENCY } from '../../../../../slices/quality/qualityConfig';
import styles from '../Filter.module.css';

export default function FilterFrequency({ value, onChange }) {
  return (
    <div>
      <Typography.Text className={styles.filterLabel}>Frequency</Typography.Text>
      <Radio.Group
        onChange={e => onChange(e.target.value)}
        defaultValue={value}
        className={styles.radioGroup}
        style={{ marginTop: 5 }}
      >
        <Radio.Button key={FREQUENCY.MONTHLY} value={FREQUENCY.MONTHLY}>
          M
        </Radio.Button>
        <Radio.Button key={FREQUENCY.ANNUALLY} value={FREQUENCY.ANNUALLY}>
          Y
        </Radio.Button>
      </Radio.Group>
    </div>
  );
}
