import { connect } from 'react-redux';

import QuantityFieldsComponent from '../../components/orders/order-form/QuantityFields/QuantityFields';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

const mapStateToProps = state => {
  return findEntitiesInState(state, {
    units_of_measure: 'unit',
  });
};

const QuantityFields = connect(mapStateToProps)(QuantityFieldsComponent);

export default QuantityFields;
