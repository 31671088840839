const schema = [
  {
    name: 'fdr_freight_rate',
    displayName: 'FDR Freight Rate',
    systemIdField: 'id',
    displayIdField: 'fdr_item_id',
    path: '/fdr_freight_rates',
    tabGroup: { key: 'pricing', label: 'Pricing' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'fdr_item_id',
        displayName: 'FDR Item',
        tooltip: 'Name or identifier of the item for which the freight rate is being set',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'fdr_item',
        required: true,
      },
      {
        name: 'port_of_loading_id',
        displayName: 'Port Of Loading',
        tooltip: 'Port where the FDR item will be shipped from',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'port',
        required: true,
      },
      {
        name: 'port_of_destination_id',
        displayName: 'Port Of Destination',
        tooltip: 'Port where the FDR item will be delivered',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'port',
        required: true,
      },
      {
        name: 'unit_of_measure_id',
        displayName: 'Unit Of Measure',
        tooltip: 'Measurement unit used for calculating the freight rate (e.g., BIN, CTN, KGS, LBS).',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'unit',
        required: true,
      },
      {
        name: 'currency',
        displayName: 'Currency',
        tooltip: 'Currency in which the freight rate is quoted',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'currency',
        required: true,
      },
      {
        name: 'effective_from',
        displayName: 'Effective From',
        tooltip: 'Date when the freight rate becomes applicable',
        dataType: 'date',
        required: true,
      },
      {
        name: 'rate',
        displayName: 'Rate',
        tooltip: 'Freight rate applied to the FDR item',
        dataType: 'number',
        required: true,
      },
      {
        name: 'buy_incoterms',
        displayName: 'Buy Incoterms',
        tooltip: 'International commercial terms specifying the conditions of the sale for the FDR Freight Rate',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'incoterms',
      },
    ],
  },
];
export default schema;
