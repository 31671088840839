import React, { Component } from 'react';

class SCADashboard extends Component {
  render() {
    return (
      <div className="m-portlet__body">
        <div className="row">
          <div className="col-sm-12" style={{ marginTop: '30px' }}>
            <p>
              <strong style={{ fontSize: '20px' }}>SCA Dashboard.</strong>
            </p>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

export default SCADashboard;
