import Constants from '../Constants';

export const VOYAGE_BASE_URL = `${Constants.URL}voyage`;

export const VOYAGE_FETCHED = 'VOYAGE_FETCHED';
export const VOYAGE_DELETED = 'VOYAGE_DELETED';
export const VOYAGE_ERROR = 'VOYAGE_ERROR';
export const VOYAGE_ADDED_OR_UPDATED = 'VOYAGE_ADDED_OR_UPDATED';

export function fetchVoyage(purchase_order_id, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${VOYAGE_BASE_URL}/${purchase_order_id}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorVoyage))
      .then(json => dispatch(loadVoyage(json)))
      .catch(error => dispatch(errorVoyage(error)));
  };
}

export function addOrUpdateVoyage(purchase_order_id, voyage, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${VOYAGE_BASE_URL}/${purchase_order_id}`, {
      method: 'POST',
      body: JSON.stringify(voyage),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorVoyage))
      .then(json => dispatch(replaceVoyage(json)))
      .catch(error => dispatch(errorVoyage(error)));
  };
}

export function deleteVoyage(purchase_order_id, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${VOYAGE_BASE_URL}/${purchase_order_id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorVoyage))
      .then(json => dispatch(deletedVoyage(json)))
      .catch(error => dispatch(errorVoyage(error)));
  };
}

export function loadVoyage(voyage) {
  return {
    type: VOYAGE_FETCHED,
    payload: voyage,
  };
}

export function replaceVoyage(voyage) {
  return {
    type: VOYAGE_ADDED_OR_UPDATED,
    payload: voyage,
  };
}

export function deletedVoyage(voyage) {
  return {
    type: VOYAGE_DELETED,
    payload: voyage,
  };
}

export function errorVoyage(error) {
  return {
    type: VOYAGE_ERROR,
    payload: error,
  };
}
