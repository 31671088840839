import { uniq } from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import moment from 'moment';
import Constants from '../../Constants';
import { checkForAsianGrinder } from '../../helpers';
import { printToday } from '../../helpers/dates';
import { isInvoiceColdstoreValidationApplied } from '../../utils';
import { convertCartonsToUnitOfMeasure } from '../orders/order-form/InternationalOrderLine/helpers';

const {
  COUNTRIES,
  INVOICE_TEMPLATE_TYPES,
  INVOICE_STATUSES,
  INVOICE_TYPES,
  INVOICE_FLOWS,
  FINANCE_TABS: TABS,
  FINANCE_CARTS: CARTS,
  PRICE_STATUSES,
  WEIGHT_TYPES,
} = Constants;

export function sumOrderByWeightType(order, weightType, packageWeights = null) {
  let weight = 0;
  const lines = get(order, 'lines') || [];
  switch (weightType) {
    case 'shipped_weight':
      weight = lines.reduce((sum, line) => {
        const packerUid = get(line, 'packer_plant.id');
        const inputProduct = get(line, 'input_product');
        const cartonCount = get(line, 'carton_count', 0);
        const lineWeight = convertCartonsToUnitOfMeasure(
          cartonCount,
          get(order, 'sell_unit_of_measure.name'),
          inputProduct,
          packerUid,
          order.purchasing_office,
          packageWeights
        );
        return sum + parseFloat(lineWeight);
      }, 0);
      break;
    case 'received_weight':
      weight = lines.reduce((sum, line) => {
        const lineWeight = get(line, 'recv_quantity', 0);
        return sum + parseFloat(lineWeight);
      }, 0);
      break;
    case 'amended_weight':
      weight = get(order, 'amended_weight', 0);
      break;
    case 'remaining_weight':
      weight = lines.reduce((sum, line) => {
        const packerUid = get(line, 'packer_plant.id');
        const inputProduct = get(line, 'input_product');
        const cartonCount = get(line, 'remaining_cartons', 0);
        const lineWeight = convertCartonsToUnitOfMeasure(
          cartonCount,
          get(order, 'sell_unit_of_measure.name'),
          inputProduct,
          packerUid,
          order.purchasingOffice,
          packageWeights
        );
        return sum + parseFloat(lineWeight);
      }, 0);
      break;
    case 'po_weight':
    default:
      weight = lines.reduce((sum, line) => {
        const lineWeight = get(line, 'sell_quantity', 0);
        const lineActive = get(line, 'active', false);
        return lineActive ? sum + parseFloat(lineWeight) : sum;
      }, 0);
  }

  return weight;
}

export function selectRows(newSelections, oldSelections) {
  const selectionsList = [...newSelections];
  const updatedSelections = selectionsList.reduce((agg, rowId) => {
    return {
      ...agg,
      [rowId]: rowId,
    };
  }, oldSelections);
  return updatedSelections;
}

export function deselectRows(newSelections, oldSelections) {
  const updatedSelections = newSelections.reduce((agg, selection) => {
    const { row } = selection;
    const shallowCopy = { ...agg };
    delete shallowCopy[row.id];
    return shallowCopy;
  }, oldSelections);

  return updatedSelections;
}
export function calculateInvoiceDate(order) {
  const invoiceSendDays = get(order, 'grinder.invoice_send_days', 0);
  const grinderInvoiceDateType = get(order, 'grinder.invoice_date_type', false);
  let invoiceDateBaseString = '';
  if (grinderInvoiceDateType) {
    if (grinderInvoiceDateType === 'etd' || grinderInvoiceDateType === 'eta') {
        invoiceDateBaseString = get(order, `voyage[0].${grinderInvoiceDateType}`);
    }
    else if (grinderInvoiceDateType === 'today') {
      invoiceDateBaseString = printToday();
    } else {
      invoiceDateBaseString = get(order, grinderInvoiceDateType, false);
      if (!invoiceDateBaseString) {
        return '';
      }
    }
    const startDate = moment.utc(invoiceDateBaseString, 'YYYY-MM-DD');
    const invoiceDate = startDate.add(parseInt(invoiceSendDays, 10), 'days');
    return moment.isMoment(invoiceDate) ? invoiceDate.format('YYYY-MM-DD') : invoiceDate;
  }
  return order.recv_delivery_date || order.delivery_date || printToday();
}
export function calculateInvoiceDueDate(invoiceDateType, order) {
  // Determine invoice_due_date
  const invoiceDueDays = get(order, 'grinder.invoice_payment_due_days', 0);
  const grinderInvoiceDateType = get(order, 'grinder.invoice_due_date_type', false);
  let startDateString = '';
  if (!grinderInvoiceDateType || grinderInvoiceDateType === 'etd' || grinderInvoiceDateType === 'eta') {
    switch (invoiceDateType) {
      case 'etd':
      case 'eta':
        startDateString = get(order, `voyage[0].${invoiceDateType}`);
        break;
      default:
        startDateString = get(order, invoiceDateType);
    }
  } else if (grinderInvoiceDateType === 'today') {
    startDateString = printToday();
  } else if (grinderInvoiceDateType === 'invoice_date') {
    startDateString = calculateInvoiceDate(order);
  } else {
    startDateString = get(order, grinderInvoiceDateType);
  }
  if (!startDateString) return '';
  const startDate = moment.utc(startDateString, 'YYYY-MM-DD');
  const invoiceDueDate = startDate.add(parseInt(invoiceDueDays, 10), 'days');
  const value = moment.isMoment(invoiceDueDate) ? invoiceDueDate.format('YYYY-MM-DD') : invoiceDueDate;
  return value;
}

export function determineInvoicingTemplate(grinder) {
  const country = get(grinder, 'destination_country');
  const isAsianGrinder = checkForAsianGrinder(country);

  const template = isAsianGrinder ? INVOICE_TEMPLATE_TYPES.TAX_ID : INVOICE_TEMPLATE_TYPES.DEFAULT;
  return template;
}

export function determineTenorStartDate(priceBucket, purchaseOrder) {
  let tenorStartDate = purchaseOrder.packer_paid_date;
  if (priceBucket.is_active) {
    if (priceBucket.lock_date) {
      tenorStartDate = priceBucket.tenor_start_date;
    }
  }
  return tenorStartDate;
}

export function determineTenorEndDate(priceBucket, purchaseOrder, dateType) {
  let invoicePaymentDueDate = purchaseOrder.invoice_payment_due_date;
  if (priceBucket && priceBucket.is_active) {
    if (priceBucket.lock_date) {
      invoicePaymentDueDate = priceBucket.tenor_end_date;
    }
  }
  if (dateType && !invoicePaymentDueDate) {
    invoicePaymentDueDate = calculateInvoiceDueDate(dateType, purchaseOrder);
  }
  return invoicePaymentDueDate;
}

export function determineWeightType(purchaseOrder, priceBucket) {
  let weightType = get(purchaseOrder, 'grinder.invoice_weight_type');

  if (priceBucket && priceBucket.lock_date) {
    weightType = priceBucket.weight_type;
  }

  if (purchaseOrder.invoice_weight_type) {
    weightType = get(purchaseOrder, 'invoice_weight_type');
  }
  return weightType;
}

export function formatWeightType(weightType) {
  const label = WEIGHT_TYPES[weightType];
  if (!label) return startCase(weightType);
  return label;
}

export function sumOrdersByWeightType(orders, packageWeights) {
  const sum = orders.reduce((agg, order) => {
    // const weightType = order.invoice_weight_type || get(order, 'grinder.invoice_weight_type', 0);
    const priceBucket = get(order, 'priceBucket');
    const invoiceWeightType = determineWeightType(order, priceBucket);
    const weight = sumOrderByWeightType(order, invoiceWeightType, packageWeights);
    return agg + parseFloat(weight);
  }, 0);
  return sum;
}

export function preparePurchaseOrderForInvoice(po, dateType) {
  const today = printToday();
  const invoiceFlow = get(po, 'grinder.invoice_flow');

  let type = '';
  switch (invoiceFlow) {
    case INVOICE_FLOWS.PRIOR_FINAL:
      type = po.grinder_invoice_status === INVOICE_STATUSES.INVOICED_PRIOR ? INVOICE_TYPES.FINAL : INVOICE_TYPES.PRIOR;
      break;
    case INVOICE_FLOWS.REGULAR:
    default:
      type = INVOICE_TYPES.REGULAR;
  }

  const priceBucket = get(po, 'priceBucket', {}) || {};
  const template = determineInvoicingTemplate(po.grinder, po.order_date);
  const invoiceWeightType = determineWeightType(po, priceBucket);
  const invoiceDate = calculateInvoiceDate(po);

  const tenorStartDate = determineTenorStartDate(priceBucket, po);
  const tenorEndDate = determineTenorEndDate(priceBucket, po, dateType);
  return {
    purchase_order_id: po.id,
    invoice_weight_type: invoiceWeightType,
    invoice_payment_due_date: po.invoice_payment_due_date || tenorEndDate,
    invoice_date: invoiceDate,
    packer_invoice_due_date: po.packer_paid_date || tenorStartDate,
    invoice_type: type,
    template,
  };
}

export function preparePurchaseOrderForPricing(po, dateType) {
  const today = printToday();

  const priceBucket = get(po, 'priceBucket', {}) || {};
  const tenorStartDate = determineTenorStartDate(priceBucket, po);
  const tenorEndDate = determineTenorEndDate(priceBucket, po, dateType);

  const invoiceWeightType = determineWeightType(po, priceBucket);

  return {
    purchase_order_id: po.id,
    grinder_invoice_due_date: po.invoice_payment_due_date || tenorEndDate,
    grinder_invoice_date: calculateInvoiceDate(po),
    packer_invoice_due_date: po.packer_paid_date || tenorStartDate,
    invoice_weight_type: invoiceWeightType,
  };
}

export function validateRows(rows) {
  const invalidRows = rows.filter(order => {
    if (get(order, 'grinder.is_unfunded')) {
      return false;
    }
    if (
      [COUNTRIES.AUSTRALIA, COUNTRIES.NEW_ZEALAND].includes(get(order, 'grinder.destination_country', '').toLowerCase())
    ) {
      return false;
    }
    return order.packer_paid_date === '' || order.packer_paid_date === null;
  });

  return isEmpty(invalidRows);
}

export function getSelectionsKeyByTab(tabName) {
  let selectionsKey;
  switch (tabName) {
    case TABS.results:
      selectionsKey = 'resultOrderSelections';
      break;
    case TABS.cart:
    default:
      selectionsKey = 'cartSelections';
  }

  return selectionsKey;
}

export function addSelectionsToCart({ orders, selections, cartContents, dateType }) {
  // New selections are the ones not already present in the selections object
  const newSelections = orders.reduce((agg, order) => (selections[order.id] ? [...agg, order] : agg), []);
  // Add new selections to cart, but filter out any duplicates:
  const cartOrderListIds = cartContents.map(order => order.id);
  const filteredSelections = newSelections.filter(selection => !cartOrderListIds.includes(selection.id));

  const updatedCartList = [...cartContents, ...filteredSelections].map(order => ({
    ...order,
    invoice_payment_due_date: order.invoice_payment_due_date || calculateInvoiceDueDate(dateType, order),
  }));

  return updatedCartList;
}

export function determineSubmitButtonText(selectedTab, selectedCart) {
  switch (selectedTab) {
    case TABS.results:
    default:
      return `Add to ${startCase(selectedCart || CARTS.invoiceCart)}`;
    case TABS.cart: {
      return selectedCart === CARTS.priceCart ? 'Lock Prices' : 'Invoice';
    }
  }
}

export function formatRowsForCart(rows, otherProps) {
  return rows.map(row => {
    const priceBucket = get(row, 'priceBucket') || {};
    return {
      ...row,
      ...otherProps,
      bucket_price_lock_date: priceBucket.lock_date,
      bucket_tenor_start_date: priceBucket.tenor_start_date,
      bucket_tenor_end_date: priceBucket.tenor_end_date,
      bucket_weight_type: startCase(priceBucket.weight_type),
      bucket_weight_type_raw: priceBucket.weight_type,
      bucket_cos_date: priceBucket.cos_date,
    };
  });
}

export function validateOrdersForPriceLocking(ordersList, configs) {
  const invoiceColdstoreValidationApplied = isInvoiceColdstoreValidationApplied(configs);
  const errorList = [];
  ordersList.forEach(order => {
    if (!get(order, 'pricing_details')) {
      errorList.push(`PO# ${order.grinder_po_number} could not be locked because it is missing a price.`);
    }
    if (invoiceColdstoreValidationApplied && ['USA', 'Canada'].includes(get(order, 'grinder.destination_country'))) {
      if (!['coldstore_released', 'received', 'complete'].includes(order.status)) {
        errorList.push(
          `PO# ${order.grinder_po_number} could not be locked because it has not been released from Coldstore yet.`
        );
      }
    }
  });
  return errorList;
}

export function validateOrdersForPriceInvoice(ordersList) {
  const errorList = [];

  ordersList.forEach(order => {
    if (order.pricing_details?.missing_sell_price === true) {
      errorList.push(`PO# ${order.grinder_po_number} could not be invoiced because it missing a price`);
    }
  });
  return errorList;
}

export function determinePriceStatus({ selectedCart, grinder }) {
  const isUSAGrinder = ['USA', 'Canada'].includes(get(grinder, 'destination_country'));

  if (isUSAGrinder) {
    switch (selectedCart) {
      case CARTS.invoiceCart:
        return PRICE_STATUSES.LOCKED;
      case CARTS.priceCart:
      default:
        return PRICE_STATUSES.UNLOCKED;
    }
  }
  return '';
}

export function isUSRegionGrinder(grinder) {
  return ['USA', 'Canada'].includes(get(grinder, 'destination_country'));
}

export function validateMandatoryFields(ordersList, columnKeyList) {
  const errorList = [];

  columnKeyList.forEach(columnKey => {
    ordersList.forEach(order => {
      if (columnKey.validator) {
        const value = columnKey.validator(order);
        if (isEmpty(value)) errorList.push(`${columnKey.name} is required`);
      }
    });
  });

  return uniq(errorList);
}
