import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ChakraProvider } from '@chakra-ui/react';
import { getTenantTheme } from '../../theme/theme';
import { useDispatch, connect } from 'react-redux';
import { resetThemeEditedFlag, retrieveTheme, retrieveThemeInEditorMode } from '../../actions/actions_theme';

const ChakraProviderContainer = ({ children, theme, user }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    themeFromApi: undefined,
  });

  useEffect(() => {
    if (user && ['read_theme', 'write_theme'].every(permission => user.role.access_list.includes(permission)))
      dispatch(retrieveThemeInEditorMode());
    else dispatch(retrieveTheme());
  }, [user]);

  useEffect(() => {
    if (theme.is_loaded) {
      const tenantTheme = getTenantTheme(theme.data);
      // Accessing favicon element
      const favicon = document.getElementById('favicon');
      favicon.href = tenantTheme?.home?.logo;
      document.title = tenantTheme?.home?.title || 'BOAB';
      setState({ themeFromApi: tenantTheme });
    }
  }, [theme.is_loaded]);

  useEffect(() => {
    if (theme.is_edited) {
      const tenantTheme = getTenantTheme(theme.data);
      setState({ themeFromApi: tenantTheme });
      dispatch(resetThemeEditedFlag());
    }
  }, [theme.is_edited]);

  return <ChakraProvider theme={state.themeFromApi}>{children}</ChakraProvider>;
};

ChakraProviderContainer.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.objectOf(PropTypes.object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => {
  return {
    theme: state.theme,
    user: state.user,
  };
};

export default connect(mapStateToProps)(ChakraProviderContainer);
