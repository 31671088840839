import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Layout, Result, Row, Card, Typography } from 'antd';
import { fetchTreasuryData, fetchTreasuryAnnotations } from '../../../slices/treasury/treasuryReducers';
import { setActiveMarket } from '../../../slices/markets/marketReducers';
import {
  selectTreasuryError,
  selectTreasuryFetchParameters,
  selectTreasuryLoading,
  selectTreasuryTimeScale,
  selectTreasuryAnnotationFetchParameters,
} from '../../../slices/treasury/selectors/selectTreasuryBasicData';
import TreasuryFilters from './TreasuryFilters';
import RiskManagement from './RiskManagement';
import PerformanceAndForecast from './PerformanceAndForecast';
import CostSavingsAndExpectedVolume from './CostSavingsAndExpectedVolume';
import LoadingOverlay from '../../../shared/LoadingOverlay/LoadingOverlay';
import TabBar from '../../../shared/TabBar';
import { MARKETS } from '../../../config/markets';
import Constants from '../../../Constants';
import IUnleashClassFlagProvider from '../../../components/unleash/UnleashClassFlagProvider';
// This container is for the treasury page as a whole and manages data
const Treasury = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectTreasuryError);
  const loading = useSelector(selectTreasuryLoading);
  const timeScale = useSelector(selectTreasuryTimeScale);
  const { market: marketParam } = useParams();

  // Parameters for data fetch
  const dataFetchParameters = useSelector(selectTreasuryFetchParameters);
  React.useEffect(() => {
    dispatch(fetchTreasuryData(dataFetchParameters));
  }, [dispatch, dataFetchParameters]);

  React.useEffect(() => {
    dispatch(fetchTreasuryAnnotations());
  }, [dispatch, timeScale]);

  React.useEffect(() => {
    if (marketParam === MARKETS.Australia.url) {
      dispatch(setActiveMarket(MARKETS.Australia.key));
    }
    if (marketParam === MARKETS.USA.url) {
      dispatch(setActiveMarket(MARKETS.USA.key));
    }
  }, [marketParam, setActiveMarket]);

  if (error && !loading) {
    return (
      <div>
        <Result title="Error" status="error" subTitle={error} />
      </div>
    );
  }

  return (
    <div className="analytic-dashboard-container" style={{ position: 'relative' }}>
      <TabBar />
      <Layout>
        <Layout.Content>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TreasuryFilters />
            </Col>
            <Col span={24}>
              <Card>
                <Typography.Title level={3}>Risk Management Strategy</Typography.Title>
                <RiskManagement timeScale={timeScale} />
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Typography.Title level={3}>Performance and Forecast</Typography.Title>
                <PerformanceAndForecast />
                <CostSavingsAndExpectedVolume />
              </Card>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
      <LoadingOverlay loading={loading} />
    </div>
  );
}

const TreasuryUnleashController = (props) => {

  return(
    <>
    <IUnleashClassFlagProvider
      flagName={Constants.UNLEASH_FLAG.TREASURY_DASHBOARD}
      show={<Treasury {...props}/>}
      hide={<h1>Feature Not Enabled</h1>}
    />
  </>
  );
};

export default TreasuryUnleashController;