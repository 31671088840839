import {
  Box,
  Text,
  VStack,
  Alert as ChakraAlert,
  AlertIcon,
  AlertDescription,
  Button as ChakraButton,
  RadioGroup,
  Radio as ChakraRadio,
  Textarea,
  FormLabel,
  HStack,
  Center,
} from '@chakra-ui/react';
import { withFormik } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
// COMPONENTS
import * as Yup from 'yup';
// LOCAL DEPS
import Constants from '../../../Constants';
import Loading from '../../basic/Loading';
import GeneratedDocumentsResultsGrid from '../../basic/ResultsGrid/GeneratedDocumentsResultsGrid';
import SelectField from '../../basic/SelectField';
import './DocumentsSection.scss';
import { configureColumns } from './generatedDocumentsGridConfig';
import { getCOOWarnings, getFIWarnings, getPackingListWarnings } from './helpers';

const { GENERATED_DOCUMENTS, DESTINATION_PORT_CHARGE_STATUSES, SYSTEM_GENERATED_DOCUMENTS } = Constants;

const { forwarding_instructions: FORWARDING_INSTRUCTIONS } = GENERATED_DOCUMENTS;

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    documentType: Yup.string().required('Required'),
    note: Yup.string(),
    destinationPortChargeStatus: Yup.string().when('documentType', {
      is: FORWARDING_INSTRUCTIONS,
      then: Yup.string().required('Please select Collect or Prepaid'),
    }),
    lotNumberFormatId: Yup.number(),
    isCustom: Yup.bool()
  }),
  handleSubmit: (values, form) => {
    // Fire off action to generate doc
    form.props.onSubmit(values);
    form.setSubmitting(false);
  },
});

class DocumentsSection extends PureComponent {
  static propTypes = {
    documents: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string,
    documentOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    lotNumberFormatOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    grinderIsChinese: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onEmail: PropTypes.func.isRequired,
    order: PropTypes.shape({
      voyage: PropTypes.arrayOf(
        PropTypes.shape({
          dischargePortId: PropTypes.number,
          id: PropTypes.number,
        })
      ),
      billOfLading: PropTypes.shape({
        id: PropTypes.number,
      }),
      hasValidFiCartons: PropTypes.bool,
    }),
    isEditable: PropTypes.bool,
    isLoading: PropTypes.bool,
    // Populated by Formik:
    isSubmitting: PropTypes.bool,
    values: PropTypes.shape({
      documentType: PropTypes.string,
      destinationPortChargeStatus: PropTypes.string,
      note: PropTypes.string,
      lotNumberFormatId: PropTypes.number,
    }),
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetForm: PropTypes.func,
    customPoDocsList: PropTypes.array,
  };

  static INITIAL_STATE = {
    hasMounted: false,
    showWarnings: false,
    warnings: [],
    selectedDocTypeLabel: null,
  };

  columns = configureColumns(['documentType', 'generationDate', 'version', 'generatedBy', 'actions']);

  state = DocumentsSection.INITIAL_STATE;

  handleSelect = selectedDocType => {
    if (!this.props) return;
    // reset warnings when dropdown value changed
    this.setState({ showWarnings: false, warnings: '' });

    const { values, resetForm, setFieldValue, order } = this.props;
    if (!selectedDocType) {
      resetForm();
    } else if (values.documentType && values.documentType !== selectedDocType) {
      resetForm();
      setFieldValue('documentType', selectedDocType ? selectedDocType.value : null);
      this.hasPrerequisitesMatched(selectedDocType.value, order);
      this.setState({ selectedDocTypeLabel: selectedDocType ? selectedDocType.label : null });
    } else {
      setFieldValue('documentType', selectedDocType ? selectedDocType.value : null);
      this.hasPrerequisitesMatched(selectedDocType.value, order);
      this.setState({ selectedDocTypeLabel: selectedDocType ? selectedDocType.label : null });
    }
  };

  handleSelectLotNumberFormat = selection => {
    const { setFieldValue } = this.props;
    setFieldValue('lotNumberFormatId', selection.value);
  };

  // business validation
  hasPrerequisitesMatched = (documentType, order) => {
    let warnings = null;
    switch (documentType) {
      case GENERATED_DOCUMENTS.confirmationOfPurchase.value:
      case GENERATED_DOCUMENTS.confirmationOfSale.value: {
        // config.note = note;
        break;
      }
      case GENERATED_DOCUMENTS.forwardingInstructions.value: {
        warnings = getFIWarnings(order);
        break;
      }
      case GENERATED_DOCUMENTS.certificateOfOrigin.value: {
        warnings = getCOOWarnings(order);
        break;
      }
      case GENERATED_DOCUMENTS.packingList.value: {
        warnings = getPackingListWarnings(order);
        break;
      }
      case GENERATED_DOCUMENTS.certificateOfInsurance.value:
      default:
        break;
    }
    if (!isEmpty(warnings)) {
      this.setState({ showWarnings: true, warnings });
    }
  };

  componentDidUpdate(prevProps) {
    const { isLoading, resetForm } = this.props;

    if (!isLoading && prevProps.isLoading) {
      resetForm();
    }
  }

  render() {
    const {
      documents,
      values,
      isLoading,
      isSubmitting,
      title,
      documentOptions,
      handleSubmit,
      setFieldValue,
      isEditable,
      onEmail,
      lotNumberFormatOptions,
      grinderIsChinese,
      customPoDocsList,
    } = this.props;

    const { showWarnings, warnings, selectedDocTypeLabel } = this.state;
    const currentSelection = documentOptions.find(option => option.value === values.documentType);

    const updatedDocuments = documents.map(doc => {
      // Add Document Label. We will use label for Document Type column.
      const allDocTypes = Object.values(SYSTEM_GENERATED_DOCUMENTS).concat(Object.values(GENERATED_DOCUMENTS));
      const foundType = allDocTypes.find(option => option.docType === doc.docType);

      return { ...doc, docLabel: get(foundType, 'label'), isEditable, onEmail: () => onEmail(doc), documents };
    });

    // initial values
    if (!values.destinationPortChargeStatus) {
      values.destinationPortChargeStatus = 'collect';
    }
    if (customPoDocsList?.includes(values.documentType)) {
      values.isCustom = true;
    }
    return (
      <form onSubmit={handleSubmit}>
        <VStack spacing="18px" align="stretch">
          {/* <!-- SELECT --> */}
          <HStack marginTop="8px" spacing="24px" alignItems="flex-end">
            <Box width="203px">
              {/* <!-- HEADER --> */}
              {title && <FormLabel>{title}</FormLabel>}
              <SelectField
                name="documentType"
                options={documentOptions}
                defaultValue={values.documentType}
                onChange={selection => this.handleSelect(selection)}
                closeMenuOnSelect
                isDisabled={!isEditable}
              />
            </Box>
            {/* <!-- SUBMIT BUTTON --> */}
            <ChakraButton
              type="submit"
              colorScheme="actionPrimary"
              width="115px"
              height="40px"
              fontWeight="700"
              isDisabled={isSubmitting || !values.documentType || showWarnings}
              onClick={handleSubmit}
            >
              Generate
            </ChakraButton>
          </HStack>

          {/* <!-- WARNINGS --> */}
          {showWarnings && (
            <ChakraAlert status="warning">
              <AlertIcon />
              <AlertDescription marginLeft="10px">
                <Box>
                  <ol>
                    {warnings.map((warning, idx) => (
                      <Box key={`doc-section-warning-${idx}`}>
                        <li>{warning}</li>
                      </Box>
                    ))}
                  </ol>
                </Box>
              </AlertDescription>
            </ChakraAlert>
          )}

          {/* <!-- NOTE --> */}
          {currentSelection && currentSelection.hasNotes && (
            <Box width="50%">
              <FormLabel>{selectedDocTypeLabel}</FormLabel>
              <Textarea
                backgroundColor="white"
                value={values.note}
                id="docType"
                placeholder="Notes"
                onChange={e => setFieldValue('note', get(e, 'target.value'))}
              />
            </Box>
          )}
          {/* <!-- LOT NUMBER FORMAT --> */}
          {currentSelection && currentSelection.hasLotNumbers && grinderIsChinese && (
            <Box width="203px">
              <FormLabel>Lot Number Format:</FormLabel>
              <SelectField
                name="lotNumberFormatId"
                options={isEditable && lotNumberFormatOptions}
                // value={values.lotNumberFormatId}
                onChange={selection => this.handleSelectLotNumberFormat(selection)}
                closeMenuOnSelect
              />
            </Box>
          )}

          {/* <!-- DESTINATION PORT CHARGE STATUS --> */}
          {currentSelection && currentSelection.value === FORWARDING_INSTRUCTIONS && (
            <VStack align="stretch" width="50%">
              <Text fontWeight="bold">Destination Port Charges</Text>
              <RadioGroup
                value={values.destinationPortChargeStatus}
                onChange={value => setFieldValue('destinationPortChargeStatus', value)}
              >
                <HStack spacing="10px">
                  {DESTINATION_PORT_CHARGE_STATUSES.map(option => (
                    <ChakraRadio
                      colorScheme="actionSecondary"
                      key={`radio-${option.value}`}
                      value={option.value}
                      marginBottom="0px"
                    >
                      {option.label}
                    </ChakraRadio>
                  ))}
                </HStack>
              </RadioGroup>
            </VStack>
          )}
          {/* <!-- GRID --> */}
          {isLoading ? (
            <Center>
              <Loading />
            </Center>
          ) : (
            <Box className="document-results">
              {updatedDocuments && updatedDocuments.length > 0 && (
                <GeneratedDocumentsResultsGrid
                  id="generated-documents"
                  className="documents-section__grid results-grid--minimal"
                  {...{
                    columns: this.columns,
                    rows: updatedDocuments || [],
                    headerRowHeight: 38,
                    rowHeight: 52,
                  }}
                />
              )}
            </Box>
          )}
        </VStack>
      </form>
    );
  }
}

export default formikEnhancer(DocumentsSection);
