import { Button, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { ANNOTATION_MODAL_MODE } from '../../modules/hooks/useAnnotation';
import PropTypes from 'prop-types';

function AnnotationModal(props) {
  const { showModal, modalMode, modalAnnotation, onModalSubmit, closeModal, deleteAnnotation, allowTitle } = props;

  const [newContent, setNewContent] = React.useState(modalAnnotation ? modalAnnotation.content : '');

  const [newTitle, setNewTitle] = React.useState(modalAnnotation ? modalAnnotation.title : '');

  React.useEffect(() => {
    setNewContent(modalAnnotation ? modalAnnotation.content : '');
    setNewTitle(modalAnnotation ? modalAnnotation.title : '');
  }, [modalAnnotation]);

  return (
    <Modal
      visible={showModal}
      onCancel={() => closeModal()}
      title={modalMode === ANNOTATION_MODAL_MODE.ADD ? 'Create new annotation' : 'Edit annotation'}
      footer={[
        modalMode === ANNOTATION_MODAL_MODE.EDIT && (
          <Button key="delete" danger onClick={() => deleteAnnotation(modalAnnotation)}>
            Delete
          </Button>
        ),
        <Button key="cancel" onClick={() => closeModal()}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => onModalSubmit({ content: newContent, title: newTitle })}>
          {modalMode === ANNOTATION_MODAL_MODE.ADD ? 'Create' : 'Save'}
        </Button>,
      ]}
    >
      {allowTitle && (
        <Input
          value={newTitle}
          onChange={e => setNewTitle(e.target.value)}
          style={{ marginBottom: 20 }}
          placeholder="Title"
        />
      )}
      <Input.TextArea
        rows={4}
        value={newContent}
        onChange={e => setNewContent(e.target.value)}
        placeholder="Annotation"
      />
    </Modal>
  );
}

AnnotationModal.propTypes = {
  showModal: PropTypes.bool,
  modalMode: PropTypes.string,
  modalAnnotation: PropTypes.instanceOf(Object),
  onModalSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  deleteAnnotation: PropTypes.func,
  allowTitle: PropTypes.bool,
};

export default AnnotationModal;
