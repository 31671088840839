import PropTypes from "prop-types"
import React, { useEffect, useState } from 'react';
import { Box, StatHelpText, TabPanel, TabPanels, Tabs, Divider } from '@chakra-ui/react';
import DashboardSectionTile from '../../components/core/DashboardSectionTile';
import OrderHistoryTable from '../../components/orders/OrderHistoryTable';
import { PaginationBar } from '../../components/core/PaginationComponents';


const PoChangeHistory = (props) => {

  const title = props.Title;
  const token = props.user.token;
  const poId = props.match.params.poId; 
  const displayRows = props.displayRows;
  const poHistory = props.poHistory.data;

  const [currentPagination, setCurrentPagination] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [CurrentRecords, setCurrentRecords] = useState(poHistory.slice((currentPagination - 1) * displayRows, currentPagination * displayRows));

  useEffect(() => {
    props.fetchPoHistroy(token, poId);
  }, [pageCount]);

  useEffect(() => {
    const History = [...poHistory];
    const sortedHistory = History.sort((a, b) => (new Date(a.edit_date) - new Date(b.edit_date)));
    const sortedHistoryDesc = sortedHistory.reverse(); 
    const sortedHistorySlice = sortedHistoryDesc.slice((currentPagination - 1) * displayRows, currentPagination * displayRows);
    setCurrentRecords(sortedHistorySlice);
    setPageCount(Math.ceil((poHistory.length + 1 )/ displayRows ));
  }, [poHistory, currentPagination]);

  const changeCurrentPagination = (newIndex) => {
    setCurrentPagination(newIndex);
  };
  


  return (
    <>
      <DashboardSectionTile title={title}  >
        <Divider/>
          <OrderHistoryTable data={CurrentRecords}/>
        <Divider/>
      </DashboardSectionTile>
      <Box>

      </Box>
      <Box padding='4'>
        <PaginationBar
          currentPage={currentPagination}
          pages={pageCount}
          onPageChange={(index) => (changeCurrentPagination(index))}
          justifyContent="flex-end"/>
      </Box>
    </>
  );
};

PoChangeHistory.propTypes = {
  Title: PropTypes.string,
  displayRows: PropTypes.number,
  fetchPoHistroy: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      poId: PropTypes.number
    })
  }),
  poHistory: PropTypes.shape({
    data: PropTypes.shape({
      length: PropTypes.number,
      slice: PropTypes.func
    })
  }),
  user: PropTypes.shape({
    token: PropTypes.string
  })
}

export default PoChangeHistory;
