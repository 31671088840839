import Constants from '../../Constants';

export const ALL_OPTION = {
  value: 'all',
  label: 'All',
};

export const DOMESTIC_LOAD_PHASES = [
  {
    value: 'domestic_purchase_unshipped',
    label: 'Domestic Purchase Unshipped',
  },
  {
    value: 'domestic_in_transit',
    label: 'Domestic In Transit',
  },
  {
    value: 'domestic_delivery',
    label: 'Domestic Delivered',
  },
];

export const INTERNATIONAL_LOAD_PHASES = [
  {
    value: 'purchase_unshipped',
    label: 'Purchase Unshipped',
  },
  {
    value: 'on_the_water',
    label: 'On The Water',
  },
  {
    value: 'inventory',
    label: 'Inventory',
  },
  {
    value: 'scheduled_delivery',
    label: 'Scheduled Delivery',
  },
];

export const LOAD_PHASES = [...INTERNATIONAL_LOAD_PHASES, ...DOMESTIC_LOAD_PHASES];

export const ORDER_TYPES = [
  {
    value: 'international',
    label: 'International',
  },
  {
    value: 'domestic',
    label: 'Domestic',
  },
];

export const loadPhaseFilter = {
  options: LOAD_PHASES,
  default: [],
  label: 'Load Status',
  placeholder: 'All Statuses',
  name: 'load_phase',
  isMulti: true,
};

export const packerFilter = {
  default: [],
  label: 'Establishment',
  placeholder: 'All Establishments',
  name: 'packer_plant',
  isMulti: true,
};

export const grinderFilter = {
  default: [],
  label: 'Grinder',
  placeholder: 'All Grinders',
  name: 'grinder',
  isMulti: true,
};

export const assigneeFilter = {
  default: [],
  label: 'Assignee',
  placeholder: 'All Assignees',
  name: 'user',
  isMulti: true,
};

export const exceptionTypeFilter = {
  default: [],
  label: 'Task Type',
  placeholder: 'All Task Types',
  name: 'task_type',
  isMulti: true,
};

export const orderTypeFilter = {
  default: [],
  options: ORDER_TYPES,
  label: 'Order Type',
  placeholder: 'All Order Types',
  name: 'orderType',
  isClearable: false,
  isSearchable: false,
  closeMenuOnSelect: true,
};

export const purchasingOfficeFilter = {
  options: Object.values(Constants.BUSINESS_OFFICES),
  default: [],
  label: 'Purch. Office',
  placeholder: 'All Purchasing Offices',
  name: 'purchase_office',
  isMulti: true,
};

export const productTypeFilter = {
  options: Constants.PRODUCT_TYPES,
  default: [],
  label: 'Product Type',
  placeholder: 'All Product Types',
  name: 'product_type',
  isMulti: true,
};

const statusOptions = Object.keys(Constants.ORDER_STATUSES).map(key => ({
  label: Constants.ORDER_STATUSES[key].replace(/_/g, ' '),
  value: Constants.ORDER_STATUSES[key],
}));

export const statusFilter = {
  options: statusOptions,
  default: [],
  label: 'Status',
  placeholder: 'All',
  name: 'po_status',
  isMulti: true,
};

export const allOrderTypeFilter = {
  ...orderTypeFilter,
  options: [ALL_OPTION, ...ORDER_TYPES],
};

export const searchFilters = [
  allOrderTypeFilter,
  grinderFilter,
  packerFilter,
  loadPhaseFilter,
  exceptionTypeFilter,
  assigneeFilter,
];

export const dashboardFilters = [
  exceptionTypeFilter,
  assigneeFilter,
  packerFilter,
  grinderFilter,
  purchasingOfficeFilter,
  productTypeFilter,
  statusFilter,
];
