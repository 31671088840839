import { createSlice } from '@reduxjs/toolkit';

const supplyChainDetailsSlice = createSlice({
  name: 'supply-chain-details',
  initialState: {
    activePackerPlant: null,
    activeGrinder: null,
  },
  reducers: {
    updateActivePackerPlant(state, action) {
      state.activePackerPlant = action.payload;
    },
    updateActiveGrinder(state, action) {
      state.activeGrinder = action.payload;
    },
  },
});

export const { updateActivePackerPlant, updateActiveGrinder } = supplyChainDetailsSlice.actions;

export default supplyChainDetailsSlice.reducer;
