import { connect } from 'react-redux';
import DrawDownReportsComponent from '../../components/finance/DrawDownReports/Index';
import IUnleashClassFlagProvider from '../../components/unleash/UnleashClassFlagProvider';
import Constants from '../../Constants';
const mapStateToProps = state => {
  return {
    token: state.user.token || '',
    user: state.user || {},
    users: state.users || null,
    isSearching: state.packerInvoiceOrders.isSearching,
    ordersToDrawDown: state.packerInvoiceOrders.ordersToDrawDown,
    generatedDrawDownLink: state.packerInvoiceOrders.drawDownLink,
    drawDownHistoricalData: state.packerInvoiceOrders.drawDownHistoricalData,
    configs: state.configs,
  };
};

const DrawDownReports = connect(mapStateToProps)(DrawDownReportsComponent);

const DrawDownReportsFeatureUnleashController = (props) => {

  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.DRAWDOWN_REPORT}
        show={<DrawDownReports {...props} />}
        hide={<h1>Feature Not Enabled</h1>}
      />
    </>
  );
};

export default DrawDownReportsFeatureUnleashController;
