const schema = [
  {
    name: 'packer',
    displayName: 'Packer',
    nested: false,
    systemIdField: 'id',
    displayIdField: 'name',
    tabGroup: { key: 'suppliers', label: 'Suppliers' },
    deletable: true,
    path: '/packers',
    autoUid: true,
    fields: [
      {
        name: 'id',
        displayName: 'UID',
        tooltip: 'Provide the Unique ID to create the Packer',
        dataType: 'shorttext',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'Provide the name of the Packer',
        dataType: 'shorttext',
        required: true,
        systemIdentifier: false,
        hidden: false,
      },
      {
        name: 'company_name',
        displayName: 'Company Name',
        tooltip: 'Provide the company name of the Packer',
        dataType: 'shorttext',
        required: false,
        systemIdentifier: false,
        hideList: true,
      },
      {
        name: 'purchasing_office',
        displayName: 'Purchasing Office',
        tooltip: 'Select the Purchasing Office associcated of the Packers',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'business_office',
      },
      {
        name: 'preferred_unit_id',
        displayName: 'Default Buy UOM',
        tooltip: 'Provide the Default Buy Unit of Measure (UOM) of the Packer.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'unit',
      },
      {
        name: 'default_buy_incoterms',
        displayName: 'Default Buy Incoterms',
        tooltip: 'Provide the default buy incoterms of the Packer',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'incoterms',
      },
      {
        name: 'default_buy_currency',
        displayName: 'Default Buy Currency',
        tooltip: 'Provide the default buy currency of the Packer',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'currency',
      },
      {
        name: 'product_type',
        displayName: 'Product Type',
        tooltip: 'Select the Product type of the Packer, either Beef or Pork.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'product_types',
        required: true,
      },
      {
        name: 'packer_plant_ids',
        displayName: 'Establishments',
        tooltip: 'Select the Establishments of the Packers',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'packer_plant',
        required: true,
      },
      {
        name: 'organisation_ids',
        displayName: 'Organisation',
        tooltip: 'Select an organisation of the Packer',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'organisation',
        required: true,
      },
      {
        name: 'address',
        displayName: 'Address',
        dataType: 'reference',
        referenceType: 'nested',
        referenceEntityType: 'address_port',
      },
      {
        name: 'latitude',
        displayName: 'Latitude',
        dataType: 'shorttext',
        hideList: true,
      },
      {
        name: 'longitude',
        displayName: 'Longitude',
        dataType: 'shorttext',
        hideList: true,
      },
      {
        name: 'timezone',
        displayName: 'Timezone',
        dataType: 'shorttext',
        hideList: true,
      },
    ],
  },
];
export default schema;
