import React from 'react';
import PropTypes from 'prop-types';
import { get, isNil, set } from 'lodash';

const propTypes = {
  row: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  column: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onRowChange: PropTypes.func,
  onClose: PropTypes.func,
  context: PropTypes.instanceOf(Object),
  type: PropTypes.string,
  step: PropTypes.string,
  disableGridMode: PropTypes.bool,
};

function autoFocusAndSelect(input) {
  input?.focus();
  input?.select();
}

function InputEditor({ row, className, column, onRowChange, onClose, type, context, step, disableGridMode, ...rest }) {
  let value = get(row, column.key);
  if (type === 'time' && typeof value === 'string') {
    value = value.substring(0, 5);
  }
  return (
    <div
      style={
        disableGridMode
          ? undefined
          : {
              display: 'list-item',
              paddingLeft: rest.px || rest.pl || rest.paddingLeft || '11px',
              paddingRight: rest.px || rest.pr || rest.paddingRight || '11px',
              paddingTop: rest.py || rest.pt || rest.paddingTop || '0px',
              paddingBottom: rest.py || rest.pb || rest.paddingBottom || '3px',
            }
      }
    >
      <input
        type={type || 'text'}
        className={`rdg-text-editor form-control ${className}`}
        style={
          disableGridMode
            ? undefined
            : {
                borderColor: '#ccc',
                paddingLeft: rest.px || rest.pl || rest.paddingLeft || '5px',
                paddingRight: rest.px || rest.pr || rest.paddingRight || '5px',
                paddingTop: rest.py || rest.pt || rest.paddingTop || '1px',
                paddingBottom: rest.py || rest.pb || rest.paddingBottom || '1px',
                lineHeight: rest.lineHeight || '22px',
                fontSize: rest.fontSize || '12px',
              }
        }
        ref={autoFocusAndSelect}
        value={isNil(value) ? '' : value}
        onChange={event => {
          let { value: val } = event.target;
          if (type === 'number') {
            val = Number(val);
          } else if (type === 'time') {
            val = val.substring(0, 5);
          }
          const newRow = { ...row };
          set(newRow, column.key, val);
          return onRowChange(newRow);
        }}
        onBlur={() => onClose && onClose(true)}
        step={step}
      />
    </div>
  );
}

InputEditor.propTypes = propTypes;

export default InputEditor;
