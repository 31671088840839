import React from 'react';
import PropTypes from 'prop-types';
import { Image, VStack } from '@chakra-ui/react';

const ArticleTile = ({ children, imageUrl, ...rest }) => {
  return (
    <VStack
      align="stretch"
      spacing="0px"
      w="370px"
      boxShadow="md"
      borderRadius="15px"
      backgroundColor="card.default"
      {...rest}
    >
      <Image borderRadius="15px 15px 0px 0px" src={imageUrl} h="199px" w="370px" objectFit="cover" />
      <VStack align="stretch" spacing="20px" padding="22px 43px 27px 43px">
        {children}
      </VStack>
    </VStack>
  );
};

ArticleTile.propTypes = {
  imageUrl: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ArticleTile;
