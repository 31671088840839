import * as React from 'react';
import { Typography } from 'antd';
import styles from '../Filter.module.css';
import FilterSelectGeneric from '../FilterSelectGeneric/FilterSelectGeneric';

export default function FilterMultiSelect(props) {
  const { label, options, value, onChange, dataColors, maxNumOfValues, indicator, withColors } = props;
  const filteredOptions = options.filter(x => value.every(t => !t || t.id !== x.id));
  const [availableOptions, setAvailableOptions] = React.useState(filteredOptions);

  React.useEffect(() => {
    if (value.length === 1 && value[0] === undefined && options) {
      setAvailableOptions(options);
    }
  }, [options, value]);

  const handleChange = React.useCallback(
    (selected, position) => {
      let tmp = [...value];

      // add or remove an option from filters
      if (selected === undefined) {
        tmp.splice(position, 1);
      } else if (selected[indicator]) {
        tmp = [selected];
      } else {
        tmp[position] = selected;
      }

      // filter the list of options to show only those that are not selected yet
      const optionsFiltered = options.filter(x => tmp.every(t => !t || t.id !== x.id));
      setAvailableOptions(optionsFiltered);

      // decide if we want to add a possibility to select another option
      if (
        tmp.every(x => x !== undefined) &&
        tmp.length < maxNumOfValues &&
        optionsFiltered.length &&
        (!selected || !selected[indicator])
      ) {
        tmp.push(undefined);
      }

      onChange(tmp);
    },
    [value, options, maxNumOfValues]
  );

  return (
    <div>
      <Typography.Text className={styles.filterLabel}>{label}</Typography.Text>
      {value.map((x, i) => {
        return (
          <FilterSelectGeneric
            key={`filter-${i}`}
            value={x ? x.name : undefined}
            onChange={val => {
              const selected = options.find(x => x.name === val);
              handleChange(selected, i);
            }}
            options={availableOptions.map(p => p.name)}
            placeholder={value.length === 1 ? '+ Add establishment' : '+ Add comparison'}
            allowClear={true}
            withColors={withColors}
            dataColors={dataColors}
          />
        );
      })}
    </div>
  );
}
