import { Radio } from 'antd';
import React from 'react';
import { DATE_MODE, SUPPLY_CHAIN_VIEW_MODE } from '../../../../slices/markets/marketsConfig';
import PropTypes from 'prop-types';

export default function SupplyChainDateModeControl(props) {
  const { controlState, onDateModeChange } = props;

  const dateModeOptions = Object.entries(DATE_MODE).map(([key, value]) => {
    let label = '';
    if (key === 'DELIVERY') {
      if (controlState.viewMode === SUPPLY_CHAIN_VIEW_MODE.PACKER_PLANTS) {
        label = 'Delivered';
      } else {
        label = 'Received';
      }
    } else {
      label = 'Ordered';
    }
    return {
      label,
      value,
    };
  });

  return (
    <div>
      <Radio.Group value={controlState.dateMode} onChange={e => onDateModeChange(e.target.value)}>
        {dateModeOptions.map(option => (
          <Radio.Button key={option.value} value={option.value}>
            {option.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
}

SupplyChainDateModeControl.propTypes = {
  controlState: PropTypes.shape({
    viewMode: PropTypes.string,
    dateMode: PropTypes.string,
  }),
  onDateModeChange: PropTypes.func,
};
