import React from 'react';
import { calculateAvgLoadCL } from '../../../functions';

const calculateFreshOrFrozen = lines => {
  // default empty line on start
  if (lines.length === 1 && !lines[0].input_product) {
    return '-';
  }

  const fresh = lines.filter(line => line.input_product && line.input_product.fresh);

  return fresh.length ? 'Fresh' : 'Frozen';
};

const calculateAverageCL = lines => {
  const lineCLs = lines
    .map(item => (item.input_product && item.quantity ? item.input_product.cl : null))
    .filter(value => value !== null);

  if (lineCLs.length !== lines.length) {
    return null;
  }

  return calculateAvgLoadCL({ lines });
};

const calculateAveragePricePerUnit = lines => {
  const pricesPerUnit = lines.map(item => parseFloat(item.price_per_unit)).filter(value => parseFloat(value) > 0);

  if (pricesPerUnit.length === 0) {
    return 0;
  }
  const sumOfPricesPerUnit = pricesPerUnit.reduce((p1, p2) => p1 + p2);

  return Math.round((sumOfPricesPerUnit / pricesPerUnit.length) * 1000) / 1000;
};

const SummaryItem = ({ lines, name }) => {
  const formControl = {
    marginBottom: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
  };
  let value = 0;

  switch (name) {
    case 'avg_price_per_unit':
      value = calculateAveragePricePerUnit(lines);
      break;
    case 'fresh_or_frozen':
      value = calculateFreshOrFrozen(lines);
      break;
    case 'avg_cl':
      value = calculateAverageCL(lines);
      break;
    default:
      break;
  }

  return (
    <input
      id="number_of_offers"
      type="text"
      value={value || '-'}
      style={formControl}
      disabled
      className="form-control"
    />
  );
};

export default SummaryItem;
