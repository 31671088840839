import FileUpload from '../../../containers/basic/FileUpload';

const schema = [
  {
    name: 'contract',
    displayName: 'Contract',
    nested: false,
    systemIdField: 'number',
    displayIdField: 'number',
    path: '/contracts',
    tabGroup: { key: 'pricing', label: 'Pricing' },
    deletable: true,
    fields: [
      {
        name: 'number',
        displayName: 'Number',
        tooltip: 'Unique identifier or reference number for the contract',
        dataType: 'shorttext',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'Title or description of the contract',
        dataType: 'shorttext',
        required: true,
        systemIdentifier: false,
        hidden: false,
      },
      {
        name: 'strategy_type_id',
        displayName: 'Type',
        tooltip: 'Type of the contract (e.g., standard Contract or NOF - Non-Obligatory Forward).',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'strategy_type',
        required: true,
      },
      {
        name: 'packer_id',
        displayName: 'Packer',
        tooltip: 'Name or identifier of the packer associated with the contract',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'packer',
        required: true,
      },
      {
        name: 'grinder_uid',
        displayName: 'Grinder',
        tooltip: 'Select the Grinder associated with the contract',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'grinder',
        required: true,
      },
      {
        name: 'delivery_date_start',
        displayName: 'Delivery Date From',
        tooltip: 'Start date for the delivery period specified in the contract',
        dataType: 'date',
        required: true,
      },
      {
        name: 'delivery_date_end',
        displayName: 'Delivery Date To',
        tooltip: 'End date for the delivery period specified in the contract',
        dataType: 'date',
        required: true,
      },
      {
        name: 'strategy_prices',
        displayName: 'Products',
        tooltip: 'Select the products included in the contract from the list',
        dataType: 'reference',
        referenceType: 'multi-nested',
        referenceEntityType: 'strategy_price',
      },
      {
        name: 'attachments',
        displayName: 'Attachments',
        dataType: 'component',
        component: FileUpload,
        propName: 'strategy',
        includeInAddNew: true,
      },
    ],
  },
];

export default schema;
