import Constants from '../Constants';

export const LOAD_ORDER = 'LOAD_ORDER';
export const LOAD_FAST_ORDERS = 'LOAD_FAST_ORDERS';
export const DELETE_ORDER = 'DELETE_ORDER';
export const REPLACE_ORDER = 'REPLACE_ORDER';
export const ADD_FILE_TO_ORDER = 'ADD_FILE_TO_ORDER';
export const LOAD_ORDER_PENDING = 'LOAD_ORDER_PENDING';
export const LOAD_PENDING_ORDERS = 'LOAD_PENDING_ORDERS';
export const LOAD_ORDER_HISTORY = 'LOAD_ORDER_HISTORY';
export const LOAD_ORDER_HISTORY_BY_USER = 'LOAD_ORDER_HISTORY_BY_USER';
export const RESET_ORDER = 'RESET_ORDER';
export const ORDER_ERROR = 'ORDER_ERROR';
export const LOAD_PO_LOGS = 'LOAD_PO_LOGS';
export const LOAD_GRINDER_PO_LOGS = 'LOAD_GRINDER_PO_LOGS';
export const LOAD_PACKER_PLANT_PO_LOGS = 'LOAD_PACKER_PLANT_PO_LOGS';
export const LOAD_EDIT_PO_LOGS = 'LOAD_EDIT_PO_LOGS';
export const LOAD_PO_LOGS_TABS = 'LOAD_PO_LOGS_TABS';
export const LOAD_PO_LOG_ORDER = 'LOAD_PO_LOG_ORDER';
export const REFRESH_ORDERS = 'REFRESH_ORDERS';

// probably need for edited order
export function saveOrder(order, token) {
  return dispatch => {
    const revisedOrder = {
      packer_plant_id: order.packer_plant_id,
      shipment_date: order.shipment_date !== '' ? order.shipment_date : null,
      delivery_date: order.delivery_date !== '' ? order.delivery_date : null,
      transporter_id: order.transporter_id === '' ? null : order.transporter_id,
      drop_trailer: order.drop_trailer === '' ? null : order.drop_trailer,
      scheduled_delivery_time: order.scheduled_delivery_time !== '' ? order.scheduled_delivery_time : null,
      production_date: order.production_date !== '' ? order.production_date : null,
      transport_cost_per_unit: order.transport_cost_per_unit,
    };
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${order.id}`, {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(revisedOrder),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        dispatch(replaceOrder(json));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function addFileToOrderLine(order, line, type, fileData, token, callBack = null) {
  return dispatch => {
    const formData = new FormData();
    formData.append('file', fileData);
    const header = new Headers({ Authorization: `Token ${token}` });
    return fetch(
      `${Constants.URL}order_management/purchase_orders/${order.purchase_order_id}/lines/${line.id}/files?type=${type}`,
      {
        method: 'POST',
        headers: header,
        body: formData,
      }
    )
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        const orderCopy = JSON.parse(JSON.stringify(order));
        orderCopy.lines = [];
        order.lines.forEach(function (thisLine) {
          if (thisLine.id === json.id) {
            json.quantity = json.quantity;
            json.FOB = json.price_per_unit;
            json.third_party_tested_cl = thisLine.third_party_tested_cl;
            json.grinder_tested_cl = thisLine.grinder_tested_cl;
            json.recv_quantity = thisLine.recv_quantity;
            orderCopy.lines.push(json);
          } else {
            orderCopy.lines.push(thisLine);
          }
        });
        if (callBack != null) {
          callBack(orderCopy);
        }
        dispatch(refreshOrders(orderCopy));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

// probably needed for saving edited ordere
export function submitPologs(order, statusChange, token) {
  return dispatch => {
    const modifiedOrder = {
      packer_plant_id: order.packer_plant_id,
      transporter_id: order.transporter_id === '' ? null : order.transporter_id,
      drop_trailer: order.drop_trailer === '' ? null : order.drop_trailer,
      shipment_date: order.shipment_date,
      delivery_date: order.delivery_date === '' ? null : order.delivery_date,
      scheduled_delivery_time: order.scheduled_delivery_time === '' ? null : order.scheduled_delivery_time,
      production_date: order.production_date,
      transport_cost_per_unit: order.transport_cost_per_unit,
      status: order.status,
    };
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${order.id}`, {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(modifiedOrder),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        if (statusChange) {
          dispatch(loadOrder(json));
        } else {
          dispatch(replaceOrder(json));
        }
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function edit_purchase_order(order, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/logs/edit`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(order),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function fetch_pologs_history_tabs(token) {
  return dispatch => {
    const header = new Headers({ Authorization: `Token ${token}` });
    return (
      fetch(`${Constants.URL}order_management/purchase_orders/logs/tabs`, {
        method: 'GET',
        headers: header,
      })
        .then(response => Constants.handleErrors(response, dispatch, errorOrder))
        .then(json => dispatch(render_po_logs_buttons(json)))
        // .then(json => dispatch(loadOrderHistory(json)))
        .catch(error => dispatch(errorOrder(error)))
    );
  };
}

export function fetch_po_logs(multifilters, token) {
  return dispatch => {
    const header = new Headers({ Authorization: `Token ${token}` });
    let queryString = '?';
    for (const multifilter in multifilters) {
      if (multifilters[multifilter] && multifilters[multifilter] !== '') {
        if (queryString !== '?') {
          queryString += '&';
        }
        queryString += `${multifilter}=${multifilters[multifilter]}`;
      }
    }
    return fetch(`${Constants.URL}order_management/purchase_orders/logs/full${queryString}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => dispatch(loadPoLogs(json)))
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function fetch_po_log_order(multifilters, token) {
  return dispatch => {
    const header = new Headers({ Authorization: `Token ${token}` });
    let queryString = '?';
    for (const multifilter in multifilters) {
      if (multifilters[multifilter] && multifilters[multifilter] !== '') {
        if (queryString !== '?') {
          queryString += '&';
        }
        queryString += `${multifilter}=${multifilters[multifilter]}`;
      }
    }
    return fetch(`${Constants.URL}order_management/purchase_orders${queryString}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => dispatch(loadPoLogOrder(json[0])))
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function fetch_po_logs_grinders(multifilters, token) {
  return dispatch => {
    const header = new Headers({ Authorization: `Token ${token}` });
    let queryString = '?';
    for (const multifilter in multifilters) {
      if (multifilters[multifilter] && multifilters[multifilter] !== '') {
        if (queryString !== '?') {
          queryString += '&';
        }
        queryString += `${multifilter}=${multifilters[multifilter]}`;
      }
    }
    return fetch(`${Constants.URL}order_management/purchase_orders/logs/grinder${queryString}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => dispatch(loadGrinderPoLogs(json)))
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function fetch_po_logs_packers(multifilters, token) {
  return dispatch => {
    const header = new Headers({ Authorization: `Token ${token}` });
    let queryString = '?';
    for (const multifilter in multifilters) {
      if (multifilters[multifilter] && multifilters[multifilter] !== '') {
        if (queryString !== '?') {
          queryString += '&';
        }
        queryString += `${multifilter}=${multifilters[multifilter]}`;
      }
    }
    return fetch(`${Constants.URL}order_management/purchase_orders/logs/packer${queryString}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => dispatch(loadPackerPoLogs(json)))
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function fetch_edit_po_logs(multifilters, token) {
  return dispatch => {
    const header = new Headers({ Authorization: `Token ${token}` });
    let queryString = '?';
    for (const multifilter in multifilters) {
      if (multifilters[multifilter] && multifilters[multifilter] !== '') {
        if (queryString !== '?') {
          queryString += '&';
        }
        queryString += `${multifilter}=${multifilters[multifilter]}`;
      }
    }
    return fetch(`${Constants.URL}order_management/purchase_orders/logs/edit${queryString}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => dispatch(loadEditPoLogs(json)))
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function clear_po_logs() {
  return dispatch => {
    dispatch(loadPoLogs([]));
  };
}

export function clear_po_logs_grinders() {
  return dispatch => {
    dispatch(loadGrinderPoLogs([]));
  };
}

export function clear_po_logs_packers() {
  return dispatch => {
    dispatch(loadPackerPoLogs([]));
  };
}

export function clear_edit_po_logs() {
  return dispatch => {
    dispatch(loadEditPoLogs([]));
  };
}

export function clear_po_log_order() {
  return dispatch => {
    dispatch(loadPoLogOrder(null));
  };
}

export function render_po_logs_buttons(tabs) {
  return {
    type: LOAD_PO_LOGS_TABS,
    payload: tabs,
  };
}

export function loadPoLogOrder(payload) {
  return {
    type: LOAD_PO_LOG_ORDER,
    payload,
  };
}

// need to be replaced with loadPologs
export function loadOrder(order) {
  return {
    type: LOAD_ORDER,
    payload: order,
  };
}

export function loadPoLogs(poLogs) {
  return {
    type: LOAD_PO_LOGS,
    payload: poLogs,
  };
}

export function loadGrinderPoLogs(poLogs) {
  return {
    type: LOAD_GRINDER_PO_LOGS,
    payload: poLogs,
  };
}
export function loadPackerPoLogs(poLogs) {
  return {
    type: LOAD_PACKER_PLANT_PO_LOGS,
    payload: poLogs,
  };
}
export function loadEditPoLogs(poLogs) {
  return {
    type: LOAD_EDIT_PO_LOGS,
    payload: poLogs,
  };
}

export function replaceOrder(pending_order) {
  return {
    type: REPLACE_ORDER,
    payload: pending_order,
  };
}

export function refreshOrders(pending_order) {
  return {
    type: REFRESH_ORDERS,
    payload: pending_order,
  };
}

export function errorOrder(error) {
  return {
    type: ORDER_ERROR,
    payload: error,
  };
}
