import React from 'react';

import ClassicLayoutContainer from './layoutContainers/ClassicLayoutContainer';
import NextGenLayoutContainer from './layoutContainers/NextGenLayoutContainer';

const DynamicLayoutContainer = props => {
  if (props.nextGen) {
    // Clears grey background associated with old skin
    document.body.classList.remove('m-content--skin-light2');
    return <NextGenLayoutContainer {...props}>{props.children}</NextGenLayoutContainer>;
  }
  return <ClassicLayoutContainer {...props}>{props.children}</ClassicLayoutContainer>;
};

export default DynamicLayoutContainer;
