import React, { useState, useEffect } from 'react';
import {
  VStack,
  Input,
  Button,
  Box,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { addTwoFactorAuthMethod, deleteTwoFactorAuthMethod, sendOtpEmail } from '../../services/two-factor-auth';

// eslint-disable-next-line react/prop-types
export default function TwoFactorMethodModal({ user, method, isActive, handleChangeActiveStatus }) {
  const [isPasswordVisible, setPasswordVisible] = useState(true);
  const [methodStatus, setMethodStatus] = useState(isActive);
  const [password, setPassword] = useState(null);
  const [success, setSuccess] = useState(false);

  const { isOpen, onOpen, onClose: closeModal } = useDisclosure();

  useEffect(() => {
    onOpen();
  }, []);

  const onClose = async () => {
    if (method === 'email' && !isActive) await sendOtpEmail(user.email);
    handleChangeActiveStatus(methodStatus, success);
  };

  const confirmPassword = async () => {
    if (isActive) {
      // deactivate/delete
      const deleted = await deleteTwoFactorAuthMethod(user.token, method, password);
      if (deleted) {
        setPasswordVisible(false);
        setMethodStatus(!isActive);
        setSuccess(true);
      }
    } else {
      // activate / add
      const added = await addTwoFactorAuthMethod(user.token, method, password);
      if (added) {
        setPasswordVisible(false);
        setMethodStatus(!isActive);
        setSuccess(true);
      }
    }
  };
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">
          {!isActive && (
            <Text as="h2" fontSize="16px">
              Activate 2FA Authentication
            </Text>
          )}
          {isActive && (
            <Text as="h2" fontSize="16px">
              Deactivate 2FA Authentication
            </Text>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isPasswordVisible && (
            <FormControl>
              <FormLabel>Confirm your account password</FormLabel>
              <Input
                type="password"
                autoComplete="off"
                placeholder="Password"
                name="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </FormControl>
          )}
          {success && methodStatus && (
            <VStack>
              <CheckCircleIcon h={8} w={8} color="green.500" />
              <Text as="h2" color="green.500" fontWeight="bold">
                Successfully Added
              </Text>
              <Text as="p">Please verify the 2FA method</Text>
            </VStack>
          )}
          {success && !methodStatus && (
            <VStack>
              <CheckCircleIcon h={8} w={8} color="green.500" />
              <Text as="h2" color="green.500" fontWeight="bold">
                Successfully Deactivated
              </Text>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          {isPasswordVisible && (
            <>
              <Button mr={3} onClick={closeModal}>
                Cancel
              </Button>
              <Button onClick={() => confirmPassword()} colorScheme="actionSecondary">
                Continue
              </Button>
            </>
          )}
          {success && methodStatus && (
            <>
              {!success && (
                <Button mr={3} onClick={closeModal}>
                  Cancel
                </Button>
              )}

              <Button onClick={() => onClose()} colorScheme="actionSecondary">
                Verify
              </Button>
            </>
          )}
          {success && !methodStatus && (
            <Button onClick={() => onClose()} colorScheme="actionSecondary">
              Done
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
