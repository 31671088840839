import axios from 'axios';
import Constants from '../Constants';

export const BASE_URL = `${Constants.URL}strategy-prices`;

export async function fetchStrategyPricesRequest() {
  const response = await axios.get(`${BASE_URL}`);
  return response.data;
}

export async function postStrategyPriceRequest(strategyPrice) {
  const response = await axios.post(`${BASE_URL}`, strategyPrice);
  return response.data;
}

export async function patchStrategyPriceRequest(strategyPrice) {
  const response = await axios.patch(`${BASE_URL}/${strategyPrice.id}`, strategyPrice);
  return response.data;
}

export async function deleteStrategyPriceRequest(strategyPrice) {
  await axios.delete(`${BASE_URL}/${strategyPrice.id}`);
  return true;
}
