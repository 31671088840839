import { renderButton } from '../../../../lib/formLoader';

const footerSection = {
  key: 'footer',
  mb: '50px',
  mr: '50px',
  display: 'flex',
  justifyContent: 'flex-end',
  children: [
    {
      renderComponent: renderButton,
      colorScheme: 'actionPrimary',
      text: 'Save',
      type: 'submit',
      onClick: (e, context) => {
        context.submitForm();
      },
    },
  ],
};

export default footerSection;
