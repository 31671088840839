// TODO: can we get this data some other way?
/**
 * Determine if an order is for an Angus beef product
 * @param {Order} order
 */
export const isOrderAngus = order => order.fmg_angus;
/**
 * Determines if an order is domestic within a specified market
 * @param {*} activeMarket
 */
export const isOrderDomestic = activeMarket => order => {
  if (activeMarket.includedCountries) {
    return activeMarket.includedCountries.some(x => order.packer_plant_country === x);
  }
  return order.packer_plant_country === activeMarket.key;
};

/**
 * Converts date strings from the API into epochs
 * @param {string} dateString In the format "3/4/20" (or "d/M/yy")
 */
export const dateStringToEpoch = dateString => new Date(dateString).valueOf();

/**
 * Higher-order-function to return a filter to select orders from
 * an unfiltered order array that pass the time period/dateMode check.
 * @param {*} timePeriod
 * @param {*} dateMode
 */
export const filterOrdersForDateMode = (timePeriod, dateMode) => order => {
  const selectedDate = order[dateMode];
  return selectedDate >= timePeriod[0] && selectedDate <= timePeriod[1];
};
export const isOrderFat = order => order.cl < 76;
export const isOrderLean = order => !isOrderFat(order);

export const filterRawOrdersByTimePeriod = (rawData, timePeriod) => {
  const isOrdered = filterOrdersForDateMode(timePeriod, 'order_date');
  const isDelivered = filterOrdersForDateMode(timePeriod, 'delivery_date');
  const filterByPeriod = order => isOrdered(order) || isDelivered(order);

  return {
    ...rawData,
    orders: {
      domestic: rawData.orders.domestic ? rawData.orders.domestic.filter(filterByPeriod) : [],
      export: rawData.orders.export ? rawData.orders.export.filter(filterByPeriod) : [],
    },
  };
};
