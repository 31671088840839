import {
  ORGANISATIONS_FETCHED,
  ORGANISATION_ADDED,
  ORGANISATION_EDIT,
  ORGANISATION_REMOVED,
} from '../actions/actions_organisations';

const organisations = (organisations = [], action) => {
  switch (action.type) {
    case ORGANISATIONS_FETCHED:
      return action.payload;
    case ORGANISATION_ADDED:
      var newOrganisations = JSON.parse(JSON.stringify(organisations));
      newOrganisations.push(action.payload);
      return newOrganisations;
    case ORGANISATION_EDIT:
      var newOrganisations = JSON.parse(JSON.stringify(organisations));
      return replaceOrganisation(newOrganisations, action.payload);
    case ORGANISATION_REMOVED:
      var newOrganisations = JSON.parse(JSON.stringify(organisations));
      return removeOrganisation(newOrganisations, action.payload);
    default:
      return organisations;
  }
};

function replaceOrganisation(allOrganisations, editedOrganisation) {
  const newOrganisations = [];
  allOrganisations.forEach(function (element) {
    if (element.id === editedOrganisation.id) {
      newOrganisations.push(editedOrganisation);
    } else {
      newOrganisations.push(element);
    }
  });
  return newOrganisations;
}

function removeOrganisation(allOrganisations, removedOrganisation) {
  const newOrganisations = [];
  allOrganisations.forEach(function (element) {
    if (element.id !== removedOrganisation.id) {
      newOrganisations.push(element);
    }
  });
  return newOrganisations;
}

export default organisations;
