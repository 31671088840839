import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Constants from '../../../Constants';
import { selectCurrentUser } from '../../../reducers/reducer_user';
import SelectField from '../../basic/SelectField';
import './TransferOrderModal.scss';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';

export default ({ children, purchaseOrderId, onTransferSuccess }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast();

    const { token } = useSelector(selectCurrentUser);
    const axiosConfig = useRef({ headers: { Authorization: `Token ${token}`, 'Content-Type': 'application/json', }, data: '{}' });
    const [grinders, setGrinders] = useState([]);
    const [grinder, setGrinder] = useState(null);
    const [purchaseOrder, setPurchaseOrder] = useState(null);
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        if (isOpen === false) {
            setGrinders([])
            setGrinder(null)
            setPurchaseOrder(null)
            setDocuments([])
        } else if (isOpen === true) {
            loadPurchaseOrder();
        }
    }, [isOpen]);

    const loadPurchaseOrder = () => axios.get(`${Constants.URL}order_management/purchase_orders/${purchaseOrderId}/netsuite_all`, axiosConfig.current).then(response => {
        const purchaseOrder = response.data
        setPurchaseOrder(purchaseOrder)
        loadGrinders({ destinationCountry: purchaseOrder.grinder.destination_country, productType: purchaseOrder.product_type });
    });

    const loadGrinders = ({ productType, destinationCountry }) => axios.get(`${Constants.URL}grinders?product_type=${productType}&destination_country=${destinationCountry}`, axiosConfig.current).then(response => setGrinders(response.data.map(g => ({ label: g.name, value: g.uid }))));

    const transferPurchaseOrder = () => {
        if (!grinder) {
            toast({
                title: 'Please select a grinder.',
                position: 'top-right',
                description: ``,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            return
        }
        axios.post(`${Constants.URL}order_management/purchase_orders/${purchaseOrderId}/transfer`, {
            grinder_uid: grinder,
            document_ids: documents
        }, axiosConfig.current).then(response => {
            const po = response.data
            onClose()
            const delayMS = 3000
            toast({
                title: `Purchase order transferred successfully!`,
                position: 'top-right',
                status: 'success',
                duration: delayMS,
                isClosable: true,
            });
            setTimeout(() => {
                window.open(`/order/search?internal_po_number=${po.internal_po_number}&po_lookup_field=internal_po_number&auto_open_po_card_purchase_order_id=${po.id}`, '_blank')
            }, delayMS)
            if (onTransferSuccess && typeof onTransferSuccess === 'function') {
                onTransferSuccess()
            }
        });
    }
    return (
        <>
            {!!children && typeof children === 'function' && children({ onOpen })}
            <Modal isOpen={isOpen} size='xl' onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent className="transfer-order-modal">
                    <ModalHeader className="header">Order Status Transferred</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack p={2} spacing={4}>
                            <FormControl>
                                <FormLabel htmlFor="select-grinder">Grinder</FormLabel>
                                <SelectField
                                    name="select-grinder"
                                    defaultValue={grinder}
                                    options={grinders}
                                    placeholder="Select"
                                    onChange={e => setGrinder(e.value)}
                                />
                            </FormControl>
                            {!!purchaseOrder && (purchaseOrder.documents || []).length > 0 && (
                                <FormControl>
                                    <FormLabel>Document to be transferred</FormLabel>
                                    <Flex flexWrap="wrap" >
                                        {purchaseOrder.documents.map(({ id, doc_type, url }) => (<Button key={id} m={2}
                                            onClick={() => {
                                                const newDocuments = [...documents]
                                                if (documents.includes(id)) {
                                                    setDocuments(newDocuments.filter(d => d !== id))
                                                } else {
                                                    setDocuments([...newDocuments, id])
                                                }
                                            }} rightIcon={documents.includes(id) ? <CloseIcon /> : <AddIcon />} colorScheme={documents.includes(id) ? 'blue' : 'gray'} variant='outline'>
                                            {doc_type}
                                        </Button>))}
                                    </Flex>
                                </FormControl>
                            )}
                        </Stack>
                    </ModalBody>
                    <ModalFooter className="footer">
                        <Button variant="outline" colorScheme="actionPrimary" mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button colorScheme="actionPrimary" onClick={transferPurchaseOrder}>Transfer</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )

}