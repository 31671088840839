import { ACCESS_LEVELS_FETCHED, DYNAMIC_NAVIGATIONS_FETCHED } from '../actions/actions_access_levels';

const access_levels = (state = false, action) => {
  switch (action.type) {
    case ACCESS_LEVELS_FETCHED:
      return action.payload;
    case DYNAMIC_NAVIGATIONS_FETCHED:
      return { dynamicNavigationLinks: action.payload };
    default:
      return state;
  }
};

export default access_levels;
