import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import { MdContentCopy } from 'react-icons/md';
import { CopyIcon, Icon as ChakraIcon } from '@chakra-ui/icons';

export const RowCopier = props => {
  const handleCopy = () => {
    const textToCopy = props.cols.reduce((acc, curr) => {
      if (curr.formattedValue) {
        const value = curr.formattedValue(props.row);
        if (acc) return value ? `${acc}\t${value}` : `${acc}\t `;
        return value ? `${value}` : `${acc}\t `;
      }
      return acc;
    }, '');
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <Tooltip label="copy row">
      <IconButton height="20px" width="20px" minWidth="20px" fontSize="14px" icon={<CopyIcon />} onClick={handleCopy} />
    </Tooltip>
  );
};

RowCopier.propTypes = {
  cols: PropTypes.arrayOf(PropTypes.object),
  row: PropTypes.objectOf(PropTypes.object),
};

const ClickToCopy = (props, style, className) => {
  const [isHovering, setIsHovering] = useState(false);
  const [copyText, setCopyText] = useState(props?.copyPrompt || 'Click to copy');

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
    setCopyText(props?.copyPrompt || 'Click to copy');
  };
  return (
    <Box {...style} className={className} onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut}>
      {props.children}
      {isHovering &&
        (props.children ? (
          <Tooltip label={copyText}>
            <IconButton
              _focus={{ outline: 'none' }}
              backgroundColor="transparent"
              fontSize="14px"
              icon={<ChakraIcon as={MdContentCopy} />}
              onClick={() => {
                navigator.clipboard.writeText(props.valueToCopy);
                setCopyText(props?.successMessage || 'Copied');
              }}
            />
          </Tooltip>
        ) : undefined)}
    </Box>
  );
};

ClickToCopy.propTypes = {
  valueToCopy: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  successMessage: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  copyPrompt: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
};

export default ClickToCopy;
