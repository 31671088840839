import { Typography, Radio, Tooltip, Tree } from 'antd';
import { format } from 'date-fns';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { descending } from 'd3';
import Icon from '@ant-design/icons';
import {
  formatChange,
  formatSI,
  formatDollars,
  findSiDefinition,
  singleMonthLetter,
  formatPercent,
} from '../../../../modules/format';
import BaseChart from '../../treasury/BaseChart/BaseChart';
import styles from './NCRs.module.css';
import { calculateRoundedMax } from '../../../../modules/data/rounded-max';
import { ChevronDown, ChevronRight } from '../../../../shared/Icons';
import yearOnYear, { getUniqueYears } from '../../../../modules/data/year-on-year';
import {
  CLAIM_DOLLARS_OPTIONS,
  INCIDENTS_OPTIONS,
  TIME_RANGE_OPTIONS,
  VOLUME_OPTIONS,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsConfig';
import {
  setQualityNCRsBreakdownMode,
  setQualityNCRsClaimDollars,
  setQualityNCRsIncidents,
  setQualityNCRsRegion,
  setQualityNCRsSelectedPackerPlantId,
  setQualityNCRsTimeRange,
  setQualityNCRsVolume,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsReducers';
import { MARKETS } from '../../../../config/markets';

const ClaimDollars = props => {
  const { ncrsData, singlePackerPlant, packerPlantsInPacker, market, selectedRegion } = props;
  const data = ncrsData.dollars;
  const countDifference = data.count - data.previousCount;
  const percentDifference = data.previousCount > 0 ? (countDifference / data.previousCount) * 100 : 100;
  const absoluteMax = Math.max.apply(
    Math,
    data.byPeriod.map(d => Math.abs(d.value))
  );
  const siDefinition = findSiDefinition(absoluteMax);
  const { market: marketParam } = useParams();

  const [dataType, setDataType] = React.useState('value');
  const dispatch = useDispatch();
  const handleNavigationToDetails = React.useCallback(() => {
    if (singlePackerPlant) {
      dispatch(setQualityNCRsSelectedPackerPlantId(singlePackerPlant.id));
      dispatch(setQualityNCRsRegion(singlePackerPlant.country));
    } else {
      dispatch(setQualityNCRsRegion(selectedRegion));
    }
    dispatch(setQualityNCRsIncidents(INCIDENTS_OPTIONS.TOTAL));
    dispatch(setQualityNCRsVolume(VOLUME_OPTIONS.PERCENT_OF_TOTAL));
    dispatch(setQualityNCRsClaimDollars(CLAIM_DOLLARS_OPTIONS.CURRENCY));
    dispatch(setQualityNCRsTimeRange(TIME_RANGE_OPTIONS.YOY));
    dispatch(setQualityNCRsBreakdownMode(null));
  }, [dispatch, selectedRegion, singlePackerPlant]);

  if (packerPlantsInPacker) {
    return (
      <PackerPerPackerPlantView
        {...props}
        marketParam={marketParam}
        handleNavigationToDetails={handleNavigationToDetails}
      />
    );
  }

  return (
    <div className={styles.QualityNcrs_claimDollars} data-packer={dataType === 'percentOfTotal'}>
      <Typography.Text className={styles.QualityNcrs_totalIncidents}>
        <Link
          to={`/analytic-dashboard/quality/${marketParam}/ncrs-details`}
          onClick={() => handleNavigationToDetails()}
        >
          <span>
            {selectedRegion ? MARKETS[selectedRegion].currency : market ? MARKETS[market.key].currency : ''}
            {formatDollars(2)(data.count)}
          </span>{' '}
          in claim dollars <Icon style={{ position: 'relative', top: -3, width: 14 }} component={ChevronRight} />
        </Link>
      </Typography.Text>
      {countDifference !== 0 ? (
        <p className={styles.QualityNcrs_difference}>
          <span className={styles.QualityNcrs_differenceArrow}>{formatChange('negative')(percentDifference)}</span>
          <strong>{Math.abs(Math.round(percentDifference * 10) / 10)}%</strong> (
          {formatDollars(2)(Math.abs(countDifference))} {`${countDifference < 0 ? 'fewer' : 'more'}`}) from previous
          month
        </p>
      ) : (
        <p className={styles.QualityNcrs_difference}>same as previous month</p>
      )}
      {singlePackerPlant && singlePackerPlant.isPacker && (
        <div style={{ width: '100%', textAlign: 'right' }}>
          <Radio.Group
            onChange={e => {
              setDataType(e.target.value);
            }}
            defaultValue={dataType}
            size="medium"
            className={styles.QualityNcrs_radio}
          >
            <Radio.Button key={1} value={'value'}>
              Claim dollars
            </Radio.Button>
            <Radio.Button key={2} value={'percentOfTotal'}>
              % of total claim dollars
            </Radio.Button>
          </Radio.Group>
        </div>
      )}
      {data && (
        <BaseChart
          barData={yearOnYear(
            data.byPeriod.map(x => ({
              interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
              value: x[dataType],
            }))
          )}
          columns={getUniqueYears(data.byPeriod.map(x => format(new Date(x.date), 'MMM yyyy')))}
          colors={{ value: '#959BB5' }}
          formatValues={v =>
            dataType === 'percentOfTotal' ? formatPercent(2)(v).replace('%', '') : formatSI(1, 1, siDefinition)(v)
          }
          formatTooltip={v => (dataType === 'percentOfTotal' ? formatPercent(2)(v) : formatDollars(2)(v))}
          formatIntervals={singleMonthLetter}
          splitIndex={-1}
          noCurrentLine={true}
          noLegend={true}
          ticksNumber={3}
          minMaxGenerator={(maxData, minData) => {
            return {
              maxVal: calculateRoundedMax(maxData),
              minVal: 0,
            };
          }}
          isLineChart={true}
          isYearOnYear={true}
          isSmall={true}
        />
      )}
    </div>
  );
};

const getTreeData = (data, dataType) => {
  if (!data) {
    return [];
  }

  const maxCount = Math.max(...data.map(x => x[dataType]));

  const row = (d, isSecondary, color) => {
    const bar = fieldName => {
      let title = '';
      title = `${dataType === 'claimAmountPercent' ? formatPercent(2)(d[dataType]) : formatDollars(2)(d[dataType])}`;
      return (
        <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={title}>
          <div
            style={{
              width: `${d[fieldName] === 0 ? 0 : (d[fieldName] * 100) / maxCount}%`,
              height: 10,
              backgroundColor: color,
              backgroundImage: `repeating-linear-gradient(-45deg, transparent, transparent 2px, rgba(255,255,255,.5) 2px, rgba(255,255,255,.5) 4px)`,
              marginTop: 0,
              opacity: 1,
            }}
          />
        </Tooltip>
      );
    };

    return (
      <div
        className={`${styles.QualityNcrs_tableRow} ${isSecondary ? styles.QualityNcrs_tableRowSecondary : ''}`}
        style={{ marginLeft: isSecondary ? -24 : 0 }}
      >
        <div
          style={{
            width: '35%',
            padding: '0 5px',
            paddingLeft: isSecondary ? 24 : 0,
          }}
        >
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {isSecondary ? d.category : d.packerPlantName}
          </div>
        </div>
        <div
          style={{
            width: '15%',
            padding: '0 5px',
            display: 'flex',
          }}
        >
          <div
            className={styles.QualityNcrs_tableRowValues}
            style={{
              width: '100%',
              fontWeight: !isSecondary && 'bold',
            }}
          >
            {dataType === 'claimAmountPercent' ? formatPercent(2)(d[dataType]) : formatDollars(2)(d[dataType])}
          </div>
        </div>
        <div
          style={{
            width: '50%',
            padding: '0 5px',
          }}
        >
          {bar(dataType)}
        </div>
      </div>
    );
  };

  return data
    .slice()
    .sort((a, b) => descending(a[dataType], b[dataType]))
    .slice(0, 5)
    .map((packerPlant, i) => {
      return {
        title: row(packerPlant, false, packerPlant.color || '#D3D5E0'),
        key: i + '-' + packerPlant.packerPlantId,
        children: packerPlant.byCategory?.map((category, j) => {
          return {
            title: row(category, true, 'rgba(11, 20, 53, 0.6)'),
            key: i + '-' + j + '-' + category.category,
          };
        }),
      };
    });
};

const PackerPerPackerPlantView = props => {
  const { ncrsData, singlePackerPlant, packerPlantsInPacker, marketParam, handleNavigationToDetails } = props;
  const data = ncrsData.volume;
  const [dataType, setDataType] = React.useState('claimAmountPercent');

  return (
    <div>
      <Typography.Text className={styles.QualityNcrs_totalIncidents}>
        <Link
          to={`/analytic-dashboard/quality/${marketParam}/ncrs-details`}
          onClick={() => handleNavigationToDetails()}
        >
          <span>{formatPercent(2)(data.percentageOfTotal)}</span> of {singlePackerPlant.name} supplied volume{' '}
          <Icon style={{ position: 'relative', top: -3, width: 14 }} component={ChevronRight} />
        </Link>
      </Typography.Text>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography.Text className={styles.QualityNcrs_label}>Largest impacted volume</Typography.Text>
        <div>
          <Radio.Group
            onChange={e => {
              setDataType(e.target.value);
            }}
            defaultValue={dataType}
            size="medium"
            className={styles.QualityNcrs_radio}
          >
            <Radio.Button key={1} value={'claimAmountPercent'}>
              % of {singlePackerPlant.name} claim dollars
            </Radio.Button>
            <Radio.Button key={2} value={'claimAmount'}>
              $
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
      {packerPlantsInPacker && (
        <Tree
          switcherIcon={<Icon component={ChevronDown} style={{ opacity: 0.6 }} />}
          selectable={false}
          treeData={getTreeData(packerPlantsInPacker, dataType)}
          blockNode={true}
        />
      )}

      <Link
        to={`/analytic-dashboard/quality/${marketParam}/ncrs-details`}
        onClick={() => handleNavigationToDetails()}
        className={styles.QualityNcrs_viewAll}
      >
        View all {singlePackerPlant.name} establishments
        <Icon style={{ position: 'relative', width: 14, top: 1 }} component={ChevronRight} />
      </Link>
    </div>
  );
};

export default ClaimDollars;
