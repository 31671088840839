import {
  BEEF_INPUT_INVENTORY_ITEMS_ADDED,
  BEEF_INPUT_INVENTORY_ITEMS_FETCHED,
} from '../actions/actions_input_product_inventory_items';

const input_product_inventory_items = (inventory_items = [], action) => {
  switch (action.type) {
    case BEEF_INPUT_INVENTORY_ITEMS_FETCHED:
      return action.payload;
    case BEEF_INPUT_INVENTORY_ITEMS_ADDED:
      var newInventoryItems = JSON.parse(JSON.stringify(inventory_items));
      newInventoryItems.push(action.payload);
      return newInventoryItems;
    default:
      return inventory_items;
  }
};

export default input_product_inventory_items;
