import { connect } from 'react-redux';

import SupportFormComponent from '../../components/support/SupportForm';

const mapStateToProps = state => {
  return {
    token: state.user.token,
    submitted: state.tickets.submitted,
    user: state.user,
    error: state.error,
  };
};

const SupportForm = connect(mapStateToProps)(SupportFormComponent);

export default SupportForm;
