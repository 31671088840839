import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NCRForm from '../Author/NCRForm';
import { connect } from 'react-redux';
import { convertToCamelCase } from '../../../helpers';
import { fetchGrinderNCR, resetNCREdit } from '../../../actions/action_ncrs';
import { isEmpty } from 'lodash';
import { checkIfIsAdminUser, checkIfIsGrinderUser, checkIfIsPackerUser } from '../../../utils';
import Constants from '../../../Constants';

const NCREdit = props => {
  const { match, dispatch, token, user, ncrs } = props;
  const isPackerUser = checkIfIsPackerUser(user);
  const isAdminUser = checkIfIsAdminUser(user);
  const isGrinderUser = checkIfIsGrinderUser(user);

  useEffect(() => {
    if (isPackerUser || isAdminUser || isGrinderUser) {
      dispatch(resetNCREdit());
      if (match.path.startsWith('/edit-incident')){
        dispatch(fetchGrinderNCR(match.params.id, token, Constants.NCR_TYPES.INCIDENT));
      } else {
        dispatch(fetchGrinderNCR(match.params.id, token));
      }
    }
  }, [match.params.id, isPackerUser, isAdminUser, isGrinderUser, token]);

  return !isEmpty(ncrs.currentNCR) ? (
    <NCRForm isPackerUserEditing={isPackerUser} isAdminUserEditing={isAdminUser} isGrinderUserEditing={isGrinderUser} />
  ) : null;
};

NCREdit.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  dispatch: PropTypes.func,
  token: PropTypes.string,
  user: PropTypes.objectOf(PropTypes.any),
  ncrs: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = state => {
  const result = {
    user: state.user,
    token: state.user.token,
    ncrs: state.ncrs,
  };
  return convertToCamelCase(result);
};

export default connect(mapStateToProps)(NCREdit);
