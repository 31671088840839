import React from 'react';
import { useSelector } from 'react-redux';
import { Select } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const PackerSelectBox = ({ onChange, value }) => {
  const packers = useSelector(state => state.food_safety_quality?.packers);

  return (
    <Select name="rs_associated_id" placeholder="Select" height="40px" onChange={onChange} value={value}>
      {packers?.map(packer => (
        <option key={packer.id} value={packer.id}>
          {packer.name}
        </option>
      ))}
    </Select>
  );
};

PackerSelectBox.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default PackerSelectBox;
