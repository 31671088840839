import get from 'lodash/get';

import { ERROR_SEND_EMAIL, CONFIRM_EMAIL_SENT, INITIATE_EMAIL_SEND, RESET_EMAIL_MODAL } from '../actions/actions_email';

const INITIAL_STATE = {
  sendEmailError: null,
  isSendingEmail: false,
};

const emailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ERROR_SEND_EMAIL: {
      return {
        ...state,
        sendEmailError: get(action, 'error.message') || get(action, 'error'),
        isSendingEmail: false,
      };
    }
    case CONFIRM_EMAIL_SENT: {
      return {
        ...state,
        isSendingEmail: false,
        sendEmailError: null,
      };
    }
    case INITIATE_EMAIL_SEND: {
      return {
        ...state,
        isSendingEmail: true,
        sendEmailError: null,
      };
    }
    case RESET_EMAIL_MODAL: {
      return {
        ...state,
        isSendingEmail: false,
        sendEmailError: null,
      };
    }
    default:
      return state;
  }
};

export default emailReducer;
