import { removeObjectFromArray, replaceObjectInArray } from '../helpers';
import {
  COLD_STORE_ADDED,
  COLD_STORE_REMOVED,
  COLD_STORE_UPDATED,
  COLD_STORES_FETCHED,
} from '../actions/actions_cold_stores';

const cold_stores = (cold_stores = [], action) => {
  const coldStoresCopy = JSON.parse(JSON.stringify(cold_stores));

  switch (action.type) {
    case COLD_STORES_FETCHED:
      return action.payload;
    case COLD_STORE_ADDED:
      coldStoresCopy.push(action.payload);
      return coldStoresCopy;
    case COLD_STORE_UPDATED:
      return replaceObjectInArray(coldStoresCopy, action.payload);
    case COLD_STORE_REMOVED:
      return removeObjectFromArray(coldStoresCopy, action.payload);
    default:
      return cold_stores;
  }
};

export default cold_stores;
