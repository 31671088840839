import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Local
import SelectField from '../../../basic/SelectField';
import ShipmentPortMark from '../../../../containers/orders/BillOfLading';
import { Box, Input, Text, VStack, Button, HStack, IconButton, Wrap, FormLabel } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import CustomFormControl from '../../../core/CustomFormControl';
import DateTimeWithTrappedFocus from '../../../core/DateTimeWithTrappedFocus';
import Tooltip from '../../../quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const propTypes = {
  addMark: PropTypes.func,
  containers: PropTypes.arrayOf(PropTypes.object),
  deleteMark: PropTypes.func,
  editMode: PropTypes.bool,
  form: PropTypes.shape({
    billOfLading: PropTypes.shape({
      bolNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      bookingReferenceNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      containerNumber: PropTypes.string,
      containerTemperature: PropTypes.string,
      sealNumber: PropTypes.string,
      exportDeclarationNumber: PropTypes.string,
      grossShippedWeight: PropTypes.string,
      shipmentPortMarks: PropTypes.arrayOf(PropTypes.shape({})),
      bolDate: PropTypes.Datetime,
    }),
    loadContainerDetails: PropTypes.string,
  }),
  handleChangeObjectList: PropTypes.func,
  handleGrossShippedWeightBlur: PropTypes.func,
  handleGrossShippedWeightChange: PropTypes.func,
  handleRemoveObjectListItem: PropTypes.func,
  onObjectValueChange: PropTypes.func,
};

const BillOfLading = ({
  addMark,
  containers,
  deleteMark,
  editMode,
  form,
  handleChangeObjectList,
  handleGrossShippedWeightBlur,
  handleGrossShippedWeightChange,
  handleRemoveObjectListItem,
  onObjectValueChange,
}) => {
  const [state, setState] = useState({
    billOfLadingIsExpanded: true,
    shipmentPortIsExpanded: true,
  });

  const containerOptions = containers.map(el => ({ value: el.value, label: el.value }));

  return (
    <VStack spacing="27px" align="stretch">
      <VStack spacing="11px" align="stretch">
        <HStack justifyContent="space-between" color="accent.one.default" width="150px">
          <Text as="p" fontWeight="medium" color="inherit">
            Bill Of Lading
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="18px"
            icon={state.billOfLadingIsExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
            onClick={() => setState({ ...state, billOfLadingIsExpanded: !state.billOfLadingIsExpanded })}
          />
        </HStack>
        {state.billOfLadingIsExpanded && (
          <VStack spacing="25px" align="stretch">
            <Wrap spacing="56px" width="100%">
              <CustomFormControl width="305px">
                <FormLabel htmlFor="bolNumber">
                  BOL Number&nbsp;
                  <Tooltip
                    content="The Bill of Lading (BOL) is a legal document issued by a carrier to acknowledge receipt of cargo."
                    placement="right"
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <Input
                  backgroundColor="white"
                  name="bolNumber"
                  value={form?.billOfLading?.bolNumber || ''}
                  isDisabled={!editMode}
                  className="form-control"
                  onChange={e => onObjectValueChange('billOfLading', 'bolNumber', e.target.value)}
                />
              </CustomFormControl>
              <CustomFormControl width="305px">
                <FormLabel htmlFor="bol-date">
                  BOL Date&nbsp;
                  <Tooltip content="Provides the date when the Bill of Lading (BOL) was issued." placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <DateTimeWithTrappedFocus
                  id="bol-date"
                  inputProps={{ ...(form?.billOfLading?.bolDate || null), disabled: !editMode }}
                  closeOnSelect
                  value={form?.billOfLading?.bolDate}
                  /* eslint-disable-next-line no-underscore-dangle */
                  onChange={e =>
                    onObjectValueChange('billOfLading', 'bolDate', e._isAMomentObject ? e.format('YYYY-MM-DD') : null)
                  }
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </CustomFormControl>
              <CustomFormControl width="305px">
                <FormLabel htmlFor="booking-reference-number">
                  Booking Reference Number&nbsp;
                  <Tooltip
                    content="The Booking Reference Number is a unique identifier provided by the shipping line to track."
                    placement="right"
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <Input
                  backgroundColor="white"
                  name="booking-reference-number"
                  value={form?.billOfLading?.bookingReferenceNumber || ''}
                  isDisabled={!editMode}
                  className="form-control"
                  onChange={e => onObjectValueChange('billOfLading', 'bookingReferenceNumber', e.target.value)}
                />
              </CustomFormControl>
            </Wrap>
            <Wrap spacing="56px" width="100%">
              <CustomFormControl width="305px">
                <FormLabel htmlFor="loadContainerDetails">
                  Container Type&nbsp;
                  <Tooltip content="Specifies the type of container used for shipping." placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <SelectField
                  isDisabled={!editMode}
                  name="loadContainerDetails"
                  defaultValue={form?.loadContainerDetails || ''}
                  options={containerOptions}
                  onChange={e => onObjectValueChange(null, 'loadContainerDetails', e ? e.value : '')}
                />
              </CustomFormControl>
              <CustomFormControl width="305px">
                <FormLabel htmlFor="container-number">
                  Container Number&nbsp;
                  <Tooltip content="Provides the unique container number." placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <Input
                  backgroundColor="white"
                  name="container-number"
                  value={form?.billOfLading?.containerNumber || ''}
                  isDisabled={!editMode}
                  className="form-control"
                  onChange={e => onObjectValueChange('billOfLading', 'containerNumber', e.target.value)}
                />
              </CustomFormControl>
              <CustomFormControl width="305px">
                <FormLabel htmlFor="container-temperature">
                  Container Temperature&nbsp;
                  <Tooltip content="Specifies the required temperature for the container." placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <Input
                  backgroundColor="white"
                  name="container-temperature"
                  value={form?.billOfLading?.containerTemperature || ''}
                  isDisabled={!editMode}
                  className="form-control"
                  onChange={e => onObjectValueChange('billOfLading', 'containerTemperature', e.target.value)}
                />
              </CustomFormControl>
            </Wrap>
            <Wrap spacing="56px" width="100%">
              <CustomFormControl width="305px">
                <FormLabel htmlFor="seal-number">
                  Seal Number&nbsp;
                  <Tooltip content="Provides the seal number used to secure the container." placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <Input
                  backgroundColor="white"
                  name="seal-number"
                  value={form?.billOfLading?.sealNumber || ''}
                  isDisabled={!editMode}
                  className="form-control"
                  onChange={e => onObjectValueChange('billOfLading', 'sealNumber', e.target.value)}
                />
              </CustomFormControl>
              <CustomFormControl width="305px">
                <FormLabel htmlFor="export-declaration-number">
                  Export Declaration Number&nbsp;
                  <Tooltip
                    content="The Export Declaration Number is a required identifier for customs clearance"
                    placement="right"
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <Input
                  backgroundColor="white"
                  name="export-declaration-number"
                  value={form?.billOfLading?.exportDeclarationNumber || ''}
                  isDisabled={!editMode}
                  className="form-control"
                  onChange={e => onObjectValueChange('billOfLading', 'exportDeclarationNumber', e.target.value)}
                />
              </CustomFormControl>
              <HStack spacing="11px" width="fit-content">
                <CustomFormControl width="305px">
                  <FormLabel htmlFor="gross-shipped-weight">
                    Gross Shipped Weight&nbsp;
                    <Tooltip
                      content="Records the total weight of the shipment in kilograms for accurate documentation"
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                  <Input
                    backgroundColor="white"
                    name="gross-shipped-weight"
                    type="text"
                    value={form?.billOfLading?.grossShippedWeight || ''}
                    isDisabled={!editMode}
                    className="form-control"
                    onChange={e => handleGrossShippedWeightChange('billOfLading', 'grossShippedWeight', e.target.value)}
                    onBlur={e => handleGrossShippedWeightBlur('billOfLading', 'grossShippedWeight', e.target.value)}
                  />
                </CustomFormControl>
                <Text as="p" fontWeight="medium" marginTop="24px !important">
                  kgs
                </Text>
              </HStack>
            </Wrap>
          </VStack>
        )}
      </VStack>
      <VStack spacing="11px" align="stretch">
        <HStack justifyContent="space-between" color="accent.one.default" width="180px">
          <Text as="p" fontWeight="medium" color="inherit">
            Shipment/Port Marks
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="18px"
            icon={state.shipmentPortIsExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
            onClick={() => setState({ ...state, shipmentPortIsExpanded: !state.shipmentPortIsExpanded })}
          />
        </HStack>
        {state.shipmentPortIsExpanded &&
          (form?.billOfLading?.shipmentPortMarks.length ? (
            form?.billOfLading?.shipmentPortMarks.map((spMark, index) => {
              return (
                <ShipmentPortMark
                  key={`shipment-port-mark-${index}`}
                  item={spMark}
                  form={form}
                  editMode={editMode}
                  index={index}
                  handleChangeObjectList={handleChangeObjectList}
                  handleRemoveObjectListItem={handleRemoveObjectListItem}
                  onDeleteMark={deleteMark}
                  onAddMark={addMark}
                  count={form?.billOfLading?.shipmentPortMarks.length}
                />
              );
            })
          ) : (
            <Text as="p">No shipment/Port marks</Text>
          ))}
      </VStack>
      <Box>
        <Button
          colorScheme="actionPrimary"
          borderRadius={2}
          onClick={addMark}
          isDisabled={!editMode}
          hidden={!editMode}
        >
          Add Shipment/Port Mark
        </Button>
      </Box>
    </VStack>
  );
};

BillOfLading.propTypes = propTypes;
export default BillOfLading;
