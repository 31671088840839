import { createSelector } from '@reduxjs/toolkit';
import { selectQualityMarketPackerPlants } from '../../quality-packer-plants/selectors/selectQualityMarketPackerPlants';
import { selectQualityData, selectQualityPackerPlant } from './selectBasicQualityData';

export const selectQualityASLUpdates = createSelector(
  selectQualityData,
  selectQualityPackerPlant,
  selectQualityMarketPackerPlants,
  (data, selectedPackerPlant, packerPlants) => {
    if (!data || !selectedPackerPlant || !packerPlants) return null;
    const { aslUpdates } = data;

    if (!aslUpdates) return undefined;
    const pc = selectedPackerPlant.isPacker
      ? packerPlants.filter(x => x.isPartOf === selectedPackerPlant.id)
      : packerPlants.filter(x => x.id.length > 3);

    // const newSuppliersOnboarding = aslUpdates.newSuppliersOnboarding.map(x => {
    //   const name = pc.find(p => p.id === x.packerPlantId)?.name;
    //   return {
    //     ...x,
    //     packerPlantName: name,
    //   };
    // });

    return { ...aslUpdates, packerPlantsCount: pc.length };
  }
);
