import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import Constants from '../../../../Constants';
import DatePicker from '../../DatePicker';

const propTypes = {
  dateFormat: PropTypes.string,
  name: PropTypes.string,
  timeFormat: PropTypes.string,
  isValidDate: PropTypes.func,
};

const DateField = ({ name, timeFormat, dateFormat, isValidDate }) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const onChange = (type, value) => {
    setValue(value);
  };

  return (
    <DatePicker
      id={name}
      value={meta.value}
      onChange={e => {
        const val = e && typeof e === 'string' && e.length > 9 ? moment(e, Constants.DEFAULT_READ_DATE_FORMAT) : e;
        // eslint-disable-next-line no-underscore-dangle
        onChange(name, val._isValid ? val.format(typeof timeFormat === 'string' ? timeFormat : dateFormat) : e);
      }}
      isValidDate={isValidDate}
      placeholderText="Select"
    />
  );
};

DateField.propTypes = propTypes;
export default DateField;
