/**
 * Scope
 * {
 *   page: 'markets' | 'treasury'
 *   module: string
 *   focus: 'au' | 'us'  ...
 *   timeScale: 'month' | 'quarter' //TODO: Remove timeScale when ui can support multiple per interval
 * }
 */

/**
 * Time Range
 *
 * [Date, Date]
 */

/**
 * Annotation
 *
 * {
 *   content: string
 *   timestamp: Date
 *   page: 'markets' | 'treasury'
 *   module: string
 *   focus: 'au' | 'us'  ...
 *   timeScale: 'month' | 'quarter' //TODO: Remove timeScale when ui can support multiple per interval
 * }
 * */
import axios from 'axios';
import Constants from '../Constants';
import queryString from 'query-string';

const BASE_URL = `${Constants.URL}analytic-dashboard`;

function getAnnotationTag({ page, module, focus }) {
  return `${page}_${module}_${focus}`;
}

function getAnnotationTags(annotation) {
  return [{ slug: getAnnotationTag(annotation) }];
}

function getAnnotationContent({ content }) {
  return `${content}`;
}

function getAnnotationTimeScale({ timeScale }) {
  return `${timeScale}`;
}

function getAnnotationTimestamp({ timestamp }) {
  return `${timestamp.toISOString()}`;
}

function getAnnotationFromAPIResponse({ content, id, tags, time_scale, timestamp }) {
  if (!tags || !tags.length) {
    return {};
  }
  const slug_array = tags[0].slug.split('_');
  const [page, module, focus] = slug_array;
  const annotation = {
    timeScale: time_scale,
    content,
    id,
    page,
    module,
    focus,
    timestamp: new Date(timestamp),
  };
  return annotation;
}

export async function fetchAnnotationsRequest(scope, timeRange) {
  const params = {
    tag: getAnnotationTag(scope),
    time_scale: getAnnotationTimeScale(scope),
  };
  if (timeRange) {
    const [start, end] = timeRange;
    params.start = start;
    params.end = end;
  }
  const query = queryString.stringify(params);
  const response = await axios.get(`${BASE_URL}/annotations?${query}`);
  const results = response.data.map(annotation => getAnnotationFromAPIResponse(annotation));
  return results;
}

export async function createAnnotationRequest(newAnnotation) {
  const paramsValues = {
    content: getAnnotationContent(newAnnotation),
    time_scale: getAnnotationTimeScale(newAnnotation),
    tags: getAnnotationTags(newAnnotation),
    timestamp: getAnnotationTimestamp(newAnnotation),
  };
  const response = await axios.post(`${BASE_URL}/annotations`, { ...paramsValues });
  return getAnnotationFromAPIResponse(response.data);
}

export async function updateAnnotationRequest(annotation) {
  const paramsValues = {
    content: getAnnotationContent(annotation),
  };
  const response = await axios.patch(`${BASE_URL}/annotations/${annotation.id}`, { ...paramsValues });
  return getAnnotationFromAPIResponse(response.data);
}

export async function deleteAnnotationRequest(annotation) {
  const response = await axios.delete(`${BASE_URL}/annotations/${annotation.id}`, { data: [] });
  return true;
}
