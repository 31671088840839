import isEmpty from 'lodash/isEmpty';
import api from '../api';

const DOWNLOAD_TIMEOUT = 500; // miliseconds

export function getDocVersion(documentsList, docId) {
  if (isEmpty(documentsList) || !docId) return '';
  return documentsList.find(doc => doc.id === docId).version;
}

export async function downloadFromS3Link(link) {
  // Getting a S3 signed URL.
  const result = await api.post('/signed_url', [{ url: link }]);
  if (result.status !== 200) return;
  const data = await result.json();
  if (!data || !data[0] || !data[0].signed_url) return;
  const { signed_url: signedUrl } = data[0];

  const windowReference = window.open();
  if (!signedUrl) return;
  await new Promise(resolve =>
    setTimeout(() => {
      if (windowReference != null) {
        windowReference.location = signedUrl;
        windowReference.focus();
      }

      resolve();
    }, DOWNLOAD_TIMEOUT)
  );
}

export async function previewFromS3Link(link) {
  try {
    // Getting a S3 signed URL.
    const result = await api.post('/signed_url', [{ url: link }]);
    if (result.status !== 200) return null;
    const data = await result.json();
    if (!data || !data[0] || !data[0].signed_url) return null;
    const { signed_url: signedUrl } = data[0];
    return signedUrl;
  } catch (error) {
    return null;
  }
}

export async function previewFromS3LinkBlob(link, fileType) {
  try {
    // Getting a S3 signed URL.
    const result = await api.post('/signed_url', [{ url: link }], {});
    if (result.status !== 200) return null;
    const data = await result.json();
    if (!data || !data[0] || !data[0].signed_url) return null;
    const { signed_url: signedUrl } = data[0];

    const blob = await (await fetch(signedUrl, {})).blob();
    const file = new File([blob], 'attachment', {
      type: fileType || blob.type,
    });
    return URL.createObjectURL(file);
  } catch (error) {
    return null;
  }
}
