import React from 'react';
import PropTypes from 'prop-types';
import SelectField from '../../../../basic/SelectField';
import FECSelection from '../../FECSelection';
import { Box, Wrap, VStack, FormLabel, HStack, Switch, IconButton } from '@chakra-ui/react';
import CustomFormControl from '../../../../core/CustomFormControl';
import { withInternationalOrderFormContext } from '../../InternationalOrderForm/InternationalOrderFormContext';
import POFormFieldLabel from '../POFormFieldLabel';
import { isEqual } from 'lodash';
import { getOr } from '../../../../../helpers';
import Tooltip from '../../../../quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const propTypes = {
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    })
  ),
  incoTerms: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    })
  ),
  fecs: PropTypes.arrayOf(PropTypes.object),

  unitsOfMeasure: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  handleChangeValue: PropTypes.func,
  onBooleanValueChange: PropTypes.func,
  editMode: PropTypes.bool,
  state: {
    form: PropTypes.shape({
      buyCurrency: PropTypes.string,
      buyIncoterms: PropTypes.string,
      buyUnitOfMeasureId: PropTypes.n,
      fec: PropTypes.instanceOf(Object),
      halal: PropTypes.bool,
      mtc: PropTypes.bool,
      sellCurrency: PropTypes.string,
      sellIncoterms: PropTypes.string,
      sellUnitOfMeasureId: PropTypes.number,
    }),
    requiredFields: PropTypes.shape({
      form: PropTypes.arrayOf(PropTypes.string),
    }),
  },
  validationErrors: PropTypes.shape({
    requiredFields: PropTypes.instanceOf(Object),
    dates: PropTypes.bool,
  }),
  showToggles: PropTypes.bool,
};

const InternationalOrderDetails = props => {
  const { unitsOfMeasure, incoTerms, fecs, currencies, onBooleanValueChange, editMode, validationErrors, showToggles } =
    props;
  const onChangeValue = props.handleChangeValue ? props.handleChangeValue : props.onChangeValue;
  const { form } = props.state ? props.state : props;

  const requiredFields = getOr(props, 'requiredFields', getOr(validationErrors, 'requiredFields', []));

  const requiredFieldsForm = getOr(requiredFields, 'form', []);

  const uomOptions = unitsOfMeasure.map(el => {
    return { value: el.id, label: el.name };
  });

  const incoTermsOptions = incoTerms.map(el => {
    return { value: el.value, label: el.value };
  });

  const currenciesOptions = currencies.map(el => {
    return { value: el.value, label: el.value };
  });

  return (
    <VStack spacing="39px" align="stretch">
      <Wrap spacing="29px">
        <CustomFormControl>
          <FormLabel color="accent.one.default">INCO Terms</FormLabel>
          <HStack>
            <VStack align="stretch" width="100%">
              <POFormFieldLabel
                label="Buy"
                htmlFor="select-buyIncoTerms"
                fieldName="buyIncoterms"
                tooltip="Specifies international commercial terms for buying (CIF, DAP, DCT, FAS, FOB)."
              />
              <SelectField
                isDisabled={!editMode}
                name="select-buyIncoTerms"
                defaultValue={form.buyIncoterms}
                options={incoTermsOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                className={requiredFieldsForm.includes('buyIncoterms') ? 'error' : ''}
                onChange={e => onChangeValue('buyIncoterms', e)}
              />
            </VStack>
            <VStack align="stretch" width="100%">
              <POFormFieldLabel
                label="Sell"
                htmlFor="select-sellIncoTerms"
                fieldName="sellIncoterms"
                tooltip="Specifies international commercial terms for selling (CIF, DAP, DCT, FAS, FOB)"
              />
              <SelectField
                isDisabled={!editMode}
                name="select-sellIncoTerms"
                defaultValue={form.sellIncoterms}
                options={incoTermsOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                className={requiredFieldsForm.includes('sellIncoterms') ? 'error' : ''}
                onChange={e => onChangeValue('sellIncoterms', e)}
              />
            </VStack>
          </HStack>
        </CustomFormControl>
        <CustomFormControl>
          <FormLabel color="accent.one.default">UOM</FormLabel>
          <HStack>
            <VStack align="stretch" width="100%">
              <POFormFieldLabel
                label="Buy"
                htmlFor="select-buyUnitOfMeasureId"
                fieldName="buyUnitOfMeasureId"
                tooltip="Specifies the unit of measure for buying (BIN, CTN, KGS, LBS)."
              />
              <SelectField
                isDisabled={!editMode}
                name="select-buyUnitOfMeasureId"
                defaultValue={form.buyUnitOfMeasureId}
                options={uomOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                className={requiredFieldsForm.includes('buyUnitOfMeasureId') ? 'error' : ''}
                isClearable={false}
                onChange={e => onChangeValue('buyUnitOfMeasureId', e)}
              />
            </VStack>
            <VStack align="stretch" width="100%">
              <POFormFieldLabel
                label="Sell"
                htmlFor="select-sellUnitOfMeasureId"
                fieldName="sellUnitOfMeasureId"
                tooltip="Specifies the unit of measure for selling (BIN, CTN, KGS, LBS)."
              />
              <SelectField
                isDisabled={!editMode}
                name="select-sellUnitOfMeasureId"
                defaultValue={form.sellUnitOfMeasureId}
                options={uomOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                className={requiredFieldsForm.includes('sellUnitOfMeasureId') ? 'error' : ''}
                isClearable={false}
                onChange={e => onChangeValue('sellUnitOfMeasureId', e)}
              />
            </VStack>
          </HStack>
        </CustomFormControl>
        <CustomFormControl>
          <FormLabel color="accent.one.default">Currency</FormLabel>
          <HStack>
            <VStack align="stretch" width="100%">
              <POFormFieldLabel
                label="Buy"
                htmlFor="select-buyCurrency"
                fieldName="buyCurrency"
                tooltip="Specifies the currency for buying (AUD, NZD, USD)."
              />
              <SelectField
                isDisabled={!editMode}
                name="select-buyCurrency"
                defaultValue={form.buyCurrency}
                options={currenciesOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                className={requiredFieldsForm.includes('buyCurrency') ? 'error' : ''}
                onChange={e => onChangeValue('buyCurrency', e)}
                isClearable={false}
              />
            </VStack>
            <VStack align="stretch" width="100%">
              <POFormFieldLabel
                label="Sell"
                htmlFor="select-sellCurrency"
                fieldName="sellCurrency"
                tooltip="Specifies the currency for selling (AUD, NZD, USD)."
              />
              <SelectField
                isDisabled={!editMode}
                name="select-sellCurrency"
                defaultValue={form.sellCurrency}
                options={currenciesOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                className={requiredFieldsForm.includes('sellCurrency') ? 'error' : ''}
                onChange={e => onChangeValue('sellCurrency', e)}
                isClearable={false}
              />
            </VStack>
          </HStack>
        </CustomFormControl>
        {form.buyCurrency && form.sellCurrency && form.buyCurrency !== form.sellCurrency && (
          <CustomFormControl>
            <FormLabel color="accent.one.default">FEC</FormLabel>
            <Box>
              <FECSelection
                disabled={!editMode}
                value={form?.fec || null}
                options={fecs}
                onChange={e => onChangeValue('fec', e)}
                requiredFields={requiredFields}
                filter={option => option.buyCurrency === form.buyCurrency && option.sellCurrency === form.sellCurrency}
              />
            </Box>
          </CustomFormControl>
        )}
      </Wrap>
      {showToggles ? (
        <Wrap spacing="29px">
          <CustomFormControl>
            <HStack>
              <FormLabel htmlFor="halal-req" margin="0px" mr="24px">
                Halal Req&nbsp;
                <Tooltip content="Displays whether Halal certification is required (Yes or No)." placement="right">
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <FormLabel>No</FormLabel>
              <Switch
                size="lg"
                id="halal-req"
                name="halal"
                colorScheme="actionPrimary"
                isDisabled={!editMode}
                isChecked={form.halal}
                onChange={() => onBooleanValueChange('halal')}
              />
              <FormLabel>Yes</FormLabel>
            </HStack>
          </CustomFormControl>
          <CustomFormControl>
            <HStack>
              <FormLabel htmlFor="mtc-req" margin="0px" mr="24px">
                MTC&nbsp;
                <Tooltip
                  content="Displays whether MTC (Meat Transfer Certificate) is required (Yes or No)."
                  placement="right"
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <FormLabel>No</FormLabel>
              <Switch
                size="lg"
                id="mtc-req"
                name="mtc"
                colorScheme="actionPrimary"
                isDisabled={!editMode}
                isChecked={form.mtc}
                value={form.buyIncoterms}
                onChange={() => onBooleanValueChange('mtc')}
              />
              <FormLabel>Yes</FormLabel>
            </HStack>
          </CustomFormControl>
        </Wrap>
      ) : (
        ''
      )}
    </VStack>
  );
};

InternationalOrderDetails.propTypes = propTypes;

export default withInternationalOrderFormContext(React.memo(InternationalOrderDetails, isEqual));
