import Constants from '../Constants';

export const DELIST_PACKES_REPORT_FETCHED = 'DELIST_PACKES_REPORT_FETCHED';
export const DELIST_PACKES_REPORT_ERROR = 'DELIST_PACKES_REPORT_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadDelistPackersReport(formFields) {
  return {
    type: DELIST_PACKES_REPORT_FETCHED,
    payload: formFields,
  };
}

export function errorDelistPackersReport(error) {
  return {
    type: DELIST_PACKES_REPORT_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchDelistPackerReport(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    const url = `${Constants.URL}packers/delist_packers_report`;

    return fetch(url, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorDelistPackersReport))
      .then(json => dispatch(loadDelistPackersReport(json)))
      .catch(error => dispatch(errorDelistPackersReport(error)));
  };
}
