import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { endOfMonth, endOfYear } from 'date-fns';
import { TIME_SCALES } from '../../../../slices/quality/qualityConfig';
import styles from './FilterTimePeriod.module.css';

export default function FilterTimePeriod(props) {
  const { value, onChange, timeScale } = props;

  return (
    <div>
      <DatePicker
        className={styles.QualityFilterTimePeriod_datePicker}
        picker={timeScale === TIME_SCALES.ANNUALLY ? 'year' : 'month'}
        value={moment(value[0])}
        allowClear={false}
        format={m => {
          if (timeScale === TIME_SCALES.ANNUALLY) {
            return m.format('YYYY');
          } else {
            return m.format('MMMM, YYYY');
          }
        }}
        disabledDate={(current) => {
          return current && current > moment().subtract(1, "month");
        }}
        onChange={newPeriod => {
          if (!newPeriod) return;
          const startOfPeriod = newPeriod.toDate().getTime();
          if (timeScale === TIME_SCALES.ANNUALLY) {
            onChange([startOfPeriod, endOfYear(startOfPeriod)]);
          } else {
            onChange([startOfPeriod, endOfMonth(startOfPeriod)]);
          }
        }}
      />
    </div>
  );
}
