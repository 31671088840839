/* eslint-disable dot-notation */
import { createSelector } from '@reduxjs/toolkit';
import { selectPackerPlants } from '../../quality-packer-plants/selectors/selectBasicPackerPlants';
import { selectQualityData, selectQualityPackerPlant } from './selectBasicQualityData';

const sumVolume = data => data.reduce((sum, { volume }) => sum + volume, 0);

export const selectQualityPackerPlantScores = createSelector(
  selectQualityData,
  selectQualityPackerPlant,
  selectPackerPlants,
  (data, selectedPackerPlant, packerPlants) => {
    if (!data || !selectedPackerPlant || !packerPlants) return null;
    // eslint-disable-next-line dot-notation
    const sortedScores = data['packer-plant-scores']?.['packer_plant_scores']?.scores
      .map(x => {
        const name = packerPlants.find(p => p.id === x.packerPlantId)?.name;
        const isPartOf = packerPlants.find(p => p.id === x.packerPlantId)?.isPartOf;
        return {
          packer_plant_id: x.packerPlantId,
          packer_plant_name: name,
          score: x.score,
          volume: x.volume,
          isPartOf,
        };
      })
      .sort((a, b) => a.score - b.score);

    let buckets = [
      {
        bucket: -1,
        count: sortedScores.filter(x => x.score === -1).length,
        volume: sumVolume(sortedScores.filter(x => x.score === -1)),
      },
      {
        bucket: 0,
        count: sortedScores.filter(x => x.score === 0).length,
        volume: sumVolume(sortedScores.filter(x => x.score === 0)),
      },
      {
        bucket: 10,
        count: sortedScores.filter(x => x.score > 0 && x.score < 70).length,
        volume: sumVolume(sortedScores.filter(x => x.score < 70)),
      },
      {
        bucket: 70,
        count: sortedScores.filter(x => x.score >= 70 && x.score < 80).length,
        volume: sumVolume(sortedScores.filter(x => x.score >= 70 && x.score < 80)),
      },
      {
        bucket: 80,
        count: sortedScores.filter(x => x.score >= 80 && x.score < 90).length,
        volume: sumVolume(sortedScores.filter(x => x.score >= 80 && x.score < 90)),
      },
      {
        bucket: 90,
        count: sortedScores.filter(x => x.score >= 90 && x.score < 95).length,
        volume: sumVolume(sortedScores.filter(x => x.score >= 90 && x.score < 95)),
      },
      {
        bucket: 95,
        count: sortedScores.filter(x => x.score >= 95).length,
        volume: sumVolume(sortedScores.filter(x => x.score >= 95)),
      },
    ];

    let total = {
      count: sortedScores.length,
      volume: sumVolume(sortedScores),
    };

    if (!sortedScores.length) {
      buckets = [
        {
          bucket: -1,
          count: 1,
          volume: 1,
        },
        {
          bucket: 0,
          count: 1,
          volume: 1,
        },
        {
          bucket: 10,
          count: 1,
          volume: 1,
        },
        {
          bucket: 70,
          count: 1,
          volume: 1,
        },
        {
          bucket: 80,
          count: 1,
          volume: 1,
        },
        {
          bucket: 90,
          count: 1,
          volume: 1,
        },
        {
          bucket: 95,
          count: 1,
          volume: 1,
        },
      ];

      total = {
        count: 7,
        volume: 7,
      };
    }

    const sortedPackerPlantsFiltered = selectedPackerPlant.isPacker
      ? sortedScores.filter(x => x.isPartOf === selectedPackerPlant.id && x.score > 0)
      : sortedScores.filter(x => x.score > 0);

    const packerPlantsInPackerCount = sortedPackerPlantsFiltered.length;
    let topSliceNum;
    let bottomSliceNum;
    if (packerPlantsInPackerCount === 0 || packerPlantsInPackerCount === 1) {
      topSliceNum = 0;
      bottomSliceNum = 0;
    } else if (packerPlantsInPackerCount > 1 && packerPlantsInPackerCount < 6) {
      const isEven = !!packerPlantsInPackerCount / 2;
      topSliceNum = isEven ? packerPlantsInPackerCount / 2 : Math.round(packerPlantsInPackerCount / 2) + 1;
      bottomSliceNum = isEven ? packerPlantsInPackerCount / 2 : Math.round(packerPlantsInPackerCount / 2) - 1;
    } else {
      topSliceNum = -3;
      bottomSliceNum = 3;
    }

    const bottom = sortedPackerPlantsFiltered.slice(0, bottomSliceNum);
    const top = sortedPackerPlantsFiltered.slice(topSliceNum).sort((a, b) => b.score - a.score);

    return {
      bottom,
      top,
      buckets,
      total,
      allPackerPlants: sortedScores,
      scoreByPeriod: data['packer-plant-scores']?.['packer_plant_scores']?.scoreByPeriod,
      rankByPeriod: data['packer-plant-scores']?.['packer_plant_scores']?.rankByPeriod,
      packerPlantsInPacker: sortedPackerPlantsFiltered,
    };
  }
);
