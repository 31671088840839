import { removeObjectFromArray, replaceObjectInArray } from '../helpers';

import {
  REPLACE_ORDERS_COLDSTORE_RELEASED,
  REPLACE_ORDER_COLDSTORE_RELEASED,
  REMOVE_ORDER_COLDSTORE_RELEASED,
  ORDERS_COLDSTORE_RELEASED_FETCHED,
} from '../actions/actions_orders_coldstore_released';

const orders_coldstore_released = (orders_coldstore_released = [], action) => {
  const ordersColdstoreReleasedCopy = JSON.parse(JSON.stringify(orders_coldstore_released));
  switch (action.type) {
    case ORDERS_COLDSTORE_RELEASED_FETCHED:
      return action.payload;
    case REPLACE_ORDERS_COLDSTORE_RELEASED:
    case REPLACE_ORDER_COLDSTORE_RELEASED:
      return replaceObjectInArray(ordersColdstoreReleasedCopy, action.payload);
    case REMOVE_ORDER_COLDSTORE_RELEASED:
      return removeObjectFromArray(ordersColdstoreReleasedCopy, action.payload);

    default:
      return orders_coldstore_released;
  }
};

export default orders_coldstore_released;
