import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectField from '../../../basic/SelectField';
import { FormLabel, HStack, IconButton, Text, VStack, Wrap } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import CustomFormControl from '../../../core/CustomFormControl';
import DateTimeWithTrappedFocus from '../../../core/DateTimeWithTrappedFocus';

const propTypes = {
  containerDepots: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
  containerReturns: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
  editMode: PropTypes.bool,
  form: PropTypes.shape(),
  onObjectValueChange: PropTypes.func,
};

const FOBInformation = ({ form, onObjectValueChange, editMode, containerDepots, containerReturns }) => {
  const [state, setState] = useState({
    fobIsExpanded: true,
  });
  const containerDepotOptions = containerDepots.map(el => ({ value: el.value, label: el.value }));
  const containerReturnOptions = containerReturns.map(el => ({ value: el.value, label: el.value }));

  return (
    <VStack spacing="11px" align="stretch">
      <HStack justifyContent="space-between" color="accent.one.default" width="150px">
        <Text as="p" fontWeight="medium" color="inherit">
          FOB Information
        </Text>
        <IconButton
          variant="unstyled"
          minWidth="18px"
          height="fit-content"
          padding="0px"
          fontSize="18px"
          icon={state.fobIsExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
          onClick={() => setState({ ...state, fobIsExpanded: !state.fobIsExpanded })}
        />
      </HStack>
      {state.fobIsExpanded && (
        <Wrap spacing="56px" width="100%">
          <CustomFormControl width="305px">
            <FormLabel htmlFor="container-depot">Container Depot</FormLabel>
            <SelectField
              isClearable
              isDisabled={!editMode}
              name="containerDepot"
              defaultValue={form.exportDetails.containerDepot || ''}
              options={containerDepotOptions}
              onChange={e => onObjectValueChange('exportDetails', 'containerDepot', e ? e.value : '')}
            />
          </CustomFormControl>
          <CustomFormControl width="305px">
            <FormLabel htmlFor="container-return">Container Return</FormLabel>
            <SelectField
              isClearable
              isDisabled={!editMode}
              name="containerReturn"
              defaultValue={form.exportDetails.containerReturn || ''}
              options={containerReturnOptions}
              onChange={e => onObjectValueChange('exportDetails', 'containerReturn', e ? e.value : '')}
            />
          </CustomFormControl>
          <CustomFormControl width="305px">
            <FormLabel htmlFor="container-gate-in-date">Container Gate in Date</FormLabel>
            <DateTimeWithTrappedFocus
              id="container-gate-in-date"
              inputProps={{
                className: 'form-control m-input',
                disabled: !editMode,
              }}
              closeOnSelect
              value={form.exportDetails.containerGateInDate || null}
              onChange={e => {
                return onObjectValueChange(
                  'exportDetails',
                  'containerGateInDate',
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                );
              }}
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </CustomFormControl>
        </Wrap>
      )}
    </VStack>
  );
};

FOBInformation.propTypes = propTypes;

export default FOBInformation;
