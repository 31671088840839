import React, { Component } from 'react';
import { fetchConfigs } from '../../actions/actions_config';

class GrinderKPIDashboard extends Component {
  componentDidMount() {
    this.props.dispatch(fetchConfigs(this.props.token));
  }

  render() {
    const configsObject = {};
    this.props.configs.forEach(function (config) {
      configsObject[config.name] = config;
    });
    const grinderRef = this.props.currentGrinder.uid
      ? `${this.props.currentGrinder.uid.toUpperCase()}_`
      : `${this.props.currentGrinder.uid}_`;
    if (this.props.configs.length) {
      return (
        <div className="m-portlet__body">
          <div className="row">
            <div className="col-sm-12" style={{ marginTop: '30px' }} />
            <div className="row table-row" style={{ marginTop: '30px', height: '200px', marginLeft: '30px' }}>
              <div className="col-sm-4">
                <div className="table-container">
                  <h4>Product Age</h4>
                  <table className="kpi-table">
                    <tbody>
                      <tr>
                        <td>Avg Days</td>
                        <td>Target</td>
                        <td>Sys Avg</td>
                      </tr>
                      <tr>
                        <td>
                          <span style={{ color: '#E5B13C' }}>
                            {configsObject[`${grinderRef}product_age_avg_days`]
                              ? configsObject[`${grinderRef}product_age_avg_days`].value_float
                              : '0'}
                          </span>
                        </td>
                        <td>
                          <span style={{ color: '#63729D' }}>
                            {configsObject[`${grinderRef}product_age_target`]
                              ? configsObject[`${grinderRef}product_age_target`].value_float
                              : '0'}
                          </span>
                        </td>
                        <td>
                          <span style={{ color: '#63729D' }}>
                            {configsObject[`${grinderRef}product_age_sys_avg`]
                              ? configsObject[`${grinderRef}product_age_sys_avg`].value_float
                              : '0'}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="table-container">
                  <h4>On Time Deliveries</h4>
                  <table className="kpi-table">
                    <tbody>
                      <tr>
                        <td>Avg Days</td>
                        <td>Target</td>
                        <td>Sys Avg</td>
                      </tr>
                      <tr>
                        <td>
                          <span style={{ color: '#C24C2F' }}>
                            {configsObject[`${grinderRef}on_time_deliveries_avg_days`]
                              ? configsObject[`${grinderRef}on_time_deliveries_avg_days`].value_float
                              : '0'}
                            %
                          </span>
                        </td>
                        <td>
                          <span style={{ color: '#63729D' }}>
                            {configsObject[`${grinderRef}on_time_deliveries_target`]
                              ? configsObject[`${grinderRef}on_time_deliveries_target`].value_float
                              : '0'}
                            %
                          </span>
                        </td>
                        <td>
                          <span style={{ color: '#63729D' }}>
                            {configsObject[`${grinderRef}on_time_deliveries_sys_avg`]
                              ? configsObject[`${grinderRef}on_time_deliveries_sys_avg`].value_float
                              : '0'}
                            %
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="table-container">
                  <h4>Grinder Production</h4>
                  <table className="kpi-table">
                    <tbody>
                      <tr>
                        <td>Forecast Accuracy</td>
                        <td>Target</td>
                        <td>Sys Avg</td>
                      </tr>
                      <tr>
                        <td>
                          <span style={{ color: '#2b4966' }}>
                            {configsObject[`${grinderRef}grinder_production_forecast_accuracy`]
                              ? configsObject[`${grinderRef}grinder_production_forecast_accuracy`].value_float
                              : '0'}
                            %
                          </span>
                        </td>
                        <td>
                          <span style={{ color: '#63729D' }}>
                            {configsObject[`${grinderRef}grinder_production_target`]
                              ? configsObject[`${grinderRef}grinder_production_target`].value_float
                              : '0'}
                            %
                          </span>
                        </td>
                        <td>
                          <span style={{ color: '#63729D' }}>
                            {configsObject[`${grinderRef}grinder_production_sys_avg`]
                              ? configsObject[`${grinderRef}grinder_production_sys_avg`].value_float
                              : '0'}
                            %
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row table-row" style={{ marginTop: '30px', height: '200px', marginLeft: '30px' }}>
              <div className="col-sm-4">
                <div className="table-container">
                  <h4>Critical Misses</h4>
                  <table className="kpi-table">
                    <tbody>
                      <tr>
                        <td>Misses</td>
                        <td>Target</td>
                        <td>Sys Avg</td>
                      </tr>
                      <tr>
                        <td>
                          <span style={{ color: '#2b4966' }}>
                            {configsObject[`${grinderRef.toLowerCase()}critical_misses`]
                              ? configsObject[`${grinderRef.toLowerCase()}critical_misses`].value_int
                              : '0'}
                          </span>
                        </td>
                        <td>
                          <span style={{ color: '#63729D' }}>
                            {configsObject[`${grinderRef}critical_misses_target`]
                              ? configsObject[`${grinderRef}critical_misses_target`].value_int
                              : '0'}
                          </span>
                        </td>
                        <td>
                          <span style={{ color: '#63729D' }}>
                            {configsObject[`${grinderRef}critical_misses_sys_avg`]
                              ? configsObject[`${grinderRef}critical_misses_sys_avg`].value_int
                              : '0'}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="table-container">
                  <h4>Vendor Payments</h4>
                  <table className="kpi-table">
                    <tbody>
                      <tr>
                        <td>% Paid on Time</td>
                        <td>Target</td>
                        <td>Sys Avg</td>
                      </tr>
                      <tr>
                        <td>
                          <span style={{ color: '#5696a7' }}>
                            {configsObject[`${grinderRef.toLowerCase()}vendor_payments`]
                              ? configsObject[`${grinderRef.toLowerCase()}vendor_payments`].value_int
                              : '0'}
                            %
                          </span>
                        </td>
                        <td>
                          <span style={{ color: '#63729D' }}>
                            {configsObject[`${grinderRef}vendor_payments_target`]
                              ? configsObject[`${grinderRef}vendor_payments_target`].value_int
                              : '0'}
                            %
                          </span>
                        </td>
                        <td>
                          <span style={{ color: '#63729D' }}>
                            {configsObject[`${grinderRef}vendor_payments_sys_avg`]
                              ? configsObject[`${grinderRef}vendor_payments_sys_avg`].value_int
                              : '0'}
                            %
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="table-container">
                  <h4>Finalized Claims</h4>
                  <table className="kpi-table">
                    <tbody>
                      <tr>
                        <td>Claims</td>
                        <td>Sys Avg</td>
                      </tr>
                      <tr>
                        <td>
                          <span style={{ color: '#6e9637' }}>
                            {configsObject[`${grinderRef.toLowerCase()}finalised_claims`]
                              ? configsObject[`${grinderRef.toLowerCase()}finalised_claims`].value_int
                              : '0'}
                            K
                          </span>
                        </td>
                        <td>
                          <span style={{ color: '#63729D' }}>
                            {configsObject[`${grinderRef}finalised_claims_sys_avg`]
                              ? configsObject[`${grinderRef}finalised_claims_sys_avg`].value_int
                              : '0'}
                            K
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return false;
  }
}

export default GrinderKPIDashboard;
