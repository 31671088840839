import { connect } from 'react-redux';

import OpsMapComponent from '../../components/dashboard/OpsMap';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

const mapStateToProps = state => {
  return {
    ...findEntitiesInState(state, { grinders: 'grinder', packer_plants: 'packer_plant' }),
    user: state.user,
    map_data: state.map_data,
    token: state.user.token,
  };
};

const OpsMap = connect(mapStateToProps)(OpsMapComponent);

export default OpsMap;
