import axios from 'axios';
import { MARKETS } from '../config/markets';
import Constants from '../Constants';
import { convertToLocalISOString } from '../helpers/dates';
import { PACKER_PLANTS_ALL } from '../slices/quality/qualityConfig';

const BASE_URL = `${Constants.URL}analytic-dashboard`;

const REQUEST_TYPES = {
  MARKET: 'MARKET',
  PACKER: 'PACKER',
  SINGLE: 'SINGLE',
};

const REQUEST_SECTIONS = {
  MARKET: ['packer-plant-scores', 'aslUpdates', 'ncrs', 'claims', 'difot', 'freshness-days', 'audits'],
  PACKER: ['packer-plant-scores', 'aslUpdates', 'ncrs', 'claims', 'difot', 'freshness-days', 'audits', 'packerHistory'],
  SINGLE: [
    'packer-plant-scores',
    'packerPlantHistory',
    'ncrs',
    'claims',
    'difot',
    'freshness-days',
    'auditSinglePackerPlant',
    'aslUpdates',
  ],
};

function determineRequestType(packerPlant) {
  if (
    [
      PACKER_PLANTS_ALL,
      MARKETS.Australia.key,
      MARKETS['New Zealand'].key,
      MARKETS.USA.key,
      MARKETS.Canada.key,
    ].includes(packerPlant.id)
  ) {
    return REQUEST_TYPES.MARKET;
  }
  if (packerPlant.isPacker) {
    return REQUEST_TYPES.PACKER;
  }
  return REQUEST_TYPES.SINGLE;
}

/* TODO get rid of dummyData */
const dummyData = {
  MARKET: {
    'packer-plant-scores': {
      scores: [],
    },
    aslUpdates: {
      keyIssues: [
        // {
        //   issue: 'Look at providing further training on processing QA, particularly the removal of bone chips.',
        //   explanation:
        //     '4 of 5 new suppliers have recorded a larger than average number of NCRs categorised under Foreign Material - Processing, with Bone chips being the most recorded sub-category.',
        // },
      ],
      newSuppliersOnboarding: [
        // {
        //   packerPlantId: '83',
        //   progress: 1,
        // },
        // {
        //   packerPlantId: '58',
        //   progress: 2,
        // },
        // {
        //   packerPlantId: '87',
        //   progress: 4,
        // },
        // {
        //   packerPlantId: '57',
        //   progress: 4,
        // },
        // {
        //   packerPlantId: '100',
        //   progress: 9,
        // },
      ],
      delistedSuppliersNumber: 0,
    },
    ncrs: {
      incidents: {
        count: 42,
        previousCount: 61,
        byPeriod: [
          {
            date: '2019-01-01T00:00:00Z',
            value: 54,
          },
          {
            date: '2019-02-01T00:00:00Z',
            value: 48,
          },
          {
            date: '2019-03-01T00:00:00Z',
            value: 32,
          },
          {
            date: '2019-04-01T00:00:00Z',
            value: 50,
          },
          {
            date: '2019-05-01T00:00:00Z',
            value: 39,
          },
          {
            date: '2019-06-01T00:00:00Z',
            value: 40,
          },
          {
            date: '2019-07-01T00:00:00Z',
            value: 29,
          },
          {
            date: '2019-08-01T00:00:00Z',
            value: 48,
          },
          {
            date: '2019-09-01T00:00:00Z',
            value: 56,
          },
          {
            date: '2019-10-01T00:00:00Z',
            value: 65,
          },
          {
            date: '2019-11-01T00:00:00Z',
            value: 32,
          },
          {
            date: '2019-12-01T00:00:00Z',
            value: 54,
          },
          {
            date: '2020-01-01T00:00:00Z',
            value: 48,
          },
          {
            date: '2020-02-01T00:00:00Z',
            value: 32,
          },
          {
            date: '2020-03-01T00:00:00Z',
            value: 50,
          },
          {
            date: '2020-04-01T00:00:00Z',
            value: 39,
          },
          {
            date: '2020-05-01T00:00:00Z',
            value: 40,
          },
          {
            date: '2020-06-01T00:00:00Z',
            value: 29,
          },
          {
            date: '2020-07-01T00:00:00Z',
            value: 48,
          },
          {
            date: '2020-08-01T00:00:00Z',
            value: 56,
          },
          {
            date: '2020-09-01T00:00:00Z',
            value: 47,
          },
          {
            date: '2020-10-01T00:00:00Z',
            value: 42,
          },
        ],
      },
      categories: [
        {
          category: 'Foreign Material - External',
          subCategories: [
            {
              subCategory: 'Soft Plastic (Blue)',
              count: 4,
              withClaimValue: 2,
              claimAmount: 100,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 3,
              previousWithClaimValue: 2,
              previousClaimAmount: 75,
            },
            {
              subCategory: 'Soft plastic (Clear)',
              count: 5,
              withClaimValue: 4,
              claimAmount: 80,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 2,
              previousWithClaimValue: 1,
              previousClaimAmount: 60,
            },
            {
              subCategory: 'Metal - Other',
              count: 2,
              withClaimValue: 0,
              claimAmount: 0,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 1,
              previousWithClaimValue: 0,
              previousClaimAmount: 0,
            },
            {
              subCategory: 'Hard plastic',
              count: 3,
              withClaimValue: 3,
              claimAmount: 130,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 6,
              previousWithClaimValue: 4,
              previousClaimAmount: 100,
            },
            {
              subCategory: 'Wood',
              count: 2,
              withClaimValue: 0,
              claimAmount: 0,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 2,
              previousWithClaimValue: 1,
              previousClaimAmount: 60,
            },
          ],
        },
        {
          category: 'Quality',
          subCategories: [
            {
              subCategory: 'No Temp Loggers',
              count: 3,
              withClaimValue: 3,
              claimAmount: 130,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 6,
              previousWithClaimValue: 4,
              previousClaimAmount: 100,
            },
            {
              subCategory: 'Transportation Issue',
              count: 2,
              withClaimValue: 0,
              claimAmount: 0,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 2,
              previousWithClaimValue: 1,
              previousClaimAmount: 60,
            },
          ],
        },
        {
          category: 'Foreign Material - Processing',
          subCategories: [
            {
              subCategory: 'Bone / Bone Chips',
              count: 3,
              withClaimValue: 3,
              claimAmount: 130,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 6,
              previousWithClaimValue: 4,
              previousClaimAmount: 100,
            },
            {
              subCategory: 'Hair',
              count: 2,
              withClaimValue: 0,
              claimAmount: 0,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 2,
              previousWithClaimValue: 1,
              previousClaimAmount: 60,
            },
          ],
        },
        {
          category: 'Packaging',
          subCategories: [
            {
              subCategory: 'Short Delivered',
              count: 3,
              withClaimValue: 3,
              claimAmount: 130,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 6,
              previousWithClaimValue: 4,
              previousClaimAmount: 100,
            },
            {
              subCategory: 'Carton damage',
              count: 2,
              withClaimValue: 0,
              claimAmount: 0,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 2,
              previousWithClaimValue: 1,
              previousClaimAmount: 60,
            },
          ],
        },
        {
          category: 'Microbiological',
          subCategories: [
            {
              subCategory: 'E Coli',
              count: 3,
              withClaimValue: 3,
              claimAmount: 130,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 6,
              previousWithClaimValue: 4,
              previousClaimAmount: 100,
            },
            {
              subCategory: 'Other High Micro',
              count: 2,
              withClaimValue: 0,
              claimAmount: 0,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 2,
              previousWithClaimValue: 1,
              previousClaimAmount: 60,
            },
          ],
        },
        {
          category: 'Infestation',
          subCategories: [
            {
              subCategory: 'Insect',
              count: 3,
              withClaimValue: 3,
              claimAmount: 130,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 6,
              previousWithClaimValue: 4,
              previousClaimAmount: 100,
            },
          ],
        },
      ],
      volume: {
        percentageOfTotal: 0.0165,
        count: 445,
        previousCount: 381,
        byPeriod: [
          {
            date: '2019-01-01T00:00:00Z',
            value: 0.004,
          },
          {
            date: '2019-02-01T00:00:00Z',
            value: 0.002,
          },
          {
            date: '2019-03-01T00:00:00Z',
            value: 0.0,
          },
          {
            date: '2019-04-01T00:00:00Z',
            value: 0.005,
          },
          {
            date: '2019-05-01T00:00:00Z',
            value: 0.01,
          },
          {
            date: '2019-06-01T00:00:00Z',
            value: 0.015,
          },
          {
            date: '2019-07-01T00:00:00Z',
            value: 0.014,
          },
          {
            date: '2019-08-01T00:00:00Z',
            value: 0.013,
          },
          {
            date: '2019-09-01T00:00:00Z',
            value: 0.008,
          },

          {
            date: '2019-10-01T00:00:00Z',
            value: 0.006,
          },
          {
            date: '2019-11-01T00:00:00Z',
            value: 0.008,
          },
          {
            date: '2019-12-01T00:00:00Z',
            value: 0.016,
          },
          {
            date: '2020-01-01T00:00:00Z',
            value: 0.012,
          },
          {
            date: '2020-02-01T00:00:00Z',
            value: 0.019,
          },
          {
            date: '2020-03-01T00:00:00Z',
            value: 0.027,
          },
          {
            date: '2020-04-01T00:00:00Z',
            value: 0.025,
          },
          {
            date: '2020-05-01T00:00:00Z',
            value: 0.024,
          },
          {
            date: '2020-06-01T00:00:00Z',
            value: 0.02,
          },
          {
            date: '2020-07-01T00:00:00Z',
            value: 0.013,
          },
          {
            date: '2020-08-01T00:00:00Z',
            value: 0.011,
          },
          {
            date: '2020-09-01T00:00:00Z',
            value: 0.019,
          },
          {
            date: '2020-10-01T00:00:00Z',
            value: 0.016,
          },
        ],
      },
      dollars: {
        count: 3575,
        previousCount: 980,
        byPeriod: [
          {
            date: '2019-01-01T00:00:00Z',
            value: 600,
          },
          {
            date: '2019-02-01T00:00:00Z',
            value: 200,
          },
          {
            date: '2019-03-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2019-04-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2019-05-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2019-06-01T00:00:00Z',
            value: 500,
          },
          {
            date: '2019-07-01T00:00:00Z',
            value: 400,
          },
          {
            date: '2019-08-01T00:00:00Z',
            value: 1230,
          },
          {
            date: '2019-09-01T00:00:00Z',
            value: 1130,
          },
          {
            date: '2019-10-01T00:00:00Z',
            value: 650,
          },
          {
            date: '2019-11-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2019-12-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-01-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-02-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-03-01T00:00:00Z',
            value: 1260,
          },
          {
            date: '2020-04-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-05-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-06-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-07-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-08-01T00:00:00Z',
            value: 60,
          },
          {
            date: '2020-09-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-10-01T00:00:00Z',
            value: 1630,
          },
        ],
      },
      packerPlants: [
        {
          packerPlantId: '62',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 3,
              claimAmount: 230,
              volume: 35,
              incidentRate: 0.012,
            },
            {
              category: 'Quality',
              count: 3,
              claimAmount: 75,
              volume: 35,
              incidentRate: 0.012,
            },
          ],
        },
        {
          packerPlantId: '136',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 5,
              claimAmount: 700,
              volume: 35,
              incidentRate: 0.012,
            },
            {
              category: 'Quality',
              count: 1,
              claimAmount: 125,
              volume: 35,
              incidentRate: 0.012,
            },
          ],
        },
        {
          packerPlantId: '77',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 4,
              claimAmount: 270,
              volume: 35,
              incidentRate: 0.012,
            },
            {
              category: 'Quality',
              count: 2,
              claimAmount: 155,
              volume: 35,
              incidentRate: 0.012,
            },
            {
              category: 'Packaging',
              count: 1,
              claimAmount: 15,
              volume: 35,
              incidentRate: 0.012,
            },
          ],
        },
        {
          packerPlantId: '103',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 3,
              claimAmount: 400,
              volume: 35,
              incidentRate: 0.012,
            },
            {
              category: 'Microbiological',
              count: 2,
              claimAmount: 300,
              volume: 35,
              incidentRate: 0.012,
            },
          ],
        },
        {
          packerPlantId: '83',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 2,
              claimAmount: 230,
              volume: 35,
              incidentRate: 0.012,
            },
            {
              category: 'Quality',
              count: 1,
              claimAmount: 75,
              volume: 35,
              incidentRate: 0.012,
            },
          ],
        },
        {
          packerPlantId: '144',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 1,
              claimAmount: 290,
              volume: 35,
              incidentRate: 0.012,
            },
            {
              category: 'Quality',
              count: 1,
              claimAmount: 12.5,
              volume: 35,
              incidentRate: 0.012,
            },
          ],
        },
        {
          packerPlantId: '55',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 1,
              claimAmount: 230,
              volume: 35,
              incidentRate: 0.012,
            },
            {
              category: 'Infestation',
              count: 2,
              claimAmount: 30,
              volume: 35,
              incidentRate: 0.012,
            },
          ],
        },
      ],
      summary: {
        incidents: {
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 243,
            },
            {
              category: 'Quality',
              count: 105,
            },
            {
              category: 'Foreign Material - Processing',
              count: 69,
            },
            {
              category: 'Packaging',
              count: 144,
            },
            {
              category: 'Microbiological',
              count: 62,
            },
            {
              category: 'Infestation',
              count: 123,
            },
          ],
        },
        volume: {
          percentageOfTotal: 0.0095,
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 29562,
            },
            {
              category: 'Quality',
              count: 14695,
            },
            {
              category: 'Foreign Material - Processing',
              count: 954,
            },
            {
              category: 'Packaging',
              count: 2875,
            },
            {
              category: 'Microbiological',
              count: 1430,
            },
            {
              category: 'Infestation',
              count: 248,
            },
          ],
        },
        dollars: {
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 8962,
            },
            {
              category: 'Quality',
              count: 33696,
            },
            {
              category: 'Foreign Material - Processing',
              count: 4800,
            },
            {
              category: 'Packaging',
              count: 9530,
            },
            {
              category: 'Microbiological',
              count: 1200,
            },
            {
              category: 'Infestation',
              count: 84,
            },
          ],
        },
      },
      exports: {
        volume: 31350,
        percentOfTotal: 0.0192,
        domesticVolume: 778700,
        exportVolume: 1900000,
        byCountry: [
          {
            volume: 28215,
            country: 'USA',
            shippedDateRange: ['2020-12-03T00:00:00Z', '2020-12-08T00:00:00Z'],
            category: 'Foreign Material - External (Hard plastic)',
          },
          {
            volume: 2194,
            country: 'Japan',
            shippedDateRange: ['2020-12-03T00:00:00Z', '2020-12-08T00:00:00Z'],
            category: 'Foreign Material - External (Hard plastic)',
          },
          {
            volume: 940,
            country: 'China',
            shippedDateRange: ['2020-10-13T00:00:00Z', '2020-10-15T00:00:00Z'],
            category: 'No temp loggers',
          },
        ],
        byPeriod: [
          {
            date: '2019-01-01T00:00:00Z',
            percentMarketDomestic: 0.12,
            percentTotalDomestic: 0.027,
            percentMarketExport: 0.23,
            percentTotalExport: 0.012,
          },
          {
            date: '2019-02-01T00:00:00Z',
            percentMarketDomestic: 0.14,
            percentTotalDomestic: 0.025,
            percentMarketExport: 0.21,
            percentTotalExport: 0.027,
          },
          {
            date: '2019-03-01T00:00:00Z',
            percentMarketDomestic: 0.28,
            percentTotalDomestic: 0.019,
            percentMarketExport: 0.11,
            percentTotalExport: 0.024,
          },
          {
            date: '2019-04-01T00:00:00Z',
            percentMarketDomestic: 0.21,
            percentTotalDomestic: 0.016,
            percentMarketExport: 0.19,
            percentTotalExport: 0.016,
          },
          {
            date: '2019-05-01T00:00:00Z',
            percentMarketDomestic: 0.17,
            percentTotalDomestic: 0.012,
            percentMarketExport: 0.14,
            percentTotalExport: 0.018,
          },
          {
            date: '2019-06-01T00:00:00Z',
            percentMarketDomestic: 0.11,
            percentTotalDomestic: 0.017,
            percentMarketExport: 0.17,
            percentTotalExport: 0.018,
          },
          {
            date: '2019-07-01T00:00:00Z',
            percentMarketDomestic: 0.9,
            percentTotalDomestic: 0.015,
            percentMarketExport: 0.17,
            percentTotalExport: 0.012,
          },
          {
            date: '2019-08-01T00:00:00Z',
            percentMarketDomestic: 0.13,
            percentTotalDomestic: 0.012,
            percentMarketExport: 0.9,
            percentTotalExport: 0.009,
          },
          {
            date: '2019-09-01T00:00:00Z',
            percentMarketDomestic: 0.15,
            percentTotalDomestic: 0.023,
            percentMarketExport: 0.25,
            percentTotalExport: 0.019,
          },
          {
            date: '2019-10-01T00:00:00Z',
            percentMarketDomestic: 0.12,
            percentTotalDomestic: 0.027,
            percentMarketExport: 0.12,
            percentTotalExport: 0.018,
          },
          {
            date: '2019-11-01T00:00:00Z',
            percentMarketDomestic: 0.2,
            percentTotalDomestic: 0.024,
            percentMarketExport: 0.2,
            percentTotalExport: 0.019,
          },
          {
            date: '2019-12-01T00:00:00Z',
            percentMarketDomestic: 0.11,
            percentTotalDomestic: 0.019,
            percentMarketExport: 0.21,
            percentTotalExport: 0.017,
          },
          {
            date: '2020-01-01T00:00:00Z',
            percentMarketDomestic: 0.18,
            percentTotalDomestic: 0.011,
            percentMarketExport: 0.19,
            percentTotalExport: 0.025,
          },
          {
            date: '2020-02-01T00:00:00Z',
            percentMarketDomestic: 0.1,
            percentTotalDomestic: 0.015,
            percentMarketExport: 0.16,
            percentTotalExport: 0.018,
          },
          {
            date: '2020-03-01T00:00:00Z',
            percentMarketDomestic: 0.8,
            percentTotalDomestic: 0.016,
            percentMarketExport: 0.12,
            percentTotalExport: 0.018,
          },
          {
            date: '2020-04-01T00:00:00Z',
            percentMarketDomestic: 0.2,
            percentTotalDomestic: 0.016,
            percentMarketExport: 0.26,
            percentTotalExport: 0.023,
          },
          {
            date: '2020-05-01T00:00:00Z',
            percentMarketDomestic: 0.14,
            percentTotalDomestic: 0.012,
            percentMarketExport: 0.23,
            percentTotalExport: 0.02,
          },
          {
            date: '2020-06-01T00:00:00Z',
            percentMarketDomestic: 0.16,
            percentTotalDomestic: 0.016,
            percentMarketExport: 0.12,
            percentTotalExport: 0.0008,
          },
          {
            date: '2020-07-01T00:00:00Z',
            percentMarketDomestic: 0.2,
            percentTotalDomestic: 0.018,
            percentMarketExport: 0.15,
            percentTotalExport: 0.009,
          },
          {
            date: '2020-08-01T00:00:00Z',
            percentMarketDomestic: 0.16,
            percentTotalDomestic: 0.021,
            percentMarketExport: 0.24,
            percentTotalExport: 0.011,
          },
          {
            date: '2020-09-01T00:00:00Z',
            percentMarketDomestic: 0.2,
            percentTotalDomestic: 0.024,
            percentMarketExport: 0.22,
            percentTotalExport: 0.013,
          },
          {
            date: '2020-10-01T00:00:00Z',
            percentMarketDomestic: 0.22,
            percentTotalDomestic: 0.023,
            percentMarketExport: 0.23,
            percentTotalExport: 0.011,
          },
        ],
      },
    },
    claims: {
      submitted: {
        count: 0,
        amount: 0,
        previousPeriod: {
          count: 0,
          amount: 0,
        },
        yearToDate: {
          count: 0,
          amount: 0,
          previousPeriod: {
            count: 0,
            amount: 0,
          },
        },
      },
      finalised: {
        count: 0,
        amount: 0,
        yearToDate: {
          count: 0,
          amount: 0,
        },
      },
      outstanding: {
        yearToDate: {
          count: 0,
          amount: 0,
        },
      },
      categories: [
        // {
        //   category: 'CL',
        //   count: 75,
        //   claimAmount: 210,
        //   volume: 17,
        //   previousVolume: 23,
        //   incidentRate: 0.012,
        //   previousIncidentRate: 0.009,
        //   previousCount: 75,
        //   previousClaimAmount: 210,
        // },
        // {
        //   category: 'FO Pre',
        //   count: 10,
        //   claimAmount: 1102,
        //   volume: 17,
        //   previousVolume: 23,
        //   incidentRate: 0.012,
        //   previousIncidentRate: 0.009,
        //   previousCount: 9,
        //   previousClaimAmount: 900,
        // },
        // {
        //   category: 'Other',
        //   count: 7,
        //   claimAmount: 80,
        //   volume: 17,
        //   previousVolume: 23,
        //   incidentRate: 0.012,
        //   previousIncidentRate: 0.009,
        //   previousCount: 7,
        //   previousClaimAmount: 80,
        // },
        // {
        //   category: 'FO Post',
        //   count: 2,
        //   claimAmount: 564,
        //   volume: 17,
        //   previousVolume: 23,
        //   incidentRate: 0.012,
        //   previousIncidentRate: 0.009,
        //   previousCount: 1,
        //   previousClaimAmount: 233,
        // },
        // {
        //   category: 'Returned product',
        //   count: 0,
        //   claimAmount: 0,
        //   volume: 17,
        //   previousVolume: 23,
        //   incidentRate: 0.012,
        //   previousIncidentRate: 0.009,
        //   previousCount: 1,
        //   previousClaimAmount: 120,
        // },
      ],
      packerPlants: [
        // {
        //   packerPlantId: '62',
        //   byCategory: [
        //     {
        //       category: 'CL',
        //       count: 3,
        //       claimAmount: 230,
        //       volume: 17,
        //       incidentRate: 0.014,
        //     },
        //     {
        //       category: 'FO Pre',
        //       count: 3,
        //       claimAmount: 75,
        //       volume: 17,
        //       incidentRate: 0.014,
        //     },
        //   ],
        // },
        // {
        //   packerPlantId: '136',
        //   byCategory: [
        //     {
        //       category: 'FO Post',
        //       count: 5,
        //       claimAmount: 700,
        //       volume: 17,
        //       incidentRate: 0.014,
        //     },
        //     {
        //       category: 'FO Pre',
        //       count: 1,
        //       claimAmount: 125,
        //       volume: 17,
        //       incidentRate: 0.014,
        //     },
        //   ],
        // },
        // {
        //   packerPlantId: '77',
        //   byCategory: [
        //     {
        //       category: 'CL',
        //       count: 4,
        //       claimAmount: 270,
        //       volume: 17,
        //       incidentRate: 0.014,
        //     },
        //     {
        //       category: 'FO Pre',
        //       count: 2,
        //       claimAmount: 155,
        //       volume: 17,
        //       incidentRate: 0.014,
        //     },
        //     {
        //       category: 'FO Post',
        //       count: 1,
        //       claimAmount: 15,
        //       volume: 17,
        //       incidentRate: 0.014,
        //     },
        //   ],
        // },
        // {
        //   packerPlantId: '103',
        //   byCategory: [
        //     {
        //       category: 'CL',
        //       count: 3,
        //       claimAmount: 400,
        //       volume: 17,
        //       incidentRate: 0.014,
        //     },
        //     {
        //       category: 'FO Post',
        //       count: 2,
        //       volume: 17,
        //       incidentRate: 0.014,
        //       claimAmount: 300,
        //     },
        //   ],
        // },
        // {
        //   packerPlantId: '83',
        //   byCategory: [
        //     {
        //       category: 'FO Pre',
        //       count: 2,
        //       claimAmount: 230,
        //       volume: 17,
        //       incidentRate: 0.014,
        //     },
        //     {
        //       category: 'Other',
        //       count: 1,
        //       claimAmount: 75,
        //       volume: 17,
        //       incidentRate: 0.014,
        //     },
        //   ],
        // },
        // {
        //   packerPlantId: '144',
        //   byCategory: [
        //     {
        //       category: 'FO Post',
        //       count: 1,
        //       claimAmount: 290,
        //       volume: 17,
        //       incidentRate: 0.014,
        //     },
        //     {
        //       category: 'CL',
        //       count: 1,
        //       claimAmount: 12.5,
        //       volume: 17,
        //       incidentRate: 0.014,
        //     },
        //   ],
        // },
        // {
        //   packerPlantId: '55',
        //   byCategory: [
        //     {
        //       category: 'Other',
        //       count: 1,
        //       claimAmount: 230,
        //       volume: 17,
        //       incidentRate: 0.014,
        //     },
        //     {
        //       category: 'CL',
        //       count: 2,
        //       claimAmount: 30,
        //       volume: 17,
        //       incidentRate: 0.014,
        //     },
        //   ],
        // },
      ],
      countByPeriod: [
        {
          date: '2019-01-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-02-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-03-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-04-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-05-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-06-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-07-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-08-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-09-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-10-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-11-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-12-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-01-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-02-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-03-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-04-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-05-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-06-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-07-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-08-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-09-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-10-01T00:00:00Z',
          value: 0,
        },
      ],
      amountByPeriod: [
        {
          date: '2019-01-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-02-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-03-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-04-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-05-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-06-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-07-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-08-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-09-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-10-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-11-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-12-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-01-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-02-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-03-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-04-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-05-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-06-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-07-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-08-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-09-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-10-01T00:00:00Z',
          value: 0,
        },
      ],
    },
    difot: {
      weightedAverage: {
        current: 0.854,
        startOfPeriod: 0.827,
        twelveMonthAverage: 0.869,
        goal: 0.8,
      },
      packerPlants: [
        {
          packerPlantId: '62',
          percent: 0.89,
          isNew: false,
        },
        {
          packerPlantId: '136',
          percent: 0.91,
          isNew: false,
        },
        {
          packerPlantId: '77',
          percent: 0.9,
          isNew: false,
        },
        {
          packerPlantId: '103',
          percent: 0.68,
          isNew: true,
        },
        {
          packerPlantId: '83',
          percent: 0.81,
          isNew: false,
        },
        {
          packerPlantId: '144',
          percent: 0.82,
          isNew: false,
        },
        {
          packerPlantId: '55',
          percent: 0.89,
          isNew: false,
        },
        {
          packerPlantId: '79',
          percent: 0.88,
          isNew: false,
        },
        {
          packerPlantId: '78',
          percent: 0.94,
          isNew: true,
        },
        {
          packerPlantId: '58',
          percent: 0.8,
          isNew: false,
        },
        {
          packerPlantId: '61',
          percent: 0.82,
          isNew: false,
        },
        {
          packerPlantId: '60',
          percent: 0.85,
          isNew: false,
        },
        {
          packerPlantId: '80',
          percent: 0.82,
          isNew: false,
        },
        {
          packerPlantId: '63',
          percent: 0.95,
          isNew: false,
        },
        {
          packerPlantId: '65',
          percent: 0.84,
          isNew: false,
        },
        {
          packerPlantId: '66',
          percent: 0.85,
          isNew: false,
        },
        {
          packerPlantId: '87',
          percent: 0.91,
          isNew: false,
        },
        {
          packerPlantId: '75',
          percent: 0.87,
          isNew: false,
        },
        {
          packerPlantId: '76',
          percent: 0.73,
          isNew: false,
        },
        {
          packerPlantId: '84',
          percent: 0.87,
          isNew: false,
        },
        {
          packerPlantId: '102',
          percent: 0.82,
          isNew: false,
        },
        {
          packerPlantId: '85',
          percent: 0.85,
          isNew: false,
        },
        {
          packerPlantId: '104',
          percent: 0.86,
          isNew: false,
        },
        {
          packerPlantId: '81',
          percent: 0.79,
          isNew: false,
        },
        {
          packerPlantId: '86',
          percent: 0.83,
          isNew: false,
        },
        {
          packerPlantId: '52',
          percent: 0.96,
          isNew: false,
        },
        {
          packerPlantId: '82',
          percent: 0.86,
          isNew: false,
        },
        {
          packerPlantId: '53',
          percent: 0.93,
          isNew: false,
        },
        {
          packerPlantId: '59',
          percent: 0.68,
          isNew: false,
        },
        {
          packerPlantId: '56',
          percent: 0.81,
          isNew: false,
        },
        {
          packerPlantId: '57',
          percent: 0.8,
          isNew: false,
        },
        {
          packerPlantId: '64',
          percent: 0.81,
          isNew: false,
        },
        {
          packerPlantId: '100',
          percent: 0.77,
          isNew: false,
        },
      ],
      byPeriod: [
        {
          date: '2019-01-01T00:00:00Z',
          percent: 0.83,
        },
        {
          date: '2019-02-01T00:00:00Z',
          percent: 0.87,
        },
        {
          date: '2019-03-01T00:00:00Z',
          percent: 0.76,
        },
        {
          date: '2019-04-01T00:00:00Z',
          percent: 0.72,
        },
        {
          date: '2019-05-01T00:00:00Z',
          percent: 0.8,
        },
        {
          date: '2019-06-01T00:00:00Z',
          percent: 0.79,
        },
        {
          date: '2019-07-01T00:00:00Z',
          percent: 0.77,
        },
        {
          date: '2019-08-01T00:00:00Z',
          percent: 0.85,
        },
        {
          date: '2019-09-01T00:00:00Z',
          percent: 0.86,
        },
        {
          date: '2019-10-01T00:00:00Z',
          percent: 0.89,
        },
        {
          date: '2019-11-01T00:00:00Z',
          percent: 0.83,
        },
        {
          date: '2019-12-01T00:00:00Z',
          percent: 0.84,
        },
        {
          date: '2020-01-01T00:00:00Z',
          percent: 0.87,
        },
        {
          date: '2020-02-01T00:00:00Z',
          percent: 0.86,
        },
        {
          date: '2020-03-01T00:00:00Z',
          percent: 0.88,
        },
        {
          date: '2020-04-01T00:00:00Z',
          percent: 0.85,
        },
        {
          date: '2020-05-01T00:00:00Z',
          percent: 0.89,
        },
        {
          date: '2020-06-01T00:00:00Z',
          percent: 0.88,
        },
        {
          date: '2020-07-01T00:00:00Z',
          percent: 0.9,
        },
        {
          date: '2020-08-01T00:00:00Z',
          percent: 0.86,
        },
        {
          date: '2020-09-01T00:00:00Z',
          percent: 0.85,
        },
        {
          date: '2020-10-01T00:00:00Z',
          percent: 0.85,
        },
      ],
    },
    'freshness-days': {
      packerPlants: {
        countInSpec: 31,
        previousCountInSpec: 29,
        packerPlantsNotInSpec: [
          {
            packerPlantId: '55',
            isNew: false,
          },
          {
            packerPlantId: '87',
            isNew: true,
          },
        ],
      },
      byPeriod: [
        {
          date: '2019-01-01T00:00:00Z',
          value: 6,
        },
        {
          date: '2019-02-01T00:00:00Z',
          value: 2,
        },
        {
          date: '2019-03-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-04-01T00:00:00Z',
          value: 5,
        },
        {
          date: '2019-05-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-06-01T00:00:00Z',
          value: 5,
        },
        {
          date: '2019-07-01T00:00:00Z',
          value: 4,
        },
        {
          date: '2019-08-01T00:00:00Z',
          value: 3,
        },
        {
          date: '2019-09-01T00:00:00Z',
          value: 3,
        },
        {
          date: '2019-10-01T00:00:00Z',
          value: 1,
        },
        {
          date: '2019-11-01T00:00:00Z',
          value: 4,
        },
        {
          date: '2019-12-01T00:00:00Z',
          value: 2,
        },
        {
          date: '2020-01-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-02-01T00:00:00Z',
          value: 3,
        },
        {
          date: '2020-03-01T00:00:00Z',
          value: 1,
        },
        {
          date: '2020-04-01T00:00:00Z',
          value: 1,
        },
        {
          date: '2020-05-01T00:00:00Z',
          value: 4,
        },
        {
          date: '2020-06-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-07-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-08-01T00:00:00Z',
          value: 2,
        },
        {
          date: '2020-09-01T00:00:00Z',
          value: 3,
        },
        {
          date: '2020-10-01T00:00:00Z',
          value: 2,
        },
      ],
    },
    audit: {
      performed: [
        // {
        //   type: 'GMP Slaughter & Deboning',
        //   completed: {
        //     total: 16,
        //     onTime: 16,
        //   },
        //   performance: {
        //     drop: 1,
        //     increase: 6,
        //   },
        //   scoreSummary: {
        //     approved: 10,
        //     provisional: 6,
        //     failed: 0,
        //   },
        //   delists: 0,
        // },
        // {
        //   type: 'HACCP',
        //   completed: {
        //     total: 13,
        //     onTime: 9,
        //   },
        //   performance: {
        //     drop: 0,
        //     increase: 3,
        //   },
        //   scoreSummary: {
        //     approved: 17,
        //     provisional: 4,
        //     failed: 1,
        //   },
        //   delists: 3,
        // },
        // {
        //   type: 'BSE Firewalls',
        //   completed: {
        //     total: 7,
        //     onTime: 7,
        //   },
        //   performance: {
        //     drop: 0,
        //     increase: 7,
        //   },
        //   scoreSummary: {
        //     approved: 5,
        //     provisional: 1,
        //     failed: 1,
        //   },
        //   delists: 0,
        // },
        // {
        //   type: 'Animal Welfare',
        //   completed: {
        //     total: 8,
        //     onTime: 7,
        //   },
        //   performance: {
        //     drop: 2,
        //     increase: 2,
        //   },
        //   scoreSummary: {
        //     approved: 7,
        //     provisional: 1,
        //     failed: 0,
        //   },
        //   delists: 1,
        // },
        // {
        //   type: 'SWA',
        //   selfAssessment: {
        //     submitted: 32,
        //     onTime: 27,
        //     outstanding: 1,
        //   },
        //   inspection: {
        //     pass: 16,
        //     provisional: 6,
        //     reAudit: 1,
        //     failed: 1,
        //   },
        //   delists: 2,
        // },
      ],
      upcoming: [
        // {
        //   type: 'GMP Slaughter & Deboning',
        //   byPackerPlant: [
        //     {
        //       packerPlantId: '62',
        //       announced: true,
        //       responsible: 'AusMeat',
        //       dateScheduledStart: '2020-10-12T00:00:00Z',
        //       datePreviousAudit: '2020-07-03T00:00:00Z',
        //       scorePreviousAudit: 'grade A',
        //       comments: 'Re-scheduled by 90 days due to COVID.',
        //     },
        //     {
        //       packerPlantId: '136',
        //       announced: true,
        //       responsible: 'AusMeat',
        //       dateScheduledStart: '2020-11-22T00:00:00Z',
        //       datePreviousAudit: '2020-06-15T00:00:00Z',
        //       scorePreviousAudit: 'grade B',
        //       comments: 'Re-scheduled by 90 days due to COVID.',
        //     },
        //     {
        //       packerPlantId: '77',
        //       announced: false,
        //       responsible: 'AusMeat',
        //       dateScheduledStart: '2020-11-12T00:00:00Z',
        //       dateScheduledEnd: '2021-02-12T00:00:00Z',
        //       datePreviousAudit: '2020-10-03T00:00:00Z',
        //       scorePreviousAudit: 'grade C',
        //     },
        //   ],
        // },
        // {
        //   type: 'HACCP',
        //   byPackerPlant: [
        //     {
        //       packerPlantId: '75',
        //       announced: true,
        //       responsible: 'AusMeat',
        //       dateScheduledStart: '2020-10-12T00:00:00Z',
        //       datePreviousAudit: '2020-07-03T00:00:00Z',
        //       scorePreviousAudit: 'grade A',
        //       comments: 'Re-scheduled by 90 days due to COVID.',
        //     },
        //   ],
        // },
        // {
        //   type: 'BSE Firewalls',
        //   byPackerPlant: [
        //     {
        //       packerPlantId: '60',
        //       announced: false,
        //       responsible: 'AusMeat',
        //       dateScheduledStart: '2020-11-12T00:00:00Z',
        //       dateScheduledEnd: '2021-02-12T00:00:00Z',
        //       datePreviousAudit: '2020-10-03T00:00:00Z',
        //       scorePreviousAudit: 'grade C',
        //     },
        //   ],
        // },
        // {
        //   type: 'Animal Welfare',
        //   byPackerPlant: [
        //     {
        //       packerPlantId: '83',
        //       announced: true,
        //       responsible: 'AusMeat',
        //       dateScheduledStart: '2020-10-12T00:00:00Z',
        //       datePreviousAudit: '2020-07-03T00:00:00Z',
        //       scorePreviousAudit: 'grade A',
        //       comments: 'Re-scheduled by 90 days due to COVID.',
        //     },
        //     {
        //       packerPlantId: '144',
        //       announced: true,
        //       responsible: 'AusMeat',
        //       dateScheduledStart: '2020-11-22T00:00:00Z',
        //       datePreviousAudit: '2020-06-15T00:00:00Z',
        //       scorePreviousAudit: 'grade B',
        //     },
        //   ],
        // },
        // {
        //   type: 'SWA',
        //   byPackerPlant: [
        //     {
        //       packerPlantId: '60',
        //       announced: false,
        //       responsible: 'AusMeat',
        //       dateScheduledStart: '2020-11-12T00:00:00Z',
        //       dateScheduledEnd: '2021-02-12T00:00:00Z',
        //       datePreviousAudit: '2020-10-03T00:00:00Z',
        //       scorePreviousAudit: 'pass',
        //     },
        //   ],
        // },
      ],
    },
  },
  PACKER: {
    'packer-plant-scores': {
      scores: [
        {
          packerPlantId: '62',
          volume: 472,
          score: 90,
        },
        {
          packerPlantId: '136',
          volume: 202,
          score: 7,
        },
        {
          packerPlantId: '77',
          volume: 943,
          score: 82,
        },
        {
          packerPlantId: '103',
          volume: 833,
          score: 10,
        },
        {
          packerPlantId: '83',
          volume: 362,
          score: 51,
        },
        {
          packerPlantId: '144',
          volume: 122,
          score: 51,
        },
        {
          packerPlantId: '55',
          volume: 1200,
          score: 84,
        },
        {
          packerPlantId: '79',
          volume: 1100,
          score: 58,
        },
        {
          packerPlantId: '78',
          volume: 300,
          score: 52,
        },
        {
          packerPlantId: '58',
          volume: 50,
          score: 93,
        },
        {
          packerPlantId: '61',
          volume: 102,
          score: 40,
        },
        {
          packerPlantId: '60',
          volume: 566,
          score: 55,
        },
        {
          packerPlantId: '80',
          volume: 20000,
          score: 82,
        },
        {
          packerPlantId: '63',
          volume: 762,
          score: 15,
        },
        {
          packerPlantId: '65',
          volume: 2000,
          score: 64,
        },
        {
          packerPlantId: '66',
          volume: 20,
          score: 8,
        },
        {
          packerPlantId: '87',
          volume: 122,
          score: 61,
        },
        {
          packerPlantId: '75',
          volume: 776,
          score: 57,
        },
        {
          packerPlantId: '76',
          volume: 822,
          score: 68,
        },
        {
          packerPlantId: '84',
          volume: 629,
          score: 10,
        },
        {
          packerPlantId: '102',
          volume: 720,
          score: 42,
        },
        {
          packerPlantId: '85',
          volume: 911,
          score: 55,
        },
        {
          packerPlantId: '104',
          volume: 129,
          score: 22,
        },
        {
          packerPlantId: '81',
          volume: 529,
          score: 79,
        },
        {
          packerPlantId: '86',
          volume: 926,
          score: 33,
        },
        {
          packerPlantId: '52',
          volume: 4000,
          score: 96,
        },
        {
          packerPlantId: '82',
          volume: 1300,
          score: 60,
        },
        {
          packerPlantId: '53',
          volume: 10100,
          score: 93,
        },
        {
          packerPlantId: '59',
          volume: 1200,
          score: 58,
        },
        {
          packerPlantId: '56',
          volume: 1200,
          score: 41,
        },
        {
          packerPlantId: '57',
          volume: 100,
          score: 50,
        },
        {
          packerPlantId: '64',
          volume: 199,
          score: 81,
        },
        {
          packerPlantId: '100',
          volume: 633,
          score: 59,
        },
      ],
    },
    packerHistory: {
      dateApproval: '2018-09-12T00:00:00Z',
      numberOfMembers: 11,
      mostRecentApproval: { packerPlantId: '144', date: '2018-12-17T00:00:00Z' },
      // Period types are:
      // * YEAR_FROM_TO: 2018-2019
      // * MONTH_FROM_TO: Feb 2016-now
      // * MONTH: Sep 2018
      // * YEAR: 2020
      // * IN_YEAR: in 2019
      keyAchievements: [
        {
          category: 'Volume',
          achievement: 'Top 3 highest supplier',
          periodType: 'YEAR_FROM_TO',
          period: {
            from: '2018-01-01T00:00:00Z',
            to: '2020-01-01T00:00:00Z',
          },
        },
        {
          category: 'Packer Plant ranking',
          achievement: 'Highest ranking: 3 of 28',
          periodType: 'MONTH',
          period: '2019-09-01T00:00:00Z',
        },
        {
          category: 'Packer Plant ranking',
          achievement: 'Top 5 ranking for 10 months',
          periodType: 'IN_YEAR',
          period: '2019-01-01T00:00:00Z',
        },
        {
          category: 'NCRs',
          achievement: '<1% of incidents for 5 months',
          periodType: 'IN_YEAR',
          period: '2019-01-01T00:00:00Z',
        },
        {
          category: 'Claims',
          achievement: 'Only 12 claims ($347)',
          periodType: 'YEAR',
          period: '2020-01-01T00:00:00Z',
        },
        {
          category: 'Audits',
          achievement: 'Met all deadlines',
          periodType: 'YEAR_FROM_TO',
          period: {
            from: '2018-01-01T00:00:00Z',
            to: '2020-01-01T00:00:00Z',
          },
        },
        {
          category: 'DIFOT',
          achievement: '97% specification',
          periodType: 'MONTH_FROM_TO',
          period: {
            from: '2016-02-01T00:00:00Z',
          },
        },
        {
          category: 'Freshness days',
          achievement: '97% specification',
          periodType: 'MONTH_FROM_TO',
          period: {
            from: '2016-02-01T00:00:00Z',
          },
        },
      ],
      additionalNotes: ['Sharp increase in Spot price purchases over the last 6 months.'],
    },
    ncrs: {
      bottomPackerPlants: ['77', '80', '81', '144', '75'],
      incidents: {
        count: 44,
        previousCount: 42,
        byPeriod: [
          {
            date: '2019-01-01T00:00:00Z',
            value: 54,
            percentOfTotal: 0.012,
          },
          {
            date: '2019-02-01T00:00:00Z',
            value: 48,
            percentOfTotal: 0.018,
          },
          {
            date: '2019-03-01T00:00:00Z',
            value: 32,
            percentOfTotal: 0.014,
          },
          {
            date: '2019-04-01T00:00:00Z',
            value: 50,
            percentOfTotal: 0.015,
          },
          {
            date: '2019-05-01T00:00:00Z',
            value: 39,
            percentOfTotal: 0.02,
          },
          {
            date: '2019-06-01T00:00:00Z',
            value: 40,
            percentOfTotal: 0.017,
          },
          {
            date: '2019-07-01T00:00:00Z',
            value: 29,
            percentOfTotal: 0.014,
          },
          {
            date: '2019-08-01T00:00:00Z',
            value: 48,
            percentOfTotal: 0.012,
          },
          {
            date: '2019-09-01T00:00:00Z',
            value: 56,
            percentOfTotal: 0.008,
          },
          {
            date: '2019-10-01T00:00:00Z',
            value: 65,
            percentOfTotal: 0.009,
          },
          {
            date: '2019-11-01T00:00:00Z',
            value: 32,
            percentOfTotal: 0.014,
          },
          {
            date: '2019-12-01T00:00:00Z',
            value: 54,
            percentOfTotal: 0.016,
          },
          {
            date: '2020-01-01T00:00:00Z',
            value: 48,
            percentOfTotal: 0.018,
          },
          {
            date: '2020-02-01T00:00:00Z',
            value: 32,
            percentOfTotal: 0.012,
          },
          {
            date: '2020-03-01T00:00:00Z',
            value: 50,
            percentOfTotal: 0.025,
          },
          {
            date: '2020-04-01T00:00:00Z',
            value: 39,
            percentOfTotal: 0.019,
          },
          {
            date: '2020-05-01T00:00:00Z',
            value: 40,
            percentOfTotal: 0.018,
          },
          {
            date: '2020-06-01T00:00:00Z',
            value: 29,
            percentOfTotal: 0.024,
          },
          {
            date: '2020-07-01T00:00:00Z',
            value: 48,
            percentOfTotal: 0.02,
          },
          {
            date: '2020-08-01T00:00:00Z',
            value: 56,
            percentOfTotal: 0.021,
          },
          {
            date: '2020-09-01T00:00:00Z',
            value: 47,
            percentOfTotal: 0.013,
          },
          {
            date: '2020-10-01T00:00:00Z',
            value: 42,
            percentOfTotal: 0.018,
          },
        ],
      },
      categories: [
        {
          category: 'Foreign Material - External',
          subCategories: [
            {
              subCategory: 'Soft Plastic (Blue)',
              count: 4,
              withClaimValue: 2,
              claimAmount: 100,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 3,
              previousWithClaimValue: 2,
              previousClaimAmount: 75,
            },
            {
              subCategory: 'Soft plastic (Clear)',
              count: 5,
              withClaimValue: 4,
              claimAmount: 80,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 2,
              previousWithClaimValue: 1,
              previousClaimAmount: 60,
            },
            {
              subCategory: 'Metal - Other',
              count: 2,
              withClaimValue: 0,
              claimAmount: 0,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 1,
              previousWithClaimValue: 0,
              previousClaimAmount: 0,
            },
            {
              subCategory: 'Hard plastic',
              count: 3,
              withClaimValue: 3,
              claimAmount: 130,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 6,
              previousWithClaimValue: 4,
              previousClaimAmount: 100,
            },
            {
              subCategory: 'Wood',
              count: 2,
              withClaimValue: 0,
              claimAmount: 0,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 2,
              previousWithClaimValue: 1,
              previousClaimAmount: 60,
            },
          ],
        },
        {
          category: 'Quality',
          subCategories: [
            {
              subCategory: 'No Temp Loggers',
              count: 3,
              withClaimValue: 3,
              claimAmount: 130,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 6,
              previousWithClaimValue: 4,
              previousClaimAmount: 100,
            },
            {
              subCategory: 'Transportation Issue',
              count: 2,
              withClaimValue: 0,
              claimAmount: 0,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 2,
              previousWithClaimValue: 1,
              previousClaimAmount: 60,
            },
          ],
        },
        {
          category: 'Foreign Material - Processing',
          subCategories: [
            {
              subCategory: 'Bone / Bone Chips',
              count: 3,
              withClaimValue: 3,
              claimAmount: 130,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 6,
              previousWithClaimValue: 4,
              previousClaimAmount: 100,
            },
            {
              subCategory: 'Hair',
              count: 2,
              withClaimValue: 0,
              claimAmount: 0,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 2,
              previousWithClaimValue: 1,
              previousClaimAmount: 60,
            },
          ],
        },
        {
          category: 'Packaging',
          subCategories: [
            {
              subCategory: 'Short Delivered',
              count: 3,
              withClaimValue: 3,
              claimAmount: 130,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 6,
              previousWithClaimValue: 4,
              previousClaimAmount: 100,
            },
            {
              subCategory: 'Carton damage',
              count: 2,
              withClaimValue: 0,
              claimAmount: 0,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 2,
              previousWithClaimValue: 1,
              previousClaimAmount: 60,
            },
          ],
        },
        {
          category: 'Microbiological',
          subCategories: [
            {
              subCategory: 'E Coli',
              count: 3,
              withClaimValue: 3,
              claimAmount: 130,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 6,
              previousWithClaimValue: 4,
              previousClaimAmount: 100,
            },
            {
              subCategory: 'Other High Micro',
              count: 2,
              withClaimValue: 0,
              claimAmount: 0,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 2,
              previousWithClaimValue: 1,
              previousClaimAmount: 60,
            },
          ],
        },
        {
          category: 'Infestation',
          subCategories: [
            {
              subCategory: 'Insect',
              count: 3,
              withClaimValue: 3,
              claimAmount: 130,
              volume: 35,
              previousVolume: 29,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 6,
              previousWithClaimValue: 4,
              previousClaimAmount: 100,
            },
          ],
        },
      ],
      volume: {
        percentageOfTotal: 0.0165,
        count: 445,
        previousCount: 381,
        byPeriod: [
          {
            date: '2019-01-01T00:00:00Z',
            value: 0.004,
            percentOfTotal: 0.017,
          },
          {
            date: '2019-02-01T00:00:00Z',
            value: 0.002,
            percentOfTotal: 0.012,
          },
          {
            date: '2019-03-01T00:00:00Z',
            value: 0.0,
            percentOfTotal: 0.011,
          },
          {
            date: '2019-04-01T00:00:00Z',
            value: 0.005,
            percentOfTotal: 0.014,
          },
          {
            date: '2019-05-01T00:00:00Z',
            value: 0.01,
            percentOfTotal: 0.016,
          },
          {
            date: '2019-06-01T00:00:00Z',
            value: 0.015,
            percentOfTotal: 0.012,
          },
          {
            date: '2019-07-01T00:00:00Z',
            value: 0.014,
            percentOfTotal: 0.02,
          },
          {
            date: '2019-08-01T00:00:00Z',
            value: 0.013,
            percentOfTotal: 0.026,
          },
          {
            date: '2019-09-01T00:00:00Z',
            value: 0.008,
            percentOfTotal: 0.025,
          },

          {
            date: '2019-10-01T00:00:00Z',
            value: 0.006,
            percentOfTotal: 0.021,
          },
          {
            date: '2019-11-01T00:00:00Z',
            value: 0.008,
            percentOfTotal: 0.015,
          },
          {
            date: '2019-12-01T00:00:00Z',
            value: 0.016,
            percentOfTotal: 0.019,
          },
          {
            date: '2020-01-01T00:00:00Z',
            value: 0.012,
            percentOfTotal: 0.017,
          },
          {
            date: '2020-02-01T00:00:00Z',
            value: 0.019,
            percentOfTotal: 0.012,
          },
          {
            date: '2020-03-01T00:00:00Z',
            value: 0.027,
            percentOfTotal: 0.012,
          },
          {
            date: '2020-04-01T00:00:00Z',
            value: 0.025,
            percentOfTotal: 0.014,
          },
          {
            date: '2020-05-01T00:00:00Z',
            value: 0.024,
            percentOfTotal: 0.005,
          },
          {
            date: '2020-06-01T00:00:00Z',
            value: 0.02,
            percentOfTotal: 0.008,
          },
          {
            date: '2020-07-01T00:00:00Z',
            value: 0.013,
            percentOfTotal: 0.012,
          },
          {
            date: '2020-08-01T00:00:00Z',
            value: 0.011,
            percentOfTotal: 0.017,
          },
          {
            date: '2020-09-01T00:00:00Z',
            value: 0.019,
            percentOfTotal: 0.014,
          },
          {
            date: '2020-10-01T00:00:00Z',
            value: 0.016,
            percentOfTotal: 0.012,
          },
        ],
      },
      dollars: {
        count: 1634,
        previousCount: 980,
        byPeriod: [
          {
            date: '2019-01-01T00:00:00Z',
            value: 600,
            percentOfTotal: 0.017,
          },
          {
            date: '2019-02-01T00:00:00Z',
            value: 200,
            percentOfTotal: 0.016,
          },
          {
            date: '2019-03-01T00:00:00Z',
            value: 0,
            percentOfTotal: 0.018,
          },
          {
            date: '2019-04-01T00:00:00Z',
            value: 0,
            percentOfTotal: 0.013,
          },
          {
            date: '2019-05-01T00:00:00Z',
            value: 0,
            percentOfTotal: 0.009,
          },
          {
            date: '2019-06-01T00:00:00Z',
            value: 500,
            percentOfTotal: 0.021,
          },
          {
            date: '2019-07-01T00:00:00Z',
            value: 400,
            percentOfTotal: 0.019,
          },
          {
            date: '2019-08-01T00:00:00Z',
            value: 1230,
            percentOfTotal: 0.007,
          },
          {
            date: '2019-09-01T00:00:00Z',
            value: 1130,
            percentOfTotal: 0.008,
          },
          {
            date: '2019-10-01T00:00:00Z',
            value: 650,
            percentOfTotal: 0.014,
          },
          {
            date: '2019-11-01T00:00:00Z',
            value: 0,
            percentOfTotal: 0.011,
          },
          {
            date: '2019-12-01T00:00:00Z',
            value: 0,
            percentOfTotal: 0.018,
          },
          {
            date: '2020-01-01T00:00:00Z',
            value: 0,
            percentOfTotal: 0.02,
          },
          {
            date: '2020-02-01T00:00:00Z',
            value: 0,
            percentOfTotal: 0.024,
          },
          {
            date: '2020-03-01T00:00:00Z',
            value: 1260,
            percentOfTotal: 0.022,
          },
          {
            date: '2020-04-01T00:00:00Z',
            value: 0,
            percentOfTotal: 0.014,
          },
          {
            date: '2020-05-01T00:00:00Z',
            value: 0,
            percentOfTotal: 0.01,
          },
          {
            date: '2020-06-01T00:00:00Z',
            value: 0,
            percentOfTotal: 0.011,
          },
          {
            date: '2020-07-01T00:00:00Z',
            value: 0,
            percentOfTotal: 0.012,
          },
          {
            date: '2020-08-01T00:00:00Z',
            value: 60,
            percentOfTotal: 0.016,
          },
          {
            date: '2020-09-01T00:00:00Z',
            value: 0,
            percentOfTotal: 0.019,
          },
          {
            date: '2020-10-01T00:00:00Z',
            value: 1630,
            percentOfTotal: 0.018,
          },
        ],
      },
      packerPlantsTotal: [
        {
          packerPlantId: '62',
          count: 90,
          claimAmount: 3000,
        },
        {
          packerPlantId: '136',
          count: 7,
          claimAmount: 1000,
        },
        {
          packerPlantId: '77',
          count: 82,
          claimAmount: 1000,
        },
        {
          packerPlantId: '103',
          count: 10,
          claimAmount: 100,
        },
        {
          packerPlantId: '83',
          count: 51,
          claimAmount: 3000,
        },
        {
          packerPlantId: '144',
          count: 51,
          claimAmount: 5200,
        },
        {
          packerPlantId: '55',
          count: 84,
          claimAmount: 3000,
        },
        {
          packerPlantId: '79',
          count: 58,
          claimAmount: 2200,
        },
        {
          packerPlantId: '78',
          count: 52,
          claimAmount: 4400,
        },
        {
          packerPlantId: '58',
          count: 93,
          claimAmount: 400,
        },
        {
          packerPlantId: '61',
          count: 40,
          claimAmount: 300,
        },
        {
          packerPlantId: '60',
          count: 55,
          claimAmount: 300,
        },
        {
          packerPlantId: '80',
          count: 82,
          claimAmount: 2000,
        },
        {
          packerPlantId: '63',
          count: 15,
          claimAmount: 3000,
        },
        {
          packerPlantId: '65',
          count: 64,
          claimAmount: 3000,
        },
        {
          packerPlantId: '66',
          count: 8,
          claimAmount: 3000,
        },
        {
          packerPlantId: '87',
          count: 61,
          claimAmount: 3000,
        },
        {
          packerPlantId: '75',
          count: 57,
          claimAmount: 4000,
        },
        {
          packerPlantId: '76',
          count: 68,
          claimAmount: 4000,
        },
        {
          packerPlantId: '84',
          count: 10,
          claimAmount: 3000,
        },
        {
          packerPlantId: '102',
          count: 42,
          claimAmount: 500,
        },
        {
          packerPlantId: '85',
          count: 55,
          claimAmount: 0,
        },
        {
          packerPlantId: '104',
          count: 22,
          claimAmount: 0,
        },
        {
          packerPlantId: '81',
          count: 79,
          claimAmount: 0,
        },
        {
          packerPlantId: '86',
          count: 33,
          claimAmount: 100,
        },
        {
          packerPlantId: '52',
          count: 96,
          claimAmount: 3300,
        },
        {
          packerPlantId: '82',
          count: 60,
          claimAmount: 3000,
        },
        {
          packerPlantId: '53',
          count: 93,
          claimAmount: 400,
        },
        {
          packerPlantId: '59',
          count: 58,
          claimAmount: 700,
        },
        {
          packerPlantId: '56',
          count: 41,
          claimAmount: 5000,
        },
        {
          packerPlantId: '57',
          count: 50,
          claimAmount: 2800,
        },
        {
          packerPlantId: '64',
          count: 81,
          claimAmount: 200,
        },
        {
          packerPlantId: '100',
          count: 59,
          claimAmount: 3500,
        },
      ],
      summary: {
        incidents: {
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 243,
            },
            {
              category: 'Quality',
              count: 105,
            },
            {
              category: 'Foreign Material - Processing',
              count: 69,
            },
            {
              category: 'Packaging',
              count: 144,
            },
            {
              category: 'Microbiological',
              count: 62,
            },
            {
              category: 'Infestation',
              count: 123,
            },
          ],
          byPackerPlant: [
            {
              packerPlantId: '77',
              count: 243,
            },
            {
              packerPlantId: '144',
              count: 90,
            },
            {
              packerPlantId: '79',
              count: 69,
            },
            {
              packerPlantId: '78',
              count: 144,
            },
            {
              packerPlantId: '80',
              count: 62,
            },
            {
              packerPlantId: '75',
              count: 123,
            },
            {
              packerPlantId: '76',
              count: 15,
            },
            {
              packerPlantId: '81',
              count: 0,
            },
            {
              packerPlantId: '82',
              count: 0,
            },
            {
              packerPlantId: '56',
              count: 0,
            },
            {
              packerPlantId: '57',
              count: 0,
            },
          ],
        },
        volume: {
          percentageOfTotal: 0.0095,
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 29562,
            },
            {
              category: 'Quality',
              count: 14695,
            },
            {
              category: 'Foreign Material - Processing',
              count: 954,
            },
            {
              category: 'Packaging',
              count: 2875,
            },
            {
              category: 'Microbiological',
              count: 1430,
            },
            {
              category: 'Infestation',
              count: 248,
            },
          ],
          byPackerPlant: [
            {
              packerPlantId: '77',
              count: 29562,
            },
            {
              packerPlantId: '144',
              count: 14695,
            },
            {
              packerPlantId: '79',
              count: 954,
            },
            {
              packerPlantId: '78',
              count: 2875,
            },
            {
              packerPlantId: '80',
              count: 1000,
            },
            {
              packerPlantId: '75',
              count: 430,
            },
            {
              packerPlantId: '76',
              count: 248,
            },
            {
              packerPlantId: '81',
              count: 0,
            },
            {
              packerPlantId: '82',
              count: 0,
            },
            {
              packerPlantId: '56',
              count: 0,
            },
            {
              packerPlantId: '57',
              count: 0,
            },
          ],
        },
        dollars: {
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 8962,
            },
            {
              category: 'Quality',
              count: 33696,
            },
            {
              category: 'Foreign Material - Processing',
              count: 4800,
            },
            {
              category: 'Packaging',
              count: 9530,
            },
            {
              category: 'Microbiological',
              count: 1200,
            },
            {
              category: 'Infestation',
              count: 84,
            },
          ],
          byPackerPlant: [
            {
              packerPlantId: '77',
              count: 8962,
            },
            {
              packerPlantId: '144',
              count: 33696,
            },
            {
              packerPlantId: '79',
              count: 4800,
            },
            {
              packerPlantId: '78',
              count: 8000,
            },
            {
              packerPlantId: '80',
              count: 1200,
            },
            {
              packerPlantId: '75',
              count: 84,
            },
            {
              packerPlantId: '76',
              count: 1530,
            },
            {
              packerPlantId: '81',
              count: 0,
            },
            {
              packerPlantId: '82',
              count: 0,
            },
            {
              packerPlantId: '56',
              count: 0,
            },
            {
              packerPlantId: '57',
              count: 0,
            },
          ],
        },
      },
      exports: {
        volume: 31350,
        percentOfTotal: 0.0192,
        byCountry: [
          {
            volume: 28215,
            country: 'USA',
            packerPlantId: '77',
            shippedDateRange: ['2020-12-03T00:00:00Z', '2020-12-08T00:00:00Z'],
            category: 'Foreign Material - External (Hard plastic)',
          },
          {
            volume: 2194,
            country: 'Japan',
            packerPlantId: '76',
            shippedDateRange: ['2020-12-03T00:00:00Z', '2020-12-08T00:00:00Z'],
            category: 'Foreign Material - External (Hard plastic)',
          },
          {
            volume: 940,
            country: 'China',
            packerPlantId: '81',
            shippedDateRange: ['2020-10-13T00:00:00Z', '2020-10-15T00:00:00Z'],
            category: 'No temp loggers',
          },
        ],
        byPeriod: [
          {
            date: '2019-01-01T00:00:00Z',
            percentMarketDomestic: 0.12,
            percentTotalDomestic: 0.027,
            percentMarketExport: 0.23,
            percentTotalExport: 0.012,
          },
          {
            date: '2019-02-01T00:00:00Z',
            percentMarketDomestic: 0.14,
            percentTotalDomestic: 0.025,
            percentMarketExport: 0.21,
            percentTotalExport: 0.027,
          },
          {
            date: '2019-03-01T00:00:00Z',
            percentMarketDomestic: 0.28,
            percentTotalDomestic: 0.019,
            percentMarketExport: 0.11,
            percentTotalExport: 0.024,
          },
          {
            date: '2019-04-01T00:00:00Z',
            percentMarketDomestic: 0.21,
            percentTotalDomestic: 0.016,
            percentMarketExport: 0.19,
            percentTotalExport: 0.016,
          },
          {
            date: '2019-05-01T00:00:00Z',
            percentMarketDomestic: 0.17,
            percentTotalDomestic: 0.012,
            percentMarketExport: 0.14,
            percentTotalExport: 0.018,
          },
          {
            date: '2019-06-01T00:00:00Z',
            percentMarketDomestic: 0.11,
            percentTotalDomestic: 0.017,
            percentMarketExport: 0.17,
            percentTotalExport: 0.018,
          },
          {
            date: '2019-07-01T00:00:00Z',
            percentMarketDomestic: 0.9,
            percentTotalDomestic: 0.015,
            percentMarketExport: 0.17,
            percentTotalExport: 0.012,
          },
          {
            date: '2019-08-01T00:00:00Z',
            percentMarketDomestic: 0.13,
            percentTotalDomestic: 0.012,
            percentMarketExport: 0.9,
            percentTotalExport: 0.009,
          },
          {
            date: '2019-09-01T00:00:00Z',
            percentMarketDomestic: 0.15,
            percentTotalDomestic: 0.023,
            percentMarketExport: 0.25,
            percentTotalExport: 0.019,
          },
          {
            date: '2019-10-01T00:00:00Z',
            percentMarketDomestic: 0.12,
            percentTotalDomestic: 0.027,
            percentMarketExport: 0.12,
            percentTotalExport: 0.018,
          },
          {
            date: '2019-11-01T00:00:00Z',
            percentMarketDomestic: 0.2,
            percentTotalDomestic: 0.024,
            percentMarketExport: 0.2,
            percentTotalExport: 0.019,
          },
          {
            date: '2019-12-01T00:00:00Z',
            percentMarketDomestic: 0.11,
            percentTotalDomestic: 0.019,
            percentMarketExport: 0.21,
            percentTotalExport: 0.017,
          },
          {
            date: '2020-01-01T00:00:00Z',
            percentMarketDomestic: 0.18,
            percentTotalDomestic: 0.011,
            percentMarketExport: 0.19,
            percentTotalExport: 0.025,
          },
          {
            date: '2020-02-01T00:00:00Z',
            percentMarketDomestic: 0.1,
            percentTotalDomestic: 0.015,
            percentMarketExport: 0.16,
            percentTotalExport: 0.018,
          },
          {
            date: '2020-03-01T00:00:00Z',
            percentMarketDomestic: 0.8,
            percentTotalDomestic: 0.016,
            percentMarketExport: 0.12,
            percentTotalExport: 0.018,
          },
          {
            date: '2020-04-01T00:00:00Z',
            percentMarketDomestic: 0.2,
            percentTotalDomestic: 0.016,
            percentMarketExport: 0.26,
            percentTotalExport: 0.023,
          },
          {
            date: '2020-05-01T00:00:00Z',
            percentMarketDomestic: 0.14,
            percentTotalDomestic: 0.012,
            percentMarketExport: 0.23,
            percentTotalExport: 0.02,
          },
          {
            date: '2020-06-01T00:00:00Z',
            percentMarketDomestic: 0.16,
            percentTotalDomestic: 0.016,
            percentMarketExport: 0.12,
            percentTotalExport: 0.0008,
          },
          {
            date: '2020-07-01T00:00:00Z',
            percentMarketDomestic: 0.2,
            percentTotalDomestic: 0.018,
            percentMarketExport: 0.15,
            percentTotalExport: 0.009,
          },
          {
            date: '2020-08-01T00:00:00Z',
            percentMarketDomestic: 0.16,
            percentTotalDomestic: 0.021,
            percentMarketExport: 0.24,
            percentTotalExport: 0.011,
          },
          {
            date: '2020-09-01T00:00:00Z',
            percentMarketDomestic: 0.2,
            percentTotalDomestic: 0.024,
            percentMarketExport: 0.22,
            percentTotalExport: 0.013,
          },
          {
            date: '2020-10-01T00:00:00Z',
            percentMarketDomestic: 0.22,
            percentTotalDomestic: 0.023,
            percentMarketExport: 0.23,
            percentTotalExport: 0.011,
          },
        ],
        byPackerPlant: [
          {
            packerPlantId: '77',
            volumeDomestic: 2326,
            volumeExport: 321000,
          },
          {
            packerPlantId: '144',
            volumeDomestic: 29000,
            volumeExport: 72100,
          },
          {
            packerPlantId: '79',
            volumeDomestic: 99926,
            volumeExport: 4100,
          },
          {
            packerPlantId: '78',
            volumeDomestic: 0,
            volumeExport: 67100,
          },
          {
            packerPlantId: '80',
            volumeDomestic: 0,
            volumeExport: 329100,
          },
          {
            packerPlantId: '75',
            volumeDomestic: 23206,
            volumeExport: 0,
          },
          {
            packerPlantId: '76',
            volumeDomestic: 7206,
            volumeExport: 76554,
          },
          {
            packerPlantId: '81',
            volumeDomestic: 43206,
            volumeExport: 34322,
          },
          {
            packerPlantId: '82',
            volumeDomestic: 23206,
            volumeExport: 0,
          },
          {
            packerPlantId: '56',
            volumeDomestic: 0,
            volumeExport: 0,
          },
          {
            packerPlantId: '57',
            volumeDomestic: 2406,
            volumeExport: 0,
          },
        ],
      },
      packerPlants: [
        {
          packerPlantId: '77',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 3,
              withClaimValue: 2,
              claimAmount: 230,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
            {
              category: 'Quality',
              count: 3,
              withClaimValue: 1,
              claimAmount: 75,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
          ],
        },
        {
          packerPlantId: '144',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 5,
              withClaimValue: 3,
              claimAmount: 700,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
            {
              category: 'Quality',
              count: 1,
              withClaimValue: 0,
              claimAmount: 125,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
          ],
        },
        {
          packerPlantId: '79',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 4,
              withClaimValue: 0,
              claimAmount: 270,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
            {
              category: 'Quality',
              count: 2,
              withClaimValue: 1,
              claimAmount: 155,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
            {
              category: 'Packaging',
              count: 1,
              withClaimValue: 0,
              claimAmount: 15,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
          ],
        },
        {
          packerPlantId: '78',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 3,
              withClaimValue: 2,
              claimAmount: 400,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
            {
              category: 'Microbiological',
              count: 2,
              withClaimValue: 2,
              claimAmount: 300,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
          ],
        },
        {
          packerPlantId: '80',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 2,
              withClaimValue: 0,
              claimAmount: 230,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
            {
              category: 'Quality',
              count: 1,
              withClaimValue: 0,
              claimAmount: 75,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
          ],
        },
        {
          packerPlantId: '75',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 1,
              withClaimValue: 1,
              claimAmount: 290,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
            {
              category: 'Quality',
              count: 1,
              withClaimValue: 0,
              claimAmount: 12.5,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
          ],
        },
        {
          packerPlantId: '76',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 1,
              withClaimValue: 0,
              claimAmount: 230,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
            {
              category: 'Infestation',
              count: 2,
              withClaimValue: 2,
              claimAmount: 30,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
          ],
        },
        {
          packerPlantId: '81',
          byCategory: [
            {
              category: 'Quality',
              count: 1,
              withClaimValue: 0,
              claimAmount: 230,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
            {
              category: 'Infestation',
              count: 2,
              withClaimValue: 2,
              claimAmount: 30,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
          ],
        },
        {
          packerPlantId: '82',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 1,
              withClaimValue: 1,
              claimAmount: 230,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
            {
              category: 'Packaging',
              count: 2,
              withClaimValue: 2,
              claimAmount: 30,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
          ],
        },
        {
          packerPlantId: '56',
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 1,
              withClaimValue: 1,
              claimAmount: 230,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
            {
              category: 'Infestation',
              count: 2,
              withClaimValue: 1,
              claimAmount: 30,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
          ],
        },
        {
          packerPlantId: '57',
          byCategory: [
            {
              category: 'Foreign Material - Processing',
              count: 1,
              withClaimValue: 1,
              claimAmount: 230,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
            {
              category: 'Infestation',
              count: 2,
              withClaimValue: 0,
              claimAmount: 30,
              claimAmountPercent: 0.012,
              volume: 35,
              volumePercent: 0.012,
              volumeWithClaimValue: 25,
              volumePercentWithClaimValue: 0.009,
            },
          ],
        },
      ],
    },
    claims: {
      submitted: {
        count: 128,
        amount: 494312,
        previousPeriod: {
          count: 109,
          amount: 454617,
        },
        yearToDate: {
          count: 1091,
          amount: 4740399,
          previousPeriod: {
            count: 1032,
            amount: 4569289,
          },
        },
      },
      finalised: {
        count: 30,
        amount: 1350000,
        yearToDate: {
          count: 876,
          amount: 4731992,
        },
      },
      outstanding: {
        yearToDate: {
          count: 14,
          amount: 7008,
        },
      },
      categories: [
        {
          category: 'CL',
          count: 75,
          claimAmount: 210,
          volume: 17,
          previousVolume: 23,
          incidentRate: 0.012,
          previousIncidentRate: 0.009,
          previousCount: 75,
          previousClaimAmount: 210,
        },
        {
          category: 'FO Pre',
          count: 10,
          claimAmount: 1102,
          volume: 17,
          previousVolume: 23,
          incidentRate: 0.012,
          previousIncidentRate: 0.009,
          previousCount: 9,
          previousClaimAmount: 900,
        },
        {
          category: 'Other',
          count: 7,
          claimAmount: 80,
          volume: 17,
          previousVolume: 23,
          incidentRate: 0.012,
          previousIncidentRate: 0.009,
          previousCount: 7,
          previousClaimAmount: 80,
        },
        {
          category: 'FO Post',
          count: 2,
          claimAmount: 564,
          volume: 17,
          previousVolume: 23,
          incidentRate: 0.012,
          previousIncidentRate: 0.009,
          previousCount: 1,
          previousClaimAmount: 233,
        },
        {
          category: 'Returned product',
          count: 0,
          claimAmount: 0,
          volume: 17,
          previousVolume: 23,
          incidentRate: 0.012,
          previousIncidentRate: 0.009,
          previousCount: 1,
          previousClaimAmount: 120,
        },
      ],
      // bottom 5 packerPlants
      packerPlants: [
        {
          packerPlantId: '77',
          byCategory: [
            {
              category: 'CL',
              count: 3,
              claimAmount: 230,
              volume: 17,
              incidentRate: 0.014,
            },
            {
              category: 'FO Pre',
              count: 3,
              claimAmount: 75,
              volume: 17,
              incidentRate: 0.014,
            },
          ],
        },
        {
          packerPlantId: '56',
          byCategory: [
            {
              category: 'FO Post',
              count: 5,
              claimAmount: 700,
              volume: 17,
              incidentRate: 0.014,
            },
            {
              category: 'FO Pre',
              count: 1,
              claimAmount: 125,
              volume: 17,
              incidentRate: 0.014,
            },
          ],
        },
        {
          packerPlantId: '57',
          byCategory: [
            {
              category: 'CL',
              count: 4,
              claimAmount: 270,
              volume: 17,
              incidentRate: 0.014,
            },
            {
              category: 'FO Pre',
              count: 2,
              claimAmount: 155,
              volume: 17,
              incidentRate: 0.014,
            },
            {
              category: 'FO Post',
              count: 1,
              claimAmount: 15,
              volume: 17,
              incidentRate: 0.014,
            },
          ],
        },
        {
          packerPlantId: '76',
          byCategory: [
            {
              category: 'CL',
              count: 3,
              claimAmount: 400,
              volume: 17,
              incidentRate: 0.014,
            },
            {
              category: 'FO Post',
              count: 2,
              volume: 17,
              incidentRate: 0.014,
              claimAmount: 300,
            },
          ],
        },
        {
          packerPlantId: '80',
          byCategory: [
            {
              category: 'FO Pre',
              count: 2,
              claimAmount: 230,
              volume: 17,
              incidentRate: 0.014,
            },
            {
              category: 'Other',
              count: 1,
              claimAmount: 75,
              volume: 17,
              incidentRate: 0.014,
            },
          ],
        },
      ],
      countByPeriod: [
        {
          date: '2019-01-01T00:00:00Z',
          value: 62,
        },
        {
          date: '2019-02-01T00:00:00Z',
          value: 22,
        },
        {
          date: '2019-03-01T00:00:00Z',
          value: 20,
        },
        {
          date: '2019-04-01T00:00:00Z',
          value: 52,
        },
        {
          date: '2019-05-01T00:00:00Z',
          value: 20,
        },
        {
          date: '2019-06-01T00:00:00Z',
          value: 52,
        },
        {
          date: '2019-07-01T00:00:00Z',
          value: 42,
        },
        {
          date: '2019-08-01T00:00:00Z',
          value: 32,
        },
        {
          date: '2019-09-01T00:00:00Z',
          value: 32,
        },
        {
          date: '2019-10-01T00:00:00Z',
          value: 65,
        },
        {
          date: '2019-11-01T00:00:00Z',
          value: 32,
        },
        {
          date: '2019-12-01T00:00:00Z',
          value: 54,
        },
        {
          date: '2020-01-01T00:00:00Z',
          value: 48,
        },
        {
          date: '2020-02-01T00:00:00Z',
          value: 32,
        },
        {
          date: '2020-03-01T00:00:00Z',
          value: 50,
        },
        {
          date: '2020-04-01T00:00:00Z',
          value: 39,
        },
        {
          date: '2020-05-01T00:00:00Z',
          value: 40,
        },
        {
          date: '2020-06-01T00:00:00Z',
          value: 29,
        },
        {
          date: '2020-07-01T00:00:00Z',
          value: 48,
        },
        {
          date: '2020-08-01T00:00:00Z',
          value: 56,
        },
        {
          date: '2020-09-01T00:00:00Z',
          value: 47,
        },
        {
          date: '2020-10-01T00:00:00Z',
          value: 42,
        },
      ],
      amountByPeriod: [
        {
          date: '2019-01-01T00:00:00Z',
          value: 600,
        },
        {
          date: '2019-02-01T00:00:00Z',
          value: 200,
        },
        {
          date: '2019-03-01T00:00:00Z',
          value: 50,
        },
        {
          date: '2019-04-01T00:00:00Z',
          value: 500,
        },
        {
          date: '2019-05-01T00:00:00Z',
          value: 100,
        },
        {
          date: '2019-06-01T00:00:00Z',
          value: 5,
        },
        {
          date: '2019-07-01T00:00:00Z',
          value: 4,
        },
        {
          date: '2019-08-01T00:00:00Z',
          value: 3,
        },
        {
          date: '2019-09-01T00:00:00Z',
          value: 3,
        },
        {
          date: '2019-10-01T00:00:00Z',
          value: 650,
        },
        {
          date: '2019-11-01T00:00:00Z',
          value: 780,
        },
        {
          date: '2019-12-01T00:00:00Z',
          value: 460,
        },
        {
          date: '2020-01-01T00:00:00Z',
          value: 1200,
        },
        {
          date: '2020-02-01T00:00:00Z',
          value: 4000,
        },
        {
          date: '2020-03-01T00:00:00Z',
          value: 300,
        },
        {
          date: '2020-04-01T00:00:00Z',
          value: 500,
        },
        {
          date: '2020-05-01T00:00:00Z',
          value: 700,
        },
        {
          date: '2020-06-01T00:00:00Z',
          value: 5400,
        },
        {
          date: '2020-07-01T00:00:00Z',
          value: 670,
        },
        {
          date: '2020-08-01T00:00:00Z',
          value: 560,
        },
        {
          date: '2020-09-01T00:00:00Z',
          value: 650,
        },
        {
          date: '2020-10-01T00:00:00Z',
          value: 1630,
        },
      ],
    },
    difot: {
      weightedAverage: {
        current: 0.854,
        startOfPeriod: 0.827,
        twelveMonthAverage: 0.869,
        goal: 0.8,
      },
      packerPlants: [
        {
          packerPlantId: '62',
          percent: 0.89,
          isNew: false,
        },
        {
          packerPlantId: '136',
          percent: 0.91,
          isNew: false,
        },
        {
          packerPlantId: '77',
          percent: 0.9,
          isNew: false,
        },
        {
          packerPlantId: '103',
          percent: 0.68,
          isNew: true,
        },
        {
          packerPlantId: '83',
          percent: 0.81,
          isNew: false,
        },
        {
          packerPlantId: '144',
          percent: 0.82,
          isNew: false,
        },
        {
          packerPlantId: '55',
          percent: 0.89,
          isNew: false,
        },
        {
          packerPlantId: '79',
          percent: 0.88,
          isNew: false,
        },
        {
          packerPlantId: '78',
          percent: 0.94,
          isNew: true,
        },
        {
          packerPlantId: '58',
          percent: 0.8,
          isNew: false,
        },
        {
          packerPlantId: '61',
          percent: 0.82,
          isNew: false,
        },
        {
          packerPlantId: '60',
          percent: 0.85,
          isNew: false,
        },
        {
          packerPlantId: '80',
          percent: 0.82,
          isNew: false,
        },
        {
          packerPlantId: '63',
          percent: 0.95,
          isNew: false,
        },
        {
          packerPlantId: '65',
          percent: 0.84,
          isNew: false,
        },
        {
          packerPlantId: '66',
          percent: 0.85,
          isNew: false,
        },
        {
          packerPlantId: '87',
          percent: 0.91,
          isNew: false,
        },
        {
          packerPlantId: '75',
          percent: 0.87,
          isNew: false,
        },
        {
          packerPlantId: '76',
          percent: 0.73,
          isNew: false,
        },
        {
          packerPlantId: '84',
          percent: 0.87,
          isNew: false,
        },
        {
          packerPlantId: '102',
          percent: 0.82,
          isNew: false,
        },
        {
          packerPlantId: '85',
          percent: 0.85,
          isNew: false,
        },
        {
          packerPlantId: '104',
          percent: 0.86,
          isNew: false,
        },
        {
          packerPlantId: '81',
          percent: 0.79,
          isNew: false,
        },
        {
          packerPlantId: '86',
          percent: 0.83,
          isNew: false,
        },
        {
          packerPlantId: '52',
          percent: 0.96,
          isNew: false,
        },
        {
          packerPlantId: '82',
          percent: 0.86,
          isNew: false,
        },
        {
          packerPlantId: '53',
          percent: 0.93,
          isNew: false,
        },
        {
          packerPlantId: '59',
          percent: 0.68,
          isNew: false,
        },
        {
          packerPlantId: '56',
          percent: 0.81,
          isNew: false,
        },
        {
          packerPlantId: '57',
          percent: 0.8,
          isNew: false,
        },
        {
          packerPlantId: '64',
          percent: 0.81,
          isNew: false,
        },
        {
          packerPlantId: '100',
          percent: 0.77,
          isNew: false,
        },
      ],
      byPeriod: [
        {
          date: '2019-01-01T00:00:00Z',
          percent: 0.83,
        },
        {
          date: '2019-02-01T00:00:00Z',
          percent: 0.87,
        },
        {
          date: '2019-03-01T00:00:00Z',
          percent: 0.76,
        },
        {
          date: '2019-04-01T00:00:00Z',
          percent: 0.72,
        },
        {
          date: '2019-05-01T00:00:00Z',
          percent: 0.8,
        },
        {
          date: '2019-06-01T00:00:00Z',
          percent: 0.79,
        },
        {
          date: '2019-07-01T00:00:00Z',
          percent: 0.77,
        },
        {
          date: '2019-08-01T00:00:00Z',
          percent: 0.85,
        },
        {
          date: '2019-09-01T00:00:00Z',
          percent: 0.86,
        },
        {
          date: '2019-10-01T00:00:00Z',
          percent: 0.89,
        },
        {
          date: '2019-11-01T00:00:00Z',
          percent: 0.83,
        },
        {
          date: '2019-12-01T00:00:00Z',
          percent: 0.84,
        },
        {
          date: '2020-01-01T00:00:00Z',
          percent: 0.87,
        },
        {
          date: '2020-02-01T00:00:00Z',
          percent: 0.86,
        },
        {
          date: '2020-03-01T00:00:00Z',
          percent: 0.88,
        },
        {
          date: '2020-04-01T00:00:00Z',
          percent: 0.85,
        },
        {
          date: '2020-05-01T00:00:00Z',
          percent: 0.89,
        },
        {
          date: '2020-06-01T00:00:00Z',
          percent: 0.88,
        },
        {
          date: '2020-07-01T00:00:00Z',
          percent: 0.9,
        },
        {
          date: '2020-08-01T00:00:00Z',
          percent: 0.86,
        },
        {
          date: '2020-09-01T00:00:00Z',
          percent: 0.85,
        },
        {
          date: '2020-10-01T00:00:00Z',
          percent: 0.85,
        },
      ],
    },
    'freshness-days': {
      packerPlants: {
        countInSpec: 10,
        previousCountInSpec: 9,
        packerPlantsNotInSpec: [
          {
            packerPlantId: '57',
            isNew: true,
          },
        ],
      },
      byPeriod: [
        {
          date: '2019-01-01T00:00:00Z',
          value: 6,
        },
        {
          date: '2019-02-01T00:00:00Z',
          value: 2,
        },
        {
          date: '2019-03-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-04-01T00:00:00Z',
          value: 5,
        },
        {
          date: '2019-05-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-06-01T00:00:00Z',
          value: 5,
        },
        {
          date: '2019-07-01T00:00:00Z',
          value: 4,
        },
        {
          date: '2019-08-01T00:00:00Z',
          value: 3,
        },
        {
          date: '2019-09-01T00:00:00Z',
          value: 3,
        },
        {
          date: '2019-10-01T00:00:00Z',
          value: 1,
        },
        {
          date: '2019-11-01T00:00:00Z',
          value: 4,
        },
        {
          date: '2019-12-01T00:00:00Z',
          value: 2,
        },
        {
          date: '2020-01-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-02-01T00:00:00Z',
          value: 3,
        },
        {
          date: '2020-03-01T00:00:00Z',
          value: 1,
        },
        {
          date: '2020-04-01T00:00:00Z',
          value: 1,
        },
        {
          date: '2020-05-01T00:00:00Z',
          value: 4,
        },
        {
          date: '2020-06-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-07-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-08-01T00:00:00Z',
          value: 2,
        },
        {
          date: '2020-09-01T00:00:00Z',
          value: 3,
        },
        {
          date: '2020-10-01T00:00:00Z',
          value: 2,
        },
      ],
    },
    audit: {
      performed: [
        {
          type: 'GMP Slaughter & Deboning',
          completed: {
            total: 16,
            onTime: 16,
          },
          performance: {
            drop: 1,
            increase: 6,
          },
          scoreSummary: {
            approved: 10,
            provisional: 6,
            failed: 0,
          },
          delists: 0,
        },
        {
          type: 'HACCP',
          completed: {
            total: 13,
            onTime: 9,
          },
          performance: {
            drop: 0,
            increase: 3,
          },
          scoreSummary: {
            approved: 17,
            provisional: 4,
            failed: 1,
          },
          delists: 3,
        },
        {
          type: 'BSE Firewalls',
          completed: {
            total: 7,
            onTime: 7,
          },
          performance: {
            drop: 0,
            increase: 7,
          },
          scoreSummary: {
            approved: 5,
            provisional: 1,
            failed: 1,
          },
          delists: 0,
        },
        {
          type: 'Animal Welfare',
          completed: {
            total: 8,
            onTime: 7,
          },
          performance: {
            drop: 2,
            increase: 2,
          },
          scoreSummary: {
            approved: 7,
            provisional: 1,
            failed: 0,
          },
          delists: 1,
        },
        {
          type: 'SWA',
          selfAssessment: {
            submitted: 32,
            onTime: 27,
            outstanding: 1,
          },
          inspection: {
            pass: 16,
            provisional: 6,
            reAudit: 1,
            failed: 1,
          },
          delists: 2,
        },
      ],
      upcoming: [
        {
          type: 'GMP Slaughter & Deboning',
          byPackerPlant: [
            {
              packerPlantId: '78',
              announced: true,
              responsible: 'AusMeat',
              dateScheduledStart: '2020-10-12T00:00:00Z',
              datePreviousAudit: '2020-07-03T00:00:00Z',
              scorePreviousAudit: 'grade A',
              comments: 'Re-scheduled by 90 days due to COVID.',
            },
            {
              packerPlantId: '56',
              announced: true,
              responsible: 'AusMeat',
              dateScheduledStart: '2020-11-22T00:00:00Z',
              datePreviousAudit: '2020-06-15T00:00:00Z',
              scorePreviousAudit: 'grade B',
              comments: 'Re-scheduled by 90 days due to COVID.',
            },
            {
              packerPlantId: '77',
              announced: false,
              responsible: 'AusMeat',
              dateScheduledStart: '2020-11-12T00:00:00Z',
              dateScheduledEnd: '2021-02-12T00:00:00Z',
              datePreviousAudit: '2020-10-03T00:00:00Z',
              scorePreviousAudit: 'grade C',
            },
          ],
        },
        {
          type: 'HACCP',
          byPackerPlant: [
            {
              packerPlantId: '75',
              announced: true,
              responsible: 'AusMeat',
              dateScheduledStart: '2020-10-12T00:00:00Z',
              datePreviousAudit: '2020-07-03T00:00:00Z',
              scorePreviousAudit: 'grade A',
              comments: 'Re-scheduled by 90 days due to COVID.',
            },
          ],
        },
        {
          type: 'BSE Firewalls',
          byPackerPlant: [
            {
              packerPlantId: '57',
              announced: false,
              responsible: 'AusMeat',
              dateScheduledStart: '2020-11-12T00:00:00Z',
              dateScheduledEnd: '2021-02-12T00:00:00Z',
              datePreviousAudit: '2020-10-03T00:00:00Z',
              scorePreviousAudit: 'grade C',
            },
          ],
        },
        {
          type: 'Animal Welfare',
          byPackerPlant: [
            {
              packerPlantId: '81',
              announced: true,
              responsible: 'AusMeat',
              dateScheduledStart: '2020-10-12T00:00:00Z',
              datePreviousAudit: '2020-07-03T00:00:00Z',
              scorePreviousAudit: 'grade A',
              comments: 'Re-scheduled by 90 days due to COVID.',
            },
            {
              packerPlantId: '144',
              announced: true,
              responsible: 'AusMeat',
              dateScheduledStart: '2020-11-22T00:00:00Z',
              datePreviousAudit: '2020-06-15T00:00:00Z',
              scorePreviousAudit: 'grade B',
            },
          ],
        },
        {
          type: 'SWA',
          byPackerPlant: [
            {
              packerPlantId: '82',
              announced: false,
              responsible: 'AusMeat',
              dateScheduledStart: '2020-11-12T00:00:00Z',
              dateScheduledEnd: '2021-02-12T00:00:00Z',
              datePreviousAudit: '2020-10-03T00:00:00Z',
              scorePreviousAudit: 'pass',
            },
          ],
        },
      ],
    },
  },
  SINGLE: {
    'packer-plant-scores': {
      scores: [
        // {
        //   packerPlantId: '62',
        //   volume: 472,
        //   score: 90,
        // },
        // {
        //   packerPlantId: '136',
        //   volume: 202,
        //   score: 7,
        // },
        // {
        //   packerPlantId: '77',
        //   volume: 943,
        //   score: 82,
        // },
        // {
        //   packerPlantId: '103',
        //   volume: 833,
        //   score: 10,
        // },
        // {
        //   packerPlantId: '83',
        //   volume: 362,
        //   score: 51,
        // },
        // {
        //   packerPlantId: '144',
        //   volume: 122,
        //   score: 51,
        // },
        // {
        //   packerPlantId: '55',
        //   volume: 1200,
        //   score: 84,
        // },
        // {
        //   packerPlantId: '79',
        //   volume: 1100,
        //   score: 58,
        // },
        // {
        //   packerPlantId: '78',
        //   volume: 300,
        //   score: 52,
        // },
        // {
        //   packerPlantId: '58',
        //   volume: 50,
        //   score: 93,
        // },
        // {
        //   packerPlantId: '61',
        //   volume: 102,
        //   score: 40,
        // },
        // {
        //   packerPlantId: '60',
        //   volume: 566,
        //   score: 55,
        // },
        // {
        //   packerPlantId: '80',
        //   volume: 20000,
        //   score: 82,
        // },
        // {
        //   packerPlantId: '63',
        //   volume: 762,
        //   score: 15,
        // },
        // {
        //   packerPlantId: '65',
        //   volume: 2000,
        //   score: 64,
        // },
        // {
        //   packerPlantId: '66',
        //   volume: 20,
        //   score: 8,
        // },
        // {
        //   packerPlantId: '87',
        //   volume: 122,
        //   score: 61,
        // },
        // {
        //   packerPlantId: '75',
        //   volume: 776,
        //   score: 57,
        // },
        // {
        //   packerPlantId: '76',
        //   volume: 822,
        //   score: 68,
        // },
        // {
        //   packerPlantId: '84',
        //   volume: 629,
        //   score: 10,
        // },
        // {
        //   packerPlantId: '102',
        //   volume: 720,
        //   score: 42,
        // },
        // {
        //   packerPlantId: '85',
        //   volume: 911,
        //   score: 55,
        // },
        // {
        //   packerPlantId: '104',
        //   volume: 129,
        //   score: 22,
        // },
        // {
        //   packerPlantId: '81',
        //   volume: 529,
        //   score: 79,
        // },
        // {
        //   packerPlantId: '86',
        //   volume: 926,
        //   score: 33,
        // },
        // {
        //   packerPlantId: '52',
        //   volume: 4000,
        //   score: 96,
        // },
        // {
        //   packerPlantId: '82',
        //   volume: 1300,
        //   score: 60,
        // },
        // {
        //   packerPlantId: '53',
        //   volume: 10100,
        //   score: 93,
        // },
        // {
        //   packerPlantId: '59',
        //   volume: 1200,
        //   score: 58,
        // },
        // {
        //   packerPlantId: '56',
        //   volume: 1200,
        //   score: 41,
        // },
        // {
        //   packerPlantId: '57',
        //   volume: 100,
        //   score: 50,
        // },
        // {
        //   packerPlantId: '64',
        //   volume: 199,
        //   score: 81,
        // },
        // {
        //   packerPlantId: '100',
        //   volume: 633,
        //   score: 59,
        // },
      ],
      rankByPeriod: [
        {
          date: '2019-01-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2019-02-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2019-03-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2019-04-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2019-05-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2019-06-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2019-07-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2019-08-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2019-09-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2019-10-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2019-11-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2019-12-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2020-01-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2020-02-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2020-03-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2020-04-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2020-05-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2020-06-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2020-07-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2020-08-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2020-09-01T00:00:00Z',
          value: 0,
          total: 0,
        },
        {
          date: '2020-10-01T00:00:00Z',
          value: 0,
          total: 0,
        },
      ],
      scoreByPeriod: [
        {
          date: '2019-01-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-02-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-03-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-04-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-05-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-06-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-07-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-08-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-09-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-10-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-11-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-12-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-01-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-02-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-03-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-04-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-05-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-06-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-07-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-08-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-09-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-10-01T00:00:00Z',
          value: 0,
        },
      ],
    },
    packerPlantHistory: {
      dateApproval: '2018-09-12T00:00:00Z',
      dateOnboardingCompleted: '2018-12-17T00:00:00Z',
      // Period types are:
      // * YEAR_FROM_TO: 2018-2019
      // * MONTH_FROM_TO: Feb 2016-now
      // * MONTH: Sep 2018
      // * YEAR: 2020
      // * IN_YEAR: in 2019
      keyAchievements: [
        // {
        //   category: 'Volume',
        //   achievement: 'Top 3 highest supplier',
        //   periodType: 'YEAR_FROM_TO',
        //   period: {
        //     from: '2018-01-01T00:00:00Z',
        //     to: '2020-01-01T00:00:00Z',
        //   },
        // },
        // {
        //   category: 'Packer Plant ranking',
        //   achievement: 'Highest ranking: 3 of 28',
        //   periodType: 'MONTH',
        //   period: '2019-09-01T00:00:00Z',
        // },
        // {
        //   category: 'Packer Plant ranking',
        //   achievement: 'Top 5 ranking for 10 months',
        //   periodType: 'IN_YEAR',
        //   period: '2019-01-01T00:00:00Z',
        // },
        // {
        //   category: 'NCRs',
        //   achievement: '<1% of incidents for 5 months',
        //   periodType: 'IN_YEAR',
        //   period: '2019-01-01T00:00:00Z',
        // },
        // {
        //   category: 'Claims',
        //   achievement: 'Only 12 claims ($347)',
        //   periodType: 'YEAR',
        //   period: '2020-01-01T00:00:00Z',
        // },
        // {
        //   category: 'Audits',
        //   achievement: 'Met all deadlines',
        //   periodType: 'YEAR_FROM_TO',
        //   period: {
        //     from: '2018-01-01T00:00:00Z',
        //     to: '2020-01-01T00:00:00Z',
        //   },
        // },
        // {
        //   category: 'DIFOT',
        //   achievement: '97% specification',
        //   periodType: 'MONTH_FROM_TO',
        //   period: {
        //     from: '2016-02-01T00:00:00Z',
        //   },
        // },
        // {
        //   category: 'Freshness days',
        //   achievement: '97% specification',
        //   periodType: 'MONTH_FROM_TO',
        //   period: {
        //     from: '2016-02-01T00:00:00Z',
        //   },
        // },
      ],
      additionalNotes: [
        // 'Sharp increase in Spot price purchases over the last 6 months.'
      ],
    },
    ncrs: {
      incidents: {
        count: 5,
        previousCount: 3,
        byPeriod: [
          {
            date: '2019-01-01T00:00:00Z',
            value: 8,
          },
          {
            date: '2019-02-01T00:00:00Z',
            value: 2,
          },
          {
            date: '2019-03-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2019-04-01T00:00:00Z',
            value: 9,
          },
          {
            date: '2019-05-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2019-06-01T00:00:00Z',
            value: 9,
          },
          {
            date: '2019-07-01T00:00:00Z',
            value: 8,
          },
          {
            date: '2019-08-01T00:00:00Z',
            value: 6,
          },
          {
            date: '2019-09-01T00:00:00Z',
            value: 7,
          },
          {
            date: '2019-10-01T00:00:00Z',
            value: 5,
          },
          {
            date: '2019-11-01T00:00:00Z',
            value: 2,
          },
          {
            date: '2019-12-01T00:00:00Z',
            value: 4,
          },
          {
            date: '2020-01-01T00:00:00Z',
            value: 8,
          },
          {
            date: '2020-02-01T00:00:00Z',
            value: 2,
          },
          {
            date: '2020-03-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-04-01T00:00:00Z',
            value: 9,
          },
          {
            date: '2020-05-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-06-01T00:00:00Z',
            value: 9,
          },
          {
            date: '2020-07-01T00:00:00Z',
            value: 8,
          },
          {
            date: '2020-08-01T00:00:00Z',
            value: 6,
          },
          {
            date: '2020-09-01T00:00:00Z',
            value: 7,
          },
          {
            date: '2020-10-01T00:00:00Z',
            value: 2,
          },
        ],
      },
      categories: [
        {
          category: 'Foreign Material - External',
          subCategories: [
            {
              subCategory: 'Soft Plastic (Blue)',
              count: 3,
              withClaimValue: 2,
              claimAmount: 100,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 3,
              previousWithClaimValue: 2,
              previousClaimAmount: 75,
            },
            {
              subCategory: 'Metal - Other',
              count: 1,
              withClaimValue: 0,
              claimAmount: 0,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 1,
              previousWithClaimValue: 0,
              previousClaimAmount: 0,
            },
          ],
        },
        {
          category: 'Quality',
          subCategories: [
            {
              subCategory: 'Transportation Issue',
              count: 1,
              withClaimValue: 0,
              claimAmount: 0,
              incidentRate: 0.012,
              previousIncidentRate: 0.009,
              previousCount: 2,
              previousWithClaimValue: 1,
              previousClaimAmount: 60,
            },
          ],
        },
      ],
      volume: {
        percentageOfTotal: 0.0194,
        count: 64,
        previousCount: 58,
        byPeriod: [
          {
            date: '2019-01-01T00:00:00Z',
            value: 0.008,
          },
          {
            date: '2019-02-01T00:00:00Z',
            value: 0.002,
          },
          {
            date: '2019-03-01T00:00:00Z',
            value: 0.0,
          },
          {
            date: '2019-04-01T00:00:00Z',
            value: 0.009,
          },
          {
            date: '2019-05-01T00:00:00Z',
            value: 0.0,
          },
          {
            date: '2019-06-01T00:00:00Z',
            value: 0.009,
          },
          {
            date: '2019-07-01T00:00:00Z',
            value: 0.008,
          },
          {
            date: '2019-08-01T00:00:00Z',
            value: 0.006,
          },
          {
            date: '2019-09-01T00:00:00Z',
            value: 0.007,
          },
          {
            date: '2019-10-01T00:00:00Z',
            value: 0.006,
          },
          {
            date: '2019-11-01T00:00:00Z',
            value: 0.008,
          },
          {
            date: '2019-12-01T00:00:00Z',
            value: 0.016,
          },
          {
            date: '2020-01-01T00:00:00Z',
            value: 0.012,
          },
          {
            date: '2020-02-01T00:00:00Z',
            value: 0.019,
          },
          {
            date: '2020-03-01T00:00:00Z',
            value: 0.027,
          },
          {
            date: '2020-04-01T00:00:00Z',
            value: 0.025,
          },
          {
            date: '2020-05-01T00:00:00Z',
            value: 0.024,
          },
          {
            date: '2020-06-01T00:00:00Z',
            value: 0.02,
          },
          {
            date: '2020-07-01T00:00:00Z',
            value: 0.013,
          },
          {
            date: '2020-08-01T00:00:00Z',
            value: 0.011,
          },
          {
            date: '2020-09-01T00:00:00Z',
            value: 0.019,
          },
          {
            date: '2020-10-01T00:00:00Z',
            value: 0.0194,
          },
        ],
      },
      dollars: {
        count: 163,
        previousCount: 80,
        byPeriod: [
          {
            date: '2019-01-01T00:00:00Z',
            value: 86,
          },
          {
            date: '2019-02-01T00:00:00Z',
            value: 21,
          },
          {
            date: '2019-03-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2019-04-01T00:00:00Z',
            value: 90,
          },
          {
            date: '2019-05-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2019-06-01T00:00:00Z',
            value: 95,
          },
          {
            date: '2019-07-01T00:00:00Z',
            value: 88,
          },
          {
            date: '2019-08-01T00:00:00Z',
            value: 67,
          },
          {
            date: '2019-09-01T00:00:00Z',
            value: 71,
          },
          {
            date: '2019-10-01T00:00:00Z',
            value: 65,
          },
          {
            date: '2019-11-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2019-12-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-01-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-02-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-03-01T00:00:00Z',
            value: 160,
          },
          {
            date: '2020-04-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-05-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-06-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-07-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-08-01T00:00:00Z',
            value: 60,
          },
          {
            date: '2020-09-01T00:00:00Z',
            value: 0,
          },
          {
            date: '2020-10-01T00:00:00Z',
            value: 163,
          },
        ],
      },
      packerPlantsTotal: [
        {
          packerPlantId: '62',
          count: 90,
          claimAmount: 3000,
        },
        {
          packerPlantId: '136',
          count: 7,
          claimAmount: 1000,
        },
        {
          packerPlantId: '77',
          count: 82,
          claimAmount: 1000,
        },
        {
          packerPlantId: '103',
          count: 10,
          claimAmount: 100,
        },
        {
          packerPlantId: '83',
          count: 51,
          claimAmount: 3000,
        },
        {
          packerPlantId: '144',
          count: 51,
          claimAmount: 5200,
        },
        {
          packerPlantId: '55',
          count: 84,
          claimAmount: 3000,
        },
        {
          packerPlantId: '79',
          count: 58,
          claimAmount: 2200,
        },
        {
          packerPlantId: '78',
          count: 52,
          claimAmount: 4400,
        },
        {
          packerPlantId: '58',
          count: 93,
          claimAmount: 400,
        },
        {
          packerPlantId: '61',
          count: 40,
          claimAmount: 300,
        },
        {
          packerPlantId: '60',
          count: 55,
          claimAmount: 300,
        },
        {
          packerPlantId: '80',
          count: 82,
          claimAmount: 2000,
        },
        {
          packerPlantId: '63',
          count: 15,
          claimAmount: 3000,
        },
        {
          packerPlantId: '65',
          count: 64,
          claimAmount: 3000,
        },
        {
          packerPlantId: '66',
          count: 8,
          claimAmount: 3000,
        },
        {
          packerPlantId: '87',
          count: 61,
          claimAmount: 3000,
        },
        {
          packerPlantId: '75',
          count: 57,
          claimAmount: 4000,
        },
        {
          packerPlantId: '76',
          count: 68,
          claimAmount: 4000,
        },
        {
          packerPlantId: '84',
          count: 10,
          claimAmount: 3000,
        },
        {
          packerPlantId: '102',
          count: 42,
          claimAmount: 500,
        },
        {
          packerPlantId: '85',
          count: 55,
          claimAmount: 0,
        },
        {
          packerPlantId: '104',
          count: 22,
          claimAmount: 0,
        },
        {
          packerPlantId: '81',
          count: 79,
          claimAmount: 0,
        },
        {
          packerPlantId: '86',
          count: 33,
          claimAmount: 100,
        },
        {
          packerPlantId: '52',
          count: 96,
          claimAmount: 3300,
        },
        {
          packerPlantId: '82',
          count: 60,
          claimAmount: 3000,
        },
        {
          packerPlantId: '53',
          count: 93,
          claimAmount: 400,
        },
        {
          packerPlantId: '59',
          count: 58,
          claimAmount: 700,
        },
        {
          packerPlantId: '56',
          count: 41,
          claimAmount: 5000,
        },
        {
          packerPlantId: '57',
          count: 50,
          claimAmount: 2800,
        },
        {
          packerPlantId: '64',
          count: 81,
          claimAmount: 200,
        },
        {
          packerPlantId: '100',
          count: 59,
          claimAmount: 3500,
        },
      ],
      summary: {
        incidents: {
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 25,
            },
            {
              category: 'Quality',
              count: 12,
            },
            {
              category: 'Foreign Material - Processing',
              count: 4,
            },
            {
              category: 'Packaging',
              count: 21,
            },
            {
              category: 'Microbiological',
              count: 1,
            },
            {
              category: 'Infestation',
              count: 2,
            },
          ],
        },
        volume: {
          percentageOfTotal: 0.0095,
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 2962,
            },
            {
              category: 'Quality',
              count: 695,
            },
            {
              category: 'Foreign Material - Processing',
              count: 94,
            },
            {
              category: 'Packaging',
              count: 25,
            },
            {
              category: 'Microbiological',
              count: 140,
            },
            {
              category: 'Infestation',
              count: 28,
            },
          ],
        },
        dollars: {
          byCategory: [
            {
              category: 'Foreign Material - External',
              count: 862,
            },
            {
              category: 'Quality',
              count: 396,
            },
            {
              category: 'Foreign Material - Processing',
              count: 400,
            },
            {
              category: 'Packaging',
              count: 30,
            },
            {
              category: 'Microbiological',
              count: 100,
            },
            {
              category: 'Infestation',
              count: 84,
            },
          ],
        },
      },
      exports: {
        volume: 31350,
        percentOfTotal: 0.0192,
        domesticVolume: 778700,
        exportVolume: 1900000,
        byCountry: [
          {
            volume: 28215,
            country: 'USA',
            shippedDateRange: ['2020-12-03T00:00:00Z', '2020-12-08T00:00:00Z'],
            category: 'Foreign Material - External (Hard plastic)',
          },
          {
            volume: 2194,
            country: 'Japan',
            shippedDateRange: ['2020-12-03T00:00:00Z', '2020-12-08T00:00:00Z'],
            category: 'Foreign Material - External (Hard plastic)',
          },
          {
            volume: 940,
            country: 'China',
            shippedDateRange: ['2020-10-13T00:00:00Z', '2020-10-15T00:00:00Z'],
            category: 'No temp loggers',
          },
        ],
        byPeriod: [
          {
            date: '2019-01-01T00:00:00Z',
            percentMarketDomestic: 0.12,
            percentTotalDomestic: 0.027,
            percentMarketExport: 0.23,
            percentTotalExport: 0.012,
          },
          {
            date: '2019-02-01T00:00:00Z',
            percentMarketDomestic: 0.14,
            percentTotalDomestic: 0.025,
            percentMarketExport: 0.21,
            percentTotalExport: 0.027,
          },
          {
            date: '2019-03-01T00:00:00Z',
            percentMarketDomestic: 0.28,
            percentTotalDomestic: 0.019,
            percentMarketExport: 0.11,
            percentTotalExport: 0.024,
          },
          {
            date: '2019-04-01T00:00:00Z',
            percentMarketDomestic: 0.21,
            percentTotalDomestic: 0.016,
            percentMarketExport: 0.19,
            percentTotalExport: 0.016,
          },
          {
            date: '2019-05-01T00:00:00Z',
            percentMarketDomestic: 0.17,
            percentTotalDomestic: 0.012,
            percentMarketExport: 0.14,
            percentTotalExport: 0.018,
          },
          {
            date: '2019-06-01T00:00:00Z',
            percentMarketDomestic: 0.11,
            percentTotalDomestic: 0.017,
            percentMarketExport: 0.17,
            percentTotalExport: 0.018,
          },
          {
            date: '2019-07-01T00:00:00Z',
            percentMarketDomestic: 0.9,
            percentTotalDomestic: 0.015,
            percentMarketExport: 0.17,
            percentTotalExport: 0.012,
          },
          {
            date: '2019-08-01T00:00:00Z',
            percentMarketDomestic: 0.13,
            percentTotalDomestic: 0.012,
            percentMarketExport: 0.9,
            percentTotalExport: 0.009,
          },
          {
            date: '2019-09-01T00:00:00Z',
            percentMarketDomestic: 0.15,
            percentTotalDomestic: 0.023,
            percentMarketExport: 0.25,
            percentTotalExport: 0.019,
          },
          {
            date: '2019-10-01T00:00:00Z',
            percentMarketDomestic: 0.12,
            percentTotalDomestic: 0.027,
            percentMarketExport: 0.12,
            percentTotalExport: 0.018,
          },
          {
            date: '2019-11-01T00:00:00Z',
            percentMarketDomestic: 0.2,
            percentTotalDomestic: 0.024,
            percentMarketExport: 0.2,
            percentTotalExport: 0.019,
          },
          {
            date: '2019-12-01T00:00:00Z',
            percentMarketDomestic: 0.11,
            percentTotalDomestic: 0.019,
            percentMarketExport: 0.21,
            percentTotalExport: 0.017,
          },
          {
            date: '2020-01-01T00:00:00Z',
            percentMarketDomestic: 0.18,
            percentTotalDomestic: 0.011,
            percentMarketExport: 0.19,
            percentTotalExport: 0.025,
          },
          {
            date: '2020-02-01T00:00:00Z',
            percentMarketDomestic: 0.1,
            percentTotalDomestic: 0.015,
            percentMarketExport: 0.16,
            percentTotalExport: 0.018,
          },
          {
            date: '2020-03-01T00:00:00Z',
            percentMarketDomestic: 0.8,
            percentTotalDomestic: 0.016,
            percentMarketExport: 0.12,
            percentTotalExport: 0.018,
          },
          {
            date: '2020-04-01T00:00:00Z',
            percentMarketDomestic: 0.2,
            percentTotalDomestic: 0.016,
            percentMarketExport: 0.26,
            percentTotalExport: 0.023,
          },
          {
            date: '2020-05-01T00:00:00Z',
            percentMarketDomestic: 0.14,
            percentTotalDomestic: 0.012,
            percentMarketExport: 0.23,
            percentTotalExport: 0.02,
          },
          {
            date: '2020-06-01T00:00:00Z',
            percentMarketDomestic: 0.16,
            percentTotalDomestic: 0.016,
            percentMarketExport: 0.12,
            percentTotalExport: 0.0008,
          },
          {
            date: '2020-07-01T00:00:00Z',
            percentMarketDomestic: 0.2,
            percentTotalDomestic: 0.018,
            percentMarketExport: 0.15,
            percentTotalExport: 0.009,
          },
          {
            date: '2020-08-01T00:00:00Z',
            percentMarketDomestic: 0.16,
            percentTotalDomestic: 0.021,
            percentMarketExport: 0.24,
            percentTotalExport: 0.011,
          },
          {
            date: '2020-09-01T00:00:00Z',
            percentMarketDomestic: 0.2,
            percentTotalDomestic: 0.024,
            percentMarketExport: 0.22,
            percentTotalExport: 0.013,
          },
          {
            date: '2020-10-01T00:00:00Z',
            percentMarketDomestic: 0.22,
            percentTotalDomestic: 0.023,
            percentMarketExport: 0.23,
            percentTotalExport: 0.011,
          },
        ],
      },
    },
    claims: {
      submitted: {
        count: 0,
        amount: 0,
        previousPeriod: {
          count: 0,
          amount: 0,
        },
        yearToDate: {
          count: 0,
          amount: 0,
          previousPeriod: {
            count: 0,
            amount: 0,
          },
        },
      },
      finalised: {
        count: 0,
        amount: 0,
        yearToDate: {
          count: 0,
          amount: 0,
        },
      },
      outstanding: {
        yearToDate: {
          count: 0,
          amount: 0,
        },
      },
      categories: [
        // {
        //   category: 'CL',
        //   count: 5,
        //   claimAmount: 210,
        //   incidentRate: 0.012,
        //   previousIncidentRate: 0.009,
        //   previousCount: 75,
        //   previousClaimAmount: 210,
        // },
        // {
        //   category: 'FO Pre',
        //   count: 10,
        //   claimAmount: 1102,
        //   incidentRate: 0.012,
        //   previousIncidentRate: 0.009,
        //   previousCount: 9,
        //   previousClaimAmount: 900,
        // },
        // {
        //   category: 'Other',
        //   count: 0,
        //   claimAmount: 80,
        //   incidentRate: 0.012,
        //   previousIncidentRate: 0.009,
        //   previousCount: 7,
        //   previousClaimAmount: 80,
        // },
        // {
        //   category: 'FO Post',
        //   count: 3,
        //   claimAmount: 564,
        //   incidentRate: 0.012,
        //   previousIncidentRate: 0.009,
        //   previousCount: 1,
        //   previousClaimAmount: 233,
        // },
        // {
        //   category: 'Returned product',
        //   count: 0,
        //   claimAmount: 0,
        //   incidentRate: 0.012,
        //   previousIncidentRate: 0.009,
        //   previousCount: 1,
        //   previousClaimAmount: 120,
        // },
      ],
      countByPeriod: [
        {
          date: '2019-01-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-02-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-03-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-04-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-05-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-06-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-07-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-08-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-09-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-10-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-11-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-12-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-01-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-02-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-03-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-04-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-05-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-06-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-07-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-08-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-09-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-10-01T00:00:00Z',
          value: 0,
        },
      ],
      amountByPeriod: [
        {
          date: '2019-01-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-02-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-03-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-04-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-05-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-06-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-07-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-08-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-09-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-10-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-11-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2019-12-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-01-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-02-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-03-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-04-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-05-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-06-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-07-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-08-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-09-01T00:00:00Z',
          value: 0,
        },
        {
          date: '2020-10-01T00:00:00Z',
          value: 0,
        },
      ],
      packerPlantsTotal: [
        // {
        //   packerPlantId: '62',
        //   count: 90,
        //   claimAmount: 3000,
        // },
        // {
        //   packerPlantId: '136',
        //   count: 7,
        //   claimAmount: 1000,
        // },
        // {
        //   packerPlantId: '77',
        //   count: 82,
        //   claimAmount: 1000,
        // },
        // {
        //   packerPlantId: '103',
        //   count: 10,
        //   claimAmount: 100,
        // },
        // {
        //   packerPlantId: '83',
        //   count: 51,
        //   claimAmount: 3000,
        // },
        // {
        //   packerPlantId: '144',
        //   count: 51,
        //   claimAmount: 5267,
        // },
        // {
        //   packerPlantId: '55',
        //   count: 84,
        //   claimAmount: 3000,
        // },
        // {
        //   packerPlantId: '79',
        //   count: 58,
        //   claimAmount: 2200,
        // },
        // {
        //   packerPlantId: '78',
        //   count: 52,
        //   claimAmount: 4400,
        // },
        // {
        //   packerPlantId: '58',
        //   count: 93,
        //   claimAmount: 400,
        // },
        // {
        //   packerPlantId: '61',
        //   count: 40,
        //   claimAmount: 300,
        // },
        // {
        //   packerPlantId: '60',
        //   count: 55,
        //   claimAmount: 300,
        // },
        // {
        //   packerPlantId: '80',
        //   count: 82,
        //   claimAmount: 2000,
        // },
        // {
        //   packerPlantId: '63',
        //   count: 15,
        //   claimAmount: 3000,
        // },
        // {
        //   packerPlantId: '65',
        //   count: 64,
        //   claimAmount: 3000,
        // },
        // {
        //   packerPlantId: '66',
        //   count: 8,
        //   claimAmount: 3000,
        // },
        // {
        //   packerPlantId: '87',
        //   count: 61,
        //   claimAmount: 3000,
        // },
        // {
        //   packerPlantId: '75',
        //   count: 57,
        //   claimAmount: 4000,
        // },
        // {
        //   packerPlantId: '76',
        //   count: 68,
        //   claimAmount: 4000,
        // },
        // {
        //   packerPlantId: '84',
        //   count: 10,
        //   claimAmount: 3000,
        // },
        // {
        //   packerPlantId: '102',
        //   count: 42,
        //   claimAmount: 500,
        // },
        // {
        //   packerPlantId: '85',
        //   count: 55,
        //   claimAmount: 100,
        // },
        // {
        //   packerPlantId: '104',
        //   count: 22,
        //   claimAmount: 100,
        // },
        // {
        //   packerPlantId: '81',
        //   count: 79,
        //   claimAmount: 100,
        // },
        // {
        //   packerPlantId: '86',
        //   count: 33,
        //   claimAmount: 100,
        // },
        // {
        //   packerPlantId: '52',
        //   count: 96,
        //   claimAmount: 3300,
        // },
        // {
        //   packerPlantId: '82',
        //   count: 60,
        //   claimAmount: 3000,
        // },
        // {
        //   packerPlantId: '53',
        //   count: 93,
        //   claimAmount: 400,
        // },
        // {
        //   packerPlantId: '59',
        //   count: 58,
        //   claimAmount: 700,
        // },
        // {
        //   packerPlantId: '56',
        //   count: 41,
        //   claimAmount: 5000,
        // },
        // {
        //   packerPlantId: '57',
        //   count: 50,
        //   claimAmount: 2800,
        // },
        // {
        //   packerPlantId: '64',
        //   count: 81,
        //   claimAmount: 200,
        // },
        // {
        //   packerPlantId: '100',
        //   count: 59,
        //   claimAmount: 3500,
        // },
      ],
    },
    difot: {
      weightedAverage: {
        current: 0.854,
        startOfPeriod: 0.827,
        twelveMonthAverage: 0.869,
        goal: 0.8,
      },
      packerPlants: [
        {
          packerPlantId: '62',
          percent: 0.89,
          isNew: false,
        },
        {
          packerPlantId: '136',
          percent: 0.91,
          isNew: false,
        },
        {
          packerPlantId: '77',
          percent: 0.9,
          isNew: false,
        },
        {
          packerPlantId: '103',
          percent: 0.68,
          isNew: true,
        },
        {
          packerPlantId: '83',
          percent: 0.81,
          isNew: false,
        },
        {
          packerPlantId: '144',
          percent: 0.82,
          isNew: false,
        },
        {
          packerPlantId: '55',
          percent: 0.89,
          isNew: false,
        },
        {
          packerPlantId: '79',
          percent: 0.88,
          isNew: false,
        },
        {
          packerPlantId: '78',
          percent: 0.94,
          isNew: true,
        },
        {
          packerPlantId: '58',
          percent: 0.8,
          isNew: false,
        },
        {
          packerPlantId: '61',
          percent: 0.82,
          isNew: false,
        },
        {
          packerPlantId: '60',
          percent: 0.85,
          isNew: false,
        },
        {
          packerPlantId: '80',
          percent: 0.82,
          isNew: false,
        },
        {
          packerPlantId: '63',
          percent: 0.95,
          isNew: false,
        },
        {
          packerPlantId: '65',
          percent: 0.84,
          isNew: false,
        },
        {
          packerPlantId: '66',
          percent: 0.85,
          isNew: false,
        },
        {
          packerPlantId: '87',
          percent: 0.91,
          isNew: false,
        },
        {
          packerPlantId: '75',
          percent: 0.87,
          isNew: false,
        },
        {
          packerPlantId: '76',
          percent: 0.73,
          isNew: false,
        },
        {
          packerPlantId: '84',
          percent: 0.87,
          isNew: false,
        },
        {
          packerPlantId: '102',
          percent: 0.82,
          isNew: false,
        },
        {
          packerPlantId: '85',
          percent: 0.85,
          isNew: false,
        },
        {
          packerPlantId: '104',
          percent: 0.86,
          isNew: false,
        },
        {
          packerPlantId: '81',
          percent: 0.79,
          isNew: false,
        },
        {
          packerPlantId: '86',
          percent: 0.83,
          isNew: false,
        },
        {
          packerPlantId: '52',
          percent: 0.96,
          isNew: false,
        },
        {
          packerPlantId: '82',
          percent: 0.86,
          isNew: false,
        },
        {
          packerPlantId: '53',
          percent: 0.93,
          isNew: false,
        },
        {
          packerPlantId: '59',
          percent: 0.68,
          isNew: false,
        },
        {
          packerPlantId: '56',
          percent: 0.81,
          isNew: false,
        },
        {
          packerPlantId: '57',
          percent: 0.8,
          isNew: false,
        },
        {
          packerPlantId: '64',
          percent: 0.81,
          isNew: false,
        },
        {
          packerPlantId: '100',
          percent: 0.77,
          isNew: false,
        },
      ],
      byPeriod: [
        {
          date: '2019-01-01T00:00:00Z',
          percent: 0.83,
        },
        {
          date: '2019-02-01T00:00:00Z',
          percent: 0.87,
        },
        {
          date: '2019-03-01T00:00:00Z',
          percent: 0.76,
        },
        {
          date: '2019-04-01T00:00:00Z',
          percent: 0.72,
        },
        {
          date: '2019-05-01T00:00:00Z',
          percent: 0.8,
        },
        {
          date: '2019-06-01T00:00:00Z',
          percent: 0.79,
        },
        {
          date: '2019-07-01T00:00:00Z',
          percent: 0.77,
        },
        {
          date: '2019-08-01T00:00:00Z',
          percent: 0.85,
        },
        {
          date: '2019-09-01T00:00:00Z',
          percent: 0.86,
        },
        {
          date: '2019-10-01T00:00:00Z',
          percent: 0.89,
        },
        {
          date: '2019-11-01T00:00:00Z',
          percent: 0.83,
        },
        {
          date: '2019-12-01T00:00:00Z',
          percent: 0.84,
        },
        {
          date: '2020-01-01T00:00:00Z',
          percent: 0.87,
        },
        {
          date: '2020-02-01T00:00:00Z',
          percent: 0.86,
        },
        {
          date: '2020-03-01T00:00:00Z',
          percent: 0.88,
        },
        {
          date: '2020-04-01T00:00:00Z',
          percent: 0.85,
        },
        {
          date: '2020-05-01T00:00:00Z',
          percent: 0.89,
        },
        {
          date: '2020-06-01T00:00:00Z',
          percent: 0.88,
        },
        {
          date: '2020-07-01T00:00:00Z',
          percent: 0.9,
        },
        {
          date: '2020-08-01T00:00:00Z',
          percent: 0.86,
        },
        {
          date: '2020-09-01T00:00:00Z',
          percent: 0.85,
        },
        {
          date: '2020-10-01T00:00:00Z',
          percent: 0.85,
        },
      ],
    },
    'freshness-days': {
      isInSpec: true,
      byPeriod: [
        {
          date: '2019-01-01T00:00:00Z',
          value: true,
        },
        {
          date: '2019-02-01T00:00:00Z',
          value: false,
        },
        {
          date: '2019-03-01T00:00:00Z',
          value: true,
        },
        {
          date: '2019-04-01T00:00:00Z',
          value: true,
        },
        {
          date: '2019-05-01T00:00:00Z',
          value: true,
        },
        {
          date: '2019-06-01T00:00:00Z',
          value: true,
        },
        {
          date: '2019-07-01T00:00:00Z',
          value: true,
        },
        {
          date: '2019-08-01T00:00:00Z',
          value: true,
        },
        {
          date: '2019-09-01T00:00:00Z',
          value: true,
        },
        {
          date: '2019-10-01T00:00:00Z',
          isInSpec: true,
        },
        {
          date: '2019-11-01T00:00:00Z',
          isInSpec: true,
        },
        {
          date: '2019-12-01T00:00:00Z',
          isInSpec: true,
        },
        {
          date: '2020-01-01T00:00:00Z',
          isInSpec: true,
        },
        {
          date: '2020-02-01T00:00:00Z',
          isInSpec: true,
        },
        {
          date: '2020-03-01T00:00:00Z',
          isInSpec: true,
        },
        {
          date: '2020-04-01T00:00:00Z',
          isInSpec: true,
        },
        {
          date: '2020-05-01T00:00:00Z',
          isInSpec: false,
        },
        {
          date: '2020-06-01T00:00:00Z',
          isInSpec: true,
        },
        {
          date: '2020-07-01T00:00:00Z',
          isInSpec: true,
        },
        {
          date: '2020-08-01T00:00:00Z',
          isInSpec: true,
        },
        {
          date: '2020-09-01T00:00:00Z',
          isInSpec: true,
        },
        {
          date: '2020-10-01T00:00:00Z',
          isInSpec: true,
        },
      ],
    },
    auditSinglePackerPlant: {
      performed: [
        // {
        //   type: 'GMP Slaughter & Deboning',
        //   subType: 're-audit',
        //   announced: true,
        //   responsible: 'AusMeat',
        //   dateCompleted: '2020-10-12T00:00:00Z',
        //   datePreviousAudit: '2020-07-03T00:00:00Z',
        //   score: 'grade A',
        //   comments: 'Re-scheduled by 90 days due to COVID.',
        // },
        // {
        //   type: 'HACCP',
        //   announced: false,
        //   responsible: 'AusMeat',
        //   dateCompleted: '2020-10-12T00:00:00Z',
        //   datePreviousAudit: '2020-09-14T00:00:00Z',
        //   performance: 'drop',
        //   score: 'grade B',
        // },
      ],
      upcoming: [
        // {
        //   type: 'HACCP',
        //   subType: 'audit lapse',
        //   dateScheduledStart: '2021-02-12T00:00:00Z',
        // },
        // {
        //   type: 'Animal Welfare',
        //   subType: 're-audit',
        //   dateScheduledStart: '2020-10-12T00:00:00Z',
        //   dateScheduledEnd: '2021-02-12T00:00:00Z',
        // },
      ],
    },
  },
};

async function requestSection({ section, timeScale, timePeriod, type, packerPlant, grinderId, market }) {

  const query = {
    time_scale: timeScale,
    date: convertToLocalISOString(timePeriod[0]),
    country_tab: market,
    product_type: Constants.PRODUCT_TYPE_LIST.BEEF,
    grinder_id: grinderId,
  };
  if (type === REQUEST_TYPES.MARKET && packerPlant.id !== PACKER_PLANTS_ALL) {
    query.packer_country = packerPlant.id;
  }
  if (type === REQUEST_TYPES.SINGLE) {
    query.packer_plant_id = packerPlant.id;
  }
  if (type === REQUEST_TYPES.PACKER) {
    query.packer_id = packerPlant.id;
  }

  if (
    [
      'freshness-days',
      'difot',
      'ncrs',
      'claims',
      'audits',
      'auditSinglePackerPlant',
      'packer-plant-scores',
      'aslUpdates',
    ].includes(section)
  ) {
    const response = await axios.get(`${BASE_URL}/quality/${section}`, {
      params: query,
    });
    return response.data;
  }

  return dummyData[type][section];
}

// PackerPlant is either
export default async function qualityDataRequest({ timeScale, timePeriod, packerPlant, grinderId, market }) {
  // It's either
  // * an individual packerPlant
  // * a packer of packerPlants
  // * an entire market
  //
  // Each has different sections and slightly different data requirements

  const type = determineRequestType(packerPlant);
  const sections = REQUEST_SECTIONS[type];

  const dataBySection = await Promise.all(
    sections.map(section => requestSection({ section, timeScale, timePeriod, type, packerPlant, grinderId, market }))
  );
  return dataBySection.reduce((all, data, index) => ({ ...all, [sections[index]]: data }), {});
}
