export const getTenant = () => {
  return window.location.host;
};

/**
 * Extracts the tenant of current domain from hostname/url and
 * returns the subdomain(tenant) section of current URL/Domain when called.
 * e.g 'https://uat.tarot.boab.io/' => 'uat.tarot'
 */
export function getTenantFromDomain() {
  if (window.location.hostname != 'undefined') {
    return window.location.hostname.split('.').slice(0, -2).join('.');
  } else {
    const hostname = /:\/\/([^\/]+)/.exec(window.location.href)[1];
    return hostname.split('.').slice(0, -2).join('.');
    // this logic might change in future depending on the host name designs
  }
}
