import Constants from '../Constants';
import api from '../api';

const basePath = `/articles`;

const actionTypes = {
  ARTICLE_LIST_REQUESTED: 'ARTICLE_LIST_REQUESTED',
  ARTICLE_LIST_REQUEST_SUCCESS: 'ARTICLE_LIST_REQUEST_SUCCESS',
  ARTICLE_LIST_REQUEST_FAILURE: 'ARTICLE_LIST_REQUEST_FAILURE',

  LATEST_ARTICLES_REQUESTED: 'LATEST_ARTICLES_REQUESTED',
  LATEST_ARTICLES_REQUEST_SUCCESS: 'LATEST_ARTICLES_REQUEST_SUCCESS',
  LATEST_ARTICLES_REQUEST_FAILURE: 'LATEST_ARTICLES_REQUEST_FAILURE',
};

export const actions = {
  requestArticleList() {
    return {
      type: actionTypes.ARTICLE_LIST_REQUESTED,
    };
  },
  articleListRequestSuccess(articles) {
    return {
      type: actionTypes.ARTICLE_LIST_REQUEST_SUCCESS,
      payload: articles,
    };
  },
  articleListRequestError(error) {
    return {
      type: actionTypes.ARTICLE_LIST_REQUEST_FAILURE,
      payload: error,
    };
  },

  requestLatestArticles() {
    return {
      type: actionTypes.LATEST_ARTICLES_REQUESTED,
    };
  },
  latestArticlesRequestSuccess(articles) {
    return {
      type: actionTypes.LATEST_ARTICLES_REQUEST_SUCCESS,
      payload: articles,
    };
  },
  latestArticlesRequestError(error) {
    return {
      type: actionTypes.LATEST_ARTICLES_REQUEST_FAILURE,
      payload: error,
    };
  },
};

export const fetchArticles = () => dispatch => {
  dispatch(actions.requestArticleList());
  return api
    .get(basePath, { params: { tags_exclude: ['KPI'], limit: 100 } })
    .then(response => Constants.handleErrors(response, dispatch, actions.articleListRequestError))
    .then(json => dispatch(actions.articleListRequestSuccess(json)))
    .catch(error => dispatch(actions.articleListRequestError(error)));
};

export const fetchLatestArticles = slug => dispatch => {
  dispatch(actions.requestLatestArticles());
  return api
    .get(basePath, { params: { limit: 5, exclude: slug, tags_exclude: ['KPI'] } })
    .then(response => Constants.handleErrors(response, dispatch, actions.latestArticlesRequestError))
    .then(json => dispatch(actions.latestArticlesRequestSuccess(json)))
    .catch(error => dispatch(actions.latestArticlesRequestError(error)));
};

const defaultState = {
  loading: false,
  error: null,
  articleList: [],
  latestArticles: [],
  latestArticlesLoading: false,
  latestArticlesError: null,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.ARTICLE_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ARTICLE_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        articleList: action.payload,
      };
    case actionTypes.ARTICLE_LIST_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.LATEST_ARTICLES_REQUESTED:
      return {
        ...state,
        latestArticlesLoading: true,
        latestArticlesError: null,
      };
    case actionTypes.LATEST_ARTICLES_REQUEST_SUCCESS:
      return {
        ...state,
        latestArticlesLoading: false,
        latestArticles: action.payload,
      };
    case actionTypes.LATEST_ARTICLES_REQUEST_FAILURE:
      return {
        ...state,
        latestArticlesLoading: false,
        latestArticlesError: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
