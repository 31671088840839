/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Center, Flex } from '@chakra-ui/react';
// Local Deps
import Button from '../core/Button';
import { createSlice } from '@reduxjs/toolkit';
import UploadDocumentModal from '../orders/UploadDocumentModal';
import { fetchNetsuitePurchaseOrder, resetModalOrder } from '../../actions/actions_orders';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../reducers/reducer_user';
import { selectCurrentOrder } from '../../slices/basic/order';
import Constants from '../../Constants';
import EditInternationalOrder from '../../containers/orders/EditInternationalOrder';
import { selectOrderOptions } from '../../slices/masterData/selectOrderEntities';
import OrderTableModal from '../../containers/orders/OrderTableModal';

const { BUSINESS_OFFICES } = Constants;

export const MODAL_TYPES = {
  ORDER: 'ORDER',
  UPLOAD: 'UPLOAD',
};

export const orderActionsFormatterSlice = createSlice({
  name: 'orderActionsFormatter',
  initialState: {
    type: null,
    poId: null,
    visible: false,
  },
  reducers: {
    setOpenModal: (state, action) => {
      const { type, poId } = action.payload;
      state.type = type;
      state.poId = poId;
      state.visible = true;
    },
    setCloseModal: state => {
      state.type = null;
      state.poId = null;
      state.visible = false;
    },
  },
});

// Actions.
export const { setOpenModal, setCloseModal } = orderActionsFormatterSlice.actions;

export const openModal =
  ({ type, poId }) =>
  async dispatch => {
    dispatch(setOpenModal({ type, poId }));
  };

export const closeModal = () => async dispatch => {
  dispatch(setCloseModal());
};

// Selectors.
export const selectModal = state => state.orderActionsFormatter;

// Reducers.
export const { reducer } = orderActionsFormatterSlice;

const propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
  }),
};

// This formatter is for display purposes only.  Clicking into the component from
// React-data-grid will activate the editor.
// I've used the same component (without the portal), to make sure that the options menu
// does not render.
function OrderActionsFormatter({ row }) {
  const dispatch = useDispatch();
  return (
    <Flex alignItems="center" style={{ height: '100%' }}>
      <Center>
        <Button
          colorScheme="actionSecondary"
          _focus={{ outline: 'none' }}
          cursor="pointer"
          minWidth="25px"
          px="2px"
          variant="borderless"
          onClick={() => dispatch(openModal({ type: MODAL_TYPES.ORDER, poId: row.id }))}
        >
          <i className="fa fa-pencil-alt" />
        </Button>
        <Button
          colorScheme="actionSecondary"
          _focus={{ outline: 'none' }}
          cusor="pointer"
          minWidth="25px"
          px="2px"
          variant="borderless"
          onClick={() => dispatch(openModal({ type: MODAL_TYPES.UPLOAD, poId: row.id }))}
        >
          <i className="fa fa-upload" />
        </Button>
      </Center>
    </Flex>
  );
}

OrderActionsFormatter.Modals = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const currentOrder = useSelector(selectCurrentOrder);
  const orderOptions = useSelector(selectOrderOptions);
  const modal = useSelector(selectModal);

  const onCloseModal = () => dispatch(closeModal());

  useEffect(() => {
    if (!modal.poId || (currentOrder && currentOrder.id !== modal.poId)) {
      dispatch(resetModalOrder());
      return;
    }
    if (!currentOrder || currentOrder.id !== modal.poId) {
      // eslint-disable-next-line react/prop-types
      dispatch(fetchNetsuitePurchaseOrder(modal.poId, currentUser.token));
    }
  }, [modal.poId]);

  return (
    modal.visible && (
      <>
        {modal.type === MODAL_TYPES.ORDER && currentOrder && (
          <>
            {/* <!--- EDIT SYDNEY PO ORDER MODAL --> */}
            {currentOrder.invoicing_office === BUSINESS_OFFICES.SYDNEY.value && (
              <EditInternationalOrder
                // Prevent form from double-requesting the order:
                currentOrder={currentOrder}
                poId={currentOrder.id}
                onClose={onCloseModal}
              />
            )}
            {/* <!--- EDIT CHICAGO PO ORDER MODAL --> */}
            {currentOrder.invoicing_office === BUSINESS_OFFICES.CHICAGO.value && (
              <OrderTableModal selectCurrentModalDetail={onCloseModal} />
            )}
          </>
        )}
        {/* <!--- UPLOAD DOCUMENT MODAL --> */}
        {modal.type === MODAL_TYPES.UPLOAD && (
          <div>
            <UploadDocumentModal
              {...{
                autoSave: true,
                closeModal: onCloseModal,
                currentOrder,
                dispatch,
                loading: orderOptions.isLoading,
                poDocTypes: orderOptions.docTypeOptions,
                token: currentUser.token,
              }}
            />
          </div>
        )}
      </>
    )
  );
};

OrderActionsFormatter.propTypes = propTypes;
export default OrderActionsFormatter;
