import Constants from '../Constants';

export const PACKER_OFFERS_FETCHED = 'PACKER_OFFERS_FETCHED';
export const PACKER_OFFERS_ADDED = 'PACKER_OFFERS_ADDED';
export const CREATED_PACKER_OFFERS = 'CREATED_PACKER_OFFERS';
export const PACKER_PLANT_OFFER_EDIT = 'PACKER_PLANT_OFFER_EDIT';
export const PACKER_PLANT_OFFER_LINE_EDIT = 'PACKER_PLANT_OFFER_LINE_EDIT';
export const PACKER_PLANT_OFFER_LINE_REMOVE = 'PACKER_PLANT_OFFER_LINE_REMOVE';
export const PACKER_OFFERS_ERROR = 'PACKER_OFFERS_ERROR';

const BASE_URL = `${Constants.URL}packer_offers`;

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadPackerOffers(packerOffers) {
  return {
    type: PACKER_OFFERS_FETCHED,
    payload: packerOffers,
  };
}

export function addPackerOffer(packerOffers) {
  return {
    type: PACKER_OFFERS_ADDED,
    payload: packerOffers,
  };
}

export function createdPackerOffers(packerOffers) {
  return {
    type: CREATED_PACKER_OFFERS,
    payload: packerOffers,
  };
}

export function editPackerOffer(packerOffer) {
  return {
    type: PACKER_PLANT_OFFER_EDIT,
    payload: packerOffer,
  };
}

export function editPackerOfferLine(packerOfferLine) {
  return {
    type: PACKER_PLANT_OFFER_LINE_EDIT,
    payload: packerOfferLine,
  };
}

export function removedPackerOfferLine(packerOfferLine) {
  return {
    type: PACKER_PLANT_OFFER_LINE_REMOVE,
    payload: packerOfferLine,
  };
}

export function errorPackerOffers(error) {
  return {
    type: PACKER_OFFERS_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchPackerOffers(token, queryString) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    const url = queryString ? BASE_URL + queryString : BASE_URL;

    return fetch(url, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPackerOffers))
      .then(json => dispatch(loadPackerOffers(json)))
      .catch(error => dispatch(errorPackerOffers(error)));
  };
}

export function copyPackerOffer(packerOfferId, copies, token) {
  const payload = { total_count: copies };
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${packerOfferId}/copies`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPackerOffers))
      .then(json => dispatch(createdPackerOffers(json)))
      .catch(error => dispatch(errorPackerOffers(error)));
  };
}

export function postPackerOfferForm(payload, copies, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(BASE_URL, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPackerOffers))
      .then(json => {
        if (copies > 1) {
          return dispatch(copyPackerOffer(json.id, copies - 1, token));
        }
        return dispatch(createdPackerOffers(json));
      })
      .catch(error => dispatch(errorPackerOffers(error)));
  };
}

export function postPackerOffer(packerOfferId, packerInputProduct, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(BASE_URL + packerOfferId, {
      method: 'POST',
      body: JSON.stringify(packerInputProduct),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPackerOffers))
      .then(json => dispatch(addPackerOffer(json)))
      .catch(error => dispatch(errorPackerOffers(error)));
  };
}

export function patchPackerOffer(packerOfferId, packerOffer, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${packerOfferId}`, {
      method: 'PATCH',
      body: JSON.stringify(packerOffer),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPackerOffers))
      .then(json => dispatch(editPackerOffer(json)))
      .catch(error => dispatch(errorPackerOffers(error)));
  };
}

export function confirmPackerOffer(payload, packerOfferId, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPackerOffers))
      .then(json => {
        return dispatch(patchPackerOffer(packerOfferId, { purchase_order_id: json.id }, token));
      })
      .catch(error => dispatch(errorPackerOffers(error)));
  };
}

export function addPackerOfferLine(packerOfferId, payload, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${packerOfferId}/lines`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPackerOffers))
      .then(json => dispatch(editPackerOfferLine(json)))
      .catch(error => dispatch(errorPackerOffers(error)));
  };
}

export function patchPackerOfferLine(packerOfferId, packerOfferLineId, payload, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${packerOfferId}/lines/${packerOfferLineId}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPackerOffers))
      .then(json => dispatch(editPackerOfferLine(json)))
      .catch(error => dispatch(errorPackerOffers(error)));
  };
}

export function removePackerOfferLine(packerOfferId, packerOfferLineId, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${packerOfferId}/lines/${packerOfferLineId}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPackerOffers))
      .then(() =>
        dispatch(
          removedPackerOfferLine({
            packer_offer_id: packerOfferId,
            packer_offer_line_id: packerOfferLineId,
          })
        )
      )
      .catch(error => dispatch(errorPackerOffers(error)));
  };
}
