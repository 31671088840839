import { createSelector } from '@reduxjs/toolkit';
import { group } from 'd3';
import { selectQualityNCRsActivePackerPlants, selectQualityNCRsMarket } from './selectBasicDetailsNcrsData';
import { selectQualityNCRsData } from './selectQualityNCRsData';
import { selectAvailableCategoriesWithSubcategories } from './selectAvailableCategories';
import { VOLUME_OPTIONS } from '../qualityDetailsNcrsConfig';
import Constants from '../../../Constants';

const selectQualityNCRsDataByCategory = createSelector(
  selectQualityNCRsData,
  selectQualityNCRsActivePackerPlants,
  selectAvailableCategoriesWithSubcategories,
  selectQualityNCRsMarket,
  (data, activePackerPlants, availableCategoriesWithSubcategories, market) => {
    if (!data) return null;

    const activePackerPlantsIds = activePackerPlants.filter(x => !!x).map(x => x.id);

    const dataByCategory = data.map(d => {
      const allCategoryData = [];
      let allPackerPlants = [];
      let totalReceivedWeight = 0;
      const multiplier = VOLUME_OPTIONS[market.key].MULTIPLIER;

      // FILTER BY ESTABLISHMENT, atleast one selection made
      if (activePackerPlantsIds.length > 0) {
        activePackerPlantsIds.forEach(ap => {
          const byPackerPlantData = d.byPackerPlant?.find(x => x.packerPlantId === ap);
          if (byPackerPlantData) {
            allPackerPlants.push(byPackerPlantData);
            // if packer or packer plants selected
            // for calculating incidentRate or percentof Volume
            // receivedWeight should be weight received for that selection
            // for the selected period(month)
            // When more than one active packerplant, it's comparison and is handled below
            if (activePackerPlantsIds.length < 2) {
              totalReceivedWeight = byPackerPlantData.receivedWeight;
            }
          } else {
            allPackerPlants.push({ ...Constants.DEFAULT_PACKER_PLANT_DATA, packerPlantId: ap });
          }
        });
      } else {
        allPackerPlants = d.byPackerPlant.filter(p => p.packerPlantId.length > 3);
        // if no selection made
        // then weight received for that period(month)
        totalReceivedWeight = d.receivedWeight;
      }

      // FILTER BY ESTABLISHMENT, more than one selection made (Add comparison)
      if (activePackerPlantsIds.length >= 2) {
        allPackerPlants.forEach(p => {
          availableCategoriesWithSubcategories.forEach(category => {
            const categoryData = p.byCategory?.find(c => c.category === category.category);
            // if packer or packer plants selected
            // for calculating incidentRate or percentof Volume
            // receivedWeight should be weight received for that selection
            // for the selected period(month)
            const packerPlantReceivedWeight = d.byPackerPlant.filter(ap => ap.packerPlantId === p.packerPlantId)[0]
              .receivedWeight;
            if (categoryData) {
              allCategoryData.push({
                ...categoryData,
                packerPlantId: p.packerPlantId,
                percentOfTotalVolume: packerPlantReceivedWeight ? categoryData.weight / packerPlantReceivedWeight : 0,
                incidentsPerUnit: packerPlantReceivedWeight
                  ? categoryData.incidentsCount / packerPlantReceivedWeight
                  : 0,
                incidentRate: packerPlantReceivedWeight
                  ? (categoryData.incidentsCount * multiplier) / packerPlantReceivedWeight
                  : 0,
              });
            } else {
              const defaultCData = { category: category.category, ...Constants.DEFAULT_CATEGORY_DATA };
              allCategoryData.push({ ...defaultCData, packerPlantId: p.packerPlantId });
            }
          });
        });
        const groupedByCategory = Array.from(
          group(allCategoryData, x => {
            return x.category;
          })
        );
        return {
          date: d.date,
          byCategory: groupedByCategory.map(([category, entries]) => {
            return {
              category,
              byPackerPlant: entries,
            };
          }),
        };
      }

      // if packer plants exists, if not show defaults
      if (allPackerPlants.length > 0) {
        allPackerPlants.forEach(p => {
          availableCategoriesWithSubcategories.forEach(category => {
            const categoryData = p.byCategory?.find(c => c.category === category.category);
            if (categoryData) {
              allCategoryData.push(categoryData);
            } else {
              const defaultCData = { category: category.category, ...Constants.DEFAULT_CATEGORY_DATA };
              allCategoryData.push(defaultCData);
            }
          });
        });
      } else {
        availableCategoriesWithSubcategories.forEach(category => {
          const defaultCData = { category: category.category, ...Constants.DEFAULT_CATEGORY_DATA };
          allCategoryData.push(defaultCData);
        });
      }

      const groupedByCategory = Array.from(
        group(allCategoryData, x => {
          return x.category;
        })
      );

      // incidentRate = num_of_incidents_per_category / received_weight - for that month
      // packer incidentRate = num_of_incidents_per_category_for_packer / received_weight_of_packer - for that month
      // bottom10 incidentRate = incident_per_sub_category_of_bottom_10 / received_weight - for that month
      return {
        date: d.date,
        byCategory: groupedByCategory.map(([category, entries]) => {
          const incidentsCount = entries.reduce((a, b) => a + b.incidentsCount, 0);
          const amountAffected = entries.reduce((a, b) => a + b.weight, 0);
          let cat = {
            category,
            incidentsCount,
            incidentRate: totalReceivedWeight ? (incidentsCount * multiplier) / totalReceivedWeight : 0,
            percentOfTotalVolume: totalReceivedWeight ? amountAffected / totalReceivedWeight : 0,
            weight: amountAffected,
            claimDollars: entries.reduce((a, b) => a + b.claimDollars, 0),
            incidentsPerUnit: totalReceivedWeight ? incidentsCount / totalReceivedWeight : 0,
          };

          if (activePackerPlants[0] && activePackerPlants[0].bottomTenPackerPlants) {
            const bottomTenPackerPlants = d.byPackerPlant.filter(x =>
              activePackerPlants[0].bottomTenPackerPlants.map(b => b.id).includes(x.packerPlantId)
            );
            const incidentsCountBottomTen = bottomTenPackerPlants
              .map(x => x.byCategory.find(c => c.category === category)?.incidentsCount)
              .reduce((a, b) => a + b, 0);
            const weightBottomTen = bottomTenPackerPlants
              .map(x => x.byCategory.find(c => c.category === category)?.weight)
              .reduce((a, b) => a + b, 0);
            const receivedWeightBottomTen = totalReceivedWeight;

            const bottomTen = {
              incidentsCountBottomTen,
              incidentRateBottomTen: receivedWeightBottomTen
                ? (incidentsCountBottomTen * multiplier) / receivedWeightBottomTen
                : 0,
              percentOfTotalVolumeBottomTen: receivedWeightBottomTen ? weightBottomTen / receivedWeightBottomTen : 0,
              weightBottomTen,
              incidentsPerUnitBottomTen: bottomTenPackerPlants
                .map(x => x.byCategory.find(c => c.category === category)?.incidentsPerUnit)
                .reduce((a, b) => a + b, 0),
              claimDollarsBottomTen: bottomTenPackerPlants
                .map(x => x.byCategory.find(c => c.category === category)?.claimDollars)
                .reduce((a, b) => a + b, 0),
            };
            cat = { ...cat, ...bottomTen };
          }
          return cat;
        }),
      };
    });

    return dataByCategory;
  }
);

// TODO not seemed to be rendered on UI, check and delete
export const selectQualityNCRsPackerDataByCategory = createSelector(
  selectQualityNCRsData,
  selectQualityNCRsActivePackerPlants,
  (data, activePackerPlants) => {
    if (!data) return null;

    let packerPackerPlantsIds = [];
    if (activePackerPlants[0] && activePackerPlants[0].includesPackerPlants) {
      packerPackerPlantsIds = activePackerPlants[0].includesPackerPlants.map(x => x.id);
    }

    const dataByCategory = data.map(d => {
      const allCategoryData = [];
      let allPackerPlants = [];

      allPackerPlants = d.byPackerPlant.filter(x => packerPackerPlantsIds.includes(x.packerPlantId));

      allPackerPlants.forEach(p => {
        allCategoryData.push(...p.byCategory.map(n => ({ ...n, packerPlantId: p.packerPlantId })));
      });

      const groupedByCategory = Array.from(
        group(allCategoryData, x => {
          return x.category;
        })
      );

      return {
        date: d.date,
        byCategory: groupedByCategory.map(([category, entries]) => {
          return {
            category,
            byPackerPlant: entries,
          };
        }),
      };
    });

    return dataByCategory;
  }
);

export default selectQualityNCRsDataByCategory;
