import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Grid, GridItem } from '@chakra-ui/react';

function Card(props) {
  const { text, children, index, templateColumns, headerComponent, ...rest } = props;
  const template = templateColumns || 'repeat(7, 1fr)';
  return (
    <Box
      {...{
        borderWidth: '1px',
        borderRadius: 'lg',
        px: '27px',
        py: '17px',
        ...rest,
      }}
    >
      <Grid gap={4} templateColumns={template}>
        {text && (
          <GridItem colSpan={4} key="left-header">
            <Heading fontSize="15px" mb="25px">
              {text}
            </Heading>
          </GridItem>
        )}
        <GridItem colSpan={3} key="right-header">
          {headerComponent}
        </GridItem>
      </Grid>
      <Box>{children}</Box>
    </Box>
  );
}

Card.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  index: PropTypes.number,
  templateColumns: PropTypes.string,
  headerComponent: PropTypes.node,
};

export default Card;
