import { get } from 'lodash';
import { USER_ADDED, USER_EDIT, USER_REMOVED, USERS_FETCHED } from '../actions/actions_users';
import { convertToCamelCase, removeObjectFromArray, replaceObjectInArray } from '../helpers';

const users = (users = [], action) => {
  const usersCopy = JSON.parse(JSON.stringify(users));

  switch (action.type) {
    case USERS_FETCHED:
      return action.payload;
    case USER_ADDED:
      usersCopy.push(action.payload);
      return usersCopy;
    case USER_EDIT:
      return replaceObjectInArray(usersCopy, action.payload);
    case USER_REMOVED:
      return removeObjectFromArray(usersCopy, action.payload);
    default:
      return users;
  }
};

// Selectors
export const fmgUsersSelector = state => {
  return state.users.filter(user => user.email.indexOf('@fultonmarketgroup.com') > -1);
};
export const buyersSelector = state => {
  const usersList = convertToCamelCase(get(state, 'users', state));
  if (!Array.isArray(usersList)) {
    return [];
  }
  return usersList.filter(user => user?.businessUse?.includes('buyer') && !user?.delisted) || [];
};
export default users;
