import { Box } from '@chakra-ui/react';
import React from 'react';

const BuyActivityPaneBody = ({ children }) => {
  return (
    <Box color="black" borderColor="gray">
      {children}
    </Box>
  );
};

export default BuyActivityPaneBody;
