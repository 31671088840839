import api from '../api';
import Constants from '../Constants';

export const USERS_FETCHED = 'USERS_FETCHED';
export const USER_ADDED = 'USER_ADDED';
export const USER_EDIT = 'USER_EDIT';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_REMOVED = 'USER_REMOVED';
export const USER_ERROR = 'USER_ERROR';
export const USER_SIGNATURE_LOADED = 'USER_SIGNATURE_LOADED';
export const USER_SIGNATURE_LOADING = 'USER_SIGNATURE_LOADING';

export function loadUsers(users) {
  return {
    type: USERS_FETCHED,
    payload: users,
  };
}

export function userSignatureLoaded(signature) {
  return {
    type: USER_SIGNATURE_LOADED,
    payload: signature,
  };
}

export function setUserSignatureLoading() {
  return {
    type: USER_SIGNATURE_LOADING,
  };
}

export function addUser(user) {
  return {
    type: USER_ADDED,
    payload: user,
  };
}

export function removeUser(user) {
  return {
    type: USER_REMOVED,
    payload: user,
  };
}

export function updateUser(user) {
  return {
    type: USER_UPDATE,
    payload: user,
  };
}

export function editUser(user) {
  return {
    type: USER_EDIT,
    payload: user,
  };
}

export function errorUser(error) {
  return {
    type: USER_ERROR,
    payload: error,
  };
}

const basePath = '/users';

export const fetchUsers = () => dispatch => {
  return api
    .get(basePath)
    .then(response => Constants.handleErrors(response, dispatch, errorUser))
    .then(json => dispatch(loadUsers(json)))
    .catch(error => dispatch(errorUser(error)));
};

export const fetchBuyerOrgUsers = () => dispatch => {
  return api
    .get(basePath)
    .then(response => Constants.handleErrors(response, dispatch, errorUser))
    .then(json => dispatch(loadUsers(json)))
    .catch(error => dispatch(errorUser(error)));
};

export const fetchUserSignature = user => dispatch => {
  return api
    .get(`${basePath}/${user.id}/files/signature`)
    .then(response => Constants.handleErrors(response, dispatch, errorUser))
    .then(json => dispatch(userSignatureLoaded(json)))
    .catch(error => dispatch(errorUser(error)));
};

export const addUserSignature = payload => dispatch => {
  const formData = new FormData();
  formData.append('file', payload.file);

  return api
    .post(`${basePath}/${payload.user_id}/files/signature`, formData)
    .then(response => Constants.handleErrors(response, dispatch, errorUser))
    .then(json => dispatch(userSignatureLoaded(json)))
    .catch(error => dispatch(errorUser(error)));
};

export const postUser = user => dispatch => {
  delete user.type;

  return api
    .post(basePath, user)
    .then(response => Constants.handleErrors(response, dispatch, errorUser))
    .then(json => dispatch(addUser(json)))
    .catch(error => dispatch(errorUser(error)));
};

export const patchMyUser = user => dispatch => {
  const { id } = user;
  const { switchUI } = user;
  delete user.id;
  delete user.type;
  delete user.organisation;
  delete user.organisation_id;
  delete user.role;
  delete user.token;
  delete user.token_expiry;
  delete user.userCredChanged;
  delete user.userUISwitched;
  delete user.switchUI;

  return api
    .patch(`${basePath}/${id}`, user)
    .then(response => Constants.handleErrors(response, dispatch, errorUser))
    .then(json => {
      const thisJson = JSON.parse(JSON.stringify(json));
      if (user.password || user.email) {
        thisJson.userCredChanged = true;
      }
      if (switchUI) {
        thisJson.userUISwitched = true;
      }
      dispatch(updateUser(thisJson));
    })
    .catch(error => dispatch(errorUser(error)));
};

export const patchUser = user => dispatch => {
  const { id } = user;
  delete user.id;
  delete user.type;
  delete user.organisation;
  delete user.role;
  delete user.token;
  delete user.token_expiry;

  return api
    .patch(`${basePath}/${id}`, user)
    .then(response => Constants.handleErrors(response, dispatch, errorUser))
    .then(json => dispatch(editUser(json)))
    .catch(error => dispatch(errorUser(error)));
};

export const deleteUser = user => dispatch => {
  return api
    .delete(`${basePath}/${user.id}}`)
    .then(response => Constants.handleErrors(response, dispatch, errorUser))
    .then(() => dispatch(removeUser(user)))
    .catch(error => dispatch(errorUser(error)));
};

export const generateUserUsageReport =
  (queryString = '') =>
  dispatch => {
    return api
      .get(`${basePath}/generate_usage_report${queryString}`)
      .then(response => response.blob())
      .then(response => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response);
        link.setAttribute('download', 'report.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => dispatch(errorUser(error)));
  };
