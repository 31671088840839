import {
  CHANGE_TRANSPORT_MODAL,
  SET_TRANSPORT_LOADING,
  TRANSPORT_CREATED,
  TRANSPORT_DELETED,
  TRANSPORT_EDIT,
  TRANSPORT_FETCHED,
} from '../actions/actions_transport';

const defaultState = {
  transport_items: [],
  modalOpened: false,
  loading: false,
};

const transports = (state = defaultState, action) => {
  switch (action.type) {
    case TRANSPORT_CREATED:
      const items = JSON.parse(JSON.stringify(state.transport_items));
      items.push(action.payload);

      return {
        ...state,
        transport_items: items,
      };
    case TRANSPORT_FETCHED:
      return {
        ...state,
        transport_items: action.payload,
      };
    case TRANSPORT_DELETED:
      const filteredTransports = state.transport_items.filter(
        el => !(el.grinder_uid === action.payload.grinder_uid && el.packer_plant_id === action.payload.packer_plant_id)
      );

      return {
        ...state,
        transport_items: filteredTransports,
      };
    case TRANSPORT_EDIT:
      const items_to_edit = JSON.parse(JSON.stringify(state.transport_items));
      return {
        ...state,
        transport_items: replaceTransport(items_to_edit, action.payload),
      };
    case CHANGE_TRANSPORT_MODAL:
      return {
        ...state,
        modalOpened: action.payload,
      };
    case SET_TRANSPORT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

function replaceTransport(allTransports, editedTransport) {
  const newTransports = [];
  allTransports.forEach(function (element) {
    if (
      element.packer_plant_id === editedTransport.packer_plant_id &&
      element.grinder_uid === editedTransport.grinder_uid
    ) {
      newTransports.push(editedTransport);
    } else {
      newTransports.push(element);
    }
  });
  return newTransports;
}

export default transports;
