import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, HStack, IconButton, Text, VStack, Wrap } from '@chakra-ui/react';
import { get } from 'lodash';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../functions';
import Tooltip from '../quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const PurchaseOrderStatusSection = ({
  isUSADomestic,
  currentOrder,
  isFrozenProduct,
  endUserName,
  grinderName,
  handleChangeValue,
  isInEditMode,
}) => {
  const packerInvoiceStatus = get(currentOrder, 'packerInvoiceStatus', {});
  const grinderInvoiceStatus = get(currentOrder, 'grinderInvoiceStatus', '-');

  return (
    <VStack align="stretch" spacing="13px">
      <Wrap spacing="23px" width="100%">
        <HStack>
          <Text fontWeight="bold">
            Purchasing Office&nbsp;
            <Tooltip content="Specifies the office managing the order for coordination." placement="right">
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
            :
          </Text>
          <Text as="p">{isUSADomestic ? 'USA' : currentOrder.purchasingOffice || 'None'}</Text>
          {isFrozenProduct && !isUSADomestic && <Text as="p"> - Coldstore Required</Text>}
        </HStack>
        <HStack>
          <Text fontWeight="bold">
            End User&nbsp;
            <Tooltip
              content="Identifies the final recipient to ensure the product meets specific requirements."
              placement="right"
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
            :
          </Text>
          <Text as="p">{endUserName}</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">
            Destination Grinder&nbsp;
            <Tooltip content="Specifies the grinder at the destination for proper handling." placement="right">
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
            :
          </Text>
          <Text as="p">{grinderName}</Text>
        </HStack>
      </Wrap>
      <Wrap spacing="23px" width="100%">
        <HStack>
          <Text fontWeight="bold">
            Packer invoice Status&nbsp;
            <Tooltip content="Tracks the payment status of the packer invoice." placement="right">
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
            :
          </Text>
          <Checkbox
            name="readyForPayment"
            colorScheme="actionSecondary"
            defaultChecked={get(packerInvoiceStatus, 'readyForPayment', false)}
            onChange={handleChangeValue}
            isDisabled={!isInEditMode || get(packerInvoiceStatus, 'drawDownReportCreatedAt', false)}
          >
            Ready For Payment
          </Checkbox>
          {get(packerInvoiceStatus, 'readyForPayment', false) && (
            <Text as="p">{moment(packerInvoiceStatus.readyForPaymentCheckedAt).format('YYYY-MM-DD hh:mm A')}</Text>
          )}
          {get(packerInvoiceStatus, 'readyForPaymentCheckedAt', false) &&
            get(packerInvoiceStatus, 'drawDownReportCreatedAt', false) && (
              <Text as="p">
                {`Drawdown Report created at  ${moment
                  .parseZone(packerInvoiceStatus.drawDownReportCreatedAt)
                  .format('YYYY-MM-DD hh:mm A')}`}
              </Text>
            )}
        </HStack>
      </Wrap>
      <Wrap spacing="23px" width="100%">
        <HStack>
          <Text fontWeight="bold">
            Grinder invoice Status&nbsp;
            <Tooltip content="Shows the current status of the grinder invoice." placement="right">
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
            :
          </Text>
          <Text as="p">
            {grinderInvoiceStatus
              ? capitalizeFirstLetter(grinderInvoiceStatus.toLowerCase() === 'todo' ? 'to do' : grinderInvoiceStatus)
              : '-'}
          </Text>
        </HStack>
      </Wrap>
    </VStack>
  );
};

PurchaseOrderStatusSection.propTypes = {
  isFrozenProduct: PropTypes.bool,
  isUSADomestic: PropTypes.bool,
  endUserName: PropTypes.string,
  grinderName: PropTypes.string,
  isInEditMode: PropTypes.bool,
  handleChangeValue: PropTypes.func,
  currentOrder: PropTypes.instanceOf(PropTypes.object),
};

export default PurchaseOrderStatusSection;
