const schema = [
  {
    name: 'shipping_line',
    displayName: 'Shipping Line',
    systemIdField: 'id',
    displayIdField: 'name',
    path: '/shipping_lines',
    tabGroup: { key: 'logistics', label: 'Logistics' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'Name of the shipping line company',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'timezone',
        displayName: 'Timezone',
        tooltip: "Timezone where the shipping line's headquarters or main office is located.",
        dataType: 'shorttext',
        required: false,
      },
      {
        name: 'address',
        displayName: 'Address',
        dataType: 'reference',
        referenceType: 'nested',
        referenceEntityType: 'address',
      },
    ],
  },
];
export default schema;
