import { Alert, AlertDescription, AlertIcon, Flex, Spacer, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import ResultsGrid from '../../../../../components/basic/ResultsGrid';
import Card from '../../../../../components/core/Card';
import { generateAndDownloadExcel } from '../../../../../services/excel-export';
import Button from '../../../../../components/core/Button';
import { Icon } from '@ant-design/compatible';

const SummaryFieldFormatter = ({ column, row, color }) => {
  const value = String(row[column.key]);
  return (
    <Text color={color} fontSize="sm" mb="10px">
      {value === '' ? '(empty)' : value}
    </Text>
  );
};
SummaryFieldFormatter.propTypes = {
  column: PropTypes.instanceOf(Object).isRequired,
  row: PropTypes.instanceOf(Object),
  color: PropTypes.string.isRequired,
};

const columns = [
  {
    key: 'grinderPurchaseOrderNumber',
    name: 'Grinder PO#',
  },
  {
    key: 'id',
    name: 'PO Line',
  },
  {
    key: 'key',
    name: 'Field Name',
  },
  {
    key: 'old',
    name: 'Product Data',
    formatter: _props => <SummaryFieldFormatter {..._props} color="red.500" />,
  },
  {
    key: 'rightArrow',
    name: '',
    formatter: () => <Icon type="arrow-right" theme="outlined" style={{ fontSize: '15px' }} />,
  },
  {
    key: 'new',
    name: 'Edited Data',
    formatter: _props => <SummaryFieldFormatter {..._props} color="green" />,
  },
];
const BulkUpdaterSummary = ({
  posChanges,
  posChangesSummary,
  onClickBackButton,
  onClickConfirmButton,
  onClickFinishButton,
  done,
}) => {
  /* --------------------------
    MANAGE EXPORT
  ----------------------------*/
  const onClickExport = useCallback(() => {
    generateAndDownloadExcel('Bulk Updater - Summary', [
      {
        worksheetName: 'Summary',
        columnConfigs: Object.entries({
          'Grinder PO#': 'grinderPurchaseOrderNumber',
          'PO Line': 'id',
          'Field Name': 'key',
          'Product Data': 'old',
          'Edited Data': 'new',
        }).reduce((list, [name, value]) => [...list, { headerText: name, getter: data => data[value] }], []),
        data: posChangesSummary,
      },
    ]);
  }, [posChangesSummary]);

  const numberOfEditedPos = posChangesSummary.length;
  return (
    <>
      {/* <!-- SUMMARY PAGE --> */}
      <Flex mt="20px" mb="10px">
        <Alert
          status={done ? 'success' : 'info'}
          border="1px solid"
          borderColor={done ? 'success' : 'infoBorder'}
          borderRadius="8px"
        >
          <AlertIcon />
          <AlertDescription>
            <b>
              {done && 'Success '}
              {numberOfEditedPos} field{numberOfEditedPos > 1 && 's'}
            </b>{' '}
            {done ? 'have been' : 'will be'} edited.
          </AlertDescription>
        </Alert>
        <Spacer />
      </Flex>
      <Flex mt="20px" mb="10px">
        <Alert
          status={done ? 'success' : 'info'}
          border="1px solid"
          borderColor={done ? 'success' : 'infoBorder'}
          borderRadius="8px"
        >
          <AlertIcon />
          <AlertDescription>
            <b>
              {done && 'Success '}
              {posChanges.length} PO{posChanges.length > 1 && 's'}
            </b>{' '}
            {done ? 'have been' : 'will be'} edited.
          </AlertDescription>
        </Alert>
        <Spacer />
      </Flex>
      {/* <!-- CARD --> */}
      <Card
        {...{
          my: '20px',
          pt: '17px',
          pb: '33px',
          px: '27px',
          borderColor: 'gray.400',
          backgroundColor: 'graySubtle',
          text: 'Summary',
        }}
      >
        <Flex justifyContent="space-between" alignItems="end" mb="10px" mt="-20px">
          <Text color="secondary.800" fontSize="md" fontWeight="bold">
            Modifications
          </Text>
          <Button
            icon="download"
            size="md"
            onClick={onClickExport}
            iconColor="var(--chakra-colors-primary100)"
            variant="ghost"
            colorScheme="actionPrimary"
            mb="10px"
          />
        </Flex>
        {/* <!-- GRID --> */}
        <ResultsGrid
          id="BulkUpdaterSummary"
          {...{
            columns,
            rows: posChangesSummary,
            rowKeyGetter: ({ grinderPurchaseOrderNumber, id, key }) =>
              grinderPurchaseOrderNumber + id + key,
            disableSelect: true,
          }}
        />
      </Card>
      {/* <!-- ACTIONS --> */}
      <Flex justifyContent="right">
        {onClickBackButton && (
          <Button variant="ghost" width="110px" mr="10px" onClick={onClickBackButton}>
            Back
          </Button>
        )}
        {!done && (
          <Button width="110px" onClick={onClickConfirmButton} disabled={numberOfEditedPos.length === 0}>
            Confirm
          </Button>
        )}
        {done && (
          <Button width="110px" onClick={onClickFinishButton} disabled={numberOfEditedPos.length === 0}>
            Finish
          </Button>
        )}
      </Flex>
    </>
  );
};

BulkUpdaterSummary.propTypes = {
  posChangesSummary: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  posChanges: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  done: PropTypes.bool,
  onClickBackButton: PropTypes.func,
  onClickFinishButton: PropTypes.func,
  onClickConfirmButton: PropTypes.func,
};
export default BulkUpdaterSummary;
