import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Box,
  Flex,
  HStack,
  Select,
  VStack,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import {
  fetchEntity,
  registerEntities,
  selectEntities,
  selectSaveSuccess,
  selectSaveSuccessMessage,
} from '../../slices/masterData/entityManagerSlice';
import EntityEditor from './EntityEditor';
import EntityTable from './EntityTable';
import allSchema from './schemas/entitySchemas';
import basicSchema from './schemas/entityBasicSchemas';
import { isManualPriceStrategy } from '../../utils';
import { fetchConfigs } from '../../actions/actions_config';
import HorizontalNavigationBand from '../core/HorizontalNavigationBand';
import { SearchIcon } from '@chakra-ui/icons';
import DashboardSectionTile from '../core/DashboardSectionTile';
import { PaginationBar } from '../core/PaginationComponents';
import AddEntityButton from './AddEntityButton';
import PropTypes from 'prop-types';

const renderTabComponent = ({ allEntitiesSchema, mainPath, entityType, TabsComponent }) => {
  return <TabsComponent {...{ allEntitiesSchema, mainPath, entityType }} />;
};

function findReferencedEntities(entityDefinition, allEntities, allEntitiesSchema) {
  let referencedEntities = {};
  ((entityDefinition && entityDefinition.fields) || [])
    .filter(
      fieldDefinition => fieldDefinition.dataType === 'reference' || fieldDefinition.dataType === 'simple-reference'
    )
    .forEach(refField => {
      const name = refField.referenceEntityType;
      const foundEntity = allEntitiesSchema.find(ent => ent.name === name);
      if (allEntities[name]) {
        // If it's already fetched.
        referencedEntities[name] = allEntities[name];
      } else {
        referencedEntities[name] = {
          entityDefinition: foundEntity,
          entities: [],
        };
      }
      // Some entity can nest itself which causes infinite loop.
      if (name !== entityDefinition.name) {
        // Recursively find referenced entity (for nested field)
        const recursiveNestedRef = findReferencedEntities(foundEntity, allEntities, allEntitiesSchema);
        referencedEntities = Object.assign(referencedEntities, recursiveNestedRef);
      }
    });
  return referencedEntities;
}

const MasterDataBaseComponent = props => {
  const [showEditModel, setShowEditModel] = useState(false);
  const [activeEntityid, setActiveEntityid] = useState(null);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [paginationState, setPaginationState] = useState({
    itemsPerPage: 10,
    currentPage: 1,
    pageCount: 0,
  });

  const [searchState, setSearchState] = useState({
    query: '',
    entities: [],
  });

  const dispatch = useDispatch();
  const saveSuccessMessage = useSelector(selectSaveSuccessMessage);
  const saveSuccess = useSelector(selectSaveSuccess);

  const user = useSelector(state => state.user);
  const configs = useSelector(state => state.configs);
  const { schema } = props;

  let allEntitiesSchema = [];
  if (schema) {
    allEntitiesSchema = schema;
  } else {
    allEntitiesSchema = isManualPriceStrategy(configs) ? basicSchema : allSchema;
  }

  const allEntities = useSelector(selectEntities);
  let { entityType } = useParams();
  if (!entityType && allEntitiesSchema) {
    // Default entity to edit.
    entityType = allEntitiesSchema[0].name;
  }

  // This component can be used for /user-management or /master-data
  const location = useLocation();
  const mainPath = location.pathname.split('/')[1];

  const theseEntities = (allEntities && allEntities[entityType]) || {
    entities: [],
  };
  const entityDefinition = theseEntities.entityDefinition || null;
  const referencedEntities = findReferencedEntities(entityDefinition, allEntities, allEntitiesSchema);

  useEffect(() => {
    dispatch(fetchConfigs(user.token));
  }, []);

  useEffect(() => {
    // Load entities schema.
    dispatch(registerEntities({ entities: allEntitiesSchema }));
    if (entityDefinition && theseEntities.entities.length === 0) {
      // Fetch current entity.
      dispatch(fetchEntity(entityDefinition));
    }
    // Fetch referenced entities.
    Object.entries(referencedEntities).forEach(([key, entity]) => {
      // Fetch data.
      if (entity && entity.entities.length === 0) {
        if (!entity.entityDefinition.nested) {
          // Do not fetch nested entity as it's already in main entity data.
          dispatch(fetchEntity(entity.entityDefinition));
        }
      }
    });
  }, [entityDefinition, allEntitiesSchema]);

  useEffect(() => {
    if (theseEntities.entities || searchState.entities) {
      const { itemsPerPage } = paginationState;
      setPaginationState({
        ...paginationState,
        pageCount: Math.ceil(
          (searchState.query ? searchState.entities.length : theseEntities.entities.length) / itemsPerPage
        ),
      });
    }
  }, [theseEntities.entities, searchState.entities, searchState.query]);

  // Invoke when user click to request another page.
  const handlePageClick = selected => {
    setPaginationState({ ...paginationState, currentPage: selected });
  };

  const handleSearch = e => {
    const { value: searchInputValue } = e.target;
    const searchFields = entityDefinition.fields.filter(field => !field.hideList);
    const searchResults = theseEntities.entities.filter(entity =>
      searchFields.some(searchField => {
        const { dataType, referenceType, name: searchFieldName } = searchField;
        const data = entity[searchFieldName];
        let entityValue;
        if (typeof data === 'string' || typeof data === 'number') {
          entityValue = data;
        }
        if (['boolean'].includes(dataType)) {
          entityValue = data ? 'Yes' : 'No';
        }
        if (dataType === 'reference' && referenceType === 'nested') {
          entityValue = data
            ? Object.values(data).reduce((prev, current) => {
                if (current && current.length) {
                  prev += ` ${current}`;
                }
                return prev;
              }, '')
            : '';
        }
        if (dataType === 'reference' && ['multi-select', 'select'].includes(referenceType)) {
          const refEnt = referencedEntities[searchField.referenceEntityType];
          if (refEnt) {
            let refEntId = refEnt.entityDefinition.systemIdField;
            let refEntDisplayId = refEnt.entityDefinition.displayIdField;
            let { entities } = refEnt;
            if (searchField.referenceEntitySubType) {
              // For form fileds reference, we have to look into sub entity type.
              entities = entities.filter(ent => ent.type === searchField.referenceEntitySubType);
              refEntId = 'value';
              refEntDisplayId = 'value';
            }
            const match = entities.filter(ent => {
              const value = refEntId ? ent[refEntId] : ent;
              if (Array.isArray(data)) {
                return data.includes(value);
              }
              return value === data;
            });

            let processedValue;

            if (match && match.length) {
              if (match.length > 1) {
                processedValue = match.map(value => (value[refEntDisplayId] ? value[refEntDisplayId] : value)).join('');
              } else {
                processedValue = match[0][refEntDisplayId];
              }
            } else {
              processedValue = 'None';
            }
            entityValue = processedValue;
          }
        }

        return entityValue ? `${entityValue}`.toLowerCase().includes(searchInputValue.toLowerCase()) : false;
      })
    );
    setSearchState({
      ...searchState,
      query: searchInputValue,
      entities: searchResults,
    });
  };

  const handleItemsPerPageSelect = e => {
    const currentPageValue = 1;
    const itemsPerPageValue = parseInt(e.target.value, 10);

    setPaginationState({
      ...paginationState,
      itemsPerPage: itemsPerPageValue,
      currentPage: currentPageValue,

      pageCount: Math.ceil(
        (searchState.query ? searchState.entities.length : theseEntities.entities.length) / itemsPerPageValue
      ),
    });
  };

  useEffect(() => {
    if (saveSuccess === true && searchState.query) {
      handleSearch({
        target: {
          value: searchState.query,
        },
      });
    }
  }, [saveSuccess, searchState.query]);

  if (!entityDefinition) return false;

  return (
    <>
      <HorizontalNavigationBand justifyContent="flex-start" paddingX="47px">
        {renderTabComponent({ allEntitiesSchema, mainPath, entityType, TabsComponent: props.TabsComponent })}
        <Box ml="auto">
          <AddEntityButton
            onClick={e => {
              e.preventDefault();
              setActiveEntityid(null);
              setShowEditModel(true);
            }}
          />
        </Box>
      </HorizontalNavigationBand>
      <VStack background="white" align="stretch" spacing="28px" marginY="43px" paddingX="61px">
        <DashboardSectionTile
          title={
            entityDefinition.shouldNotPluralize ? entityDefinition.displayName : `${entityDefinition.displayName}s`
          }
        >
          {theseEntities.entities && theseEntities.entities.length ? (
            <>
              <InputGroup marginTop="5px !important">
                <Input
                  marginLeft="auto !important"
                  placeholder="Search"
                  width="264px"
                  height="40px"
                  value={searchState.query}
                  backgroundColor="#ffffff"
                  onChange={handleSearch}
                />
                <InputRightElement>
                  <SearchIcon color="#0000005E" />
                </InputRightElement>
              </InputGroup>
              <EntityTable
                entityDefinition={entityDefinition}
                entities={searchState.query ? searchState.entities : theseEntities.entities}
                referencedEntities={referencedEntities}
                setActiveEntityid={setActiveEntityid}
                setShowEditModel={setShowEditModel}
                pageSize={paginationState.itemsPerPage}
                currentPage={paginationState.currentPage}
              />
            </>
          ) : (
            <Alert
              status="warning"
              bg="warningBg"
              borderColor="warningBorder"
              w="500px"
              style={{ textAlign: 'center', margin: ' 20vh auto' }}
            >
              <Flex>
                <Flex direction="column">
                  <AlertTitle pl="10px" color="warning">
                    <Box
                      className="fa fa-inbox"
                      style={{ backgroundColor: 'transparent', fontSize: '30px', color: 'lightgrey' }}
                    ></Box>
                    <Box style={{ backgroundColor: 'transparent', fontSize: '15px', color: 'lightgrey' }}>No Data</Box>
                  </AlertTitle>
                </Flex>
              </Flex>
            </Alert>
          )}
        </DashboardSectionTile>
        <HStack marginLeft="auto">
          <Select width="fit-content" marginLeft="auto" onChange={e => handleItemsPerPageSelect(e)}>
            <option value={10}>Show 10</option>
            <option value={20}>Show 20</option>
            <option value={30}>Show 30</option>
            <option value={40}>Show 40</option>
            <option value={50}>Show 50</option>
          </Select>
        </HStack>
        <PaginationBar
          currentPage={paginationState.currentPage}
          pages={paginationState.pageCount}
          onPageChange={page => handlePageClick(page)}
          justifyContent="flex-end"
        />
      </VStack>
      <EntityEditor
        entityDefinition={entityDefinition}
        referencedEntities={referencedEntities}
        showEditModel={showEditModel}
        setShowEditModel={setShowEditModel}
        entityType={entityType}
        activeEntityid={activeEntityid}
        setActiveEntityid={setActiveEntityid}
      />
    </>
  );
};

MasterDataBaseComponent.propTypes = {
  schema: PropTypes.instanceOf(Object),
  TabsComponent: PropTypes.node,
};

export default MasterDataBaseComponent;
