import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Box, IconButton, Input } from '@chakra-ui/react';

import './NumberSpinnerInput.scss';

class NumberSpinnerInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    showError: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    limit: PropTypes.number,
  };

  static defaultProps = {
    showError: false,
    className: '',
    name: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      showError: props.showError,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  handleIncrementDown = () => {
    const { onChange } = this.props;
    if (this.state.value <= 1) {
      return;
    }
    const value = this.state.value - 1;
    this.setState({ value }, () => onChange(value));
  };

  handleIncrementUp = () => {
    const { onChange } = this.props;
    if (this.props.limit) {
      if (this.state.value < this.props.limit) {
        const value = this.state.value + 1;
        this.setState({ value }, () => onChange(value));
      } else {
        // TODO extend feature to support error messages, ex below
        // this.setState({showError: 'Upper threshold met. Cannot increment.'})
      }
    } else {
      const value = this.state.value + 1;
      this.setState({ value }, () => onChange(value));
    }
  };

  handleTextChange = e => {
    const { onChange } = this.props;
    const value = get(e, 'target.value');
    if (value === '') {
      this.setState({ value }, () => onChange(value));
      return;
    }
    const number = parseInt(value);
    if (!number) {
      return;
    }
    this.setState({ value: number }, () => onChange(number));
  };

  render() {
    const { name, showError } = this.props;
    const { value } = this.state;
    return (
      <Box className="number-spinner-input">
        <Box className={`number-spinner-input__input-group ${showError && 'number-spinner-input__input-group--error'}`}>
          <Box className="number-spinner-input__control input-group-btn data-dwn">
            <IconButton variant="ghost" icon={<i className="fa fa-minus" />} data-dir="dwn" onClick={this.handleIncrementDown} />
          </Box>
          <Input
            name={name || ''}
            value={value || ''}
            type="text"
            className="number-spinner-input__input form-control text-center"
            onChange={this.handleTextChange}
            min="0"
            max="40"
          />
          <Box className="number-spinner-input__control input-group-btn data-up">
            <IconButton variant="ghost" icon={<i className="fa fa-plus" />} data-dir="up" onClick={this.handleIncrementUp} />
          </Box>
        </Box>
      </Box>
    );
  }
}

export default NumberSpinnerInput;
