import Constants from '../Constants';

export const CONFIGS_FETCHED = 'CONFIGS_FETCHED';
export const CONFIG_ADDED = 'CONFIG_ADDED';
export const CONFIG_EDIT = 'CONFIG_EDIT';
export const CONFIG_REMOVED = 'CONFIG_REMOVED';
export const CONFIG_ERROR = 'CONFIG_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadConfigs(config) {
  return {
    type: CONFIGS_FETCHED,
    payload: config,
  };
}

export function addConfig(config) {
  return {
    type: CONFIG_ADDED,
    payload: config,
  };
}

export function removeConfig(config) {
  return {
    type: CONFIG_REMOVED,
    payload: config,
  };
}

export function editConfig(config) {
  return {
    type: CONFIG_EDIT,
    payload: config,
  };
}

export function errorConfig(error) {
  return {
    type: CONFIG_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchConfigs(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}config_values`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorConfig))
      .then(json => dispatch(loadConfigs(json)))
      .catch(error => dispatch(errorConfig(error)));
  };
}

export function postConfig(config, token) {
  const copy = { ...config };
  return dispatch => {
    delete copy.type;
    copy.value_int = Number(copy.value_int);
    copy.value_float = parseFloat(copy.value_float);
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}config_values`, {
      method: 'POST',
      body: JSON.stringify(copy),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorConfig))
      .then(json => dispatch(addConfig(json)))
      .catch(error => dispatch(errorConfig(error)));
  };
}

export function patchConfig(config, token) {
  const copy = { ...config };
  return dispatch => {
    delete copy.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}config_values/${copy.id}`, {
      method: 'PATCH',
      body: JSON.stringify(copy),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorConfig))
      .then(json => dispatch(editConfig(json)))
      .catch(error => dispatch(errorConfig(error)));
  };
}

export function deleteConfig(config, token) {
  const copy = { ...config };
  return dispatch => {
    delete copy.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}config_values/${copy.id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorConfig))
      .then((/* json */) => dispatch(removeConfig(copy)))
      .catch(error => dispatch(errorConfig(error)));
  };
}
