import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({
  icon = '',
  color = 'white',
  fontSize = '14px',
  width = '16px',
  height = '16px',
  lineHeight = '16px',
  ...rest
}) => <i className={`far fas fa-${icon}`} style={{ color, fontSize, width, height, lineHeight, ...rest }} />;

Icon.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
};

export default Icon;
