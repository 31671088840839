import { MARKETS } from '../../../config/markets';
// ---------------
// Basic selectors

/**
 * Whether the market page is loading
 * @param {*} state
 */
export const selectQualityClaimsLoading = state => state.qualityClaims.loading;

/**
 * Any page-wide error for the market page
 * @param {*} state
 */
export const selectQualityClaimsError = state => state.qualityClaims.error;

/**
 * The raw data by packerPlant stored in state, from requests.
 * @param {*} state
 */
export const selectQualityClaimsData = state => state.qualityClaims.data && state.qualityClaims.data.data;

/**
 * The raw data by category stored in state, from requests.
 * @param {*} state
 */
export const selectQualityClaimsDataByCategory = state =>
  state.qualityClaims.data && state.qualityClaims.data.dataByCategory;

/**
 * The raw data by claim amount stored in state, from requests.
 * @param {*} state
 */
export const selectQualityClaimsDataByClaimAmount = state =>
  state.qualityClaims.data && state.qualityClaims.data.dataByClaimAmount;

/**
 * The raw data by days to resolve stored in state, from requests.
 * @param {*} state
 */
export const selectQualityClaimsDataByDaysToResolve = state =>
  state.qualityClaims.data && state.qualityClaims.data.dataByDaysToResolve;

/**
 * The raw categories stored in state.
 * @param {*} state
 */
export const selectQualityClaimsCategories = state => state.qualityClaims.data && state.qualityClaims.data.categories;

/**
 * The raw categories stored in state.
 * @param {*} state
 */
export const selectQualityClaimsPackerPlants = state =>
  state.qualityClaims.data && state.qualityClaims.data.packerPlants;

/**
 * The time scale in the filter ui, i.e., historically or YoY
 * @param {*} state
 */
export const selectQualityClaimsTimeRange = state => state.qualityClaims.timeRange;

/**
 * The time period selected by the date picker
 * @param {*} state
 */
export const selectQualityClaimsTimePeriod = state => state.qualityClaims.timePeriod;

/**
 * The time period frequency selected
 * @param {*} state
 */
export const selectQualityClaimsFrequency = state => state.qualityClaims.frequency;

/**
 * The active market
 * @param {*} state
 */
export const selectQualityClaimsMarket = state => MARKETS[state.qualityClaims.marketKey];

/**
 * The active region
 * @param {*} state
 */
export const selectQualityClaimsRegion = state => state.qualityClaims.region;

/**
 * The status setting
 * @param {*} state
 */
export const selectQualityClaimsStatus = state => state.qualityClaims.status;

/**
 * The count setting
 * @param {*} state
 */
export const selectQualityClaimsCount = state => state.qualityClaims.count;

/**
 * The amount setting
 * @param {*} state
 */
export const selectQualityClaimsAmount = state => state.qualityClaims.amount;

/**
 * The group by setting
 * @param {*} state
 */
export const selectQualityClaimsGroupBy = state => state.qualityClaims.groupBy;

/**
 * The filter by setting
 * @param {*} state
 */
export const selectQualityClaimsFilterBy = state => state.qualityClaims.filterBy;

/**
 * The selected filteredByPackerPlant to filter the data for
 * @param {*} state
 */
export const selectQualityClaimsFilteredByPackerPlant = state => state.qualityClaims.filteredByPackerPlant;

/**
 * The selected groupedByPackerPlant to group the data by
 * @param {*} state
 */
export const selectQualityClaimsGroupedByPackerPlant = state => state.qualityClaims.groupedByPackerPlant;

/**
 * The selected packerPlant from the Dashboard to filter the data for
 * @param {*} state
 */
export const selectQualityClaimsSelectedPackerPlantId = state => state.qualityClaims.selectedPackerPlantId;

/**
 * If the data should be grouped by a selected in the dashboard packer instead of filtering by it
 * @param {*} state
 */
export const selectQualityClaimsShouldBeGroupedByPacker = state => state.qualityClaims.shouldBeGroupedByPacker;

/**
 * The active grinder
 * @param {*} state
 */
export const selectQualityClaimsGrinder = state => state.qualityClaims.grinder;
