import { Col, Row, Typography } from 'antd';
import { differenceInMonths, differenceInYears, format, subYears } from 'date-fns';
import * as React from 'react';
import styles from './PackerPlantHistory.module.css';
import { group } from 'd3';

const MAX_ENTRIES_SHOW_LESS = 2;

const PackerPlantHistory = props => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { data, selectedPackerPlant } = props;
  if (!data || !selectedPackerPlant) return null;

  const diffInYears = differenceInYears(new Date(), new Date(data.dateApproval));
  const diffInMonths = differenceInMonths(subYears(new Date(), diffInYears), new Date(data.dateApproval));

  const pluralSuffix = diff => {
    if (diff === 1) return '';
    return 's';
  };

  const achievementsByCategory = group(data.keyAchievements, achievement => achievement.category);

  const needsShowMoreToggle = achievementsByCategory.size > MAX_ENTRIES_SHOW_LESS;

  return (
    <div>
      <p className={styles.PackerPlantHistory_numbers}>
        {selectedPackerPlant.name} has been on the ASL for{' '}
        {/* {diffInMonths === 0 && diffInYears === 0 ? (
          <strong>less than a month</strong>
        ) : diffInYears === 0 ? (
          <strong>
            {diffInMonths} {`month${pluralSuffix(diffInMonths)}`}
          </strong>
        ) : diffInMonths === 0 ? (
          <strong>
            {diffInYears} {`year${pluralSuffix(diffInYears)}`}
          </strong>
        ) : (
          <strong>
            {diffInYears} {`year${pluralSuffix(diffInYears)}`}, {diffInMonths} {`month${pluralSuffix(diffInMonths)}`}
          </strong>
        )} */}
        .
      </p>
      <Row>
        <Col span={24} style={{ marginBottom: 20, opacity: 0.6 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '30%' }}>ASL approval:</div>
            <div>
              <strong>{/* {format(new Date(data.dateApproval).getTime(), 'MMMM yyyy')} */}-</strong>
            </div>
          </div>
        </Col>
      </Row>
      <div className={styles.PackerPlantHistory_tables}>
        <div className={styles.PackerPlantHistory_table}>
          <div className={styles.PackerPlantHistory_title}>
            <Typography.Text>Key achievements</Typography.Text>
          </div>
          {Array.from(achievementsByCategory, ([category, achievements], index) => (
            <PackerPlantAchievement key={`subCat-${index}`} category={category} achievements={achievements} />
          )).slice(0, isOpen ? achievementsByCategory.size : MAX_ENTRIES_SHOW_LESS)}
          {needsShowMoreToggle && (
            <Typography.Text className={styles.PackerPlantHistory_toggleMore} onClick={() => setIsOpen(!isOpen)}>
              Read {isOpen ? 'less...' : 'more...'}
            </Typography.Text>
          )}
        </div>
      </div>
    </div>
  );
};

function PeriodText({ periodType, period }) {
  let periodText = '';
  if (periodType === 'YEAR_FROM_TO') {
    const from = format(new Date(period.from), 'yyyy');
    const to = period.to ? format(new Date(period.to), 'yyyy') : 'now';
    periodText = `${from} - ${to}`;
  }
  if (periodType === 'MONTH_FROM_TO') {
    const from = format(new Date(period.from), 'MMM-yyyy');
    const to = period.to ? format(new Date(period.to), 'MMM-yyyy') : 'now';
    periodText = `${from} - ${to}`;
  }
  if (periodType === 'MONTH') {
    periodText = format(new Date(period), 'MMM-yyyy');
  }
  if (periodType === 'YEAR') {
    periodText = format(new Date(period), 'yyyy');
  }
  if (periodType === 'IN_YEAR') {
    periodText = `in ${format(new Date(period), 'yyyy')}`;
  }

  return <em>{periodText}</em>;
}

function PackerPlantAchievement({ category, achievements }) {
  return (
    <div className={styles.PackerPlantHistory_tableRow} data-vertical={true}>
      <div style={{ width: '100%', paddingLeft: 20 }}>
        <Typography.Text className={styles.PackerPlantHistory_achievementCategory}>{category}</Typography.Text>
        {achievements.map(({ achievement, periodType, period }, index) => (
          <Typography.Text key={`achievement-${index}`} className={styles.PackerPlantHistory_achievementText}>
            {achievement} <PeriodText periodType={periodType} period={period} />
          </Typography.Text>
        ))}
      </div>
    </div>
  );
}

function PackerPlantNote({ note }) {
  return (
    <div className={styles.PackerPlantHistory_tableRow} data-vertical={true}>
      <div style={{ width: `100%`, paddingLeft: 20 }}>{note}</div>
    </div>
  );
}

export default PackerPlantHistory;
