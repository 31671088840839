import {
  CREATED_PACKER_OFFERS,
  PACKER_PLANT_OFFER_EDIT,
  PACKER_PLANT_OFFER_LINE_EDIT,
  PACKER_PLANT_OFFER_LINE_REMOVE,
  PACKER_OFFERS_ADDED,
  PACKER_OFFERS_FETCHED,
} from '../actions/actions_packer_offer';
import { DELETE_ORDER, LOAD_ORDER } from '../actions/actions_orders';

const packer_offers = (packerOffers = [], action) => {
  switch (action.type) {
    case PACKER_OFFERS_FETCHED:
      return action.payload;
    case PACKER_OFFERS_ADDED:
      const newPackerOffer = JSON.parse(JSON.stringify(packerOffers));
      newPackerOffer.unshift(action.payload);
      return newPackerOffer;
    case CREATED_PACKER_OFFERS:
      return Array.isArray(action.payload) ? action.payload.length : 1;
    case PACKER_PLANT_OFFER_EDIT:
      const newPackerOffers = JSON.parse(JSON.stringify(packerOffers));
      return replacePackerOffer(newPackerOffers, action.payload);
    case PACKER_PLANT_OFFER_LINE_EDIT:
      const pos = JSON.parse(JSON.stringify(packerOffers));

      if (Array.isArray(action.payload)) {
        return addPackerOfferLines(pos, action.payload);
      }
      return replacePackerOfferLine(pos, action.payload);
    case PACKER_PLANT_OFFER_LINE_REMOVE:
      return removePackerOfferLine([...packerOffers], action.payload);
    case LOAD_ORDER:
      return updatePackerOfferStatus([...packerOffers], action.payload);
    case DELETE_ORDER:
      return updatePackerOfferStatus([...packerOffers], action.payload);
    default:
      return packerOffers;
  }
};

function updatePackerOfferStatus(allPackerOffers, purchaseOrder) {
  const statusMap = {
    cancelled: 'cancelled',
    pending: 'pending',
    ordered: 'accepted',
  };

  const packerOffer = allPackerOffers.find(item => item.purchase_order_id === purchaseOrder.id);

  if (packerOffer) {
    packerOffer.status = statusMap[purchaseOrder.status];
    return replacePackerOffer(allPackerOffers, packerOffer);
  }

  return allPackerOffers;
}

function replacePackerOffer(allPackerOffers, editedPackerOffer) {
  const newPackerOffers = [];
  allPackerOffers.forEach(function (element) {
    if (element.id === editedPackerOffer.id) {
      newPackerOffers.push(editedPackerOffer);
    } else {
      newPackerOffers.push(element);
    }
  });
  return newPackerOffers;
}

function addPackerOfferLines(allPackerOffers, packerOfferLines) {
  if (packerOfferLines.length === 0) {
    return allPackerOffers;
  }

  const packerOffer = allPackerOffers.find(item => item.id === packerOfferLines[0].packer_offer_id);
  packerOffer.lines = packerOffer.lines.concat(packerOfferLines);

  return replacePackerOffer(allPackerOffers, packerOffer);
}

function replacePackerOfferLine(allPackerOffers, packerOfferLine) {
  const packerOffer = allPackerOffers.find(item => item.id === packerOfferLine.packer_offer_id);
  const newLines = [];

  packerOffer.lines.forEach(line => {
    if (line.id === packerOfferLine.id) {
      newLines.push(packerOfferLine);
    } else {
      newLines.push(line);
    }
  });
  packerOffer.lines = newLines;

  return replacePackerOffer(allPackerOffers, packerOffer);
}

function removePackerOfferLine(allPackerOffers, removedPackerOfferLine) {
  const packerOffer = allPackerOffers.find(item => item.id === removedPackerOfferLine.packer_offer_id);
  const packerOfferLineIndex = packerOffer.lines.findIndex(
    line => line.id === removedPackerOfferLine.packer_offer_line_id
  );

  packerOffer.lines.splice(packerOfferLineIndex, 1);

  return replacePackerOffer(allPackerOffers, packerOffer);
}

export default packer_offers;
