import { ChevronUpIcon, Search2Icon, SettingsIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Text,
  VStack,
  Input,
  Flex,
  Button,
  InputGroup,
  InputRightElement,
  HStack,
  Grid,
  Stack,
  SimpleGrid,
  GridItem,
  IconButton,
  CheckboxGroup,
  Checkbox,
  ButtonGroup,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Select,
  Heading,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import startCase from 'lodash/startCase';
import queryString from 'query-string';
import Constants from '../../Constants';
import { getSelectedOptions, orderOptions } from './DailyTaskUtils';
import { dashboardFilters } from './searchFiltersConfig';
import Loading from '../basic/Loading';
import SelectField from '../basic/SelectField';
import isEmpty from 'lodash/isEmpty';
import RightAngleIcon from '../core/RightAngleIcon';
import DailyTaskListTableContent from './DailyTaskListTableContent';
import { PaginationBar } from '../core/PaginationComponents';
import { TaskCategories } from './TaskCategories';
import AuthGate from '../../containers/auth/AuthGate';

export class DailyTasksDashboard extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
  };

  // Reference to exception_types
  taskTypesRef = React.createRef();

  state = {
    isLoading: true,
    isApplyingSearchFilter: false,
    exceptionGroups: {},
    userOptions: [],
    grinderOptions: [],
    packer_plantOptions: [],
    user: [],
    packer_plant: [],
    grinder: [],
    orderType: {},
    po: [],
    poSearchTerm: '',
    task_type: [],
    status: [],
    product_type: [],
    purchase_office: [],
    isFiltered: true,
    isReloading: false,
    assignedCheckBoxes: {
      unassigned: false,
      assigned: false,
    },
    isListView: false,
    allPurchaseOrders: {},
    pagination: {
      page: 1,
      size: 20,
    },
    resolvedCheckBoxes: {
      resolved: false,
      unresolved: true,
    },
  };

  getCategoryList = exceptionTypes => {
    if (exceptionTypes) {
      const categorySet = new Set(exceptionTypes.map(exceptionType => exceptionType.category));
      const categoryList = Array.from(categorySet);
      return categoryList;
    }
    return [];
  };

  fetchTasksByCategory = async (categories, queryParams) => {
    const url = `${Constants.URL}exceptions?is_multi_search=true&status=OPEN&${queryString.stringify({
      category: categories,
    })}${queryParams ? `&${queryParams}` : ''}`;
    try {
      const res = await axios.get(url, this.authHeaders);
      const data = get(res, 'data', []);

      if (data) {
        const groupedExceptions = groupBy(data[0].exceptions, 'exception.exception_type.category');
        const exceptionGroups = mapKeys(groupedExceptions, (v, k) => camelCase(k));
        this.setState((prevState, prevProps) => {
          return {
            exceptionGroups: {
              ...prevState.exceptionGroups,
              ...exceptionGroups,
            },
          };
        });
        return data;
      }
    } finally {
      this.setState({
        isLoading: false,
        isReloading: false,
        isApplyingSearchFilter: false,
      });
    }
  };

  fetchPurchaseOrders = async queryParams => {
    try {
      const url = `${Constants.URL}purchase_orders?is_multi_search=true${queryParams ? `&${queryParams}` : ''}`;
      const res = await axios.get(url, this.authHeaders);
      return get(res, 'data', []);
    } catch (error) {
      this.setState({
        isLoading: false,
        isReloading: false,
        isApplyingSearchFilter: false,
      });
      return [];
    }
  };

  async componentDidMount() {
    const { user, history } = this.props;
    const { pagination } = this.state;
    const { page, size } = pagination;
    const {
      location: { pathname, search },
    } = history;

    const parsedFilters = queryString.parse(search);
    const appliedFilters = { ...parsedFilters };

    // Determine default order type filter
    const defaultView = 'all';
    const orderTypeValue = parsedFilters.order_type || defaultView;
    const orderType = {
      label: startCase(orderTypeValue),
      value: orderTypeValue,
    };
    appliedFilters.order_type = orderType.value;

    // Determine user filter
    const loggedInUser = {
      value: user.id,
      label: `${user.first_name} ${user.last_name}`,
    };
    appliedFilters.user = parsedFilters.user || loggedInUser.value;

    appliedFilters.page = page;
    appliedFilters.size = size;
    appliedFilters.resolved = this.state.resolvedCheckBoxes.unresolved ? false : true;

    // Update Url
    const appliedFiltersQuery = queryString.stringify(appliedFilters);
    history.push(`${pathname}?${appliedFiltersQuery}`);

    try {
      // Get dropdown data
      const usersReq = axios.get(`${Constants.URL}users`, this.authHeaders);
      const grindersReq = axios.get(`${Constants.URL}grinders`, this.authHeaders);
      const packersReq = axios.get(`${Constants.URL}packer_plants`, this.authHeaders);
      const taskTypesReq = axios.get(`${Constants.URL}exception_types`, this.authHeaders);
      const [users, packerPlants, grinders, taskTypes] = await Promise.all([
        usersReq,
        packersReq,
        grindersReq,
        taskTypesReq,
      ]);

      // User options:
      const userOptions = get(users, 'data', []).filter(usr => !usr.delisted).map(usr => ({
        value: usr.id,
        label: `${usr.first_name} ${usr.last_name}`.trim(),
      }));
      const sortedUsers = orderOptions(userOptions);
      const selectedUsers = getSelectedOptions(appliedFilters.user, sortedUsers);
      // Grinder Options:
      const grinderOptions = get(grinders, 'data', []).filter(grinder => !grinder.delisted).map(grinder => ({
        value: grinder.uid,
        label: grinder.name,
      }));
      const sortedGrinders = orderOptions(grinderOptions);
      const selectedGrinders = getSelectedOptions(appliedFilters.grinder, sortedGrinders);

      // Packer Options:
      const packerPlantOptions = get(packerPlants, 'data', []).filter(packerPlant => !packerPlant.delisted).map(packerPlant => ({
        value: packerPlant.id,
        label: packerPlant.name,
      }));
      const sortedPackers = orderOptions(packerPlantOptions);
      const selectedPackers = getSelectedOptions(appliedFilters.packerPlant, sortedPackers);

      // Exception Type Options:
      const taskTypeOptions = get(taskTypes, 'data', []).map(taskTypeOption => ({
        value: taskTypeOption.name,
        label: taskTypeOption.label,
      }));
      const sortedTaskTypes = orderOptions(taskTypeOptions);
      const selectedTaskTypes = getSelectedOptions(appliedFilters.taskType, sortedTaskTypes);
      this.taskTypesRef.current = groupBy(get(taskTypes, 'data', []), 'category');

      // Common new state:
      const commonNewState = {
        orderType,
        isLoading: false,
        packer_plant: selectedPackers,
        grinder: selectedGrinders,
        task_type: selectedTaskTypes,
        user: selectedUsers,
        userOptions: [...sortedUsers],
        grinderOptions: sortedGrinders,
        packer_plantOptions: sortedPackers,
        task_typeOptions: sortedTaskTypes,
        appliedFiltersQuery,
        appliedFilters,
        isListView: appliedFilters.view === 'list',
      };

      // Fetch exceptions by category
      switch (defaultView) {
        case 'international': {
          await this.fetchTasksByCategory('international', appliedFiltersQuery);
          break;
        }
        case 'domestic': {
          await this.fetchTasksByCategory('domestic', appliedFiltersQuery);
          break;
        }
        case 'all':
        default: {
          let categories = [];
          for (let category in this.taskTypesRef.current) {
            categories.push(category);
          }
          await this.fetchTasksByCategory(categories, appliedFiltersQuery);
          break;
        }
      }

      const allPurchaseOrders = await this.fetchPurchaseOrders(appliedFiltersQuery);
      if (allPurchaseOrders.purchase_orders) {
        allPurchaseOrders.purchase_orders.sort();
      }

      this.setState((prevState, prevProps) => ({
        ...prevState,
        ...commonNewState,
        allPurchaseOrders,
      }));

      return {};
    } catch (error) {
      this.setState({
        isLoading: false,
        isApplyingSearchFilter: false,
        isReloading: false,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { orderType, isReloading, isApplyingSearchFilter } = this.state;

    if (isApplyingSearchFilter !== prevState.isApplyingSearchFilter || isReloading !== prevState.isReloading) {
      const { appliedFiltersQuery } = this.state;

      const allPurchaseOrders = await this.fetchPurchaseOrders(appliedFiltersQuery);
      if (allPurchaseOrders.purchase_orders) {
        allPurchaseOrders.purchase_orders.sort();
      }

      // Fetch exceptions by category
      switch (orderType.value) {
        case 'international': {
          await this.fetchTasksByCategory('international', appliedFiltersQuery);
          this.setState((prevState, prevProps) => ({
            ...prevState,
            allPurchaseOrders,
            isLoading: false,
            isApplyingSearchFilter: false,
            isReloading: false,
          }));
          break;
        }
        case 'domestic': {
          await this.fetchTasksByCategory('domestic', appliedFiltersQuery);
          this.setState((prevState, prevProps) => ({
            ...prevState,
            allPurchaseOrders,
            isLoading: false,
            isFiltered: true,
            isReloading: false,
            isApplyingSearchFilter: false,
          }));
          break;
        }
        case 'all':
        default: {
          let categories = [];
          for (let category in this.taskTypesRef.current) {
            categories.push(category);
          }
          await this.fetchTasksByCategory(categories, appliedFiltersQuery);
          this.setState((prevState, prevProps) => ({
            ...prevState,
            allPurchaseOrders,
            isLoading: false,
            isReloading: false,
            isApplyingSearchFilter: false,
          }));
          break;
        }
      }
    }
    return null;
  }

  handleFilterChange = (selected, fieldName) => {
    const selectedOptions = Array.isArray(selected) ? selected : [selected];

    const commonNewState = {
      ...this.state,
      [fieldName]: selected,
      isFiltered: true,
      appliedFilters: {
        ...this.state.appliedFilters,
        [fieldName]: selectedOptions.map(opt => opt.value),
      },
    };

    this.setState(commonNewState);
  };

  handleCheckboxChange = checkBoxName => {
    let appliedFilters = {
      ...this.state.appliedFilters,
    };

    const assignedValue = this.state.assignedCheckBoxes.assigned;
    const unassignedValue = this.state.assignedCheckBoxes.unassigned;
    if (assignedValue === !unassignedValue || !assignedValue === unassignedValue) {
      delete appliedFilters.assigned;
    } else if (checkBoxName === 'unassigned') {
      appliedFilters = {
        ...this.state.appliedFilters,
        assigned: unassignedValue,
      };
    } else {
      appliedFilters = {
        ...this.state.appliedFilters,
        assigned: !assignedValue,
      };
    }

    const commonNewState = {
      ...this.state,
      isFiltered: true,
      appliedFilters: {
        ...appliedFilters,
      },
      assignedCheckBoxes: {
        ...this.state.assignedCheckBoxes,
        [checkBoxName]: !this.state.assignedCheckBoxes[checkBoxName],
      },
    };

    this.setState(commonNewState);
  };

  handleResolveFilterCheckboxChange = checkBoxName => {
    const { history } = this.props;
    const {
      location: { pathname, search },
    } = history;
    const parsed = queryString.parse(search);
    let { appliedFilters } = this.state;

    const resolvedValue = this.state.resolvedCheckBoxes.resolved;
    const unresolvedValue = this.state.resolvedCheckBoxes.unresolved;
    if (resolvedValue === !unresolvedValue || !resolvedValue === unresolvedValue) {
      appliedFilters = {
        ...parsed,
      };
      delete appliedFilters.resolved;
    } else if (checkBoxName === 'unresolved') {
      appliedFilters = {
        ...parsed,
        resolved: unresolvedValue,
      };
    } else {
      appliedFilters = {
        ...parsed,
        resolved: !resolvedValue,
      };
    }
    const updatedQueryString = queryString.stringify(appliedFilters);
    history.push(`${pathname}?${updatedQueryString}`);

    const commonNewState = {
      ...this.state,
      isApplyingSearchFilter: true,
      isFiltered: true,
      appliedFilters,
      appliedFiltersQuery: updatedQueryString,
      resolvedCheckBoxes: {
        ...this.state.resolvedCheckBoxes,
        [checkBoxName]: !this.state.resolvedCheckBoxes[checkBoxName],
      },
    };

    this.setState(commonNewState);
  };

  handleClearFilter = () => {
    const { history } = this.props;
    const {
      location: { pathname },
    } = history;

    history.push(`${pathname}`);
    this.setState({
      po: [],
      user: [],
      packerPlant: [],
      grinder: [],
      task_type: [],
      status: [],
      product_type: [],
      purchase_office: [],
      poSearchTerm: '',
      isFiltered: false,
      isReloading: true,
      appliedFiltersQuery: '',
      assignedCheckBoxes: {
        unassigned: false,
        assigned: false,
      },
      pagination: {
        page: 1,
        size: 20,
      },
      appliedFilters: {},
    });
  };

  handlePOSearch = () => {
    const { poSearchTerm: text } = this.state;
    const { history } = this.props;
    const {
      location: { pathname, search },
    } = history;
    // const parsed = queryString.parse(search);
    const appliedFilters = {
      // ...parsed,
      ...this.state.appliedFilters,
      ...(!isEmpty(text) && { po_number: [text] }),
    };
    const updatedQueryString = queryString.stringify(appliedFilters);

    history.push(`${pathname}?${updatedQueryString}`);
    this.setState({
      po: [text],
      isApplyingSearchFilter: true,
      appliedFiltersQuery: updatedQueryString,
      allPurchaseOrders: {},
      exceptionGroups: {},
    });
  };

  handlePageChange = (fieldName, value) => {
    const { history } = this.props;
    const {
      location: { pathname, search },
    } = history;
    const parsed = queryString.parse(search);
    const appliedFilters = {
      ...parsed,
      [fieldName]: value,
    };
    const updatedQueryString = queryString.stringify(appliedFilters);

    const commonNewState = {
      ...this.state,
      isApplyingSearchFilter: true,
      isFiltered: true,
      appliedFilters: {
        ...this.state.appliedFilters,
        [fieldName]: value,
      },
      appliedFiltersQuery: updatedQueryString,
      pagination: { ...this.state.pagination, [fieldName]: value },
    };
    this.setState(commonNewState);

    history.push(`${pathname}?${updatedQueryString}`);
  };

  getExceptionGroupsData = viewFilter => {
    const { exceptionGroups } = this.state;
    const exceptionTypes = this.taskTypesRef.current[viewFilter];
    const categoryList = this.getCategoryList(exceptionTypes).filter(category => category);
    const exceptionData = categoryList.map(category => {
      return {
        label: startCase(category.replace('_', ' ')),
        data: exceptionGroups[camelCase(category)] || [],
        count: exceptionGroups[camelCase(category)]?.length || 0,
      };
    });
    return exceptionData;
  };

  getExceptionGroups = () => {
    const { getExceptionGroupsData } = this;
    const { orderType } = this.state;
    const showInternationalView = orderType.value === 'international';
    const showDomesticView = orderType.value === 'domestic';
    const showAllView = orderType.value === 'all';
    let data = [];
    if (showInternationalView) {
      data.push(...getExceptionGroupsData(showInternationalView));
    }
    if (showDomesticView) {
      data.push(...getExceptionGroupsData(showDomesticView));
    }
    if (showAllView) {
      for (let category in this.taskTypesRef.current) {
        data.push(...getExceptionGroupsData(category));
      }
    }
    return data.filter(group => group.count > 0);
  };

  handleTaskPatch = async (exceptionId, data) => {
    try {
      const response = await axios.patch(`${Constants.URL}exceptions/${exceptionId}`, data, this.authHeaders);

      const commonNewState = {
        ...this.state,
        isReloading: true,
      };
      this.setState(commonNewState);
      return response;
    } catch (e) {
      return null;
    }
  };

  toggleListView = () => this.setState(prevState => ({ isListView: !prevState.isListView }));

  render() {
    const {
      isLoading,
      isReloading,
      assignedCheckBoxes,
      isFiltered,
      isListView,
      isApplyingSearchFilter,
      poSearchTerm,
      allPurchaseOrders,
      pagination,
      userOptions,
    } = this.state;

    const { user } = this.props;

    return (
      <VStack align="stretch" spacing="28px">
        <Flex alignItems="center">
          <Heading fontSize="18px">Daily Tasks</Heading>
          <ButtonGroup ml="auto">
            <AuthGate requiredPermissions={['admin', 'exceptions_read']}>
              <Link to="/daily-tasks-admin" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button
                  rightIcon={<SettingsIcon />}
                  variant="solid"
                  size="lg"
                  ml="auto"
                  fontWeight="600"
                  fontSize="16px"
                  onClick={this.toggleListView}
                >
                  Admin
                </Button>
              </Link>
            </AuthGate>
            <Button
              rightIcon={!isListView && <i className="cfc-icon icon-ui-bullets" />}
              variant={isListView ? 'outline' : 'solid'}
              colorScheme="actionPrimary"
              size="lg"
              fontWeight="600"
              fontSize="16px"
              onClick={this.toggleListView}
            >
              {isListView ? 'Dashboard' : 'List View'}
            </Button>
          </ButtonGroup>
        </Flex>
        <VStack
          align="stretch"
          paddingTop="13px"
          paddingBottom="52px"
          paddingX="27px"
          boxShadow="md"
          borderRadius="15px"
          backgroundColor="card.default"
          spacing="30px"
        >
          <Flex alignItems="center" width="100%">
            <RightAngleIcon />
            <Heading fontSize="15px" marginLeft="10px">
              Search Filters
            </Heading>
            <IconButton ml="auto" aria-label="Collapse" bgColor="inherit" icon={<ChevronUpIcon />} />
          </Flex>

          <SimpleGrid columns={{ sm: 1, md: 3 }} spacingY="24px" spacingX="45px" minChildWidth="370px">
            {isLoading ? (
              <Box minW="100%">
                <Loading />
              </Box>
            ) : (
              <>
                <GridItem colSpan={1}>
                  <HStack spacing={0} mr="auto" justifyContent="space-between">
                    <Text fontWeight="medium" width="111px">
                      By PO#
                    </Text>
                    <InputGroup alignItems="center" width="264px" height="40px">
                      <Input
                        placeholder="Search"
                        fontSize="16px"
                        bgColor="white"
                        value={poSearchTerm}
                        onChange={e => this.setState({ poSearchTerm: e.target.value, isFiltered: true })}
                      />
                      <InputRightElement>
                        <Search2Icon color="gray.300" />
                      </InputRightElement>
                    </InputGroup>
                  </HStack>
                </GridItem>
                <GridItem colSpan={1} colStart={3}>
                  <CheckboxGroup colorScheme="actionSecondary">
                    <HStack spacing={5} alignItems="center" justifyContent="end">
                      <Checkbox
                        mb={0}
                        isChecked={assignedCheckBoxes.assigned}
                        onChange={() => this.handleCheckboxChange('assigned')}
                      >
                        Assigned
                      </Checkbox>
                      <Checkbox
                        mb={0}
                        isChecked={assignedCheckBoxes.unassigned}
                        onChange={() => this.handleCheckboxChange('unassigned')}
                      >
                        Unassigned
                      </Checkbox>
                    </HStack>
                  </CheckboxGroup>
                </GridItem>
                {dashboardFilters.map(sf => (
                  <GridItem colSpan={1}>
                    <SelectField
                      key={sf.name}
                      options={sf.options || this.state[`${sf.name}Options`] || []}
                      onChange={this.handleFilterChange}
                      {...sf}
                      containerWidth="264px"
                      labelProps={{ fontSize: '14px', width: '111px' }}
                      asHorizontalStack
                      containerStackProps={{
                        align: 'center',
                        justifyContent: 'space-between',
                      }}
                      value={this.state[sf.name]}
                    />
                  </GridItem>
                ))}
                <GridItem colSpan={{ sm: 1, md: 2 }}>
                  <ButtonGroup colorScheme="actionSecondary" spacing="6" float="right" width="fit-content">
                    {isFiltered && (
                      <Button
                        size="lg"
                        variant="link"
                        _hover={{ textDecoration: 'none' }}
                        fontSize="16px"
                        onClick={() => this.handleClearFilter()}
                      >
                        Clear Filters
                      </Button>
                    )}
                    <Button
                      variant="solid"
                      size="lg"
                      width="166px"
                      isDisabled={isApplyingSearchFilter || isReloading}
                      fontSize="16px"
                      onClick={() => this.handlePOSearch()}
                    >
                      Search POs
                    </Button>
                  </ButtonGroup>
                </GridItem>
              </>
            )}
          </SimpleGrid>
        </VStack>
        {!isLoading && (
          <Stack width="fit-content" alignSelf="start">
            <Alert status="info" variant="left-accent">
              <AlertIcon />
              Daily Task exceptions created and shown are only for last 3 months by default
            </Alert>
          </Stack>
        )}
        {isListView ? (
          <Box>
            <VStack
              align="stretch"
              paddingY="13px"
              paddingLeft="27px"
              paddingRight="30px"
              boxShadow="md"
              borderRadius="15px"
              backgroundColor="card.default"
              spacing="30px"
            >
              <Flex alignItems="center" width="100%">
                <RightAngleIcon />
                <Heading fontSize="15px" marginLeft="10px">
                  Tasks List
                </Heading>
                <CheckboxGroup colorScheme="actionSecondary">
                  <HStack spacing={5} alignItems="center" marginLeft="auto">
                    <Checkbox
                      isChecked={this.state.resolvedCheckBoxes.resolved === true}
                      mb={0}
                      onChange={() => this.handleResolveFilterCheckboxChange('resolved')}
                    >
                      <Text fontWeight="medium">Show Resolved Tasks</Text>
                    </Checkbox>
                    <Checkbox
                      isChecked={this.state.resolvedCheckBoxes.unresolved === true}
                      mb={0}
                      onChange={() => this.handleResolveFilterCheckboxChange('unresolved')}
                    >
                      <Text fontWeight="medium">Show Unresolved Tasks</Text>
                    </Checkbox>
                  </HStack>
                </CheckboxGroup>
              </Flex>
              <TableContainer bgColor="white">
                <Table variant="simple">
                  <Thead backgroundColor="card.default">
                    <Tr>
                      <Th>
                        <Text fontSize="12px">PO #</Text>
                      </Th>
                      <Th>
                        <Text as="p" fontSize="12px">
                          INTERNAL PO #
                        </Text>
                      </Th>
                      <Th>
                        <Text as="p" fontSize="12px">
                          GRINDER
                        </Text>
                      </Th>
                      <Th>
                        <Text as="p" fontSize="12px">
                          PACKER
                        </Text>
                      </Th>
                      <Th>
                        <Text as="p" fontSize="12px">
                          TASKS FOUND
                        </Text>
                      </Th>
                      <Th textAlign="center">
                        <Text as="p" fontSize="12px">
                          USERS
                        </Text>
                      </Th>
                      <Th textAlign="center">
                        <Text as="p" fontSize="12px">
                          RESOLVE
                        </Text>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {isApplyingSearchFilter ? (
                      <Tr>
                        <Td colSpan={7}>
                          <Flex width="100%" height="200px" justifyContent="center" alignItems="center">
                            <Loading />
                          </Flex>
                        </Td>
                      </Tr>
                    ) : (
                      <DailyTaskListTableContent
                        token={this.props.token}
                        dispatch={this.props.dispatch}
                        allPurchaseOrders={allPurchaseOrders}
                        onResolveClick={async (exceptionId, data) => {
                          await this.handleTaskPatch(exceptionId, data);
                          this.setState({});
                        }}
                        userOptions={userOptions}
                        onAssign={async (exceptionId, data) => {
                          await this.handleTaskPatch(exceptionId, data);
                          this.setState({});
                        }}
                      />
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
              {!isEmpty(allPurchaseOrders.pagination) && allPurchaseOrders.pagination.pages > 0 && (
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                  <GridItem colSpan={11}>
                    <PaginationBar
                      pages={allPurchaseOrders.pagination.pages}
                      currentPage={pagination.page}
                      onPageChange={page => this.handlePageChange('page', page)}
                      isApplyingSearchFilter={isApplyingSearchFilter}
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Box width="119px">
                      <Select placeholder="Show 20" onChange={e => this.handlePageChange('size', e.target.value)}>
                        <option value="10">Show 10</option>
                        <option value="20">Show 20</option>
                        <option value="30">Show 30</option>
                        <option value="40">Show 40</option>
                        <option value="50">Show 50</option>
                      </Select>
                    </Box>
                  </GridItem>
                </Grid>
              )}
            </VStack>
          </Box>
        ) : (
          <SimpleGrid columns={{ sm: 1, md: 4 }} spacing="37px" minChildWidth="286px" mt="48px">
            {isApplyingSearchFilter || isReloading ? (
              <Box minW="100%">
                <Loading />
              </Box>
            ) : (
              <TaskCategories groups={this.getExceptionGroups()} onViewAll={this.toggleListView} />
            )}
          </SimpleGrid>
        )}
      </VStack>
    );
  }
}

//TODO Fix Issues With Router

export default withRouter(DailyTasksDashboard);
