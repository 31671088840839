/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { startOfDay, subYears } from 'date-fns';

import fetchTreasuryDataFixture from '../../data-fixtures/treasury-all';
import { TREASURY_TIME_INTERVAL, TREASURY_PROTEIN, INITIAL_ANNOTATIONS_DATA } from './treasuryConfig';
import { selectTreasuryActiveMarket, selectTreasuryTimeScale } from './selectors/selectTreasuryBasicData';
import { fetchAnnotations, setAnnotations } from '../annotations/annotations';

const PAST_YEAR_COUNT = 2;
const treasurySlice = createSlice({
  name: 'treasury',
  initialState: {
    loading: false,
    annotationsLoading: false,
    error: null,
    data: null,
    timeRange: [startOfDay(subYears(new Date(), PAST_YEAR_COUNT)).getTime(), startOfDay(new Date()).getTime()],
    timeScale: TREASURY_TIME_INTERVAL.QUARTERLY,
    activeProtein: TREASURY_PROTEIN.BEEF,
    activeCurrency: 'USD',
    activeMarket: 'USA', // TODO: this should be a shared reducer with market
    annotationsData: INITIAL_ANNOTATIONS_DATA,
  },
  reducers: {
    updateAnnotationsLoading(state, action) {
      state.annotationsLoading = action.payload;
    },
    treasuryDataRequest(state) {
      state.loading = true;
      state.error = null;
    },
    treasuryDataRequestSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    treasuryDataRequestFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateTimeScale(state, action) {
      state.timeScale = action.payload;
    },
    updateTimeRange(state, action) {
      state.timeRange = action.payload;
    },
    updateActiveProtein(state, action) {
      state.activeProtein = action.payload;
    },
    updateActiveCurrency(state, action) {
      state.activeCurrency = action.payload;
    },
    updateAnnotationsData(state, action) {
      state.annotationsData = action.payload;
    },
  },
});

export const {
  treasuryDataRequest,
  treasuryDataRequestSuccess,
  treasuryDataRequestFailure,
  updateTimeScale,
  updateTimeRange,
  updateActiveProtein,
  updateActiveCurrency,
  updateAnnotationsData,
  updateAnnotationsLoading,
} = treasurySlice.actions;

// ----------------------------
// THUNKS
// ----------------------------

/**
 * Thunk to fetch and update data in place.
 * @param {TreasuryFetchParameters} fetchParameters
 */
export const fetchTreasuryData = fetchParameters => {
  return async dispatch => {
    dispatch(treasuryDataRequest());
    try {
      const marketsData = await fetchTreasuryDataFixture(fetchParameters);
      dispatch(treasuryDataRequestSuccess(marketsData));
      return marketsData;
    } catch (error) {
      dispatch(treasuryDataRequestFailure(error.toString()));
      return error;
    }
  };
};

/**
 * Thunk to fetch annotations.
 */
export const fetchTreasuryAnnotations = () => {
  return async (dispatch, getState) => {
    dispatch(updateAnnotationsLoading(true));
    try {
      const state = getState();

      const baseScope = {
        page: 'treasury',
        focus: selectTreasuryActiveMarket(state),
        timeScale: selectTreasuryTimeScale(state),
      };

      // Reset to empty on a fresh fetch
      dispatch(setAnnotations([]));

      await Promise.all([
        dispatch(fetchAnnotations({ ...baseScope, module: 'risk-management' })),
        dispatch(fetchAnnotations({ ...baseScope, module: 'performance-and-forecast' })),
        dispatch(
          fetchAnnotations({
            ...baseScope,
            module: 'cost-savings',
          })
        ),
        dispatch(
          fetchAnnotations({
            ...baseScope,
            module: 'expected-volume',
          })
        ),
      ]);

      dispatch(updateAnnotationsLoading(false));
      return true;
    } catch (error) {
      dispatch(treasuryDataRequestFailure(error.toString()));
      return error;
    }
  };
};
export default treasurySlice.reducer;
