import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, HStack, IconButton, Input, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { MdOutlineInfo } from 'react-icons/md';
import { ReactComponent as TrashOutlineIconSVG } from '../../../../assets/trash-outline-icon.svg';
import { cloneDeep } from 'lodash';
import SelectField from '../../../basic/SelectField';
import Tooltip from '../../../quality/_components/Tooltip';

const PriceAdjustmentTableTd = ({ children, ...rest }) => {
  return (
    <Td paddingRight="57px" paddingLeft={0} paddingTop="8px" {...rest}>
      {children}
    </Td>
  );
};

PriceAdjustmentTableTd.propTypes = {
  children: PropTypes.node.isRequired,
};

const PriceAdjustment = ({ line, isInEditMode, onChange, isLocked }) => {
  const [productLine, setProductLine] = useState(cloneDeep(line));
  const [state, setState] = useState({
    deletedPriceAdjustmentIds: [],
    isExpanded: false,
  });

  const columns = [
    {
      title: 'Value',
      dataIndex: 'value',
      isRequired: true,
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      isRequired: true,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
  ];

  const onLinePriceAdjustmentsChange = (idx, field, value) => {
    setProductLine(prevProdLine => {
      const productLineClone = cloneDeep(prevProdLine);
      const linePriceAdjustment = productLineClone.priceAdjustments[idx];
      linePriceAdjustment[field] = value;
      linePriceAdjustment.purchaseOrderLineId = productLineClone.id;

      return productLineClone;
    });
  };
  const handleAdd = count => {
    const productLineClone = cloneDeep(productLine);
    // increment
    if (count > productLineClone?.priceAdjustments?.length) {
      // length count starts from 0
      productLineClone?.priceAdjustments?.push({
        value: '',
        reason: null,
        notes: '',
      });
    }
    // decrement
    else {
      productLineClone?.priceAdjustments?.pop();
    }
    setProductLine(productLineClone);
  };

  const handleDelete = (linePriceAdjustmentId, idx) => {
    const productLineClone = cloneDeep(productLine);
    if (linePriceAdjustmentId) {
      setState(prevState => ({
        ...prevState,
        deletedPriceAdjustmentIds: [...prevState.deletedPriceAdjustmentIds, linePriceAdjustmentId],
      }));
      productLineClone.priceAdjustments.splice(
        productLineClone.priceAdjustments.findIndex(i => i.id === linePriceAdjustmentId),
        1
      );
      const deletedPriceAdjustmentIds = [...state.deletedPriceAdjustmentIds];
      // save the removed ids and make a request to api on Save
      deletedPriceAdjustmentIds.push(linePriceAdjustmentId);
      setState(prevState => ({
        ...prevState,
        deletedPriceAdjustmentIds,
      }));
    } else {
      productLineClone.priceAdjustments.splice(idx, 1);
    }
    setProductLine(productLineClone);
  };

  // -------------------------
  //   Lifecycle Hook
  // -------------------------
  useEffect(() => {
    onChange(productLine.idx, productLine.id, productLine.priceAdjustments, state.deletedPriceAdjustmentIds);

    return () => {
      setState(prevState => ({
        ...prevState,
        deletedPriceAdjustmentIds: [],
      }));
    };
  }, [productLine]);

  useEffect(() => {
    setProductLine(cloneDeep(line));
  }, []);

  return (
    <Box>
      <Flex justifyContent="space-between">
        <HStack justifyContent="space-between" color="accent.one.default" width="145px">
          <Text color="inherit" fontWeight="bold">
            Price Adjustment
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="24px"
            icon={state.isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
            onClick={() => setState({ ...state, isExpanded: !state.isExpanded })}
          />
        </HStack>
        {state.isExpanded && !isLocked && productLine.id && (
          <Flex>
            <IconButton
              marginLeft="auto"
              onClick={() => handleAdd((productLine?.priceAdjustments?.length || 0) + 1)}
              isDisabled={!isInEditMode}
              icon={
                <i
                  className="la la-plus international-order-line__icon--orange"
                  style={{
                    fontSize: '16px',
                  }}
                />
              }
              colorScheme="actionSecondary"
              height="25px"
              minWidth="25px"
              borderRadius="32px"
            />
          </Flex>
        )}
      </Flex>

      {state.isExpanded && (
        <Table variant="unstyled">
          <Thead>
            <Tr>
              {columns.map(column => (
                <Th
                  key={column.dataIndex}
                  paddingLeft={0}
                  paddingBottom={0}
                  textTransform="capitalize"
                  fontSize="14px"
                  fontWeight="500"
                  fontFamily="var(--chakra-fonts-body)"
                >
                  {column.title} {column.isRequired && isInEditMode ? ' *' : ''}
                </Th>
              ))}
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {productLine.priceAdjustments &&
              productLine.priceAdjustments.map((priceAdjustment, idx) => (
                <Tr key={priceAdjustment.id || idx}>
                  <PriceAdjustmentTableTd>
                    <Input
                      name="price-adjustment-value"
                      className="form-control"
                      type="number"
                      value={priceAdjustment.value || ''}
                      isDisabled={!isInEditMode || priceAdjustment.id}
                      placeholder="Value"
                      onChange={e => onLinePriceAdjustmentsChange(idx, 'value', e.target.value)}
                      backgroundColor={!isInEditMode || priceAdjustment.id ? 'inherit' : 'white'}
                      minW="90px"
                    />
                  </PriceAdjustmentTableTd>
                  <PriceAdjustmentTableTd>
                    <Box width="360px">
                      <SelectField
                        {...{
                          isDisabled: !isInEditMode || priceAdjustment.id,
                          isSearchable: true,
                          closeMenuOnSelect: false,
                          name: 'select-reason',
                          defaultValue: priceAdjustment.reason,
                          onChange: e => onLinePriceAdjustmentsChange(idx, 'reason', e.value),
                          options: [
                            { value: 'price_error', label: 'Price Error' },
                            { value: 'claim', label: 'Claim' },
                            { value: 'flagship_farmer_recoup', label: 'Flagship Farmer Recoup' },
                            { value: 'other', label: 'Other' },
                          ],
                        }}
                      />
                    </Box>
                  </PriceAdjustmentTableTd>
                  <PriceAdjustmentTableTd>
                    <Input
                      minW="250px"
                      name="price-adjustment-notes"
                      className="form-control"
                      value={priceAdjustment.notes || ''}
                      isDisabled={!isInEditMode || priceAdjustment.id}
                      placeholder="Add Notes"
                      onChange={e => onLinePriceAdjustmentsChange(idx, 'notes', e.target.value)}
                      backgroundColor={!isInEditMode ? 'inherit' : 'white'}
                    />
                  </PriceAdjustmentTableTd>
                  <PriceAdjustmentTableTd paddingRight={0}>
                    {priceAdjustment.isAddedAfterPoLocked && (
                      <Tooltip content="This price adjustment added after purchase order locked." placement="left">
                        <MdOutlineInfo size="22px" color="red" />
                      </Tooltip>
                    )}
                    {!priceAdjustment.id && (
                      <IconButton
                        variant="ghost"
                        icon={<TrashOutlineIconSVG />}
                        isDisabled={!isInEditMode}
                        onClick={() => {
                          handleDelete(priceAdjustment.id, idx);
                        }}
                      />
                    )}
                  </PriceAdjustmentTableTd>
                </Tr>
              ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

PriceAdjustment.propTypes = {
  onChange: PropTypes.func,
  isInEditMode: PropTypes.bool,
  line: PropTypes.instanceOf(PropTypes.object),
  isLocked: PropTypes.bool,
};

export default PriceAdjustment;
