import * as Yup from 'yup';
import { orderDetailsSection, deliverySection, orderLinesSection, newOrderLine, footerSection } from './FormSections';

const newOrder = {
  lineList: [newOrderLine],
};

export function initialize(
  netsuitePurchaseOrder,
  packerPlantInOrder,
  packerInOrder,
  poDispositionInOrder,
  transporterInOrder,
  dropTrailerOpation,
  inputProductOptions,
  orderTypeOptions
) {
  const grinderOption = {
    label: netsuitePurchaseOrder.grinder.name,
    value: netsuitePurchaseOrder.grinder.uid,
  };

  const packerOption = {
    ...packerInOrder,
  };

  const packerPlantOption = {
    ...packerPlantInOrder,
  };

  const poDispositionOption = {
    ...poDispositionInOrder,
  };

  const transporterOption = {
    ...transporterInOrder,
  };

  let deliveryTime = null;
  if (netsuitePurchaseOrder.scheduled_delivery_time) {
    if (netsuitePurchaseOrder.scheduled_delivery_time.length > 5) {
      deliveryTime = netsuitePurchaseOrder.scheduled_delivery_time.slice(0, 5);
    } else {
      deliveryTime = netsuitePurchaseOrder.scheduled_delivery_time;
    }
  }

  let orderLineList = [];
  if (netsuitePurchaseOrder.lines) {
    netsuitePurchaseOrder.lines.map(orderLine =>
      orderLineList.push({
        product: inputProductOptions.find(itm => itm.value === orderLine.input_product_uid),
        weight: orderLine.buy_quantity,
        receivedWeight: orderLine.recv_quantity,
        tag: {},
        orderType: orderTypeOptions.find(itm => itm.value === orderLine.price_type),
        fobPrice: '',
        packerDeliveredPrice: '',
        nofName: {},
      })
    );
  } else {
    orderLineList = [newOrderLine];
  }

  return {
    grinder: grinderOption,
    packer: packerOption,
    packerPlant: packerPlantOption,
    estId: packerPlantInOrder.est,
    packerSoNumber: netsuitePurchaseOrder.packer_so_number,
    freightRate: netsuitePurchaseOrder.transport_cost_per_unit,
    disposition: poDispositionOption,
    standing: netsuitePurchaseOrder.is_standing,
    deliveryDate: netsuitePurchaseOrder.delivery_date,
    deliveryTime,
    carrier: transporterOption,
    dropTrailer: dropTrailerOpation,
    isRecurring: false,
    orderLineList,
  };
}

export const validationSchema = Yup.object().shape({
  packer: Yup.object().shape({
    value: Yup.string().required('Please select a packer.'),
  }),
  grinder: Yup.object().shape({
    value: Yup.string().required('Please select a grinder.'),
  }),
  isFresh: Yup.bool(),
  isRecurring: Yup.bool(),
  orderList: Yup.array().of(
    Yup.object().shape({
      packerPlantId: Yup.string().required('Please enter an establishment ID.'),
      packerPlant: Yup.object().shape({
        value: Yup.string().required('Please select an establishment.'),
      }),
      freightRate: Yup.number('Please enter a freight rate.').nullable().required('Please enter a freight rate.'),
      dateStart: Yup.string().required('Please add a date.'),
      dateEnd: Yup.string().required('Please add a date.'),
      disposition: Yup.object().shape({
        value: Yup.string(),
      }),
      isStanding: Yup.bool(),
      lineList: Yup.array().of(
        Yup.object().shape({
          product: Yup.object().shape({ value: Yup.string().required('Please select a product.') }),
          quantity: Yup.number().required('Please enter a quantity.'),
          tag: Yup.object().shape({ value: Yup.string() }).nullable().required('Please select a tag.'),
          orderType: Yup.object().shape({ value: Yup.string().required('Please select a type.') }),
          fobPrice: Yup.number('Price must be a number.').required('Please enter a price.'),
          nofName: Yup.object().shape({ value: Yup.string().required('Please select a name.') }),
          packerDeliveredPrice: Yup.number('Price must be a number.').required('Please enter a price.'),
        })
      ),
    })
  ),
});

export const formConfig = [orderDetailsSection, deliverySection, orderLinesSection, footerSection];
