const schema = [
  {
    name: 'address',
    displayName: 'Address',
    nested: true,
    systemIdField: null,
    displayIdField: null,
    deletable: true,
    fields: [
      {
        name: 'line1',
        displayName: 'Line1',
        dataType: 'text',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'line2',
        displayName: 'Line2',
        dataType: 'text',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'city',
        displayName: 'City',
        dataType: 'shorttext',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'state',
        displayName: 'State',
        dataType: 'shorttext',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'country',
        displayName: 'Country',
        dataType: 'simple-reference',
        referenceEntityType: 'country',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'post_code',
        displayName: 'Post Code',
        dataType: 'shorttext',
        required: false,
        systemIdentifier: false,
      },
    ],
  },
];
export default schema;
