import React from 'react';
import AuthGate from '../../../containers/auth/AuthGate';

const Tab = props => {
  const { tabKey, name, onClick, authConfig, active, onTabClick } = props;
  const tabElement = (
    <li key={tabKey} className={`nav-item m-tabs__item ${active ? 'active' : ''}`}>
      <a
        className="nav-link m-tabs__link"
        onClick={() => {
          onTabClick(tabKey);

          if (onClick) {
            onClick();
          }
        }}
      >
        {name}
      </a>
    </li>
  );
  if (authConfig) {
    return <AuthGate {...authConfig}>{tabElement}</AuthGate>;
  }

  return tabElement;
};

export default Tab;
