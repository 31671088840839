import { Typography } from 'antd';
import React from 'react';
import styles from './DetailPageHeading.module.css';
import PropTypes from 'prop-types';

function DetailPageHeading({ title }) {
  return (
    <Typography.Title level={3} className={styles.pageHeading}>
      {title}
    </Typography.Title>
  );
}

DetailPageHeading.propTypes = {
  title: PropTypes.string,
};

export default DetailPageHeading;
