export const uniq = data => [...new Set(data)];

export const flatten = array => {
  return array.reduce((flattened, subArray) => {
    return [...flattened, ...subArray];
  }, []);
};
export const sentenceCase = string => {
  if (string == null) return '';
  const newString = `${string}`;
  const lower = newString.toLowerCase();
  return `${string[0].toUpperCase()}${lower.slice(1)}`;
};
