import { renderCard, renderGrid } from '../../../../lib/formLoader';

const deliverySection = {
  headerText: 'Delivery',
  renderComponent: renderCard,
  children: [
    {
      renderComponent: renderGrid,
      templateColumns: 'repeat(8, 1fr)',
      gap: 9,
      children: [
        {
          label: 'Scheduled Delivery Date',
          name: 'deliveryDate',
          fieldType: 'date',
          timeFormat: false,
          dateFormat: 'YYYY-MM-DD',
          colSpan: 2,
        },
        {
          label: 'Delivery Time',
          name: 'deliveryTime',
          fieldType: 'date',
          timeConstraints: { minutes: { step: 5 } },
          timeFormat: 'HH:mm',
          dateFormat: false,
          colSpan: 2,
        },
        {
          label: 'Carrier',
          name: 'carrier',
          optionsKey: 'carrier',
          fieldType: 'select',
          colSpan: 2,
        },
        {
          label: 'Drop Trailer',
          name: 'dropTrailer',
          optionsKey: 'dropTrailer',
          fieldType: 'select',
          colSpan: 2,
          height: '77px',
        },
      ],
    },
  ],
};

export default deliverySection;
