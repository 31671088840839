import React from 'react';
import { Select, Divider } from 'antd';
import styles from './FilterPackerPlants.module.css';

export default function FilterPackerPlants(props) {
  const { value, options, onChange } = props;

  const handleChange = val => {
    const selectedPackerPlant = options.find(x => x.id === val);
    if (selectedPackerPlant) {
      onChange(selectedPackerPlant);
    }
  };

  return (
    <div>
      <Select
        showSearch
        value={value.id}
        style={{ width: '300px', textAlign: 'left' }}
        onChange={handleChange}
        className={styles.QualityFilterPackerPlants_selectBox}
        filterOption={(input, option) => {
          if (typeof option.children === 'string') {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      >
        {options.map(({ est, id, name, isPacker }) => {
          if (id === 'Divider') {
            return (
              <Select.Option key={'divider'} disabled={true} style={{ overflow: 'none', minHeight: 5 }}>
                <Divider style={{ margin: 0 }} />
              </Select.Option>
            );
          }
          return (
            <Select.Option key={`packer-plant-${id}`} value={id}>
              {isPacker
                ? `--- ${name} (${id}) ---`
                : ['All', 'Australia', 'New Zealand'].includes(id)
                ? name
                : `${name} (${est})`}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}
