import Constants from '../Constants';

export const LOAD_DAILY_TASK_DETAILS = 'LOAD_DAILY_TASK_DETAILS';
export const ERROR_DAILY_TASK_DETAILS = 'ERROR_DAILY_TASK_DETAILS';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadDailyTaskDetails(dailyTaskDetails) {
  return {
    type: LOAD_DAILY_TASK_DETAILS,
    payload: dailyTaskDetails,
  };
}

export function errorDailyTaskDetails(error) {
  return {
    type: ERROR_DAILY_TASK_DETAILS,
    payload: { error },
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchDailyTaskDetails(token, grinderPoId) {
  return dispatch => {
    const headers = new Headers({ Authorization: `Token ${token}` });

    return fetch(`${Constants.URL}exceptions/purchase_order/${grinderPoId}`, {
      method: 'GET',
      headers,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorDailyTaskDetails))
      .then(json => {
        dispatch(loadDailyTaskDetails(json));
      });
  };
}
