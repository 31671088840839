import React, { useRef } from 'react';
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import './DateTimeWithTrappedFocus.scss';

const DateTimeWithTrappedFocus = props => {
  const componentRef = useRef(null);
  return (
    <Datetime
      {...props}
      inputProps={{
        ...props.inputProps,
        ref: componentRef,
        className: `${props.inputProps.className} form-control m-input date-time__input`,
      }}
      onChange={e => {
        componentRef.current.focus();
        props.onChange(e);
      }}
    />
  );
};

DateTimeWithTrappedFocus.propTypes = {
  inputProps: PropTypes.objectOf(PropTypes.object),
  onChange: PropTypes.func,
};

export default DateTimeWithTrappedFocus;
