import { connect } from 'react-redux';

import DailyTasksDashboardComponent from '../../components/dailyTasks/DailyTasksDashboard';

const mapStateToProps = state => {
  return {
    user: state.user,
    token: state.user.token,
  };
};

const DailyTasksDashboard = connect(mapStateToProps)(DailyTasksDashboardComponent);

export default DailyTasksDashboard;
