import { SELECT_CURRENT_MODAL_DETAIL } from '../actions/index';

const current_modal_detail = (state = false, action) => {
  switch (action.type) {
    case SELECT_CURRENT_MODAL_DETAIL:
      if (state.uid === action.payload.uid) {
        return false;
      }
      return action.payload;
    default:
      return state;
  }
};

export default current_modal_detail;
