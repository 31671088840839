import { connect } from 'react-redux';

import UserSettingsComponent from '../../components/pages/UserSettings';

const mapStateToProps = state => {
  // Any props of items can be accessed
  return {
    user: state.user,
    token: state.user.token,
    password_reset: state.password_reset,
  };
};

const UserSettings = connect(mapStateToProps)(UserSettingsComponent);

export default UserSettings;
