import React from 'react';
import Hashes from 'jshashes';
import { Avatar } from 'antd';

// "Adopted" from https://stackoverflow.com/questions/1855884/determine-font-color-based-on-background-color#answer-44615197
const hexToLuma = color => {
  const hex = color.replace(/#/, '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  return [0.299 * r, 0.587 * g, 0.114 * b].reduce((accumulator, current) => accumulator + current) / 255;
};

const getInitial = namePart => {
  return namePart.substring(0, 1).toUpperCase();
};

const UserAvatar = ({ user, size }) => {
  if (user.imgUrl) {
    // This will not be called until user images are supported, but adding the logic now
    // so we don't have to work in the future to support custom images as avatars
    return <Avatar src={user.imgUrl} size={size} />;
  }

  // Fallback to an initial based colored avatar derived from the users email.
  const userHash = new Hashes.SHA1().hex(user.email);
  const userBG = userHash.substring(userHash.length - 6, userHash.length);
  const userInitials = `${getInitial(user.first_name)}${getInitial(user.last_name)}`;

  return (
    <Avatar
      style={{
        backgroundColor: `#${userBG}`,
        color: hexToLuma(userBG) < 0.5 ? 'white' : 'black',
      }}
      size={size}
    >
      {userInitials}
    </Avatar>
  );
};

export default UserAvatar;
