import * as Yup from 'yup';
import get from 'lodash/get';
import { withFormik } from 'formik';

import EmailModal from './EmailModal';

export function initializeForm(props) {
  const initialValues = get(props, 'initialValues') || {};
  return {
    body: initialValues.body || '',
    subject: initialValues.subject || '',
  };
}

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    subject: Yup.string().required('Required'),
    body: Yup.string().required('Required'),
  }),
  handleSubmit: (values, form) => {
    form.props.onSubmit({
      ...values,
      attachmentList: form.props.attachmentList,
    });
    form.setSubmitting(false);
  },
  mapPropsToValues: initializeForm,
});

export default formikEnhancer(EmailModal);
