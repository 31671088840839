import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { checkObjectForValues } from '../../lib/PurchaseOrderFunctions';

export function getFIWarnings(order) {
  const warnings = [];
  const voyage = get(order, 'voyage[0]');
  const billOfLading = get(order, 'billOfLading');
  const hasValidCartonCount = get(order, 'hasValidFiCartons');
  const containerType = get(order, 'loadContainerDetails');

  const hasVoyageDetails = checkObjectForValues(voyage, ['dischargePort', 'dischargePortId', 'id']);
  const hasBillOfLading = checkObjectForValues(billOfLading);
  if (!hasVoyageDetails) {
    warnings.push(
      'Purchase Order missing required voyage fields: ShippingLine, Vessel Name, Voyage Number, Port of Loading, Port of Discharge'
    );
  } else {
    const missingFields = [];
    if (!voyage.shippingLineId) {
      missingFields.push('Shipping Line');
    }
    if (!voyage.vesselName) {
      missingFields.push('Vessel Name');
    }
    if (!voyage.voyageNumber) {
      missingFields.push('Voyage Number');
    }
    if (!voyage.loadPortId) {
      missingFields.push('Port of Loading');
    }
    if (!voyage.dischargePortId) {
      missingFields.push('Port of Discharge');
    }
    if (missingFields.length) {
      warnings.push(`Purchase Order missing required Voyage details: ${missingFields}`);
    }
  }
  if (!hasBillOfLading) {
    warnings.push(
      'Purchase Order missing required Bill of Lading fields:Booking Reference Number, Container Number, Seal Number, Gross Shipped Weight, Container Type'
    );
  } else {
    const missingFields = [];
    if (!billOfLading.bookingReferenceNumber) {
      missingFields.push('Booking Reference Number');
    }
    if (!billOfLading.containerNumber) {
      missingFields.push('Container Number');
    }
    if (!billOfLading.sealNumber) {
      missingFields.push('Seal Number');
    }
    if (!billOfLading.grossShippedWeight) {
      missingFields.push('Gross Shipped Weight');
    }
    if (!containerType) {
      missingFields.push('Container Type');
    }
    if (missingFields.length) {
      warnings.push(`Purchase Order missing required Bill of Lading fields: ${missingFields}`);
    }
  }
  if (!hasValidCartonCount) {
    warnings.push('Production details carton count does not match shipment port mark carton count.');
  }
  return warnings;
}

export function getCOOWarnings(order) {
  const warnings = [];
  const grinderCountry = get(order, 'grinder.destinationCountry');
  const hasProductionDates = get(order, 'lines')[0].productionDetails.length;
  if (grinderCountry !== 'South Korea') {
    warnings.push("COO is only applicable for grinders from 'South Korea'.");
  }
  if (!hasProductionDates) {
    warnings.push('Missing production dates.');
  }
  if (order.grinderInvoiceStatus !== 'invoiced_final') {
    warnings.push('Final Invoice has to be generated before creating COO.');
  }
  return warnings;
}

export function getPackingListWarnings(order) {
  const warnings = [];
  const grossShippedWeight = get(order, 'billOfLading.grossShippedWeight');
  if (!grossShippedWeight) {
    warnings.push('Gross Shippped Weight (under the Bill of Lading section) is required to generate a Packing List.');
  }
  const lines = get(order, 'lines') || [];
  const lineMissingProductionDetails = lines.find(line => {
    if (!line.active) return false;
    return isEmpty(line.productionDetails);
  });
  
  if (lineMissingProductionDetails) {
    lines.length === 1 ? warnings.push('Production Details is required to generate a Packing List.') :
    warnings.push('Production Details (for each line) is required to generate a Packing List.');
  }

  return warnings;
}
