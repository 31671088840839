import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ErrorMessage, Field } from 'formik';
import get from 'lodash/get';

import SelectField from '../../basic/SelectField';

function FormSelect(props) {
  const {
    indices,
    value,
    values,
    name,
    options,
    defaultValue,
    onChange,
    closeMenuOnSelect,
    isDisabled,
    label,
    onFilterOptions,
    ...rest
  } = props;
  const fieldName = typeof name === 'function' ? name(indices) : name;

  let filteredOptions = options;
  if (onFilterOptions) {
    filteredOptions = options?.filter(opt => onFilterOptions(opt, values));
  }

  return (
    <Box>
      <Field name={fieldName} id={fieldName}>
        {({ form: { setFieldValue } }) => (
          <SelectField
            {...{
              name: fieldName,
              label,
              options: filteredOptions,
              defaultValue,
              onChange: option => {
                setFieldValue(fieldName, option || []);
                if (onChange) {
                  onChange(name, option, setFieldValue);
                }
              },
              closeMenuOnSelect: closeMenuOnSelect || true,
              isDisabled,
              value: value || {},
              ...rest,
            }}
          />
        )}
      </Field>
      <ErrorMessage name={fieldName}>
        {msg => {
          return <Text color="error">{get(msg, 'value') || msg}</Text>;
        }}
      </ErrorMessage>
    </Box>
  );
}

export default FormSelect;
