import Constants from '../Constants';

export const CURRENT_AFFAIRS_BASE_URL = `${Constants.URL}current-affairs`;

export const CURRENT_AFFAIRS_FETCHED = 'CURRENT_AFFAIRS_FETCHED';
export const CURRENT_AFFAIRS_ERROR = 'CURRENT_AFFAIRS_ERROR';
export const COVID19_AUS_FETCHED = 'COVID19_AUS_FETCHED';
export const COVID19_AUS_ERROR = 'COVID19_AUS_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadCurrentAffairs(currentAffairs) {
  return {
    type: CURRENT_AFFAIRS_FETCHED,
    payload: currentAffairs,
  };
}

export function errorCurrentAffairs(error) {
  return {
    type: CURRENT_AFFAIRS_ERROR,
    payload: error,
  };
}

export function loadCovid19Aus(currentAffairs) {
  return {
    type: COVID19_AUS_FETCHED,
    payload: currentAffairs,
  };
}

export function errorCovid19Aus(error) {
  return {
    type: COVID19_AUS_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchCurrentAffairsDataSource(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${CURRENT_AFFAIRS_BASE_URL}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorCurrentAffairs))
      .then(json => dispatch(loadCurrentAffairs(json)))
      .catch(error => dispatch(errorCurrentAffairs(error)));
  };
}

export function fetchAusCOVID19Data(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${CURRENT_AFFAIRS_BASE_URL}/aus-covid19-details`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorCovid19Aus))
      .then(json => dispatch(loadCovid19Aus(json)))
      .catch(error => dispatch(errorCovid19Aus(error)));
  };
}
