import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Badge, Tag } from '@chakra-ui/react';
import { fetchOrderStatusMap, selectOrderStatusMap } from '../../../slices/order-status-map/orderStatusMapSlice';
import { useDispatch, useSelector } from 'react-redux';

const StatusTag = ({ status }) => {
  const dispatch = useDispatch();
  const orderStatus = useSelector(selectOrderStatusMap);

  useEffect(() => {
    if (!Object.keys(orderStatus).length) {
      dispatch(fetchOrderStatusMap());
    }
  }, [orderStatus]);

  const getStyling = () => {
    if (status === 'pending')
      return {
        colorScheme: 'blue'
      };

    if (status === 'cancelled')
      return {
        colorScheme: 'red'
      };

    if (['on_hold', 'transferred'].includes(status))
      return {
        colorScheme: 'yellow'
      };
    return {
      colorScheme: 'green'
    };
  };

  return (
    <Badge {...getStyling()}>{orderStatus[status] || ''}</Badge>
  );
};

StatusTag.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusTag;
