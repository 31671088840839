import { connect } from 'react-redux';
import CurrentAffairsComponent from '../../components/pages/CurrentAffairs/CurrentAffairs';

const mapStateToProps = state => {
  return {
    token: state.user.token,
    current_affairs: state.current_affairs,
    user: state.user,
    error: state.error,
  };
};
const CurrentAffairs = connect(mapStateToProps)(CurrentAffairsComponent);

export default CurrentAffairs;
