import PropTypes from 'prop-types';
import { cloneDeep, get } from 'lodash';
import React, { Component } from 'react';
import { today } from '../../../utils';
import DataTable from '../../basic/DataTable/DataTable';
import Loading from '../../basic/Loading';
import ReportParamsCard from './reportParamsCard';
import AuthGate from '../../../containers/auth/AuthGate';
import { Link } from 'react-router-dom';
import './selfServiceReportConfig.scss';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Image,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack,
  Wrap,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Center,
} from '@chakra-ui/react';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import HorizontalNavigationBand from '../../core/HorizontalNavigationBand';
import { ChevronRightIcon } from '@chakra-ui/icons';

export default class SelfServiceReportConfig extends Component {
  state = {
    reportTableData: null,
    loading: false,
    selfServiceReportConfig: {
      config: {},
    },
    errorMessage: null,
    reportId: null,
  };

  breadcrumbsConfig = [
    {
      url: '/self-service-reports',
      component: 'ReportsListComponent',
      onClick: () => {
        this.props.history.push('/self-service-reports');
      },
      title: 'Back to list',
      activeIndex: 0,
    },
    {
      url: '/self-service-report',
      component: 'SelfServiceReportComponent',
      title: 'Reports (Self Service)',
      activeIndex: 1,
    },
  ];

  componentDidMount() {
    const {
      user: { token },
      match: { params },
      fetchReportConfig,
    } = this.props;
    fetchReportConfig(token, params.reportId);
    const { reportId } = this.state;
    if (!reportId) {
      this.setState({ reportId: params.reportId });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      user: { token },
      match: { params },
      fetchReportConfig,
      selfServiceReportConfig,
    } = this.props;
    if (params.reportId && params.reportId !== prevProps.match.params.reportId) {
      fetchReportConfig(token, params.reportId);
    }
    if (selfServiceReportConfig.error && !prevState.errorMessage) {
      // Show message when run report fails with error.
      this.setState({
        errorMessage: selfServiceReportConfig.error.message,
      });
    } else if (!selfServiceReportConfig.error && prevState.errorMessage) {
      this.setState({
        errorMessage: null,
      });
    }
    if (
      selfServiceReportConfig.reportsData &&
      selfServiceReportConfig.reportsData !== prevProps.selfServiceReportConfig.reportsData
    ) {
      const reportData = cloneDeep(selfServiceReportConfig.reportsData);
      this.setState({
        reportTableData: reportData,
        loading: false,
        errorMessage: null,
      });
    }
    if (selfServiceReportConfig.config && selfServiceReportConfig.config !== prevProps.selfServiceReportConfig.config) {
      const queryParams = new URLSearchParams(window.location.search);
      const toAppendQueryParams = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of queryParams.entries()) {
        if (key in toAppendQueryParams) {
          toAppendQueryParams[key].push(value);
        } else {
          toAppendQueryParams[key] = [value];
        }
      }

      const selfServiceReportConfigCopy = cloneDeep(selfServiceReportConfig);
      const stateQueryParams = selfServiceReportConfigCopy.config.params;
      Object.keys(toAppendQueryParams).forEach(compName => {
        const validParam = stateQueryParams.find(q => q.name === compName);
        if (validParam) {
          validParam.default = `"${get(toAppendQueryParams, compName, '')}"`;
        }
      });
      this.setState({
        selfServiceReportConfig: selfServiceReportConfigCopy,
      });
    }
  }

  runReportHandler = paramValues => {
    const {
      user: { token },
      match: { params },
      runReport,
    } = this.props;
    const payload = paramValues
      ? Object.entries(paramValues).map(([key, val]) => ({
          name: key,
          value: JSON.stringify(val),
        }))
      : [];
    runReport(token, payload, params.reportId);
    this.setState({ loading: true, reportTableData: null });
  };

  onExit = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  deleteAndClose = () => {
    const {
      deleteReport,
      user: { token },
      history,
    } = this.props;

    deleteReport(token, this.state.reportId);
    this.onExit();
    history.push('/self-service-reports');
  };

  render() {
    const {
      selfServiceReportConfig: { config },
    } = this.state;
    const { loading, reportTableData, errorMessage, reportId, isDeleteModalOpen } = this.state;
    const selfServiceReportConfig = cloneDeep(config);
    const params = get(selfServiceReportConfig, 'params', '');

    return (
      <>
        <VStack spacing="56px" align="stretch" marginBottom="95px">
          <HorizontalNavigationBand justifyContent="flex-start" paddingX="52px">
            <Wrap spacing="20px" width="100%">
              <Link to="/self-service-reports" style={{ textDecoration: 'none', color: 'inherit' }}>
                <HStack height="100%" alignItems="center">
                  <Text as="p" fontWeight="700">
                    Back To List
                  </Text>
                  <ChevronRightIcon width="25px" height="25px" />
                </HStack>
              </Link>
              <Tabs index={0}>
                <TabList>
                  <Tab
                    _focus={{ outline: 'none' }}
                    _selected={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
                    _hover={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
                    fontWeight="normal"
                    width="162px"
                    fontSize="14px"
                    padding="0px"
                    height="103px"
                    marginRight="10px"
                  >
                    Reports (Self Service)
                  </Tab>
                </TabList>
              </Tabs>
            </Wrap>
          </HorizontalNavigationBand>
          <VStack align="stretch" paddingX="51px" spacing="37px">
            <DashboardSectionTile title="Reports">
              <HStack
                spacing="35px"
                boxShadow="md"
                bgColor="white"
                padding="10px 21px 10px 14px"
                borderRadius="6px"
                marginY="30px"
              >
                <VStack align="stretch">
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    bgColor="gray.200"
                    width="40px"
                    height="40px"
                    borderRadius="20px"
                  >
                    {selfServiceReportConfig && selfServiceReportConfig.category && (
                      <Image
                        src={`../../../img/self_service_reports_images/${selfServiceReportConfig.category.toLowerCase()}-small.svg`}
                        width="20px"
                        height="20px"
                      />
                    )}
                  </Flex>
                </VStack>
                <Flex width="100%" alignItems="center">
                  <VStack>
                    <Box mr="10px">
                      <Text as="p" fontSize="16px" fontWeight="semibold">
                        {selfServiceReportConfig.name}
                      </Text>
                      <Text as="p" fontWeight="semibold" color="gray">
                        {selfServiceReportConfig.description}
                      </Text>
                    </Box>
                  </VStack>
                  <Box ml="auto">
                    <AuthGate requiredPermissions={['write_self_service_report']}>
                      <ButtonGroup>
                        <Button
                          onClick={() => {
                            this.setState({ isDeleteModalOpen: true });
                          }}
                          colorScheme="actionPrimary"
                          variant="outline"
                          borderWidth="2px"
                          height="36px"
                          width="86px"
                        >
                          Remove
                        </Button>
                        <Link to={`/self-service-report/${reportId}`}>
                          <Button
                            type="default"
                            colorScheme="actionPrimary"
                            variant="solid"
                            marginLeft="15px"
                            height="36px"
                            width="86px"
                          >
                            Edit
                          </Button>
                        </Link>
                      </ButtonGroup>
                      <Modal isOpen={isDeleteModalOpen} onClose={this.onExit}>
                        <ModalOverlay />
                        <ModalContent minW="600px">
                          <ModalHeader>Remove Report</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <Alert status="error">
                              <AlertTitle>You are about to permananently remove a report!</AlertTitle>
                              <AlertDescription>Please check with tech team before doing so.</AlertDescription>
                            </Alert>
                            <ButtonGroup marginY="20px" width="100%" justifyContent="center">
                              <Button
                                colorScheme="red"
                                height="36px"
                                width="86px"
                                onClick={() => {
                                  this.deleteAndClose();
                                }}
                              >
                                Delete
                              </Button>
                              <Button
                                marginLeft="20px !important"
                                height="36px"
                                width="86px"
                                onClick={() => this.setState({ isDeleteModalOpen: false })}
                              >
                                Cancel
                              </Button>
                            </ButtonGroup>
                          </ModalBody>
                        </ModalContent>
                      </Modal>
                    </AuthGate>
                  </Box>
                </Flex>
              </HStack>
              <ReportParamsCard paramsConfigList={params} runReport={userInput => this.runReportHandler(userInput)} />
              {loading && !errorMessage && (
                <Center>
                  <Loading />
                </Center>
              )}
              {errorMessage && (
                <Alert status="warning" marginTop="5px">
                  <AlertIcon />
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Alert>
              )}
              {reportTableData &&
                (reportTableData.length ? (
                  <DataTable
                    data={reportTableData}
                    fileName={`${selfServiceReportConfig?.name.replace(/ /g, '_')}_${today()}`}
                  />
                ) : (
                  <Alert status="warning" marginTop="5px">
                    <AlertIcon />
                    <AlertDescription>No data found for provided filters</AlertDescription>
                  </Alert>
                ))}
            </DashboardSectionTile>
          </VStack>
        </VStack>
      </>
    );
  }
}

SelfServiceReportConfig.propTypes = {
  user: PropTypes.shape({ token: PropTypes.string }),
  deleteReport: PropTypes.func,
  fetchReportConfig: PropTypes.func,
  history: PropTypes.shape({
    action: PropTypes.string,
    block: PropTypes.func,
    createHref: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    push: PropTypes.func,
  }),
  match: PropTypes.shape({ params: PropTypes.shape({ reportId: PropTypes.string }) }),
  runReport: PropTypes.func,
  selfServiceReportConfig: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    config: PropTypes.shape({
      category: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      params: PropTypes.arrayOf(
        PropTypes.shape({
          default: PropTypes.string,
          id: PropTypes.number,
          label: PropTypes.string,
          name: PropTypes.string,
          options: PropTypes.string,
          param_type: PropTypes.string,
          report_id: PropTypes.number,
        })
      ),
      permissions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          report_id: PropTypes.number,
          role_id: PropTypes.number,
          user_id: PropTypes.number,
        })
      ),
    }),
    reportDeleted: PropTypes.string,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    reportsData: PropTypes.arrayOf(PropTypes.object),
  }),
};
