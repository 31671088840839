import { connect } from 'react-redux';
import PackerOfferForm from '../../components/packers/packer_offer_form/new/PackerOfferForm';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

const mapStateToProps = state => {
  return {
    ...findEntitiesInState(state, {
      input_products: 'input_product',
      packer_plants: 'packer_plant',
      packer: 'packers',
      offer_source: ['form_fields', 'offer_source'],
      price_types: ['form_fields', 'price_type'],
    }),
    user: state.user,
    token: state.user.token,
    added_packer_offers_count: state.packer_offers,
  };
};

const packerOfferForm = connect(mapStateToProps)(PackerOfferForm);

export default packerOfferForm;
