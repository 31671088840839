import { USER_LOGGED_IN, USER_LOGGED_OUT } from '../actions/actions_auth';
import { USER_UPDATE } from '../actions/actions_users';

const user = (state = false, action) => {
  switch (action.type) {
    case USER_LOGGED_IN:
    case USER_UPDATE:
      return action.payload;
    case USER_LOGGED_OUT:
      return null;
    default:
      return state;
  }
};

export const selectCurrentUser = state => {
  return state.user;
};

export default user;
