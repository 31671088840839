import React, { Component } from 'react';

import Constants from '../../../Constants';
import ReleaseTemplate from './ReleaseTemplate';
import moment from 'moment';

class Released extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const coldstore_status = Constants.ORDER_STATUSES.COLDSTORE_RELEASED;
    return (
      <ReleaseTemplate
        coldstore_status={coldstore_status}
        // only with current week or future release are shown on released tab
        orders={this.props.orders}
        grinders={this.props.grinders}
        transporters={this.props.transporters}
        handleNavigation={this.props.handleNavigation}
        previewOrders={this.props.previewOrders}
        dispatch={this.props.dispatch}
        token={this.props.user.token}
        user={this.props.user}
        notifying_action_groups={this.props.notifying_action_groups}
        coldstoreSendEmailError={this.props.coldstoreSendEmailError}
        isColdstoreEmailSent={this.props.isColdstoreEmailSent}
        headerMessage="Released loads"
        noDataHeaderMessage="No releases scheduled for the current week or in the future"
        internalPoLabel={this.props.internalPoLabel}
        title="Released Loads"
      />
    );
  }
}

export default Released;
