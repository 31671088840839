import Constants from '../Constants';

export const getEmailTemplate = async (templateKey, data) => {
  try {
    const accessToken = localStorage.getItem('boabUser');
    if (!accessToken) {
      return '';
    }
    const json = JSON.parse(accessToken);
    const { token } = json;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    const response = await fetch(`${Constants.URL}template_by_key/${templateKey}`, {
      method: 'POST',
      body: JSON.stringify(data || {}),
      headers: header,
    });
    return response.json();
  } catch (error) {
    return '';
  }
};
