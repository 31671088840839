import React from 'react';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  RadioGroup,
  Radio,
  FormLabel,
  Tfoot,
  Checkbox,
  Alert,
  AlertIcon,
  AlertDescription,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Textarea,
  StackDivider,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {
  AddValueFields,
  EditButton,
  FieldGroup,
  InputField,
  PerUnitTdField,
  PreambleText,
  TfootTh,
  VStackLayout,
} from '../Components/index';
import { ReactComponent as WarningOutlineIconSVG } from '../../../assets/warning-outline.svg';
import './ClaimListForm.scss';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';
import { getOr } from '../../../helpers';
import moment from 'moment';
import SelectField from '../../basic/SelectField';
import { Link } from 'react-router-dom';
import { checkIfIsAdminUser, checkIfIsGrinderUser, checkIfIsPackerUser } from '../../../utils';
import { formatMonetaryValue } from '../../../functions';
import Constants from '../../../Constants';

const ClaimListForm = ({
  isDisabled,
  isReviewing,
  onUpdate,
  claimEntities,
  user,
  ncrs: { currentNCR },
  editDisabled,
  form,
}) => {
  const { values: formikValues, errors } = useFormikContext();
  const isEditing = get(formikValues, 'isEditing');
  const editingExistingNCR = get(formikValues, 'editingExistingNCR');
  const priority = get(currentNCR, 'claim.priority');
  const status = get(currentNCR, 'status');

  const isPackerUser = checkIfIsPackerUser(user);
  const isAdminUser = checkIfIsAdminUser(user);
  const isGrinderUser = checkIfIsGrinderUser(user);

  const adminReviewOptions = Constants.NCR_ADMIN_REVIEW_OPTIONS;

  const adminAcceptanceOptions = Constants.NCR_ADMIN_ACCEPTANCE_OPTIONS;

  const packerAcceptanceOptions = Constants.NCR_PACKER_ACCEPTANCE_OPTIONS;
  const closureOptions = Constants.NCR_ADMIN_GRINDER_CLOSURE_OPTIONS;

  const reviewOptions = isPackerUser ? packerAcceptanceOptions : adminReviewOptions;

  const acceptanceOptions = isPackerUser ? packerAcceptanceOptions : adminAcceptanceOptions;

  const showOtherField = false;
  const claimAcceptanceKey = isPackerUser ? 'packerAcceptance' : 'adminAcceptance';

  const adminReview = adminReviewOptions.find(
    reviewOptions => reviewOptions.value === get(currentNCR, 'claim.adminAcceptance.value', null)
  );

  const packerAcceptance = packerAcceptanceOptions.find(
    acceptanceOption => acceptanceOption.value === get(currentNCR, 'claim.packerAcceptance.value', null)
  );
  const packerAcceptanceDate = get(currentNCR, 'claim.packerAcceptance.modified', null);

  const adminAcceptanceDate = get(currentNCR, 'claim.adminAcceptance.modified', null);
  const adminAcceptance = adminAcceptanceOptions.find(
    acceptanceOption => acceptanceOption.value === get(currentNCR, 'claim.adminAcceptance.value', null)
  );

  const closedDate = get(currentNCR, 'closedDate', null);
  const closedByValue = closureOptions.find(
    closureOption => closureOption.value === get(currentNCR, 'closedByValue', null)
  );

  const allowFinalValueEdit = !isDisabled && isEditing && isAdminUser && editingExistingNCR;

  const ncrCurrencyCode = get(formikValues, 'ncrDetails.purchaseOrderLine.purchaseOrder.sellCurrency');

  const calculateInitialValueTotal = () =>
    claimEntities.reduce((acc, curr) => {
      const { id } = curr;
      let claimValue = get(formikValues, `claims.claim${id}.value`, 0);
      claimValue = isNaN(claimValue) ? 0 : claimValue;
      let unitValue = getOr(formikValues, `claims.claim${id}.grinderPricePerUnit`, 0);
      unitValue = isNaN(unitValue) ? 0 : unitValue;
      const measureId = get(formikValues, `claims.claim${id}.unitOfMeasureId`); // If weight then 1 and if carton then 2.
      const cartonValue = ['lbs', 'pound'].includes(formikValues?.ncrDetails?.unitOfMeasure?.name?.toLowerCase())
        ? 59.96
        : 27.2;
      return acc + (measureId && +measureId === 2 ? unitValue * cartonValue * claimValue : unitValue * claimValue);
    }, 0);

  const calculateFinalValueTotal = () =>
    claimEntities.reduce((acc, curr) => {
      const { id } = curr;
      let claimValue = get(formikValues, `claims.claim${id}.value`, 0);
      claimValue = isNaN(claimValue) ? 0 : claimValue;
      let unitValue = getOr(formikValues, `claims.claim${id}.approvedPerUnit`, 0);
      unitValue = isNaN(unitValue) ? 0 : unitValue;
      const measureId = get(formikValues, `claims.claim${id}.unitOfMeasureId`); // If weight then 1 and if carton then 2.
      const cartonValue = ['lbs', 'pound', 'pounds'].includes(formikValues?.ncrDetails?.unitOfMeasure?.name?.toLowerCase())
        ? 59.96
        : 27.2;
      return acc + (measureId && +measureId === 2 ? unitValue * cartonValue * claimValue : unitValue * claimValue);
    }, 0);

  const purcherOrderLine = () => {
    const purchadeOrderLineId = get(formikValues, 'purchaseOrderLineId', 0);
    const lines = get(form, 'purchaseOrder.lines', []);
    const line = lines.find(line => line.id === purchadeOrderLineId);
    if (line?.sellPricePerUnit) {
      return line.sellPricePerUnit;
    } else {
      return null;
    }
  };

  const claimValueComments = getOr(currentNCR, 'claim.packerAcceptance.comments', '');
  const fullHostname = window.location.hostname;

  return (
    <DashboardSectionTile title="Claim Value" padding="22px 33px 22px 20px" className="claim-list-form">
      <VStackLayout paddingBottom="42px" align="stretch" spacing="24px">
        <Box>
          <TableContainer marginBottom="24px">
            <Table variant="simple">
              <Thead>
                <Tr>
                  {[
                    {
                      key: 'ncr_breakdown',
                      label: 'ncr breakdown',
                    },
                    {
                      key: 'measure',
                      label: 'measure',
                    },
                    {
                      key: 'grinder_initial_value',
                      label: 'grinder initial value',
                    },
                    {
                      key: 'approved_per_unit',
                      label: 'final value',
                    },
                  ].map(col => (
                    <Th key={col.key} fontSize="12px">
                      {col.label}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody backgroundColor="#ffffff" fontSize="14px">
                {claimEntities.map(claimEntity => {
                  const { id, isRequired, name, type } = claimEntity;

                  // Determine the correct label for the claim entity
                  const displayName =
                    name === 'Other (Please specify) *FMG Approval Required' && fullHostname.includes('agilechain')
                      ? 'Other (Please specify) *CFC Approval Required'
                      : name;

                  const rowHasError = formikValues.highlightInputError && !isEmpty(get(errors, `claims.claim${id}`));
                  const claimValue = get(formikValues, `claims.claim${id}.value`);
                  let grinderPricePerUnit = get(formikValues, `claims.claim${id}.grinderPricePerUnit`, null);
                  let sellPricePerUnit = purcherOrderLine();

                  if (
                    grinderPricePerUnit === null &&
                    typeof get(formikValues, `claims.claim${id}.unitOfMeasureId`, null) !== 'object'
                  ) {
                    grinderPricePerUnit = sellPricePerUnit;
                  }

                  return (
                    <Tr key={id} fontWeight="400" className={rowHasError && 'error-row'}>
                      <Td>
                        {displayName} {/* Use the modified displayName here */}
                        {isRequired ? '*' : ''}
                        {rowHasError ? (
                          <Popover placement="right">
                            <PopoverTrigger>
                              <Flex alignItems="center" color="#C62704" marginTop="8px" width="fit-content">
                                <WarningOutlineIconSVG />
                                &nbsp; Missing Information
                              </Flex>
                            </PopoverTrigger>
                            <PopoverContent
                              width="296px"
                              _focus={{
                                outline: 'none',
                              }}
                              boxShadow="0px 1.25px 4px rgba(0, 0, 0, 0.25)"
                            >
                              <PopoverArrow />
                              <PopoverCloseButton top="16px" right="14px" />
                              <PopoverBody padding="16px 46px 16px 19px">
                                <Flex>
                                  <WarningOutlineIconSVG />
                                  <Text marginLeft="8px" fontSize="16px" width="200px" whiteSpace="break-spaces">
                                    <b>{Object.values(getOr(errors, `claims.claim${id}`, {}))[0]}</b> is missing. Must
                                    be completed.
                                  </Text>
                                </Flex>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        ) : (
                          ''
                        )}
                      </Td>
                      <Td id={`claim${id}`} paddingTop={type === 'other' ? null : '28px'}>
                        {type === 'quantity' ? (
                          <Box>
                            <RadioGroup
                              value={parseInt(get(formikValues, `claims.claim${id}.unitOfMeasureId`, null), 10)}
                              colorScheme="actionPrimary"
                              marginBottom="7px"
                              onChange={value => {
                                onUpdate(`claims.claim${id}.unitOfMeasureId`, value);
                              }}
                            >
                              <HStack spacing="8px">
                                <Radio value={1} marginBottom={0} isDisabled={isDisabled || !isEditing || isPackerUser}>
                                  Weight
                                </Radio>
                                <Radio value={2} marginBottom={0} isDisabled={isDisabled || !isEditing || isPackerUser}>
                                  Cartons
                                </Radio>
                              </HStack>
                            </RadioGroup>
                            <InputField
                              isDisabled={isDisabled || !isEditing || isPackerUser}
                              value={claimValue}
                              onChange={e => {
                                onUpdate(`claims.claim${id}.value`, e.target.value);
                                onUpdate(`claims.claim${id}.grinderPricePerUnit`, grinderPricePerUnit);
                              }}
                              type="number"
                              min={0}
                            />
                          </Box>
                        ) : (
                          ''
                        )}
                        {type === 'time' ? (
                          <>
                            <FormLabel>Time (hours)</FormLabel>
                            <InputField
                              type="number"
                              isDisabled={isDisabled || !isEditing || isPackerUser}
                              value={claimValue}
                              onChange={e => onUpdate(`claims.claim${id}.value`, e.target.value)}
                            />
                          </>
                        ) : (
                          ''
                        )}
                        {type === 'other' ? (
                          <AddValueFields
                            isDisabled={isDisabled || !isEditing || isPackerUser}
                            labelValue={get(formikValues, `claims.claim${id}.label`)}
                            onClaimLabelChange={e => {
                              onUpdate(`claims.claim${id}.label`, e.target.value);
                            }}
                            claimValue={claimValue}
                            onClaimValueChange={e => {
                              onUpdate(`claims.claim${id}.value`, e.target.value);
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </Td>
                      <PerUnitTdField
                        isDisabled={isDisabled || !isEditing || isPackerUser}
                        value={grinderPricePerUnit}
                        onChange={e => {
                          onUpdate(`claims.claim${id}.grinderPricePerUnit`, e.target.value);
                        }}
                      />
                      <PerUnitTdField
                        isDisabled={!allowFinalValueEdit}
                        value={get(formikValues, `claims.claim${id}.approvedPerUnit`, null)}
                        onChange={e => {
                          onUpdate(`claims.claim${id}.approvedPerUnit`, e.target.value);
                        }}
                      />
                    </Tr>
                  );
                })}
                {showOtherField ? (
                  <>
                    <Tr fontWeight="400">
                      <Td>Destruction Costs</Td>
                      <Td>
                        <AddValueFields isDisabled={isDisabled} />
                      </Td>
                      <PerUnitTdField isDisabled={isDisabled} />
                      <PerUnitTdField isDisabled={isDisabled} />
                    </Tr>
                    <Tr fontWeight="400">
                      <Td>Transport Costs</Td>
                      <Td>
                        <AddValueFields isDisabled={isDisabled} />
                      </Td>
                      <PerUnitTdField isDisabled={isDisabled} />
                      <PerUnitTdField isDisabled={isDisabled} />
                    </Tr>
                    <Tr fontWeight="400">
                      <Td>Costs Covered</Td>
                      <Td>
                        <AddValueFields isDisabled={isDisabled} />
                      </Td>
                      <PerUnitTdField isDisabled={isDisabled} />
                      <PerUnitTdField isDisabled={isDisabled} />
                    </Tr>
                  </>
                ) : (
                  ''
                )}
              </Tbody>

              <Tfoot>
                <Tr>
                  <TfootTh>Total</TfootTh>
                  <TfootTh />
                  <TfootTh>
                    {formatMonetaryValue(ncrCurrencyCode, calculateInitialValueTotal(), {
                      notation: 'standard',
                    }) || '-'}
                  </TfootTh>
                  <TfootTh>
                    {formatMonetaryValue(ncrCurrencyCode, calculateFinalValueTotal(), {
                      notation: 'standard',
                    }) || '-'}
                  </TfootTh>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
          {!isReviewing && isGrinderUser ? (
            <Box>
              <Checkbox
                colorScheme="actionPrimary"
                isChecked={get(formikValues, 'claim.isUnderReview', null)}
                onChange={e => onUpdate('claim.isUnderReview', e.target.checked)}
                isDisabled={
                  isDisabled || (get(formikValues, 'recordNo') != undefined && status != Constants.NCR_STATUES.RAISED)
                }
              >
                Claim Value Data under Review by Grinder
              </Checkbox>
              {get(formikValues, 'claim.isUnderReview') ? (
                <Alert
                  status="info"
                  marginTop="19px"
                  variant="left-accent"
                  borderRadius="6px"
                  fontWeight="400"
                  fontSize="16px"
                  paddingY="16px"
                  width="fit-content"
                  paddingRight="50px"
                >
                  <AlertIcon />
                  <AlertDescription>
                    <b>Important:</b> If you want to complete other information please do it before&nbsp;
                    <b>{moment().add(7, 'days').format('DD-MM-YYYY')}</b>
                  </AlertDescription>
                </Alert>
              ) : (
                ''
              )}
            </Box>
          ) : (
            ''
          )}
        </Box>
        {isReviewing ? (
          <HStack spacing="31px" alignItems="flex-start">
            <Box>
              {priority === 'serious' ? (
                <Box marginBottom="38px">
                  <PreambleText fontSize="16px">
                    Claim Value Review by {fullHostname.includes('agilechain') ? 'CFC' : 'FMG'}
                  </PreambleText>

                  {adminReview ? (
                    <Text>
                      <b>{get(adminReview, 'label', null)}</b>
                      &nbsp;by&nbsp;
                      {get(currentNCR, 'claim.adminAcceptance.user')
                        ? `${get(currentNCR, 'claim.adminAcceptance.user.firstName')} ${get(
                            currentNCR,
                            'claim.adminAcceptance.user.lastName'
                          )}`
                        : '-'}
                      &nbsp;on&nbsp;
                      {moment(adminAcceptanceDate).isValid() ? moment(adminAcceptanceDate).format('DD-MMM-YYYY') : '-'}
                    </Text>
                  ) : (
                    '-'
                  )}
                </Box>
              ) : (
                ''
              )}
              <Box>
                <PreambleText fontSize="16px">NCR Acceptance including Claim Value by Packer</PreambleText>
                {packerAcceptance ? (
                  <Text>
                    <b>{get(packerAcceptance, 'label', null)}</b>
                    &nbsp;by&nbsp;
                    {get(currentNCR, 'claim.packerAcceptance.user')
                      ? `${get(currentNCR, 'claim.packerAcceptance.user.firstName')} ${get(
                          currentNCR,
                          'claim.packerAcceptance.user.lastName'
                        )}`
                      : '-'}
                    &nbsp;on&nbsp;
                    {moment(packerAcceptanceDate).isValid() ? moment(packerAcceptanceDate).format('DD-MMM-YYYY') : '-'}
                  </Text>
                ) : (
                  '-'
                )}
              </Box>
            </Box>
            <StackDivider borderWidth="1px" />
            {priority === 'major' ? (
              <Box>
                <PreambleText fontSize="16px">
                  NCR Closed by {fullHostname.includes('agilechain') ? 'CFC' : 'FMG'} or Grinder
                </PreambleText>

                {closedByValue ? (
                  <Text>
                    <b>Closed</b>
                    &nbsp;by&nbsp;
                    {get(currentNCR, 'closedByUser')
                      ? `${get(currentNCR, 'closedByUser.firstName')} ${get(currentNCR, 'closedByUser.lastName')}`
                      : '-'}
                    &nbsp;on&nbsp;
                    {moment(closedDate).isValid() ? moment(closedDate).format('DD-MMM-YYYY') : '-'}
                  </Text>
                ) : (
                  '-'
                )}
              </Box>
            ) : (
              ''
            )}
            {priority === 'serious' ? (
              <Box>
                <PreambleText fontSize="16px">
                  Final NCR Closed by {fullHostname.includes('agilechain') ? 'CFC' : 'FMG'}
                </PreambleText>

                {closedByValue ? (
                  <Text>
                    <b>Closed</b>
                    &nbsp;by&nbsp;
                    {get(currentNCR, 'closedByUser')
                      ? `${get(currentNCR, 'closedByUser.firstName')} ${get(currentNCR, 'closedByUser.lastName')}`
                      : '-'}
                    &nbsp;on&nbsp;
                    {moment(closedDate).isValid() ? moment(closedDate).format('DD-MMM-YYYY') : '-'}
                  </Text>
                ) : (
                  '-'
                )}
              </Box>
            ) : (
              ''
            )}
            {isReviewing && (isPackerUser || (priority === 'serious' && isAdminUser) || priority === 'major') ? (
              <Box marginLeft="auto !important">
                <Link to={`/edit-ncr/${get(currentNCR, 'recordNo')}?startEditTabKey=claim_value`}>
                  <EditButton isDisabled={editDisabled} />
                </Link>
              </Box>
            ) : (
              ''
            )}
          </HStack>
        ) : (
          ''
        )}
        {isReviewing && (isAdminUser || isPackerUser) && claimValueComments ? (
          <Box>
            <PreambleText fontSize="16px">Comments:</PreambleText>
            <>
              <Text>
                <b>
                  {get(currentNCR, 'claim.packerAcceptance.user')
                    ? `${get(currentNCR, 'claim.packerAcceptance.user.firstName')} ${get(
                        currentNCR,
                        'claim.packerAcceptance.user.lastName'
                      )}`
                    : '-'}
                </b>
                &nbsp;&nbsp;
                <Box as="span" opacity="0.5">
                  {moment(packerAcceptanceDate).isValid()
                    ? moment(packerAcceptanceDate).format('MMMM D, YYYY, hh:mm A')
                    : ''}
                </Box>
              </Text>
              <Text>{getOr(currentNCR, 'claim.packerAcceptance.comments', '--')}</Text>
            </>
          </Box>
        ) : (
          ''
        )}
        {!isReviewing && editingExistingNCR ? (
          <Box>
            <HStack spacing="70px">
              {isPackerUser || (isAdminUser && priority === 'serious') ? (
                <FieldGroup
                  label={
                    isPackerUser
                      ? 'NCR Acceptance including Claim Value by Packer'
                      : `Claim Value Review by ${fullHostname.includes('agilechain') ? 'CFC' : 'FMG'}`
                  }
                  labelProps={{
                    width: 'max-content',
                  }}
                >
                  <SelectField
                    placeholder="Select"
                    name="claimAcceptance"
                    value={reviewOptions.filter(
                      reviewOption =>
                        getOr(
                          formikValues,
                          `claim.${claimAcceptanceKey}.value.value`,
                          getOr(formikValues, `claim.${claimAcceptanceKey}.value`, null)
                        ) === reviewOption.value
                    )}
                    className={
                      formikValues.highlightInputError && get(errors, `claim.${claimAcceptanceKey}`) && 'error'
                    }
                    onChange={e => onUpdate(`claim.${claimAcceptanceKey}.value`, e)}
                    options={reviewOptions}
                  />
                </FieldGroup>
              ) : (
                ''
              )}
              {(isAdminUser || isGrinderUser) && priority === 'major' ? (
                <FieldGroup label={`NCR Closed by ${fullHostname.includes('agilechain') ? 'CFC' : 'FMG'} or Grinder`}>
                  <SelectField
                    placeholder="Select"
                    name="claimAcceptance"
                    value={closureOptions.filter(
                      closureOption =>
                        getOr(formikValues, 'closedByValue.value', getOr(formikValues, 'closedByValue', null)) ===
                        closureOption.value
                    )}
                    onChange={e => onUpdate('closedByValue', e)}
                    options={closureOptions}
                    isDisabled={!packerAcceptance}
                  />
                </FieldGroup>
              ) : (
                ''
              )}
              {isAdminUser && priority === 'serious' ? (
                <FieldGroup label={`Final NCR Closed by ${fullHostname.includes('agilechain') ? 'CFC' : 'FMG'}`}>
                  <SelectField
                    placeholder="Select"
                    name="closureAcceptance"
                    value={acceptanceOptions.filter(
                      acceptanceOption =>
                        getOr(formikValues, 'closedByValue.value', getOr(formikValues, 'closedByValue', null)) ===
                        acceptanceOption.value
                    )}
                    className={formikValues.highlightInputError && get(errors, 'closedByValue') && 'error'}
                    onChange={e => onUpdate('closedByValue', e)}
                    options={acceptanceOptions}
                    isDisabled={!packerAcceptance}
                  />
                </FieldGroup>
              ) : (
                ''
              )}
            </HStack>
            {isPackerUser ? (
              <Box marginTop="20px">
                <FormLabel>Comments:</FormLabel>
                <Textarea
                  rows={6}
                  placeholder="Comments"
                  name="comments"
                  value={get(formikValues, `claim.${claimAcceptanceKey}.comments`, null)}
                  className={
                    formikValues.highlightInputError && get(errors, `claim.${claimAcceptanceKey}.comments`) && 'error'
                  }
                  onChange={e => onUpdate(`claim.${claimAcceptanceKey}.comments`, e.target.value)}
                />
              </Box>
            ) : (
              ''
            )}
          </Box>
        ) : (
          ''
        )}
      </VStackLayout>
    </DashboardSectionTile>
  );
};

ClaimListForm.propTypes = {
  isDisabled: PropTypes.bool,
  isReviewing: PropTypes.bool,
  onUpdate: PropTypes.func,
  claimEntities: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
  ncrs: PropTypes.objectOf(PropTypes.any),
  editDisabled: PropTypes.bool,
};

export default ClaimListForm;
