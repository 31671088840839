import { connect } from 'react-redux';

import GrinderKPIDashboardComponent from '../../components/dashboard/GrinderKPIDashboard';

const mapStateToProps = state => {
  return {
    user: state.user,
    configs: state.configs,
    token: state.user.token,
  };
};

const GrinderKPIDashboard = connect(mapStateToProps)(GrinderKPIDashboardComponent);

export default GrinderKPIDashboard;
