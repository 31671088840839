import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FilterCheckboxGroupGeneric from '../../../../components/analyticDashboard/qualityDetails/shared/FilterCheckboxGroupGeneric/FilterCheckboxGroupGeneric';
import FilterMultiSelect from '../../../../components/analyticDashboard/qualityDetails/shared/FilterMultiSelect/FilterMultiSelect';
import FilterRadioGeneric from '../../../../components/analyticDashboard/qualityDetails/shared/FilterRadioGeneric/FilterRadioGeneric';
import FilterSelectGeneric from '../../../../components/analyticDashboard/qualityDetails/shared/FilterSelectGeneric/FilterSelectGeneric';
import {
  BREAKDOWN_OPTIONS,
  INCIDENTS_OPTIONS,
  PACKER_MODE,
  TIME_RANGE_OPTIONS,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsConfig';
import {
  setQualityNCRsActivePackerPlants,
  setQualityNCRsBreakdownMode,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsReducers';
import {
  selectQualityNCRsActivePackerPlants,
  selectQualityNCRsBreakdownMode,
  selectQualityNCRsClaimDollars,
  selectQualityNCRsIncidents,
  selectQualityNCRsTimeRange,
  selectQualityNCRsVolume,
} from '../../../../slices/quality-details-ncrs/selectors/selectBasicDetailsNcrsData';
import selectQualityNCRsBottomTenPackerPlants from '../../../../slices/quality-details-ncrs/selectors/selectQualityNCRsBottomTenPackerPlants';
import {
  dataColorsPropTypes,
  setDataColorsPropTypes,
  availableYearsPropTypes,
  selectedYearsPropTypes,
  setSelectedYearsPropTypes,
  availableCategoriesPropTypes,
  selectedCategoriesPropTypes,
  setSelectedCategoriesPropTypes,
  availableCategoriesWithSubcategoriesPropTypes,
  selectedSubcategoriesPropTypes,
  setSelectedSubcategoriesPropTypes,
  availableMarketsPropTypes,
  selectedMarketsPropTypes,
  setSelectedMarketsPropTypes,
  availablePackerPlantsPropTypes,
  packerModePropTypes,
  setPackerModePropTypes,
  breakdownPackerPlantPropTypes,
  setBreakdownPackerPlantPropTypes,
  highlightedPackerPlantsPropTypes,
  setHighlightedPackerPlantsPropTypes,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsPropTypes';
import styles from './QualityDetailsNCRs.module.css';
import { CUSTOM_FILTERS } from '../../../../slices/quality/qualityConfig';

const MAX_NUM_OF_SELECTED_PACKER_PLANTS = 5;
const DEFAULT_NUM_OF_YEARS_SELECTED = 3;
export const MAX_NUM_OF_ITEMS_CHECKED = 10;

function getColors(selectedFields, availableFields, isMarkets) {
  const colors = [
    '#09AFA3', // teal
    '#FF1577', // magenta
    '#5039B5', // purple
    '#FED000', // yellow
    '#00B5F2', // blue
    '#FC5D36', // orange
    '#4D536A', // dark grey
    '#AB2424', // maroon
    '#FEC5B8', // peach
    '#DCC48E', // golden
  ];
  const colorsObject = {};

  if (isMarkets) {
    const colorsForMarkets = [
      '#AB2424', // maroon
      '#FEC5B8', // peach
    ];
    availableFields.forEach((x, i) => {
      colorsObject[x] = colorsForMarkets[i % colorsForMarkets.length];
    });
    return colorsObject;
  }

  if (availableFields && availableFields.length <= MAX_NUM_OF_ITEMS_CHECKED) {
    availableFields.forEach((x, i) => {
      colorsObject[x] = colors[i % colors.length];
    });
  } else {
    selectedFields.forEach((x, i) => {
      colorsObject[x] = colors[i % colors.length];
    });
  }

  return colorsObject;
}

function addBottomTenToTheListOfPackerPlants(packerPlants, bottomTenPackerPlants) {
  const bottomTen = {
    id: 'bottom_10',
    name: 'Bottom 10',
    includesPackerPlants: bottomTenPackerPlants,
    bottomTenPackerPlants,
  };
  return [bottomTen].concat(packerPlants);
}

function removeNumberOfPackerPlantsFromName(name) {
  const ind = name.indexOf('(');
  return name.slice(0, ind);
}

function Paragraph(props) {
  const { options } = props;
  return <div style={{ marginTop: 20, marginBottom: 10 }}>{options[0]}</div>;
}

Paragraph.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
};

const CONTROL_TYPE = {
  CHECKBOX_GROUP: FilterCheckboxGroupGeneric,
  RADIO_GROUP: FilterRadioGeneric,
  SELECT: FilterSelectGeneric,
  MULTI_SELECT: FilterMultiSelect,
  PARAGRAPH: Paragraph,
};

export default function QualityDetailsNCRsSidebar(props) {
  const {
    dataColors,
    setDataColors,
    availableYears,
    selectedYears,
    setSelectedYears,
    availableCategories,
    selectedCategories,
    setSelectedCategories,
    availableCategoriesWithSubcategories,
    selectedSubcategories,
    setSelectedSubcategories,
    availableMarkets,
    selectedMarkets,
    setSelectedMarkets,
    availablePackerPlants,
    packerMode,
    setPackerMode,
    breakdownPackerPlant,
    setBreakdownPackerPlant,
    highlightedPackerPlants,
    setHighlightedPackerPlants,
  } = props;

  const incidentsMode = useSelector(selectQualityNCRsIncidents);
  const volumeMode = useSelector(selectQualityNCRsVolume);
  const claimDollarsMode = useSelector(selectQualityNCRsClaimDollars);
  const breakdownMode = useSelector(selectQualityNCRsBreakdownMode);
  const activePackerPlants = useSelector(selectQualityNCRsActivePackerPlants);
  const timeRange = useSelector(selectQualityNCRsTimeRange);
  const bottomTenPackerPlants = useSelector(selectQualityNCRsBottomTenPackerPlants);

  const dispatch = useDispatch();

  const extraControlText = () => {
    if (breakdownPackerPlant?.includesPackerPlants) {
      if (breakdownPackerPlant.includesPackerPlants.length === 10) {
        return 'Establishments with the highest total NCR incident count for the selected time range.';
      }
      if (breakdownPackerPlant.includesPackerPlants.length > 10) {
        return `10 ${removeNumberOfPackerPlantsFromName(
          breakdownPackerPlant.name
        )} members with the highest total NCR incident count for the selected time range.`;
      }
      return `All ${removeNumberOfPackerPlantsFromName(breakdownPackerPlant.name)} members`;
    }
    return '';
  };

  const extraComponentsTypesText = () => {
    if (activePackerPlants[0]?.includesPackerPlants) {
      if (activePackerPlants[0].includesPackerPlants.length > 10) {
        return `10 ${removeNumberOfPackerPlantsFromName(
          activePackerPlants[0].name
        )} members with the highest total NCR incident count for the selected time range.`;
      }
      return `All ${removeNumberOfPackerPlantsFromName(activePackerPlants[0].name)} members`;
    }
    return '';
  };

  const extraComponentsTypes = {
    packerSelect: isAggregate => ({
      label: '',
      control: CONTROL_TYPE.SELECT,
      options: [PACKER_MODE.TOTAL, isAggregate ? PACKER_MODE.BOTTOM_10_TOTAL : PACKER_MODE.BOTTOM_10],
      value: packerMode,
      setValue: setPackerMode,
      isDisabled:
        activePackerPlants[0]?.includesPackerPlants && activePackerPlants[0]?.includesPackerPlants.length <= 10,
    }),
    paragraph: text => ({
      label: '',
      control: CONTROL_TYPE.PARAGRAPH,
      options: [text],
    }),
  };

  const componentsTypes = {
    years: () => ({
      label: '',
      control: CONTROL_TYPE.CHECKBOX_GROUP,
      options: availableYears,
      value: selectedYears,
      setValue: setSelectedYears,
    }),
    categoryCheckbox: () => ({
      label: 'Categories',
      control: CONTROL_TYPE.CHECKBOX_GROUP,
      options: availableCategories,
      value: selectedCategories,
      setValue: x => setSelectedCategories(x.sort()),
    }),
    categoryRadio: (resetOnClick, withColors) => ({
      label: 'Categories',
      control: CONTROL_TYPE.RADIO_GROUP,
      options: availableCategories,
      value:
        selectedCategories && selectedCategories.length === availableCategories.length
          ? undefined
          : selectedCategories[0],
      setValue: x => setSelectedCategories(x.sort()),
      resetOnClick,
      withColors,
    }),
    categorySelect: () => ({
      label: 'Category',
      control: CONTROL_TYPE.SELECT,
      options: availableCategoriesWithSubcategories.map(x => x.category),
      value: selectedCategories[0],
      setValue: val => {
        setSelectedCategories([val]);
        setSelectedSubcategories(
          availableCategoriesWithSubcategories
            .find(x => x.category === val)
            ?.subCategories.slice(0, MAX_NUM_OF_ITEMS_CHECKED)
        );
      },
    }),
    subcategoryCheckbox: () => ({
      label: 'Subcategories',
      control: CONTROL_TYPE.CHECKBOX_GROUP,
      options:
        (availableCategoriesWithSubcategories || []).find(x => x.category === selectedCategories[0])?.subCategories ||
        [],
      value: selectedSubcategories,
      setValue: x => setSelectedSubcategories(x.sort()),
    }),
    subcategoryRadio: () => ({
      label: 'Subcategories',
      control: CONTROL_TYPE.RADIO_GROUP,
      options:
        (availableCategoriesWithSubcategories || []).find(x => x.category === selectedCategories[0])?.subCategories ||
        [],
      value: selectedSubcategories[0],
      setValue: x => setSelectedSubcategories(x.sort()),
    }),
    filterByPackerPlant: (maxNumOfValues, withColors) => ({
      label: 'Filter by establishment',
      control: CONTROL_TYPE.MULTI_SELECT,
      options: availablePackerPlants,
      value: activePackerPlants,
      setValue: x => {
        let packer;
        for (let i = 0; i < x.length; i += 1) {
          if (x[i]?.includesPackerPlants) {
            packer = x[i];
            break;
          }
        }
        if (packer) {
          return dispatch(setQualityNCRsActivePackerPlants([packer]));
        }
        return dispatch(setQualityNCRsActivePackerPlants(x));
      },
      maxNumOfValues,
      extraControl:
        activePackerPlants[0] && activePackerPlants[0].includesPackerPlants
          ? extraComponentsTypes.packerSelect(
              timeRange === TIME_RANGE_OPTIONS.YOY ||
                incidentsMode === INCIDENTS_OPTIONS.TOTAL_WITH_BREAKDOWN ||
                incidentsMode === INCIDENTS_OPTIONS.BY_MARKET ||
                !(!volumeMode && !claimDollarsMode)
            )
          : undefined,
      indicator: 'includesEstablishments',
      withColors,
    }),
    marketCheckbox: () => ({
      label: 'Markets',
      control: CONTROL_TYPE.CHECKBOX_GROUP,
      options: availableMarkets,
      value: selectedMarkets,
      setValue: x => setSelectedMarkets(x.sort()),
    }),
    marketRadio: (resetOnClick, withColors) => ({
      label: 'Markets',
      control: CONTROL_TYPE.RADIO_GROUP,
      options: availableMarkets,
      value: selectedMarkets.length === availableMarkets.length ? undefined : selectedMarkets[0],
      setValue: x => setSelectedMarkets(x.sort()),
      resetOnClick,
      withColors,
    }),
    breakdown: () => ({
      label: 'Breakdown by',
      control: CONTROL_TYPE.SELECT,
      options: [BREAKDOWN_OPTIONS.CATEGORY, BREAKDOWN_OPTIONS.PACKER_PLANT, BREAKDOWN_OPTIONS.MARKET],
      value: breakdownMode,
      setValue: x => {
        dispatch(setQualityNCRsBreakdownMode(x));
        dispatch(setQualityNCRsActivePackerPlants([undefined]));
      },
      style: breakdownMode === BREAKDOWN_OPTIONS.PACKER_PLANT ? { marginBottom: 0 } : undefined,
    }),
    breakdownPackerPlantSelect: () => ({
      label: '',
      control: CONTROL_TYPE.SELECT,
      options: [
        ...addBottomTenToTheListOfPackerPlants(availablePackerPlants || [], bottomTenPackerPlants).map(x => x.name),
      ],
      value: breakdownPackerPlant?.name,
      setValue: x =>
        setBreakdownPackerPlant(
          addBottomTenToTheListOfPackerPlants(availablePackerPlants || [], bottomTenPackerPlants).find(
            p => p.name === x
          )
        ),
      style: { marginBottom: 0 },
      extraControl: extraComponentsTypes.paragraph(extraControlText()),
    }),
    packerPlantRadio: options => ({
      label: '',
      control: CONTROL_TYPE.RADIO_GROUP,
      options,
      value: highlightedPackerPlants.length === options.length ? undefined : highlightedPackerPlants[0],
      setValue: setHighlightedPackerPlants,
      resetOnClick: true,
      withColors: true,
    }),
  };

  const definedActivePackerPlants = React.useMemo(() => activePackerPlants.filter(x => !!x), [activePackerPlants]);

  // adjusting packer mode if the amount of charts changes
  React.useEffect(() => {
    if (
      packerMode === PACKER_MODE.BOTTOM_10_TOTAL &&
      incidentsMode !== INCIDENTS_OPTIONS.BY_MARKET &&
      incidentsMode !== INCIDENTS_OPTIONS.TOTAL_WITH_BREAKDOWN &&
      !volumeMode &&
      !claimDollarsMode
    ) {
      setPackerMode(PACKER_MODE.BOTTOM_10);
    } else if (
      packerMode === PACKER_MODE.BOTTOM_10 &&
      incidentsMode !== INCIDENTS_OPTIONS.BY_MARKET &&
      incidentsMode !== INCIDENTS_OPTIONS.TOTAL_WITH_BREAKDOWN &&
      !(!volumeMode && !claimDollarsMode)
    ) {
      setPackerMode(PACKER_MODE.BOTTOM_10_TOTAL);
    }
  }, [packerMode, incidentsMode, volumeMode, claimDollarsMode]);

  // resetting filters when they are not needed any more and setting only the relevant ones
  React.useEffect(() => {
    if (incidentsMode === INCIDENTS_OPTIONS.TOTAL || !incidentsMode) {
      setSelectedCategories([]);
      setSelectedSubcategories([]);
      setSelectedMarkets([]);
      if (timeRange === TIME_RANGE_OPTIONS.YOY && availableYears) {
        setSelectedYears(availableYears.slice(0, DEFAULT_NUM_OF_YEARS_SELECTED));
      } else if (timeRange === TIME_RANGE_OPTIONS.HISTORICAL) {
        setSelectedYears([]);
      }
    } else if (incidentsMode === INCIDENTS_OPTIONS.TOTAL_WITH_BREAKDOWN) {
      setSelectedYears([]);
      setSelectedSubcategories([]);
      if (breakdownMode === BREAKDOWN_OPTIONS.CATEGORY) {
        setSelectedCategories(availableCategories);
        setSelectedMarkets([]);
        setBreakdownPackerPlant(undefined);
      } else if (breakdownMode === BREAKDOWN_OPTIONS.PACKER_PLANT) {
        setSelectedCategories([]);
        setSelectedMarkets([]);
        if (!breakdownPackerPlant) {
          setBreakdownPackerPlant(
            addBottomTenToTheListOfPackerPlants(availablePackerPlants || [], bottomTenPackerPlants).find(
              x => x.id === 'bottom_10'
            )
          );
        }
      } else if (breakdownMode === BREAKDOWN_OPTIONS.MARKET) {
        setSelectedCategories([]);
        setSelectedMarkets(availableMarkets);
        setBreakdownPackerPlant(undefined);
      }
    } else if (incidentsMode === INCIDENTS_OPTIONS.BY_CATEGORY) {
      setSelectedCategories(availableCategories.slice(0, MAX_NUM_OF_ITEMS_CHECKED));
      setSelectedSubcategories([]);
      setSelectedMarkets([]);
      setSelectedYears([]);
    } else if (incidentsMode === INCIDENTS_OPTIONS.BY_SUBCATEGORY) {
      setSelectedCategories([
        availableCategoriesWithSubcategories.find(x => x.category === CUSTOM_FILTERS.MOST_REPORTED_SUB_CATEGORIES)
          ?.category,
      ]);
      setSelectedSubcategories(
        availableCategoriesWithSubcategories.find(x => x.category === CUSTOM_FILTERS.MOST_REPORTED_SUB_CATEGORIES)
          ?.subCategories || []
      );
      setSelectedMarkets([]);
      setSelectedYears([]);
    } else if (incidentsMode === INCIDENTS_OPTIONS.BY_MARKET) {
      setSelectedCategories([]);
      setSelectedSubcategories([]);
      setSelectedMarkets(availableMarkets);
      setSelectedYears([]);
    }
  }, [incidentsMode, breakdownMode, availableYears]);

  // reset breakdown packerPlant if we are not looking at a breakdown
  React.useEffect(() => {
    if (incidentsMode !== INCIDENTS_OPTIONS.TOTAL_WITH_BREAKDOWN) {
      setBreakdownPackerPlant(undefined);
    }
  }, [incidentsMode]);

  // reset highlighted packerPlant if the radio isn't there
  React.useEffect(() => {
    if (breakdownPackerPlant && breakdownPackerPlant.bottomTenPackerPlants) {
      setHighlightedPackerPlants(breakdownPackerPlant.bottomTenPackerPlants.map(x => x.name));
    } else if (breakdownPackerPlant) {
      setHighlightedPackerPlants([breakdownPackerPlant.name]);
    } else {
      setHighlightedPackerPlants([]);
    }
  }, [breakdownPackerPlant]);

  // setting categories based on how many packerPlants are selected to be filtered by
  React.useEffect(() => {
    if (incidentsMode === INCIDENTS_OPTIONS.BY_CATEGORY) {
      if (
        definedActivePackerPlants.length > 1 ||
        (definedActivePackerPlants[0] &&
          definedActivePackerPlants[0].includesPackerPlants &&
          packerMode === PACKER_MODE.BOTTOM_10)
      ) {
        setSelectedCategories([availableCategories[0]]);
      } else {
        setSelectedCategories(availableCategories);
      }
    }
  }, [activePackerPlants, packerMode]);

  // setting subcategories based on how many packerPlants are selected to be filtered by
  React.useEffect(() => {
    if (incidentsMode === INCIDENTS_OPTIONS.BY_SUBCATEGORY) {
      if (
        definedActivePackerPlants.length > 1 ||
        (definedActivePackerPlants[0] &&
          definedActivePackerPlants[0].includesPackerPlants &&
          packerMode === PACKER_MODE.BOTTOM_10)
      ) {
        setSelectedSubcategories([
          availableCategoriesWithSubcategories.find(x => x.category === selectedCategories[0])?.subCategories[0] || [],
        ]);
      } else {
        setSelectedSubcategories(
          availableCategoriesWithSubcategories.find(x => x.category === selectedCategories[0])?.subCategories || []
        );
      }
    }
  }, [activePackerPlants, packerMode, incidentsMode, selectedCategories]);

  // setting packer mode when a packer is selected in the filter
  React.useEffect(() => {
    if (activePackerPlants[0] && activePackerPlants[0].includesPackerPlants) {
      setPackerMode(PACKER_MODE.TOTAL);
    } else {
      setPackerMode(undefined);
    }
  }, [activePackerPlants]);

  // colors setting
  React.useEffect(() => {
    if (selectedYears && (incidentsMode === INCIDENTS_OPTIONS.TOTAL || incidentsMode === null)) {
      if (availableYears && selectedYears && timeRange === TIME_RANGE_OPTIONS.YOY) {
        setDataColors(getColors(selectedYears, availableYears));
      } else if (definedActivePackerPlants.length > 1) {
        setDataColors(
          getColors(definedActivePackerPlants.map(x => x.name)),
          definedActivePackerPlants.map(x => x.name)
        );
      } else if (
        definedActivePackerPlants[0] &&
        definedActivePackerPlants[0].bottomTenPackerPlants &&
        packerMode === PACKER_MODE.BOTTOM_10
      ) {
        setDataColors(
          getColors(
            definedActivePackerPlants[0].bottomTenPackerPlants.map(x => x.name),
            definedActivePackerPlants[0].bottomTenPackerPlants.map(x => x.name)
          )
        );
      } else if (definedActivePackerPlants[0] && !definedActivePackerPlants[0].bottomTenPackerPlants) {
        setDataColors({ [definedActivePackerPlants[0].name]: '#4E5984' });
      } else {
        setDataColors({});
      }
    } else if (incidentsMode === INCIDENTS_OPTIONS.TOTAL_WITH_BREAKDOWN) {
      if (breakdownMode === BREAKDOWN_OPTIONS.CATEGORY) {
        setDataColors(getColors(selectedCategories, availableCategories));
      } else if (breakdownMode === BREAKDOWN_OPTIONS.MARKET) {
        setDataColors(getColors(selectedMarkets, availableMarkets, true));
      } else if (breakdownMode === BREAKDOWN_OPTIONS.PACKER_PLANT && breakdownPackerPlant) {
        if (breakdownPackerPlant.bottomTenPackerPlants) {
          setDataColors(
            getColors(
              breakdownPackerPlant.bottomTenPackerPlants.map(x => x.name),
              breakdownPackerPlant.bottomTenPackerPlants.map(x => x.name)
            )
          );
        } else {
          setDataColors({});
        }
      }
    } else if (availableCategories && selectedCategories && incidentsMode === INCIDENTS_OPTIONS.BY_CATEGORY) {
      if (definedActivePackerPlants.length > 1) {
        setDataColors(
          getColors(definedActivePackerPlants.map(x => x.name)),
          definedActivePackerPlants.map(x => x.name)
        );
      } else if (
        definedActivePackerPlants[0] &&
        definedActivePackerPlants[0].bottomTenPackerPlants &&
        packerMode === PACKER_MODE.BOTTOM_10
      ) {
        setDataColors(
          getColors(
            definedActivePackerPlants[0].bottomTenPackerPlants.map(x => x.name),
            definedActivePackerPlants[0].bottomTenPackerPlants.map(x => x.name)
          )
        );
      } else {
        setDataColors(getColors(selectedCategories, availableCategories));
      }
    } else if (selectedSubcategories && incidentsMode === INCIDENTS_OPTIONS.BY_SUBCATEGORY) {
      if (definedActivePackerPlants.length > 1) {
        setDataColors(
          getColors(definedActivePackerPlants.map(x => x.name)),
          definedActivePackerPlants.map(x => x.name)
        );
      } else if (
        definedActivePackerPlants[0] &&
        definedActivePackerPlants[0].bottomTenPackerPlants &&
        packerMode === PACKER_MODE.BOTTOM_10
      ) {
        setDataColors(
          getColors(
            definedActivePackerPlants[0].bottomTenPackerPlants.map(x => x.name),
            definedActivePackerPlants[0].bottomTenPackerPlants.map(x => x.name)
          )
        );
      } else {
        setDataColors(
          getColors(
            selectedSubcategories,
            availableCategoriesWithSubcategories.find(x => x.category === selectedCategories[0])?.subCategories
          )
        );
      }
    } else if (availableMarkets && selectedMarkets && INCIDENTS_OPTIONS.BY_MARKET) {
      setDataColors(getColors(selectedMarkets, availableMarkets, true));
    }
  }, [
    timeRange,
    incidentsMode,
    selectedYears,
    selectedCategories,
    selectedSubcategories,
    selectedMarkets,
    breakdownPackerPlant,
    highlightedPackerPlants,
    activePackerPlants,
    packerMode,
  ]);

  // components config
  const components = React.useMemo(() => {
    if (!incidentsMode && !volumeMode && !claimDollarsMode) {
      return [];
    }

    if (incidentsMode === INCIDENTS_OPTIONS.TOTAL || !incidentsMode) {
      if (timeRange === TIME_RANGE_OPTIONS.YOY) {
        return [componentsTypes.years(), componentsTypes.filterByPackerPlant(1, false)];
      }
      if (timeRange === TIME_RANGE_OPTIONS.HISTORICAL) {
        return [
          componentsTypes.filterByPackerPlant(MAX_NUM_OF_SELECTED_PACKER_PLANTS, definedActivePackerPlants.length > 1),
        ];
      }
    } else if (incidentsMode === INCIDENTS_OPTIONS.TOTAL_WITH_BREAKDOWN) {
      if (breakdownMode === BREAKDOWN_OPTIONS.CATEGORY) {
        return [
          componentsTypes.breakdown(),
          componentsTypes.categoryRadio(true, true),
          componentsTypes.filterByPackerPlant(1, false),
        ];
      }
      if (breakdownMode === BREAKDOWN_OPTIONS.PACKER_PLANT) {
        if (breakdownPackerPlant && breakdownPackerPlant.bottomTenPackerPlants) {
          return [
            componentsTypes.breakdown(),
            componentsTypes.breakdownPackerPlantSelect(true),
            componentsTypes.packerPlantRadio(breakdownPackerPlant.bottomTenPackerPlants.map(x => x.name)),
          ];
        }
        return [componentsTypes.breakdown(), componentsTypes.breakdownPackerPlantSelect()];
      }
      if (breakdownMode === BREAKDOWN_OPTIONS.MARKET) {
        return [
          componentsTypes.breakdown(),
          componentsTypes.marketRadio(true, true),
          componentsTypes.filterByPackerPlant(1, false),
        ];
      }
    } else if (incidentsMode === INCIDENTS_OPTIONS.BY_CATEGORY) {
      if (
        definedActivePackerPlants.length > 1 ||
        (definedActivePackerPlants[0] &&
          definedActivePackerPlants[0].includesPackerPlants &&
          packerMode === PACKER_MODE.BOTTOM_10)
      ) {
        return [
          componentsTypes.categoryRadio(false, false),
          componentsTypes.filterByPackerPlant(MAX_NUM_OF_SELECTED_PACKER_PLANTS, definedActivePackerPlants.length > 1),
        ];
      }
      return [
        componentsTypes.categoryCheckbox(),
        componentsTypes.filterByPackerPlant(MAX_NUM_OF_SELECTED_PACKER_PLANTS, false),
      ];
    } else if (incidentsMode === INCIDENTS_OPTIONS.BY_SUBCATEGORY) {
      if (
        definedActivePackerPlants.length > 1 ||
        (definedActivePackerPlants[0] &&
          definedActivePackerPlants[0].includesPackerPlants &&
          packerMode === PACKER_MODE.BOTTOM_10)
      ) {
        return [
          componentsTypes.categorySelect(),
          componentsTypes.subcategoryRadio(),
          componentsTypes.filterByPackerPlant(MAX_NUM_OF_SELECTED_PACKER_PLANTS, definedActivePackerPlants.length > 1),
        ];
      }
      return [
        componentsTypes.categorySelect(),
        componentsTypes.subcategoryCheckbox(),
        componentsTypes.filterByPackerPlant(MAX_NUM_OF_SELECTED_PACKER_PLANTS, false),
      ];
    } else if (incidentsMode === INCIDENTS_OPTIONS.BY_MARKET) {
      return [componentsTypes.marketCheckbox(), componentsTypes.filterByPackerPlant(1, false)];
    }
    return [];
  }, [
    incidentsMode,
    volumeMode,
    claimDollarsMode,
    timeRange,
    activePackerPlants,
    availableYears,
    selectedYears,
    breakdownMode,
    availableCategories,
    selectedCategories,
    availableMarkets,
    selectedMarkets,
    availableCategoriesWithSubcategories,
    selectedSubcategories,
    breakdownPackerPlant,
  ]);

  const component = config => {
    if (!config.options) return null;
    return (
      <config.control
        label={config.label}
        options={config.options}
        value={config.value}
        onChange={config.setValue}
        maxNumOfValues={config.maxNumOfValues}
        resetOnClick={config.resetOnClick}
        withColors={config.withColors}
        dataColors={dataColors}
        indicator={config.indicator}
        isDisabled={config.isDisabled}
      />
    );
  };

  return (
    <div>
      {components.map(x => {
        return (
          <div
            key={x.label + x.value + x.options.join()}
            className={styles.QualityDetailsNCRs_sideBarFilterContainer}
            style={x.style}
          >
            {component(x)}
            {x.extraControl && component(x.extraControl)}
          </div>
        );
      })}
      {packerMode === PACKER_MODE.BOTTOM_10 &&
        activePackerPlants[0]?.includesPackerPlants &&
        component(extraComponentsTypes.paragraph(extraComponentsTypesText()))}
      {packerMode === PACKER_MODE.BOTTOM_10 &&
        activePackerPlants[0]?.bottomTenPackerPlants &&
        component(componentsTypes.packerPlantRadio(activePackerPlants[0].bottomTenPackerPlants.map(x => x.name)))}
    </div>
  );
}

QualityDetailsNCRsSidebar.propTypes = {
  dataColors: dataColorsPropTypes,
  setDataColors: setDataColorsPropTypes,
  availableYears: availableYearsPropTypes,
  selectedYears: selectedYearsPropTypes,
  setSelectedYears: setSelectedYearsPropTypes,
  availableCategories: availableCategoriesPropTypes,
  selectedCategories: selectedCategoriesPropTypes,
  setSelectedCategories: setSelectedCategoriesPropTypes,
  availableCategoriesWithSubcategories: availableCategoriesWithSubcategoriesPropTypes,
  selectedSubcategories: selectedSubcategoriesPropTypes,
  setSelectedSubcategories: setSelectedSubcategoriesPropTypes,
  availableMarkets: availableMarketsPropTypes,
  selectedMarkets: selectedMarketsPropTypes,
  setSelectedMarkets: setSelectedMarketsPropTypes,
  availablePackerPlants: availablePackerPlantsPropTypes,
  packerMode: packerModePropTypes,
  setPackerMode: setPackerModePropTypes,
  breakdownPackerPlant: breakdownPackerPlantPropTypes,
  setBreakdownPackerPlant: setBreakdownPackerPlantPropTypes,
  highlightedPackerPlants: highlightedPackerPlantsPropTypes,
  setHighlightedPackerPlants: setHighlightedPackerPlantsPropTypes,
};
