import React from 'react';

const calculateTransportCost = (transport, lines) => {
  if (!transport || !lines || lines.length === 0) {
    return 0;
  }
  const quantitySum = lines.map(line => line.quantity).reduce((prv, curr) => prv + curr);

  return (quantitySum * transport.cost).toFixed(2);
};

const TransportCost = ({ packer_plant_id, grinder_uid, transport, lines }) => {
  let transportCost = null;

  if (packer_plant_id && grinder_uid && transport) {
    transportCost = calculateTransportCost(transport, lines);
  }

  return (
    <div className="row">
      <div style={{ margin: '0 auto', paddingTop: '25px' }}>
        {transportCost > 0 ? (
          <h4 className="text-center">
            Transport cost: <strong>${transportCost}</strong>
          </h4>
        ) : (
          false
        )}
      </div>
    </div>
  );
};

export default TransportCost;
