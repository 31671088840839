import React from 'react';

const RiskManagement = ({ configsObject }) => {
  return (
    <div className="row" style={{ marginTop: '30px', height: '200px' }}>
      <div className="col-sm-6">
        <h4>Risk Management</h4>
        <p>
          <strong>% Existing Price Risk Managed Volume</strong>
        </p>
        {configsObject.risk_managment_fy ? (
          <table className="kpi-table">
            <tbody>
              <tr>
                <td>FY</td>
                <td>Target</td>
                <td>Variance</td>
              </tr>
              <tr>
                <td>
                  <span>{configsObject.risk_managment_fy.value_float}</span>
                </td>
                <td>
                  <span style={{ color: '#63729D' }}>{configsObject.risk_management_target.value_float}</span>
                </td>
                <td>
                  <span
                    style={
                      configsObject.risk_management_target.value_float - configsObject.risk_managment_fy.value_float > 0
                        ? { color: 'green' }
                        : { color: 'red' }
                    }
                  >
                    {(
                      configsObject.risk_management_target.value_float - configsObject.risk_managment_fy.value_float
                    ).toFixed(2)}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          false
        )}
      </div>
      <div className="col-sm-6">
        <h4>Quality</h4>
        <p>
          <strong>YTD Critical Misses & EAS</strong>
        </p>
        {configsObject.quality_critical_misses ? (
          <table className="kpi-table">
            <tbody>
              <tr>
                <td>Critical Misses</td>
                <td>EAS</td>
              </tr>
              <tr>
                <td>
                  <span>{configsObject.quality_critical_misses.value_int}</span>
                </td>
                <td>
                  <span style={configsObject.quality_eas.value_int < 0 ? { color: 'green' } : { color: 'red' }}>
                    {configsObject.quality_eas.value_int}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          false
        )}
      </div>
    </div>
  );
};

export default RiskManagement;
