import { ANGUS, FRESH_FROZEN, ORDER_PRICE_TYPE } from '../slices/markets/marketsConfig';

const COLORS = {
  HIGHLIGHT: '#FC5D36',
  // Cow Icons
  [ORDER_PRICE_TYPE.RISK_MANAGED]: '#805CFF',
  [ORDER_PRICE_TYPE.SPOT]: '#F01D82',
  [ORDER_PRICE_TYPE.FORMULA]: '#1CA6B2',
  [ORDER_PRICE_TYPE.CONTRACT]: '#805CFF',
  [ORDER_PRICE_TYPE.NOF]: '#F2C94C',
  [FRESH_FROZEN.FRESH]: '#5039B5',
  [FRESH_FROZEN.FROZEN]: '#FC5D36',
  [ANGUS.ANGUS]: '#FED000',
  [ANGUS.NOT_ANGUS]: '#00B5F2',
  GRINDER: 'rgb(61, 229, 199)',
  PACKER_PLANT: 'rgb(66, 91, 231)',
};

export const COLORS_RGB = {
  GRINDER: [61, 229, 199], // Green
  PACKER_PLANT: [66, 91, 231], // Blue
};

export default COLORS;
