import Constants from './Constants';

class API {
  constructor({ baseURL, headers }) {
    if (!baseURL) {
      throw new Error('Base url is required as a first parameter');
    }

    if (baseURL[baseURL.length - 1] === '/') {
      this.baseURL = baseURL.slice(0, baseURL.length - 1);
    } else {
      this.baseURL = baseURL;
    }

    this.headers = new Headers(headers || {});
  }

  registerHeader = (key, val) => {
    if (key && val) {
      // remove already appended headers
      this.headers.delete(key);
      this.headers.append(key, val);
    }
    return this;
  };

  deregisterHeader = key => {
    if (key in this.headers) {
      this.headers.delete(key);
    }
    return this;
  };

  request = (method, path, body, config = {}) => {
    let headers;
    if (config.headers) {
      headers = new Headers([...this.headers.entries(), ...config.headers.entries()]);
    } else {
      headers = new Headers([...this.headers.entries()]);
    }

    // Allow the browser to set the right content type and boundary for form data by deleting the default content Type
    if (body instanceof FormData) headers.delete('Content-Type');

    let url = `${this.baseURL}${path}`;
    if (config.params) {
      url = `${url}?${new URLSearchParams(config.params)}`;
    }
    return fetch(url, {
      method,
      body,
      headers,
    });
  };

  // Convenience methods
  get = (path, config = {}) => {
    return this.request('GET', path, null, config);
  };

  post = (path, body, config = {}) => {
    return this.request('POST', path, body instanceof FormData ? body : JSON.stringify(body), config);
  };

  put = (path, body, config = {}) => {
    return this.request('PUT', path, JSON.stringify(body), config);
  };

  patch = (path, body, config = {}) => {
    return this.request('PATCH', path, JSON.stringify(body), config);
  };

  delete = (path, config = {}) => {
    return this.request('DELETE', path, null, config);
  };

  // Entity convenience methods.
  registerResource = resource => {
    const containsParams = /:/.test(resource.path);
    const includesBody = ['POST', 'PUT', 'PATCH'].includes(resource.httpMethod);
    if (!(resource.namespace in this)) {
      this[resource.namespace] = {};
    }
    this[resource.namespace][resource.methodName] = (...args) => {
      let params;
      let body;
      let { path } = resource;
      if (containsParams) {
        params = args.shift();
        Object.entries(params).forEach(([k, v]) => {
          path = path.replace(`:${k}`, v);
        });
      }
      if (includesBody) {
        body = args.shift();
      }
      const config = args.shift();
      // Trigger before function depending on methodName
      return this.request(resource.httpMethod, path, JSON.stringify(body), config);
      // Trigger after function depending on methodName
    };
  };

  registerResources = resources => {
    resources.forEach(resource => {
      this.registerResource(resource);
    });
  };
}

export const resourceCreator = ({ name, path }) => {
  const resources = [];
  // List all
  resources.push({
    namespace: name,
    methodName: 'list',
    httpMethod: 'GET',
    path,
  });
  // Create one
  resources.push({
    namespace: name,
    methodName: 'create',
    httpMethod: 'POST',
    path,
  });
  // Get one by id
  resources.push({
    namespace: name,
    methodName: 'getOne',
    httpMethod: 'GET',
    path: `${path}/:id`,
  });
  // Put one by id
  resources.push({
    namespace: name,
    methodName: 'put',
    httpMethod: 'PUT',
    path: `${path}/:id`,
  });
  // Patch one by id
  resources.push({
    namespace: name,
    methodName: 'patch',
    httpMethod: 'PATCH',
    path: `${path}/:id`,
  });
  // Delete one by id
  resources.push({
    namespace: name,
    methodName: 'delete',
    httpMethod: 'DELETE',
    path: `${path}/:id`,
  });
  return resources;
};

const api = new API({
  baseURL: Constants.URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;
