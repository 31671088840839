import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  column: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onRowChange: PropTypes.func,
  onClose: PropTypes.func,
  row: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

function InputFormatter({ row, column, className, value, ...rest }) {
  let val = value;
  if (!value && row[column?.key]) {
    val = row[column.key];
    // eslint-disable-next-line no-underscore-dangle
    if (val._isAMomentObject) {
      val = val.format(DEFAULT_DATE_FORMAT);
    }
  }
  return (
    <div
      style={{
        paddingLeft: rest.px || rest.pl || rest.paddingLeft || '3px',
        paddingRight: rest.px || rest.pr || rest.paddingRight || '3px',
        paddingTop: rest.py || rest.pt || rest.paddingTop || '4px',
        paddingBottom: rest.py || rest.pb || rest.paddingBottom || '3px',
      }}
    >
      <input
        className={`rdg-text-editor form-control ${className}`}
        style={{
          borderColor: '#ccc',
          paddingLeft: rest.px || rest.pl || rest.paddingLeft || '5px',
          paddingRight: rest.px || rest.pr || rest.paddingRight || '5px',
          paddingTop: rest.py || rest.pt || rest.paddingTop || '1px',
          paddingBottom: rest.py || rest.pb || rest.paddingBottom || '1px',
          lineHeight: rest.lineHeight || '22px',
          fontSize: rest.fontSize || '12px',
        }}
        value={val || row[column.key]}
      />
    </div>
  );
}

InputFormatter.propTypes = propTypes;

export default InputFormatter;
