import { connect } from 'react-redux';

import { logout } from '../../actions/actions_auth';

import Logout from '../../components/pages/Logout';

const mapStateToProps = state => {
  return {
    user: state.user,
    token: state?.user?.token,
    password_reset: state.password_reset,
    error: state.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogout: token => {
      dispatch(logout(token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
