import { connect } from 'react-redux';

import Login from '../../components/pages/Login';

const mapStateToProps = state => {
  return {
    user: state.user,
    password_reset: state.password_reset,
    error: state.error,
  };
};

const ActiveLogin = connect(mapStateToProps)(Login);

export default ActiveLogin;
