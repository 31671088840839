import { connect } from 'react-redux';

import { convertToCamelCase } from '../../helpers';
import InternationalOrderLineComponent from '../../components/orders/order-form/InternationalOrderLine/InternationalOrderLine';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

const mapStateToProps = state => {
  const {
    packer_plants: packerPlants,
    units_of_measure: unitsOfMeasure,
    tags,
    load_sizes: loadSizes,
    end_users: endUsers,
    price_types: priceTypes,
  } = findEntitiesInState(state);
  const result = {
    user: state.user,
    configs: state.configs,
    token: state.user.token,
    packerPlants,
    unitsOfMeasure,
    tags,
    loadSizes,
    endUsers,
    productSpecifications: state.specifications || [],
    priceTypes,
  };
  const cameled = convertToCamelCase(result);
  return cameled;
};

const InternationalOrderLine = connect(mapStateToProps)(InternationalOrderLineComponent);

export default InternationalOrderLine;
