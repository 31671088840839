import get from 'lodash/get';
import moment from 'moment';

export const getGrinderOptionsByOrders = (orders, grinders) => {
  if (orders.length && grinders.length) {
    let grinderUids = orders.map(order => order.grinder_uid);
    grinderUids = [...new Set(grinderUids)];

    return grinderUids.map(grinderUid => {
      const grinder = grinders.find(grinderOption => grinderOption.uid === grinderUid);
      return {
        label: grinder.name,
        value: grinder.uid,
      };
    });
  }
  return [];
};

export const getEndUserOptionsByOrders = (orders, endUsers) => {
  if (orders.length && endUsers.length) {
    let endUserIds = orders.map(order => order.end_user_id);
    endUserIds = [...new Set(endUserIds)];

    return endUserIds.map(endUserId => {
      const endUser = endUsers.find(endUserOption => endUserOption.id === endUserId);
      return {
        label: endUser.legal_name,
        value: endUser.id,
        grinders_uids: endUser.grinders_uids,
      };
    });
  }
  return [];
};

export const getEarliestProductionDate = order => {
  let earliestProductionDate = null;
  order.lines.forEach(line => {
    const tempEarliestProductionDate = get(line, 'earliest_production_date') || null;
    if (earliestProductionDate == null) {
      earliestProductionDate = moment(tempEarliestProductionDate, 'YYYY-MM-DD');
    } else if (earliestProductionDate > tempEarliestProductionDate) {
      earliestProductionDate = moment(tempEarliestProductionDate, 'YYYY-MM-DD');
    }
  });
  return earliestProductionDate.format('MM-DD-YYYY');
};

export const getNextRolloverDate = order => {
  // not sure why this failed sometimes
  if (order.coldstore_details) {
    let rolloverDate = moment(order.coldstore_details.inspection_date, 'YYYY-MM-DD');
    const rollWindow = parseInt(order.coldstore_details.cold_store.roll_window);

    if (rollWindow > 0) {
      while (rolloverDate < moment()) {
        rolloverDate = rolloverDate.add(rollWindow, 'days');
      }
    }
    return rolloverDate.format('MM-DD-YYYY');
  }
};

export const calculateUsedByDate = (line, inputProduct) => {
  const usedByDate = typeof line === 'string' ? line : get(line, 'earliest_production_date'); // calculated property field
  if (!usedByDate) {
    return null;
  }
  return moment(usedByDate, 'YYYY-MM-DD').add(inputProduct.cl < 75 ? 90 : 333, 'days');
};

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

export const isOrderCompleted = order => {
  if (order) {
    return !(
      order.delivery_date === null ||
      order.shipment_date === null ||
      (order.scheduled_delivery_time === null && order.null_delivery_time !== true) ||
      order.transporter_id === null
    );
  }
  return false;
};

export function groupByMultiple(array, f) {
  const groups = {};
  array.forEach(o => {
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(group => {
    return groups[group];
  });
}

export const markLines = orders => {
  return orders.map(order => {
    order.lines = order.lines.map((line, i) => {
      return { ...line, isMain: i === 0, orderStatus: order.status };
    });
    return order;
  });
};

// If the locked shipment date matches the order's current shipment date, then the order is valid
export function checkValidShipmentDate(order) {
  const priceBucket = get(order, 'active_price_bucket');
  // Only check shipment date on price bucket if the order is active and locked
  if (priceBucket && priceBucket.is_active && priceBucket.lock_date && priceBucket.shipment_date) {
    return priceBucket.shipment_date === order.shipment_date;
  }
  return true;
}

// This function returns a list of invalid orders
export function checkForInvalidOrders(orders) {
  const invalidOrders = orders.filter(order => !checkValidShipmentDate(order));
  return invalidOrders;
}

export const getGrinderOptionsByEndUser = (endUser, grinders) => {
  if (endUser && grinders.length) {
    return grinders
      .filter(grinder => endUser.grinders_uids.includes(grinder.uid))
      .map(grinder => ({
        label: grinder.name,
        value: grinder.uid,
      }));
  }
  return null;
};

export const getEndUserOptionsByGrinder = (grinder, endUsers) => {
  if (grinder && endUsers.length) {
    return endUsers
      .filter(endUser => endUser.grinders_uids.includes(grinder.value))
      .map(endUser => ({
        label: endUser.legal_name,
        value: endUser.id,
        grinders_uids: endUser.grinders_uids,
      }));
  }
  return null;
};

export const getGrinderFromOrdersOptionsByEndUser = (endUser, grinders) => {
  if (endUser && grinders.length) {
    return grinders
      .filter(grinder => endUser.grinders_uids.includes(grinder.value))
      .map(grinder => ({
        label: grinder.label,
        value: grinder.value,
      }));
  }
  return null;
};

export const getEndUserOptionsByGrinderFromOrders = (grinder, endUsers) => {
  if (grinder && endUsers.length) {
    return endUsers
      .filter(endUser => endUser.grinders_uids.includes(grinder.value))
      .map(endUser => ({
        label: endUser.label,
        value: endUser.value,
        grinders_uids: endUser.grinders_uids,
      }));
  }
  return null;
};

export const getEndUserOptionsByOrderGrinders = (grinders, endUsers) => {
  if (grinders.length && endUsers.length) {
    return endUsers.filter(endUser => {
      const matchedGrinder = grinders.find(grinder => endUser.grinders_uids.includes(grinder.value));
      return matchedGrinder;
    });
  }
  return [];
};
