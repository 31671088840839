import { connect } from 'react-redux';

import DynamicSidebarMenu from '../../components/DynamicSidebarMenu';

const mapStateToProps = state => {
  return {
    nextGen: state.user.next_gen_ui,
    dynamicNavigationLinks: state.access_levels.dynamicNavigationLinks,
  };
};

export default connect(mapStateToProps)(DynamicSidebarMenu);
