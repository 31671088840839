import { connect } from 'react-redux';
import ReportsList from '../../components/selfServiceReports/selfServiceReportsList/reportsList';

const mapStateToProps = state => {
  return {
    user: state.user,
    selfServiceReports: state.selfServiceReports,
  };
};

const reportsList = connect(mapStateToProps)(ReportsList);
export default reportsList;
