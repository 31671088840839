export const OPTIMIZER_BEEF_FORECAST = 'OPTIMIZER_BEEF_FORECAST';

export function pickOptimizerBeefForecast(grinder, forecast) {
  return {
    type: OPTIMIZER_BEEF_FORECAST,
    payload: {
      grinder,
      forecast,
    },
  };
}
