import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import OrderFormFinalizeMessage from './OrderFormFinalizeMessage';

const SaveSection = props => {
  const buttonRowStyle = { paddingTop: '6px' };

  const { finalizeSuccessMsg, reset, pendingLoaded, fastLoaded, latestOrders, copies, save } = props;

  return (
    <Box className="col-md-12">
      {finalizeSuccessMsg ? (
        <OrderFormFinalizeMessage
          reset={reset}
          pendingLoaded={pendingLoaded}
          fastLoaded={fastLoaded}
          latestOrders={latestOrders}
          copies={copies}
        />
      ) : (
        false
      )}
      <Box className="row" style={buttonRowStyle}>
        <Box className="col-md-6" />
        <Box className="col-md-6">
          <Box className="pull-right">
            <Button
              colorScheme="actionSecondary"
              onClick={() => save(true)}
              height="43px"
              width="166px"
              fontSize="16px"
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SaveSection;
