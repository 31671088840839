import axios from 'axios';
import Constants from '../../Constants';

export const BASE_URL = `${Constants.URL}bulk-upload`;

export default async function opsBulkFileUpload(fileData) {
  const response = await axios.post(`${BASE_URL}`, fileData);
  console.log('response ----> ', response);
  return response;
}
