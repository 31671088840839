import Article from './containers/articles/Article';
import ArticlesList from './containers/articles/ArticlesList';
import ArticleEdit from './containers/articles/ArticleEdit';
import BulkUpdaterPage from './containers/bulkUpdaterPage/BulkUpdaterPage';
import BuyActivity from './components/buyActivity/BuyActivity';
import ColdstoreManagement from './containers/pages/ColdstoreManagement';
import DailyTasksAdmin from './containers/dailyTasks/DailyTasksAdmin';
import DailyTasksDashboard from './containers/dailyTasks/DailyTasksDashboard';
import Dashboard from './containers/pages/Dashboard';
import KPI from './components/kpi/KPI';
import DrawDownReports from './containers/finance/DrawDownReports';
import FinancePage from './containers/finance/FinancePage';
import Logout from './containers/pages/Logout';
import Markets from './containers/analyticDashboard/markets/Markets';
import MarketsVolumeDetails from './containers/analyticDashboard/marketsDetails/Volume';
import SupplyChainDetails from './containers/analyticDashboard/supplyChainDetails/SupplyChainDetails';
import MasterData from './containers/masterData/MasterData';
import OrderManagement from './containers/pages/OrderManagement';
import PurchaseOrderCard from './containers/orders/PurchaseOrderCard';
import PurchaseOrderPage from './containers/orders/PurchaseOrderPage';
import CreateSydneyOrder from './containers/orders/CreatePurchaseOrderForm';
import CreateChicagoOrder from './containers/orders/PurchaseOrder';
import OpsMap from './containers/dashboard/OpsMap';
import Quality from './containers/analyticDashboard/quality/Quality';
import QualityDetailsNCRs from './containers/analyticDashboard/qualityDetails/ncrs/QualityDetailsNCRs';
import QualityDetailsClaims from './containers/analyticDashboard/qualityDetails/claims/QualityDetailsClaims';
import ReportsList from './containers/selfServiceReports/selfServiceReportsList';
import selfServiceReport from './containers/selfServiceReports/selfServiceReport';
import selfServiceReportConfig from './containers/selfServiceReports/selfServiceReportConfig';
import Treasury from './containers/analyticDashboard/treasury/Treasury';
import TreasuryDetails from './containers/analyticDashboard/treasuryDetails/TreasuryDetails';
import UserManagement from './containers/pages/UserManagement';
import UserSettings from './containers/pages/UserSettings';
import Home from './containers/basic/Home';
import KPIArchive from './components/kpi/KPIArchive';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getDocument } from './actions/actions_generated_documents';
import Constants from './Constants';
import DataImportWizard from './containers/dataImportWizard/DataImportWizard';
import Pricing from './containers/pricing/Pricing';
import NCRs from './components/ncrs/Review/NCRs';
import NCRCard from './components/ncrs/Review/NCRCard';
import NCREdit from './components/ncrs/Review/NCREdit';
import CreateNCR from './components/ncrs/Author/CreateNCR';
import NCRForm from './components/ncrs/Author/NCRForm';
import EmailAttachments from './containers/emailAttachments/EmailAttachments';
import FoodSafetyQuality from './containers/quality/FoodSafetyQuality';
import OpsBulkUpdaterPage from './containers/OpsBulkUpdater/OpsBulkUpdaterPage';

const routeConfig = {
  groups: [
    {
      name: 'Home',
      hideGroupName: true,
      children: [
        {
          name: 'Home',
          authConfig: {
            anyOfPermissions: ['dashboard_read', 'read_analytic_dashboard', 'wip_demo'],
          },
          routeConfig: {
            exact: true,
            path: '/',
          },
          component: Home,
          icon: 'cfc-icon icon-gen-home',
        },
        {
          name: 'KPI',
          excludeFromNavigation: true,
          authConfig: {
            requiredPermissions: ['dashboard_read'],
          },
          routeConfig: {
            exact: true,
            path: '/kpi-dashboard',
          },
          component: Dashboard,
          icon: 'cfc-icon icon-gen-home',
        },
        {
          name: 'Ops',
          excludeFromNavigation: true,
          authConfig: {
            requiredPermissions: ['dashboard_read'],
          },
          routeConfig: {
            exact: true,
            path: '/dashboards/ops',
          },
          component: OpsMap,
          icon: 'cfc-icon icon-com-settings',
        },
        {
          name: 'Daily Tasks',
          disabledFlag: Constants.UNLEASH_FLAG.DAILY_TASKS,
          authConfig: {
            requiredPermissions: ['exceptions_read'],
          },
          routeConfig: {
            exact: true,
            path: '/daily-tasks',
          },
          component: DailyTasksDashboard,
          icon: 'cfc-icon icon-ui-bullets',
          children: [
            {
              name: 'Daily Tasks Admin',
              excludeFromNavigation: true,
              routeConfig: {
                exact: true,
                path: '/daily-tasks-admin',
              },
              component: DailyTasksAdmin,
            },
          ],
        },
        {
          name: 'NCRs',
          // disabledFlag: Constants.UNLEASH_FLAG.DAILY_TASKS,
          authConfig: {
            requiredPermissions: ['ncr'],
          },
          routeConfig: {
            exact: true,
            path: '/ncr',
          },
          component: NCRs,
          icon: 'fmg-icon fmg-icon-claims',
          className: 'cfc-content--no-padding',
          children: [
            {
              name: 'New NCR',
              excludeFromNavigation: true,
              routeConfig: {
                exact: true,
                path: '/author-ncr',
              },
              icon: 'cfc-icon icon-ui-bullets',
              className: 'cfc-content--no-padding',
              component: CreateNCR,
            },
            {
              name: 'change NCR',
              excludeFromNavigation: true,
              routeConfig: {
                exact: true,
                path: '/change-ncr/:id',
              },
              icon: 'cfc-icon icon-ui-bullets',
              className: 'cfc-content--no-padding',
              component: NCRForm,
            },
            {
              name: 'Edit NCR',
              excludeFromNavigation: true,
              routeConfig: {
                exact: true,
                path: '/edit-ncr/:id',
              },
              icon: 'cfc-icon icon-ui-bullets',
              className: 'cfc-content--no-padding',
              component: NCREdit,
            },
            {
              name: 'Edit Incident',
              excludeFromNavigation: true,
              routeConfig: {
                exact: true,
                path: '/edit-incident/:id',
              },
              icon: 'cfc-icon icon-ui-bullets',
              className: 'cfc-content--no-padding',
              component: NCREdit,
            },
            {
              name: 'NCR Card',
              excludeFromNavigation: true,
              routeConfig: {
                exact: true,
                path: '/ncr/:id',
              },
              icon: 'cfc-icon icon-ui-bullets',
              className: 'cfc-content--no-padding',
              component: NCRCard,
            },
            {
              name: 'NCR Card',
              excludeFromNavigation: true,
              routeConfig: {
                exact: true,
                path: '/incidents/:id',
              },
              icon: 'cfc-icon icon-ui-bullets',
              className: 'cfc-content--no-padding',
              component: NCRCard,
            },
          ],
        },
        {
          name: 'User Settings',
          excludeFromNavigation: true,
          routeConfig: {
            exact: true,
            path: '/user-settings',
          },
          component: UserSettings,
        },
        {
          name: 'Log Out',
          excludeFromNavigation: true,
          routeConfig: {
            exact: true,
            path: '/logout',
          },
          component: Logout,
        },
      ],
    },
    {
      name: 'Business Intelligence',
      authConfig: {
        anyOfPermissions: ['articles_read', 'dashboard_read', 'read_analytic_dashboard', 'read_self_service_report'],
      },
      children: [
        {
          name: 'Market Insights (Articles)',
          disabledFlag: Constants.UNLEASH_FLAG.ARTICLES,
          authConfig: {
            requiredPermissions: ['articles_read'],
          },
          routeConfig: {
            exact: true,
            path: '/articles',
          },
          component: ArticlesList,
          icon: 'cfc-icon icon-gen-light-bulb',
          className: 'cfc-content--no-padding',
          children: [
            {
              name: 'Article',
              excludeFromNavigation: true,
              routeConfig: {
                exact: true,
                path: '/articles/:slug',
              },
              component: Article,
            },
            {
              name: 'Edit Article',
              excludeFromNavigation: true,
              routeConfig: {
                exact: true,
                path: '/edit-article/:slug',
              },
              component: ArticleEdit,
            },
            {
              name: 'Create New Article',
              excludeFromNavigation: true,
              routeConfig: {
                exact: false,
                path: '/articles/edit/:slug',
              },
              component: ArticleEdit,
            },
          ],
        },
        {
          name: 'Business Performance',
          disabledFlag: Constants.UNLEASH_FLAG.BUSINESS_PERFORMANCE,
          authConfig: {
            anyOfPermissions: [
              'dashboard_read',
              'read_market_analytic_dashboard',
              'read_treasury_analytic_dashboard',
              'read_analytic_dashboard',
              'read_analytic_dashboard_quality',
              'read_articles_dashboard',
            ],
          },
          routeConfig: {
            exact: true,
            path: '/analytic-dashboard/markets',
          },
          component: Markets,
          icon: 'cfc-icon icon-pre-line-graph',
          children: [
            {
              name: 'Market Dashboard',
              disabledFlag: Constants.UNLEASH_FLAG.MARKET_DASHBOARD,
              authConfig: {
                requiredPermissions: ['read_analytic_dashboard'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/market/:market',
                pathOptions: ['/analytic-dashboard/market/aus', '/analytic-dashboard/market/us'],
                defaultPath: '/analytic-dashboard/market/aus',
              },
              component: Markets,
              icon: 'cfc-icon icon-pre-bar-chart',
            },
            {
              name: 'Treasury Dashboard',
              disabledFlag: Constants.UNLEASH_FLAG.TREASURY_DASHBOARD,
              authConfig: {
                requiredPermissions: ['read_treasury_analytic_dashboard'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/treasury/:market',
                pathOptions: ['/analytic-dashboard/treasury/aus', '/analytic-dashboard/treasury/us'],
                defaultPath: '/analytic-dashboard/treasury/us',
              },
              component: Treasury,
              icon: 'cfc-icon icon-fin-bank',
            },
            {
              name: 'Treasury Dashboard Details',
              excludeFromNavigation: true,
              authConfig: {
                requiredPermissions: ['read_analytic_dashboard', 'read_treasury_analytic_dashboard'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/treasury/:market/treasury-details',
              },
              component: TreasuryDetails,
            },
            {
              name: 'Markets Volume Details',
              excludeFromNavigation: true,
              authConfig: {
                requiredPermissions: ['read_market_analytic_dashboard', 'read_analytic_dashboard'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/market/:market/volume-details',
              },
              component: MarketsVolumeDetails,
            },
            {
              name: 'Supply Chain Details',
              excludeFromNavigation: true,
              authConfig: {
                requiredPermissions: ['read_market_analytic_dashboard', 'read_analytic_dashboard'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/market/:market/supply-chain-details',
              },
              component: SupplyChainDetails,
            },
            {
              name: 'Quality Dashboard',
              disabledFlag: Constants.UNLEASH_FLAG.QUALITY_DASHBOARD,
              authConfig: {
                requiredPermissions: ['read_analytic_dashboard_quality'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/quality/:market',
                pathOptions: ['/analytic-dashboard/quality/aus', '/analytic-dashboard/quality/us'],
                defaultPath: '/analytic-dashboard/quality/aus',
              },
              component: Quality,
              icon: 'cfc-icon icon-doc-certificate',
            },
            {
              name: 'NCRs Details',
              excludeFromNavigation: true,
              authConfig: {
                requiredPermissions: ['read_analytic_dashboard_quality'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/quality/:market/ncrs-details',
              },
              component: QualityDetailsNCRs,
            },
            {
              name: 'Claims Details',
              excludeFromNavigation: true,
              authConfig: {
                requiredPermissions: ['read_analytic_dashboard_quality'],
              },
              routeConfig: {
                exact: true,
                path: '/analytic-dashboard/quality/:market/claims-details',
              },
              component: QualityDetailsClaims,
              icon: 'cfc-icon icon-fin-bank',
            },
            {
              name: 'KPIs & Insights',
              disabledFlag: Constants.UNLEASH_FLAG.KPI_INSIGHTS,
              authConfig: {
                requiredPermissions: ['dashboard_read'],
              },
              routeConfig: {
                exact: true,
                path: '/kpi',
              },
              component: KPI,
              icon: 'cfc-icon icon-pre-node-graph',
              className: 'cfc-content--no-padding',
              children: [
                {
                  name: () => `${window.location.pathname.split('/').filter(Boolean)[1]} - KPIs & Insights`,
                  excludeFromNavigation: true,
                  routeConfig: {
                    exact: true,
                    path: '/kpi/:region/edit/:slug',
                  },
                  component: () => <ArticleEdit isKpi />,
                },
                {
                  name: () => `${window.location.pathname.split('/').filter(Boolean)[1]} - KPIs & Insights`,
                  excludeFromNavigation: true,
                  routeConfig: {
                    exact: true,
                    path: '/kpi/:region/:slug',
                  },
                  component: () => <Article isKpi />,
                },
                {
                  name: () => `${window.location.pathname.split('/').filter(Boolean)[1]} - KPIs & Insights`,
                  excludeFromNavigation: true,
                  routeConfig: {
                    exact: true,
                    path: '/kpi/:region',
                  },
                  component: KPI,
                  className: 'cfc-content--no-padding',
                },
                {
                  name: 'Archive - KPIs & Insights',
                  excludeFromNavigation: true,
                  routeConfig: {
                    exact: true,
                    path: '/kpi-archive',
                  },
                  component: KPIArchive,
                },
              ],
            },
          ],
        },
        {
          name: 'Reporting',
          disabledFlag: Constants.UNLEASH_FLAG.SELF_SERVICE_REPORT,
          authConfig: {
            requiredPermissions: ['read_self_service_report'],
          },
          routeConfig: {
            exact: true,
            path: '/self-service-reports',
          },
          component: ReportsList,
          icon: 'cfc-icon icon-pre-pie-chart',
          className: 'cfc-content--no-padding',
          children: [
            {
              name: 'Self Service Report Config',
              excludeFromNavigation: true,
              routeConfig: {
                exact: true,
                path: '/self-service-reports/:reportId',
              },
              component: selfServiceReportConfig,
              className: 'cfc-content--no-padding',
            },
            {
              name: 'Self Service Report',
              excludeFromNavigation: true,
              routeConfig: {
                exact: true,
                path: '/self-service-report/:reportId',
              },
              component: selfServiceReport,
            },
          ],
        },
        {
          name: 'Documents',
          authConfig: {
            requiredPermissions: ['read_self_service_report', 'order_management_read'],
          },
          routeConfig: {
            exact: true,
            path: '/purchase-orders/:purchaseOrderId/documents',
          },
          excludeFromNavigation: true,
          component: props => {
            const { params } = props.match;
            const { search } = props.location;
            const dispatch = useDispatch();
            React.useEffect(() => {
              dispatch(getDocument(params.purchaseOrderId, search));
            }, [props.location]);
            return '';
          },
        },
      ],
    },
    {
      name: 'Procurement',
      authConfig: {
        anyOfPermissions: ['buy_activity_read', 'order_management_write'],
      },
      children: [
        {
          name: 'Buy Activity Dashboard',
          disabledFlag: Constants.UNLEASH_FLAG.BUY_ACTIVITY,
          authConfig: {
            requiredPermissions: ['buy_activity_read'],
          },
          routeConfig: {
            exact: true,
            path: '/buy-activity',
          },
          component: BuyActivity,
          icon: 'cfc-icon icon-pre-chart-up',
        },
        {
          name: 'Create Order',
          disabledFlag: Constants.UNLEASH_FLAG.CREATE_ORDER,
          authConfig: {
            requiredPermissions: ['order_management_write'],
          },
          routeConfig: {
            exact: true,
            path: '/order-management',
          },
          component: OrderManagement,
          icon: 'cfc-icon icon-com-tick',
          className: 'cfc-content--no-padding',
          children: [
            {
              name: 'USA Orders',
              disabledFlag: Constants.UNLEASH_FLAG.CHICAGO_ORDER,
              authConfig: {
                requiredPermissions: ['chicago_order_form_write'],
              },
              routeConfig: {
                exact: true,
                path: '/order-management/chicago/create-order',
              },
              component: CreateChicagoOrder,
              icon: 'cfc-icon icon-cus-chicago-bean-cloudgate',
            },
            {
              name: 'AU/NZ Orders',
              disabledFlag: Constants.UNLEASH_FLAG.SYDNEY_ORDER,
              authConfig: {
                requiredPermissions: ['order_form_write'],
              },
              routeConfig: {
                exact: true,
                path: '/order-management/sydney/create-order',
              },
              component: CreateSydneyOrder,
              icon: 'cfc-icon icon-cus-sydney-opera-house',
            },
          ],
        },
      ],
    },
    {
      name: 'Logistics',
      authConfig: {
        anyOfPermissions: ['order_management_read'],
      },
      children: [
        {
          name: 'Bulk Updater',
          disabledFlag: Constants.UNLEASH_FLAG.BULK_UPDATER,
          authConfig: {
            requiredPermissions: ['bulk_updater', 'order_management_read'],
          },
          routeConfig: {
            exact: true,
            path: '/order/bulk',
          },
          component: BulkUpdaterPage,
          icon: 'cfc-icon icon-fin-notes',
          className: 'cfc-content--no-padding',
        },
        {
          name: 'Ops Bulk Updater',
          disabledFlag: Constants.UNLEASH_FLAG.BULK_UPDATER,
          authConfig: {
            requiredPermissions: ['bulk_updater', 'order_management_read'],
          },
          routeConfig: {
            exact: true,
            path: '/order/ops-bulk',
          },
          component: OpsBulkUpdaterPage,
          icon: 'cfc-icon icon-fin-notes',
          className: 'cfc-content--no-padding',
        },        
        {
          name: 'Order Management',
          disabledFlag: Constants.UNLEASH_FLAG.ORDER_MANAGEMENT,
          authConfig: {
            requiredPermissions: ['order_management_read'],
          },
          routeConfig: {
            exact: true,
            path: '/order/search',
          },
          component: OrderManagement,
          icon: 'cfc-icon icon-com-tick',
          className: 'cfc-content--no-padding',
          children: [
            {
              name: 'Purchase Order Card',
              excludeFromNavigation: true,
              routeConfig: {
                exact: false,
                path: '/order-management/po-card/:poId',
              },
              component: PurchaseOrderCard,
            },
            {
              name: 'Purchase Order Page',
              excludeFromNavigation: true,
              routeConfig: {
                exact: false,
                path: '/order-management/po-page/:poId',
              },
              component: PurchaseOrderPage,
              icon: 'cfc-icon icon-com-tick',
              className: 'cfc-content--no-padding',
            },
          ],
        },
        {
          name: 'Coldstore Management',
          authConfig: {
            requiredPermissions: ['order_management_read'],
          },
          routeConfig: {
            exact: true,
            path: '/coldstore-management',
          },
          component: ColdstoreManagement,
          icon: 'cfc-icon icon-wea-flake',
          disabledFlag: Constants.UNLEASH_FLAG.COLD_STORE,
          className: 'cfc-content--no-padding',
        },
      ],
    },
    {
      name: 'Finance',
      authConfig: {
        anyOfPermissions: ['finance_invoices_read'],
      },
      children: [
        {
          name: 'Finance Invoicing',
          disabledFlag: Constants.UNLEASH_FLAG.INVOICING,
          authConfig: {
            requiredPermissions: ['finance_invoices_read'],
          },
          routeConfig: {
            exact: true,
            path: '/finance-invoicing',
          },
          component: FinancePage,
          icon: 'fa fa-dollar',
          className: 'cfc-content--no-padding',
        },
        {
          name: 'Drawdown Reports',
          disabledFlag: Constants.UNLEASH_FLAG.DRAWDOWN_REPORT,
          authConfig: {
            requiredPermissions: ['finance_invoices_read'],
          },
          routeConfig: {
            exact: true,
            path: '/finance-invoicing/drawdown-reports',
          },
          component: DrawDownReports,
          icon: 'cfc-icon icon-pre-chart-down',
          className: 'cfc-content--no-padding',
        },
      ],
    },
    {
      name: 'Quality',
      authConfig: {
        anyOfPermissions: ['food_safety_quality'],
      },
      children: [
        {
          name: 'Food Safety & Quality',
          disabledFlag: Constants.UNLEASH_FLAG.INVOICING,
          authConfig: {
            requiredPermissions: ['finance_invoices_read'],
            // requiredPermissions: ['food_safety_quality'],
          },
          routeConfig: {
            exact: true,
            path: '/food-safety-quality',
          },
          component: FoodSafetyQuality,
          icon: 'cfc-icon icon-fin-notes',
          className: 'cfc-content--no-padding',
        }
      ],
    },
    {
      name: 'Admin',
      disableFlag: 'admin',
      authConfig: {
        anyOfPermissions: ['master_data_read', 'roles_write', 'organisations_write', 'users_write'],
      },
      children: [
        {
          name: 'Master Data Management',
          authConfig: {
            anyOfPermissions: ['master_data_read', 'master_data_write'],
          },
          routeConfig: {
            exact: true,
            path: '/master-data',
          },
          component: MasterData,
          icon: 'flaticon-clipboard',
          className: 'cfc-content--no-padding',
          children: [
            {
              name: 'Master Data Management',
              excludeFromNavigation: true,
              routeConfig: {
                exact: false,
                path: '/master-data/entity/:entityType',
              },
              component: MasterData,
              className: 'cfc-content--no-padding',
            },
          ],
        },
        {
          name: 'Data Import Wizard',
          authConfig: {
            requiredPermissions: ['master_data_read', 'master_data_write'],
          },
          routeConfig: {
            exact: true,
            path: '/data-import',
          },
          component: DataImportWizard,
          icon: 'cfc-icon icon-fin-notes',
          className: 'cfc-content--no-padding',
        },
        {
          name: 'Pricing Engine',
          authConfig: {
            requiredPermissions: ['master_data_read', 'master_data_write'],
          },
          routeConfig: {
            exact: true,
            path: '/pricing',
          },
          component: Pricing,
          icon: 'fa fa-dollar',
          className: 'cfc-content--no-padding',
        },
        {
          name: 'Document Upload',
          authConfig: {
            requiredPermissions: ['master_data_read', 'master_data_write'],
          },
          routeConfig: {
            exact: true,
            path: '/attachments',
          },
          component: EmailAttachments,
          icon: 'fa fa-envelope',
          className: 'cfc-content--no-padding',
        },
        {
          name: 'Users Management',
          authConfig: {
            requiredPermissions: [
              'roles_read',
              'users_read',
              'organisations_read',
              'roles_write',
              'users_write',
              'organisations_write',
            ],
          },
          routeConfig: {
            exact: true,
            path: '/user-management',
          },
          component: UserManagement,
          icon: 'cfc-icon icon-use-user',
          className: 'cfc-content--no-padding',
          children: [
            {
              name: 'Users Management',
              excludeFromNavigation: true,
              routeConfig: {
                exact: false,
                path: '/user-management/entity/:entityType',
              },
              component: UserManagement,
              className: 'cfc-content--no-padding',
            },
          ],
        },
      ],
    },
  ],
};

export default routeConfig;
