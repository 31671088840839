import React, { memo, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
} from '@chakra-ui/react';
import { SketchPicker } from 'react-color';
import SelectField from '../basic/SelectField';
import { debounce, isEmpty, isEqual } from 'lodash';
import color from 'color';

const typeRegex = /\s([a-zA-Z]+)/;
const hexCode = /^#(?:[0-9a-fA-F]{3}){1,2}$/g;
const rgbaCode = /rgba\((\d{1,3}), (\d{1,3}), (\d{1,3}), (\d+\.\d{0,2})\)/;

export function getType(obj) {
  return {}.toString.call(obj).match(typeRegex)[1].toLowerCase();
}

function isColorCode(text) {
  if (getType(text) !== 'string') {
    return false;
  }
  if (hexCode.test(text) || (rgbaCode.test(text) && typeof color === 'function' && color(text))) {
    return true;
  }
  return false;
}

const ThemeListInput = memo(({ field, value, parents, onUpdate, localEnabled }) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    debounceOnUpdate(localValue);
  }, [localValue]);

  const setField = isNaN(field) ? field : `[${field}]`;

  const handleInputUpdate = event => {
    setLocalValue(event.target.value);
  };

  const handleColorUpdate = color => {
    setLocalValue(color.hex);
  };

  const debounceOnUpdate = debounce(value => {
    onUpdate(value, parents ? `${parents}.${setField}` : `${setField}`);
  }, 1500);

  return (
    <Flex my={3}>
      <InputGroup size="sm">
        <InputLeftAddon children={field} />
        <Input value={localValue} onChange={handleInputUpdate} isDisabled={!localEnabled} />
      </InputGroup>
      {isColorCode(value) && (
        <Popover preventOverflow placement="bottom-start">
          <PopoverTrigger>
            <Box
              as="button"
              bg={value}
              cursor="pointer"
              mx={0}
              w={8}
              h={8}
              disabled={!localEnabled}
              style={{
                cursor: localEnabled ? 'auto' : 'not-allowed',
              }}
            />
          </PopoverTrigger>
          <PopoverContent boxSize="fit-content">
            <PopoverArrow />
            <SketchPicker color={localValue} onChange={handleColorUpdate} />
          </PopoverContent>
        </Popover>
      )}
    </Flex>
  );
}, isEqual);

const ThemeListCheckBox = memo(({ field, value, parents, onUpdate, localEnabled }) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    debounceOnUpdate(localValue);
  }, [localValue]);

  const handleCheckboxUpdate = event => {
    setLocalValue(event.target.checked);
  };

  const debounceOnUpdate = debounce(value => {
    onUpdate(value, parents ? `${parents}.${field}` : `${field}`);
  }, 1500);

  return (
    <Box my={3}>
      <Checkbox isChecked={localValue} onChange={handleCheckboxUpdate} isDisabled={!localEnabled}>
        {field}
      </Checkbox>
    </Box>
  );
}, isEqual);

const ThemeListSubfield = memo(({ field, theme, parents, onUpdate, localEnabled }) => {
  const subFields = Object.keys(theme).map(subField => {
    const type = getType(theme[subField]);
    const value = theme[subField];
    return (
      <ThemeList
        key={subField}
        field={subField}
        value={value}
        type={type}
        parents={parents ? `${parents}.${field}` : `${field}`}
        onUpdate={onUpdate}
        localEnabled={localEnabled}
      />
    );
  });

  return (
    <Box>
      <Accordion allowToggle={true} allowMultiple={false}>
        <AccordionItem>
          <AccordionButton _expanded={{ bg: 'actionPrimary.500', color: 'white' }}>
            <Box flex="1" textAlign="left">
              <Text>{field}</Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>{subFields}</AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}, isEqual);

const ThemeList = memo(({ field, type, value, parents, onUpdate, localEnabled }) => {
  switch (type) {
    case 'number':
    case 'string':
      return (
        <ThemeListInput field={field} value={value} parents={parents} onUpdate={onUpdate} localEnabled={localEnabled} />
      );
    case 'boolean':
      return (
        <ThemeListCheckBox
          field={field}
          value={value}
          parents={parents}
          onUpdate={onUpdate}
          localEnabled={localEnabled}
        />
      );
    case 'object':
      if (isEmpty(value)) return null;
      return (
        <ThemeListSubfield
          field={field}
          theme={value}
          parents={parents}
          onUpdate={onUpdate}
          localEnabled={localEnabled}
        />
      );
    case 'array':
      const arrayOptions = value.filter(subField => subField).map(subField => ({ value: subField, label: subField }));
      return (
        <Box flex="1" textAlign="left">
          <Text>{field}</Text>
          <SelectField
            isMulti
            options={arrayOptions}
            value={arrayOptions}
            name={parents ? `${parents}.${field}` : `${field}`}
            onChange={(option, name) => {
              onUpdate(option.value, name);
            }}
            style={{ marginBottom: '15px' }}
            isDisabled={!localEnabled}
          />
        </Box>
      );
    case null:
    default:
      return null;
  }
}, isEqual);

export default ThemeList;
