import axios from 'axios';
import Constants from '../Constants';

export const BASE_URL = `${Constants.URL}strategies`;

export async function fetchStrategiesRequest() {
  const response = await axios.get(`${BASE_URL}`);
  return response.data;
}

export async function postStrategyRequest(strategy) {
  const response = await axios.post(`${BASE_URL}`, strategy);
  return response.data;
}

export async function patchStrategyRequest(strategy) {
  const response = await axios.patch(`${BASE_URL}/${strategy.number}`, strategy);
  return response.data;
}

export async function deleteStrategyRequest(strategy) {
  await axios.delete(`${BASE_URL}/${strategy.number}`);
  return true;
}
