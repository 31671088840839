import React from 'react';
import { Select, Typography } from 'antd';
import { TREASURY_DETAILS_VOLUME } from '../../../../slices/treasury-details/treasuryDetailsReducers';

const OPTIONS = [
  TREASURY_DETAILS_VOLUME.TOTAL,
  TREASURY_DETAILS_VOLUME.ALL_STRATEGIES,
  TREASURY_DETAILS_VOLUME.FORMULA,
  TREASURY_DETAILS_VOLUME.SPOT,
  TREASURY_DETAILS_VOLUME.CONTRACT,
  TREASURY_DETAILS_VOLUME.NOF,
  TREASURY_DETAILS_VOLUME.HEDGED_VS_EXPOSED,
  TREASURY_DETAILS_VOLUME.HEDGED,
  TREASURY_DETAILS_VOLUME.EXPOSED,
  TREASURY_DETAILS_VOLUME.FAT_VS_LEAN,
];

export default function FilterVolume(props) {
  const { activeVolume, onActiveVolumeChange } = props;

  function handleChange(value) {
    onActiveVolumeChange(value);
  }

  return (
    <div>
      <Typography.Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Volume
      </Typography.Text>
      <Select value={activeVolume} style={{ width: '100%' }} onChange={handleChange}>
        <Select.Option value={undefined}>--</Select.Option>
        {OPTIONS.map(o => (
          <Select.Option key={o} value={o}>
            {o}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
