import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { python } from '@codemirror/lang-python';
import { html } from '@codemirror/lang-html';
import CodeMirror from '@uiw/react-codemirror';
import { Alert } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Select from '../../../components/basic/SelectField';
import Tooltip from '../../../components/quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const DEFAULT_PYTHON_EDITOR = 'def main(**kwargs):';

export default ({ documentTemplates, calculators, onSaveDocumentTemplate, onRemoveDocumentTemplate }) => {
  const [preventClosingModalOnSubmit, setPreventClosingModalOnSubmit] = useState(false);

  const [calculatorLabels, setCalculatorLabels] = useState({});
  useEffect(() => {
    if (calculators) {
      setCalculatorLabels(
        calculators.reduce((o, c) => {
          o[c.uid] = c.label;
          return o;
        }, {})
      );
    }
  }, [calculators]);

  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues,
    resetForm,
    values,
    touched,
    isValid,
  } = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      label: Yup.string().nullable().required('Required'),
      description: Yup.string().nullable().required('Required'),
      uid: Yup.string().nullable().required('Required'),
      calculator_uid: Yup.string().nullable().required('Required'),
      email_subject: Yup.string().nullable().required('Required'),
      email_to: Yup.string().nullable().required('Required'),
      email_body: Yup.string().nullable().required('Required'),
      template: Yup.string().nullable().required('Required'),
      template_data_code: Yup.string().nullable().required('Required'),
    }),
    onSubmit: data => {
      data.email_to = !Array.isArray(data.email_to) ? data.email_to.split(',') : data.email_to;
      onSaveDocumentTemplate(data);
      if (!preventClosingModalOnSubmit) {
        closeFormModal();
      }
    },
  });
  const [showFormModal, setShowFormModal] = useState(false);

  const openFormModal = (documentTemplate = {}) => {
    resetForm();
    setValues(documentTemplate);
    setShowFormModal(true);
  };

  const closeFormModal = () => setShowFormModal(false);

  const removeVariable = () => {
    if (confirm('Are you sure you want to delete this item?') == true) {
      onRemoveDocumentTemplate(values);
      closeFormModal();
    }
  };

  return (
    <Box>
      <VStack marginBottom="80px" alignItems="end">
        <Button
          colorScheme="actionPrimary"
          onClick={() => openFormModal({ template_data_code: DEFAULT_PYTHON_EDITOR })}
        >
          New
        </Button>
      </VStack>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Label</Th>
              <Th>Description</Th>
              <Th>Calculator</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {documentTemplates &&
              documentTemplates.map(item => (
                <Tr key={item.uid}>
                  <Td>{item.label}</Td>
                  <Td>{item.description}</Td>
                  <Td>{calculatorLabels[item.calculator_uid]}</Td>
                  <Td>
                    <Button onClick={() => openFormModal(item)}>Edit</Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Modal size="6xl" isOpen={showFormModal} onClose={closeFormModal}>
        <ModalOverlay />
        <ModalContent minHeight="200px">
          <form onSubmit={handleSubmit}>
            <ModalHeader>Document Template</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing="10">
                <FormControl id="label" isInvalid={errors.label && touched.label}>
                  <FormLabel>
                    Label&nbsp;
                    <Tooltip
                      content="A unique name to identify this Document Template within the Pricing Engine."
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Input name="label" type="text" onChange={handleChange} onBlur={handleBlur} value={values.label} />
                  {errors.label && touched.label && (
                    <Alert style={{ marginTop: '5px' }} message={errors.label} type="error" />
                  )}
                </FormControl>
                <FormControl id="description" isInvalid={errors.description && touched.description}>
                  <FormLabel>
                    Description&nbsp;
                    <Tooltip
                      content="Detailed information about this Document Template to better understand its purpose."
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Input
                    name="description"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                  {errors.description && touched.description && (
                    <Alert style={{ marginTop: '5px' }} message={errors.description} type="error" />
                  )}
                </FormControl>
                <FormControl id="uid" isInvalid={errors.uid && touched.uid}>
                  <FormLabel>Name *</FormLabel>
                  <Input
                    name="uid"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.uid}
                    readOnly={values.created}
                  />
                  {errors.uid && touched.uid && (
                    <Alert style={{ marginTop: '5px' }} message={errors.uid} type="error" />
                  )}
                </FormControl>
                <FormControl id="calculator_uid" isInvalid={errors.calculator_uid && touched.calculator_uid}>
                  <FormLabel>
                    Calculator&nbsp;
                    <Tooltip content="Select the Calculator while it is created under Calculator." placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Select
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: true,
                      removeSelected: true,
                      name: 'calculator_uid',
                      value: values.calculator_uid && {
                        label: calculatorLabels[values.calculator_uid],
                        value: values.calculator_uid,
                      },
                      onChange: calculator_uid => {
                        setFieldValue('calculator_uid', calculator_uid?.value);
                      },
                      options: (calculators || []).map(c => ({ label: c.label, value: c.uid })),
                    }}
                  />
                  {errors.calculator_uid && touched.calculator_uid && (
                    <Alert style={{ marginTop: '5px' }} message={errors.calculator_uid} type="error" />
                  )}
                </FormControl>
                <FormControl id="email_subject" isInvalid={errors.email_subject && touched.email_subject}>
                  <FormLabel>
                    Email subject&nbsp;
                    <Tooltip content="The Email subject for the document template." placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Input
                    name="email_subject"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email_subject}
                  />
                  {errors.email_subject && touched.email_subject && (
                    <Alert style={{ marginTop: '5px' }} message={errors.email_subject} type="error" />
                  )}
                </FormControl>
                <FormControl id="email_to" isInvalid={errors.email_to && touched.email_to}>
                  <FormLabel>
                    Email to&nbsp;
                    <Tooltip content="The recipient Email for the document template." placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Input
                    name="email_to"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email_to}
                  />
                  {errors.email_to && touched.email_to && (
                    <Alert style={{ marginTop: '5px' }} message={errors.email_to} type="error" />
                  )}
                </FormControl>
                <FormControl id="email_body" isInvalid={errors.email_body && touched.email_body}>
                  <FormLabel>
                    Email body&nbsp;
                    <Tooltip content="The Email body for the document template." placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Textarea name="email_body" onChange={handleChange} onBlur={handleBlur} value={values.email_body} />
                  {errors.email_body && touched.email_body && (
                    <Alert style={{ marginTop: '5px' }} message={errors.email_body} type="error" />
                  )}
                </FormControl>
                <Tabs>
                  <TabList>
                    <Tab>Template *</Tab>
                    <Tab>Data Code *</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <FormControl id="template" isInvalid={errors.template && touched.template}>
                        <FormLabel>Template *</FormLabel>
                        <CodeMirror
                          value={values.template}
                          height="500px"
                          extensions={[html({})]}
                          onChange={template => {
                            setFieldValue('template', template);
                          }}
                        />
                        {errors.template && touched.template && (
                          <Alert style={{ marginTop: '5px' }} message={errors.template} type="error" />
                        )}
                      </FormControl>
                    </TabPanel>
                    <TabPanel>
                      <FormControl
                        id="template_data_code"
                        isInvalid={errors.template_data_code && touched.template_data_code}
                      >
                        <FormLabel>Data Code *</FormLabel>
                        <CodeMirror
                          value={values.template_data_code}
                          height="500px"
                          extensions={[python({})]}
                          onChange={template_data_code => {
                            setFieldValue('template_data_code', template_data_code);
                          }}
                        />
                        {errors.template_data_code && touched.template_data_code && (
                          <Alert style={{ marginTop: '5px' }} message={errors.template_data_code} type="error" />
                        )}
                      </FormControl>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Checkbox
                marginRight="auto"
                isChecked={preventClosingModalOnSubmit}
                onChange={() => setPreventClosingModalOnSubmit(!preventClosingModalOnSubmit)}
              >
                Prevent closing modal on submit
              </Checkbox>
              {values.created && (
                <Button type="button" colorScheme="error" onClick={removeVariable} marginRight="16px">
                  Remove
                </Button>
              )}
              <Button type="submit" colorScheme="actionPrimary" isDisabled={!isValid}>
                {!values.created ? 'New' : 'Save'}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};
