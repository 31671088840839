import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  Text,
  TabPanel,
  useDisclosure,
  HStack,
  ButtonGroup,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { RiPaintFill } from 'react-icons/ri';
import { IoArrowUndo } from 'react-icons/io5';
import { retrieveTheme, revertEditedChanges, saveTheme, updateEditedTheme } from '../../actions/actions_theme';
import ThemeList, { getType } from './ThemeList';
import { connect, useDispatch } from 'react-redux';
import { setWith } from 'lodash';

import ThemeJSONEditor from './ThemeJSONEditor';
import AvailableThemes from './AvailableThemes';
import LocalThemeToggle from './LocalThemeToggle';
import AuthGate from '../../containers/auth/AuthGate';

const ThemePanel = ({ theme }) => {
  const dispatch = useDispatch();
  const [saveEnabled, setSaveEnabled] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const saveChanges = () => {
    dispatch(saveTheme(theme.data)).then(() => {
      dispatch(retrieveTheme());
    });
  };

  const onEditTheme = (editedTheme, isPartialEdit) => {
    if (theme.local_enabled) {
      setSaveEnabled(false);
      dispatch(updateEditedTheme(editedTheme, isPartialEdit)).then(() => {
        setSaveEnabled(true);
      });
    }
  };

  const updateField = useCallback((value, path) => {
    const property = setWith({}, path, value, Object);
    onEditTheme({ ...property }, true);
  }, []);

  const revertChangesHandler = () => {
    setSaveEnabled(false);
    Promise.allSettled([dispatch(revertEditedChanges()), dispatch(retrieveTheme())]).then(() => {
      setSaveEnabled(true);
    });
  };

  return (
    <AuthGate requiredPermissions={['read_theme', 'write_theme']}>
      <IconButton
        isRound
        ref={btnRef}
        size="lg"
        colorScheme="actionSecondary"
        onClick={onOpen}
        position="fixed"
        right="40px"
        bottom="40px"
        zIndex="1300"
        icon={<RiPaintFill />}
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        closeOnOverlayClick={false}
        size="lg"
      >
        <DrawerOverlay background="#00000020" />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex alignItems="center">
              <Text>Edit theme</Text>
              {saveEnabled ? (
                <Badge ml={4} colorScheme="green">
                  Saved
                </Badge>
              ) : (
                <Badge ml={4}>Unsaved</Badge>
              )}
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Tabs isFitted isLazy>
              <TabList>
                <Tab>Available Themes</Tab>
                <Tab>Editor</Tab>
                <Tab>List</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <AvailableThemes />
                </TabPanel>
                <TabPanel>
                  <ThemeJSONEditor
                    theme={theme}
                    onValidChange={validChange => onEditTheme(validChange)}
                    onInValidChange={() => setSaveEnabled(false)}
                  />
                </TabPanel>
                <TabPanel>
                  {Object.keys(theme?.data).map(field => {
                    const type = getType(theme?.data[field]);
                    const value = theme?.data[field];
                    return (
                      <ThemeList
                        field={field}
                        value={value}
                        type={type}
                        localEnabled={theme.local_enabled}
                        parents={null}
                        onUpdate={updateField}
                      />
                    );
                  })}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
          <DrawerFooter>
            <HStack width="100%">
              <ButtonGroup display="flex" alignItems="baseline">
                <Button variant="outline" marginRight="20px" onClick={onClose}>
                  Cancel
                </Button>
                <LocalThemeToggle theme={theme} />
              </ButtonGroup>
              <ButtonGroup marginLeft="auto !important">
                <Button variant="outline" colorScheme="actionSecondary" onClick={revertChangesHandler}>
                  <Icon as={IoArrowUndo} />
                </Button>
                <Button colorScheme="actionPrimary" onClick={saveChanges} isDisabled={!saveEnabled}>
                  Save
                </Button>
              </ButtonGroup>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </AuthGate>
  );
};

ThemePanel.propTypes = {
  theme: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => {
  return {
    theme: state.theme,
  };
};

export default connect(mapStateToProps)(ThemePanel);
