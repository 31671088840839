import { connect } from 'react-redux';

import PurchaseOrderCardV2 from '../../components/orders/order-form/PurchaseOrderCardV2/PurchaseOrderCardV2';
import Constants from '../../Constants';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

const mapStateToProps = state => {
  return {
    ...findEntitiesInState(state),
    voyage: state.voyage || Constants.INITIAL_VOYAGE,
    user: state.user,
    users: state.users,
    token: state.user.token,
  };
};

const PurchaseOrderCard = connect(mapStateToProps)(PurchaseOrderCardV2);

export default PurchaseOrderCard;
