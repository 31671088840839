import { removeObjectFromArray, replaceObjectInArray } from '../helpers';
import {
  VOYAGE_LEG_ADDED,
  VOYAGE_LEG_REMOVED,
  VOYAGE_LEG_UPDATED,
  VOYAGE_LEGS_FETCHED,
} from '../actions/actions_voyage_legs';

const voyage_legs = (voyage_legs = [], action) => {
  const voyageLegsCopy = JSON.parse(JSON.stringify(voyage_legs));

  switch (action.type) {
    case VOYAGE_LEGS_FETCHED:
      return action.payload;
    case VOYAGE_LEG_ADDED:
      voyageLegsCopy.push(action.payload);
      return voyageLegsCopy;
    case VOYAGE_LEG_UPDATED:
      return replaceObjectInArray(voyageLegsCopy, action.payload);
    case VOYAGE_LEG_REMOVED:
      return removeObjectFromArray(voyageLegsCopy, action.payload);
    default:
      return voyage_legs;
  }
};

export default voyage_legs;
