import { createSelector } from '@reduxjs/toolkit';
import { mean, group } from 'd3';
import { TREASURY_TIME_INTERVAL } from '../treasuryConfig';
import { selectTreasuryDataRaw, selectTreasuryTimeScale, selectTreasuryTimeRange } from './selectTreasuryBasicData';

import { getMonth, getQuarter, getYear, format } from 'date-fns';
import { getDates } from './selectTreasuryTimeGroupedData';
import { selectTreasuryDetailsShowOutlook } from '../../treasury-details/treasuryDetailsReducers';

// TODO: make this more generic between selectors. Selector for date?
// Return a key to identify grouping for different timescales
const timeGroupingFuncs = {
  [TREASURY_TIME_INTERVAL.MONTHLY]: row => `${getYear(row.date)} - ${getMonth(row.date)}`,
  [TREASURY_TIME_INTERVAL.QUARTERLY]: row => `${getYear(row.date)} - Q${getQuarter(row.date)}`,
};

export const selectTreasuryBuyPerformanceData = createSelector(
  selectTreasuryDataRaw,
  selectTreasuryTimeScale,
  selectTreasuryDetailsShowOutlook,
  selectTreasuryTimeRange,
  (rawData, timeScale, showOutlook, timeRange) => {
    if (!rawData) return null;

    const { buyPerformance } = rawData;

    let buyData = showOutlook ? buyPerformance : buyPerformance.filter(x => x.date < new Date().getTime());

    buyData = showOutlook
      ? buyData.filter(d => d.date > timeRange[0])
      : buyData.filter(d => d.date > timeRange[0] && d.date < timeRange[1]);

    let aggregatedData = buyData;

    if (timeScale === TREASURY_TIME_INTERVAL.QUARTERLY) {
      // Aggregate into quarterly blocks.

      const grouped = group(buyData, timeGroupingFuncs[timeScale]);
      aggregatedData = Array.from(grouped.entries()).map(keyQuarters => {
        const quarters = keyQuarters[1];
        const [first] = quarters;
        return {
          interval: `Q${getQuarter(first.date)} ${getYear(first.date)}`,
          rawInterval: {
            interval: getQuarter(first.date),
            year: getYear(first.date),
          },
          usda_price: first.usda_price !== undefined ? mean(quarters, m => m.usda_price) : null,
          fmg_price: mean(quarters, m => m.fmg_price),
          fmg_high: mean(quarters, m => m.fmg_high),
          fmg_low: mean(quarters, m => m.fmg_low),
          usda_fat: mean(quarters, m => m.usda_fat),
          usda_lean: mean(quarters, m => m.usda_lean),
          usda_50CL: mean(quarters, m => m.usda_50CL),
          usda_65CL: mean(quarters, m => m.usda_65CL),
          usda_78CL: mean(quarters, m => m.usda_78CL),
          usda_90CL: mean(quarters, m => m.usda_90CL),
          mecardo_price: mean(quarters, m => m.mecardo_price),
          mla_price: mean(quarters, m => m.mla_price),
        };
      });
    } else if (timeScale === TREASURY_TIME_INTERVAL.MONTHLY) {
      // Aggregate into quarterly blocks.
      const { oneYearAgo, oneYearFromNow } = getDates();

      const grouped = group(
        buyData.filter(x => x.date > oneYearAgo && x.date < oneYearFromNow),
        timeGroupingFuncs[timeScale]
      );
      aggregatedData = Array.from(grouped.entries()).map(keyMonths => {
        const months = keyMonths;
        const [first] = months;
        return {
          interval: `${format(first.date, 'MMM')} ${getYear(first.date)}`,
          rawInterval: {
            interval: format(first.date, 'MMM'),
            year: getYear(first.date),
          },
          usda_price: first.usda_price !== undefined ? mean(months, m => m.usda_price) : null,
          fmg_price: mean(months, m => m.fmg_price),
          fmg_high: mean(months, m => m.fmg_high),
          fmg_low: mean(months, m => m.fmg_low),
          usda_fat: mean(months, m => m.usda_fat),
          usda_lean: mean(months, m => m.usda_lean),
          usda_50CL: mean(months, m => m.usda_50CL),
          usda_65CL: mean(months, m => m.usda_65CL),
          usda_78CL: mean(months, m => m.usda_78CL),
          usda_90CL: mean(months, m => m.usda_90CL),
          mecardo_price: mean(months, m => m.mecardo_price),
          mla_price: mean(months, m => m.mla_price),
        };
      });
    }

    return aggregatedData;
  }
);
