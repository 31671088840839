import { connect } from 'react-redux';

import VoyageDetailsItemComponent from '../../components/orders/order-form/edit-form/VoyageDetailsItem';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice'; 
import { convertToCamelCase } from '../../helpers';
const mapStateToProps = state => {
  const {voyage_legs} = findEntitiesInState(state);

  return {
    voyageLegs: convertToCamelCase(voyage_legs),
    user: state.user,
    token: state.user.token
  };
};

const VoyageDetailsItem = connect(mapStateToProps)(VoyageDetailsItemComponent);

export default VoyageDetailsItem;
