import * as React from 'react';
import { RankingChart } from '../../../treasuryDetails/RankingChart/RankingChart';
import { useBreakdownData } from '../../../../../containers/analyticDashboard/qualityDetails/claims/QualityDetailsClaimsCharts';
import styles from './BreakdownChart.module.css';

export default function BreakdownChart(props) {
  const { breakdownColors, highlightedPackerPlants, selectedCategories, selectedClaimAmounts, selectedDaysToResolve } =
    props;

  const breakdownData = useBreakdownData(selectedCategories, selectedClaimAmounts, selectedDaysToResolve);

  const entities = React.useMemo(() => {
    return highlightedPackerPlants.map(x => ({
      name: x,
      color: breakdownColors[x] || '#4E5984',
    }));
  }, [breakdownColors, highlightedPackerPlants]);

  if (breakdownData.length === 0) return null;

  return (
    <div className={styles.QualityDetailsClaims_breakdownChart}>
      <div className={styles.QualityDetailsClaims_chartTitle}>Establishments</div>
      {/* hack to rerender the charts without scroll when needed */}
      {breakdownData[0]?.rankingBreakdown.length > 12 && (
        <RankingChart data={breakdownData} splitIndex={100} entities={entities} withScroll={true} />
      )}
      {breakdownData[0]?.rankingBreakdown.length <= 12 && (
        <RankingChart data={breakdownData} splitIndex={100} entities={entities} withScroll={false} />
      )}
    </div>
  );
}
