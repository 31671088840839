import { Col, Row, Tooltip, Typography } from 'antd';
import { format } from 'date-fns';
import * as React from 'react';
import { formatChange, formatPercent, singleMonthLetter } from '../../../../modules/format';
import BaseChart from '../../treasury/BaseChart/BaseChart';
import styles from './FreshnessDays.module.css';
import { calculateRoundedMax } from '../../../../modules/data/rounded-max';
import yearOnYear, { getUniqueYears } from '../../../../modules/data/year-on-year';
import Constants from '../../../../Constants';

const FreshnessDays = props => {
  const { data, singlePackerPlant } = props;

  if (singlePackerPlant && !singlePackerPlant.isPacker) {
    return <FreshnessDaysSinglePackerPlant data={data} />;
  }

  if (!data.packerPlants) {
    return null;
  }

  return (
    <div data-test="freshnessdays-component">
      <div
        style={{
          display: 'flex',
          width: `100%`,
        }}
      >
        <Tooltip title={`${data.packerPlants.packerPlantsNotInSpec.length} establishments not within target`}>
          <div
            style={{
              width: `${(data.packerPlants.packerPlantsNotInSpec.length * 100) / data.packerPlants.totalCount}%`,
              height: 12,
              backgroundColor: 'var(--chakra-colors-red-50)',
              marginRight: 2,
              borderRadius: '5px 0 0 5px',
            }}
          />
        </Tooltip>
        <Tooltip title={`${data.packerPlants.countInSpec} establishments within target`}>
          <div
            style={{
              width: `${(data.packerPlants.countInSpec * 100) / data.packerPlants.totalCount}%`,
              height: 12,
              backgroundColor: '#D0F1EE',
              marginRight: 2,
              borderRadius: '0 5px 5px 0',
            }}
          />
        </Tooltip>
      </div>
      <div
        style={{
          display: 'flex',
          width: `100%`,
          justifyContent: 'space-between',
        }}
      >
        <div className={styles.FreshnessDays_freshnessDaysPercentage}>{`${formatPercent(1)(
          data.packerPlants.packerPlantsNotInSpec.length / data.packerPlants.totalCount
        )}`}</div>
        <div className={styles.FreshnessDays_freshnessDaysPercentage}>{`${formatPercent(1)(
          data.packerPlants.countInSpec / data.packerPlants.totalCount
        )}`}</div>
      </div>
      <Row style={{ marginTop: 10 }}>
        <Col span={12}>
          <div className={styles.FreshnessDays_title} style={{ color: 'var(--chakra-colors-red-50)' }}>
            <div>
              <strong>{data.packerPlants.packerPlantsNotInSpec.length}</strong> establishments not within target
            </div>
          </div>
          <div>
            {data.packerPlants.packerPlantsNotInSpec.map(x => (
              <div key={`freshness-${x.packerPlantId}`}>
                {x.packerPlantName} {x.isNew && <span className={styles.FreshnessDays_newBadge}>new</span>}
              </div>
            ))}
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.FreshnessDays_title}>
            <div>
              <strong>{data.packerPlants.countInSpec}</strong> establishments within target
            </div>
          </div>
          <div>
            <span style={{ display: 'inline-block', width: 15 }}>
              {formatChange('positive')(data.packerPlants.countInSpec - data.packerPlants.previousCountInSpec)}
            </span>
            <span style={{ opacity: 0.6 }}>
              <strong>{data.packerPlants.countInSpec - data.packerPlants.previousCountInSpec} establishments</strong>{' '}
              from 30d ago
            </span>
          </div>
        </Col>
      </Row>
      <div className={styles.FreshnessDays_countInSpec} style={{ marginTop: 10 }}>
        <div className={styles.FreshnessDays_incidentsTitle}>
          <Typography.Text className={styles.FreshnessDays_label}>
            count of establishments not within target
          </Typography.Text>
        </div>
        {data && (
          <BaseChart
            barData={yearOnYear(
              data.byPeriod.map(x => ({
                interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
                value: x.value,
              }))
            )}
            columns={getUniqueYears(data.byPeriod.map(x => format(new Date(x.date), 'MMM yyyy')))}
            colors={{ value: '#959BB5' }}
            splitIndex={-1}
            noCurrentLine
            noLegend
            ticksNumber={4}
            formatIntervals={singleMonthLetter}
            formatTooltip={val => `${val} establishments`}
            minMaxGenerator={(maxData, minData) => {
              return {
                maxVal: calculateRoundedMax(maxData),
                minVal: 0,
              };
            }}
            isSmall
            isLineChart
            isYearOnYear
          />
        )}
      </div>
    </div>
  );
};

const InSpec = () => (
  <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.1111 4.77778L12.6667 25L4 16.3333"
      stroke="var(--chakra-colors-teal)"
      strokeWidth="8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const NotIsSpec = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 4L13 13M13 13L4 4M13 13L4 22M13 13L22 22"
      stroke="var(--chakra-colors-red-50)"
      strokeWidth="8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const NoData = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 4L13 13M13 13L4 4M13 13L4 22M13 13L22 22"
      stroke="var(--chakra-colors-gray-50)"
      strokeWidth="8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const FreshnessDaysSinglePackerPlant = props => {
  const { data } = props;
  return (
    <div>
      <div className={styles.FreshnessDays_title}>
        <div style={{ width: 30, marginRight: 10 }}>
          {data.inSpecStatus === Constants.IN_SPEC_STATUS.NO_DATA && <NoData />}
          {data.inSpecStatus === Constants.IN_SPEC_STATUS.IN_SPEC && <InSpec />}
          {data.inSpecStatus === Constants.IN_SPEC_STATUS.NOT_IN_SPEC && <NotIsSpec />}
        </div>
        <div>
          <strong>
            {data.inSpecStatus === Constants.IN_SPEC_STATUS.IN_SPEC
              ? 'within target'
              : data.inSpecStatus === Constants.IN_SPEC_STATUS.NOT_IN_SPEC
              ? 'not within target'
              : 'no data'}
          </strong>
        </div>
      </div>
      <p className={styles.FreshnessDays_specDefinition}>Delivered product is maximum 5 days old</p>
      <div className={styles.FreshnessDays_incidentsTitle}>
        <Typography.Text className={styles.FreshnessDays_label}>history of meeting targets</Typography.Text>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: 10,
        }}
      >
        {data.byPeriod.map((x, i) => {
          return (
            <div
              key={`month-${i}`}
              style={{
                width: `calc(${100 / data.byPeriod.length}% - 2px)`,
                borderTop: `5px solid ${
                  x.inSpecStatus === Constants.IN_SPEC_STATUS.IN_SPEC
                    ? 'var(--chakra-colors-teal)'
                    : x.inSpecStatus === Constants.IN_SPEC_STATUS.NOT_IN_SPEC
                    ? '#FF2C1B'
                    : 'var(--chakra-colors-gray-50)'
                }`,
                paddingTop: 5,
                textAlign: 'center',
              }}
            >
              <span className={styles.FreshnessDays_lg}>{format(new Date(x.date), 'MMM')}</span>
              <span className={styles.FreshnessDays_xs}>{format(new Date(x.date), 'MMMMM')}</span>
              <br />
              {format(new Date(x.date), 'MMM') === 'Jan' ? (
                <span style={{ fontSize: 12, fontWeight: 500, whiteSpace: 'nowrap' }}>
                  {format(new Date(x.date), 'yyyy')}
                </span>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>
      <hr className={styles.FreshnessDays_hr} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className={styles.FreshnessDays_legend} style={{ backgroundColor: 'var(--chakra-colors-teal)' }} />
        <div style={{ marginRight: 20 }}>within target</div>
        <div className={styles.FreshnessDays_legend} style={{ backgroundColor: '#FF2C1B' }} />
        <div style={{ marginRight: 20 }}>not within target</div>
        <div className={styles.FreshnessDays_legend} style={{ backgroundColor: 'var(--chakra-colors-gray-50)' }} />
        <div>no data</div>
      </div>
    </div>
  );
};

export default FreshnessDays;
