import React from 'react';
import PropTypes from 'prop-types';
// Local Dependencies
import UploadDocumentForm from './UploadDocumentForm';
import { convertToCamelCase } from '../../helpers';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  Flex,
} from '@chakra-ui/react';

const propTypes = {
  autoSave: PropTypes.bool,
  closeModal: PropTypes.func,
  currentOrder: PropTypes.shape({
    loaded: PropTypes.bool,
    voyage: PropTypes.arrayOf(PropTypes.object),
  }),
  disabled: PropTypes.bool,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  poDocTypes: PropTypes.array.isRequired,
  stateForm: PropTypes.instanceOf(Object),
  token: PropTypes.string,
};

const UploadDocumentModal = props => {
  const { currentOrder, ...rest } = props;
  return (
    <Box>
      <Modal isOpen onClose={props.closeModal} size="full">
        <ModalOverlay />
        <ModalContent minH={40} width="657px">
          <ModalHeader borderBottomWidth={1}>Upload document</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent="center" alignItems="center">
              <UploadDocumentForm {...{ currentOrder, ...convertToCamelCase(rest) }} />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

UploadDocumentModal.propTypes = propTypes;

export default UploadDocumentModal;
