import { createSelector } from '@reduxjs/toolkit';
import { selectPackerPlants } from '../../quality-packer-plants/selectors/selectBasicPackerPlants';
import { selectQualityData, selectQualityPackerPlant } from './selectBasicQualityData';
import { getTwoPeriods } from '../../../modules/data/year-on-year';

export const selectQualityDIFOT = createSelector(
  selectQualityData,
  selectQualityPackerPlant,
  selectPackerPlants,
  (data, selectedPackerPlant, packerPlants) => {
    if (!data || !selectedPackerPlant || !packerPlants) return null;
    const pc = selectedPackerPlant.isPacker
      ? packerPlants.filter(x => x.isPartOf === selectedPackerPlant.id)
      : packerPlants;
    const twoPeriods = getTwoPeriods(data.difot.byPeriod);
    const sortedPackerPlants = (data.difot.packerPlants || data.difot.packers)
      .map(x => {
        const name = packerPlants.find(p => p.id === x.packerPlantId)?.name;
        const isPartOf = packerPlants.find(p => p.id === x.packerPlantId)?.isPartOf;
        return {
          packer_plant_id: x.packerPlantId,
          packer_plant_name: name,
          percent: x.percent,
          isNew: x.isNew,
          isPartOf,
        };
      })
      .sort((a, b) => a.percent - b.percent);

    const buckets = [
      {
        bucket: 10,
        count: sortedPackerPlants.filter(x => x.percent < 70).length,
      },
      {
        bucket: 70,
        count: sortedPackerPlants.filter(x => x.percent >= 70 && x.percent < 80).length,
      },
      {
        bucket: 80,
        count: sortedPackerPlants.filter(x => x.percent >= 80 && x.percent < 90).length,
      },
      {
        bucket: 90,
        count: sortedPackerPlants.filter(x => x.percent >= 90 && x.percent < 95).length,
      },
      {
        bucket: 95,
        count: sortedPackerPlants.filter(x => x.percent >= 95).length,
      },
    ];

    const sortedPackerPlantsFiltered = selectedPackerPlant.isPacker
      ? sortedPackerPlants.filter(x => x.isPartOf === selectedPackerPlant.id)
      : sortedPackerPlants;

    const bottom = sortedPackerPlantsFiltered.slice(0, 3).filter(x => x.percent < data.difot.weightedAverage.goal);
    const top = sortedPackerPlantsFiltered
      .slice(-3)
      .sort((a, b) => b.percent - a.percent)
      .filter(x => x.percent > Math.max(...bottom.map(y => y.percent)));
    return {
      ...data.difot,
      byPeriod: twoPeriods,
      packerPlants: {
        bottom,
        top,
        buckets,
        total: sortedPackerPlants.length,
        allPackerPlants: sortedPackerPlants,
        packerPlantsInPacker: pc,
      },
    };
  }
);
