import React from 'react';
import moment from 'moment';

const POQuickFilters = props => {
  const quickFiltersBtnStyle = { marginRight: '10px' };
  const filters = { paddingBottom: '30px' };

  const last24Hours = `?start_date=${moment().subtract(24, 'hours').format('YYYY-MM-DD')}`;
  const deliveryLastWeek = `?delivery_start_date=${moment()
    .subtract(7, 'days')
    .format('YYYY-MM-DD')}&delivery_end_date=${moment().format('YYYY-MM-DD')}`;
  const deliveryNextWeek = `?delivery_start_date=${moment().format('YYYY-MM-DD')}&delivery_end_date=${moment()
    .add(7, 'days')
    .format('YYYY-MM-DD')}`;
  const deliveryNext2Weeks = `?delivery_start_date=${moment().format('YYYY-MM-DD')}&delivery_end_date=${moment()
    .add(14, 'days')
    .format('YYYY-MM-DD')}`;

  return (
    <div style={filters}>
      <h5>Quick Filters</h5>
      <div className="row">
        <div className="col-sm-12">
          <button
            style={quickFiltersBtnStyle}
            className="btn btn-sm btn-success"
            onClick={() => props.search(last24Hours)}
          >
            Created Last 24 hours
          </button>
          <button
            style={quickFiltersBtnStyle}
            className="btn btn-sm btn-success"
            onClick={() => props.search(deliveryLastWeek)}
          >
            Delivered Last Week
          </button>
          <button
            style={quickFiltersBtnStyle}
            className="btn btn-sm btn-success"
            onClick={() => props.search(deliveryNextWeek)}
          >
            Delivered Next Week
          </button>
          <button
            style={quickFiltersBtnStyle}
            className="btn btn-sm btn-success"
            onClick={() => props.search(deliveryNext2Weeks)}
          >
            Delivered Next Two Weeks
          </button>
        </div>
      </div>
    </div>
  );
};

export default POQuickFilters;
