import { createSelector } from '@reduxjs/toolkit';
import { group } from 'd3';
import { selectQualityNCRsActivePackerPlants, selectQualityNCRsMarket } from './selectBasicDetailsNcrsData';
import { selectQualityNCRsData } from './selectQualityNCRsData';
import { VOLUME_OPTIONS } from '../qualityDetailsNcrsConfig';

const selectQualityNCRsDataByMarket = createSelector(
  selectQualityNCRsData,
  selectQualityNCRsActivePackerPlants,
  selectQualityNCRsMarket,
  (data, activePackerPlants, market) => {
    if (!data) return null;

    const activePackerPlantsIds = activePackerPlants.filter(x => !!x).map(x => x.id);

    const defaultMarketData = {
      incidentsCount: 0,
      incidentRate: 0,
      totalVolume: 0,
      percentOfTotalVolume: 0,
      // percentOfMarket: 0,
      weight: 0,
      claimDollars: 0,
      receivedWeight: 0,
      incidentsPerUnit: 0,
    };
    const defaultDomesticMarketData = {
      market: 'Domestic',
      ...defaultMarketData,
    };

    const defaultExportMarketData = {
      market: 'Export',
      ...defaultMarketData,
    };

    const dataByMarket = data.map(d => {
      const allMarketData = [];
      let allPackerPlants = [];

      if (activePackerPlantsIds.length) {
        allPackerPlants = d.byPackerPlant.filter(x => activePackerPlantsIds.includes(x.packerPlantId));
      } else {
        allPackerPlants = d.byPackerPlant.filter(p => p.packerPlantId.length > 3);
      }

      allPackerPlants.forEach(p => {
        let domesticMarketData = defaultDomesticMarketData;
        let exportMarketData = defaultExportMarketData;
        if (p.byMarket) {
          p.byMarket.forEach(marketData => {
            if (marketData.market === 'Domestic') {
              domesticMarketData = marketData;
            }
            if (marketData.market === 'Export') {
              exportMarketData = marketData;
            }
          });
        }
        allMarketData.push(...[domesticMarketData, exportMarketData]);
      });

      const groupedByMarket = Array.from(
        group(allMarketData, x => {
          return x.market;
        })
      );

      let totalReceivedWeight = 0;
      groupedByMarket.forEach(g => {
        g[1].forEach(m => {
          totalReceivedWeight += m.receivedWeight;
        });
      });

      const multiplier = VOLUME_OPTIONS[market.key].MULTIPLIER;
      return {
        date: d.date,
        byMarket: groupedByMarket.map(([marketKey, entries]) => {
          const incidentsCount = entries.reduce((a, b) => a + b.incidentsCount, 0);
          const marketReceivedWeight = entries.reduce((a, b) => a + b.receivedWeight, 0);
          const weight = entries.reduce((a, b) => a + b.weight, 0);
          let mar = {
            market: marketKey,
            incidentsCount,
            incidentRate: marketReceivedWeight ? (incidentsCount * multiplier) / marketReceivedWeight : 0,
            percentOfTotalVolume: marketReceivedWeight ? weight / marketReceivedWeight : 0,
            percentOfMarket: weight / totalReceivedWeight,
            weight,
            claimDollars: entries.reduce((a, b) => a + b.claimDollars, 0),
            incidentsPerUnit: marketReceivedWeight ? incidentsCount / marketReceivedWeight : 0,
          };
          if (activePackerPlants[0] && activePackerPlants[0].bottomTenPackerPlants) {
            const bottomTenPackerPlants = d.byPackerPlant.filter(
              x =>
                activePackerPlants[0].bottomTenPackerPlants.map(b => b.id).includes(x.packerPlantId) &&
                x.packerPlantId.length > 3
            );
            const incidentsCountBottomTen = bottomTenPackerPlants
              .map(x => x.byMarket.find(c => c.market === market)?.incidentsCount)
              .reduce((a, b) => a + b, 0);
            const weightBottomTen = bottomTenPackerPlants
              .map(x => x.byMarket.find(c => c.market === market)?.weight)
              .reduce((a, b) => a + b, 0);
            const receivedWeightBottomTen = bottomTenPackerPlants
              .map(x => x.byMarket.find(c => c.market === market)?.receivedWeight)
              .reduce((a, b) => a + b, 0);
            const bottomTen = {
              incidentsCountBottomTen,
              incidentRateBottomTen:
                receivedWeightBottomTen === 0 ? 0 : (incidentsCountBottomTen * multiplier) / receivedWeightBottomTen,
              percentOfTotalVolumeBottomTen:
                receivedWeightBottomTen === 0 ? 0 : weightBottomTen / receivedWeightBottomTen,
              weightBottomTen,
              claimDollarsBottomTen: bottomTenPackerPlants
                .map(x => x.byMarket.find(c => c.market === market)?.claimDollars)
                .reduce((a, b) => a + b, 0),
              incidentsPerUnitBottomTen: bottomTenPackerPlants
                .map(x => x.byMarket.find(c => c.market === market)?.incidentsPerUnit)
                .reduce((a, b) => a + b, 0),
            };
            mar = { ...mar, ...bottomTen };
          }
          return mar;
        }),
      };
    });

    return dataByMarket;
  }
);

export default selectQualityNCRsDataByMarket;
