import { UOM_ADDED, UOM_FETCHED, UOM_REMOVED, UOM_UPDATED } from '../actions/actions_units_of_measure';
import { removeObjectFromArray, replaceObjectInArray } from '../helpers';

const units_of_measure = (units_of_measure = [], action) => {
  const uomsCopy = JSON.parse(JSON.stringify(units_of_measure));

  switch (action.type) {
    case UOM_FETCHED:
      return action.payload;
    case UOM_ADDED:
      uomsCopy.push(action.payload);
      return uomsCopy;
    case UOM_UPDATED:
      return replaceObjectInArray(uomsCopy, action.payload);
    case UOM_REMOVED:
      return removeObjectFromArray(uomsCopy, action.payload);
    default:
      return units_of_measure;
  }
};

export default units_of_measure;
