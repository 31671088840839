import React from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import get from 'lodash/get';
// Local Deps
import TextFormatter from './TextFormatter';

function MoneyFormatter({ row, column }) {
  const value = get(row, column.key) || '';
  const formatted = value ? accounting.formatMoney(value) : '';
  return <TextFormatter text={formatted} />;
}

MoneyFormatter.propTypes = {
  row: PropTypes.instanceOf(Object),
  column: PropTypes.shape({
    key: PropTypes.string,
  }),
};

export default MoneyFormatter;
