import React from 'react';
import { Select, Typography, Divider } from 'antd';
import styles from '../Filter.module.css';

export default function FilterSelectGeneric(props) {
  const {
    value,
    onChange,
    options,
    optionLabels,
    label,
    includeNullOption,
    placeholder,
    allowClear,
    isDisabled,
    withColors,
    dataColors,
  } = props;

  function handleChange(value) {
    onChange(value);
  }

  return (
    <div data-with-colors={withColors} data-color={dataColors && dataColors[value]}>
      {label && <Typography.Text className={styles.filterLabel}>{label}</Typography.Text>}
      <Select
        value={value}
        className={styles.selectBox}
        onChange={handleChange}
        placeholder={placeholder}
        allowClear={allowClear}
        disabled={isDisabled}
      >
        {includeNullOption && <Select.Option value={null}>--</Select.Option>}
        {options.map(o => {
          if (o === 'Divider') {
            return (
              <Select.Option key={o} disabled style={{ overflow: 'none', minHeight: 5 }}>
                <Divider style={{ margin: 0 }} />
              </Select.Option>
            );
          }
          return (
            <Select.Option key={o} value={o}>
              {optionLabels ? optionLabels[o] : o}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}
