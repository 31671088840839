import get from 'lodash/get';

import api from '../api';
import Constants from '../Constants';
import { convertToSnakeCase } from '../helpers';
import { downloadFromS3Link } from '../helpers/downloads';
export const GENERATED_DOCUMENT = 'GENERATED_DOCUMENT';
export const GENERATED_DOCUMENT_ERROR = 'GENERATED_DOCUMENT_ERROR';
export const PO_DOCUMENTS_FETCHED = 'PO_DOCUMENTS_FETCHED';

const BASE_PATH = `/order_management/purchase_orders`;

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadDocuments(response) {
  return {
    type: PO_DOCUMENTS_FETCHED,
    documents: get(response, 'documents', []),
    purchaseOrder: get(response, 'purchase_order', {}),
  };
}

export function errorGeneratedDocument(error) {
  return {
    type: GENERATED_DOCUMENT_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function generateDocument(doc) {
  const URL = `${BASE_PATH}/${doc.documentType}`;

  const payload = convertToSnakeCase(doc);
  // TODO -- make casing consistent in the future.
  // The backend is expecting camelCase for these props
  const casingCorrectedPayload = {
    ...payload,
    ...(doc.purchaseOrderId && { purchaseOrderId: doc.purchaseOrderId }),
    ...(doc.generatedDate && { generatedDate: doc.generatedDate }),
    ...(doc.destinationPortCharge && { destinationPortCharge: doc.destinationPortCharge }),
    ...(doc.producedAt && { producedAt: doc.producedAt }),
    ...(doc.pdfTitle && { pdfTitle: doc.pdfTitle }),
  };

  return dispatch => {
    return api
      .post(URL, casingCorrectedPayload)
      .then(response => Constants.handleErrors(response, dispatch, errorGeneratedDocument))
      .then(json => {
        dispatch(loadDocuments(json));
      })
      .catch(error => dispatch(errorGeneratedDocument(error)));
  };
}

// will need changes once API can be accesed directly
export function getDocument(purchaseOrderId, queryParams) {
  const URL = `${BASE_PATH}/${purchaseOrderId}/documents/${queryParams}`;
  return dispatch => {
    return api
      .get(URL)
      .then(response => Constants.handleErrors(response, dispatch, errorGeneratedDocument))
      .then(json => {
        downloadFromS3Link(json.download_link);
      })
      .catch(error => dispatch(errorGeneratedDocument(error)));
  };
}
