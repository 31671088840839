import React, { Fragment, useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Input,
  Flex,
} from '@chakra-ui/react';
import { Formik, useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import api from '../../../api';

const UpdateContactInfo = ({ isOpenPopup, setIsOpenPopup, estId, editItem, updateList }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const updateContactInfo = async values => {
    const payload = {
      screen: 'contactinfo',
      fields_info: values,
    };

    try {
      setIsLoading(true);
      if (estId) {
        const response = await api.patch(`/op-establishment/${estId}`, payload);
        if (response.ok) {
          const dt = await response.json();
          updateList(allList => {
            const updatedList = allList.map(item => {
              if (item.id === estId) {
                return { ...item, contacts_info: dt };
              }
              return item;
            });
            return updatedList;
          });
          toast({
            title: 'Record updated successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        }
      }
    } catch (error) {
      toast({
        title: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
      setIsOpenPopup(false);
    }
  };

  const { values, errors, touched, setFieldValue, setValues, handleSubmit } = useFormik({
    initialValues: {
      fields: [
        {
          id: 0,
          info_type: 'Business Owner',
          fullname: '',
          mobile: '',
          email: '',
          after_hours: '',
          position: '',
        },
        {
          id: 0,
          info_type: 'Quality Contact',
          fullname: '',
          mobile: '',
          email: '',
          after_hours: '',
          position: '',
        },
        {
          id: 0,
          info_type: 'Sales Manager',
          fullname: '',
          mobile: '',
          email: '',
          after_hours: '',
          position: '',
        },
        {
          id: 0,
          info_type: 'Sustainability Manager',
          fullname: '',
          mobile: '',
          email: '',
          after_hours: '',
          position: '',
        },
        {
          id: 0,
          info_type: 'After Hours Contact',
          fullname: '',
          mobile: '',
          after_hours: '',
          position: '',
        },
      ],
    },
    validationSchema: Yup.object({
      fields: Yup.array().of(
        Yup.object().shape({
          info_type: Yup.string().required('Info type is required'),
          fullname: Yup.string().required('Full name is required'),
          mobile: Yup.string()
            .required('Mobile is required')
            .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Mobile number is not valid'),
          // email: Yup.string().when('info_type', (info_type, schema) => {
          //   return info_type[0] !== 'After Hours Contact'
          //     ? schema.email('Invalid email').required('Email is required')
          //     : schema;
          // }),
          after_hours: Yup.string().when('info_type', (info_type, schema) => {
            return info_type[0] === 'After Hours Contact' ? schema.required('After hours is required') : schema;
          }),
          position: Yup.string().when('info_type', (info_type, schema) => {
            return info_type[0] === 'After Hours Contact' ? schema.required('Position is required') : schema;
          }),
        })
      ),
    }),
    onSubmit: async _values => {
      setIsLoading(true);
      await updateContactInfo(
        _values.fields.map(field => ({
          ...field,
          id: field.id || null,
          email: field.email || '',
          after_hours: field.after_hours || '',
          position: field.position || '',
        }))
      );
    },
  });

  const handleChange = (e, index) => {
    setFieldValue(`fields[${index}].${e.target.name}`, e.target.value);
  };

  useEffect(() => {
    if (editItem) {
      setValues({
        fields: values.fields.map(field => {
          const dataField = editItem.find(f => f.info_type === field.info_type);
          return {
            ...field,
            id: dataField?.id || 0,
            fullname: dataField?.fullname || '',
            mobile: dataField?.mobile || '',
            email: dataField?.email || '',
            after_hours: dataField?.after_hours || '',
            position: dataField?.position || '',
          };
        }),
      });
    }
  }, [editItem]);

  return (
    <Modal isOpen={isOpenPopup} onClose={() => setIsOpenPopup(false)} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent maxW="min(870px, 80%)">
        <ModalHeader borderBottomWidth="1px" fontSize="20px">
          Update Contact Information
        </ModalHeader>
        <ModalCloseButton />
        <Formik>
          <form onSubmit={handleSubmit}>
            <ModalBody padding="22px">
              {values?.fields.map((field, index) => (
                <Fragment key={field.info_type}>
                  <Flex gap="20px" css={{ gap: '20px' }}>
                    <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                      <FormLabel>Type*</FormLabel>
                      <Input
                        name="info_type"
                        value={field.info_type}
                        onChange={e => handleChange(e, index)}
                        disabled={field.info_type === 'After Hours Contact'}
                      />
                      {errors?.fields?.[index]?.info_type && touched?.fields?.[index]?.info_type && (
                        <Alert status="error">
                          <AlertIcon />
                          <AlertDescription>{errors?.fields?.[index]?.info_type}</AlertDescription>
                        </Alert>
                      )}
                    </Box>
                    <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                      <FormLabel>Name*</FormLabel>
                      <Input name="fullname" value={field.fullname} onChange={e => handleChange(e, index)} />
                      {errors?.fields?.[index]?.fullname && touched?.fields?.[index]?.fullname && (
                        <Alert status="error">
                          <AlertIcon />
                          <AlertDescription>{errors?.fields?.[index]?.fullname}</AlertDescription>
                        </Alert>
                      )}
                    </Box>
                    <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                      <FormLabel>Mobile*</FormLabel>
                      <Input name="mobile" value={field.mobile} onChange={e => handleChange(e, index)} />
                      {errors?.fields?.[index]?.mobile && touched?.fields?.[index]?.mobile && (
                        <Alert status="error">
                          <AlertIcon />
                          <AlertDescription>{errors?.fields?.[index]?.mobile}</AlertDescription>
                        </Alert>
                      )}
                    </Box>
                    <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                      {field.info_type === 'After Hours Contact' ? (
                        <>
                          <FormLabel>Position</FormLabel>
                          <Input name="position" value={field.position} onChange={e => handleChange(e, index)} />
                          {errors?.fields?.[index]?.position && touched?.fields?.[index]?.position && (
                            <Alert status="error">
                              <AlertIcon />
                              <AlertDescription>{errors?.fields?.[index]?.position}</AlertDescription>
                            </Alert>
                          )}
                        </>
                      ) : (
                        <>
                          <FormLabel>Email</FormLabel>
                          <Input name="email" value={field.email} onChange={e => handleChange(e, index)} />
                          {errors?.fields?.[index]?.email && touched?.fields?.[index]?.email && (
                            <Alert status="error">
                              <AlertIcon />
                              <AlertDescription>{errors?.fields?.[index]?.email}</AlertDescription>
                            </Alert>
                          )}
                        </>
                      )}
                    </Box>
                  </Flex>
                  <Flex gap="20px" css={{ gap: '20px' }}>
                    {field.info_type === 'After Hours Contact' && (
                      <Box flexGrow={1} marginBottom="20px" flexBasis="25%" maxW="25%" paddingRight="20px">
                        <FormLabel>After Hours Time</FormLabel>
                        <Input name="after_hours" value={field.after_hours} onChange={e => handleChange(e, index)} />
                        {errors?.fields?.[index]?.after_hours && touched?.fields?.[index]?.after_hours && (
                          <Alert status="error">
                            <AlertIcon />
                            <AlertDescription>{errors?.fields?.[index]?.after_hours}</AlertDescription>
                          </Alert>
                        )}
                      </Box>
                    )}
                  </Flex>
                </Fragment>
              ))}
            </ModalBody>
            <ModalFooter borderTopWidth="1px" padding="16px 22px">
              <ButtonGroup marginLeft="auto">
                <Button type="button" fontSize="16px" width="126px" height="43px" onClick={() => setIsOpenPopup(false)}>
                  Close
                </Button>
                &nbsp;
                <Button
                  type="submit"
                  width="126px"
                  height="43px"
                  colorScheme="actionPrimary"
                  marginLeft="10px"
                  fontSize="16px"
                  loadingText="Saving..."
                  isLoading={isLoading}
                >
                  Save
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </Formik>
      </ModalContent>
    </Modal>
  );
};

UpdateContactInfo.propTypes = {
  isOpenPopup: PropTypes.bool,
  setIsOpenPopup: PropTypes.func,
  estId: PropTypes.number,
  editItem: PropTypes.shape({}) || null,
  updateList: PropTypes.func,
};
export default UpdateContactInfo;
