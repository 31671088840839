import axios from 'axios';

export const setRequestAuthToken = token => {
  axios.defaults.headers.common.Authorization = `Token ${token}`;
};

export const removeRequestAuthorizationToken = () => {
  delete axios.defaults.headers.common.Authorization;
};

export const setRequestContentType = apiUrl => {
  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.patch['Content-Type'] = 'application/json';
  axios.defaults.headers.delete['Content-Type'] = 'application/json';
};
