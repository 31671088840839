import { connect } from 'react-redux';
import BuyActivityStream from '../../components/buyActivity/BuyActivityStream';
import { getRecentPOActivity } from '../../actions/actions_buy_activity';
import { fetchUsers } from '../../actions/actions_users';
import { fetchBeefInputProducts } from '../../actions/actions_input_products';
import { validCLsSelector } from '../../reducers/reducer_input_products';
import { fetchGrinders } from '../../actions/actions_grinders';
import { fetchPackerPlants } from '../../actions/actions_packer_plants';

const mapStateToProps = state => {
  return {
    requesting: state.buyActivity.requestingRecentPOActivity,
    error: state.buyActivity.recentPOActivityError,
    recentPOFilters: state.buyActivity.recentPOFilters,
    recentPOs: state.buyActivity.recentPOs,
    users: state.users,
    cls: validCLsSelector(state),
    configs: state.configs,
    inputProducts: state.input_products,
    grinders: state.grinders,
    packerPlants: state.packer_plants,
    token: state.user.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRecentPOActivity: config => dispatch(getRecentPOActivity(config)),
    getUsers: () => dispatch(fetchUsers()),
    getInputProducts: () => dispatch(fetchBeefInputProducts()),
    dispatch,
    getCLs: () => dispatch(fetchBeefInputProducts()),
    getGrinders: () => dispatch(fetchGrinders()),
    getPackerPlants: () => dispatch(fetchPackerPlants())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyActivityStream);
