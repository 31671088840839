import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import Constants from '../../Constants';

export const kpiSlice = createSlice({
  name: 'kpi',
  initialState: {
    data: [],
    currentData: {},
  },
  reducers: {
    setKPIData: (state, action) => {
      state.data = action.payload;
    },
    setCurrentKPIData: (state, action) => {
      state.currentData[action.payload.slug] = action.payload;
    },
  },
});

// Actions.
export const { setKPIData, setCurrentKPIData } = kpiSlice.actions;

export const fetchAllKPIData = region => async dispatch => {
  const response = await api.get(`/articles?tags_all=KPI&limit=100${region ? `&region=${region}` : ''}`);
  const json = await Constants.handleErrors(response, dispatch);
  dispatch(setKPIData([...json]));
  return json;
};

export const fetchCurrentKPIData = slug => async dispatch => {
  const response = await api.get(`/articles/${slug}`);
  const json = await Constants.handleErrors(response, dispatch);
  dispatch(setCurrentKPIData({ ...json }));
  return json;
};

const recentLimit = 6;

// Selectors.
export const selectKPIRecentData = state => {
  // Recent 6 articles.
  return state.kpi.data.slice(0, recentLimit);
};

export const selectKPIArchiveData = state => {
  return state.kpi.data.slice(recentLimit);
};

export const selectCurrentKPIData = state => {
  return state.kpi.currentData;
};

// Reducers.
export default kpiSlice.reducer;
