import React from 'react';

const TicketList = ({ title, tickets, onReadMoreClick, onCloseClick }) => {
  return (
    <div>
      <div className="m-portlet__head">
        <div className="m-portlet__head-caption">
          <div className="m-portlet__head-title">
            <h3 className="m-portlet__head-text">{title}</h3>
          </div>
        </div>
      </div>
      <div style={{ padding: '20px', marginLeft: '20px' }}>
        <div className="row">
          <div className="col-sm-12">
            {tickets.length ? (
              <div className="row">
                {tickets.map((ticket, i) => {
                  return (
                    <div className="col-sm-3" style={{ marginTop: '20px' }} key={`ticket-${i}`}>
                      <h5>
                        <strong>Subject</strong>: {ticket.subject}
                      </h5>
                      <h6>
                        <strong>Requestor</strong>: {ticket.name} ({ticket.email})
                      </h6>
                      <hr />
                      <button
                        onClick={() => {
                          onReadMoreClick(ticket);
                        }}
                        className="btn btn-success btn-sm"
                      >
                        Read more
                      </button>
                      &nbsp;
                      {ticket.opened ? (
                        <button
                          onClick={() => {
                            onCloseClick(ticket);
                          }}
                          className="btn btn-danger btn-sm"
                        >
                          Close
                        </button>
                      ) : (
                        false
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <p>No tickets</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketList;
