export const MARKET_FOCUS = {
  DOMESTIC: 'Domestic',
  EXPORT: 'Export',
  ALL: 'All',
};

export const VOLUME = {
  COLD_STORE: 'Cold Store',
  DELIVERED: 'Delivered',
  ORDERED: 'Ordered',
};

export const GROUP_BY = {
  COUNTRY: 'Country',
  PURCHASE_TYPE: 'Purchase type',
  GRINDER: 'Grinder',
  PACKER_PLANT: 'Packer Plant',
  FAT_V_LEAN: 'Fat v Lean',
  CHEMICAL_LEAN: 'CL (Chemical Lean)',
  ANGUS_V_REGULAR: 'Angus v Regular',
  FRESH_V_FROZEN: 'Fresh v Frozen',
};

export const FREQUENCY = {
  WEEKLY: 'W',
  MONTHLY: 'M',
  QUARTERLY: 'Q',
  ANNUALLY: 'Y',
};

export const VOLUME_UNITS = {
  KG: 'kg',
  LOADS: 'Loads',
};

export const AGGREGATE_BY = {
  DOMESTIC_V_EXPORT: 'Domestic v Export',
  COUNTRY: 'Country',
};

export const TIME_MODE = {
  YEAR_ON_YEAR: 'YoY',
  HISTORICAL: 'Historical',
};

export const TIME_RANGE = {
  ONE_MONTH: '1M',
  THREE_MONTHS: '3M',
  SIX_MONTHS: '6M',
  ONE_YEAR: '1Y',
  MAX: 'MAX', // For max we go from 5 years ago to now.
  CUSTOM: 'Custom',
};
