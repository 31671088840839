import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Textarea,
  Input,
  IconButton,
} from '@chakra-ui/react';
import { Formik, useFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import api from '../../../api';
import DatePicker from '../../core/DatePicker';
import Constants from '../../../Constants';
import { actions } from '../../../reducers/reducer_establishment_details';
import Tooltip from '../_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const { requestEstblishmentDetailsEditListedStatus } = actions;

const AddHistory = ({ isOpenPopup, setIsOpenPopup, setData, estId, editItem }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const addEstablishmentHistory = async values => {
    const payload = JSON.parse(JSON.stringify(values));
    if (!payload.reinstated_date || payload.reinstated_date === 'Invalid date') {
      delete payload.reinstated_date;
    }

    try {
      setIsLoading(true);
      if (editItem) {
        const response = await api.patch(`/op-audit-reason/${editItem.id}`, payload);
        if (response.ok) {
          const json = await response.json();
          setData(prevState => prevState.map(item => (item.id === json.id ? json : item)));
          toast({
            title: 'Record updated successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        }
      } else {
        const response = await api.post('/op-audit-reason', { ...payload, est_id: estId });
        if (response.ok) {
          const json = await response.json();
          setData(prevState => [...prevState, json]);
          toast({
            title: 'Record added successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        }
      }
      const listedStatusResponse = await api.get(`/op-establishment-detail/${estId}`);
      if (listedStatusResponse.ok) {
        dispatch(requestEstblishmentDetailsEditListedStatus(await listedStatusResponse.json()));
      }
    } catch (error) {
      toast({
        title: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
      setIsOpenPopup(false);
    }
  };

  // const parseDateString = (value, originalValue) => {
  //   return originalValue ? moment(originalValue).startOf('day').toDate() : new Date();
  // };

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: {
      delisted_date: '',
      audit_failure: '',
      audit_reason: '',
      reinstated_date: '',
    },
    onSubmit: () => {
      const parsedValues = {
        ...values,
        delisted_date: moment(values.delisted_date).format('YYYY-MM-DD'),
        reinstated_date: moment(values.reinstated_date).format('YYYY-MM-DD'),
      };
      addEstablishmentHistory(parsedValues);
    },
    validationSchema: Yup.object().shape({
      delisted_date: Yup.date().required('Delisted Date is Required'),
      audit_failure: Yup.string().max(100).required('Audit Failure is Required'),
      audit_reason: Yup.string().max(100).required('Reason is Required'),
      reinstated_date: Yup.date()
        // .required('Re-instated Date is Required')
        .when('delisted_date', (delisted_date, schema) => {
          return delisted_date
            ? schema.min(delisted_date, 'Reinstasted date must be greater than Delisted date')
            : schema;
        }),
    }),
  });

  useEffect(() => {
    if (editItem) {
      setFieldValue('delisted_date', moment(editItem.delisted_date, Constants.DEFAULT_READ_DATE_FORMAT));
      setFieldValue('audit_failure', editItem.audit_failure);
      setFieldValue('audit_reason', editItem.audit_reason);
      setFieldValue('reinstated_date', moment(editItem.reinstated_date, Constants.DEFAULT_READ_DATE_FORMAT));
    }
  }, [editItem]);

  return (
    <Modal isOpen={isOpenPopup} onClose={() => setIsOpenPopup(false)} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent maxW="min(700px, 80%)">
        <ModalHeader borderBottomWidth="1px" fontSize="20px">
          {editItem ? 'Edit' : 'Add'}
        </ModalHeader>
        <ModalCloseButton />
        <Formik>
          <form onSubmit={handleSubmit}>
            <ModalBody padding="22px">
              <Box flexGrow={1} marginBottom="25px">
                <FormLabel>
                  Delisted Date&nbsp;
                  <Tooltip
                    content="Select the date when the document was officially removed from the list."
                    placement="right"
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <DatePicker
                  id="delisted_date"
                  value={moment(values.delisted_date, Constants.DEFAULT_READ_DATE_FORMAT)}
                  onChange={e => {
                    setFieldValue('delisted_date', e);
                  }}
                />
                {errors.delisted_date && touched.delisted_date && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>{errors.delisted_date}</AlertDescription>
                  </Alert>
                )}
              </Box>
              <Box flexGrow={1} marginBottom="25px">
                <FormLabel>
                  Audit Failure&nbsp;
                  <Tooltip content="Provide details of the audit failure." placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <Input name="audit_failure" value={values.audit_failure} onChange={handleChange} />
                {errors.audit_failure && touched.audit_failure && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>{errors.audit_failure}</AlertDescription>
                  </Alert>
                )}
              </Box>
              <Box flexGrow={1} marginBottom="25px">
                <FormLabel>
                  Reason&nbsp;
                  <Tooltip content="Provide the reason for the audit failure." placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <Textarea height="120px" value={values.audit_reason} onChange={handleChange} name="audit_reason" />
                {errors.audit_reason && touched.audit_reason && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>{errors.audit_reason}</AlertDescription>
                  </Alert>
                )}
              </Box>
              <Box flexGrow={1}>
                <FormLabel>
                  Re-instated Date&nbsp;
                  <Tooltip content="Select the date when the document was re-instated to the list" placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <DatePicker
                  id="reinstated_date"
                  value={moment(values.reinstated_date, Constants.DEFAULT_READ_DATE_FORMAT)}
                  onChange={e => {
                    setFieldValue('reinstated_date', e);
                  }}
                />
                {errors.reinstated_date && touched.reinstated_date && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>{errors.reinstated_date}</AlertDescription>
                  </Alert>
                )}
              </Box>
            </ModalBody>
            <ModalFooter borderTopWidth="1px" marginTop="26px" padding="16px 22px">
              <ButtonGroup marginLeft="auto">
                <Button type="button" fontSize="16px" width="126px" height="43px" onClick={() => setIsOpenPopup(false)}>
                  Close
                </Button>
                &nbsp;
                <Button
                  type="submit"
                  width="126px"
                  height="43px"
                  colorScheme="actionPrimary"
                  marginLeft="10px"
                  fontSize="16px"
                  loadingText="Saving..."
                  isLoading={isLoading}
                >
                  Save
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </Formik>
      </ModalContent>
    </Modal>
  );
};

AddHistory.propTypes = {
  isOpenPopup: PropTypes.bool,
  setIsOpenPopup: PropTypes.func,
  setData: PropTypes.func,
  estId: PropTypes.number,
  editItem: PropTypes.shape({}) || null,
};
export default AddHistory;
