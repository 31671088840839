import { ACCESS_LEVELS_ERROR } from '../actions/actions_access_levels';
import { LOGIN_ERROR, PASSWORD_RESET_ERROR, REQUEST_ACCESS_ERROR } from '../actions/actions_auth';
import { COLD_STORE_ERROR } from '../actions/actions_cold_stores';
import { CONFIG_ERROR } from '../actions/actions_config';
import { FEC_ERROR } from '../actions/actions_fec';
import { FORM_FIELD_ERROR } from '../actions/actions_form_fields';
import { GENERATED_DOCUMENT_ERROR } from '../actions/actions_generated_documents';
import { GRINDER_ERROR } from '../actions/actions_grinders';
import { BEEF_INPUT_PRODUCT_ERROR } from '../actions/actions_input_products';
import { BEEF_INPUT_INVENTORY_ITEMS_ERROR } from '../actions/actions_input_product_inventory_items';
import { BEEF_INPUT_INVENTORY_ITEMS_SEARCH_ERROR } from '../actions/actions_input_product_inventory_items_search';
import { BEEF_INPUT_INVENTORY_LEVELS_ERROR } from '../actions/actions_input_product_inventory_levels';
import { MAP_ERROR } from '../actions/actions_map';
import { OPTIMIZER_ERROR } from '../actions/actions_optimizer';
import { ORDER_ERROR } from '../actions/actions_orders';
import { ORGANISATION_ERROR } from '../actions/actions_organisations';
import { BEEF_OUTPUT_PRODUCT_ERROR } from '../actions/actions_output_products';
import { BEEF_OUTPUT_INVENTORY_ITEMS_ERROR } from '../actions/actions_output_product_inventory_items';
import { PACKER_ERROR } from '../actions/actions_packers';
import { PACKER_OFFERS_ERROR } from '../actions/actions_packer_offer';
import { PACKER_PLANT_ERROR } from '../actions/actions_packer_plants';
import { PORT_ERROR } from '../actions/actions_ports';
import { PRODUCTION_PLANNER_ERROR } from '../actions/actions_production_planner';
import { ROLE_ERROR } from '../actions/actions_roles';
import { SHIPPING_LINES_ERROR } from '../actions/actions_shipping_lines';
import { SUPPORT_ERROR } from '../actions/actions_support';
import { TRANSPORT_ERROR } from '../actions/actions_transport';
import { TRANSPORTER_ERROR } from '../actions/actions_transporters';
import { UOM_ERROR } from '../actions/actions_units_of_measure';
import { USER_ERROR } from '../actions/actions_users';
import { VOYAGE_ERROR } from '../actions/actions_voyage';
import { VOYAGE_LEG_ERROR } from '../actions/actions_voyage_legs';
import { CLOSE_ERROR } from '../actions/index';
import {
  NCR_ATTACHMENT_UPLOAD_ERROR,
  NCR_CREATION_ERROR,
  NCR_DELETE_ERROR,
  NCR_EDIT_ERROR,
  SEARCH_NCRS_ERROR,
} from '../actions/action_ncrs';
import {
  ADD_NCR_CHAT_ERROR,
  FETCH_NCR_CHAT_CHANNELS_ERROR,
  FETCH_NCR_CHAT_CHANNEL_MESSAGES_ERROR,
  SEND_NCR_CHAT_MESSAGE_ERROR,
} from '../actions/action_ncr_chat';
import { FETCH_NCR_CHAT_HISTORY_ERROR } from '../actions/action_ncr_history';

// import { CURRENT_AFFAIRS_ERROR, COVID19_AUS_ERROR } from '../actions/actions_current_affairs';

const error = (state = false, action) => {
  switch (action.type) {
    case ACCESS_LEVELS_ERROR:
    case REQUEST_ACCESS_ERROR:
    case LOGIN_ERROR:
    case GRINDER_ERROR:
    case BEEF_INPUT_INVENTORY_ITEMS_SEARCH_ERROR:
    case BEEF_INPUT_INVENTORY_ITEMS_ERROR:
    case BEEF_INPUT_INVENTORY_LEVELS_ERROR:
    case BEEF_INPUT_PRODUCT_ERROR:
    case OPTIMIZER_ERROR:
    case ORDER_ERROR:
    case ORGANISATION_ERROR:
    case BEEF_OUTPUT_INVENTORY_ITEMS_ERROR:
    case BEEF_OUTPUT_PRODUCT_ERROR:
    case PACKER_OFFERS_ERROR:
    case FORM_FIELD_ERROR:
    case PACKER_ERROR:
    case PRODUCTION_PLANNER_ERROR:
    case ROLE_ERROR:
    case TRANSPORT_ERROR:
    case USER_ERROR:
    case MAP_ERROR:
    case SUPPORT_ERROR:
    case PASSWORD_RESET_ERROR:
    case CONFIG_ERROR:
    case PACKER_PLANT_ERROR:
    case UOM_ERROR:
    case SHIPPING_LINES_ERROR:
    case PORT_ERROR:
    case VOYAGE_LEG_ERROR:
    case VOYAGE_ERROR:
    case COLD_STORE_ERROR:
    case FEC_ERROR:
    case TRANSPORTER_ERROR:
    case GENERATED_DOCUMENT_ERROR:
    case NCR_CREATION_ERROR:
    case NCR_EDIT_ERROR:
    case SEARCH_NCRS_ERROR:
    case NCR_ATTACHMENT_UPLOAD_ERROR:
    case NCR_DELETE_ERROR:
    case ADD_NCR_CHAT_ERROR:
    case FETCH_NCR_CHAT_CHANNELS_ERROR:
    case SEND_NCR_CHAT_MESSAGE_ERROR:
    case FETCH_NCR_CHAT_CHANNEL_MESSAGES_ERROR:
    case FETCH_NCR_CHAT_HISTORY_ERROR:
      return `${action.payload} (${action.type})`;
    case CLOSE_ERROR:
      return false;
    default:
      return state;
  }
};

export default error;
