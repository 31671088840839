const IconButton = {
  // 2. We can add a new button size or extend existing
  sizes: {
    lg: {
      fontSize: 'lg',
      py: '14px',
    },
    md: {
      fontSize: 'md',
      p: '12px',
    },
    sm: {
      fontSize: 'sm',
      p: '8px',
    },
  },
  // 3. We can add a new visual variant
  variants: {
    solid: {
      _hover: {
        bg: 'pink',
      },
    },
  },
};

export default IconButton;
