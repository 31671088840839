import Constants from '../Constants';

export const RESET_NCR_HISTORY = 'RESET_NCR_HISTORY';
export const FETCH_NCR_CHAT_HISTORY_SUCCESS = 'FETCH_NCR_CHAT_HISTORY_SUCCESS';
export const FETCH_NCR_CHAT_HISTORY_ERROR = 'FETCH_NCR_CHAT_HISTORY_ERROR';
export const FETCH_INCIDENT_CHAT_HISTORY_SUCCESS = 'FETCH_INCIDENT_CHAT_HISTORY_SUCCESS';
export const FETCH_INCIDENT_CHAT_HISTORY_ERROR = 'FETCH_INCIDENT_CHAT_HISTORY_ERROR';

export function resetNCRHistory() {
  return {
    type: RESET_NCR_HISTORY,
  };
}
export function fetchNCRHistorySuccess(channels) {
  return {
    type: FETCH_NCR_CHAT_HISTORY_SUCCESS,
    payload: channels,
  };
}

export function fetchNCRHistoryError() {
  return {
    type: FETCH_NCR_CHAT_HISTORY_ERROR,
  };
}

export function fetchIncidentHistorySuccess(channels) {
  return {
    type: FETCH_INCIDENT_CHAT_HISTORY_SUCCESS,
    payload: channels,
  };
}

export function fetchIncidentHistoryError() {
  return {
    type: FETCH_INCIDENT_CHAT_HISTORY_ERROR,
  };
}

export function fetchNCRHistory(paramsObject, token) {
  return async dispatch => {
    dispatch(resetNCRHistory());
    const { recordNo } = paramsObject;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    let params = '';
    if (paramsObject) params = new URLSearchParams(paramsObject).toString();

    try {
      const response = await fetch(`${Constants.URL}grinder_ncr/${recordNo}/history?${params}`, {
        method: 'GET',
        headers: header,
      });
      const history = await Constants.handleErrors(response, dispatch, fetchNCRHistoryError);
      dispatch(fetchNCRHistorySuccess(history));
    } catch (error) {
      dispatch(fetchNCRHistoryError(error));
    }
    return null;
  };
}

export function fetchIncidentHistory(paramsObject, token){
  return async dispatch => {
   dispatch(resetNCRHistory()) 
   const { recordNo } = paramsObject;
   const header = new Headers({
     'Content-Type': 'application/json',
     Authorization: `Token ${token}`,
   });   
   
   let params = '';
   if (paramsObject) params = new URLSearchParams(paramsObject).toString();

   try {
    // http://localhost:8000/api/incidents/285/history
    const response = await fetch(`${Constants.URL}incidents/${recordNo}/history?${params}`, {
      method: 'GET',
      headers: header,
    });
    const history = await Constants.handleErrors(response, dispatch, fetchNCRHistoryError);
    dispatch(fetchIncidentHistorySuccess(history));
   }
   catch(error) {
    dispatch(fetchIncidentHistoryError(error));
   }

   return null;
  }
}
