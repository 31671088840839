import React from 'react';

import { formatPercent, formatSI } from '../../../../modules/format';
import BaseChart from '../BaseChart/BaseChart';
import DataTable from '../DataTable/DataTable';
import AnnotationLayer from '../../../../shared/AnnotationLayer/AnnotationLayer';
import { format } from 'date-fns';

const colors = {
  Formula: '#1CA6B2',
  Spot: '#F01D82',
  Contract: '#805CFF',
  NOF: '#F2C94C',
  'Financial derivative': '#00B5F2',
  'Frozen product usage': '#FC5D36',
  Hedged: '#006df2',
  Exposed: '#FF9A3D',
  Fat: '#545B72',
  Lean: '#AB2424',
  Total: '#029916',
};

const columns = ['Formula', 'Spot', 'Contract', 'NOF', 'Financial derivative', 'Frozen product usage'];

const breakdownArrayToObject = (breakdown, volumeType) =>
  breakdown.reduce(
    (acc, n) => ({
      ...acc,
      [n.orderType]: n[volumeType] || 0,
      [`${n.orderType}-forecast`]: n[`forecasted${volumeType.charAt(0).toUpperCase() + volumeType.slice(1)}`] || 0,
    }),
    {}
  );

export default function VolumeChart(props) {
  const {
    riskManagementData,
    splitIndex,
    addNewAnnotation,
    editAnnotation,
    moduleAnnotations,
    annotationIntervalFormat,
    isLarge,
    strategies,
    volumeType,
    withDataTable,
    withMultiOptionTable,
    forceColor,
  } = props;

  let maxVal = 0;
  const barData = riskManagementData.map(({ interval, rawInterval, annotation, breakdown }) => {
    const vol = volumeType === 'percent' ? 'volumeRatio' : 'volume';
    return {
      interval,
      rawInterval,
      annotation,
      ...breakdownArrayToObject(breakdown, vol),
    };
  });

  barData.forEach(x => {
    const max = Math.max(...Object.values(x).filter(y => typeof y === 'number'));
    if (max > maxVal) maxVal = Math.round(max * 10) / 10;
  });

  return (
    <div style={{ display: 'flex' }}>
      <BaseChart
        barData={barData}
        isLarge={isLarge}
        splitIndex={splitIndex}
        colors={forceColor || colors}
        columns={strategies || columns}
        volumeType={volumeType}
        formatValues={volumeType === 'percent' ? formatPercent(0) : formatSI(0)}
        ticksNumber={7}
        futureDataKey="forecast"
        tableContent={chartProps => {
          return withDataTable && <DataTable {...chartProps} multiOption={withMultiOptionTable} />;
        }}
        AnnotationLayer={p => {
          if (!p.scales) return <div />;
          return (
            <AnnotationLayer
              data={p.barData}
              xScale={p.scales.x}
              splitIndex={p.splitIndex}
              addNewAnnotation={addNewAnnotation}
              editAnnotation={editAnnotation}
              moduleAnnotations={moduleAnnotations}
              getXValue={d => {
                return format(d.timestamp, annotationIntervalFormat);
              }}
            />
          );
        }}
      />
    </div>
  );
}
