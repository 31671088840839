import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  HStack,
} from '@chakra-ui/react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { convertToCamelCase } from '../../../helpers';
import { get } from 'lodash';
import moment from 'moment';

const ShowConnectedIncidents = ({
    onCloseModal,
    recordNo,
    connectIncidents,
}) => {
    const tableCols = [
        {
            key: 'recordNo',
            label: 'Case ID'
        },
        {
            key: 'nonConformanceSubType[0].category',
            label: 'Category'
        },
        {
            key: 'status',
            label: 'status'
        },
        {
            key: 'entryDate',
            label: 'Date Raised'
        },
        {
            key: 'modified',
            label: 'Last Update'
        },
        {
            key: '',
            label: 'Action'
        }                                        
    ]
    const incidents = get(connectIncidents,'incidents',[])
  return (
    <Modal isCentered isOpen onClose={onCloseModal}>
      <ModalOverlay />
      <ModalContent minWidth="1000px">
        {/* <ModalHeader borderBottomWidth="1px">
          <Heading fontSize="20px ">Incident {recordNo}</Heading>
        </ModalHeader> */}
        <ModalCloseButton />
        <ModalBody padding="25px 52px">
        <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            {tableCols.map((col, index) => {
              return (
                <Th
                  key={col.key}
                  padding="12px 6px"
                >
                  <HStack spacing="3px">
                    <Text fontSize="12px">{col.label}</Text>
                  </HStack>
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody backgroundColor="#ffffff" fontSize="12px">
        {incidents.map((col, colIndex) => {
                return (
                    <Tr>
                        {tableCols.map((val, index) => {
                            let element = null;
                            if (['entryDate','modified'].includes(val.key)) {
                              let date = get(col, val.key,'-')
                              element = (moment(date).isValid() ? moment(date).format('DD-MM-YYYY') : '-')
                            } else if (val.key !== '') {
                              element = get(col, val.key,'-')
                            }else {
                              element = (
                                <Link to={`/incidents/${get(col, 'recordNo','')}`} onClick={onCloseModal}>
                                  <Button marginLeft="20px" colorScheme="actionSecondary" width="57px" height="28px" borderRadius="24px">
                                      Open
                                  </Button>
                                </Link> 
                            )
                            }
                            return (
                                <Td key={index}>{element}</Td>
                            )
                        })}
                    </Tr>                    
                )
            })}
        </Tbody>
        </Table>
        </TableContainer>
        </ModalBody>
        <ModalFooter borderTopWidth="1px">
          <ButtonGroup colorScheme="actionPrimary" marginLeft="auto" fontSize="16px" fontWeight="900" spacing="9px">
            <Button
              variant="outline"
              fontSize="inherit"
              fontWeight="inherit"
              height="40px"
              width="130px"
              onClick={onCloseModal}
            >
              Close
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ShowConnectedIncidents.propTypes = {
    recordNo: PropTypes.number,
    onCloseModal: PropTypes.func
};

const mapStateToProps = state => {
  const result = {
  };
  return convertToCamelCase(result);
};

export default connect(mapStateToProps)(ShowConnectedIncidents);
