import * as React from 'react';
import styles from './NCRs.module.css';
import BubbleChart from '../../../../shared/BubbleChart/BubbleChart';
import { Radio, Typography } from 'antd';
import { formatDollars, formatSI } from '../../../../modules/format';
import ThisPeriod from './ThisPeriod';

const Comparison = props => {
  const { data, singlePackerPlant, packerPlantsInPacker, timeScale, timePeriod } = props;

  const [dataType, setDataType] = React.useState('count');

  const dataSorted = data.sort((a, b) => a[dataType] - b[dataType]);
  const rank =
    singlePackerPlant && !singlePackerPlant.isPacker
      ? dataSorted.findIndex(x => x.packerPlantName === singlePackerPlant.name) + 1
      : undefined;

  const formatDollarsSeparate = value => {
    return (
      <>
        <span className={styles.QualityNcrs_dollar}>$</span>
        {formatDollars(2)(value).replace('$', '')}
      </>
    );
  };

  const multiHighlights = {};

  if (packerPlantsInPacker) {
    packerPlantsInPacker.forEach(x => {
      multiHighlights[x.packerPlantName] = x.color;
    });
  }

  const topList = packerPlantsInPacker && packerPlantsInPacker.filter(x => x.count === 0);

  let noIncidents = false;
  if (topList && packerPlantsInPacker && topList.length === packerPlantsInPacker.length) {
    noIncidents = true;
  }

  const bottomList =
    packerPlantsInPacker && topList && [...packerPlantsInPacker].slice(0, packerPlantsInPacker.length - topList.length);

  while (bottomList && bottomList.length > 5) {
    const pp = bottomList.pop();
    if (topList.length < 5) {
      topList.push(pp);
    }
  }

  return (
    <div className={styles.QualityNcrs_comparison}>
      <div className={styles.QualityNcrs_byCategoryTitle}>
        <Typography.Text className={styles.QualityNcrs_label}>
          {packerPlantsInPacker ? `overall rankings` : `comparison against other establishments with incidents`}
        </Typography.Text>
        <Radio.Group
          onChange={e => {
            setDataType(e.target.value);
          }}
          defaultValue={dataType}
          size="medium"
          className={styles.QualityNcrs_radio}
        >
          <Radio.Button key={1} value={'count'}>
            Count
          </Radio.Button>
          <Radio.Button key={2} value={'claimAmount'}>
            Claim amount
          </Radio.Button>
        </Radio.Group>
      </div>
      {!noIncidents && packerPlantsInPacker && packerPlantsInPacker.length > 0 && (
        <div style={{ margin: '10px 0px' }}>
          {topList.length > 0 && (
            <div>
              <span style={{ opacity: 0.6 }}>Top:</span> {topList.map(x => x.packerPlantName).join(', ')}
            </div>
          )}
          {bottomList.length > 0 && (
            <div>
              <span style={{ opacity: 0.6 }}>Bottom:</span>{' '}
              {bottomList
                .slice(0, 5)
                .map(x => x.packerPlantName)
                .join(', ')}
            </div>
          )}
        </div>
      )}
      {rank ? (
        <p className={styles.QualityNcrs_comparisonRank}>
          Rank{' '}
          <strong>
            {rank} out of {dataSorted.length}
          </strong>{' '}
          establishments
        </p>
      ) : (
        <p className={styles.QualityNcrs_comparisonRank}>
          <strong>{dataSorted.length}</strong> establishments
        </p>
      )}
      {!noIncidents && data.length > 0 ? (
        <BubbleChart
          data={data.map(x => ({
            ...x,
            value: x[dataType],
          }))}
          format={dataType === 'count' ? formatSI(1) : formatDollarsSeparate}
          isSmall
          highlights={
            !!packerPlantsInPacker ? multiHighlights : singlePackerPlant && { [singlePackerPlant.name]: '#2C333D' }
          }
          withEmptyBubbles={!!packerPlantsInPacker}
          getColor={x => '#2C333D'}
          formatTooltip={x =>
            `${x.packerPlantName} - ${dataType === 'count' ? formatSI(1)(x[dataType]) : formatDollars(2)(x[dataType])}`
          }
          nameField="packerPlantName"
          withIntervals
        />
      ) : (
        <p style={{ marginTop: '10px' }}>
          No incidents reported yet in <ThisPeriod timeScale={timeScale} timePeriod={timePeriod} />
        </p>
      )}
    </div>
  );
};

export default Comparison;
