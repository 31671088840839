import Icon from '@ant-design/icons';
import { Radio, Select, Tooltip, Tree } from 'antd';
import Text from 'antd/lib/typography/Text';
import { group, max, mean, scaleLinear } from 'd3';
import { format } from 'date-fns';
import React from 'react';
import startCase from 'lodash/startCase';
import COLORS, { COLORS_RGB } from '../../../../modules/colors';
import { WEIGHT_UNIT } from '../../../../modules/const';
import { formatMarketQuantity, formatPercent } from '../../../../modules/format';
import { ChevronDown } from '../../../../shared/Icons';
import { SUPPLY_CHAIN_VIEW_MODE } from '../../../../slices/markets/marketsConfig';
import styles from './DataTable.module.css';

const UNITS = {
  UNIT: 'unit',
  PERCENT: 'percent',
};

const DETAIL = {
  SCHEDULE: 'schedule',
  TOTALS: 'totals',
};

const viewModeOptions = Object.entries(SUPPLY_CHAIN_VIEW_MODE).map(([key, value]) => ({
  // Capitalize
  label: startCase(value),
  value,
}));

export default function DataTable(props) {
  const {
    marketSupplyChainData,
    controlState,
    onHoveredLocationChange,
    hoveredLocationId,
    activeMarket,
    onViewModeChange,
    incidents,
  } = props;

  const data =
    controlState.viewMode === SUPPLY_CHAIN_VIEW_MODE.GRINDERS
      ? marketSupplyChainData.grinders
      : marketSupplyChainData.packerPlants;

  const dataTotal = data.reduce((a, b) => a + b.totalVolume, 0);

  const [detail, setDetail] = React.useState(DETAIL.TOTALS);
  const [units, setUnits] = React.useState(UNITS.UNIT);

  const scheduleVis = row => {
    if (!row.schedule) {
      return <div />;
    }
    const grouped = group(row.schedule, x => format(x.date, 'EEE'));
    const res = Array.from(grouped.entries()).map(([day, dayVolumes]) => {
      return {
        date: day,
        volume: mean(dayVolumes, d => d.volume),
      };
    });
    const blockWidthPercent = 1 / res.length;
    const maxVolume = max(res, d => d.volume);
    const heightScale = scaleLinear().range([0, 15]).domain([0, maxVolume]);

    // TODO: colour based on incidents.
    const color = '#0B1435';

    const scheduleBlocks = res.map(day => {
      return (
        <div
          key={day.date.toString()}
          className={styles.scheduleVisBlock}
          style={{
            width: `${blockWidthPercent * 100}%`,
            height: heightScale(day.volume),
            backgroundColor: color,
            opacity: 0.8,
          }}
        />
      );
    });

    return (
      <Tooltip title="Average per day Monday - Sunday">
        <div className={styles.schedule}>
          <div className={styles.scheduleVis}>{scheduleBlocks}</div>
        </div>
      </Tooltip>
    );
  };

  const sourceVis = (row, total) => {
    const percentOfSupply = (row.totalVolume || row.volume) / total;
    const sourceBlock = (
      <div
        key={row.id}
        className={styles.sourceVisBlock}
        style={{
          width: `${percentOfSupply * 100}%`,
          minWidth: '4px',
          backgroundColor: '#0B1435',
          opacity: row.totalVolume !== undefined ? 0.6 : 0.4,
        }}
      />
    );

    return (
      <div className={styles.source}>
        <div className={styles.sourceVis}>{sourceBlock}</div>
      </div>
    );
  };

  const getTreeData = data => {
    if (!data) {
      return [];
    }
    const row = (d, isSecondary, totalVolume, color) => (
      <div className={styles.tableRow} style={{ marginLeft: isSecondary ? -24 : 0 }}>
        <div
          style={{
            width: '40%',
            padding: '0 5px',
            paddingLeft: isSecondary ? 24 : 0,
          }}
        >
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: d.id === hoveredLocationId ? 'bold' : 'normal',
            }}
            onMouseEnter={() => {
              onHoveredLocationChange(d.id);
            }}
            onMouseLeave={() => {
              onHoveredLocationChange(null);
            }}
          >
            <div
              className={styles.entity}
              style={{
                backgroundColor: color,
              }}
            />
            {d.name}
          </div>
        </div>
        <div style={{ width: '40%', padding: '0 5px' }}>
          <div>
            {detail === DETAIL.SCHEDULE ? (
              <div style={{ width: '100%' }}>{scheduleVis(d)}</div>
            ) : (
              <div style={{ width: '100%' }}>{sourceVis(d, totalVolume)}</div>
            )}
          </div>
        </div>
        <div style={{ width: '20%', padding: '0 5px' }}>
          <Text>
            {units === UNITS.UNIT
              ? formatMarketQuantity(activeMarket, 1, 1)(d.totalVolume || d.volume)
              : formatPercent(2)((d.totalVolume || d.volume) / totalVolume)}
          </Text>
        </div>
      </div>
    );

    const isAffectedByIncident = id => {
      if (!incidents) return false;
      return incidents.some(
        inc =>
          inc.parties_involved.packer_plants.some(p => p.packer_plant_id === id) ||
          inc.parties_involved.grinders.some(p => p.grinder_id === id)
      );
    };

    return data.map((d, i) => {
      return {
        title: row(
          d,
          false,
          dataTotal,
          isAffectedByIncident(d.id)
            ? `rgb(${COLORS_RGB.INCIDENT.join(',')})`
            : controlState.viewMode === SUPPLY_CHAIN_VIEW_MODE.PACKER_PLANTS
            ? COLORS.PACKER_PLANT
            : COLORS.GRINDER
        ),
        key: `${i}-${d.id}`,
        children: d.orders.map((o, j) => {
          return {
            title: row(
              o,
              true,
              dataTotal,
              isAffectedByIncident(o.id)
                ? `rgb(${COLORS_RGB.INCIDENT.join(',')})`
                : controlState.viewMode !== SUPPLY_CHAIN_VIEW_MODE.PACKER_PLANTS
                ? COLORS.PACKER_PLANT
                : COLORS.GRINDER
            ),
            key: `${i}-${j}-${o.id}`,
          };
        }),
      };
    });
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: `calc(100% - 24px)`,
          marginLeft: `24px`,
        }}
      >
        <div className={styles.table}>
          <div className={styles.title}>
            View by
            <div>
              <Select
                value={controlState.viewMode}
                style={{ width: '100%', textTransform: 'none' }}
                onChange={onViewModeChange}
              >
                {viewModeOptions.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.title}>
            Detail
            <div style={{ textTransform: 'none' }}>
              <Radio.Group value={detail} onChange={e => setDetail(e.target.value)}>
                <Radio.Button value={DETAIL.SCHEDULE} checked={detail === DETAIL.SCHEDULE}>
                  Schedule
                </Radio.Button>
                <Radio.Button value={DETAIL.TOTALS} checked={detail === DETAIL.TOTALS}>
                  Totals
                </Radio.Button>
              </Radio.Group>
            </div>
          </div>
        </div>
        <div className={styles.tableSmall}>
          <div className={styles.title}>
            Units
            <div style={{ textTransform: 'none' }}>
              <Radio.Group value={units} onChange={e => setUnits(e.target.value)}>
                <Radio.Button value={UNITS.UNIT} checked={units === UNITS.UNIT}>
                  {activeMarket.quantityUnit === WEIGHT_UNIT.KILOGRAM ? 'kg' : 'lb'}
                </Radio.Button>
                <Radio.Button value={UNITS.PERCENT} checked={units === UNITS.PERCENT}>
                  %
                </Radio.Button>
              </Radio.Group>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Tree
          switcherIcon={<Icon component={ChevronDown} style={{ opacity: 0.6 }} />}
          selectable={false}
          treeData={getTreeData(data)}
          blockNode
        />
      </div>
    </div>
  );
}
