import Blob from 'blob';
import { saveAs } from 'file-saver';
import { s2ab, sheetFromArrayOfArrays, Workbook } from '../functions';

export const generateAndDownloadExcel = (filename, worksheetConfigs) => {
  const excelWorkbook = new Workbook();

  worksheetConfigs.forEach(wsConfig => {
    const { worksheetName, columnConfigs, data } = wsConfig;

    const headerRow = columnConfigs.map(({ headerText }) => headerText);

    const dataRows = data.map(dataObj => {
      return columnConfigs.map(({ getter }) => {
        try {
          return getter(dataObj);
        } catch (e) {
          return '';
        }
      });
    });

    const excelWorksheet = sheetFromArrayOfArrays([headerRow, ...dataRows]);

    excelWorkbook.SheetNames.push(worksheetName);
    excelWorkbook.Sheets[worksheetName] = excelWorksheet;
  });

  const aboutWorkbook = window.XLSX.write(excelWorkbook, {
    bookType: 'xlsx',
    // bookSST is slower and more memory intensive, but has better compatibility with older versions of iOS Numbers
    bookSST: false,
    type: 'binary',
  });
  saveAs(new Blob([s2ab(aboutWorkbook)], { type: 'application/octet-stream' }), `${filename}.xlsx`);
};
