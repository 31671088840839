import Constants from '../Constants';

export const TRANSPORTERS_FETCHED = 'TRANSPORTERS_FETCHED';
export const TRANSPORTER_ADDED = 'TRANSPORTER_ADDED';
export const TRANSPORTER_EDIT = 'TRANSPORTER_EDIT';
export const TRANSPORTER_REMOVED = 'TRANSPORTER_REMOVED';
export const TRANSPORTER_ERROR = 'TRANSPORTER_ERROR';

export function fetchTransporters(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}transporters`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorTransporter))
      .then(json => dispatch(loadTransporters(json)))
      .catch(error => dispatch(errorTransporter(error)));
  };
}

export function postTransporter(transporter, token) {
  return dispatch => {
    delete transporter.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}transporters`, {
      method: 'POST',
      body: JSON.stringify(transporter),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorTransporter))
      .then(json => dispatch(addTransporter(json)))
      .catch(error => dispatch(errorTransporter(error)));
  };
}

export function patchTransporter(transporter, token) {
  return dispatch => {
    delete transporter.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}transporters/${transporter.id}`, {
      method: 'PATCH',
      body: JSON.stringify(transporter),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorTransporter))
      .then(json => dispatch(editTransporter(json)))
      .catch(error => dispatch(errorTransporter(error)));
  };
}

export function deleteTransporter(transporter, token) {
  return dispatch => {
    delete transporter.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}transporters/${transporter.id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorTransporter))
      .then(json => dispatch(removeTransporter(transporter)))
      .catch(error => dispatch(errorTransporter(error)));
  };
}

export function loadTransporters(transporters) {
  return {
    type: TRANSPORTERS_FETCHED,
    payload: transporters,
  };
}

export function addTransporter(transporter) {
  return {
    type: TRANSPORTER_ADDED,
    payload: transporter,
  };
}

export function removeTransporter(transporter) {
  return {
    type: TRANSPORTER_REMOVED,
    payload: transporter,
  };
}

export function editTransporter(transporter) {
  return {
    type: TRANSPORTER_EDIT,
    payload: transporter,
  };
}

export function errorTransporter(error) {
  return {
    type: TRANSPORTER_ERROR,
    payload: error,
  };
}
