import { connect } from 'react-redux';
import Covid19AusComponent from '../../components/pages/CovidAus/Covid19Aus';

const mapStateToProps = state => {
  return {
    token: state.user.token,
    aus_covid19_data: state.current_affairs,
    user: state.user,
    error: state.error,
  };
};
const Covid19Aus = connect(mapStateToProps)(Covid19AusComponent);

export default Covid19Aus;
