const schema = [
  {
    name: 'freight_rate',
    displayName: 'Freight Rate',
    systemIdField: 'id',
    displayIdField: 'packer_plant_id',
    path: '/transport',
    tabGroup: { key: 'pricing', label: 'Pricing' },
    deletable: false,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'packer_plant_id',
        displayName: 'Establishment',
        tooltip: 'Select the Establishments of the Freight Rate',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'packer_plant',
        required: true,
        filterable: true,
      },
      {
        name: 'grinder_uid',
        displayName: 'Grinder',
        tooltip: 'Select the grinder associated with the freight rate',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'grinder',
        required: true,
        filterable: true,
      },
      {
        name: 'cost',
        displayName: 'Cost',
        tooltip: 'Amount charged for the freight service',
        dataType: 'number',
        required: true,
      },
      {
        name: 'miles',
        displayName: 'Miles',
        tooltip: 'Distance in miles for which the freight rate applies',
        dataType: 'number',
        required: false,
      },
      {
        name: 'delivery_date_from',
        displayName: 'Delivery Date From',
        tooltip: 'Start date from which the delivery is expected to begin',
        dataType: 'date',
        required: false,
      },
      {
        name: 'delisted',
        displayName: 'Is Delisted',
        dataType: 'boolean',
      },
    ],
  },
];
export default schema;
