import React, { useState, useEffect } from 'react';
import {  Button, Text, VStack, HStack, Wrap, IconButton, Input } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import SelectField from '../../basic/SelectField';
import CustomFormControl from '../../core/CustomFormControl';
import { AddIcon, ChevronDownIcon, ChevronRightIcon, SmallCloseIcon } from '@chakra-ui/icons';

import POFormFieldLabel from './edit-form/POFormFieldLabel';

function Temperatures({isInEditMode, line, lineIndex, handleTemperatureChanges}) {
    const [expandedDetails, setExpandedDetails] = React.useState(false);
    const [type, setType] = useState(line.opTempType ||  'bin'); // 'bin','carton' or 'batch'
    const [defaultTypes, setDefaultTypes] = useState([
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
    { value: null },
  ]);
  const [extraTypes, setExtraTypes] = useState([]);

  useEffect(() => {
    if (
      line.temperatures &&
      line.temperatures.length > 0
    ) {
      setType(line.opTempType || 'bin');
      const defaultTypesTemp = line.temperatures.slice(0, 22);
      const extraTypesTemp = line.temperatures.slice(22);

      if (defaultTypesTemp.length > 0) {
        setDefaultTypes(
          defaultTypesTemp.map((item) => ({
            id: item?.id,
            value: item?.temperature,
          })),
        );
      }

      if (extraTypesTemp.length > 0) {
        setExtraTypes(
          extraTypesTemp.map((item, index) => ({
            id: item?.id,
            localId: index + 1,
            value: item?.temperature,
          })),
        );
      }
    }
  },[]);

  useEffect(() => {
    handleTemperatureChanges(lineIndex, 'temperatures',  [...defaultTypes, ...extraTypes]);
  },[defaultTypes, extraTypes]);

  const handleDefaultTypeValue = (value,index) => {
    setDefaultTypes((prev) => {
      const newTypes = [...prev];
      newTypes[index].value = value ? value : null;
      return newTypes;
    });
  }; 
  
  const handleExtraTypeValue = (value, id) => {
    setExtraTypes((prev) =>
      prev.map((item) => {
        if (item.localId === id) {
          item.value = value ? value : null;
        }

        return item;
      }),
    );
  };  

  const addExtraType = () => {
    if (extraTypes.length <= 7) {
      setExtraTypes((prev) => [
        ...prev,
        {
          localId: prev.length > 0 ? prev[prev.length - 1].localId + 1 : 1,
          value: null,
        },
      ]);
    }
  }; 
  
  const handleTempTypeChange = (value) => {
    setType(value);
    handleTemperatureChanges(lineIndex, 'opTempType', value);
  }

    return (
        <VStack align="stretch" spacing="30px" >
        <HStack justifyContent="space-between" color="accent.one.default" width="143px">
          <Text as="p" fontWeight="medium" color="inherit">
            Temperatures
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="24px"
            icon={expandedDetails ? <ChevronDownIcon /> : <ChevronRightIcon />}
            onClick={() => setExpandedDetails(!expandedDetails)}
          />
        </HStack>
        {expandedDetails && (
          <>
          <Wrap spacing="56px" paddingX="10px" width="100%">
          <CustomFormControl width="200px">
            <POFormFieldLabel label="Type" htmlFor="tempType" className="international-order-line__label" />
            <SelectField
              isDisabled={!isInEditMode}
              name="tempType"
              defaultValue={type}
              options={[
                { label: 'Bin', value: 'bin' },
                { label: 'Carton', value: 'carton' },
                { label: 'Batch', value: 'batch' },
              ]}
              onChange={e => handleTempTypeChange(e.value)}
              controlStyles={{ height: '38px' }}
            />
          </CustomFormControl>
        </Wrap> 
        <Wrap spacing="10px" paddingX="10px" width="100%">
              {defaultTypes.map((item, index) => (
                <CustomFormControl width="19%" key={index}>
                  <POFormFieldLabel label={`${type[0].toUpperCase() + type.slice(1)} ${index + 1}`} htmlFor={`temp${index}`} className="international-order-line__label" />
                  <Input
                    height="40px"
                    type="number"
                    disabled={!isInEditMode}
                    value={item.value || ''}
                    onChange={e => {
                      const newTypes = [...defaultTypes];
                      newTypes[index].value = e.target.value;
                      handleDefaultTypeValue(e.target.value, index);
                    }}
                  />
                </CustomFormControl>
              ))}
              {extraTypes.map((item, index) => (
                <CustomFormControl width="19%" key={index}>
                <POFormFieldLabel label={`${type[0].toUpperCase() + type.slice(1)} ${index + defaultTypes.length + 1}`} htmlFor={`temp${index}`} className="international-order-line__label" />
                <Input
                  height="40px"
                  type="number"
                  disabled={!isInEditMode}
                  value={item.value || ''}
                  onChange={e => {
                    handleExtraTypeValue(e.target.value, item.localId);
                  }}
                />
              <IconButton
                sx={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  colorScheme:"actionPrimary",
                  position: 'absolute',
                  right: '-17px',
                  top: '17px',
                }}
                disabled={!isInEditMode}
                onClick={() => {
                  setExtraTypes((prev) =>
                    prev.filter((itm) => itm.localId !== item.localId),
                  );
                }}
              >
                <SmallCloseIcon />
              </IconButton>                
              </CustomFormControl>
                              
              )
              )}
            <Button
              disabled={!isInEditMode}
              onClick={addExtraType}
              marginTop="auto!important"
            >
            <AddIcon />
          </Button>              
        </Wrap>  
        </>         
        )}
        </VStack>            
    )
}

Temperatures.propTypes = {
    temperatures: PropTypes.arrayOf(PropTypes.object),
    isInEditMode: PropTypes.bool.isRequired,
};


export default Temperatures;