import { connect } from 'react-redux';

import ConfirmPurchaseOrderModalComponent from '../../components/packers/confirm_packer_offer/ConfirmPackerOfferModal';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

const mapStateToProps = state => {
  return {
    ...findEntitiesInState(state),
    transport: state.transport.transport_items,
    user: state.user,
    token: state.user.token,
  };
};

const ConfirmPurchaseOrderModal = connect(mapStateToProps)(ConfirmPurchaseOrderModalComponent);

export default ConfirmPurchaseOrderModal;
