// see: https://stackoverflow.com/questions/32673760/how-can-i-know-if-a-given-string-is-hexValue-rgb-rgba-or-hsl-color-using-javascript
export const checkValidColor = color => {
  let e = document.getElementById('divValidColor');
  if (!e) {
    e = document.createElement('div');
    e.id = 'divValidColor';
  }
  e.style.borderColor = '';
  e.style.borderColor = color;
  const tempColor = e.style.borderColor;
  if (tempColor.length === 0) {
    return false;
  }
  return true;
};

export const splitRgbAndHslValues = color => {
  return color.replace(/[^\d,.%]/g, '').split(',');
};

// https://stackoverflow.com/questions/2353211/hsl-to-rgb-color-conversion
export const convertHslToRgb = (h, s, l) => {
  let r = '';
  let g = '';
  let b = '';

  if (s === 0) {
    // achromatic
    b = l;
    g = b;
    r = g;
  } else {
    const hue2rgb = function hue2rgb(p, q, t) {
      let tValue = t;
      if (tValue < 0) tValue += 1;
      if (tValue > 1) tValue -= 1;
      if (tValue < 1 / 6) return p + (q - p) * 6 * tValue;
      if (tValue < 1 / 2) return q;
      if (tValue < 2 / 3) return p + (q - p) * (2 / 3 - tValue) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

// https://stackoverflow.com/questions/35969656/how-can-i-generate-the-opposite-color-according-to-current-color
export const invertColor = (color, toBlackOrWhite) => {
  const fallbackColor = '#000000';
  if (!checkValidColor(color)) return fallbackColor;

  let r = '';
  let g = '';
  let b = '';
  let colorValue = color;
  if (colorValue.indexOf('#') === 0) {
    colorValue = colorValue.slice(1);
    // convert 3-digit hexValue to 6-digits.
    if (colorValue.length === 3) {
      colorValue = colorValue[0] + colorValue[0] + colorValue[1] + colorValue[1] + colorValue[2] + colorValue[2];
    }
    r = parseInt(colorValue.slice(0, 2), 16);
    g = parseInt(colorValue.slice(2, 4), 16);
    b = parseInt(colorValue.slice(4, 6), 16);
  } else if (colorValue.indexOf('hsl') === 0) {
    [r, g, b] = convertHslToRgb(splitRgbAndHslValues(colorValue));
  } else if (colorValue.indexOf('rgb') === 0) {
    [r, g, b] = splitRgbAndHslValues(colorValue);
  }

  if (toBlackOrWhite) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);

  // pad each with zeros and return
  return `#${String(r).padStart(4, '0')}${String(g).padStart(4, '0')}${String(b).padStart(4, '0')}`;
};
