import { Col, Row, Typography } from 'antd';
import { format } from 'date-fns';
import * as React from 'react';
import yearOnYear, { getUniqueYears } from '../../../../modules/data/year-on-year';
import { formatChange, formatPercent, singleMonthLetter } from '../../../../modules/format';
import BubbleChart from '../../../../shared/BubbleChart/BubbleChart';
import BaseChart from '../../treasury/BaseChart/BaseChart';
import styles from './DIFOT.module.css';

const DIFOT = props => {
  const { data, singlePackerPlant } = props;

  const getColor = percent => {
    if (percent >= 0.95) {
      return '#3DE5C7';
    }
    if (percent >= 0.9) {
      return '#D0F1EE';
    }
    if (percent >= 0.8) {
      return '#E3E8F9';
    }
    if (percent >= 0.7) {
      return '#FEC5B8';
    }
    return '#F8617B';
  };

  const multiHighlights = {};

  if (data.packerPlants.packerPlantsInPacker) {
    data.packerPlants.packerPlantsInPacker.forEach(x => {
      multiHighlights[x.name] = '#2C333D';
    });
  }

  return (
    <div className={styles.Difot_difot}>
      <Row style={{ marginTop: 10 }}>
        <Col span={8}>
          <div className={styles.Difot_title}>
            <div>
              <strong>{formatPercent(1)(data.weightedAverage.current)}</strong> WAVG
            </div>
          </div>
          {!singlePackerPlant || singlePackerPlant.isPacker ? (
            <div style={{ marginBottom: 10, opacity: 0.6 }}>
              across{' '}
              {singlePackerPlant && singlePackerPlant.isPacker
                ? data.packerPlants.packerPlantsInPacker.length
                : data.packerPlants.total}{' '}
              {singlePackerPlant && singlePackerPlant.isPacker ? `${singlePackerPlant.name} ` : ''}
              establishments
            </div>
          ) : (
            <div style={{ marginBottom: 10 }}>
              <strong>
                Rank{' '}
                {data.packerPlants.total -
                  data.packerPlants.allPackerPlants.findIndex(x => x.packer_plant_name === singlePackerPlant.name)}{' '}
              </strong>
              out of {data.packerPlants.total} establishments
            </div>
          )}
          <div>
            <span className={styles.Difot_differenceArrow}>
              {formatChange('positive')(data.weightedAverage.current - data.weightedAverage.startOfPeriod)}
            </span>
            <strong>{formatPercent(1)(data.weightedAverage.current - data.weightedAverage.startOfPeriod)}</strong> from
            30d ago
          </div>
        </Col>
        <Col span={16}>
          <BubbleChart
            data={data.packerPlants.allPackerPlants.map(x => ({
              ...x,
              value: x.percent,
            }))}
            average={(!singlePackerPlant || singlePackerPlant.isPacker) && data.weightedAverage.current}
            goal={data.weightedAverage.goal}
            goalLine={true}
            format={formatPercent(0)}
            isSmall={true}
            getColor={getColor}
            highlights={
              singlePackerPlant &&
              (singlePackerPlant.isPacker ? multiHighlights : { [singlePackerPlant.name]: '#2C333D' })
            }
            minMaxGenerator={(maxData, minData) => {
              return {
                maxVal: Math.min(1, ceilingToTens(maxData + 0.1)),
                minVal: floorToTens(minData),
              };
            }}
            formatTooltip={p => `${p.packer_plant_name} - ${formatPercent(0)(p.value)}`}
            nameField={'packer_plant_name'}
            colorsLegend={
              singlePackerPlant && [
                { start: 0.68, end: 0.7, color: '#F8617B' },
                { start: 0.7, end: 0.8, color: '#FEC5B8' },
                { start: 0.8, end: 0.9, color: '#E3E8F9' },
                { start: 0.9, end: 0.95, color: '#D0F1EE' },
                { start: 0.95, end: 0.96, color: '#3DE5C7' },
              ]
            }
          />
        </Col>
        {(!singlePackerPlant || singlePackerPlant.isPacker) && (
          <Col span={24} style={{ display: 'flex' }}>
            <div className={styles.Difot_table} style={{ marginRight: 10 }}>
              <div className={styles.Difot_title}>Establishments of concern</div>
              {data &&
                (data.packerPlants.bottom.length ? (
                  data.packerPlants.bottom
                    .sort((a, b) => a.percent - b.percent)
                    .map((x, i) => (
                      <div className={styles.Difot_tableRow} key={`laggers-${i}`}>
                        <div className={styles.Difot_score} style={{ backgroundColor: getColor(x.percent) }} />
                        <div className={styles.Difot_label}>
                          {x.packer_plant_name} {x.isNew && <span className={styles.Difot_newBadge}>new</span>}
                        </div>
                      </div>
                    ))
                ) : (
                  <div className={styles.Difot_tableRow}>--</div>
                ))}
            </div>
            <div className={styles.Difot_table} style={{ marginLeft: 10 }}>
              <div className={styles.Difot_title}>Top performing establishments</div>
              {data &&
                data.packerPlants.top.map((x, i) => (
                  <div className={styles.Difot_tableRow} key={`packer-plant-${i}`}>
                    <div className={styles.Difot_score} style={{ backgroundColor: getColor(x.percent) }} />
                    <div className={styles.Difot_label}>
                      {x.packer_plant_name} {x.isNew && <span className={styles.Difot_newBadge}>new</span>}
                    </div>
                  </div>
                ))}
            </div>
          </Col>
        )}
      </Row>
      <div style={{ marginTop: 20 }}>
        <Typography.Text className={styles.Difot_chartLabel}>DIFOT WAVG history</Typography.Text>
        {data && (
          <BaseChart
            barData={yearOnYear(
              data.byPeriod.map(x => ({
                interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
                value: x.percent,
              }))
            )}
            columns={getUniqueYears(data.byPeriod.map(x => format(new Date(x.date), 'MMM yyyy')))}
            colors={{ value: '#959BB5' }}
            lastBarColor="#4E5984"
            formatValues={v => formatPercent(1)(v).replace('%', '')}
            formatIntervals={singleMonthLetter}
            formatTooltip={val => `${formatPercent(1)(val)}`}
            splitIndex={-1}
            noCurrentLine={true}
            noLegend={true}
            ticksNumber={3}
            minMaxGenerator={(maxData, minData) => {
              return {
                maxVal: Math.min(1, ceilingToTens(maxData + 0.1)),
                minVal: floorToTens(minData - 0.1),
              };
            }}
            isSmall={true}
            isLineChart={true}
            isYearOnYear={true}
            highlightedValue={{
              value: data.weightedAverage.goal,
              style: {
                stroke: '#0B1435',
                opacity: 0.4,
                strokeDasharray: '4 3',
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

function ceilingToTens(value) {
  return Math.ceil(value * 10) / 10;
}

function floorToTens(value) {
  return Math.floor(value * 10) / 10;
}

export default DIFOT;
