import queryString from 'query-string';
import axios from 'axios';
import moment from 'moment';
import Constants from '../Constants';

const BASE_URL = `${Constants.URL}`;
const CLAIMS_DETAILS_URL = `${BASE_URL}analytic-dashboard/quality/claims-details`;

export default async function qualityClaimsDataRequest({
  timeRange,
  timePeriod,
  frequency,
  market,
  region,
  grinderId,
}) {
  const queryParams = queryString.stringify({
    timeRange,
    frequency,
    market,
    region,
    grinder_id: grinderId,
  });
  const claimsDetail = await axios.get(
    `${CLAIMS_DETAILS_URL}?${queryParams}&timePeriod=${timePeriod.map(d => moment(d).format('YYYY-MM-DD'))}`
  );

  return {
    categories: claimsDetail.data.categories,
    data: [...claimsDetail.data.byPackerPlant].sort((a, b) => new Date(a.date) - new Date(b.date)),
    dataByCategory: [...claimsDetail.data.byCategory].sort((a, b) => new Date(a.date) - new Date(b.date)),
    dataByClaimAmount: [...claimsDetail.data.byClaimAmount].sort((a, b) => new Date(a.date) - new Date(b.date)),
    dataByDaysToResolve: [...claimsDetail.data.byDaysToResolve].sort((a, b) => new Date(a.date) - new Date(b.date)),
  };
}
