import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const CardBodyGroup = props => {
  const { children } = props;
  return (
    <Box {...props} padding="10px" borderRadius={8} bgColor="white" borderColor="gray.50" borderWidth={1}>
      {children}
    </Box>
  );
};

CardBodyGroup.propTypes = propTypes;

export default CardBodyGroup;
