import { createSlice } from '@reduxjs/toolkit';
import { endOfYear, startOfYear, subYears } from 'date-fns';
import { MARKETS } from '../../config/markets';
import { qualityNCRsDataRequest } from '../../data-fixtures/quality-ncrs-all';
import { INCIDENTS_OPTIONS, TIME_RANGE_OPTIONS, VOLUME_OPTIONS } from './qualityDetailsNcrsConfig';
import { fetchPackerPlants } from '../quality-packer-plants/qualityPackerPlantsReducers';
import { fetchGrinders } from '../quality-grinders/qualityGrindersReducers';
import { FREQUENCY, DEFAULT_GRINDER_OPTION } from '../quality/qualityConfig';

const now = new Date();

export const initialState = {
  loading: true,
  error: null,
  data: null,

  // Global filters
  marketKey: MARKETS.Australia.key,

  // Filters
  region: MARKETS.Australia.includedCountries[0], // set to equal to marketKey if the selected market doesn't have regions
  incidents: INCIDENTS_OPTIONS.TOTAL,
  volume: VOLUME_OPTIONS[MARKETS.Australia.key].PERCENT_OF_TOTAL,
  claimDollars: null,
  timeRange: TIME_RANGE_OPTIONS.YOY,
  timePeriod: [startOfYear(subYears(now, 3)), endOfYear(now)],
  frequency: FREQUENCY.MONTHLY,
  breakdownMode: null,
  activePackerPlants: [undefined],
  selectedPackerPlantId: undefined,
  grinder: DEFAULT_GRINDER_OPTION.id,
};

const qualityNCRsSlice = createSlice({
  name: 'quality-ncrs',
  initialState,
  reducers: {
    /* eslint-disable-next-line no-unused-vars */
    qualityNCRsDataRequestStart(state, _action) {
      state.loading = true;
    },
    qualityNCRsDataRequestSuccess(state, { payload }) {
      state.loading = false;
      state.data = payload;
      state.error = null;
    },
    qualityNCRsDataRequestFailure(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
    setQualityNCRsMarketKey(state, { payload }) {
      state.marketKey = payload;
    },
    setQualityNCRsRegion(state, { payload }) {
      state.region = payload;
    },
    setQualityNCRsIncidents(state, { payload }) {
      state.incidents = payload;
    },
    setQualityNCRsVolume(state, { payload }) {
      state.volume = payload;
    },
    setQualityNCRsClaimDollars(state, { payload }) {
      state.claimDollars = payload;
    },
    setQualityNCRsTimeRange(state, { payload }) {
      state.timeRange = payload;
    },
    setQualityNCRsTimePeriod(state, { payload }) {
      state.timePeriod = payload;
    },
    setQualityNCRsFrequency(state, { payload }) {
      state.frequency = payload;
    },
    setQualityNCRsBreakdownMode(state, { payload }) {
      state.breakdownMode = payload;
    },
    setQualityNCRsActivePackerPlants(state, { payload }) {
      state.activePackerPlants = payload;
    },
    setQualityNCRsSelectedPackerPlantId(state, { payload }) {
      state.selectedPackerPlantId = payload;
    },
    setQualityNCRsGrinder(state, { payload }) {
      state.grinder = payload;
    },
  },
});

export const {
  qualityNCRsDataRequestStart,
  qualityNCRsDataRequestSuccess,
  qualityNCRsDataRequestFailure,
  setQualityNCRsMarketKey,
  setQualityNCRsRegion,
  setQualityNCRsIncidents,
  setQualityNCRsVolume,
  setQualityNCRsClaimDollars,
  setQualityNCRsTimeRange,
  setQualityNCRsTimePeriod,
  setQualityNCRsFrequency,
  setQualityNCRsBreakdownMode,
  setQualityNCRsActivePackerPlants,
  setQualityNCRsSelectedPackerPlantId,
  setQualityNCRsGrinder,
} = qualityNCRsSlice.actions;

// ------
// Thunks

/**
 * Fetches quality details NCRs data and manages loading/error state
 * @param {QualityFetchParameters} fetchParameters
 */
export const fetchQualityNCRsData = fetchParameters => {
  return async dispatch => {
    dispatch(qualityNCRsDataRequestStart());

    try {
      const [qualityNCRsData] = await Promise.all(
        [qualityNCRsDataRequest(fetchParameters)],
        dispatch(
          fetchPackerPlants({
            market: fetchParameters.market,
            timePeriod: fetchParameters.timePeriod,
          })
        ),
        dispatch(
          fetchGrinders({
            market: fetchParameters.market,
          })
        )
      );

      dispatch(qualityNCRsDataRequestSuccess(qualityNCRsData));
      return true;
    } catch (error) {
      dispatch(qualityNCRsDataRequestFailure(error.toString()));
      return error;
    }
  };
};

export const updateQualityNCRsClaimDollars = claimDollars => {
  return async dispatch => {
    dispatch(setQualityNCRsClaimDollars(claimDollars));
    await dispatch(fetchQualityNCRsData);
  };
};

export const updateQualityNCRsTimeRange = timeRange => {
  return async dispatch => {
    dispatch(setQualityNCRsTimeRange(timeRange));
    await dispatch(fetchQualityNCRsData);
  };
};

export const updateQualityNCRsTimePeriod = timePeriod => {
  return async dispatch => {
    dispatch(setQualityNCRsTimePeriod(timePeriod));
    await dispatch(fetchQualityNCRsData);
  };
};

export const updateQualityNCRsFrequency = frequency => {
  return async dispatch => {
    dispatch(setQualityNCRsFrequency(frequency));
    await dispatch(fetchQualityNCRsData);
  };
};

export const updateQualityNCRsRegion = region => {
  return async dispatch => {
    dispatch(setQualityNCRsRegion(region));
    await dispatch(fetchQualityNCRsData);
  };
};

export const updateQualityNCRsGrinder = grinder => {
  return async dispatch => {
    dispatch(setQualityNCRsGrinder(grinder));
    await dispatch(fetchQualityNCRsData);
  };
};

export default qualityNCRsSlice.reducer;
