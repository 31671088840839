import { WEIGHT_FETCHED } from '../actions/actions_package_weights';

const package_weights = (packageWeights = [], action) => {
  switch (action.type) {
    case WEIGHT_FETCHED:
      return action.payload;
    default:
      return packageWeights;
  }
};

export default package_weights;
