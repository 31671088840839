import Constants from '../Constants';

export const PACKER_PLANTS_FETCHED = 'PACKER_PLANTS_FETCHED';
export const PACKER_PLANT_ADDED = 'PACKER_PLANT_ADDED';
export const PACKER_PLANT_EDIT = 'PACKER_PLANT_EDIT';
export const PACKER_PLANT_REMOVED = 'PACKER_PLANT_REMOVED';
export const PACKER_PLANT_ERROR = 'PACKER_PLANT_ERROR';

const BASE_URL = `${Constants.URL}packer_plants`;

export function fetchPackerPlants(token, queryString) {
  return dispatch => {
    // exclude delisted packers by default
    const url = queryString ? BASE_URL + queryString : `${BASE_URL}?exclude_delisted=true`;

    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(url, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPackerPlant))
      .then(json => dispatch(loadPackerPlants(json)))
      .catch(error => dispatch(errorPackerPlant(error)));
  };
}

export function postPackerPlant(packer_plant, token) {
  return dispatch => {
    delete packer_plant.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(BASE_URL, {
      method: 'POST',
      body: JSON.stringify(packer_plant),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPackerPlant))
      .then(json => dispatch(addPackerPlant(json)))
      .catch(error => dispatch(errorPackerPlant(error)));
  };
}

export function patchPackerPlant(packer_plant, token) {
  return dispatch => {
    delete packer_plant.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${packer_plant.id}`, {
      method: 'PATCH',
      body: JSON.stringify(packer_plant),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPackerPlant))
      .then(json => dispatch(editPackerPlant(json)))
      .catch(error => dispatch(errorPackerPlant(error)));
  };
}

export function deletePackerPlant(packer_plant, token) {
  return dispatch => {
    delete packer_plant.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${packer_plant.id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPackerPlant))
      .then(json => dispatch(removePackerPlant(packer_plant)))
      .catch(error => dispatch(errorPackerPlant(error)));
  };
}

export function loadPackerPlants(packer_plants) {
  return {
    type: PACKER_PLANTS_FETCHED,
    payload: packer_plants,
  };
}

export function addPackerPlant(packer_plant) {
  return {
    type: PACKER_PLANT_ADDED,
    payload: packer_plant,
  };
}

export function removePackerPlant(packer_plant) {
  return {
    type: PACKER_PLANT_REMOVED,
    payload: packer_plant,
  };
}

export function editPackerPlant(packer_plant) {
  return {
    type: PACKER_PLANT_EDIT,
    payload: packer_plant,
  };
}

export function errorPackerPlant(error) {
  return {
    type: PACKER_PLANT_ERROR,
    payload: error,
  };
}
