import React, { useEffect, useState } from 'react';
import { Box, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import OrderManagementTabList from '../orders/OrderManagementTabList';
import PoChangeHistory from '../../containers/orders/ChangeHistory';
import EditInternationalOrder from '../orders/order-form/edit-form/EditInternationalOrder';


const PurchaseOrderPageComponent = props => {

  const TabList = [
    {
      type: 'order',
      name: 'order',
      label: 'Order',
    },
    {
      type: 'history',
      name: 'history',
      label: 'History',
    },
  ];

  const token = props.user.token;
  const poId = props.match.params.poId; 

  useEffect(() => {
    //props.fetchPurchaseOrder(token, poId);
    //props.fetchPackageWeights();
    //props.fetchTransporter(token);
    //props.fetchEntitiesData();
  }, []);


  const [ActiveType, SetActiveType] = useState(TabList[0].name);
  const [ActiveIndex, SetActiveIndex] = useState(0);
  
  const handleTabNavigation = (type, name) => {
    const tabIndex = TabList.findIndex(tabItem => tabItem.name === name);
    SetActiveType(type);
    SetActiveIndex(tabIndex);
  };

  return (
    <Box mb={0}>
      <Tabs index={ActiveIndex}>
        <OrderManagementTabList tabs={TabList} handleNavigation={(type, name) => handleTabNavigation(type, name)} />
        <Box minH="xl" paddingX="52px">
          <TabPanels>
            <TabPanel paddingX="0px" paddingY="26px">
              {/*<EditInternationalOrder 
                currentOrder={props.currentOrder}
                poID={poId}
                onClose={null}
                {...props}
  />*/}
            </TabPanel>
            <TabPanel>
              <PoChangeHistory Title = {"History"} displayRows = {8} {...props} />
            </TabPanel>
          </TabPanels>
        </Box>
      </Tabs>
    </Box>
  );
};

export default PurchaseOrderPageComponent;
