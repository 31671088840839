import { Box, HStack, Image, Text, Button, Flex } from '@chakra-ui/react';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
// Local
import { addDocumentToOrder } from '../../actions/actions_orders';
import SelectField from '../basic/SelectField';

class UploadDocumentForm extends Component {
  static propTypes = {
    autoSave: PropTypes.bool,
    closeModal: PropTypes.func,
    currentOrder: PropTypes.shape({
      loaded: PropTypes.bool,
      voyage: PropTypes.arrayOf(PropTypes.object),
    }),
    disabled: PropTypes.bool,
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    poDocTypes: PropTypes.array.isRequired,
    stateForm: PropTypes.instanceOf(Object),
    token: PropTypes.string,
    error: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      form: {
        fileKey: Math.random().toString(36),
        docType: false,
        file: false,
      },
    };
  }

  componentDidUpdate(nextProps, prevProps) {
    // TODO FixME, have a proper state management
    const { currentOrder, error } = this.props;
    if (currentOrder && currentOrder.loaded === prevProps.loading) {
      this.setState({ loading: false });
    }

    if (error && this.state.loading) {
      this.setState({ loading: false });
    }
  }

  onFieldChange = (e, fieldName) => {
    const copy = Object.assign({}, this.state);
    copy.form[fieldName] = fieldName === 'docType' ? (e ? e.value : null) : e.target.files[0];
    this.setState(copy);
  };

  onUploadFile = file => {
    const copy = Object.assign({}, this.state);
    copy.form.file = file[0];
    this.setState(copy);
  };

  onSave = () => {
    const { token, dispatch, stateForm, currentOrder, autoSave } = this.props;
    // Doc quick upload uses currentOrder prop.
    let po = { ...currentOrder };
    if (stateForm) {
      // When user is editing US/International order
      // Use component state with latest user changes.
      po = cloneDeep(stateForm.form);
      if (stateForm.voyage) {
        po.voyage = [{ ...stateForm.voyage }];
      }
    }
    dispatch(addDocumentToOrder(po, { ...this.state.form, saveToDB: autoSave }, token));
    this.updateState();
    this.setState({ loading: true });
    this.closeModal();
  };

  // generate fileKey and update state
  updateState = () => {
    this.setState(prevState => ({
      fileKey: Math.random().toString(36),
      form: {
        docType: prevState.form.docType,
        file: false,
      },
    }));
  };

  // if component used in modal let's close it after upload finished
  closeModal = () => {
    const { closeModal } = this.props;
    if (closeModal) {
      closeModal();
    }
  };

  render() {
    const { form, loading } = this.state;
    const { poDocTypes, disabled } = this.props;

    const docTypes = poDocTypes.map(item => {
      return { value: item.label || item.value, label: item.label || item.value };
    });
    return (
      <Box width="100%">
        {/* <FormLabel>Attach document</FormLabel> */}
        {/* <HStack spacing="19px"> */}
        <Box mt={5}>
          <SelectField
            name="select-doc-type"
            defaultValue={form.docType}
            options={!disabled && docTypes}
            placeholder="Select document type..."
            onChange={e => {
              this.onFieldChange(e, 'docType');
            }}
          />
        </Box>

        {loading ? (
          <Image src="./img/loader.gif" alt="loading" />
        ) : (
          <Dropzone
            className="document-dropzone document-dropzone--override"
            onDrop={this.onUploadFile}
            isDragActive={form.docType}
          >
            <Text
              as="label"
              fontSize="sm"
              paddingY={2}
              paddingX={4}
              mb={0}
              borderRadius={2}
              cursor={disabled ? 'not-allowed' : 'pointer'}
              color="white"
              bg="gray"
              fontWeight="medium"
              opacity={disabled ? 0.4 : 1}
            >
              Choose file
              <span hidden={!form.file}>
                &nbsp;&nbsp;
                <i className="flaticon-interface" />
              </span>
            </Text>
          </Dropzone>
        )}
        {/* {form.file && form.docType && ( */}
        <Flex justifyContent="end" mb={5}>
          <Button colorScheme="actionPrimary" fontWeight="bold" width="80px" onClick={this.onSave}>
            Save
          </Button>
        </Flex>
        {/* </HStack> */}
      </Box>
    );
  }
}

export default UploadDocumentForm;
