import moment from 'moment';
import React, { Component } from 'react';
import { fetchFormFields } from '../../actions/actions_form_fields';
import { fetchGrinders } from '../../actions/actions_grinders';
import { fetchBeefInputProducts } from '../../actions/actions_input_products';
import { fetchNetsuitePurchaseOrder, resetModalOrder } from '../../actions/actions_orders';
import { fetchPackers } from '../../actions/actions_packers';
import { confirmPackerOffer, fetchPackerOffers, patchPackerOffer } from '../../actions/actions_packer_offer';
import { fetchPackerPlants } from '../../actions/actions_packer_plants';
import { fetchTransporters } from '../../actions/actions_transporters';
import ConfirmPurchaseOrderModal from '../../containers/packers/ConfirmPurchaseOrderModal';
import EditPackerOfferForm from '../../containers/packers/EditPackerOfferForm';
import { calculateAvgLoadCL, calculateAvgUnitPrice, getQueryString } from '../../functions';
import { findForeignItem } from '../../helpers';
import OrderTableModal from '../orders/order-table-modal/OrderTableModal';
import PackerFilters from './po_filters/PackerFilters';
import PackerOffersTableSection from './po_table/POTableSection';

class PackerOffers extends Component {
  constructor(props) {
    super(props);

    this.defaultFilters = {
      start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      delivery_start_date: '',
      delivery_end_date: '',
    };

    this.state = {
      multiFilters: this.defaultFilters,
      loaded: false,
      modals: {
        editOpened: false,
        editPurchaseOrderOpened: false,
        confirmOpened: false,
      },
      selectedPackerOffer: false,
    };

    this.searchPackerOffers = this.searchPackerOffers.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.cancelPackerOffer = this.cancelPackerOffer.bind(this);
    this.editPackerOffer = this.editPackerOffer.bind(this);
    this.changeConfirmModalState = this.changeConfirmModalState.bind(this);
    this.changeEditPurchaseOrderState = this.changeEditPurchaseOrderState.bind(this);
    this.updateModalState = this.updateModalState.bind(this);
    this.submitConfirmPurchaseOrder = this.submitConfirmPurchaseOrder.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ loaded: PackerOffers.isLoaded(nextProps) });
  }

  componentDidMount() {
    this.props.dispatch(fetchPackerPlants(this.props.token));
    this.props.dispatch(fetchPackers(this.props.token));
    this.props.dispatch(fetchBeefInputProducts(this.props.token));
    this.props.dispatch(fetchFormFields(this.props.token, '?type=price_type,offer_source'));
    this.props.dispatch(fetchPackerOffers(this.props.token, getQueryString(this.state.multiFilters)));
  }

  static isLoaded({ packer_offers, input_products, packer }) {
    return packer_offers.length && input_products.length && packer.length;
  }

  onFilterChange(fieldName, value) {
    const newValue = Object.assign({}, this.state.multiFilters);
    newValue[fieldName] = value;
    this.setState({ multiFilters: newValue });
  }

  searchPackerOffers(queryString) {
    if (!queryString) {
      queryString = getQueryString(this.state.multiFilters);
    }
    this.props.dispatch(fetchPackerOffers(this.props.token, queryString));
  }

  changeEditPurchaseOrderState(newState, packerOffer) {
    if (newState) {
      this.props.dispatch(fetchPackerPlants(this.props.token));
      this.props.dispatch(fetchGrinders(this.props.token));
      this.props.dispatch(fetchTransporters(this.props.token));
      this.props.dispatch(fetchNetsuitePurchaseOrder(packerOffer.purchase_order_id, this.props.token));
    } else {
      this.props.dispatch(resetModalOrder());
    }

    this.updateModalState(newState, 'editPurchaseOrderOpened', packerOffer);
  }

  changeConfirmModalState(newState, packerOffer) {
    if (newState) {
      const queryString = `?id=${PackerOffers.getPackerIdsFromPackerOfferObj(packerOffer)}`;
      this.props.dispatch(fetchPackerPlants(this.props.token, queryString));
      this.props.dispatch(fetchGrinders(this.props.token));
    }
    this.updateModalState(newState, 'confirmOpened', packerOffer);
  }

  static getPackerIdsFromPackerOfferObj(packerOffer) {
    return packerOffer.packer_id ? packerOffer.packers.packer_plant_ids.join(',') : packerOffer.packer_plant_id;
  }

  submitConfirmPurchaseOrder(payload) {
    const payloadCopy = Object.assign({}, payload);
    payloadCopy.transport_cost_per_unit = this.props.transport.cost;
    this.props.dispatch(confirmPackerOffer(payloadCopy, this.state.selectedPackerOffer.id, this.props.token));
    this.updateModalState(false, 'confirmOpened', false);
  }

  updateModalState(newState, fieldName, packerOffer) {
    const newModalState = Object.assign({}, this.state.modals);
    newModalState[fieldName] = newState;
    this.setState({ modals: newModalState, selectedPackerOffer: packerOffer });
  }

  cancelPackerOffer(packerOfferId) {
    const confirmResult = window.confirm('Are you sure you want to cancel offer?');

    if (confirmResult) {
      this.props.dispatch(patchPackerOffer(packerOfferId, { cancelled: true }, this.props.token));
    }
  }

  editPackerOffer(packerOfferId, index) {
    this.props.dispatch(fetchPackerPlants(this.props.token));
    this.updateModalState(true, 'editOpened', this.props.packer_offers[index]);
  }

  resetSearch() {
    this.setState({ multiFilters: this.defaultFilters });
    this.props.dispatch(fetchPackerOffers(this.props.token));
  }

  resolvePackerStuff(packer_offer) {
    const itemId = packer_offer.packer_plant_id ? packer_offer.packer_plant_id : packer_offer.packer_id;
    const collectionName = packer_offer.packer_plant_id ? 'packers' : 'packer';
    packer_offer[collectionName.slice(0, -1)] = findForeignItem(this.props[collectionName], itemId);
  }

  render() {
    const { confirmOpened, editPurchaseOrderOpened, editOpened } = this.state.modals;
    const packerOffersByStatus = {
      current: [],
      pending: [],
      accepted: [],
      expired: [],
      cancelled: [],
    };

    if (this.state.loaded) {
      this.props.packer_offers.forEach((packer_offer, index) => {
        this.resolvePackerStuff(packer_offer);

        packer_offer.lines.map(line => {
          line.input_product = findForeignItem(this.props.input_products, line.input_product_uid);
        });
        calculateAvgUnitPrice(packer_offer);
        calculateAvgLoadCL(packer_offer);
        packer_offer.props_index = index;
        packerOffersByStatus[packer_offer.status].push(packer_offer);
      });
    }

    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper" style={{ padding: '30px' }}>
        <EditPackerOfferForm opened={editOpened} close={this.updateModalState} item={this.state.selectedPackerOffer} />
        <ConfirmPurchaseOrderModal
          submit={this.submitConfirmPurchaseOrder}
          close={this.changeConfirmModalState}
          packer_offer={this.state.selectedPackerOffer}
          opened={confirmOpened}
        />
        {editPurchaseOrderOpened ? (
          <OrderTableModal
            selectCurrentModalDetail={this.changeEditPurchaseOrderState}
            current_order={this.props.netsuite_purchase_order}
            {...this.props}
          />
        ) : (
          false
        )}
        <div className="m-content">
          <div className="row">
            <div className="col-xl-12">
              <div className="m-portlet m-portlet--full-height ">
                <div>
                  <h2>Packer Offers</h2>
                  <hr />
                  <PackerFilters
                    filters={this.state.multiFilters}
                    search={this.searchPackerOffers}
                    resetSearch={this.resetSearch}
                    onFilterChange={this.onFilterChange}
                  />
                </div>
                <div className="m-portlet m-portlet--full-height " style={{ paddingTop: '20px' }}>
                  {Object.keys(packerOffersByStatus).map((status, index) => (
                    <PackerOffersTableSection
                      key={index}
                      openConfirm={this.changeConfirmModalState}
                      cancel={this.cancelPackerOffer}
                      edit={this.editPackerOffer}
                      editPurchaseOrder={this.changeEditPurchaseOrderState}
                      status={status}
                      expand={status === 'current'}
                      items={packerOffersByStatus[status]}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PackerOffers;
