import React, { Component } from 'react';
import SupportForm from '../../containers/support/SupportForm';
import SubmittedTickets from '../../containers/support/SubmittedTickets';

const CARDS = {
  SUPPORT_TICKET: 'SUPPORT_TICKETS',
  SUBMITTED_TICKETS: 'SUBMITTED_TICKETS',
};

class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_type: CARDS.SUPPORT_TICKET,
    };
  }

  render() {
    const { active_type } = this.state;
    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="row">
            <div className="col-xl-12">
              <div className="m-portlet m-portlet--full-height ">
                <div className="m-portlet__head">
                  <div className="m-portlet__head-tools">
                    <ul className="nav nav-tabs m-tabs m-tabs-line admin-tabs" role="tablist">
                      <li
                        key={CARDS.SUPPORT_TICKET}
                        className={`nav-item m-tabs__item ${
                          this.state.active_type === CARDS.SUPPORT_TICKET ? 'active' : ''
                        }`}
                      >
                        <a
                          className="nav-link m-tabs__link"
                          onClick={() => this.setState({ active_type: CARDS.SUPPORT_TICKET })}
                        >
                          Support Tickets
                        </a>
                      </li>
                      {this.props.user.role.access_list.toString().indexOf('admin') > -1 ? (
                        <li
                          key={CARDS.SUBMITTED_TICKETS}
                          className={`nav-item m-tabs__item ${
                            this.state.active_type === CARDS.SUBMITTED_TICKETS ? 'active' : ''
                          }`}
                        >
                          <a
                            className="nav-link m-tabs__link"
                            onClick={() =>
                              this.setState({
                                active_type: CARDS.SUBMITTED_TICKETS,
                              })
                            }
                          >
                            Submitted Tickets
                          </a>
                        </li>
                      ) : (
                        false
                      )}
                    </ul>
                  </div>
                </div>
                <div className="m-portlet__body" style={{ minHeight: '320px' }}>
                  <div className="tab-content">
                    {active_type === CARDS.SUPPORT_TICKET ? (
                      <div className="tab-pane active" id="m_widget2_tab4_content">
                        <SupportForm />
                      </div>
                    ) : (
                      false
                    )}
                    {active_type === CARDS.SUBMITTED_TICKETS ? (
                      <div className="tab-pane active" id="m_widget2_tab1_content" aria-expanded="true">
                        <SubmittedTickets />
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Support;
