const schema = [
  {
    name: 'non_conformance_type',
    displayName: 'Non Conformance Type',
    systemIdField: 'id',
    displayIdField: 'name',
    path: '/non-conformance-types',
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'The name or description of the non-conformance type',
        dataType: 'shorttext',
        required: true,
      },
    ],
  },
];
export default schema;
