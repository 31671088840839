import { createSelector } from '@reduxjs/toolkit';
import { group, sum } from 'd3';
import { differenceInDays, eachDayOfInterval, endOfDay, format, startOfDay, startOfWeek, getDay } from 'date-fns';
import { filterOrdersForDateMode } from '../../../modules/order-utils';
import { LOCATION_TYPE, DATE_MODE } from '../marketsConfig';
import {
  selectActiveFocusOrders,
  selectLocationsData,
  selectMarketsTimePeriod,
  selectSupplyChainDateMode,
} from './selectBasicMarketData';

/**
 * The packer_plant in action.
 * @param {*} state
 */
export const selectSupplyChainDetailsActivePackerPlant = state => state.supplyChainDetails.activePackerPlant;

/**
 * The grinder in action.
 * @param {*} state
 */
export const selectSupplyChainDetailsActiveGrinder = state => state.supplyChainDetails.activeGrinder;

// TODO: make sure packerPlant/grinder ID's dont overlap.
const makeLocationLookup = locations => (id, type) => {
  if (!Array.isArray(locations)) return null;
  const feature = locations.find(ftr => ftr.properties.uid === id && ftr.properties.type === type);
  if (feature) return feature;
  // eslint-disable-next-line
  console.warn(`Could not find feature for ID ` + id);
  return null;
};

/**
 * Filters orders based on the selected date mode.
 */
export const selectMarketSupplyChainOrders = createSelector(
  selectActiveFocusOrders,
  selectMarketsTimePeriod,
  selectSupplyChainDateMode,
  (activeFocusOrders, timePeriod, dateMode) => {
    const filtered = activeFocusOrders.filter(filterOrdersForDateMode(timePeriod, dateMode));
    return filtered;
  }
);

export const selectMarketSupplyChainPackerPlants = createSelector(selectMarketSupplyChainOrders, orders => {
  if (!orders) return null;

  const groupedByPackerPlant = group(orders, order => order.packer_plant_name);
  const packerPlants = Array.from(groupedByPackerPlant.entries()).map(packerPlantAndOrders => {
    return packerPlantAndOrders[0];
  });

  return packerPlants;
});

export const selectMarketSupplyChainGrinders = createSelector(selectMarketSupplyChainOrders, orders => {
  if (!orders) return null;

  const groupedByGrinder = group(orders, order => order.grinder_name);
  const grinders = Array.from(groupedByGrinder.entries()).map(grinderNameAndorders => {
    return grinderNameAndorders[0];
  });

  return grinders;
});

export const selectMarketSupplyChainData = createSelector(
  selectMarketSupplyChainOrders,
  selectLocationsData,
  selectMarketsTimePeriod,
  selectSupplyChainDateMode,
  selectSupplyChainDetailsActivePackerPlant,
  selectSupplyChainDetailsActiveGrinder,
  (ordersAll, locationsData, timePeriod, dateMode, activePackerPlant, activeGrinder) => {
    if (!ordersAll) return null;

    let orders =
      activePackerPlant !== null ? ordersAll.filter(x => x.packer_plant_name === activePackerPlant) : ordersAll;

    orders = activeGrinder !== null ? orders.filter(x => x.grinder_name === activeGrinder) : orders;

    const locationLookup = makeLocationLookup(locationsData);

    const scheduleDays = eachDayOfInterval({
      start: new Date(timePeriod[0]),
      end: new Date(timePeriod[1]),
    }).map(dateStart => [dateStart, endOfDay(dateStart)]);

    const groupedByGrinder = group(orders, order => order.grinder_uid);
    const grinders = Array.from(groupedByGrinder.entries())
      .map(([grinderId, grinderOrders]) => {
        const first = grinderOrders[0];
        const grinderLocation = locationLookup(grinderId, LOCATION_TYPE.GRINDER);

        const grinderOrdersByPackerPlant = group(grinderOrders, order => order.packer_plant_id);
        const grinderPackerPlantOrders = Array.from(grinderOrdersByPackerPlant.entries()).map(
          ([packerPlantId, packerPlantOrders]) => {
            const firstInner = packerPlantOrders[0];
            const packerPlantLocation = locationLookup(packerPlantId, LOCATION_TYPE.PACKER_PLANT);

            const path =
              grinderLocation && packerPlantLocation
                ? [grinderLocation.geometry.coordinates, packerPlantLocation.geometry.coordinates]
                : null;

            const volume = sum(packerPlantOrders, order => order.quantity);

            const packerPlantSchedule = scheduleDays.map(daySpan => {
              const dayOrders = packerPlantOrders.filter(filterOrdersForDateMode(daySpan, dateMode));
              const dayVolume = sum(dayOrders, d => d.quantity);

              return {
                date: daySpan[0],
                volume: dayVolume,
              };
            });

            return {
              from: grinderId,
              id: packerPlantId,
              name: firstInner.packer_plant_name,
              path,
              volume,
              schedule: packerPlantSchedule,
            };
          }
        );

        const schedule = scheduleDays.map(daySpan => {
          const dayOrders = grinderOrders.filter(filterOrdersForDateMode(daySpan, dateMode));
          const dayVolume = sum(dayOrders, d => d.quantity);

          return {
            date: daySpan[0],
            volume: dayVolume,
          };
        });

        const totalVolume = sum(grinderPackerPlantOrders, d => d.volume);
        return {
          id: grinderId,
          name: first.grinder_name,
          orders: grinderPackerPlantOrders,
          location: grinderLocation,
          totalVolume,
          schedule,
        };
      })
      .sort((a, b) => b.totalVolume - a.totalVolume);

    const groupedByPackerPlant = group(orders, order => order.packer_plant_id);
    const packerPlants = Array.from(groupedByPackerPlant.entries())
      .map(([packerPlantId, packerPlantOrders]) => {
        const first = packerPlantOrders[0];
        const packerPlantLocation = locationLookup(packerPlantId, LOCATION_TYPE.PACKER_PLANT);

        const packerPlantOrdersByGrinder = group(packerPlantOrders, order => order.grinder_uid);
        const packerPlantGrinderOrders = Array.from(packerPlantOrdersByGrinder.entries()).map(
          ([grinderId, grinderOrders]) => {
            const firstInner = grinderOrders[0];
            const grinderLocation = locationLookup(grinderId, LOCATION_TYPE.GRINDER);

            const path =
              grinderLocation && packerPlantLocation
                ? [grinderLocation.geometry.coordinates, packerPlantLocation.geometry.coordinates]
                : null;

            const volume = sum(grinderOrders, order => order.quantity);

            const grinderSchedule = scheduleDays.map(daySpan => {
              const dayOrders = grinderOrders.filter(filterOrdersForDateMode(daySpan, dateMode));
              const dayVolume = sum(dayOrders, d => d.quantity);

              return {
                date: daySpan[0],
                volume: dayVolume,
              };
            });

            return {
              from: packerPlantId,
              id: grinderId,
              name: firstInner.grinder_name,
              path,
              volume,
              schedule: grinderSchedule,
            };
          }
        );

        const schedule = scheduleDays.map(daySpan => {
          const dayOrders = packerPlantOrders.filter(filterOrdersForDateMode(daySpan, dateMode));
          const dayVolume = sum(dayOrders, d => d.quantity);

          return {
            date: daySpan[0],
            volume: dayVolume,
          };
        });

        const totalVolume = sum(packerPlantOrders, d => d.quantity);
        return {
          id: packerPlantId,
          name: first.packer_plant_name,
          orders: packerPlantGrinderOrders,
          location: packerPlantLocation,
          totalVolume,
          schedule,
        };
      })
      .sort((a, b) => b.totalVolume - a.totalVolume);

    return {
      grinders,
      packerPlants,
    };
  }
);

export const MAX_DAYS_TO_SHOW_DATA_DAILY = 14;

export const selectMarketSupplyChainVolumeData = createSelector(
  selectMarketSupplyChainOrders,
  selectMarketsTimePeriod,
  selectSupplyChainDateMode,
  selectSupplyChainDetailsActivePackerPlant,
  selectSupplyChainDetailsActiveGrinder,
  (ordersAll, timePeriod, dateMode, activePackerPlant, activeGrinder) => {
    if (!ordersAll) return null;

    let orders =
      activePackerPlant !== null ? ordersAll.filter(x => x.packer_plant_name === activePackerPlant) : ordersAll;

    orders = activeGrinder !== null ? orders.filter(x => x.grinder_name === activeGrinder) : orders;

    // const annotations = treasuryAnnotations[TREASURY_MODULE.RISK_MANAGEMENT];

    const ordersTotal = sum(orders, o => o.quantity);

    const dayDiff = differenceInDays(timePeriod[1], timePeriod[0]);

    const weekStartDigit = getDay(new Date(timePeriod[0]));

    const tmp =
      dayDiff < MAX_DAYS_TO_SHOW_DATA_DAILY
        ? group(orders, o => startOfDay(o[dateMode === DATE_MODE.ORDER ? 'order_date' : 'delivery_date']).getTime())
        : group(orders, o =>
            startOfWeek(o[dateMode === DATE_MODE.ORDER ? 'order_date' : 'delivery_date'], {
              weekStartsOn: weekStartDigit,
            }).getTime()
          );

    const intervals = Array.from(tmp.entries()).map(([intervalString, intervalOrders]) => {
      const interval = parseInt(intervalString, 10) || intervalString;

      let groupedOrders = intervalOrders;
      if (activePackerPlant || activeGrinder) {
        const groupedByEntity = group(intervalOrders, i => (activeGrinder ? i.packerPlant_Name : i.grinder_name));
        groupedOrders = Array.from(groupedByEntity.entries()).map(([entity, entityOrders]) => {
          const volume = sum(entityOrders, e => e.quantity);
          return {
            orderEntity: entity,
            volume,
            volumeRatio: volume / ordersTotal,
          };
        });
      }

      return {
        interval: format(interval, dayDiff < MAX_DAYS_TO_SHOW_DATA_DAILY ? 'dd/MM' : 'dd/MM/yy'),
        orders: groupedOrders,
      };
    });

    return { intervals, ordersTotal };
  }
);
