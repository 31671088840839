const schema = [
  {
    name: 'input_product',
    displayName: 'Input Product',
    systemIdField: 'uid',
    displayIdField: 'name',
    path: '/input_products',
    tabGroup: { key: 'products', label: 'Products' },
    deletable: false,
    autoUid: true,
    fields: [
      {
        name: 'uid',
        displayName: 'UID',
        tooltip: 'Provide the Unique ID to create the product.',
        dataType: 'text',
        required: false,
        systemIdentifier: true,
        filterable: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'Provide the Name of the Product',
        dataType: 'text',
        required: true,
        filterable: true,
      },
      {
        name: 'description',
        displayName: 'Description',
        tooltip: 'Specify detailed information about the product',
        dataType: 'text',
      },
      {
        name: 'cl',
        displayName: 'CL',
        tooltip: 'Provide the number of days required for this product in the Coldstore.',
        dataType: 'number',
        required: true,
      },
      {
        name: 'fresh',
        displayName: 'Fresh',
        tooltip: 'Select whether the product is Fresh by choosing Yes or No',
        dataType: 'boolean',
      },
      {
        name: 'claim',
        displayName: 'Claim',
        tooltip: 'Provide the claim for the product.',
        dataType: 'shorttext',
      },
      {
        name: 'product_type',
        displayName: 'Product Type',
        tooltip: 'Select the Product type, either Beef or Pork.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'product_types',
      },
      {
        name: 'category',
        displayName: 'Category',
        tooltip: 'Based on the product type selection, choose the category of the products.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'input_product_category',
        required: true,
      },
      {
        name: 'sub_category',
        displayName: 'Sub Category',
        tooltip: 'Based on the category selection, choose the subcategory of the products.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'input_product_subcategory',
        required: true,
      },
      {
        name: 'country',
        displayName: 'Country',
        tooltip: "Select the country of the product's origin.",
        dataType: 'simple-reference',
        referenceEntityType: 'country',
        code: true,
        required: true,
      },
      {
        name: 'packaging',
        displayName: 'Packaging',
        tooltip: "Indicate whether the products are packed; if yes, select 'Pack'.",
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'input_product_packaging',
        required: true,
      },
      {
        name: 'organisation_ids',
        displayName: 'Organisation',
        tooltip: 'Select the organization responsible for the products.',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'organisation',
        required: true,
      },
      {
        name: 'delisted',
        displayName: 'Is Delisted',
        tooltip: 'Indicate whether the product is delisted by selecting Yes or No.',
        dataType: 'boolean',
      },
    ],
  },
];
export default schema;
