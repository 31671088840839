import {
  BEEF_OUTPUT_PRODUCTS_FETCHED,
  BEEF_OUTPUT_PRODUCT_ADDED,
  BEEF_OUTPUT_PRODUCT_EDIT,
  BEEF_OUTPUT_PRODUCT_REMOVED,
} from '../actions/actions_output_products';

const output_products = (output_products = [], action) => {
  switch (action.type) {
    case BEEF_OUTPUT_PRODUCTS_FETCHED:
      return action.payload;
    case BEEF_OUTPUT_PRODUCT_ADDED:
      var newBeefOutputProducts = JSON.parse(JSON.stringify(output_products));
      newBeefOutputProducts.push(action.payload);
      return newBeefOutputProducts;
    case BEEF_OUTPUT_PRODUCT_EDIT:
      var newBeefOutputProducts = JSON.parse(JSON.stringify(output_products));
      return replaceBeefOutputProduct(newBeefOutputProducts, action.payload);
    case BEEF_OUTPUT_PRODUCT_REMOVED:
      var newBeefOutputProducts = JSON.parse(JSON.stringify(output_products));
      return removeBeefOutputProduct(newBeefOutputProducts, action.payload);
    default:
      return output_products;
  }
};

function replaceBeefOutputProduct(allBeefOutputProducts, editedBeefOutputProduct) {
  const newBeefOutputProducts = [];
  allBeefOutputProducts.forEach(function (element) {
    if (element.uid === editedBeefOutputProduct.uid) {
      newBeefOutputProducts.push(editedBeefOutputProduct);
    } else {
      newBeefOutputProducts.push(element);
    }
  });
  return newBeefOutputProducts;
}

function removeBeefOutputProduct(allBeefOutputProducts, removedBeefOutputProduct) {
  const newBeefOutputProducts = [];
  allBeefOutputProducts.forEach(function (element) {
    if (element.uid !== removedBeefOutputProduct.uid) {
      newBeefOutputProducts.push(element);
    }
  });
  return newBeefOutputProducts;
}

export default output_products;
