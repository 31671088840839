import React, { Component } from 'react';
import Datetime from 'react-datetime';
// Local Deps
import { fetchTransport } from '../../../actions/actions_transport';
import TransportCost from './TransportCost';
import { getClearedPurchaseOrderLines, validateConfirmPackerOfferForm } from '../../../functions';
import ValidationErrors from '../validators/ValidationErrors';
import SelectField from '../../basic/SelectField';

class ConfirmPackerOfferModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      grinder_uid: '',
      packer_plant_id: '',
      delivery_date: '',
      validationErrors: [],
      lines: [],
    };
    this.onPropertyChange = this.onPropertyChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const loaded = nextProps.packer_plants.length && nextProps.grinders.length;
    this.setState({
      loaded,
      lines: nextProps.packer_offer ? nextProps.packer_offer.lines : null,
    });
  }

  closeModal() {
    this.setState(
      {
        loaded: false,
        grinder_uid: '',
        packer_plant_id: '',
        delivery_date: '',
        lines: [],
        validationErrors: [],
      },
      () => {
        this.props.close(false);
      }
    );
  }

  stateChangedCallback() {
    if (this.state.packer_plant_id && this.state.grinder_uid) {
      this.props.dispatch(fetchTransport(this.state.packer_plant_id, this.state.grinder_uid));
    }
  }

  onPropertyChange(field_name, value) {
    const newState = Object.assign({}, this.state);
    newState[field_name] = value;
    this.setState(newState, this.stateChangedCallback);
  }

  submitForm = () => {
    const form = Object.assign({}, this.state);

    const validationErrors = validateConfirmPackerOfferForm(form, this.props.packer_offer);
    this.setState({ validationErrors });

    form.lines = getClearedPurchaseOrderLines(form.lines);

    if (validationErrors.length === 0) {
      this.props.submit(form);
      this.setState({
        loaded: false,
        grinder_uid: '',
        packer_plant_id: '',
        delivery_date: '',
        lines: [],
        validationErrors: [],
      });
    }
  };

  static prepareSelectValues(items) {
    return items.map(item => {
      return { label: item.name, value: item.id };
    });
  }

  render() {
    const { loaded, validationErrors, lines } = this.state;
    const dateProps = {
      placeholder: 'Delivery Date',
      className: 'form-control m-input',
    };
    const sendEnabled = this.state.packer_plant_id && this.state.grinder_uid && this.state.delivery_date;
    const modalOpenedStyle = this.props.opened ? { display: 'block' } : { display: 'none' };
    const modalShowStyle = `modal fade ${this.props.opened ? 'show' : false}`;
    const grinderOptions = ConfirmPackerOfferModal.prepareSelectValues(this.props.grinders);
    const packerPlantOptions = ConfirmPackerOfferModal.prepareSelectValues(this.props.packer_plants);

    return (
      <div className="confirm-packer-offer-modal">
        {loaded ? (
          <div style={modalOpenedStyle}>
            <div
              className={modalShowStyle}
              id="m_modal_4"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              style={{ overflowY: 'scroll', display: 'block' }}
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title" id="exampleModalLabel">
                      Place Order
                    </h3>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        this.closeModal();
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-sm-4">
                        <label htmlFor="select-grinder">
                          <strong>Packer</strong>
                        </label>
                        <SelectField
                          name="select-grinder"
                          defaultValue={this.state.packer_plant_id}
                          options={packerPlantOptions}
                          placeholder="Packer"
                          onChange={e => {
                            this.onPropertyChange('packer_plant_id', e ? e.value : null);
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="select-grinder">
                          <strong>Grinder</strong>
                        </label>
                        <SelectField
                          name="select-grinder"
                          defaultValue={this.state.grinder_uid}
                          options={grinderOptions}
                          placeholder="Grinder"
                          onChange={e => {
                            this.onPropertyChange('grinder_uid', e ? e.value : null);
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="select-grinder">
                          <strong>Delivery Date</strong>
                        </label>
                        <Datetime
                          id="delivery_end_date"
                          inputProps={dateProps}
                          closeOnSelect
                          defaultValue={this.state.delivery_date}
                          onChange={e => {
                            this.onPropertyChange('delivery_date', e._isAMomentObject ? e.format('YYYY-MM-DD') : e);
                          }}
                          timeFormat={false}
                          dateFormat="YYYY-MM-DD"
                        />
                      </div>
                    </div>
                    <TransportCost
                      lines={lines}
                      packer_plant_id={this.state.packer_plant_id}
                      grinder_uid={this.state.grinder_uid}
                      transport={this.props.transport}
                    />
                    <ValidationErrors mainMessage="Form contains errors:" errors={validationErrors} />
                  </div>
                  <div className="modal-footer" style={{ justifyContent: 'baseline' }}>
                    <div style={{ width: '100%' }} className="text-center">
                      <button disabled={!sendEnabled} className="btn btn-info center-block" onClick={this.submitForm}>
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-backdrop fade show" />
          </div>
        ) : (
          false
        )}
      </div>
    );
  }
}

export default ConfirmPackerOfferModal;
