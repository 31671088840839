import { Typography } from 'antd';
import * as React from 'react';
import { useSelector } from 'react-redux';
import MARKETS from '../../../../../config/markets';
import { calculateRoundedMax } from '../../../../../modules/data/rounded-max';
import { formatDollars } from '../../../../../modules/format';
import {
  selectQualityClaimsRegion,
  selectQualityClaimsTimeRange,
  selectQualityClaimsMarket,
} from '../../../../../slices/quality-details-claims/selectors/selectBasicDetailsClaimsData';
import { TIME_RANGE_OPTIONS } from '../../../../../slices/quality-details-claims/qualityDetailsClaimsConfig';
import BaseChart from '../../../treasury/BaseChart/BaseChart';
import { useChartData } from '../../../../../containers/analyticDashboard/qualityDetails/claims/QualityDetailsClaimsCharts';
import { equalsIgnoreOrder } from '../../../../../utils';
import styles from '../../QualityDetails.module.css';

export default function AmountChart(props) {
  const { isLarge, columns, colors, selectedCategories, selectedClaimAmounts, selectedDaysToResolve } = props;

  const timeRange = useSelector(selectQualityClaimsTimeRange);
  const region = useSelector(selectQualityClaimsRegion);
  const market = useSelector(selectQualityClaimsMarket);

  const amountData = useChartData(`amount`, selectedCategories, selectedClaimAmounts, selectedDaysToResolve);

  const total = React.useMemo(() => {
    let sum = 0;
    if (equalsIgnoreOrder(columns, ['Finalised', 'Submitted'])) {
      columns.forEach(x => {
        if (x === 'Submitted') {
          sum += amountData.reduce((a, b) => a + (b[x] || 0), 0);
        }
      });
    } else {
      columns.forEach(x => {
        sum += amountData.reduce((a, b) => a + (b[x] || 0), 0);
      });
    }
    return sum;
  }, [amountData, columns]);

  if (amountData.length === 0) return null;

  return (
    <div className={styles.QualityDetails_amountChart}>
      <div className={styles.QualityDetails_chartTitle}>
        <div style={{ color: '#0B1435' }}>
          Total claim amount{' '}
          <span style={{ fontWeight: 'normal' }}>
            ({`${region === 'All' ? market.currency : MARKETS[region].currency}`})
          </span>
        </div>
        {timeRange === TIME_RANGE_OPTIONS.HISTORICAL && (
          <div className={styles.QualityDetails_chartTotal}>
            <Typography.Text>
              <span style={{ opacity: 0.6 }}>Total</span> <strong>{formatDollars(2)(total || 0)}</strong>
            </Typography.Text>
          </div>
        )}
      </div>
      <BaseChart
        barData={amountData}
        columns={columns}
        colors={colors}
        isLarge={isLarge}
        splitIndex={-1}
        noCurrentLine={true}
        noLegend={true}
        ticksNumber={4}
        formatValues={formatDollars(2)}
        pathColors={colors}
        minMaxGenerator={(maxData, minData) => {
          return {
            maxVal: calculateRoundedMax(maxData),
            minVal: 0,
          };
        }}
        formatTooltip={formatDollars(2)}
        padding={{ right: 2 }}
        isLineChart={timeRange === TIME_RANGE_OPTIONS.YOY}
        noLastPoint={true}
        isYearOnYear={timeRange === TIME_RANGE_OPTIONS.YOY}
      />
    </div>
  );
}
