import React from 'react';
import { commify } from '../../../../functions';
import Datetime from 'react-datetime';

import SelectField from '../../../basic/SelectField';

const EditPackerOfferLine = props => {
  const fabricationDateInputProps = {
    placeholder: 'Fabrication',
    className: 'form-control m-input',
  };

  const ingredientOptions = [];

  props.input_products.forEach(function (element) {
    ingredientOptions.push({ value: element.uid, label: element.name });
  });

  return (
    <tr>
      <td>
        {props.line.active ? (
          <button
            className="btn btn-sm btn-info"
            onClick={() => {
              props.save(props.index);
            }}
          >
            <i className="flaticon-interface" />
          </button>
        ) : (
          <button className="btn btn-sm btn-info" onClick={() => props.setActive(true, props.index)}>
            <i className="flaticon-edit-1" />
          </button>
        )}
        &nbsp;
        <button
          className="btn btn-sm btn-danger"
          onClick={() => {
            props.removeFromList(props.index);
          }}
        >
          <i className="flaticon-cancel" />
        </button>
      </td>
      <td>
        {props.line.active ? (
          <SelectField
            name="select-input-product"
            defaultValue={props.line.input_product_uid}
            options={ingredientOptions}
            placeholder="Select an ingredient"
            onChange={option => props.onLineValueChange('input_product_uid', option ? option.value : null, props.index)}
          />
        ) : props.line.input_product ? (
          props.line.input_product.name
        ) : (
          ''
        )}
      </td>
      <td>
        {props.line.active ? (
          <div>
            {props.dateWarnings.indexOf(props.index) > -1 ? (
              <div
                onClick={props.removeWarning(props.index)}
                style={{ textTransform: 'none' }}
                className="fab-date-alert alert alert-info"
              >
                <i onClick={props.removeWarning(props.index)} className="flaticon-circle" />
                The date you have selected is greater than 14 days in the past. Please ensure you have selected the
                correct date before continuing
              </div>
            ) : (
              false
            )}
            <Datetime
              id="fabrication_date"
              inputProps={fabricationDateInputProps}
              closeOnSelect
              value={props.line.fabrication_date}
              onChange={e =>
                props.onLineValueChange(
                  'fabrication_date',
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : null,
                  props.index
                )
              }
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </div>
        ) : (
          props.line.fabrication_date
        )}
      </td>
      <td>
        {props.line.active ? (
          <input
            id="quantity"
            type="number"
            className="form-control "
            placeholder="Quantity"
            onChange={e =>
              props.onLineValueChange('quantity', e.target.value ? parseInt(e.target.value) : 0, props.index)
            }
            value={props.line.quantity}
          />
        ) : (
          commify(props.line.quantity)
        )}
      </td>
      <td>
        {props.line.active ? (
          <input
            id="price_per_unit"
            type="number"
            className="form-control "
            placeholder="Price Per Unit"
            onChange={e =>
              props.onLineValueChange('price_per_unit', e.target.value ? parseFloat(e.target.value) : 0, props.index)
            }
            value={props.line.price_per_unit}
          />
        ) : (
          `$${props.line.price_per_unit.toFixed(4)}`
        )}
      </td>
    </tr>
  );
};

export default EditPackerOfferLine;
