import { createSlice } from '@reduxjs/toolkit';
import { uploadFileToS3Request } from '../../data-fixtures/basic';

const initialState = { attachments: [] };

const uploadFileToS3Slice = createSlice({
  name: 'attachments',
  initialState,
  reducers: {
    fileUploaded: (state, { payload }) => payload,
    fileRemoved: (state, { payload }) => {
      state.attachments = [...state.attachments.splice(payload.indx, 1)];
    },
  },
});

export const { fileUploaded, fileRemoved } = uploadFileToS3Slice.actions;

// Basic selectors
export const selectStrategies = state => {
  return state.strategies.data;
};

// Thunks
export const uploadFileToS3 = fileData => {
  return async dispatch => {
    let attachmentLink;
    try {
      attachmentLink = await uploadFileToS3Request(fileData);
      dispatch(fileUploaded({ uploadSuccess: true, uploadedFileUrl: attachmentLink }));
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
    }
    return attachmentLink;
  };
};

export const handleFileRemoved = fileIndx => {
  return async dispatch => {
    try {
      dispatch(fileRemoved({ indx: fileIndx }));
      return fileIndx;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export const resetUploadStatus = () => {
  return async dispatch => {
    dispatch(fileUploaded({ uploadSaved: true }));
  };
};

export default uploadFileToS3Slice.reducer;
