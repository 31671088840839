import { Box } from '@chakra-ui/react';
import React from 'react';
import './Watermark.css';

const WatermarkText = ({ children, containerStyles }) => {
  return (
    <Box color="gray" fontWeight="bold" fontSize="16px">
      {children}
    </Box>
  );
};

export default WatermarkText;
