import { Box, FormLabel } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import MultiSelect from 'react-select';

const CustomMultiSelect = ({ label, value, options, onChange }) => {
  return (
    <Box flexGrow={1}>
      {label && <FormLabel>{label}</FormLabel>}
      <MultiSelect
        isMulti
        value={value || []}
        options={options || []}
        name="types"
        onChange={onChange || (() => {})}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: `var(--chakra-colors-secondary-300, ${theme.colors.primary})`,
            primary25: `var(--chakra-colors-secondary-100, ${theme.colors.primary25})`,
          },
        })}
        styles={{
          placeholder: styles => ({
            ...styles,
            lineHeight: '20px',
            height: '17px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
          input: styles => ({
            ...styles,
            height: '30px',
            lineHeight: '30px',
            borderColor: '#ccc',
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            marginBottom: '3px',
          }),
          indicatorsContainer: styles => ({
            ...styles,
            height: '40px',
          }),
          indicatorSeparator: styles => {
            return {
              ...styles,
              backgroundColor: 'transparent',
              marginTop: '1px',
              marginBottom: '1px',
            };
          },
          valueContainer: styles => ({
            ...styles,
            lineHeight: '16px',
            minHeight: '38px',
            paddingRight: 0,
          }),
          dropdownIndicator: styles => ({
            ...styles,
            paddingLeft: '3px',
            paddingRight: '3px',
            paddingTop: 0,
            paddingBottom: 0,
          }),
          clearIndicator: styles => ({
            ...styles,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '1px',
            paddingRight: '1px',
          }),
          control: styles => ({
            ...styles,
            minHeight: '40px',
            cursor: 'pointer',
            boxShadow: 'none',
            border: '1px solid #DCDFE3',
            borderRadius: '4px',
          }),
          option: styles => ({
            ...styles,
            cursor: 'pointer',
            color: 'inherit',
          }),
          menu: styles => ({
            ...styles,
          }),
          singleValue: styles => ({
            ...styles,
            color: '#9FA6AD',
            fontWeight: '500',
          }),
          multiValue: styles => {
            return {
              ...styles,
              backgroundColor: '#fff',
              border: '1px solid #3A44B0',
              color: '#3A44B0',
              padding: '0px 5px',
              fontSize: '12px',
              borderRadius: '6px',
            };
          },
          multiValueLabel: styles => ({
            ...styles,
            color: '#3A44B0',
          }),
          multiValueRemove: styles => ({
            ...styles,
            color: '#3A44B0',
            padding: 0,
            ':hover': {
              color: '#3A44B0',
            },
          }),
        }}
      />
    </Box>
  );
};

export default CustomMultiSelect;

CustomMultiSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.objectOf({ label: PropTypes.string, value: PropTypes.string })),
  options: PropTypes.arrayOf(PropTypes.objectOf({ label: PropTypes.string, value: PropTypes.string })),
  onChange: PropTypes.func,
};
