import { Box, IconButton } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { closeError } from '../../actions/index';

const AppError = ({ error, dispatch }) => {
  return (
    <>
      {error ? (
        <Box
          className="error-msg alert alert-danger"
          bottom="0"
          position="fixed"
          zIndex={100000}
          margin="0 0 15px 15px"
          maxWidth={{
            base: '300px',
            lg: '450px',
          }}
        >
          <IconButton
            width="24px"
            minWidth="24px"
            height="27px"
            onClick={() => dispatch(closeError(false))}
            icon={<i className="flaticon-circle" />}
          />
          {error.toString()}
        </Box>
      ) : (
        false
      )}
    </>
  );
};

AppError.propTypes = {
  error: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default AppError;
