import { connect } from 'react-redux';
import SelfServiceReport from '../../components/selfServiceReports/selfServiceReportPage/selfServiceReportPage';
import { fetchReportConfig } from '../../actions/actions_self_service_reports';

const mapStateToProps = state => {
  return {
    user: state.user,
    users: state.users,
    roles: state.roles,
    categories: state.selfServiceReportConfig.categories,
    reportConfig: state.selfServiceReportConfig.config,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchReportConfig: (token, reportId) => dispatch(fetchReportConfig(token, reportId)),
    dispatch,
  };
};

const newReportConfig = connect(mapStateToProps, mapDispatchToProps)(SelfServiceReport);

export default newReportConfig;
