import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react';
import HorizontalNavigationBand from '../../components/core/HorizontalNavigationBand';

import React, { useState } from 'react';
import DocumentsQueue from './DocumentsQueue/DocumentsQueue';
import './email-attachment.scss';
import DocumentTypes from './DocumentTypes/DocumentTypes';
import DocumentsQueueTester from './DocumentsQueueTester/DocumentsQueueTester';

const tab = {
  documentsQueue: 'Documents Queue',
  documentTypes: 'Document Types',
  documentsQueueTester: 'Tester'
}
const tabs = [
  tab.documentsQueue,
  tab.documentTypes,
  tab.documentsQueueTester
];

export default () => {
  const [activeTab, setActiveTab] = useState(tabs[0])
  return (
    <Box style={{ display: 'grid' }}>
      <Tabs>
        <HorizontalNavigationBand justifyContent="flex-start" paddingX="52px">
          <TabList>
            {tabs.map(tab => (
              <Tab key={tab}
                _focus={{ outline: 'none' }}
                _selected={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
                _hover={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
                fontWeight="normal"
                width="162px"
                fontSize="14px"
                padding="0px"
                height="103px"
                marginRight="10px"
                onClick={() => setActiveTab(tab)}>
                {tab}
              </Tab>
            ))}
          </TabList>
        </HorizontalNavigationBand>
        <TabPanels>
          <TabPanel>
            {activeTab === tab.documentsQueue && <DocumentsQueue />}
          </TabPanel>
          <TabPanel>
            {activeTab === tab.documentTypes && <DocumentTypes />}
          </TabPanel>
          <TabPanel>
            {activeTab === tab.documentsQueueTester && <DocumentsQueueTester />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
