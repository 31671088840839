import moment from 'moment';
import React, { Component } from 'react';
import PackerOfferTableContent from './PackerOfferTableContent';

class POTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  sortTable(field_name) {
    this.setState({
      sortTable: this.state.sortTable === field_name ? `${field_name}_reverse` : field_name,
    });
  }

  render() {
    const noFoundStyle = { paddingLeft: '5px', fontSize: 15 };
    const lineHeaderStyle = { background: '#EEEEEE' };

    const tableData = this.props.items;

    if (this.state.sortTable && tableData.length > 1) {
      tableData.sort(
        function (a, b) {
          if (this.state.sortTable.indexOf('_reverse') > -1) {
            const sortTable = this.state.sortTable.replace('_reverse', '');

            if (this.state.sortTable.indexOf('packer_pricing') > -1) {
              var val1 = a[sortTable] ? a[sortTable].name : 0;
              var val2 = b[sortTable] ? b[sortTable].name : 0;
            } else {
              var val1 = a[sortTable] ? a[sortTable] : 0;
              var val2 = b[sortTable] ? b[sortTable] : 0;
            }

            if (!isNaN(parseInt(val1)) && sortTable.indexOf('date') === -1) {
              return val2 - val1;
            }
            if (this.state.sortTable.indexOf('date') > -1) {
              return moment.utc(b[sortTable]).diff(moment.utc(a[sortTable]));
            }
            return val2.localeCompare(val1);
          }
          if (this.state.sortTable === 'packers') {
            var val1 = a[this.state.sortTable] ? a[this.state.sortTable].name : 0;
            var val2 = b[this.state.sortTable] ? b[this.state.sortTable].name : 0;
          } else {
            var val1 = a[this.state.sortTable] ? a[this.state.sortTable] : 0;
            var val2 = b[this.state.sortTable] ? b[this.state.sortTable] : 0;
          }

          if (!isNaN(parseInt(val1)) && this.state.sortTable.indexOf('date') === -1) {
            return val1 - val2;
          }
          if (this.state.sortTable.indexOf('date') > -1) {
            return moment.utc(a[this.state.sortTable]).diff(moment.utc(b[this.state.sortTable]));
          }
          return val1.localeCompare(val2);
        }.bind(this)
      );
    }

    return (
      <div>
        {tableData.length ? (
          <table style={{ marginTop: '15px' }} className="table m-table table-hover m-table--head-separator-danger">
            <thead>
              <tr>
                <th>Offer</th>
                <th
                  onClick={() => {
                    this.sortTable('start_date');
                  }}
                >
                  Offer Date
                  <i className="sort-icon fa fa-sort" />
                </th>
                <th
                  onClick={() => {
                    this.sortTable('packers');
                  }}
                >
                  Offer From
                  <i className="sort-icon fa fa-sort" />
                </th>
                <th
                  onClick={() => {
                    this.sortTable('delivery_start');
                  }}
                >
                  Delivery Start
                  <i className="sort-icon fa fa-sort" />
                </th>
                <th
                  onClick={() => {
                    this.sortTable('delivery_end');
                  }}
                >
                  Delivery End
                  <i className="sort-icon fa fa-sort" />
                </th>
                <th style={lineHeaderStyle}>Input Product</th>
                <th style={lineHeaderStyle}>Quantity</th>
                <th
                  onClick={() => {
                    this.sortTable('avg_load_cl');
                  }}
                >
                  Avg. Load CL
                  <i className="sort-icon fa fa-sort" />
                </th>
                <th
                  onClick={() => {
                    this.sortTable('avg_load_cl');
                  }}
                >
                  Avg. Unit Price
                  <i className="sort-icon fa fa-sort" />
                </th>
                <th>Order</th>
              </tr>
            </thead>
            <PackerOfferTableContent
              items={tableData}
              openConfirm={this.props.openConfirm}
              cancel={this.props.cancel}
              edit={this.props.edit}
              editPurchaseOrder={this.props.editPurchaseOrder}
            />
          </table>
        ) : (
          <p style={noFoundStyle}>No items found</p>
        )}
      </div>
    );
  }
}

export default POTable;
