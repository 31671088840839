import { CURRENCY, WEIGHT_UNIT } from '../modules/const';
import { VOLUME_GROUPINGS } from './marketsVolumeGrouping';
import { MENU_ITEMS } from './menuItems';
import {store} from '../index';

export const MARKETS = {
  USA: {
    key: 'USA',
    selectorText: 'US',
    label: 'US',
    url: 'us',
    allowExportFocus: false,
    includeUsdaCalculations: false,
    hasSustainabilityMetrics: false,
    volumeGroupings: [VOLUME_GROUPINGS.PRICING_PERFORMANCE, VOLUME_GROUPINGS.SOURCING, VOLUME_GROUPINGS.PRODUCT],
    menuItems: [MENU_ITEMS.BEEF_10_1, MENU_ITEMS.BEEF_4_1],
    bounds: [
      [-171.791110603, 18.91619],
      [-66.96466, 71.3577635769],
    ],
    quantityUnit: WEIGHT_UNIT.POUND,
    currency: CURRENCY.USD,
    includedCountries: ['USA', 'Canada'],
  },
  Australia: {
    key: 'Australia',
    selectorText: 'AUS',
    label: 'AUS/NZ',
    url: 'aus',
    includedCountries: ['Australia', 'New Zealand'],
    allowExportFocus: true,
    includeUsdaCalculations: false,
    hasSustainabilityMetrics: true,
    volumeGroupings: [
      VOLUME_GROUPINGS.COUNTRY,
      VOLUME_GROUPINGS.PRICING,
      VOLUME_GROUPINGS.PRODUCT,
      VOLUME_GROUPINGS.ANGUS,
    ],
    menuItems: [MENU_ITEMS.BEEF_10_1, MENU_ITEMS.BEEF_4_1, MENU_ITEMS.ANGUS_4_1],
    bounds: [
      [112.85, -9.86],
      // [153.69, -43.7], only Australia's bounds, the line below includes New Zealand
      [178.517093541, -46.641235447],
    ],
    quantityUnit: WEIGHT_UNIT.KILOGRAM,
    currency: CURRENCY.AUD,
  },
  'New Zealand': {
    key: 'New Zealand',
    selectorText: 'NZ',
    allowExportFocus: true,
    includeUsdaCalculations: false,
    hasSustainabilityMetrics: false,
    volumeGroupings: [
      VOLUME_GROUPINGS.COUNTRY,
      VOLUME_GROUPINGS.PRICING,
      VOLUME_GROUPINGS.PRODUCT,
      VOLUME_GROUPINGS.ANGUS,
    ],
    menuItems: [MENU_ITEMS.BEEF_10_1, MENU_ITEMS.BEEF_4_1, MENU_ITEMS.ANGUS_4_1],
    bounds: [
      [166.509144322, -46.641235447],
      [178.517093541, -34.4506617165],
    ],
    quantityUnit: WEIGHT_UNIT.KILOGRAM,
    currency: CURRENCY.NZD,
    includedCountries: ['Australia', 'New Zealand'],
  },
  Canada: {
    key: 'Canada',
    selectorText: 'CAN',
    label: 'CAN',
    allowExportFocus: false,
    includeUsdaCalculations: false,
    hasSustainabilityMetrics: false,
    volumeGroupings: [VOLUME_GROUPINGS.PRICING, VOLUME_GROUPINGS.PRODUCT, VOLUME_GROUPINGS.ANGUS],
    menuItems: [MENU_ITEMS.BEEF_10_1, MENU_ITEMS.BEEF_4_1],
    bounds: [
      [-140.99778, 41.6751050889],
      [-52.6480987209, 83.23324],
    ],
    quantityUnit: WEIGHT_UNIT.POUND,
    currency: CURRENCY.USD,
  },
  All: {
    key: 'All',
    selectorText: 'All',
    label: 'All',
    currency: '--',
  },
};


// start TODO Remove only for demo
export const getRenameExportToImport = () => store?.getState()?.user?.role?.name === 'ArbySonicDemo' ? 'Import' : 'Export';

export const getProductSpecification = () => store?.getState()?.user?.organisation?.name === 'Inspire Brands' ? 'VL' : 'CL';
// ende TODO Remove only for demo

export default MARKETS;
