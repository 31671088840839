import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, VStack, Text } from '@chakra-ui/react';

import './InvoiceCart.scss';
import Constants from '../../Constants';
import { getCartConfig } from './invoiceGridConfig';
import ResultsGrid from '../basic/ResultsGrid';
import { formatRowsForCart } from './helpers';

const { FINANCE_CARTS: CARTS } = Constants;

/* eslint-disable react/prefer-stateless-function */
class InvoiceCart extends Component {
  static propTypes = {
    pagination: PropTypes.instanceOf(Object),
    dateType: PropTypes.string,
    invoiceType: PropTypes.string,
    grinder: PropTypes.instanceOf(Object),
    orderList: PropTypes.arrayOf(PropTypes.object),
    pricedOrderList: PropTypes.arrayOf(PropTypes.object),
    selectedRows: PropTypes.arrayOf(PropTypes.object),
    selectedCart: PropTypes.string.isRequired,
    onRowSelect: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onGridRowUpdate: PropTypes.func.isRequired,
    packageWeights: PropTypes.arrayOf(PropTypes.Object),
    internalPoLabel: PropTypes.string,
  };

  render() {
    const {
      invoiceType,
      orderList,
      pagination,
      onRowSelect,
      onPageChange,
      onGridRowUpdate,
      onReset,
      selectedRows,
      selectedCart,
      grinder,
    } = this.props;

    const columns = getCartConfig(this.props, selectedCart, grinder, invoiceType);
    const action = selectedCart === CARTS.InvoiceCart ? 'invoice' : 'lock prices for';
    const AugmentedSelectedRows = selectedRows.map(order => ({
      ...order,
      delivery_date: order.recv_delivery_date || order.delivery_date,
    }));

    return (
      <VStack spacing="19px" align="stretch">
        <VStack spacing="10px" align="stretch">
          {/* <!-- TEXT --> */}
          <Text as="p" fontWeight="medium">
            {`Select items to ${action} (please fill in the 'Pricing based on' field before)`}
          </Text>
          {/* <!-- BUTTON --> */}
          <Button variant="link" onClick={onReset} width="max-content" colorScheme="actionPrimary">
            Clear Cart
          </Button>
        </VStack>

        {/* <!-- GRID --> */}
        <ResultsGrid
          className="invoice-cart__grid results-grid--minimal invoice-cart"
          {...{
            columns,
            onPageChange,
            onRowSelect,
            onGridRowUpdate,
            pagination,
            selectedRows: AugmentedSelectedRows,
            rows: orderList,
          }}
        />
      </VStack>
    );
  }
}

export default InvoiceCart;
