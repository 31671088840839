import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import ErrorMessage from '../ErrorMessage';
import FinalizeMessage from '../../../packers/packer_offer_form/FinalizeMessage';
import SaveSection from '../SaveSection';
import { get } from 'lodash';
import { withInternationalOrderFormContext } from './InternationalOrderFormContext';

function InternationalOrderFormEndSection(props) {
  const { form } = props.state ? props.state : props;

  const { validationErrors, loadSizes, baseForm, isIneligible } = props;

  const fullForm = {
    ...baseForm,
    ...form,
  };
  if (!isIneligible)
    return (
      <>
        {/* <!-- ERROR MESSAGE --> */}
        <Box mt="17px">
          <ErrorMessage
            form={fullForm}
            buyQuantityError={get(props.state, 'validationErrors.requiredFields.buyQuantity', false)}
            dateError={validationErrors.dates}
            loadSizes={loadSizes}
            {...props}
          />
        </Box>
        {/* <!-- FINALIZE MESSAGE --> */}
        <FinalizeMessage reset={false} copies={form.copies} />
        {/* <!-- SAVE SECTION --> */}
        <SaveSection
          onChangeValue={props.handleChangeValue}
          save={props.confirmForm}
          copies={form.copies}
          singleIngredientForm={baseForm.singleIngredientForm}
        />
      </>
    );
  return '';
}

InternationalOrderFormEndSection.propTypes = {
  isIneligible: PropTypes.bool,
  handleChangeValue: PropTypes.func,
  loadSizes: PropTypes.arrayOf(PropTypes.object),
  confirmForm: PropTypes.func.isRequired,
  baseForm: PropTypes.shape({
    grinderUid: PropTypes.string,
    packerId: PropTypes.string,
    packerPlantId: PropTypes.string,
    purchasingOffice: PropTypes.string,
    singleIngredientForm: PropTypes.bool,
    productType: PropTypes.string,
    voyage: PropTypes.shape({
      dischargePortId: PropTypes.number,
    }),
  }),
  state: {
    form: PropTypes.shape({
      buyCurrency: PropTypes.string,
      buyIncoterms: PropTypes.string,
      buyUnitOfMeasureId: PropTypes.n,
      fec: PropTypes.instanceOf(Object),
      halal: PropTypes.bool,
      mtc: PropTypes.bool,
      sellCurrency: PropTypes.string,
      sellIncoterms: PropTypes.string,
      sellUnitOfMeasureId: PropTypes.number,
    }),
    requiredFields: PropTypes.shape({
      form: PropTypes.arrayOf(PropTypes.string),
    }),
  },
  validationErrors: PropTypes.shape({
    requiredFields: PropTypes.instanceOf(Object),
    dates: PropTypes.bool,
  }),
};

export default withInternationalOrderFormContext(InternationalOrderFormEndSection);
