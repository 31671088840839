import React, { Component, Children } from 'react';
import AuthGate from '../../../containers/auth/AuthGate';

class TabbedWindow extends Component {
  state = {
    activeTabKey: null,
  };

  render() {
    const { activeTabKey } = this.state;
    const { defaultTab, children } = this.props;
    const tabToActivate = activeTabKey || defaultTab;
    const tabs = Children.map(children, (child, idx) => {
      return React.cloneElement(child, {
        active: tabToActivate === child.props.tabKey,
        onTabClick: tabKey => this.setState({ activeTabKey: tabKey }),
      });
    });

    const activeTab = tabs.find(tab => tab.props.active);
    const activeTabContent = activeTab.props.children;

    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="m-portlet m-portlet--full-height ">
                <div className="m-portlet__head">
                  <div className="m-portlet__head-tools">
                    <ul className="nav nav-tabs m-tabs m-tabs-line admin-tabs" role="tablist">
                      {tabs}
                    </ul>
                  </div>
                </div>
                <div className="m-portlet__body" style={{ minHeight: '320px' }}>
                  <div className="tab-content">
                    <div className="tab-pane active" id="m_widget2_tab4_content">
                      {activeTabContent}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TabbedWindow;
