const schema = [
  {
    name: 'country',
    displayName: 'Countries',
    systemIdField: 'id',
    shouldNotPluralize: true,
    displayIdField: 'name',
    path: '/countries',
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'Provide the Official name of the country.',
        dataType: 'text',
        required: true,
      },
      {
        name: 'code',
        displayName: 'Code',
        tooltip: 'The standardized country code of the country',
        dataType: 'text',
        required: false,
      }
    ],
  },
];
export default schema;
