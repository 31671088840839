import Constants from '../Constants';

export const FORM_FIELDS_FETCHED = 'FORM_FIELDS_FETCHED';
export const FORM_FIELD_ADDED = 'FORM_FIELD_ADDED';
export const FORM_FIELD_EDIT = 'FORM_FIELD_EDIT';
export const FORM_FIELD_REMOVED = 'FORM_FIELD_REMOVED';
export const FORM_FIELD_ERROR = 'FORM_FIELD_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadFormFields(formFields) {
  return {
    type: FORM_FIELDS_FETCHED,
    payload: formFields,
  };
}

export function addFormField(formField) {
  return {
    type: FORM_FIELD_ADDED,
    payload: formField,
  };
}

export function removeFormField(formField) {
  return {
    type: FORM_FIELD_REMOVED,
    payload: formField,
  };
}

export function editFormField(formField) {
  return {
    type: FORM_FIELD_EDIT,
    payload: formField,
  };
}

export function errorFormField(error) {
  return {
    type: FORM_FIELD_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchFormFields(token, queryString) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    let url = `${Constants.URL}form_fields`;

    if (queryString) {
      url += queryString;
    }

    return fetch(url, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorFormField))
      .then(json => dispatch(loadFormFields(json)))
      .catch(error => dispatch(errorFormField(error)));
  };
}

export function postFormField(payload, token) {
  const form = [
    {
      id: payload.id,
      type: payload.field_type,
      value: payload.value,
    },
  ];
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}form_fields`, {
      method: 'POST',
      body: JSON.stringify(form),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorFormField))
      .then(json => dispatch(addFormField(json)))
      .catch(error => dispatch(errorFormField(error)));
  };
}

export function patchFormField(payload, token) {
  return dispatch => {
    const form = {
      id: payload.id,
      type: payload.field_type,
      value: payload.value,
    };
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}form_fields/${payload.id}`, {
      method: 'PATCH',
      body: JSON.stringify(form),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorFormField))
      .then(json => dispatch(editFormField(json)))
      .catch(error => dispatch(errorFormField(error)));
  };
}

export function deleteFormField(formField, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}form_fields/${formField.id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorFormField))
      .then(() => dispatch(removeFormField(formField)))
      .catch(error => dispatch(errorFormField(error)));
  };
}
