import Constants from '../Constants';
import api from '../api';

const basePath = `/articles`;

const actionTypes = {
  ARTICLE_REQUESTED: 'ARTICLE_REQUESTED',
  ARTICLE_REQUEST_SUCCESS: 'ARTICLE_REQUEST_SUCCESS',
  ARTICLE_REQUEST_FAILURE: 'ARTICLE_REQUEST_FAILURE',

  ARTICLE_TRIGGER_EDIT_MODE: 'ARTICLE_TRIGGER_EDIT_MODE',
  ARTICLE_RESET_EDIT_STATUS: 'ARTICLE_RESET_EDIT_STATUS',

  ARTICLE_SAVE_REQUESTED: 'ARTICLE_SAVE_REQUESTED',
  ARTICLE_SAVE_REQUEST_SUCCESS: 'ARTICLE_SAVE_REQUEST_SUCCESS',
  ARTICLE_SAVE_REQUEST_FAILURE: 'ARTICLE_SAVE_REQUEST_FAILURE',

  ARTICLE_DELETE_REQUESTED: 'ARTICLE_DELETE_REQUESTED',
  ARTICLE_DELETE_REQUEST_SUCCESS: 'ARTICLE_DELETE_REQUEST_SUCCESS',
  ARTICLE_DELETE_REQUEST_FAILURE: 'ARTICLE_DELETE_REQUEST_FAILURE',
};

export const actions = {
  requestArticle() {
    return {
      type: actionTypes.ARTICLE_REQUESTED,
    };
  },
  articleRequestSuccess(article) {
    return {
      type: actionTypes.ARTICLE_REQUEST_SUCCESS,
      payload: article,
    };
  },
  articleRequestError(error) {
    return {
      type: actionTypes.ARTICLE_REQUEST_FAILURE,
      payload: error,
    };
  },
  triggerEditMode() {
    return {
      type: actionTypes.ARTICLE_TRIGGER_EDIT_MODE,
    };
  },
  resetEditStatus() {
    return {
      type: actionTypes.ARTICLE_RESET_EDIT_STATUS,
    };
  },
  requestArticleSave() {
    return {
      type: actionTypes.ARTICLE_SAVE_REQUESTED,
    };
  },
  articleSaveRequestSuccess(article) {
    return {
      type: actionTypes.ARTICLE_SAVE_REQUEST_SUCCESS,
      payload: article,
    };
  },
  articleSaveRequestError(error) {
    return {
      type: actionTypes.ARTICLE_SAVE_REQUEST_FAILURE,
      payload: error,
    };
  },
  requestArticleDelete() {
    return {
      type: actionTypes.ARTICLE_DELETE_REQUESTED,
    };
  },
  articleDeleteRequestSuccess(article) {
    return {
      type: actionTypes.ARTICLE_DELETE_REQUEST_SUCCESS,
      payload: article,
    };
  },
  articleDeleteRequestError(error) {
    return {
      type: actionTypes.ARTICLE_DELETE_REQUEST_FAILURE,
      payload: error,
    };
  },
};

export const fetchArticle = slug => dispatch => {
  dispatch(actions.requestArticle());
  return api
    .get(`${basePath}/${slug}`)
    .then(response => Constants.handleErrors(response, dispatch, actions.articleRequestError))
    .then(json => dispatch(actions.articleRequestSuccess(json)))
    .catch(error => dispatch(actions.articleRequestError(error)));
};

export const postArticle = article => dispatch => {
  dispatch(actions.requestArticleSave());
  return api
    .post(basePath, article)
    .then(response => Constants.handleErrors(response, dispatch, actions.articleSaveRequestError))
    .then(json => dispatch(actions.articleSaveRequestSuccess(json)))
    .catch(error => dispatch(actions.articleSaveRequestError(error)));
};

export const patchArticle = article => dispatch => {
  dispatch(actions.requestArticleSave());
  return api
    .patch(`${basePath}/${article.slug}`, article)
    .then(response => Constants.handleErrors(response, dispatch, actions.articleSaveRequestError))
    .then(json => dispatch(actions.articleSaveRequestSuccess(json)))
    .then(() => dispatch(actions.resetEditStatus()))
    .catch(error => dispatch(actions.articleSaveRequestError(error)));
};

export const deleteArticle = article => dispatch => {
  dispatch(actions.requestArticleDelete());
  return api
    .delete(`${basePath}/${article.slug}` )
    .then(response => Constants.handleErrors(response, dispatch, actions.articleDeleteRequestError))
    .then(() => dispatch(actions.articleDeleteRequestSuccess(article))
    .then(() => dispatch(actions.resetEditStatus())))
    .catch(error => dispatch(actions.articleDeleteRequestError(error)));
}

const defaultState = {
  loading: false,
  error: null,
  article: null,
  editing: false,
  saving: false,
  saved: false,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.ARTICLE_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        editing: false,
        saving: false,
        saved: false,
        article: null,
      };
    case actionTypes.ARTICLE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        article: action.payload,
      };
    case actionTypes.ARTICLE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.ARTICLE_TRIGGER_EDIT_MODE:
      return {
        ...state,
        editing: true,
      };
    case actionTypes.ARTICLE_SAVE_REQUESTED:
      return {
        ...state,
        editing: true,
        saving: true,
      };
    case actionTypes.ARTICLE_SAVE_REQUEST_SUCCESS:
      return {
        ...state,
        editing: false,
        saving: false,
        saved: true,
        article: action.payload,
      };
    case actionTypes.ARTICLE_SAVE_REQUEST_FAILURE:
      return {
        ...state,
        editing: true,
        saving: false,
        error: action.payload,
      };
    case actionTypes.ARTICLE_RESET_EDIT_STATUS:
      return {
        ...state,
        editing: false,
        saving: false,
        saved: false,
      };
    case actionTypes.ARTICLE_DELETE_REQUESTED:
      return {
        ...state,
        article: null,
      };
    case actionTypes.ARTICLE_DELETE_REQUEST_SUCCESS:
      return{
        ...state,
        articles: action.payload.filter(element => element.id !== action.payload)
      };
    default:
      return state;
  }
};

export default reducer;
