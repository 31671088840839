import SignatureUpload from '../../../containers/admin/SignatureUpload';

const schema = [
  {
    name: 'user',
    displayName: 'User',
    systemIdField: 'id',
    displayIdField: 'email',
    path: '/users',
    deletable: false,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
        excludePayload: true,
      },
      {
        name: 'email',
        displayName: 'Email',
        tooltip: 'Email address of the user for login and notifications',
        dataType: 'shorttext',
        displayIdentifier: true,
        required: true,
      },
      {
        name: 'password',
        displayName: 'Password',
        tooltip: 'Secure password for the user account',
        dataType: 'password',
        required: false,
      },
      {
        name: 'first_name',
        displayName: 'First Name',
        tooltip: 'Provide the user first name',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'last_name',
        displayName: 'Last Name',
        tooltip: 'Provide the user last name',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'organisation_id',
        displayName: 'Organisation',
        tooltip: 'Organization to which the user belongs',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'organisation',
        required: true,
      },
      {
        name: 'role_id',
        displayName: 'Role',
        tooltip: 'Role or position assigned to the user within the organization',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'role',
        required: true,
      },
      {
        name: 'home_url',
        displayName: 'Home URL',
        tooltip: 'URL for the user home page or dashboard within the application',
        dataType: 'shorttext',
        required: false,
      },
      {
        name: 'contacts',
        displayName: 'Contacts',
        dataType: 'reference',
        referenceType: 'multi-nested',
        referenceEntityType: 'contact',
      },
      {
        name: 'signature_upload',
        displayName: 'SignatureUpload',
        dataType: 'component',
        component: SignatureUpload,
        propName: 'user',
      },
      {
        name: 'delisted',
        displayName: 'Is Delisted',
        tooltip: 'Indicate whether the user is currently delisted (Select Yes or No)',
        dataType: 'boolean',
        containBlankOption: false,
      },
      {
        name: 'business_use',
        displayName: 'Business Use',
        tooltip: 'Select whether the user is associated with the Buyer or is applicable to All',
        dataType: 'reference',
        referenceType: 'multi-select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'business_use',
      },
    ],
  },
];
export default schema;
