import Constants from '../../../Constants';

const schema = [
  {
    name: 'claim_entity',
    displayName: 'Claim Entities',
    systemIdField: 'id',
    shouldNotPluralize: true,
    displayIdField: 'id',
    path: '/claims/entity',
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        tooltip: 'The name of the claim entity.',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'type',
        displayName: 'Type',
        tooltip: 'The type of claim, such as Quantity, Time, or other categories.',
        dataType: 'simple-select',
        options: Constants.CLAIM_ENTITY_TYPES,
      },
      {
        name: 'is_required',
        displayName: 'Required',
        tooltip: 'Indicates whether providing this claim entity is mandatory (Yes) or optional (No).',
        dataType: 'boolean',
      },
    ],
  },
];
export default schema;
