import { connect } from 'react-redux';
import PurchaseOrderPageComponent from '../../components/pages/PurchaseOrderComponent';
import Constants from '../../Constants';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';
import { fetchPurchaseOrderHistory } from '../../slices/po-history/poHistoryReducers';
import { selectCurrentOrder } from '../../slices/basic/order';
import { fetchNetsuitePurchaseOrder } from '../../actions/actions_orders';
import { fetchEntitiesData } from '../../slices/masterData/entityManagerSlice';
import { fetchTransporters } from '../../actions/actions_transporters';
import { fetchPackageWeights } from '../../actions/actions_package_weights';
const mapStateToProps = (state, props) => {
  const currentOrder = state.orders.netsuite_purchase_order || {};
  const { end_users: endUsers, surcharge_types: surchargeTypes } = { ...findEntitiesInState(state) };
  return {
    ...findEntitiesInState(state),
    currentOrder,
    user: state.user,
    users: state.users || [],
    voyage: currentOrder?.voyage?.[0] || Constants.INITIAL_VOYAGE,
    poDocuments: state.po_documents || [],
    orders: state.orders,
    email: state.email,
    configs: state.configs,
    endUsers,
    surchargeTypes,
    settingsConfig: state.settingsConfig,
    ...props,
    packageWeights: state.packageWeights,
    transporters: state.transporters,
    token: state.user.token,
    poHistory: state.poHistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPoHistroy: (token , orderID) => dispatch(fetchPurchaseOrderHistory(token, orderID)),
    fetchPurchaseOrder: (token, orderID) => dispatch(fetchNetsuitePurchaseOrder(token, orderID)),
    fetchPackageWeight: () => dispatch(fetchPackageWeights()),
    fetchTransporter: token => dispatch(fetchTransporters(token)),
    fetchSettingsConfig: token => dispatch(fetchSettingsConfig(token)),
    fetchEntitiesData: () => dispatch(fetchEntitiesData()),

  }
};

const PurchaseOrder = connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderPageComponent);

export default PurchaseOrder;
