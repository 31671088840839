import get from 'lodash/get';
import Constants from '../../../../Constants';
import { string } from 'prop-types';

export async function fetchWeight(inputProductUid, pakerUid) {
  const accessToken = localStorage.getItem('boabUser');
  if (!accessToken) {
    return {};
  }
  const json = JSON.parse(accessToken);
  const { token } = json;
  const header = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Token ${token}`,
  });
  const response = await fetch(
    `${Constants.URL}package_weight?packer_plant_id=${pakerUid}&input_product_uid=${inputProductUid}`,
    {
      method: 'GET',
      headers: header,
    }
  )
    .then(resp => {
      if (!resp.ok) {
        throw new Error(resp.statusText);
      }
      return resp.json();
    })
    .catch(err => {
      console.log(err);
      return {};
    });

  return response;
}

function genericDetermineWeightPerCarton(inputProduct, packerUid, purchasingOffice, packageWeights, unit) {
  let packageWeight;
  let inputProductUid;
  // TODO Fix inputProduct type to be consistent in all func calls
  if (inputProduct) {
    if (typeof inputProduct === 'string') {
      inputProductUid = inputProduct;
    } else if (!('uid' in inputProduct)) {
      inputProductUid = inputProduct?.value;
    } else {
      inputProductUid = inputProduct.uid;
    }
  }

  // Look for matches on both input product and packer plant first
  packageWeight = packageWeights.find(
    pw => pw.inputProductUid === inputProductUid && pw.packerPlantId === packerUid && pw.unitOfMeasure?.name === unit
  )?.weight;

  // If no matches exist, look for matches on just input product
  if (!packageWeight) {
    packageWeight = packageWeights.find(
      pw => pw.inputProductUid === inputProductUid && pw.unitOfMeasure?.name === unit
    )?.weight;
  }

  // If no match, look for just packer_plant_id
  if (!packageWeight) {
    packageWeight = packageWeights.find(
      pw => pw.packerPlantId === packerUid && pw.unitOfMeasure?.name === unit
    )?.weight;
  }

  // If no matches exist, just get the standard weight for the unit of measure
  if (!packageWeight) {
    packageWeight = packageWeights.find(
      pw => !pw.inputProductUid && !pw.packerPlantId && pw.unitOfMeasure?.name === unit
    )?.weight;
  }

  // If we still haven't matched anything, default to a conversion rate of 1, which will just return the number passed in
  if (!packageWeight) {
    packageWeight = 1;
  }

  return packageWeight;
}

function genericCartonConversion(cartonCount, inputProduct, packerUid, purchasingOffice, packageWeights, unit) {
  const weightPerCarton = genericDetermineWeightPerCarton(
    inputProduct,
    packerUid,
    purchasingOffice,
    packageWeights,
    unit
  );
  return cartonCount * weightPerCarton;
}

export function determineKgsPerCarton(inputProduct, packerUid, purchasingOffice, packageWeights, unit) {
  return genericDetermineWeightPerCarton(inputProduct, packerUid, purchasingOffice, packageWeights, unit);
}

export function convertCartonsToKg(cartonCount, inputProduct, packerUid, purchasingOffice, packageWeights, unit) {
  return genericCartonConversion(cartonCount, inputProduct, packerUid, purchasingOffice, packageWeights, unit);
}

export function determineLbsPerCarton(inputProduct, packerUid, purchasingOffice, packageWeights, unit) {
  return genericDetermineWeightPerCarton(inputProduct, packerUid, purchasingOffice, packageWeights, unit);
}

export function convertCartonsToLbs(cartonCount, inputProduct, packerUid, purchasingOffice, packageWeights, unit) {
  return genericCartonConversion(cartonCount, inputProduct, packerUid, purchasingOffice, packageWeights, unit);
}

export function convertCartonsToUnitOfMeasure(
  cartonCount,
  unitOfMeasure,
  inputProduct,
  packerUid,
  purchasingOffice,
  packageWeights
) {
  if (!cartonCount || !unitOfMeasure || typeof unitOfMeasure !== 'string') return 0;

  const unit = unitOfMeasure.toLowerCase();
  const weight = genericCartonConversion(cartonCount, inputProduct, packerUid, purchasingOffice, packageWeights, unit);

  return weight.toFixed(2);
}

// TODO pass packageWeights when acccessing below function
export const calculateBuyQuantityCarton = (
  buyUnitOfMeasureId,
  unitsOfMeasure,
  packageCount,
  inputProduct,
  packerPlantId,
  purchasingOffice
  // packageWeights
) => {
  const uom = unitsOfMeasure.find(_uom => _uom.id === buyUnitOfMeasureId);

  const unit = get(uom, 'name', '');

  return convertCartonsToUnitOfMeasure(packageCount, unit, inputProduct, packerPlantId, purchasingOffice, null);
};

// TODO - for handline scenarios with poitems comes as bins
// TODO pass packageWeights when acccessing below function
export const calculateBuyQuantityBin = (
  buyUnitOfMeasureId,
  unitsOfMeasure,
  packageCount,
  inputProduct,
  packerPlantId,
  purchasingOffice
  // packageWeights
) => {
  const uom = unitsOfMeasure.find(_uom => _uom.id === buyUnitOfMeasureId);

  const unit = get(uom, 'name', '');
  return convertCartonsToUnitOfMeasure(packageCount, unit, inputProduct, packerPlantId, purchasingOffice, null);
};

export function convertUnitOfMeasureToCartons(
  value,
  unitOfMeasure,
  inputProduct,
  packerUid,
  purchasingOffice,
  packageWeights
) {
  const unitsOfMeasurePerCarton = convertCartonsToUnitOfMeasure(
    1,
    unitOfMeasure,
    inputProduct,
    packerUid,
    purchasingOffice,
    packageWeights
  );

  const cartons = value / unitsOfMeasurePerCarton;
  return cartons;
}

export function lookupUomName(unitsOfMeasure, unitOfMeasureId) {
  const uom = unitsOfMeasure.find(item => item.id === unitOfMeasureId);

  if (uom) {
    const { name } = uom;
    return name;
  }
  return '';
}
