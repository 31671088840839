import React from 'react';
import { defaultTo, get, isEmpty } from 'lodash';
import { convertToSnakeCase, getOr } from '../../helpers';
import { ReactComponent as PDFIconSVG } from '../../assets/pdf.svg';
import { ReactComponent as DOCIconSVG } from '../../assets/doc.svg';
import { ReactComponent as XMLIconSVG } from '../../assets/xls.svg';
import { checkIfIsAdminUser, checkIfIsGrinderUser, checkIfIsPackerUser } from '../../utils';
import moment from 'moment';
import Constants from '../../Constants';

const prepareFinalPayload = payload => convertToSnakeCase(payload);

const unNestObjectValues = obj => {
  return Object.entries(obj || {}).reduce((acc, [key, property]) => {
    let temp = acc;
    if (
      property &&
      typeof property === 'object' &&
      !Array.isArray(property) &&
      (property.value || !isNaN(property.value) || !isEmpty(property.value) || (property.id && property.name))
    )
      temp = {
        ...temp,
        [key]: property.value
      };
    else {
      temp = {
        ...temp,
        [key]: property,
      };
    }
    return temp;
  }, {});
};

const buildClaimEntities = claims => {
  const claimEntities = Object.entries(claims || {}).reduce((acc, [key, dataObj]) => {
    const { unitOfMeasureId, value, grinderPricePerUnit, approvedPerUnit, label, id } = dataObj;
    return approvedPerUnit || grinderPricePerUnit || label || value
      ? [
          ...acc,
          {
            id,
            claim_entity_type_id: key.split('claim')[1],
            unitOfMeasureId,
            value,
            grinderPricePerUnit,
            label,
            approvedPerUnit: isNaN(parseFloat(approvedPerUnit)) ? null : approvedPerUnit,
          },
        ]
      : acc;
  }, []);

  return isEmpty(claimEntities) ? undefined : claimEntities;
};

export const prepareCreateNCRPayload = (form, purchaseOrder, user) => {
  const { ncrType, amountAffected, purchaseOrderLineId, claims, nonConformanceSubType, claim, closedByValue } =
    unNestObjectValues(form);
  const ncrDetails = unNestObjectValues(form.ncrDetails);
  const { initialClaimValue, willMakeClaim } = ncrDetails;
  const { id: purchaseOrderId } = purchaseOrder;
  const eventType = ncrType === Constants.NCR_TYPES.NCR ? 'non_conformance' : Constants.NCR_TYPES.INCIDENT;
  let adminAcceptance = {};
  if (checkIfIsAdminUser(user)) {
    adminAcceptance = unNestObjectValues(get(claim, 'adminAcceptance', {}));
    if (!isEmpty(adminAcceptance)) adminAcceptance.userId = user.id;
  }
  const claimEntities = buildClaimEntities(claims);
  const { nonConformanceType } = form;

  if (nonConformanceType) {
    delete nonConformanceType.subCategories;
    delete nonConformanceType.label;
    delete nonConformanceType.value;
  }
  delete ncrDetails.initialClaimValue;

  const closedByHasChanges = !isEmpty(closedByValue);

  return prepareFinalPayload({
    ncrType,
    purchaseOrders: [
      {
        purchaseOrderId,
        purchaseOrderLineId,
        unitOfMeasureId: ncrDetails.unitOfMeasure,
        amountAffected,
      },
    ],
    ...(closedByHasChanges && {
      closedByValue,
      closedByUserId: user.id,
    }),
    ncrDetails: {
      ...ncrDetails,
      nonConformanceTypeId: nonConformanceType,
      nonConformanceSubTypeId: nonConformanceSubType,
      type: eventType,
      purchaseOrderLineId,
      unitOfMeasureId: ncrDetails.unitOfMeasure,
      ...(willMakeClaim && initialClaimValue && !isNaN(initialClaimValue) && { initialClaimValue }),
    },
    ...(willMakeClaim && {
      claim: { adminAcceptance, claimEntities, currencyCode: claim.currencyCode, isUnderReview: claim.isUnderReview },
    }),
    ncrMedia: form.uploadedAttachments,
  });
};

export const prepareCreateIncidentPayload = (form, purchaseOrder, user) => {
  const { ncrType, amountAffected, purchaseOrderLineIds, willPotentialClaimValues, claims, nonConformanceSubType, claim, closedByValue } =
    unNestObjectValues(form);
  const ncrDetails = unNestObjectValues(form.ncrDetails);
  const eventType = ncrType === Constants.NCR_TYPES.NCR ? 'non_conformance' : Constants.NCR_TYPES.INCIDENT;
  const { nonConformanceType } = form;

  if (nonConformanceType) {
    delete nonConformanceType.subCategories;
    delete nonConformanceType.label;
    delete nonConformanceType.value;
  }

  let potentialClaimValues = []
  if (willPotentialClaimValues) {
    potentialClaimValues = willPotentialClaimValues.filter(claimValue => claimValue.value === 'true').map(cv => cv.id)
  }

  if (purchaseOrderLineIds) {
    for (const poLine of purchaseOrderLineIds) {
      if (poLine.unitOfMeasure) {
        poLine.unitOfMeasureId = get(poLine, 'unitOfMeasure.id', null)
        delete poLine.unitOfMeasure
      }
      if (potentialClaimValues.includes(poLine.purchaseOrderId)){
        poLine.willMakeClaim = true
      }
    }
  }

  const isMultiPacker = () => {
    let packerNames = purchaseOrderLineIds.map( poLine => poLine.packerName)
    if ( new Set(packerNames).size > 1){
      return true
    } else {
      return false
    }
  }

  ncrDetails.incidentMedia = ncrDetails.ncrMedia

  delete ncrDetails.ncrMedia
  delete ncrDetails.initialClaimValue

  return prepareFinalPayload({
    ncrType,
    purchaseOrders: [
      ...purchaseOrderLineIds
    ],
    incidentDetails: {
      ...ncrDetails,
      isMultiPacker: isMultiPacker(),
      nonConformanceTypeId: nonConformanceType,
      nonConformanceSubTypeId: nonConformanceSubType,
      type: eventType,
      unitOfMeasureId: ncrDetails.unitOfMeasure
    },
    incidentMedia: form.uploadedAttachments,
  });
}


export const prepareUpdateIncidentPayload = (form, purchaseOrder, user) => {
  const { ncrType, amountAffected, purchaseOrderLineIds, recordNo, isEntrapment,isIncident,isMultiPacker,groupId } =
    unNestObjectValues(form);
  const ncrDetails = unNestObjectValues(form.ncrDetails);
  const eventType = ncrType === Constants.NCR_TYPES.NCR ? 'non_conformance' : Constants.NCR_TYPES.INCIDENT;
  const { nonConformanceType,nonConformanceSubType } = form;
  let unitOfMeasureId = null
  if (nonConformanceType) {
    delete nonConformanceType.subCategories;
    delete nonConformanceType.label;
    delete nonConformanceType.value;
  }

  if (purchaseOrderLineIds) {
    for (const poLine of purchaseOrderLineIds) {
      if (poLine.unitOfMeasure) {
        unitOfMeasureId = get(poLine, 'unitOfMeasure.id', null)
        delete poLine.unitOfMeasure
      }
    }
  }
  delete ncrDetails.ncrMedia
  delete ncrDetails.initialClaimValue

  return prepareFinalPayload({
    record_no: recordNo,
    ncrType,
    purchaseOrders: [
      ...purchaseOrderLineIds
    ],
    incidentDetails: {
      ...ncrDetails,
      isIncident,
      incidentMedia: form.uploadedAttachments,
      isMultiPacker,
      quantityAffected: amountAffected,
      nonConformanceSubTypeId:nonConformanceSubType?.id,
      nonConformanceTypeId: nonConformanceType,
      unitOfMeasureId: unitOfMeasureId
    },
    incidentMedia: form.uploadedAttachments,
  });
}



export const calculateInitialClaimValue = (amountAffected, sellPricePerUnit, ncrUom, sellUom) => {
  let initialClaimValue = parseFloat((amountAffected * sellPricePerUnit).toFixed(2));
  const gramsPerNcrUom = get(ncrUom, 'conversionRate');
  const gramsPerSellUom = get(sellUom, 'conversionRate');
  if (gramsPerNcrUom === gramsPerSellUom) return initialClaimValue;
  if (!gramsPerNcrUom || !gramsPerSellUom) return null;

  const sellPricePerGram = sellPricePerUnit / gramsPerSellUom;
  initialClaimValue = amountAffected * (sellPricePerGram * gramsPerNcrUom);
  return parseFloat(initialClaimValue.toFixed(2));
};

export const getAttachmentIcon = fileName => {
  const splitName = fileName.split('.');
  const extension = splitName[splitName.length - 1];
  const xmlExtensions = ['dat', 'csv', 'tsv', 'xls', 'xlsx', 'ods', 'sxc', 'dif'];
  const pdfExtensions = ['pdf'];
  const docExtensions = ['doc', 'docm', 'docx', 'dot', 'dotx', 'txt', 'ppt', 'pptx', 'odt'];
  if (xmlExtensions.includes(extension)) return { extension, icon: <XMLIconSVG /> };
  if (pdfExtensions.includes(extension)) return { extension, icon: <PDFIconSVG /> };
  if (docExtensions.includes(extension)) return { extension, icon: <DOCIconSVG /> };
  return { extension, icon: null };
};


export const prepareUpdateNCRPayload = (form, preEditNCR, user) => {

  const { recordNo, amountAffected, claims, claim, nonConformanceSubType, closedByValue } = unNestObjectValues(form);

  const ncrDetails = unNestObjectValues(form.ncrDetails);

  const claimEntities = buildClaimEntities(claims);
  const { nonConformanceType } = form;

  if (nonConformanceType) {
    delete nonConformanceType.subCategories;
    delete nonConformanceType.label;
    delete nonConformanceType.value;
  }
  if (ncrDetails.initialInvestigation) delete ncrDetails.initialInvestigation.user;
  if (ncrDetails.finalInvestigation) delete ncrDetails.finalInvestigation.user;
  const adminAcceptance = unNestObjectValues(get(claim, 'adminAcceptance', {}));
  if (adminAcceptance) delete adminAcceptance.user;

  const claimAcceptanceHasChanges = Object.entries(adminAcceptance).some(([key, value]) => {
    if (key !== 'userId') {
      return get(preEditNCR, `claim.adminAcceptance.${key}`) !== value;
    }
    return false;
  });

  if (adminAcceptance) delete adminAcceptance.user;

  const closedByHasChanges = closedByValue !== get(preEditNCR, 'closedByValue');

  const payload = prepareFinalPayload({
    recordNo,
    amountAffected,
    ...(closedByHasChanges && {
      closedByValue,
      closedByUserId: user.id,
    }),
    nonConformanceType,
    nonConformanceSubTypeId: nonConformanceSubType,
    ncrDetails: {
      ...ncrDetails,
      unitOfMeasure: get(form, 'ncrDetails.unitOfMeasure'),
      unitOfMeasureId: get(ncrDetails, 'unitOfMeasure'),
    },
    claim: {
      claimEntities,
      adminAcceptance: {
        ...adminAcceptance,
        ...(claimAcceptanceHasChanges && {
          userId: user.id,
        }),
      },
      isUnderReview: claim?.isUnderReview,
    },
    ncrMedia: form.uploadedAttachments,
  });

  return payload;
};


export const preparePackerUpdateNCRPayload = (form, preEditNCR, user) => {
  const { ncrDetails, claim, recordNo } = form;
  const { initialInvestigation: initialInvestigationData, finalInvestigation: finalInvestigationData } = unNestObjectValues(ncrDetails);
  const ncrDetailsId = get(ncrDetails, 'id');
  const packerAcceptance = unNestObjectValues(defaultTo(get(claim, 'packerAcceptance'), {}));

  const initialInvestigation = defaultTo(initialInvestigationData, {});
  const finalInvestigation = defaultTo(finalInvestigationData, {});

  delete initialInvestigation.user;
  delete finalInvestigation.user;

  const initialInvestigationHasChanges = Object.entries(initialInvestigation).some(([key, value]) => {
    if (key !== 'id' && key !== 'ncrDetailsId' && key !== 'userId') {
      return get(preEditNCR, `ncrDetails.initialInvestigation.${key}`) !== value;
    }
    return false;
  });

  const finalInvestigationHasChanges = Object.entries(finalInvestigation).some(([key, value]) => {
    if (key !== 'id' && key !== 'ncrDetailsId' && key !== 'userId') {
      return get(preEditNCR, `ncrDetails.finalInvestigation.${key}`) !== value;
    }
    return false;
  });

  const claimAcceptanceHasChanges = Object.entries(packerAcceptance).some(([key, value]) => {
    if (key !== 'userId') {
      return get(preEditNCR, `claim.packerAcceptance.${key}`) !== value;
    }
    return false;
  });

  const payload = {
    recordNo,
    ncr_details: {
      initialInvestigation: {
        ncr_details_id: ncrDetailsId,
        ...initialInvestigation,
        ...(initialInvestigationHasChanges && { userId: user.id }),
      },
      finalInvestigation: {
        ncr_details_id: ncrDetailsId,
        ...finalInvestigation,
        ...(finalInvestigationHasChanges && { userId: user.id }),
      },
      ncrMedia: form.ncrDetails.ncrMedia,
    },
    claim: {
      packerAcceptance: {
        ...packerAcceptance,
        ...(claimAcceptanceHasChanges && {
          userId: user.id,
        }),
      },
    },
    ncrMedia: form.uploadedAttachments,
  };

  return prepareFinalPayload(
    payload,
    ['investigationDate', 'correctiveActionImplementedDate', 'preventiveActionImplementedDate']
  );
};


export const prepareAdminUpdateNCRPayload = (form, preEditNCR, user) => {
  const { claim, claims, recordNo, amountAffected, nonConformanceSubType, closedByValue } = unNestObjectValues(form);
  const ncrDetails = unNestObjectValues(form.ncrDetails);
  const ncrDetailsId = get(ncrDetails, 'id');
  const adminAcceptance = unNestObjectValues(get(claim, 'adminAcceptance', {}));
  const { monitoringRequired, sendReminderDate } = unNestObjectValues(get(ncrDetails, 'finalInvestigation', {}));

  const { nonConformanceType } = form;

  if (nonConformanceType) {
    delete nonConformanceType.subCategories;
    delete nonConformanceType.label;
    delete nonConformanceType.value;
  }
  const claimEntities = buildClaimEntities(claims);

  const claimAcceptanceHasChanges = Object.entries(adminAcceptance).some(([key, value]) => {
    if (key !== 'userId') {
      return get(preEditNCR, `claim.adminAcceptance.${key}`) !== value;
    }
    return false;
  });

  if (adminAcceptance) delete adminAcceptance.user;

  const claimData = {
    adminAcceptance: {
      ...adminAcceptance,
      ...(claimAcceptanceHasChanges && {
        userId: user.id,
      }),
    },
    claimEntities,
  };

  if (ncrDetails.initialInvestigation) delete ncrDetails.initialInvestigation.user;
  if (ncrDetails.finalInvestigation) delete ncrDetails.finalInvestigation.user;

  const closedByHasChanges = closedByValue !== get(preEditNCR, 'closedByValue');

  return prepareFinalPayload({
    recordNo,
    amountAffected,
    ...(closedByHasChanges && {
      closedByValue,
      closedByUserId: user.id,
    }),
    nonConformanceType,
    nonConformanceSubTypeId: nonConformanceSubType,
    ncrDetails: {
      ...ncrDetails,
      ncr_details_id: ncrDetailsId,
      unitOfMeasure: get(form, 'ncrDetails.unitOfMeasure'),
      unitOfMeasureId: get(ncrDetails, 'unitOfMeasure'),
      finalInvestigation: {
        monitoringRequired,
        sendReminderDate,
      },
    },
    claim: claimData,
    ncrMedia: form.uploadedAttachments,
  });
};

export const checkIfOverdue = (ncr) => {
  const openedDate = getOr(ncr, 'openedDate', null)
  const status = getOr(ncr, 'status', null)
  let dateOpened = new Date(openedDate)
  dateOpened.setHours(0,0,0,0)
  const opened_moment = moment(dateOpened)
  return (
    status != Constants.NCR_STATUES.CLOSED && status != Constants.NCR_STATUES.RAISED && openedDate && moment().diff(opened_moment, 'days') > 30
  )
};

export const checkIfReleaseToPacker = (ncr) => {
  const priority = getOr(ncr, 'claim.priority', null)
  const releaseToPacker = getOr(ncr, 'releaseToPacker', null)
  return (
    ncr.status === Constants.NCR_STATUES.RAISED && priority === Constants.CLAIM_STATUSES.SERIOUS && !releaseToPacker
  )
};

export const checkIfMonitoringRequired = (ncr) => {
  const claimValue = getOr(ncr, 'ncrDetails.finalClaimValue', getOr(ncr, 'ncrDetails.initialClaimValue', null));
  if (!claimValue) return false;
  const monitoringDone = get(ncr, 'monitoringDone', null);
  const monitoringRequired = get(ncr, 'ncrDetails.finalInvestigation.monitoringRequired', null);
  const monitoringRequiredSetAt = get(ncr, 'ncrDetails.finalInvestigation.sendReminderDate', null);
  let dateStr = new Date().toLocaleString("en-US", { timeZone: "Australia/Sydney" });
  let date = new Date(dateStr).setHours(0,0,0,0)
  if (!monitoringRequiredSetAt) return false
  let monitoringRequiredSetAtStr = monitoringRequiredSetAt.toLocaleString("en-US", { timeZone: "Australia/Sydney" });
  let monitoringRequiredSetAtDate = new Date(monitoringRequiredSetAtStr).setHours(0,0,0,0)
  return (
    monitoringRequired &&
    moment(monitoringRequiredSetAt).isValid() &&
    moment(date).diff(moment(monitoringRequiredSetAtDate), 'days') >= 0 && !monitoringDone
  );
};