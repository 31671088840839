import React from 'react';
import Select from 'react-select';

const ModalEditorSelector = ({
  value,
  items,
  valueChange,
  type,
  name,
  valueField = 'id',
  labelField = 'name',
  multi = false,
  className,
  ...rest
}) => {
  let options = items ? items.map(el => ({ value: el[valueField], label: el[labelField] })) : [];
  if (multi) {
    options = [...options, { value: 'all', label: 'Select All' }];
  }
  const valueArray = Array.isArray(value) ? value : [value];
  const selection = options.filter(opt => valueArray.includes(opt.value));
  return (
    <Select
      name={name}
      value={selection}
      options={options}
      placeholder="Select value"
      isMulti={multi}
      isClearable={true}
      onChange={valueChange.bind(this, type, options)}
      className={className}
      styles={{
        container: styles => ({
          ...styles,
          ...rest.containerStyles,
        }),
        control: styles => ({
          ...styles,
          overflowY: 'scroll',
          ...rest.controlStyles,
        }),
        singleValue: styles => ({
          ...styles,
          ...rest.singleValueStyles,
        }),
        multiValue: styles => ({
          ...styles,
          ...rest.multiValueStyles,
        }),
        multiValueLabel: styles => ({
          ...styles,
          ...rest.multiValueLabelStyles,
        }),
        multiValueRemove: styles => ({
          ...styles,
          ...rest.multiValueRemoveStyles,
        }),
        menuPortal: styles => ({
          ...styles,
          ...rest.menuPortalStyles,
        }),
        clearIndicator: styles => ({
          ...styles,
          padding: '0px',
          ...rest.clearIndicatorStyles,
        }),
        dropdownIndicator: styles => ({
          ...styles,
          padding: '0px',
          ...rest.dropdownIndicatorStyles,
        }),
      }}
      components={{
        IndicatorSeparator: () => null,
        ...rest.components,
      }}
    />
  );
};

export default ModalEditorSelector;
