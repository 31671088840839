import { Col, Layout, Result, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import LoadingOverlay from '../../../../shared/LoadingOverlay/LoadingOverlay';
import styles from './QualityDetailsClaims.module.css';
import DetailPageHeading from '../../../../shared/CardTitle/CardTitle';
import {
  selectQualityClaimsError,
  selectQualityClaimsLoading,
  selectQualityClaimsSelectedPackerPlantId,
  selectQualityClaimsShouldBeGroupedByPacker,
} from '../../../../slices/quality-details-claims/selectors/selectBasicDetailsClaimsData';
import { selectQualityClaimsFetchParameters } from '../../../../slices/quality-details-claims/selectors/selectQualityClaimsFetchParameters';
import {
  fetchQualityClaimsData,
  setQualityClaimsFilterBy,
  setQualityClaimsFilteredByPackerPlant,
  setQualityClaimsGroupBy,
  setQualityClaimsGroupedByPackerPlant,
  setQualityClaimsSelectedPackerPlantId,
  setQualityClaimsShouldBeGroupedByPacker,
  setQualityClaimsMarketKey,
  setQualityClaimsRegion,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsReducers';
import {
  FILTER_BY_OPTIONS,
  GROUP_BY_OPTIONS,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsConfig';
import QualityDetailsClaimsFilters from './QualityDetailsClaimsFilters';
import QualityDetailsClaimsSidebar from './QualityDetailsClaimsSidebar';
import selectAvailableYears from '../../../../slices/quality-details-claims/selectors/selectAvailableYears';
import selectAvailableCategories from '../../../../slices/quality-details-claims/selectors/selectAvailableCategories';
import selectAvailableStatuses from '../../../../slices/quality-details-claims/selectors/selectAvailableStatuses';
import selectAvailablePackerPlants from '../../../../slices/quality/selectors/selectAvailablePackerPlants';
import selectAvailableClaimAmounts from '../../../../slices/quality-details-claims/selectors/selectAvailableClaimAmounts';
import selectAvailableDaysToResolve from '../../../../slices/quality-details-claims/selectors/selectAvailableDaysToResolve';
import QualityDetailsClaimsCharts from './QualityDetailsClaimsCharts';
import { setActiveMarket } from '../../../../slices/markets/marketReducers';
import { setMarketKey } from '../../../../slices/quality/qualityReducers';
import TabBar from '../../../../shared/TabBar';
import { MARKETS } from '../../../../config/markets';

// This container is for the Quality Details Claims page as a whole and manages data

export default function QualityDetailsClaims() {
  const dispatch = useDispatch();
  const error = useSelector(selectQualityClaimsError);
  const loading = useSelector(selectQualityClaimsLoading);
  const availableYears = useSelector(selectAvailableYears);
  const availableStatuses = useSelector(selectAvailableStatuses);
  const availableCategories = useSelector(selectAvailableCategories);
  const availableClaimAmounts = useSelector(selectAvailableClaimAmounts);
  const availableDaysToResolve = useSelector(selectAvailableDaysToResolve);
  const availablePackerPlants = useSelector(selectAvailablePackerPlants);
  const selectedPackerPlantId = useSelector(selectQualityClaimsSelectedPackerPlantId);
  const shouldBeGroupedByPacker = useSelector(selectQualityClaimsShouldBeGroupedByPacker);
  const { market: marketParam } = useParams();

  const [dataColors, setDataColors] = React.useState({});

  // Parameters for data fetch
  const fetchParameters = useSelector(selectQualityClaimsFetchParameters);

  React.useEffect(() => {
    dispatch(fetchQualityClaimsData(fetchParameters));
  }, [dispatch, fetchParameters]);

  React.useEffect(() => {
    if (marketParam === MARKETS.Australia.url) {
      dispatch(setActiveMarket(MARKETS.Australia.key));
      dispatch(setMarketKey(MARKETS.Australia.key));
      dispatch(setQualityClaimsMarketKey(MARKETS.Australia.key));
      dispatch(setQualityClaimsRegion(MARKETS.All.key));
    }
    if (marketParam === MARKETS.USA.url) {
      dispatch(setActiveMarket(MARKETS.USA.key));
      dispatch(setMarketKey(MARKETS.USA.key));
      dispatch(setQualityClaimsMarketKey(MARKETS.USA.key));
      dispatch(setQualityClaimsRegion(MARKETS.All.key));
    }
  }, [marketParam, setActiveMarket, setMarketKey, setQualityClaimsMarketKey, setQualityClaimsRegion]);

  const [selectedYears, setSelectedYears] = React.useState([]);
  const [selectedStatuses, setSelectedStatuses] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [selectedClaimAmounts, setSelectedClaimAmounts] = React.useState([]);
  const [selectedDaysToResolve, setSelectedDaysToResolve] = React.useState([]);
  const [highlightedPackerPlants, setHighlightedPackerPlants] = React.useState([]);

  React.useEffect(() => {
    if (selectedPackerPlantId && availablePackerPlants.length) {
      const pac = availablePackerPlants.find(x => x.id === selectedPackerPlantId);
      if (shouldBeGroupedByPacker) {
        dispatch(setQualityClaimsGroupBy(GROUP_BY_OPTIONS.PACKER_PLANT));
        dispatch(setQualityClaimsGroupedByPackerPlant(pac));
        setHighlightedPackerPlants(pac.bottomTenPackerPlants);
      } else {
        dispatch(setQualityClaimsFilterBy(FILTER_BY_OPTIONS.PACKER_PLANT));
        dispatch(setQualityClaimsFilteredByPackerPlant(pac));
      }
      dispatch(setQualityClaimsSelectedPackerPlantId(undefined));
      dispatch(setQualityClaimsShouldBeGroupedByPacker(false));
    }
  }, [selectedPackerPlantId, availablePackerPlants]);

  if (error) {
    return (
      <div>
        <Result title="Error" status="error" subTitle={error} />
      </div>
    );
  }

  return (
    <div style={{ position: 'relative' }} className={styles.QualityDetailsClaims_quality}>
      <TabBar />
      <div style={{ paddingLeft: 24 }}>
        <DetailPageHeading>Claims</DetailPageHeading>
      </div>
      <Layout>
        <Layout.Content>
          <Row gutter={[16, 32]} style={{ padding: `12px 24px 6px` }}>
            <QualityDetailsClaimsFilters />
          </Row>
          <Row gutter={[0, 32]} style={{ backgroundColor: '#ffffff', padding: `12px 24px 6px` }}>
            <Col span={18} style={{ paddingRight: '64px' }}>
              {!loading && (
                <QualityDetailsClaimsCharts
                  dataColors={dataColors}
                  selectedStatuses={selectedStatuses}
                  selectedYears={selectedYears}
                  selectedCategories={selectedCategories}
                  selectedClaimAmounts={selectedClaimAmounts}
                  selectedDaysToResolve={selectedDaysToResolve}
                  highlightedPackerPlants={highlightedPackerPlants}
                />
              )}
            </Col>
            <Col span={6}>
              <QualityDetailsClaimsSidebar
                dataColors={dataColors}
                setDataColors={setDataColors}
                availableYears={availableYears}
                selectedYears={selectedYears}
                setSelectedYears={setSelectedYears}
                availableStatuses={availableStatuses}
                selectedStatuses={selectedStatuses}
                setSelectedStatuses={setSelectedStatuses}
                availableCategories={availableCategories}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                availableClaimAmounts={availableClaimAmounts}
                selectedClaimAmounts={selectedClaimAmounts}
                setSelectedClaimAmounts={setSelectedClaimAmounts}
                availableDaysToResolve={availableDaysToResolve}
                selectedDaysToResolve={selectedDaysToResolve}
                setSelectedDaysToResolve={setSelectedDaysToResolve}
                availablePackerPlants={availablePackerPlants}
                highlightedPackerPlants={highlightedPackerPlants}
                setHighlightedPackerPlants={setHighlightedPackerPlants}
              />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
      <LoadingOverlay loading={loading} />
    </div>
  );
}
