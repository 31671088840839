import { Col, Radio, Row, Space, Statistic, Typography, Checkbox } from 'antd';
import { range } from 'd3';
import numeral from 'numeral';
import React from 'react';
import PropTypes from 'prop-types';

// Local deps
import COLORS from '../../../../modules/colors';
import { WEIGHT_UNIT, COUNTRY_CODE_MAP } from '../../../../modules/const';
import { formatMarketQuantity, formatPercent, formatSI, formatChange } from '../../../../modules/format';
import { IconCowNew } from '../../../../shared/Icons';
import { ANGUS, FRESH_FROZEN, ORDER_PRICE_TYPE } from '../../../../slices/markets/marketsConfig';
import styles from './VolumeTable.module.css';
import { getProductSpecification } from '../../../../config/markets';

// TODO: these should use enums
const GROUP_LABELS = {
  [ORDER_PRICE_TYPE.SPOT]: 'Spot',
  [ORDER_PRICE_TYPE.NOF]: 'NOF',
  [ORDER_PRICE_TYPE.CONTRACT]: 'Contract',
  [ORDER_PRICE_TYPE.RISK_MANAGED]: 'Risk-managed',
  [ORDER_PRICE_TYPE.FORMULA]: 'Formula',
  [FRESH_FROZEN.FROZEN]: 'Frozen',
  [FRESH_FROZEN.FRESH]: 'Fresh',
  [ANGUS.ANGUS]: 'Angus',
  [ANGUS.NOT_ANGUS]: 'Regular',
};

const TARGET_ICONS = 15;

const ICON_INTERVALS = [
  100, 1_000, 5_000, 10_000, 50_000, 100_000, 500_000, 1_000_000, 5_000_000, 10_000_000, 50_000_000, 100_000_000,
  500_000_000,
];

const statisticStringReplacer = (activeMarket, raw) => {
  if (!raw) return raw;
  // eslint-disable-next-line no-template-curly-in-string
  return raw.replace('${market}', activeMarket.key);
};

export default function VolumeTable(props) {
  const {
    marketsVolumeData,
    previousMarketsVolumeData,
    onActiveVolumeGroupingChange,
    volumeGroups,
    activeVolumeGrouping,
    activeMarket,
    volumeOptions,
    onVolumeOptionsChange,
  } = props;

  const volumeGroupBlocks = React.useMemo(
    () =>
      volumeGroups
        .filter(group => marketsVolumeData.statisticValues[group.key] !== null)
        .map(group => {
          return (
            <Col span={6} key={group.key}>
              <Statistic
                title={<span>{group.title}</span>}
                value={marketsVolumeData.statisticValues[group.key]}
                prefix={statisticStringReplacer(activeMarket, group.prefix)}
                suffix={statisticStringReplacer(activeMarket, group.suffix)}
                precision={group.precision}
                formatter={group.formatter}
              />
            </Col>
          );
        }),
    [activeVolumeGrouping, volumeGroups, marketsVolumeData, onActiveVolumeGroupingChange]
  );
  const volumeGroupSwitch = React.useMemo(
    () => (
      <Col span={24}>
        <Radio.Group value={activeVolumeGrouping.key} size="small" style={{ width: '100%' }}>
          {volumeGroups.map(group => {
            return (
              <Radio.Button
                key={group.key}
                value={group.key}
                style={{
                  width: `calc(100% / ${volumeGroups.length})`,
                  textAlign: 'center',
                }}
                onClick={() => onActiveVolumeGroupingChange(group)}
                checked={group.key === activeVolumeGrouping.key}
              >
                {group.label}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </Col>
    ),
    [activeVolumeGrouping, volumeGroups, marketsVolumeData, onActiveVolumeGroupingChange]
  );

  return (
    <>
      <Row gutter={[16, 32]}>
        <Col span={6}>
          <Statistic
            title={<span style={{ color: COLORS.HIGHLIGHT }}>Total</span>}
            value={marketsVolumeData.volumeTotal}
            precision={0}
            formatter={formatMarketQuantity(activeMarket, 1, 0, false)}
            suffix={activeMarket.quantityUnit === WEIGHT_UNIT.POUND ? 'lb' : 'kg'}
          />
        </Col>
        {volumeGroupBlocks}
        {volumeGroupSwitch}
        <Col span={24}>
          <Table
            marketsVolumeData={marketsVolumeData}
            previousMarketsVolumeData={previousMarketsVolumeData}
            activeMarket={activeMarket}
            activeVolumeGrouping={activeVolumeGrouping}
            volumeOptions={volumeOptions}
            onVolumeOptionsChange={onVolumeOptionsChange}
          />
        </Col>
      </Row>
    </>
  );
}

const Table = props => {
  const {
    marketsVolumeData,
    activeMarket,
    previousMarketsVolumeData,
    activeVolumeGrouping,
    volumeOptions,
    onVolumeOptionsChange,
  } = props;

  // It's possible to switch in "domestic orders" when viewing exports broken down by countries.
  // That way throws off because domestic much outweigh export.
  const maximumVolume = Math.max(
    marketsVolumeData.volumeTotal,
    Math.max(...marketsVolumeData.groupRows.map(r => r.volume))
  );

  const raw = maximumVolume / TARGET_ICONS;

  const iconsRepresent = ICON_INTERVALS.reduce((prev, next) => {
    const old = Math.abs(prev - raw);
    const newer = Math.abs(next - raw);

    // Newer is closer to the goal interval
    if (newer < old) return next;
    return prev;
  }, 1);

  const cows = (value, key) => {
    const color = COLORS[key];
    const num = value / iconsRepresent;
    const numFull = Math.floor(num);
    const fractionPercent = num % 1;

    const wholeCows = range(0, numFull).map(i => (
      <div className={styles.cowVisIcon} key={i}>
        <IconCowNew />
      </div>
    ));
    const fractionalCow = fractionPercent > 0 && (
      <div className={styles.cowVisIcon}>
        <IconCowNew />
        <div
          className={styles.cowVisIconCoverUp}
          style={{
            width: `${100 - fractionPercent * 100}%`,
          }}
        />
      </div>
    );

    return (
      <div className={styles.cowVis} style={{ color }}>
        {wholeCows}
        {fractionalCow}
      </div>
    );
  };

  const options = activeVolumeGrouping.options && (
    <div className={styles.options}>
      {activeVolumeGrouping.options.map(option => {
        // TODO: types of options
        const value = volumeOptions[option.key];
        return (
          <div key={option.key}>
            <Checkbox
              onChange={() =>
                onVolumeOptionsChange({
                  ...volumeOptions,
                  [option.key]: !value,
                })
              }
              checked={value}
            >
              {option.label}
            </Checkbox>
          </div>
        );
      })}
    </div>
  );

  const quantityUnit = activeMarket.quantityUnit === WEIGHT_UNIT.POUND ? 'lb' : 'kg';
  const singleCowQuantity = numeral(iconsRepresent).format('0a');

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {marketsVolumeData.groupRows.map(x => {
        const percentageOfVolume = x.volume / marketsVolumeData.volumeTotal;
        const previousGroup = previousMarketsVolumeData.groupRows.find(({ key }) => key === x.key);

        let differenceEl;
        if (previousGroup) {
          const previousPercentageOfVolume = previousGroup.volume / previousMarketsVolumeData.volumeTotal;

          const difference = percentageOfVolume - previousPercentageOfVolume;
          differenceEl = (
            <>
              {formatChange('positive')(difference)} <span>{formatPercent(1)(Math.abs(difference))}</span>
            </>
          );
        }

        const flag = COUNTRY_CODE_MAP[x.key] && (
          <div className={styles.flag}>
            <img src={`${process.env.PUBLIC_URL}/img/flags/${COUNTRY_CODE_MAP[x.key]}.svg`} alt={`${x.key}`} />
          </div>
        );

        return (
          <div className="volume-table">
            <div className={styles.dataRow} key={x.key}>
              <div style={{ width: '25%' }}>
                {flag}
                <Typography.Text style={{ fontSize: '22px' }}>{GROUP_LABELS[x.key] || x.key}</Typography.Text>
              </div>
              <div className={styles.dataVis}>
                <div>{cows(x.volume, x.key)}</div>
                <div className={styles.dataNumbers}>
                  <div style={{ width: '25%' }}>
                    <span className={styles.bold}>{formatSI(1)(x.volume)}</span> {quantityUnit}
                  </div>
                  {x.cost !== undefined && (
                    <>
                      <div style={{ width: '25%', textAlign: 'center' }}>
                        $ <span className={styles.bold}>{formatSI(2, 2)(x.cost)}</span>
                      </div>
                      <div style={{ width: '25%', textAlign: 'center' }}>
                        $ <span className={styles.bold}>{formatSI(2, 2)(x.cost / x.volume)}</span> per unit
                      </div>
                      <div style={{ width: '25%', textAlign: 'center' }}>
                        <span className={styles.bold}>{formatSI(0)(x.averageCL)}</span>{getProductSpecification()} avg
                      </div>
                    </>
                  )}
                </div>
              </div>
              {!x.hidePercent && (
                <div className={styles.percentageWrapper}>
                  <div className={styles.percentage}>
                    <span>{formatPercent(1)(percentageOfVolume)}</span>
                    {differenceEl}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
      <div className={styles.legend}>
        <IconCowNew />
        <span className={styles.bold} style={{ marginRight: 5 }}>
          {/* <!-- TODO - Add locale or format to numeral in the future --> */}
          {singleCowQuantity.includes('k') ? singleCowQuantity : singleCowQuantity.toUpperCase()}
        </span>
        <span>{quantityUnit}</span>
        {options}
      </div>
      <div className={styles.subtext}>* Panel reflects ordered quantities over the selected time period.</div>
    </Space>
  );
};

// TODO define props shape
VolumeTable.propTypes = {
  marketsVolumeData: PropTypes.shape({
    volumeTotal: PropTypes.number,
    statisticValues: PropTypes.shape(),
    groupRows: PropTypes.arrayOf({
      volume: PropTypes.number,
      key: PropTypes.string,
    }),
  }),
  previousMarketsVolumeData: PropTypes.shape(),
  onActiveVolumeGroupingChange: PropTypes.func,
  volumeGroups: PropTypes.shape(),
  activeVolumeGrouping: PropTypes.shape(),
  activeMarket: PropTypes.shape(),
  volumeOptions: PropTypes.shape(),
  onVolumeOptionsChange: PropTypes.func,
};

Table.propTypes = {
  marketsVolumeData: PropTypes.shape({
    volumeTotal: PropTypes.number,
    statisticValues: PropTypes.shape(),
    groupRows: PropTypes.arrayOf({
      volume: PropTypes.number,
      key: PropTypes.string,
    }),
  }),
  previousMarketsVolumeData: PropTypes.shape(),
  activeVolumeGrouping: PropTypes.shape(),
  activeMarket: PropTypes.shape(),
  volumeOptions: PropTypes.shape(),
  onVolumeOptionsChange: PropTypes.func,
};
