import { Icon } from '@ant-design/compatible';
import { camelCase, cloneDeep, isEmpty, upperFirst } from 'lodash';
import mapboxgl from 'mapbox-gl';
import React, { Component } from 'react';
import { fetchCurrentAffairsDataSource } from '../../../actions/actions_current_affairs';
import './CurrentAffairs.scss';

export default class CurrentAffairs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIssues: [
        /* example keys of the array that goes here
         {
          key: null,
          color: null,
          heading: null
        } */
      ],
    };
  }

  componentDidMount() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiY2Zjb2pvbm8iLCJhIjoiY2toeWNhOXh3MGY0cDJ4b2RhMG54M3ExaSJ9.hlvEPe6nwdJ1a3z1dGB5bA';
    if (isEmpty(this.props.current_affairs)) {
      this.props.dispatch(fetchCurrentAffairsDataSource(this.props.user.token));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.current_affairs != this.props.current_affairs) {
      const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/dark-v9',
        // default centered to USA
        center: [-105.7129, 38.0902],
        zoom: 3.0,
      });

      // TODO to use a smart way to provide circle radius
      // const clusterRadiusVariants = []
      // const data = [...Array(10).keys()].every(p => clusterRadiusVariants.push(p,p+5))
      map.once('style.load', ev => {
        const currentAffairs = cloneDeep(this.props.current_affairs);
        Object.keys(currentAffairs).forEach(key => {
          if (currentAffairs[key].visible == true) {
            // TODO avoid hardcoding 'confirmed' and find a way to use clusterKey
            const clusterKey = currentAffairs[key].textField;
            map.addSource(key, {
              type: 'geojson',
              data: currentAffairs[key].dataSource,
              cluster: currentAffairs[key].cluster,
              clusterMaxZoom: 14,
              clusterRadius: 30,
              // TODO FIXME hard coded
              clusterProperties: {
                confirmed: ['+', ['get', clusterKey]],
              },
            });
            const { layerInfo } = currentAffairs[key];
            layerInfo.map(info => {
              // paint will have properties like colour, radius
              if (info.layerType.type == 'paint') {
                // this is for clustering, not needed for packers and grinders or non clustered data
                if (currentAffairs[key].cluster == true) {
                  map.addLayer({
                    id: info.id,
                    type: info.type,
                    source: info.source,
                    filter: ['>', `${currentAffairs[key].textField}`, 0],
                    paint: {
                      'circle-color': info.layerType.color,
                      'circle-stroke-color': info.layerType.color,
                      'circle-opacity': 0.5,
                      // TODO FixME to use circle radius mathematical expression
                      'circle-radius': [
                        'step',
                        ['get', `${currentAffairs[key].textField}`],
                        0,
                        0,

                        5,
                        500,

                        7,
                        1000,

                        8,
                        5000,

                        13,
                        10000,

                        15,
                        20000,

                        18,
                        30000,

                        21,
                        50000,

                        23,
                        100000,

                        40,
                      ],
                    },
                  });
                  // clustered mostly current issues, display properties in it on click
                  map.on('click', info.id, function (e) {
                    const data = [];
                    for (const [key, value] of Object.entries(e.features[0].properties)) {
                      // TODO FixME  avoid showing mapbox default cluster information better
                      if (!(key.match(/^cluster.*$/) || key.match(/^point_count.*$/))) {
                        data.push(`${upperFirst(camelCase(key))}: ${parseInt(value)}`);
                      }
                    }
                    new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(data).addTo(map);
                  });
                } else {
                  map.addLayer({
                    id: info.id,
                    type: info.type,
                    source: info.source,
                    paint: {
                      'circle-color': info.layerType.color,
                      'circle-stroke-color': info.layerType.color,
                      'circle-stroke-width': 1,
                      'circle-radius': info.layerType.radius,
                    },
                  });

                  // non-cluster mostly packer_plant and grinder, display name on click
                  map.on('click', info.id, function (e) {
                    new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(e.features[0].properties.name).addTo(map);
                  });
                }

                // populating state to use in info-box
                const currentIssues = cloneDeep(this.state.currentIssues);
                currentIssues.push({
                  key,
                  color: info.layerType.color || '',
                  heading: `${key.toUpperCase()} ${upperFirst(camelCase(currentAffairs[key].textField))}`,
                });
                this.setState({ currentIssues });
              }
              // layout is used for displaying text fields
              // TODO FixME cluster sum not working
              else if (info.layerType.type == 'layout') {
                map.addLayer({
                  id: info.id,
                  type: info.type,
                  source: info.source,
                  filter: ['>', `${currentAffairs[key].textField}`, 0],
                  layout: {
                    'text-field': `{${currentAffairs[key].textField}}`,
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 12,
                    'text-allow-overlap': true,
                  },
                });
              }
            });
          }
        });
        // Hide loading bar once tiles from geojson are loaded
        map.on('data', function (e) {
          if (e.dataType === 'source') {
            document.getElementById('loader').style.visibility = 'hidden';
          }
        });
      });
    }
  }

  render() {
    return (
      <div className="current-affairs">
        <div className="current-affairs__info-box">
          {this.state.currentIssues &&
            this.state.currentIssues.map(topic => {
              if (topic.key == 'covid19') {
                return (
                  <div key={topic.key}>
                    <i className="fa fa-square" style={{ color: topic.color }} /> {topic.heading}
                    <br />
                    <i className="fa fa-long-arrow-right current_affairs__child-info-arrow" />
                    <a href="/aus-covid19-details" target="_blank" className="current_affairs__child-info-data">
                      AUS COVID DATA
                    </a>
                  </div>
                );
              }
              return (
                <div key={topic.key}>
                  <i className="fa fa-square" style={{ color: topic.color }} /> {topic.heading}
                  <br />
                </div>
              );
            })}
        </div>
        <div id="map" />
        <div
          className="loader loader--style1"
          title="0"
          id="loader"
          style={{ textAlign: 'center', margin: '15vh auto' }}
        >
          <Icon type="loading" />
        </div>
      </div>
    );
  }
}
