import React, { useEffect, useState } from 'react';
import { Field, useField } from 'formik';
import { startCase } from 'lodash';
import ModalEditorSelector from '../../utilities/ModalEditorSelector';
import Constants from '../../../Constants';
import { fetchEntity } from '../../../slices/masterData/entityManagerSlice';
import { useDispatch } from 'react-redux';
import ReferenceSelectField from '../../masterData/fields/ReferenceSelectField';
import { Box, FormLabel, Alert, AlertDescription, AlertIcon, Wrap, IconButton } from '@chakra-ui/react';
import get from 'lodash/get';
import Tooltip from '../../quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

export const FieldErrorMessage = ({ errors, fieldName }) => {
  const error = get(errors, fieldName);
  if (!error) return false;
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertDescription>Required</AlertDescription>
    </Alert>
  );
};
const PoStrategy = ({ entityManager, errors, ...rest }) => {
  const [poStrategy, setPoStrategy] = useState('');
  const PoStrategyEntity = entityManager.data.form_fields;
  const PoSeriesEntity = entityManager.data.po_series;
  const poSeriesField = {
    name: 'po_series_id',
    displayName: 'PO Series',
    dataType: 'reference',
    referenceType: 'select',
    referenceEntityType: 'po_series',
    required: true,
  };

  const grinderField = {
    name: 'po_strategy',
    displayName: 'PO Strategy',
    tooltip: 'Select Manual, Sequence, or Custom to define the PO strategy of the Grinder.',
    dataType: 'reference',
    referenceType: 'select',
    referenceEntityType: 'form_fields',
    referenceEntitySubType: 'po_strategy',
  };
  function validate(value) {
    let error;
    if (
      !value &&
      (poStrategy === Constants.PO_STRATEGY.SEQUENCE || poStrategy === Constants.PO_STRATEGY.SEQUENCE_EDITABLE)
    ) {
      error = 'Required';
      errors = {
        ...errors,
        po_series_id: 'Required',
      };
    }
    return error;
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchEntity(entityManager.data.po_series.entityDefinition));
  }, []);
  // override
  const CustomReferenceSelectField = ({
    name,
    referenceEntityType,
    referenceEntitySubType,
    referenceType,
    referencedEntity,
  }) => {
    const [field, meta, helpers] = useField(name);
    const { setValue } = helpers;
    let entities = referencedEntity && referencedEntity.entities;
    let { systemIdField, displayIdField } = referencedEntity.entityDefinition;
    const multi = referenceType === 'multi-select';

    const onChange = (type, options, changes) => {
      if (!changes) {
        setPoStrategy(changes);
        return setValue(changes);
      }
      if (poStrategy !== changes.value) {
        setPoStrategy(changes.value);
        return setValue(changes.value);
      }
    };
    if (referenceEntitySubType) {
      entities = entities
        .filter(ent => ent.type === referenceEntitySubType)
        .map(ent => ({
          id: ent.value,
          name: startCase(ent.value),
        }));
      systemIdField = 'id';
      displayIdField = 'name';
    }

    if (!systemIdField || !displayIdField) {
      entities = entities.map(ent => ({ id: ent, name: ent }));
      systemIdField = 'id';
      displayIdField = 'name';
    }
    if (meta.value && poStrategy !== meta.value) {
      setPoStrategy(meta.value);
    }
    return (
      <ModalEditorSelector
        type={name}
        name="Popp"
        items={entities}
        valueChange={onChange}
        value={meta.value}
        valueField={systemIdField}
        labelField={displayIdField}
        multi={multi}
        className={multi ? 'restrict-height' : ''}
        {...rest}
      />
    );
  };

  const isEditable =
    poStrategy === Constants.PO_STRATEGY.SEQUENCE || poStrategy === Constants.PO_STRATEGY.SEQUENCE_EDITABLE;
  return (
    <Wrap spacing="30px">
      <Box className="form-group" key={grinderField.name} width="234px">
        <FormLabel htmlFor={grinderField.name}>
          {grinderField.displayName}&nbsp;
          {grinderField.tooltip && (
            <Tooltip content={grinderField.tooltip} placement="right">
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
          )}
        </FormLabel>
        <Field
          as={CustomReferenceSelectField}
          name={grinderField.name}
          SubType={grinderField.SubType}
          referencedEntity={PoStrategyEntity}
          referenceEntityType={grinderField.referenceEntityType}
          referenceEntitySubType={grinderField.referenceEntitySubType}
          referenceType={grinderField.referenceType}
        />
      </Box>
      {isEditable && (
        <Box className="form-group" key={poSeriesField.name} width="234px">
          <FormLabel htmlFor={poSeriesField.name}>{poSeriesField.displayName}</FormLabel>
          <Field
            as={ReferenceSelectField}
            name={poSeriesField.name}
            SubType={poSeriesField.SubType}
            referencedEntity={PoSeriesEntity}
            referenceEntityType={poSeriesField.referenceEntityType}
            referenceEntitySubType={poSeriesField.referenceEntitySubType}
            referenceType={poSeriesField.referenceType}
            validate={validate}
          />
          <FieldErrorMessage errors={errors} fieldName={poSeriesField.name} />
        </Box>
      )}
    </Wrap>
  );
};

export default PoStrategy;
