const schema = [
  {
    name: 'voyage_leg',
    displayName: 'Voyage Leg',
    systemIdField: 'id',
    displayIdField: 'vessel_name',
    path: '/voyage_legs',
    tabGroup: { key: 'logistics', label: 'Logistics' },
    deletable: false,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'vessel_name',
        displayName: 'Vessel Name',
        tooltip: 'Name of the vessel assigned to this voyage leg.',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'voyage_number',
        displayName: 'Voyage Number',
        tooltip: 'Unique identifier for the specific voyage leg',
        dataType: 'shorttext',
        required: true,
      },
    ],
  },
];
export default schema;
