import { isEmpty } from 'lodash';
import * as Yup from 'yup';

export const createNCRValidationSchemas = {
  po_details: {
    schema: Yup.object().shape({
      purchaseOrderLineId: Yup.number().required('purchase order line is required'),
      ncrDetails: Yup.object().shape({
        unitOfMeasure: Yup.object().required('uom is required'),
      }),
      amountAffected: Yup.number().required('quantity affected is required'),
    }),
  },
  ncr_detail: [
    {
      schema: Yup.object().shape({
        nonConformanceType: Yup.mixed().required('non conformance category is required'),
        nonConformanceSubType: Yup.mixed().required('non conformance sub category is required'),
        ncrDetails: Yup.object().shape({
          // processStage: Yup.mixed().required('process stage is required'),
          // productStatus: Yup.mixed().required('product status is required'),
          targetClosureDate: Yup.mixed().required('target closure date is required'),
          isOnHold: Yup.boolean().nullable(),
          isFinishedGoods: Yup.boolean().nullable(),
          details: Yup.string().required('details is required'),
          willMakeClaim: Yup.boolean().nullable(),
        }),
      }),
    },
    {
      schema: Yup.object().shape({
        ncrDetails: Yup.object().shape({
          immediateAction: Yup.string().required('immediate actions is required'),
          furtherAction: Yup.string().nullable(),
          initialClaimValue: Yup.number()
            .when('willMakeClaim', {
              is: FieldA => FieldA,
              then: Yup.number().required(),
            })
            .nullable(),
        }),
      }),
    },
  ],
  claim_value: {
    name: 'claim',
    refEntityName: 'claimEntities',
    isDynamic: true,
    createDynamicSchema: (isRequired, entityType, priority) => {
      return isRequired
        ? Yup.object().shape({
            ...(entityType === 'quantity' && { unitOfMeasureId: Yup.string().required('unit of measure') }),
            ...(entityType === 'other' && { label: Yup.string().required('label') }),
            value: Yup.string().required('measure'),
            grinderPricePerUnit: Yup.string().required('grinder initial value'),
            ...(priority === 'serious'
              ? {
                  approvedPerUnit: Yup.string().typeError('final value').required('final value'),
                }
              : { approvedPerUnit: Yup.string().typeError('final value').nullable() }),
          })
        : Yup.object()
            .shape(
              {
                ...(entityType === 'quantity' && {
                  unitOfMeasureId: Yup.string().when(['grinderPricePerUnit', 'approvedPerUnit'], {
                    is: (grinderPricePerUnit, approvedPerUnit) =>
                      !isEmpty(grinderPricePerUnit) || !isEmpty(approvedPerUnit),
                    then: schema => schema.required('quantity'),
                    otherwise: schema => schema.notRequired(),
                  }),
                }),
                ...(entityType === 'other' && {
                  label: Yup.string()
                    .when(['value', 'grinderPricePerUnit', 'approvedPerUnit'], {
                      is: (value, grinderPricePerUnit, approvedPerUnit) =>
                        !isEmpty(value) || !isEmpty(grinderPricePerUnit) || !isEmpty(approvedPerUnit),
                      then: schema => schema.required('label'),
                      otherwise: schema => schema.notRequired(),
                    })
                    .nullable(),
                }),
                value: Yup.string().when(['grinderPricePerUnit', 'approvedPerUnit'], {
                  is: (grinderPricePerUnit, approvedPerUnit) =>
                    !isEmpty(grinderPricePerUnit) || !isEmpty(approvedPerUnit),
                  then: schema => schema.required('value'),
                  otherwise: schema => schema.notRequired(),
                }),
                grinderPricePerUnit: Yup.string().nullable(),
                ...(priority === 'serious'
                  ? {
                      approvedPerUnit: Yup.string()
                        .when(['grinderPricePerUnit', 'value'], {
                          is: (grinderPricePerUnit, value) => !isEmpty(grinderPricePerUnit) || !isEmpty(value),
                          then: schema => schema.required('final value'),
                          otherwise: schema => schema.notRequired(),
                        })
                        .nullable(),
                    }
                  : { approvedPerUnit: Yup.string().nullable() }),
              },
              [
                ['unitOfMeasureId', 'value'],
                ['unitOfMeasureId', 'grinderPricePerUnit'],
                ['label', 'value'],
                ['label', 'grinderPricePerUnit'],
                ['value', 'grinderPricePerUnit'],
                ['value', 'approvedPerUnit'],
              ]
            )
            .default(null)
            .nullable();
    },
    createStaticSchema(dynamic, priority) {
      return Yup.object().shape({
        claims: Yup.object().shape(dynamic),
        claim: Yup.object()
          .shape({
            adminAcceptance: Yup.mixed(),
            ...(priority === 'serious'
              ? {
                  adminAcceptance: Yup.mixed().required('admin acceptance'),
                }
              : { adminAcceptance: Yup.mixed() }),
            packerAcceptance: Yup.mixed(),
            isUnderReview: Yup.boolean().nullable(),
          })
          .nullable(),
      });
    },
  },
  attachments: {
    schema: Yup.object().shape({
      ncrDetails: Yup.object().shape({
        ncrMedia: Yup.object().shape({
          photos: Yup.array().of(Yup.object()).required(),
          product_labels: Yup.array().of(Yup.object()).required(),
        }),
      }),
    }),
  },
};

export const createIncidentValidationSchemas = {
  po_details: {
    schema: Yup.object().shape({
      purchaseOrderLineIds: Yup.array().of(Yup.object().required().shape({
        purchaseOrderLineId: Yup.number().required('purchasr order line is required'),
        purchaseOrderId: Yup.number().required('purchase order is required'),
        grinderPoNumber: Yup.string().required('Grinder PO number is required.'),
        unitOfMeasure: Yup.object().required('uom is required'),
        amountAffected: Yup.number().required('amountAffected is required')
      })).required()
    }),
  },
  ncr_detail: 
    {
      schema: Yup.object().shape({
        nonConformanceType: Yup.mixed().required('non conformance category is required'),
        nonConformanceSubType: Yup.mixed().required('non conformance sub category is required'),
        ncrDetails: Yup.object().shape({
          isMultiPacker: Yup.boolean().nullable(),
          details: Yup.string().required('details is required'),
        }),
      }),
    },
  attachments: {
    schema: Yup.object().shape({
      ncrDetails: Yup.object().shape({
        ncrMedia: Yup.object().shape({
          attachments: Yup.array().of(Yup.object()).required(),
        }),
      }),
    }),
  },
};


export const editIncidentValidationSchemas = {
  po_details: {
    schema: Yup.object().shape({
      purchaseOrderLineIds: Yup.array().of(Yup.object().required().shape({
        purchaseOrderLineId: Yup.number().required('purchasr order line is required'),
        purchaseOrderId: Yup.number().required('purchase order is required'),
        grinderPoNumber: Yup.string().required('Grinder PO number is required.'),
        unitOfMeasure: Yup.object().required('uom is required'),
        amountAffected: Yup.number().required('amountAffected is required')
      })).required()
    }),
  },
  ncr_detail: 
    {
      schema: Yup.object().shape({
        nonConformanceType: Yup.mixed().required('non conformance category is required'),
        nonConformanceSubType: Yup.mixed().required('non conformance sub category is required'),
        ncrDetails: Yup.object().shape({
          isMultiPacker: Yup.boolean().nullable(),
          details: Yup.string().required('details is required'),
        }),
      }),
    },
  attachments: {
    schema: Yup.object().shape({
      ncrDetails: Yup.object().shape({
        ncrMedia: Yup.object().shape({
          attachments: Yup.array().of(Yup.object()).required(),
        }),
      }),
    }),
  },
};

export const packerEditNCRWithValueValidationSchema = {
  initial_investigation: {
    schema: Yup.object().shape({
      ncrDetails: Yup.object().shape({
        initialInvestigation: Yup.object().shape({
          investigationDate: Yup.string().required(),
          details: Yup.string().required('details is required'),
          isAcknowledged: Yup.bool().oneOf([true], 'must be checked').required(),
        }),
      }),
    }),
  },
  final_investigation: {
    schema: Yup.object().shape({
      ncrDetails: Yup.object().shape({
        finalInvestigation: Yup.object().shape({
          investigationDate: Yup.string().required(),
          rootCause: Yup.string().required(),
          correctiveAction: Yup.string().required(),
          correctiveActionImplementedDate: Yup.string().required(),
          preventiveAction: Yup.string().required(),
          preventiveActionImplementedDate: Yup.string().required(),
        }),
      }),
    }),
  },
  attachments: {
    schema: Yup.object().shape({
      ncrDetails: Yup.object().shape({
        ncrMedia: Yup.object().shape({
          photos: Yup.array().of(Yup.object()).required(),
          product_labels: Yup.array().of(Yup.object()).required(),
        }),
      }),
    }),
  },
  claim_value: {
    schema: Yup.object().shape({
      claim: Yup.object().shape({
        comments: Yup.string(),
        claimValueApproval: Yup.mixed(),
      }),
    }),
  },
};
export const packerEditNCRWithNoValueValidationSchema = {
  initial_investigation: {
    schema: Yup.object().shape({
      ncrDetails: Yup.object().shape({
        initialInvestigation: Yup.object().shape({
          investigationDate: Yup.string().required(),
          details: Yup.string().required('details is required'),
        }),
      }),
    }),
  },
  attachments: {
    schema: Yup.object().shape({
      ncrDetails: Yup.object().shape({
        ncrMedia: Yup.object().shape({
          photos: Yup.array().of(Yup.object()).required(),
          product_labels: Yup.array().of(Yup.object()).required(),
        }),
      }),
    }),
  },
};

export const generalNCRValidationSchema = {
  createDynamicSchema: (isRequired, entityType) =>
    isRequired
      ? Yup.object().shape({
          ...(entityType === 'quantity' && { unitOfMeasureId: Yup.string().required('unit of measure') }),
          value: Yup.string().required('measure'),
          grinderPricePerUnit: Yup.string().required('grinder initial value'),
          approvedPerUnit: Yup.string().nullable(),
          ...(entityType === 'other' && { label: Yup.string().required('label') }),
        })
      : Yup.object()
          .shape(
            {
              ...(entityType === 'quantity' && {
                unitOfMeasureId: Yup.string().when(['value', 'grinderPricePerUnit'], {
                  is: (value, grinderPricePerUnit) => !isEmpty(value) || !isEmpty(grinderPricePerUnit),
                  then: schema => schema.required('label'),
                  otherwise: schema => schema.notRequired(),
                }),
              }),
              ...(entityType === 'other' && {
                label: Yup.string()
                  .when(['value', 'grinderPricePerUnit'], {
                    is: (value, grinderPricePerUnit) => !isEmpty(value) || !isEmpty(grinderPricePerUnit),
                    then: schema => schema.required('label'),
                    otherwise: schema => schema.notRequired(),
                  })
                  .nullable(),
              }),
              value: Yup.string().when(['label', 'grinderPricePerUnit'], {
                is: (label, grinderPricePerUnit) => !isEmpty(label) || !isEmpty(grinderPricePerUnit),
                then: schema => schema.required('value'),
                otherwise: schema => schema.notRequired(),
              }),
              grinderPricePerUnit: Yup.string().when(['label', 'value'], {
                is: (label, value) => !isEmpty(label) || !isEmpty(value),
                then: schema => schema.required('measure'),
                otherwise: schema => schema.notRequired(),
              }),
              approvedPerUnit: Yup.string().nullable(),
            },
            [
              ['unitOfMeasureId', 'value'],
              ['unitOfMeasureId', 'grinderPricePerUnit'],
              ['label', 'value'],
              ['label', 'grinderPricePerUnit'],
              ['value', 'grinderPricePerUnit'],
            ]
          )
          .default(null)
          .nullable(),
  createStaticSchema(dynamic) {
    return Yup.object().shape({
      purchaseOrderLineId: Yup.number().required('purchase order line is required'),
      ncrDetails: Yup.object().shape({
        unitOfMeasure: Yup.object().required('uom is required'),
        processStage: Yup.mixed().required('process stage is required'),
        productStatus: Yup.mixed().required('product status is required'),
        targetClosureDate: Yup.mixed().required('target closure date is required'),
        isOnHold: Yup.boolean().nullable(),
        isFinishedGoods: Yup.boolean().nullable(),
        details: Yup.string().required('details is required'),
        willMakeClaim: Yup.boolean().nullable(),
        immediateAction: Yup.string().required('immediate actions is required'),
        furtherAction: Yup.string().nullable(),
        initialClaimValue: Yup.number()
          .when('willMakeClaim', {
            is: FieldA => FieldA,
            then: Yup.number().required(),
          })
          .nullable(),
        ncrMedia: Yup.object().shape({
          photos: Yup.array().of(Yup.object()).required(),
          product_labels: Yup.array().of(Yup.object()).required(),
        }),
      }),
      amountAffected: Yup.number().required('quantity affected is required'),
      nonConformanceType: Yup.object().required('non conformance category is required'),
      nonConformanceSubType: Yup.object().required('non conformance sub category is required'),
      claims: Yup.object().shape(dynamic),
      claim: Yup.object()
        .shape({
          adminAcceptance: Yup.mixed(),
          packerAcceptance: Yup.mixed(),
          isUnderReview: Yup.boolean().nullable(),
        })
        .nullable(),
    });
  },
};

export const packerGeneralNCRValidationSchema = {
  schema: Yup.object().shape({
    ncrDetails: Yup.object().shape({
      initialInvestigation: Yup.object().shape({
        investigationDate: Yup.string().required(),
        details: Yup.string().required('details is required'),
        isAcknowledged: Yup.bool().oneOf([true], 'must be checked').required(),
      }),
      finalInvestigation: Yup.object().shape({
        investigationDate: Yup.string().required(),
        rootCause: Yup.string().required(),
        correctiveAction: Yup.string().required(),
        correctiveActionImplementedDate: Yup.string().required(),
        preventiveAction: Yup.string().required(),
        preventiveActionImplementedDate: Yup.string().required(),
      }),
      ncrMedia: Yup.object().shape({
        photos: Yup.array().of(Yup.object()).required(),
        product_labels: Yup.array().of(Yup.object()).required(),
      }),
    }),
    claim: Yup.object().shape({
      comments: Yup.string(),
      claimValueApproval: Yup.mixed(),
    }),
  }),
};
