import React, { useRef } from 'react';
import { PropTypes } from 'prop-types';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

const Confirm = ({ onConfirm, children, title }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Please Confirm
            </AlertDialogHeader>

            <AlertDialogBody>{title}</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="actionPrimary" variant="outline" ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="actionPrimary"
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

Confirm.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Confirm;
