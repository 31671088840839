const schema = [
  {
    name: 'load_size',
    displayName: 'Load Size',
    systemIdField: 'id',
    displayIdField: 'origin',
    path: '/load_sizes',
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'origin',
        displayName: 'Origin',
        tooltip: 'Location where the load originates.',
        dataType: 'simple-reference',
        referenceEntityType: 'country',
        required: true,
      },
      {
        name: 'destination',
        displayName: 'Destination',
        tooltip: 'Location where the load is to be delivered.',
        dataType: 'simple-reference',
        referenceEntityType: 'country',
        required: true,
      },
      {
        name: 'maximum_load_size',
        displayName: 'Maximum Load Size',
        tooltip: 'Maximum allowable size or weight for the load.',
        dataType: 'integer',
        required: true,
      },
      {
        name: 'minimum_load_size',
        displayName: 'Minimum Load Size',
        tooltip: 'Minimum required size or weight for the load.',
        dataType: 'integer',
        required: true,
      },
      {
        name: 'unit_of_measure_id',
        displayName: 'Unit Of Measure',
        tooltip: 'Measurement unit used for the load size (e.g., BIN, CTN, KGS, LBS).',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'unit',
      },
    ],
  },
];
export default schema;
