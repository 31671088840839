import Constants from '../Constants';
import api from '../api';
import get from 'lodash/get';

export const PRICED_ORDERS_REQUESTED = 'PRICED_ORDERS_REQUESTED';
export const PRICED_CART_ORDERS_REQUESTED = 'PRICED_CART_ORDERS_REQUESTED';
export const PRICED_ORDERS_FETCHED = 'PRICED_ORDERS_FETCHED';
export const PRICED_ORDERS_ERROR = 'PRICED_ORDERS_ERROR';
export const CLEAR_PRICED_ORDERS = 'CLEAR_PRICED_ORDERS';

export const CART_PRICED_ORDERS_FETCHED = 'CART_PRICED_ORDERS_FETCHED';

const BASE_PATH = `/price-orders`;

/* ---------------------------------
    THUNKS
------------------------------------*/
export function priceOrders(pricingConfig) {
  return dispatch => {
    dispatch(requestPricedOrders());
    return api
      .post(BASE_PATH, pricingConfig)
      .then(response => Constants.handleErrors(response, dispatch, errorPricedOrders))
      .then(json => dispatch(loadPricedOrders(json)))
      .catch(error => dispatch(errorPricedOrders(error)));
  };
}

export function priceCartOrders(pricingConfig) {
  const isLockingPrices = get(pricingConfig, 'shouldLockPrices', false);
  return dispatch => {
    dispatch(requestPricedCartOrders(isLockingPrices));
    return api
      .post(BASE_PATH, pricingConfig)
      .then(response => Constants.handleErrors(response, dispatch, errorPricedOrders))
      .then(json => dispatch(loadPricedCartOrders(json, isLockingPrices)))
      .catch(error => dispatch(errorPricedOrders(error)));
  };
}

/* ---------------------------------
    SIMPLE ACTIONS
------------------------------------*/
export function requestPricedOrders() {
  return {
    type: PRICED_ORDERS_REQUESTED,
  };
}

export function requestPricedCartOrders(isLockingPrices) {
  return {
    type: PRICED_CART_ORDERS_REQUESTED,
    isLockingPrices,
  };
}

export function loadPricedOrders(payload) {
  return {
    type: PRICED_ORDERS_FETCHED,
    payload: get(payload, 'priced_orders') || [],
  };
}

export function clearPricedOrders() {
  return {
    type: CLEAR_PRICED_ORDERS,
  };
}

export function errorPricedOrders(error) {
  return {
    type: PRICED_ORDERS_ERROR,
    payload: error,
  };
}

export function loadPricedCartOrders(payload, isLockingPrices) {
  return {
    type: CART_PRICED_ORDERS_FETCHED,
    payload: get(payload, 'priced_orders') || [],
    isLockingPrices,
  };
}
