import moment from 'moment';
import Constants from '../../../../Constants';

// Used for determining the correct dates for recurring orders
const momentDaysIndex = {
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
  sun: 0,
};

const getRecurringDays = (day, momentDaysIndex, startDate, endDate) => {
  const dayOfTheWeek = momentDaysIndex[day];

  const deliveryDates = [];

  const currentDate = startDate.clone().day(dayOfTheWeek);

  if (currentDate.isSameOrAfter(startDate)) {
    deliveryDates.push(currentDate.clone().format('YYYY-MM-DD'));
  }

  while (currentDate.day(7 + dayOfTheWeek).isSameOrBefore(endDate)) {
    deliveryDates.push(currentDate.clone().format('YYYY-MM-DD'));
  }

  return deliveryDates;
};

export function constructRecurringOrderLinesFromDeliveryDates(agg, item, orderLines) {
  // grab the first key
  const key = Object.keys(item)[0];
  const { dates, numberOfOrderCopies } = item[key];

  const recurringOrderLines = dates.reduce((agg, dateString) => {
    const linesWithDateString = orderLines.map(line => ({
      ...line,
      dateString,
    }));

    // make copies of order lines
    let orderLinesCopies = [];
    for (let i = 0; i < numberOfOrderCopies; i++) {
      orderLinesCopies = [...orderLinesCopies, ...linesWithDateString];
    }

    return [...agg, ...orderLinesCopies];
  }, []);

  return [...agg, ...recurringOrderLines];
}

export function constructOrderLines(line, po, buyUom, sellUom, inputProducts, order) {
  const quantityInSellUOM = (buyUom.conversionRate * line.buyQuantity) / sellUom.conversionRate;
  // Note that we don't have any dates information against each order line just yet
  return {
    ...line,
    inputProduct: inputProducts.find(ip => ip.uid === line.inputProductUid),
    buyPrice: line.buyPricePerUnit * line.buyQuantity,
    quantityInSellUOM,
    sellPrice: line.sellPricePerUnit * quantityInSellUOM,
    buyUom,
    sellUom,
    buyCurrency: order.buyCurrency,
    sellCurrency: order.sellCurrency,
    grinderPoNumber: po.grinderPoNumber,
    internalPoNumber: po.internalPoNumber,
    specifications: line.specifications ? line.specifications : [],
  };
}

/**
 * This will create an arrray of dict recurring day object that is in a form of
 * { day : dates: deliveryDates, numberOfOrderCopies: 0}
 */
export function generateRecurringDeliveryDates(po) {
  if (po.dateType === 'recurring') {
    const { recurringDateStart, recurringDateEnd } = po;

    const startDate = moment(recurringDateStart, 'YYYY-MM-DD');
    const endDate = moment(recurringDateEnd, 'YYYY-MM-DD');

    // check for days selected for recurring orders
    const selectedDays = Object.keys(po.days).filter(k => po.days[k] > 0);

    const recurringDays = selectedDays.map(day => {
      const deliveryDates = getRecurringDays(day, momentDaysIndex, startDate.clone(), endDate);
      return { [`${day}`]: { dates: deliveryDates, numberOfOrderCopies: po.days[day] } };
    });

    return recurringDays;
  }
  return [];
}

export function createConfirmationDateString(
  dateType,
  { deliveryDate, shipmentDateStart, shipmentDateEnd, arrivalDateStart, arrivalDateEnd }
) {
  if (dateType) {
    switch (dateType) {
      case 'shipment':
        return `${shipmentDateStart} to ${shipmentDateEnd}`;
      case 'arrival':
        return `${arrivalDateStart} to ${arrivalDateEnd}`;
      default:
        return deliveryDate;
    }
  } else {
    // means that we are getting the server side response payload upon order submission as date_type is not part of the purchase order schema
    if (deliveryDate) {
      return deliveryDate;
    }

    if (shipmentDateStart) {
      return `${shipmentDateStart} to ${shipmentDateEnd}`;
    }
    if (arrivalDateStart) {
      return `${arrivalDateStart} to ${arrivalDateEnd}`;
    }
  }
}
