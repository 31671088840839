import { createSelector } from '@reduxjs/toolkit';
import startCase from 'lodash/startCase';
import isEmpty from 'lodash/isEmpty';

// Local deps
import Constants from '../../Constants';

import { selectEntities } from './entityManagerSlice';
import { buyersSelector } from '../../reducers/reducer_users';

export const createOptions = (list, labelKey = 'name', valueKey = 'id') => {
  if (!list) return [];

  return list.map(item => ({
    ...item,
    label: typeof labelKey === 'function' ? labelKey(item) : item[labelKey],
    value: typeof valueKey === 'function' ? valueKey(item) : item[valueKey],
  }));
};

// Select Order Options
export const selectOrderOptions = createSelector(selectEntities, entities => {
  const isLoading = isEmpty(entities?.grinder?.entities || []) || isEmpty(entities?.coldstore?.entities || []);
  isEmpty(entities?.packer?.entities || []) ||
    isEmpty(entities?.packer_plant?.entities || []) ||
    isEmpty(entities?.input_product?.entities || []) ||
    isEmpty(entities?.form_fields?.entities || []) ||
    isEmpty(entities?.transporter?.entities || []) ||
    isEmpty(entities?.freight_rate?.entities || []);
  const formFieldOptions = createOptions(entities?.form_fields?.entities || [], ff => startCase(ff.value), 'value');
  return {
    businessOfficeOptions: formFieldOptions.filter(o => o.type === 'business_office'),
    docTypeOptions: formFieldOptions.filter(o => o.type === 'po_doc_type'),
    formFieldOptions,
    formulaNameOptions: formFieldOptions.filter(o => o.type === 'formula_name'),
    grinderOptions: createOptions(entities?.grinder?.entities || [], 'name', 'uid'),
    transporterOptions: createOptions(entities?.transporter?.entities || []),
    transportOptions: createOptions(entities?.freight_rate?.entities || []),
    inputProductOptions: createOptions(entities?.input_product?.entities || [], 'name', 'uid'),
    isLoading,
    coldstoreOptions: createOptions(entities?.coldstore?.entities || []),
    missingDocTypeOptions: Constants.MISSING_DOC_TYPE_LIST,
    orderShipmentTypeOptions: Constants.ORDER_SHIPMENT_TYPE,
    orderTypeOptions: formFieldOptions.filter(o => o.type === 'price_type'),
    packerOptions: createOptions(entities?.packer?.entities || []),
    packerPlantOptions: createOptions(entities?.packer_plant?.entities || []),
    portOptions: createOptions(entities?.port?.entities || []),
    shippingLineOptions: createOptions(entities?.shipping_line?.entities || []),
    productTypeOptions: Constants.PRODUCT_TYPES,
    tagOptions: formFieldOptions.filter(o => o.type === 'tags'),
  };
});

// Select Buyer Options
export const selectBuyerOptions = createSelector(buyersSelector, users => {
  return createOptions(users, u => `${u.first_name || u.firstName} ${u.last_name || u.lastName}`);
});
