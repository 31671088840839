import { connect } from 'react-redux';

import InventoryComponent from '../../components/optimizer/Inventory';

const mapStateToProps = state => {
  return {
    optimizer_data: state.optimizer_data,
    token: state.user.token,
  };
};

const Inventory = connect(mapStateToProps)(InventoryComponent);

export default Inventory;
