import { createSelector } from '@reduxjs/toolkit';
import { selectQualityClaimsCategories } from './selectBasicDetailsClaimsData';

const selectAvailableClaimAmounts = createSelector(selectQualityClaimsCategories, categories => {
  if (!categories || categories.length === 0) return [];

  return [...categories[0].claimAmounts];
});

export default selectAvailableClaimAmounts;
