import React, { useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import LiveCLs from '../../containers/buyActivity/LiveCLs';
import BuyActivityStream from '../../containers/buyActivity/BuyActivityStream';
import BuyVolumes from '../../containers/buyActivity/BuyVolumes';
import './BuyActivity.scss';
import { Box, VStack, SimpleGrid, Text } from '@chakra-ui/react';
import Constants from '../../Constants';
import IUnleashClassFlagProvider from '../../components/unleash/UnleashClassFlagProvider';
import { fetchOrderStatusMap } from '../../slices/order-status-map/orderStatusMapSlice';



const BuyActivity = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrderStatusMap())
    }, []);

  
  return (
    <VStack spacing={5} align="stretch">
      <Box>
        <Text as="h4" mb={0}>
          Global Roll-up
        </Text>
      </Box>
      <SimpleGrid columns={{ sm: 1, lg: 2 }} spacing={8}>
        <Box>
          <LiveCLs />
        </Box>
        <Box>
          <BuyVolumes />
        </Box>
      </SimpleGrid>
      <Box>
        <BuyActivityStream />
      </Box>
    </VStack>
  );
};

const BuyActivityUnleashController = (props) => {

  return(
    <>
    <IUnleashClassFlagProvider
      flagName={Constants.UNLEASH_FLAG.BUY_ACTIVITY}
      show={<BuyActivity {...props}/>}
      hide={<h1>Feature Not Enabled</h1>}
    />
  </>
  );
};

export default BuyActivityUnleashController;