import React from 'react';
import PropTypes from 'prop-types';
import { Wrap } from '@chakra-ui/react';
import ArticleRegionCard from './ArticleRegionCard';

const ArticleRegionCardList = ({ articleRegions = [] }) => {
  return (
    <Wrap spacing="50px" justify="center">
      {articleRegions.map(article => (
        <ArticleRegionCard {...article} />
      ))}
    </Wrap>
  );
};

ArticleRegionCardList.propTypes = {
  articleRegions: PropTypes.arrayOf(PropTypes.object),
};

export default ArticleRegionCardList;
