import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertIcon, AlertDescription, Text } from '@chakra-ui/react';
import { commify } from '../../../functions';
import { convertToCamelCase } from '../../../helpers';

const propTypes = {
  packerPlants: PropTypes.arrayOf(PropTypes.object),
  unitsOfMeasure: PropTypes.any,
  grinders: PropTypes.arrayOf(PropTypes.object),
  loadSizes: PropTypes.arrayOf(PropTypes.object),
  form: PropTypes.instanceOf(Object),
  buyQuantityError: PropTypes.bool,
  dateError: PropTypes.bool,
  shipmentDateError: PropTypes.bool,
  showShipmentDateErrorSeparately: PropTypes.bool,
};

const ErrorMessage = ({
  packerPlants = [],
  unitsOfMeasure,
  grinders = [],
  loadSizes,
  form = {},
  buyQuantityError,
  dateError,
  shipmentDateError,
  showShipmentDateErrorSeparately,
}) => {
  const packerPlant = convertToCamelCase(packerPlants).find(pp => pp.id === form.packerPlantId);
  const grinder = convertToCamelCase(grinders).find(g => g.uid === form.grinderUid);

  let loadSize = { maximum_load_size: 0, unit_of_measure: { name: '' } };
  let defaultUom = {};

  if (!form.buyUnitOfMeasureId && unitsOfMeasure) {
    defaultUom = unitsOfMeasure.find(u => u.name === 'lbs');
  }
  if (packerPlant && grinder && loadSizes) {
    const uomId = form.buyUnitOfMeasureId ? form.buyUnitOfMeasureId : defaultUom?.id;
    loadSize = loadSizes.find(
      lf =>
        lf.origin === packerPlant.originCountry &&
        lf.destination === grinder.destinationCountry &&
        lf.unit_of_measure_id === uomId
    );
  }
  const showError = (buyQuantityError && loadSize) || dateError || shipmentDateError;

  const renderShipmentDateError = () => {
    const errorLi = <li>&quot;Shipment Date&quot; must be on or after today, if filled in.</li>;
    if (!showShipmentDateErrorSeparately && dateError) return errorLi;
    if (shipmentDateError) return errorLi;
    return '';
  };

  return (
    <>
      {showError ? (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            {buyQuantityError ? (
              <Text as="span">
                Please check the input fields and ensure they are all filled in correctly.
                <br />
                Note: A purchase order cannot contain more than {commify(loadSize.maximum_load_size)}{' '}
                {loadSize.unit_of_measure.name}
              </Text>
            ) : (
              false
            )}
            <Text as="span">
              {dateError ? (
                <li>
                  Dates must be in the following chronological sequence:
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Expected Production -&gt; Shipment -&gt; Delivery -&gt; Production
                </li>
              ) : (
                false
              )}
              {renderShipmentDateError()}
            </Text>
          </AlertDescription>
        </Alert>
      ) : (
        false
      )}
    </>
  );
};

ErrorMessage.propTypes = propTypes;

export default ErrorMessage;
