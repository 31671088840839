import { createSelector } from '@reduxjs/toolkit';
import { group } from 'd3';
import {
  selectQualityClaimsData,
  selectQualityClaimsDataByCategory,
  selectQualityClaimsDataByClaimAmount,
  selectQualityClaimsDataByDaysToResolve,
  selectQualityClaimsFilterBy,
  selectQualityClaimsStatus,
} from './selectBasicDetailsClaimsData';
import { FILTER_BY_OPTIONS, STATUS_OPTIONS } from '../qualityDetailsClaimsConfig';

const selectQualityClaimsDataFilteredByStatus = createSelector(
  selectQualityClaimsData,
  selectQualityClaimsDataByCategory,
  selectQualityClaimsDataByClaimAmount,
  selectQualityClaimsDataByDaysToResolve,
  selectQualityClaimsFilterBy,
  selectQualityClaimsStatus,
  (data, dataByCategory, dataByClaimAmount, dataByDaysToResolve, filterBy, statusMode) => {
    if (!data || !dataByCategory) return null;

    let dataFiltered = [];

    if (filterBy === FILTER_BY_OPTIONS.CATEGORY) {
      dataFiltered = dataByCategory;
    } else if (filterBy === FILTER_BY_OPTIONS.CLAIM_AMOUNT) {
      dataFiltered = dataByClaimAmount;
    } else if (filterBy === FILTER_BY_OPTIONS.DAYS_TO_RESOLVE) {
      dataFiltered = dataByDaysToResolve;
    } else {
      dataFiltered = data;
    }

    if (!statusMode) {
      return dataFiltered;
    }
    if (statusMode === STATUS_OPTIONS.SUBMITTED_VS_FINALISED) {
      dataFiltered = dataFiltered.map(x => ({
        ...x,
        byStatus: x.byStatus.filter(
          s => s.status === STATUS_OPTIONS.SUBMITTED || s.status === STATUS_OPTIONS.FINALISED
        ),
      }));
    } else if (statusMode === STATUS_OPTIONS.SUBMITTED_VS_REJECTED) {
      dataFiltered = dataFiltered.map(x => ({
        ...x,
        byStatus: x.byStatus.filter(s => s.status === STATUS_OPTIONS.SUBMITTED || s.status === STATUS_OPTIONS.REJECTED),
      }));
    } else if (statusMode === STATUS_OPTIONS.FINALISED_VS_REJECTED) {
      dataFiltered = dataFiltered.map(x => ({
        ...x,
        byStatus: x.byStatus.filter(s => s.status === STATUS_OPTIONS.FINALISED || s.status === STATUS_OPTIONS.REJECTED),
      }));
    } else {
      dataFiltered = dataFiltered.map(x => ({
        ...x,
        byStatus: x.byStatus.filter(s => s.status === statusMode),
      }));
    }
    return dataFiltered;
  }
);

export const selectQualityClaimsDataFiltered = createSelector(
  selectQualityClaimsDataFilteredByStatus,
  selectQualityClaimsFilterBy,
  (data, filterBy) => {
    if (!filterBy) {
      return data.map(intervalData => {
        const stat = intervalData.byStatus.map(statusData => {
          const byCategory = Array.from(
            group(statusData.byPackerPlant.map(x => x.byCategory).flat(), x => x.category)
          ).map(([category, entries]) => ({
            category,
            count: entries.reduce((a, b) => a + b.count, 0) / 2,
            amount: entries.reduce((a, b) => a + b.amount, 0) / 2,
          }));
          const byClaimAmount = Array.from(
            group(statusData.byPackerPlant.map(x => x.byClaimAmount).flat(), x => x.claimAmount)
          ).map(([claimAmount, entries]) => ({
            claimAmount,
            count: entries.reduce((a, b) => a + b.count, 0) / 2,
            amount: entries.reduce((a, b) => a + b.amount, 0) / 2,
          }));
          const byDaysToResolve = Array.from(
            group(statusData.byPackerPlant.map(x => x.byDaysToResolve).flat(), x => x.daysToResolve)
          ).map(([daysToResolve, entries]) => ({
            daysToResolve,
            count: entries.reduce((a, b) => a + b.count, 0) / 2,
            amount: entries.reduce((a, b) => a + b.amount, 0) / 2,
          }));
          return {
            status: statusData.status,
            count: statusData.count,
            amount: statusData.amount,
            byCategory,
            byClaimAmount,
            byDaysToResolve,
            byPackerPlant: statusData.byPackerPlant,
          };
        });
        return {
          date: intervalData.date,
          byStatus: stat,
        };
      });
    }
    return data;
  }
);

export default selectQualityClaimsDataFiltered;
