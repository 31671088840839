import axios from 'axios';
import Constants from '../Constants';

const BASE_URL = `${Constants.URL}analytic-dashboard`;

/**
 * Makes the API request for grinders in this market for this time period.
 *
 * @param {String} market
 * @param {[Date, Date]} timePeriod
 */
export default async function fetchGrindersRequest(market) {
  const query = {
    country_tab: market,
  };
  const response = await axios.get(`${BASE_URL}/quality/grinders`, {
    params: query,
  });
  return response.data;
}
