import Constants from '../../../Constants';

const schema = [
  {
    name: 'notifying-action-groups',
    displayName: 'Notification Email',
    systemIdField: 'id',
    displayIdField: 'action_type',
    path: '/notifying-action-groups',
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'action_type',
        displayName: 'Action Type',
        tooltip: 'Select the type of action that triggers the notification email',
        dataType: 'simple-select',
        options: Constants.ACTION_TYPES,
        required: true,
      },
      {
        name: 'email_id',
        displayName: 'Email',
        tooltip: 'The email address where the notification will be sent.',
        dataType: 'text',
        required: true,
      },
      {
        name: 'is_internal_user',
        displayName: 'Internal User',
        tooltip: 'Specify whether the email is for an internal user (Yes) or not (No).',
        dataType: 'boolean',
        required: false,
      },
      {
        name: 'grinder_uid',
        displayName: 'Grinder',
        tooltip: 'Associated grinder for which the notification applies.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'grinder',
        required: false,
      },
      {
        name: 'packer_plant_id',
        displayName: 'Establishment',
        tooltip: 'Facility of the establishment related to the notification.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'packer_plant',
        required: false,
      },
      {
        name: 'coldstore_id',
        displayName: 'Coldstore',
        tooltip: 'Cold storage facility linked to the notification.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'coldstore',
      },
      {
        name: 'transporter_id',
        displayName: 'Transporter',
        tooltip: 'Transport service provider associated with the notification.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'transporter',
      },
    ],
  },
];
export default schema;
