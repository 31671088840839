import { PACKER_ADDED, PACKER_EDIT, PACKER_FETCHED, PACKER_REMOVED } from '../actions/actions_packers';

const packer = (packer = [], action) => {
  const copy = JSON.parse(JSON.stringify(packer));

  switch (action.type) {
    case PACKER_FETCHED:
      return action.payload;
    case PACKER_ADDED:
      copy.push(action.payload);
      return copy;
    case PACKER_EDIT:
      return replacePackersPricingGroup(copy, action.payload);
    case PACKER_REMOVED:
      return removePackersPricingGroup(copy, action.payload);
    default:
      return packer;
  }
};

function replacePackersPricingGroup(allPackersPricingGroups, editedPackersPricingGroup) {
  const newPackersPricingGroups = [];
  allPackersPricingGroups.forEach(function (element) {
    if (element.id === editedPackersPricingGroup.id) {
      newPackersPricingGroups.push(editedPackersPricingGroup);
    } else {
      newPackersPricingGroups.push(element);
    }
  });
  return newPackersPricingGroups;
}

function removePackersPricingGroup(allPackersPricingGroups, removedPackersPricingGroup) {
  const newPackersPricingGroups = [];
  allPackersPricingGroups.forEach(function (element) {
    if (element.id !== removedPackersPricingGroup.id) {
      newPackersPricingGroups.push(element);
    }
  });
  return newPackersPricingGroups;
}

export default packer;
