import React from 'react';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import { AttachmentFieldSectionAdapter, EditButton, PreambleText, VStackLayout } from '../Components/index';
import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { getOr } from '../../../helpers';
import { get, groupBy } from 'lodash';
import { useFormikContext } from 'formik';
import { Link } from 'react-router-dom';
import { checkIfIsAdminUser, checkIfIsGrinderUser, checkIfIsPackerUser } from '../../../utils';

const Attachments = props => {
  const { values: formikValues } = useFormikContext();
  const {
    onUpdate,
    user,
    ncrs: { currentNCR },
    editDisabled,
    isNCR,
  } = props;

  const ncrMedia = getOr(formikValues, isNCR ? 'ncrDetails.ncrMedia' : 'incidentMedia', []);
  const groupedAttachment = groupBy(ncrMedia, 'type');
  const ncrAttachmentSections = [
    {
      key: 'photos',
      label: 'Photos',
    },
    {
      key: 'product_labels',
      label: 'Product Labels',
    },
    {
      key: 'others',
      label: 'Others',
    },
  ];

  const incidentAttachmentSections = [
    {
      key: 'attachments',
      label: 'attachments',
    },
  ];

  const attachmentSections = isNCR ? ncrAttachmentSections : incidentAttachmentSections

  return (
    <Box id="divToPrintAttachment" >
    <DashboardSectionTile
      title="Attachments"
      padding="22px 33px 22px 20px"
      marginTop="2px"
      position="relative"
      borderTopLeftRadius={0}
      tileStatusComponent={() =>
        checkIfIsGrinderUser(user) || checkIfIsAdminUser(user) || checkIfIsPackerUser(user)? (
          <Link to={`/edit-${isNCR ? 'ncr': 'incident'}/${get(currentNCR, 'recordNo')}?startEditTabKey=attachments`}>
            <EditButton isDisabled={editDisabled} />
          </Link>
        ) : (
          ''
        )
      }
      headerFlexStyle={{
        justifyContent: 'space-between',
      }}
    >
      <VStackLayout paddingRight="0">
        {attachmentSections.map((attachmentSection, index) => {
          const { key, label } = attachmentSection;
          return (
            <Box key={key}>
              <PreambleText fontSize="16px">{label}:</PreambleText>
              <AttachmentFieldSectionAdapter
                attachmentSectionKey={key}
                sectionIndex={index}
                label={label}
                onUpdate={onUpdate}
                ncrMedia={ncrMedia}
                attachmentsInGroup={getOr(groupedAttachment, key, [])}
                isReviewing
              />
            </Box>
          );
        })}
      </VStackLayout>
    </DashboardSectionTile>
    </Box>
  );
};

Attachments.propTypes = {
  onUpdate: PropTypes.func,
  ncrs: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
  editDisabled: PropTypes.bool,
};

export default Attachments;
