import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ErrorMessage } from 'formik';

import Switch from '../Switch';

function FormSwitch(props) {
  const { indices, label, name, height, ...rest } = props;
  const fieldName = typeof name === 'function' ? name(indices) : name;

  return (
    <Box {...{ d: 'flex', alignItems: 'flex-end', height }}>
      <Switch {...{ label, name: fieldName, ...rest }} />
      <ErrorMessage name={fieldName}>{msg => <Text color="error">{msg}</Text>}</ErrorMessage>
    </Box>
  );
}

export default FormSwitch;
