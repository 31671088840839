import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
} from '@chakra-ui/react';
// Local Deps
import Button from '../../core/Button';
import CircleIcon from '../../core/CircleIcon';
import LoadingGraphic from '../../../assets/color-ring-loading.svg';

const propTypes = {
  error: PropTypes.string,
  isOpen: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onBackToSummaryClick: PropTypes.func,
  onCloseModal: PropTypes.func,
  onNewSearchClick: PropTypes.func,
  wasSuccessful: PropTypes.bool,
};

const BulkUpdaterModal = props => {
  const { isOpen, onCloseModal, isSubmitting, wasSuccessful, error, onBackToSummaryClick, onNewSearchClick } = props;

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onCloseModal}>
      <ModalOverlay />
      <ModalContent minHeight="200px">
        <ModalHeader>Bulk Update</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* <!-- CARD --> */}
          <Box>{wasSuccessful ? 'The operation was successful.' : ''}</Box>
          <Flex mb="35px" textAlign="center" justifyContent="center">
            {/* <!-- LOADING GIF --> */}
            {isSubmitting && (
              <Box textAlign="center">
                <Img src={LoadingGraphic} />
                Submitting changes...
              </Box>
            )}
            {/* <!-- SUCCESS --> */}
            {wasSuccessful && !error && <CircleIcon icon="check" color="success" />}
            {/* <!-- ERROR --> */}
            {error && !wasSuccessful && (
              <Box>
                <CircleIcon icon="close" color="error" />
                <Box mt="10px">Operation failed due to an unexpected error.</Box>
              </Box>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          {wasSuccessful && (
            <>
              {/* TODO -- In the future we will add a button that allows the user to go back
             to the previous search.  We aren't persisting the search form's state at this time, though,
             so only new searches are offered here. */}
              <Button colorScheme="actionPrimary" onClick={onNewSearchClick}>
                New Search
              </Button>
            </>
          )}
          {error && (
            <Button colorScheme="actionPrimary" onClick={onBackToSummaryClick}>
              Back to Summary
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

BulkUpdaterModal.propTypes = propTypes;

export default BulkUpdaterModal;
