import { createSelector } from '@reduxjs/toolkit';
import { mean, sum } from 'd3';
import { selectTreasuryDetailsShowOutlook } from '../../treasury-details/treasuryDetailsReducers';
import {
  selectTreasuryTimeGroupedData,
  selectTreasuryTimeGroupedDataWithForecast,
} from './selectTreasuryTimeGroupedData';

export const selectTreasuryTableData = createSelector(
  selectTreasuryTimeGroupedData,
  selectTreasuryTimeGroupedDataWithForecast,
  selectTreasuryDetailsShowOutlook,
  (timeGrouped, timeGroupedWithForecast, showOutlook) => {
    if (!timeGrouped || !timeGroupedWithForecast) return null;

    const groupedData = showOutlook ? timeGroupedWithForecast : timeGrouped;

    const timeGroups = groupedData.map(year => {
      const newIntervals = year.intervals.map(interval => {
        const volumeTotal = sum(interval.orders, order => order.volume_received || 0);
        const volumeHedged = sum(
          interval.orders.filter(x => x.order_type === 'Contract' || x.order_type === 'NOF'),
          order => order.volume_received || 0
        );
        const volumeForecastedTotal = sum(interval.orders, order => order.volume_expected || 0);
        const volumeForecastedHedged = sum(
          interval.orders.filter(x => x.order_type === 'Contract' || x.order_type === 'NOF'),
          order => order.volume_expected || 0
        );

        const volumeHedgedRatio = (volumeHedged + volumeForecastedHedged) / (volumeTotal + volumeForecastedTotal);

        const avgMarketPrice = mean(interval.orders, order => order.usda_market_price);
        const effectiveHedgedRate = mean(
          interval.orders.filter(x => x.order_type === 'Contract' || x.order_type === 'NOF'),
          order => order.buy_price_per_unit
        );
        const volume = volumeTotal + volumeForecastedTotal;

        const notionalAmt = avgMarketPrice * volume;
        const markToMarket = notionalAmt - effectiveHedgedRate * volume;

        const dataBreakdown = {
          volumeHedgedRatio,
          avgMarketPrice,
          effectiveHedgedRate,
          notionalAmt,
          markToMarket,
        };

        return {
          interval: `${interval.interval} ${interval.year}`,
          year: interval.year,
          dataBreakdown,
        };
      });

      return {
        year: year.year,
        intervals: newIntervals,
      };
    });

    return {
      timeGroups,
    };
  }
);
