import { createSelector } from '@reduxjs/toolkit';

import {
  selectQualityTimeScale,
  selectQualityTimePeriod,
  selectQualityPackerPlant,
  selectQualityGrinder,
} from './selectBasicQualityData';

/**
 * The parameters for quality data requests based on the filter state
 */
export const selectQualityFetchParameters = createSelector(
  selectQualityTimeScale,
  selectQualityTimePeriod,
  selectQualityPackerPlant,
  selectQualityGrinder,
  (timeScale, timePeriod, packerPlant, grinder) => ({
    timeScale,
    timePeriod,
    packerPlant,
    grinderId: grinder.id,
  })
);
