const schema = [
  {
    name: 'config_value',
    displayName: 'Config',
    nested: false,
    systemIdField: 'id',
    displayIdField: 'name',
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    deletable: true,
    path: '/config_values',
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'name',
        displayName: 'Name',
        dataType: 'shorttext',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'value_int',
        displayName: 'Value Integer',
        dataType: 'integer',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'value_str',
        displayName: 'Value String',
        dataType: 'text',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'value_float',
        displayName: 'Value Float',
        dataType: 'number',
        required: false,
        systemIdentifier: false,
      },
    ],
  },
];
export default schema;
