import { createSelector } from '@reduxjs/toolkit';
import {
  selectQualityClaimsTimeRange,
  selectQualityClaimsTimePeriod,
  selectQualityClaimsFrequency,
  selectQualityClaimsMarket,
  selectQualityClaimsRegion,
  selectQualityClaimsGrinder,
} from './selectBasicDetailsClaimsData';

/**
 * The parameters for quality data requests based on the filter state
 */
export const selectQualityClaimsFetchParameters = createSelector(
  selectQualityClaimsTimeRange,
  selectQualityClaimsTimePeriod,
  selectQualityClaimsFrequency,
  selectQualityClaimsMarket,
  selectQualityClaimsRegion,
  selectQualityClaimsGrinder,
  (timeRange, timePeriod, frequency, market, region, grinder) => ({
    timeRange,
    timePeriod,
    frequency,
    market: market.key,
    region,
    grinderId: grinder,
  })
);
