import { get } from 'lodash';
import { isJSON } from '../helpers';
import {
  FETCH_NCR_CHAT_HISTORY_ERROR,
  FETCH_NCR_CHAT_HISTORY_SUCCESS,
  RESET_NCR_HISTORY,
  FETCH_INCIDENT_CHAT_HISTORY_SUCCESS,
  FETCH_INCIDENT_CHAT_HISTORY_ERROR,
} from '../actions/action_ncr_history';

const initialState = {
  items: [],
  isLoading: false,
  itemsFetched: false,
  hasError: false,
  errorMessage: '',
  pagination: {
    has_next: false,
    has_previous: false,
    next_page: null,
    pages: 0,
    previous_page: null,
    total: 0,
  },
};

const getErrorMessage = payload => {
  return get(isJSON(payload) ? JSON.parse(payload) : payload, 'title', '');
};

const ncrHistory = (state = initialState, action) => {
  switch (action.type) {
    case RESET_NCR_HISTORY:
      return {
        ...state,
        hasError: false,
        itemsFetched: false,
        items: [],
      };
    case FETCH_NCR_CHAT_HISTORY_SUCCESS: {
      const { items, ...rest } = action.payload;
      return {
        ...state,
        hasError: false,
        itemsFetched: true,
        items,
        pagination: rest,
      };
    }
    case FETCH_NCR_CHAT_HISTORY_ERROR:
      return {
        ...state,
        hasError: true,
        itemsFetched: false,
        errorMessage: getErrorMessage(action.payload),
      };
    
    case FETCH_INCIDENT_CHAT_HISTORY_SUCCESS: {
      const { items, ...rest } = action.payload;
      return {
        ...state,
        hasError: false,
        itemsFetched: true,
        items,
        pagination: rest,
      };
    }
    
    case FETCH_INCIDENT_CHAT_HISTORY_ERROR: 
      return {
        ...state,
        hasError: true,
        itemsFetched: false,
        errorMessage: getErrorMessage(action.payload),
      };    

    default:
      return state;
  }
};

export default ncrHistory;
