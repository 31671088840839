import { connect } from 'react-redux';

import SubmittedTicketsComponent from '../../components/support/SubmittedTickets';

const mapStateToProps = state => {
  return {
    support_emails: state.support_emails,
    tickets: state.tickets.tickets,
    submitted: state.tickets.submitted,
    token: state.user.token,
    user: state.user,
    error: state.error,
  };
};

const SubmittedTickets = connect(mapStateToProps)(SubmittedTicketsComponent);

export default SubmittedTickets;
