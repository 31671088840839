import { createSelector } from '@reduxjs/toolkit';
import { selectPackerPlants } from '../../quality-packer-plants/selectors/selectBasicPackerPlants';
import { selectQualityData, selectQualityPackerPlant } from './selectBasicQualityData';
import { selectQualityPackers } from './selectQualityPackers';
import { getTwoPeriods } from '../../../modules/data/year-on-year';

const colors = [
  '#09AFA3',
  '#FF1577',
  '#5039B5',
  '#FED000',
  '#00B5F2',
  '#FC5D36',
  '#006df2',
  '#FF9A3D',
  '#545B72',
  '#AB2424',
  '#029916',
];

export const selectQualityNCRs = createSelector(
  selectQualityData,
  selectPackerPlants,
  selectQualityPackers,
  selectQualityPackerPlant,
  (data, packerPlants, packers, selectedPackerPlant) => {
    if (!data || !packerPlants) return null;

    const { ncrs } = data;
    const twoPeriods = getTwoPeriods(ncrs.incidents.byPeriod);

    let totalClaimAmountForCategory = 0;
    let totalVolumeForCategory = 0;
    ncrs.categories.forEach(c => {
      c.subCategories.forEach(sc => {
        totalClaimAmountForCategory += sc.claimAmount;
        totalVolumeForCategory += sc.volume;
      });
    });

    const categories = ncrs.categories.map(x => {
      const subCategoriesData = x.subCategories.map(sc => {
        return {
          ...sc,
          claimAmountPercent: totalClaimAmountForCategory === 0 ? 0 : sc.claimAmount / totalClaimAmountForCategory,
          volumePercent: totalVolumeForCategory === 0 ? 0 : sc.volume / totalVolumeForCategory,
        };
      });
      return {
        count: subCategoriesData.reduce((a, b) => a + b.count, 0),
        withClaimValue: subCategoriesData.reduce((a, b) => a + b.withClaimValue, 0),
        claimAmount: subCategoriesData.reduce((a, b) => a + b.claimAmount, 0),
        volume: subCategoriesData.reduce((a, b) => a + b.volume, 0),
        previousVolume: subCategoriesData.reduce((a, b) => a + b.previousVolume, 0),
        incidentRate: subCategoriesData.reduce((a, b) => a + b.incidentRate, 0),
        previousIncidentRate: subCategoriesData.reduce((a, b) => a + b.previousIncidentRate, 0),
        previousCount: subCategoriesData.reduce((a, b) => a + b.previousCount, 0),
        previousWithClaimValue: subCategoriesData.reduce((a, b) => a + b.previousWithClaimValue, 0),
        previousClaimAmount: subCategoriesData.reduce((a, b) => a + b.previousClaimAmount, 0),
        ...x,
        subCategories: subCategoriesData,
      };
    });

    const byPackerPlantOverLast12Months = (ncrs.summary.incidents.byPackerPlant || []).map((x, i) => {
      const packerPlant = packerPlants.find(p => p.id === x.packerPlantId);
      return {
        ...x,
        packerPlantName: packerPlant?.name,
        packerPlantId: packerPlant?.id,
        color: colors[i],
      };
    });

    const summaryIncidents = {
      count: ncrs.summary.incidents.byCategory.reduce((a, b) => a + b.count, 0),
      ...ncrs.summary.incidents,
      byPackerPlant: byPackerPlantOverLast12Months,
    };

    const packerPlantsInPacker = packers[selectedPackerPlant.id];

    let totalClaimAmount = 0;
    let totalVolume = 0;
    let totalVolumeWithClaimValue = 0;
    ncrs.packerPlants?.forEach(pp => {
      pp.byCategory.forEach(c => {
        totalClaimAmount += c.claimAmount;
        totalVolume += c.volume;
        totalVolumeWithClaimValue += c.volumeWithClaimValue;
      });
    });

    const packerPlantsByCategory = (ncrs.packerPlants || []).map(x => {
      const packerPlantName = packerPlants.find(p => p.id === x.packerPlantId)?.name;
      const packerPlantColor = byPackerPlantOverLast12Months.find(p => p.packerPlantId === x.packerPlantId)?.color;
      const byCategoryData = x.byCategory.map(c => {
        return {
          ...c,
          claimAmountPercent: totalClaimAmount === 0 ? 0 : c.claimAmount / totalClaimAmount,
          volumePercent: totalVolume === 0 ? 0 : c.volume / totalVolume,
          volumePercentWithClaimValue:
            totalVolumeWithClaimValue === 0 ? 0 : c.volumeWithClaimValue / totalVolumeWithClaimValue,
        };
      });

      return {
        packerPlantName,
        count: byCategoryData.reduce((a, b) => a + b.count, 0),
        withClaimValue: byCategoryData.reduce((a, b) => a + b.withClaimValue, 0),
        incidentRate: byCategoryData.reduce((a, b) => a + b.incidentRate, 0),
        claimAmount: byCategoryData.reduce((a, b) => a + b.claimAmount, 0),
        claimAmountPercent: byCategoryData.reduce((a, b) => a + b.claimAmountPercent, 0),
        volume: byCategoryData.reduce((a, b) => a + b.volume, 0),
        volumePercent: byCategoryData.reduce((a, b) => a + b.volumePercent, 0),
        volumeWithClaimValue: byCategoryData.reduce((a, b) => a + b.volumeWithClaimValue, 0),
        volumePercentWithClaimValue: byCategoryData.reduce((a, b) => a + b.volumePercentWithClaimValue, 0),
        packerPlantId: x.packerPlantId,
        byCategory: byCategoryData,
        color: packerPlantColor || '#D3D5E0',
      };
    });

    const packerPlantsWithCount = packerPlantsInPacker
      ?.map(x => {
        const packerPlantWithCount = packerPlantsByCategory?.find(p => p.packerPlantId === x.id);
        if (packerPlantWithCount) {
          return packerPlantWithCount;
        }
        return { packerPlantId: x.id, count: 0, claimAmount: 0, claimAmountPercent: 0, volume: 0, volumePercent: 0 };
      })
      ?.sort((a, b) => b.count - a.count);

    const bottomPackerPlants = (packerPlantsWithCount || []).map(x => {
      const packerPlant = packerPlants.find(p => p.id === x.packerPlantId);
      const packerPlantColor = byPackerPlantOverLast12Months.find(p => p.packerPlantId === x.packerPlantId)?.color;
      return {
        ...x,
        packerPlantName: packerPlant?.name,
        packerPlantId: packerPlant?.id,
        color: packerPlantColor || '#D3D5E0',
      };
    });

    const packerPlantsTotal = (ncrs.packerPlantsTotal || []).map(x => {
      const packerPlantName = packerPlants.find(p => p.id === x.packerPlantId)?.name;
      const claimAmount = x.claimAmount || 0;
      return {
        packerPlantName,
        claimAmount,
        ...x,
      };
    });

    const dollars = {
      ...ncrs.dollars,
      byPeriod: [...ncrs.dollars.byPeriod].sort((a, b) => new Date(a.date) - new Date(b.date)),
    };

    const summaryVolume = {
      count: ncrs.summary.volume.byCategory.reduce((a, b) => a + b.count, 0),
      ...ncrs.summary.volume,
      byPackerPlant: (ncrs.summary.volume.byPackerPlant || []).map(x => {
        const packerPlantName = packerPlants.find(p => p.id === x.packerPlantId)?.name;
        return {
          packerPlantName,
          ...x,
        };
      }),
    };
    const summaryDollars = {
      count: ncrs.summary.dollars.byCategory.reduce((a, b) => a + b.count, 0),
      ...ncrs.summary.dollars,
      byPackerPlant: (ncrs.summary.dollars.byPackerPlant || []).map(x => {
        const packerPlantName = packerPlants.find(p => p.id === x.packerPlantId)?.name;
        return {
          packerPlantName,
          ...x,
        };
      }),
    };

    // const exportsByCountry = ncrs.exports.byCountry.map(x => {
    //   const packerPlantName = packerPlants.find(p => p.id === x.packerPlantId)?.name;
    //   return {
    //     ...x,
    //     packerPlantName,
    //   };
    // });

    // const exportsByPackerPlant = (ncrs.exports.byPackerPlant || []).map(x => {
    //   const packerPlantName = packerPlants.find(p => p.id === x.packerPlantId)?.name;
    //   return {
    //     ...x,
    //     packerPlantName,
    //   };
    // });

    return {
      ...ncrs,
      incidents: { ...ncrs.incidents, byPeriod: twoPeriods },
      volume: { ...ncrs.volume, byPeriod: getTwoPeriods(ncrs.volume.byPeriod) },
      dollars,
      categories,
      packerPlants: packerPlantsByCategory,
      packerPlantsTotal,
      summary: {
        incidents: summaryIncidents,
        volume: summaryVolume,
        dollars: summaryDollars,
      },
      // exports: {
      //   ...ncrs.exports,
      //   byCountry: exportsByCountry,
      //   byPackerPlant: exportsByPackerPlant,
      // },
      bottomPackerPlants,
    };
  }
);
