import React from 'react';
import { Typography } from 'antd';
import styles from './FilterTitle.module.css';

export default function FilterTitle({ marginTop, children }) {
  return (
    <Typography.Title
      strong={false}
      level={5}
      className={styles.filterTitle}
      style={{ fontWeight: 'normal', marginTop }}
    >
      {children}
    </Typography.Title>
  );
}
