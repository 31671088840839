import { Box, Button, Center, Heading } from '@chakra-ui/react';
import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import ArticleTile from './ArticleTile';

const ArticleRegionCard = ({ name, image_url: imageUrl }) => {
  return (
    <ArticleTile imageUrl={imageUrl} paddingBottom="10px">
      <Box h="60px">
        <Center>
          <Heading fontSize="18px" fontWeight="600" lineHeight="28px">
            {truncate(name, { length: 25 })}
          </Heading>
        </Center>
      </Box>
      <Center mt="40px !important">
        <Link to={`/kpi/${name}`}>
          <Button
            w="160px"
            height="46px"
            colorScheme="actionPrimary"
            fontSize="16px"
            fontWeight="600"
            borderRadius="10px"
          >
            View
          </Button>
        </Link>
      </Center>
    </ArticleTile>
  );
};

ArticleRegionCard.propTypes = {
  name: PropTypes.string,
  image_url: PropTypes.string,
};

export default ArticleRegionCard;
