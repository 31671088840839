import {createContext, useContext, useState} from 'react'; 

const ChangeReqForContext =  createContext();

export default function ChangeReqForProvider({children}) {
    const [changeReqFor, setChangeReqFor] = useState('');
    return (
        <ChangeReqForContext.Provider value={{changeReqFor,setChangeReqFor}}>
            {children}
        </ChangeReqForContext.Provider>
    )
}

export function useChangeReqFor() {
    return useContext(ChangeReqForContext);
}