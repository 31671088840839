import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NCRForm from './NCRForm';
import { connect } from 'react-redux';
import { convertToCamelCase } from '../../../helpers';
import { checkIfIsPackerUser } from '../../../utils';
import { resetPurchaseOrders } from '../../../actions/actions_orders';

const CreateNCR = props => {
  const { user,dispatch } = props;
  const isPackerUser = checkIfIsPackerUser(user);
  
  useEffect(() => {
    dispatch(resetPurchaseOrders()); 
  },[])

  return !isPackerUser ? <NCRForm /> : null;
};

CreateNCR.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  dispatch: PropTypes.func
};

const mapStateToProps = state => {
  const result = {
    user: state.user,
    token: state.user.token,
    ncrs: state.ncrs,
  };
  return convertToCamelCase(result);
};

export default connect(mapStateToProps)(CreateNCR);
