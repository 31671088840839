import { createSlice } from '@reduxjs/toolkit';
import fetchPackerPlantsRequest from '../../data-fixtures/quality-packer-plants-all';

const packerPlantsSlice = createSlice({
  name: 'qualityPackerPlants',
  initialState: {
    data: [],
  },
  reducers: {
    setPackerPlants(state, { payload }) {
      state.data = payload;
    },
  },
});

export const { setPackerPlants } = packerPlantsSlice.actions;

// ------
// Thunks
export const fetchPackerPlants = ({ market, timePeriod }) => {
  return async dispatch => {
    try {
      const packerPlants = await fetchPackerPlantsRequest(market, timePeriod);
      dispatch(setPackerPlants(packerPlants));
      return packerPlants;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export default packerPlantsSlice.reducer;
