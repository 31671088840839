import { createSelector } from '@reduxjs/toolkit';
import { selectPackerPlants } from '../../quality-packer-plants/selectors/selectBasicPackerPlants';
import { ALL_PACKER_PLANTS_OPTION, DIVIDER_OPTION } from '../qualityConfig';
import { selectQualityMarket } from './selectBasicQualityData';
import { MARKETS } from '../../../config/markets';

export const selectQualitySortedPackerPlantsOptions = createSelector(
  selectPackerPlants,
  selectQualityMarket,
  (packerPlants, market) => {
    const packerPlantCountries = (market.includedCountries || [market.key]).map(country => country.toLowerCase());
    const independantPackerPlants = [...packerPlants]
      .filter(
        x =>
          !x.delisted &&
          !x.isPacker &&
          !x.isPartOf &&
          packerPlantCountries.includes(x.country && x.country.toLowerCase())
      )
      .sort((a, b) => a.name.localeCompare(b.name));
    const packers = [];
    [...packerPlants]
      .filter(x => !x.delisted && !!x.isPacker)
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach(x => {
        const packerPackerPlants = [...packerPlants]
          .filter(p => p.isPartOf === x.id)
          .sort((a, b) => a.name.localeCompare(b.name));
        packers.push(x, ...packerPackerPlants);
      });
    return [...independantPackerPlants, ...packers];
  }
);

export const selectQualityPackerPlantOptions = createSelector(
  selectQualitySortedPackerPlantsOptions,
  selectQualityMarket,
  (sortedPackerPlants, market) => {
    // Disabled until the backend supports individual country based
    let countryOptions = [];
    if (market.includedCountries) {
      countryOptions = market.includedCountries.map(x => {
        const country = MARKETS[x];
        return {
          id: country.key,
          name: `${country.selectorText} establishments`,
        };
      });
      return [ALL_PACKER_PLANTS_OPTION, ...countryOptions, DIVIDER_OPTION, ...sortedPackerPlants];
    }

    return [ALL_PACKER_PLANTS_OPTION, DIVIDER_OPTION, ...sortedPackerPlants];
  }
);
