import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { Flex, Text, Badge, HStack, Box } from '@chakra-ui/react';
import { CheckCircleIcon, MinusIcon, Search2Icon } from '@chakra-ui/icons';

export const UserSelectFieldValueContainer = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <Search2Icon color="gray.300" />}
        {children}
      </components.ValueContainer>
    )
  );
};
UserSelectFieldValueContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};

export const UserSelectFieldOption = props => {
  const { data } = props;

  const getNameInitials = nameString => {
    if (nameString) {
      const nameArray = nameString.trim().split(' ');
      const firstName = nameArray[0];
      const lastName = nameArray[nameArray.length - 1];
      return `${firstName[0] + lastName[0]}`;
    }
    return '';
  };
  return (
    components.Option && (
      <components.Option {...props}>
        <Flex height="30px" alignItems="center" pointerEvents={data.selected ? 'none' : 'auto'}>
          <Badge
            variant="solid"
            width="25px"
            height="25px"
            borderRadius="25px"
            colorScheme="actionSecondary"
            flexShrink={0}
          >
            <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
              <Text as="p" fontSize="12px" fontWeight="medium">
                {getNameInitials(data.label).toUpperCase()}
              </Text>
            </Flex>
          </Badge>
          <HStack spacing={2} mx={2} width="100%">
            {data.selected ? <CheckCircleIcon color="green.500" /> : null}
            <Text
              as="p"
              color="black"
              fontSize="12px"
              ml="10px"
              fontWeight="medium"
              title={data.label}
              maxWidth="176px"
              isTruncated
            >
              {data.label}
            </Text>
            {data.selected ? (
              <Box marginLeft="auto !important">
                <MinusIcon color="red.500" pointerEvents="auto" />
              </Box>
            ) : null}
          </HStack>
        </Flex>
      </components.Option>
    )
  );
};
UserSelectFieldOption.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
    selected: PropTypes.bool,
  }),
};
