import { Typography } from 'antd';
import { startOfYear, endOfYear, subYears, format } from 'date-fns';
import * as React from 'react';
import { singleMonthLetter } from '../../../../modules/format';
import BaseChart from '../../treasury/BaseChart/BaseChart';
import styles from './Claims.module.css';
import { calculateRoundedMax } from '../../../../modules/data/rounded-max';
import Icon from '@ant-design/icons/lib/components/Icon';
import { ChevronRight } from '../../../../shared/Icons';
import yearOnYear, { getUniqueYears } from '../../../../modules/data/year-on-year';
import {
  COUNT_OPTIONS,
  STATUS_OPTIONS,
  TIME_RANGE_OPTIONS,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsConfig';
import {
  setQualityClaimsAmount,
  setQualityClaimsCount,
  setQualityClaimsRegion,
  setQualityClaimsSelectedPackerPlantId,
  setQualityClaimsStatus,
  setQualityClaimsTimeRange,
  updateQualityClaimsTimePeriod,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsReducers';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Incidents = props => {
  const { data, singlePackerPlant, selectedRegion } = props;
  const { market: marketParam } = useParams();
  const dispatch = useDispatch();
  const handleNavigationToDetails = React.useCallback(() => {
    if (singlePackerPlant) {
      dispatch(setQualityClaimsSelectedPackerPlantId(singlePackerPlant.id));
      dispatch(setQualityClaimsRegion(singlePackerPlant.country));
    } else {
      dispatch(setQualityClaimsRegion(selectedRegion));
    }
    dispatch(setQualityClaimsStatus(STATUS_OPTIONS.SUBMITTED));
    dispatch(setQualityClaimsCount(COUNT_OPTIONS.TOTAL));
    dispatch(setQualityClaimsAmount(null));
    const now = new Date();
    dispatch(updateQualityClaimsTimePeriod([startOfYear(subYears(now, 3)), endOfYear(now)]));
    dispatch(setQualityClaimsTimeRange(TIME_RANGE_OPTIONS.YOY));
  }, [dispatch, selectedRegion, singlePackerPlant]);

  return (
    <div>
      <div className={styles.QualityClaims_incidentsTitle}>
        <Link
          to={`/analytic-dashboard/quality/${marketParam}/claims-details`}
          onClick={() => handleNavigationToDetails()}
        >
          <Typography.Text className={styles.QualityClaims_label}>
            count of incidents
            <Icon style={{ position: 'relative', width: 14 }} component={ChevronRight} />
          </Typography.Text>
        </Link>
      </div>
      {data && (
        <BaseChart
          barData={yearOnYear(
            data.map(x => ({
              interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
              value: x.value,
            }))
          )}
          columns={getUniqueYears(data.map(x => format(new Date(x.date), 'MMM yyyy')))}
          colors={{ value: '#959BB5' }}
          lastBarColor="#4E5984"
          splitIndex={-1}
          noCurrentLine={true}
          noLegend={true}
          ticksNumber={4}
          formatIntervals={singleMonthLetter}
          formatTooltip={val => `${val} incidents`}
          minMaxGenerator={(maxData, minData) => {
            return {
              maxVal: calculateRoundedMax(maxData),
              minVal: 0,
            };
          }}
          isSmall={true}
          isLineChart={true}
          withSmoothing={false}
          isYearOnYear={true}
        />
      )}
    </div>
  );
};

export default Incidents;
