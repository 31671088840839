const colors = {
  primary100: '#0B1435',
  primaryBoldScheme: {
    default: '#0B1435',
    500: '#0B1435',
  },
  primary: {
    default: '#0B1435',
    900: 'rgba(11, 20, 53, 0.9)',
    800: 'rgba(11, 20, 53, 0.8)',
    700: 'rgba(11, 20, 53, 0.7)',
    600: 'rgba(11, 20, 53, 0.6)',
    500: 'rgba(11, 20, 53, 0.5)',
    400: 'rgba(11, 20, 53, 0.4)',
    300: 'rgba(11, 20, 53, 0.3)',
    200: 'rgba(11, 20, 53, 0.2)',
    100: 'rgba(11, 20, 53, 0.1)',
    50: 'rgba(11, 20, 53, 0.05)',
  },
  secondary: {
    default: '#3A44B0',
    900: 'rgba(58, 68, 176, 0.9)',
    800: 'rgba(58, 68, 176, 0.8)',
    700: 'rgba(58, 68, 176, 0.7)',
    600: 'rgba(58, 68, 176, 0.6)',
    500: 'rgba(58, 68, 176, 0.5)',
    400: 'rgba(58, 68, 176, 0.4)',
    300: 'rgba(58, 68, 176, 0.3)',
    200: 'rgba(58, 68, 176, 0.2)',
    100: 'rgba(58, 68, 176, 0.1)',
    50: 'rgba(58, 68, 176, 0.05)',
  },
  tertiary: {
    default: '#e82feb',
    900: '#e82feb',
    800: 'rgba(232, 47, 235, 0.9)',
    700: 'rgba(232, 47, 235, 0.8)',
    600: 'rgba(232, 47, 235, 0.7)',
    500: 'rgba(232, 47, 235, 0.6)',
    400: 'rgba(232, 47, 235, 0.5)',
    300: 'rgba(232, 47, 235, 0.4)',
    200: 'rgba(232, 47, 235, 0.3)',
    100: 'rgba(232, 47, 235, 0.2)',
    50: 'rgba(232, 47, 235, 0.1)',
  },
  nav: {
    main: '#05314C',
    dark: '#0B1435',
    light: '#2544b4',
    text: '#ffffff',
    lightShadow: 'rgba(126, 114, 242, 0.35)',
    darkShadow: 'rgba(0, 0, 0, 0.25)',
    scrollBarBackgroundColor: '#05314C',
    active: '#FD5C38',
    500: '#05314C',
    horizontalBandBgColor: '#EBF0FF',
  },
  blackScheme: {
    900: '#09101D',
    800: 'rgba(9, 16, 29, 0.9)',
    700: 'rgba(9, 16, 29, 0.8)',
    600: 'rgba(9, 16, 29, 0.7)',
    500: 'rgba(9, 16, 29, 0.6)',
    400: 'rgba(9, 16, 29, 0.5)',
    300: 'rgba(9, 16, 29, 0.4)',
    200: 'rgba(9, 16, 29, 0.3)',
    100: 'rgba(9, 16, 29, 0.2)',
    50: 'rgba(9, 16, 29, 0.1)',
  },
  whiteBoldScheme: {
    900: '#FFFFFF',
    800: '#FFF',
    700: '#FFF',
    600: '#FFF',
    500: '#FFF',
    400: '#FFF',
    300: '#FFF',
    200: '#FFF',
    100: '#FFF',
    50: '#FFF',
  },
  neutral: {
    black: '#09101D',
    1: '#2C3A4B',
    2: '#394452',
    3: '#545D69',
    4: '#6D7580',
    5: '#858C94',
    6: '#A5ABB3',
    7: '#DADEE3',
    8: '#EBEEF2',
    9: '#F4F6F9',
    white: '#FFFFFF',
  },
  gray3: { default: '#828282' },
  darkGray: { default: '#343434' },
  graySubtle: { default: '#FAFAFA' },
  grayBorder: { default: '#CCC' },
  geekBlue: {
    default: '#2F54EB',
  },
  black: '#09101D',
  white: '#FFFFFF',
  accent: {
    1: '#FD5C38',
    2: '#2E5BFF',
    3: '#21DDB8',
    4: '#221874',
    5: '#7CC6D6',
    6: '#EA5455',
    7: '#21DDB847',
    8: '#2D6A6A1A',
    9: '#E9AD8C3B',
    10: '#2218741A',
    11: '#7CC6D640',
    12: '#E1604D26',
    one: {
      default: '#FD5C38',
      500: '#FD5C38',
    },
    two: {
      default: '#2E5BFF',
      500: '#2E5BFF',
    },
    three: {
      default: '#21DDB8',
      500: '#21DDB8',
    },
    four: {
      default: '#221874',
      500: '#221874',
    },
    five: {
      default: '#7CC6D6',
      500: '#7CC6D6',
    },
    six: {
      default: '#EA5455',
      500: '#EA5455',
    },
    seven: {
      default: '#21DDB847',
      500: '#21DDB847',
    },
    eight: {
      default: '#2D6A6A1A',
      500: '#2D6A6A1A',
    },
    nine: {
      default: '#E9AD8C3B',
      500: '#E9AD8C3B',
    },
    ten: {
      default: '#2218741A',
      500: '#2218741A',
    },
    eleven: {
      default: '#7CC6D640',
      500: '#7CC6D640',
    },
    twelve: {
      default: '#E1604D26',
      500: '#E1604D26',
    },
  },
  actionPrimary: {
    default: '#031B4E',
    hover: 'rgba(3, 27, 78, 0.8)',
    active: '#031B4E',
    disabled: 'rgba(11, 95, 255, 0.5)',
    hover10: 'rgba(0, 84, 240, 0.1)',
    active20: 'rgba(0, 75, 214, 0.2)',
    inverted: 'rgba(255, 255, 255, 1)',
    visited: 'rgba(46, 91, 255, 1)',
    600: '#031B4E',
    500: '#031B4E',
  },
  actionSecondary: {
    default: '#3A44B0',
    hover: 'rgba(58, 68, 176, 0.8)',
    blueButton: '#007eff',
    active: 'rgba(58, 68, 176, 0.7)',
    disabled: 'rgba(58, 68, 176, 0.5)',
    hover10: 'rgba(58, 68, 176, 0.3)',
    active20: 'rgba(58, 68, 176, 0.2)',
    inverted: 'rgba(255, 255, 255, 1)',
    visited: 'rgba(46, 91, 255, 1)',
    600: '#3A44B0',
    500: '#3A44B0',
  },
  actionTertiary: {
    default: '#05314C',
    hover: 'rgba(240, 245, 255, 0.8)',
    active: 'rgba(240, 245, 255, 0.7)',
    disabled: 'rgba(240, 245, 255, 0.5)',
    hover10: 'rgba(240, 245, 255, 0.3)',
    active20: 'rgba(240, 245, 255, 0.2)',
    inverted: '#FFFFFF',
    visited: '#05314C',
    900: '#05314C',
    800: 'rgba(165, 16, 16, 1)',
    700: 'rgba(165, 16, 16, 1)',
    600: 'rgba(165, 16, 16, 1)',
    500: 'rgba(165, 16, 16, 1)',
    400: 'rgba(165, 16, 16, 0.8)',
    300: 'rgba(165, 16, 16, 0.7)',
    200: 'rgba(165, 16, 16, 0.6)',
    100: 'rgba(165, 16, 16, 0.5)',
    50: 'rgba(165, 16, 16, 0.4)',
  },
  actionNeutral: {
    default: '#9098A1',
    hover: '#858C94',
    active: '#798087',
    disabled: 'rgba(144, 152, 161, 0.7)',
    hover10: 'rgba(109, 117, 128, 0.1)',
    active20: 'rgba(109, 117, 128, 0.2)',
    inverted: '#FFFFFF',
    visited: '#5E38BA',
    500: '#9098A1',
  },
  success: { default: '#00BA88', 500: '#00BA88' },
  suggessBg: { default: '#EDF9F0', 500: '#EDF9F0' },
  warning: { default: '#E79900', 500: '#E79900' },
  warningBg: { default: '#FFFBE6', 500: '#FFFBE6' },
  warningBorder: { default: '#F3C160', 500: '#F3C160' },
  error: { default: '#DA1414', 500: '#DA1414' },
  errorBg: { default: '#FEEFEF', 500: '#FEEFEF' },
  info: { default: '#2E5AAC', 500: '#2E5AAC' },
  infoBg: { default: '#EEF2FA', 500: '#EEF2FA' },
  infoBorder: { default: '#89A7E0', 500: '#89A7E0' },
  mainGradient: {
    900: '#031B4E',
    50: '#0043FF',
  },
  secondaryGradient: {
    900: '#4BF2E6',
    50: '#0065FF',
  },
  coolGradient: {
    900: '#9482FC',
    50: '#05314C',
  },
  loginBgGradient: {
    900: '#2D3357',
    50: '#161A29',
  },
  card: {
    default: '#FAFAFA',
    500: '#FAFAFA',
  },
};

export default colors;
