import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

const TextFormatter = (props, style) => {
  return (
    <Box fontSize="14px" {...style}>
      {props?.value || props}
    </Box>
  );
};

TextFormatter.propTypes = {
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
};

export default TextFormatter;
