import React, { useEffect, useState, useReducer, useRef } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Text,
  VStack,
  Flex,
  StackDivider,
  Tr,
  Td,
  Tooltip,
  Badge,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Spinner,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import SelectField from '../basic/SelectField';
import isEmpty from 'lodash/isEmpty';
import { UserSelectFieldOption, UserSelectFieldValueContainer } from './UserSelectFieldComponents';
import CategoryTag from './CategoryTag';
import ResolutionButton from './ResolutionButton';
import EditInternationalOrder from '../../containers/orders/EditInternationalOrder';
import OrderTableModal from '../../containers/orders/OrderTableModal';
import { fetchNetsuitePurchaseOrder } from '../../actions/actions_orders';
import { checkIfChicagoForm } from '../lib/PurchaseOrderFunctions';

// PurchaseOrderFunctions
const initialState = {
  isLoading: false,
  exceptionId: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'loading':
      return {
        isLoading: true,
        exceptionId: action.payload,
      };
    case 'done':
      return {
        isLoading: false,
        exceptionId: null,
      };
    default:
      throw new Error();
  }
}

const TableBodyContent = ({ allPurchaseOrders, onResolveClick, userOptions, onAssign, dispatch, token }) => {
  const initRef = useRef();
  const previousOrder = useRef();
  const [showEditForm, setShowEditForm] = useState(false);
  const [isChicagoForm, setIsChicagoForm] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [userAssignmentState, dispatchAssignment] = useReducer(reducer, initialState);
  const [userResolveState, dispatchResolve] = useReducer(reducer, initialState);

  useEffect(() => {
    if (currentOrder) {
      dispatch(fetchNetsuitePurchaseOrder(currentOrder.id, token)).then(() => {
        const formIsChicago = checkIfChicagoForm({
          purchasingOffice: currentOrder.purchasing_office,
          invoicingOffice: currentOrder.invoicing_office,
        });
        previousOrder.current = currentOrder;
        setIsChicagoForm(formIsChicago);
        setShowEditForm(!showEditForm);
      });
    }
  }, [currentOrder]);

  const handleClosePOEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  const handleOptionSelect = (exceptionId, selectedOption, onClose, assign = true) => {
    dispatchAssignment({ type: 'loading', payload: exceptionId });
    if (assign) {
      // Add or update user assignment
      onAssign(exceptionId, { assignee: selectedOption.value.toString() }).finally(() => {
        dispatchAssignment({ type: 'done' });
      });
      onClose();
    } else {
      // Remove user assignment
      onAssign(exceptionId, { assignee: null }).finally(() => {
        dispatchAssignment({ type: 'done' });
      });
    }
  };

  return (
    <>
      {showEditForm &&
        currentOrder &&
        (isChicagoForm ? (
          <OrderTableModal selectCurrentModalDetail={handleClosePOEditForm} />
        ) : (
          <EditInternationalOrder onClose={handleClosePOEditForm} />
        ))}

      {!isEmpty(allPurchaseOrders.purchase_orders) ? (
        allPurchaseOrders.purchase_orders.map(data => {
          const { purchase_order: purchaseOrder } = data;
          const { exceptions } = purchaseOrder;

          return (
            <Tr key={purchaseOrder.id}>
              <Td fontWeight="medium">
                <Text
                  data-po-number={get(purchaseOrder, 'internal_po_number')}
                  onClick={() => setCurrentOrder({ ...purchaseOrder })}
                  _hover={{ cursor: 'pointer' }}
                  fontWeight="600"
                >
                  {get(purchaseOrder, 'grinder_po_number')}
                </Text>
              </Td>
              <Td>
                <Text>{get(purchaseOrder, 'internal_po_number')}</Text>
              </Td>
              <Td>
                <Text>{get(data, 'grinder_name')}</Text>
              </Td>
              <Td>
                <Text>{get(data, 'packer_name')}</Text>
              </Td>
              <Td paddingX={0}>
                <VStack align="stretch" divider={<StackDivider borderWidth="1.5px" style={{ margin: '10px 0' }} />}>
                  {exceptions.map(exception => {
                    return (
                      <Flex key={exception.id} alignItems="center" height="43px">
                        <CategoryTag
                          category={get(exception, 'exception_type.category')}
                          label={get(exception, 'exception_type.label')}
                        />
                      </Flex>
                    );
                  })}
                </VStack>
              </Td>
              <Td paddingX={0}>
                <VStack
                  align="stretch"
                  alignItems="center"
                  divider={<StackDivider borderWidth="1.5px" style={{ margin: '10px 0' }} />}
                >
                  {exceptions.map(exception => {
                    const assignedUser = exception.assignee
                      ? {
                          value: exception.assigned_user.id,
                          label: `${exception.assigned_user.first_name} ${exception.assigned_user.last_name}`,
                          selected: true,
                        }
                      : null;
                    const userOptionsUpdated = userOptions
                      .map(userOption => (userOption.value === assignedUser?.value ? assignedUser : userOption))
                      .reduce((acc, element) => {
                        if (element.selected) {
                          return [element, ...acc];
                        }
                        return [...acc, element];
                      }, []);
                    return (
                      <Flex key={exception.id} alignItems="center" color="gray.400" height="43px">
                        {userAssignmentState.isLoading && userAssignmentState.exceptionId === exception.id ? (
                          <Spinner cursor="pointer" />
                        ) : (
                          <Popover isLazy placement="left" initialFocusRef={initRef}>
                            {({ onClose }) =>
                              !isEmpty(get(exception, 'assigned_user')) ? (
                                <>
                                  <PopoverTrigger>
                                    <Box tabIndex="0" role="button" aria-label="Some box">
                                      <Tooltip
                                        hasArrow
                                        label={`${get(exception, 'assigned_user.first_name')} ${get(
                                          exception,
                                          'assigned_user.last_name'
                                        )}`}
                                        bg="black"
                                        placement="top"
                                      >
                                        <Badge
                                          variant="solid"
                                          width="30px"
                                          height="30px"
                                          borderRadius="30px"
                                          colorScheme="actionSecondary"
                                        >
                                          <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
                                            <Text fontWeight="medium">
                                              {get(exception, 'assigned_user.first_name', '').trim().slice(0, 1)}
                                              {get(exception, 'assigned_user.last_name', '').trim().slice(0, 1)}
                                            </Text>
                                          </Flex>
                                        </Badge>
                                      </Tooltip>
                                    </Box>
                                  </PopoverTrigger>
                                  <PopoverContent>
                                    <PopoverBody padding={0}>
                                      <SelectField
                                        placeholder="Search"
                                        options={userOptionsUpdated}
                                        optionStyles={{
                                          pointerEvents: 'none',
                                        }}
                                        onChange={selectedOption => {
                                          if (selectedOption.value === assignedUser.value) {
                                            // Unassign user
                                            handleOptionSelect(exception.id, selectedOption, onClose, false);
                                          } else {
                                            // Assign user
                                            handleOptionSelect(exception.id, selectedOption, onClose);
                                          }
                                        }}
                                        containerWidth="100%"
                                        asHorizontalStack
                                        containerStackProps={{
                                          align: 'center',
                                        }}
                                        controlStyles={{
                                          borderRadius: '0px',
                                        }}
                                        menuStyles={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                          borderRadius: '0px',
                                        }}
                                        placeholderStyles={{ marginLeft: '20px' }}
                                        singleValueStyles={{ marginLeft: '20px' }}
                                        components={{
                                          DropdownIndicator: () => null,
                                          ValueContainer: UserSelectFieldValueContainer,
                                          Option: UserSelectFieldOption,
                                        }}
                                      />
                                    </PopoverBody>
                                  </PopoverContent>
                                </>
                              ) : (
                                <>
                                  <PopoverTrigger>
                                    <Box tabIndex="0" role="button" aria-label="Some box">
                                      <Tooltip hasArrow label="Assign Task" bg="black" placement="bottom">
                                        <Box position="relative">
                                          <i
                                            className="fa fa-user-circle"
                                            style={{
                                              fontSize: '30px',
                                            }}
                                          />
                                          <Badge
                                            variant="solid"
                                            width="14px"
                                            height="14px"
                                            borderRadius="14px"
                                            colorScheme="actionSecondary"
                                            position="absolute"
                                            right={0}
                                            bottom={0}
                                            padding={0}
                                          >
                                            <Flex
                                              justifyContent="center"
                                              alignItems="center"
                                              height="100%"
                                              width="100%"
                                            >
                                              <AddIcon boxSize="8.4px" />
                                            </Flex>
                                          </Badge>
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </PopoverTrigger>
                                  <PopoverContent width="176px">
                                    <PopoverBody padding={0}>
                                      <SelectField
                                        placeholder="Search"
                                        options={userOptions}
                                        onChange={selectedOption =>
                                          handleOptionSelect(exception.id, selectedOption, onClose)
                                        }
                                        containerWidth="100%"
                                        asHorizontalStack
                                        containerStackProps={{
                                          align: 'center',
                                        }}
                                        controlStyles={{
                                          borderRadius: '0px',
                                        }}
                                        menuStyles={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                          borderRadius: '0px',
                                        }}
                                        placeholderStyles={{ marginLeft: '20px' }}
                                        singleValueStyles={{ marginLeft: '20px' }}
                                        components={{
                                          DropdownIndicator: () => null,
                                          ValueContainer: UserSelectFieldValueContainer,
                                          Option: UserSelectFieldOption,
                                        }}
                                      />
                                    </PopoverBody>
                                  </PopoverContent>
                                </>
                              )
                            }
                          </Popover>
                        )}
                      </Flex>
                    );
                  })}
                </VStack>
              </Td>
              <Td paddingX={0} textAlign="center" verticalAlign="center">
                <VStack
                  align="stretch"
                  alignItems="center"
                  divider={<StackDivider borderWidth="1.5px" style={{ margin: '10px 0' }} />}
                >
                  {exceptions.map(exception => {
                    return (
                      <Flex key={exception.id} alignItems="center" height="43px">
                        {userResolveState.isLoading && userResolveState.exceptionId === exception.id ? (
                          <Spinner cursor="pointer" />
                        ) : (
                          <ResolutionButton
                            isResolved={exception.status === 'CLOSED' && exception.is_flagged}
                            onClick={() => {
                              dispatchResolve({ type: 'loading', payload: exception.id });
                              onResolveClick(exception.id, {
                                is_flagged: !exception.is_flagged,
                                status: exception.status === 'CLOSED' ? 'OPEN' : 'CLOSED',
                              }).finally(() => {
                                dispatchResolve({ type: 'done' });
                              });
                            }}
                          />
                        )}
                      </Flex>
                    );
                  })}
                </VStack>
              </Td>
            </Tr>
          );
        })
      ) : (
        <Tr>
          <Td colSpan={7}>
            <Flex width="100%" height="200px" justifyContent="center" alignItems="center">
              <Text>No Daily Tasks found</Text>
            </Flex>
          </Td>
        </Tr>
      )}
    </>
  );
};
TableBodyContent.propTypes = {
  allPurchaseOrders: PropTypes.objectOf(PropTypes.any).isRequired,
  onResolveClick: PropTypes.func.isRequired,
  userOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAssign: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  token: PropTypes.string,
};

export default TableBodyContent;
