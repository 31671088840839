import { Icon } from '@ant-design/compatible';
import React from 'react';
import './Loading.scss';

const Loading = ({ style }) => {
  return (
    <div className="loading" style={style}>
      <Icon type="loading" />
      <div>Loading</div>
    </div>
  );
};

export default Loading;
