/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Local
import Constants from '../../../../Constants';
import './ColdStoreDetails.scss';
import SelectField from '../../../basic/SelectField';
import ColdStoreLotNumbers from './ColdStoreLotNumbers';
import { Badge, Box, Checkbox, FormLabel, HStack, IconButton, Input, Text, VStack, Wrap } from '@chakra-ui/react';
import CustomFormControl from '../../../core/CustomFormControl';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { TimePicker } from 'antd';
import moment from 'moment';
import { sortInAlphabeticalOrder } from '../../../../functions';
import DateTimeWithTrappedFocus from '../../../core/DateTimeWithTrappedFocus';
import Tooltip from '../../../quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const propTypes = {
  disabled: PropTypes.bool,
  coldStoreDetails: PropTypes.shape({
    coldStore: PropTypes.shape({
      address: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        line1: PropTypes.string,
        line2: PropTypes.string,
        postCode: PropTypes.string,
        state: PropTypes.string,
      }),
      addressId: PropTypes.number,
      companyName: PropTypes.string,
      id: PropTypes.number,
      initialFreeTime: PropTypes.number,
      initialPrice: PropTypes.number,
      initialPriceCurrency: PropTypes.string,
      lat: PropTypes.number,
      locationName: PropTypes.string,
      lon: PropTypes.number,
      notificationEmail: PropTypes.string,
      rollWindow: PropTypes.number,
      rollWindowPrice: PropTypes.number,
      rollWindowPriceCurrency: PropTypes.string,
      timezone: PropTypes.string,
    }),
    coldStoreId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]), // TODO -- resolve
    containerBackTemp: PropTypes.string,
    containerFrontTemp: PropTypes.string,
    containerMiddleTemp: PropTypes.string,
    dateOfEntry: PropTypes.string,
    id: PropTypes.number,
    inspectionDate: PropTypes.string,
    receiptDate: PropTypes.string,
    lotNumbers: PropTypes.arrayOf(PropTypes.string),
    overRedLine: PropTypes.bool,
    purchaseOrderId: PropTypes.number,
    temperatureMeasurementUnitId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    customedClearedDate: PropTypes.Datetime,
  }).isRequired,
  coldStores: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        line1: PropTypes.string,
        line2: PropTypes.string,
        postCode: PropTypes.string,
        state: PropTypes.string,
      }),
      addressId: PropTypes.number,
      companyName: PropTypes.string,
      id: PropTypes.number,
      initialFreeTime: PropTypes.number,
      initialPrice: PropTypes.number,
      initialPriceCurrency: PropTypes.string,
      lat: PropTypes.number,
      locationName: PropTypes.string,
      lon: PropTypes.number,
      notificationEmail: PropTypes.string,
      rollWindow: PropTypes.number,
      rollWindowPrice: PropTypes.number,
      rollWindowPriceCurrency: PropTypes.string,
      timezone: PropTypes.string,
    })
  ),
  defaultColdstoreID: PropTypes.number,
  onObjectValueChange: PropTypes.func,
  handleAtColdstore: PropTypes.func,
  setColdstoreStatus: PropTypes.bool,
  form: PropTypes.instanceOf(Object),
  transporterOptions: PropTypes.arrayOf(PropTypes.object),
  handleDateChange: PropTypes.func,
  validationErrors: PropTypes.arrayOf(PropTypes.object),
  isUSADomestic: PropTypes.bool,
  isFrozenProduct: PropTypes.bool,
};

const ColdStoreDetails = ({
  disabled,
  coldStoreDetails,
  coldStores,
  defaultColdstoreID,
  onObjectValueChange,
  handleAtColdstore,
  setColdstoreStatus,
  form,
  transporterOptions,
  handleDateChange,
  validationErrors,
  isUSADomestic,
  isFrozenProduct,
}) => {
  const [state, setState] = useState({
    temperatureIsExpanded: true,
    coldStoreReleasedIsExpanded: true,
    refuseDeliveryTimePicker: false,
  });

  useEffect(() => {
    setState({ ...state, refuseDeliveryTimePicker: !form.scheduledDeliveryTime });
  }, [form.scheduledDeliveryTime]);

  const coldStoreOptions = [];
  const handleInspectionDateChange = e => {
    // eslint-disable-next-line no-underscore-dangle
    const inspectionDate = e._isAMomentObject ? e.format('YYYY-MM-DD') : null;
    onObjectValueChange('coldstoreDetails', 'inspectionDate', inspectionDate);
    handleAtColdstore(Boolean(coldStoreDetails.inspectionDate || inspectionDate));
  };

  const handleReceiptDateChange = e => {
    // eslint-disable-next-line no-underscore-dangle
    const receiptDate = e._isAMomentObject ? e.format('YYYY-MM-DD') : null;
    onObjectValueChange('coldstoreDetails', 'receiptDate', receiptDate);
    handleAtColdstore(Boolean(coldStoreDetails.receiptDate || receiptDate));
  };

  coldStores.forEach(el => {
    coldStoreOptions.push({ label: el.locationName, value: el.id });
  });

  coldStoreOptions.sort((a, b) => {
    const packerA = a.label.toLowerCase();

    const packerB = b.label.toLowerCase();
    return sortInAlphabeticalOrder(packerA, packerB);
  });

  let selectedColdStoreID = null;

  if (coldStoreDetails?.coldStoreId) {
    selectedColdStoreID = coldStoreDetails.coldStoreId;
  } else {
    selectedColdStoreID = defaultColdstoreID;
  }

  const InspectionDateOptions = {
    className: 'form-control m-input',
    disabled: disabled || selectedColdStoreID === null,
  };

  const CustomsDateOptions = {
    className: 'form-control m-input',
    disabled: disabled || selectedColdStoreID === null,
  };

  const temperatureMeasurementUnit = coldStoreDetails?.temperatureMeasurementUnitId
    ? Constants.TEMPERATURE_MEASUREMENT_UNITS.find(unit => unit.value === coldStoreDetails.temperatureMeasurementUnitId)
    : Constants.TEMPERATURE_MEASUREMENT_UNITS.find(unit => unit.label === Constants.FAHRENHEIT);

  const areColdstoreReleaseFieldsDisabled = true;

  return (
    <VStack align="stretch" spacing="22px">
      <HStack spacing="21px" alignItems="flex-start">
        {/* <!-- COLDSTORE LOCATION --> */}
        <CustomFormControl width="257px">
          <FormLabel htmlFor="coldstore-location">
            Coldstore Location&nbsp;
            <Tooltip
              content="Specifies the location within the cold storage facility where the goods are stored"
              placement="right"
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
          </FormLabel>
          <SelectField
            isClearable
            isDisabled={disabled}
            name="coldstore-location"
            defaultValue={selectedColdStoreID}
            options={coldStoreOptions}
            onChange={e => onObjectValueChange('coldstoreDetails', 'coldStoreId', e ? e.value : '')}
          />
        </CustomFormControl>

        {/* <!-- INSPECTION DATE & TIME --> */}
        {!isUSADomestic ? (
          <CustomFormControl width="257px">
            <FormLabel htmlFor="customs-cleared-date">
              Customs Cleared Date&nbsp;
              <Tooltip
                content="Records the date when the shipment clears customs for tracking customs clearance processes."
                placement="right"
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </FormLabel>
            <DateTimeWithTrappedFocus
              id="customs-cleared-date"
              inputProps={CustomsDateOptions}
              closeOnSelect
              isDisabled={disabled}
              value={coldStoreDetails?.customedClearedDate}
              onChange={e =>
                onObjectValueChange(
                  'coldstoreDetails',
                  'customedClearedDate',
                  // eslint-disable-next-line no-underscore-dangle
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : null
                )
              }
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </CustomFormControl>
        ) : (
          ''
        )}
        <CustomFormControl width="257px" position="relative">
          <VStack spacing="2px" marginBottom="7px" align="stretch">
            {setColdstoreStatus || coldStoreDetails?.inspectionDate ? (
              <Badge
                color="#FFFFFF"
                backgroundColor="#FFAB00"
                textTransform="uppercase"
                fontWeight="bold"
                fontSize="12px"
                paddingX="4px"
                paddingY="1px"
                borderRadius="2px"
                margin="0px"
                width="fit-content"
                position="absolute"
                top="-20px"
              >
                ADDED TO COLDSTORE
              </Badge>
            ) : null}
            <FormLabel htmlFor="inspection-date" margin="0px">
              {isUSADomestic && !isFrozenProduct ? (
                'Coldstore Receipt Date'
              ) : (
                <>
                  Inspection Date&nbsp;
                  <Tooltip
                    content="Records the date when the inspection of the goods occurred for quality control"
                    placement="right"
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </>
              )}
            </FormLabel>
          </VStack>
          <DateTimeWithTrappedFocus
            id={isUSADomestic ? 'coldstore-receipt-date' : 'inspection-date'}
            inputProps={InspectionDateOptions}
            closeOnSelect
            value={isUSADomestic && !isFrozenProduct ? coldStoreDetails?.receiptDate : coldStoreDetails?.inspectionDate}
            onChange={e =>
              isUSADomestic && !isFrozenProduct ? handleReceiptDateChange(e) : handleInspectionDateChange(e)
            }
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
          />
        </CustomFormControl>
        {/* <!-- LOT NUMBERS --> */}
        <Box>
          <FormLabel htmlFor="lot-number">
            Lot Numbers&nbsp;
            <Tooltip
              content="Records or displays the lot numbers associated with the shipment for tracking specific batches"
              placement="right"
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                icon={<IoInformationCircleOutline size="14px" />}
                variant="unstyled"
              />
            </Tooltip>
          </FormLabel>
          <ColdStoreLotNumbers
            lotNumbers={coldStoreDetails?.lotNumbers}
            disabled={disabled}
            onObjectValueChange={onObjectValueChange}
          />
        </Box>
        {/* <!-- AT COLDSTORE CHECKBOX --> 
        <div className="col-sm-2" style={{ display: 'none' }}>
          At coldstore &nbsp; &nbsp;
          <input
            id="at-coldstore"
            type="checkbox"
            disabled={disabled}
            style={checkBoxStyle}
            checked={setColdstoreStatus}
            value={setColdstoreStatus}
            onChange={e => handleAtColdstore(e.target.checked)}
          />
        </div> */}
      </HStack>
      {/* <!-- CONTAINER TEMPERATURES --> */}
      {!isUSADomestic ? (
        <VStack spacing="11px" align="stretch">
          <HStack justifyContent="space-between" color="accent.one.default" width="190px">
            <Text as="p" fontWeight="medium" color="inherit">
              Container Temperatures
            </Text>
            <IconButton
              variant="unstyled"
              minWidth="18px"
              height="fit-content"
              padding="0px"
              fontSize="18px"
              icon={state.temperatureIsExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
              onClick={() => setState({ ...state, temperatureIsExpanded: !state.temperatureIsExpanded })}
            />
          </HStack>
          {state.temperatureIsExpanded && (
            <Wrap spacing="21px">
              <CustomFormControl width="257px">
                <FormLabel htmlFor="container-front-temp">
                  Front&nbsp;
                  <Tooltip
                    content="Records the temperature at the front of the container to monitor temperature distribution."
                    placement="right"
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <Input
                  type="number"
                  step="0.01"
                  min="-80"
                  name="container-front-temp"
                  value={get(coldStoreDetails, 'containerFrontTemp', '')}
                  disabled={disabled}
                  className="form-control"
                  onChange={e => onObjectValueChange('coldstoreDetails', 'containerFrontTemp', e.target.value || '')}
                  onWheelCapture={e => {
                    e.target.blur();
                  }}
                />
              </CustomFormControl>
              <CustomFormControl width="257px">
                <FormLabel htmlFor="container-middle-temp">
                  Middle&nbsp;
                  <Tooltip
                    content="Records the temperature in the middle section of the container for additional temperature monitoring."
                    placement="right"
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <Input
                  type="number"
                  step="0.01"
                  min="-80"
                  name="container-middle-temp"
                  value={get(coldStoreDetails, 'containerMiddleTemp', '')}
                  disabled={disabled}
                  className="form-control"
                  onChange={e => onObjectValueChange('coldstoreDetails', 'containerMiddleTemp', e.target.value || '')}
                  onWheelCapture={e => {
                    e.target.blur();
                  }}
                />
              </CustomFormControl>
              <CustomFormControl width="257px">
                <FormLabel htmlFor="container-back-temp">
                  Back&nbsp;
                  <Tooltip
                    content="Records the temperature at the back of the container to complete temperature distribution assessment."
                    placement="right"
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <Input
                  type="number"
                  step="0.01"
                  min="-80"
                  name="container-back-temp"
                  value={get(coldStoreDetails, 'containerBackTemp', '')}
                  disabled={disabled}
                  className="form-control"
                  onChange={e => onObjectValueChange('coldstoreDetails', 'containerBackTemp', e.target.value || '')}
                  onWheelCapture={e => {
                    e.target.blur();
                  }}
                />
              </CustomFormControl>
              {/* <!-- UNITS OF MEASURE --> */}
              <CustomFormControl width="135px">
                <FormLabel htmlFor="temperature-measurement-units">
                  Units of Measure&nbsp;
                  <Tooltip
                    content="Selects the unit of temperature measurement from °F, °C, or °K for standardized temperature reporting."
                    placement="right"
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <SelectField
                  isDisabled={disabled}
                  name="temperatureMeasurementUnitId"
                  defaultValue={temperatureMeasurementUnit}
                  placeholder="UOM"
                  onChange={e =>
                    onObjectValueChange('coldstoreDetails', 'temperatureMeasurementUnitId', parseInt(e.value, 10))
                  }
                  options={Constants.TEMPERATURE_MEASUREMENT_UNITS}
                />
              </CustomFormControl>
              {/* <!-- OVER THE RED LINE --> */}
              <CustomFormControl width="fit-content" marginLeft="30px !important">
                <HStack spacing="15px" marginTop="35px">
                  <Checkbox
                    backgroundColor="inherit"
                    type="checkbox"
                    colorScheme="actionSecondary"
                    name="over-red-line"
                    isDisabled={disabled}
                    marginBottom="0px"
                    isChecked={coldStoreDetails?.overRedLine || false}
                    onChange={() =>
                      onObjectValueChange('coldstoreDetails', 'overRedLine', !coldStoreDetails.overRedLine)
                    }
                  />
                  <FormLabel>
                    Over the red line&nbsp;
                    <Tooltip
                      content='Selects the checkbox if any temperature reading exceeds a predefined threshold ("red line") to identify temperature excursions.'
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                </HStack>
              </CustomFormControl>
            </Wrap>
          )}
        </VStack>
      ) : (
        ''
      )}
      <VStack spacing="11px" align="stretch">
        <HStack justifyContent="space-between" color="accent.one.default" width="190px">
          <Text as="p" fontWeight="medium" color="inherit">
            Coldstore Release
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="18px"
            icon={state.coldStoreReleasedIsExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
            onClick={() => setState({ ...state, coldStoreReleasedIsExpanded: !state.coldStoreReleasedIsExpanded })}
          />
        </HStack>
        {state.coldStoreReleasedIsExpanded && (
          <Wrap spacing="21px">
            <CustomFormControl>
              <FormLabel htmlFor="pickup-date">
                Pickup Date&nbsp;
                <Tooltip
                  content="Records the date when the goods are scheduled or ready for pickup by the transportation provider."
                  placement="right"
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <DateTimeWithTrappedFocus
                id="pickup-date"
                inputProps={{
                  className: 'form-control m-input',
                  disabled: areColdstoreReleaseFieldsDisabled,
                }}
                closeOnSelect
                value={form.shipmentDate}
                onChange={e => {
                  handleDateChange(
                    'shipmentDate',
                    e._isAMomentObject ? e.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                  );
                }}
                className={validationErrors.requiredFields?.form?.includes('shipmentDate') ? 'error' : ''}
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
              />
            </CustomFormControl>
            <CustomFormControl>
              <FormLabel htmlFor="delivery-date">
                Delivery Date&nbsp;
                <Tooltip
                  content="Records the date when the goods are scheduled or expected to be delivered for logistics planning."
                  placement="right"
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <DateTimeWithTrappedFocus
                id="delivery-date"
                inputProps={{
                  className: 'form-control m-input',
                  disabled: areColdstoreReleaseFieldsDisabled,
                }}
                closeOnSelect
                value={form.deliveryDate}
                onChange={e => {
                  handleDateChange(
                    'deliveryDate',
                    e._isAMomentObject ? e.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                  );
                }}
                className={validationErrors.requiredFields?.form?.includes('deliveryDate') ? 'error' : ''}
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
              />
            </CustomFormControl>
            <CustomFormControl>
              <HStack>
                <FormLabel htmlFor="delivery-time">
                  Delivery Time&nbsp;
                  <Tooltip
                    content="Records the expected delivery time for precise scheduling and coordination of the delivery process."
                    placement="right"
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </FormLabel>
                <Checkbox
                  colorScheme="actionSecondary"
                  defaultChecked={state.refuseDeliveryTimePicker}
                  onChange={e => {
                    setState({ ...state, refuseDeliveryTimePicker: e.target.checked });
                  }}
                  isDisabled={areColdstoreReleaseFieldsDisabled}
                  isChecked={state.refuseDeliveryTimePicker || false}
                >
                  N/A
                </Checkbox>
              </HStack>
              <TimePicker
                use12Hours
                disabled={areColdstoreReleaseFieldsDisabled}
                format="h:mm a"
                value={
                  state.refuseDeliveryTimePicker
                    ? null
                    : form.scheduledDeliveryTime && moment(form.scheduledDeliveryTime, 'HH:mm')
                }
                onChange={value => handleDateChange('scheduledDeliveryTime', value && value.format('HH:mm'))}
                className="form-control m-input"
              />
            </CustomFormControl>
            <CustomFormControl width="257px">
              <FormLabel htmlFor="truckingCompany">
                Trucking Company&nbsp;
                <Tooltip
                  content="Specifies the transportation company responsible for transporting the goods"
                  placement="right"
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <SelectField
                isDisabled={areColdstoreReleaseFieldsDisabled}
                name="truckingCompany"
                defaultValue={form.transporterId}
                placeholder="Trucking Company"
                onChange={e => onObjectValueChange('form', 'transporterId', e.value)}
                options={transporterOptions}
              />
            </CustomFormControl>
          </Wrap>
        )}
      </VStack>
    </VStack>
  );
};

ColdStoreDetails.propTypes = propTypes;

export default ColdStoreDetails;
