import get from 'lodash/get';
import { PO_DOCUMENTS_FETCHED } from '../actions/actions_generated_documents';
import { CONFIRMATION_DOC_LOADING, CONF_DOC_GENERATED, CONF_DOC_RESET } from '../actions/actions_orders';
import Constants from '../Constants';

const initialState = {
  cos: {
    loading: false,
    url: null,
    id: null,
  },
  cop: {
    loading: false,
    url: null,
    id: null,
  },
  documents: [],
};

const poDocuments = (state = initialState, action) => {
  switch (action.type) {
    case CONF_DOC_GENERATED: {
      const documentType = get(action, 'documentType');
      const url = get(action, 'document.url');
      const id = get(action, 'document.id');
      if (documentType === Constants.PO_DOC_TYPES.PURCHASE) {
        return {
          ...state,
          cop: {
            loading: false,
            url,
            id,
          },
        };
      }

      if (documentType === Constants.PO_DOC_TYPES.SALE) {
        return {
          ...state,
          cos: {
            loading: false,
            url,
            id,
          },
        };
      }

      return state;
    }
    case CONFIRMATION_DOC_LOADING:
      const copy = JSON.parse(JSON.stringify(state));

      if (action.documentType === Constants.PO_DOC_TYPES.SALE) {
        copy.cos.loading = true;
      }

      if (action.documentType === Constants.PO_DOC_TYPES.PURCHASE) {
        copy.cop.loading = true;
      }
      return copy;

    case CONF_DOC_RESET:
      return initialState;

    case PO_DOCUMENTS_FETCHED: {
      return {
        ...state,
        documents: action.documents,
        purchaseOrder: action.purchaseOrder,
      };
    }
    default:
      return {
        ...state,
        cos: {
          ...state.cos,
          loading: false,
        },
        cop: {
          ...state.cop,
          loading: false,
        },
      };
  }
};

export default poDocuments;
