import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { Box, Button, Flex, FormLabel, IconButton, Text, VStack, Wrap } from '@chakra-ui/react';
import FieldRender from './FieldRender';

const deleteNestedEntity = (availableEntities, setAvailableEntities, index, setFieldValue, prefix) => {
  const newAvailableEntities = [...availableEntities];
  newAvailableEntities.splice(index, 1);
  setAvailableEntities(newAvailableEntities);
  // Formik doesn't have a proper way of removing field value.
  // Set to null and remove it on submit.
  setFieldValue(prefix, null);
};

const addNestedEntity = (availableEntities, setAvailableEntities) => {
  setAvailableEntities([...availableEntities, {}]);
};

const MultiNestedFieldChakra = ({ errors, field, referencedEntities, thisEntity, prefix = null }) => {
  const { values, setFieldValue } = useFormikContext();
  const fieldName = field.name;
  thisEntity = thisEntity && fieldName in thisEntity ? thisEntity[fieldName] : [];
  const [availableEntities, setAvailableEntities] = useState(thisEntity);
  const elements = (
    <VStack width="100%" spacing="14px" align="stretch" marginTop="40px !important">
      <Box>
        <Text as="p" fontSize="15px" fontWeight="bold" color="accent.one.default">
          {field.displayName}
        </Text>
      </Box>
      <Box>
        {availableEntities.map((entity, index) => {
          const prefix = `${fieldName}[${index}]`;
          return (
            <Wrap key={index} spacing="30px">
              {referencedEntities[field.referenceEntityType].entityDefinition.fields.map(referencedField => {
                const value =
                  values[fieldName] && values[fieldName][index] ? values[fieldName][index][referencedField.name] : '';
                return (
                  <FieldRender
                    key={referencedField.name}
                    errors={errors}
                    field={referencedField}
                    referencedEntities={referencedEntities}
                    thisEntity={thisEntity}
                    prefix={prefix}
                    value={value}
                  />
                );
              })}
              {/* <button
                style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }}
                onClick={e => {
                  e.preventDefault();
                  deleteNestedEntity(availableEntities, setAvailableEntities, index, setFieldValue, prefix);
                }}
                className="btn btn-sm btn-danger"
              >
                <i className="flaticon-cancel" />
              </button> */}
              <Box>
                <FormLabel htmlFor={fieldName}>&nbsp;</FormLabel>
                <IconButton
                  variant="outline"
                  colorScheme="error"
                  height="36px"
                  icon={<i className="flaticon-cancel" />}
                  onClick={e => {
                    e.preventDefault();
                    deleteNestedEntity(availableEntities, setAvailableEntities, index, setFieldValue, prefix);
                  }}
                />
              </Box>
            </Wrap>
          );
        })}
      </Box>
      <Flex justifyContent="flex-end">
        <Button
          onClick={e => {
            e.preventDefault();
            addNestedEntity(availableEntities, setAvailableEntities);
          }}
          colorScheme="actionSecondary"
        >
          Add {field.displayName}
        </Button>
      </Flex>
    </VStack>
  );
  return elements;
};
export default MultiNestedFieldChakra;
