import { get, isEmpty } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from 'react';
import Datetime from 'react-datetime';
// Local Deps
import './RenderInputComponentByType.scss';
import SelectField from '../basic/SelectField';

export default class RenderInputComponentByType extends Component {
  onMultiSelectChange = (fieldName, inputType, selectedValues, multiSelectOptions) => {
    const allValues = cloneDeep(multiSelectOptions);
    // removing custom defined 'all' key
    allValues.pop('all');
    if (selectedValues && selectedValues.find(o => o.value === 'all')) {
      this.props.OnFieldValueChange(fieldName, inputType, allValues);
    } else {
      this.props.OnFieldValueChange(fieldName, inputType, selectedValues);
    }
  };

  render() {
    const { inputType, sourceData, fieldValue, isDisabled, name, OnFieldValueChange } = this.props;
    let multiSelectOptions = [];
    if (sourceData && inputType === 'multi-select') {
      multiSelectOptions = sourceData.reduce((agg, obj) => {
        agg.push({
          label: Object.keys(obj)[0],
          value: Object.values(obj)[0],
        });
        return agg;
      }, []);
      multiSelectOptions.push({ value: 'all', label: 'Select All' });
    }

    return (
      // / return <Input/> based on type
      inputType === 'multi-select' ? (
        <SelectField
          className="render-input-component"
          isDisabled={isDisabled}
          name={name}
          defaultValue={fieldValue}
          options={multiSelectOptions}
          isMulti
          style={{ width: '300px' }}
          menuStyles={{
            zIndex: '9999 !important',
          }}
          onChange={e => {
            this.onMultiSelectChange(name, inputType, e, multiSelectOptions);
          }}
        />
      ) : inputType === 'text' ? (
        <input
          type="text"
          disabled={isDisabled}
          className="form-control"
          name={name}
          value={fieldValue}
          onChange={e => {
            OnFieldValueChange(name, inputType, get(e, 'target.value', ''));
          }}
          style={{ width: '300px', borderColor: '#d9d9d9' }}
        />
      ) : inputType === 'boolean' ? (
        <SelectField
          isDisabled={isDisabled}
          name={name}
          defaultValue={fieldValue}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          // className={requiredFieldErrors.includes('input_product_id') ? 'error' : ''}
          onChange={e => {
            OnFieldValueChange(name, inputType, get(e, 'target.value', ''));
          }}
          style={{ width: '300px' }}
        />
      ) : (
        inputType === 'date' && (
          <Datetime
            id={name}
            inputProps={{
              className: 'form-control m-input',
              disabled: isDisabled,
            }}
            closeOnSelect
            value={fieldValue instanceof Array ? fieldValue[0] : fieldValue}
            onChange={e => {
              const numberDatePattern = /^[0-9\-\/]+$/;
              if (e._isAMomentObject || numberDatePattern.test(e) || isEmpty(e)) {
                OnFieldValueChange(name, inputType, e._isAMomentObject ? e.format('YYYY-MM-DD') : e);
              } else {
                OnFieldValueChange(name, inputType, '');
              }
            }}
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
            style={{ width: '300px' }}
          />
        )
      )
    );
  }
}
