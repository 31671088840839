import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertIcon, HStack, IconButton, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

const StyledAlert = ({ status, infoMessage, onClick }) => {
  return (
    <Alert
      status={status}
      borderRadius="8px"
      width="693px"
      boxShadow="md"
      paddingTop="9px"
      paddingBottom="7px"
      colorScheme={`${status}`}
      backgroundColor={`${status}Bg.default`}
      borderWidth="1px"
      borderColor={`${status}Border.default`}
    >
      <AlertIcon />
      <HStack width="100%" color={`${status}.default`}>
        <Text as="p" color="inherit">
          {infoMessage}
        </Text>
        <IconButton
          variant="unstyled"
          color="inherit"
          icon={<CloseIcon />}
          marginLeft="auto !important"
          onClick={() => onClick()}
        />
      </HStack>
    </Alert>
  );
};

StyledAlert.propTypes = {
  status: PropTypes.string.isRequired,
  infoMessage: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StyledAlert;
