import React from 'react';
import { Typography, Row, Col, Card } from 'antd';
import ScaleForGoodGoals from '../../../components/analyticDashboard/markets/ScaleForGoodGoals/ScaleForGoodGoals';
import SustainabilityMetrics from '../../../components/analyticDashboard/markets/SustainabilityMetrics/SustainabilityMetrics';
import { useSelector } from 'react-redux';
import { format as formatDate } from 'date-fns';
import { selectSustainabilityMetrics } from '../../../slices/markets/selectors/selectSustainabilityMetrics';
import IUnleashClassFlagProvider from '../../../components/unleash/UnleashClassFlagProvider';
import Constants from '../../../Constants';
// import { ChevronRight } from '../../../shared/Icons';

// This container specifically serves the quality section
function ScaleForGood() {
  const sustainabilityMetricsData = useSelector(selectSustainabilityMetrics);
  const isSustainabilityMetricsDataValid = sustainabilityMetricsData && sustainabilityMetricsData.length;

  const metricsLastMonthLabel = React.useMemo(() => {
    if (!isSustainabilityMetricsDataValid) return null;
    const lastDatum = sustainabilityMetricsData[sustainabilityMetricsData.length - 1];
    const date = new Date(lastDatum.date);
    return formatDate(date, 'MMM y');
  }, [sustainabilityMetricsData]);

  return (
    <Card>
      <Typography.Title level={3}>
        Scale for Good
        {/* <div style={{ display: 'inline-block', width: '18px' }}><ChevronRight /></div> */}
      </Typography.Title>
      <Row>
        {isSustainabilityMetricsDataValid && (
          <Col span={14}>
            <Typography.Text style={{ textTransform: 'uppercase', color: '#fc5d36' }}>
              Sustainable Beef Sourcing {metricsLastMonthLabel}
            </Typography.Text>
            <SustainabilityMetrics data={sustainabilityMetricsData} />
          </Col>
        )}
        <Col span={10}>
          <Typography.Text style={{ textTransform: 'uppercase', color: '#fc5d36' }}>Latest updates</Typography.Text>
          <ScaleForGoodGoals />
        </Col>
      </Row>
    </Card>
  );
}

const ScaleForGoodUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.MARKET_DASHBOARD_SCALE_FOR_GOOD}
        show={<ScaleForGood {...props} />}
        hide=""
      />
    </>
  );
};

export default ScaleForGoodUnleashController;
