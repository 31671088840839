import React, { useState, useEffect } from 'react';
import {
  VStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import QRCode from 'react-qr-code';

import OtpInputField from './Otp';
import { confirmOtpRequest, fetchQrCode, sendOtpEmail } from '../../services/two-factor-auth';

// eslint-disable-next-line react/prop-types
export default function OptConfirmModal({ user, method, handleCloseModal }) {
  const [otp, setOtp] = useState('');
  const [success, setSuccess] = useState(false);
  const [qrCode, setQrCode] = useState(false);
  const [resendEmail, setResendEmail] = useState(true);

  const { isOpen, onOpen, onClose: closeModal } = useDisclosure();

  useEffect(() => {
    onOpen();
  }, []);

  useEffect(() => {
    if (method === 'authentication_app') {
      const getQrCode = async () => {
        const qrcode = await fetchQrCode(user.token);
        if (qrcode) {
          setQrCode(qrcode.qr_code);
        }
      };
      getQrCode();
    }
  }, []);
  const onClose = () => {
    handleCloseModal(success);
  };
  const handleChangeOtp = userOtp => {
    setOtp(userOtp);
  };

  const confirmOtp = async () => {
    const confirmation = await confirmOtpRequest(user.token, otp, method);
    if (confirmation) {
      setSuccess(true);
    }
  };

  const handleResendOtp = async () => {
    setResendEmail(false);
    await sendOtpEmail(user.email);
    setTimeout(() => setResendEmail(true), 30000);
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Confirm 2FA</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!success && (
            <VStack>
              {method === 'authentication_app' && (
                <VStack>
                  {qrCode && (
                    <QRCode size={256} style={{ height: 'auto', width: '50%' }} value={qrCode} viewBox="0 0 256 256" />
                  )}

                  <Text>Please scan this QR code in google authenticator app</Text>
                  <Text>Please note this QR code will not be shown again</Text>
                </VStack>
              )}
              {method === 'email' && (
                <Text fontSize="14px">Enter the 6-digit authentication code sent to your email address:</Text>
              )}
              {method === 'authentication_app' && (
                <Text fontSize="14px">Enter the 6-digit authentication code generated by your app:</Text>
              )}
              <OtpInputField handleChangeOtp={handleChangeOtp} marginLeft={0} />
              {method === 'email' && (
                <Button
                  disabled={!resendEmail}
                  variant="ghost"
                  marginTop="10px !important"
                  fontSize="12px"
                  fontWeight="700"
                  onClick={() => handleResendOtp()}
                >
                  Resend Code
                </Button>
              )}
            </VStack>
          )}
          {success && (
            <VStack>
              <CheckCircleIcon h={8} w={8} color="green.500" />
              <Text as="h2" color="green.500" fontWeight="bold">
                Successfully verified
              </Text>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          {!success && (
            <>
              <Button mr={3} onClick={() => handleCloseModal(success)}>
                Cancel
              </Button>
              <Button onClick={() => confirmOtp()} type="link" colorScheme="actionSecondary">
                Verify
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
