import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import { postSupportTicket } from '../../actions/actions_support';
import Constants from '../../Constants';
import SupportInfo from './SupportInfo';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';

class SupportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      description: false,
      device: '',
      browser: '',
      submitted: false,
    };

    this.uploadImage = this.uploadImage.bind(this);
    this.submitTicket = this.submitTicket.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ submitted: nextProps.submitted });
  }

  onEditorStateChange = editorState => {
    this.setState({ description: editorState });
  };

  uploadImage(file) {
    const formData = new FormData();
    formData.append('file', file);
    const header = new Headers({ Authorization: `Token ${this.props.token}` });
    return fetch(`${Constants.URL}upload_image_file`, {
      method: 'POST',
      headers: header,
      body: formData,
    })
      .then(response => response.json())
      .then(json => {
        return {
          data: {
            link: json.link,
          },
        };
      })
      .catch(error => console.log(error));
  }

  submitTicket() {
    const ticket = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      description: draftToHtml(convertToRaw(this.state.description.getCurrentContent())),
      device: this.state.device,
      browser: this.state.browser,
    };
    this.props.dispatch(postSupportTicket(ticket, this.props.token));
  }

  render() {
    const toolbarConfig = {
      options: [
        'inline',
        'blockType',
        'fontSize',
        'fontFamily',
        'list',
        'textAlign',
        'colorPicker',
        'link',
        'image',
        'remove',
        'history',
      ],
      image: {
        uploadEnabled: true,
        uploadCallback: this.uploadImage,
      },
    };

    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="row">
            <div className="col-xl-12">
              <div className="m-portlet m-portlet--full-height ">
                <div className="m-portlet__head">
                  <div className="m-portlet__head-caption">
                    <div className="m-portlet__head-title">
                      <h3 className="m-portlet__head-text">Support Tickets</h3>
                    </div>
                  </div>
                </div>
                <div className="m-portlet__body" style={{ minHeight: '320px' }}>
                  <div className="tab-content">
                    <div className="tab-pane active" id="m_widget2_tab1_content" aria-expanded="true">
                      <div style={{ padding: '20px' }}>
                        <div className="row">
                          <SupportInfo />
                          <div className="col-sm-1" />
                          <div className="col-sm-6">
                            <h4>Submit Support Ticket</h4>
                            <label>Requestor</label>
                            <input
                              value={this.state.name}
                              onChange={e => this.setState({ name: e.target.value })}
                              type="text"
                              className="form-control"
                              placeholder="Your Name"
                            />
                            <br />
                            <label>Email</label>
                            <input
                              value={this.state.email}
                              onChange={e => this.setState({ email: e.target.value })}
                              type="email"
                              className="form-control"
                              placeholder="Your Email"
                            />
                            <br />
                            <label>Subject</label>
                            <input
                              value={this.state.subject}
                              onChange={e => this.setState({ subject: e.target.value })}
                              type="text"
                              className="form-control"
                              placeholder="Subject"
                            />
                            <br />
                            <label>Description</label>
                            <Editor
                              editorState={this.state.description}
                              onEditorStateChange={this.onEditorStateChange}
                              wrapperClassName="demo-wrapper"
                              editorClassName="demo-editor"
                              toolbar={toolbarConfig}
                            />
                            <hr />
                            <label>Device</label>
                            <select
                              className="form-control"
                              value={this.state.device}
                              onChange={e => this.setState({ device: e.target.value })}
                            >
                              <option>(none selected)</option>
                              <option value="iOS">iOS</option>
                              <option value="Android">Android</option>
                              <option value="Windows">Windows</option>
                              <option value="MacOS">MacOS</option>
                              <option value="Other">Other</option>
                            </select>
                            <br />
                            <label>Browser</label>
                            <select
                              className="form-control"
                              value={this.state.browser}
                              onChange={e => this.setState({ browser: e.target.value })}
                            >
                              <option>(none selected)</option>
                              <option value="Firefox">Firefox</option>
                              <option value="Chrome">Chrome</option>
                              <option value="Safari">Safari</option>
                              <option value="Internet Explorer">Internet Explorer</option>
                              <option value="Other">Other</option>
                            </select>
                            <hr />
                            {this.state.submitted ? (
                              <div className="alert alert-info">
                                Thank you for submitting your ticket, {this.state.name}! We'll get back to you very
                                soon.
                              </div>
                            ) : (
                              false
                            )}
                            <button className="btn btn-info" onClick={this.submitTicket.bind(this)}>
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SupportForm;
