import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { renderChildren } from '../../lib/formLoader';
import Loading from '../../basic/Loading';

// FormikForm is wrapped by BoabForm component
function FormikForm(props) {
  const {
    context,
    formConfig,
    isLoading,
    isSubmitting,
    resetForm,
    setFieldValue,
    shouldReset,
    shouldSubmit,
    submitForm,
    values,
  } = props;

  useMemo(() => {
    if (shouldReset) resetForm();
  }, [shouldReset]);

  useMemo(() => {
    if (shouldSubmit) submitForm();
  }, [shouldSubmit]);

  return (
    <Form enableReinitialize={false}>
      {isLoading ? (
        <Loading />
      ) : (
        renderChildren(formConfig, { ...context, values, submitForm, setFieldValue, resetForm, isSubmitting })
      )}
    </Form>
  );
}

FormikForm.propTypes = {
  shouldReset: PropTypes.bool,
  shouldSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  values: PropTypes.instanceOf(Object),
  context: PropTypes.instanceOf(Object),
  submitForm: PropTypes.func,
  resetForm: PropTypes.func,
  setFieldValue: PropTypes.func,
  formConfig: PropTypes.arrayOf(PropTypes.object),
};

function BoabForm(props) {
  const { isLoading, initialValues, validationSchema, onSubmit, formConfig, context, shouldReset, shouldSubmit } =
    props;

  return (
    <Formik {...{ initialValues, validationSchema, ...(onSubmit && { onSubmit }) }}>
      {formikProps => <FormikForm {...{ ...formikProps, shouldReset, shouldSubmit, formConfig, context, isLoading }} />}
    </Formik>
  );
}

BoabForm.propTypes = {
  context: PropTypes.instanceOf(Object),
  formConfig: PropTypes.arrayOf(PropTypes.object),
  initialValues: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  shouldReset: PropTypes.bool,
  shouldSubmit: PropTypes.bool,
  validationSchema: PropTypes.instanceOf(Object),
};

export default BoabForm;
