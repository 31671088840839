import Constants from '../Constants';

export const BEEF_INPUT_INVENTORY_ITEMS_SEARCH_FETCHED = 'BEEF_INPUT_INVENTORY_ITEMS_SEARCH_FETCHED';
export const BEEF_INPUT_INVENTORY_ITEMS_SEARCH_ERROR = 'BEEF_INPUT_INVENTORY_ITEMS_SEARCH_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadBeefInputInventoryItemsSearch(searchResults) {
  return {
    type: BEEF_INPUT_INVENTORY_ITEMS_SEARCH_FETCHED,
    payload: searchResults,
  };
}

export function errorBeefInputInventoryItemsSearch(error) {
  return {
    type: BEEF_INPUT_INVENTORY_ITEMS_SEARCH_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function searchBeefInputInventoryItems(search, token) {
  return dispatch => {
    const keys = Object.keys(search) || [];
    const querystring = keys.reduce((agg, searchTerm) => {
      if (search[searchTerm] !== '') {
        if (querystring === '?') {
          return `${agg}${searchTerm}=${search[searchTerm]}`;
        }
        return `${agg}&${searchTerm}=${search[searchTerm]}`;
      }
      return agg;
    }, '?');

    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    return fetch(`${Constants.URL}inventory/input_product_inventory_items/search${querystring}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorBeefInputInventoryItemsSearch))
      .then(json => {
        dispatch(loadBeefInputInventoryItemsSearch(json));
      })
      .catch(error => dispatch(errorBeefInputInventoryItemsSearch(error)));
  };
}
