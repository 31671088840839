import { connect } from 'react-redux';

import KPIDashboardComponent from '../../components/dashboard/KPIDashboard';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

const mapStateToProps = state => {
  return {
    ...findEntitiesInState(state),
    user: state.user,
    kpis: state.kpis,
    articles: state.articles.articleList,
    token: state.user.token,
  };
};

const KPIDashboard = connect(mapStateToProps)(KPIDashboardComponent);

export default KPIDashboard;
