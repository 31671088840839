import React from 'react';

const SupportInfo = () => (
  <div className="col-sm-5">
    <p>
      While no one wants to contact technical support, eventually it will happen to most of us for some reason. Maybe it
      is an unknown error that you have not encountered previously, or perhaps a certain button is not working the way
      you would expect. For these types of technical issues, the BOAB Support Ticket service is exactly where you need
      to be, so let's help you on your way to addressing your concern!
    </p>
    <ol style={{ paddingLeft: '1.5em' }}>
      <li>Troubleshooting the Issue</li>
      The best first step in troubleshooting anything is to confirm if the issue you encountered is able to be repeated
      more than once. So before you log this ticket, we would please ask if you could repeat the same steps you took
      originally and note the outcome in the ‘Description of Issue’ section. If the issue does not present itself again,
      this in no way means that there is nothing wrong and you are welcome to proceed.
      <p />
      <li>Details Details Details!</li>
      When describing the issue, please be extremely detailed. Obviously we do not need to know what the temperature was
      outside at the time of the issue occurring, however the following types of details can provide a lot insight to
      the BOAB Technical Team when triaging an issue:
      <ul>
        <li>What screen you were in?</li>
        <li>What process were you trying to transact?</li>
        <li>What was the exact error wording that appeared?</li>
        <li>Where you able to repeat the transaction without the issue occurring again or not?</li>
        <li>Did you have multiple BOAB sessions running at the same time?</li>
      </ul>
      <p />
      <li>A Picture Says a Thousand Words!</li>
      <p>
        Where possible, and if you remember, please take screenshot of the issue and attach it to the support ticket in
        the ‘Supporting Images’ section.
      </p>
      <p>
        To make it easy for you, you are able to drag and drop the image - if you have saved it. Or you can copy and
        paste it into the Description of Issue’ section and it will create the attachment for you.
      </p>
    </ol>
    <p>
      If you are unable to process a critical transaction please contact the BOAB Technical Support team directly via
      email at &nbsp;
      <span style={{ textDecoration: 'underline' }}>ITsupport@fultonmarketgroup.com</span> or if your issue is urgent
      please contact:
    </p>
    <ul>
      <li>
        US Contact – US Central Time
        <br />
        Jonathan Freeman
        <br />
        +1 (703) 505-5246
        <br />
        <span style={{ textDecoration: 'underline' }}>jonathan@fultonmarketgroup.com</span>
      </li>
      <br />
      <li>
        Australian Contact AUS AEDT Time
        <br />
        Ashley Maxwell-Lam
        <br />
        +61 458 108 552
        <br />
        <span style={{ textDecoration: 'underline' }}>ashley@fultonmarketgroup.com</span>
      </li>
    </ul>

    <p>
      If the matter is urgent and you are emailing it through to the technical team, please put{' '}
      <span style={{ fontWeight: 'bold' }}>URGENT – BOAB Tech Support</span> in the subject line.
    </p>
    <p>
      All tickets will be addressed within 3 days of lodgment. This does not mean the issue will be fixed within 3 days.
    </p>
  </div>
);

export default SupportInfo;
