import { Collapse } from 'antd';
import React from 'react';
import { ExclamationMarkIcon } from '../../../../shared/Icons';
import styles from './SupplyChainIncidents.module.css';
import PropTypes from 'prop-types';

export default function SupplyChainIncidents(props) {
  const { incidents } = props;

  return (
    <Collapse ghost defaultActiveKey={1} expandIconPosition="right">
      {incidents.map(x => {
        return (
          <Collapse.Panel
            header={
              <div className={styles.title}>
                <div className={styles.icon}>
                  <ExclamationMarkIcon />
                </div>
                {x.title}
              </div>
            }
            key={x.title}
          >
            <div>
              {x.description}{' '}
              <a className={styles.link} href={x.link} target="_blank" rel="noreferrer">
                {x.link_label}
              </a>
            </div>
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
}

SupplyChainIncidents.propTypes = {
  incidents: PropTypes.arrayOf({
    title: PropTypes.string,
    description: PropTypes.string,
    link_label: PropTypes.string,
  }),
};
