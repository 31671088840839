import React from 'react';
import { Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths, subYears } from 'date-fns';
import FilterSelectGeneric from '../../../../components/analyticDashboard/qualityDetails/shared/FilterSelectGeneric/FilterSelectGeneric';
import FilterTimePeriod from '../../../../components/analyticDashboard/qualityDetails/shared/FilterTimePeriod/FilterTimePeriod';
import FilterFrequency from '../../../../components/analyticDashboard/qualityDetails/shared/FilterFrequency/FilterFrequency';
import {
  selectQualityClaimsAmount,
  selectQualityClaimsCount,
  selectQualityClaimsFrequency,
  selectQualityClaimsMarket,
  selectQualityClaimsRegion,
  selectQualityClaimsStatus,
  selectQualityClaimsTimePeriod,
  selectQualityClaimsTimeRange,
  selectQualityClaimsGroupBy,
  selectQualityClaimsGrinder,
} from '../../../../slices/quality-details-claims/selectors/selectBasicDetailsClaimsData';
import {
  setQualityClaimsAmount,
  setQualityClaimsCount,
  setQualityClaimsStatus,
  updateQualityClaimsFrequency,
  updateQualityClaimsRegion,
  updateQualityClaimsTimePeriod,
  updateQualityClaimsTimeRange,
  updateQualityClaimsGrinder,
  setQualityClaimsTimeRange,
  setQualityClaimsFilterBy,
  setQualityClaimsGroupBy,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsReducers';
import {
  TIME_RANGE_OPTIONS,
  STATUS_OPTIONS,
  COUNT_OPTIONS,
  AMOUNT_OPTIONS,
  GROUP_BY_OPTIONS,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsConfig';
import { selectQualityGrinderOptions } from '../../../../slices/quality/selectors/selectQualityGrinderOptions';
import { FREQUENCY } from '../../../../slices/quality/qualityConfig';

export default function QualityDetailsClaimsFilters(props) {
  const market = useSelector(selectQualityClaimsMarket);
  const region = useSelector(selectQualityClaimsRegion);
  const statusMode = useSelector(selectQualityClaimsStatus);
  const countMode = useSelector(selectQualityClaimsCount);
  const amountMode = useSelector(selectQualityClaimsAmount);
  const groupByMode = useSelector(selectQualityClaimsGroupBy);
  const timeRange = useSelector(selectQualityClaimsTimeRange);
  const timePeriod = useSelector(selectQualityClaimsTimePeriod);
  const frequency = useSelector(selectQualityClaimsFrequency);
  const selectedGrinder = useSelector(selectQualityClaimsGrinder);
  const grinderOptions = useSelector(selectQualityGrinderOptions);
  const optionLabels = {};
  grinderOptions.forEach(g => {
    optionLabels[g.id] = g.name;
  });

  const dispatch = useDispatch();

  const changeRegion = React.useCallback(region => dispatch(updateQualityClaimsRegion(region)), [dispatch]);
  const changeGrinder = React.useCallback(g => dispatch(updateQualityClaimsGrinder(g)), [dispatch]);
  const changeStatusMode = React.useCallback(
    statusMode => {
      dispatch(setQualityClaimsStatus(statusMode));
      dispatch(setQualityClaimsGroupBy(null));
      dispatch(setQualityClaimsFilterBy(null));
      if (
        statusMode === STATUS_OPTIONS.SUBMITTED_VS_FINALISED ||
        statusMode === STATUS_OPTIONS.SUBMITTED_VS_REJECTED ||
        statusMode === STATUS_OPTIONS.FINALISED_VS_REJECTED
      ) {
        dispatch(setQualityClaimsTimeRange(TIME_RANGE_OPTIONS.HISTORICAL));
      }
    },
    [dispatch]
  );
  const changeCountMode = React.useCallback(
    countMode => {
      dispatch(setQualityClaimsCount(countMode));
      if (!!countMode && !!amountMode && groupByMode === GROUP_BY_OPTIONS.PACKER_PLANT) {
        dispatch(setQualityClaimsGroupBy(null));
      }
    },
    [dispatch, groupByMode]
  );
  const changeAmountMode = React.useCallback(
    amountMode => {
      dispatch(setQualityClaimsAmount(amountMode));
      if (!!countMode && !!amountMode && groupByMode === GROUP_BY_OPTIONS.PACKER_PLANT) {
        dispatch(setQualityClaimsGroupBy(null));
      }
    },
    [dispatch, groupByMode]
  );

  const changeTimeRange = React.useCallback(
    tRange => {
      const now = new Date();
      if (tRange === TIME_RANGE_OPTIONS.YOY) {
        dispatch(updateQualityClaimsFrequency(FREQUENCY.MONTHLY));
        dispatch(updateQualityClaimsTimePeriod([startOfYear(subYears(now, 3)), endOfYear(now)]));
      } else {
        dispatch(updateQualityClaimsTimePeriod([startOfMonth(subMonths(now, 12)), endOfMonth(now)]));
      }
      dispatch(updateQualityClaimsTimeRange(tRange));
    },
    [dispatch, TIME_RANGE_OPTIONS]
  );

  const changeTimePeriod = React.useCallback(tPeriod => dispatch(updateQualityClaimsTimePeriod(tPeriod)), [dispatch]);

  const changeFrequency = React.useCallback(
    f => {
      dispatch(updateQualityClaimsFrequency(f));
      const now = new Date();
      if (f === FREQUENCY.ANNUALLY) {
        dispatch(updateQualityClaimsTimePeriod([startOfYear(subYears(now, 3)), endOfYear(now)]));
      } else {
        dispatch(updateQualityClaimsTimePeriod([startOfMonth(subMonths(now, 12)), endOfMonth(now)]));
      }
    },
    [dispatch, FREQUENCY]
  );

  return (
    <>
      {market.includedCountries && market.includedCountries.length && (
        <Col span={3}>
          <FilterSelectGeneric
            value={region}
            onChange={changeRegion}
            label="Region"
            options={['All', ...market.includedCountries]}
          />
        </Col>
      )}
      <Col span={3}>
        <FilterSelectGeneric
          value={selectedGrinder}
          onChange={changeGrinder}
          label="Grinder"
          options={grinderOptions.map(g => g.id)}
          optionLabels={optionLabels}
        />
      </Col>
      <Col span={3}>
        <FilterSelectGeneric
          value={statusMode}
          onChange={changeStatusMode}
          label="Status"
          options={[
            STATUS_OPTIONS.SUBMITTED,
            STATUS_OPTIONS.FINALISED,
            STATUS_OPTIONS.OVERDUE,
            STATUS_OPTIONS.REJECTED,
            STATUS_OPTIONS.SUBMITTED_VS_FINALISED,
            STATUS_OPTIONS.SUBMITTED_VS_REJECTED,
            STATUS_OPTIONS.FINALISED_VS_REJECTED,
          ]}
        />
      </Col>
      <Col span={3}>
        <FilterSelectGeneric
          value={countMode}
          onChange={changeCountMode}
          label="Count"
          options={[COUNT_OPTIONS.TOTAL]}
          includeNullOption
        />
      </Col>
      <Col span={3}>
        <FilterSelectGeneric
          value={amountMode}
          onChange={changeAmountMode}
          label="Amount"
          options={[AMOUNT_OPTIONS.TOTAL]}
          includeNullOption
        />
      </Col>
      <Col span={3}>
        <FilterSelectGeneric
          value={timeRange}
          onChange={changeTimeRange}
          label="Time range"
          isDisabled={
            statusMode === STATUS_OPTIONS.SUBMITTED_VS_FINALISED ||
            statusMode === STATUS_OPTIONS.SUBMITTED_VS_REJECTED ||
            statusMode === STATUS_OPTIONS.FINALISED_VS_REJECTED
          }
          options={[TIME_RANGE_OPTIONS.HISTORICAL, TIME_RANGE_OPTIONS.YOY]}
        />
      </Col>
      {timeRange === TIME_RANGE_OPTIONS.HISTORICAL && (
        <>
          <Col span={4}>
            <FilterTimePeriod value={timePeriod} onChange={changeTimePeriod} frequency={frequency} />
          </Col>
          <Col span={3}>
            <FilterFrequency value={frequency} onChange={changeFrequency} />
          </Col>
        </>
      )}
    </>
  );
}
