import React from 'react';
import styles from './ColumnChart.module.css';
import { format } from 'd3';

export default function ColumnYAxis({
  scale,
  base,
  yAxisRange: [left, right]
}) {
  const formats = {
    number: format(',.2s'),
  };

  const [top, bottom] = scale.range();
  const height = Math.abs(top - bottom);

  const tickCount = Math.floor(height / 50);
  const ticks = scale.ticks(tickCount);

  return (
    <>
      {ticks.map((value, tickIndex) => {
        const y = base - scale(value);

        return (
          <g key={`group-${tickIndex}`}>
            <line
              x1={left}
              x2={right}
              y1={y}
              y2={y}
              key={`line-${tickIndex}`}
              className={styles.yAxisTickLine}
            />
            <text
              className={styles.yAxisTick}
              key={`text-${tickIndex}`}
              x={left - 20}
              y={y}
            >
              {formats.number(value)}
            </text>
          </g>
        );
      })}
    </>
  );
}
