import { renderCard, renderGrid } from '../../../../lib/formLoader';

const orderDetailsSection = {
  headerText: 'Order Details',
  renderComponent: renderCard,
  children: [
    {
      renderComponent: renderGrid,
      templateColumns: 'repeat(8, 1fr)',
      gap: 9,
      children: [
        {
          label: 'Grinder',
          name: 'grinder',
          optionsKey: 'grinder',
          fieldType: 'select',
          colSpan: 2,
          height: '77px',
        },
        {
          label: 'Packer',
          name: 'packer',
          fieldType: 'select',
          optionsKey: 'packer',
          colSpan: 2,
        },
        {
          label: 'Est ID',
          name: 'estId',
          fieldType: 'input',
          colSpan: 2,
        },
        {
          label: 'Establishment',
          name: 'packerPlant',
          optionsKey: 'packerPlant',
          fieldType: 'select',
          colSpan: 2,
        },
        {
          label: 'Freight Rate',
          name: 'freightRate',
          fieldType: 'number',
          colSpan: 2,
        },
        {
          label: 'Packer SO #',
          name: 'packerSoNumber',
          fieldType: 'input',
          colSpan: 2,
        },
        {
          label: 'PO Disposition',
          name: 'disposition',
          optionsKey: 'disposition',
          fieldType: 'select',
          colSpan: 2,
        },
        {
          label: 'Standing',
          name: 'standing',
          fieldType: 'checkbox',
          colSpan: 2,
          height: '65px',
        },
      ],
    },
  ],
};

export default orderDetailsSection;
