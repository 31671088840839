import * as React from 'react';
import { useSelector } from 'react-redux';
import { useBreakdownData } from '../../../../../containers/analyticDashboard/qualityDetails/ncrs/QualityDetailsNCRsCharts';
import { BREAKDOWN_OPTIONS, PACKER_MODE } from '../../../../../slices/quality-details-ncrs/qualityDetailsNcrsConfig';
import { selectQualityNCRsBreakdownMode } from '../../../../../slices/quality-details-ncrs/selectors/selectBasicDetailsNcrsData';
import { RankingChart } from '../../../treasuryDetails/RankingChart/RankingChart';
import {
  breakdownColorsPropTypes,
  breakdownPackerPlantPropTypes,
  selectedCategoriesPropTypes,
  selectedSubcategoriesPropTypes,
  selectedMarketsPropTypes,
  highlightedPackerPlantsPropTypes,
  packerModePropTypes,
} from '../../../../../slices/quality-details-ncrs/qualityDetailsNcrsPropTypes';
import styles from './BreakdownChart.module.css';

export default function BreakdownChart(props) {
  const {
    breakdownColors,
    breakdownPackerPlant,
    selectedCategories,
    selectedSubcategories,
    selectedMarkets,
    highlightedPackerPlants,
    packerMode,
  } = props;

  const breakdownMode = useSelector(selectQualityNCRsBreakdownMode);
  const breakdownData = useBreakdownData(packerMode, selectedCategories, selectedSubcategories);

  const title = React.useMemo(() => {
    switch (breakdownMode) {
      case BREAKDOWN_OPTIONS.CATEGORY:
        return 'Categories';
      case BREAKDOWN_OPTIONS.PACKER_PLANT:
        return 'Establishments';
      case BREAKDOWN_OPTIONS.MARKET:
        return 'Markets';
      default:
        return '';
    }
  }, [breakdownMode]);

  const entities = React.useMemo(() => {
    if (packerMode === PACKER_MODE.BOTTOM_10) {
      return highlightedPackerPlants.map(x => ({
        name: x,
        color: breakdownColors[x] || '#4E5984',
      }));
    }
    switch (breakdownMode) {
      case BREAKDOWN_OPTIONS.CATEGORY:
        return selectedCategories.map(x => ({
          name: x,
          color: breakdownColors[x],
        }));
      case BREAKDOWN_OPTIONS.PACKER_PLANT:
        return highlightedPackerPlants.map(x => ({
          name: x,
          color: breakdownColors[x] || '#4E5984',
        }));
      case BREAKDOWN_OPTIONS.MARKET:
        return selectedMarkets.map(x => ({
          name: x,
          color: breakdownColors[x],
        }));
      default:
        return [];
    }
  }, [
    breakdownMode,
    breakdownColors,
    selectedCategories,
    selectedMarkets,
    highlightedPackerPlants,
    breakdownPackerPlant,
    packerMode,
  ]);

  if (breakdownData.length === 0) return null;

  return (
    <div className={styles.QualityDetailsNCRs_breakdownChart}>
      <div className={styles.QualityDetailsNCRs_chartTitle}>{title}</div>
      {/* hack to rerender the charts without scroll when needed */}
      {breakdownData[0]?.rankingBreakdown.length > 12 && (
        <RankingChart data={breakdownData} splitIndex={100} entities={entities} withScroll />
      )}
      {breakdownData[0]?.rankingBreakdown.length <= 12 && (
        <RankingChart data={breakdownData} splitIndex={100} entities={entities} withScroll={false} />
      )}
    </div>
  );
}

BreakdownChart.propTypes = {
  breakdownColors: breakdownColorsPropTypes,
  breakdownPackerPlant: breakdownPackerPlantPropTypes,
  selectedCategories: selectedCategoriesPropTypes,
  selectedSubcategories: selectedSubcategoriesPropTypes,
  selectedMarkets: selectedMarketsPropTypes,
  highlightedPackerPlants: highlightedPackerPlantsPropTypes,
  packerMode: packerModePropTypes,
};
