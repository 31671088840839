const schema = [
  {
    name: 'finance_factoring',
    displayName: 'Finance Factoring',
    systemIdField: 'id',
    displayIdField: 'grinder_uid',
    path: '/finance_factorings',
    tabGroup: { key: 'pricing', label: 'Pricing' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'grinder_uid',
        displayName: 'Grinder',
        tooltip: 'Select the grinder from the list for which the factoring applies',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'grinder',
        required: true,
      },
      {
        name: 'effective_from',
        displayName: 'Effective From',
        tooltip: 'Date when the factoring terms begin.',
        dataType: 'date',
        required: true,
      },
      {
        name: 'rate',
        displayName: 'Rate',
        tooltip: 'Provide the price or rate applicable for the factoring.',
        dataType: 'number',
        required: true,
      },
      {
        name: 'should_lock',
        displayName: 'Should Lock',
        tooltip: 'Specify whether the factoring terms should be locked (Yes or No)',
        dataType: 'boolean',
        required: true,
      },
    ],
  },
];
export default schema;
