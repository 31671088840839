import { createSlice } from '@reduxjs/toolkit';
import fetchGrindersRequest from '../../data-fixtures/quality-grinders-all';

const grindersSlice = createSlice({
  name: 'qualityGrinders',
  initialState: {
    data: [],
  },
  reducers: {
    setGrinders(state, { payload }) {
      state.data = payload;
    },
  },
});

export const { setGrinders } = grindersSlice.actions;

// ------
// Thunks
export const fetchGrinders = ({ market }) => {
  return async dispatch => {
    try {
      const grinders = await fetchGrindersRequest(market);
      dispatch(setGrinders(grinders));
      return grinders;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export default grindersSlice.reducer;
