import { Row, Col, Typography } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter, useParams } from 'react-router-dom';

import FilterMarket from '../components/analyticDashboard/markets/FilterMarket/FilterMarket';
import { selectActiveMarket, selectAvailableMarkets } from '../slices/markets/selectors/selectBasicMarketData';
import { setActiveMarket } from '../slices/markets/marketReducers';
import { setQualityPackerPlant, setQualityGrinder } from '../slices/quality/qualityReducers';
import { updateShowOutlook } from '../slices/treasury-details/treasuryDetailsReducers';
import { updateActivePackerPlant, updateActiveGrinder } from '../slices/markets-details/supplyChainDetails';
import { ChevronRight } from './Icons';
import { ALL_PACKER_PLANTS_OPTION, ALL_GRINDERS_OPTION, DEFAULT_GRINDER_OPTION } from '../slices/quality/qualityConfig';
import { setQualityNCRsActivePackerPlants } from '../slices/quality-details-ncrs/qualityDetailsNcrsReducers';
import { MARKETS } from '../config/markets';
import PropTypes from 'prop-types';

const TabBar = ({ history, setActiveMarketAction = setActiveMarket }) => {
  const dispatch = useDispatch();
  const { market } = useParams();

  const activeMarket = useSelector(selectActiveMarket);
  const activeMarketKey = activeMarket.key;
  const availableMarkets = useSelector(selectAvailableMarkets);

  const handleActiveMarketChange = React.useCallback(
    newVal => {
      dispatch(setActiveMarketAction(newVal));
      dispatch(setQualityPackerPlant(ALL_PACKER_PLANTS_OPTION));
      dispatch(setQualityGrinder(ALL_GRINDERS_OPTION));
      dispatch(setQualityNCRsActivePackerPlants([undefined]));
    },
    [dispatch, setActiveMarketAction, setQualityPackerPlant]
  );

  const handleQualityActiveMarketChange = React.useCallback(
    newVal => {
      dispatch(setActiveMarketAction(newVal));
      dispatch(setQualityPackerPlant(ALL_PACKER_PLANTS_OPTION));
      dispatch(setQualityGrinder(DEFAULT_GRINDER_OPTION));
      dispatch(setQualityNCRsActivePackerPlants([undefined]));
    },
    [dispatch, setActiveMarketAction, setQualityPackerPlant]
  );

  const handleBackToTreasury = React.useCallback(() => {
    dispatch(updateShowOutlook(true));
  }, [dispatch]);

  const handleBackToQuality = React.useCallback(() => {
    dispatch(setActiveMarketAction(MARKETS.Australia.key));
    dispatch(setQualityPackerPlant(ALL_PACKER_PLANTS_OPTION));
    dispatch(setQualityGrinder(DEFAULT_GRINDER_OPTION));
  }, [dispatch]);

  const handleBackToMarkets = React.useCallback(() => {
    dispatch(updateActivePackerPlant(null));
    dispatch(updateActiveGrinder(null));
  }, [dispatch]);

  const isDetailsPage = history.location.pathname.indexOf('details') !== -1;
  const isTreasuryDetails = history.location.pathname.indexOf('treasury') !== -1;
  const isQualityDetails = history.location.pathname.indexOf('quality') !== -1;

  let handlerActiveMarketChange = handleActiveMarketChange;
  let urlParam = 'market';
  let clickHandler = handleBackToMarkets;
  if (isTreasuryDetails) {
    urlParam = 'treasury';
    clickHandler = handleBackToTreasury;
  } else if (isQualityDetails) {
    urlParam = 'quality';
    clickHandler = handleBackToQuality;
    handlerActiveMarketChange = handleQualityActiveMarketChange;
  }
  return (
    <Row style={{ backgroundColor: 'white' }} align="middle" justify="space-between">
      {!isDetailsPage ? (
        <>
          <Col span={16} />
          <Col span={4} style={{ marginBottom: 20, textAlign: 'end', paddingRight: '20px' }}>
            <FilterMarket
              markets={availableMarkets}
              activeMarket={activeMarketKey}
              onActiveMarketChange={handlerActiveMarketChange}
            />
          </Col>
        </>
      ) : (
        <Col span={16} style={{ height: 64, paddingTop: 20 }}>
          <Link to={`/analytic-dashboard/${urlParam}/${market}`} onClick={clickHandler}>
            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 20 }}>
              <div
                style={{
                  display: 'inline-block',
                  width: '18px',
                  height: '18px',
                  transform: 'rotate(180deg)',
                }}
              >
                <ChevronRight />
              </div>
              <Typography.Text style={{ color: '#FC5D36' }}>
                Back to <strong>{urlParam}</strong>
              </Typography.Text>
            </div>
          </Link>
        </Col>
      )}
    </Row>
  );
};

TabBar.propTypes = {
  history: PropTypes.instanceOf(Object),
  setActiveMarketAction: PropTypes.func,
};

export default withRouter(TabBar);
