const schema = [
  {
    name: 'celery_email_report_configs',
    displayName: 'Celery Email Report Config',
    nested: false,
    systemIdField: 'name',
    displayIdField: 'name',
    deletable: true,
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    createMultiple: false,
    path: '/celery_notifictaion',
    fields: [
      {
        name: 'name',
        displayName: 'Name',
        dataType: 'text',
        required: true,
        systemIdentifier: true,
        hidden: false,
      },
      {
        name: 'subject',
        displayName: 'Subject',
        dataType: 'text',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'text_body',
        displayName: 'Text Body',
        dataType: 'text',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'recipient_local',
        displayName: 'Recipient Local',
        dataType: 'text',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'recipient_dev',
        displayName: 'Recipient Dev',
        dataType: 'text',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'recipient_uat',
        displayName: 'Recipient UAT',
        dataType: 'text',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'recipient_prod',
        displayName: 'Recipient Prod',
        dataType: 'text',
        required: false,
        systemIdentifier: false,
      },
    ],
  },
];
export default schema;
