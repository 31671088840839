import api from '../api';
import Constants from '../Constants';

export const INTERESTS_FETCHED = 'INTERESTS_FETCHED';
export const INTEREST_ADDED = 'INTEREST_ADDED';
export const INTEREST_UPDATED = 'INTEREST_UPDATED';
export const INTEREST_REMOVED = 'INTEREST_REMOVED';
export const INTEREST_ERROR = 'INTEREST_ERROR';

const basePath = '/interests';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export const loadInterests = interests => {
  return {
    type: INTERESTS_FETCHED,
    payload: interests,
  };
};

export const addInterest = interest => {
  return {
    type: INTEREST_ADDED,
    payload: interest,
  };
};

export const removeInterest = interest => {
  return {
    type: INTEREST_REMOVED,
    payload: interest,
  };
};

export const updateInterest = interest => {
  return {
    type: INTEREST_UPDATED,
    payload: interest,
  };
};

export const errorInterests = error => {
  return {
    type: INTEREST_ERROR,
    payload: error,
  };
};

/* ---------------------------------
    THUNKS
-----------------------------------*/
export const fetchInterests = () => dispatch => {
  return api
    .get(basePath)
    .then(response => Constants.handleErrors(response, dispatch, errorInterests))
    .then(json => dispatch(loadInterests(json)))
    .catch(error => dispatch(errorInterests(error)));
};

export const createInterest = payload => dispatch => {
  const payloadCopy = { ...payload };
  if (payloadCopy.rate) {
    payloadCopy.rate /= 100;
  }
  return api
    .post(basePath, payloadCopy)
    .then(response => Constants.handleErrors(response, dispatch, errorInterests))
    .then(json => dispatch(addInterest(json)))
    .catch(error => dispatch(errorInterests(error)));
};

export const patchInterest = interest => dispatch => {
  const url = `${basePath}/${interest.id}`;
  const payload = { ...interest };
  if (payload.rate) {
    payload.rate /= 100;
  }
  return api
    .patch(url, payload)
    .then(response => Constants.handleErrors(response, dispatch, errorInterests))
    .then(json => dispatch(updateInterest(json)))
    .catch(error => dispatch(errorInterests(error)));
};

export const deleteInterest = interest => dispatch => {
  const url = `${basePath}/${interest.id}`;
  return api
    .delete(url)
    .then(response => Constants.handleErrors(response, dispatch, errorInterests))
    .then(() => dispatch(removeInterest(interest)))
    .catch(error => dispatch(errorInterests(error)));
};
