import {
  MAP_76CL_PERFORMANCE,
  MAP_AGRIFOODS_FROZEN_INVENTORY,
  MAP_CHEMICAL_LEAN_PERFORMANCE,
  MAP_CHEMICAL_LEAN_VALUE,
  MAP_CLAIMS,
  MAP_CONVERSION_COSTS,
  MAP_COST_SAVINGS,
  MAP_DELIVERED_ON_TIME,
  MAP_EMPTY,
  MAP_FILES_SENT,
  MAP_FORECAST_ACCURACY,
  MAP_GRINDER_YIELD,
  MAP_LOAD_WEIGHTS,
  MAP_ORDERS_CL,
  MAP_PRODUCT_AGE,
  MAP_RISK_MANAGED_VOLUME,
  MAP_SENSORS,
  MAP_SINGLE_LOT_TESTED,
  MAP_TODAYS_ORDERS,
  MAP_WEATHER,
} from '../actions/actions_map';

const map_data = (
  map_data = {
    orders_cl: false,
    todays_orders: false,
    performance: false,
    forecast_accuracy: false,
    agrifoods_frozen_inventory: false,
    files_sent: false,
    single_lot_tested: false,
    risk_managed_volume: false,
    delivered_on_time: false,
    load_weights: false,
    grinder_yield: false,
    weather: false,
    product_age: false,
    cost_savings: false,
    chemical_lean_value: false,
    conversion_costs: false,
    chemical_lean_performance: false,
    sensors: false,
    claims: false,
    no_data: false,
    returned: makeId(),
  },
  action
) => {
  switch (action.type) {
    case MAP_EMPTY:
      return {
        ...map_data,
        no_data: true,
      };
    case MAP_SENSORS:
      return {
        ...map_data,
        sensors: action.payload,
        no_data: action.payload.length <= 0,
        returned: makeId(),
      };
    case MAP_AGRIFOODS_FROZEN_INVENTORY:
      return {
        ...map_data,
        agrifoods_frozen_inventory: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_GRINDER_YIELD:
      return {
        ...map_data,
        grinder_yield: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_CONVERSION_COSTS:
      return {
        ...map_data,
        conversion_costs: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_CHEMICAL_LEAN_PERFORMANCE:
      return {
        ...map_data,
        chemical_lean_performance: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_CHEMICAL_LEAN_VALUE:
      return {
        ...map_data,
        chemical_lean_value: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_COST_SAVINGS:
      return {
        ...map_data,
        cost_savings: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_CLAIMS:
      return {
        ...map_data,
        claims: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_TODAYS_ORDERS:
      return {
        ...map_data,
        todays_orders: action.payload,
        no_data: action.payload.length <= 0,
        returned: makeId(),
      };
    case MAP_FORECAST_ACCURACY:
      return {
        ...map_data,
        forecast_accuracy: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_FILES_SENT:
      return {
        ...map_data,
        files_sent: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_SINGLE_LOT_TESTED:
      return {
        ...map_data,
        single_lot_tested: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_PRODUCT_AGE:
      return {
        ...map_data,
        product_age: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_76CL_PERFORMANCE:
      return {
        ...map_data,
        performance: action.payload,
        no_data: action.payload.length <= 0,
        returned: makeId(),
      };
    case MAP_ORDERS_CL:
      return {
        ...map_data,
        orders_cl: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_RISK_MANAGED_VOLUME:
      return {
        ...map_data,
        risk_managed_volume: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_DELIVERED_ON_TIME:
      return {
        ...map_data,
        delivered_on_time: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_LOAD_WEIGHTS:
      return {
        ...map_data,
        load_weights: action.payload,
        no_data: !action.payload.system,
        returned: makeId(),
      };
    case MAP_WEATHER:
      return {
        ...map_data,
        weather: action.payload,
      };
    default:
      return map_data;
  }
};

function makeId() {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 5; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export default map_data;
