import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Center,
  Flex,
  usePrevious,
} from '@chakra-ui/react';
import moment from 'moment';
import { capitalizeFirstLetter, formatMonetaryValue } from '../../../functions';
import { get, isEmpty, isEqual, isNull } from 'lodash';
import { Link } from 'react-router-dom';
import { getOr, nestedGetOr } from '../../../helpers';
import { checkIfIsAdminUser, checkIfIsGrinderUser, checkIfIsPackerUser } from '../../../utils';
import styles from './claimListTable.module.css';

const renderNCRTypeTag = ncrType => {
  const value = ncrType || 'default';

  return (
    <Center
      {...getOr(colorConfig, value, get(colorConfig, 'default'))}
      textTransform="capitalize"
      width="63px"
      height="26px"
      borderRadius="4px"
      fontWeight="500"
    >
      <Text>{ncrType.replace(/_/g, ' ') || 'N/A'}</Text>
    </Center>
  );
};

const renderStatus = status => {
  const displayValue = status.replace(/_/g, ' ');
  const colorConfig = {
    raised: {
      backgroundColor: '#53C0C3',
    },
    closed: {
      backgroundColor: '#D9D9D9',
    },
    rejected: {
      backgroundColor: '#FF4D4F',
    },
    in_progress: {
      backgroundColor: '#1890FF',
    },
    under_review: {
      backgroundColor: '#FAAD14',
    },
    accepted: {
      backgroundColor: '#52C41A',
    },
    default: {
      backgroundColor: '#53C0C3',
    },
  };

  return (
    <Flex alignItems="center">
      <Box
        backgroundColor={getOr(colorConfig, `${status}.backgroundColor`, get(colorConfig, 'default.backgroundColor'))}
        width="6px"
        height="6px"
        borderRadius="10px"
      />
      <Text textTransform="capitalize" fontWeight="400" marginLeft="8px">
        {displayValue}
      </Text>
    </Flex>
  );
};

const renderActions = ({
    caseId,
    userTypes: { isAdminUser, isPackerUser, isGrinderUser },
    onClickDelete,
  }) => {  
    return (
      <Flex alignItems="center">
        <HStack  spacing="12px">
          <Link to={`/incidents/${caseId}`}>
            <Button marginLeft="20px" colorScheme="actionSecondary" width="57px" height="28px" borderRadius="24px">
              Open
            </Button>
          </Link>       
        </HStack>
      </Flex>
    );
  };


const IncidentClaimsListTable = ({ ncrs, user, onClickDelete}) => {
  const { searchResult } = ncrs;


  const [claimListData, setClaimListData] = useState([]);
  const searchResultProp = usePrevious(searchResult);
  useEffect(() => {
    if (!isEqual(searchResult, searchResultProp)) {
      setClaimListData(searchResult.map(data => ({ ...data, feKey: Math.ceil(Math.random() * 1000) })));
    }
  }, [searchResult, searchResultProp]);

  const [claimsListCols, setClaimsListCols] = useState([
    {
      key: 'case_id',
      paths: ['recordNo'],
      label: 'case id',
      sort: 'DESC',
      sortable: true,
    },
    {
      key: 'grinder_po',
      paths: ['grinderPo'],
      label: 'grinder po#',
      sort: false,
      sortable: true,
      emptyValue: '-',
    },
    {
      key: 'category',
      paths: ['nonConformanceSubtypeName.category'],
      label: 'category',
      sort: false,
      sortable: true,
      emptyValue: '-',
    },
    {
      key: 'status',
      paths: ['status'],
      label: 'status',
      sort: false,
      sortable: true,
    },
    {
      key: 'date_raised',
      paths: ['entryDate'],
      label: 'date raised',
      sort: false,
      sortable: true,
      dataType: 'date',
      emptyValue: '-',
    },
    {
      key: 'last_update',
      paths: ['modified'],
      label: 'last update',
      sort: false,
      sortable: true,
      dataType: 'date',
      emptyValue: '-',
    },
    {
      key: 'actions',
      label: 'actions',
      sort: false,
      sortable: false,
    },
  ]);

  const isAdminUser = checkIfIsAdminUser(user);
  const isPackerUser = checkIfIsPackerUser(user);
  const isGrinderUser = checkIfIsGrinderUser(user);

  return (
    <TableContainer>
      <Table variant="simple" className={styles.sticky_first_column}>
        <Thead>
          <Tr>
            {claimsListCols.map((col, index) => {
              return (
                <Th
                  key={col.key}
                  padding="12px 6px"
                >
                  <HStack spacing="3px">
                    <Text fontSize="12px">{col.label}</Text>
                  </HStack>
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody backgroundColor="#ffffff" fontSize="12px">
          {claimListData.map(data => {
            return (
              <Tr
                key={getOr(data, claimsListCols[0].paths, getOr(data, 'feKey', 'na'))}
                fontWeight="400"
              >
                {claimsListCols.map(col => {
                  const emptyValue = col.emptyValue || 'N/A';
                  const retrievedValue = nestedGetOr(0, col.paths || [], data, emptyValue);
                  let element = retrievedValue;
                  if (col.key === 'claim_type') element = retrievedValue === 'non_conformance' ? 'NCR' : capitalizeFirstLetter(retrievedValue);
                  if (col.key === 'ncr_type') element = renderNCRTypeTag(retrievedValue);
                  if (col.key === 'claim_value' && retrievedValue !== emptyValue)
                    element = formatMopurchaseOrdernetaryValue('USD', retrievedValue, {
                      notation: 'standard',
                    });
                  if (col.key === 'status' && retrievedValue) element = renderStatus(retrievedValue);
                  if (col.dataType === 'date' && retrievedValue && moment(retrievedValue).isValid())
                    element = moment(retrievedValue).format('DD-MM-YYYY');
                  if (col.key === 'actions')
                    element = renderActions({
                      data,
                      caseId: nestedGetOr(0, claimsListCols[0].paths || [], data, 'N/A'),
                      userTypes: { isAdminUser, isPackerUser, isGrinderUser },
                      onClickDelete,
                    });
                  return (
                    <Td key={col.key} padding="9px 8px">
                      {element}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

IncidentClaimsListTable.propTypes = {
  onClickDelete: PropTypes.func,
  ncrs: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
};

export default IncidentClaimsListTable;
