import React from 'react';
import { Radio, Typography } from 'antd';
import styles from '../Filter.module.css';

export default function FilterRadioGeneric(props) {
  const {
    label,
    value,
    onChange,
    options,
    withColors,
    dataColors,
    resetOnClick,
  } = props;

  const handleClick = React.useCallback((x) => {
    if (options) {
      if (
        x.target.className !== 'ant-radio-button-input' &&
        x.target.className !== 'ant-radio-button-wrapper'
      ) {
        onChange(options);
      }
    }
  }, [options]);

  React.useEffect(() => {
    if (resetOnClick) {
      window.addEventListener('click', handleClick);
      return function cleanup() {
        window.removeEventListener('click', handleClick);
      };
    }
  }, [resetOnClick, handleClick]);

  return (
    <div data-vertical={true} data-all-selected={value === undefined}>
      {label && (
        <Typography.Text className={styles.filterLabel}>
          {label}
        </Typography.Text>
      )}
      <Radio.Group
        onChange={(e) => onChange([e.target.value])}
        value={value}
        className={styles.radioGroup}
      >
        {options.map((x) => (
          <Radio.Button key={x} value={x}>
            {withColors && dataColors && (
              <div
                className={styles.colorIndicator}
                data-color={dataColors[x]}
              />
            )}
            {x}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
}
