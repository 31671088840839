import { FEC_ADDED, FEC_REMOVED, FEC_UPDATED, FECS_FETCHED } from '../actions/actions_fec';

const findByKey = (target, key) => {
  const targetVal = target[key];
  return el => {
    return el[key] === targetVal;
  };
};

const defaultFinderFn = target => findByKey(target, 'id');

const removeObjectFromArray = (array, removedObject, finderFn = defaultFinderFn) => {
  const idx = array.findIndex(finderFn(removedObject));
  return [...array.slice(0, idx), ...array.slice(idx + 1)];
};

const replaceObjectInArray = (array, replacement, finderFn = defaultFinderFn) => {
  const idx = array.findIndex(finderFn(replacement));
  return [...array.slice(0, idx), replacement, ...array.slice(idx + 1)];
};

const fecReducer = (fecs = [], action) => {
  switch (action.type) {
    case FECS_FETCHED:
      return action.payload;
    case FEC_ADDED:
      return [...fecs, action.payload];
    case FEC_UPDATED:
      return replaceObjectInArray(fecs, action.payload);
    case FEC_REMOVED:
      return removeObjectFromArray(fecs, action.payload);
    default:
      return fecs;
  }
};

export default fecReducer;
