import React from 'react';

const ClassicLayoutContainer = ({ navigation, mainContent, children }) => {
  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        {navigation}
        <div id="main-body" className="main-body">
          {mainContent}
          {children}
        </div>
      </div>
    </div>
  );
};

export default ClassicLayoutContainer;
