import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Input, InputGroup, InputLeftElement, List, ListItem, Text } from '@chakra-ui/react';
import { FaAngleRight } from 'react-icons/fa';
import { SearchIcon } from '@chakra-ui/icons';
import NoData from '../_components/NoData';

const SideBar = ({ packerId, changePacker, handleSearch }) => {
  const establishmentDetailsState = useSelector(state => state.establishmentDetails);
  const [search, setSearch] = useState('');

  const changeHandler = e => {
    setSearch(e.target.value);
    handleSearch(e.target.value);
  };

  return (
    <Box backgroundColor="card.default" w="350px" flexBasis="350px" padding="20px 20px 0px">
      <InputGroup width="100%" marginBottom="10px">
        <InputLeftElement>
          <SearchIcon color="#0000005E" />
        </InputLeftElement>
        <Input
          marginLeft="auto !important"
          placeholder="Search"
          width="100%"
          height="40px"
          value={search}
          fontWeight="normal !important"
          backgroundColor="#ffffff"
          onChange={changeHandler}
        />
      </InputGroup>
      <List
        maxHeight="100%"
        height="calc(100% - 30px)"
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            height: '0',
            width: '0',
          },
          '&::-webkit-scrollbar-track': {
            height: '0',
            width: '0',
            background: 'var(--chakra-colors-gray-100)',
          },
        }}
      >
        {establishmentDetailsState?.packers && establishmentDetailsState?.packers?.length ? (
          establishmentDetailsState.packers.map(packerItem => {
            return (
              <ListItem
                key={packerItem.id}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                fontSize="14px"
                background={packerId === packerItem?.id ? 'rgba(58, 68, 176, 0.04)' : ''}
                color="#3A44B0"
                padding="16px"
                borderBottom="1px solid #E3E3E3"
                cursor="pointer"
                _hover={{
                  background: 'rgba(58, 68, 176, 0.04)',
                  color: '#3A44B0',
                }}
                _focus={{
                  background: packerId === packerItem?.id ? 'rgba(58, 68, 176, 0.04)' : '',
                  color: packerId === packerItem?.id ? '#3A44B0' : '',
                }}
                onClick={() => changePacker(packerItem)}
              >
                <Text>{packerItem.name}</Text>
                {packerId === packerItem?.id ? <FaAngleRight size="12px" /> : ''}
              </ListItem>
            );
          })
        ) : (
          <NoData />
        )}
      </List>
    </Box>
  );
};

SideBar.propTypes = {
  packerId: PropTypes.number,
  changePacker: PropTypes.func,
  handleSearch: PropTypes.func,
};

export default SideBar;
