import sortBy from 'lodash/sortBy';

export function filterOptionsByPurchasingOffice(optionsList, purchasingOffice) {
  if (!purchasingOffice) return sortBy(optionsList);
  return sortBy(optionsList.filter(p => p.purchasingOffice?.includes(purchasingOffice)));
}

export function filterPackerPlantsByPackerId(packerPlantOptions, packerId) {
  if (!packerId || !packerPlantOptions) {
    return packerPlantOptions || [];
  }
  const filtered = packerPlantOptions.filter(opt => opt.packerId === packerId);
  // sort packer options alphabetically with TBA as always first when available
  const tbaIndex = filtered.findIndex(p => p.label.includes('TBA'));
  const copy = [...filtered];
  if (tbaIndex > -1) {
    copy.splice(tbaIndex, 1);
    copy.unshift(filtered[tbaIndex]);
  }
  const sortedOptions = sortBy(copy);
  return sortedOptions;
}

export function formatOptionsFromFormFields(list) {
  return sortBy(list.map(itm => ({ value: itm.value, label: itm.value })));
}

export const filterGrinderOptionsByEndUser = (endUser, grinders) => {
  if (endUser && grinders.length) {
    return grinders.filter(grinder => endUser.grinders_uids.includes(grinder.uid));
  }
  return null;
};

export const filterEndUserOptionsByGrinder = (grinder, endUsers) => {
  if (grinder && endUsers.length) {
    return endUsers
      .filter(endUser => endUser.grindersUids.includes(grinder.value) && endUser.isActive === true)
      .map(endUser => ({
        label: endUser.legalName,
        value: endUser.id,
        grinders_uids: endUser.grindersUids,
      }));
  }
  return null;
};
