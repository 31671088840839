import { MARKETS } from '../../config/markets';

export const INCIDENTS_OPTIONS = {
  TOTAL: 'Total',
  TOTAL_WITH_BREAKDOWN: 'Total with breakdown',
  BY_CATEGORY: 'By category',
  BY_SUBCATEGORY: 'By subcategory',
  BY_MARKET: 'By market (domestic vs export)',
};

export const VOLUME_OPTIONS = {
  [MARKETS.Australia.key]: {
    PERCENT_OF_TOTAL: '% of all volume supplied',
    INCIDENTS_RATE: 'Incidents per 10 (tonnes)',
    PERCENT_OF_MARKET: '% of market',
    WEIGHT: 'Affected Weight',
    NORMALISE_BY: [5, 7],
    MULTIPLIER: 10000,
  },
  [MARKETS.USA.key]: {
    PERCENT_OF_TOTAL: '% of all volume supplied',
    INCIDENTS_RATE: 'Incidents per 1M (lbs)',
    PERCENT_OF_MARKET: '% of market',
    WEIGHT: 'Affected Weight',
    NORMALISE_BY: [7, 6],
    MULTIPLIER: 1000000,
  },
};

export const CLAIM_DOLLARS_OPTIONS = ['AUD', 'NZD', 'USD', 'CAD'];

export const TIME_RANGE_OPTIONS = {
  HISTORICAL: 'Historical',
  YOY: 'YoY',
};

export const BREAKDOWN_OPTIONS = {
  CATEGORY: 'Category',
  PACKER_PLANT: 'Establishment',
  MARKET: 'Market (domestic vs export)',
};

export const PACKER_MODE = {
  TOTAL: 'Packer total',
  BOTTOM_10_TOTAL: 'Bottom 10 total',
  BOTTOM_10: 'Bottom 10',
};

export const WEIGHT_OPTIONS = {
  [MARKETS.Australia.key]: 'kgs',
  [MARKETS.USA.key]: 'lbs',
};
