import React from 'react';
import { Route } from 'react-router-dom';
import AuthGate from '../containers/auth/AuthGate';
import routeConfig from '../routeConfig';
import { getLevelName } from '../utils';

const renderLevel = (level, levelRenderer, key) => {
  return (
    <AuthGate {...level.authConfig} key={key}>
      {level.routeConfig && <Route {...level.routeConfig} component={() => <>{getLevelName(level.name)}</>} />}
      {level.children && level.children.map((child, j) => levelRenderer(child, levelRenderer, `${key}${j}`))}
    </AuthGate>
  );
};

const AppRoutesPageTitles = () => {
  return <> {routeConfig.groups.map((group, key) => renderLevel(group, renderLevel, `${key}`))} </>;
};

export default AppRoutesPageTitles;
