import { connect } from 'react-redux';
import PackerOffersComponent from '../../components/packers/PackerOffers';

const mapStateToProps = state => ({
  packer_plants: state.packer_plants,
  grinders: state.grinders,
  input_products: state.input_products,
  packers: state.packers,
  packer_offers: state.packer_offers,
  netsuite_purchase_order: state.orders.netsuite_purchase_order,
  transporters: state.transporters,
  transport: state.transport.transport_items,
  user: state.user,
  token: state.user.token,
});

const PackerOffers = connect(mapStateToProps)(PackerOffersComponent);

export default PackerOffers;
