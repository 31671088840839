import { connect } from 'react-redux';
import OrderHistoryComponent from '../../components/orders/OrderHistory';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';
import { convertToCamelCase } from '../../helpers';

const mapStateToProps = state => {
  return {
    ...findEntitiesInState(state),
    orders: state.orders.orders,
    order_history: state.orders.order_history,
    export_order_history: state.orders.export_order_history,
    orderPagination: state.orders.orderHistoryPagination,
    user: state.user,
    users: state.users,
    token: state.user.token,
    configs: state.configs,
    specifications: state.specifications,
    packageWeights: convertToCamelCase(state.packageWeights),
    formFields: convertToCamelCase(state.form_fields),
    apiRequestError: state.error,
  };
};

const OrderHistory = connect(mapStateToProps)(OrderHistoryComponent);

export default OrderHistory;
