import { createSelector } from '@reduxjs/toolkit';
import { selectQualitySortedPackerPlantsOptions } from '../../quality/selectors/selectQualityPackerPlantOptions';
import { selectQualityNCRsRawData } from './selectQualityNCRsData';

const selectQualityNCRsBottomTenPackerPlants = createSelector(
  selectQualityNCRsRawData,
  selectQualitySortedPackerPlantsOptions,
  (qualityNCRsRaw, sortedPackerPlantsOptions) => {
    const packerPlantIncidentsCountMap = new Map();
    if (qualityNCRsRaw) {
      qualityNCRsRaw.forEach(dataByDate => {
        if (dataByDate.byPackerPlant && dataByDate.byPackerPlant.length > 0) {
          dataByDate.byPackerPlant
            .filter(pp => pp.packerPlantId.length > 3)
            .forEach(packerPlant => {
              if (packerPlant.incidentsCount && packerPlant.incidentsCount > 0) {
                const incidentsCount = packerPlantIncidentsCountMap.get(packerPlant.packerPlantId);
                if (incidentsCount) {
                  packerPlantIncidentsCountMap.set(
                    packerPlant.packerPlantId,
                    incidentsCount + packerPlant.incidentsCount
                  );
                } else {
                  packerPlantIncidentsCountMap.set(packerPlant.packerPlantId, packerPlant.incidentsCount);
                }
              }
            });
        }
      });
    }
    const sortedPackerPlantIds = [...packerPlantIncidentsCountMap].sort((a, b) => b[1] - a[1]).map(a => a[0]);

    const sortedPackerPlants = [];

    sortedPackerPlantIds.forEach(ppId => {
      const packerPlant = sortedPackerPlantsOptions.find(pp => pp.id === ppId);
      if (packerPlant && !packerPlant.isPacker) {
        sortedPackerPlants.push(packerPlant);
      }
    });
    return sortedPackerPlants.slice(0, 10);
  }
);

export default selectQualityNCRsBottomTenPackerPlants;
