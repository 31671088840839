import { createSlice } from '@reduxjs/toolkit';
import {
  deleteStrategyTypeRequest,
  fetchStrategyTypesRequest,
  patchStrategyTypeRequest,
  postStrategyTypeRequest,
} from '../../data-fixtures/strategy-types';
import { removeObjectFromArray, replaceObjectInArray } from '../../helpers';

const initialState = { strategyTypes: [] };

const strategyTypesSlice = createSlice({
  name: 'strategyTypes',
  initialState,
  reducers: {
    setStrategyTypes: (state, { payload }) => payload,
    addStrategyType: (state, { payload }) => {
      state.push(payload);
    },
    updateStrategyType: (state, { payload }) => {
      replaceObjectInArray(state.strategyTypes, payload);
    },
    deleteStrategyType: (state, { payload }) => {
      removeObjectFromArray(state.strategyTypes, payload);
    },
  },
});

export const { setStrategyTypes, addStrategyType, updateStrategyType, deleteStrategyType } = strategyTypesSlice.actions;

// Basic selectors
export const selectStrategyTypes = state => {
  return state.strategyTypes.data;
};

// Thunks
export const fetchStrategyTypes = () => {
  return async dispatch => {
    try {
      const strategyTypes = await fetchStrategyTypesRequest();
      dispatch(setStrategyTypes(strategyTypes));
      return strategyTypes;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export const postStrategyType = strategyType => {
  return async dispatch => {
    try {
      const newStrategyType = await postStrategyTypeRequest(strategyType);
      dispatch(addStrategyType(newStrategyType));
      return newStrategyType;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export const patchStrategyType = strategyType => {
  return async dispatch => {
    try {
      const updatedStrategyType = await patchStrategyTypeRequest(strategyType);
      dispatch(updateStrategyType(updatedStrategyType));
      return updatedStrategyType;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export const handleDeleteStrategyType = strategyType => {
  return async dispatch => {
    try {
      await deleteStrategyTypeRequest(strategyType);
      dispatch(deleteStrategyType(strategyType));
      return strategyType;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      return error;
    }
  };
};

export default strategyTypesSlice.reducer;
