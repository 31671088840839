import { FETCH_THEMES_SUCCESS, FETCH_THEMES_ERROR } from '../actions/actions_themes';

const initialThemes = [];

const themes = (themesData = initialThemes, action) => {
  switch (action.type) {
    case FETCH_THEMES_SUCCESS:
      return action.payload;
    case FETCH_THEMES_ERROR:
      return [];
    default:
      return themesData;
  }
};

export default themes;
