import React from 'react';
import { Select, Typography } from 'antd';

export default function FilterGrinder(props) {
  const { grinder, grinders, onActiveGrinderChange } = props;

  return (
    <div>
      <Typography.Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Filter by grinder
      </Typography.Text>
      <Select value={grinder} style={{ width: '100%' }} onChange={onActiveGrinderChange}>
        <Select.Option value={null}>--</Select.Option>
        {grinders.map(x => (
          <Select.Option key={`grinder-${x}`} value={x}>
            {x}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
