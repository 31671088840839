import React from 'react';
import { Radio } from 'antd';
import { SUPPLY_CHAIN_VIEW_MODE } from '../../../../slices/markets/marketsConfig';
import PropTypes from 'prop-types';

const viewModeOptions = Object.entries(SUPPLY_CHAIN_VIEW_MODE).map(([key, value]) => ({
  label: value.charAt(0).toUpperCase() + value.substr(1).toLowerCase(),
  value,
}));

export default function SupplyChainViewModeControl(props) {
  const { controlState, onViewModeChange } = props;
  return (
    <div>
      <span style={{ marginRight: '20px', color: 'rgba(11, 20, 53, 0.7)' }}>View by</span>
      <Radio.Group value={controlState.viewMode} onChange={e => onViewModeChange(e.target.value)}>
        {viewModeOptions.map(option => (
          <Radio.Button key={option.value} value={option.value}>
            {option.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
}

// TODO define shape
SupplyChainViewModeControl.propTypes = {
  controlState: PropTypes.shape(),
  onViewModeChange: PropTypes.func,
};
