import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  HStack,
} from '@chakra-ui/react';

export const EmailModalOrderDetails = ({ grinderName, coldstoreLocationName, transporterName, ...rest }) => {
  return (
    <HStack spacing="25px" paddingLeft="65px" paddingTop="0px" paddingBottom="9px" borderBottomWidth="1px" {...rest}>
      <HStack spacing="5px">
        <Text as="p" fontWeight="bold">
          Grinder:
        </Text>
        <Text as="p">{grinderName}</Text>
      </HStack>
      <HStack spacing="5px">
        <Text as="p" fontWeight="bold">
          Coldstore:
        </Text>
        <Text as="p">{coldstoreLocationName}</Text>
      </HStack>
      <HStack spacing="5px">
        <Text as="p" fontWeight="bold">
          Trucking Company:
        </Text>
        <Text as="p">{transporterName}</Text>
      </HStack>
    </HStack>
  );
};

EmailModalOrderDetails.propTypes = {
  grinderName: PropTypes.string.isRequired,
  coldstoreLocationName: PropTypes.string.isRequired,
  transporterName: PropTypes.string.isRequired,
};

export const EmailModalBaseComponent = ({ visible, onExit, headerTitle, modalBodyContent, modalFooterContent }) => {
  return (
    <Modal isOpen={visible} onClose={onExit} size="full">
      <ModalOverlay />
      <ModalContent maxWidth="895px">
        <ModalHeader borderBottom="1px" borderColor="gray.200" marginBottom="12px">
          <Text as="p" fontSize="23px" fontWeight="semibold">
            {headerTitle}
          </Text>
          <ModalCloseButton marginTop="8px" />
        </ModalHeader>
        <ModalBody padding="0px">{modalBodyContent}</ModalBody>
        <ModalFooter borderTopWidth="1px" marginTop="21px">
          {modalFooterContent}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

EmailModalBaseComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
  headerTitle: PropTypes.string.isRequired,
  modalBodyContent: PropTypes.elementType.isRequired,
  modalFooterContent: PropTypes.elementType.isRequired,
};
