import React, { Component } from 'react';

import KPIDashboard from '../../containers/dashboard/KPIDashboard';
import GrinderKPIDashboard from '../../containers/dashboard/GrinderKPIDashboard';
import OpsMap from '../../containers/dashboard/OpsMap';
import ReportMap from '../../containers/dashboard/ReportMap';
import SCADashboard from '../dashboard/SCADashboard';
import MarketDashboard from '../dashboard/MarketDashboard';
import POSDashboard from '../dashboard/POSDashboard';
import DailyTasksDashboard from '../../containers/dailyTasks/DailyTasksDashboard';
import { fetchEntitiesData } from '../../slices/masterData/entityManagerSlice';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    let startingPage = 'kpi_dashboard';
    if (this.props.user.role.access_list.indexOf('kpi_read') === -1) {
      startingPage = 'ops_map';
    }
    if (this.props.user.organisation.org_type.indexOf('grinder') > -1) {
      startingPage = 'grinder_kpi_dashboard';
    }
    if (this.props.user.role.access_list.includes('exceptions_read')) {
      startingPage = 'daily-tasks';
    }
    this.state = {
      active_type: startingPage,
      currentGrinder: {
        name: 'All Grinders',
        id: 'ALL',
      },
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchEntitiesData(['grinder']));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.grinders.length === 1) {
      this.setState({
        currentGrinder: nextProps.grinders[0],
      });
    }
  }

  selectGrinder(e) {
    let thisGrinder = false;
    if (e !== 'all') {
      this.props.grinders.map(grinder => {
        if (grinder.name === e) {
          thisGrinder = grinder;
        }
      });
    } else {
      thisGrinder = {
        id: 'ALL',
        name: 'All Grinders',
      };
    }
    this.setState({ currentGrinder: thisGrinder });
  }

  render() {
    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="row">
            <div className="col-xl-12">
              <div style={{ marginBottom: '0px' }} className="m-portlet m-portlet--full-height ">
                <div id="main-header" className="m-portlet__head">
                  <div className="m-portlet__head-tools">
                    {this.state.active_type === 'grinder_kpi_dashboard' ? (
                      <div className="m-portlet__head-tools pull-right">
                        <div className="btn-group pull-right open dot-tabs" style={{ marginTop: '0px' }}>
                          <button
                            type="button"
                            className="btn green btn-sm btn-outline dropdown-toggle"
                            data-toggle="dropdown"
                            aria-expanded="true"
                          >
                            <p>
                              {this.state.currentGrinder.name === ''
                                ? 'Select a Grinder'
                                : this.state.currentGrinder.name}
                            </p>{' '}
                            <i className="fa fa-ellipsis-v" />
                          </button>
                          <ul className="dropdown-menu pull-right" role="menu">
                            {this.props.grinders.length > 1 ? (
                              <li>
                                <a href={null} onClick={this.selectGrinder.bind(this, 'all')}>
                                  All Grinders
                                </a>
                              </li>
                            ) : (
                              false
                            )}
                            {this.props.grinders.map(grinder => {
                              return (
                                <li key={grinder.name}>
                                  <a href={null} onClick={this.selectGrinder.bind(this, grinder.name)}>
                                    {grinder.name}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      false
                    )}
                    <ul className="nav nav-tabs m-tabs m-tabs-line admin-tabs" role="tablist">
                      {this.props.user.role.access_list.indexOf('exceptions_read') > -1 ? (
                        <li
                          key="list-menu-daily-tasks"
                          className={`nav-item m-tabs__item ${
                            this.state.active_type === 'daily-tasks' ? 'active' : ''
                          }`}
                        >
                          <a
                            className="nav-link m-tabs__link"
                            onClick={() => this.setState({ active_type: 'daily-tasks' })}
                          >
                            Daily Tasks
                          </a>
                        </li>
                      ) : (
                        false
                      )}
                      {this.props.user.role.access_list.indexOf('kpi_read') > -1 ? (
                        <li
                          key="list-menu-kpi-dashboard"
                          className={`nav-item m-tabs__item ${
                            this.state.active_type === 'kpi_dashboard' ? 'active' : ''
                          }`}
                        >
                          <a
                            className="nav-link m-tabs__link"
                            onClick={() => this.setState({ active_type: 'kpi_dashboard' })}
                          >
                            KPIs
                          </a>
                        </li>
                      ) : (
                        false
                      )}
                      {/* TODO - hide it because of pork business preso */}
                      {/* {this.props.user.organisation.org_type.indexOf('grinder') > -1 ||
                      this.props.user.role.access_list.indexOf('kpi_read') > -1 ? (
                        <li
                          key="list-menu-grinder-kpi-dashboard"
                          className={`nav-item m-tabs__item ${
                            this.state.active_type === 'grinder_kpi_dashboard' ? 'active' : ''
                          }`}
                        >
                          <a
                            className="nav-link m-tabs__link"
                            onClick={() => this.setState({ active_type: 'grinder_kpi_dashboard' })}
                          >
                            Grinder KPIs
                          </a>
                        </li>
                      ) : (
                        false
                      )} */}
                      <li
                        key="list-menu-ops-map"
                        className={`nav-item m-tabs__item ${this.state.active_type === 'ops_map' ? 'active' : ''}`}
                      >
                        <a className="nav-link m-tabs__link" onClick={() => this.setState({ active_type: 'ops_map' })}>
                          Ops
                        </a>
                      </li>
                      {/* TODO - hide it because of pork business preso */}
                      {/* <li
                        key="list-menu-report-map"
                        className={`nav-item m-tabs__item ${this.state.active_type === 'report_map' ? 'active' : ''}`}
                      >
                        <a
                          className="nav-link m-tabs__link"
                          onClick={() => this.setState({ active_type: 'report_map' })}
                        >
                          Reports
                        </a>
                      </li> */}
                      {/* TODO - hide these menus for now as requested by the business */}
                      {/* {this.props.user.role.access_list.indexOf('kpi_read') > -1 ? (
                        <li
                          key="list-menu-sca"
                          className={`nav-item m-tabs__item ${this.state.active_type === 'sca' ? 'active' : ''}`}
                        >
                          <a className="nav-link m-tabs__link" onClick={() => this.setState({ active_type: 'sca' })}>
                            SCA
                          </a>
                        </li>
                      ) : (
                        false
                      )}
                      {this.props.user.role.access_list.indexOf('kpi_read') > -1 ? (
                        <li
                          key="list-menu-markets"
                          className={`nav-item m-tabs__item ${this.state.active_type === 'markets' ? 'active' : ''}`}
                        >
                          <a
                            className="nav-link m-tabs__link"
                            onClick={() => this.setState({ active_type: 'markets' })}
                          >
                            Markets
                          </a>
                        </li>
                      ) : (
                        false
                      )}
                      {this.props.user.role.access_list.indexOf('kpi_read') > -1 ? (
                        <li
                          key="list-menu-pos"
                          className={`nav-item m-tabs__item ${this.state.active_type === 'pos' ? 'active' : ''}`}
                        >
                          <a className="nav-link m-tabs__link" onClick={() => this.setState({ active_type: 'pos' })}>
                            POS
                          </a>
                        </li>
                      ) : (
                        false
                      )} */}
                    </ul>
                  </div>
                </div>
                {this.state.active_type === 'kpi_dashboard' ? (
                  <div className="m-portlet__body" style={{ minHeight: '320px' }}>
                    <div className="tab-content">
                      <div className="tab-pane active" id="m_widget2_tab4_content">
                        <KPIDashboard />
                      </div>
                    </div>
                  </div>
                ) : (
                  false
                )}
                {this.state.active_type === 'grinder_kpi_dashboard' ? (
                  <div className="m-portlet__body" style={{ minHeight: '320px' }}>
                    <div className="tab-content">
                      <div className="tab-pane active" id="m_widget2_tab4_content">
                        <GrinderKPIDashboard currentGrinder={this.state.currentGrinder} />
                      </div>
                    </div>
                  </div>
                ) : (
                  false
                )}
                {this.state.active_type === 'ops_map' ? (
                  <div className="tab-pane active" id="m_widget2_tab4_content">
                    <OpsMap />
                  </div>
                ) : (
                  false
                )}
                {this.state.active_type === 'report_map' ? (
                  <div className="tab-pane active" id="m_widget2_tab4_content">
                    <ReportMap />
                  </div>
                ) : (
                  false
                )}
                {this.state.active_type === 'sca' ? (
                  <div className="m-portlet__body" style={{ minHeight: '320px' }}>
                    <div className="tab-content">
                      <div className="tab-pane active" id="m_widget2_tab4_content">
                        <SCADashboard />
                      </div>
                    </div>
                  </div>
                ) : (
                  false
                )}
                {this.state.active_type === 'markets' ? (
                  <div className="m-portlet__body" style={{ minHeight: '320px' }}>
                    <div className="tab-content">
                      <div className="tab-pane active" id="m_widget2_tab4_content">
                        <MarketDashboard />
                      </div>
                    </div>
                  </div>
                ) : (
                  false
                )}
                {this.state.active_type === 'pos' ? (
                  <div className="tab-pane active" id="m_widget2_tab4_content">
                    <POSDashboard />
                  </div>
                ) : (
                  false
                )}
                {this.state.active_type === 'daily-tasks' ? (
                  <div className="tab-pane active" id="m_widget2_tab4_content">
                    <DailyTasksDashboard />
                  </div>
                ) : (
                  false
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
