import PropTypes from 'prop-types';
import { cloneDeep, get } from 'lodash';
import queryString from 'query-string';
import React, { Component } from 'react';
import RenderInputComponentByType from '../../utilities/RenderInputComponentByType';
import './reportParamsCard.scss';
import { Alert, AlertDescription, AlertIcon, VStack, Button, Flex, Wrap, FormLabel } from '@chakra-ui/react';
import CustomFormControl from '../../core/CustomFormControl';
import { isJSON } from '../../../helpers';

export default class ReportParamsCard extends Component {
  state = {
    reportParams: {},
    showError: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { reportParams } = this.state;
    const { paramsConfigList } = this.props;
    if (prevState.reportParams !== reportParams) {
      const queryParams = queryString.parse(window.location.search);
      const reportId = [...paramsConfigList][0].report_id;
      window.history.replaceState(
        null,
        null,
        `self-service-reports/${reportId}/?${queryString.stringify({
          ...queryParams,
          ...cloneDeep(reportParams),
        })}`
      );
    }
  }

  onChangeInput = (field, inputType, fieldValue) => {
    if ((fieldValue instanceof Array && fieldValue.length <= 0) || fieldValue === null || fieldValue === '') {
      this.setState({ showError: true });
    } else {
      this.setState({ showError: false });
    }
    const { reportParams } = this.state;
    const reportParamsClone = cloneDeep(reportParams);
    if (inputType === 'multi-select') {
      const multipleSelectedValues = (fieldValue || []).map(obj => obj.value);
      reportParamsClone[field] = multipleSelectedValues;
    } else {
      reportParamsClone[field] = fieldValue;
    }
    this.setState({ reportParams: reportParamsClone });
  };

  getRunReportParams = () => {
    const { paramsConfigList } = this.props;
    const paramsConfigListClone = cloneDeep(paramsConfigList);
    const urlQueryParams = queryString.parse(window.location.search);
    // urlQueryParams is an object which would not wrap values in array
    // if a specific key has only one value
    // for BE to work, multi-select values should always be sent
    Object.keys(urlQueryParams).map(paramKey => {
      const paramConfig = paramsConfigListClone.find(c => c.name === paramKey);
      if (
        paramConfig &&
        paramConfig.param_type === 'multi-select' &&
        urlQueryParams[paramKey] instanceof Array === false
      ) {
        urlQueryParams[paramKey] = [urlQueryParams[paramKey]];
        return urlQueryParams[paramKey];
      }
      return '';
    });
    return this.state.reportParams.length
      ? { ...urlQueryParams, ...cloneDeep(this.state.reportParams) }
      : urlQueryParams;
  };

  render() {
    const { paramsConfigList, runReport } = this.props;
    const { reportParams, showError } = this.state;
    const paramsConfigListClone = cloneDeep(paramsConfigList);
    return (
      <VStack spacing="34px" align="stretch">
        {showError && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>Field cannot be empty and must have a valid input.</AlertDescription>
          </Alert>
        )}

        <Wrap spacing="41px">
          {paramsConfigListClone &&
            paramsConfigListClone.map(config => {
              const defaultValues = isJSON(config.default) ? JSON.parse(config.default) : config.default;
              let params = get(reportParams, `${config.name}`, defaultValues);
              params = params instanceof Array ? params : params.replace(/"/g, '').split(',');
              return (
                <CustomFormControl key={`ssr_${config.name}`}>
                  <FormLabel htmlFor="report-params-config">{config.label}</FormLabel>
                  <RenderInputComponentByType
                    inputType={config.param_type}
                    sourceData={config.options ? JSON.parse(config.options) : ''}
                    fieldValue={params}
                    isDisabled={false}
                    name={config.name}
                    OnFieldValueChange={(field, inputType, value) => this.onChangeInput(field, inputType, value)}
                  />
                </CustomFormControl>
              );
            })}
        </Wrap>
        <Flex justifyContent="flex-end">
          <Button
            colorScheme="actionSecondary"
            width="152px"
            height="43px"
            isDisabled={showError}
            onClick={() => runReport(this.getRunReportParams())}
          >
            Run Report
          </Button>
        </Flex>
      </VStack>
    );
  }
}

ReportParamsCard.propTypes = {
  paramsConfigList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        default: PropTypes.string,
        id: PropTypes.number,
        label: PropTypes.string,
        name: PropTypes.string,
        options: PropTypes.string,
        param_type: PropTypes.string,
        report_id: PropTypes.number,
      })
    ),
    PropTypes.string,
  ]),
  runReport: PropTypes.func.isRequired,
};
