import Constants from '../Constants';

export const SETTINGS_CONFIG_FETCHED = 'SETTINGS_CONFIG_FETCHED';
export const SETTINGS_CONFIG_ERROR = 'SETTINGS_CONFIG_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/

export function loadSettingsConfig(config) {
  return {
    type: SETTINGS_CONFIG_FETCHED,
    payload: config,
  };
}

export function errorSettingsConfig(error) {
  return {
    type: SETTINGS_CONFIG_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/

export function fetchSettingsConfig(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}settings_config`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorSettingsConfig))
      .then(json => dispatch(loadSettingsConfig(json)))
      .catch(error => dispatch(errorSettingsConfig(error)));
  };
}
