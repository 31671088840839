import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes, { any } from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useTheme,
  ButtonGroup,
  Button,
  Center,
  Image,
} from '@chakra-ui/react';
import HorizontalNavigationBand from '../../core/HorizontalNavigationBand';
import NewNCRStart from './NewNCRStart';
import { invertColor } from '../../../theme/utils';
import { get, groupBy, isEmpty, merge, set } from 'lodash';
import { ReactComponent as CheckIconSVG } from '../../../assets/check.svg';
import { AdvanceButton, BackButton } from '../Components/index';
import PODetail from './PODetail';
import NCRDetails from './NCRDetails';
import ClaimListForm from '../Forms/ClaimListForm';
import Attachments from './Attachments';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { convertToCamelCase, getOr, iff } from '../../../helpers';
import { connect } from 'react-redux';
import { fetchEntitiesData, findEntitiesInState } from '../../../slices/masterData/entityManagerSlice';
import { Formik } from 'formik';
import {
  createNCRValidationSchemas,
  generalNCRValidationSchema,
  packerEditNCRWithNoValueValidationSchema,
  packerEditNCRWithValueValidationSchema,
  packerGeneralNCRValidationSchema,
  createIncidentValidationSchemas,
  editIncidentValidationSchemas,
} from '../validationSchemas';
import {
  createGrinderNCR,
  resetNCRAttachmentUpload,
  resetNCRCreation,
  updateGrinderNCR,
  uploadAttachments,
  resetNCREdit,
  fetchNCROrder,
  fetchChangeIncident,
  resetChangeIncident,
} from '../../../actions/action_ncrs';
import {
  prepareAdminUpdateNCRPayload,
  prepareCreateNCRPayload,
  preparePackerUpdateNCRPayload,
  prepareUpdateNCRPayload,
  prepareCreateIncidentPayload,
  prepareUpdateIncidentPayload,
} from '../helpers';
import InitialInvestigation from './InitialInvestigation';
import FinalInvestigation from './FinalInvestigation';
import { fetchBuyerOrgUsers } from '../../../actions/actions_users';
import moment from 'moment';
import { useChangeReqFor } from '../Context/ChangeReqForContext';
import Constants from '../../../Constants';
import EntrapmentForm from '../Forms/EntrapmentForm';

const DoneTabContent = ({ currentNCR, createdIncidents, isCreating, dispatch, ncrType }) => {
  let incidentIds = '';
  if (ncrType === Constants.NCR_TYPES.INCIDENT) {
    if (Array.isArray(createdIncidents) && isCreating) {
      incidentIds = createdIncidents.map(i => i.recordNo);
    } else {
      incidentIds = get(currentNCR, 'recordNo', '');
    }
  }
  return (
    <>
      <Link
        to={`${ncrType === Constants.NCR_TYPES.INCIDENT ? '/incidents/' : '/ncr/'}${get(currentNCR, 'recordNo', '')}`}
        class="close-button"
      >
        <span className="close-icon">&nbsp;</span>
      </Link>
      <Center flexDirection="column">
        <Image src="/img/new_ncr_success.png" width="387px" height="260px" />
        <Box marginTop="20px" fontSize="20px" textAlign="center">
          <Text fontWeight="500">
            {ncrType === Constants.NCR_TYPES.INCIDENT
              ? `Incident ${incidentIds}`
              : `NCR ${get(currentNCR, 'recordNo', '')}`}{' '}
            has been successfully {isCreating ? 'created' : 'updated'}!
          </Text>
          {isCreating ? <Text fontWeight="400">Check your email for confirmation.</Text> : ''}
        </Box>
        <Link to="/ncr?claim_type=non_conformance">
          <Button
            variant={isCreating ? 'solid' : 'outline'}
            colorScheme="actionPrimary"
            fontWeight="700"
            marginTop="40px"
            width="168px"
            height="40px"
            onClick={() => {
              dispatch(resetNCRCreation());
              dispatch(resetNCREdit());
            }}
          >
            Back to NCR&apos;s Home
          </Button>
        </Link>
      </Center>
    </>
  );
};
DoneTabContent.propTypes = {
  currentNCR: PropTypes.objectOf(PropTypes.any),
  createdIncidents: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.objectOf(any)),
  ]),
  isCreating: PropTypes.bool,
  dispatch: PropTypes.func,
  ncrType: PropTypes.string,
};

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const NCRForm = props => {
  const query = useQuery();
  const startEditTabKey = query.get('startEditTabKey');
  const grinderPo = query.get('grinderPo');
  const packerPlantName = query.get('packerName');

  const {
    match,
    isPackerUserEditing,
    isAdminUserEditing,
    isGrinderUserEditing,
    // hasExecutedSearchs,
    dispatch,
    token,
    ncrs: {
      isCreating,
      hasError,
      hasCreated,
      isUploading,
      isButtonDisabled,
      uploadedAttachments,
      uploadCompleted,
      currentNCR,
      createdIncidents,
      changeIncident,
      isUpdating,
      hasUpdated,
    },
    user,
  } = props;
  const editingExistingNCR = isPackerUserEditing || isAdminUserEditing || isGrinderUserEditing;
  const changeNCR = match?.path?.startsWith('/change-ncr');
  const changeIncidentId = match?.params?.id;

  const theme = useTheme();
  const history = useHistory();
  const formRef = useRef();
  const [state, setState] = useState({
    error: '',
    selectMode: true,
    currentNCRDetailsStage: 0,
    internalPO: null,
    packerId: null,
    purchaseOrder: null,
    packerIdInternalPOs: [{ packerId: null, packerPlantId: null, internalPO: '' }],
    ncrType: null,
    hasValidatedOnEditTabInitialMount: false,
  });
  const [grinderPO, setGrinderPO] = useState(null);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isChangeNCRFilled, setIsChangeNCRFilled] = useState(false);
  const [hasExecutedNCRSearch, setHasExecutedNCRSearch] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTabIndex]);

  useEffect(() => {
    if (editingExistingNCR) setState(prevState => ({ ...prevState, selectMode: false }));
  }, [editingExistingNCR]);

  useEffect(() => {
    if (
      !isAdminUserEditing &&
      get(currentNCR, 'status', '').toLowerCase() === 'closed' &&
      !get(currentNCR, 'incidentMedia', null)
    )
      history.push(`/ncr/${get(currentNCR, 'recordNo', '')}`);
  }, [currentNCR?.status]);

  useEffect(() => {
    dispatch(resetNCRCreation());
    dispatch(resetNCREdit());
    dispatch(fetchEntitiesData());
    dispatch(fetchBuyerOrgUsers(token));
    dispatch(fetchEntitiesData(['claim_entity', 'non_conformance_type', 'non_conformance_sub_type']));
  }, []);

  const getPackerName = pPlantName => {
    const packerPlant = props.packerPlants.find(pp => pp.name === pPlantName);
    if (packerPlant) {
      return packerPlant.packerId;
    }
    return '';
  };

  const getEstablishmentId = pPlantName => {
    const packerPlant = props.packerPlants.find(pp => pp.name === pPlantName);
    if (packerPlant) {
      return packerPlant.id;
    }
    return '';
  };

  const fillNCRWithIncident = (incident, frmRef) => {
    if (incident && frmRef.current) {
      setIsChangeNCRFilled(true);
      const { values } = frmRef.current;
      let updatedValues = { ...values };
      let ncrDetails = { ...updatedValues?.ncrDetails };
      let claim = { ...updatedValues?.claim };
      claim = { ...claim, currencyCode: incident?.purchaseOrder.sellCurrency };
      ncrDetails = { ...ncrDetails, details: incident?.details, unitOfMeasure: { ...incident?.UnitOfMeasure } };
      const ncrMedia = {};
      const otherMedia = [...incident?.incidentMedia];
      ncrMedia.others = otherMedia.map(item => {
        const array = new Uint8Array(16); // Generate 16 random bytes
        window.crypto.getRandomValues(array);
        const randomSequence = Array.from(array)
          .map(byte => byte.toString(16).padStart(2, '0'))
          .join('');
        const id = `rc-upload-${Date.now()}-${randomSequence}`;
        return { url: item.url, type: 'others', id, file: { uid: id } };
      });
      ncrDetails = { ...ncrDetails, ncrMedia: { ...ncrMedia } };
      // ncrDetails = {...ncrDetails}
      updatedValues = {
        ...updatedValues,
        ncrDetails,
        claim,
        amountAffected: incident?.quantityAffected,
        purchaseOrderLineId: incident?.purchaseOrderLineId,
      };
      const nonConformanceType = props.nonConformanceTypesOptions.find(
        typeOption => typeOption?.id === incident?.nonConformanceSubType?.nonConformanceTypeId
      );
      if (nonConformanceType) {
        updatedValues = { ...updatedValues, nonConformanceType: { ...nonConformanceType } };
        const nonConformanceSubType = props.nonConformanceSubTypesOptions.find(
          typeOption => typeOption?.id === incident.nonConformanceSubType?.id
        );
        updatedValues = { ...updatedValues, nonConformanceSubType: { ...nonConformanceSubType } };
      }
      updatedValues = { ...updatedValues, ncrMedia: { ...ncrMedia } };
      // updatedValues = {...updatedValues}
      formRef.current.setValues(updatedValues, !isEmpty(formRef.current.errors));
    }
  };

  const [packerId, setPackerId] = useState(getPackerName(packerPlantName));
  const [establishmentId, setEstablishmentId] = useState(getEstablishmentId(packerPlantName));
  const [resetIncident, setResetIncident] = useState(false);

  useEffect(() => {
    if (packerPlantName && props.packerPlants.length > 0) {
      setPackerId(getPackerName(packerPlantName));
      setEstablishmentId(getEstablishmentId(packerPlantName));
    }
  }, [packerPlantName, props.packerPlants]);

  useEffect(() => {
    if (changeNCR && !resetIncident) {
      setState(prevState => {
        return {
          ...prevState,
          selectMode: false,
          ncrType: {
            key: 'ncr',
            label: 'NCR',
          },
        };
      });
      if (changeIncidentId) {
        dispatch(resetChangeIncident());
        dispatch(fetchChangeIncident(token, changeIncidentId));
        setResetIncident(true);
      }
    }

    if (changeNCR && formRef?.current !== undefined && !isChangeNCRFilled) {
      if (props.orderHistory.length > 0) {
        setState(prevState => {
          return {
            ...prevState,
            purchaseOrder: get(props, 'orderHistory[0]', []),
          };
        });
        fillNCRWithIncident(changeIncident, formRef);
      }
    }
  }, [changeNCR, changeIncident, props.orderHistory, resetIncident]);

  useEffect(() => {
    if (changeNCR && !hasExecutedNCRSearch && packerId && establishmentId) {
      if (!hasExecutedNCRSearch) {
        const packerPoNUmber = [{ packerId, internalPO: grinderPo, packerPlantId: establishmentId }];
        dispatch(fetchNCROrder(token, packerPoNUmber));
        setHasExecutedNCRSearch(true);
      }
    }
  }, [changeNCR, hasExecutedNCRSearch, packerId, establishmentId]);

  const handleAdvance = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const addPurchaseOrder = () => {
    setState(prevState => ({
      ...prevState,
      packerIdInternalPOs: [...prevState.packerIdInternalPOs, { packerId: null, packerPlantId: null, internalPO: '' }],
    }));
  };

  const handleTypeChange = () => {
    setState(prevState => ({
      ...prevState,
      packerIdInternalPOs: [{ packerId: null, packerPlantId: null, internalPO: '' }],
    }));
  };

  const willMakeClaim = get(currentNCR, 'ncrDetails.willMakeClaim');

  const updateFieldValue = (path, value, poLineId = null, poId = null, grinderPoNumber = null, packerName = null) => {
    if (formRef.current) {
      const { values } = formRef.current;
      let updatedValues = { ...values };
      if (path === 'purchaseOrderLineId') {
        updatedValues = { ...updatedValues, uom: null, amountAffected: null, initialClaimValue: null };
      }
      if (path === 'purchaseOrderLineId') {
        let { purchaseOrderLineIds } = updatedValues;
        const filterPoLineId = purchaseOrderLineIds.find(PoLineId => PoLineId.purchaseOrderLineId === value);
        if (!filterPoLineId) {
          const line = {
            purchaseOrderLineId: value,
            purchaseOrderId: poId,
            grinderPoNumber,
            unitOfMeasure: null,
            amountAffected: null,
            packerName,
          };
          purchaseOrderLineIds = [...purchaseOrderLineIds, line];
          updatedValues = { ...updatedValues, purchaseOrderLineIds };
        } else {
          purchaseOrderLineIds = purchaseOrderLineIds.filter(PoLineId => PoLineId.purchaseOrderLineId !== value);
          updatedValues = { ...updatedValues, purchaseOrderLineIds };
        }
      } else if (path === 'amountAffected' || path === 'unitOfMeasure') {
        const { purchaseOrderLineIds } = updatedValues;
        for (let i in purchaseOrderLineIds) {
          if (purchaseOrderLineIds[i].purchaseOrderLineId === poLineId) {
            if (path === 'amountAffected') {
              purchaseOrderLineIds[i].amountAffected = value;
              break;
            } else {
              purchaseOrderLineIds[i].unitOfMeasure = { ...value };
              break;
            }
          }
        }
        updatedValues = { ...updatedValues, purchaseOrderLineIds };
      }
      if (path === 'willMakePotentialClaim') {
        let willPotentialClaimValues = updatedValues.willPotentialClaimValues;
        let willPotentialClaimValue = null;
        if (
          Boolean(willPotentialClaimValues.length === 0 || !willPotentialClaimValues.find(item => item.id === poId))
        ) {
          willPotentialClaimValue = { id: poId, value: value };
          willPotentialClaimValues = [...willPotentialClaimValues, willPotentialClaimValue];
          updatedValues = { ...updatedValues, willPotentialClaimValues };
        } else {
          for (let potentialClaim of willPotentialClaimValues) {
            if (potentialClaim.id === poId) {
              potentialClaim.value = value;
              break;
            }
          }
        }
      }
      if (path !== 'unitOfMeasure') {
        set(updatedValues, path, value);
      }
      formRef.current.setValues(updatedValues, !isEmpty(formRef.current.errors));
    }
  };

  const updateFieldValues = objectData => {
    if (formRef.current) {
      const { values } = formRef.current;
      const updatedValues = { ...values, ...objectData };
      const mergedValues = { ...values };
      merge(mergedValues, updatedValues);
      formRef.current.setValues(mergedValues, !isEmpty(formRef.current.errors));
    }
  };

  let ncrType = Constants.NCR_TYPES.NCR;
  if (editingExistingNCR) {
    ncrType = get(currentNCR, 'incidentMedia', null) ? Constants.NCR_TYPES.INCIDENT : Constants.NCR_TYPES.NCR;
  } else {
    ncrType = get(state, 'ncrType.key', Constants.NCR_TYPES.NCR);
  }

  const grinderUserTabs = [
    {
      key: 'po_details',
      label: 'PO Details',
      content: (
        <PODetail
          {...props}
          form={{
            ...state,
            ...(editingExistingNCR && {
              purchaseOrder: {
                ...get(currentNCR, 'ncrDetails.purchaseOrderLine.purchaseOrder'),
                lines: isEmpty(get(currentNCR, 'ncrDetails.purchaseOrderLine'))
                  ? []
                  : [get(currentNCR, 'ncrDetails.purchaseOrderLine')],
              },
            }),
          }}
          onUpdate={(field, value, poLineId = null, poId = null, grinderPoNumber = null, packerName = null) => {
            updateFieldValue(field, value, poLineId, poId, grinderPoNumber, packerName);
          }}
          onUpdateMany={data => updateFieldValues(data)}
          editingExistingNCR={editingExistingNCR}
          currentNCR={currentNCR}
          changeNCR={changeNCR}
        />
      ),
    },
    {
      key: 'ncr_detail',
      label: `${ncrType === Constants.NCR_TYPES.NCR ? 'NCR' : 'Incident'} Details`,
      content: (
        <NCRDetails
          currentStage={state.currentNCRDetailsStage}
          {...props}
          form={state}
          onUpdate={(field, value) => {
            updateFieldValue(field, value);
          }}
          onUpdateMany={data => updateFieldValues(data)}
        />
      ),
    },
    ...(get(formRef, 'current.values.ncrDetails.willMakeClaim')
      ? [
          {
            key: 'claim_value',
            label: 'Claim Value',
            content: (
              <ClaimListForm
                {...props}
                isReviewing={false}
                onUpdate={(field, value) => {
                  updateFieldValue(field, value);
                }}
                onUpdateMany={data => updateFieldValues(data)}
                form={state}
              />
            ),
          },
        ]
      : []),
    {
      key: 'attachments',
      label: 'Attachments',
      content: (
        <Attachments
          {...props}
          isReviewing={false}
          onUpdate={(field, value) => {
            updateFieldValue(field, value);
          }}
          onUpdateMany={data => updateFieldValues(data)}
        />
      ),
    },
    {
      key: 'done',
      label: 'Done',
      content: (
        <DoneTabContent
          currentNCR={currentNCR}
          createdIncidents={createdIncidents}
          isCreating={!editingExistingNCR}
          dispatch={dispatch}
          ncrType={ncrType}
        />
      ),
    },
  ];

  const packerUserClaimValueTabs = [
    {
      key: 'initial_investigation',
      label: 'Initial Investigation',
      content: (
        <InitialInvestigation
          {...props}
          isReviewing={false}
          onUpdate={(field, value) => {
            updateFieldValue(field, value);
          }}
          onUpdateMany={data => updateFieldValues(data)}
        />
      ),
    },
    {
      key: 'final_investigation',
      label: 'Final Investigation',
      content: (
        <FinalInvestigation
          {...props}
          isReviewing={false}
          onUpdate={(field, value) => {
            updateFieldValue(field, value);
          }}
          onUpdateMany={data => updateFieldValues(data)}
        />
      ),
    },
    {
      key: 'attachments',
      label: 'Attachments',
      content: (
        <Attachments
          {...props}
          isReviewing={false}
          onUpdate={(field, value) => {
            updateFieldValue(field, value);
          }}
          onUpdateMany={data => updateFieldValues(data)}
        />
      ),
    },
    {
      key: 'claim_value',
      label: 'Claim Value',
      content: (
        <ClaimListForm
          {...props}
          isReviewing={false}
          onUpdate={(field, value) => {
            updateFieldValue(field, value);
          }}
          onUpdateMany={data => updateFieldValues(data)}
          form={state}
        />
      ),
    },
    {
      key: 'done',
      label: 'Done',
      content: <DoneTabContent currentNCR={currentNCR} dispatch={dispatch} />,
    },
  ];

  const packerUserNoClaimValueTabs = [
    {
      key: 'initial_investigation',
      label: 'Investigation',
      content: (
        <InitialInvestigation
          {...props}
          isReviewing={false}
          onUpdate={(field, value) => {
            updateFieldValue(field, value);
          }}
          onUpdateMany={data => updateFieldValues(data)}
        />
      ),
    },
    {
      key: 'attachments',
      label: 'Attachments',
      content: (
        <Attachments
          {...props}
          isReviewing={false}
          onUpdate={(field, value) => {
            updateFieldValue(field, value);
          }}
          onUpdateMany={data => updateFieldValues(data)}
        />
      ),
    },
    {
      key: 'done',
      label: 'Done',
      content: <DoneTabContent currentNCR={currentNCR} dispatch={dispatch} />,
    },
  ];

  const grinderIncidentEditingTabs = [
    {
      key: 'po_details',
      label: 'PO Details',
      content: (
        <PODetail
          {...props}
          form={{
            ...state,
            ...(editingExistingNCR
              ? ncrType === Constants.NCR_TYPES.NCR
                ? {
                    purchaseOrder: {
                      ...get(currentNCR, 'ncrDetails.purchaseOrderLine.purchaseOrder'),
                      lines: isEmpty(get(currentNCR, 'ncrDetails.purchaseOrderLine'))
                        ? []
                        : [get(currentNCR, 'ncrDetails.purchaseOrderLine')],
                    },
                  }
                : {
                    ...state,
                    purchaseOrder: {
                      ...get(currentNCR, 'purchaseOrder'),
                      lines: [
                        {
                          id: get(currentNCR, 'purchaseOrderLineId'),
                          inputProduct: { ...get(currentNCR, 'inputProduct') },
                          unitOfMeasure: get(currentNCR, 'UnitOfMeasure'),
                        },
                      ],
                    },
                  }
              : {}),
            purchaseOrderLineIds: [
              {
                purchaseOrderLineId: get(currentNCR, 'purchaseOrderLineId'),
                purchaseOrderId: get(currentNCR, 'purchaseOrder.purchaseOrderId'),
                grinderPoNumber: get(currentNCR, 'purchaseOrder.ginderPo'),
                amountAffected: get(currentNCR, 'quantityAffected'),
                unitOfMeasure: get(currentNCR, 'UnitOfMeasure'),
              },
            ],
          }}
          onUpdate={(field, value, poLineId = null, poId = null, grinderPoNumber = null, packerName = null) => {
            updateFieldValue(field, value, poLineId, poId, grinderPoNumber, packerName);
          }}
          onUpdateMany={data => updateFieldValues(data)}
          editingExistingNCR={editingExistingNCR}
          currentNCR={currentNCR}
          changeNCR={changeNCR}
        />
      ),
    },
    {
      key: 'attachments',
      label: 'Attachments',
      content: (
        <Attachments
          {...props}
          form={state}
          isReviewing={false}
          onUpdate={(field, value) => {
            updateFieldValue(field, value);
          }}
          onUpdateMany={data => updateFieldValues(data)}
          editingExistingNCR={editingExistingNCR}
        />
      ),
    },
    {
      key: 'done',
      label: 'Done',
      content: (
        <DoneTabContent
          currentNCR={currentNCR}
          isCreating={!editingExistingNCR}
          dispatch={dispatch}
          ncrType={ncrType}
        />
      ),
    },
  ];

  const packerUserTabs = willMakeClaim ? packerUserClaimValueTabs : packerUserNoClaimValueTabs;
  const creationTabs = isPackerUserEditing ? packerUserTabs : grinderUserTabs;
  const tabs = editingExistingNCR
    ? ncrType === Constants.NCR_TYPES.NCR
      ? creationTabs
      : grinderIncidentEditingTabs
    : creationTabs;
  const completeSubmissionTabIndex = tabs.length - 2;

  useEffect(() => {
    if (
      !isUploading &&
      !hasError &&
      uploadCompleted &&
      !state.selectMode &&
      (!isUploading && uploadCompleted ? !isButtonDisabled : isButtonDisabled)
    ) {
      let payload = { ...formRef.current.values, uploadedAttachments };
      let ncrType = get(payload, 'ncrType', Constants.NCR_TYPES.NCR);
      if (editingExistingNCR) {
        if (ncrType === Constants.NCR_TYPES.NCR) {
          if (isGrinderUserEditing) {
            payload = prepareUpdateNCRPayload(payload, currentNCR, user);
          } else if (isAdminUserEditing) {
            payload = prepareAdminUpdateNCRPayload(payload, currentNCR, user);
          } else if (isPackerUserEditing) {
            payload = preparePackerUpdateNCRPayload(payload, currentNCR, user);
          }
        } else {
          payload = prepareUpdateIncidentPayload(payload, currentNCR, user);
        }
        dispatch(
          updateGrinderNCR(
            {
              ...payload,
              submit: !get(formRef.current, 'values.draftSave'),
            },
            props.token,
            '',
            ncrType
          )
        );
      } else {
        if (ncrType === Constants.NCR_TYPES.INCIDENT) {
          payload = prepareCreateIncidentPayload(
            { ...formRef.current.values, uploadedAttachments },
            state.purchaseOrder,
            user
          );
        } else {
          payload = prepareCreateNCRPayload(
            { ...formRef.current.values, uploadedAttachments },
            state.purchaseOrder,
            user
          );
        }
        dispatch(
          createGrinderNCR(
            {
              ...payload,
              submit: !get(formRef.current, 'values.draftSave') && !get(formRef.current, 'values.isUnderReview'),
            },
            props.token
          )
        );
      }
    }
  }, [
    isUploading,
    hasError,
    uploadedAttachments,
    uploadCompleted,
    state.selectMode,
    state.purchaseOrder,
    isGrinderUserEditing,
    isButtonDisabled,
  ]);
  const getValidationSchema = () => {
    const priority = get(currentNCR, 'claim.priority');
    let schemaConfig;

    if (isPackerUserEditing) {
      schemaConfig = (
        willMakeClaim ? packerEditNCRWithValueValidationSchema : packerEditNCRWithNoValueValidationSchema
      )[get(tabs, `${activeTabIndex}.key`)];
    } else {
      if (ncrType === Constants.NCR_TYPES.NCR) {
        schemaConfig = get(createNCRValidationSchemas, get(tabs, `${activeTabIndex}.key`), null);
      } else {
        if (!editingExistingNCR) {
          schemaConfig = get(createIncidentValidationSchemas, get(tabs, `${activeTabIndex}.key`), null);
        }
      }
    }

    if (!schemaConfig) return null;
    if (!schemaConfig.isDynamic)
      return (Array.isArray(schemaConfig) ? schemaConfig[state.currentNCRDetailsStage] : schemaConfig).schema;
    const refEntity = props[schemaConfig.refEntityName];
    if (!refEntity) return null;
    const dynamicSchema = refEntity.reduce((acc, curr) => {
      return {
        ...acc,
        [`${schemaConfig.name}${curr.id}`]: schemaConfig.createDynamicSchema(
          curr.isRequired,
          curr.type,
          isAdminUserEditing ? priority : null
        ),
      };
    }, {});
    return schemaConfig.createStaticSchema(dynamicSchema, isAdminUserEditing ? priority : null);
  };

  const getAllTabsValidationSchema = () => {
    const priority = get(currentNCR, 'claim.priority');
    let schemaConfig = generalNCRValidationSchema;
    if (isPackerUserEditing) {
      schemaConfig = (
        willMakeClaim
          ? packerGeneralNCRValidationSchema
          : packerEditNCRWithNoValueValidationSchema[get(tabs, `${activeTabIndex}.key`)]
      ).schema;
      return schemaConfig;
    } else if (editingExistingNCR && ncrType === Constants.NCR_TYPES.INCIDENT) {
      schemaConfig = editIncidentValidationSchemas[get(tabs, `${activeTabIndex}.key`)].schema;
      return schemaConfig;
    }

    let dynamicSchema = null;
    const refEntity = props.claimEntities;
    if (willMakeClaim)
      dynamicSchema = refEntity.reduce((acc, curr) => {
        return {
          ...acc,
          [`claim${curr.id}`]: schemaConfig.createDynamicSchema(curr.isRequired, curr.type, priority),
        };
      }, {});
    return schemaConfig.createStaticSchema(dynamicSchema);
  };

  const { changeReqFor, setChangeReqFor } = useChangeReqFor();
  const submitForm = async shouldValidate => {
    if (formRef.current) {
      if (shouldValidate) {
        const result = await formRef.current.validateForm();
        if (!isEmpty(result)) return;
      }
      const attachments = Object.values(getOr(formRef.current.values, 'ncrDetails.ncrMedia', {})).reduce(
        (acc, curr) => {
          return [...acc, ...curr];
        },
        []
      );
      dispatch(uploadAttachments(attachments, props.token)); // Upload attachments first
      let payload;
      if (isPackerUserEditing) {
        payload = preparePackerUpdateNCRPayload(formRef.current.values, currentNCR, user);
      }
      if (payload) {
        dispatch(
          updateGrinderNCR(
            {
              ...payload,
              submit: !get(formRef.current, 'values.draftSave'),
            },
            props.token,
            changeReqFor
          )
        );
        return;
      }

      dispatch(resetNCRAttachmentUpload());
    }
    setChangeReqFor('');
  };

  useEffect(() => {
    const currentTabIndex = tabs.findIndex(tab => tab.key === startEditTabKey);
    if (currentTabIndex > -1) setActiveTabIndex(currentTabIndex);
  }, [formRef.current?.values?.purchaseOrderLineId, startEditTabKey]);

  const handleTabsChange = index => {
    if (index <= tabs.length - 1 && index >= 0) {
      setActiveTabIndex(index);
      setState(prevState => ({ ...prevState, hasValidatedOnEditTabInitialMount: false }));
    }
  };

  useEffect(() => {
    if (!isCreating && !hasError && hasCreated && !state.selectMode) {
      handleTabsChange(completeSubmissionTabIndex + 1);
    }
  }, [isCreating, hasError, hasCreated, state.selectMode]);

  useEffect(() => {
    if (!isUpdating && !hasError && hasUpdated) {
      handleTabsChange(completeSubmissionTabIndex + 1);
    }
  }, [isUpdating, hasError, hasUpdated]);

  useEffect(() => {
    const handleEditTabInitialMountValidation = async () => {
      if (formRef.current && !state.hasValidatedOnEditTabInitialMount) {
        await formRef.current.validateForm();
        formRef.current.setFieldValue('highlightInputError', false);
        setState(prevState => ({ ...prevState, hasValidatedOnEditTabInitialMount: true }));
      }
    };
    if (isPackerUserEditing) {
      handleEditTabInitialMountValidation();
    } else if (activeTabIndex === completeSubmissionTabIndex && editingExistingNCR) {
      handleEditTabInitialMountValidation();
    }
  }, [activeTabIndex, state.hasValidatedOnEditTabInitialMount]);

  const isAnythingChangedInForm = () => {
    const initValues = {
      ...currentNCR,
      editingExistingNCR,
      ...(editingExistingNCR && {
        isEditing: true,
      }),
      ...(ncrType === Constants.NCR_TYPES.NCR
        ? {
            purchaseOrderLineId: get(currentNCR, 'ncrDetails.purchaseOrderLine.id'),
            nonConformanceType: iff(
              get(currentNCR, 'nonConformanceType.id', null),
              {
                value: get(currentNCR, 'nonConformanceType.id'),
                id: get(currentNCR, 'nonConformanceType.id'),
                name: get(currentNCR, 'nonConformanceType.name'),
                label: get(currentNCR, 'nonConformanceType.name'),
              },
              get(currentNCR, 'nonConformanceSubType.nonConformanceTypeId')
                ? {
                    value: get(currentNCR, 'nonConformanceSubType.nonConformanceTypeId'),
                    id: get(currentNCR, 'nonConformanceSubType.nonConformanceTypeId'),
                    name: get(currentNCR, 'nonConformanceSubType.category'),
                    label: get(currentNCR, 'nonConformanceSubType.category'),
                  }
                : null
            ),
            nonConformanceSubType: get(currentNCR, 'nonConformanceSubType.id')
              ? {
                  value: get(currentNCR, 'nonConformanceSubType.id'),
                  id: get(currentNCR, 'nonConformanceSubType.id'),
                  name: get(currentNCR, 'nonConformanceSubType.name'),
                  label: get(currentNCR, 'nonConformanceSubType.name'),
                }
              : null,
            ncrDetails: {
              ...get(currentNCR, 'ncrDetails', {}),
              ncrMedia: groupBy(getOr(currentNCR, 'ncrDetails.ncrMedia', []), 'type'),
              initialInvestigation: getOr(currentNCR, 'ncrDetails.initialInvestigation', {}),
            },
            claims: get(currentNCR, 'claim.claimEntities', []).reduce(
              (acc, curr) => ({
                ...acc,
                [`claim${curr.claimEntityTypeId}`]: curr,
              }),
              {}
            ),
            highlightInputError: true,
            draftSave: true,
          }
        : {
            ncrType: 'incident',
            purchaseOrderLineIds: [
              {
                purchaseOrderLineId: get(currentNCR, 'purchaseOrderLineId'),
                amountAffected: get(currentNCR, 'quantityAffected', '-'),
                unitOfMeasure: get(currentNCR, 'UnitOfMeasure', ''),
                grinderPoNumber: get(currentNCR, 'purchaseOrder.ginderPo'),
                purchaseOrderId: get(currentNCR, 'purchaseOrder.purchaseOrderId'),
              },
            ],
            purchaseOrderLineId: get(currentNCR, 'ncrDetails.purchaseOrderLine.id'),
            nonConformanceType: iff(
              get(currentNCR, 'nonConformanceType.id', null),
              {
                value: get(currentNCR, 'nonConformanceType.id'),
                id: get(currentNCR, 'nonConformanceType.id'),
                name: get(currentNCR, 'nonConformanceType.name'),
                label: get(currentNCR, 'nonConformanceType.name'),
              },
              get(currentNCR, 'nonConformanceSubType.nonConformanceTypeId')
                ? {
                    value: get(currentNCR, 'nonConformanceSubType.nonConformanceTypeId'),
                    id: get(currentNCR, 'nonConformanceSubType.nonConformanceTypeId'),
                    name: get(currentNCR, 'nonConformanceSubType.category'),
                    label: get(currentNCR, 'nonConformanceSubType.category'),
                  }
                : null
            ),
            nonConformanceSubType: get(currentNCR, 'nonConformanceSubType.id')
              ? {
                  value: get(currentNCR, 'nonConformanceSubType.id'),
                  id: get(currentNCR, 'nonConformanceSubType.id'),
                  name: get(currentNCR, 'nonConformanceSubType.name'),
                  label: get(currentNCR, 'nonConformanceSubType.name'),
                }
              : null,
            ncrDetails: {
              ...get(currentNCR, 'ncrDetails', {}),
              isEntrapment: get(currentNCR, 'isEntrapment', false),
              details: get(currentNCR, 'details', ''),
              ncrMedia: groupBy(getOr(currentNCR, 'incidentMedia', []), 'type'),
            },
            highlightInputError: true,
            draftSave: true,
          }),
    };
    const values = formRef.current?.values;
    if (!initValues || !values) return false;
    initValues.highlightInputError = false;
    values.highlightInputError = false;

    return JSON.stringify(initValues) === JSON.stringify(values);
  };

  return (
    <Box fontWeight="400">
      <Tabs index={activeTabIndex} onChange={handleTabsChange}>
        <HorizontalNavigationBand
          justifyContent="flex-start"
          display={{
            base: 'none',
            lg: 'flex',
          }}
        >
          {!state.selectMode && (ncrType === Constants.NCR_TYPES.NCR || ncrType === Constants.NCR_TYPES.INCIDENT) && (
            <TabList borderBottomWidth={0} marginLeft="54px">
              {tabs.map((tab, index) => {
                const currentActive = activeTabIndex === index;
                const passedCheckpoint = activeTabIndex > index;
                const avatarStyleProps = {
                  backgroundColor: 'transparent',
                  color: '#000000',
                  borderWidth: '1px',
                  borderColor: '#000000',
                };
                if (currentActive) {
                  avatarStyleProps.backgroundColor = 'actionSecondary.default';
                  avatarStyleProps.color = invertColor(get(theme, 'colors.actionSecondary.default', ''), true);
                  avatarStyleProps.borderWidth = '0px';
                }
                if (passedCheckpoint) {
                  avatarStyleProps.borderColor = 'actionSecondary.default';
                  avatarStyleProps.color = 'actionSecondary.default';
                }

                return (
                  <Tab
                    key={tab.label}
                    border="none"
                    _focus={{
                      outline: 'none',
                    }}
                    paddingX="8px"
                    paddingRight={activeTabIndex >= index ? '' : '64px'}
                    pointerEvents="none"
                  >
                    <Flex alignItems="center" opacity={!(currentActive || passedCheckpoint) && '0.45'}>
                      <Avatar
                        height="24px"
                        width="24px"
                        name={!passedCheckpoint && (index + 1).toString()}
                        icon={passedCheckpoint && <CheckIconSVG />}
                        {...avatarStyleProps}
                        fontWeight="400"
                      />
                      <Text fontSize="16px" marginLeft="8px" color="#000000">
                        {tab.label}
                      </Text>
                      {activeTabIndex >= index && index < completeSubmissionTabIndex + 1 && (
                        <Divider width="44px" marginLeft="12px" borderColor="actionSecondary.default" />
                      )}
                    </Flex>
                  </Tab>
                );
              })}
            </TabList>
          )}
        </HorizontalNavigationBand>
        <Box
          maxW={{
            base: '100%',
            lg: '1271px',
          }}
          marginLeft="auto"
          marginRight="auto"
          marginTop="22px"
        >
          {activeTabIndex < tabs.length - 1 && (
            <Heading
              fontSize="16px"
              marginLeft={{
                base: '4px',
                lg: '0',
              }}
              textAlign={{ base: 'center', lg: 'unset' }}
            >
              {editingExistingNCR ? 'Edit' : 'New'} {ncrType}
            </Heading>
          )}

          {state.selectMode && (
            <NewNCRStart
              onUpdate={(field, value) => {
                if (!field.includes('-')) {
                  const PO = state.packerIdInternalPOs[0];
                  PO[field] = value;

                  if (field === 'internalPO') {
                    setGrinderPO(value);
                  }

                  setState(prevState => ({
                    ...prevState,
                    packerIdInternalPOs: [PO],
                  }));
                } else {
                  const [fieldName, index] = field.split('-');
                  const POs = state.packerIdInternalPOs;
                  const PO = POs[index];
                  PO[fieldName] = value;
                  POs[index] = PO;
                  setState(prevState => ({
                    ...prevState,
                    packerIdInternalPOs: POs,
                  }));
                }
              }}
              addPurchaseOrder={addPurchaseOrder}
              handleTypeChange={handleTypeChange}
              onSubmit={(type, purchaseOrder) => {
                setState({
                  ...state,
                  selectMode: false,
                  ncrType: type,
                  purchaseOrder,
                });
              }}
              form={state}
              {...props}
            />
          )}

          {!state.selectMode && (ncrType === Constants.NCR_TYPES.NCR || ncrType === Constants.NCR_TYPES.INCIDENT) && (
            <Formik
              innerRef={formRef}
              validationSchema={
                (activeTabIndex === completeSubmissionTabIndex && isPackerUserEditing && willMakeClaim) ||
                (activeTabIndex === completeSubmissionTabIndex && (isAdminUserEditing || isGrinderUserEditing))
                  ? getAllTabsValidationSchema()
                  : getValidationSchema()
              }
              initialValues={
                editingExistingNCR
                  ? {
                      ...currentNCR,
                      editingExistingNCR,
                      ...(editingExistingNCR && {
                        isEditing: true,
                      }),
                      ...(ncrType === Constants.NCR_TYPES.NCR
                        ? {
                            purchaseOrderLineId: get(currentNCR, 'ncrDetails.purchaseOrderLine.id'),
                            nonConformanceType: iff(
                              get(currentNCR, 'nonConformanceType.id', null),
                              {
                                value: get(currentNCR, 'nonConformanceType.id'),
                                id: get(currentNCR, 'nonConformanceType.id'),
                                name: get(currentNCR, 'nonConformanceType.name'),
                                label: get(currentNCR, 'nonConformanceType.name'),
                              },
                              get(currentNCR, 'nonConformanceSubType.nonConformanceTypeId')
                                ? {
                                    value: get(currentNCR, 'nonConformanceSubType.nonConformanceTypeId'),
                                    id: get(currentNCR, 'nonConformanceSubType.nonConformanceTypeId'),
                                    name: get(currentNCR, 'nonConformanceSubType.category'),
                                    label: get(currentNCR, 'nonConformanceSubType.category'),
                                  }
                                : null
                            ),
                            nonConformanceSubType: get(currentNCR, 'nonConformanceSubType.id')
                              ? {
                                  value: get(currentNCR, 'nonConformanceSubType.id'),
                                  id: get(currentNCR, 'nonConformanceSubType.id'),
                                  name: get(currentNCR, 'nonConformanceSubType.name'),
                                  label: get(currentNCR, 'nonConformanceSubType.name'),
                                }
                              : null,
                            ncrDetails: {
                              ...get(currentNCR, 'ncrDetails', {}),
                              ncrMedia: groupBy(getOr(currentNCR, 'ncrDetails.ncrMedia', []), 'type'),
                              initialInvestigation: getOr(currentNCR, 'ncrDetails.initialInvestigation', {}),
                            },
                            claims: get(currentNCR, 'claim.claimEntities', []).reduce(
                              (acc, curr) => ({
                                ...acc,
                                [`claim${curr.claimEntityTypeId}`]: curr,
                              }),
                              {}
                            ),
                            highlightInputError: true,
                            draftSave: true,
                          }
                        : {
                            ncrType: 'incident',
                            purchaseOrderLineIds: [
                              {
                                purchaseOrderLineId: get(currentNCR, 'purchaseOrderLineId'),
                                amountAffected: get(currentNCR, 'quantityAffected', '-'),
                                unitOfMeasure: get(currentNCR, 'UnitOfMeasure', ''),
                                grinderPoNumber: get(currentNCR, 'purchaseOrder.ginderPo'),
                                purchaseOrderId: get(currentNCR, 'purchaseOrder.purchaseOrderId'),
                              },
                            ],
                            purchaseOrderLineId: get(currentNCR, 'ncrDetails.purchaseOrderLine.id'),
                            nonConformanceType: iff(
                              get(currentNCR, 'nonConformanceType.id', null),
                              {
                                value: get(currentNCR, 'nonConformanceType.id'),
                                id: get(currentNCR, 'nonConformanceType.id'),
                                name: get(currentNCR, 'nonConformanceType.name'),
                                label: get(currentNCR, 'nonConformanceType.name'),
                              },
                              get(currentNCR, 'nonConformanceSubType.nonConformanceTypeId')
                                ? {
                                    value: get(currentNCR, 'nonConformanceSubType.nonConformanceTypeId'),
                                    id: get(currentNCR, 'nonConformanceSubType.nonConformanceTypeId'),
                                    name: get(currentNCR, 'nonConformanceSubType.category'),
                                    label: get(currentNCR, 'nonConformanceSubType.category'),
                                  }
                                : null
                            ),
                            nonConformanceSubType: get(currentNCR, 'nonConformanceSubType.id')
                              ? {
                                  value: get(currentNCR, 'nonConformanceSubType.id'),
                                  id: get(currentNCR, 'nonConformanceSubType.id'),
                                  name: get(currentNCR, 'nonConformanceSubType.name'),
                                  label: get(currentNCR, 'nonConformanceSubType.name'),
                                }
                              : null,
                            ncrDetails: {
                              ...get(currentNCR, 'ncrDetails', {}),
                              isEntrapment: get(currentNCR, 'isEntrapment', false),
                              details: get(currentNCR, 'details', ''),
                              ncrMedia: groupBy(getOr(currentNCR, 'incidentMedia', []), 'type'),
                            },
                            highlightInputError: true,
                            draftSave: true,
                          }),
                    }
                  : {
                      isEditing: true,
                      ncrType: get(state, 'ncrType.key', Constants.NCR_TYPES.NCR),
                      purchaseOrderLineId: null,
                      purchaseOrderLineIds: [],
                      willPotentialClaimValues: [],
                      ncrDetails: {
                        targetClosureDate: moment().add(14, 'days').format(),
                        isIncident: true,
                        isMultiPacker: false,
                        isEntrapment: false,
                        willMakePotentialClaim: false,
                      },
                      claim: {
                        currencyCode: get(state, 'purchaseOrder.sellCurrency', 'USD'),
                      },
                      highlightInputError: true,
                      draftSave: true,
                    }
              }
              onSubmit={() => {
                if (
                  activeTabIndex === 1 &&
                  ncrType !== Constants.NCR_TYPES.INCIDENT &&
                  state.currentNCRDetailsStage < 1 &&
                  !isPackerUserEditing
                ) {
                  setState({
                    ...state,
                    currentNCRDetailsStage: state.currentNCRDetailsStage + 1,
                  });
                  return;
                }
                if (activeTabIndex === completeSubmissionTabIndex) {
                  if (formRef.current) {
                    formRef.current.setFieldValue('draftSave', false);
                  }
                  submitForm(true);
                  return;
                }
                handleTabsChange(activeTabIndex + 1);
              }}
            >
              {({ errors, setFieldValue }) => {
                return (
                  <TabPanels>
                    {tabs.map(tab => (
                      <TabPanel key={tab.key} padding="16px 0">
                        {tab.content}
                      </TabPanel>
                    ))}
                    {activeTabIndex < tabs.length - 1 && (
                      <Flex justifyContent="space-between" marginY="35px">
                        <BackButton
                          onClick={() => {
                            setFieldValue('highlightInputError', true);
                            if (activeTabIndex > 1) {
                              if (ncrType === Constants.NCR_TYPES.INCIDENT) {
                                setState(prevState => ({ ...prevState, currentNCRDetailsStage: 0 }));
                              } else {
                                setState(prevState => ({ ...prevState, currentNCRDetailsStage: 1 }));
                              }
                            }
                            if (isPackerUserEditing) {
                              history.push(`/ncr/${get(currentNCR, 'recordNo', '')}`);
                              return;
                            }
                            if (activeTabIndex === 0) {
                              if (isGrinderUserEditing || isAdminUserEditing) {
                                history.push(
                                  `/${ncrType === Constants.NCR_TYPES.NCR ? 'ncr' : 'incidents'}/${get(
                                    currentNCR,
                                    'recordNo',
                                    ''
                                  )}`
                                );
                                return;
                              }
                              if (changeNCR) {
                                history.push(`/incidents/${changeIncidentId}`);
                                return;
                              }
                              setState({
                                ...state,
                                error: '',
                                selectMode: true,
                              });
                              return;
                            }
                            if (activeTabIndex === 1 && state.currentNCRDetailsStage > 0) {
                              setState({
                                ...state,
                                currentNCRDetailsStage: state.currentNCRDetailsStage - 1,
                              });
                              return;
                            }
                            handleTabsChange(activeTabIndex - 1);
                          }}
                          fontSize="16px"
                          isDisabled={isCreating || isUploading}
                        />
                        <ButtonGroup spacing="22px" colorScheme="actionPrimary" fontSize="16px">
                          <Button
                            variant="outline"
                            height="40px"
                            width="130px"
                            fontWeight="600"
                            fontSize="inherit"
                            isLoading={isUpdating || isCreating || isButtonDisabled}
                            isDisabled={isCreating || isUploading}
                            onClick={() => {
                              submitForm(false);
                            }}
                          >
                            Save
                          </Button>
                          <AdvanceButton
                            onClick={() => {
                              setFieldValue('highlightInputError', true);
                              if (isPackerUserEditing) {
                                if (formRef.current) {
                                  formRef.current.setFieldValue('draftSave', false);
                                }
                                submitForm(true);
                                return;
                              }
                              handleAdvance();
                            }}
                            paddingX="26px"
                            isLoading={isCreating || isUploading || isUpdating || isButtonDisabled}
                            {...(isPackerUserEditing && { rightIcon: null })}
                            isDisabled={
                              isCreating ||
                              isUploading ||
                              isUpdating ||
                              (completeSubmissionTabIndex === activeTabIndex && !isEmpty(errors)) ||
                              (completeSubmissionTabIndex === activeTabIndex && isAnythingChangedInForm()) ||
                              (isPackerUserEditing && !isEmpty(errors))
                            }
                          >
                            {completeSubmissionTabIndex === activeTabIndex || isPackerUserEditing ? 'Submit' : 'Next'}
                          </AdvanceButton>
                        </ButtonGroup>
                      </Flex>
                    )}
                  </TabPanels>
                );
              }}
            </Formik>
          )}

          {!state.selectMode && ncrType === Constants.NCR_TYPES.ENTRAPMENT && (
            <EntrapmentForm
              token={token}
              order={state.purchaseOrder}
              grinderPoNumber={state.packerIdInternalPOs[0].internalPO || grinderPO}
              onSave={() => {
                setState({
                  error: '',
                  selectMode: true,
                  currentNCRDetailsStage: 0,
                  internalPO: null,
                  packerId: null,
                  purchaseOrder: null,
                  packerIdInternalPOs: [{ packerId: null, packerPlantId: null, internalPO: '' }],
                  ncrType: null,
                  hasValidatedOnEditTabInitialMount: false,
                });
              }}
            />
          )}
        </Box>
      </Tabs>
    </Box>
  );
};

NCRForm.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  ncrs: PropTypes.objectOf(PropTypes.any),
  claimEntities: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
  isPackerUserEditing: PropTypes.bool,
  isAdminUserEditing: PropTypes.bool,
  isGrinderUserEditing: PropTypes.bool,
  packerPlants: PropTypes.arrayOf(PropTypes.any),
  nonConformanceTypesOptions: PropTypes.arrayOf(PropTypes.any),
  nonConformanceSubTypesOptions: PropTypes.arrayOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  orderHistory: PropTypes.arrayOf(PropTypes.any),
};

const mapStateToProps = state => {
  const result = {
    ...findEntitiesInState(state),
    ...findEntitiesInState(state, {
      grinders: 'grinder',
      packer_plants: 'packer_plant',
      packers: 'packer',
      input_products: 'input_product',
      cold_stores: 'coldstore',
      transporters: 'transporter',
      claim_entities: 'claim_entity',
      non_conformance_types: 'non_conformance_type',
      non_conformance_sub_types: 'non_conformance_sub_type',
      units_of_measure: 'unit',
    }),
    user: state.user,
    users: state.users,
    token: state.user.token,
    apiRequestError: state.error,
    order_history: state.orders.order_history,
    hasExecutedSearchs: state.orders.hasExecutedSearchs,
    ncrs: state.ncrs,
  };
  const cameled = convertToCamelCase(result);
  return {
    ...cameled,
    packerOptions: cameled.packers.map(packer => ({
      ...packer,
      value: packer.id,
      label: packer.name,
    })),
    unitsOfMeasureOptions: cameled.unitsOfMeasure.map(unitOfMeasure => ({
      ...unitOfMeasure,
      value: unitOfMeasure.id,
      label: unitOfMeasure.name,
    })),
    nonConformanceTypesOptions: cameled.nonConformanceTypes.map(unitOfMeasure => ({
      ...unitOfMeasure,
      value: unitOfMeasure.id,
      label: unitOfMeasure.name,
    })),
    nonConformanceSubTypesOptions: cameled.nonConformanceSubTypes.map(unitOfMeasure => ({
      ...unitOfMeasure,
      value: unitOfMeasure.id,
      label: unitOfMeasure.name,
    })),
  };
};

export default connect(mapStateToProps)(NCRForm);
