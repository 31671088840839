import { connect } from 'react-redux';
import IUnleashClassFlagProvider from '../../components/unleash/UnleashClassFlagProvider';
import FinancePageComponent from '../../components/finance/FinancePage';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';
import Constants from '../../Constants';
import { convertToCamelCase } from '../../helpers';

const mapStateToProps = state => {
  return {
    ...findEntitiesInState(state),
    token: state.user.token || '',
    user: state.user || {},
    users: state.users || null,
    configs: state.configs,
    invoicing: state.invoicing || {
      orderList: [],
      orderPagination: {
        pages: 1,
        has_next: false,
        has_previous: false,
        next_page: null,
        previous_page: null,
        total: 1,
      },
      invoicePDFList: [],
      sentInvoiceList: [],
      pricedOrderList: [],
      pricedCartOrderList: [],
      isLoadingPricedOrders: false,
      isSendingInvoices: false,
    },
    endUsers: state.end_users,
    packageWeights: convertToCamelCase(state.packageWeights),
    notifyingActionGroups: state.notifying_action_groups,
  };
};

const FinancePage = connect(mapStateToProps)(FinancePageComponent);

const FinancePageUnleashConntroller = (props) => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.INVOICING}
        show={<FinancePage {...props} />}
        hide={<h1>Feature Not Enabled</h1>}
      />
    </>
  );
};

export default FinancePageUnleashConntroller;
