import api from '../api';
import Constants from '../Constants';

export const FINANCE_FACTORINGS_FETCHED = 'FINANCE_FACTORINGS_FETCHED';
export const FINANCE_FACTORING_ADDED = 'FINANCE_FACTORING_ADDED';
export const FINANCE_FACTORING_UPDATED = 'FINANCE_FACTORING_UPDATED';
export const FINANCE_FACTORING_REMOVED = 'FINANCE_FACTORING_REMOVED';
export const FINANCE_FACTORING_ERROR = 'FINANCE_FACTORING_ERROR';

const basePath = '/finance_factorings';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export const loadFinanceFactorings = FinanceFactorings => {
  return {
    type: FINANCE_FACTORINGS_FETCHED,
    payload: FinanceFactorings,
  };
};

export const addFinanceFactoring = FinanceFactoring => {
  return {
    type: FINANCE_FACTORING_ADDED,
    payload: FinanceFactoring,
  };
};

export const removeFinanceFactoring = FinanceFactoring => {
  return {
    type: FINANCE_FACTORING_REMOVED,
    payload: FinanceFactoring,
  };
};

export const updateFinanceFactoring = FinanceFactoring => {
  return {
    type: FINANCE_FACTORING_UPDATED,
    payload: FinanceFactoring,
  };
};

export const errorFinanceFactorings = error => {
  return {
    type: FINANCE_FACTORING_ERROR,
    payload: error,
  };
};

/* ---------------------------------
    THUNKS
-----------------------------------*/
export const fetchFinanceFactorings = () => dispatch => {
  return api
    .get(basePath)
    .then(response => Constants.handleErrors(response, dispatch, errorFinanceFactorings))
    .then(json => dispatch(loadFinanceFactorings(json)))
    .catch(error => dispatch(errorFinanceFactorings(error)));
};

export const createFinanceFactoring = payload => dispatch => {
  return api
    .post(basePath, payload)
    .then(response => Constants.handleErrors(response, dispatch, errorFinanceFactorings))
    .then(json => dispatch(addFinanceFactoring(json)))
    .catch(error => dispatch(errorFinanceFactorings(error)));
};

export const patchFinanceFactoring = FinanceFactoring => dispatch => {
  const url = `${basePath}/${FinanceFactoring.id}`;
  const { id, grinder, ...payload } = FinanceFactoring;
  return api
    .patch(url, payload)
    .then(response => Constants.handleErrors(response, dispatch, errorFinanceFactorings))
    .then(json => dispatch(updateFinanceFactoring(json)))
    .catch(error => dispatch(errorFinanceFactorings(error)));
};

export const deleteFinanceFactoring = FinanceFactoring => dispatch => {
  const url = `${basePath}/${FinanceFactoring.id}`;
  return api
    .delete(url)
    .then(response => Constants.handleErrors(response, dispatch, errorFinanceFactorings))
    .then(() => dispatch(removeFinanceFactoring(FinanceFactoring)))
    .catch(error => dispatch(errorFinanceFactorings(error)));
};
