import Icon from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import * as React from 'react';
import { formatDollars, formatPercent, formatSI } from '../../../../modules/format';
import { ChevronRight } from '../../../../shared/Icons';
import styles from './NCRs.module.css';
import { WEIGHT_OPTIONS } from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsConfig';

const Summary = props => {
  const { ncrsData, categoryColors, packerPlantsInPacker, market } = props;
  const data = ncrsData.summary;

  return (
    <div style={{ marginTop: 20 }}>
      <Typography.Text className={styles.QualityNcrs_incidentsPercent}>
        Summary of the <span>last 12 months</span> {packerPlantsInPacker ? 'by establishment' : 'by category'}
      </Typography.Text>

      <SummaryBlock
        data={data.incidents}
        packerPlantsInPacker={packerPlantsInPacker}
        categoryColors={categoryColors}
        title={'count of incidents'}
        label={
          <span>
            <strong>{data.incidents.count}</strong> incidents
          </span>
        }
      />

      <SummaryBlock
        data={data.volume}
        packerPlantsInPacker={packerPlantsInPacker}
        categoryColors={categoryColors}
        title={'volume supplied'}
        label={
          <span>
            <strong>{formatSI(2)(data?.volume?.count)}</strong> {WEIGHT_OPTIONS[market.key]} (
            <strong>{formatPercent(2)(data?.volume?.percentageOfTotal)}</strong> total volume)
          </span>
        }
      />
      <SummaryBlock
        data={data.dollars}
        packerPlantsInPacker={packerPlantsInPacker}
        categoryColors={categoryColors}
        title={'claim amount'}
        label={
          <span>
            <strong>{formatDollars(2)(data?.dollars?.count)}</strong> in claim dollars
          </span>
        }
      />
    </div>
  );
};

const SummaryBlock = props => {
  const { data, title, label, packerPlantsInPacker, categoryColors } = props;

  return (
    <div className={styles.QualityNcrs_summaryBlock}>
      <div className={styles.QualityNcrs_summaryTitle}>
        {/* <a> */}
        <Typography.Text className={styles.QualityNcrs_label}>{title}</Typography.Text>
        {/* <Icon style={{ position: 'relative', width: 14 }} component={ChevronRight} /> */}
        {/* </a> */}
      </div>
      <div
        style={{
          display: 'flex',
          width: `100%`,
        }}
      >
        {packerPlantsInPacker && data.byPackerPlant.length > 0
          ? data.byPackerPlant
              .slice()
              .sort((a, b) => b.count - a.count)
              .map(p => (
                <Tooltip key={`summary-${p.packerPlantId}`} title={`${p.packerPlantName} - ${p.count}`}>
                  <div
                    style={{
                      width: `${data.count === 0 ? 0 : (p.count * 100) / data.count}%`,
                      height: 10,
                      backgroundColor: packerPlantsInPacker.find(pac => pac.packerPlantId === p.packerPlantId)?.color,
                      backgroundImage:
                        'repeating-linear-gradient(-45deg, transparent, transparent 2px, rgba(255,255,255,.5) 2px, rgba(255,255,255,.5) 4px)',
                      marginRight: 2,
                    }}
                  />
                </Tooltip>
              ))
          : data?.byCategory &&
            data.byCategory
              .slice()
              .sort((a, b) => b.count - a.count)
              .map(c => (
                <Tooltip key={`summary-${c.category}`} title={`${c.category} - ${Math.round(c.count)}`}>
                  <div
                    style={{
                      width: `${data.count === 0 ? 0 : (c.count * 100) / data.count}%`,
                      height: 10,
                      backgroundColor: categoryColors[c.category],
                      marginRight: 2,
                    }}
                  />
                </Tooltip>
              ))}
      </div>
      <p style={{ marginTop: 6 }}>{label}</p>
    </div>
  );
};

export default Summary;
