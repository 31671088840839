import Constants from '../Constants';

import {
  releasePDFEmailSent,
  replaceOrderPendingColdstoreRelease,
  removeOrderPendingColdstoreRelease,
} from './actions_orders_pending_coldstore_release';
import {
  replaceOrderPendingColdstoreSchedule,
  removeOrderPendingColdstoreSchedule,
} from './actions_orders_pending_coldstore_schedule';

import { replaceOrderColdstoreReleased, removeOrderColdstoreReleased } from './actions_orders_coldstore_released';
import { replaceOrderColdstore } from './actions_orders_coldstore';

export const COLD_STORE_SEND_EMAIL_ERROR = 'COLD_STORE_SEND_EMAIL_ERROR';
export const ORDER_COLDSTORE_ERROR = 'ORDER_COLDSTORE_ERROR';
export const RELEASE_PDF_EMAIL_SENT = 'RELEASE_PDF_EMAIL_SENT';
export const TRIGGER_COLDSTORE_EMAIL_SENT_STATUS = 'TRIGGER_COLDSTORE_EMAIL_SENT_STATUS';
export const PREVIEW_PDF = 'PREVIEW_PDF';
export const RESET_COLD_STORE_MODAL = 'RESET_COLD_STORE_MODAL';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function coldstoreSendEmailError(error) {
  return {
    type: COLD_STORE_SEND_EMAIL_ERROR,
    payload: error,
  };
}

export function errorOrderColdstore(error) {
  return {
    type: ORDER_COLDSTORE_ERROR,
    payload: error,
  };
}

export function resetColdStoreEmailModal() {
  return {
    type: RESET_COLD_STORE_MODAL,
  };
}

export function previewPDF(data) {
  return {
    type: PREVIEW_PDF,
    payload: data,
  };
}

export function triggerColdstoreEmailSentStatus(data) {
  return {
    type: TRIGGER_COLDSTORE_EMAIL_SENT_STATUS,
    payload: data,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function getColdStoreReleasePDF(payload, token) {
  return dispatch => {
    const endpointUrl = `${Constants.URL}order_management/purchase_orders/coldstore_release`;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(endpointUrl, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(payload),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrderColdstore))
      .then(json => dispatch(previewPDF(json)))
      .catch(error => dispatch(errorOrderColdstore(error)));
  };
}

export function sendColdStoreEmail(payload, token) {
  return dispatch => {
    const endpointUrl = `${Constants.URL}order_management/purchase_orders/coldstore/mail`;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(endpointUrl, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(payload),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrderColdstore))

      .catch(error => dispatch(coldstoreSendEmailError(error)));
  };
}

export function sendEmailWithReleasePDF(payload, orders, token) {
  return dispatch => {
    const endpointUrl = `${Constants.URL}order_management/purchase_orders/coldstore_release/mail`;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(endpointUrl, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(payload),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrderColdstore))
      .then(json => {
        dispatch(
          releasePDFEmailSent(
            ...orders.map(order => ({
              ...order,
              releaseDocument: json,
              status: Constants.ORDER_STATUSES.COLDSTORE_RELEASED,
            }))
          )
        );
        dispatch(triggerColdstoreEmailSentStatus(true));
      })
      .catch(error => dispatch(coldstoreSendEmailError(error)));
  };
}

export function bulkOrders(orders, status, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/bulk`, {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(orders),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrderColdstore))
      .then(jsons => {
        let replaceOrderFn = null;
        if (status === Constants.ORDER_STATUSES.PENDING_COLDSTORE_SCHEDULE) {
          replaceOrderFn = replaceOrderPendingColdstoreSchedule;
        }
        if (status === Constants.ORDER_STATUSES.PENDING_COLDSTORE_RELEASE) {
          replaceOrderFn = replaceOrderPendingColdstoreRelease;
        }
        if (status === Constants.ORDER_STATUSES.COLDSTORE_RELEASED) {
          replaceOrderFn = replaceOrderColdstoreReleased;
        }
        jsons.forEach(json => {
          dispatch(replaceOrderFn(json));
        });
      })
      .catch(error => dispatch(errorOrderColdstore(error)));
  };
}

// TODO, use PUT updateOrder for both chicago and syndey form
export function saveOrder(payload, orderId, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${orderId}`, {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(payload),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrderColdstore))
      .then(json => {
        const modifiedOrder = JSON.parse(JSON.stringify(json));
        const { status } = modifiedOrder;
        modifiedOrder.orderUpdated = true;
        let replaceOrderFn = null;

        if (status === Constants.ORDER_STATUSES.COLDSTORE) {
          replaceOrderFn = replaceOrderColdstore;
        }

        if (status === Constants.ORDER_STATUSES.PENDING_COLDSTORE_SCHEDULE) {
          replaceOrderFn = replaceOrderPendingColdstoreSchedule;
        }
        if (status === Constants.ORDER_STATUSES.PENDING_COLDSTORE_RELEASE) {
          replaceOrderFn = replaceOrderPendingColdstoreRelease;
        }
        if (status === Constants.ORDER_STATUSES.COLDSTORE_RELEASED) {
          replaceOrderFn = replaceOrderColdstoreReleased;
        }
        dispatch(replaceOrderFn(modifiedOrder));
      })
      .catch(error => dispatch(errorOrderColdstore(error)));
  };
}

export function removeOrderFromScreen(currentOrder, newOrderStatus) {
  return dispatch => {
    const { status } = currentOrder;
    if (newOrderStatus === Constants.ORDER_STATUSES.COLDSTORE) {
      if (status === Constants.ORDER_STATUSES.COLDSTORE_RELEASED) {
        dispatch(removeOrderColdstoreReleased(currentOrder));
      }
      if (status === Constants.ORDER_STATUSES.PENDING_COLDSTORE_SCHEDULE) {
        dispatch(removeOrderPendingColdstoreSchedule(currentOrder));
      }
    }

    if (newOrderStatus === Constants.ORDER_STATUSES.PENDING_COLDSTORE_SCHEDULE) {
      dispatch(removeOrderPendingColdstoreRelease(currentOrder));
    }
    if (newOrderStatus === Constants.ORDER_STATUSES.PENDING_COLDSTORE_RELEASE) {
      dispatch(removeOrderPendingColdstoreSchedule(currentOrder));
    }
    if (newOrderStatus === Constants.ORDER_STATUSES.COLDSTORE_RELEASED) {
      dispatch(removeOrderPendingColdstoreRelease(currentOrder));
    }
  };
}
