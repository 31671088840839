import { GRINDER_ADDED, GRINDER_EDIT, GRINDER_REMOVED, GRINDERS_FETCHED } from '../actions/actions_grinders';
import { removeObjectFromArray, replaceObjectInArray } from '../helpers';

const grinders = (grinders = [], action) => {
  const newGrinders = JSON.parse(JSON.stringify(grinders));

  switch (action.type) {
    case GRINDERS_FETCHED:
      return action.payload;
    case GRINDER_ADDED:
      newGrinders.push(action.payload);
      return newGrinders;
    case GRINDER_EDIT:
      return replaceObjectInArray(newGrinders, action.payload);
    case GRINDER_REMOVED:
      return removeObjectFromArray(newGrinders, action.payload);
    default:
      return grinders;
  }
};

export default grinders;
