import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import {
  Box,
  Tag,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  ButtonGroup,
  Switch,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  Text,
  Center,
} from '@chakra-ui/react';
import moment from 'moment';
import { removeTheme, retrieveThemes, setActiveTheme } from '../../actions/actions_themes';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { previewTheme, resetThemeLoadedFlag } from '../../actions/actions_theme';

const AvailableThemes = ({ theme, themes }) => {
  const dispatch = useDispatch();
  const [openPopover, setOpenPopover] = useState({
    active: null,
    delete: null,
  });

  useEffect(() => {
    if (theme.is_loaded) {
      dispatch(retrieveThemes());
    }
  }, [theme.is_loaded]);

  const checkIfIsCurrentlyEdited = id => {
    const themeId = localStorage.getItem('theme_id');
    return id.toString() === themeId;
  };

  const handleActiveSwitch = id => {
    setOpenPopover({ ...openPopover, active: null });
    dispatch(resetThemeLoadedFlag());
    dispatch(setActiveTheme(id));
    dispatch(previewTheme(id));
  };

  const handleDelete = id => {
    setOpenPopover({ ...openPopover, active: null });
    dispatch(resetThemeLoadedFlag());
    dispatch(removeTheme(id));
    dispatch(retrieveThemes());
  };

  const handlePreviewSelect = id => {
    dispatch(resetThemeLoadedFlag());
    dispatch(previewTheme(id));
  };

  return (
    <Box>
      <TableContainer>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Last modified</Th>
              <Th>Is active</Th>
              <Th />
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {themes.map(availableTheme => (
              <Tr key={availableTheme.id}>
                <Td>{availableTheme.name}</Td>
                <Td>{moment(availableTheme.modified).format('YYYY-MM-DD hh:mm a')}</Td>
                <Td>
                  <Tag colorScheme={availableTheme.is_active ? 'green' : 'yellow'}>
                    {availableTheme.is_active ? 'active' : 'inactive'}
                  </Tag>
                </Td>
                <Td>
                  <Popover isOpen={openPopover.active === availableTheme.id} placement="right">
                    <PopoverTrigger>
                      <Switch
                        colorScheme="actionPrimary"
                        size="sm"
                        isChecked={availableTheme.is_active}
                        onChange={() => setOpenPopover({ ...openPopover, active: availableTheme.id })}
                        isDisabled={availableTheme.is_active}
                      />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton onClick={() => setOpenPopover({ ...openPopover, active: null })} />
                      <PopoverBody height="60px">
                        <Center height="100%">
                          <Text as="p">
                            Set <strong>{availableTheme.name}</strong> as active theme?
                          </Text>
                        </Center>
                      </PopoverBody>
                      <PopoverFooter>
                        <ButtonGroup size="sm" width="100%">
                          <Button
                            variant="outline"
                            colorScheme="actionPrimary"
                            width="60px"
                            padding={0}
                            onClick={() => setOpenPopover({ ...openPopover, active: null })}
                          >
                            Cancel
                          </Button>
                          <Button
                            colorScheme="actionPrimary"
                            width="60px"
                            padding={0}
                            marginLeft="auto !important"
                            onClick={() => handleActiveSwitch(availableTheme.id)}
                          >
                            Confirm
                          </Button>
                        </ButtonGroup>
                      </PopoverFooter>
                    </PopoverContent>
                  </Popover>
                </Td>
                <Td>
                  <IconButton
                    colorScheme="actionPrimary"
                    size="sm"
                    onClick={() => handlePreviewSelect(availableTheme.id)}
                    icon={<EditIcon />}
                    isDisabled={checkIfIsCurrentlyEdited(availableTheme.id)}
                  />
                </Td>

                <Td>
                  <Popover isOpen={openPopover.delete === availableTheme.id} placement="right">
                    <PopoverTrigger>
                      <IconButton
                        colorScheme="actionPrimary"
                        size="sm"
                        onClick={() => setOpenPopover({ ...openPopover, delete: availableTheme.id })}
                        isDisabled={availableTheme.is_active}
                        icon={<DeleteIcon />}
                      />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton onClick={() => setOpenPopover({ ...openPopover, delete: null })} />
                      <PopoverBody height="60px">
                        <Center height="100%">
                          <Text as="p">
                            Delete <strong>{availableTheme.name}</strong> theme?
                          </Text>
                        </Center>
                      </PopoverBody>
                      <PopoverFooter>
                        <ButtonGroup size="sm" width="100%">
                          <Button
                            variant="outline"
                            colorScheme="actionPrimary"
                            width="60px"
                            padding={0}
                            onClick={() => setOpenPopover({ ...openPopover, delete: null })}
                          >
                            Cancel
                          </Button>
                          <Button
                            colorScheme="actionPrimary"
                            width="60px"
                            padding={0}
                            marginLeft="auto !important"
                            onClick={() => handleDelete(availableTheme.id)}
                          >
                            Confirm
                          </Button>
                        </ButtonGroup>
                      </PopoverFooter>
                    </PopoverContent>
                  </Popover>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

AvailableThemes.propTypes = {
  theme: PropTypes.objectOf(PropTypes.object).isRequired,
  themes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)).isRequired,
};

const mapStateToProps = state => {
  return {
    theme: state.theme,
    themes: state.themes,
  };
};

export default connect(mapStateToProps)(AvailableThemes);
