import { createSelector } from '@reduxjs/toolkit';
import { selectPackerPlants } from '../../quality-packer-plants/selectors/selectBasicPackerPlants';
import { selectQualityData } from './selectBasicQualityData';

export const selectQualityAuditSinglePackerPlant = createSelector(
  selectQualityData,
  selectPackerPlants,
  (data, packerPlants) => {
    if (!data || !packerPlants) return null;

    const audit = data.auditSinglePackerPlant;

    return audit;
  }
);
