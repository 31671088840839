import { NAV_BAR_INTERACTED, NAV_OPEN_STAGES } from '../actions/actions_navigation';

const initialState = {
  openStatus: NAV_OPEN_STAGES.closed,
};

const navigation = (state = initialState, action) => {
  switch (action.type) {
    case NAV_BAR_INTERACTED:
      return {
        ...state,
        openStatus: action.payload,
      };
    default:
      return initialState;
  }
};

export default navigation;
