import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import Constants from '../../Constants';

export const articleRegionSlice = createSlice({
  name: 'articleRegion',
  initialState: { regions: [] },
  reducers: {
    setArticleRegions: (state, action) => {
      state.regions = action.payload;
    },
  },
});

// Actions.
export const { setArticleRegions } = articleRegionSlice.actions;

export const fetchArticleRegions = () => async dispatch => {
  const response = await api.get('/article_regions');
  const json = await Constants.handleErrors(response, dispatch);
  dispatch(setArticleRegions([...json]));
  return json;
};

// Selectors.
export const selectArticleRegions = state => {
  return state.articleRegion.regions;
};

// Reducers.
export default articleRegionSlice.reducer;
