import isEmpty from 'lodash/isEmpty';

import api from '../api';
import Constants from '../Constants';

export const FDR_RATES_FETCHED = 'FDR_RATES_FETCHED';
export const FDR_RATE_ADDED = 'FDR_RATE_ADDED';
export const FDR_RATE_UPDATED = 'FDR_RATE_UPDATED';
export const FDR_RATE_REMOVED = 'FDR_RATE_REMOVED';
export const FDR_RATE_ERROR = 'FDR_RATE_ERROR';

const basePath = '/fdr_rates';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export const loadFDRRates = FDRRates => {
  return {
    type: FDR_RATES_FETCHED,
    payload: FDRRates,
  };
};

export const addFDRRate = FDRRate => {
  return {
    type: FDR_RATE_ADDED,
    payload: FDRRate,
  };
};

export const removeFDRRate = FDRRate => {
  return {
    type: FDR_RATE_REMOVED,
    payload: FDRRate,
  };
};

export const updateFDRRate = FDRRate => {
  return {
    type: FDR_RATE_UPDATED,
    payload: FDRRate,
  };
};

export const errorFDRRates = error => {
  return {
    type: FDR_RATE_ERROR,
    payload: error,
  };
};

/* ---------------------------------
    THUNKS
-----------------------------------*/
export const fetchFDRRates = () => dispatch => {
  return api
    .get(basePath)
    .then(response => Constants.handleErrors(response, dispatch, errorFDRRates))
    .then(json => dispatch(loadFDRRates(json)))
    .catch(error => dispatch(errorFDRRates(error)));
};

export const createFDRRate = payload => dispatch => {
  return api
    .post(basePath, payload)
    .then(response => Constants.handleErrors(response, dispatch, errorFDRRates))
    .then(json => dispatch(addFDRRate(json)))
    .catch(error => dispatch(errorFDRRates(error)));
};

export const patchFDRRate = FDRRate => dispatch => {
  const url = `${basePath}/${FDRRate.id}`;
  const { id, unit_of_measure: uom, grinder, fdr_item: fdr, buy_incoterms: buyIncoterms, ...payload } = FDRRate;
  if (!isEmpty(buyIncoterms)) {
    payload.buy_incoterms = buyIncoterms.map(inco => inco.value);
  }
  return api
    .patch(url, payload)
    .then(response => Constants.handleErrors(response, dispatch, errorFDRRates))
    .then(json => dispatch(updateFDRRate(json)))
    .catch(error => dispatch(errorFDRRates(error)));
};

export const deleteFDRRate = FDRRate => dispatch => {
  const url = `${basePath}/${FDRRate.id}`;
  return api
    .delete(url)
    .catch(response => Constants.handleErrors(response, dispatch, errorFDRRates))
    .then(() => dispatch(removeFDRRate(FDRRate)))
    .catch(error => dispatch(errorFDRRates(error)));
};
