import queryString from 'query-string';
import api from '../api';
import Constants from '../Constants';

export const types = {
  LIVE_CL_PRICES_REQUESTED: 'LIVE_CL_PRICES_REQUESTED',
  LIVE_CL_PRICES_REQUEST_SUCCESS: 'LIVE_CL_PRICES_REQUEST_SUCCESS',
  LIVE_CL_PRICES_REQUEST_FAILURE: 'LIVE_CL_PRICES_REQUEST_FAILURE',

  RECENT_PO_ACTIVITY_REQUESTED: 'RECENT_PO_ACTIVITY_REQUESTED',
  RECENT_PO_ACTIVITY_REQUEST_SUCCESS: 'RECENT_PO_ACTIVITY_REQUEST_SUCCESS',
  RECENT_PO_ACTIVITY_REQUEST_FAILURE: 'RECENT_PO_ACTIVITY_REQUEST_FAILURE',

  RECENT_PO_ACTIVITY_FILTER_SUCCESS:  'RECENT_PO_ACTIVITY_FILTER_SUCCESS',
  RECENT_PO_ACTIVITY_FILTER_FAILURE: 'RECENT_PO_ACTIVITY_FILTER_SUCCESS',

  BUY_VOLUMES_REQUESTED: 'BUY_VOLUMES_REQUESTED',
  BUY_VOLUMES_REQUEST_SUCCESS: 'BUY_VOLUMES_REQUEST_SUCCESS',
  BUY_VOLUMES_REQUEST_FAILURE: 'BUY_VOLUMES_REQUEST_FAILURE',
};

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export const actions = {
  requestLiveClPrices: ({ days }) => {
    return {
      type: types.LIVE_CL_PRICES_REQUESTED,
      payload: {
        days,
      },
    };
  },
  liveClPricesRequestSuccess: liveCLs => {
    return {
      type: types.LIVE_CL_PRICES_REQUEST_SUCCESS,
      payload: liveCLs,
    };
  },
  liveClPricesRequestFailure: error => {
    return {
      type: types.LIVE_CL_PRICES_REQUEST_FAILURE,
      payload: error,
    };
  },
  requestRecentPOActivity: ({ days }) => {
    return {
      type: types.RECENT_PO_ACTIVITY_REQUESTED,
      payload: {
        days,
      },
    };
  },
  recentPOActivityRequestSuccess: recentPOs => {
    return {
      type: types.RECENT_PO_ACTIVITY_REQUEST_SUCCESS,
      payload: recentPOs,
    };
  },
  recentPOActivityRequestFailure: error => {
    return {
      type: types.RECENT_PO_ACTIVITY_REQUEST_FAILURE,
      payload: error,
    };
  },
  recentPOActivityFilterSuccess: recentPOs => {
    return {
      type: types.RECENT_PO_ACTIVITY_FILTER_SUCCESS,
      payload: recentPOs,
    };
  },
  recentPOActivityFilterFailure: error => {
    return {
      type: types.RECENT_PO_ACTIVITY_FILTER_FAILURE,
      payload: error,
    };
  },
  requestBuyVolumes: () => {
    return {
      type: types.BUY_VOLUMES_REQUESTED,
    };
  },
  buyVolumesRequestSuccess: buyVolumes => {
    return {
      type: types.BUY_VOLUMES_REQUEST_SUCCESS,
      payload: buyVolumes,
    };
  },
  buyVolumesRequestFailure: error => {
    return {
      type: types.BUY_VOLUMES_REQUEST_FAILURE,
      payload: error,
    };
  },
};

/* ---------------------------------
    THUNKS
-----------------------------------*/
export const getLiveCLs = ({ days, toCountry, fromCountry, productType, currency, unitOfMeasure, queryType='cl' }) => {
  return dispatch => {
    dispatch(actions.requestLiveClPrices({ days, toCountry, fromCountry, productType, currency, unitOfMeasure }));
    return api
      .get(`/buy_activity/live_cl_prices`, {
        params: { days, toCountry, fromCountry, productType, currency, unitOfMeasure, queryType },
      })
      .then(response => Constants.handleErrors(response, dispatch, actions.liveClPricesRequestFailure))
      .then(json => dispatch(actions.liveClPricesRequestSuccess(json)))
      .catch(error => dispatch(actions.liveClPricesRequestFailure(error)));
  };
};

export const getRecentPOActivity = ({ buyer, cl, offset, limit, clearOld, productType, fresh_or_frozen, grinderId, packerPlantId, queryType, productId, showOnly }) => {
  const params = {
    ...(!showOnly && {offset,limit}),
    ...(!showOnly && productId && {productId}),
    queryType,
    ...(!showOnly && buyer && { buyer }),
    ...(!showOnly && cl && { cl }),
    ...(!showOnly && productType && { productType }),
    ...(!showOnly && fresh_or_frozen && { fresh_or_frozen }),
    ...(!showOnly && grinderId && { grinderId }),
    ...(!showOnly && packerPlantId && { packerPlantId }),
    ...(showOnly && {showOnly})
  };
  return dispatch => {
    (!showOnly && dispatch(actions.requestRecentPOActivity({ buyer, cl, offset, limit, clearOld, productType })));
    return api
      .get(`/buy_activity/recent_po_activity?${queryString.stringify(params)}`)
      .then(response => Constants.handleErrors(response, dispatch, actions.recentPOActivityRequestFailure))
      .then(json => {
        if(!showOnly){
          dispatch(actions.recentPOActivityRequestSuccess(json))
        }else{
          dispatch(actions.recentPOActivityFilterSuccess(json))
        }
      })
      .catch(error => {
        if(!showOnly){
          dispatch(actions.recentPOActivityRequestFailure(error))
        }else{
          dispatch(actions.recentPOActivityFilterFailure(error))
        }
      });
  };
};

export const getBuyVolumes = ({ by, days, displayCurrency, displayUnitOfMeasure }) => {
  return dispatch => {
    dispatch(actions.requestBuyVolumes());
    return api
      .get(`/buy_activity/buy_volumes`, { params: { by, days, displayCurrency, displayUnitOfMeasure } })
      .then(response => Constants.handleErrors(response, dispatch, actions.buyVolumesRequestFailure))
      .then(json => dispatch(actions.buyVolumesRequestSuccess(json)))
      .catch(error => dispatch(actions.buyVolumesRequestFailure(error)));
  };
};
