import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';
import DatetimeRangePicker from 'react-datetime-range-picker';
import { EditIcon, AttachmentIcon } from '@chakra-ui/icons';
// Local deps
import {
  bulkOrders,
  fetchNetsuitePurchaseOrder,
  fetchOrderHistory,
  resetModalOrder,
  submitOrder,
} from '../../../actions/actions_orders';
import EditInternationalOrder from '../../../containers/orders/EditInternationalOrder';
import { commify, formatMonetaryValue } from '../../../functions';
import { findForeignItem, convertToCamelCase } from '../../../helpers';
import { fetchEntitiesData } from '../../../slices/masterData/entityManagerSlice';
import { checkIfChicagoForm } from '../../lib/PurchaseOrderFunctions';
import OrderTableModal from '../order-table-modal/OrderTableModal';
import UploadDocumentModal from '../UploadDocumentModal';
import './OrderTable.scss';
import { Box, Button, ButtonGroup, Checkbox, Text } from '@chakra-ui/react';
import { isArray } from 'lodash';
import { fetchEndUsers } from '../../../actions/actions_end_user';
import { getInternalPoLabel } from '../../../utils';
import { connect } from 'react-redux';
import StatusTag from '../order-form/StatusTag';
import Tooltip from '../../quality/_components/Tooltip';
import { MdLockOutline } from 'react-icons/md';

function getPosition(element) {
  let xPosition = 0;
  let yPosition = 0;

  while (element) {
    xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
    yPosition += element.offsetTop - element.scrollTop + element.clientTop;
    element = element.offsetParent;
  }

  return { x: xPosition, y: yPosition };
}

class OrderTable extends Component {
  constructor(props) {
    super(props);

    this.fixedHeaderTable = element => {
      this.headerTable = element;
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      addDocumentOpened: false,
      date_range: [moment().subtract(2, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      fixedHeader: false,
      headerWidth: 0,
      isChicagoForm: null,
      selectedOrders: [],
      sortTable: false,
      upload_doc_order: false,
    };
  }

  static propTypes = {
    current_order: PropTypes.instanceOf(Object),
    dispatch: PropTypes.func,
    grinders: PropTypes.arrayOf(PropTypes.object),
    all_grinders: PropTypes.arrayOf(PropTypes.object),
    all_input_products: PropTypes.arrayOf(PropTypes.object),
    packer_plants: PropTypes.arrayOf(PropTypes.object),
    all_packer_plants: PropTypes.arrayOf(PropTypes.object),
    packers: PropTypes.arrayOf(PropTypes.object),
    setLoading: PropTypes.func,
    token: PropTypes.string,
    endUsers: PropTypes.arrayOf(PropTypes.object),
    configs: PropTypes.arrayOf(PropTypes.object),
  };

  getQueryParam(param, defaultValue = null) {
    return new URLSearchParams(location.search).get(param) || defaultValue;
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
    if (this.props.endUsers.length === 0) {
      this.props.dispatch(fetchEndUsers(this.props.token));
    }
    if (location.search) {
      const autoOpenPoCardPurchaseOrderId = this.getQueryParam('auto_open_po_card_purchase_order_id');
      if (autoOpenPoCardPurchaseOrderId) {
        this.editOrder(autoOpenPoCardPurchaseOrderId);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { current_order: currentOrder } = this.props;

    if (currentOrder && currentOrder.id !== get(prevProps, 'current_order.id')) {
      const isChicagoForm = checkIfChicagoForm({
        purchasingOffice: currentOrder.purchasing_office,
        invoicingOffice: currentOrder.invoicing_office,
      });
      this.setState({
        isChicagoForm,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  sortTable = (a, b) => {
    const { packer_plants: packerPlants, grinders, all_input_products: inputProducts } = this.props;
    const { sortTable } = this.state;
    // If reverse string exists, then sort the other direction
    let thingA = false;
    let thingB = false;
    if (sortTable.indexOf('packer') > -1) {
      packerPlants.forEach(pp => {
        if (pp.id === a.packer_plant_id) {
          thingA = pp;
        }
        if (pp.id === b.packer_plant_id) {
          thingB = pp;
        }
      });
    }
    if (sortTable.indexOf('grinder') > -1) {
      grinders.forEach(grinder => {
        if (grinder.uid === a.grinder_uid) {
          thingA = grinder;
        }
        if (grinder.uid === b.grinder_uid) {
          thingB = grinder;
        }
      });
    }
    if (sortTable.indexOf('input_product') > -1) {
      inputProducts.forEach(ip => {
        if (ip.uid === a.lines[0].input_product_uid) {
          thingA = ip;
        }
        if (ip.uid === b.lines[0].input_product_uid) {
          thingB = ip;
        }
      });
    }
    if (sortTable.indexOf('_reverse') > -1) {
      const table = sortTable.replace('_reverse', '');
      if (table === 'packer' || table === 'grinder' || table === 'input_product') {
        return thingB.name.localeCompare(thingA.name);
      }
      const val1 = a[table] ? a[table] : 0;
      const val2 = b[table] ? b[table] : 0;
      if (!isNaN(Number(val1)) && sortTable.indexOf('date') === -1) {
        return val2 - val1;
      }
      if (sortTable.indexOf('date') > -1) {
        return moment.utc(b[sortTable]).diff(moment.utc(a[sortTable]));
      }
      return val2.localeCompare(val1);
    }
    if (sortTable === 'packer' || sortTable === 'grinder' || sortTable === 'input_product') {
      return thingA.name.localeCompare(thingB.name);
    }
    const val1 = a[sortTable] ? a[sortTable] : 0;
    const val2 = b[sortTable] ? b[sortTable] : 0;
    if (!isNaN(Number(val1)) && sortTable.indexOf('date') === -1) {
      return val1 - val2;
    }
    if (sortTable.indexOf('date') > -1) {
      return moment.utc(a[sortTable]).diff(moment.utc(b[sortTable]));
    }
    return val1.localeCompare(val2);
  };

  handleScroll() {
    if (!this.headerTable) {
      return;
    }

    const positionFromTop = getPosition(this.headerTable).y;
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const totalHeightFromTop = this.headerTable.offsetHeight + positionFromTop;

    if (scrollTop >= positionFromTop && scrollTop <= totalHeightFromTop) {
      this.setState({ fixedHeader: true });
    } else {
      this.setState({
        fixedHeader: false,
        headerWidth: this.headerTable.offsetWidth,
      });
    }
  }

  selectCurrentModalDetail = () => {
    const { dispatch } = this.props;
    dispatch(resetModalOrder());
  };

  editOrder = purchaseOrderID => {
    const { dispatch, token } = this.props;
    dispatch(fetchNetsuitePurchaseOrder(purchaseOrderID, token));
    dispatch(fetchEntitiesData());
  };

  getOrderHistory() {
    const { dispatch, setLoading, token } = this.props;
    const { date_range: dateRange } = this.state;
    dispatch(fetchOrderHistory(dateRange[0], dateRange[1], token));
    setLoading();
  }

  submitOrder(order) {
    const { dispatch, token } = this.props;
    const currentOrder = JSON.parse(JSON.stringify(order));
    currentOrder.status = 'ordered';
    dispatch(submitOrder(currentOrder, true, token));
    dispatch(resetModalOrder());
  }

  selectOrder(order, e) {
    const { selectedOrders } = this.state;
    let newSelectedOrders = [];

    if (e.target.checked) {
      newSelectedOrders = JSON.parse(JSON.stringify(selectedOrders));
      newSelectedOrders.push(order);
    } else {
      selectedOrders.forEach(thisOrder => {
        if (thisOrder.uid !== order.uid) {
          newSelectedOrders.push(order);
        }
      });
    }
    this.setState({ selectedOrders: newSelectedOrders });
  }

  submitOrders() {
    const { dispatch, token } = this.props;
    const { selectedOrders } = this.state;
    dispatch(bulkOrders(selectedOrders, 'ordered', token));
    this.setState({ selectedOrders: [] });
  }

  deleteOrders() {
    const { dispatch, token } = this.props;
    const { selectedOrders } = this.state;
    dispatch(bulkOrders(selectedOrders, 'cancelled', token));
    this.setState({ selectedOrders: [] });
  }

  changeAttachmentModalState = order => {
    const { addDocumentOpened } = this.state;
    this.setState({
      addDocumentOpened: !addDocumentOpened,
      upload_doc_order: order,
    });
  };

  render() {
    const { orderStatus } = this.props;
    const OrderStatusLable = orderStatus['orderStatusMap'];
    const {
      addDocumentOpened,
      date_range: dateRange,
      fixedHeader,
      headerWidth,
      isChicagoForm,
      selectedOrders,
      sortTable,
      upload_doc_order: uploadDocOrder,
    } = this.state;
    const {
      current_order: currentOrder,
      current_orders: currentOrders,
      date_select: dateSelect,
      all_grinders: grinders,
      all_input_products: inputProducts,
      order_history: orderHistory,
      all_packer_plants: packerPlants,
      type,
      user,
    } = this.props;

    const dateRangeInputProps = {
      placeholder: 'Date range',
      className: 'form-control m-input',
    };

    const tableData = [...(isArray(currentOrders) ? currentOrders : currentOrders.items)];
    if (sortTable) {
      tableData.sort(this.sortTable);
    }

    const filteredTable = tableData.map(item => ({
      ...convertToCamelCase(item),
      packer_plant: findForeignItem(this.props.packer_plants, item.packer_plant_id),
      grinder: findForeignItem(this.props.grinders, item.grinder_uid),
    }));

    const internalPoLabel = getInternalPoLabel(this.props.configs);
    return (
      <div className="pending-order-page">
        {addDocumentOpened && (
          <UploadDocumentModal
            {...this.props}
            current_order={uploadDocOrder}
            closeModal={this.changeAttachmentModalState}
            autoSave
          />
        )}

        {currentOrder &&
          (isChicagoForm ? (
            <Box>
              {/* TODO -- Should not be passing all props to OrderTableModal,
                Should be explicit */}
              <OrderTableModal selectCurrentModalDetail={this.selectCurrentModalDetail.bind(this)} {...this.props} />
            </Box>
          ) : (
            <EditInternationalOrder onClose={this.selectCurrentModalDetail.bind(this)} />
          ))}
        {filteredTable.length ? (
          <Box className="order-results-table">
            {dateSelect ? (
              <Box className="date-range-search">
                <Text as="p" fontWeight="bold">
                  Date Range
                </Text>
                <Button
                  colorScheme="actionPrimary"
                  borderRadius={2}
                  h={12}
                  onClick={this.getOrderHistory.bind(this)}
                  float="right"
                >
                  <Text as="p" fontSize="md">
                    Search
                  </Text>
                </Button>
                <DatetimeRangePicker
                  id="date_range"
                  pickerClassName="col-sm-6"
                  inputProps={dateRangeInputProps}
                  closeOnSelect
                  startDate={moment(dateRange[0]).toDate()}
                  endDate={moment(dateRange[1]).toDate()}
                  value={dateRange}
                  onChange={e =>
                    this.setState({
                      date_range: [moment(e.start).format('YYYY-MM-DD'), moment(e.end).format('YYYY-MM-DD')],
                    })
                  }
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </Box>
            ) : (
              false
            )}
            <table
              ref={this.fixedHeaderTable}
              style={{ marginTop: '15px' }}
              className="table m-table table-hover m-table--head-separator-danger"
            >
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Status</th>
                  <th>Product Type</th>
                  <th
                    onClick={() =>
                      this.setState({
                        sortTable: sortTable === 'delivery_date' ? 'delivery_date_reverse' : 'delivery_date',
                      })
                    }
                  >
                    Delivery Date
                    <i className="sort-icon fa fa-sort" />
                  </th>
                  <th
                    onClick={() =>
                      this.setState({
                        sortTable:
                          sortTable === 'internal_po_number' ? 'internal_po_number_reverse' : 'internal_po_number',
                      })
                    }
                  >
                    {internalPoLabel}
                    <i className="sort-icon fa fa-sort" />
                  </th>
                  {orderHistory || type === 'history' ? (
                    <th
                      onClick={e => {
                        if (e.target.type !== 'text') {
                          this.setState({
                            sortTable:
                              sortTable === 'grinder_po_number' ? 'grinder_po_number_reverse' : 'grinder_po_number',
                          });
                        }
                      }}
                    >
                      Grinder PO#
                      <i className="sort-icon fa fa-sort" />
                    </th>
                  ) : (
                    false
                  )}
                  <th
                    onClick={() =>
                      this.setState({
                        sortTable: sortTable === 'end_user_id' ? 'end_user_id_reverse' : 'end_user_id',
                      })
                    }
                  >
                    End User
                    <i className="sort-icon fa fa-sort" />
                  </th>

                  <th
                    onClick={() =>
                      this.setState({
                        sortTable: sortTable === 'grinder' ? 'grinder_reverse' : 'grinder',
                      })
                    }
                  >
                    Grinder
                    <i className="sort-icon fa fa-sort" />
                  </th>
                  <th
                    onClick={() =>
                      this.setState({
                        sortTable: sortTable === 'packer' ? 'packer_reverse' : 'packer',
                      })
                    }
                  >
                    Establishment
                    <i className="sort-icon fa fa-sort" />
                  </th>
                  <th
                    onClick={() =>
                      this.setState({
                        sortTable: sortTable === 'input_product' ? 'input_product_reverse' : 'input_product',
                      })
                    }
                  >
                    Product
                    <i className="sort-icon fa fa-sort" />
                  </th>
                  <th
                    onClick={() =>
                      this.setState({
                        sortTable: sortTable === 'quantity' ? 'quantity_reverse' : 'quantity',
                      })
                    }
                  >
                    Quantity
                    <i className="sort-icon fa fa-sort" />
                  </th>
                  {user.organisation.org_type.indexOf('grinder') === -1 ? (
                    <th
                      onClick={() =>
                        this.setState({
                          sortTable: sortTable === 'all_in_cost' ? 'all_in_cost_reverse' : 'all_in_cost',
                        })
                      }
                    >
                      All in Cost
                      <i className="sort-icon fa fa-sort" />
                    </th>
                  ) : (
                    false
                  )}
                  {!orderHistory ? (
                    <th
                      onClick={() => {
                        if (selectedOrders.length === currentOrders.length) {
                          this.setState({ selectedOrders: [] });
                        } else {
                          this.setState({
                            selectedOrders: currentOrders,
                          });
                        }
                      }}
                    >
                      Select Order
                    </th>
                  ) : (
                    false
                  )}
                </tr>
              </thead>
              {fixedHeader ? (
                <thead
                  style={
                    fixedHeader
                      ? {
                          display: 'table',
                          position: 'fixed',
                          top: 0,
                          width: headerWidth,
                        }
                      : {}
                  }
                >
                  <tr>
                    <th style={{ width: '4%' }}>Order</th>
                    <th style={{ width: '4%' }}>Product Type</th>
                    <th style={{ width: '4%' }}>Status</th>
                    <th
                      style={{ width: '4%' }}
                      onClick={() =>
                        this.setState({
                          sortTable: sortTable === 'delivery_date' ? 'delivery_date_reverse' : 'delivery_date',
                        })
                      }
                    >
                      Delivery Date
                      <i className="sort-icon fa fa-sort" />
                    </th>
                    {orderHistory || type === 'history' ? (
                      <th
                        onClick={() =>
                          this.setState({
                            sortTable:
                              sortTable === 'grinder_po_number' ? 'grinder_po_number_reverse' : 'grinder_po_number',
                          })
                        }
                      >
                        Grinder PO#
                        <i className="sort-icon fa fa-sort" />
                      </th>
                    ) : (
                      false
                    )}
                    <th
                      onClick={() =>
                        this.setState({
                          sortTable: sortTable === 'grinder' ? 'grinder_reverse' : 'grinder',
                        })
                      }
                    >
                      Grinder
                      <i className="sort-icon fa fa-sort" />
                    </th>
                    <th
                      onClick={() =>
                        this.setState({
                          sortTable: sortTable === 'packer' ? 'packer_reverse' : 'packer',
                        })
                      }
                    >
                      Packer
                      <i className="sort-icon fa fa-sort" />
                    </th>
                    <th
                      onClick={() =>
                        this.setState({
                          sortTable: sortTable === 'input_product' ? 'input_product_reverse' : 'input_product',
                        })
                      }
                    >
                      Product
                      <i className="sort-icon fa fa-sort" />
                    </th>
                    <th
                      onClick={() =>
                        this.setState({
                          sortTable: sortTable === 'quantity' ? 'quantity_reverse' : 'quantity',
                        })
                      }
                    >
                      Quantity
                      <i className="sort-icon fa fa-sort" />
                    </th>
                    {user.organisation.org_type.indexOf('grinder') === -1 ? (
                      <th
                        onClick={() =>
                          this.setState({
                            sortTable: sortTable === 'all_in_cost' ? 'all_in_cost_reverse' : 'all_in_cost',
                          })
                        }
                      >
                        All in Cost
                        <i className="sort-icon fa fa-sort" />
                      </th>
                    ) : (
                      false
                    )}
                    {orderHistory || type === 'history' ? <th>COA</th> : false}
                    {!orderHistory ? (
                      <th
                        onClick={() => {
                          if (selectedOrders.length === currentOrders.length) {
                            this.setState({ selectedOrders: [] });
                          } else {
                            this.setState({
                              selectedOrders: currentOrders,
                            });
                          }
                        }}
                      >
                        Select Order
                      </th>
                    ) : (
                      false
                    )}
                  </tr>
                </thead>
              ) : (
                false
              )}
              {filteredTable.map((order, i) => {
                return (
                  <tbody key={`table-body-${i}`}>
                    {order.lines.map((lineItem, index) => {
                      let thisPacker = false;
                      packerPlants.forEach(pp => {
                        if (pp.id === order.packerPlantId) {
                          thisPacker = pp;
                        }
                      });

                      let thisInputProduct = false;
                      inputProducts.forEach(ip => {
                        if (ip.uid === lineItem.inputProductUid) {
                          thisInputProduct = ip;
                        }
                      });

                      let thisGrinder = false;
                      grinders.forEach(g => {
                        if (g.uid === order.grinderUid) {
                          thisGrinder = g;
                        }
                      });
                      let rowBackgroundColor = false;
                      let cancelledOrder = false;

                      if (order.status === 'cancelled') {
                        cancelledOrder = true;
                      }

                      if (!lineItem.expectedProductionDate) {
                        rowBackgroundColor = {
                          background: 'rgba(255, 217, 102, 0.5)',
                          // background: '#fffced',
                          borderLeft: '2px solid rgb(255, 217, 102)',
                        };
                      }

                      if (!lineItem.active || order.status === 'cancelled') {
                        rowBackgroundColor = {
                          background: 'rgba(255,0,0,0.2)',
                          // background: '#fff4ee',
                          borderLeft: '2px solid rgba(255,0,0,0.6)',
                        };
                      }
                      let itemIsSelected = false;
                      selectedOrders.forEach(so => {
                        if (order.id === so.id) {
                          itemIsSelected = true;
                        }
                      });
                      const thisEndUser = this.props.endUsers.find(obj => obj.id === order.endUserId);
                      return (
                        <tr
                          key={`table-${order.id}-${lineItem.id}`}
                          style={!rowBackgroundColor ? { borderLeft: '2px solid #dfdfdf' } : rowBackgroundColor}
                        >
                          {index === 0 ? (
                            <td className="req-field actions" rowSpan={order.lines.length}>
                              <Button
                                leftIcon={<EditIcon />}
                                onClick={this.editOrder.bind(this, order.id)}
                                variant="outline"
                              ></Button>
                              <Button
                                leftIcon={<AttachmentIcon />}
                                onClick={() => {
                                  this.changeAttachmentModalState(order);
                                }}
                                variant="outline"
                              ></Button>
                            </td>
                          ) : (
                            false
                          )}
                          <td className={cancelledOrder ? 'cancelled-order' : 'req-field'}>
                            {index > 0 ? null : order.status ? <StatusTag status={order.status} /> : ''}
                          </td>
                          <td className="req-field">{order.productType}</td>
                          <td className={cancelledOrder ? 'cancelled-order' : 'req-field'}>
                            {index > 0 ? null : (
                              <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', position: 'relative' }}>
                                <Box>{order.deliveryDate}</Box>
                                {order.poLocked && (
                                  <Tooltip
                                    content="This purchase order is locked. Changes will not affect the grinder price."
                                    placement="bottom"
                                  >
                                    <MdLockOutline size="18px" color="#1a202c" />
                                  </Tooltip>
                                )}
                              </Box>
                            )}
                          </td>
                          <td className={cancelledOrder ? 'cancelled-order' : 'req-field'}>
                            {index > 0 ? null : order.internalPoNumber}
                          </td>
                          {orderHistory || type === 'history' ? (
                            <td className={cancelledOrder ? 'cancelled-order' : 'req-field'}>
                              {index > 0 ? null : order.grinderPoNumber}
                            </td>
                          ) : (
                            false
                          )}
                          <td className={cancelledOrder ? 'cancelled-order' : 'req-field'}>
                            {index > 0 ? null : thisEndUser?.legal_name}
                          </td>
                          <td className={cancelledOrder ? 'cancelled-order' : 'req-field'}>
                            {index > 0 ? null : thisGrinder.name}
                          </td>
                          <td className={cancelledOrder ? 'cancelled-order' : 'req-field'}>
                            {index > 0 ? null : thisPacker.name}
                          </td>
                          <td>{thisInputProduct.name}</td>
                          <td>{commify(lineItem.buyQuantity)}</td>
                          {user.organisation.org_type.indexOf('grinder') === -1 ? (
                            <td>
                              {index <= 0 && order.allInCost
                                ? formatMonetaryValue(order.sellCurrency || 'USD', order.allInCost.toFixed(2))
                                : formatMonetaryValue(
                                    order.sellCurrency || 'USD',
                                    order.lines
                                      .reduce(
                                        (accumulator, line) => accumulator + line.sellPricePerUnit * line.sellQuantity,
                                        0
                                      )
                                      .toFixed(2)
                                  )}
                            </td>
                          ) : (
                            false
                          )}

                          {index === 0 && !orderHistory ? (
                            <td rowSpan={order.lines.length}>
                              <Checkbox
                                mb={0}
                                marginX="auto"
                                onChange={this.selectOrder.bind(this, order)}
                                isChecked={itemIsSelected}
                              />
                              {order.status === 'pending' ? (
                                <Button
                                  borderRadius={2}
                                  bg="white"
                                  width={6}
                                  ml={3}
                                  onClick={this.submitOrder.bind(this, order)}
                                >
                                  <i className="fa fa-sign-in" />
                                </Button>
                              ) : (
                                false
                              )}
                            </td>
                          ) : (
                            false
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                );
              })}
            </table>
            {type && type === 'pending' && selectedOrders.length ? (
              <ButtonGroup spacing={2} float="right">
                <Button colorScheme="actionSecondary" borderRadius={2} onClick={this.submitOrders.bind(this)}>
                  <Text as="p" fontSize="md">
                    Submit Orders
                  </Text>
                </Button>
                <Button colorScheme="actionSecondary" borderRadius={2} onClick={this.deleteOrders.bind(this)}>
                  <Text as="p" fontSize="md">
                    Cancel Orders
                  </Text>
                </Button>
              </ButtonGroup>
            ) : (
              false
            )}
            {type && type === 'history' && selectedOrders.length ? (
              <Button
                colorScheme="actionSecondary"
                float="right"
                borderRadius={2}
                onClick={this.deleteOrders.bind(this)}
              >
                <Text as="p" fontSize="md">
                  Cancel Orders
                </Text>
              </Button>
            ) : (
              false
            )}
          </Box>
        ) : (
          <Box>
            <Text as="p">No results found.</Text>
          </Box>
        )}
        <hr />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    orderStatus: state.orderStatusMap,
  };
};

export default connect(mapStateToProps)(OrderTable);
