import React, { Component } from 'react';
import moment from 'moment';
import EditPackerOfferLine from './EditPackerOfferLine';
import {
  addPackerOfferLine,
  patchPackerOfferLine,
  removePackerOfferLine,
} from '../../../../actions/actions_packer_offer';
import { getClearedPurchaseOrderLine, validatePackerOfferLines } from '../../../../functions';

class EditPackerOfferLines extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validationErrors: [],
      lines: [],
    };

    this.onFabricationDateChange = this.onFabricationDateChange.bind(this);
    this.onLineValueChange = this.onLineValueChange.bind(this);
    this.changeLineActivity = this.changeLineActivity.bind(this);
    this.saveLine = this.saveLine.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keypress', this.removeAllWarnings, false);
    this.setState({ lines: this.props.form.lines.slice() });
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.removeAllWarnings);
  }

  removeAllWarnings = () => {
    this.setState({ dateWarnings: [] });
  };

  addLine = () => {
    const stateCopy = Object.assign({}, this.state);
    const newPackerOfferLine = {
      active: true,
      input_product_uid: 0,
      number_of_bins: 0,
      price_per_unit: 0,
      quantity: 0,
      fabrication_date: '',
      price_type: 'spot',
    };

    stateCopy.lines.push(newPackerOfferLine);
    this.setState(stateCopy);
  };

  resolveInputProduct(inputProductUid) {
    const inputProduct = this.props.input_products.filter(item => {
      return item.uid === inputProductUid;
    });
    return inputProduct.length ? inputProduct[0] : null;
  }

  changeLineActivity(activityFlag, index) {
    const stateToUpdate = Object.assign({}, this.state);
    stateToUpdate.lines[index].active = activityFlag;
    this.setState(stateToUpdate);
  }

  static getClearedLine(line) {
    const DEFAULT_BIN_SIZE = 2000;
    const quantityNumber = parseFloat(line.quantity);
    let roundedNumber = Math.ceil(quantityNumber / DEFAULT_BIN_SIZE) * DEFAULT_BIN_SIZE;

    roundedNumber = parseInt(roundedNumber);
    line.quantity = roundedNumber;
    line.number_of_bins = roundedNumber / DEFAULT_BIN_SIZE;

    return getClearedPurchaseOrderLine(line);
  }

  saveLine = index => {
    const lineCopy = Object.assign({}, this.state.lines[index]);
    const lineForm = EditPackerOfferLines.getClearedLine(lineCopy);

    const linesCopy = [...this.state.lines];
    linesCopy[index] = lineForm;

    const validationErrors = validatePackerOfferLines(this.props.form, linesCopy);
    this.props.updateValidationErrors(validationErrors);

    if (validationErrors.length) {
      return;
    }

    if (lineCopy.id) {
      this.props.dispatch(patchPackerOfferLine(this.props.packer_offer_id, lineCopy.id, lineForm, this.props.token));
    } else {
      this.props.dispatch(addPackerOfferLine(this.props.packer_offer_id, [lineForm], this.props.token));
    }

    this.props.close();
  };

  onFabricationDateChange = (index, e) => {
    if (e._isAMomentObject && e.isBefore(moment().subtract(14, 'days'))) {
      const currentWarnings = Object.assign([], this.state.dateWarnings);
      currentWarnings.push(index);
      this.setState({ dateWarnings: currentWarnings });
    }
    this.onLineValueChange('fabrication_date', e, index);
  };

  removeLine = index => {
    const formCopy = Object.assign({}, this.state);

    if (formCopy.lines[index].id) {
      const shouldRemove = window.confirm('Are you sure you want to remove this line?');

      if (shouldRemove) {
        this.props.dispatch(
          removePackerOfferLine(this.props.packer_offer_id, formCopy.lines[index].id, this.props.token)
        );

        formCopy.lines.splice(index, 1);
        this.setState({ form: formCopy });
        this.props.close();
      }
    } else {
      formCopy.lines.splice(index, 1);
      this.setState({ form: formCopy });
    }
  };

  removeWarning = index => {
    const stateCopy = Object.assign({}, this.state);
    stateCopy.dateWarnings.splice(stateCopy.dateWarnings.indexOf(index));
    this.setState(stateCopy);
  };

  onLineValueChange = (fieldName, value, index) => {
    const copyFormState = Object.assign({}, this.state);
    copyFormState.lines[index][fieldName] = value;

    if (fieldName === 'input_product_uid') {
      copyFormState.lines[index].input_product = this.resolveInputProduct(copyFormState.lines[index][fieldName]);
    }

    this.setState({ form: copyFormState });
  };

  render() {
    const form = this.state;

    form.lines.forEach(line => {
      line.input_product = this.resolveInputProduct(line.input_product_uid);
    });

    return (
      <div>
        <div className="order-results-table" style={{ marginBottom: '20px' }}>
          <table style={{ marginTop: '15px' }} className="table m-table table-hover m-table--head-separator-danger">
            <thead>
              <tr>
                <th>Actions</th>
                <th>Ingredient</th>
                <th>Fabrication Date</th>
                <th>Quantity</th>
                <th>Price per Unit</th>
              </tr>
            </thead>
            <tbody>
              {form
                ? form.lines.map((line, index) => (
                    <EditPackerOfferLine
                      dateWarnings={this.props.dateWarnings}
                      key={line.id || index}
                      input_products={this.props.input_products}
                      onLineValueChange={this.onLineValueChange}
                      line={line}
                      removeLine={this.removeLine}
                      save={this.saveLine}
                      setActive={this.changeLineActivity}
                      index={index}
                    />
                  ))
                : false}
            </tbody>
          </table>
          <div className="row">
            <div className="col-md-12">
              <button onClick={this.addLine} style={{ marginLeft: '20px' }} className="btn btn-sm btn-success">
                <i className="flaticon-add" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPackerOfferLines;
