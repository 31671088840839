import Constants from '../Constants';

export const ACCESS_LEVELS_FETCHED = 'ACCESS_LEVELS_FETCHED';
export const ACCESS_LEVELS_ERROR = 'ACCESS_LEVELS_ERROR';
export const DYNAMIC_NAVIGATIONS_FETCHED = 'DYNAMIC_NAVIGATIONS_FETCHED';
export const ERROR_NAVIGATION_FETCHED = 'ERROR_NAVIGATION_FETCHED';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadAccessLevels(accessLevels) {
  return {
    type: ACCESS_LEVELS_FETCHED,
    payload: accessLevels,
  };
}
export function errorAccessLevels(error) {
  return {
    type: ACCESS_LEVELS_ERROR,
    payload: error,
  };
}

export function loadDynamicNavigation(navigationLinks) {
  return {
    type: DYNAMIC_NAVIGATIONS_FETCHED,
    payload: navigationLinks,
  };
}
export function errorDynamicNavigation(error) {
  return {
    type: ERROR_NAVIGATION_FETCHED,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchAccessLevels(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}access_levels`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorAccessLevels))
      .then(json => dispatch(loadAccessLevels(json)))
      .catch(error => dispatch(errorAccessLevels(error)));
  };
}

export function fetchDynamicNavigations(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}dynamic_navigations`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorDynamicNavigation))
      .then(json => dispatch(loadDynamicNavigation(json)))
      .catch(error => dispatch(errorDynamicNavigation(error)));
  };
}
