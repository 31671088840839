import { createSelector } from '@reduxjs/toolkit';

// -----------------------------
// Basic selectors
// -----------------------------

// Public
/**
 * Whether the market page is loading
 * @param {*} state
 */
export const selectTreasuryLoading = state => state.treasury.loading || state.treasury.annotationsLoading;
/**
 * Any page-wide error for the market page
 * @param {*} state
 */
export const selectTreasuryError = state => state.treasury.error;

/**
 * The time scale in action.
 * @param {*} state
 */
export const selectTreasuryTimeScale = state => state.treasury.timeScale;

/**
 * The time range in action.
 * @param {*} state
 */
export const selectTreasuryTimeRange = state => state.treasury.timeRange;

/**
 * The protein in action.
 * @param {*} state
 */
export const selectTreasuryActiveProtein = state => state.treasury.activeProtein;

/**
 * The currency in action.
 * @param {*} state
 */
export const selectTreasuryActiveCurrency = state => state.treasury.activeCurrency;

/**
 * Raw treasury orders as fetched from API with minimal processing. No aggregation.
 * @param {*} state
 */
export const selectTreasuryDataRaw = state => state.treasury.data;

export const selectTreasuryActiveMarket = state => state.treasury.activeMarket;

export const selectTreasuryAnnotationsData = state => state.treasury.annotationsData;

// ------
// Complex selectors

/**
 * Select all the parameters required to fetch data.
 */
export const selectTreasuryFetchParameters = createSelector(
  selectTreasuryActiveProtein,
  selectTreasuryActiveMarket,
  (activeProtein, activeMarket) => ({
    activeProtein,
    activeMarket,
  })
);

export const selectTreasuryAnnotationFetchParameters = createSelector(
  selectTreasuryTimeScale,
  selectTreasuryActiveProtein,
  selectTreasuryActiveMarket,
  (timeScale, activeProtein, activeMarket) => ({
    timeScale,
    activeProtein,
    activeMarket,
  })
);
