import axios from 'axios';
import queryString from 'query-string';
import Constants from '../Constants';
import moment from 'moment';

const BASE_URL = `${Constants.URL}`;
const NCR_DETAILS_AND_CATEGORIES_URL = `${BASE_URL}analytic-dashboard/quality/ncrs/details`;

export async function qualityNCRsDataRequest({
  timeRange,
  timePeriod,
  frequency,
  market,
  region,
  grinderId,
  currency,
}) {
  const queryParams = queryString.stringify({
    timeRange,
    frequency,
    market,
    region,
    grinder_id: grinderId,
    currency,
  });
  const ncrDetailDataPromise = axios.get(
    `${NCR_DETAILS_AND_CATEGORIES_URL}?${queryParams}&timePeriod=${timePeriod.map(d => moment(d).format('YYYY-MM-DD'))}`
  );
  const ncrDetail = await ncrDetailDataPromise;

  return {
    categories: ncrDetail.data.categories,
    data: ncrDetail.data.ncr_details,
    packer_plant_ids: ncrDetail.data.packer_plant_ids,
  };
}
