import { BALANCE_SHEET_FETCHED } from '../actions/actions_balance_sheet';

const balance_sheet = (balance_sheet = [], action) => {
  switch (action.type) {
    case BALANCE_SHEET_FETCHED:
      return action.payload;
    default:
      return balance_sheet;
  }
};

export default balance_sheet;
