import Constants from '../Constants';

export const LOAD_SIZES_FETCHED = 'LOAD_SIZES_FETCHED';
export const LOAD_SIZES_ADDED = 'LOAD_SIZES_ADDED';
export const LOAD_SIZES_EDIT = 'LOAD_SIZES_EDIT';
export const LOAD_SIZES_REMOVED = 'LOAD_SIZES_REMOVED';
export const LOAD_SIZES_ERROR = 'LOAD_SIZES_ERROR';

const BASE_URL = `${Constants.URL}load_sizes`;

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadLoadSizes(loadSizes) {
  return {
    type: LOAD_SIZES_FETCHED,
    payload: loadSizes,
  };
}

export function addFormField(loadSize) {
  return {
    type: LOAD_SIZES_ADDED,
    payload: loadSize,
  };
}

export function removeLoadSize(loadSize) {
  return {
    type: LOAD_SIZES_REMOVED,
    payload: loadSize,
  };
}

export function editLoadSize(loadSize) {
  return {
    type: LOAD_SIZES_EDIT,
    payload: loadSize,
  };
}

export function errorLoadSize(error) {
  return {
    type: LOAD_SIZES_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchLoadSizes(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    return fetch(BASE_URL, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorLoadSize))
      .then(json => dispatch(loadLoadSizes(json)))
      .catch(error => dispatch(errorLoadSize(error)));
  };
}

export function postLoadSize(payload, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(BASE_URL, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorLoadSize))
      .then(json => dispatch(addFormField(json)))
      .catch(error => dispatch(errorLoadSize(error)));
  };
}

export function patchLoadSize(payload, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${payload.id}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorLoadSize))
      .then(json => dispatch(editLoadSize(json)))
      .catch(error => dispatch(errorLoadSize(error)));
  };
}

export function deleteLoadSize(loadSize, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${loadSize.id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorLoadSize))
      .then(() => dispatch(removeLoadSize(loadSize)))
      .catch(error => dispatch(errorLoadSize(error)));
  };
}
