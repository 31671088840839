import './FoodSafetyTable.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Box, VStack, TabPanels, TabPanel } from '@chakra-ui/react';
import HorizontalNavigationBand from '../core/HorizontalNavigationBand';
import UploadDocsButton from './_components/UploadDocsButton';
import FoodSafetyTabs from './_components/FoodSafetyTabs';
import FmgDocs from './resources/FmgDocs';
import Specifications from './resources/Specifications';
import BeefBundle from './resources/BeefBundle';
import Establishments from './establishments';
import HACCPLetters from './audit/HACCPLetters';
import AnnualSiteAuditReport from './audit/AnnualSiteAuditReport';
import AuditCertificate from './audit/AuditCertificate';
import ImportantContacts from './importantContacts';
import { fetchPackers } from '../../reducers/reducer_food_safety_quality';
import LetterofGuarantee from './LetterofGuarantee';
import AllergenStatements from './AllergenStatements';
import Constants from '../../Constants';
import CoaExample from './CoaExample';
import AnimalWelfare from './AnimalWelfare';
import OtherFacilityDocuments from './OtherFacilityDocuments';

/**
 * FoodSafetyQuality component.
 *
 * @returns {JSX.Element} The rendered FoodSafetyQuality component.
 */
const FoodSafetyQuality = () => {
  const dispatch = useDispatch();
  const [resourcesTab, setResourcesTab] = useState(Constants.RESOURCE_DOCUMENT_TYPES.btt);
  const [tabIndex, setTabIndex] = useState(0);
  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false);

  const handleTabsChange = index => {
    setTabIndex(index);
  };

  useEffect(() => {
    dispatch(fetchPackers());
  }, []);

  return (
    <Tabs index={tabIndex} onChange={handleTabsChange}>
      <HorizontalNavigationBand justifyContent="flex-start" paddingX="47px">
        <FoodSafetyTabs tabIndex={tabIndex} resourcesTab={resourcesTab} setResourcesTab={setResourcesTab} />
        <Box ml="auto">
          {tabIndex !== 1 && (
            <UploadDocsButton
              onClick={e => {
                e.preventDefault();
                setIsOpenAddPopup(true);
              }}
            />
          )}
        </Box>
      </HorizontalNavigationBand>

      <VStack background="white" align="stretch" spacing="28px" paddingX="61px">
        <TabPanels>
          {/* Resources Tab Panel */}
          <TabPanel>
            {tabIndex === 0 && resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.btt && (
              <FmgDocs isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
            )}
            {tabIndex === 0 && resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.beef_bundle && (
              <BeefBundle isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
            )}
          </TabPanel>
          <TabPanel>{tabIndex === 1 && <Establishments />}</TabPanel>
          <TabPanel>
            {tabIndex === 2 && <HACCPLetters isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />}
          </TabPanel>
          <TabPanel>
            {tabIndex === 3 && (
              <LetterofGuarantee isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 4 && (
              <AllergenStatements isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 5 && (
              <ImportantContacts
                isOpenAddPopup={tabIndex === 5 && isOpenAddPopup}
                setIsOpenAddPopup={setIsOpenAddPopup}
              />
            )}
          </TabPanel>
          <TabPanel>
            {tabIndex === 6 && resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.audit_certificate && (
              <AuditCertificate
                isOpenAddPopup={tabIndex === 6 && isOpenAddPopup}
                setIsOpenAddPopup={setIsOpenAddPopup}
              />
            )}
            {tabIndex === 6 && resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.annual_audit_report && (
              <AnnualSiteAuditReport
                isOpenAddPopup={tabIndex === 6 && isOpenAddPopup}
                setIsOpenAddPopup={setIsOpenAddPopup}
              />
            )}
            {tabIndex === 6 && resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.spec && (
              <Specifications isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
            )}
            {tabIndex === 6 && resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.coa_example && (
              <CoaExample isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
            )}
            {tabIndex === 6 && resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.animal_welfare_audit_report && (
              <AnimalWelfare isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
            )}
            {tabIndex === 6 && resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.other && (
              <OtherFacilityDocuments isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
            )}
          </TabPanel>
        </TabPanels>
      </VStack>
    </Tabs>
  );
};

export default FoodSafetyQuality;
