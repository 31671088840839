import React, { Component } from 'react';

import PackerOffers from '../../containers/packers/PackerOffers';

class Inventory extends Component {
  render() {
    return (
      <div className="m-portlet__body">
        {this.props.optimizer_data ? (
          <div>
            <div className="alert alert-info" style={{ display: 'inline-block', margin: '20px 0px 10px 20px' }}>
              The custom pricing you enter here will not be saved to the database.
            </div>
            <PackerOffers optimizer />
          </div>
        ) : (
          <div className="loading-graphic">
            <img src="img/loader.gif" alt="loading" />
          </div>
        )}
      </div>
    );
  }
}

export default Inventory;
