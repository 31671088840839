import { BEEF_INPUT_INVENTORY_LEVELS_FETCHED } from '../actions/actions_input_product_inventory_levels';

const input_product_inventory_levels = (inventory_levels = [], action) => {
  switch (action.type) {
    case BEEF_INPUT_INVENTORY_LEVELS_FETCHED:
      return action.payload;
    default:
      return inventory_levels;
  }
};

export default input_product_inventory_levels;
