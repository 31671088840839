import { Col, Row, Typography } from 'antd';
import * as React from 'react';
import { formatChange, formatDollars, formatPercent } from '../../../../modules/format';
import styles from './Claims.module.css';
import { ChevronRight, ExclamationMarkIcon } from '../../../../shared/Icons';
import ByCategory from './ByCategory';
import PackerPlantsWithMostClaims from './PackerPlantsWithMostClaims';
import Incidents from './Incidents';
import ClaimAmount from './ClaimAmount';
import { endOfMonth, startOfMonth, subMonths, format } from 'date-fns';
import Comparison from '../NCRs/Comparison';
import Icon from '@ant-design/icons/lib/components/Icon';
import {
  AMOUNT_OPTIONS,
  COUNT_OPTIONS,
  STATUS_OPTIONS,
  TIME_RANGE_OPTIONS,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsConfig';
import {
  setQualityClaimsAmount,
  setQualityClaimsCount,
  setQualityClaimsRegion,
  setQualityClaimsSelectedPackerPlantId,
  setQualityClaimsStatus,
  setQualityClaimsTimeRange,
  updateQualityClaimsTimePeriod,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsReducers';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Constants from '../../../../Constants';

const Claims = props => {
  const { claimsData, timePeriod, timeScale, singlePackerPlant, selectedRegion, market } = props;

  const categoryColors = {};
  (claimsData.categories || []).forEach((x, i) => {
    categoryColors[x.category] = Constants.CATEGORY_COLORS[i % Constants.CATEGORY_COLORS.length];
  });

  return (
    <div>
      <Row gutter={[50, 16]}>
        <Col span={8}>
          <ClaimsInfo
            title="Submitted"
            color="#FC5D36"
            data={claimsData.submitted}
            timePeriod={timePeriod}
            status={STATUS_OPTIONS.SUBMITTED}
            {...props}
          />
        </Col>
        <Col span={8}>
          <ClaimsInfo
            title="Finalised"
            data={claimsData.finalised}
            submitted={claimsData.submitted}
            timePeriod={timePeriod}
            status={STATUS_OPTIONS.FINALISED}
            {...props}
          />
        </Col>
        <Col span={8}>
          <ClaimsInfo
            title="Overdue"
            icon={<ExclamationMarkIcon />}
            data={claimsData.outstanding}
            submitted={claimsData.submitted}
            timePeriod={timePeriod}
            status={STATUS_OPTIONS.OVERDUE}
            {...props}
          />
        </Col>
        <Col span={12} style={{ paddingTop: 24 }}>
          <ByCategory
            data={claimsData.categories}
            categoryColors={categoryColors}
            timePeriod={timePeriod}
            timeScale={timeScale}
            market={market}
            singlePackerPlant={singlePackerPlant}
            selectedRegion={selectedRegion}
          />
        </Col>
        <Col span={12} style={{ paddingTop: 24 }}>
          <Incidents
            data={claimsData.countByPeriod}
            singlePackerPlant={singlePackerPlant}
            selectedRegion={selectedRegion}
          />
        </Col>

        <Col span={12} style={{ paddingTop: 24 }}>
          {(!singlePackerPlant || (singlePackerPlant && singlePackerPlant.isPacker)) && (
            <PackerPlantsWithMostClaims
              data={claimsData.packerPlants}
              categoryColors={categoryColors}
              market={market}
              singlePackerPlant={singlePackerPlant}
              selectedRegion={selectedRegion}
              timePeriod={timePeriod}
              timeScale={timeScale}
            />
          )}
          {claimsData.packerPlantsTotal.length > 0 && (
            <Comparison data={claimsData.packerPlantsTotal} singlePackerPlant={singlePackerPlant} />
          )}
        </Col>
        <Col span={12} style={{ paddingTop: 24 }}>
          <ClaimAmount
            data={claimsData.amountByPeriod}
            singlePackerPlant={singlePackerPlant}
            selectedRegion={selectedRegion}
          />
        </Col>
      </Row>
    </div>
  );
};

const ClaimsInfo = props => {
  const { title, icon, data, submitted, timePeriod, color, status, singlePackerPlant, selectedRegion } = props;
  const { market: marketParam } = useParams();
  const dispatch = useDispatch();
  const handleNavigationToDetails = React.useCallback(() => {
    if (singlePackerPlant) {
      dispatch(setQualityClaimsSelectedPackerPlantId(singlePackerPlant.id));
      dispatch(setQualityClaimsRegion(singlePackerPlant.country));
    } else {
      dispatch(setQualityClaimsRegion(selectedRegion));
    }
    dispatch(setQualityClaimsStatus(status));
    dispatch(setQualityClaimsCount(COUNT_OPTIONS.TOTAL));
    dispatch(setQualityClaimsAmount(AMOUNT_OPTIONS.TOTAL));
    const now = new Date();
    dispatch(updateQualityClaimsTimePeriod([startOfMonth(subMonths(now, 12)), endOfMonth(now)]));
    dispatch(setQualityClaimsTimeRange(TIME_RANGE_OPTIONS.HISTORICAL));
  }, [dispatch, selectedRegion, singlePackerPlant]);

  return (
    <div className={styles.QualityClaims_claimsInfo}>
      <Link
        to={`/analytic-dashboard/quality/${marketParam}/claims-details`}
        onClick={() => handleNavigationToDetails()}
      >
        <Typography.Text className={styles.QualityClaims_label} style={color && { color: color, opacity: 1 }}>
          {icon && (
            <span className="icon" style={{ display: 'inline-block', width: 30, marginRight: 5 }}>
              {icon}
            </span>
          )}
          <span>{title}</span>
          <Icon style={{ position: 'relative', width: 14 }} component={ChevronRight} />
        </Typography.Text>
      </Link>
      <Row>
        {data.count !== undefined && data.count !== null && data.count >= 0 && timePeriod && (
          <Col span={12} className={styles.QualityClaims_infoBright}>
            <div className={styles.QualityClaims_count}>{data.count}</div>
            <div className={styles.QualityClaims_amount}>{formatDollars(2)(data.amount)}</div>
            <div className={styles.QualityClaims_period}>{format(timePeriod[0], 'MMMM yyyy')}</div>
            {data.previousPeriod ? (
              <div className={styles.QualityClaims_dataPoints}>
                <div>
                  <span className={styles.QualityClaims_differenceArrow}>
                    {formatChange('negative')(data.count - data.previousPeriod.count)}
                  </span>{' '}
                  <strong>
                    {formatPercent(1)((data.count - data.previousPeriod.count) / data.previousPeriod.count)}
                  </strong>{' '}
                  ({Math.abs(data.count - data.previousPeriod.count)}{' '}
                  {data.count - data.previousPeriod.count > 0 ? 'more' : 'fewer'} claims)
                </div>
                <div>
                  <span className={styles.QualityClaims_differenceArrow}>
                    {formatChange('negative')(data.amount - data.previousPeriod.amount)}
                  </span>{' '}
                  <strong>
                    {formatPercent(1)((data.amount - data.previousPeriod.amount) / data.previousPeriod.amount)}
                  </strong>{' '}
                  ({formatDollars(2)(Math.abs(data.amount - data.previousPeriod.amount))})
                </div>
                <div>from previous month</div>
              </div>
            ) : (
              <div className={styles.QualityClaims_dataPoints}>
                <strong>{formatPercent(1)(data.count / submitted.count)}</strong> of {submitted.count} submitted
              </div>
            )}
          </Col>
        )}
        <Col
          span={12}
          className={title !== 'Overdue' ? styles.QualityClaims_infoLight : styles.QualityClaims_infoBright}
        >
          <div className={styles.QualityClaims_count}>{data.yearToDate.count}</div>
          <div className={styles.QualityClaims_amount}>{formatDollars(2)(data.yearToDate.amount)}</div>
          <div className={styles.QualityClaims_period}>YTD {format(timePeriod[0], 'yyyy')}</div>
          {data.yearToDate.previousPeriod ? (
            <div className={styles.QualityClaims_dataPoints}>
              <div>
                <span className={styles.QualityClaims_differenceArrow}>
                  {formatChange('negative')(data.yearToDate.count - data.yearToDate.previousPeriod.count)}
                </span>{' '}
                <strong>
                  {formatPercent(1)(
                    (data.yearToDate.count - data.yearToDate.previousPeriod.count) /
                      data.yearToDate.previousPeriod.count
                  )}
                </strong>{' '}
                ({Math.abs(data.yearToDate.count - data.yearToDate.previousPeriod.count)}{' '}
                {data.yearToDate.count - data.yearToDate.previousPeriod.count > 0 ? 'more' : 'fewer'} claims)
              </div>
              <div>
                <span className={styles.QualityClaims_differenceArrow}>
                  {formatChange('negative')(data.yearToDate.amount - data.yearToDate.previousPeriod.amount)}
                </span>{' '}
                <strong>
                  {formatPercent(1)(
                    (data.yearToDate.amount - data.yearToDate.previousPeriod.amount) /
                      data.yearToDate.previousPeriod.amount
                  )}
                </strong>{' '}
                ({formatDollars(2)(Math.abs(data.yearToDate.amount - data.yearToDate.previousPeriod.amount))})
              </div>
              <div>from same time last year</div>
            </div>
          ) : (
            <div className={styles.QualityClaims_dataPoints}>
              <strong>{formatPercent(1)(data.yearToDate.count / submitted.yearToDate.count)}</strong> of{' '}
              {submitted.yearToDate.count} submitted
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Claims;
