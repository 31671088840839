import React from 'react';
import { Box, FormControl, FormLabel, Text } from '@chakra-ui/react';

function SimpleText(field) {
  const { indices, name, label, type, value } = field;

  return (
    <Box>
      <FormControl display="flex" flexDir="column">
        <FormLabel mb="7px">{label}</FormLabel>
        <Box pl="12px" mb="3px">
          <Text fontSize="md" fontWeight="bold">
            {label}
          </Text>
        </Box>
      </FormControl>
    </Box>
  );
}

export default SimpleText;
