import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@chakra-ui/react';
import { isEqual } from 'lodash';

const CustomFormControl = ({ children, ...rest }) => {
  return (
    <FormControl width="264px" {...rest}>
      {children}
    </FormControl>
  );
};

CustomFormControl.propTypes = {
  children: PropTypes.node.isRequired,
  changeableKey: PropTypes.string ,
};

const areEqual = (prevProps, nextProps) => {
  if (!nextProps?.changeableKey) {
    return false;
  }
  if (prevProps.changeableKey === nextProps.changeableKey) {
    return true;
  }
  return false;
};

export default React.memo(CustomFormControl, areEqual);
