/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { startOfDay, subYears } from 'date-fns';

export const TREASURY_DETAILS_VOLUME = {
  TOTAL: 'Total',
  ALL_STRATEGIES: 'All strategies',
  FORMULA: 'Formula',
  SPOT: 'Spot',
  CONTRACT: 'Contract',
  NOF: 'NOF',
  HEDGED_VS_EXPOSED: 'Hedged vs Exposed',
  HEDGED: 'Hedged',
  EXPOSED: 'Exposed',
  FAT_VS_LEAN: 'Fat vs Lean',
};

export const TREASURY_DETAILS_PRICE = {
  MARKET_PRICE_AND_FORECAST: 'Market price and forecast',
  COST_PRICE: 'Cost price',
  PRICE_SAVINGS: 'Price savings',
};

export const TREASURY_DETAILS_COST = {
  TOTAL: 'Total',
  SAVINGS: 'Savings',
};

const PAST_YEAR_COUNT = 2;

const treasuryDetailsReducers = createSlice({
  name: 'treasury-details',
  initialState: {
    activeVolume: null,
    activePrice: TREASURY_DETAILS_PRICE.MARKET_PRICE_AND_FORECAST,
    activeCost: null,
    timeRange: [startOfDay(subYears(new Date(), PAST_YEAR_COUNT)).getTime(), startOfDay(new Date()).getTime()],
    showOutlook: true,
  },
  reducers: {
    updateActiveVolume(state, action) {
      state.activeVolume = action.payload;
    },
    updateActivePrice(state, action) {
      state.activePrice = action.payload;
    },
    updateActiveCost(state, action) {
      state.activeCost = action.payload;
    },
    updateTimeRange(state, action) {
      state.timeRange = action.payload;
    },
    updateShowOutlook(state, action) {
      state.showOutlook = action.payload;
    },
  },
});

export const { updateActiveVolume, updateActivePrice, updateActiveCost, updateTimeRange, updateShowOutlook } =
  treasuryDetailsReducers.actions;

// ------
// Basic selectors

// Public

/**
 * The volume in action.
 * @param {*} state
 */
export const selectTreasuryDetailsActiveVolume = state => state.treasuryDetails.activeVolume;

/**
 * The price in action.
 * @param {*} state
 */
export const selectTreasuryDetailsActivePrice = state => state.treasuryDetails.activePrice;

/**
 * The cost in action.
 * @param {*} state
 */
export const selectTreasuryDetailsActiveCost = state => state.treasuryDetails.activeCost;

/**
 * The time range in action.
 * @param {*} state
 */
export const selectTreasuryDetailsTimeRange = state => state.treasuryDetails.timeRange;

/**
 * If we want to see the forecast data.
 * @param {*} state
 */
export const selectTreasuryDetailsShowOutlook = state => state.treasuryDetails.showOutlook;

export default treasuryDetailsReducers.reducer;
