import { connect } from 'react-redux';

import ShipmentPortMarkComponent from '../../components/orders/order-form/edit-form/ShipmentPortMark';

const mapStateToProps = state => {
  return {
    token: state.user.token,
  };
};

const ShipmentPortMark = connect(mapStateToProps)(ShipmentPortMarkComponent);

export default ShipmentPortMark;
