import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import OrderTableModal from '../../components/orders/order-table-modal/OrderTableModal';
import { fetchEntitiesData, findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

class OrderTableModalHOC extends React.Component {
  static propTypes = {
    current_order: PropTypes.instanceOf(Object).isRequired,
    selectCurrentModalDetail: PropTypes.func.isRequired,
    packer_plants: PropTypes.arrayOf(PropTypes.object),
    grinders: PropTypes.arrayOf(PropTypes.object),
    input_products: PropTypes.arrayOf(PropTypes.object),
    transporters: PropTypes.arrayOf(PropTypes.object),
    freight_rate: PropTypes.arrayOf(PropTypes.object),
    po_doc_types: PropTypes.arrayOf(PropTypes.object),
    load_sizes: PropTypes.arrayOf(PropTypes.object),
    packers: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    packer_plants: [],
    grinders: [],
    input_products: [],
    transporters: [],
    freight_rate: [],
    po_doc_types: [],
    load_sizes: [],
    packers: [],
  };

  componentDidMount() {
    this.props.dispatch(fetchEntitiesData());
  }

  render() {
    return (
      <div>
        <OrderTableModal {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const result = {
    ...findEntitiesInState(state),
    user: state.user,
    token: state.user.token,
    current_order: state.orders.netsuite_purchase_order,
    endUsers: state.end_users,
  };
  return result;
};

const connectedComponent = connect(mapStateToProps)(OrderTableModalHOC);

export default connectedComponent;
