const schema = [
  {
    name: 'fdr_item',
    displayName: 'FDR Item',
    systemIdField: 'id',
    displayIdField: 'item',
    path: '/fdr_items',
    tabGroup: { key: 'pricing', label: 'Pricing' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'item',
        displayName: 'Item',
        tooltip: 'Name or title of the item being added to the Fixed Deposit Receipt (FDR)',
        dataType: 'shorttext',
        required: true,
      },
      {
        name: 'category',
        displayName: 'Category',
        tooltip: 'Classification or type of the item within the FDR',
        dataType: 'shorttext',
        required: false,
      },
      {
        name: 'description',
        displayName: 'Description',
        tooltip: 'Brief explanation or details about the item',
        dataType: 'text',
        required: false,
      },
    ],
  },
];
export default schema;
