import React from 'react';
import { Radio } from 'antd';
import { CURRENCY } from '../../../../slices/markets/marketsConfig';
import PropTypes from 'prop-types';

export default function FilterCurrency(props) {
  const { activeCurrency, onActiveCurrencyChange } = props;
  return (
    <div>
      <Radio.Group value={activeCurrency} onChange={e => onActiveCurrencyChange(e.target.value)}>
        <Radio.Button value={CURRENCY.AUD}>AUD</Radio.Button>
        <Radio.Button value={CURRENCY.USD}>USD</Radio.Button>
      </Radio.Group>
    </div>
  );
}

FilterCurrency.propTypes = {
  activeCurrency: PropTypes.string.isRequired,
  onActiveCurrencyChange: PropTypes.func.isRequired,
};
