import { connect } from 'react-redux';
import DataManagementComponent from '../../components/pages/DataManagement';

const mapStateToProps = state => {
  return {
    token: state.user.token,
    fileUploaded: state.data_management,
    user: state.user,
    error: state.error,
  };
};
const DataManagement = connect(mapStateToProps)(DataManagementComponent);

export default DataManagement;
