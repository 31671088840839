import Constants from '../Constants';

export const ORDERS_PENDING_COLDSTORE_SCHEDULE_BASE_URL = `${Constants.URL}coldstore_orders?status=pending_coldstore_schedule`;
export const ORDERS_PENDING_COLDSTORE_SCHEDULE_FETCHED = 'ORDERS_PENDING_COLDSTORE_SCHEDULE_FETCHED';
export const REPLACE_ORDER_PENDING_COLDSTORE_SCHEDULE = 'REPLACE_ORDER_PENDING_COLDSTORE_SCHEDULE';
export const REPLACE_ORDERS_PENDING_COLDSTORE_SCHEDULE = 'REPLACE_ORDERS_PENDING_COLDSTORE_SCHEDULE';
export const REMOVE_ORDER_PENDING_COLDSTORE_SCHEDULE = 'REMOVE_ORDER_PENDING_COLDSTORE_SCHEDULE';
export const ORDERS_PENDING_COLDSTORE_SCHEDULE_DELETED = 'ORDERS_PENDING_COLDSTORE_SCHEDULE_DELETED';
export const ORDERS_PENDING_COLDSTORE_SCHEDULE_ERROR = 'ORDERS_PENDING_COLDSTORE_SCHEDULE_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function replaceOrderPendingColdstoreSchedule(orderPendingColdstoreSchedule) {
  return {
    type: REPLACE_ORDER_PENDING_COLDSTORE_SCHEDULE,
    payload: orderPendingColdstoreSchedule,
  };
}

export function replaceOrdersPendingColdstoreSchedule(ordersPendingColdstoreSchedule) {
  return {
    type: REPLACE_ORDERS_PENDING_COLDSTORE_SCHEDULE,
    payload: ordersPendingColdstoreSchedule,
  };
}

export function removeOrderPendingColdstoreSchedule(orderPendingColdstoreSchedule) {
  return {
    type: REMOVE_ORDER_PENDING_COLDSTORE_SCHEDULE,
    payload: orderPendingColdstoreSchedule,
  };
}

export function loadOrdersPendingColdstoreSchedule(ordersPendingColdstoreSchedule) {
  return {
    type: ORDERS_PENDING_COLDSTORE_SCHEDULE_FETCHED,
    payload: ordersPendingColdstoreSchedule,
  };
}

export function errorOrdersPendingColdstoreSchedule(error) {
  return {
    type: ORDERS_PENDING_COLDSTORE_SCHEDULE_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchOrdersPendingColdstoreSchedule(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${ORDERS_PENDING_COLDSTORE_SCHEDULE_BASE_URL}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrdersPendingColdstoreSchedule))
      .then(json => dispatch(loadOrdersPendingColdstoreSchedule(json)))
      .catch(error => dispatch(errorOrdersPendingColdstoreSchedule(error)));
  };
}
