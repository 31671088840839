import Constants from '../Constants';

export const DATA_MANAGEMENT_BASE_URL = `${Constants.URL}data-management`;

export const DATA_MANAGEMENT_FILE_UPLOADED = 'DATA_MANAGEMENT_FILE_UPLOADED';
export const DATA_MANAGEMENT_FILE_ERROR = 'DATA_MANAGEMENT_FILE_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function uploadedDataManagementFile(json) {
  return {
    type: DATA_MANAGEMENT_FILE_UPLOADED,
    payload: json,
  };
}
export function errorUploadDataManagementFile(error) {
  return {
    type: DATA_MANAGEMENT_FILE_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function uploadDataManagementFile(payload, token) {
  return dispatch => {
    const formData = new FormData();
    formData.append('file', payload.file);
    const header = new Headers({ Authorization: `Token ${token}` });
    return fetch(`${DATA_MANAGEMENT_BASE_URL}?fileName=${payload.fileName}`, {
      method: 'POST',
      body: formData,
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorUploadDataManagementFile))
      .then(json => dispatch(uploadedDataManagementFile(json)))
      .catch(error => dispatch(errorUploadDataManagementFile(error)));
  };
}
