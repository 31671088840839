import PropTypes from 'prop-types';
import { packerPlantPropTypes } from '../quality/qualityPropTypes';

export const breakdownColorsPropTypes = PropTypes.instanceOf(Object);
export const breakdownPackerPlantPropTypes = packerPlantPropTypes;
export const dataColorsPropTypes = PropTypes.instanceOf(Object);
export const highlightedPackerPlantsPropTypes = PropTypes.arrayOf(packerPlantPropTypes);
export const packerModePropTypes = PropTypes.string;
export const selectedCategoriesPropTypes = PropTypes.arrayOf(PropTypes.string);
export const selectedMarketsPropTypes = PropTypes.arrayOf(PropTypes.string);
export const selectedSubcategoriesPropTypes = PropTypes.arrayOf(PropTypes.string);
export const selectedYearsPropTypes = PropTypes.arrayOf(PropTypes.number);

export const availableCategoriesPropTypes = PropTypes.arrayOf(PropTypes.string);
export const availableCategoriesWithSubcategoriesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({ category: PropTypes.string, subCategories: PropTypes.arrayOf(PropTypes.string) })
);
export const availableMarketsPropTypes = PropTypes.arrayOf(PropTypes.string);
export const availablePackerPlantsPropTypes = PropTypes.arrayOf(packerPlantPropTypes);
export const availableYearsPropTypes = PropTypes.arrayOf(PropTypes.number);

export const setBreakdownPackerPlantPropTypes = PropTypes.func;
export const setDataColorsPropTypes = PropTypes.func;
export const setHighlightedPackerPlantsPropTypes = PropTypes.func;
export const setPackerModePropTypes = PropTypes.func;
export const setSelectedCategoriesPropTypes = PropTypes.func;
export const setSelectedMarketsPropTypes = PropTypes.func;
export const setSelectedSubcategoriesPropTypes = PropTypes.func;
export const setSelectedYearsPropTypes = PropTypes.func;

export const isLargePropTypes = PropTypes.bool;
export const columnsPropTypes = PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]));
export const colorsPropTypes = PropTypes.instanceOf(Object);
