import React, { useEffect } from 'react';
import { useCounter } from '@chakra-ui/counter';
import { Box, FormControl, Text } from '@chakra-ui/react';
import { ErrorMessage, Field, useField } from 'formik';
import Button from '../Button';

function Counter(props) {
  const { indices, name, label, type, value, min, max, step, ml, ...rest } = props;
  const fieldName = typeof name === 'function' ? name(indices) : name;

  const counter = useCounter({
    max: max || undefined,
    min: min || 1,
    step: 1,
  });

  const [field, meta, helpers] = useField(fieldName);
  const { setValue } = helpers;

  useEffect(() => {
    setValue(counter.value ? Number(counter.value) : 0);
  }, [counter.value]);

  // Start at 1, not 0
  if (!counter.value) {
    counter.increment();
  }

  return (
    <Box
      {...{
        borderWidth: '1px',
        borderRadius: '0.25rem',
        borderColor: 'gray.400',
        backgroundColor: 'white',
        ml: '15px',
      }}
    >
      <FormControl display="flex" flexDir="row">
        <Button type="borderless" onClick={() => counter.decrement()} icon="minus" iconColor="primary" pt="4px" />
        <Field
          {...{
            type,
            name: fieldName,
            value: counter.value,
            style: {
              borderColor: 'transparent',
              minWidth: 35,
              textAlign: 'center',
              padding: 0,
            },
            className: 'form-control',
            ...rest,
          }}
        />
        <Button type="borderless" onClick={() => counter.increment()} icon="plus" iconColor="primary" />
      </FormControl>
      <ErrorMessage name={fieldName}>{msg => <Text color="error">{msg}</Text>}</ErrorMessage>
    </Box>
  );
}

export default Counter;
