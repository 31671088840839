import { createSelector } from '@reduxjs/toolkit';
import { group, sum } from 'd3';

import { filterOrdersForDateMode } from '../../../modules/order-utils';
import { DATE_MODE } from '../marketsConfig';
import {
  selectActiveFocusOrders,
  selectActiveMarket,
  selectActiveFocus,
  selectActiveVolumeGrouping,
  selectMarketsTimePeriod,
  selectMarketsPreviousTimePeriod,
  selectUsdaPrices,
  selectVolumeOptions,
  selectMarketsDataRaw,
  selectMarketsPreviousPeriodDataRaw,
} from './selectBasicMarketData';

/**
 * Returns number of weeks since the year 0.
 * Use to compare weeks across year boundaries
 * @param {Date|string|Number} ts
 */
// const weeksSinceYearZero = ts =>
//   getYear(ts) * 52 +
//   getWeek(ts, {
//     weekStartsOn: 1, // Week starts on MONDAY.
//   });

/**
 * Cost performance date mode (i.e. delivery vs order dates) is determined by the time period.
 * If it's the current week or future, we use order dates.
 * If it's previous weeks, we use the delivery date.
 */
export const selectCostPerformanceDateMode = createSelector(selectMarketsTimePeriod, () => {
  // Use years since 0
  // const thisWeek = weeksSinceYearZero(new Date());
  // if (weeksSinceYearZero(timePeriod[0]) >= thisWeek || weeksSinceYearZero(timePeriod[1]) >= thisWeek) {
  return DATE_MODE.ORDER;
  // }

  // Amy requested on 2021-02-22 that Volume section show ORDER dates, not delivery dates.
  // return DATE_MODE.DELIVERY;
});

/**
 * Selects orders for the volume
 * by filtering the active focus based on the date mode
 */
export const selectVolumeOrders = createSelector(
  selectActiveFocusOrders,
  selectMarketsTimePeriod,
  selectCostPerformanceDateMode,
  (activeFocusOrders, timePeriod, dateMode) => {
    const filtered = activeFocusOrders.filter(filterOrdersForDateMode(timePeriod, dateMode)).map(d => ({
      ...d,
      date: d[dateMode],
    }));
    return filtered;
  }
);

export const selectPreviousPeriodVolumeOrders = createSelector(
  selectMarketsPreviousPeriodDataRaw,
  selectActiveFocus,
  selectMarketsPreviousTimePeriod,
  selectCostPerformanceDateMode,
  (data, activeFocus, timePeriod, dateMode) => {
    return data.orders[activeFocus]
      .filter(filterOrdersForDateMode(timePeriod, dateMode))
      .map(d => ({ ...d, date: d[dateMode] }));
  }
);

/**
 * Filters usda prices by the markets time period
 */
export const selectActiveUsdaPrices = createSelector(
  selectUsdaPrices,
  selectMarketsTimePeriod,
  (usdaPrices, timePeriod) => {
    if (!usdaPrices) return null;
    return usdaPrices.filter(({ date }) => date >= timePeriod[0] && date <= timePeriod[1]);
  }
);

/**
 * Returns a structure with data required for the markets volume table
 */
export const selectMarketsVolumeData = createSelector(
  selectActiveMarket,
  selectActiveVolumeGrouping,
  selectVolumeOrders,
  selectPreviousPeriodVolumeOrders,
  selectUsdaPrices,
  selectVolumeOptions,
  selectMarketsDataRaw,
  (activeMarket, activeVolumeGrouping, currentOrders, previousOrders, usdaPrices, volumeOptions, rawData) => {
    if (!currentOrders) return null;

    const aggregator = orders => {
      const volumeTotal = sum(orders, d => d.buy_quantity);

      const grouped = group(
        orders,
        activeVolumeGrouping.rowGroupingFunc
          ? order => activeVolumeGrouping.rowGroupingFunc(order, activeMarket)
          : order => order[activeVolumeGrouping.rowGroupingKey]
      );

      let groupRows = Array.from(grouped.entries())
        .map(([rowKey, orderList]) => {
          const rowObject = {
            key: rowKey,
          };

          const volume = sum(orderList, d => d.buy_quantity);

          activeVolumeGrouping.tableColumns.forEach(column => {
            const value = column.getRowValue(orderList, volume, rowKey, usdaPrices);
            rowObject[column.key] = value;
          });
          return rowObject;
        })
        .sort((a, b) => b.volume - a.volume);

      if (activeVolumeGrouping.groupRowOverride) {
        const options = {};
        activeVolumeGrouping.options.forEach(option => {
          options[option.key] = volumeOptions[option.key];
        });

        groupRows = activeVolumeGrouping.groupRowOverride(groupRows, orders, options, { activeMarket, rawData });
      }

      const statisticValues = {};
      activeMarket.volumeGroupings.forEach(grouping => {
        statisticValues[grouping.key] = grouping.getStatisticValue(orders, volumeTotal, activeMarket);
      });

      return {
        volumeTotal,
        groupRows: groupRows || [],
        statisticValues,
      };
    };

    const currentData = aggregator(currentOrders);
    const previousData = aggregator(previousOrders);
    return {
      currentData,
      previousData,
    };
  }
);
