import React from 'react';

const PackerOfferActions = props => {
  const showCancel = props.item.status !== 'cancelled';

  return (
    <td key={props.item.id}>
      <button onClick={() => props.edit(props.item.id, props.item.props_index)} className="btn btn-sm btn-info">
        <i className="flaticon-edit-1" />
      </button>
      &nbsp;
      {showCancel ? (
        <button
          onClick={() => {
            props.cancel(props.item.id);
          }}
          className="btn btn-sm btn-danger"
        >
          <i className="flaticon-cancel" />
        </button>
      ) : (
        false
      )}
    </td>
  );
};

export default PackerOfferActions;
