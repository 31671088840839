import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { python } from '@codemirror/lang-python';
import CodeMirror from '@uiw/react-codemirror';
import { Alert } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Select from '../../../components/basic/SelectField';
import ConditionBuilder from '../../../components/ConditionBuilder/ConditionBuilder';
import Tooltip from '../../../components/quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const DEFAULT_PYTHON_EDITOR = 'def main(**kwargs):';

const formatEventOption = e => ({ label: e.replaceAll('_', ' '), value: e });

export default ({ calculators, variables, events, fees, onSaveCalculator, onRemoveCalculator }) => {
  const [preventClosingModalOnSubmit, setPreventClosingModalOnSubmit] = useState(false);
  const [feesLabelMap, setFeesLabelMap] = useState();

  useEffect(() => {
    if (!fees) {
      return;
    }
    setFeesLabelMap(
      fees.reduce((o, c) => {
        o[c.uid] = c.label;
        return o;
      }, {})
    );
  }, [fees]);

  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues,
    resetForm,
    values,
    touched,
    isValid,
  } = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      label: Yup.string().nullable().required('Required'),
      description: Yup.string().nullable().required('Required'),
      events: Yup.array().nullable(),
      fees: Yup.array().nullable(),
      condition: Yup.object().nullable(),
      rule_code: Yup.string().nullable().required('Required'),
      action_code: Yup.string().nullable().required('Required'),
    }),
    onSubmit: data => {
      onSaveCalculator(data);
      if (!preventClosingModalOnSubmit) {
        closeFormModal();
      }
    },
  });
  const [showFormModal, setShowFormModal] = useState(false);

  const openFormModal = (calculator = {}) => {
    resetForm();
    setValues(calculator);
    setShowFormModal(true);
  };

  const closeFormModal = () => setShowFormModal(false);

  const removeCalculator = () => {
    if (confirm('Are you sure you want to delete this item?') == true) {
      onRemoveCalculator(values);
      closeFormModal();
    }
  };

  return (
    <Box>
      <VStack marginBottom="80px" alignItems="end">
        <Button
          colorScheme="actionPrimary"
          onClick={() =>
            openFormModal({
              rule_code: DEFAULT_PYTHON_EDITOR,
              action_code: DEFAULT_PYTHON_EDITOR,
              events: [],
              fees: [],
            })
          }
        >
          New
        </Button>
      </VStack>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Label</Th>
              <Th>Description</Th>
              <Th>Events</Th>
              <Th>Fees</Th>
              <Th>Condition</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {calculators &&
              calculators.map(item => (
                <Tr key={item.uid}>
                  <Td>{item.label}</Td>
                  <Td>{item.description}</Td>
                  <Td>
                    {(item.events || []).map(e => (
                      <div key={e}>
                        <Tag marginBottom="6px">{e.replaceAll('_', ' ')}</Tag>
                        <br />
                      </div>
                    ))}
                  </Td>
                  <Td>
                    {(item.fees || []).map(f => (
                      <div key={f}>
                        <Tag marginBottom="6px">{feesLabelMap && feesLabelMap[f]}</Tag>
                        <br />
                      </div>
                    ))}
                  </Td>
                  <Td>{item?.condition?.pythonCondition}</Td>
                  <Td>
                    <Button onClick={() => openFormModal(item)}>Edit</Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Modal size="6xl" isOpen={showFormModal} onClose={closeFormModal}>
        <ModalOverlay />
        <ModalContent minHeight="200px">
          <form onSubmit={handleSubmit}>
            <ModalHeader>Calculator</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing="10">
                <FormControl id="label" isInvalid={errors.label && touched.label}>
                  <FormLabel>
                    Label&nbsp;
                    <Tooltip
                      content="Enter a unique name to identify this calculator within the Pricing Engine"
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Input name="label" type="text" onChange={handleChange} onBlur={handleBlur} value={values.label} />
                  {errors.label && touched.label && (
                    <Alert style={{ marginTop: '5px' }} message={errors.label} type="error" />
                  )}
                </FormControl>
                <FormControl id="description" isInvalid={errors.description && touched.description}>
                  <FormLabel>
                    Description&nbsp;
                    <Tooltip
                      content="Provide a detailed explanation of the calculator's purpose and intended use"
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Input
                    name="description"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                  {errors.description && touched.description && (
                    <Alert style={{ marginTop: '5px' }} message={errors.description} type="error" />
                  )}
                </FormControl>
                <FormControl id="events" isInvalid={errors.events && touched.events}>
                  <FormLabel>
                    Events&nbsp;
                    <Tooltip
                      content="Select the event during which this calculator should be triggered, such as during the creation or update of a PO, or during invoicing."
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                  <Select
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: false,
                      removeSelected: true,
                      isMulti: true,
                      name: 'events',
                      value: (values.events || []).map(formatEventOption),
                      onChange: events => {
                        setFieldValue(
                          'events',
                          events.map(e => e.value)
                        );
                      },
                      options: (events || []).map(formatEventOption),
                    }}
                  />
                  {errors.events && touched.events && (
                    <Alert style={{ marginTop: '5px' }} message={errors.events} type="error" />
                  )}
                </FormControl>
                <FormControl id="condition" isInvalid={errors.condition && touched.condition}>
                  <FormLabel>
                    Condition&nbsp;
                    <Tooltip
                      content="Define the variable rules and conditions that determine how this calculator will operate."
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  {variables && (
                    <ConditionBuilder
                      condition={values.condition}
                      variables={variables.map(({ uid, label, ops }) => ({ name: uid, label, ops }))}
                      onUpdateCondition={con => {
                        setFieldValue('condition', con);
                      }}
                    />
                  )}
                  {errors.condition && touched.condition && (
                    <Alert style={{ marginTop: '5px' }} message={errors.condition} type="error" />
                  )}
                </FormControl>
                <FormControl id="fees" isInvalid={errors.fees && touched.fees}>
                  <FormLabel>
                    Fees&nbsp;
                    <Tooltip
                      content="Select the appropriate fees, as defined under the Fees Rules in the Fees Tab, to apply within this calculator."
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  </FormLabel>
                  <Select
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: false,
                      removeSelected: true,
                      isMulti: true,
                      name: 'fees',
                      value: (values.fees || []).map(f => ({ label: feesLabelMap[f], value: f })),
                      onChange: fees => {
                        setFieldValue(
                          'fees',
                          fees.map(e => e.value)
                        );
                      },
                      options: (fees || []).map(f => ({ label: f.label, value: f.uid })),
                    }}
                  />
                  {errors.fees && touched.fees && (
                    <Alert style={{ marginTop: '5px' }} message={errors.fees} type="error" />
                  )}
                </FormControl>
                <Tabs>
                  <TabList>
                    <Tab>Rule code *</Tab>
                    <Tab>Action code *</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <FormControl id="rule_code" isInvalid={errors.rule_code && touched.rule_code}>
                        <FormLabel>
                          Rule code&nbsp;
                          <Tooltip
                            content="Enter the technical code that defines how the calculation should be performed"
                            placement="right"
                          >
                            <IconButton
                              width="14px"
                              height="14px"
                              padding="0"
                              minW="auto"
                              borderRadius="50%"
                              color="#878787"
                              icon={<IoInformationCircleOutline size="14px" />}
                              variant="unstyled"
                            />
                          </Tooltip>
                          *
                        </FormLabel>
                        <CodeMirror
                          value={values.rule_code}
                          height="500px"
                          extensions={[python({})]}
                          onChange={rule_code => {
                            setFieldValue('rule_code', rule_code);
                          }}
                        />
                        {errors.rule_code && touched.rule_code && (
                          <Alert style={{ marginTop: '5px' }} message={errors.rule_code} type="error" />
                        )}
                      </FormControl>
                    </TabPanel>
                    <TabPanel>
                      <FormControl id="action_code" isInvalid={errors.action_code && touched.action_code}>
                        <FormLabel>
                          Action code&nbsp;
                          <Tooltip
                            content="Enter the technical code that specifies how the output of the calculation should be displayed, based on the Rule Code"
                            placement="right"
                          >
                            <IconButton
                              width="14px"
                              height="14px"
                              padding="0"
                              minW="auto"
                              borderRadius="50%"
                              color="#878787"
                              icon={<IoInformationCircleOutline size="14px" />}
                              variant="unstyled"
                            />
                          </Tooltip>
                          *
                        </FormLabel>
                        <CodeMirror
                          value={values.action_code}
                          height="500px"
                          extensions={[python({})]}
                          onChange={action_code => {
                            setFieldValue('action_code', action_code);
                          }}
                        />
                        {errors.action_code && touched.action_code && (
                          <Alert style={{ marginTop: '5px' }} message={errors.action_code} type="error" />
                        )}
                      </FormControl>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Checkbox
                marginRight="auto"
                isChecked={preventClosingModalOnSubmit}
                onChange={() => setPreventClosingModalOnSubmit(!preventClosingModalOnSubmit)}
              >
                Prevent closing modal on submit
              </Checkbox>
              {values.created && (
                <Button type="button" colorScheme="error" onClick={removeCalculator} marginRight="16px">
                  Remove
                </Button>
              )}
              <Button type="submit" colorScheme="actionPrimary" isDisabled={!isValid}>
                {!values.created ? 'New' : 'Save'}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};
