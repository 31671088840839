import get from 'lodash/get';
import { connect } from 'react-redux';
import FileUploadComponent from '../../components/basic/FileUpload';
import { handleFileRemoved, resetUploadStatus, uploadFileToS3 } from '../../slices/basic/file-upload';

const mapStateToProps = state => {
  return {
    token: state.user.token,
    uploadedState: get(state, 'attachments'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadFileToS3: payload => dispatch(uploadFileToS3(payload)),
    handleFileRemoved: indx => dispatch(handleFileRemoved(indx)),
    resetUploadStatus: () => dispatch(resetUploadStatus()),
  };
};

const FileUpload = connect(mapStateToProps, mapDispatchToProps)(FileUploadComponent);

export default FileUpload;
