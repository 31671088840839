import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { MdDelete } from 'react-icons/md';
import PropTypes from 'prop-types';
import Tooltip from '../_components/Tooltip';

const DeleteContact = ({ onDelete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  return (
    <>
      <Tooltip content="Delete">
        <IconButton
          width="24px"
          height="24px"
          padding="0"
          minW="auto"
          border="1px solid #D9DADF"
          borderRadius="2px"
          color="#00000099"
          backgroundColor="#fff"
          icon={<MdDelete size={12} />}
          onClick={onOpen}
        />
      </Tooltip>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Contact
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure? You can&apos;t undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onDelete();
                  onClose();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

DeleteContact.propTypes = {
  onDelete: PropTypes.func,
};

export default DeleteContact;
