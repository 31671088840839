import { createSelector } from '@reduxjs/toolkit';
import { ascending, group } from 'd3';
import { getDay, startOfDay, startOfMonth, startOfQuarter, startOfWeek, startOfYear } from 'date-fns';
import { get } from 'lodash';
// Local Deps:
import fetchMarketsDataFixture from '../../../data-fixtures/markets-all';
import { KGS_PER_LOAD } from '../../../modules/const';
import { isOrderAngus, isOrderDomestic, isOrderFat } from '../../../modules/order-utils';
import { flatten, uniq } from '../../../modules/utils';
import { selectActiveMarket } from '../../markets/selectors/selectBasicMarketData';
import {
  AGGREGATE_BY,
  FREQUENCY,
  GROUP_BY,
  MARKET_FOCUS,
  TIME_MODE,
  VOLUME,
  VOLUME_UNITS,
} from '../marketsDetailsConfig';
import marketsVolumeDetailSlice, {
  marketsVolumeDetailDataRequest,
  marketsVolumeDetailDataRequestFailure,
  marketsVolumeDetailDataRequestSuccess,
  updateGroupBySelection,
  updateYearSelection,
} from '../volumeDetails';

const mapToArray = map => Array.from(map.entries());

export function calculateOrderVolume(order) {
  switch (order.status) {
    case 'coldstore': {
      return VOLUME.COLD_STORE;
    }
    case 'coldstore_released': {
      return VOLUME.COLD_STORE;
    }
    case 'received': {
      return VOLUME.DELIVERED;
    }
    case 'invoiced': {
      return VOLUME.DELIVERED;
    }
    case 'ordered':
    case 'pending_coldstore_schedule':
    default: {
      return VOLUME.ORDERED;
    }
  }
}

function getFilterDateByVolume(order, filters) {
  switch (filters.volume) {
    case 'Cold Store': {
      return get(order, 'coldstore_entry_date', null) ? order.coldstore_entry_date : null;
    }
    case 'Delivered': {
      return order.delivery_date;
    }
    default: {
      return order.order_date;
    }
  }
}

// ------
// Basic selectors

// Public

/**
 * The market focus being filtered on.
 * @param {*} state
 */
export const selectMarketsVolumeDetailMarket = state => state.marketsVolumeDetail.market;

/**
 * Historical or Year on year
 * @param {*} state
 */
export const selectMarketsVolumeDetailTimeMode = state => state.marketsVolumeDetail.timeMode;

/**
 * The user-selected time range option in the UI.
 * @param {*} state
 */
export const selectMarketsVolumeDetailTimeRangeSelection = state => state.marketsVolumeDetail.timeRangeSelection;

/**
 * The time ranges to filter the data by based on user filter selections.
 * @param {*} state
 */
export const selectMarketsVolumeDetailTimeRange = state => state.marketsVolumeDetail.timeRange;

/**
 * Whether the market page is loading
 * @param {*} state
 */
export const selectMarketsVolumeDetailLoading = state => state.marketsVolumeDetail.loading;
/**
 * Any page-wide error for the market page
 * @param {*} state
 */
export const selectMarketsVolumeDetailError = state => state.markets.error;

/**
 * The raw data stored in state, from requests.
 * @param {*} state
 */
export const selectMarketsVolumeDetailDataRaw = state => state.marketsVolumeDetail.data;

export const selectMarketsVolumeDetailVolumeType = state => state.marketsVolumeDetail.volume;

/**
 * All filters as an object
 * @param {*} state
 */

export const selectMarketsVolumeDetailFilters = state => {
  const slice = state.marketsVolumeDetail;

  return {
    market: slice.market,
    volume: slice.volume,
    groupBy: slice.groupBy,
    aggregateBy: slice.aggregateBy,
    units: slice.units,
    frequency: slice.frequency,
    groupBySelection: slice.groupBySelection,
    aggregateBySelection: slice.aggregateBySelection,
    yearSelection: slice.yearSelection,
  };
};

export const selectMarketsVolumeDetailFetchParameters = createSelector(
  selectActiveMarket,
  selectMarketsVolumeDetailTimeRange,
  selectMarketsVolumeDetailVolumeType,
  (activeMarket, timeRange, volumeFilter) => {
    return {
      activeMarket,
      timePeriod: timeRange,
      volumeFilter,
    };
  }
);

/* Not needed as we are getting data for the time Range
 
export const selectMarketsVolumeDetailOrders = createSelector(
  selectMarketsVolumeDetailDataRaw,
  selectMarketsVolumeDetailMarket,
  selectMarketsVolumeDetailTimeRange,
  (data, market, [start, end]) => {
    let orders = data.orders[market.toLowerCase()];
    if (market === MARKET_FOCUS.ALL) {
      orders = flatten(Object.values(data.orders));
    }

    return orders.filter(order => {
      return order.order_date > start && order.order_date < end;
    });
  }
); */

export const selectMarketsVolumeDetailOrders = createSelector(
  selectMarketsVolumeDetailDataRaw,
  selectMarketsVolumeDetailMarket,
  (data, market) => {
    let orders = data.orders[market.toLowerCase()];
    if (market === MARKET_FOCUS.ALL) {
      orders = flatten(Object.values(data.orders));
    }

    return orders;
  }
);
/* Not needed as we are passing volume, timerange to backend payload
export const selectMarketsVolumeDetailOrdersFiltered = createSelector(
  selectMarketsVolumeDetailOrders,
  selectMarketsVolumeDetailFilters,
  (orders, filters) => {
    // Filter by Volume
    const filteredOrders = orders.filter(order =>
      filters.volume ? calculateOrderVolume(order) === filters.volume : true
    );
    return filteredOrders;
  }
); */

const filterOrdersByVolume = {
  Ordered: 'order_date',
  Delivered: 'delivery_date',
  'Cold Store': 'coldstore_entry_date',
};

export const selectMarketsVolumeDetailOrdersFiltered = createSelector(
  selectMarketsVolumeDetailOrders,
  selectMarketsVolumeDetailFilters,
  selectMarketsVolumeDetailTimeRange,
  (orders, filters, timeRange) => {
    const dateStart = timeRange[0].getTime();
    const dateEnd = timeRange[1].getTime();
    // Filter by volume type
    const filteredOrders = orders.filter(order => {
      const date = get(order, filterOrdersByVolume[filters.volume]);
      if (date < dateStart || date > dateEnd) {
        return false;
      }
      return date !== null;
    });
    return filteredOrders;
  }
);

export const selectMarketsVolumeDetailData = createSelector(
  selectMarketsVolumeDetailOrdersFiltered,
  // selectMarketsVolumeDetailOrders,
  selectMarketsVolumeDetailFilters,
  selectMarketsVolumeDetailTimeMode,
  selectActiveMarket,
  selectMarketsVolumeDetailTimeRange,
  (orders, filters, timeMode, activeMarket, timeRange) => {
    const startInteger = getDay(timeRange[0]);

    const frequencyGrouper = frequency => {
      let startOfFn;

      if (frequency === FREQUENCY.WEEKLY) startOfFn = date => startOfWeek(date, { weekStartsOn: startInteger });
      if (frequency === FREQUENCY.MONTHLY) startOfFn = startOfMonth;
      if (frequency === FREQUENCY.QUARTERLY) startOfFn = startOfQuarter;
      if (frequency === FREQUENCY.ANNUALLY) startOfFn = startOfYear;

      return order => {
        const date = getFilterDateByVolume(order, filters);
        const value = startOfFn(date).valueOf();
        return value;
      };
    };

    const groupByGrouper = groupBy => {
      if (groupBy === GROUP_BY.COUNTRY) return order => order.grinder_country;
      if (groupBy === GROUP_BY.PURCHASE_TYPE) return order => order.price_type;
      if (groupBy === GROUP_BY.GRINDER) return order => order.grinder_name;
      if (groupBy === GROUP_BY.PACKER_PLANT) return order => order.packer_plant_name;
      if (groupBy === GROUP_BY.FAT_V_LEAN) return order => (isOrderFat(order) ? 'Fat' : 'Lean');
      if (groupBy === GROUP_BY.CHEMICAL_LEAN) return order => order.chemical_lean;
      if (groupBy === GROUP_BY.ANGUS_V_REGULAR) return order => (isOrderAngus(order) ? 'Angus' : 'Regular');
      if (groupBy === GROUP_BY.FRESH_V_FROZEN) return order => order.fresh_frozen;
      return order => order.grinder_country;
    };

    const aggregateByGrouper = aggregateBy => {
      if (aggregateBy === AGGREGATE_BY.DOMESTIC_V_EXPORT) return order => isOrderDomestic(order, activeMarket);
      if (aggregateBy === AGGREGATE_BY.COUNTRY) return order => order.grinder_country;
      return order => order.grinder_country;
    };

    const yearOnYearGrouper = () => {
      return order =>
        getFilterDateByVolume(order, filters) && startOfYear(getFilterDateByVolume(order, filters)).valueOf();
    };

    const dataTransform = groupedOrders => {
      const kgOrLoads = value => {
        if (filters.units === VOLUME_UNITS.KG) return value;
        if (filters.units === VOLUME_UNITS.LOADS) return value / KGS_PER_LOAD;
        return value;
      };
      return mapToArray(groupedOrders).reduce((acc, [column, values]) => {
        const placeHolder = {};
        placeHolder[column] = values.reduce((totalWeight, order) => totalWeight + kgOrLoads(order.buy_quantity), 0);
        return { ...acc, ...placeHolder };
      }, {});
    };

    let chartGroup = () => null;
    let seriesGroup = () => null;
    let dataGroup = order =>
      getFilterDateByVolume(order, filters) && startOfDay(getFilterDateByVolume(order, filters)).valueOf();

    if (filters.frequency) {
      dataGroup = frequencyGrouper(filters.frequency);
    }

    if (filters.groupBy && !filters.aggregateBy) {
      seriesGroup = groupByGrouper(filters.groupBy);
    }

    if (filters.groupBy && filters.aggregateBy) {
      chartGroup = aggregateByGrouper(filters.aggregateBy);
      seriesGroup = groupByGrouper(filters.groupBy);
    }

    if (!filters.groupBy && filters.aggregateBy) {
      chartGroup = aggregateByGrouper(filters.aggregateBy);
    }

    if (timeMode === TIME_MODE.YEAR_ON_YEAR) {
      chartGroup = filters.groupBy ? groupByGrouper(filters.groupBy) : () => null;
      seriesGroup = yearOnYearGrouper();
      dataGroup = order => startOfDay(getFilterDateByVolume(order, filters)).valueOf();
    }

    const charts = mapToArray(group(orders, chartGroup)).map(([chartKey, chartData]) => {
      const series = mapToArray(group(chartData, seriesGroup)).map(([seriesKey, seriesData]) => {
        const data = dataTransform(group(seriesData, dataGroup));

        return {
          key: seriesKey,
          values: Object.entries(data),
          labels: Object.keys(data),
        };
      });

      return {
        key: chartKey,
        series,
      };
    });
    return charts.sort((a, b) => ascending(a.key, b.key));
  }
);

export const selectAggregationOptions = createSelector(
  // selectMarketsVolumeDetailOrdersFiltered,
  selectMarketsVolumeDetailOrders,
  selectMarketsVolumeDetailFilters,
  (orders, filters) => {
    if (!filters.aggregateBy) return [];

    if (filters.aggregateBy === AGGREGATE_BY.COUNTRY) {
      return uniq(orders.map(order => order.grinder_country)).sort(ascending);
    }
    return [];
  }
);

export const fetchMarketsVolumeDetailData = fetchParameters => {
  return async dispatch => {
    try {
      dispatch(marketsVolumeDetailDataRequest());
      const marketsData = await fetchMarketsDataFixture(fetchParameters);
      dispatch(marketsVolumeDetailDataRequestSuccess(marketsData));
      return marketsData;
    } catch (error) {
      dispatch(marketsVolumeDetailDataRequestFailure(error.toString()));
      return error;
    }
  };
};

export const updateGroupBy = payload => {
  return (dispatch, getState) => {
    dispatch(marketsVolumeDetailSlice.actions.updateGroupBy(payload));
    const groupedData = selectMarketsVolumeDetailData(getState());
    const groups = uniq(flatten(groupedData.map(({ series }) => series.map(({ key }) => key)))).sort(ascending);
    dispatch(updateGroupBySelection(groups.slice(0, 5)));
  };
};

export const updateTimeMode = payload => {
  return (dispatch, getState) => {
    dispatch(marketsVolumeDetailSlice.actions.updateTimeMode(payload));
    const groupedData = selectMarketsVolumeDetailData(getState());
    const years = uniq(flatten(groupedData.map(({ series }) => series.map(({ key }) => key)))).sort(ascending);
    dispatch(updateYearSelection(years.slice(0, 5)));

    if (payload === TIME_MODE.HISTORICAL) {
      const filters = selectMarketsVolumeDetailFilters(getState());
      // Reset the group by selection cos it was set to [] during y-o-y mode
      dispatch(updateGroupBy(filters.groupBy));
    }
  };
};
