import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DashboardSectionTile from '../core/DashboardSectionTile';
import { VStack } from '@chakra-ui/react';
import DocumentsSection from '../documents/DocumentsSection';
import DocumentEmailModal from './DocumentEmailModal';
import Constants from '../../Constants';
import { get, isEmpty } from 'lodash';
import { errorSendEmail, resetEmailModal, sendEmail } from '../../actions/actions_email';
import moment from 'moment';
import { generateDocument } from '../../actions/actions_generated_documents';

const GeneratedDocuments = ({
  form,
  currentOrder,
  isInEditMode,
  internalPoLabel,
  user,
  dispatch,
  token,
  email,
  grinderIsChinese,
  isGeneratingDocument,
  setIsGeneratingDocument,
  emailModalIsOpen,
  setEmailModalIsOpen,
  customPoDocs,
}) => {
  const [state, setState] = useState({
    selectedGeneratedDocument: null,
  });

  const handleOpenEmailModal = document => {
    setState(prevState => ({
      ...prevState,
      selectedGeneratedDocument: document,
    }));

    setEmailModalIsOpen(true);
  };

  const handleEmailModalCancel = () => {
    dispatch(resetEmailModal());
    window.scrollTo(0, 0);
  };

  const handleSendEmail = recipientEmail => {
    if (!recipientEmail || !recipientEmail.subject || isEmpty(recipientEmail.recipients)) {
      dispatch(
        errorSendEmail(
          'This email is missing either a subject or some recipients.  Please check these fields and try again.'
        )
      );
    }

    dispatch(sendEmail(token, recipientEmail));
  };

  const handleDocumentGeneration = ({
    documentType,
    note,
    destinationPortChargeStatus,
    lotNumberFormatId,
    isCustom = false,
  }) => {
    const config = { documentType, purchaseOrderId: currentOrder.id };
    const { GENERATED_DOCUMENTS } = Constants;
    if (isCustom) {
      config.documentType = 'custom_po_docs';
      config.documentName = documentType;
    } else {
      switch (documentType) {
        case GENERATED_DOCUMENTS.multiConfirmationOfPurchase.value:
        case GENERATED_DOCUMENTS.multiConfirmationOfSale.value:
        case GENERATED_DOCUMENTS.confirmationOfPurchase.value:
        case GENERATED_DOCUMENTS.confirmationOfSale.value: {
          config.note = note;
          config.purchaseOrderIds = [config.purchaseOrderId];
          break;
        }
        case GENERATED_DOCUMENTS.forwardingInstructions.value: {
          config.destinationPortCharge = destinationPortChargeStatus;
          config.producedAt = moment().format('LLL');
          break;
        }
        case GENERATED_DOCUMENTS.certificateOfOrigin.value: {
          config.generatedDate = moment().format('DD-MM-YYYY');
          break;
        }
        case GENERATED_DOCUMENTS.certificateOfInsurance.value: {
          config.generatedDate = moment().format('DD-MM-YYYY');
          break;
        }
        case GENERATED_DOCUMENTS.packingList.value:
          config.purchaseOrderIds = config.purchaseOrderId;
          config.lotNumberFormat = lotNumberFormatId;
          break;
        default:
          break;
      }
    }
    setIsGeneratingDocument(true);
    dispatch(generateDocument(config)).finally(() => setIsGeneratingDocument(false));
  };

  const documentOptionsList = Object.values(Constants.GENERATED_DOCUMENTS);
  for (let i = 0; i < customPoDocs?.length; i++) {
    const doc = customPoDocs[i];
    documentOptionsList.push({
      value: doc.value,
      type: 'custom_po_docs',
      label: doc.value
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '),
    });
  }

  const customPoDocsList = [];
  customPoDocs?.forEach(doc => {
    customPoDocsList.push(doc.value);
  });

  const lotNumberFormatOptionsList = Object.values(Constants.LOT_NUMBER_FORMATS);
  const allSystemDocs = Constants.GENERATED_DOCUMENT_TYPES_LIST + ',' + customPoDocsList;
  return (
    <>
      <VStack align="stretch" spacing="8px">
        {/* <!-- GENERATED DOCUMENTS --> */}
        <DocumentsSection
          {...{
            documents: form.documents.filter(doc => allSystemDocs.includes(doc.docType)),
            isLoading: isGeneratingDocument,
            documentOptions: documentOptionsList,
            title: 'Generated Documents',
            onCancel: handleEmailModalCancel,
            onSubmit: handleDocumentGeneration,
            onEmail: handleOpenEmailModal,
            order: currentOrder,
            isEditable: isInEditMode,
            lotNumberFormatOptions: lotNumberFormatOptionsList,
            grinderIsChinese,
            customPoDocsList,
          }}
        />
      </VStack>
      {emailModalIsOpen ? (
        <DocumentEmailModal
          attachmentList={[state.selectedGeneratedDocument]}
          documentType={get(state.selectedGeneratedDocument, 'docType')}
          isVisible={emailModalIsOpen}
          fmgPONumbers={[form.internalPoNumber]}
          grinderPONumbers={[form.grinderPoNumber]}
          purchaseOrderIds={[form.id]}
          recipients={[user.email]}
          onSend={handleSendEmail}
          onCancel={handleEmailModalCancel}
          error={email.sendEmailError}
          isSendingEmail={email.isSendingEmail}
          recipientsGroup={
            Constants.DOC_ACTION_TYPES[get(state.selectedGeneratedDocument, 'docType').toLowerCase()]
              ? Constants.DOC_ACTION_TYPES[get(state.selectedGeneratedDocument, 'docType').toLowerCase()]
              : Constants.DOC_ACTION_TYPES.cos
          }
          order={form}
          internalPoLabel={internalPoLabel}
        />
      ) : null}
    </>
  );
};

GeneratedDocuments.propTypes = {
  form: PropTypes.shape({
    buyCurrency: PropTypes.string,
    buyIncoterms: PropTypes.string,
    fec: PropTypes.instanceOf(Object),
    halal: PropTypes.bool,
    mtc: PropTypes.bool,
    sellCurrency: PropTypes.string,
    sellIncoterms: PropTypes.string,
    sellUnitOfMeasureId: PropTypes.number,
    internalPoNumber: PropTypes.string,
    grinderPoNumber: PropTypes.string,
    id: PropTypes.number,
    documents: PropTypes.arrayOf(Object),
    documentOptionsList: PropTypes.instanceOf(Object),
  }),
  user: PropTypes.instanceOf(Object),
  email: PropTypes.shape({
    isSendingEmail: PropTypes.bool,
    sendEmailError: PropTypes.string,
  }),
  grinderIsChinese: PropTypes.bool,
  currentOrder: PropTypes.instanceOf(Object),
  isInEditMode: PropTypes.bool,
  internalPoLabel: PropTypes.string,
  dispatch: PropTypes.func,
  token: PropTypes.string,
  setIsGeneratingDocument: PropTypes.func,
  isGeneratingDocument: PropTypes.bool,
  setEmailModalIsOpen: PropTypes.func,
  emailModalIsOpen: PropTypes.bool,
};

export default GeneratedDocuments;
