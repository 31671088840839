import { MARKETS } from '../../../config/markets';

/**
 * Whether the market page is loading
 * @param {*} state
 */
export const selectQualityLoading = state => state.quality.loading;

/**
 * Any page-wide error for the market page
 * @param {*} state
 */
export const selectQualityError = state => state.quality.error;

/**
 * The raw data stored in state, from requests.
 * @param {*} state
 */
export const selectQualityData = state => state.quality.data;

/**
 * The time scale in the filter ui, i.e., monthly quarterly or yearly
 * @param {*} state
 */
export const selectQualityTimeScale = state => state.quality.timeScale;

/**
 * The time period selected by the date picker
 * @param {*} state
 */
export const selectQualityTimePeriod = state => state.quality.timePeriod;

/**
 * The packer_plant selected in the packer_plant filter. It's either an id of
 * an available packer_plant, or, PACKER_PLANTS_ALL
 * @param {*} state
 */
export const selectQualityPackerPlant = state => state.quality.packerPlant;

/**
 * The grinder selected in the grinder filter. It's either an id of
 * an available grinder, or, GRINDERS_ALL
 * @param {*} state
 */
 export const selectQualityGrinder = state => state.quality.grinder;

/**
 * The active market
 * @param {*} state
 */
export const selectQualityMarket = state => MARKETS[state.quality.marketKey];

/**
 * The last time data was update for this timeScale and timePeriod
 * @param {*} state
 */
/* eslint-disable-next-line no-unused-vars */
export const selectQualityLastUpdated = _state => new Date();
