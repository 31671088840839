import React from 'react';
import { Radio } from 'antd';
import { MARKET_FOCUS } from '../../../../slices/markets/marketsConfig';
import PropTypes from 'prop-types';

export default function FilterFocus(props) {
  const { activeFocus, onActiveFocusChange } = props;
  return (
    <div>
      <Radio.Group value={activeFocus} onChange={e => onActiveFocusChange(e.target.value)}>
        <Radio.Button value={MARKET_FOCUS.DOMESTIC}>Domestic</Radio.Button>
        <Radio.Button value={MARKET_FOCUS.EXPORT}>Export</Radio.Button>
      </Radio.Group>
    </div>
  );
}

FilterFocus.propTypes = {
  activeFocus: PropTypes.string.isRequired,
  onActiveFocusChange: PropTypes.func.isRequired,
};
