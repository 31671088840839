import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/snippets/json';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'brace/ext/searchbox';
import 'ace-builds/webpack-resolver';
import { CloseIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';
import './ThemeJSONEditor.scss';

function ThemeJSONEditor({ theme, onValidChange, onInValidChange }) {
  const [localTheme, setLocalTheme] = useState();
  const [isValidJSON, setIsValidJSON] = useState(true);
  useEffect(() => {
    setLocalTheme(theme.data);
  }, []);

  useEffect(() => {
    if (theme.is_loaded) {
      setLocalTheme(JSON.stringify(theme.data, null, 2));
      setIsValidJSON(true);
    }
  }, [theme.is_loaded]);

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const handleChange = validChange => {
    if (isJsonString(validChange)) {
      setIsValidJSON(true);
      onValidChange(JSON.parse(validChange));
    } else {
      setIsValidJSON(false);
      onInValidChange();
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (theme.local_enabled) handleChange(localTheme);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [localTheme]);

  const handleInputChange = changedJSON => {
    setLocalTheme(changedJSON);
  };

  return (
    <Box
      position="relative"
      opacity={theme.local_enabled ? 1 : 0.6}
      className={theme.local_enabled ? 'theme-json-editor__active' : 'theme-json-editor__disabled'}
    >
      {!isValidJSON && <CloseIcon position="absolute" color="red" right="0" />}
      <AceEditor
        mode="json"
        theme="chrome"
        value={localTheme}
        onChange={handleInputChange}
        name="ace_editor"
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
        readOnly={!theme.local_enabled}
      />
    </Box>
  );
}

ThemeJSONEditor.propTypes = {
  theme: PropTypes.objectOf(PropTypes.object).isRequired,
  onValidChange: PropTypes.func.isRequired,
  onInValidChange: PropTypes.func.isRequired,
};

export default ThemeJSONEditor;
