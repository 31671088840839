import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { ArrowDown, ArrowUp } from '../shared/Icons';
import { WEIGHT_UNIT } from './const';
import { format as d3Format } from 'd3-format';

export const siDefinitions = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'k' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'B' },
  { value: 1e12, symbol: 'T' },
];

export function findSiDefinition(value) {
  const abs = Math.abs(value);
  let i;
  for (i = siDefinitions.length - 1; i > 0; i--) {
    if (abs >= siDefinitions[i].value) {
      break;
    }
  }
  return siDefinitions[i];
}

export const formatSI = (decimals = 2, minDecimals = 0, desiredSiDefinition = undefined) => {
  const format = formatDecimal(decimals, minDecimals);
  return num => {
    if (!isDef(num)) return undefined;

    const isNegative = num < 0;

    const abs = Math.abs(num);
    const si = desiredSiDefinition || findSiDefinition(abs);
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const numberPart = format(abs / si.value).replace(rx, '$1');
    const decimalPart = numberPart.split('.')[1];
    let missingDecimals = '';
    if (minDecimals > 0) {
      missingDecimals =
        decimalPart === undefined
          ? `.${getStringWithZeros(minDecimals)}`
          : getStringWithZeros(minDecimals - decimalPart.length);
    }
    return (isNegative ? '-' : '') + numberPart + missingDecimals + si.symbol;
  };
};

export const formatDecimal =
  (decimals = 2, minDecimals = 0) =>
  raw => {
    if (!isDef(raw)) return '-';
    return raw.toLocaleString(undefined, {
      maximumFractionDigits: decimals,
      minimumFractionDigits: minDecimals,
    });
  };

export const formatDollars = (decimals = 2, desiredSiDefinition = undefined) => {
  // Min because sometimes you want to round, but still
  // want the -$ logic so you pass in 0 decimals.
  const fmt = formatSI(decimals, Math.min(decimals, 2), desiredSiDefinition);

  return raw => {
    if (!isDef(raw)) return '-';
    if (raw < 0) {
      return `-$${fmt(raw * -1)}`;
    }
    return `$${fmt(raw)}`;
  };
};

export const formatPercent =
  (decimals = 2) =>
  (raw, allValuesList) => {
    if (!isDef(raw)) return '-';
    const formatted = `${(raw * 100).toLocaleString(undefined, {
      maximumFractionDigits: raw < 0.01 && raw !== 0 ? 2 : decimals,
      minimumFractionDigits: raw < 0.01 && raw !== 0 ? 2 : decimals,
    })}%`;
    return formatted;
  };

export const formatChange = growth => raw => {
  if (raw > 0) {
    return <ArrowUp fill={growth === 'positive' ? '#27AE60' : '#EB5757'} />;
  }
  return <ArrowDown fill={growth === 'positive' ? '#EB5757' : '#27AE60'} />;
};

export const format = formatter => raw => {
  switch (formatter) {
    case 'percent':
      return formatPercent(2)(raw);
    case 'dollars':
      return `$${formatSI(2, 2)(raw)}`;
    default:
      return raw;
  }
};

const isDef = num => !isNaN(num) && num !== null && num !== undefined && num !== '';

/**
 * Format a quantity value (which internally are kilograms) into display values based on the active markets units.
 * @param {*} market
 * @param {*} decimals
 * @param {*} minDecimals
 * @param {*} showUnit  Whether to show the lb/kg units
 */
export const formatMarketQuantity = (market, decimals = 2, minDecimals = 0, showUnit = true) => {
  const fmt = formatSI(decimals, minDecimals);
  switch (market.quantityUnit) {
    case WEIGHT_UNIT.POUND: {
      return lb => {
        return fmt(lb) + (showUnit ? ' lb' : '');
      };
    }
    case WEIGHT_UNIT.KILOGRAM:
    default: {
      return kg => {
        return fmt(kg) + (showUnit ? ' kg' : '');
      };
    }
  }
};

const getStringWithZeros = number => {
  let res = '';
  for (let i = 0; i < number; i++) {
    res += '0';
  }
  return res;
};

export const singleMonthLetter = interval => {
  return interval[0];
};

export const formatWithCommas = d3Format(',');

export function getRemainder(number, decimals = 4) {
  const remainder = number - Math.floor(number);
  return remainder.toFixed(decimals);
}

export function largestRemainderRound(dataObject, desiredTotal = 100, decimals = 0) {
  const MULTIPLIER = 10 ** decimals;
  const entries = Object.entries(dataObject);
  if (isEmpty(entries)) return entries;
  const result = entries
    .map(([key, value]) => {
      return {
        key,
        value,
        floor: Math.floor(value * MULTIPLIER) / MULTIPLIER || value,
        remainder: getRemainder(value) || -1,
      };
    })
    .sort((a, b) => {
      return b.remainder - a.remainder;
    });

  const lowerSum = result.reduce((sum, current) => {
    return sum + current.floor;
  }, 0);

  const delta = parseFloat((desiredTotal - lowerSum).toFixed(decimals));
  const increment = 1 / MULTIPLIER;

  if (lowerSum > 0) {
    for (let i = 0; i < delta; i += increment) {
      const index = parseInt((i / increment) % result.length);
      result[index].floor += increment;
    }
  }

  const augmentedDataObject = result.reduce((agg, current) => {
    return { ...agg, [current.key]: current.floor };
  }, {});
  return augmentedDataObject;
}

// Not used
// export function normaliseBy(precision, value) {
//   // eslint-disable-next-line no-restricted-properties
//   const normalisedValue = value * Math.pow(10, precision);
//   return formatSI(4)(normalisedValue);
// }
