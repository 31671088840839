import React from 'react';

const ProductAge = ({ configsObject }) => {
  return (
    <div className="row" style={{ marginTop: '30px', height: '200px' }}>
      <div className="col-sm-6">
        <h4>Product Age</h4>
        <p>
          <strong>Spec: 7 Days 10:1, 5 Days 4:1</strong>
        </p>
        {configsObject.product_age_avg_days ? (
          <table className="kpi-table">
            <tbody>
              <tr>
                <td>Avg Days</td>
                <td>Target</td>
                <td>Variance</td>
              </tr>
              <tr>
                <td>
                  <span>{configsObject.product_age_avg_days.value_float}</span>
                </td>
                <td>
                  <span style={{ color: '#63729D' }}>{configsObject.product_age_target.value_float}</span>
                </td>
                <td>
                  <span
                    style={
                      configsObject.product_age_target.value_float - configsObject.product_age_avg_days.value_float > 0
                        ? { color: 'green' }
                        : { color: 'red' }
                    }
                  >
                    {(
                      configsObject.product_age_target.value_float - configsObject.product_age_avg_days.value_float
                    ).toFixed(2)}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          false
        )}
      </div>
      <div className="col-sm-6">
        <h4>% Raw Material Price Forecast Accuracy</h4>
        {configsObject.rmpfa_30days ? (
          <table className="forecast-accuracy-table">
            <tbody>
              <tr>
                <td />
                <td>Actual</td>
                <td>Target</td>
                <td>Variance</td>
              </tr>
              <tr>
                <td>30 Days</td>
                <td>
                  <span>{configsObject.rmpfa_30days.value_float}</span>
                </td>
                <td>
                  <span style={{ color: '#63729D' }}>{configsObject.rmpfa_30days_target.value_float}</span>
                </td>
                <td>
                  <span
                    style={
                      configsObject.rmpfa_30days.value_float - configsObject.rmpfa_30days_target.value_float > 0
                        ? { color: 'green' }
                        : { color: 'red' }
                    }
                  >
                    {(configsObject.rmpfa_30days.value_float - configsObject.rmpfa_30days_target.value_float).toFixed(
                      2
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td>60 Days</td>
                <td>
                  <span>{configsObject.rmpfa_60days.value_float}</span>
                </td>
                <td>
                  <span style={{ color: '#63729D' }}>{configsObject.rmpfa_60days_target.value_float}</span>
                </td>
                <td>
                  <span
                    style={
                      configsObject.rmpfa_60days.value_float - configsObject.rmpfa_60days_target.value_float > 0
                        ? { color: 'green' }
                        : { color: 'red' }
                    }
                  >
                    {(configsObject.rmpfa_60days.value_float - configsObject.rmpfa_60days_target.value_float).toFixed(
                      2
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td>90 Days</td>
                <td>
                  <span>{configsObject.rmpfa_90days.value_float}</span>
                </td>
                <td>
                  <span style={{ color: '#63729D' }}>{configsObject.rmpfa_90days_target.value_float}</span>
                </td>
                <td>
                  <span
                    style={
                      configsObject.rmpfa_90days.value_float - configsObject.rmpfa_90days_target.value_float > 0
                        ? { color: 'green' }
                        : { color: 'red' }
                    }
                  >
                    {(configsObject.rmpfa_90days.value_float - configsObject.rmpfa_90days_target.value_float).toFixed(
                      2
                    )}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          false
        )}
      </div>
    </div>
  );
};

export default ProductAge;
