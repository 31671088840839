import api from '../api';
import Constants from '../Constants';

export const FDR_ITEMS_FETCHED = 'FDR_ITEMS_FETCHED';
export const FDR_ITEM_ADDED = 'FDR_ITEM_ADDED';
export const FDR_ITEM_UPDATED = 'FDR_ITEM_UPDATED';
export const FDR_ITEM_REMOVED = 'FDR_ITEM_REMOVED';
export const FDR_ITEM_ERROR = 'FDR_ITEM_ERROR';

const basePath = '/fdr_items';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export const loadFDRItems = FDRItems => {
  return {
    type: FDR_ITEMS_FETCHED,
    payload: FDRItems,
  };
};

export const addFDRItem = FDRItem => {
  return {
    type: FDR_ITEM_ADDED,
    payload: FDRItem,
  };
};

export const removeFDRItem = FDRItem => {
  return {
    type: FDR_ITEM_REMOVED,
    payload: FDRItem,
  };
};

export const updateFDRItem = FDRItem => {
  return {
    type: FDR_ITEM_UPDATED,
    payload: FDRItem,
  };
};

export const errorFDRItems = error => {
  return {
    type: FDR_ITEM_ERROR,
    payload: error,
  };
};

/* ---------------------------------
    THUNKS
-----------------------------------*/
export const fetchFDRItems = () => dispatch => {
  return api
    .get(basePath)
    .then(response => Constants.handleErrors(response, dispatch, errorFDRItems))
    .then(json => dispatch(loadFDRItems(json)))
    .catch(error => dispatch(errorFDRItems(error)));
};

export const createFDRItem = payload => dispatch => {
  return api
    .post(basePath, payload)
    .then(response => Constants.handleErrors(response, dispatch, errorFDRItems))
    .then(json => dispatch(addFDRItem(json)))
    .catch(error => dispatch(errorFDRItems(error)));
};

export const patchFDRItem = FDRItem => dispatch => {
  const url = `${basePath}/${FDRItem.id}`;
  return api
    .patch(url, FDRItem)
    .then(response => Constants.handleErrors(response, dispatch, errorFDRItems))
    .then(json => dispatch(updateFDRItem(json)))
    .catch(error => dispatch(errorFDRItems(error)));
};

export const deleteFDRItem = FDRItem => dispatch => {
  const url = `${basePath}/${FDRItem.id}`;
  return api
    .delete(url)
    .then(response => Constants.handleErrors(response, dispatch, errorFDRItems))
    .then(() => dispatch(removeFDRItem(FDRItem)))
    .catch(error => dispatch(errorFDRItems(error)));
};
