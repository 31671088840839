import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Modal,
  Button,
  Alert,
  AlertIcon,
  AlertDescription,
  VStack,
  Image,
  Center,
  ButtonGroup,
} from '@chakra-ui/react';
import { get, orderBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Datetime from 'react-datetime';
import NoDataImageSrc from '../../../assets/no-data.svg';

class ConfirmYourSelectionModal extends Component {
  static propTypes = {
    isVisible: PropTypes.bool,
    orders: PropTypes.arrayOf(PropTypes.object),
    orderDeliveryDates: PropTypes.instanceOf(Object),
    inputProducts: PropTypes.arrayOf(PropTypes.object),
    onCloseModal: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    isSubmitting: false,
    isConfirmed: false,
  };

  componentDidUpdate(prevProps) {
    const { orders } = this.props;
    const { isSubmitting } = this.state;
    if (isSubmitting && prevProps.orders !== orders) {
      this.setState({
        isSubmitting: false,
        isConfirmed: true,
      });
    }
  }

  handleConfirm = () => {
    const { orders, orderDeliveryDates, onSubmit } = this.props;
    const updatedOrders = orders
      .map(order => ({
        ...order,
        delivery_date: orderDeliveryDates[order.id],
      }))
      .sort((a, b) => new Date(a.delivery_date) - new Date(b.delivery_date));
    this.setState({ isSubmitting: true }, () => {
      onSubmit(updatedOrders);
    });
  };

  renderDeliveryDate = order => {
    return (
      <div>
        <input {...order} />
      </div>
    );
  };

  getColumns = () => [
    {
      title: 'PO#',
      dataIndex: 'internal_po_number',
    },
    {
      title: 'Grinder PO#',
      dataIndex: 'grinder_po_number',
    },
    {
      title: 'Delivery Date',
      dataIndex: 'delivery_date',
      render: order => (
        <Datetime
          id="delivery_date"
          inputProps={{
            placeholder: '',
            className: 'form-control',
            style: { left: 0 },
            readOnly: true,
          }}
          closeOnSelect
          value={
            this.props.orderDeliveryDates[order.id] && moment(this.props.orderDeliveryDates[order.id], 'YYYY-MM-DD')
          }
          renderDeliveryDate={order => this.renderDeliveryDate(order)}
          onChange={value => {
            try {
              this.props.onDateChange(order.id, value);
            } catch (e) {
              this.props.onDateChange(order.id, null);
            }
          }}
          timeFormat={false}
          dateFormat="MM-DD-YYYY"
        />
      ),
    },
    {
      title: 'Use by Date',
      dataIndex: 'used_by_date',
    },
    {
      title: 'CL',
      dataIndex: 'cl_point',
    },
    {
      title: 'Grinder Delivered Price',
      dataIndex: 'price',
    },
    {
      title: 'Coldstore',
      dataIndex: 'coldstore',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: order => (
        <button
          key={order.id}
          type="button"
          className="fa fa-trash"
          onClick={() => {
            const updatedOrderIdList = this.props.orders
              .filter(selectedOrder => selectedOrder.id !== order.id)
              .map(filteredOrder => filteredOrder.id);
            this.props.setUpdatedOrderIds(updatedOrderIdList);
          }}
          style={{ cursor: 'pointer', border: 'none', backgroundColor: 'transparent' }}
        />
      ),
    },
  ];

  getOrderDeliveryDates = order => get(this.props.orderDeliveryDates, order.id);

  getDataSource = () => {
    const lines = [];
    const orders = orderBy(this.props.orders, order => this.getOrderDeliveryDates(order));
    orders.forEach(order => {
      order.lines.forEach(line => {
        const usedByDate = line.used_by_date && moment(line.used_by_date, 'YYYY-MM-DD');
        const formattedUsedByDate = usedByDate && usedByDate.format('MM-DD-YYYY');
        lines.push({
          key: line.id,
          internal_po_number: order.internal_po_number,
          grinder_po_number: order.grinder_po_number,
          delivery_date: order,
          used_by_date: formattedUsedByDate || '',
          cl_point: get(line, 'input_product.cl'),
          price: line.sell_price_per_unit ? line.sell_price_per_unit.toFixed(4) : 0,
          coldstore: order.coldstore_details ? order.coldstore_details.cold_store.location_name : null,
          actions: order,
        });
      });
    });
    return lines;
  };

  renderConfirmation = () => {
    const columns = this.getColumns();
    const dataSource = this.getDataSource();

    return (
      <VStack align="stretch" spacing="24px" paddingX="32px">
        <Text as="p">
          The following orders will be sent to their respective grinder(s). Click &apos;Confirm&apos; to proceed.
        </Text>
        <TableContainer overflowX="visible" overflowY="visible" borderWidth="1px" borderRadius="2px" maxHeight="400px" style={{ overflowY: 'auto' }} >
          <Table size="sm">
            <Thead bg="gray.100">
              <Tr>
                {columns.map(col => (
                  <Th key={col.dataIndex} color="black" fontSize="12px" padding="10px 8px">
                    {col.title.split(' ').map((text, index) => (
                      <>
                        {text}
                        {col.title.split(' ').length - 1 !== index && <br />}
                      </>
                    ))}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody backgroundColor="white">
              {dataSource.length > 0 ? (
                dataSource.map(data => (
                  <Tr key={data.key}>
                    <Td>{data.internal_po_number}</Td>
                    <Td>{data.grinder_po_number}</Td>
                    <Td>
                      <Datetime
                        id="delivery_date"
                        inputProps={{
                          placeholder: '',
                          className: 'form-control',
                          style: { left: 0, width: '220px' },
                          readOnly: true,
                        }}
                        closeOnSelect
                        value={
                          this.props.orderDeliveryDates[data.delivery_date.id] &&
                          moment(this.props.orderDeliveryDates[data.delivery_date.id], 'YYYY-MM-DD')
                        }
                        renderDeliveryDate={() => this.renderDeliveryDate(data.delivery_date)}
                        onChange={value => {
                          try {
                            this.props.onDateChange(data.delivery_date.id, value);
                          } catch (e) {
                            this.props.onDateChange(data.delivery_date.id, null);
                          }
                        }}
                        timeFormat={false}
                        dateFormat="MM-DD-YYYY"
                      />
                    </Td>
                    <Td>{data.used_by_date}</Td>
                    <Td>{data.cl_point}</Td>
                    <Td>{data.price}</Td>
                    <Td>{data.coldstore}</Td>
                    <Td textAlign="center">
                      <button
                        type="button"
                        key={data.actions.id}
                        className="fa fa-trash"
                        onClick={() => {
                          const updatedOrderIdList = this.props.orders
                            .filter(selectedOrder => selectedOrder.id !== data.actions.id)
                            .map(filteredOrder => filteredOrder.id);
                          this.props.setUpdatedOrderIds(updatedOrderIdList);
                        }}
                        style={{ cursor: 'pointer', border: 'none', backgroundColor: 'transparent' }}
                      />
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr backgroundColor="card.default">
                  <Td colSpan={columns.length}>
                    <Center height="102px">
                      <Image src={NoDataImageSrc} alt="no data" />
                    </Center>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    );
  };

  renderResult = () => (
    <Alert
      status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      bgColor="inherit"
    >
      <AlertIcon boxSize="50px" color="actionSecondary.default" />
      <AlertDescription>
        <Text as="p">Thanks!</Text>
        <Text as="p">The selected load(s) can now be scheduled for delivery.</Text>
        <Text as="p">An email has been sent to internal users and respective grinders.</Text>
      </AlertDescription>
    </Alert>
  );

  handleExit = () => {
    const { onCloseModal } = this.props;
    this.setState({
      isLoading: false,
      isConfirmed: false,
    });
    onCloseModal();
  };

  render() {
    const { isVisible, orders, goToScheduleTab, onCloseModal } = this.props;
    const { isSubmitting, isConfirmed } = this.state;
    return (
      <Modal isOpen={isVisible} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent maxWidth="860px">
          <ModalHeader borderBottom="1px" borderColor="gray.200" paddingLeft="48px" marginBottom="17px">
            <Text as="p" fontSize="20px" fontWeight="bold">
              Confirm Your Selection
            </Text>
            <ModalCloseButton marginTop="8px" />
          </ModalHeader>
          <ModalBody paddingX={0}>{isConfirmed ? this.renderResult() : this.renderConfirmation()}</ModalBody>
          <ModalFooter borderTopWidth="1px" marginTop="48px">
            <ButtonGroup spacing="8px">
              <Button
                variant={this.getDataSource().length > 0 ? 'outline' : 'solid'}
                colorScheme={this.getDataSource().length < 1 ? 'actionPrimary' : null}
                height="40px"
                width="116px"
                onClick={onCloseModal}
              >
                Close
              </Button>
              <Button
                colorScheme="actionPrimary"
                height="40px"
                width="112px"
                onClick={this.handleConfirm}
                isLoading={isSubmitting}
                hidden={isConfirmed || orders.length === 0}
              >
                Confirm
              </Button>
              <Button
                colorScheme="actionPrimary"
                height="40px"
                width="112px"
                onClick={() => {
                  this.handleExit();
                  goToScheduleTab();
                }}
                hidden={!isConfirmed}
              >
                Go to Schedule
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
}

export default ConfirmYourSelectionModal;
