import React, { Component } from 'react';
import POTable from '../../../containers/packers/PackerOffersTable';

class PackerOffersTableSection extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: this.props.expand };
  }

  render() {
    const capitalizedText = { textTransform: 'capitalize' };

    return (
      <div className="col-sm-12">
        <h4 style={capitalizedText}>
          {this.props.status} Offers
          <i
            onClick={() => {
              this.setState({ expand: !this.state.expand });
            }}
            className={`collapsing-icon fa fa-caret-right ${this.state.expand ? 'opened' : ''}`}
          />
        </h4>
        <hr />
        {this.state.expand ? (
          <POTable
            items={this.props.items}
            openConfirm={this.props.openConfirm}
            cancel={this.props.cancel}
            edit={this.props.edit}
            editPurchaseOrder={this.props.editPurchaseOrder}
          />
        ) : (
          false
        )}
      </div>
    );
  }
}

export default PackerOffersTableSection;
