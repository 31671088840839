import { FormLabel, HStack, Input, Wrap } from '@chakra-ui/react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import POFormFieldLabel from '../edit-form/POFormFieldLabel';
import CustomFormControl from '../../../core/CustomFormControl';
import {
  convertCartonsToUnitOfMeasure,
  convertUnitOfMeasureToCartons,
  lookupUomName,
} from '../InternationalOrderLine/helpers';
import './QuantityFields.scss';

class QuantityFields extends Component {
  static propTypes = {
    index: PropTypes.any.isRequired,
    buyQuantity: PropTypes.any,
    buyUnitOfMeasureId: PropTypes.number,
    sellQuantity: PropTypes.any,
    sellUnitOfMeasureId: PropTypes.number,
    ingredient: PropTypes.instanceOf(Object),
    packerUid: PropTypes.string,
    purchasingOffice: PropTypes.string,
    unitsOfMeasure: PropTypes.any,
    errors: PropTypes.arrayOf(PropTypes.string),
    requiredFieldNames: PropTypes.arrayOf(PropTypes.string),

    isInEditMode: PropTypes.bool.isRequired,
    isFrozenProduct: PropTypes.bool,

    onLineValueChange: PropTypes.func.isRequired,
    recvQuantity: PropTypes.number,
    isRecvQuantityEditable: PropTypes.bool,
    packageWeights: PropTypes.arrayOf(Object),
    purchaseOrderId: PropTypes.string,
    isCartonWeightRequired: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.onRecvQuantityChange = this.onRecvQuantityChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      index,
      unitsOfMeasure,
      buyUnitOfMeasureId,
      sellUnitOfMeasureId,
      buyQuantity,
      isFrozenProduct,
      onLineValueChange,
      packerUid,
      ingredient,
      purchasingOffice,
      packageWeights,
    } = this.props;

    if (prevProps.buyQuantity !== buyQuantity) {
      this.updateSellQuantity();
    }

    if (prevProps.buyUnitOfMeasureId !== buyUnitOfMeasureId) {
      if (isFrozenProduct) {
        // Frozen lines have packages.  Updating packageCount
        const buyUom = unitsOfMeasure.find(item => item.id === buyUnitOfMeasureId);
        const updatedPackageCount = convertUnitOfMeasureToCartons(
          buyQuantity,
          buyUom,
          ingredient,
          packerUid,
          purchasingOffice,
          packageWeights
        );
        onLineValueChange('packageCount', index, updatedPackageCount);
      }
      this.updateSellQuantity();
    }

    if (prevProps.sellUnitOfMeasureId !== sellUnitOfMeasureId) {
      this.updateSellQuantity();
    }
  }

  static calculateSellQuantity({
    buyQuantity,
    isFrozen,
    uomList,
    buyUomId,
    sellUomId,
    inputProduct,
    packerUid,
    purchasingOffice,
    packageWeights,
  }) {
    const parsedBuyQuantity = parseFloat(buyQuantity);
    const buyUom = uomList.find(item => item.id === buyUomId);
    const sellUom = uomList.find(item => item.id === sellUomId);
    // For frozen loads, both buy quantity and sell quantity are derived from carton count
    // So to get the sell quantity from the buy quantity this is the path we must take:
    // Buy Quantity --> Converted to Cartons --> Converted To Sell Quantity
    if (isFrozen) {
      const buyQuantityInCartons = convertUnitOfMeasureToCartons(
        parsedBuyQuantity,
        get(buyUom, 'name'),
        inputProduct,
        packerUid,
        purchasingOffice,
        packageWeights
      );
      const sellQuantityInSellUom = convertCartonsToUnitOfMeasure(
        buyQuantityInCartons,
        get(sellUom, 'name'),
        inputProduct,
        packerUid,
        purchasingOffice,
        packageWeights
      );
      return sellQuantityInSellUom;
    }

    // For fresh loads we convert directly from Buy Quantity --> Sell Quantity
    if (buyUom !== sellUom) {
      const gramsPerBuyUom = get(buyUom, 'conversionRate');
      const gramsPerSellUom = get(sellUom, 'conversionRate');

      if (!gramsPerBuyUom || !gramsPerSellUom) return '';

      const sellQuantityInSellUom = (parsedBuyQuantity * gramsPerBuyUom) / gramsPerSellUom;
      return sellQuantityInSellUom;
    }

    return parsedBuyQuantity;
  }

  updateSellQuantity() {
    const {
      buyQuantity,
      buyUnitOfMeasureId,
      sellUnitOfMeasureId,
      unitsOfMeasure,
      index,
      onLineValueChange,
      isFrozenProduct,
      ingredient,
      packerUid,
      purchasingOffice,
      packageWeights,
    } = this.props;

    const sellQuantity = QuantityFields.calculateSellQuantity({
      buyQuantity,
      isFrozen: isFrozenProduct,
      uomList: unitsOfMeasure,
      buyUomId: buyUnitOfMeasureId,
      sellUomId: sellUnitOfMeasureId,
      inputProduct: ingredient,
      packerUid,
      purchasingOffice,
      packageWeights,
    });
    onLineValueChange('sellQuantity', index, sellQuantity);
  }

  onRecvQuantityChange(e) {
    const { index, onLineValueChange } = this.props;
    onLineValueChange('recvQuantity', index, get(e, 'target.value'));
  }

  onBuyQuantityChange(e) {
    const {
      index,
      onLineValueChange,
      isFrozenProduct,
      unitsOfMeasure,
      buyUnitOfMeasureId,
      ingredient,
      packerUid,
      purchasingOffice,
      packageWeights,
    } = this.props;

    const buyQuantity = get(e, 'target.value');

    onLineValueChange('buyQuantity', index, buyQuantity);

    // Update carton count for frozen products (fresh loads don't have cartons)
    if (isFrozenProduct) {
      const buyUom = lookupUomName(unitsOfMeasure, buyUnitOfMeasureId);
      const cartons = convertUnitOfMeasureToCartons(
        buyQuantity,
        buyUom,
        ingredient,
        packerUid,
        purchasingOffice,
        packageWeights
      );
      onLineValueChange('packageCount', index, cartons);
    }
  }

  onQuantityChange(e, name) {
    const { index, onLineValueChange } = this.props;
    onLineValueChange(name, index, get(e, 'target.value'));
  }

  handleBlur(e) {
    e.target.blur();
  }

  render() {
    const {
      unitsOfMeasure,
      buyUnitOfMeasureId,
      sellUnitOfMeasureId,
      isInEditMode,
      errors,
      purchaseOrderId,
      isCartonWeightRequired,
      isRecvQuantityEditable,
      requiredFieldNames,
    } = this.props;

    // this is to have first order line's uom labels displayed correctly when selecting uom fields
    const buyUomName = lookupUomName(unitsOfMeasure, buyUnitOfMeasureId);
    const sellUomName = lookupUomName(unitsOfMeasure, sellUnitOfMeasureId);
    const lineErrors = errors || [];
    return (
      <Wrap spacing="18px !important">
        {/* <!-- QUANTITY BUY --> */}
        <HStack spacing="14px">
          <CustomFormControl width="166px">
            <POFormFieldLabel
              label="Quantity Buy"
              htmlFor="quantity_buy"
              fieldName="buyQuantity"
              isRequired={requiredFieldNames.includes('buyQuantity')}
              tooltip="Specifies the weight based on the values selected in UOM (Buy)."
            />
            <HStack spacing="8px">
              <Input
                isDisabled={!buyUnitOfMeasureId || !isInEditMode}
                id="buy-quantity"
                name="quantity"
                type="number"
                value={this.props.buyQuantity || ''}
                onChange={e =>
                  isCartonWeightRequired ? this.onBuyQuantityChange(e) : this.onQuantityChange(e, 'buyQuantity')
                }
                className={lineErrors.includes('buyQuantity') ? 'error' : ''}
                placeholder="Buy"
                onWheelCapture={e => {
                  e.target.blur();
                }}
                width="125px"
                height="40px"
              />
              <FormLabel htmlFor="buy-quantity">{buyUomName}</FormLabel>
            </HStack>
          </CustomFormControl>
          {/* <!-- QUANTITY SELL --> */}
          <CustomFormControl width="166px">
            <POFormFieldLabel
              label="Quantity Sell"
              htmlFor="quantity_sell"
              fieldName="sellQuantity"
              isRequired={requiredFieldNames.includes('sellQuantity')}
              tooltip="Specifies the weight based on the values selected in UOM (Sell)."
            />
            <HStack spacing="8px">
              <Input
                isDisabled={!isInEditMode}
                readOnly={isCartonWeightRequired}
                onChange={e => (!isCartonWeightRequired ? this.onQuantityChange(e, 'sellQuantity') : null)}
                id="sell-quantity"
                value={this.props.sellQuantity || ''}
                type="number"
                className={lineErrors.includes('sellQuantity') ? 'error' : ''}
                backgroundColor={isInEditMode ? 'white' : 'gray.100'}
                placeholder="Sell"
                onWheelCapture={e => {
                  e.target.blur();
                }}
                width="125px"
                height="40px"
              />
              <FormLabel htmlFor="sell-quantity">{sellUomName}</FormLabel>
            </HStack>
          </CustomFormControl>
        </HStack>

        {purchaseOrderId && (
          <CustomFormControl width="165px">
            <POFormFieldLabel
              label="Quantity Received"
              htmlFor="quantity-received"
              tooltip="Specifies the weight based on the values selected in UOM (Sell)."
            />
            <HStack>
              <Input
                isDisabled={!isInEditMode || !isRecvQuantityEditable}
                onChange={this.onRecvQuantityChange}
                id="quantity-received"
                value={this.props.recvQuantity || ''}
                type="number"
                backgroundColor={!isRecvQuantityEditable || !isInEditMode ? 'gray.100' : 'white'}
                placeholder="Sell"
                onWheelCapture={this.handleBlur}
                width="125px"
                height="40px"
              />
              <FormLabel htmlFor="quantity-received">{sellUomName}</FormLabel>
            </HStack>
          </CustomFormControl>
        )}
      </Wrap>
    );
  }
}

export default QuantityFields;
