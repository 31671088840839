import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createScopedAnnotationSelector,
  TIME_SCALE,
  createAnnotation,
  updateAnnotation,
  deleteAnnotation,
} from '../../slices/annotations/annotations';

import { parse } from 'date-fns';

export const ANNOTATION_MODAL_MODE = {
  EDIT: 'edit',
  ADD: 'add',
};

export default function useAnnotations({ page, module, focusSelector, timeScaleSelector }) {
  const dispatch = useDispatch();
  const annotationsSelector = createScopedAnnotationSelector(page, focusSelector, module, timeScaleSelector);

  const [modalAnnotation, setModalAnnotation] = React.useState(null);
  const [modalMode, setModalMode] = React.useState(ANNOTATION_MODAL_MODE.ADD);
  const showModal = !!modalAnnotation;

  const annotations = useSelector(annotationsSelector);
  const focus = useSelector(focusSelector);
  const timeScale = useSelector(timeScaleSelector);

  const handleAddAnnotation = React.useCallback(
    annotation => {
      dispatch(createAnnotation(annotation));
    },
    [dispatch]
  );

  const handleEditAnnotation = React.useCallback(
    annotation => {
      dispatch(updateAnnotation(annotation));
    },
    [dispatch]
  );

  // ---
  // Public

  const deleteAnnotationCallback = React.useCallback(
    annotation => {
      if (window.confirm('Are you sure you want to delete this annotation?')) {
        dispatch(deleteAnnotation(annotation));
        closeModal();
      }
    },
    [dispatch]
  );

  const onModalSubmit = React.useCallback(
    ({ content, title }) => {
      const newAnnotation = {
        ...modalAnnotation,
        content,
        title,
      };

      if (modalMode === ANNOTATION_MODAL_MODE.ADD) {
        handleAddAnnotation(newAnnotation);
      } else {
        handleEditAnnotation(newAnnotation);
      }

      setModalAnnotation(null);
    },
    [handleAddAnnotation, handleEditAnnotation, modalAnnotation]
  );

  // Old year, interval
  const addNewAnnotation = React.useCallback(
    (...args) => {
      // Handle old way and new way
      // Old way is ('interval', 'year'). New way is (timestamp[, extraContent])
      let timestamp = args[0];
      let extra = args[1] || {};

      if (!(timestamp instanceof Date)) {
        const parser = timeScale === TIME_SCALE.QUARTERLY ? 'yyyy QQQ' : 'yyyy MMM';
        const [year, interval] = args;
        timestamp = parse(`${year} ${interval}`, parser, new Date());
        extra = {};
      }

      const newAnnotation = {
        timestamp,
        page,
        focus,
        module,
        timeScale,
        ...extra,
      };
      setModalAnnotation(newAnnotation);
      setModalMode(ANNOTATION_MODAL_MODE.ADD);
    },
    [page, focus, module, timeScale]
  );

  const editAnnotation = React.useCallback(annotation => {
    setModalAnnotation(annotation);
    setModalMode(ANNOTATION_MODAL_MODE.EDIT);
  }, []);

  const closeModal = React.useCallback(() => {
    setModalAnnotation(null);
  }, []);

  return {
    annotations,
    addNewAnnotation,
    editAnnotation,
    deleteAnnotation: deleteAnnotationCallback,
    closeModal,
    showModal,
    modalMode,
    modalAnnotation,
    onModalSubmit,
  };
}
