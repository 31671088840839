import { Checkbox, Radio, Tooltip, Tree, Typography } from 'antd';
import { format, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import * as React from 'react';
import { formatDollars, formatMarketQuantity, formatSI } from '../../../../modules/format';
import styles from './Claims.module.css';
import { descending } from 'd3';
import Icon from '@ant-design/icons/lib/components/Icon';
import { ChevronRight } from '../../../../shared/Icons';
import {
  AMOUNT_OPTIONS,
  GROUP_BY_OPTIONS,
  STATUS_OPTIONS,
  TIME_RANGE_OPTIONS,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsConfig';
import {
  setQualityClaimsAmount,
  setQualityClaimsCount,
  setQualityClaimsGroupBy,
  setQualityClaimsRegion,
  setQualityClaimsSelectedPackerPlantId,
  setQualityClaimsStatus,
  setQualityClaimsTimeRange,
  updateQualityClaimsTimePeriod,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsReducers';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ThisPeriod from '../NCRs/ThisPeriod';
import { INCIDENTS_RATE_UOM } from '../../../../slices/quality-details-claims/qualityDetailsClaimsConfig';

const ByCategory = props => {
  const { data, categoryColors, timePeriod, timeScale, market, singlePackerPlant, selectedRegion } = props;
  const [dataType, setDataType] = React.useState('count');
  const [withCompareAgainstPrevMonth, setWithCompareAgainstPrevMonth] = React.useState(false);
  const { market: marketParam } = useParams();
  const dispatch = useDispatch();
  const TOLERANCE = 0.01;

  const handleNavigationToDetails = React.useCallback(() => {
    if (singlePackerPlant) {
      dispatch(setQualityClaimsSelectedPackerPlantId(singlePackerPlant.id));
      dispatch(setQualityClaimsRegion(singlePackerPlant.country));
    } else {
      dispatch(setQualityClaimsRegion(selectedRegion));
    }
    dispatch(setQualityClaimsStatus(STATUS_OPTIONS.SUBMITTED));
    dispatch(setQualityClaimsCount(null));
    dispatch(setQualityClaimsAmount(AMOUNT_OPTIONS.TOTAL));
    const now = new Date();
    dispatch(updateQualityClaimsTimePeriod([startOfMonth(subMonths(now, 12)), endOfMonth(now)]));
    dispatch(setQualityClaimsTimeRange(TIME_RANGE_OPTIONS.HISTORICAL));
    dispatch(setQualityClaimsGroupBy(GROUP_BY_OPTIONS.CATEGORY));
  }, [dispatch, selectedRegion, singlePackerPlant]);

  const formatBasedOnDataType = React.useMemo(() => {
    if (dataType === 'count') {
      return val => val;
    } else if (dataType === 'incidentRate') {
      return formatSI(4);
    } else {
      return formatDollars(0);
    }
  }, [dataType]);

  const fieldName = withCompareAgainstPrevMonth
    ? 'previous' + dataType.charAt(0).toUpperCase() + dataType.slice(1)
    : dataType;

  const maxCount = withCompareAgainstPrevMonth
    ? Math.max(...data.map(x => x[dataType]), ...data.map(x => x[fieldName]))
    : Math.max(...data.map(x => x[dataType]));

  const getTreeData = data => {
    if (!data) {
      return [];
    }
    const row = (d, isSecondary, color) => {
      const bar = (isPrevMonth, fieldName) => {
        let title = '';
        if (dataType === 'incidentRate') {
          title = `${
            isPrevMonth ? format(subMonths(timePeriod[0], 1), 'MMMM yyyy') : format(timePeriod[0], 'MMMM yyyy')
          }
          ${formatBasedOnDataType(d[fieldName])} ${INCIDENTS_RATE_UOM[market.key].INCIDENTS_RATE}`;

          // ${formatMarketQuantity(market)(isPrevMonth ? d.previousVolume : d.volume)} impacted volume;
        } else {
          title = `${
            isPrevMonth ? format(subMonths(timePeriod[0], 1), 'MMMM yyyy') : format(timePeriod[0], 'MMMM yyyy')
          } - ${formatBasedOnDataType(d[fieldName])}`;
        }
        return (
          <Tooltip title={title} overlayStyle={{ whiteSpace: 'pre-line' }}>
            <div
              style={{
                width: `${
                  !d[fieldName] || d[fieldName] === 0 || Math.abs(d[fieldName] - 0) < TOLERANCE
                    ? 0
                    : (d[fieldName] * 100) / maxCount
                }%`,
                height: isSecondary ? 7 : 10,
                backgroundColor: color,
                marginTop: isPrevMonth ? '3px' : 0,
                opacity: isPrevMonth ? 0.35 : 1,
              }}
            />
          </Tooltip>
        );
      };

      return (
        <div
          className={`${styles.QualityClaims_tableRow} ${isSecondary ? styles.QualityClaims_tableRowSecondary : ''}`}
          style={{ marginLeft: isSecondary ? -24 : 0 }}
        >
          <div
            style={{
              width: '35%',
              padding: '0 5px',
              paddingLeft: isSecondary ? 24 : 0,
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {isSecondary ? d.subCategory : d.category}
            </div>
          </div>
          <div
            style={{
              width: '15%',
              padding: '0 5px',
              display: 'flex',
            }}
          >
            <div
              className={styles.QualityClaims_tableRowValues}
              style={{
                width: withCompareAgainstPrevMonth ? '50%' : '100%',
                fontWeight: !isSecondary && 'bold',
              }}
            >
              {formatBasedOnDataType(d[dataType])}
            </div>
            {withCompareAgainstPrevMonth && (
              <div className={styles.QualityClaims_tableRowValues} style={{ width: '50%' }}>
                {formatBasedOnDataType(d[fieldName])}
              </div>
            )}
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 5px',
            }}
          >
            {bar(false, dataType)}
            {withCompareAgainstPrevMonth && bar(true, fieldName)}
          </div>
        </div>
      );
    };

    /**
     * If with Compare Against Prev Month checked, hide categories/subcategories with count=0 and previousCount=0
     * If without Compare Against Prev Month checked, hide categories/subcategories with count=0
     * */
    const filterOutZeroCount = data =>
      data.filter(
        d =>
          (withCompareAgainstPrevMonth && (d.count > 0 || d.previousCount > 0)) ||
          (!withCompareAgainstPrevMonth && d.count > 0)
      );

    return filterOutZeroCount(data.slice())
      .slice()
      .sort((a, b) => descending(a.count, b.count))
      .map((category, i) => {
        return {
          title: row(category, false, categoryColors[category.category]),
          key: i + '-' + category.category,
        };
      });
  };

  return (
    <div>
      <div className={styles.QualityClaims_byCategoryTitle}>
        <Link
          to={`/analytic-dashboard/quality/${marketParam}/claims-details`}
          onClick={() => handleNavigationToDetails()}
        >
          <Typography.Text className={styles.QualityClaims_label}>
            by category
            <Icon style={{ position: 'relative', width: 14 }} component={ChevronRight} />
          </Typography.Text>
        </Link>
        <Radio.Group
          onChange={e => {
            setDataType(e.target.value);
          }}
          defaultValue={dataType}
          size="medium"
          className={styles.QualityClaims_radio}
        >
          <Radio.Button key={1} value={'count'}>
            Count
          </Radio.Button>
          <Radio.Button key={2} value={'incidentRate'}>
            Incident Rate
          </Radio.Button>
          <Radio.Button key={3} value={'claimAmount'}>
            Claim amount
          </Radio.Button>
        </Radio.Group>
      </div>
      {data.length > 0 ? (
        <>
          <Tree selectable={false} treeData={getTreeData(data)} blockNode={true} />
          <div className={styles.QualityClaims_byCategoryOptions}>
            <Checkbox
              checked={withCompareAgainstPrevMonth}
              onChange={() => setWithCompareAgainstPrevMonth(!withCompareAgainstPrevMonth)}
            >
              Compare against prev month
            </Checkbox>
          </div>
        </>
      ) : (
        <p>
          No incidents reported yet in <ThisPeriod timeScale={timeScale} timePeriod={timePeriod} />
        </p>
      )}
    </div>
  );
};

export default ByCategory;
