import React from 'react';
import { Route } from 'react-router-dom';
import Helmet from 'react-helmet';

import './AppRoutes.scss';
import AuthGate from '../containers/auth/AuthGate';
import routeConfig from '../routeConfig';
import routeConfigLegacy from '../routeConfigLegacy';
import { getLevelName } from '../utils';
import { useTheme } from '@chakra-ui/react';

const renderLevel = (level, levelRenderer, key) => {
  const levelName = getLevelName(level.name);
  const theme = useTheme();
  return (
    <AuthGate {...level.authConfig} key={key}>
      {level.routeConfig && (
        <Route
          {...level.routeConfig}
          component={routeProps => {
            return (
              <>
                <Helmet>
                  <title>
                    {theme?.home?.title || 'BOAB'} - {levelName}
                  </title>
                </Helmet>
                <div className={`cfc-content ${level.className}`}>
                  <level.component {...routeProps} />
                </div>
              </>
            );
          }}
        />
      )}
      {level.children && level.children.map((child, j) => levelRenderer(child, levelRenderer, `${key}${j}`))}
    </AuthGate>
  );
};

const AppRoutes = ({ nextGen }) => {
  let rc = routeConfigLegacy;
  if (nextGen) {
    rc = routeConfig;
  }
  return <> {rc.groups.map((group, i) => renderLevel(group, renderLevel, `${i}`))} </>;
};

export default AppRoutes;
