import { createSelector } from '@reduxjs/toolkit';
import { group, mean, median, sum } from 'd3';
import { selectTreasuryDetailsShowOutlook } from '../../treasury-details/treasuryDetailsReducers';
import { TREASURY_MODULE } from '../treasuryConfig';
import { selectTreasuryAnnotationsData } from './selectTreasuryBasicData';
import {
  selectTreasuryTimeGroupedData,
  selectTreasuryTimeGroupedDataWithForecast,
} from './selectTreasuryTimeGroupedData';

export const selectTreasuryData = createSelector(
  selectTreasuryTimeGroupedData,
  selectTreasuryTimeGroupedDataWithForecast,
  selectTreasuryAnnotationsData,
  selectTreasuryDetailsShowOutlook,
  (timeGrouped, timeGroupedWithForecast, treasuryAnnotations, showOutlook) => {
    if (!timeGrouped || !timeGroupedWithForecast) return null;

    const annotations = treasuryAnnotations[TREASURY_MODULE.RISK_MANAGEMENT];

    const groupedData = showOutlook ? timeGroupedWithForecast : timeGrouped;

    const data = [];
    groupedData.forEach(year => {
      const newIntervals = year.intervals.map(interval => {
        const totalVolume = sum(interval.orders, order => order.volume_received || 0);
        const totalForecastedVolume = sum(interval.orders, order => order.volume_expected || 0);
        const groupedByOrderType = group(interval.orders, order => order.order_type);

        const gatherData = (array, name) => {
          const volume = sum(array, order => order.volume_received || 0);
          const forecastedVolume = sum(array, order => order.volume_expected || 0);
          const volumeRatio = volume / totalVolume;

          const forecastedVolumeRatio = forecastedVolume / totalForecastedVolume;

          const costSavings = sum(array, order => {
            if (!order.buy_price_per_unit || !order.usda_market_price || !order.volume_received) {
              return 0;
            }
            return (order.usda_market_price - order.buy_price_per_unit) * order.volume_received;
          });
          const costTotal = sum(array, order => {
            if (!order.buy_price_per_unit || !order.volume_received) {
              return 0;
            }
            return order.buy_price_per_unit * order.volume_received;
          });

          const averageCostPrice = mean(array, order => order.buy_price_per_unit);
          const medianCostPrice = median(array, order => order.buy_price_per_unit);
          const usdaCostPrice = mean(array, order => order.usda_market_price);

          return {
            orderType: name,
            volume,
            forecastedVolume,
            volumeRatio,
            forecastedVolumeRatio,
            costSavings,
            costTotal,
            averageCostPrice,
            medianCostPrice,
            usdaCostPrice,
          };
        };

        let breakdown = Array.from(groupedByOrderType.entries()).map(([orderType, typeOrders]) => {
          return gatherData(typeOrders, orderType);
        });

        const total = gatherData(interval.orders, 'Total');
        const hedged = gatherData(
          interval.orders.filter(x => x.order_type === 'Contract' || x.order_type === 'NOF'),
          'Hedged'
        );
        const exposed = gatherData(
          interval.orders.filter(x => x.order_type !== 'Contract' && x.order_type !== 'NOF'),
          'Exposed'
        );
        const fat = gatherData(
          interval.orders.filter(x => parseInt(x.cl, 10) < 76),
          'Fat'
        );
        const lean = gatherData(
          interval.orders.filter(x => parseInt(x.cl, 10) >= 76),
          'Lean'
        );

        breakdown = breakdown.concat(total, hedged, exposed, fat, lean);
        // TODO: calculate interval coverage.

        const matchingAnnotation = annotations.find(
          annotation => annotation.interval === interval.interval && annotation.year === interval.year
        );

        return {
          interval: `${interval.interval} ${interval.year}`,
          rawInterval: { year: interval.year, interval: interval.interval },
          breakdown,
          annotation: matchingAnnotation || null,
        };
      });

      data.push(...newIntervals);
    });

    return data;
  }
);
