import { connect } from 'react-redux';

import ColdstoreManagementComponent from '../../components/pages/ColdstoreManagement';

const mapStateToProps = state => {
  return {
    user: state.user,
    configs:state.configs,
  };
};

const ColdstoreManagement = connect(mapStateToProps)(ColdstoreManagementComponent);

export default ColdstoreManagement;
