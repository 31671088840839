import Constants from '../Constants';

export const GRINDERS_FETCHED = 'GRINDERS_FETCHED';
export const GRINDER_ADDED = 'GRINDER_ADDED';
export const GRINDER_EDIT = 'GRINDER_EDIT';
export const GRINDER_REMOVED = 'GRINDER_REMOVED';
export const GRINDER_ERROR = 'GRINDER_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadGrinders(grinders) {
  return {
    type: GRINDERS_FETCHED,
    payload: grinders,
  };
}

export function addGrinder(grinder) {
  return {
    type: GRINDER_ADDED,
    payload: grinder,
  };
}

export function removeGrinder(grinder) {
  return {
    type: GRINDER_REMOVED,
    payload: grinder,
  };
}

export function editGrinder(grinder) {
  return {
    type: GRINDER_EDIT,
    payload: grinder,
  };
}

export function errorGrinder(error) {
  return {
    type: GRINDER_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchGrinders(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}grinders`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorGrinder))
      .then(json => dispatch(loadGrinders(json)))
      .catch(error => dispatch(errorGrinder(error)));
  };
}

export function fetchOrgGrinders(orgId, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}organisations/${orgId}/grinders`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorGrinder))
      .then(json => dispatch(loadGrinders(json)))
      .catch(error => dispatch(errorGrinder(error)));
  };
}

export function postGrinder(grinder, token) {
  const grinderCopy = { ...grinder };
  return dispatch => {
    delete grinderCopy.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}grinders`, {
      method: 'POST',
      body: JSON.stringify(grinderCopy),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorGrinder))
      .then(json => dispatch(addGrinder(json)))
      .catch(error => dispatch(errorGrinder(error)));
  };
}

export function patchGrinder(grinder, token) {
  const grinderCopy = { ...grinder };
  return dispatch => {
    delete grinderCopy.type;
    grinderCopy.last_po_number = parseFloat(grinderCopy.last_po_number);
    grinderCopy.po_number_prefix = grinderCopy.po_number_prefix ? grinderCopy.po_number_prefix : '';
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}grinders/${grinderCopy.uid}`, {
      method: 'PATCH',
      body: JSON.stringify(grinderCopy),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorGrinder))
      .then(json => dispatch(editGrinder(json)))
      .catch(error => dispatch(errorGrinder(error)));
  };
}

export function deleteGrinder(grinder, token) {
  const grinderCopy = { ...grinder };
  return dispatch => {
    delete grinderCopy.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}grinders/${grinderCopy.uid}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorGrinder))
      .then(() => dispatch(removeGrinder(grinderCopy)))
      .catch(error => dispatch(errorGrinder(error)));
  };
}
