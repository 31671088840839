import React from 'react';
import PropTypes from 'prop-types';
import snakeCase from 'lodash/snakeCase';
import { TabList, Tab, Tag, Center, Text, useTheme } from '@chakra-ui/react';
import { get } from 'lodash';
import { invertColor } from '../../theme/utils';

const propTypes = {
  activeTabIndex: PropTypes.number,
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      isDisabled: PropTypes.bool,
      label: PropTypes.string,
    })
  ),
};

const TabListComponent = ({ tabList, activeTabIndex = 0 }) => {
  const theme = useTheme();

  return (
    <TabList borderBottom="0px solid transparent">
      {tabList.map((tab, idx) => {
        return (
          <Tab
            key={tab.label}
            _selected={{ color: 'black' }}
            color={idx !== activeTabIndex && '#828282'}
            index={`tab-${snakeCase(tab.label)}`}
            isDisabled={tab.isDisabled}
            pr="0px"
            _focus={{ boxShadow: 'none', outline: 'none' }}
          >
            <Tag
              minWidth="24px"
              minHeight="24px"
              colorScheme={idx === activeTabIndex ? 'actionSecondary' : 'whiteBoldScheme'}
              color={
                idx === activeTabIndex
                  ? invertColor(get(theme, 'colors.actionSecondary.default', ''), true)
                  : '#00000040'
              }
              borderWidth="1px"
              borderColor="#00000040"
              borderRadius="full"
              lineHeight="10px"
              fontSize="16px"
            >
              <Center>{idx + 1}</Center>
            </Tag>
            <Text fontWeight="500" ml="5px">
              {tab.label}
            </Text>
            {idx !== tabList.length - 1 && <hr style={{ width: '60px', marginLeft: '10px' }} />}
          </Tab>
        );
      })}
    </TabList>
  );
};

TabListComponent.propTypes = propTypes;

export default TabListComponent;
