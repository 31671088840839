import React, { Component } from 'react';
import { Route, Router } from 'react-router-dom';
import { closeError } from '../actions/index';
// Actions:
import { autoQueryStringLogin, checkForToken } from '../actions/actions_auth';

// Components
import DynamicSidebarMenu from '../containers/layout/DynamicSidebarMenu';
import DynamicLayoutContainer from '../containers/layout/DynamicLayoutContainer';

// Containers:
import Login from '../containers/pages/Login';
// Local Deps
import './App.scss';
import './analyticDashboard.scss';
import Maintenance from './pages/Maintenance';
import ResetPassword from './pages/ResetPassword';
import browserHistory from './utilities/BrowserHistory';
import AppRoutes from './AppRoutes';
import AppError from '../containers/utilities/AppError';
import ThemePanel from './theme_panel/ThemePanel';
import './utilities/FetchInterceptor';

// Hacky maintenance mode indicator. This should change to be
// a database switch, but in the meantime this will have to work.

const MAINTENANCE_MODE = false;

class App extends Component {
  constructor(props) {
    super(props);
    let url = window.location.pathname + window.location.search;
    if (url.indexOf('?') > -1) {
      url = url.substring(0, url.indexOf('?'));
    }
    this.state = {
      relative_path: window.location.pathname + window.location.search,
      path_no_query_string: url,
    };
  }

  componentDidMount() {
    // Check for automatic login via querystring
    // Exception for reset password page
    if (
      this.state.relative_path.indexOf('/reset-password') === -1 &&
      this.state.relative_path.indexOf('/login') === -1
    ) {
      if (getParameterByName('token')) {
        this.props.dispatch(autoQueryStringLogin(getParameterByName('token')));
        window.history.pushState(null, '', window.location.href.split('?')[0]);
      } else {
        this.props.dispatch(checkForToken(browserHistory));
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user !== prevProps.user && !this.props.user) {
      browserHistory.push('/login');
    }
    // Remove old error states that are not relevant for updated components
    this.props.dispatch(closeError(false));
  }

  render() {
    if (MAINTENANCE_MODE) {
      return <Maintenance />;
    }

    return (
      <Router history={browserHistory}>
        <div>
          <AppError />
          {this.props.user ? (
            <DynamicLayoutContainer
              navigation={<DynamicSidebarMenu dispatch={this.props.dispatch} url={this.state.path_no_query_string} />}
              mainContent={
                <>
                  <ThemePanel />
                  <AppRoutes nextGen={this.props.user.next_gen_ui} />
                </>
              }
            />
          ) : (
            <div>
              {this.state.path_no_query_string.indexOf('reset-password') > -1 ? (
                <ResetPassword />
              ) : (
                <Route exact path="/login" component={Login} />
              )}
            </div>
          )}
        </div>
      </Router>
    );
  }
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default App;
