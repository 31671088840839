import Constants from '../Constants';

export const ROLES_FETCHED = 'ROLES_FETCHED';
export const ROLE_ADDED = 'ROLE_ADDED';
export const ROLE_EDIT = 'ROLE_EDIT';
export const ROLE_REMOVED = 'ROLE_REMOVED';
export const ROLE_ERROR = 'ROLE_ERROR';

export function fetchRoles(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}roles`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorRole))
      .then(json => dispatch(loadRoles(json)))
      .catch(error => dispatch(errorRole(error)));
  };
}

export function postRole(role, token) {
  return dispatch => {
    delete role.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}roles`, {
      method: 'POST',
      body: JSON.stringify(role),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorRole))
      .then(json => dispatch(addRole(json)))
      .catch(error => dispatch(errorRole(error)));
  };
}

export function patchRole(role, token) {
  return dispatch => {
    delete role.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}roles/${role.id}`, {
      method: 'PATCH',
      body: JSON.stringify(role),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorRole))
      .then(json => dispatch(editRole(json)))
      .catch(error => dispatch(errorRole(error)));
  };
}

export function deleteRole(role, token) {
  return dispatch => {
    delete role.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}roles/${role.id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorRole))
      .then(json => dispatch(removeRole(role)))
      .catch(error => dispatch(errorRole(error)));
  };
}

export function loadRoles(roles) {
  return {
    type: ROLES_FETCHED,
    payload: roles,
  };
}

export function addRole(role) {
  return {
    type: ROLE_ADDED,
    payload: role,
  };
}

export function removeRole(role) {
  return {
    type: ROLE_REMOVED,
    payload: role,
  };
}

export function editRole(role) {
  return {
    type: ROLE_EDIT,
    payload: role,
  };
}

export function errorRole(error) {
  return {
    type: ROLE_ERROR,
    payload: error,
  };
}
