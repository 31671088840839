import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import Constants from '../../Constants';

export const orderStatusMapSlice = createSlice({
  name: 'orderStatus',
  initialState: { orderStatusMap: {} },
  reducers: {
    setOrderStatusMap: (state, action) => {
      state.orderStatusMap = action.payload;
    },
  },
});

// Actions.
export const { setOrderStatusMap } = orderStatusMapSlice.actions;

export const fetchOrderStatusMap = () => async dispatch => {
  const response = await api.get('/order_management/purchase_orders/statuses');
  const json = await Constants.handleErrors(response, dispatch);
  const orderStatusMap = (json || []).reduce((o, c) => {
    o[c.status] = c.label
    return o;
  }, {})
  dispatch(setOrderStatusMap(orderStatusMap));
  return orderStatusMap;
};

// Selectors.
export const selectOrderStatusMap = state => {
  return state.orderStatusMap.orderStatusMap;
};

// Reducers.
export default orderStatusMapSlice.reducer;
