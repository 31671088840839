import { defaultTo, get } from 'lodash';
import {
  NCR_CREATION_START,
  NCR_CREATION_FINISH,
  LOAD_NCRS,
  NCR_CREATION_ERROR,
  LOAD_NCR_ORDER_START,
  LOAD_NCR_ORDER_ERROR,
  NCR_CREATION_RESET,
  SEARCH_NCRS_FINISH,
  SEARCH_NCRS_ERROR,
  SEARCH_NCRS_START,
  SEARCH_NCRS_RESET,
  LOAD_REVIEW_NCR,
  LOAD_REVIEW_INCIDENTS,
  NCR_ATTACHMENT_UPLOAD_START,
  NCR_ATTACHMENT_UPLOAD_FINISH,
  NCR_ATTACHMENT_UPLOAD_ERROR,
  NCR_ATTACHMENT_UPLOAD_RESET,
  NCR_ATTACHMENT_UPLOAD_SAVE,
  NCR_EDIT_RESET,
  NCR_EDIT_START,
  NCR_EDIT_FINISH,
  NCR_EDIT_ERROR,
  NCR_ATTACHMENT_UPLOAD_SET,
  NCR_DELETE_START,
  NCR_DELETE_FINISH,
  NCR_DELETE_ERROR,
  NCR_DELETE_RESET,
  NCR_RELEASE_START,
  NCR_RELEASE_FINISH,
  NCR_RELEASE_ERROR,
  NCR_RELEASE_RESET,
  LOAD_NCR_SUMMARY,
  NCR_ORDER_ERROR,
  CLEAR_NCR_ORDER_ERROR,
  RESET_REVIEW_NCR,
  INCIDENT_BY_GROUP_ID_START,
  INCIDENT_BY_GROUP_ID_FINISH,
  INCIDENT_BY_GROUP_ID_ERROR,
  LOAD_REVIEW_INCIDENT,
  RESET_REVIEW_INCIDENT,
  ERROR_REVIEW_INCIDENT,
  CLEAR_CHANGE_INCIDENT,
  START_NCR_PDF,
  FINISH_NCR_PDF,
  ERROR_NCR_PDF,
  START_INCIDENT_PDF,
  FINISH_INCIDENT_PDF,
  ERROR_INCIDENT_PDF,
} from '../actions/action_ncrs';
import { isJSON } from '../helpers';

const initialState = {
  ncrPurchaseOrder: null,
  searchResult: [],
  isCreating: false,
  isSearching: false,
  isLoading: false,
  hasCreated: false,
  hasError: false,
  errorMessage: '',
  currentNCR: null,
  createdIncidents: null,
  uploadedAttachments: [],
  isUploading: false,
  isButtonDisabled: false,
  uploadCompleted: false,
  isUpdating: false,
  hasUpdated: false,
  isDeleting: false,
  hasDeleted: false,
  refreshSearch: false,
  isIncidentByGroupLoading: false,
  hasIncidentbyGroupLoading: false,
  isChangeToNCRSart: false,
  hasChangeToNCRFinish: false,
  hasChangeToNCRError: false,
  summary: {},
  connectIncidents: {},
  changeIncident: null,
  incidentPDF: null,
  isNCRPDFStarted: false,
  hasNCRPDFDownloaded: false,
  errorNCRPDF: false,
  isIncidentPDFStarted: false,
  hasIncidentPDFDownloaded: false,
  errorIncidentPDF: false,
  pagination: {
    has_next: false,
    has_previous: false,
    next_page: null,
    pages: 0,
    previous_page: null,
    total: 0,
  },
};

const getErrorMessage = payload => {
  const title = get(isJSON(payload) ? JSON.parse(payload) : payload, 'title', '');
  if (title) {
    return title;
  } else {
    return get(isJSON(payload) ? JSON.parse(payload) : payload, 'message', '');
  }
};

const ncrs = (state = initialState, action) => {
  switch (action.type) {
    case NCR_ORDER_ERROR:
      return {
        ...state,
        hasError: true,
        errorMessage: getErrorMessage(action.payload),
      };
    case CLEAR_NCR_ORDER_ERROR:
      return {
        ...state,
        hasError: false,
        errorMessage: '',
      };
    case NCR_CREATION_RESET:
      return {
        ...state,
        isButtonDisabled: false,
        isCreating: false,
        hasCreated: false,
        hasError: false,
        errorMessage: '',
        uploadedAttachments: [],
        isUploading: false,
        uploadCompleted: false,
      };
    case NCR_CREATION_START:
      return {
        ...state,
        isButtonDisabled: true,
        isCreating: true,
        hasCreated: false,
      };
    case NCR_CREATION_FINISH:
      return {
        ...state,
        isButtonDisabled: false,
        isCreating: false,
        hasCreated: true,
        hasError: false,
        errorMessage: '',
        uploadCompleted: false,
        refreshSearch: true,
      };
    case NCR_CREATION_ERROR:
      return {
        ...state,
        isButtonDisabled: false,
        isCreating: false,
        hasCreated: false,
        uploadedAttachments: [],
        isUploading: false,
        uploadCompleted: false,
        hasError: true,
        errorMessage: getErrorMessage(action.payload),
      };
    case NCR_EDIT_RESET:
      return {
        ...state,
        isButtonDisabled: false,
        isUpdating: false,
        hasUpdated: false,
      };
    case NCR_EDIT_START:
      return {
        ...state,
        isButtonDisabled: true,
        isUpdating: true,
        hasUpdated: false,
        hasError: false,
        errorMessage: '',
        uploadedAttachments: [],
        isUploading: false,
        uploadCompleted: false,
      };
    case NCR_EDIT_FINISH:
      return {
        ...state,
        isUpdating: false,
        isButtonDisabled: false,
        hasUpdated: true,
        hasError: false,
        errorMessage: '',
        uploadCompleted: false,
        refreshSearch: true,
      };
    case NCR_EDIT_ERROR:
      return {
        ...state,
        isButtonDisabled: false,
        isUpdating: false,
        hasUpdated: false,
        uploadedAttachments: [],
        isUploading: false,
        uploadCompleted: false,
        hasError: true,
      };
    case LOAD_NCR_ORDER_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_NCR_ORDER_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: getErrorMessage(action.payload),
      };
    case SEARCH_NCRS_START:
      return {
        ...state,
        isSearching: true,
        hasError: false,
        errorMessage: '',
        searchResult: [],
      };

    case SEARCH_NCRS_FINISH:
      return {
        ...state,
        isSearching: false,
        refreshSearch: false,
      };

    case SEARCH_NCRS_ERROR:
      return {
        ...state,
        isSearching: false,
        hasError: true,
        errorMessage: getErrorMessage(action.payload),
      };

    case LOAD_NCRS: {
      const { items, ...rest } = action.payload;
      return {
        ...state,
        isLoading: false,
        searchResult: defaultTo(items, action.payload),
        pagination: rest,
      };
    }
    case LOAD_REVIEW_NCR:
      return {
        ...state,
        isLoading: false,
        refreshSearch: true,
        currentNCR: action.payload,
      };

    case LOAD_REVIEW_INCIDENTS:
      return {
        ...state,
        isLoading: false,
        refreshSearch: true,
        createdIncidents: action.payload,
      };

    case RESET_REVIEW_NCR:
      return {
        ...state,
        currentNCR: null,
      };

    case SEARCH_NCRS_RESET:
      return {
        ...state,
        isSearching: false,
        hasError: false,
        searchResult: [],
        errorMessage: '',
      };

    case NCR_ATTACHMENT_UPLOAD_START:
      return {
        ...state,
        isButtonDisabled: true,
        isUploading: true,
        uploadCompleted: false,
        hasError: false,
        errorMessage: '',
      };

    case NCR_ATTACHMENT_UPLOAD_SAVE:
      return {
        ...state,
        isUploading: true,
        isButtonDisabled: false,
        uploadCompleted: false,
        uploadedAttachments: [...state.uploadedAttachments, action.payload],
      };

    case NCR_ATTACHMENT_UPLOAD_SET:
      return {
        ...state,
        uploadedAttachments: action.payload,
      };

    case NCR_ATTACHMENT_UPLOAD_FINISH:
      return {
        ...state,
        isUploading: false,
        isButtonDisabled: false,
        uploadCompleted: true,
      };

    case NCR_ATTACHMENT_UPLOAD_ERROR:
      return {
        ...state,
        uploadedAttachments: [],
        isUploading: false,
        isButtonDisabled: false,
        hasError: true,
        uploadCompleted: false,
      };

    case NCR_ATTACHMENT_UPLOAD_RESET:
      return {
        ...state,
        uploadedAttachments: [],
        isUploading: false,
        hasError: false,
        errorMessage: getErrorMessage(action.payload),
        uploadCompleted: false,
      };

    case NCR_DELETE_START:
      return {
        ...state,
        isDeleting: true,
        hasDeleted: false,
      };
    case NCR_DELETE_FINISH:
      return {
        ...state,
        isDeleting: false,
        hasDeleted: true,
        refreshSearch: true,
      };
    case NCR_DELETE_ERROR:
      return {
        ...state,
        isDeleting: false,
        hasDeleted: false,
        hasError: true,
        errorMessage: getErrorMessage(action.payload),
      };

    case NCR_DELETE_RESET:
      return {
        ...state,
        isDeleting: false,
        hasDeleted: false,
      };

    case NCR_RELEASE_START:
      return {
        ...state,
        isReleasingToPacker: true,
        hasReleasingToPacker: false,
      };
    case NCR_RELEASE_FINISH:
      return {
        ...state,
        isReleasingToPacker: false,
        hasReleasedToPacker: true,
        refreshSearch: true,
      };
    case NCR_RELEASE_ERROR:
      return {
        ...state,
        isReleasingToPacker: false,
        hasReleasedToPacker: false,
        hasError: true,
        errorMessage: getErrorMessage(action.payload),
      };

    case NCR_RELEASE_RESET:
      return {
        ...state,
        isReleasingToPackerisDeleting: false,
        hasReleasedToPacker: false,
      };
    case LOAD_NCR_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };

    case INCIDENT_BY_GROUP_ID_START:
      return {
        ...state,
        isIncidentByGroupLoading: true,
        hasIncidentbyGroupLoading: false,
        hasError: false,
      };
    case INCIDENT_BY_GROUP_ID_FINISH:
      return {
        ...state,
        connectIncidents: action.payload,
        isIncidentByGroupLoading: false,
        hasIncidentbyGroupLoading: true,
        hasError: false,
      };
    case INCIDENT_BY_GROUP_ID_ERROR:
      return {
        ...state,
        isIncidentByGroupLoading: false,
        hasIncidentbyGroupLoading: false,
        hasError: true,
        errorMessage: getErrorMessage(action.payload),
      };

    case LOAD_REVIEW_INCIDENT:
      return {
        ...state,
        changeIncident: action.payload,
      };

    case CLEAR_CHANGE_INCIDENT:
      return {
        ...state,
        changeIncident: null,
      };

    case RESET_REVIEW_INCIDENT:
      return {
        ...state,
        changeIncident: null,
      };

    case ERROR_REVIEW_INCIDENT:
      return {
        ...state,
      };

    case START_NCR_PDF:
      return {
        ...state,
        isNCRPDFStarted: true,
        hasNCRPDFDownloaded: false,
        errorNCRPDF: true,
      };

    case FINISH_NCR_PDF:
      return {
        ...state,
        isNCRPDFStarted: false,
        hasNCRPDFDownloaded: true,
        errorNCRPDF: false,
      };

    case ERROR_NCR_PDF:
      return {
        ...state,
        isNCRPDFStarted: false,
        hasNCRPDFDownloaded: false,
        errorNCRPDF: true,
      };

    case START_INCIDENT_PDF:
      return {
        ...state,
        isIncidentPDFStarted: true,
        hasIncidentPDFDownloaded: false,
        errorIncidentPDF: true,
      };

    case FINISH_INCIDENT_PDF:
      return {
        ...state,
        isIncidentPDFStarted: false,
        hasIncidentPDFDownloaded: true,
        errorIncidentPDF: false,
      };

    case ERROR_INCIDENT_PDF:
      return {
        ...state,
        isIncidentPDFStarted: false,
        hasIncidentPDFDownloaded: false,
        errorIncidentPDF: true,
      };

    default:
      return state;
  }
};

export default ncrs;
