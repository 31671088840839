export const NAV_BAR_INTERACTED = 'NAV_BAR_INTERACTED';

export const NAV_OPEN_STAGES = {
  closed: 'closed',
  min_opened: 'min_opened',
  max_opened: 'max_opened',
};

export function openNavMin() {
  return async dispatch => {
    dispatch({
      type: NAV_BAR_INTERACTED,
      payload: NAV_OPEN_STAGES.min_opened,
    });
  };
}

export function openNavMax() {
  return async dispatch => {
    dispatch({
      type: NAV_BAR_INTERACTED,
      payload: NAV_OPEN_STAGES.max_opened,
    });
  };
}

export function closeNav() {
  return async dispatch => {
    dispatch({
      type: NAV_BAR_INTERACTED,
      payload: NAV_OPEN_STAGES.closed,
    });
  };
}
