import { connect } from 'react-redux';
import POTableComponent from '../../components/packers/po_table/POTable';

const mapStateToProps = state => ({
  input_products: state.input_products,
  packers: state.packers,
  user: state.user,
  token: state.user.token,
});

const POTable = connect(mapStateToProps)(POTableComponent);

export default POTable;
