import Constants from '../Constants';

export const ORDERS_COLDSTORE_BASE_URL = `${Constants.URL}coldstore_orders?has_delivery_date=false&status=coldstore`;
export const ORDERS_COLDSTORE_FETCHED = 'ORDERS_COLDSTORE_FETCHED';
export const ORDERS_COLDSTORE_ERROR = 'ORDERS_COLDSTORE_ERROR';
export const COLD_STORE_SEND_EMAIL_ERROR = 'COLD_STORE_SEND_EMAIL_ERROR';
export const REPLACE_ORDERS_COLDSTORE = 'REPLACE_ORDERS_COLDSTORE';
export const REPLACE_ORDER_COLDSTORE = 'REPLACE_ORDER_COLDSTORE';
export const REMOVE_ORDER_COLDSTORE = 'REMOVE_ORDER_COLDSTORE';
export const ORDER_COLDSTORE_ERROR = 'ORDER_COLDSTORE_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadOrdersColdstore(ordersColdstore) {
  return {
    type: ORDERS_COLDSTORE_FETCHED,
    payload: ordersColdstore,
  };
}

export function errorOrdersColdstore(error) {
  return {
    type: ORDERS_COLDSTORE_ERROR,
    payload: error,
  };
}

export function replaceOrdersColdstore(ordersColdstore) {
  return {
    type: REPLACE_ORDERS_COLDSTORE,
    payload: ordersColdstore,
  };
}

export function replaceOrderColdstore(orderColdstore) {
  return {
    type: REPLACE_ORDER_COLDSTORE,
    payload: orderColdstore,
  };
}

export function removeOrderColdstore(orderColdstore) {
  return {
    type: REMOVE_ORDER_COLDSTORE,
    payload: orderColdstore,
  };
}

export function errorOrderColdstore(error) {
  return {
    type: ORDER_COLDSTORE_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchOrdersColdstore(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${ORDERS_COLDSTORE_BASE_URL}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrdersColdstore))
      .then(json => dispatch(loadOrdersColdstore(json)))
      .catch(error => dispatch(errorOrdersColdstore(error)));
  };
}

export function saveOrdersColdstore(orders, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/bulk`, {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(orders),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrdersColdstore))
      .then(jsons => {
        dispatch(replaceOrdersColdstore(jsons));
      })
      .catch(error => dispatch(errorOrderColdstore(error)));
  };
}
