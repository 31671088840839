import React from 'react';
import PropTypes from 'prop-types';
import { Box, ButtonGroup, Flex, IconButton, Input, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { ReactComponent as TrashOutlineIconSVG } from '../../../assets/trash-outline-icon.svg';
import { sortInAlphabeticalOrder } from '../../../functions';
import SelectField from '../../basic/SelectField';

const IssuesXcodesTableTd = ({ children, ...rest }) => {
  return (
    <Td paddingRight="16px" paddingLeft={0} paddingTop="8px" {...rest}>
      {children}
    </Td>
  );
};

IssuesXcodesTableTd.propTypes = {
  children: PropTypes.node.isRequired,
};

const columns = [
  {
    title: 'Category',
    dataIndex: 'category',
    isRequired: true,
  },
  {
    title: 'Reason',
    dataIndex: 'code',
    isRequired: true,
  },
  {
    title: 'Description',
    dataIndex: 'description',
  },
];

const IssuesXcodes = ({
  issuesXcodesOption,
  allIssuesXcodesOption,
  isInEditMode,
  issuesXcodes,
  onChange,
  onDelete,
  onAdd,
}) => {
  // filter issuesXcodesOption to get only unique categories
  const categoryOptions = issuesXcodesOption
    ?.filter((v, i, a) => a.findIndex(t => t.category === v.category) === i)
    ?.map(category => ({
      value: category.category,
      label: category.category,
    }))
    .sort((a, b) => {
      const optA = a.label.toLowerCase();
      const optB = b.label.toLowerCase();
      return sortInAlphabeticalOrder(optA, optB);
    });

  // filter issuesXcodesOption to get only unique codes and return value and label
  const getCodesByCategory = category => {
    return issuesXcodesOption
      .filter(issueXcode => issueXcode.category === category)
      .map(issueXcode => ({
        value: issueXcode.code,
        label: issueXcode.code,
      }))
      .sort((a, b) => {
        const optA = a.label.toLowerCase();
        const optB = b.label.toLowerCase();
        return sortInAlphabeticalOrder(optA, optB);
      });
  };

  const getFieldValue = (id, key) => {
    const issueXcode = allIssuesXcodesOption?.find(ix => ix?.id === id);
    return issueXcode ? issueXcode[key] : '';
  };

  return (
    <Table variant="unstyled">
      <Thead>
        <Tr>
          <Th width="10px" padding="0" paddingRight="26px" />
          {columns.map(column => (
            <Th
              key={column.dataIndex}
              paddingLeft={0}
              paddingBottom={0}
              paddingTop={0}
              textTransform="capitalize"
              fontSize="14px"
              fontWeight="500"
              fontFamily="var(--chakra-fonts-body)"
            >
              {column.title} {column.isRequired && isInEditMode ? ' *' : ''}
            </Th>
          ))}
          <Th />
        </Tr>
      </Thead>
      <Tbody backgroundColor="white">
        {Array.isArray(issuesXcodes) &&
          issuesXcodes.length > 0 &&
          issuesXcodes.map((issueXcode, index) => (
            <Tr key={issueXcode.id || index}>
              <IssuesXcodesTableTd paddingLeft="1em">
                <Text color="accent.one.default" fontWeight="700">
                  {index + 1}
                </Text>
              </IssuesXcodesTableTd>
              <IssuesXcodesTableTd paddingLeft={0}>
                <Box minWidth="204px">
                  <SelectField
                    isDisabled={!isInEditMode}
                    isSearchable
                    closeMenuOnSelect
                    name="select-category"
                    defaultValue={getFieldValue(issueXcode.issue_xcode_id, 'category') || issueXcode.category}
                    onChange={e => onChange(index, 'category', e.value)}
                    options={categoryOptions}
                  />
                </Box>
              </IssuesXcodesTableTd>
              <IssuesXcodesTableTd paddingLeft={0}>
                <Box minWidth="204px">
                  <SelectField
                    isDisabled={!isInEditMode}
                    isSearchable
                    closeMenuOnSelect
                    name="select-code"
                    defaultValue={getFieldValue(issueXcode.issue_xcode_id, 'code')}
                    onChange={e => onChange(index, 'code', e.value)}
                    options={getCodesByCategory(
                      issueXcode.category || getFieldValue(issueXcode.issue_xcode_id, 'category')
                    )}
                  />
                </Box>
              </IssuesXcodesTableTd>
              <IssuesXcodesTableTd paddingLeft={0}>
                <Input
                  className="form-control"
                  value={issueXcode.description || ''}
                  isDisabled={!isInEditMode}
                  minWidth="210px"
                  placeholder="Add description"
                  onChange={e => onChange(index, 'description', e.target.value)}
                />
              </IssuesXcodesTableTd>
              <IssuesXcodesTableTd colSpan={1} paddingRight="1em">
                <ButtonGroup gap="4" alignItems="center" justifyContent="flex-end" width="100%">
                  <IconButton
                    onClick={onAdd}
                    isDisabled={!isInEditMode}
                    icon={
                      <i
                        className="la la-plus international-order-line__icon--orange"
                        style={{
                          fontSize: '16px',
                        }}
                      />
                    }
                    colorScheme="actionSecondary"
                    height="25px"
                    minWidth="25px"
                    borderRadius="32px"
                  />
                  <IconButton
                    variant="ghost"
                    icon={<TrashOutlineIconSVG />}
                    isDisabled={!isInEditMode}
                    onClick={() => {
                      onDelete(index);
                    }}
                  />
                </ButtonGroup>
              </IssuesXcodesTableTd>
            </Tr>
          ))}
        {issuesXcodes.length < 1 && (
          <Tr>
            <IssuesXcodesTableTd colSpan={5} paddingRight={0}>
              <Flex>
                <IconButton
                  marginLeft="auto"
                  onClick={onAdd}
                  isDisabled={!isInEditMode}
                  icon={
                    <i
                      className="la la-plus international-order-line__icon--orange"
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  }
                  colorScheme="actionSecondary"
                  height="25px"
                  minWidth="25px"
                  borderRadius="32px"
                />
              </Flex>
            </IssuesXcodesTableTd>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

IssuesXcodes.propTypes = {
  issuesXcodesOption: PropTypes.arrayOf(Object),
  allIssuesXcodesOption: PropTypes.arrayOf(Object),
  isInEditMode: PropTypes.bool,
  issuesXcodes: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
};

export default IssuesXcodes;
