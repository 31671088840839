const schema = [
  {
    name: 'cold_store_storage_fee',
    displayName: 'Cold Store Fee',
    systemIdField: 'id',
    displayIdField: 'coldstore_id',
    path: '/cold_store_storage_fees',
    tabGroup: { key: 'pricing', label: 'Pricing' },
    deletable: true,
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'cold_store_id',
        displayName: 'Coldstore',
        tooltip: 'Name or identifier of the cold storage facility',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'coldstore',
        required: true,
      },
      {
        name: 'effective_from',
        displayName: 'Effective From',
        tooltip: 'Date when the cold store fee starts to apply',
        dataType: 'date',
        required: true,
      },
      {
        name: 'window',
        displayName: 'Window (days)',
        tooltip: 'Duration in days for which the fee is applicable',
        dataType: 'integer',
        required: true,
      },
      {
        name: 'rate',
        displayName: 'Price (per pound)',
        tooltip: 'Cost per pound for using the cold storage',
        dataType: 'number',
        required: true,
      },
      {
        name: 'currency',
        displayName: 'Currency',
        tooltip: 'Currency in which the fee is charged',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'currency',
      },
    ],
  },
];
export default schema;
