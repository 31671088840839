import { createSlice } from '@reduxjs/toolkit';
import Constants from '../../Constants';

//Clean Up
const purchaseOrderHistorySates = {
    IDLE: "IDLE",
    REQUEST: "REQUEST",
    LOADING: "LOADING",
    RECIEVED: "RECIEVED",
    FAIL: "FAIL",
    CLEAR: "CLEAR"
};

const purchaseOrderHistorySlice = createSlice({
  name: 'purchaseOrderHistory',
  initialState: {
    data: [],
    isLoading: purchaseOrderHistorySates.IDLE
  },
  reducers: {
    loadPurchaseOrderHistory (state) {
      state.isLoading = purchaseOrderHistorySates.LOADING
    },
    recievePurchaseOrderHistorySuccess (state, action) {
      state.data = action.payload;
      state.isLoading = "RECIEVED";
    },
    recievePurchaseOrderHistoryFail (state, error) {
      console.log(error);
      state.isLoading = "FAIL";

    }
  },
});

export const { loadPurchaseOrderHistory, recievePurchaseOrderHistorySuccess, recievePurchaseOrderHistoryFail } = purchaseOrderHistorySlice.actions

export function fetchPurchaseOrderHistory(token, orderID) {

  return dispatch => {

    const header = new Headers({ Authorization: `Token ${token}` });
    return fetch(`${Constants.URL}order_management/purchase_orders/${orderID}/edit_history`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, () => {}))
      .then(json => dispatch(recievePurchaseOrderHistorySuccess(json)))
      .catch(error => dispatch(recievePurchaseOrderHistoryFail(error)));
  };
}


export default purchaseOrderHistorySlice.reducer