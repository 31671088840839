import { createSelector } from '@reduxjs/toolkit';
import { selectQualitySortedPackerPlantsOptions } from './selectQualityPackerPlantOptions';
import { selectPackerPlants } from '../../quality-packer-plants/selectors/selectBasicPackerPlants';

const selectAvailablePackerPlants = createSelector(
  selectQualitySortedPackerPlantsOptions,
  selectPackerPlants,
  (sortedPackerPlantsOptions, packerPlants) => {
    if (!sortedPackerPlantsOptions || !packerPlants || packerPlants.length === 0) return [];
    const availablePackerPlants = sortedPackerPlantsOptions
      .map(pp => {
        const packerPlant = packerPlants.find(p => p.id === pp.id);
        if (!packerPlant) return null;
        return {
          ...packerPlant,
          isIndependent: !packerPlant.isPacker && !packerPlant.isPartOf,
        };
      })
      .filter(p => p && !p.delisted);

    const res = availablePackerPlants.map(x => {
      if (!x) {
        return null;
      }
      const packerPlantsInPacker = packerPlants
        .filter(p => p.isPartOf === x.id && availablePackerPlants.some(a => a.id === p.id))
        .map(p => ({
          id: p.id,
          name: p.name,
        }));
      return {
        ...x,
        name: packerPlantsInPacker.length ? `${x.name} (${packerPlantsInPacker.length} establishments)` : x.name,
        includesPackerPlants: packerPlantsInPacker.length ? packerPlantsInPacker : undefined,
        bottomTenPackerPlants: packerPlantsInPacker.length
          ? packerPlantsInPacker.slice(0, 10) // TODO make dynamic based on bottom 10 packerPlants
          : undefined,
      };
    });

    const independantPackerPlants = res.filter(x => x && x.isIndependent).sort((a, b) => a.name.localeCompare(b.name));
    const packers = [];

    res
      .filter(x => x && !!x.includesPackerPlants)
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach(x => {
        const packerPackerPlants = res
          .filter(p => x.includesPackerPlants.some(y => y.id === p.id))
          .sort((a, b) => a.name.localeCompare(b.name));
        // filter out packers with only one packer plant
        if (x.includesPackerPlants.length > 1) {
          packers.push(x, ...packerPackerPlants);
        } else {
          packers.push(...packerPackerPlants);
        }
      });

    const sortedPackerPlants = [...independantPackerPlants, ...packers];
    return sortedPackerPlants;
  }
);

export default selectAvailablePackerPlants;
