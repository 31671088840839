import React from 'react';
import PropTypes from 'prop-types';
import { Box, Center, Text, HStack, Button, Heading } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ArticleTile from './ArticleTile';

const ArticleCard = ({ article = {} }) => {
  return (
    <ArticleTile imageUrl={article.hero_url}>
      <Box>
        <Box height="44px">
          <Heading
            height="100%"
            color="accent.one.default"
            fontSize="12px"
            fontWeight="600"
            casing="uppercase"
            textAlign="center"
            overflow="hidden"
          >
            {article.title}
          </Heading>
        </Box>
        <Box marginTop="14px" height="82px">
          <Heading
            height="100%"
            fontSize="18px"
            fontWeight="600"
            lineHeight="28px"
            textAlign="center"
            overflow="hidden"
          >
            {article.hook}
          </Heading>
        </Box>
        <Center marginTop="14px">
          <HStack spacing="4px">
            <Text color="neutral.3" fontSize="14px">
              {`${moment(article.published_date).format('MMM DD, YYYY')} by `}
            </Text>
            <Text color="neutral.3" fontSize="14px" fontWeight="700">
              {' '}
              {article.author}
            </Text>
          </HStack>
        </Center>
      </Box>
      <Center>
        <Link to={`/articles/${article.slug}`}>
          <Button
            w="160px"
            height="46px"
            colorScheme="actionPrimary"
            fontSize="16px"
            fontWeight="600"
            borderRadius="10px"
          >
            Read More
          </Button>
        </Link>
      </Center>
    </ArticleTile>
  );
};

ArticleCard.propTypes = {
  article: PropTypes.instanceOf(Object),
};

export default ArticleCard;
