import { connect } from 'react-redux';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';
import { convertToCamelCase } from '../../helpers';
import { InternationalOrderFormProvider } from '../../components/orders/order-form/InternationalOrderForm/InternationalOrderFormContext';

const mapStateToProps = state => {
  const {
    currencies,
    fecs,
    grinders,
    inco_terms: incoTerms,
    input_products: inputProducts,
    load_sizes: loadSizes,
    packer_plants: packerPlants,
    packers,
    ports,
    tags,
    units_of_measure: unitsOfMeasure,
    voyage,
  } = { ...findEntitiesInState(state) };

  const result = {
    currencies,
    fecs,
    grinders,
    incoTerms,
    inputProducts,
    loadSizes,
    packerPlants,
    packers,
    ports,
    tags,
    token: state.user.token,
    unitsOfMeasure,
    voyage,
    configs: state.configs,
  };

  const cameled = convertToCamelCase(result);
  return cameled;
};

const InternationalOrderFormContainer = connect(mapStateToProps)(InternationalOrderFormProvider);

export default InternationalOrderFormContainer;
