import { NOTIFYING_ACTION_GROUPS_FETCHED } from '../actions/actions_notifying_action_groups';

const notifying_action_groups = (notifying_action_groups = [], action) => {
  switch (action.type) {
    case NOTIFYING_ACTION_GROUPS_FETCHED:
      return action.payload;
    default:
      return notifying_action_groups;
  }
};

export default notifying_action_groups;
