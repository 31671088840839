const schema = [
  {
    name: 'coldstore',
    displayName: 'Cold Store',
    nested: false,
    systemIdField: 'id',
    displayIdField: 'company_name',
    tabGroup: { key: 'logistics', label: 'Logistics' },
    deletable: true,
    path: '/cold_stores',
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'integer',
        required: true,
        systemIdentifier: true,
        hidden: true,
      },
      {
        name: 'location_name',
        displayName: 'Location Name',
        tooltip: 'Enter the name of the cold storage location',
        dataType: 'shorttext',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'company_name',
        displayName: 'Invoice Company Name',
        tooltip: 'Company name to appear on invoices.',
        dataType: 'shorttext',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'lat',
        displayName: 'Latitude',
        tooltip: 'Geographic latitude of the cold store.',
        dataType: 'shorttext',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'lon',
        displayName: 'Longitude',
        tooltip: 'Geographic longitude of the cold store.',
        dataType: 'shorttext',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'timezone',
        displayName: 'Timezone',
        tooltip: 'Timezone where the cold store is located.',
        dataType: 'shorttext',
        required: false,
        systemIdentifier: false,
      },
      {
        name: 'unit_of_measure_id',
        displayName: 'Unit Of Measure',
        tooltip: 'Select the Measurement unit for inventory (e.g., BIN, CTN, KGS, LBS).',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'unit',
        required: true,
      },
      {
        name: 'currency',
        displayName: 'Currency',
        tooltip: 'Select the Default currency for transactions.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'currency',
        required: true,
      },
      {
        name: 'initial_free_time',
        displayName: 'Initial Free Time',
        tooltip: 'Free storage time before charges apply.',
        dataType: 'integer',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'initial_price',
        displayName: 'Initial Price',
        tooltip: 'Base price after the free time expires.',
        dataType: 'number',
        required: true,
      },
      {
        name: 'initial_price_currency',
        displayName: 'Initial Price Currency',
        tooltip: 'Currency in which the initial price is charged.',
        dataType: 'reference',
        referenceType: 'select',
        referenceEntityType: 'form_fields',
        referenceEntitySubType: 'currency',
      },
      {
        name: 'notification_email',
        displayName: 'Notification Email',
        tooltip: 'Email to receive notifications related to the cold store.',
        dataType: 'shorttext',
        hideList: true,
        required: true,
      },
      {
        name: 'address',
        displayName: 'Address',
        dataType: 'reference',
        referenceType: 'nested',
        referenceEntityType: 'address',
      },
    ],
  },
];
export default schema;
