import { COVID19_AUS_FETCHED, CURRENT_AFFAIRS_FETCHED } from '../actions/actions_current_affairs';

const current_affairs = (dataSet = {}, action) => {
  switch (action.type) {
    case CURRENT_AFFAIRS_FETCHED:
      return action.payload;
    case COVID19_AUS_FETCHED:
      return action.payload;
    default:
      return dataSet;
  }
};

export default current_affairs;
