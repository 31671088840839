import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import React, { Component } from 'react';
import Datetime from 'react-datetime';
import _ from 'underscore';
import { fetchConfigs } from '../../actions/actions_config';
import { fetchGrinders } from '../../actions/actions_grinders';
import { fetchBeefInputProducts } from '../../actions/actions_input_products';
import {
  fetchAgrifoodsFrozenInventory,
  fetchChemicalLeanPerformance,
  fetchChemicalLeanValue,
  fetchClaims,
  fetchConversionCosts,
  fetchCostSavings,
  fetchDeliveredOnTime,
  fetchEmpty,
  fetchFilesSent,
  fetchForecastAccuracy,
  fetchGrinderYield,
  fetchLoadWeights,
  fetchOrdersCL,
  fetchPerformance,
  fetchProductAge,
  fetchRiskManagedVolume,
  fetchSingleLotTested,
  fetchWeather,
} from '../../actions/actions_map';
import { fetchPackerPlants } from '../../actions/actions_packer_plants';
import { commify } from '../../functions';
import MapFunctionsInit from '../lib/MapFunctions';

function getData(type, feature, current_report, props) {
  let thisName = false;
  if (feature.layer.id.indexOf('extrusion') > -1) {
    thisName = feature.layer.id.split(`${type}-extrusion-`)[1];
    if (feature.layer.id.indexOf('_pc') > -1) {
      thisName = thisName.split('-')[0];
    }
    if (feature.layer.id.indexOf('_quan') > -1) {
      thisName = thisName.split('-')[0];
    }
  } else {
    thisName = feature.layer.id.split(`${type}-`)[1];
  }
  let currentData = false;
  let thisGrinderOrPacker = false;

  props[type].forEach(function (grinderOrPacker) {
    if (grinderOrPacker.name === thisName) {
      thisGrinderOrPacker = grinderOrPacker;
    }
  });
  if (current_report === 'delivered_on_time') {
    currentData = props.map_data.delivered_on_time[type];
  } else if (current_report === 'orders_cl') {
    currentData = props.map_data.orders_cl[type];
  } else if (current_report === 'performance') {
    if (type === 'grinders') {
      currentData = props.map_data.performance;
    }
  } else if (current_report === 'claims') {
    currentData = props.map_data.claims[type];
  } else if (current_report === 'risk_managed_volumes') {
    currentData = props.map_data.risk_managed_volume[type];
  } else if (current_report === 'conversion_costs') {
    if (type === 'grinders') {
      currentData = props.map_data.conversion_costs[type];
    }
  } else if (current_report === 'load_weights') {
    currentData = props.map_data.load_weights[type];
  } else if (current_report === 'chemical_lean_performance') {
    if (type === 'packers') {
      currentData = props.map_data.chemical_lean_performance[type];
    }
  } else if (current_report === 'grinder_yield') {
    currentData = props.map_data.grinder_yield[type];
  } else if (current_report === 'chemical_lean_value') {
    currentData = props.map_data.chemical_lean_value[type];
  } else if (current_report === 'product_age') {
    currentData = props.map_data.product_age[type];
  } else if (current_report === 'agrifoods_frozen_inventory') {
    currentData = props.map_data.agrifoods_frozen_inventory[type];
  } else if (current_report === 'single_lot_tested') {
    currentData = props.map_data.single_lot_tested[type];
  } else if (current_report === 'cost_savings') {
    if (type === 'grinders') {
      currentData = props.map_data.cost_savings[type];
    }
  } else if (current_report === 'files_sent') {
    currentData = props.map_data.files_sent[type];
  } else if (current_report === 'forecast_accuracy') {
    currentData = props.map_data.forecast_accuracy[type];
  }
  let thisData = false;
  let varType = 'grinder_uid';

  if (type === 'packers') {
    varType = 'packer_plant_id';
  }
  if (currentData) {
    currentData.forEach(function (data) {
      if (data[varType] === thisGrinderOrPacker.uid) {
        thisData = data;
      }
    });
  }
  if (!thisData) {
    thisData = {};
    thisData.name = thisName;
    thisData.info = 'No information to display in this report.';
  } else {
    thisData.name = thisName;
  }
  return thisData;
}

function getRange(prop, array) {
  const minMax = [1000000000, 0];
  array.forEach(function (item) {
    if (item[prop] < minMax[0]) {
      minMax[0] = item[prop];
    }
    if (item[prop] > minMax[1]) {
      minMax[1] = item[prop];
    }
  });
  return minMax;
}

// Potential issue: if zoomed in, all the information might not update
// Force user zoom back out before sending request for new report data?

class MapDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: false,
      initialLoad: true,
      loading: true,
      loaded: false,
      lines: [],
      needsRefresh: false,
      grindersToPackersAndPackersToGrinders: [],
      panels: {
        grinders: false,
        packer_plants: false,
        dcs: false,
        selectedData: false,
        summaryData: false,
      },
      filters: {
        packer_plants: {},
        grinders: {},
      },
      ordered_start_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      ordered_end_date: moment().add(1, 'days').format('YYYY-MM-DD'),
      current_report: 'performance',
      selectedData: false,
      summaryStats: [],
      summarySnippet: 'Cost to produce at 76CL.',
      legend: [],
      detailExpanded: [false, false],
      no_data: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchPackerPlants(this.props.token));
    this.props.dispatch(fetchGrinders(this.props.token));
    if (this.props.configs.length === 0){
      this.props.dispatch(fetchConfigs(this.props.token));
    }
    this.props.dispatch(fetchBeefInputProducts(this.props.token));

    mapboxgl.accessToken = 'pk.eyJ1IjoiY2Zjb2pvbm8iLCJhIjoiY2toeWNhOXh3MGY0cDJ4b2RhMG54M3ExaSJ9.hlvEPe6nwdJ1a3z1dGB5bA';
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/light-v9',
      center: [-105.7129, 38.0902],
      zoom: 3.3,
      pitch: 50,
      rotate: 60,
    });

    this.setState({ map });

    map.on(
      'load',
      function () {
        this.setState({ loaded: true });
        this.UNSAFE_componentWillReceiveProps(this.props);
        const that = this;
        setTimeout(function () {
          that.props.dispatch(
            fetchPerformance(
              that.props.grinders,
              that.state.ordered_start_date,
              that.state.ordered_end_date,
              that.props.token
            )
          );
        }, 1500);
      }.bind(this)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { map } = this.state;

    if (Object.keys(prevState.filters.grinders).length && Object.keys(prevState.filters.packer_plants).length) {
      if (
        !_.isEqual(this.state.filters, prevState.filters) ||
        this.state.ordered_start_date !== prevState.ordered_start_date ||
        this.state.ordered_end_date !== prevState.ordered_end_date ||
        this.state.current_report !== prevState.current_report
      ) {
        this.setState({ needsRefresh: true });
      }
    }

    // Removing any extra extrusions from more specialized reports (Volumes)
    if (prevState.current_report === 'risk_managed_volumes' && this.state.current_report !== 'risk_managed_volumes') {
      const contractTypes = ['spot_pc', 'formula_pc', 'contract_pc', 'NOF_and_pc', 'other_pc'];
      this.props.grinders.forEach(function (grinder) {
        contractTypes.forEach(function (contract) {
          if (map.getLayer(`grinders-extrusion-${grinder.name}-${contract}`)) {
            map.removeLayer(`grinders-extrusion-${grinder.name}-${contract}`);
          }
        });
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, forceUpdate) {
    const { map, initialLoad, loaded } = this.state;
    const MapFunctions = MapFunctionsInit(map);

    if (nextProps.map_data.no_data) {
      this.setState({
        no_data: true,
        loading: false,
        summaryStats: [],
        selectedData: false,
      });
    } else {
      this.setState({ no_data: false });
    }
    this.setState({ needsRefresh: false });

    /*
    Agrifoods Frozen Inventory
    */
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.agrifoods_frozen_inventory, nextProps.map_data.agrifoods_frozen_inventory) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'agrifoods_frozen_inventory'))
    ) {
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      const agrifoodsInventory = [];
      nextProps.packer_plants.forEach(function (packer_plant) {
        nextProps.map_data.agrifoods_frozen_inventory.packer_plants.forEach(function (agrifoods) {
          if (agrifoods.packer_plant_id === packer_plant.id) {
            agrifoodsInventory.push({
              packer_plants: [packer_plant.name],
              agrifoods_number: agrifoods.number,
              agrifoods_weighted_average_cl: agrifoods.weighted_average_cl,
            });
          }
        });
      });

      const numberRange = getRange('agrifoods_number', agrifoodsInventory);
      const weightedAvgCLRange = getRange('agrifoods_weighted_average_cl', agrifoodsInventory);
      MapFunctions.setExtrusion(
        'packers',
        nextProps.packer_plants,
        agrifoodsInventory,
        ['agrifoods_number', 'agrifoods_weighted_average_cl'],
        numberRange,
        weightedAvgCLRange,
        ['#FFD966', '#63729D'],
        true
      );

      nextProps.grinders.forEach(function (grinder) {
        map.setLayoutProperty(`grinders-${grinder.name}`, 'visibility', 'none');
        map.setLayoutProperty(`grinders-extrusion-${grinder.name}`, 'visibility', 'none');
      });
      nextProps.packer_plants.forEach(function (packer_plant) {
        let foundPacker = false;
        nextProps.map_data.agrifoods_frozen_inventory.packer_plants.forEach(function (agrifoods) {
          if (agrifoods.packer_plant_id === packer_plant.id) {
            foundPacker = packer_plant;
          }
        });
        if (!foundPacker && map.getSource(`packers-${packer_plant.name}`)) {
          map.setLayoutProperty(`packers-${packer_plant.name}`, 'visibility', 'none');
          map.setLayoutProperty(`packers-extrusion-${packer_plant.name}`, 'visibility', 'none');
        }
      });

      const legend = [
        {
          color: '#FFD966',
          name: 'Low Average CL',
        },
        {
          color: '#63729D',
          name: 'High Average CL',
        },
      ];

      const newSummaryStats = [
        {
          name: 'Number of Loads',
          value: commify(nextProps.map_data.agrifoods_frozen_inventory.system.number),
          packer_plants: [],
        },
        {
          name: 'Average Weighted CL',
          value: commify(nextProps.map_data.agrifoods_frozen_inventory.system.weighted_average_cl.toFixed(2)),
          packer_plants: [],
        },
      ];
      nextProps.map_data.agrifoods_frozen_inventory.packer_plants.forEach(function (agrifoods) {
        nextProps.packer_plants.forEach(function (packer_plant) {
          if (packer_plant.id === agrifoods.packer_plant_id) {
            newSummaryStats[0].packer_plants.push({
              name: packer_plant.name,
              type: 'number',
              value: commify(agrifoods.number),
            });
            newSummaryStats[1].packer_plants.push({
              name: packer_plant.name,
              type: 'number',
              value: commify(agrifoods.weighted_average_cl.toFixed(2)),
            });
          }
        });
      });

      this.setState({
        loading: false,
        summaryStats: newSummaryStats,
        legend,
      });
    }
    /*
    Grinder Yield
    */
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.grinder_yield, nextProps.map_data.grinder_yield) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'grinder_yield'))
    ) {
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      const grinderYield = [];
      nextProps.grinders.forEach(function (grinder) {
        nextProps.map_data.grinder_yield.grinders.forEach(function (grinder_yield) {
          if (grinder_yield.grinder_uid === grinder.uid) {
            grinderYield.push({
              grinders: [grinder.name],
              yield_value: grinder_yield.value,
            });
          }
        });
      });

      const yieldPriceRange = getRange('yield_value', grinderYield);
      MapFunctions.setExtrusion(
        'grinders',
        nextProps.grinders,
        grinderYield,
        ['yield_value'],
        yieldPriceRange,
        [0, 90, 98.1]
      );

      const newSummaryStats = [
        {
          name: 'Average all values',
          value: commify(nextProps.map_data.grinder_yield.system.value),
          grinders: [],
        },
      ];
      nextProps.map_data.grinder_yield.grinders.forEach(function (grinder_yield) {
        nextProps.grinders.forEach(function (grinder) {
          if (grinder.uid === grinder_yield.grinder_uid) {
            newSummaryStats[0].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(grinder_yield.value),
            });
          }
        });
      });

      const legend = [
        {
          color: 'red',
          name: '<= 90%',
        },
        {
          color: 'green',
          name: '> 98%',
        },
      ];

      this.setState({
        loading: false,
        summaryStats: newSummaryStats,
        legend,
      });
    }
    /*
  Conversion costs
*/
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.conversion_costs, nextProps.map_data.conversion_costs) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'conversion_costs'))
    ) {
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      const grinderConversionCostsValue = [];
      nextProps.grinders.forEach(function (grinder) {
        nextProps.map_data.conversion_costs.grinders.forEach(function (conversion_cost) {
          if (conversion_cost.grinder_uid === grinder.uid) {
            grinderConversionCostsValue.push({
              grinders: [grinder.name],
              conversion_cost_value: conversion_cost.value,
            });
          }
        });
      });

      const conversionCostValuePriceRange = getRange('conversion_cost_value', grinderConversionCostsValue);
      MapFunctions.setExtrusion(
        'grinders',
        nextProps.grinders,
        grinderConversionCostsValue,
        ['conversion_cost_value'],
        conversionCostValuePriceRange,
        false
      );

      nextProps.packer_plants.forEach(function (packer_plant) {
        map.setPaintProperty(`packers-${packer_plant.name}`, 'circle-color', '#EC864F');
      });

      const newSummaryStats = [
        {
          name: 'Average Conversion Cost',
          value: commify(nextProps.map_data.conversion_costs.system.value),
          grinders: [],
        },
      ];
      nextProps.map_data.conversion_costs.grinders.forEach(function (conversion_cost) {
        nextProps.grinders.forEach(function (grinder) {
          if (grinder.uid === conversion_cost.grinder_uid) {
            newSummaryStats[0].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(conversion_cost.value),
            });
          }
        });
      });

      this.setState({
        loading: false,
        summaryStats: newSummaryStats,
        legend: [],
      });
    }

    /*
  Chemical Lean Performance
*/
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.chemical_lean_performance, nextProps.map_data.chemical_lean_performance) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'chemical_lean_performance'))
    ) {
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      const packerChemicalLeanPerformanceValue = [];
      nextProps.packer_plants.forEach(function (packer_plant) {
        nextProps.map_data.chemical_lean_performance.packer_plants.forEach(function (chemical_lean_performance_value) {
          if (chemical_lean_performance_value.packer_plant_id === packer_plant.id) {
            packerChemicalLeanPerformanceValue.push({
              packer_plants: [packer_plant.name],
              chemical_lean_performance_value: chemical_lean_performance_value.value,
            });
          }
        });
      });

      const chemicalLeanPerformanceValuePriceRange = getRange(
        'chemical_lean_performance_value',
        packerChemicalLeanPerformanceValue
      );

      MapFunctions.addDataToPackers(
        nextProps.packer_plants,
        nextProps.map_data.chemical_lean_performance.packer_plants,
        ['value'],
        ['chemical_lean_performance_value']
      );
      nextProps.packer_plants.forEach(function (packer_plant) {
        if (packer_plant.name.indexOf('Frozen Inventory') === -1) {
          nextProps.map_data.chemical_lean_performance.packer_plants.forEach(function (packerData) {
            if (packerData.packer_plant_id === packer_plant.id) {
              map.setPaintProperty(`packers-${packer_plant.name}`, 'circle-color', {
                property: 'chemical_lean_performance_value',
                type: 'exponential',
                stops: [
                  [chemicalLeanPerformanceValuePriceRange[0], 'red'],
                  [chemicalLeanPerformanceValuePriceRange[1], 'green'],
                ],
              });
            }
          });
        }
      });

      const newSummaryStats = [
        {
          name: 'Total Chemical Lean Performance Values',
          value: commify(nextProps.map_data.chemical_lean_performance.system.value),
          packer_plants: [],
        },
      ];
      nextProps.map_data.chemical_lean_performance.packer_plants.forEach(function (chemical_lean_performance) {
        nextProps.packer_plants.forEach(function (packer_plant) {
          if (packer_plant.id === chemical_lean_performance.packer_plant_id) {
            newSummaryStats[0].packer_plants.push({
              name: packer_plant.name,
              type: 'number',
              value: commify(chemical_lean_performance.value),
            });
          }
        });
      });

      const legend = [
        {
          color: 'red',
          name: 'Low Chemical Lean Performance Value',
        },
        {
          color: 'green',
          name: 'High Chemical Lean Performance Value',
        },
      ];

      this.setState({
        loading: false,
        summaryStats: newSummaryStats,
        legend,
      });
    }

    /*
  Chemical Lean Value
*/
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.chemical_lean_value, nextProps.map_data.chemical_lean_value) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'chemical_lean_value'))
    ) {
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      const grinderChemicalLeanValue = [];
      nextProps.grinders.forEach(function (grinder) {
        nextProps.map_data.chemical_lean_value.grinders.forEach(function (chemical_lean_value) {
          if (chemical_lean_value.grinder_uid === grinder.uid) {
            grinderChemicalLeanValue.push({
              grinders: [grinder.name],
              chemical_lean_value: chemical_lean_value.value,
            });
          }
        });
      });

      const chemicalLeanValuePriceRange = getRange('chemical_lean_value', grinderChemicalLeanValue);
      MapFunctions.setExtrusion(
        'grinders',
        nextProps.grinders,
        grinderChemicalLeanValue,
        ['chemical_lean_value'],
        chemicalLeanValuePriceRange,
        false
      );

      nextProps.packer_plants.forEach(function (packer_plant) {
        nextProps.map_data.chemical_lean_value.packer_plants.forEach(function (chemical_lean_value) {
          if (chemical_lean_value.packer_plant_id === packer_plant.id) {
            map.setPaintProperty(`packers-${packer_plant.name}`, 'circle-color', 'green');
          }
        });
      });

      const chemicalLeanValueRangePacker = getRange('value', nextProps.map_data.chemical_lean_value.packer_plants);
      const newLines = MapFunctions.addLines(
        nextProps.grinders,
        nextProps.packer_plants,
        nextProps.map_data.chemical_lean_value.packer_grinder,
        ['value'],
        ['chemical_lean_value'],
        'chemical_lean_value',
        chemicalLeanValueRangePacker,
        false,
        false,
        false
      );
      const grindersToPackersAndPackersToGrinders = MapFunctions.associateGrindersAndPackersWithLines(
        nextProps.grinders,
        nextProps.packer_plants
      );

      const newSummaryStats = [
        {
          name: 'Total Chemical Lean Values',
          value: commify(nextProps.map_data.chemical_lean_value.system.value),
          grinders: [],
        },
      ];
      nextProps.map_data.chemical_lean_value.grinders.forEach(function (chemical_lean_value) {
        nextProps.grinders.forEach(function (grinder) {
          if (grinder.uid === chemical_lean_value.grinder_uid) {
            newSummaryStats[0].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(chemical_lean_value.value),
            });
          }
        });
      });

      this.setState({
        loading: false,
        summaryStats: newSummaryStats,
        legend: [],
        lines: newLines,
        grindersToPackersAndPackersToGrinders,
      });
    }
    /*
  Cost Savings
*/
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.cost_savings, nextProps.map_data.cost_savings) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'cost_savings'))
    ) {
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      const grinderCostSavings = [];
      nextProps.grinders.forEach(function (grinder) {
        nextProps.map_data.cost_savings.grinders.forEach(function (cost_savings) {
          if (cost_savings.grinder_uid === grinder.uid) {
            grinderCostSavings.push({
              grinders: [grinder.name],
              cost_savings_value: cost_savings.cost_savings,
            });
          }
        });
      });

      const costSavingsPriceRange = getRange('cost_savings_value', grinderCostSavings);
      MapFunctions.setExtrusion(
        'grinders',
        nextProps.grinders,
        grinderCostSavings,
        ['cost_savings_value'],
        costSavingsPriceRange,
        false
      );

      const newSummaryStats = [
        {
          name: 'Total Savings',
          value: commify(nextProps.map_data.cost_savings.system.cost_savings),
          grinders: [],
        },
        {
          name: 'Additional Value',
          value: commify(nextProps.map_data.cost_savings.system.additional_value),
          grinders: [],
        },
      ];
      nextProps.map_data.cost_savings.grinders.forEach(function (cost_saving) {
        nextProps.grinders.forEach(function (grinder) {
          if (grinder.uid === cost_saving.grinder_uid) {
            newSummaryStats[0].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(cost_saving.cost_savings),
            });
            newSummaryStats[1].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(cost_saving.additional_value),
            });
          }
        });
      });

      this.setState({
        loading: false,
        summaryStats: newSummaryStats,
        legend: [],
      });
    }
    /*
  Claims
*/
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.claims, nextProps.map_data.claims) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'claims'))
    ) {
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      const grinderClaims = [];
      nextProps.grinders.forEach(function (grinder) {
        nextProps.map_data.claims.grinders.forEach(function (claims_grinder) {
          if (claims_grinder.grinder_uid === grinder.uid) {
            grinderClaims.push({
              grinders: [grinder.name],
              claims_value: claims_grinder.value,
            });
          }
        });
      });

      const claimsPriceRange = getRange('claims_value', grinderClaims);
      MapFunctions.setExtrusion(
        'grinders',
        nextProps.grinders,
        grinderClaims,
        ['claims_value'],
        claimsPriceRange,
        false
      );

      nextProps.packer_plants.forEach(function (packer_plant) {
        nextProps.map_data.claims.packer_plants.forEach(function (claims_packer) {
          if (claims_packer.packer_plant_id === packer_plant.id) {
            map.setPaintProperty(`packers-${packer_plant.name}`, 'circle-color', 'red');
          }
        });
      });

      const claimsCountRange = getRange('count', nextProps.map_data.claims.packer_plants);
      const newLines = MapFunctions.addLines(
        nextProps.grinders,
        nextProps.packer_plants,
        nextProps.map_data.claims.packer_grinder,
        ['value', 'count'],
        ['claims_value', 'claims_count'],
        'claims_count',
        claimsCountRange,
        false,
        false,
        false
      );
      const grindersToPackersAndPackersToGrinders = MapFunctions.associateGrindersAndPackersWithLines(
        nextProps.grinders,
        nextProps.packer_plants
      );

      const newSummaryStats = [
        {
          name: '# of Claims',
          value: commify(nextProps.map_data.claims.system.count),
          grinders: [],
        },
        {
          name: 'Value of Claims',
          value: commify(nextProps.map_data.claims.system.value),
          grinders: [],
        },
      ];
      nextProps.map_data.claims.grinders.forEach(function (claim) {
        nextProps.grinders.forEach(function (grinder) {
          if (grinder.uid === claim.grinder_uid) {
            newSummaryStats[0].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(claim.count),
            });
            newSummaryStats[1].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(claim.value),
            });
          }
        });
      });

      const legend = [
        {
          color: 'red',
          name: 'Claim against packer',
        },
      ];

      this.setState({
        loading: false,
        summaryStats: newSummaryStats,
        legend,
        lines: newLines,
        grindersToPackersAndPackersToGrinders,
      });
    }

    /*
  Forecast Accuracy
*/
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.forecast_accuracy, nextProps.map_data.forecast_accuracy) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'forecast_accuracy'))
    ) {
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      const propertiesToAdd = ['production_quantity', 'forecast_quanity', 'error_pc'];
      nextProps.map_data.forecast_accuracy.grinders.forEach(function (grinderData) {
        nextProps.grinders.forEach(function (thisGrinder) {
          if (thisGrinder.uid === grinderData.grinder_uid) {
            const thisPointGeoJSON = map.getSource(`grinders-${thisGrinder.name}`)._data;
            const thisPolyGeoJSON = map.getSource(`grinders-extrusion-${thisGrinder.name}`)._data;
            propertiesToAdd.forEach(function (property) {
              thisPointGeoJSON.features[0].properties[property] = grinderData[property];
              thisPolyGeoJSON.features[0].properties[property] = grinderData[property];
            });

            map.getSource(`grinders-${thisGrinder.name}`).setData(thisPointGeoJSON);
            map.getSource(`grinders-extrusion-${thisGrinder.name}`).setData(thisPolyGeoJSON);

            let currentBaseHeight = 0;
            let currentExtrudeHeight = 0;
            const maxHeightRangeProduction = getRange(
              'production_quantity',
              nextProps.map_data.forecast_accuracy.grinders
            );
            const maxHeightRangeForecast = getRange('forecast_quanity', nextProps.map_data.forecast_accuracy.grinders);
            const maxHeightRange = [
              maxHeightRangeProduction[0] + maxHeightRangeForecast[0],
              maxHeightRangeProduction[1] + maxHeightRangeForecast[1],
            ];
            let productionTypes = [];
            let usableGrinderNumbers = [];

            const maxHeights = 990000;
            if (grinderData.production_quantity > grinderData.forecast_quanity) {
              productionTypes = ['forecast_quanity', 'production_quantity'];
              usableGrinderNumbers = [
                grinderData.forecast_quanity,
                grinderData.production_quantity - grinderData.forecast_quanity,
              ];
            } else if (grinderData.production_quantity < grinderData.forecast_quanity) {
              productionTypes = ['production_quantity', 'forecast_quanity'];
              usableGrinderNumbers = [
                grinderData.production_quantity,
                grinderData.forecast_quanity - grinderData.production_quantity,
              ];
            } else {
              // If they are equal?
            }
            productionTypes.forEach(function (production, index) {
              let color = '#FFD966';
              if (production === 'forecast_quanity') {
                color = {
                  property: 'error_pc',
                  type: 'exponential',
                  stops: [
                    [0, 'green'],
                    [1, 'red'],
                  ],
                };
              }
              const thisHeightAsPercentage = usableGrinderNumbers[index] / maxHeightRange[1];
              const thisHeightFromPercentage = maxHeights * thisHeightAsPercentage;
              currentExtrudeHeight += thisHeightFromPercentage;
              map.addLayer({
                id: `grinders-extrusion-${thisGrinder.name}-${production}`,
                type: 'fill-extrusion',
                source: `grinders-extrusion-${thisGrinder.name}`,
                paint: {
                  'fill-extrusion-color': color,
                  'fill-extrusion-height': currentExtrudeHeight,
                  'fill-extrusion-base': currentBaseHeight,
                },
                layout: {
                  visibility: 'visible',
                },
              });
              currentBaseHeight = currentExtrudeHeight;
            });

            map.setLayoutProperty(`grinders-${thisGrinder.name}`, 'visibility', 'none');
          }
        });
      });

      const newSummaryStats = [
        {
          name: 'Forecast Quantity',
          value: commify(nextProps.map_data.forecast_accuracy.system.forecast_quanity),
          grinders: [],
        },
        {
          name: 'Production Quantity',
          value: commify(nextProps.map_data.forecast_accuracy.system.production_quantity),
          grinders: [],
        },
        {
          name: 'Error Percentage',
          value: commify(nextProps.map_data.forecast_accuracy.system.error_pc),
          grinders: [],
        },
      ];
      nextProps.map_data.forecast_accuracy.grinders.forEach(function (forecast) {
        nextProps.grinders.forEach(function (grinder) {
          if (grinder.uid === forecast.grinder_uid) {
            newSummaryStats[0].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(forecast.forecast_quanity),
            });
            newSummaryStats[1].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(forecast.production_quantity),
            });
            newSummaryStats[2].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(forecast.error_pc),
            });
          }
        });
      });

      const legend = [
        {
          color: '#FFD966',
          name: 'Production Quantity',
        },
        {
          color: 'green',
          name: 'Forecast Quantity 0% error',
        },
        {
          color: 'red',
          name: 'Forecast Quantity 100%+ error',
        },
      ];

      this.setState({
        loading: false,
        summaryStats: newSummaryStats,
        legend,
        lines: [],
      });
    }

    /*
  Files Sent report
*/
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.files_sent, nextProps.map_data.files_sent) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'files_sent'))
    ) {
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      const grinderFilesSent = [];
      nextProps.grinders.forEach(function (grinder) {
        nextProps.map_data.files_sent.grinders.forEach(function (files_sent_grinder) {
          if (files_sent_grinder.grinder_uid === grinder.uid) {
            grinderFilesSent.push({
              grinders: [grinder.name],
              files_sent: files_sent_grinder.received_files.length,
            });
          }
        });
      });

      const filesSentRange = getRange('files_sent', grinderFilesSent);

      MapFunctions.setExtrusion(
        'grinders',
        nextProps.grinders,
        grinderFilesSent,
        ['files_sent'],
        filesSentRange,
        false
      );

      const newSummaryStats = [
        {
          name: 'Received Files',
          value: [],
          grinders: [],
        },
      ];
      nextProps.map_data.files_sent.grinders.forEach(function (files_sent_grinder) {
        nextProps.grinders.forEach(function (grinder) {
          if (grinder.uid === files_sent_grinder.grinder_uid) {
            newSummaryStats[0].grinders.push({
              name: grinder.name,
              type: 'list',
              value: files_sent_grinder.received_files,
            });
            newSummaryStats[0].value.concat(files_sent_grinder.received_files);
          }
        });
      });

      const legend = [];
      this.setState({
        loading: false,
        lines: [],
        summaryStats: newSummaryStats,
        legend,
      });

      if (!this.state.panels.summaryData) {
        this.togglePanel('summaryData', { target: { type: 'div' } });
      }
    }

    /*
  Single Lot Tested
*/
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.single_lot_tested, nextProps.map_data.single_lot_tested) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'single_lot_tested'))
    ) {
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      const orderRelationshipQuantityRange = getRange(
        'total_volume',
        nextProps.map_data.single_lot_tested.packer_grinder
      );
      const newLines = MapFunctions.addLines(
        nextProps.grinders,
        nextProps.packer_plants,
        nextProps.map_data.single_lot_tested.packer_grinder,
        ['total_volume', 'single_lot_tested_pc'],
        ['single_lot_total_volume', 'single_lot_tested_pc'],
        'single_lot_total_volume',
        orderRelationshipQuantityRange,
        'single_lot_tested_pc',
        [
          [0, 'red'],
          [1, 'green'],
        ],
        false
      );
      const grindersToPackersAndPackersToGrinders = MapFunctions.associateGrindersAndPackersWithLines(
        nextProps.grinders,
        nextProps.packer_plants
      );
      this.setState({
        lines: newLines,
        grindersToPackersAndPackersToGrinders,
      });

      const newSummaryStats = [
        {
          name: 'Total Volume',
          value: commify(nextProps.map_data.single_lot_tested.system.total_volume),
          grinders: [],
        },
        {
          name: 'Single Lot Tested Percent',
          value: commify(nextProps.map_data.single_lot_tested.system.single_lot_tested_pc),
          grinders: [],
        },
      ];
      nextProps.map_data.single_lot_tested.grinders.forEach(function (single_lot_grinder) {
        nextProps.grinders.forEach(function (grinder) {
          if (grinder.uid === single_lot_grinder.grinder_uid) {
            newSummaryStats[0].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(single_lot_grinder.total_volume),
            });
            newSummaryStats[1].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(single_lot_grinder.single_lot_tested_pc),
            });
          }
        });
      });

      const legend = [
        {
          color: 'red',
          name: '0% tested',
        },
        {
          color: 'green',
          name: '100% tested',
        },
      ];

      this.setState({
        loading: false,
        legend,
        summaryStats: newSummaryStats,
      });
    }

    /*
  Risk Managed Volumes report
*/
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.risk_managed_volume, nextProps.map_data.risk_managed_volume) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'risk_managed_volumes'))
    ) {
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      const propertiesToAdd = ['spot_pc', 'formula_pc', 'contract_pc', 'nof_pc', 'other_pc'];
      nextProps.map_data.risk_managed_volume.grinders.forEach(function (grinderVolume) {
        nextProps.grinders.forEach(function (thisGrinder) {
          if (thisGrinder.uid === grinderVolume.grinder_uid) {
            const thisPointGeoJSON = map.getSource(`grinders-${thisGrinder.name}`)._data;
            const thisPolyGeoJSON = map.getSource(`grinders-extrusion-${thisGrinder.name}`)._data;
            propertiesToAdd.forEach(function (property) {
              thisPointGeoJSON.features[0].properties[property] = 0;
              thisPolyGeoJSON.features[0].properties[property] = 0;
            });
            grinderVolume.spot_pc = grinderVolume.spot_volume / grinderVolume.total_volume;
            grinderVolume.formula_pc = grinderVolume.formula_volume / grinderVolume.total_volume;
            grinderVolume.contract_pc = grinderVolume.contract_volume / grinderVolume.total_volume;
            grinderVolume.nof_pc = grinderVolume.nof_volume / grinderVolume.total_volume;
            grinderVolume.other_pc = grinderVolume.other_volume / grinderVolume.total_volume;
            propertiesToAdd.forEach(function (property) {
              const thisValue = thisPointGeoJSON.features[0].properties[property]
                ? thisPointGeoJSON.features[0].properties[property] + grinderVolume[property]
                : grinderVolume[property];

              thisPointGeoJSON.features[0].properties[property] = thisValue;
              thisPolyGeoJSON.features[0].properties[property] = thisValue;
            });

            map.getSource(`grinders-${thisGrinder.name}`).setData(thisPointGeoJSON);
            map.getSource(`grinders-extrusion-${thisGrinder.name}`).setData(thisPolyGeoJSON);

            let currentBaseHeight = 0;
            let currentExtrudeHeight = 0;
            const maxHeightRange = getRange('total_volume', nextProps.map_data.risk_managed_volume.grinders);
            const contractTypes = ['spot_pc', 'formula_pc', 'contract_pc', 'nof_pc', 'other_pc'];

            const maxHeights = 990000;
            const thisHeightAsPercentage = grinderVolume.total_volume / maxHeightRange[1];
            const thisHeightFromPercentage = maxHeights * thisHeightAsPercentage;
            contractTypes.forEach(function (contract) {
              let color = '#5E6C73';
              if (contract === 'formula_pc') {
                color = '#FFD966';
              } else if (contract === 'contract_pc') {
                color = '#63729D';
              } else if (contract === 'nof_pc') {
                color = '#379';
              } else if (contract === 'other_pc') {
                color = '#99973C';
              }
              currentExtrudeHeight += thisHeightFromPercentage * grinderVolume[contract];

              const layerId = `grinders-extrusion-${thisGrinder.name}-${contract}`;
              const layer = map.getLayer(layerId);

              if (layer) {
                map.removeLayer(layerId);
              }

              map.addLayer({
                id: layerId,
                type: 'fill-extrusion',
                source: `grinders-extrusion-${thisGrinder.name}`,
                paint: {
                  'fill-extrusion-color': color,
                  'fill-extrusion-height': currentExtrudeHeight,
                  'fill-extrusion-base': currentBaseHeight,
                },
                layout: {
                  visibility: 'visible',
                },
              });
              currentBaseHeight = currentExtrudeHeight;
            });

            map.setLayoutProperty(`grinders-${thisGrinder.name}`, 'visibility', 'none');
          }
        });
      });

      const newSummaryStats = [
        {
          name: 'Total Volume',
          value: commify(nextProps.map_data.risk_managed_volume.system.total_volume),
          grinders: [],
        },
        {
          name: 'Spot Volume',
          value: commify(nextProps.map_data.risk_managed_volume.system.spot_volume),
          grinders: [],
        },
        {
          name: 'Formula Volume',
          value: commify(nextProps.map_data.risk_managed_volume.system.formula_volume),
          grinders: [],
        },
        {
          name: 'Contract Volume',
          value: commify(nextProps.map_data.risk_managed_volume.system.contract_volume),
          grinders: [],
        },
        {
          name: 'NOF Volume',
          value: commify(nextProps.map_data.risk_managed_volume.system.nof_volume),
          grinders: [],
        },
        {
          name: 'Other Volume',
          value: commify(nextProps.map_data.risk_managed_volume.system.other_volume),
          grinders: [],
        },
      ];
      nextProps.map_data.risk_managed_volume.grinders.forEach(function (risk_managed) {
        nextProps.grinders.forEach(function (grinder) {
          if (grinder.uid === risk_managed.grinder_uid) {
            newSummaryStats[0].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(risk_managed.total_volume),
            });
            newSummaryStats[1].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(risk_managed.spot_volume),
            });
            newSummaryStats[2].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(risk_managed.formula_volume),
            });
            newSummaryStats[3].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(risk_managed.contract_volume),
            });
            newSummaryStats[4].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(risk_managed.nof_volume),
            });
            newSummaryStats[5].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(risk_managed.other_volume),
            });
          }
        });
      });

      const legend = [
        {
          color: '#5E6C73',
          name: 'Spot',
        },
        {
          color: '#FFD966',
          name: 'Formula',
        },
        {
          color: '#63729D',
          name: 'Contract',
        },
        {
          color: '#379',
          name: 'NOF',
        },
        {
          color: '#99973C',
          name: 'Other',
        },
      ];

      this.setState({
        loading: false,
        summaryStats: newSummaryStats,
        legend,
        lines: [],
      });
    }

    /*
  Delivered on Time report
*/
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.delivered_on_time, nextProps.map_data.delivered_on_time) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'delivered_on_time'))
    ) {
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      // Colour lines according to delivery percentage
      // 90-100 red to green, under 90 red
      const orderRelationshipQuantityRange = getRange(
        'total_volume',
        nextProps.map_data.delivered_on_time.packer_grinder
      );
      const newLines = MapFunctions.addLines(
        nextProps.grinders,
        nextProps.packer_plants,
        nextProps.map_data.delivered_on_time.packer_grinder,
        ['total_volume', 'on_time_in_full_pc'],
        ['on_time_total_volume', 'on_time_in_full_pc'],
        'on_time_total_volume',
        orderRelationshipQuantityRange,
        'on_time_in_full_pc',
        [
          [0, 'red'],
          [0.9, 'red'],
          [1, 'green'],
        ],
        false
      );
      const grindersToPackersAndPackersToGrinders = MapFunctions.associateGrindersAndPackersWithLines(
        nextProps.grinders,
        nextProps.packer_plants
      );
      this.setState({
        lines: newLines,
        grindersToPackersAndPackersToGrinders,
      });

      // Color packers
      MapFunctions.addDataToPackers(
        nextProps.packer_plants,
        nextProps.map_data.delivered_on_time.packer_plants,
        ['total_volume', 'on_time_in_full_pc'],
        ['total_volume', 'on_time_in_full_pc']
      );
      nextProps.packer_plants.forEach(function (packer_plant) {
        if (packer_plant.name.indexOf('Frozen Inventory') === -1) {
          nextProps.map_data.delivered_on_time.packer_plants.forEach(function (packerData) {
            if (packerData.packer_plant_id === packer_plant.id) {
              map.setPaintProperty(`packers-${packer_plant.name}`, 'circle-color', {
                property: 'on_time_in_full_pc',
                type: 'exponential',
                stops: [
                  [0, 'red'],
                  [0.9, 'red'],
                  [1, 'green'],
                ],
              });
            }
          });
        }
      });

      const newSummaryStats = [
        {
          name: 'Total Volume',
          value: commify(nextProps.map_data.delivered_on_time.system.total_volume),
          grinders: [],
        },
        {
          name: 'On Time Full Volume',
          value: commify(nextProps.map_data.delivered_on_time.system.on_time_in_full_volume),
          grinders: [],
        },
      ];
      nextProps.map_data.delivered_on_time.grinders.forEach(function (delivered_grinder) {
        nextProps.grinders.forEach(function (grinder) {
          if (grinder.uid === delivered_grinder.grinder_uid) {
            newSummaryStats[0].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(delivered_grinder.total_volume),
            });
            newSummaryStats[1].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(delivered_grinder.on_time_in_full_volume),
            });
          }
        });
      });

      const legend = [
        {
          color: 'red',
          name: '90% or less volume',
        },
        {
          color: 'green',
          name: '100% volume',
        },
      ];

      this.setState({
        loading: false,
        legend,
        summaryStats: newSummaryStats,
      });
    }

    /*
  Product Age report
*/
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.product_age, nextProps.map_data.product_age) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'product_age'))
    ) {
      // Remove lines
      this.state.lines.forEach(function (line) {
        map.removeLayer(line);
        map.removeSource(line);
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      const orderQuantityRange = getRange('volume', nextProps.map_data.product_age.packer_grinder);
      let product_age_target = false;
      nextProps.configs.forEach(function (config) {
        if (config.name === 'product_age_target') {
          product_age_target = config;
        }
      });
      const newLines = MapFunctions.addLines(
        nextProps.grinders,
        nextProps.packer_plants,
        nextProps.map_data.product_age.packer_grinder,
        ['volume', 'weighted_average_product_age'],
        ['product_age_volume', 'product_age_weighted_average_product_age'],
        'product_age_volume',
        orderQuantityRange,
        'product_age_weighted_average_product_age',
        [
          [0, 'green'],
          [product_age_target.value_float, 'green'],
          [5, 'red'],
        ]
      );
      const grindersToPackersAndPackersToGrinders = MapFunctions.associateGrindersAndPackersWithLines(
        nextProps.grinders,
        nextProps.packer_plants
      );
      this.setState({
        lines: newLines,
        grindersToPackersAndPackersToGrinders,
      });

      const newSummaryStats = [
        {
          name: 'Volume',
          value: commify(nextProps.map_data.product_age.system.volume),
          grinders: [],
        },
        {
          name: 'Weighted Average Product Age',
          value: nextProps.map_data.product_age.system.weighted_average_product_age.toFixed(1),
          grinders: [],
        },
      ];
      nextProps.map_data.product_age.grinders.forEach(function (product_age) {
        nextProps.grinders.forEach(function (grinder) {
          if (grinder.uid === product_age.grinder_uid) {
            newSummaryStats[0].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(product_age.volume),
            });
            newSummaryStats[1].grinders.push({
              name: grinder.name,
              type: 'number',
              value: product_age.weighted_average_product_age.toFixed(1),
            });
          }
        });
      });

      const legend = [
        {
          color: 'green',
          name: '4 Days or less',
        },
        {
          color: 'red',
          name: '5 Days or more',
        },
      ];

      this.setState({
        loading: false,
        summaryStats: newSummaryStats,
        legend,
      });
    }
    /*
  Orders CL report
*/
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.orders_cl, nextProps.map_data.orders_cl) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'orders_cl'))
    ) {
      // Remove lines
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      const orderQuantityRange = getRange('volume', nextProps.map_data.orders_cl.packer_grinder);
      const weightedAverageRange = getRange('weighted_average_cl', nextProps.map_data.orders_cl.packer_grinder);
      const newLines = MapFunctions.addLines(
        nextProps.grinders,
        nextProps.packer_plants,
        nextProps.map_data.orders_cl.packer_grinder,
        ['volume', 'weighted_average_cl'],
        ['orders_cl_volume', 'orders_weighted_average_cl'],
        'orders_cl_volume',
        orderQuantityRange,
        'orders_weighted_average_cl',
        [
          [weightedAverageRange[0], '#FFD966'],
          [weightedAverageRange[1], '#63729D'],
        ]
      );
      const grindersToPackersAndPackersToGrinders = MapFunctions.associateGrindersAndPackersWithLines(
        nextProps.grinders,
        nextProps.packer_plants
      );
      this.setState({
        lines: newLines,
        grindersToPackersAndPackersToGrinders,
      });

      const newSummaryStats = [
        {
          name: 'CL Volume',
          value: commify(nextProps.map_data.orders_cl.system.volume),
          grinders: [],
        },
        {
          name: 'Weighted Average CL',
          value: nextProps.map_data.orders_cl.system.weighted_average_cl.toFixed(2),
          grinders: [],
        },
      ];
      nextProps.map_data.orders_cl.grinders.forEach(function (order_cl) {
        nextProps.grinders.forEach(function (grinder) {
          if (grinder.uid === order_cl.grinder_uid) {
            newSummaryStats[0].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(order_cl.volume),
            });
            newSummaryStats[1].grinders.push({
              name: grinder.name,
              type: 'number',
              value: order_cl.weighted_average_cl.toFixed(2),
            });
          }
        });
      });

      const legend = [
        {
          color: '#FFD966',
          name: 'Low Average CL',
        },
        {
          color: '#63729D',
          name: 'High Average CL',
        },
      ];

      this.setState({
        loading: false,
        summaryStats: newSummaryStats,
        legend,
      });
    }

    /*
  Load Weights
*/
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.load_weights, nextProps.map_data.load_weights) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'load_weights'))
    ) {
      this.state.lines.forEach(function (line) {
        map.removeLayer(line);
        map.removeSource(line);
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);
      MapFunctions.addDataToPackers(
        nextProps.packer_plants,
        nextProps.map_data.load_weights.packer_plants,
        ['average_load_weight'],
        ['average_load_weight']
      );

      nextProps.map_data.load_weights.packer_plants.forEach(function (loadPacker) {
        nextProps.packer_plants.forEach(function (packer_plant) {
          if (packer_plant.id === loadPacker.packer_plant_id) {
            map.setPaintProperty(`packers-${packer_plant.name}`, 'circle-color', {
              property: 'average_load_weight',
              type: 'exponential',
              stops: [
                [0, 'red'],
                [38000, 'red'],
                [40000, 'green'],
              ],
            });
          }
        });
      });

      const newSummaryStats = [
        {
          name: 'Average Load Weight',
          value: commify(nextProps.map_data.load_weights.system.average_load_weight),
          grinders: [],
        },
        {
          name: 'Number',
          value: nextProps.map_data.load_weights.system.number,
          grinders: [],
        },
      ];
      nextProps.map_data.load_weights.grinders.forEach(function (load_weight) {
        nextProps.grinders.forEach(function (grinder) {
          if (grinder.uid === load_weight.grinder_uid) {
            newSummaryStats[0].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(load_weight.average_load_weight),
            });
            newSummaryStats[1].grinders.push({
              name: grinder.name,
              type: 'number',
              value: load_weight.number,
            });
          }
        });
      });

      const legend = [
        {
          color: 'red',
          name: '38,000 lbs or less',
        },
        {
          color: 'green',
          name: '40,000 lbs or more',
        },
      ];

      this.setState({
        loading: false,
        summaryStats: newSummaryStats,
        legend,
        lines: [],
      });
    }

    /*
  Performance report
*/
    if (
      loaded &&
      !initialLoad &&
      !nextProps.map_data.no_data &&
      (!_.isEqual(this.props.map_data.performance, nextProps.map_data.performance) ||
        (!_.isEqual(this.props.map_data.returned, nextProps.map_data.returned) &&
          this.state.current_report === 'performance'))
    ) {
      // Remove lines
      this.state.lines.forEach(function (line) {
        if (map.getLayer(line) && map.getSource(line)) {
          map.removeLayer(line);
          map.removeSource(line);
        }
      });
      MapFunctions.resetGrinders(nextProps.grinders, this.state.filters);
      MapFunctions.resetPackers(nextProps.packer_plants, this.state.filters);

      // Calculating performance maximum and minimum price and volume
      const grinderPerformance = [];
      nextProps.grinders.forEach(function (grinder) {
        nextProps.map_data.performance.forEach(function (perf) {
          if (perf.grinder_uid === grinder.uid) {
            grinderPerformance.push({
              grinders: [grinder.name],
              performance_weighted_average_purchase_price: perf.weighted_average_purchase_price,
              performance_volume: perf.volume,
            });
          }
        });
      });

      const performancePriceRange = getRange('performance_weighted_average_purchase_price', grinderPerformance);
      const performanceVolumeRange = getRange('performance_volume', grinderPerformance);

      MapFunctions.setExtrusion(
        'grinders',
        nextProps.grinders,
        grinderPerformance,
        ['performance_volume', 'performance_weighted_average_purchase_price'],
        performanceVolumeRange,
        performancePriceRange,
        ['#6be585', '#dd3e54']
      );

      const newSummaryStats = [
        {
          name: 'Volume',
          value: 0,
          grinders: [],
        },
        {
          name: 'Price',
          value: 0,
          grinders: [],
        },
      ];
      let wAPP = 0;
      nextProps.map_data.performance.forEach(function (perf) {
        nextProps.grinders.forEach(function (grinder) {
          if (grinder.uid === perf.grinder_uid) {
            newSummaryStats[0].grinders.push({
              name: grinder.name,
              type: 'number',
              value: commify(perf.volume),
            });
            newSummaryStats[1].grinders.push({
              name: grinder.name,
              type: 'dollar',
              value: `$${perf.weighted_average_purchase_price.toFixed(6)}`,
            });
          }
        });
        newSummaryStats[0].value += perf.volume;
        wAPP += perf.weighted_average_purchase_price * perf.volume;
      });
      newSummaryStats[1].value = `$${(wAPP / newSummaryStats[0].value).toFixed(6)}`;
      newSummaryStats[0].value = commify(newSummaryStats[0].value);

      const legend = [
        {
          color: 'red',
          name: 'Highest Purchase Price',
        },
        {
          color: 'green',
          name: 'Lowest Purchase Price',
        },
      ];

      this.setState({
        loading: false,
        lines: [],
        summaryStats: newSummaryStats,
        legend,
      });

      if (!this.state.panels.summaryData) {
        this.togglePanel('summaryData', { target: { type: 'div' } });
      }
    }

    // First load
    if (
      loaded &&
      initialLoad &&
      map &&
      map.isStyleLoaded() &&
      nextProps.packer_plants.length &&
      nextProps.grinders.length &&
      nextProps.input_products.length
    ) {
      const filterSetup = MapFunctions.dataInitialization(nextProps.grinders, nextProps.packer_plants, mapboxgl);

      map.on(
        'click',
        function (e) {
          const features = map.queryRenderedFeatures(e.point);
          if (
            features.length &&
            (this.state.current_report === 'chemical_lean_value' ||
              this.state.current_report === 'delivered_on_time' ||
              this.state.current_report === 'claims' ||
              this.state.current_report === 'orders_cl' ||
              this.state.current_report === 'product_age' ||
              this.state.current_report === 'single_lot_tested')
          ) {
            const firstFeature = features[0];
            if (firstFeature.layer.id.indexOf('line') === -1) {
              MapFunctions.showLines(this.state.grindersToPackersAndPackersToGrinders, firstFeature);
            }
          }
          // Set selected data
          if (features.length) {
            const firstFeature = features[0];
            if (firstFeature.layer.id.indexOf('packer') > -1) {
              const thisData = getData('packers', firstFeature, this.state.current_report, this.props);
              this.setState({ selectedData: thisData });

              if (!this.state.panels.selectedData) {
                this.togglePanel('selectedData', { target: { type: 'div' } });
              }
            } else if (firstFeature.layer.id.indexOf('grinder') > -1) {
              const thisData = getData('grinders', firstFeature, this.state.current_report, this.props);
              this.setState({ selectedData: thisData });

              if (!this.state.panels.selectedData) {
                this.togglePanel('selectedData', { target: { type: 'div' } });
              }
            } else {
              this.setState({ selectedData: false });
            }
          }
        }.bind(this)
      );

      nextProps.dispatch(fetchWeather(nextProps.token));

      this.setState({
        initialLoad: false,
        filters: filterSetup,
        loading: false,
      });
    }
  }

  changeVisibility(type) {
    const { map } = this.state;
    const newFilters = JSON.parse(JSON.stringify(this.state.filters));

    const splitType = type.split(',');
    // If any are unselected when user clicks "all", then select them all, otherwise deselect all
    if (splitType[1] === 'all') {
      let areAnyUnselected = false;
      for (const thisFilter in newFilters[splitType[0]]) {
        if (!newFilters[splitType[0]][thisFilter]) {
          areAnyUnselected = true;
        }
      }
      for (const thisFilter in newFilters[splitType[0]]) {
        newFilters[splitType[0]][thisFilter] = areAnyUnselected;
      }
    } else if (splitType[1].indexOf('group') > -1) {
      // Special packers sub-group checkboxes
      const splitGroup = splitType[1].split('|');
      let areAnyUnselected = false;
      for (const thisFilter in newFilters[splitType[0]]) {
        if (thisFilter.indexOf(splitGroup[1]) > -1) {
          if (!newFilters[splitType[0]][thisFilter]) {
            areAnyUnselected = true;
          }
        }
      }
      for (const thisFilter in newFilters[splitType[0]]) {
        if (thisFilter.indexOf(splitGroup[1]) > -1) {
          newFilters[splitType[0]][thisFilter] = areAnyUnselected;
        }
      }
    } else {
      newFilters[splitType[0]][splitType[1]] = !newFilters[splitType[0]][splitType[1]];
    }

    for (const filterType in newFilters) {
      for (const filter in newFilters[filterType]) {
        if (newFilters[filterType][filter]) {
          if (this.state.current_report === 'performance' && filterType === 'grinders') {
            map.setLayoutProperty(`${filterType}-extrusion-${filter}`, 'visibility', 'visible');
          } else {
            map.setLayoutProperty(`${filterType}-${filter}`, 'visibility', 'visible');
          }
        } else {
          map.setLayoutProperty(`${filterType}-${filter}`, 'visibility', 'none');
          map.setLayoutProperty(`${filterType}-extrusion-${filter}`, 'visibility', 'none');
        }
      }
    }

    this.setState({ filters: newFilters });
  }

  togglePanel(panel, event) {
    if (event.target.type !== 'checkbox') {
      const newPanels = JSON.parse(JSON.stringify(this.state.panels));
      for (const thisPanel in newPanels) {
        if (thisPanel === panel) {
          newPanels[thisPanel] = !newPanels[thisPanel];
        } else {
          newPanels[thisPanel] = false;
        }
      }
      this.setState({
        panels: newPanels,
      });
    }
  }

  refreshReport() {
    const currentGrinders = [];
    const currentPackers = [];
    this.props.grinders.forEach(
      function (grinder) {
        if (this.state.filters.grinders[grinder.name]) {
          currentGrinders.push(grinder);
        }
      }.bind(this)
    );
    this.props.packer_plants.forEach(
      function (grinder) {
        if (this.state.filters.packer_plants[grinder.name]) {
          currentPackers.push(grinder);
        }
      }.bind(this)
    );

    if (this.state.current_report === 'performance') {
      this.props.dispatch(
        fetchPerformance(currentGrinders, this.state.ordered_start_date, this.state.ordered_end_date, this.props.token)
      );
      this.setState({ summarySnippet: 'Cost to produce at 76CL.' });
    } else if (this.state.current_report === 'risk_managed_volumes') {
      this.props.dispatch(
        fetchRiskManagedVolume(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({
        summarySnippet: 'Percentage of volumes by Price Type (Contract, Spot, Formula, NOF or Other).',
      });
    } else if (this.state.current_report === 'delivered_on_time') {
      this.props.dispatch(
        fetchDeliveredOnTime(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({
        summarySnippet: 'Percentage of orders where scheduled delivery date equals actual delivery date.',
      });
    } else if (this.state.current_report === 'load_weights') {
      this.props.dispatch(
        fetchLoadWeights(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({ summarySnippet: 'Average Received Weights by Packer.' });
    } else if (this.state.current_report === 'orders_cl') {
      this.props.dispatch(
        fetchOrdersCL(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({
        summarySnippet: 'Order quantity and weighted average CLs.',
      });
    } else if (this.state.current_report === 'product_age') {
      this.props.dispatch(
        fetchProductAge(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({
        summarySnippet: 'Age of product at actual received date.',
      });
    } else if (this.state.current_report === 'forecast_accuracy') {
      this.props.dispatch(
        fetchForecastAccuracy(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({
        summarySnippet: 'Grinder Production Forecast Accuracy.',
      });
    } else if (this.state.current_report === 'chemical_lean_performance') {
      this.props.dispatch(
        fetchChemicalLeanPerformance(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({ summarySnippet: 'Chemical Lean Performance by Packer.' });
    } else if (this.state.current_report === 'chemical_lean_value') {
      this.props.dispatch(
        fetchChemicalLeanValue(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({ summarySnippet: 'Chemical Lean Value.' });
    } else if (this.state.current_report === 'conversion_costs') {
      this.props.dispatch(
        fetchConversionCosts(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({ summarySnippet: 'Grinder Conversion Costs.' });
    } else if (this.state.current_report === 'cost_savings') {
      this.props.dispatch(
        fetchCostSavings(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({ summarySnippet: 'Cost savings and additional value.' });
    } else if (this.state.current_report === 'agrifoods_frozen_inventory') {
      this.props.dispatch(
        fetchAgrifoodsFrozenInventory(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({
        summarySnippet: '# and average CL of loads at the 5 "Grinder Group Frozen Inventory" packer_plants.',
      });
    } else if (this.state.current_report === 'grinder_yield') {
      this.props.dispatch(
        fetchGrinderYield(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({
        summarySnippet: 'Lbs of input product to lbs output product.',
      });
    } else if (this.state.current_report === 'files_sent') {
      this.props.dispatch(
        fetchFilesSent(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({ summarySnippet: 'Grinders sent required data to BOAB.' });
    } else if (this.state.current_report === 'single_lot_tested') {
      this.props.dispatch(
        fetchSingleLotTested(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({
        summarySnippet: 'Percentage of loads from Packers with Single Lot Testing.',
      });
    } else if (this.state.current_report === 'claims') {
      this.props.dispatch(
        fetchClaims(
          currentGrinders,
          currentPackers,
          this.state.ordered_start_date,
          this.state.ordered_end_date,
          this.props.token
        )
      );
      this.setState({ summarySnippet: 'Registered grinder claims.' });
    } else {
      this.props.dispatch(fetchEmpty());
    }
    this.setState({ loading: true, selectedData: false });
  }

  expandDetail(index) {
    const detailExpanded = JSON.parse(JSON.stringify(this.state.detailExpanded));
    detailExpanded[index] = !detailExpanded[index];
    this.setState({ detailExpanded });
  }

  render() {
    let allPackersSelected = true;
    for (const packer_plant in this.state.filters.packer_plants) {
      if (!this.state.filters.packer_plants[packer_plant]) {
        allPackersSelected = false;
      }
    }

    let allGrindersSelected = true;
    for (const grinder in this.state.filters.grinders) {
      if (!this.state.filters.grinders[grinder]) {
        allGrindersSelected = false;
      }
    }
    const allDCsSelected = false;

    const startDate = {
      placeholder: 'Ordered Start Date',
      className: 'form-control m-input width-100',
    };
    const endDate = {
      placeholder: 'Ordered End Date',
      className: 'form-control m-input width-100',
    };

    const packerGroups = ['JBS', 'Tyson', 'Cargill', 'AFG', 'National Beef', 'Other'];
    const groupedPackers = [[], [], [], [], [], []];

    this.props.packer_plants.forEach(function (packer_plant) {
      if (packer_plant.name.indexOf('Frozen Inventory') === -1) {
        let found = false;
        groupedPackers.forEach(function (groupPacker, index) {
          if (packer_plant.name.indexOf(packerGroups[index]) > -1) {
            groupPacker.push(packer_plant.name);
            found = true;
          }
        });
        if (!found) {
          groupedPackers[5].push(packer_plant.name);
        }
      }
    });

    const currentDataToRender = [];
    if (this.state.selectedData) {
      for (const prop in this.state.selectedData) {
        currentDataToRender.push({
          name: prop,
          value: this.state.selectedData[prop],
        });
      }
    }

    return (
      <div>
        {!this.state.loading ? false : <img className="map-loader" src="./img/loader.gif" />}

        <div className="report-selector">
          <div style={{ display: 'flex' }}>
            <Datetime
              id="ordered_start_date"
              inputProps={startDate}
              closeOnSelect
              value={this.state.ordered_start_date}
              onChange={e => {
                if (e) {
                  this.setState({ ordered_start_date: e.format('YYYY-MM-DD') });
                } else {
                  this.setState({ ordered_start_date: '' });
                }
              }}
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
            <div className="report-to-text">to</div>
            <Datetime
              id="ordered_end_date"
              inputProps={endDate}
              closeOnSelect
              value={this.state.ordered_end_date}
              onChange={e => {
                if (e) {
                  this.setState({ ordered_end_date: e.format('YYYY-MM-DD') });
                } else {
                  this.setState({ ordered_end_date: '' });
                }
              }}
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
            <div style={{ marginTop: '5px' }}>
              <select
                className="form-control width-200"
                onChange={e => this.setState({ current_report: e.target.value })}
              >
                <optgroup label="Financial">
                  <option value="performance">76CL Performance</option>
                  <option value="cost_savings">Cost Savings</option>
                  {this.props.user.organisation.org_type.indexOf('grinder') === -1 ? (
                    <option value="performance">Buy Performance</option>
                  ) : (
                    false
                  )}
                  <option value="claims">Claims</option>
                  <option disabled value="chemical_lean_performance">
                    Chemical Lean Performance
                  </option>
                  <option disabled value="chemical_lean_value">
                    Chemical Lean Value
                  </option>
                </optgroup>
                <optgroup label="Orders">
                  <option value="orders_cl">Orders CL</option>
                  <option value="risk_managed_volumes">Risk Managed Volumes</option>
                  <option value="product_age">Product Age</option>
                  <option value="delivered_on_time">Delivered in Full On Time</option>
                  <option value="single_lot_tested">Single Lot Testing</option>
                  <option value="load_weights">Load Weights</option>
                </optgroup>
                <optgroup label="Grinder Performance">
                  <option disabled value="grinder_yield">
                    Yield
                  </option>
                  <option value="forecast_accuracy">Grinder Production Forecast Accuracy</option>
                  <option value="files_sent">Files Sent</option>
                  <option disabled value="conversion_costs">
                    Conversion Costs
                  </option>
                </optgroup>
                <optgroup label="Agrifoods">
                  <option disabled value="agrifoods_frozen_inventory">
                    Agrifoods Frozen Inventory
                  </option>
                </optgroup>
              </select>
            </div>
            <div>
              <button
                style={{ marginTop: '5px', marginLeft: '10px' }}
                onClick={this.refreshReport.bind(this)}
                className="btn pull-right btn-info"
              >
                Refresh Report
              </button>
            </div>
          </div>
        </div>

        <div className="map-overlay">
          <div className="grinders-overlay">
            <div className="overlay-header" onClick={this.togglePanel.bind(this, 'grinders')}>
              <input
                type="checkbox"
                checked={allGrindersSelected}
                onChange={this.changeVisibility.bind(this, 'grinders,all')}
              />{' '}
              Grinders{' '}
              <div
                className="pull-right"
                style={{
                  borderRadius: '50%',
                  width: '15px',
                  height: '15px',
                  background: '#2C333D',
                  marginTop: '5px',
                }}
              />
            </div>
            <div className={`overlay-collapse ${!this.state.panels.grinders ? 'collapsed' : ''}`}>
              {this.props.grinders.map(grinder => {
                return (
                  <div key={`grinder-${grinder.name}`}>
                    <input
                      type="checkbox"
                      checked={this.state.filters.grinders[grinder.name] || false}
                      onChange={this.changeVisibility.bind(this, `grinders,${grinder.name}`)}
                    />{' '}
                    {grinder.name}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="packers-overlay">
            <div className="overlay-header" onClick={this.togglePanel.bind(this, 'packers')}>
              <input
                type="checkbox"
                checked={allPackersSelected}
                onChange={this.changeVisibility.bind(this, 'packers,all')}
              />{' '}
              Packers{' '}
              <div
                className="pull-right"
                style={{
                  borderRadius: '50%',
                  width: '15px',
                  height: '15px',
                  background: '#EC864F',
                  marginTop: '5px',
                }}
              />
            </div>
            <div className={`overlay-collapse ${!this.state.panels.packer_plants ? 'collapsed' : ''}`}>
              {packerGroups.map((packerGroup, i) => {
                let allPackerGroupsSelected = true;
                groupedPackers[i].forEach(
                  function (groupPacker) {
                    if (!this.state.filters.packer_plants[groupPacker]) {
                      allPackerGroupsSelected = false;
                    }
                  }.bind(this)
                );
                if (groupedPackers[i].length) {
                  return (
                    <div key={`packer-group-${i}`} style={{ marginBottom: '10px' }}>
                      <p>
                        {packerGroup !== 'Other' ? (
                          <input
                            type="checkbox"
                            checked={allPackerGroupsSelected}
                            onChange={this.changeVisibility.bind(this, `packers,group|${packerGroup}`)}
                          />
                        ) : (
                          false
                        )}
                        &nbsp;<strong>{packerGroup}</strong>
                      </p>
                      {groupedPackers[i].map((groupPacker, k) => {
                        return (
                          <div key={`packer-group-${i}-${k}`}>
                            <input
                              type="checkbox"
                              checked={this.state.filters.packer_plants[groupPacker]}
                              onChange={this.changeVisibility.bind(this, `packers,${groupPacker}`)}
                            />{' '}
                            {groupPacker}
                          </div>
                        );
                      })}
                    </div>
                  );
                }
                return false;
              })}
            </div>
          </div>
          <div className="dcs-overlay">
            <div className="overlay-header" onClick={this.togglePanel.bind(this, 'dcs')}>
              <input type="checkbox" checked={allDCsSelected} onChange={this.changeVisibility.bind(this, 'dcs,all')} />{' '}
              Distribution Centers
            </div>
            <div className={`overlay-collapse ${!this.state.panels.dcs ? 'collapsed' : ''}`} />
          </div>
          {this.state.selectedData ? (
            <div className="selected-overlay">
              <div className="overlay-header" onClick={this.togglePanel.bind(this, 'selectedData')}>
                {this.state.selectedData.name}
              </div>
              <div className={`overlay-collapse ${!this.state.panels.selectedData ? 'collapsed' : ''}`}>
                <table className="table">
                  <tbody>
                    {currentDataToRender.map((item, i) => {
                      if (item.name !== 'name' && item.name !== 'grinder_uid' && item.name !== 'packer_plant_id') {
                        let { value } = item;
                        let { name } = item;

                        if (item.name.indexOf('price') > -1) {
                          value = value.toFixed(6);
                        }
                        if (item.name.indexOf('_cl') > -1 || item.name.indexOf('_age') > -1) {
                          value = value.toFixed(2);
                        }
                        if (item.name.indexOf('volume') > -1 || item.name.indexOf('_weight') > -1) {
                          value = commify(value);
                        }
                        if (item.name.indexOf('count') > -1 || item.name.indexOf('value') > -1) {
                          value = commify(value);
                        }
                        if (item.name.indexOf('_pc') > -1) {
                          name = name.replace('_pc', '_percent');
                          value = `${(value * 100).toFixed(2)}%`;
                        }
                        if (item.name === 'received_files') {
                          return (
                            <tr key={`table-data-${i}`}>
                              {name !== 'info' ? (
                                <td style={{ textTransform: 'capitalize' }}>{name.replace(/_/g, ' ')}</td>
                              ) : (
                                false
                              )}
                              <td>
                                {value.map((file, i) => {
                                  return <p key={`file-name-${i}`}>{file.filename}</p>;
                                })}
                              </td>
                            </tr>
                          );
                        }
                        return (
                          <tr key={`table-data-${i}`}>
                            {name !== 'info' ? (
                              <td style={{ textTransform: 'capitalize' }}>{name.replace(/_/g, ' ')}</td>
                            ) : (
                              false
                            )}
                            <td>{value}</td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            false
          )}
          <div className="summary-overlay">
            <div className="overlay-header" onClick={this.togglePanel.bind(this, 'summaryData')}>
              System Summary Data
            </div>
            <div className="overlay-collapse">
              <p style={{ marginTop: '-6px', marginBottom: '0px' }}>
                <small>{this.state.summarySnippet}</small>
              </p>
              <div>
                {this.state.summaryStats.map((stats, i) => {
                  const grinderRows = [];
                  const grindersOrPackers = stats.packer_plants ? 'packers' : 'grinders';
                  if (stats.name !== 'Received Files') {
                    stats[grindersOrPackers].forEach(function (grinder, k) {
                      const floatValue1 = parseFloat(grinder.value.toString().replace(/,/g, '').replace('$', ''));
                      const floatValue2 = parseFloat(stats.value.toString().replace(/,/g, '').replace('$', ''));
                      let divided = 0;

                      if (floatValue1 > 0) {
                        divided = floatValue1 / floatValue2;
                      }

                      grinderRows.push(
                        <tr key={`stats-${i}-${k}`} style={{ fontSize: '10px' }}>
                          <td style={{ padding: '0.5rem' }}>{grinder.name}</td>
                          <td style={{ padding: '0.5rem' }}>{grinder.value}</td>
                          <td style={{ padding: '0.5rem' }}>{`${(divided * 100).toFixed(2)}%`}</td>
                        </tr>
                      );
                    });
                  } else {
                    // If there is a list, like for files sent
                    stats[grindersOrPackers].forEach(function (grinder, k) {
                      grinderRows.push(
                        <tr key={`stats-${i}-${k}`} style={{ fontSize: '10px' }}>
                          <td style={{ padding: '0.5rem' }}>{grinder.name}</td>
                          <td style={{ padding: '0.5rem' }}>
                            {grinder.value.map((file_sent, i) => {
                              return <p key={`file-sent-${i}`}>{file_sent.filename}</p>;
                            })}
                          </td>
                          <td style={{ padding: '0.5rem' }} />
                        </tr>
                      );
                    });
                  }
                  if (stats.name !== 'Received Files') {
                    return (
                      <table key={`data-table-${i}`} className="table" style={{ marginBottom: '0px' }}>
                        <tbody>
                          <tr
                            style={{ cursor: 'pointer' }}
                            onClick={this.expandDetail.bind(this, i)}
                            key={`stats-${i}`}
                          >
                            <td>{stats.name}</td>
                            <td>{stats.value}</td>
                            <td>
                              <i
                                className={`collapsing-icon fa fa-caret-right ${
                                  this.state.detailExpanded[i] ? 'opened' : ''
                                }`}
                              />
                            </td>
                          </tr>
                          {this.state.detailExpanded[i] ? grinderRows : false}
                        </tbody>
                      </table>
                    );
                  }
                  // If there is a list, like for files sent
                  return (
                    <table key={`data-table-${i}`} className="table" style={{ marginBottom: '0px' }}>
                      <tbody>
                        <tr style={{ cursor: 'pointer' }} onClick={this.expandDetail.bind(this, i)} key={`stats-${i}`}>
                          <td>{stats.name}</td>
                          <td>
                            {stats.value.map((file_sent, i) => {
                              return <p key={`file-sent-${i}`}>{file_sent.filename}</p>;
                            })}
                          </td>
                          <td>
                            <i
                              className={`collapsing-icon fa fa-caret-right ${
                                this.state.detailExpanded[i] ? 'opened' : ''
                              }`}
                            />
                          </td>
                        </tr>
                        {this.state.detailExpanded[i] ? grinderRows : false}
                      </tbody>
                    </table>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="map-legend">
          {this.state.legend.map((legendItem, i) => {
            return (
              <div key={`legend-${i}`} style={{ display: 'flex' }}>
                <div
                  style={{
                    borderRadius: '50%',
                    width: '15px',
                    height: '15px',
                    background: legendItem.color,
                    marginTop: '3px',
                    marginRight: '5px',
                  }}
                />
                <div>{legendItem.name}</div>
              </div>
            );
          })}
        </div>

        {this.state.needsRefresh ? (
          <div className="refresh-alert">
            <div className="alert alert-danger">
              Please refresh the report to see your changes reflected in the report data.
            </div>
          </div>
        ) : (
          false
        )}

        {this.state.no_data ? (
          <div className="no-data-alert">
            <div className="alert alert-info">No data was fetched for the requested report.</div>
          </div>
        ) : (
          false
        )}

        <div id="map" style={{ width: '100%', height: window.innerHeight - 60 }} />
      </div>
    );
  }
}

export default MapDashboard;
