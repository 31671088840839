import React from 'react';
import SummaryItem from './SummaryItem';

const PackerOfferFormSummary = props => {
  return (
    <div style={{ paddingTop: '60px' }}>
      <div className="col-sm-12">
        <h3>Summary</h3>
        <hr />
      </div>
      <div className="row col-sm-12">
        <div className="col-sm-1" />
        <div className="col-sm-2 text-center" style={{ paddingTop: '35px' }}>
          Fresh/Frozen
        </div>
        <div className="col-sm-1">
          <label>&nbsp;</label>
          <SummaryItem lines={props.lines} name="fresh_or_frozen" />
        </div>
        <div className="col-sm-8">
          <div className="col-sm-12">
            <label htmlFor="total_count">How many offers would you like to make? </label>
            <input
              value={props.total_count}
              onChange={e => {
                props.onValueChange('total_count', e ? e.target.value : null, props.index);
              }}
              id="total_count"
              type="number"
              className="form-control"
              onWheelCapture={e => {
                e.target.blur();
              }}
            />
          </div>
        </div>
        <div className="col-sm-1" />
        <div className="col-sm-2 text-center">Average Load CL</div>
        <div className="col-sm-1">
          <SummaryItem lines={props.lines} name="avg_cl" />
        </div>
        <div className="col-sm-8" />
        <div className="col-sm-1" />
        <div className="col-sm-2 text-center">Average Price Per Unit</div>
        <div className="col-sm-1">
          <SummaryItem lines={props.lines} name="avg_price_per_unit" />
        </div>
        <div className="col-sm-9" />
      </div>
    </div>
  );
};

export default PackerOfferFormSummary;
