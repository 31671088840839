const schema = [
    {
      name: 'group_housed_premium_configs',
      displayName: 'Group Housed Premium',
      nested: false,
      systemIdField: 'id',
      displayIdField: 'packer_plant_id',
      deletable: false,
      tabGroup: { key: 'pricing', label: 'Pricing' },
      createMultiple: false,
      path: '/group_housed_premium',
      fields: [
        {
          name: 'id',
          displayName: 'ID',
          dataType: 'integer',
          required: true,
          systemIdentifier: true,
          hidden: true,
        },
        {
          name: 'product_category',
          displayName: 'Product Category',
          tooltip: 'Based on the product type selection, choose the category of the products.',
          dataType: 'reference',
          referenceType: 'select',
          referenceEntityType: 'form_fields',
          referenceEntitySubType: 'input_product_category',
          required: true,
        },
        {
          name: 'product_sub_category',
          displayName: 'Product Sub Category',
          tooltip: 'Based on the category selection, choose the subcategory of the products.',
          dataType: 'reference',
          referenceType: 'select',
          referenceEntityType: 'form_fields',
          referenceEntitySubType: 'input_product_subcategory',
          required: true,
        },
        {
          name: 'packer_plant_id',
          displayName: 'Establishment',
          tooltip: 'Select the Establishments of the Group Housed Premium Rate',
          dataType: 'reference',
          referenceType: 'select',
          referenceEntityType: 'packer_plant_pork',
          required: true,
          filterable: true,
        },
        {
          name: 'grinder_uid',
          displayName: 'Grinder',
          tooltip: 'Select the grinder associated with the group housed premium rate',
          dataType: 'reference',
          referenceType: 'select',
          referenceEntityType: 'grinder',
          required: true,
          filterable: true,
        },
        {
          name: 'cost',
          displayName: 'Cost',
          tooltip: 'Amount charged for the freight service',
          dataType: 'number',
          required: true,
        },
        {
          name: 'delivery_date_from',
          displayName: 'Delivery Date From',
          tooltip: 'Start date from which the delivery is expected to begin',
          dataType: 'date',
          required: true,
        },
        {
          name: 'delisted',
          displayName: 'Is Delisted',
          dataType: 'boolean',
        },
      ],
    },
  ];
  export default schema;