import { connect } from 'react-redux';

import InternationalConfirmationComponent from '../../components/orders/order-form/InternationalConfirmation/InternationalConfirmation';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';
import { convertToCamelCase } from '../../helpers';

const mapStateToProps = state => {
  const {
    grinders,
    input_products: inputProducts,
    packer_plants: packerPlants,
    packers,
    units_of_measure: unitsOfMeasure,
  } = { ...findEntitiesInState(state) };

  const result = {
    email: state.email || {},
    inputProducts,
    grinders,
    packerPlants,
    packers,
    fastLoaded: state.orders.fastLoaded,
    latestOrders: state.orders.latestOrders,
    poDocuments: state.po_documents,
    token: state.user.token,
    unitsOfMeasure,
    user: state.user,
    users: state.users,
    configs: state.configs,
    apiRequestError: state.error,
  };
  const cameled = convertToCamelCase(result);
  return cameled;
};

const InternationalConfirmation = connect(mapStateToProps)(InternationalConfirmationComponent);

export default InternationalConfirmation;
