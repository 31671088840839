import { types, actions } from '../actions/actions_buy_activity';

const defaultState = {
  requesting: false,
  error: null,

  requestingRecentPOActivity: false,
  recentPOActivityError: null,
  recentPOs: [],
  recentPOFilters: {},

  requestingLiveClPrices: false,
  liveCLPriceError: null,
  liveCLs: [],
  wtdAvg76: undefined,

  requestingBuyVolumes: false,
  buyVolumesError: null,
  buyVolumes: [],
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.BUY_ACTIVITY_REQUESTED:
      return {
        ...state,
        requesting: true,
        error: null,
      };
    case types.BUY_ACTIVITY_REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
        recentPOs: action.payload.poActivity,
      };
    case types.BUY_ACTIVITY_REQUEST_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };

    case types.LIVE_CL_PRICES_REQUESTED:
      return {
        ...state,
        requestingLiveClPrices: true,
        liveCLPriceError: null,
        liveCLs: [],
      };
    case types.LIVE_CL_PRICES_REQUEST_SUCCESS:
      return {
        ...state,
        requestingLiveClPrices: false,
        liveCLs: action.payload.cl_data,
        wtdAvg76: action.payload.wtd_avg_76_buy_price,
      };
    case types.LIVE_CL_PRICES_REQUEST_FAILURE:
      return {
        ...state,
        requestingLiveClPrices: false,
        liveCLPriceError: action.payload,
      };

    case types.RECENT_PO_ACTIVITY_REQUESTED:
      return {
        ...state,
        requestingRecentPOActivity: true,
        recentPOActivityError: null,
        recentPOs: action.payload.clearOld ? [] : state.recentPOs,
      };
    case types.RECENT_PO_ACTIVITY_REQUEST_SUCCESS:
      return {
        ...state,
        requestingRecentPOActivity: false,
        recentPOs: action.payload,
      };
    case types.RECENT_PO_ACTIVITY_REQUEST_FAILURE:
      return {
        ...state,
        requestingRecentPOActivity: false,
        recentPOActivityError: action.payload,
      };

    case types.BUY_VOLUMES_REQUESTED:
      return {
        ...state,
        requestingBuyVolumes: true,
        buyVolumesError: null,
        buyVolumes: [],
      };
    case types.BUY_VOLUMES_REQUEST_SUCCESS:
      return {
        ...state,
        requestingBuyVolumes: false,
        buyVolumes: action.payload,
      };
    case types.BUY_VOLUMES_REQUEST_FAILURE:
      return {
        ...state,
        requestingBuyVolumes: false,
        buyVolumesError: action.payload,
      };
    case types.RECENT_PO_ACTIVITY_FILTER_SUCCESS:
      return {
        ...state,
        recentPOFilters: action.payload
      };
    case types.RECENT_PO_ACTIVITY_FILTER_FAILURE:
      return {
        ...state,
        recentPOFilters: {},
      };
    default:
      return state;
  }
};

export default reducer;
