import React from 'react';
import { Radio, Typography } from 'antd';
import { MARKET_FOCUS } from '../../../../slices/markets/marketsConfig';
import { getRenameExportToImport } from '../../../../config/markets';

export default function FilterFocus(props) {
  const { activeFocus, onActiveFocusChange } = props;
  return (
    <div>
      <Typography.Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Market
      </Typography.Text>
      <div>
        <Radio.Group value={activeFocus} onChange={e => onActiveFocusChange(e.target.value)}>
          <Radio.Button value={MARKET_FOCUS.DOMESTIC}>Domestic</Radio.Button>
          <Radio.Button value={MARKET_FOCUS.EXPORT}>{getRenameExportToImport()}</Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
}
