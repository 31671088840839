import axios from 'axios';
import Constants from '../Constants';

export const BASE_URL = `${Constants.URL}strategy-types`;

export async function fetchStrategyTypesRequest() {
  const response = await axios.get(`${BASE_URL}`);
  return response.data;
}

export async function postStrategyTypeRequest(strategyType) {
  const response = await axios.post(`${BASE_URL}`, strategyType);
  return response.data;
}

export async function patchStrategyTypeRequest(strategyType) {
  const response = await axios.patch(`${BASE_URL}/${strategyType.id}`, strategyType);
  return response.data;
}

export async function deleteStrategyTypeRequest(strategyType) {
  await axios.delete(`${BASE_URL}/${strategyType.id}`);
  return true;
}
