import organisation from './organisation';
import role from './role';
import user from './user';
import contact from './contact';
import access_level from './access_level';
import form_fields from './formFields';

const schema = [...organisation, ...role, ...user, ...contact, ...access_level, ...form_fields];

export default schema;
