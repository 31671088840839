/*
 * Analyzes a list of flat objects to determine field types, formatting hints,
 * and other data that may be useful for rendering lists of data that may not be known
 * ahead of time.
 *
 * This expects that each object in the list adheres to the schema of the first object.
 */
const analyzer = (data, limit = 500) => {
  const fields = Object.keys(data[0]);
  const fieldConfigs = {};
  const dataSize = data.length;

  // Set up the configuration object
  fields.forEach(field => {
    fieldConfigs[field] = {
      fieldName: field,
      count: 0,
      undefOrNull: 0,
      uniqueValues: new Set(),
      dataType: undefined,
      selectable: undefined,
      filterable: undefined,
      isSearchable: undefined,
      sortable: undefined,
      complete: !(data.length > limit),
      numberCount: 0,
      dateCount: 0,
      stringCount: 0,
    };
  });

  // Initial processing
  data.forEach(d => {
    fields.forEach(field => {
      const val = d[field];
      if (val === undefined || val === null || val === '') {
        fieldConfigs[field].undefOrNull++;
        return;
      }

      fieldConfigs[field].count++;
      if (typeof val !== 'object') {
        fieldConfigs[field].uniqueValues.add(val);
      }

      if (!isNaN(val)) {
        fieldConfigs[field].numberCount++;
      } else if (false === true) {
        // TODO: figure out date parsing later.
        fieldConfigs[field].dateCount++;
      } else {
        fieldConfigs[field].stringCount++;
      }
    });
  });

  // Post-processing
  Object.entries(fieldConfigs).forEach(([key, value]) => {
    // Make a guess at a datatype
    if (value.stringCount > 0) {
      value.dataType = 'string';
    } else if (value.dateCount > 0) {
      value.dataType = 'date';
    } else if (value.numberCount > 0) {
      value.dataType = 'number';
    } else {
      value.dataType = 'unknown';
    }

    // Determine type of filters to use, if any
    if (value.uniqueValues.size <= 1) {
      value.filterable = false;
      value.isSearchable = false;
      value.selectable = false;
      value.sortable = false;
    } else if (
      value.dataType === 'string' &&
      value.uniqueValues.size > 1 &&
      // value.uniqueValues.size / Math.min(dataSize, limit) < 0.2 &&
      value.count / Math.min(dataSize, limit) > 0.5
    ) {
      value.selectable = true;
      value.isSearchable = false;
      value.filterable = true;
      value.sortable = true;
    } else {
      value.selectable = false;
      value.isSearchable = true;
      value.filterable = true;
      value.sortable = true;
    }
  });

  return fieldConfigs;
};

export default analyzer;
