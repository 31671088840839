import { createSelector } from '@reduxjs/toolkit';
import { selectMarketsDataRaw } from './selectBasicMarketData';

/**
 * Returns a structure with data required for the markets quality scorecard
 */
export const selectMarketsQualityScorecardData = createSelector(selectMarketsDataRaw, dataRaw => {
  if (!dataRaw) return null;
  const { packerPlantScores } = dataRaw;

  const sortedScores = packerPlantScores.slice().sort((a, b) => a.score - b.score);

  const buckets = [
    {
      bucket: 10,
      count: sortedScores.filter(x => x.score < 70).length,
    },
    {
      bucket: 70,
      count: sortedScores.filter(x => x.score >= 70 && x.score < 80).length,
    },
    {
      bucket: 80,
      count: sortedScores.filter(x => x.score >= 80 && x.score < 90).length,
    },
    {
      bucket: 90,
      count: sortedScores.filter(x => x.score >= 90 && x.score < 95).length,
    },
    {
      bucket: 95,
      count: sortedScores.filter(x => x.score >= 95).length,
    },
  ];

  const bottom = sortedScores.slice(0, 3);
  const top = sortedScores.slice(-3).sort((a, b) => b.score - a.score);

  return {
    top,
    bottom,
    buckets,
    total: sortedScores.length,
  };
});
