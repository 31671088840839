import Constants from '../Constants';

export const KPIS_FETCHED = 'KPIS_FETCHED';
export const KPIS_ERROR = 'KPIS_ERROR';

/* ---------------------------------
    ACTIONS
-----------------------------------*/
export function loadKPIs(kpis) {
  return {
    type: KPIS_FETCHED,
    payload: kpis,
  };
}

export function errorKPIs(error) {
  return {
    type: KPIS_ERROR,
    payload: error,
  };
}

/* ---------------------------------
    THUNKS
-----------------------------------*/
export function fetchDashboardKPIs(grinderUids, packerPlantIds, startDate, endDate, keys, token) {
  return dispatch => {
    const queryString = `keys=${keys}`;

    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}dashboard/kpis?${queryString}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorKPIs))
      .then(json => {
        dispatch(loadKPIs(json));
      })
      .catch(error => dispatch(errorKPIs(error)));
  };
}
