import { connect } from 'react-redux';

import DynamicLayoutContainer from '../../components/layout/DynamicLayoutContainer';

const mapStateToProps = state => {
  return {
    nextGen: state.user.next_gen_ui,
  };
};

export default connect(mapStateToProps)(DynamicLayoutContainer);
