import { connect } from 'react-redux';
// Local Deps
import EditInternationalOrderComponent from '../../components/orders/order-form/edit-form/EditInternationalOrder';
import Constants from '../../Constants';
import { convertToCamelCase } from '../../helpers';
import { findAllEntitiesWithoutFilterInState, findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

const mapStateToProps = (state, props) => {
  const currentOrder = state.orders.netsuite_purchase_order || {};
  const { end_users: endUsers, surcharge_types: surchargeTypes, voyage_legs } = { ...findEntitiesInState(state) };
  const result = {
    ...findEntitiesInState(state),
    ...findEntitiesInState(state, { form_fields: "form_fields" }),
    token: state.user.token,
    currentOrder,
    user: state.user,
    users: state.users || [],
    voyage: currentOrder?.voyage?.[0] || Constants.INITIAL_VOYAGE,
    voyageLegs: voyage_legs,
    poDocuments: state.po_documents || [],
    orders: state.orders,
    email: state.email,
    configs: state.configs,
    endUsers,
    surchargeTypes,
    settingsConfig: state.settingsConfig,
    ...props,
    packageWeights: state.packageWeights,
    transporters: state.transporters,
    error: state.error,
    ...findAllEntitiesWithoutFilterInState(state, {
      all_grinders: 'grinder',
      all_packers: 'packer',
      all_packer_plants: 'packer_plant',
      all_input_products: 'input_product',
      all_issues_xcodes: 'load_notes',
    }),
  };
  const cameled = convertToCamelCase(result);
  return cameled;
};

const EditInternationalOrder = connect(mapStateToProps)(EditInternationalOrderComponent);

export default EditInternationalOrder;
