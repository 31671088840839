import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Home from '../../components/pages/Home'

const mapStateToProps = state => ({
  to: state.user.home_url || '/',
  user: state.user
});

export default connect(mapStateToProps)(Home);
