/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Layout, Result, Row, Card } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQualityData, setMarketKey } from '../../../slices/quality/qualityReducers';
import { setActiveMarket } from '../../../slices/markets/marketReducers';
import { PACKER_PLANTS_ALL, PACKER_PLANT_RANKING_COMPONENTS } from '../../../slices/quality/qualityConfig';
import {
  selectQualityError,
  selectQualityLoading,
  selectQualityMarket,
  selectQualityPackerPlant,
  selectQualityTimePeriod,
  selectQualityTimeScale,
} from '../../../slices/quality/selectors/selectBasicQualityData';
import { selectQualityFetchParameters } from '../../../slices/quality/selectors/selectQualityFetchParameters';
import { MARKETS } from '../../../config/markets';
import LoadingOverlay from '../../../shared/LoadingOverlay/LoadingOverlay';
import QualityFilters from './QualityFilters';
import QualityPackerPlantScorecard from '../../../components/analyticDashboard/markets/QualityPackerPlantScorecard/QualityPackerPlantScorecard';
import { selectQualityPackerPlantScores } from '../../../slices/quality/selectors/selectQualityPackerPlantScores';
import { selectQualityASLUpdates } from '../../../slices/quality/selectors/selectQualityASLUpdates';
import { selectPackerPlantHistory } from '../../../slices/quality/selectors/selectPackerPlantHistory';
import { selectQualityAudit } from '../../../slices/quality/selectors/selectQualityAudit';
import { selectQualityAuditSinglePackerPlant } from '../../../slices/quality/selectors/selectQualityAuditSinglePackerPlant';
import { selectQualityClaims } from '../../../slices/quality/selectors/selectQualityClaims';
import { selectQualityDIFOT } from '../../../slices/quality/selectors/selectQualityDIFOT';
import { selectQualityFreshnessDays } from '../../../slices/quality/selectors/selectQualityFreshnessDays';
import { selectQualityNCRs } from '../../../slices/quality/selectors/selectQualityNCRs';
import { selectQualityPackerPlantOptions } from '../../../slices/quality/selectors/selectQualityPackerPlantOptions';
import NCRs from '../../../components/analyticDashboard/quality/NCRs/NCRs';
import Claims from '../../../components/analyticDashboard/quality/Claims/Claims';
import FreshnessDays from '../../../components/analyticDashboard/quality/FreshnessDays/FreshnessDays';
import DIFOT from '../../../components/analyticDashboard/quality/DIFOT/DIFOT';
import CardTitle from '../../../shared/CardTitle/CardTitle';
import PackerPlantHistory from '../../../components/analyticDashboard/quality/PackerPlantHistory/PackerPlantHistory';
import { format } from 'date-fns';
import { singleMonthLetter } from '../../../modules/format';
import ASLUpdates from '../../../components/analyticDashboard/quality/ASLUpdates/ASLUpdates';
import styles from './Quality.module.css';
import Audit from '../../../components/analyticDashboard/quality/Audit/Audit';
import TabBar from '../../../shared/TabBar';
import Constants from '../../../Constants';
import IUnleashClassFlagProvider from '../../../components/unleash/UnleashClassFlagProvider';
// This container is for the Quality page as a whole and manages data

function ComingSoon() {
  return (
    <div className={styles.Quality_comingSoon}>
      <p>Coming Soon</p>
    </div>
  );
}

const Quality = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectQualityError);
  const loading = useSelector(selectQualityLoading);
  const timePeriod = useSelector(selectQualityTimePeriod);
  const timeScale = useSelector(selectQualityTimeScale);
  const market = useSelector(selectQualityMarket);
  const packerPlantScores = useSelector(selectQualityPackerPlantScores);
  const aslUpdates = useSelector(selectQualityASLUpdates);
  const packerPlantHistory = useSelector(selectPackerPlantHistory);
  const packerHistory = useSelector(selectPackerPlantHistory);
  const ncrsData = useSelector(selectQualityNCRs);
  const claimsData = useSelector(selectQualityClaims);
  const auditData = useSelector(selectQualityAudit);
  const auditSinglePackerPlantData = useSelector(selectQualityAuditSinglePackerPlant);
  const freshnessDaysData = useSelector(selectQualityFreshnessDays);
  const difotData = useSelector(selectQualityDIFOT);
  const activePackerPlant = useSelector(selectQualityPackerPlant);
  const activeMarket = useSelector(selectQualityMarket);
  const packerPlantOptions = useSelector(selectQualityPackerPlantOptions);

  const { market: marketParam } = useParams();

  // Parameters for data fetch
  const fetchParameters = useSelector(selectQualityFetchParameters);

  React.useEffect(() => {
    if (marketParam === MARKETS.Australia.url) {
      dispatch(setActiveMarket(MARKETS.Australia.key));
      dispatch(setMarketKey(MARKETS.Australia.key));
      dispatch(fetchQualityData({ ...fetchParameters, market: MARKETS.Australia.key }));
    }
    if (marketParam === MARKETS.USA.url) {
      dispatch(setActiveMarket(MARKETS.USA.key));
      dispatch(setMarketKey(MARKETS.USA.key));
      dispatch(fetchQualityData({ ...fetchParameters, market: MARKETS.USA.key }));
    }
  }, [marketParam, setActiveMarket, setMarketKey, fetchParameters]);

  if (error) {
    return (
      <div>
        <Result title="Error" status="error" subTitle={error} />
      </div>
    );
  }

  const selectedPackerPlant = [
    PACKER_PLANTS_ALL,
    MARKETS.Australia.key,
    MARKETS['New Zealand'].key,
    MARKETS.USA.key,
    MARKETS.Canada.key,
  ].includes(activePackerPlant.id)
    ? undefined
    : activePackerPlant;

  const selectedRegion = !selectedPackerPlant && (activePackerPlant.id === 'All' ? market.key : activePackerPlant.id);

  const currency = [MARKETS.Australia.key, MARKETS['New Zealand'].key, MARKETS.USA.key, MARKETS.Canada.key].includes(
    activePackerPlant.id
  )
    ? MARKETS[activePackerPlant.id].currency
    : market.currency;

  const selectedPackerPlantNameAndId = selectedPackerPlant && (
    <span style={{ opacity: 0.6 }}>: {`${selectedPackerPlant.name} (${selectedPackerPlant.id})`}</span>
  );

  return (
    <div
      className={`analytic-dashboard-container ${styles.Quality_quality}`}
      style={{ position: 'relative' }}
      data-test="quality-container"
    >
      <TabBar setActiveMarketAction={setMarketKey} />
      <Layout>
        <Layout.Content style={{ padding: 24 }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <QualityFilters />
            </Col>
            <Col span={12}>
              <Card>
                <CardTitle>
                  <a>
                    Establishment Ranking
                    {selectedPackerPlantNameAndId}
                  </a>
                </CardTitle>
                <p style={{ color: 'rgba(11, 20, 53, 0.6)' }}>{`Based on ${
                  PACKER_PLANT_RANKING_COMPONENTS[market.key]
                } during ${format(timePeriod[0], 'MMMM yyyy')}.`}</p>
                {packerPlantScores && (
                  <QualityPackerPlantScorecard
                    scorecardData={packerPlantScores}
                    singlePackerPlant={selectedPackerPlant}
                    formatIntervals={singleMonthLetter}
                    timePeriod={timePeriod[0]}
                  />
                )}
              </Card>
            </Col>
            <Col span={12}>
              {aslUpdates && (
                <Card>
                  <CardTitle>ASL updates</CardTitle>
                  <ASLUpdates aslUpdates={aslUpdates} packerPlantOptions={packerPlantOptions} />
                </Card>
              )}
              {/* {packerPlantHistory && (
                <div style={{ padding: 24, position: 'relative' }}>
                  <CardTitle>Establishment history</CardTitle>
                  <PackerPlantHistory selectedPackerPlant={selectedPackerPlant} data={packerPlantHistory} />
                  <ComingSoon />
                </div>
              )}
              {packerHistory && (
                <div style={{ padding: 24, position: 'relative' }}>
                  <CardTitle>Packer history</CardTitle>
                  <PackerPlantHistory selectedPackerPlant={selectedPackerPlant} data={packerHistory} />
                  <ComingSoon />
                </div>
              )} */}
            </Col>
            <Col span={24}>
              <Card>
                <CardTitle>
                  NCRs
                  {selectedPackerPlantNameAndId}
                </CardTitle>
                {ncrsData && (
                  <NCRs
                    {...{
                      ncrsData,
                      timePeriod,
                      timeScale,
                      singlePackerPlant: selectedPackerPlant,
                      market,
                      selectedRegion,
                    }}
                  />
                )}
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <CardTitle>
                  Claims {market && <span style={{ fontSize: 20 }}>({currency}$)</span>}
                  {selectedPackerPlantNameAndId}
                </CardTitle>
                {claimsData && (
                  <>
                    <Claims
                      claimsData={claimsData}
                      timePeriod={timePeriod}
                      timeScale={timeScale}
                      singlePackerPlant={selectedPackerPlant}
                      market={market}
                      selectedRegion={selectedRegion}
                    />
                  </>
                )}
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <CardTitle>
                  DIFOT
                  {selectedPackerPlantNameAndId}
                </CardTitle>
                {difotData && <DIFOT data={difotData} singlePackerPlant={selectedPackerPlant} />}
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <CardTitle>
                  Freshness days
                  {selectedPackerPlantNameAndId}
                </CardTitle>
                {freshnessDaysData && (
                  <FreshnessDays data={freshnessDaysData} singlePackerPlant={selectedPackerPlant} />
                )}
              </Card>
            </Col>
            <Col span={12} />
            <Col span={24}>
              <Card>
                <CardTitle>
                  Audits
                  {selectedPackerPlantNameAndId}
                </CardTitle>
                {auditData && !selectedPackerPlant && <Audit data={auditData} timePeriod={timePeriod} />}
                {auditData && selectedPackerPlant && selectedPackerPlant.isPacker && (
                  <Audit data={auditData} timePeriod={timePeriod} />
                )}
                {auditSinglePackerPlantData && selectedPackerPlant && (
                  <Audit
                    data={auditSinglePackerPlantData}
                    singlePackerPlant={selectedPackerPlant}
                    timePeriod={timePeriod}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
      <LoadingOverlay loading={loading} />
    </div>
  );
}

const QualityUnleashController = (props) => {

  return(
    <>
    <IUnleashClassFlagProvider
      flagName={Constants.UNLEASH_FLAG.QUALITY_DASHBOARD}
      show={<Quality {...props}/>}
      hide={<h1>Feature Not Enabled</h1>}
    />
  </>
  );
};

export default QualityUnleashController;