import React from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import accounting from 'accounting';

import './PriceBreakdown.scss';

const propTypes = {
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      item_name: PropTypes.string,
      quantity: PropTypes.number,
      amount: PropTypes.number,
      rate: PropTypes.number,
    })
  ),
};

const PriceBreakdown = ({ lineItems }) => {
  const groupedItems = groupBy(lineItems, 'category');
  const categories = Object.keys(groupedItems);
  return (
    <div className="price-breakdown">
      {categories.map(cat => (
        <div className="price-breakdown__category" key={`price-breakdown-category-${cat}`}>
          <div className="price-breakdown__text">{cat}</div>
          <div className="price-breakdown__items">
            <div className="price-breakdown__item" key="price-breakdown-item-headers">
              <div className="price-breakdown__header price-breakdown__category-name">Name</div>
              <div className="price-breakdown__header price-breakdown__quantity">Quantity</div>
              <div className="price-breakdown__header price-breakdown__amount">Amount</div>
              {cat !== 'Surcharges' && <div className="price-breakdown__header price-breakdown__amount">Unit Rate</div>}
            </div>
            {groupedItems[cat].map((item, idx) => (
              <div className="price-breakdown__item" key={`price-breakdown-item-${idx}`}>
                <div className="price-breakdown__cell price-breakdown__category-name">{get(item, 'item_name', '')}</div>
                <div className="price-breakdown__cell price-breakdown__quantity">
                  {accounting.formatNumber(get(item, 'quantity', 0), 0)}
                </div>
                <div className="price-breakdown__cell price-breakdown__amount">
                  {/* TODO -- do the calculation on the backend */}
                  {get(item, 'item_name', '') === 'BEEF-SALE'
                    ? accounting.formatMoney(get(item, 'quantity', 0) * get(item, 'amount', 0), '$', 2)
                    : accounting.formatMoney(get(item, 'amount', 0), '$', 2)}
                </div>
                {cat !== 'Surcharges' && (
                  <div className="price-breakdown__cell price-breakdown__amount">
                    {accounting.formatMoney(get(item, 'rate', 0), '$', 4)}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

PriceBreakdown.propTypes = propTypes;

export default PriceBreakdown;
