import { message } from 'antd';
import { get, isEmpty, isEqual, partition, startCase } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { sendColdStoreEmail } from '../../../actions/actions_coldstore_orders_util';
import { getNotifyingActionGroups } from '../../../actions/actions_notifying_action_groups';
import { fetchOrdersColdstore, saveOrdersColdstore } from '../../../actions/actions_orders_coldstore';
// Actions
import { fetchEntitiesData } from '../../../slices/masterData/entityManagerSlice';
import Constants from '../../../Constants';
import SelectField from '../../basic/SelectField';
import Loading from '../../basic/Loading';
import {
  getEndUserOptionsByGrinder,
  getEndUserOptionsByGrinderFromOrders,
  getEndUserOptionsByOrders,
  getGrinderFromOrdersOptionsByEndUser,
  getGrinderOptionsByEndUser,
  getGrinderOptionsByOrders,
  getNextRolloverDate,
  groupBy,
} from '../helpers';
// Local
import './ColdstoreInventory.scss';
import ColdstoreInventoryFilters from './ColdstoreInventoryFilters';
// Components
import ColdstoreInventoryTable from './ColdstoreInventoryTable';
import ConfirmYourSelectionModal from './ConfirmYourSelectionModal';
import LoadsChart from './LoadsChart';
import { getEmailTemplate } from '../../../services/email-templates';
import {
  Center,
  Flex,
  Input,
  Button,
  Box,
  Text,
  HStack,
  VStack,
  FormLabel,
  StackDivider,
  Wrap,
  IconButton,
  Heading,
} from '@chakra-ui/react';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import { ReactComponent as SyncIcon } from '../../../assets/sync-icon.svg';
import { ReactComponent as MenuIcon } from '../../../assets/menu-icon.svg';
import { IoInformationCircleOutline } from 'react-icons/io5';
import Tooltip from '../../quality/_components/Tooltip';

const dashboardSectionTileStyling = {
  paddingX: '36px',
  paddingBottom: '47px',
};
class ColdstoreInventory extends Component {
  static propTypes = {
    cold_stores: PropTypes.arrayOf(PropTypes.object).isRequired,
    currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
    dispatch: PropTypes.func.isRequired,
    grinders: PropTypes.arrayOf(PropTypes.object).isRequired,
    end_users: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleNavigation: PropTypes.func,
    input_products: PropTypes.arrayOf(PropTypes.object).isRequired,
    notifying_action_groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    orders: PropTypes.arrayOf(PropTypes.object).isRequired,
    packer_plants: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.shape({
      token: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
    internalPoLabel: PropTypes.string,
  };

  static baseFilters = {
    usedByDateStart: null,
    usedByDateEnd: null,
    clPoints: null,
    packerPlants: [],
    grinders: [],
    currency: null,
    priceMin: null,
    priceMax: null,
    storageDate: null,
    coldstore: null,
  };

  static fieldsToClearOnFiltersReset = {
    orderFilterGrinder: null,
    grinderPoNumber: '',
    lotNumber: '',
    internalPoNumber: '',
    orderFilterEndUser: null,
  };

  state = {
    isMounting: true,
    isLoading: false,
    lastUpdate: null,

    overview: {
      selectedGrinder: null,
      cl: null,
      selectedEndUser: null,
    },

    orderFilterGrinder: null,

    orderFilterEndUser: null,

    selectedOrderIds: [],
    // TODO update date directly on order instead of using below
    orderDeliveryDates: {},

    hasConfirmedModal: false,

    filters: { ...ColdstoreInventory.baseFilters },

    grinderPoNumber: '',
    internalPoNumber: '',
    lotNumber: '',

    grinderOptionsFilteredByEndUser: null,
    endUserOptionsFilteredByGrinder: null,

    grinderOptionsFromOrderFilteredByEndUser: null,
    endUserOptionsFilteredByGrinderFromOrders: null,
  };

  componentDidMount() {
    this.reloadOrders();
    const { dispatch, notifying_action_groups: notifyingActionGroups, user } = this.props;
    if (notifyingActionGroups.length === 0) {
      dispatch(getNotifyingActionGroups(user.token));
    }
    dispatch(fetchEntitiesData());
  }

  componentDidUpdate() {
    const {
      grinders,
      packer_plants: packerPlants,
      input_products: inputProducts,
      cold_stores: coldstores,
    } = this.props;
    const { isMounting } = this.state;
    if (isMounting && !isEmpty(grinders) && !isEmpty(packerPlants) && !isEmpty(inputProducts) && !isEmpty(coldstores)) {
      this.setState({
        isMounting: false,
      });
    }
  }

  handleDateChange = (orderId, momentValue) => {
    const { orderDeliveryDates } = this.state;
    const date = momentValue && momentValue.format('YYYY-MM-DD');

    this.setState({
      orderDeliveryDates: { ...orderDeliveryDates, [orderId]: date },
    });

    // auto select / de-select based on the delivery dates
    const { selectedOrderIds } = this.state;
    if (date && orderId) {
      selectedOrderIds.push(orderId);
    } else if (orderId && !date) {
      const orderIndex = selectedOrderIds.findIndex(id => id === orderId);
      selectedOrderIds.splice(orderIndex, 1);
    }
    this.setState({ selectedOrderIds: [...new Set(selectedOrderIds)] });
  };

  handleSelectOrder = orderId => {
    const { selectedOrderIds } = this.state;
    const newSelectedOrders = selectedOrderIds.includes(orderId)
      ? selectedOrderIds.filter(id => id !== orderId)
      : [orderId, ...selectedOrderIds];

    this.setState({
      selectedOrderIds: newSelectedOrders,
    });
  };

  getBody = async orders => {
    const emailOrdersDetails = orders.map(order => {
      const lotNumbers =
        get(order, 'coldstore_details.lot_numbers') !== null &&
        order.coldstore_details &&
        order.coldstore_details.lot_numbers.length
          ? order.coldstore_details.lot_numbers.join(', ')
          : `-`;
      const storageDate = order.coldstore_details ? moment(getNextRolloverDate(order)).format('YYYY-MM-DD') : '-';
      const locationName = order.coldstore_details ? order.coldstore_details.cold_store.location_name : '';
      return {
        lot_numbers: lotNumbers,
        delivery_date: order.delivery_date,
        grinder_po_number: order.grinder_po_number,
        storageDate,
        location_name: locationName,
        lines: order.lines.map(line => {
          const usedByDate = line.used_by_date || '-';
          return {
            input_product_name: line.input_product.name,
            input_product_cl: line.input_product.cl,
            packer_plant_name: line.packer_plant.name,
            packer_plant_est: line.packer_plant.est,
            used_by_date: usedByDate,
          };
        }),
      };
    });
    const emailTemplate = await getEmailTemplate('coldstore_inventory_email', {
      orders: emailOrdersDetails,
      user: {
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name,
        email: this.props.user.email,
      },
    });
    return emailTemplate;
  };

  getSubject = async (orders, grinderUID) => {
    const grinderName = startCase(this.props.grinders.find(grinder => grinder.uid === grinderUID).name);
    const earliestDeliveryDt = orders
      .map(o => o.delivery_date)
      .reduce((d1, d2) => {
        return d1 < d2 ? d1 : d2;
      });
    const firstDayOfDeliveryWeek = moment(earliestDeliveryDt, 'YYYY-MM-DD').startOf('isoWeek').format('MM/DD/YYYY');
    const emailTemplate = await getEmailTemplate('coldstore_inventory_email_subject', {
      grinderName,
      firstDayOfDeliveryWeek,
    });
    return emailTemplate;
  };

  handleSubmitModal = orders => {
    const { dispatch, user } = this.props;
    const patchedOrders = orders.map(order => ({
      id: order.id,
      delivery_date: order.delivery_date,
      status: Constants.ORDER_STATUSES.PENDING_COLDSTORE_SCHEDULE,
    }));
    dispatch(saveOrdersColdstore(patchedOrders, user.token));
    // dispatch(bulkOrders(patchedOrders, Constants.ORDER_STATUSES.PENDING_COLDSTORE_SCHEDULE, token));
    /*  email when orders move from Inventory to Schedule.
        - groupby grinders
        - send email to all internal users + emails under group 'coldstore_scheduled' */
    const initialAgg = { internal_users: [] };
    const emailGroups = this.props.notifying_action_groups.reduce((agg, action) => {
      const aggCopy = { ...agg };
      if (action.action_type === 'coldstore_scheduled') {
        aggCopy[action.grinder_uid] = [...(agg[action.grinder_uid] || []), action.email_id];
      }
      // internal users receives emails irrespective of order details
      if (action.is_internal_user && action.action_type === 'coldstore_scheduled') {
        aggCopy.internal_users = [...agg.internal_users, action.email_id];
      }
      return aggCopy;
    }, initialAgg);

    const groupedOrdersByGrinders = groupBy(orders, 'grinder_uid');
    Object.entries(groupedOrdersByGrinders).forEach(async ([grinderUID, orderList]) => {
      const emailIds =
        emailGroups[grinderUID] && emailGroups[grinderUID].length
          ? emailGroups.internal_users.concat(emailGroups[grinderUID])
          : emailGroups.internal_users;

      if (this.props?.user?.email) {
        emailIds.push(this.props.user.email);
      }

      const payload = {
        email_ids: [...new Set(emailIds)],
        subject: await this.getSubject(orderList, grinderUID),
        body: await this.getBody(orderList),
        asHtml: true,
      };
      dispatch(sendColdStoreEmail(payload, this.props.user.token));
    });
  };

  cleanFilters = () => {
    this.setState({
      ...ColdstoreInventory.fieldsToClearOnFiltersReset,
      filters: { ...ColdstoreInventory.baseFilters },
    });
  };

  setFilters = newFilters => {
    const { filters } = this.state;
    this.setState({
      filters: { ...filters, ...newFilters },
    });
  };

  checkIfLoading = () => {
    return !(this.props.input_products.length && this.props.grinders.length);
  };

  onOverviewOptionChange = (field, option) => {
    const { overview } = this.state;
    this.setState({
      overview: {
        ...overview,
        [field]: option,
      },
    });
    if (field === 'selectedEndUser') {
      this.setState({
        grinderOptionsFilteredByEndUser: getGrinderOptionsByEndUser(option, this.props.grinders),
      });
    } else if (field === 'selectedGrinder') {
      this.setState({
        endUserOptionsFilteredByGrinder: getEndUserOptionsByGrinder(option, this.props.end_users),
      });
    }
  };

  goToScheduleTab = () => this.props.handleNavigation('release_management', 'schedule');

  getLoadsByOverview = () => {
    const { selectedGrinder, cl, selectedEndUser } = this.state.overview;
    const filteredOrders = this.getFilteredOrders();

    let orders = filteredOrders;
    if (selectedGrinder) {
      orders = orders.filter(order => order.grinder_uid === selectedGrinder?.value);
    }

    if (cl) {
      orders = orders.filter(order => order.lines.some(line => line.input_product.cl === cl?.value));
    }

    if (selectedEndUser) {
      orders = orders.filter(order => order?.end_user_id === selectedEndUser?.value);
    }

    return orders;
  };

  getExpiringLoads = () => {
    if (this.props.input_products.length <= 0) {
      return [];
    }

    let expiringLoads = 0;
    this.getLoadsByOverview().forEach(order => {
      const usedByDate = moment(order.earliest_used_by_date, 'YYYY-MM-DD');

      if (usedByDate && usedByDate.isBetween(moment(), moment().add(30, 'days'))) {
        expiringLoads += 1;
      }
    });

    return expiringLoads;
  };

  reloadOrders = () => {
    this.setState({ isLoading: true });

    this.props.dispatch(fetchOrdersColdstore(this.props.user.token)).then(() => {
      this.setState({
        isLoading: false,
        lastUpdate: moment().format('DD-MM-YYYY h:mm A'),
      });
    });
  };

  setUpdatedOrderIds = orderIds => {
    this.setState({ selectedOrderIds: orderIds });
  };

  onSelectLoads = () => {
    if (this.state.selectedOrderIds.length === 0) {
      message.warning('Please select load(s) to proceed');
      return;
    }

    let areDatesSet = true;
    this.props.orders
      .filter(order => this.state.selectedOrderIds.includes(order.id))
      .forEach(order => {
        const deliveryDate = this.state.orderDeliveryDates[order.id];
        if (deliveryDate === undefined || deliveryDate === null || deliveryDate === '') {
          message.error('All selected loads need a delivery date');
          areDatesSet = false;
        }
      });

    if (areDatesSet) {
      this.setState({ hasConfirmedModal: true });
    }
  };

  getInputProductByUid = uid => this.props.input_products.find(inputProduct => inputProduct.uid === uid);

  getFilteredOrders = () => {
    const { selectedOrderIds, orderFilterGrinder, orderFilterEndUser } = this.state;
    const [selectedOrders, partitionedOrders] = partition(this.props.orders, order =>
      selectedOrderIds.includes(order.id)
    );
    let orders = [];
    orders = orderFilterGrinder
      ? partitionedOrders.filter(order => order.grinder_uid === orderFilterGrinder?.value)
      : [...partitionedOrders];

    orders = orderFilterEndUser
      ? orders.filter(order => order?.end_user_id === orderFilterEndUser?.value)
      : [...orders];

    const { filters } = this.state;

    if (filters.packerPlants.length) {
      orders = orders.filter(order => filters.packer_plants?.includes(order.packer_plant_id));
    }

    if (filters.grinders.length) {
      orders = orders.filter(order => filters.grinders.includes(order.grinder_uid));
    }

    if (filters.storageDate) {
      orders = orders.filter(order => filters.storageDate === getNextRolloverDate(order));
    }

    if (filters.clPoints) {
      orders = orders.filter(order => order.lines.some(line => line.input_product.cl === filters.clPoints.value));
    }

    if (filters.coldstore) {
      orders = orders.filter(order => order.coldstore_details?.cold_store_id === filters.coldstore.value);
    }

    if (filters.usedByDateStart) {
      orders = orders.filter(order => {
        const usedByDate = order.earliest_used_by_date;
        return usedByDate && usedByDate >= filters.usedByDateStart;
      });
    }

    if (filters.usedByDateEnd) {
      orders = orders.filter(order => {
        const usedByDate = order.earliest_used_by_date;
        return usedByDate && usedByDate <= filters.usedByDateStart;
      });
    }

    if (filters.currency) {
      orders = orders.filter(order => order.sell_currency === filters.currency.label);
    }

    if (filters.priceMin) {
      orders = orders.filter(order => order.lines.some(line => line.sell_price_per_unit >= filters.priceMin));
    }

    if (filters.priceMax) {
      orders = orders.filter(order => order.lines.some(line => line.sell_price_per_unit <= filters.priceMax));
    }

    if (this.state.grinderPoNumber !== '') {
      orders = orders.filter(order => String(order.grinder_po_number).includes(this.state.grinderPoNumber));
    }

    if (this.state.lotNumber !== '') {
      orders = orders.filter(order =>
        String(order.coldstore_details?.lot_numbers?.join(', ')).includes(this.state.lotNumber)
      );
    }

    if (this.state.internalPoNumber !== '') {
      orders = orders.filter(order => String(order.internal_po_number).includes(this.state.internalPoNumber));
    }

    return [...selectedOrders, ...orders].map(order => {
      return {
        ...order,
        selected: this.state.selectedOrderIds.includes(order.id),
        usedByDate: order.earliest_used_by_date || '',
      };
    });
  };

  render() {
    const {
      cold_stores: coldstores,
      currencies,
      grinders,
      end_users: endUsers,
      input_products: inputProducts,
      orders,
      packer_plants: packerPlants,
    } = this.props;
    // Separated out mounting from loading states, as mounting state requires multiple different resource requests
    const {
      filters,
      internalPoNumber,
      grinderPoNumber,
      lotNumber,
      hasConfirmedModal,
      isLoading,
      isMounting,
      lastUpdate,
      orderDeliveryDates,
      orderFilterGrinder,
      overview,
      selectedOrderIds,
      showFilters,
      orderFilterEndUser,
      grinderOptionsFilteredByEndUser,
      endUserOptionsFilteredByGrinder,
      grinderOptionsFromOrderFilteredByEndUser,
      endUserOptionsFilteredByGrinderFromOrders,
    } = this.state;

    const showLoadingIndicator = isMounting || isLoading;

    const clearFilterIsHidden =
      isEqual(filters, ColdstoreInventory.baseFilters) &&
      grinderPoNumber === '' &&
      lotNumber === '' &&
      internalPoNumber === '' &&
      orderFilterGrinder === null &&
      orderFilterEndUser === null;

    const grinderOptions = grinders.map(grinder => ({
      label: grinder.name,
      value: grinder.uid,
    }));

    const endUserOptions = endUsers.map(endUser => ({
      label: endUser.legal_name,
      value: endUser.id,
      grinders_uids: endUser.grinders_uids,
    }));

    /*
      1. Creates an array [] of distinct cl's
      2. Sorts them in ascending order using sort() and compare function
      3. Makes an array of objects with cl as label and name
    */
    const inputProductOptions = Array.from(new Set(inputProducts.map(inputProduct => inputProduct.cl)))
      .sort((a, b) => {
        return a - b;
      })
      .map(cl => {
        return {
          label: cl,
          value: cl,
        };
      });

    const filteredOrders = this.getFilteredOrders();
    const selectedOrders = orders.filter(order => selectedOrderIds.includes(order.id));

    const grinderOptionsFromOrders = getGrinderOptionsByOrders(orders, grinders);

    const endUserOptionsFromOrders = getEndUserOptionsByOrders(orders, endUsers);

    // Overview loads are displayed in chart
    const overviewLoads = this.getLoadsByOverview();
    const expiringLoads = this.getExpiringLoads();

    return (
      <VStack align="stretch" spacing="47px" padding="46px">
        <DashboardSectionTile
          title={
            <>
              Inventory Overview&nbsp;
              <Tooltip
                content="The graph shows the number of loads expiring in the next 12 months, with a monthly breakdown"
                placement="right"
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </>
          }
          {...dashboardSectionTileStyling}
        >
          {showLoadingIndicator ? (
            <Center>
              <Loading />
            </Center>
          ) : (
            <HStack spacing="24px" alignItems="flex-start" justify="space-around">
              <VStack spacing="35px" align="stretch" flexGrow={1}>
                {inputProducts.length && <LoadsChart orders={overviewLoads} inputProducts={inputProducts} />}
              </VStack>
              <VStack spacing="35px" align="stretch">
                <Wrap spacing="26px" width="334px">
                  <Box width="154px">
                    <FormLabel htmlFor="end_user_id">
                      End User&nbsp;
                      <Tooltip
                        content="Filter the graph based on the selected end user to see expiring loads in the next 12 months."
                        placement="right"
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          icon={<IoInformationCircleOutline size="14px" />}
                          variant="unstyled"
                        />
                      </Tooltip>
                    </FormLabel>
                    <SelectField
                      className="search-filter__select"
                      isClearable
                      isSearchable
                      closeMenuOnSelect
                      title="End User"
                      name="end_user_id"
                      isMulti={false}
                      placeholder="All end users"
                      value={overview.selectedEndUser || null}
                      onChange={option => this.onOverviewOptionChange('selectedEndUser', option)}
                      options={endUserOptionsFilteredByGrinder || endUserOptions}
                    />
                  </Box>
                  <Box width="154px">
                    <FormLabel htmlFor="grinder_uid">
                      Grinder&nbsp;
                      <Tooltip
                        content="Filter the graph based on the selected Grinder to see expiring loads in the next 12 months."
                        placement="right"
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          icon={<IoInformationCircleOutline size="14px" />}
                          variant="unstyled"
                        />
                      </Tooltip>
                    </FormLabel>
                    <SelectField
                      className="search-filter__select"
                      isClearable
                      isSearchable
                      closeMenuOnSelect
                      title="Grinder"
                      name="grinder_uid"
                      isMulti={false}
                      placeholder="All grinders"
                      value={overview.selectedGrinder || null}
                      onChange={option => this.onOverviewOptionChange('selectedGrinder', option)}
                      options={grinderOptionsFilteredByEndUser || grinderOptions}
                    />
                  </Box>
                  <Box width="154px">
                    <FormLabel htmlFor="cl">
                      CL&nbsp;
                      <Tooltip
                        content="Filter the graph based on the selected CL (Clinical Lean) to see expiring loads in the next 12 months"
                        placement="right"
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          icon={<IoInformationCircleOutline size="14px" />}
                          variant="unstyled"
                        />
                      </Tooltip>
                    </FormLabel>
                    <SelectField
                      className="search-filter__select"
                      isClearable
                      isSearchable
                      closeMenuOnSelect
                      title="CL"
                      name="cl"
                      isMulti={false}
                      placeholder="All CLs"
                      value={overview.cl || null}
                      onChange={selectedOption => this.onOverviewOptionChange('cl', selectedOption)}
                      options={inputProductOptions}
                    />
                  </Box>
                </Wrap>
                <HStack
                  height="145px"
                  width="337px"
                  backgroundColor="white"
                  divider={<StackDivider />}
                  boxShadow="md"
                  borderRadius="6px"
                  paddingY="14px"
                >
                  <Flex width="156px" flexDirection="column" height="86px" alignItems="center">
                    <Heading
                      color="secondary.default"
                      fontFamily="Poppins"
                      fontSize="45px"
                      fontWeight={700}
                      lineHeight="32px"
                    >
                      {overviewLoads.length}
                    </Heading>
                    <Text
                      as="p"
                      fontFamily="Roboto"
                      fontWeight={400}
                      fontStyle="normal"
                      fontSize="20px"
                      textAlign="center"
                      lineHeight="25px"
                      mt={17.89}
                    >
                      Loads&nbsp;
                      <Tooltip
                        content="Display the number of loads expiring in the next 12 months based on the End User, Grinder, and CL selections."
                        placement="right"
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          icon={<IoInformationCircleOutline size="14px" />}
                          variant="unstyled"
                        />
                      </Tooltip>
                    </Text>
                  </Flex>
                  <Flex width="156px" flexDirection="column" height="86px" alignItems="center">
                    <Heading
                      color="secondary.default"
                      fontFamily="Poppins"
                      fontSize="45px"
                      fontWeight={700}
                      lineHeight="32px"
                    >
                      {expiringLoads}
                    </Heading>
                    <Text
                      as="p"
                      fontFamily="Roboto"
                      fontWeight={400}
                      fontStyle="normal"
                      fontSize="20px"
                      textAlign="center"
                      lineHeight="25px"
                      mt={9.42}
                    >
                      Expiring in 30 Days&nbsp;
                      <Tooltip
                        content="Display the number of loads expiring in the next 30 days based on the End User, Grinder, and CL selections."
                        placement="right"
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          icon={<IoInformationCircleOutline size="14px" />}
                          variant="unstyled"
                        />
                      </Tooltip>
                    </Text>
                  </Flex>
                </HStack>
              </VStack>
            </HStack>
          )}
        </DashboardSectionTile>
        <DashboardSectionTile title="Inventory" {...dashboardSectionTileStyling}>
          {showLoadingIndicator ? (
            <Center>
              <Loading />
            </Center>
          ) : (
            <Box>
              <HStack paddingLeft={23}>
                <Text as="p">Updated by: {lastUpdate}</Text>
                <IconButton backgroundColor="inherit" icon={<SyncIcon />} onClick={() => this.reloadOrders()} />
              </HStack>
              <HStack spacing="26px" marginTop="15px">
                <IconButton
                  backgroundColor="inherit"
                  icon={<MenuIcon />}
                  onClick={() => this.setState({ showFilters: true })}
                  marginTop="27px !important"
                />
                {showFilters && (
                  <ColdstoreInventoryFilters
                    onClose={() => this.setState({ showFilters: false })}
                    showFilters={showFilters}
                    orders={orders}
                    inputProducts={inputProducts}
                    packerPlants={packerPlants}
                    grinders={grinders}
                    coldstores={coldstores}
                    currencies={currencies}
                    filters={filters}
                    setFilters={this.setFilters}
                    cleanFilters={this.cleanFilters}
                  />
                )}
                <HStack spacing="17px">
                  <Box width="154px">
                    <FormLabel htmlFor="end_user_id" fontSize="16px">
                      End User&nbsp;
                      <Tooltip
                        content="Apply a filter to the reports to view expiring loads for the selected end user"
                        placement="right"
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          icon={<IoInformationCircleOutline size="14px" />}
                          variant="unstyled"
                        />
                      </Tooltip>
                    </FormLabel>
                    <SelectField
                      className="search-filter__select"
                      isClearable
                      isSearchable
                      closeMenuOnSelect
                      title="End User"
                      name="end_user_id"
                      isMulti={false}
                      placeholder="All end users"
                      value={orderFilterEndUser || null}
                      onChange={option => {
                        this.setState({
                          orderFilterEndUser: option,
                          grinderOptionsFromOrderFilteredByEndUser: getGrinderFromOrdersOptionsByEndUser(
                            option,
                            grinderOptionsFromOrders
                          ),
                        });
                      }}
                      options={endUserOptionsFilteredByGrinderFromOrders || endUserOptionsFromOrders}
                    />
                  </Box>
                  <Box width="154px">
                    <FormLabel htmlFor="grinder_uid" fontSize="16px">
                      Grinder&nbsp;
                      <Tooltip
                        content="Apply a filter to the reports to view expiring loads for the chosen Grinder."
                        placement="right"
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          icon={<IoInformationCircleOutline size="14px" />}
                          variant="unstyled"
                        />
                      </Tooltip>
                    </FormLabel>
                    <SelectField
                      className="search-filter__select"
                      isClearable
                      isSearchable
                      closeMenuOnSelect
                      title="Grinder"
                      name="grinder_uid"
                      isMulti={false}
                      placeholder="All grinders"
                      value={orderFilterGrinder || null}
                      onChange={option =>
                        this.setState({
                          orderFilterGrinder: option,
                          endUserOptionsFilteredByGrinderFromOrders: getEndUserOptionsByGrinderFromOrders(
                            option,
                            endUserOptionsFromOrders
                          ),
                        })
                      }
                      options={grinderOptionsFromOrderFilteredByEndUser || grinderOptionsFromOrders}
                    />
                  </Box>
                  <Box width="154px">
                    <FormLabel htmlFor="grinder_po" fontSize="16px">
                      Grinder PO#&nbsp;
                      <Tooltip
                        content="Search the reports using the entered Grinder purchase order number to view expiring loads"
                        placement="right"
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          icon={<IoInformationCircleOutline size="14px" />}
                          variant="unstyled"
                        />
                      </Tooltip>
                    </FormLabel>
                    <Input
                      name="grinder_po"
                      height="36px"
                      borderRadius={0}
                      placeholder="Grinder PO#"
                      onChange={e => this.setState({ grinderPoNumber: e.target.value })}
                      value={grinderPoNumber}
                      allowClear
                    />
                  </Box>
                  <Box width="154px">
                    <FormLabel htmlFor="internalPoLabel" fontSize="16px">
                      {this.props.internalPoLabel}
                      &nbsp;
                      <Tooltip
                        content={`Search the reports using the entered ${this.props.internalPoLabel} purchase order number to view expiring loads`}
                        placement="right"
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          icon={<IoInformationCircleOutline size="14px" />}
                          variant="unstyled"
                        />
                      </Tooltip>
                    </FormLabel>
                    <Input
                      name="internalPoLabel"
                      placeholder={this.props.internalPoLabel}
                      height="36px"
                      borderRadius={0}
                      onChange={e => this.setState({ internalPoNumber: e.target.value })}
                      value={internalPoNumber}
                      allowClear
                    />
                  </Box>
                  <Box width="154px">
                    <FormLabel htmlFor="lotNumber" fontSize="16px">
                      Lot Number&nbsp;
                      <Tooltip
                        content="Search the reports using the entered lot number to view expiring loads"
                        placement="right"
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          icon={<IoInformationCircleOutline size="14px" />}
                          variant="unstyled"
                        />
                      </Tooltip>
                    </FormLabel>
                    <Input
                      name="lotNumber"
                      placeholder="Lot Number"
                      height="36px"
                      borderRadius={0}
                      onChange={e => this.setState({ lotNumber: e.target.value })}
                      value={lotNumber}
                      allowClear
                    />
                  </Box>
                  <Button
                    variant="unstyled"
                    color="actionSecondary.default"
                    onClick={this.cleanFilters}
                    hidden={clearFilterIsHidden}
                    marginTop="27px !important"
                    fontSize="16px"
                  >
                    Clear Filters
                  </Button>
                </HStack>
                <Button
                  colorScheme="actionSecondary"
                  marginLeft="auto !important"
                  height="43px"
                  width="152px"
                  fontWeight="bold"
                  marginTop="20px !important"
                  fontSize="16px"
                  onClick={this.onSelectLoads}
                >
                  Select loads
                </Button>
              </HStack>
              <Box overflowX="visible" overflowY="visible" marginTop="31px">
                {!this.checkIfLoading() && (
                  <ColdstoreInventoryTable
                    orders={filteredOrders}
                    packerPlants={packerPlants}
                    inputProducts={inputProducts}
                    orderDeliveryDates={this.state.orderDeliveryDates}
                    internalPoLabel={this.props.internalPoLabel}
                    onSelectOrder={this.handleSelectOrder}
                    onDateChange={this.handleDateChange}
                  />
                )}
              </Box>
            </Box>
          )}
        </DashboardSectionTile>
        <ConfirmYourSelectionModal
          isVisible={hasConfirmedModal}
          onCloseModal={() => this.setState({ hasConfirmedModal: false })}
          orders={selectedOrders}
          orderDeliveryDates={orderDeliveryDates}
          inputProducts={inputProducts}
          onDateChange={this.handleDateChange}
          onSubmit={this.handleSubmitModal}
          goToScheduleTab={this.goToScheduleTab}
          setUpdatedOrderIds={this.setUpdatedOrderIds}
        />
      </VStack>
    );
  }
}

export default ColdstoreInventory;
