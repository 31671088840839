import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HorizontalNavigationBand from '../../core/HorizontalNavigationBand';
import {
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  StackDivider,
  Tag,
  Td,
  Text,
  Th,
  VStack,
  Wrap,
  Avatar,
  Divider,
  useTheme,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  TableContainer,
} from '@chakra-ui/react';
import { ReactComponent as ArrowLeftIconSVG } from '../../../assets/arrow-left.svg';
import { ReactComponent as ArrowRightIconSVG } from '../../../assets/arrow-right.svg';
import { ReactComponent as DeleteIconSVG } from '../../../assets/delete.svg';
import { ReactComponent as TipIconSVG } from '../../../assets/tip.svg';
import { findEntitiesInState } from '../../../slices/masterData/entityManagerSlice';
import './index.scss';
import { invertColor } from '../../../theme/utils';
import { convertToCamelCase, getOr, nestedGetOr, truncateStartAndEnd } from '../../../helpers';
import { downloadFromS3Link, previewFromS3Link, previewFromS3LinkBlob } from '../../../helpers/downloads';
import { useFormikContext } from 'formik';
import { defaultTo, get } from 'lodash';
import { Upload } from 'antd';
import { ReactComponent as DropZoneIconSVG } from '../../../assets/drop-zone.svg';
import { getAttachmentIcon } from '../helpers';
import { formatMonetaryValue } from '../../../functions';
import moment from 'moment';
import { connect } from 'react-redux';
import { createNCRValidationSchemas } from '../validationSchemas';
import Constants from '../../../Constants';

export const EditButton = props => (
  <Button colorScheme="actionPrimary" fontWeight="700" width="108px" height="40px" {...props}>
    Edit
  </Button>
);

export const vStackLayoutProps = {
  marginLeft: '6px',
  marginTop: '18px',
  spacing: '24px',
  align: 'stretch',
  padding: '15px 36px 16px 21px',
  fontWeight: '400',
};

export const detailsHStackLayoutProps = {
  spacing: '40px',
  paddingRight: '43px',
  divider: <StackDivider />,
};

export const VStackLayout = ({ children, ...rest }) => (
  <VStack {...vStackLayoutProps} divider={<StackDivider />} {...rest}>
    {children}
  </VStack>
);

VStackLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export const PreambleText = ({ children, ...rest }) => (
  <Text fontWeight="500" {...rest}>
    {children}
  </Text>
);
PreambleText.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AttachmentCard = ({
  label,
  url,
  icon,
  canDelete,
  onDelete,
  isReviewing,
  isEditing,
  isSignableUrl,
  extension,
}) => {
  const [resourceUrl, setResourceUrl] = useState(url);

  useEffect(() => {
    (async () => {
      if (!icon && isSignableUrl) {
        const previewLink = await (extension === 'svg'
          ? previewFromS3LinkBlob(url, 'image/svg+xml')
          : previewFromS3Link(url));
        setResourceUrl(previewLink);
      }
    })();
  }, [icon, isSignableUrl]);

  const viewOnly = isReviewing && !isEditing;
  const editable = isReviewing ? isEditing : canDelete;

  return (
    <HStack
      width="395px"
      height="66px"
      spacing="8px"
      padding="9px"
      backgroundColor="#FFFFFF"
      borderWidth="1px"
      borderRadius="2px"
      onClick={e => {
        if (viewOnly) {
          e.preventDefault();
          downloadFromS3Link(url);
        }
      }}
      cursor={viewOnly && 'pointer'}
    >
      <Box width="48px" height="48px" flexShrink={0}>
        {icon || <Image src={resourceUrl} objectFit="cover" width="100%" height="100%" alt={label.substr(0, 3)} />}
      </Box>
      <Text>{truncateStartAndEnd(label, 10)}</Text>
      {editable ? (
        <IconButton
          icon={<DeleteIconSVG />}
          marginLeft="auto !important"
          height="30px"
          maxWidth="30px"
          variant="ghost"
          _focus={{
            outline: 'none',
          }}
          onClick={onDelete}
        />
      ) : (
        ''
      )}
    </HStack>
  );
};

AttachmentCard.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  icon: PropTypes.elementType,
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  isReviewing: PropTypes.bool,
  isEditing: PropTypes.bool,
  isSignableUrl: PropTypes.bool,
  extension: PropTypes.string,
};

export const AttachmentsContainer = ({ children, ...rest }) => (
  <Wrap spacing="24px" marginTop="10px" {...rest}>
    {children}
  </Wrap>
);
AttachmentsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
export const AttachmentFieldSectionAdapter = ({
  attachmentSectionKey,
  sectionIndex,
  label,
  onUpdate,
  attachmentsInGroup,
  ...rest
}) => {
  return (
    <AttachmentFieldSection
      attachmentSectionKey={attachmentSectionKey}
      sectionId={(sectionIndex + 1).toString()}
      label={label}
      onSelectFile={data => {
        onUpdate(`ncrDetails.ncrMedia.${attachmentSectionKey}`, [
          ...attachmentsInGroup,
          { ...data, type: attachmentSectionKey },
        ]);
      }}
      onDeleteFile={id => {
        onUpdate(`ncrDetails.ncrMedia.${attachmentSectionKey}`, [
          ...attachmentsInGroup.filter(attachment => attachment.id !== id),
        ]);
      }}
      fileList={attachmentsInGroup.map(attachment => {
        const isSignableUrl = !/^blob/.test(attachment.url);
        const splitUrl = attachment.url.split('/');
        const attachmentLabel = isSignableUrl ? decodeURI(splitUrl[splitUrl.length - 1]) : attachment.label;
        return {
          ...attachment,
          label: attachmentLabel,
          isSignableUrl,
        };
      })}
      {...rest}
    />
  );
};
AttachmentFieldSectionAdapter.propTypes = {
  attachmentSectionKey: PropTypes.string.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
  onDeleteFile: PropTypes.func,
  attachmentsInGroup: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isReviewing: PropTypes.bool,
};

export const AttachmentFieldSection = ({
  sectionId,
  attachmentSectionKey,
  label,
  onSelectFile,
  fileList,
  onDeleteFile,
  isReviewing,
}) => {
  const theme = useTheme();
  const { values: formikValues, errors } = useFormikContext();
  const isEditing = get(formikValues, 'isEditing', []);

  const [dragZoneSate, setDropZoneSate] = useState({
    borderColor: '#D9D9D9',
  });

  const {
    attachments: { schema: attachmentsValidationSchema },
  } = createNCRValidationSchemas;

  return (
    <Box>
      {!isReviewing || isEditing ? (
        <Box marginBottom="45px">
          <HStack spacing="28px" marginBottom="14px">
            <Divider borderColor="#6D7580" />
            <Flex alignItems="center">
              <Avatar
                name={sectionId}
                backgroundColor="accent.one.default"
                color={invertColor(get(theme, 'colors.accent.one.default', ''), true)}
                height="24px"
                width="24px"
              />
              <Heading fontWeight="400" fontSize="15px" marginLeft="20px" textTransform="uppercase" width="max-content">
                {label}{' '}
                <Box as="span" color="red">
                  {get(
                    attachmentsValidationSchema,
                    `fields.ncrDetails.fields.ncrMedia.fields.${attachmentSectionKey}._exclusive.required`,
                    false
                  )
                    ? '*'
                    : ''}
                </Box>
              </Heading>
            </Flex>
            <Divider borderColor="#6D7580" />
          </HStack>
          <Upload
            type="file"
            accept="image/*"
            name="hero-url"
            multiple
            showUploadList={false}
            customRequest={customRequestArgs => {
              const { file } = customRequestArgs;
              onSelectFile({
                file,
                url: URL.createObjectURL(file),
                label: file.name,
                mimeType: file.type,
                id: file.uid,
              });
            }}
            className="dropzone"
          >
            <Center
              height="175px"
              borderRadius="8px"
              borderWidth="1px"
              borderStyle="dashed"
              backgroundColor="#FFFFFF"
              borderColor={dragZoneSate.borderColor}
              onDragEnter={() => {
                setDropZoneSate({
                  ...dragZoneSate,
                  borderColor: 'actionSecondary.default',
                });
              }}
              onDragLeave={() => {
                setDropZoneSate({
                  ...dragZoneSate,
                  borderColor: '#D9D9D9',
                });
              }}
              className={
                formikValues.highlightInputError &&
                get(errors, `ncrDetails.ncrMedia.${attachmentSectionKey}`) &&
                'error'
              }
            >
              <Box>
                <Center color="actionSecondary.default">
                  <DropZoneIconSVG />
                </Center>
                <Text fontSize="16px" marginTop="6px">
                  Click or drag file to this area to upload
                </Text>
              </Box>
            </Center>
          </Upload>
        </Box>
      ) : (
        ''
      )}
      <Box>
        <AttachmentsContainer>
          {fileList.map(file => {
            const { icon, extension } = getAttachmentIcon(file.label);
            return (
              <AttachmentCard
                key={file.id}
                {...file}
                canDelete
                onDelete={() => onDeleteFile(file.id)}
                icon={icon}
                extension={extension}
                isReviewing={isReviewing}
                isEditing={isEditing}
              />
            );
          })}
        </AttachmentsContainer>
      </Box>
    </Box>
  );
};

AttachmentFieldSection.propTypes = {
  sectionId: PropTypes.string.isRequired,
  attachmentSectionKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onSelectFile: PropTypes.func,
  onDeleteFile: PropTypes.func,
  fileList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isReviewing: PropTypes.bool,
};

const renderChatStatusTag = status => {
  const colorConfig = {
    new: {
      color: '#F5222D',
      backgroundColor: '#FFF1F0',
      borderColor: '#FFA39E',
    },
    internal: {
      color: '#722ED1',
      backgroundColor: '#F9F0FF',
      borderColor: '#D3ADF7',
    },
  };

  return (
    <Center
      {...colorConfig[status]}
      textTransform="capitalize"
      borderRadius="2px"
      fontWeight="400"
      borderWidth="1px"
      padding="2px 8px"
    >
      <Text>{status}</Text>
    </Center>
  );
};

export const ChatItem = ({ onOpenDrawer, mostRecentMessage, unreadCount, groupType, orgName }) => {
  const lastSenderFullName = `${get(mostRecentMessage, 'user.firstName', '')} ${get(
    mostRecentMessage,
    'user.lastName',
    ''
  )}`;
  const lastMessageTimestamp = get(mostRecentMessage, 'modified', null);
  const status = unreadCount > 0 ? 'new' : null;
  return (
    <div>
      <Flex justifyContent={'flex-start'} flexDirection={'column'}>
        <Flex>
          {groupType ? (
            <HorizontalNavigationBand fontSize="15px" paddingX="10px" height="30px">
              <Text fontWeight="700" textTransform="uppercase">
                {groupType}
              </Text>
              {/* &nbsp;
          <Text fontWeight="500">{orgName}</Text> */}
            </HorizontalNavigationBand>
          ) : (
            ''
          )}
        </Flex>
        <div style={{ marginTop: '20px' }}>
          <Flex>
            {status ? (
              <Box width="64px" flexShrink={0} marginTop="7px">
                {renderChatStatusTag(status)}
              </Box>
            ) : (
              ''
            )}
            &nbsp;&nbsp;
            <Avatar name={lastSenderFullName} height="36px" width="36px" />
            <Box marginLeft="8px">
              <Flex alignItems="center">
                <Text fontSize="16px" fontWeight="700">
                  {lastSenderFullName}
                </Text>
                <Text fontSize="12px" marginLeft="8px">
                  {lastMessageTimestamp ? moment(lastMessageTimestamp).format('DD MMMM YYYY hh:mm a') : ''}
                </Text>
              </Flex>
              <Text fontSize="16px" style={{ wordBreak: 'break-word' }}>{get(mostRecentMessage, 'message')} </Text>
              <Button variant="unstyled" marginTop="12px" fontWeight="300" onClick={onOpenDrawer}>
                Open Chat
              </Button>
            </Box>
          </Flex>
        </div>
      </Flex>
    </div>
  );
};

ChatItem.propTypes = {
  onOpenDrawer: PropTypes.func,
  mostRecentMessage: PropTypes.objectOf(PropTypes.any),
  unreadCount: PropTypes.number,
};

const renderHistoryLogItemStatus = status => {
  const statusDisplayValue = status || 'None';
  return (
    <Center
      {...(status
        ? { color: '#2F54EB', backgroundColor: '#F0F5FF', borderColor: '#ADC6FF' }
        : { color: '#000000A6', backgroundColor: 'inherit', borderColor: '#D9D9D9' })}
      textTransform="capitalize"
      borderRadius="4px"
      fontWeight="400"
      borderWidth="1px"
      padding="1px 8px"
      fontSize="12px"
    >
      <Text>{statusDisplayValue}</Text>
    </Center>
  );
};

export const HistoryLogItem = ({ field, editedBy, editedTo, editedFrom, modified }) => {
  let updateInfo = (
    <>
      <Text>updated</Text>
      <Text textTransform="capitalize">{field.replaceAll('_', ' ')}</Text>
    </>
  );
  let updateElement = (
    <Text>
      {editedFrom} &rarr; {editedTo}
    </Text>
  );

  if (field === 'new') {
    updateInfo = 'created Incident';
    updateElement = <Text>New Incident Created</Text>;
  }
  if (field === 'status') {
    updateInfo = 'updated the Incident Status';
    updateElement = <Text>New Incident Created &rarr; Status updated to Accepted</Text>;
    updateElement = (
      <HStack spacing="7px">
        <Text>New Incident Created &rarr; Status updated to</Text> {renderHistoryLogItemStatus('Accepted')}
      </HStack>
    );
  }
  if (field === Constants.NCR_TYPES.INCIDENT) {
    updateInfo = 'changed Incident to NCR';
    updateElement = <Text>Incident &rarr; Incident changed to NCR</Text>;
  }
  if (field === 'date') {
    updateInfo = 'updated the NCR Date';
    updateElement = <Text>None - 2021-02-02</Text>;
  }
  if (field === 'release') {
    updateInfo = 'released NCR to Packer';
  }
  if (field === 'in_progress') {
    updateInfo = 'updated NCR Details';
    updateElement = (
      <HStack spacing="7px">
        {renderHistoryLogItemStatus()}
        <Text>&rarr;</Text> {renderHistoryLogItemStatus('In Progress')}
      </HStack>
    );
  }

  return (
    <Flex alignItems="center">
      <Avatar name={editedBy} height="38px" width="38px" />
      <Box marginLeft="19px">
        <Box>
          <HStack spacing="4px" alignItems="center" marginBottom="5px">
            <Text>
              <b>{editedBy}</b>
            </Text>
            {updateInfo}
            <Text color="#00000080" marginLeft="8px !important">
              {moment(modified).format('MMMM DD, YYYY, hh:mm A')}
            </Text>
          </HStack>
          {updateElement}
        </Box>
      </Box>
    </Flex>
  );
};

HistoryLogItem.propTypes = {
  field: PropTypes.string,
  editedBy: PropTypes.string,
  editedTo: PropTypes.string,
  editedFrom: PropTypes.string,
  modified: PropTypes.string,
};

export const POGrinderNo = ({ grinder, packer_plant, isFrozen }) => (
  <HStack spacing="8px">
    <Text fontWeight="700" fontSize="16px">
      Grinder PO# {grinder}
    </Text>
    <Text fontWeight="700" fontSize="16px" minWidth="174px">
      / Packer: {packer_plant}
    </Text>
    <Tag className="frozen-status__tag" colorScheme="gray" fontSize="16px" padding="4px 8px">
      {isFrozen ? 'Frozen' : 'Fresh'}
    </Tag>
  </HStack>
);
POGrinderNo.propTypes = {
  value: PropTypes.number.isRequired,
  isFrozen: PropTypes.bool.isRequired,
};

export const BackButton = ({ children, ...rest }) => (
  <Button
    variant="outline"
    leftIcon={<ArrowLeftIconSVG />}
    colorScheme="actionPrimary"
    height="40px"
    fontWeight="600"
    paddingX="27px"
    {...rest}
  >
    {children || 'Back'}
  </Button>
);

BackButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export const OutlineButton = ({ children, ...rest }) => (
  <Button variant="outline" colorScheme="actionPrimary" height="40px" fontWeight="600" paddingX="27px" {...rest}>
    {children}
  </Button>
);

OutlineButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AdvanceButton = ({ children, ...rest }) => (
  <Button
    variant="solid"
    rightIcon={<ArrowRightIconSVG />}
    fontWeight="700"
    height="40px"
    paddingX="20px"
    fontSize="inherit"
    {...rest}
  >
    {children}
  </Button>
);

AdvanceButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export const InputField = props => (
  <Input
    height="33px"
    width="129px"
    isDisabled
    onWheelCapture={e => {
      e.target.blur();
    }}
    onKeyDownCapture={e => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') e.preventDefault();
    }}
    {...props}
  />
);

export const PerUnitTdField = ({ label, ...rest }) => (
  <Td paddingTop="28px">
    <FormLabel>{label || 'Per Unit'}</FormLabel>
    <InputField type="number" min={0} {...rest} />
  </Td>
);
PerUnitTdField.propTypes = {
  label: PropTypes.func.isRequired,
};

export const AddValueFields = props => {
  const { onClaimLabelChange, onClaimValueChange, labelValue, claimValue } = props;
  return (
    <VStack spacing="5px" align="stretch">
      <InputField placeholder="Add Value" onChange={onClaimLabelChange} value={labelValue} {...props} />
      <InputField type="number" onChange={onClaimValueChange} value={claimValue} {...props} />
    </VStack>
  );
};
AddValueFields.propTypes = {
  onClaimLabelChange: PropTypes.func.isRequired,
  onClaimValueChange: PropTypes.func.isRequired,
  labelValue: PropTypes.string.isRequired,
  claimValue: PropTypes.string.isRequired,
};

export const TfootTh = ({ children }) => (
  <Th fontSize="16px" backgroundColor="#F0F0F0" textTransform="none">
    {children}
  </Th>
);
TfootTh.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TipCard = ({ children, closable, ...rest }) => {
  const [visible, setVisible] = useState(true);
  return (
    <>
      {visible ? (
        <Box
          backgroundColor="nav.horizontalBandBgColor"
          padding="15px 26px 29px 19px"
          filter="drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))"
          borderWidth="1px"
          borderRadius="8px"
          borderColor="actionSecondary.default"
          fontWeight="400"
          height="max-content"
          className="tip-card"
          {...rest}
        >
          <Flex>
            <Box>
              <Flex alignItems="center">
                <TipIconSVG />
                <Heading fontSize="18px" fontWeight="600" marginLeft="11px" color="actionSecondary.default">
                  Tip
                </Heading>
              </Flex>
              <Box marginLeft="12px" marginTop="11px">
                {children}
              </Box>
            </Box>
            {closable ? (
              <CloseButton marginLeft="auto" width="13px" height="13px" onClick={() => setVisible(false)} />
            ) : (
              ''
            )}
          </Flex>
        </Box>
      ) : (
        ''
      )}
    </>
  );
};
TipCard.propTypes = {
  children: PropTypes.node.isRequired,
  closable: PropTypes.bool,
};

export const FieldGroup = ({ children, label, labelProps, ...rest }) => (
  <Box width="224px" {...rest}>
    <FormLabel {...labelProps}>{label}</FormLabel>
    {children}
  </Box>
);

FieldGroup.propTypes = {
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node.isRequired,
};

const badgeProps = {
  colorScheme: 'accent.one',
  fontSize: '16px',
  textTransform: 'capitalize',
  fontWeight: 500,
  padding: '1px 8px',
  borderRadius: '4px',
};

export const StatusPriorityBadge = ({ children, ...rest }) => (
  <Badge {...badgeProps} {...rest}>
    {children}
  </Badge>
);

StatusPriorityBadge.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const poDetailsTableCols = [
  {
    key: 'no',
    label: 'no',
    paths: ['ncrDetails.purchaseOrderLine.inputProduct.uid'],
  },
  {
    key: 'product_name',
    label: 'product name',
    paths: ['ncrDetails.purchaseOrderLine.inputProduct.name'],
    emptyValue: '-',
  },
  {
    key: 'cl',
    label: 'cl',
    paths: ['ncrDetails.purchaseOrderLine.inputProduct.cl'],
    emptyValue: '-',
  },
  {
    key: 'received_weight',
    label: 'received weight',
    paths: ['ncrDetails.purchaseOrderLine.recvQuantity'],
    emptyValue: '-',
  },
];

const PODetailsComponent = ({ overviewNCR, showTotal, tableCols, packerPlants, packers, isNCR, ...rest }) => {
  const calculateFinalValueTotal = () =>
    overviewNCR?.claim?.claimEntities?.reduce((acc, curr) => {
      const { value, approvedPerUnit, unitOfMeasureId } = curr;
      const claimValue = isNaN(value) ? 0 : value;
      const unitValue = isNaN(approvedPerUnit) ? 0 : approvedPerUnit;
      const cartonValue = ['lbs', 'pound'].includes(overviewNCR?.ncrDetails?.unitOfMeasure?.name?.toLowerCase())
        ? 59.96
        : 27.2;
      return (
        acc +
        (unitOfMeasureId && +unitOfMeasureId === 2 ? unitValue * cartonValue * claimValue : unitValue * claimValue)
      );
    }, 0);
  const willMakeClaim = get(overviewNCR, 'ncrDetails.willMakeClaim');

  // const claimEntityTotal = getOr(
  //   overviewNCR,
  //   'claim.claimEntityFinalTotal',
  //   getOr(overviewNCR, 'claim.claimEntityInitialTotal', null)
  // );

  // const calculateInitialValueTotal = () =>
  //   overviewNCR?.claim?.claimEntities?.reduce((acc, curr) => {
  //     const { value, grinderPricePerUnit, unitOfMeasureId } = curr;
  //     const claimValue = isNaN(value) ? 0 : value;
  //     const unitValue = isNaN(grinderPricePerUnit) ? 0 : grinderPricePerUnit;
  //     const cartonValue = ['lbs', 'pound'].includes(overviewNCR?.ncrDetails?.unitOfMeasure?.name?.toLowerCase())
  //       ? 59.96
  //       : 27.2;
  //     return (
  //       acc +
  //       (unitOfMeasureId && +unitOfMeasureId === 2 ? unitValue * cartonValue * claimValue : unitValue * claimValue)
  //     );
  //   }, 0);

  const initialClaimValue = getOr(overviewNCR, 'ncrDetails.initialClaimValue', null);

  const claimEntityTotal = calculateFinalValueTotal();
  const ncrCurrencyCode = get(overviewNCR, 'ncrDetails.purchaseOrderLine.purchaseOrder.sellCurrency');
  const colsConfig = defaultTo(tableCols, poDetailsTableCols);

  const packerId = get(
    packerPlants.find(packerPlant => packerPlant.id === get(overviewNCR, 'packerPlantId')),
    'packerId',
    null
  );

  const establishmentName = get(
    packerPlants.find(packerPlant => packerPlant.id === get(overviewNCR, 'packerPlantId')),
    'name',
    null
  );

  return (
    <VStack align="stretch" spacing="17px" fontWeight="400" {...rest}>
      <Box fontSize="16px">
        <Text>
          <b>Grinder:</b> {isNCR ? get(overviewNCR, 'grinder.name') : get(overviewNCR, 'purchaseOrder.grinderName')}
        </Text>
        <Text>
          <b>Packer:</b>&nbsp;
          {get(
            packers.find(packer => packer.id === packerId),
            'name',
            '-'
          )}
        </Text>
        {isNCR ? (
          <>
            <Text>
              <b>Packer SO:</b>&nbsp;
              {get(overviewNCR, 'ncrDetails.purchaseOrderLine.purchaseOrder.packerSoNumber')}
            </Text>
          </>
          ) : (
            ''
            )}
        <Text>
          <b>Establishment:</b>&nbsp;
          {establishmentName}
        </Text>
        {willMakeClaim ? (
          <>
            <Text>
              <b>Initial Estimated NCR Claim Value:</b>{' '}
              {initialClaimValue
                ? formatMonetaryValue(ncrCurrencyCode, initialClaimValue, {
                    notation: 'standard',
                  })
                : '-'}
            </Text>
            <Text>
              <b>Final NCR Claim Value:</b>{' '}
              {claimEntityTotal
                ? formatMonetaryValue(ncrCurrencyCode, claimEntityTotal, {
                    notation: 'standard',
                  })
                : '-'}
            </Text>
          </>
        ) : (
          ''
        )}
      </Box>
      <Box>
        <POGrinderNo
          isFrozen = {
            isNCR 
              ? !get(overviewNCR, 'ncrDetails.purchaseOrderLine.chilled')
              : !get(overviewNCR, 'purchaseOrder.chilled')
          }
          grinder={
            isNCR
              ? get(overviewNCR, 'ncrDetails.purchaseOrderLine.purchaseOrder.grinderPoNumber')
              : get(overviewNCR, 'purchaseOrder.ginderPo')
          }
          packer_plant={get(
            packers.find(
              packer =>
                packer.id ===
                get(
                  packerPlants.find(packerPlant => packerPlant.id === get(overviewNCR, 'packerPlantId')),
                  'packerId',
                  null
                )
            ),
            'name',
            ''
          )}
        />
        <HStack marginTop="13px" alignItems="flex-end" spacing={0}>
          <TableContainer width="max-content">
            <Table variant="simple">
              <Thead>
                <Tr>
                  {colsConfig.map(col => (
                    <Th key={col.key} fontSize="12px" padding="12px">
                      {col.label}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody backgroundColor="#ffffff" fontSize="14px">
                {[overviewNCR].map(data => {
                  return (
                    <Tr key={data.recordNo} fontWeight="400">
                      {colsConfig.map(col => {
                        const emptyValue = col.emptyValue || 'N/A';
                        const retrievedValue = nestedGetOr(0, col.paths || [], data, emptyValue);
                        let element = retrievedValue;

                        if (col.key === 'received_weight' && emptyValue !== retrievedValue) {
                          element = new Intl.NumberFormat(undefined, {
                            notation: 'standard',
                            maximumFractionDigits: 2,
                          }).format(retrievedValue);
                          element = `${element} ${getOr(
                            data,
                            'ncrDetails.purchaseOrderLine.purchaseOrder.sellUnitOfMeasure.name',
                            ''
                          )}`;
                        }

                        if (col.key === 'quantity_affected' && emptyValue !== retrievedValue) {
                          element = new Intl.NumberFormat(undefined, {
                            notation: 'standard',
                            maximumFractionDigits: 2,
                          }).format(retrievedValue);
                          element = `${element} ${getOr(data, 'ncrDetails.unitOfMeasure.name', '')}`;
                        }

                        if (col.key === 'claim_value' && emptyValue !== retrievedValue)
                          // element = formatMonetaryValue(ncrCurrencyCode, retrievedValue, {
                          //   notation: 'standard',
                          // });
                          element = formatMonetaryValue(ncrCurrencyCode, claimEntityTotal, {
                            notation: 'standard',
                          });
                        if (col.key === 'delivery_date' && emptyValue !== retrievedValue)
                          element = moment(retrievedValue).format('DD-MM-YYYY');

                        return (
                          <Td key={col.key} padding="12px">
                            {element}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
          {showTotal ? (
            <TableContainer width="max-content">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th fontSize="12px" padding="12px">
                      Total
                    </Th>
                  </Tr>
                </Thead>
                <Tbody backgroundColor="#ffffff" fontSize="14px">
                  <Tr fontWeight="400">
                    <Td padding="12px">
                      {formatMonetaryValue(
                        ncrCurrencyCode,
                        // [overviewNCR].reduce(
                        //   (acc, curr) =>
                        //     acc +
                        //     nestedGetOr(0, colsConfig.find(col => col.key === 'claim_value').paths || [], curr, 0),
                        //   0
                        // ),
                        claimEntityTotal,
                        {
                          notation: 'standard',
                        }
                      )}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            ''
          )}
        </HStack>
      </Box>
    </VStack>
  );
};

PODetailsComponent.propTypes = {
  showTotal: PropTypes.bool,
  overviewNCR: PropTypes.objectOf(PropTypes.any),
  tableCols: PropTypes.objectOf(PropTypes.any),
  packerPlants: PropTypes.arrayOf(PropTypes.any),
  packers: PropTypes.arrayOf(PropTypes.any),
};

const mapStateToProps = state => {
  const result = {
    ...findEntitiesInState(state, {
      packer_plants: 'packer_plant',
      packers: 'packer',
    }),
  };
  const cameled = convertToCamelCase(result);
  return {
    ...cameled,
    packerOptions: cameled.packers.map(packer => ({
      ...packer,
      value: packer.id,
      label: packer.name,
    })),
  };
};

export const PODetails = connect(mapStateToProps)(PODetailsComponent);
