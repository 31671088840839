import React from 'react';
import Datetime from 'react-datetime';

import SelectField from '../../../basic/SelectField';

const PackerOfferFormLine = props => {
  const ingredientOptions = props.input_products.map(item => {
    return { label: item.name, value: item.id };
  });

  const priceTypeOptions = [];

  props.price_types.forEach(function (element) {
    const capitalized = element.value.charAt(0).toUpperCase() + element.value.slice(1);
    priceTypeOptions.push({ value: element.value, label: capitalized });
  });
  const fabricationDateInputProps = {
    placeholder: 'Fabrication',
    className: 'form-control m-input',
  };

  return (
    <div key={`order-line-${props.index}`}>
      <div className="row" style={{ marginTop: '10px' }}>
        {props.dateWarnings.indexOf(props.index) > -1 ? (
          <div className="col-sm-12">
            <div
              onClick={() => {
                props.removeWarning(props.index);
              }}
              style={{ textTransform: 'none' }}
              className="fab-date-alert alert alert-info"
            >
              <i
                onClick={() => {
                  props.removeWarning(props.index);
                }}
                className="flaticon-circle"
              />
              The date you have selected is greater than 14 days in the past. Please ensure you have selected the
              correct date before continuing
            </div>
          </div>
        ) : (
          false
        )}
        <div className="col-sm-1">
          <h2>{props.index + 1}</h2>
          {props.index !== 0 ? (
            <button
              className="btn btn-sm btn-success"
              onClick={() => {
                props.removeFromList(props.index);
              }}
            >
              Remove
            </button>
          ) : (
            false
          )}
        </div>
        <div className="col-sm-3">
          <label htmlFor="select-input-product">Ingredient</label>
          <SelectField
            name="select-packer"
            defaultValue={props.line.input_product_uid}
            options={ingredientOptions}
            placeholder="Ingredient"
            onChange={e => {
              props.onLineValueChange('input_product_uid', e ? e.value : null, props.index);
            }}
          />
        </div>
        <div className="col-sm-2">
          <label htmlFor="fabrication_date">Fabrication Date</label>
          <Datetime
            id="fabrication_date"
            inputProps={fabricationDateInputProps}
            closeOnSelect
            value={props.line.fabrication_date}
            onChange={e => {
              if (e._isAMomentObject) {
                props.onFabricationDateChange(props.index, e ? e.format('YYYY-MM-DD') : null);
              } else {
                props.onFabricationDateChange(props.index, e);
              }
            }}
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
          />
        </div>
        <div className="col-sm-2">
          <label htmlFor="quantity">Quantity</label>
          <input
            id="quantity"
            type="number"
            className={`form-control ${props.error && props.line.number_of_bins === '' ? 'error' : ''}`}
            placeholder="Quantity"
            onChange={e => props.onLineValueChange('quantity', e ? e.target.value : null, props.index)}
            value={props.line.quantity}
            onWheelCapture={e => {
              e.target.blur();
            }}
          />
        </div>
        <div className="col-sm-2">
          <label htmlFor="price_per_unit">Price per Unit</label>
          <input
            id="price_per_unit"
            type="number"
            className={`form-control ${props.error && props.line.price_per_unit === '' ? 'error' : ''}`}
            placeholder="Price Per Unit"
            value={props.line.price_per_unit}
            onChange={e => props.onLineValueChange('price_per_unit', e.target.value, props.index)}
            onWheelCapture={e => {
              e.target.blur();
            }}
          />
        </div>
        <div className="col-sm-2">
          <label htmlFor="price_type">Order Type</label>
          <SelectField
            isDisabled
            defaultValue={props.line.price_type}
            options={priceTypeOptions}
            onChange={e => {
              props.onLineValueChange('price_type', e ? e.value : null, props.index);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PackerOfferFormLine;
