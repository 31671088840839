import React from 'react';
import { Select, Typography } from 'antd';

export default function FilterPackerPlant(props) {
  const { packer_plant, packer_plants, onActivePackerPlantChange } = props;

  return (
    <div>
      <Typography.Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Filter by establishment
      </Typography.Text>
      <Select value={packer_plant} style={{ width: '100%' }} onChange={onActivePackerPlantChange}>
        <Select.Option value={null}>--</Select.Option>
        {packer_plants.map(x => (
          <Select.Option key={`packer-plant-${x}`} value={x}>
            {x}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
