import { sum } from 'd3';

export function calculateWeightedAverage(orders, accessor, quantityKey = 'quantity') {
  const totalWeight = sum(orders, order => order[quantityKey]);
  const totalUnit = sum(orders, order => order[quantityKey] * accessor(order));

  return totalUnit / totalWeight;
}

export function calculate76LeanWeightedAveragePrice(orders, quantityKey = 'quantity') {
  const wtdAveragePrice = calculateWeightedAverage(orders, order => order.sell_price_per_unit, quantityKey);
  const wtdAverageLean = calculateWeightedAverage(orders, order => order.cl, quantityKey);
  const price = (wtdAveragePrice / wtdAverageLean) * 76;
  return price;
}
